import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';

const search = (idOrToken, queryParams = { itemLimit: 500 }) => {
  return axios
    .get(
      makeApiWithQuery(`${getConsoleApiEndpoint('Marker')}/Search/${idOrToken}`, { ...queryParams })
    )
    .then((response) => response.data)
};

const categorySearch = (idOrToken, category, queryParams = { itemLimit: 100 }) => {
  if (category) {
    return axios
      .get(
        makeApiWithQuery(
          `${getConsoleApiEndpoint('Marker')}/CategorySearch/${category}/${idOrToken}`,
          { ...queryParams }
        )
      )
      .then((response) => response.data)
  } else {
    return axios
      .get(makeApiWithQuery(`${getConsoleApiEndpoint('Marker')}/${idOrToken}`, { ...queryParams }))
      .then((response) => response.data)
  }
};

const get = (markerId) => {
  return axios
    .get(`${getConsoleApiEndpoint('Marker')}/${markerId}`)
    .then((response) => response.data)
};

const create = (model) => {
  return axios
    .post(`${getConsoleApiEndpoint('Marker')}`, model)
    .then((response) => response.data)
};

const edit = (model) => {
  return axios
    .put(`${getConsoleApiEndpoint('Marker')}/${model.id}`, model)
    .then((response) => response.data)
};

const remove = (id) => {
  return axios
    .delete(`${getConsoleApiEndpoint('Marker')}/${id}`)
    .then((response) => response.data)
};

const deleteTag = (contentId, markerType, tagKey) =>
  axios
    .delete(`${getConsoleApiEndpoint('Marker')}/${contentId}/${markerType}Tag/${tagKey}`)
    .then((response) => response.data)
    .then((response) => response.data)

const deleteTagsByType = (contentId, markerType) =>
  axios
    .delete(`${getConsoleApiEndpoint('Marker')}/${contentId}/${markerType}Tag`)
    .then((response) => response.data)
    .then((response) => response.data)

const deleteMarkersByType = (contentId, markerType) =>
    axios
      .delete(`${getConsoleApiEndpoint('Marker')}/${contentId}/${markerType}`)
      .then((response) => response.data)
      .then((response) => response.data)

const getMarkerTypes = () => {
  return axios
    .get(`${getConsoleApiEndpoint('MarkerEnums')}/MarkerTypes`)
    .then((res) => res.data)
};

const getMarkerTypeValues = () => {
  return axios
    .get(`${getConsoleApiEndpoint('MarkerEnums')}/MarkerTypeValues`)
    .then((res) => res.data)
};

const getTags = () => {
  return axios
    .get(`${getConsoleApiEndpoint('MarkerEnums')}/TagTypes`)
    .then((res) => res.data)
};

const getVideoSettings = () => {
  return axios
    .get(`${getConsoleApiEndpoint('MarkerEnums')}/ClipVideoSettings`)
    .then((res) => res.data)
};

const getAudioSettings = () => {
  return axios
    .get(`${getConsoleApiEndpoint('MarkerEnums')}/ClipAudioSettings`)
    .then((res) => res.data)
};

const searchGlobalTags = (tagType) => {
  if (!tagType) return [];
  return axios
    .get(`${getConsoleApiEndpoint('Marker')}/SearchGlobalTag?type=${tagType}&itemLimit=1000`)
    .then((res) => res.data.pageContent)
};

const translate = (reqBody) => {
  return axios
    .put(`${getConsoleApiEndpoint('Marker')}/RequestLocalization/Subtitle`, reqBody)
    .then((response) => response.data)
};

const refreshIndexes = () =>
  axios
    .put(`${getConsoleApiEndpoint('Marker')}/RefreshIndexes`)
    .then((response) => response.data)

const recalculateGlobalTags = () =>
  axios
    .put(`${getConsoleApiEndpoint('Marker')}/RecalculateGlobalTags`)
    .then((response) => response.data)

export {
  search,
  categorySearch,
  get,
  create,
  edit,
  remove,
  deleteTag,
  deleteTagsByType,
  deleteMarkersByType,
  getMarkerTypes,
  getMarkerTypeValues,
  getTags,
  searchGlobalTags,
  translate,
  refreshIndexes,
  recalculateGlobalTags,
  getVideoSettings,
  getAudioSettings,
};

export default {
  search,
  categorySearch,
  get,
  create,
  edit,
  remove,
  deleteTag,
  deleteTagsByType,
  deleteMarkersByType,
  getMarkerTypes,
  getMarkerTypeValues,
  getTags,
  searchGlobalTags,
  translate,
  refreshIndexes,
  recalculateGlobalTags,
  getVideoSettings,
  getAudioSettings,
};
