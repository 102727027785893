import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Items from 'pages/Content/Form/Items';
import collectionsServiceV2 from 'services/collectionsServiceV2';
import parametersService from 'services/parametersService';
import contentAction from 'store/actions/contentAction';
import AlertService from 'services/alertService';
import IngestFile from 'components/IngestFile';
import NoResultsDataGridOverlay from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';
import ThemedButton from 'components/ThemedButton';
import TabPanel from 'components/TabPanel';
import AssetTypeIcon from 'components/AssetTypeIcon';
import withFileUpload from 'helpers/withFileS3Upload';
import { dateFormatter } from 'helpers/time';
import { utcToLocalTimeZone } from 'utils/timezoneDate';


const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(6),
  },
  dialogHeaderTabs: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(1),
    width: '100%',
    zIndex: theme.zIndex.modal + 1,
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    padding: theme.spacing(1, 0),
    left: theme.spacing(1),
    right: theme.spacing(1),
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.modal + 1,
  },
}));

const ImportDialog = ({
  contentId,
  contentTitle,
  originLang,
  upload,
  enqueueSnackbar,
  trigger = () => {},
  closeModal = () => {},
}) => {
  const classes = useStyles();
  const user = useSelector((state) => state.auth?.user);
  const contentType = useSelector((state) => state.content.contentType);
  const [resultsFromServer, setResultsFromServer] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [idsToAdd, setIdsToAdd] = useState([]);
  const [remixOrgs, setRemixOrgs] = useState([]);
  const [tabNumber, setTabNumber] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const dispatch = useDispatch();

  const importDialogExtraFields = useSelector((state) => state.content.importDialogExtraFields);

  useEffect(() => {
    async function checkDisplayImportExtraField() {
      try {
        const response = await parametersService.getByKey('Console:Content:Ingest:Tags');
        dispatch(
          contentAction.setImportExtraFields({
            toDisplay: response?.value === 'true',
            paramChecked: true,
          })
        );
      } catch (error) {
        dispatch(
          contentAction.setImportExtraFields({ ...importDialogExtraFields, paramChecked: true })
        );
      }
    }
    if (!importDialogExtraFields.paramChecked) checkDisplayImportExtraField();
  }, [importDialogExtraFields]);

  useEffect(() => {
    const getOrgs = () =>
      collectionsServiceV2.getContentRelation(contentId, 'Organization').then((res) => {
        const { role } = user;
        const { organizations } = user;
        setRemixOrgs(
          res.pageContent
            ?.map((e) => e.targetId)
            .filter((e) => role === 'SysAdmin' || organizations.includes(e)) || []
        );
      });
    getOrgs();
  }, []);

  useEffect(() => {
    if (resultsFromServer.length) {
      setFilesUploaded(true);
    }
  }, [resultsFromServer]);

  const saveOrgRelations = (ids) =>
    Promise.allSettled(
      ids.reduce((acc, curr) => {
        Array.from(
          new Set([...remixOrgs, localStorage.getItem('selectedOrganization')].filter((e) => !!e))
        ).forEach((org) =>
          acc.push(collectionsServiceV2.addContentRelation(curr, 'Organization', org))
        );
        return acc;
      }, [])
    );

  const saveImport = async () => {
    if (!resultsFromServer.length && !idsToAdd.length) return;
    const ingestedContentIds = resultsFromServer.map((ingested) => ingested.id);
    const relationsToAdd = [...ingestedContentIds, ...idsToAdd];
    Promise.all(
      relationsToAdd.map((id) => collectionsServiceV2.addContentRelation(contentId, 'Playlist', id))
    )
      .then(() => saveOrgRelations(relationsToAdd))
      .then(() => {
        trigger?.();
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: contentTitle }),
        });
        closeModal();
        setResultsFromServer([]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  };

  const columnsDefinition = [
    {
      field: 'type',
      headerName: ' ',
      width: 24,
      renderCell: (item) => <AssetTypeIcon type={item.row.type} color="primary" />
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      minWidth: 142,
      renderCell: (item) => dateFormatter(utcToLocalTimeZone({ date: item.row.createdDate })),
    },
    {
      field: 'originalTitle',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      minWidth: 320,
      flex: 3,
      renderCell: (item) => item.row.originalTitle,
    },
  ];

  return (
    <Box flex={1}>
      <Box className={classes.dialogHeaderTabs}>
        <Tabs
          value={tabNumber}
          onChange={(e, newTab) => {
            e.preventDefault();
            setTabNumber(newTab);
          }}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={lookUp({ key: 'CONSOLE_CONTENT_INGEST' })} />
          <Tab label={lookUp({ key: 'CONSOLE_ITEMS_TAB' })} />
        </Tabs>
      </Box>
      <Box flex={1} className={classes.container}>
        <TabPanel value={tabNumber} index={0}>
          <IngestFile
            upload={upload}
            enqueueSnackbar={enqueueSnackbar}
            setResultsFromServer={setResultsFromServer}
            onUploadStatusChange={setIsUploading}
            dropdown={Boolean(importDialogExtraFields.toDisplay)}
            parentContentType={contentType}
            parentLocale={originLang}
            fullWidthContent
          />
          {resultsFromServer[0]?.assets?.length && (
            <Typography variant="button" gutterBottom>{lookUp({ key: 'CONSOLE_FILE_UPLOADED' })}</Typography>
          )}
          {Boolean(resultsFromServer.length) && (
            <DataGrid
              columns={columnsDefinition}
              rows={resultsFromServer}
              components={{
                LoadingOverlay: DataGridOverlayLoaderLinear,
                NoRowsOverlay: NoResultsDataGridOverlay,
                NoResultsOverlay: NoResultsDataGridOverlay,
              }}
              componentsProps={{
                noRowsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
                noResultsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
              }}
              rowHeight={64}
              autoHeight
              autoPageSize
              disableSelectionOnClick
            />
          )}
        </TabPanel>
        <TabPanel value={tabNumber} index={1}>
          <Items
            model={{ referenceItems: {} }}
            handleChange={(key, value) => setIdsToAdd(value.Playlist)}
            enqueueSnackbar={enqueueSnackbar}
            parentComponent="ImportDialog"
          />
        </TabPanel>
      </Box>
      <DialogActions className={classes.actions}>
        <Box flex={1} />
        <ThemedButton onClick={closeModal}>
          {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
        </ThemedButton>
        <ThemedButton
          color="success"
          onClick={saveImport}
          disabled={filesUploaded || idsToAdd.length ? false : true}
          autoFocus
        >
          {contentType === 'Remix'
            ? lookUp({ key: 'CONSOLE_ADD_FILE_TO_REMIX_BUTTON' })
            : lookUp({ key: 'CONSOLE_ADD_FILE_TO_PLAYLIST_BUTTON' })
          }
        </ThemedButton>
      </DialogActions>
    </Box>
  );
};

ImportDialog.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentTitle: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  upload: PropTypes.func.isRquired,
  enqueueSnackbar: PropTypes.func.isRquired,
  trigger: PropTypes.func.isRquired,
};

export default withFileUpload(withSnackbar(ImportDialog));
