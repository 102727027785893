import { Link as RouterLink } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import AlertService from 'services/alertService';
import {
  makeStyles,
  Typography,
  TextField,
  MenuItem,
  ListSubheader,
  Button,
  Badge,
  Grid,
  Switch,
  FormGroup,
  Input,
} from '@material-ui/core';
import { GridToolbarContainer } from '@material-ui/data-grid';
import { Add, Clear, FilterList, CheckBox, Publish, GetApp, Delete } from '@material-ui/icons/';
import { ConfirmDialog } from 'components';

const useStyles = makeStyles((theme) => ({
  extraToolbarButton: {
    fontSize: '0.8125rem',
    padding: '0 0.2rem',
    textDecoration: 'none',
    margin: '0 .2rem',
    minWidth: '2em',
    color: 'white',
    backgroundColor: theme.palette.primary?.main,
    '&:hover': {
      backgroundColor: 'grey',
    },
  },
}));

function ListToolBar(props) {
  const {
    additionalFilters,
    additionalFiltersState,
    clearAllSearchFields,
    contentFormatAndType,
    create,
    dropdownFilters,
    enqueueSnackbar,
    exportData,
    extraToolbarButtons,
    handleMassDelete,
    isCheckboxVisible,
    mainDropdownSelector,
    page,
    pageSize,
    resetFilters,
    search,
    selectionModel,
    setCheckboxVisible,
    setQuickAddOpen,
    setTagCloudOpen,
    toolbarSwitches,
    uploadFile,
    uploadType,
    checkbox = false,
  } = props;

  const classes = useStyles();
  
  const importFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    uploadType === 'localizationXLSX' && formData.append('file', file);
    const dataToUpload = uploadType === 'localizationXLSX' ? formData : file;
    return uploadFile(dataToUpload)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_File_upload_success' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_file_upload_error' }),
        });
      })
      .finally(() => {
        const uploadInput = document.getElementById('uploadFileInput');
        uploadInput.value = '';
      });
  };

  const exportClick = async (items = false) => {
    let loadParams = {
      q: search.q,
    };
    additionalFiltersState &&
      Object.keys(additionalFiltersState).forEach((key) => {
        loadParams[key] = additionalFiltersState[key];
      });
    loadParams.type = contentFormatAndType || null;
    loadParams.pageSize = pageSize.table || 25;
    loadParams.page = page || 0;
    if (uploadType === 'localizationXLSX') loadParams = 'LocalizationStrings.xlsx';
    try {
      (await items)
        ? exportData(
            loadParams,
            selectionModel.map((item) => item.id)
          )
        : exportData(loadParams);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_File_export_starting_MESSAGE' }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const uploadClick = (event) => {
    const uploadInput = document.getElementById('uploadFileInput');
    uploadInput.click();
  };

  const dropdownOptions = (options, category) => {
    if (!options.length) return;
    const menuItems = options
      .sort((a, b) => a?.label?.localeCompare(b.label))
      .map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Typography variant="button">{option.label}</Typography>
        </MenuItem>
      ));
    if (category) menuItems.unshift(<ListSubheader>{category}</ListSubheader>);
    return menuItems;
  };

  const arrayDropdown = (dropdown) => {
    return (
      <TextField
        key={dropdown.key}
        select
        style={{ marginTop: '.225em', marginRight: '0.5em' }}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        SelectProps={{
          displayEmpty: true,
          autoWidth: true,
          MenuProps: { disableScrollLock: true }
        }}
        value={additionalFiltersState[dropdown.key] || ''}
        onChange={(e) => dropdown.change(e.target.value)}
      >
        <MenuItem value={''}>
          <Typography variant="button">
            {dropdown.loading
              ? `${dropdown.label} ${lookUp({ key: 'CONSOLE_LOADING' })}`
              : `${dropdown.label}`}
          </Typography>
        </MenuItem>
        {dropdownOptions(dropdown.options)}
      </TextField>
    );
  };

  const categorizedDropdown = (dropdown) => {
    const categories = Object.keys(dropdown?.options || {});
    return (
      <TextField
        select
        style={{ marginTop: '.225em', marginRight: '0.5em' }}
        variant="standard"
        InputProps={{ disableUnderline: true }}
        SelectProps={{
          displayEmpty: true,
          autoWidth: true,
          MenuProps: { disableScrollLock: true }
        }}
        value={additionalFiltersState[dropdown.key] || ''}
        onChange={(e) => dropdown.change(e.target.value)}
      >
        <MenuItem value={''}>
          <Typography variant="button">
            {dropdown.loading ? `${dropdown.label} loading...` : `All ${dropdown.label}`}
          </Typography>
        </MenuItem>
        {categories.map((cat) => dropdownOptions(dropdown.options[cat], cat))}
      </TextField>
    );
  };

  return (
    <GridToolbarContainer
      style={{ padding: '.5rem 0', width: '100%', alignContent: 'space-between' }}
    >
      <Grid container justifyContent="space-between">
        <Grid container justifyContent="space-between">
          <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1} alignItems="center">
              <Grid item>
                {!!mainDropdownSelector && mainDropdownSelector(clearAllSearchFields)}
              </Grid>
              <Grid item>
                {dropdownFilters &&
                  dropdownFilters.length > 0 &&
                  dropdownFilters.map((dropdown, i) =>
                    dropdown.component ? dropdown.component :
                    Array.isArray(dropdown.options)
                      ? dropdown.options.some((option) => option !== '')
                        ? arrayDropdown(dropdown)
                        : null
                      : categorizedDropdown(dropdown)
                  )}
              </Grid>
              {resetFilters && (
                <Button
                  onClick={resetFilters}
                  color="primary"
                  size="small"
                  variant="text"
                  startIcon={<Clear style={{ marginRight: '-.25em' }} />}
                >
                  {lookUp({ key: 'CONSOLE_RESET_BUTTON' })}
                </Button>
              )}
            </Grid>
            {additionalFilters?.length > 0 && (
              <Badge
                color="primary"
                badgeContent={
                  Object.keys(additionalFiltersState).filter((k) => {
                    return additionalFiltersState[k].length > 0 && k !== 'type';
                  }).length
                }
              >
                <Button
                  onClick={openFilters}
                  color="primary"
                  size="small"
                  variant="text"
                  startIcon={<FilterList style={{ marginRight: '-.25em' }} />}
                >
                  {lookUp({ key: 'CONSOLE_FILTERS_BUTTON' })}
                </Button>
              </Badge>
            )}
          </Grid>
        </Grid>
        {extraToolbarButtons && (
          <Grid item container>
            {extraToolbarButtons.map((e, i) => (
              <Button
                key={i}
                color="inherit"
                className={classes.extraToolbarButton}
                size="small"
                variant="text"
                onClick={() => {
                  e.click();
                  setTagCloudOpen(true);
                }}
              >
                {e.label}
              </Button>
            ))}
          </Grid>
        )}
        <Grid container justifyContent="flex-end" alignItems="center">
          {checkbox && 
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => setCheckboxVisible((o) => !o)}
              startIcon={<CheckBox />}
            >
              {lookUp({ key: 'CONSOLE_SELECT_BUTTON' })}
            </Button>
          }
          {isCheckboxVisible && (
            <>
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={selectionModel.length > 0 ? () => exportClick(true) : null}
                startIcon={<GetApp />}
              >
                {lookUp({
                  key: 'CONSOLE_EXPORT_BUTTON_TEMPLATE',
                  number: selectionModel?.length || 0,
                })}
              </Button>
              <ConfirmDialog
                buttonColor={'primary'}
                buttonVariant={'text'}
                buttonSize={'small'}
                buttonType={'Plain'}
                buttonFullWidth={false}
                dialogText={'Approve'}
                buttonStartIcon={<Delete />}
                message={lookUp({
                  key: 'CONSOLE_DELETEITEMS_MESSAGE_TEMPLATE',
                  number: selectionModel?.length,
                })}
                buttonText={lookUp({
                  key: 'CONSOLE_DELETE_BUTTON_TEMPLATE',
                  number: selectionModel?.length || 0,
                })}
                onConfirm={
                  selectionModel.length > 0 ? () => handleMassDelete(selectionModel) : null
                }
              />
            </>
          )}
          {!!uploadFile && (
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={uploadClick}
              startIcon={<Publish />}
            >
              {lookUp({ key: 'CONSOLE_UPLOAD_BUTTON' })}
              <Input
                id="uploadFileInput"
                type="file"
                style={{ display: 'none' }}
                onChange={importFile}
              />
            </Button>
          )}
          {exportData && (
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => exportClick()}
              startIcon={<Publish style={{ transform: 'rotate(180deg)' }} />}
            >
              {lookUp({ key: 'CONSOLE_EXPORT_BUTTON' })}
            </Button>
          )}
          {/* <GridToolbarExport /> */} {/* Built-in CSV export button */}
          {create && (
            <RouterLink to={create.link} style={{ textDecoration: 'none' }}>
              <Button
                color="primary"
                size="small"
                variant="text"
                startIcon={<Add style={{ marginRight: '-.25em' }} />}
              >
                {lookUp({ key: 'CONSOLE_ADD_BUTTON' })}
              </Button>
            </RouterLink>
          )}
          {setQuickAddOpen && (
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => setQuickAddOpen(true)}
            >
              {lookUp({ key: 'CONSOLE_QUICK_ADD_BUTTON' })}
            </Button>
          )}
          <FormGroup row>
            {toolbarSwitches &&
              toolbarSwitches.map((element, index) => (
                <div key={index}>
                  <Switch
                    key={index}
                    color="primary"
                    size="small"
                    name={element.label}
                    checked={element.state}
                    onClick={element.onClick}
                    inputProps={{ role: 'checkbox' }}
                  />
                  <Typography variant="button" color="primary">
                    {element.label}
                  </Typography>
                </div>
              ))}
          </FormGroup>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default withSnackbar(ListToolBar);
