import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape, string } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, Switch, Typography, makeStyles, TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { FormFooter, EditorInfo } from 'components';
import parametersService from 'services/parametersService';
import navigationAction from 'store/actions/navigationAction';
import { goBackOrStay } from 'helpers/common';
import AlertService from 'services/alertService';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  textField: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    overflowwrap: 'break-word',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

function ParametersForm(props) {
  const { enqueueSnackbar } = props;

  const { key } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const location = useLocation();

  const [model, setModel] = useState({
    key: '',
    value: '',
    isSystem: false,
  });
  const [isGlobal, setIsGlobal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const pageTitle = key
      ? lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: decodeURIComponent(key) })
      : lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_${'Parameter'}` }) });
    dispatch(navigationAction.setPageTitle(pageTitle));
    if (!key) return;
    const getData = async () => {
      let isGlobal = false;
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has('isGlobal')) {
        isGlobal = searchParams.get('isGlobal').toLowerCase() === 'true';
      }
      const result = await parametersService.getByKey(key, isGlobal);
      setModel((model) => ({ ...model, ...result }));
    };
    getData();
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('isGlobal')) {
      setIsGlobal(searchParams.get('isGlobal').toLowerCase() === 'true');
    }
  }, [location.search]);

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {

      key
        ? await parametersService.edit(model, isGlobal)
        : await parametersService.create(model, isGlobal);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: model.key
          ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.key })
          : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.key }),
      });
      goBackOrStay(navigate, '/configuration/parameters');
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_save_error' }),
      });
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  };

  const deleteParameter = async () => {
    setIsDeleting(true);
    try {
      await parametersService.remove(model.key, isGlobal);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/configuration/parameters'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.key }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_PARAMETER' }),
        }),
      });
      setIsDeleting(false);
    }
  };

  return (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            key && (
              <ThemedButton
                color={model.published ? 'secondary' : 'success'}
                disabled={
                  isPublishing ||
                  isSaving
                }
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({
                  key: model.published ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON',
                })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            name && (
              <DeleteButton onDelete={deleteParameter} loading={isDeleting} disabled={isDeleting} />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/configuration/parameters')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: key ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>

        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <TextField
                id="parameter-key"
                label={lookUp({ key: 'CONSOLE_KEY' })}
                required
                className={classes.textField}
                value={model.key !== 'undefined' ? model.key : ''}
                onChange={(e) => handleModelChange('key', e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item>
              <TextField
                id="parameter-value"
                label={lookUp({ key: 'CONSOLE_VALUE' })}
                multiline={true}
                required
                className={classes.textField}
                value={model.value !== 'undefined' ? model.value : ''}
                onChange={(e) => handleModelChange('value', e.target.value)}
              />
            </Grid>
          </Grid>

          {userRole === 'SysAdmin' ? (
            <>
              <Grid item xs={12}>
                <Switch
                  color="primary"
                  size="small"
                  checked={model.isSystem}
                  onChange={(event) => {
                    setModel((model) => ({ ...model, isSystem: event.target.checked }));
                  }}
                />
                <Typography variant="button" color="primary">
                  {lookUp({ key: 'CONSOLE_ISSYSTEM' })}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Switch
                  color="primary"
                  size="small"
                  checked={isGlobal}
                  onChange={(event) => {
                    navigate(`${location.pathname}?isGlobal=${event.target.checked}`);
                  }}
                />
                <Typography variant="button" color="primary">
                  {lookUp({ key: 'CONSOLE_GLOBAL' })}
                </Typography>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>

      {key && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

ParametersForm.propTypes = {
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(ParametersForm);
