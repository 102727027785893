import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { func, string } from 'prop-types';
import _ from 'lodash';
import { withSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Tooltip, TextField, CircularProgress } from '@material-ui/core';
import libraryPropertyService from 'services/libraryPropertyService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  input: {
    display: 'flex',
    height: '40px',
  },
}))

const PropsSelect = (props) => {
  
  const classes = useStyles();
  const { handleChange, label, value, enqueueSnackbar } = props;
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getOptions = async (e, value) => {
    setIsLoading(true);

    try {
      const resOpt = await libraryPropertyService.getPropertyComplete(value);
      setOptions(resOpt);
    } catch (e) {
      enqueueSnackbar(lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_property' }) }), {
        variant: 'error',
        autoHideDuration: 5000
      });
    }

    setIsLoading(false);
  };

  const handleSelectChange = (e, value) => {
    handleChange(value);
  };

  const inputChange = (e, value) => {
    handleSelectChange(e, value);
    _.debounce(getOptions, 1000);
    getOptions(e, value);
  };

  useEffect(() => {
    getOptions(null, '');
  }, []);

  return (
    <div className={classes.root}>
    <Tooltip title={lookUp({ key: 'CONSOLE_PROPERTY_SELECT_HELPERTEXT' })}>
      <Autocomplete
        freeSolo
        value={value}
        onInputChange={inputChange}
        onChange={handleSelectChange}
        getOptionSelected={(option, value) => option === value}
        options={options}
        loading={isLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    </Tooltip>
    </div>
  );
};

PropsSelect.propTypes = {
  handleChange: func.isRequired,
  value: string.isRequired,
  label: string,
  enqueueSnackbar: func.isRequired
};

PropsSelect.defaultProps = {
  label: lookUp({ key: 'CONSOLE_NAME' })
};

export default withSnackbar(PropsSelect);
