import { SET_BRAND_STYLE } from '../actions/brandStyleAction';

const initialState = {
  images: {
    backdrop: '',
    primaryEmblem: '',
    primaryLogo: '',
    whiteEmblem: '',
    whiteLogo: '',
  },
  chartColors: [
    '#d2691E',
    '#2f4f4f',
    '#daa520',
    '#6495ed',
    '#808000',
    '#8b4513',
    '#228b22',
    '#483d8b',
    '#008b8b',
    '#f0e68c',
    '#ff7f50',
    '#00ced1',
    '#a9a9a9',
    '#7fff00',
    '#a52a2a',
    '#8a2be2',
    '#f4a460',
    '#b8860b',
    '#006400',
    '#ff8c00',
    '#5f9ea0',
    '#ff1493',
    '#7cfc00',
    '#f5deb3',
    '#90ee90',
    '#800000',
    '#778899',
    '#f08080',
    '#3cb371',
    '#0000cd',
    '#ffdead',
    '#6b8e23',
    '#7b68ee',
    '#ff4500',
    '#00fa9a',
    '#ffa500',
    '#000080',
    '#c71585',
    '#afeeee',
    '#cd853f',
    '#800080',
    '#98fb98',
    '#4169e1',
    '#fa8072',
    '#2e8b57',
    '#6a5acd',
    '#ff6347',
    '#d2b48c',
    '#008080',
    '#000000',
  ]
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_BRAND_STYLE:
      return {
        ...state,
        images: action.images,
      };
    default:
      return state;
  }
};
