const types = {
  Image: 'images',
  Audio: 'audio-items',
  Video: 'video-items',
  Advertisement: 'advert',
  LiveStream: 'streams',
  Podcast: 'podcasts',
  Collection: 'collections',
  Playlist: 'playlists',
  Trailer: 'trailers',
  Text: 'text',
  Pdf: 'pdf',
  Word: 'word',
  Series: 'series',
  Files: 'file',
  Merchandise: 'merchandise',
  SmartContract: 'smart-contract',
  ArFile: 'ar-file',
  VrStream: 'vr-stream',
  Season: 'seasons',
  Episode: 'episodes',
  Remix: 'remix',
  ScreenCapture: 'screen-captures',
  CameraCapture: 'camera-captures',
  LiveChat: 'live-chats'
};

const videoTypes = [
  'Video', 'CameraCapture', 'ScreenCapture',
];

const titles = {
  'images': 'Image',
  'audio-items': 'Audio Item',
  'video-items': 'Video Item',
  'advert': 'Advertisement',
  'streams': 'Stream',
  'podcasts': 'Podcast',
  'collections': 'Collection',
  'playlists': 'Playlist',
  'trailers': 'Trailer',
  'text': 'Text',
  'pdf': 'PDF',
  'word': 'Word',
  'series': 'Series',
  'files': 'File',
  'merchandise': 'Merchandise',
  'smart-contract': 'SmartContract',
  'ar-file': 'ArFile',
  'vr-stream': 'VrStream',
  'seasons': 'Season',
  'episodes': 'Episode',
  'remix': 'Remix',
  'screen-captures': 'ScreenCapture',
  'camera-captures': 'CameraCapture',
  'live-chats': 'LiveChat',
};

const query = {
  'images': 'Image',
  'audio-items': 'Audio',
  'video-items': 'Video',
  'advert': 'Advertisement',
  'streams': 'LiveStream',
  'podcasts': 'Podcast',
  'collections': 'Collection',
  'playlists': 'Playlist',
  'trailers': 'Trailer',
  'text': 'Text',
  'pdf': 'Pdf',
  'word': 'Word',
  'series': 'Series',
  'files': 'File',
  'merchandise': 'Merchandise',
  'smart-contract': 'SmartContract',
  'ar-file': 'ArFile',
  'vr-stream': 'VrStream',
  'seasons': 'Season',
  'episodes': 'Episode',
  'remix': 'Remix',
  'screen-captures': 'ScreenCapture',
  'camera-captures': 'CameraCapture',
  'live-chats': 'LiveChat',
};

export default {
  types,
  videoTypes,
  titles,
  query 
};

export {
  types,
  videoTypes,
  titles,
  query 
};