import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, func, bool, object, array } from 'prop-types';
import { withSnackbar } from 'notistack';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  makeStyles,
  Collapse,
  Switch,
  Tooltip,
  Divider,
  MenuList,
  Popper,
  MenuItem,
  ClickAwayListener,
  ButtonGroup,
  Grow,
  Paper,
} from '@material-ui/core';
import { Add, Delete, ArrowDownward, ArrowUpward, } from '@material-ui/icons';
import { Loader } from 'components';
import RulesForm from './RulesForm';
import PropsForm from './PropsForm';
import libraryEnumsService from 'services/libraryEnumsService';
import {
  setActionGroups,
  setActionTypes,
  setHttpMethods,
  setScheduleTypes,
  setParametersItems,
  setCatalogItems
} from 'store/actions/libraryEnumsAction';
import parametersService from 'services/parametersService';
import catalogService from 'services/catalogService';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  actionDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const ActionsForm = (props) => {
  
  const {
    handleChange,
    actions,
    enqueueSnackbar,
    scheduleTypes,
    catalogItems,
    parametersItems,
    setScheduleTypes,
    setCatalogItems,
    setParametersItems
  } = props;

  const classes = useStyles();

  const [items, setItems] = useState(
    actions.map((a) => {
      return (
        a.schedule
          ? a
          : {
            ...a,
            schedule: null
          }
      )
    }))


  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const [scheduleTypesOptions, setScheduleTypesOptions] = useState([]);
  const [isScheduleTypesOptionsLoading, setIsScheduleTypesOptionsLoading] = useState(false);

  const [actionTypesOptions, setActionTypesOptions] = useState([]);
  const [isActionTypesOptionsLoading, setIsActionTypesOptionsLoading] = useState(false);

  const [actionGroupsOptions, setActionGroupsOptions] = useState([]);
  const [isActionGroupsOptionsLoading, setIsActionGroupsOptionsLoading] = useState(false);

  const [httpMethodsOptions, setHttpMethodsOptions] = useState([]);
  const [isHttpMethodsOptionsLoading, setIsHttpMethodsOptionsLoading] = useState(false);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const newOptions = ['Action', 'Navigation', 'SeeAll'];

  const toOptions = (items) => items.map((i) => ({ label: i, value: i }));

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleItemsChange = (index, key, value) => {
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));
  }

  const handleItemsScheduleChange = (index, key, value) => {
    const transfValue = (key === 'value') ? moment(value).format('HH:mm:ss') : value;
    setItems((prev) =>
      prev.map((p, i) => (i === index ? { ...p, schedule: (key === 'type' && value === null) ? null : { ...p.schedule, [key]: transfValue } } : p)));
  }

  const handleItemsRemove = (index) => setItems((prev) => prev.filter((p, i) => i !== index));


  const handleItemsAdd = () => {
    switch (selectedIndex) {
      case (0):

        return (
          setItems((prev) => [
            ...prev,
            {
              name: '',
              enabled: true,
              actionType: 'Navigate',
              displayRules: [],
              actionGroup: 'None',
              httpMethod: 'Get',
              url: '',
              parameters: [],
              schedule: null
            }
          ]))
      case (1):
        return (
          setItems((prev) => [
            ...prev,
            {
              name: 'Navigation',
              enabled: true,
              actionType: 'Navigate',
              displayRules: [],
              actionGroup: 'None',
              httpMethod: 'Get',
              url: '',
              parameters: [],
              schedule: null
            }
          ])
        )
      case (2):
        return (
          setItems((prev) => [
            ...prev,
            {
              name: 'SeeAll',
              enabled: true,
              actionType: 'Navigate',
              displayRules: [],
              actionGroup: 'None',
              httpMethod: 'Get',
              url: '',
              parameters: [],
              schedule: null
            }
          ])
        )
      default:
        return '';
    }
  }

  const handleEditSchedule = (index) =>
    setItems((prev) =>
      prev.map((p, i) =>
        i === index
          ? { ...p, schedule: { type: p.schedule.type, value: p.schedule.value } }
          : p
      )
    );

  const getCatalogs = async () => {
    try {
      const { totalCount } = await catalogService.search({ page: 0, perPage: 1 });
      const { data } = await catalogService.search({ page: 0, perPage: totalCount });

      setCatalogItems([...new Set(data.map((d) => d.name))]);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_CATALOGS'}) })
      });
    }
  };

  const getParameters = async () => {
    try {
      const { totalCount } = await parametersService.search({ page: 0, perPage: 1 });
      const { data } = await parametersService.search({ page: 0, perPage: totalCount });

      let par = [];
      data.map((p) => p.key.toLowerCase().split(':')).map((p) => par.push(...p));

      setParametersItems([...new Set(par)]);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_PARAMETERS'}) })
      });
    }
  };

  const getScheduleTypesOptions = async () => {
    setIsScheduleTypesOptionsLoading(true);
    try {
      const options =
        scheduleTypes.length === 0 ? await libraryEnumsService.getScheduleTypes() : scheduleTypes;

      scheduleTypes.length === 0 && setScheduleTypes(options);
      setScheduleTypesOptions([{ label: lookUp({ key: 'CONSOLE_NONE' }), value: null }, ...toOptions(options)]);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_SCHEDULE_TYPES'}) })
      });
      setIsScheduleTypesOptionsLoading(false);
    }
  };

  useEffect(() => {
    const getHttpMethodsOptions = async () => {
      try {
        const options = await libraryEnumsService.getHttpMethods()
        setHttpMethodsOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_HTTP_METHODS'}) })
        });
      }
    }
    getHttpMethodsOptions()
  }, [])

  useEffect(() => {
    const getActionTypesOptions = async () => {
      try {
        const options = await libraryEnumsService.getActionTypes()
        setActionTypesOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_ACTION_TYPES'}) })
        });
      }
    }
    getActionTypesOptions()
  }, [])

  useEffect(() => {
    const getActionGroupsOptions = async () => {
      try {
        const options = await libraryEnumsService.getActionGroups();
        setActionGroupsOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_ACTION_GROUPS'}) })
        });
      }
    }
    getActionGroupsOptions()
  }, [])


  useEffect(() => {
    parametersItems.length === 0 && getParameters().then(() => false);
    catalogItems.length === 0 && getCatalogs().then(() => false);
    getScheduleTypesOptions().then(() => setIsScheduleTypesOptionsLoading(false));
  }, []);

  useEffect(() => {
    handleChange(items);
  }, [items]);

  const handlePanel = (index) => {
    setIsCollapseOpen(isCollapseOpen === index ? false : index);
  };

  const contentSchedule = (item, index) => {
    if (item.schedule === null) return <span></span>;
    if (item.schedule.value === null) return <span></span>;
    const interval = item.schedule.value || '00:00:00';
    const intervalInMs = (
      parseInt(interval.slice(0, 2)) * 3600000 +
      parseInt(interval.slice(3, 5)) * 60000 +
      parseInt(interval.slice(6)) * 1000
    )
    switch (item.schedule.type) {
      case 'Delayed':
        return (
          <KeyboardTimePicker
            label={lookUp({ key: 'CONSOLE_DELAYED' })}
            ampm={false}
            inputVariant="outlined"
            size="small"
            className={classes.textField}
            views={['hours', 'minutes', 'seconds']}
            placeholder={lookUp({ key: 'CONSOLE_CHOOSE_TIME_HELPERTEXT' })}
            mask="__:__:__"
            format="HH:mm:ss"
            value={moment(intervalInMs).utc(false) || null}
            onChange={(interval) => handleItemsScheduleChange(index, 'value', interval)}
            keyboardIcon={<AccessAlarmIcon />}
          />

        );
      case 'onStart':
        return <span></span>
      case 'None':
        return <span></span>
      case 'Periodic':
        return (
          <KeyboardTimePicker
            label={lookUp({ key: 'CONSOLE_INTERVAL' })}
            ampm={false}
            inputVariant="outlined"
            size="small"
            className={classes.textField}
            views={['hours', 'minutes', 'seconds']}
            placeholder={lookUp({ key: 'CONSOLE_CHOOSE_TIME_HELPERTEXT' })}
            mask="__:__:__"
            format="HH:mm:ss"
            value={item.schedule ? moment(intervalInMs).utc(false) : null}
            onChange={(interval) => handleItemsScheduleChange(index, 'value', interval)}
          />
        )
      case 'ForTracking':
        return (
          <TextField
            label={lookUp({ key: 'CONSOLE_EVENTNAME' })}
            className={classes.textField}
            value={item.schedule ? item.schedule.value : ''}
            onChange={(event) => handleItemsScheduleChange(index, 'value', event.target.value)}
          />
        )
      case 'AtEventOnce':
        return (
          <TextField
            label={lookUp({ key: 'CONSOLE_EVENTNAME' })}
            className={classes.textField}
            value={item.schedule ? item.schedule.value : ''}
            onChange={(event) => handleItemsScheduleChange(index, 'value', event.target.value)}
          />
        )
      case 'ForEventAlways':
        return (
          <TextField
            label={lookUp({ key: 'CONSOLE_EVENTNAME' })}
            className={classes.textField}
            value={item.schedule ? item.schedule.value : ''}
            onChange={(e) =>
              handleItemsScheduleChange(
                index,
                'value',
                e.target.value
              )
            }
          />
        )
      default:
        return '';
    }
  };

  return (
    <>
      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
          {items.map((item, index) => (
            <Grid item xs={12} key={`action-${index}`}>
              <Typography gutterBottom>{lookUp({ key: 'CONSOLE_ACTION' })} {index + 1}</Typography>
              <Grid container alignItems={'center'}>
                <Grid item xs={11}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={9}>
                      <TextField
                        label={lookUp({ key: 'CONSOLE_NAME' })}
                        required
                        className={classes.textField}
                        value={item.name || ''}
                        onChange={(e) => handleItemsChange(index, 'name', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3} className={classes.textCenter}>
                      <Tooltip title={lookUp({ key: 'CONSOLE_ENABLED' })}>
                        <Switch
                          checked={item.enabled}
                          onChange={() => handleItemsChange(index, 'enabled', !item.enabled)}
                          color={'primary'}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      {isActionTypesOptionsLoading ? (
                        <Loader inline />
                      ) : (
                        <TextField
                          select
                          fullWidth
                          id={`action-type-${index}`}
                          required
                          label={lookUp({ key: 'CONSOLE_ACTION_TYPES' })}
                          placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                          value={item.actionType}
                          onChange={(event) =>
                            handleItemsChange(index, 'actionType', event.target.value)
                          }
                        >
                          {actionTypesOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {lookUp({ key: `CONSOLE_${option.label}` })}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      {isActionGroupsOptionsLoading ? (
                        <Loader inline />
                      ) : (
                        <TextField
                          select
                          fullWidth
                          id={`action-group-${index}`}
                          label={lookUp({ key: 'CONSOLE_ACTION_GROUP' })}
                          placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                          value={item.actionGroup}
                          onChange={(event) =>
                            handleItemsChange(index, 'actionGroup', event.target.value)
                          }
                        >
                          {actionGroupsOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {lookUp({ key: `CONSOLE_${option.label}` })}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      {isHttpMethodsOptionsLoading ? (
                        <Loader inline />
                      ) : (
                        <TextField
                          select
                          fullWidth
                          id={`http-methods-${index}`}
                          label={lookUp({ key: 'CONSOLE_HTTP_METHODS' })}
                          placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                          value={item.httpMethod}
                          onChange={(event) =>
                            handleItemsChange(index, 'httpMethod', event.target.value)
                          }
                        >
                          {httpMethodsOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label={lookUp({ key: 'CONSOLE_URL' })}
                        className={classes.textField}
                        disabled={parametersItems.length === 0}
                        value={item.url || ''}
                        onChange={(e) => handleItemsChange(index, 'url', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Grid
                    container
                    direction={'column'}
                    justifyContent={'space-evenly'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <IconButton onClick={() => handleItemsRemove(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => {
                        handlePanel(index);
                      }}
                        aria-expanded={isCollapseOpen === index}
                        aria-label="show more"
                      >
                        {isCollapseOpen === index ? <ArrowUpward /> : <ArrowDownward />}
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle1'} className={classes.marginTop} gutterBottom>
                  {lookUp({ key: 'CONSOLE_SCHEDULE_TITLE' })}
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      select
                      fullWidth
                      id={`schedule-type-${index}`}
                      required
                      label={!!item.schedule && "Schedule Type"}
                      placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                      value={item.schedule === null ? null : item.schedule.type}
                      SelectProps={{displayEmpty: "None"}}
                      onChange={(event) =>
                        handleItemsScheduleChange(index, 'type', event.target.value)
                      }
                    >
                      {scheduleTypesOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {lookUp({ key: `CONSOLE_${option.label}` })}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <>
                    <Grid item xs={6} md={3}>
                      {contentSchedule(item, index)}
                    </Grid>
                  </>
                </Grid>
              </Grid>
              <Collapse in={isCollapseOpen === index} timeout="auto">
                <Grid container alignItems={'center'} spacing={3} className={classes.marginTop}>

                  <Grid item xs={12}>
                    <PropsForm
                      title={'Parameters'}
                      properties={item.parameters}
                      handleChange={(data) => handleItemsChange(index, 'parameters', data)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <RulesForm
                      title={'Displaying Rules'}
                      rules={item.displayRules}
                      handleChange={(data) => handleItemsChange(index, 'displayRules', data)}
                    />
                  </Grid>
                </Grid>
              </Collapse>
              <Divider className={classes.actionDivider} />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.addButton}>
        <ButtonGroup  color="primary" ref={anchorRef} aria-label="split button">
          <Button color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
            {lookUp({ key: `CONSOLE_${newOptions[selectedIndex]}` })}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement = 'right', position: 'absolute'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" >
                    {newOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {lookUp({ key: `CONSOLE_${option}` })}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

      </Grid>
    </>
  );
};

ActionsForm.propTypes = {
  enqueueSnackbar: func.isRequired,
  handleChange: func.isRequired,
  disabled: bool,
  actions: arrayOf(
    shape({
      name: string,
      enabled: bool,
      actionType: string,
      displayRules: arrayOf(
        shape({
          propertyName: string,
          formatting: string,
          condition: string,
          propertyValue: string,
          valueIsReference: bool,
          valueCollectionSeparator: string,
          propertyCollectionSeparator: string,
          containsCondition: string
        })
      ),
      actionGroup: string,
      httpMethod: string,
      url: string,
      parameters: arrayOf(
        shape({
          collection: string,
          name: string,
          value: string
        })
      ),
      schedule: object
    })
  ).isRequired,
  scheduleTypes: array.isRequired,
  httpMethods: array.isRequired,
  actionTypes: array.isRequired,
  actionGroups: array.isRequired,
  catalogItems: array.isRequired,
  parametersItems: array.isRequired,
  setScheduleTypes: func.isRequired,
  setHttpMethods: func.isRequired,
  setActionTypes: func.isRequired,
  setActionGroups: func.isRequired,
  setCatalogItems: func.isRequired,
  setParametersItems: func.isRequired
};

const mapStateToProps = ({
  libraryEnums: {
    scheduleTypes,
    httpMethods,
    actionTypes,
    actionGroups,
    catalogItems,
    parametersItems
  }
}) => ({
  scheduleTypes,
  httpMethods,
  actionTypes,
  actionGroups,
  catalogItems,
  parametersItems
});

const mapDispatchToProps = (dispatch) => ({
  setScheduleTypes: (data) => dispatch(setScheduleTypes(data)),
  setHttpMethods: (data) => dispatch(setHttpMethods(data)),
  setActionTypes: (data) => dispatch(setActionTypes(data)),
  setActionGroups: (data) => dispatch(setActionGroups(data)),
  setCatalogItems: (data) => dispatch(setCatalogItems(data)),
  setParametersItems: (data) => dispatch(setParametersItems(data))
});

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(ActionsForm);
