import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import transcodingProfilesService from 'services/transcodingProfilesService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(1, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
}));

function Transcode(props) {
  const { url, id, open, setOpen, enqueueSnackbar } = props;
  
  const [availableTranscodeProfiles, setAvailableTranscodeProfiles] = useState({});
  const [selectedTranscodes, setSelectedTranscodes] = useState({});

  const classes = useStyles();

  const startTranscode = () => {
    if (Object.keys(selectedTranscodes).length === 0) {
      setOpen('transcode', false);
      return;
    }
    const bodies = Object.keys(selectedTranscodes).map((key) => {
      return {
        streamKind: key,
        profileUrl: selectedTranscodes[key],
        referenceObjectId: id,
        inputFileLocation: url,
        name: `TranscodeJob_${Date.now()}`,
      };
    });
    bodies.forEach((body) =>
      jobManagerService
        .transcode(body)
        .then((jobResponse) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} ${jobResponse.streamKind} -> ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`,
          });
          setOpen('transcode', false);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_FAIL_TEMPLATE', type: lookUp({ key: `CONSOLE_${'Transcode'}` }) }),
          });
        })
    );
    setOpen('transcode', false);
  };

  const handleSelect = (value) => {
    const newTranscodes = { ...selectedTranscodes, [value.streamKind]: value.profileUrl };
    if (!value.profileUrl) delete newTranscodes[value.streamKind];
    setSelectedTranscodes(newTranscodes);
  };

  useEffect(() => {
    if (Object.keys(availableTranscodeProfiles).length > 0) return;
    transcodingProfilesService.getAll().then((profiles) => {
      setAvailableTranscodeProfiles(
        profiles.reduce((acc, profile) => {
          acc[profile.streamKind] = {
            ...acc[profile.streamKind],
            [profile.name]: profile.profileUrl,
          };
          return acc;
        }, {})
      );
    });
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_TRANSCODE_TITLE' })}
        </Typography>
        <IconButton aria-label="close" onClick={() => setOpen('transcode', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>{lookUp({ key: 'CONSOLE_TRANSCODE_HELPERTEXT' })}</span>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2} justifyContent="flex-start" className={classes.transModalGrid}>
          {Object.keys(availableTranscodeProfiles).length > 0 &&
            Object.keys(availableTranscodeProfiles).map((type) => (
              <Grid item xs={12} key={type}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id={`${type}_encoding`} margin="dense">
                    {type} profile
                  </InputLabel>
                  <Select
                    labelId={`${type}_encoding`}
                    label={`${type} Profile`}
                    margin="dense"
                    value={selectedTranscodes[type] || ''}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    onChange={(event) =>
                      handleSelect({ streamKind: type, profileUrl: event.target.value })
                    }
                  >
                    <MenuItem value={''}>None</MenuItem>
                    {Object.entries(availableTranscodeProfiles[type]).map((profile) => (
                      <MenuItem value={profile[1]} key={profile[1]}>
                        {profile[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <Button  onClick={startTranscode} color="primary">
            {lookUp({ key: 'CONSOLE_Start_Processing_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(Transcode);
