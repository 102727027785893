import { useEffect, useState, useRef } from 'react';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  ListSubheader,
  TextField,
} from '@material-ui/core';
import transcodingProfilesService from 'services/transcodingProfilesService';
import markerService from 'services/markerService';
import AlertService from 'services/alertService';
import './index.scss';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
  },
  input: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
  },
}));

const TranscodeWithCut = ({
  onAttribChange,
  model,
  drawMarkers,
  selectedBrPtType,
  keyword,
  enqueueSnackbar,
}) => {
  // copied from Transcode.jsx:
  const inputLabel = useRef();
  const [availableTranscodeProfiles, setAvailableTranscodeProfiles] = useState({});
  const [labelWidth, setLabelWidth] = useState(0);
  const [markerTypes, setMarkerTypes] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);

  const classes = useStyles();

  const typesToCut = [
    'Cut',
    'Chapter',
    'Caption',
    'Celebrity',
    'Face',
    'Label',
    'ContentModeration',
    'NFT',
  ];

  const getMarkers = () => {
    markerService
      .getMarkerTypes()
      .then((markerTypes) => {
        setMarkerTypes(markerTypes.filter((e) => typesToCut.includes(e)));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_MARKER_TYPE' }),
          }),
        });
      });
  };

  const getTags = () => {
    markerService
      .getTags()
      .then((tagTypes) => {
        setTagTypes(tagTypes);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TAG_TYPE' }),
          }),
        });
      });
  };

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
    if (!!drawMarkers) {
      getMarkers();
      getTags();
    }
  }, []);

  // copied from Transcode.jsx - it will be changed if the Backend sets it:
  useEffect(() => {
    if (Object.keys(availableTranscodeProfiles).length > 0) return;
    transcodingProfilesService.getAll().then((profiles) => {
      setAvailableTranscodeProfiles(
        profiles.reduce((acc, profile) => {
          acc[profile.streamKind] = {
            ...acc[profile.streamKind],
            [profile.name]: profile.profileUrl,
          };
          return acc;
        }, {})
      );
    });
  }, []);

  const handleSelect = (value) => {
    if (!value) return;
    const parsedValue = JSON.parse(value);
    onAttribChange(parsedValue.streamKind, 'text', model.id, 'streamKind');
    onAttribChange(parsedValue.profileUrl, 'text', model.id, 'profileUrl');
  };

  const handleMarkerType = (value) => {
    onAttribChange(value, 'text', model.id, 'markerType');
    !tagTypes.find((tag) => tag.slice(0, -3) === value) &&
      onAttribChange('', 'text', model.id, 'markerKeyword');
  };

  const handleMarkerKeyword = (value) => {
    onAttribChange(value, 'text', model.id, 'markerKeyword');
  };

  const renderSelectGroup = (type) => {
    const items = Object.entries(availableTranscodeProfiles[type]).map((profile) => {
      return (
        <MenuItem
          value={JSON.stringify({ streamKind: type, profileUrl: profile[1] })}
          key={profile[1]}
        >
          {profile[0]}
        </MenuItem>
      );
    });
    // Solution for display data in Grouped Dropdown Menu: to put ListSubheader in an array in a this renderSelectGroup function
    return [<ListSubheader>{type} profile</ListSubheader>, items];
  };

  return (
    <>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth
        size="small"
        margin="dense"
      >
        {/* Bugfix for Material-UI FormControl InputLabel display problem: */}
        <InputLabel htmlFor="transcode-grouped-select" ref={inputLabel}>
          {lookUp({ key: 'CONSOLE_TRANSCODING_PROFILES' })}
        </InputLabel>
        <Select
          value={
            model.streamKind && model.profileUrl
              ? JSON.stringify({ streamKind: model.streamKind, profileUrl: model.profileUrl })
              : ''
          }
          id="transcode-grouped-select"
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          onChange={(event) => handleSelect(event.target.value)}
          labelWidth={labelWidth}
        >
          {Object.keys(availableTranscodeProfiles).length > 0 &&
            Object.keys(availableTranscodeProfiles).map((p) => renderSelectGroup(p))}
        </Select>
      </FormControl>
      {drawMarkers ? (
        <>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            size="small"
            margin="dense"
          >
            <InputLabel htmlFor="transcode-grouped-select">{lookUp({ key: 'CONSOLE_MARKER_TYPE' })}</InputLabel>
            <Select
              labelId="brPtType_select"
              margin="dense"
              variant="outlined"
              label={lookUp({ key: 'CONSOLE_MARKER_TYPE' })}
              value={model.markerType ?? selectedBrPtType ?? ''}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              onChange={(event) => handleMarkerType(event.target.value)}
            >
              {markerTypes?.map((marker, index) => {
                return (
                  <MenuItem value={marker} key={`${marker}_${index}`}>
                    {lookUp({ key: `CONSOLE_MARKERTYPE_${marker}` })}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {model.markerType && tagTypes.find((tag) => tag.slice(0, -3) === model.markerType) && (
            <TextField
              className={classes.input}
              id="outlined-basic"
              margin="dense"
              label={lookUp({ key: 'CONSOLE_MARKER_KEYWORD' })}
              value={keyword || model.markerKeyword || ''}
              onChange={(event) => handleMarkerKeyword(event.target.value)}
            />
          )}
        </>
      ) : null}
    </>
  );
};

export default TranscodeWithCut;
