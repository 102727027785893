import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Tooltip,
  MenuItem,
  Chip,
  TextField,
  IconButton,
  Link,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import PublicIcon from '@material-ui/icons/Public';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { CreatedOn } from 'components';
import { search, getRegions } from 'services/countryService';
import AlertService from 'services/alertService';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';
import AddButton from 'components/AddButton';
import Dialog from 'components/Dialog';
import DataGridOverlayNoResults from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minHeight: '3.5em',
  },
  gridInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 0,
    }
  },
  textField: {
    width: '100%'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  extraPropButton: {
    marginBottom: theme.spacing(4),
    paddingLeft: '2em',
  },
  addButton: {
    position: 'relative',
    zIndex: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  linkItem: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
  countryCode: {
    ...theme.typography.button,
    fontWeight: 'bold',
    margin: 0,
  },
  headerHelperText: {
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(2),
    color: theme.palette.text.disabled,
  },
}));

const ExhibitionWindow = (props) => {
  
  const {
    handleChange,
    model,
    enqueueSnackbar,
  } = props;

  const classes = useStyles();

  const [countries, setCountries] = useState([{ label: lookUp({ key: 'CONSOLE_GLOBAL' }), value: '--' }]);
  const [ratings, setRatings] = useState({ '--': [] });
  const [advisories, setAdvisories] = useState({ '--': [] });
  const [countrySelect, setCountrySelect] = useState([]);
  const [regions, setRegions] = useState({});
  const [isAddOpen, setIsAddOpen] = useState(false);

  const handleClose = () => setIsAddOpen(false);

  const addExhibitions = (presets) => {
    setIsAddOpen(false);
    const codes = countrySelect.map(e => e.value);
    const copyExhibitions = { ...model.exhibitionWindow };
    codes.forEach(code => copyExhibitions[code] =
    {
      ...presets,
      ageLimit: code === '--' ? 99 :  // "Global" code: '--' - means no applicable age limit, set to 99.
      Math.min(...Object.values(ratings)
        .find(e => e.code === code).categories.map(c => c.minAge)
        .filter(age => age > presets.ageLimit)
        , 99)
    });
    setCountrySelect([]);
    handleChange('exhibitionWindow', copyExhibitions);
    return;
  };

  const deleteItem = (code) => {
    const copyExhibitions = { ...model.exhibitionWindow };
    delete copyExhibitions[code];
    handleChange('exhibitionWindow', copyExhibitions);
  };

  const changeRating = (code, value) => {
    const copyExhibitions = { ...model.exhibitionWindow };
    copyExhibitions[code].ageLimit = value;
    handleChange('exhibitionWindow', copyExhibitions);
  };

  const changeAdvisory = (code, value) => {
    const copyExhibitions = { ...model.exhibitionWindow };
    copyExhibitions[code].advisoryCodes = value;
    handleChange('exhibitionWindow', copyExhibitions);
  };

  const columns = [
    {
      field: 'country', headerName: '', width: 40,
      renderCell: item =>
        <Tooltip
          title={
            item.value === '' ? '' :
              countries.find((e) => e.value === item.value)
                ? 'Edit ' + countries.find((e) => e.value === item.value).label
                : 'Unset'
          }
        >
          {item.value === '' ? (
            <PublicIcon color="primary" fontSize="small" />
          ) : (
            <Link
              className={classes.countryCode}
              href={`/configuration/countries/${item.row.country}/edit#basic`}
              target={'_blank'}
              color="primary"
              underline="none"
            >
              {item.value}
            </Link>
          )}
        </Tooltip>
    },
    {
      field: 'availableFrom', headerName: lookUp({ key: 'CONSOLE_Start' }), width: 165,
      renderCell: item => <CreatedOn date={item.value} editor={editDate(item.row.country, 'availableFrom')} />,
    },
    {
      field: 'availableUntil', headerName: lookUp({ key: 'CONSOLE_end' }), width: 165,
      renderCell: item => <CreatedOn date={item.value} editor={editDate(item.row.country, 'availableUntil')} />,
    },
    {
      field: 'ageLimit', headerName: lookUp({ key: 'CONSOLE_Rating' }), flex: 2, sortable: false,
      renderCell: item => (
        item.row.country === '--'
          ? <>N/A</>
          : model.exhibitionWindow[item.row.country]
          && (model.exhibitionWindow[item.row.country].ageLimit || model.exhibitionWindow[item.row.country].ageLimit === 0)
          && <RatingsDropdown
            ratings={ratings}
            changeRating={changeRating}
            code={item.row.country}
            rating={
              model.exhibitionWindow[item.row.country]
                ? model.exhibitionWindow[item.row.country].ageLimit
                : 0}
          />)
    },
    {
      field: 'advisory', headerName: lookUp({ key: 'CONSOLE_ADVISORIES' }), flex: 3, sortable: false,
      renderCell: item => (
        item.row.country === '--'
          ? <>N/A</>
          : advisories[item.row.country] && advisories[item.row.country].length > 0
            ? <AdvisoriesField
              advisories={advisories}
              changeAdvisory={changeAdvisory}
              code={item.row.country}
              advisory={model.exhibitionWindow[item.row.country].advisoryCodes || []}
            />
            : <Link href={`/configuration/countries/${item.row.country}/edit#advisories`} target={"_blank"} style={{ textDecoration: 'none' }}>
              <Button
                style={{ marginLeft: '-.5em' }} size="small">Set up options</Button>
            </Link>),
    },
    {
      field: 'del', headerName: ' ', sortable: false, width: 40,
      renderCell: (item) => (
        <DeleteButton
          onDelete={() => deleteItem(item.row.country)}
          dialogContent={item.row.country ? (
            <Typography>
              {lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: item.row.country })}
            </Typography>
          ) : null}
          variant="icon"
        />
      )
    },
  ];

  const editDate = (code, key) => {
    const setDate = (value) => {
      const index = model.exhibitionWindow.findIndex(e => e.country === code);
      const copyExhibitions = [...model.exhibitionWindow];
      copyExhibitions[index][key] = value;
      handleChange('exhibitionWindow', copyExhibitions);
    };
    return {
      onChange: event => setDate(event.target.value),
    }
  }

  useEffect(() => {
    const getCountry = async () => {
      try {
        const countryRules = (await search({ e: true })).data;
        const rulesMap = countryRules.length > 0
          ? countryRules.map(e => { return { label: e.name, value: e.code } })
            .sort((a, b) => a.label.localeCompare(b.label))
          : [];
        setCountries([{ label: lookUp({ key: 'CONSOLE_GLOBAL' }), value: "--" }, ...rulesMap]);
        if (!countryRules.length) return;
        setRatings({
          ...ratings, ...countryRules
        });
        setAdvisories(countryRules
          .reduce((advisoriesHash, e) => {
            advisoriesHash[e.code] = e.advisories;
            return advisoriesHash;
          }, {})
        );
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: `CONSOLE_${`country`}` }) })
        });
      }
    }
    getCountry();
  }, []);

  useEffect(() => {
    const loadRegions = async () => {
      try {
        const data = await getRegions(true);  // Search for published countries only
        const regionsData = Object.keys(data)
          .sort()
          .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {});
        setRegions(regionsData);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_REGIONS'}) })
        });
      }
    }
    loadRegions();
  }, [])

  return (
    <Grid container>
      <Grid item container xs={12} justifyContent="flex-end" alignContent="center" alignItems="center">
        <Box flex={1} />
        <AddButton
          tooltipText={lookUp({ key: 'CONSOLE_ADD_LANGUAGE_HELPERTEXT' })}
          onAdd={() => setIsAddOpen(true)}
        />
      </Grid>
      {model.exhibitionWindow && countries && (
        // FIXME move DataGrid in place wrapped by Dialog
        <ListCountries columns={columns} exhibitionWindow={model.exhibitionWindow} />
      )}
      <Grid container sm={12} justifyContent="flex-end" alignContent="center" alignItems="center">
        <AddButton
          tooltipText={lookUp({ key: 'CONSOLE_ADD_LANGUAGE_HELPERTEXT' })}
          onAdd={() => setIsAddOpen(true)}
        />
      </Grid>
      <Dialog
        open={isAddOpen}
        onClose={handleClose}
        title={lookUp({ key: 'CONSOLE_ADD_EXHIBITION_WINDOW_TITLE' })}
        maxWidth="md"
        fullWidth
      >
        <AddCountry
          model={model}
          regions={regions}
          setRegions={setRegions}
          countrySelect={countrySelect}
          setCountrySelect={setCountrySelect}
          countries={countries}
          addExhibitions={addExhibitions}
        />
      </Dialog>
    </Grid>
  )
}

export default withSnackbar(ExhibitionWindow);

// FIXME move DataGrid in place wrapped by Dialog
const ListCountries = ({ columns, exhibitionWindow }) => {
  
  const rows = Object.keys(exhibitionWindow).map(e => ({ ...exhibitionWindow[e], country: e, id: e, }))
  return (
    <Grid item lg={12} xs={12}>
      <DataGrid
        columns={columns}
        rows={rows || []}
        autoHeight
        pagination
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        components={{
          LoadingOverlay: DataGridOverlayLoaderLinear,
          NoRowsOverlay: DataGridOverlayNoResults,
          NoResultsOverlay: DataGridOverlayNoResults,
        }}
        componentsProps={{
          noRowsOverlay: {
            title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
          },
          noResultsOverlay: {
            title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
          },
          Checkbox: { disableRipple: true },
          pagination: { 
            classes: null,
            labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
            labelDisplayedRows: ({from, to, count}) => `${from}-${to} / ${count}`,
            SelectProps: {
              MenuProps: { disableScrollLock: true }
            }
          },
        }}
      />
    </Grid>
  )
}


const RatingsDropdown = ({ ratings, changeRating, code, rating }) => {
  
  const classes = useStyles();
  const country = Object.values(ratings).find(e => e.code === code);
  if (!country || !country.categories || country.categories.length === 0) {       // Will never occur -- in theory (countries can't be created or saved without at least one rating category).
    return (
      <Link href={`/configuration/countries/${code}/edit#categories`} target={"_blank"} style={{ textDecoration: 'none' }}>
        <Button size="small">Set up options</Button>
      </Link>
    )
  };

  const { categories } = country;

  return (
    <TextField
      className={classes.gridInput}
      select
      fullWidth
      value={rating}
      onChange={(event) => changeRating(code, event.target.value)}
    >
      {categories.map(e =>
        <MenuItem key={`${code}${e.minAge}`} value={e.minAge}><Typography variant="button">{e.name}</Typography></MenuItem>
      )}
    </TextField>
  )
};


const AdvisoriesField = ({ advisories, changeAdvisory, code, advisory, }) => {
  
  const classes = useStyles();
  return (
    <Autocomplete
      multiple
      className={classes.gridInput}
      fullWidth
      disableCloseOnSelect
      style={{ paddingTop: 11 }}
      size="small"
      onChange={(event, value) => changeAdvisory(code, value)}
      options={advisories[code].map(e => e.code)}
      getOptionLabel={option => advisories[code].find(e => e.code === option).name}
      value={advisory}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip key={index} title={advisories[code].find(e => e.code === option).name}>
            <Chip size="small" label={option} {...getTagProps({ index })} />
          </Tooltip>
        ))}
      renderInput={params => (
        <TextField
          {...params}
          size="medium"
          fullWidth
        />)}
    />
  )
}

// FIXME fix dataGrid layout, replace DataGrid, with List, all features on the DataGrid are disabled...
const AddCountry = ({ regions, countrySelect, setCountrySelect, countries, addExhibitions, }) => {
  const classes = useStyles();

  const now = new Date();
  const [presets, setPresets] = useState({ id: 0, availableFrom: now.toISOString().split('.')[0], availableUntil: new Date(now.setFullYear(now.getFullYear() + 2)).toISOString().split('.')[0], ageLimit: 0, });

  const editPreset = (key, value) => {
    setPresets(p => { return { ...p, [key]: value, } })
  };

  const editDates = (key) => {
    const setDate = (value) => {
      editPreset(key, value);
    };
    return {
      onChange: event => setDate(event.target.value),
    }
  };

  const presetColumns = [
    {
      field: 'drop', headerName: ' ', width: 40,
    },
    {
      field: 'availableFrom', headerName: lookUp({ key: 'CONSOLE_START' }), width: 165,
      renderCell: item => <CreatedOn date={item.value} editor={editDates('availableFrom')} />,
    },
    {
      field: 'availableUntil', headerName: lookUp({ key: 'CONSOLE_END' }), width: 165,
      renderCell: item => <CreatedOn date={item.value} editor={editDates('availableUntil')} />,
    },
    {
      field: 'ageLimit',
      description: lookUp({ key: 'CONSOLE_ADD_EXHIBITION_WINDOW_MINAGE_HELPERTEXT' }),
      headerName: (
        <>
          {lookUp({ key: 'CONSOLE_MINIMUM_AGE' })}
          <Typography variant="caption" className={classes.headerHelperText}>
            {lookUp({ key: 'CONSOLE_ADD_EXHIBITION_WINDOW_MINAGE_HELPERTEXT' })}
          </Typography>
        </>
      ),
      flex: 1,
      renderCell: item => (
        <>
          <TextField
            size="medium"
            type="number"
            inputProps={{ min: 0, max: 99 }}
            value={item.value}
            onChange={(event) => editPreset('ageLimit', event.target.value)}
          />
        </>
      ),
    },
    {
      field: 'button',
      headerName: ' ',
      renderCell: () => (
        <ThemedButton
          onClick={() => addExhibitions(presets)}
          disabled={countrySelect?.length < 1}
          fullWidth
          autoFocus
        >
          {lookUp({
            key: 'CONSOLE_ADD_BUTTON_TEMPLATE',
            number: countrySelect?.length ?? 0,
            type: lookUp({ key: (countrySelect?.length > 1 ? 'CONSOLE_COUNTRIES' : 'CONSOLE_COUNTRY') })
          })}
        </ThemedButton>
      )
    },
  ];

  const handleAddRegions = (codes) => {
    setCountrySelect(selected => [...selected.filter(country => !codes.includes(country.value)), ...countries.filter(country => codes.includes(country.value))]);
  };

  const codesToCountryNames = (arr) => {
    if (!Array.isArray(arr) || arr.length < 1) return '';
    return arr.reduce((countryNames, code) => {
      countryNames.push(countries.find(c => c.value === code) && countries.find(c => c.value === code).label);
      return countryNames;
    }, []).join(', ')
  };

  return (
    <Box flex={1}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          {countries.length > 0 &&
            <Autocomplete
              fullWidth
              multiple
              disableCloseOnSelect
              blurOnSelect={false}
              id="add_countries"
              value={countrySelect}
              options={countries}
              getOptionLabel={option => option.label || ''}
              onChange={(event, values) => {
                setCountrySelect(values);
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    key={index}
                    label={option.label || ''}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} size="small" label={lookUp({ key: 'CONSOLE_ADD_COUNTRIES' })} />
              )}
            />
          }
        </Grid>
        {regions && (
          <Grid item md={6} xs={12}>
            <TextField
              select
              multiple
              fullWidth
              size="small"
              id="add_regions"
              label={lookUp({ key: 'CONSOLE_ADD_REGIONS_HELPERTEXT' })}
            >{Object.entries(regions).map((key) =>
              <Tooltip title={codesToCountryNames(key[1])} key={key[0]}>
                <MenuItem key={key[0]} onClick={() => handleAddRegions(key[1])}>{key[0]}<Typography variant='caption'>({key[1].length})</Typography></MenuItem>
              </Tooltip>
            )}
            </TextField>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataGrid
            columns={presetColumns.map?.((column) => ({ ...column, sortable: false }))}
            rows={[presets]}
            components={{
              LoadingOverlay: DataGridOverlayLoaderLinear,
              NoRowsOverlay: DataGridOverlayNoResults,
              NoResultsOverlay: DataGridOverlayNoResults,
            }}
            componentsProps={{
              noRowsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
              noResultsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
            }}
            autoHeight
            hideFooter
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </Box>
  )
}

