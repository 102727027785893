import supportedJobs from 'services/hardcodePatch/jobManager-supportedJobs.json';

export const getFromSupportedJobs = (property, selectedNode) => {
  return Object.values(supportedJobs).reduce((selectedJob, job) =>{
    const obj = Object.entries(job).find(subjob => {
      return subjob[0] === selectedNode.label
    })
    if (obj) {
      return obj[1][property]
    }
    return selectedJob
    })
};