import React from 'react';
import {
  Avatar
} from '@material-ui/core'

const CustomerCard = ({ customer }) => {
  return (
    <>
      <Avatar
        alt={customer.displayName || customer.username}
        src={customer.avatarImageUrl || customer.properties?.['Custom:User:Avatar']}
        style={{marginRight: '.5em'}}
      />
      {customer.displayName || customer.username}
    </>
  );
};

export default CustomerCard;
