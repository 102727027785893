import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PublishIcon from '@material-ui/icons/Publish';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { Dropzone } from './index';
import withFileUpload from 'helpers/withFileS3Upload';
import { getTypes } from 'helpers/content';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  marginX: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  contentSize: {
    width: '100%',
    maxHeight: '40vh',
    minWidth: '20vw'
  },
}));

const FileUpload = ({
  enqueueSnackbar,
  upload,
  fileUrl,
  handleChange = () => {},
  contentType = 'image',
  assetType = 'FileAsset',
  isPublic = true,
}) => {
  const [isLinkBroken, setIsLinkBroken] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isIngestLoading, setIsIngestLoading] = useState(false);
  const [uploadProgressValue, setUploadProgressValue] = useState(0);
  const classes = useStyles();

  const acceptedFormats = () => {
    switch (contentType) {
      case 'image':
        return 'image/*';
      case 'audio':
        return 'audio/mp3, audio/ogg, audio/wav, audio/x-m4a';
      case 'video':
        return 'video/mp4, video/webm, video/ogg, video/quicktime';
      case 'podcast':
        return 'audio/mp3, audio/ogg, audio/wav, audio/x-m4a, video/mp4, video/m4v, video/webm, video/ogg, video/quicktime';
      default:
        return 'image/*';
    }
  };

  const { contentType: fileContentType, fileType } = getTypes(fileUrl);

  const content = () => {
    switch (fileContentType) {
      case 'image':
        return <img src={fileUrl} alt={assetType} onError={() => setIsLinkBroken(true)} />;
      case 'audio':
        return (
          <audio controls className={classes.contentSize}>
            <source
              src={fileUrl}
              type={`audio/${fileType || 'mp3'}`}
              onError={() => setIsLinkBroken(true)}
            />
          </audio>
        );
      case 'video':
        return (
          <video controls className={classes.contentSize}>
            <source
              src={fileUrl}
              type={`video/${fileType || 'mp4'}`}
              onError={() => setIsLinkBroken(true)}
            />
          </video>
        );
      default:
        return <img src={fileUrl} alt={assetType} onError={() => setIsLinkBroken(true)} />;
    }
  };

  const uploadFile = async ([file], [rejectedFile]) => {
    if (rejectedFile) {
      const fileType = rejectedFile.type.split('/')[0];
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: `File "${rejectedFile.name}" has invalid type "${fileType}".`
      });
    } else {
      setIsUploading(true);
      isLinkBroken && setIsLinkBroken(false);

      const onUploadProgress = (event) =>
        setUploadProgressValue(Math.round((event.loaded * 100) / event.total));

      try {
        const { publicUrl } = await upload({
          file,
          assetType,
          isPublic,
          onUploadProgress,
          onFileIngestStart: () => setIsIngestLoading(true),
          onFileIngestEnd: () => setIsIngestLoading(false)
        });

        handleChange(publicUrl);

        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_FILE_UPLOADED' })
        });
      } catch (error) {
        AlertService.displayError({ msgBar: enqueueSnackbar, error, context: `Can't upload file` });
      } finally {
        setIsUploading(false);
        setUploadProgressValue(0);
      }
    }
  };

  return isUploading ? (
    isIngestLoading ? (
      <LinearProgress className={classes.marginX} />
    ) : (
      <LinearProgress
        variant={'determinate'}
        value={uploadProgressValue}
        className={classes.marginX}
      />
    )
  ) : (
    <Dropzone accept={acceptedFormats()} multiple={false} onDrop={uploadFile}>
      {fileUrl ? (
        isLinkBroken ? (
          <>
            <BrokenImageIcon fontSize="large" color="error" />
            <Typography variant="body2" color="error">
              File link broken. Try later or upload new {contentType} file.
            </Typography>
          </>
        ) : (
          content()
        )
      ) : (
        <>
          <PublishIcon fontSize="large" color="disabled" />
          <Typography variant="body2">
            {lookUp({ key: 'CONSOLE_INGEST_DROP_TEXT_TEMPLATE', fileType: lookUp({ key: `CONSOLE${contentType}` }) })}
          </Typography>
        </>
      )}
    </Dropzone>
  );
};

FileUpload.propTypes = {
  contentType: PropTypes.oneOf([
    'image',
    'audio',
    'video',
    'podcast',
  ]),
  fileUrl: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  assetType: PropTypes.oneOf([
    'FileAsset',
    'Content',
    'Partner',
    'Artist',
    'Label',
    'Festival',
    'Catalog',
  ]),
  upload: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  isPublic: PropTypes.bool,
};

export default compose(
  withFileUpload,
  withSnackbar
)(FileUpload);
