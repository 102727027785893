import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeTokenFromCookies } from 'helpers/common'
import { resetUser, setLastRoute } from 'store/actions/authAction';
import { withSnackbar } from 'notistack';


function ReturnLMS ({ enqueueSnackbar }) {
  const dispatch = useDispatch();
  const lmsReturnURL = useSelector(state => state.navigation.lmsReturnURL);

  useEffect(() => {
    if (lmsReturnURL) {
      dispatch(setLastRoute('/'));
      dispatch(resetUser());
      removeTokenFromCookies();
      removeTokenFromCookies('refreshToken');
      setTimeout(() => window.location.replace(lmsReturnURL), 100);
    }
  }, [lmsReturnURL]);

  return <></>;
};

export default withSnackbar(ReturnLMS);
