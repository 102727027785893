const SET_STORE_TYPES = 'SET_STORE_TYPES';

const setStoreTypes = (storeTypes) => ({
  type: SET_STORE_TYPES,
  storeTypes
});

export { SET_STORE_TYPES, setStoreTypes };

export default {
  SET_STORE_TYPES,
  setStoreTypes
};
