import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { func, string, } from 'prop-types';
import { withSnackbar } from 'notistack';
import { 
  makeStyles, 
  TextField, 
  IconButton, 
  LinearProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { 
  DataGrid, 
  GridOverlay,
  getGridNumericColumnOperators,
} from '@material-ui/data-grid';
import {
  Rating,
} from '@material-ui/lab';
import { 
  CreatedOn, 
  ConfirmDialog, 
  CustomerCard 
} from 'components';
import collectionsService from 'services/collectionsService';
import customersService from 'services/customersService';
import AlertService from 'services/alertService';
import DeleteButton from 'components/DeleteButton';
import SearchBar from 'components/SearchBar';
import DataGridOverlayNoResults from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    margin: theme.spacing(1, 0.5, 1.5),
  },
  ratingsFilterInput: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 48,
    paddingLeft: 20,
  },
}));

function RatingInputValue(props) {
  const classes = useStyles();
  const { item, applyValue } = props;

  const handleFilterChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };
  return (
    <div className={classes.ratfingsFilterInput}>
      <Rating
        name="custom-rating-filter-operator"
        placeholder={lookUp({ key: 'CONSOLE_FILTER_VALUE_HELPERTEXT' })}
        value={Number(item.value)}
        onChange={handleFilterChange}
        precision={0.5}
      />
    </div>
  );
}

const CommentsTab = (props) => {
  
  const { id, enqueueSnackbar } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(1);
  const [filterModel, setFilterModel] = useState({
    items: [],
  });
  const [trigger, setTrigger] = useState(true);

  const getData = async () => await collectionsService.getRatings(id, page, pageSize);

  const addCustomerDetails = async (e) => {
    let rating;
    try {
      rating = {
        ...e, 
        id: e.customerId + e.createdDate,
        customer: JSON.stringify(await customersService.getCustomer(e.customerId))
      };
    } catch (error) {
      console.error(error);
    }
    return rating || null;
  }

  useEffect(() => {
    setIsLoading(true);
    setData([]);
    setFiltered([]);
    getData()
      .then((data) => {
        let ratings = [];
        data.pageContent.forEach((e) => {
          let rating = addCustomerDetails(e);
          rating && ratings.push(rating);
        });
        return Promise.all(ratings);
      })
      .then((preparedData) => {
        setData(preparedData.filter(e => !!e));
        setFiltered(preparedData.filter(e => !!e));
      })
      .catch(error => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(()=>setIsLoading(false));
  }, [trigger, page, pageSize]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    if (searchValue === '') { setFiltered(data); return };
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        return row[field] && searchRegex.test(row[field].toString());
      });
    });
    setFiltered(filteredRows);
  };

  const ratingFilterOperators = getGridNumericColumnOperators().map(
    operator => ({
      ...operator,
      InputComponent: RatingInputValue,
    })
  )

  const handleDelete = (customerId, comment) => {
    collectionsService.deleteRating(customerId, id)
    .then(response => {
      setTrigger(o => !o);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: comment }),
      });
    })
    .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', title: comment }),
      });
    })
  }

  const columns = [
    {
      field: 'customer',
      headerName: lookUp({ key: 'CONSOLE_CUSTOMER' }),
      flex: 2,
      renderCell: item => <CustomerCard customer={JSON.parse(item.value)}/>,
    },
    {
      field: 'rating',
      headerName: lookUp({ key: 'CONSOLE_RATING' }),
      type: 'number',
      width: 120,
      // filterOperators: ratingFilterOperators, ------ TO DEBUG (doesn't display correctly)
      renderCell: item => <Rating value={item.row.data.rating / 2} size="small" precision={.5} readOnly/>
    },
    {
      field: 'comment',
      headerName: lookUp({ key: 'CONSOLE_COMMENT' }),
      flex: 5,
      renderCell: item => item.row.data.comment,
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'right',
      width: 150,
      renderCell: (item) => <CreatedOn date={item.value}/>
    },
    {
      field: 'del',
      headerName: ' ',
      filterable: false,
      sortable: false,
      align: 'center',
      width: 40,
      renderCell: (item) => (
        <DeleteButton
          onDelete={() => handleDelete(item.row.customerId, item.row.providerId, item.row?.data?.comment || 'Item')}
          dialogContent={item.row.data?.comment ? (
            <Typography>
              {lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: item.row.data?.comment })}
            </Typography>
          ) : null}
          variant="icon"
        />
      ),
    }
  ];

  return (
    <DataGrid
      loading={isLoading}
      rows={filtered}
      columns={columns}
      filterModel={filterModel}
      onFilterModelChange={setFilterModel}
      components={{
        Toolbar: QuickSearchToolbar,
        LoadingOverlay: DataGridOverlayLoaderLinear,
        NoRowsOverlay: DataGridOverlayNoResults,
        NoResultsOverlay: DataGridOverlayNoResults,
      }}
      pagination
      pageSize={pageSize}
      onPageChange={(params) => setPage(params)}
      onPageSizeChange={(params) => setPageSize(params)}
      componentsProps={{
        toolbar: {
          requestSearch,
        },
        noRowsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
        noResultsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
      }}
      autoHeight
    />
  );
};

// FIXME create standalone component on Reviewstab directory
const QuickSearchToolbar = ({ requestSearch }) => (
  <Grid sm={6} md={3}>
    <SearchBar
      label={lookUp({ key: 'CONSOLE_SEARCH'})}
      onChange={requestSearch}
    />
  </Grid>
);

CommentsTab.propTypes = {
  id: string.isRequired,
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(CommentsTab);
