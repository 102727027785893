import { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  CloudUpload,
  Close,
  Crop,
  GraphicEq,
  SwitchVideo,
  Translate,
  Chat,
  AddPhotoAlternate,
  Announcement,
  WebAsset,
  LiveTv,
  Subtitles,
  TrackChanges,
} from '@material-ui/icons';
import {
  styled,
  Typography,
  Tooltip,
  Button,
  Grid,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slider,
  Input,
  IconButton,
  Tab,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { lookUp } from 'services/stringService';
import AudioVisualizer from '../AwsLaunchers/AudioVisualizer';
import Comprehend from '../AwsLaunchers/Comprehend';
import Cut from '../AwsLaunchers/Cut';
import GenerateSubtitle from '../AwsLaunchers/GenerateSubtitle';
import MediaProcessor from '../AwsLaunchers/MediaProcessor';
import NftMinting from '../AwsLaunchers/NftMinting';
import SubtitleUpload from '../AwsLaunchers/SubtitleUpload';
import Transcode from '../AwsLaunchers/Transcode';
import Transcribe from '../AwsLaunchers/Transcribe';
import Translation from '../AwsLaunchers/Translation';
import VodToLive from '../AwsLaunchers/VodToLive';
import withFileUpload from 'helpers/withFileS3Upload';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import { videoTypes } from 'constants/contentConst';


const useStyles = makeStyles((theme) => ({
  transCodeModal: {
    padding: '10px',
    width: '400px',
    height: 'auto',
  },
  transCribeModal: {
    padding: '10px',
    width: '400px',
    height: '250px',
  },
  transLateModal: {
    padding: '10px',
    width: '400px',
    height: 'auto',
  },
  comprehendModal: {
    padding: '10px',
    width: '450px',
    height: 'auto',
  },
  enrichModal: {
    padding: '10px',
    width: '500px',
    height: 'auto',
  },
  cutModal: {
    padding: '10px',
    width: '450px',
    height: 'auto',
  },
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalButton: {
    margin: 0,
    color: '#ffffff',
    height: theme.spacing(6),
    width: '100%',
  },
  modalTooltip: {
    height: theme.spacing(6),
    width: '100%',
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.5, 1, 1.2),
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
  shotLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-5),
  },
  techCueLabel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  sliderGrid: {
    marginTop: theme.spacing(3.5),
  },
  techCueGrid: {
    marginTop: '-27px',
  },
  techCueCaption: {
    marginTop: '-22px',
  },
  slider: {
    marginTop: 0,
    paddingTop: 0,
  },
  sliderInput: {
    width: theme.spacing(6),
  },
  narrowTopMargin: {
    marginTop: theme.spacing(-4),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  nbsp: {
    whiteSpace: 'nowrap',
  },

}));

function AudioVideoModal(props) {
  const {
    enqueueSnackbar,
    modalType,
    isOpen,
    switchModal,
    contentData,
    brPtContainer,
    selectedBrPt,
    contentType,
    hasSubtitleMarkers,
    upload,
    showSubtitleTabs,
    setShowSubtitleTabs,
  } = props;

  const contentAssets = contentData.assets;
  const hRef =
    contentAssets.find((e) => e.subType === 'MP4')?.objectUrl ||
    contentAssets.find((e) => e.subType === 'Original')?.objectUrl;

  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);

  const classes = useStyles();

  const [rekogSliders, setRekogSliders] = useState({
    label: 95,
    face: 95,
    shot: 95,
    techCue: 95,
    explicit: 95,
    text: 90,
  });

  const [rekogSwitch, setRekogSwitch] = useState({
    RekognitionCelebrityJob: false,
    RekognitionFaceDetectionJob: false,
    RekognitionTextDetectionJob: false,
    RekognitionContentModerationJob: false,
    RekognitionLabelDetectionJob: false,
    RekognitionSegmentDetectionJob: false,
    TechnicalCue: false,
    Shot: false,
  });

  const isAutoCut  = useSelector((state) => state.content.isAutoCut);

  const SaveButton = styled(Button)({
    // background: '#6495ed',
    color: '#ffffff',
  });

  const handleRekogSliders = (sliderType, newValue) => {
    setRekogSliders((sliders) => ({ ...sliders, [sliderType]: parseInt(newValue) }));
  };

  const handleRekognitionJobs = (jobType, isTurnedOn) => {
    setRekogSwitch((rekogSwitch) => ({ ...rekogSwitch, [jobType]: isTurnedOn }));
  };

  const processRekognition = () => {
    const rekogJobs = Object.keys(rekogSwitch).filter((jobName) => {
      if (jobName !== 'Shot' && jobName !== 'TechnicalCue') {
        return rekogSwitch[jobName];
      }
      return false;
    });
    if (rekogJobs.length < 1) return;
    const rekogJobsPromises = rekogJobs.reduce((apiCalls, rekogJob) => {
      if (!rekogSwitch[rekogJob]) return apiCalls;
      const reqBody = {
        InputFileLocation: hRef,
        ReferenceObjectId: contentData.id,
        Name: `${rekogJob}_${Date.now()}`,
      };
      switch (rekogJob) {
        case 'RekognitionContentModerationJob':
          reqBody.minConfidence = rekogSliders.explicit;
          break;
        case 'RekognitionFaceDetectionJob':
          reqBody.minConfidence = rekogSliders.face;
          break;
        case 'RekognitionTextDetectionJob':
          reqBody.minConfidence = rekogSliders.text;
          break;
        case 'RekognitionSegmentDetectionJob':
          reqBody.Shot = rekogSwitch.Shot;
          if (reqBody.Shot) reqBody.MinConfidenceShot = rekogSliders.shot;
          reqBody.TechnicalCue = rekogSwitch.TechnicalCue;
          if (reqBody.TechnicalCue) reqBody.MinConfidenceTechnicalCue = rekogSliders.techCue;
          break;
        case 'RekognitionLabelDetectionJob':
          reqBody.minConfidence = rekogSliders.label;
        default:
          break;
      }
      apiCalls.push(jobManagerService.create(rekogJob, reqBody));
      return apiCalls;
    }, []);
    Promise.all(rekogJobsPromises)
      .then((jobResponses) => {
        jobResponses.forEach((jobResponse) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`,
          });
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE', type: lookUp({ key: 'CONSOLE_JOB' })}),
        });
      })
      .finally(() => switchModal('enrichment', false));
  };

  const transModal = {};

  transModal.transcode = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_TRANSCODE_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_TRANSCODE' })}
          icon={<SwitchVideo />}
          onClick={(event) => switchModal('transcode', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.transcode}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('transcode', false)}
      >
        <Transcode
          url={contentAssets.find((e) => e.subType === 'Original')?.objectUrl}
          id={contentData.id}
          setOpen={switchModal}
          open={isOpen.transcode}
        />
      </Dialog>
    </>
  );

  transModal.transcribe = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_TRANSCRIBE_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_TRANSCRIBE' })}
          icon={<Chat />}
          onClick={(event) => switchModal('transcribe', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.transcribe}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('transcribe', false)}
      >
        <Transcribe
          url={hRef}
          contentData={contentData}
          audioUrl={
            contentData.assets.find((e) => {
              return e.type === 'Audio' && e.subType === 'WAV' && e.workflowStatus === 'Succeeded';
            })?.objectUrl
          }
          setOpen={switchModal}
          open={isOpen.transcribe}
        />
      </Dialog>
    </>
  );

  transModal.translate = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_TRANSLATE_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_TRANSLATE' })}
          icon={<Translate />}
          onClick={(event) => switchModal('translate', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.translate}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('translate', false)}
      >
        <Translation
          setOpen={switchModal}
          id={contentData.id}
          open={isOpen.translate}
          transcriptAsset={
            contentAssets.find((e) => e.subType.includes('TranscriptRaw')) || 
            contentAssets.find((e) => e.subType.includes('Transcript'))
          }
        />
      </Dialog>
    </>
  );

  transModal.subtUpload = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_SUBTITLEFILEUPLOAD_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_UPLOAD_SUBTITLE' })}
          icon={<CloudUpload />}
          onClick={() => switchModal('subtUpload', true)}
        />
        </Tooltip>

        <Dialog
          open={isOpen.subtUpload}
          fullWidth
          maxWidth="xs"
          onClose={() => switchModal('subtUpload', false)}
        >
          <SubtitleUpload
            contentData={contentData}
            setOpen={switchModal}
            upload={upload}
            defaultLocale={defaultLocale.lang}
          />
        </Dialog>
    </>
  )

  transModal.subtitling = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_GENERATE_SUBTITLE_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_GENERATE_SUBTITLE' })}
          icon={<Subtitles style={{opacity: hasSubtitleMarkers ? 1 : .25}} />}
          onClick={() => hasSubtitleMarkers && switchModal('subtitle', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.subtitle}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('subtitle', false)}
      >
        <GenerateSubtitle
          setOpen={switchModal}
          id={contentData.id}
        />
      </Dialog>
    </>
  );

  transModal.subtitle = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_SUBTITLE' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_SUBTITLE' })}
          icon={<Subtitles />}
          onClick={() => setShowSubtitleTabs(!showSubtitleTabs)}
        />
      </Tooltip>
    </>
  );

  transModal.comprehend = (
    <>
      <Tooltip  title={!contentData.assets.find((e) => {
        return e.subType === 'Transcript' && e.workflowStatus === 'Succeeded'
      })
        ? lookUp({ key: 'CONSOLE_NO_TRANSCRIPT_ASSET_PRESENT_HELPERTEXT' })
        : lookUp({ key: 'CONSOLE_TEXTUAL_ANALYSES' })}
      >
        <div>
          <Tab
            label={lookUp({ key: 'CONSOLE_COMPREHEND' })}
            icon={<Announcement style={{opacity: !contentData.assets.find(
              (e) => e.subType === 'Transcript' && e.workflowStatus === 'Succeeded'
            ) ? .25 : 1}} />}
            onClick={() => 
                contentData.assets.find(e => e.subType === 'Transcript' && e.workflowStatus === 'Succeeded')
                && switchModal('comprehend', true)}
            className={classes.nbsp}
          />
        </div>
      </Tooltip>
      <Dialog
        open={isOpen.comprehend}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('comprehend', false)}
      >
        <Comprehend
          id={contentData.id}
          source={contentData.assets?.find(
            (e) => e.subType === 'Transcript' && e.workflowStatus === 'Succeeded'
          )}
          setOpen={switchModal}
          open={isOpen.comprehend}
        />
      </Dialog>
    </>
  );

  const disableCut = () => {
    if (isAutoCut.on) {
      if (!brPtContainer?.Cut?.length) {
        return true;
      } else {
        if (brPtContainer?.Cut.length === 1) {
          if (brPtContainer?.Cut[0].isInvisible) {
            return true;
          }
        }
      }
    }
    return false;
  };

  transModal.cut = (
    <>
      <Tooltip title={lookUp(
        { 
          key: 'CONSOLE_CUT_HELPERTEXT_TEMPLATE',
          type: lookUp({ key: `CONSOLE_${contentType}`})
        })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_CUT' })}
          icon={<Crop style={{ opacity: disableCut() ? 0.25 : 1 }} />}
          onClick={(event) => switchModal('cut', true)}
          disabled={disableCut()}
        />
      </Tooltip>
      <Dialog open={isOpen.cut} fullWidth maxWidth="xs" onClose={() => switchModal('cut', false)}>
        <Cut
          url={contentAssets.find((e) => e.subType === 'Original')?.objectUrl}
          id={contentData.id}
          setOpen={switchModal}
          open={isOpen.cut}
          brPtContainer={brPtContainer}
          selectedBrPt={selectedBrPt}
          contentType={contentType}
          enqueueSnackbar={enqueueSnackbar}
        />
      </Dialog>
    </>
  );

  transModal.audiovisualizer = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_AUDIO_VISUALIZER_TEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_AUDIO_VISUALIZER' })}
          icon={<GraphicEq />}
          onClick={() => switchModal('audiovisualizer', true)}
          className={classes.nbsp}
        />
      </Tooltip>
      <Dialog
        open={isOpen.audiovisualizer}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('audiovisualizer', false)}
      >
        <AudioVisualizer
          contentData={contentData}
          setOpen={switchModal}
          enqueueSnackbar={enqueueSnackbar}
        />
      </Dialog>
    </>
  );

  transModal.mediaProcessor = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_AUDIO_VISUALIZER_TEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_MEDIA_PROCESSOR' })}
          icon={<TrackChanges />}
          onClick={() => switchModal('mediaProcessor', true)}
          className={classes.nbsp}
        />
      </Tooltip>
      <Dialog
        open={isOpen.mediaProcessor}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('mediaProcessor', false)}
      >
        <MediaProcessor
          contentData={contentData}
          setOpen={switchModal}
          enqueueSnackbar={enqueueSnackbar}
        />
      </Dialog>
    </>
  );

  if (videoTypes.includes(contentType)) {
    transModal.enrichment = (
      <>
        <Tooltip title={lookUp({ key: 'CONSOLE_ENRICHMENT_HELPERTEXT' })}>
          <Tab
            label={lookUp({ key: 'CONSOLE_ENRICHMENT' })}
            icon={<AddPhotoAlternate />}
            onClick={(event) => switchModal('enrichment', true)}
          />
        </Tooltip>
        {isOpen.enrichment ? (
          <Dialog
            open={isOpen.enrichment}
            maxWidth="xl"
            classes={{ paper: classes.enrichModal }}
            onClose={(event) => switchModal('enrichment', false)}
          >
            <div className={classes.xButtonWrapper}>
              <Typography className={classes.modalTitle}>
                {lookUp({ key: 'CONSOLE_CONTENT_INDEXING_TITLE' })}
              </Typography>
              <IconButton aria-label="close" onClick={(event) => switchModal('enrichment', false)}>
                <Close />
              </IconButton>
            </div>
            <span className={classes.modalSubtitle}>
              {lookUp({ key: 'CONSOLE_CONTENT_INDEXING_TEXT' })}
            </span>
            <DialogContent className={classes.dialogBody}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.RekognitionCelebrityJob}
                          onChange={(event) => {
                            handleRekognitionJobs('RekognitionCelebrityJob', event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONCELEBRITYJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.RekognitionTextDetectionJob}
                          onChange={(event) => {
                            handleRekognitionJobs(
                              'RekognitionTextDetectionJob',
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONTEXTDETECTIONJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={7} className={classes.sliderGrid}>
                  <Slider
                    className={classes.slider}
                    defaultValue={95}
                    value={rekogSliders.text}
                    step={1}
                    marks
                    track="inverted"
                    onChange={(event, value) => handleRekogSliders('text', value)}
                    disabled={!rekogSwitch.RekognitionTextDetectionJob}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Input
                    className={classes.sliderInput}
                    value={rekogSliders.text}
                    margin="dense"
                    onChange={(event) => handleRekogSliders('text', event.target.value)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={!rekogSwitch.RekognitionTextDetectionJob}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL' })}</Typography>
                </Grid>
                <Grid item xs={12} className={classes.narrowTopMargin}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.RekognitionFaceDetectionJob}
                          onChange={(event) => {
                            handleRekognitionJobs(
                              'RekognitionFaceDetectionJob',
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONFACEDETECTIONJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={7} className={classes.sliderGrid}>
                  <Slider
                    className={classes.slider}
                    defaultValue={95}
                    value={rekogSliders.face}
                    step={1}
                    marks
                    track="inverted"
                    onChange={(event, value) => handleRekogSliders('face', value)}
                    disabled={!rekogSwitch.RekognitionFaceDetectionJob}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Input
                    className={classes.sliderInput}
                    value={rekogSliders.face}
                    margin="dense"
                    onChange={(event) => handleRekogSliders('face', event.target.value)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={!rekogSwitch.RekognitionFaceDetectionJob}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL' })}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.narrowTopMargin}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.RekognitionContentModerationJob}
                          onChange={(event) => {
                            handleRekognitionJobs(
                              'RekognitionContentModerationJob',
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONCONTENTMODERATIONJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={7} className={classes.sliderGrid}>
                  <Slider
                    className={classes.slider}
                    defaultValue={95}
                    value={rekogSliders.explicit}
                    step={1}
                    marks
                    track="inverted"
                    onChange={(event, value) => handleRekogSliders('explicit', value)}
                    disabled={!rekogSwitch.RekognitionContentModerationJob}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Input
                    className={classes.sliderInput}
                    value={rekogSliders.explicit}
                    margin="dense"
                    onChange={(event) => handleRekogSliders('explicit', event.target.value)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={!rekogSwitch.RekognitionContentModerationJob}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL' })}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.narrowTopMargin}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.RekognitionLabelDetectionJob}
                          onChange={(event) => {
                            handleRekognitionJobs(
                              'RekognitionLabelDetectionJob',
                              event.target.checked
                            );
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONLABELDETECTIONJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={7} className={classes.sliderGrid}>
                  <Slider
                    className={classes.slider}
                    defaultValue={50}
                    value={rekogSliders.label}
                    step={1}
                    marks
                    track="inverted"
                    onChange={(event, value) => handleRekogSliders('label', value)}
                    disabled={!rekogSwitch.RekognitionLabelDetectionJob}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Input
                    className={classes.sliderInput}
                    value={rekogSliders.label}
                    margin="dense"
                    onChange={(event) => handleRekogSliders('label', event.target.value)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={!rekogSwitch.RekognitionLabelDetectionJob}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL' })}</Typography>
                </Grid>

                <Grid item xs={12} className={classes.narrowTopMargin}>
                  <FormControl className={classes.formControl}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rekogSwitch.TechnicalCue}
                          onChange={(event) => {
                            const isChck = event.target.checked;
                            const toSwitchSegment = isChck || (!isChck && !rekogSwitch.Shot);
                            toSwitchSegment &&
                              handleRekognitionJobs('RekognitionSegmentDetectionJob', isChck);
                            handleRekognitionJobs('TechnicalCue', isChck);
                          }}
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_REKOGNITIONSEGMENTDETECTIONJOB' })}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={7}>
                  <Slider
                    className={classes.slider}
                    defaultValue={50}
                    value={rekogSliders.techCue}
                    step={1}
                    track="inverted"
                    marks
                    onChange={(event, value) => handleRekogSliders('techCue', value)}
                    disabled={!rekogSwitch.TechnicalCue}
                  />
                </Grid>

                <Grid item xs={2} className={classes.techCueGrid}>
                  <Input
                    className={classes.sliderInput}
                    value={rekogSliders.techCue}
                    margin="dense"
                    onChange={(event) => handleRekogSliders('techCue', event.target.value)}
                    inputProps={{
                      step: 10,
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={!rekogSwitch.RekognitionSegmentDetectionJob}
                  />
                </Grid>

                <Grid item xs={3} className={classes.techCueCaption}>
                  <Typography variant="caption" gutterBottom>
                    {lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL' })}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={classes.dialogActions}>
                  <SaveButton
                    
                    onClick={() => {
                      processRekognition();
                      switchModal('enrichment', false);
                    }}
                    color="primary"
                  >
                    {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
                  </SaveButton>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        ) : null}
      </>
    );
  }

  transModal.nftMinting = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_NFTMINTING_HELPERTEXT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_NFTMINTING' })}
          icon={<WebAsset />}
          onClick={(event) => switchModal('nftMinting', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.nftMinting}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('nftMinting', false)}
      >
        <NftMinting
          url={contentAssets.find((e) => e.subType === 'Original')?.objectUrl}
          id={contentData.id}
          setOpen={switchModal}
          open={isOpen.nftMinting}
        />
      </Dialog>
    </>
  );

  transModal.vodToLive = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_CREATE_LIVE_EVENT' })}>
        <Tab
          label={lookUp({ key: 'CONSOLE_PLUS_LIVE_EVENT' })}
          icon={<LiveTv />}
          onClick={(event) => switchModal('vodToLive', true)}
        />
      </Tooltip>
      <Dialog
        open={isOpen.vodToLive}
        fullWidth
        maxWidth="xs"
        onClose={() => switchModal('vodToLive', false)}
      >
        <VodToLive
          asset={contentAssets.find((e) => e.subType === 'MP4')}
          id={contentData.id}
          setOpen={switchModal}
          open={isOpen.vodToLive}
        />
      </Dialog>
    </>
  );

  return <> {transModal[modalType]} </>;
}

export default compose(withFileUpload, withSnackbar)(AudioVideoModal);
