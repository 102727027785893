import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  styled,
  Grid,
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const SaveButton = styled(Button)({
  color: '#ffffff',
});

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: 16,
  },
  formControl: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    padding: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));


const Subtitling = (props) => {
  const { id, url, open, setOpen, enqueueSnackbar } = props;
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [translateLangs, setTranslateLangs] = useState({});
  const providers = ['Amazon', 'Google', 'Azure'];

  const classes = useStyles();

  const startSubtitling = (toLang) => {
    const reqBody = {
      ReferenceObjectId: id,
      Name: `SubtitlingJob_${Date.now()}`,
      InputFileLocation: url,
      provider: selectedProvider,
      LanguageCode: selectedLanguage,
    };
    jobManagerService
      .subtitle(reqBody)
      .then((resp) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message:  
          !selectedLanguage 
          ? lookUp({ key: 'CONSOLE_SUBTITLING_STARTED_TEMPLATE', provider: selectedProvider, })
          : lookUp({ key: 'CONSOLE_SUBTITLING_TRANSLATED_STARTED_TEMPLATE', provider: selectedProvider, language: lookUp({ key: `CONSOLE_LANG_${translateLangs[toLang]}` })})
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  };

  useEffect(() => {
    jobManagerService
      .getLanguageCodes('Translate')
      .then((translateLangs) => {
        setTranslateLangs(translateLangs);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_CREATE_SUBTITLE_HEADING' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('subtitling', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>
        {lookUp({ key: 'CONSOLE_CREATE_SUBTITLE_TEXT' })}
      </span>
      <DialogContent>
        <Grid container spacing={2} direction="row" className={classes.transModalGrid}>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label={lookUp({ key: 'CONSOLE_PROVIDER' })}
              value={selectedProvider}
              onChange={(e) => setSelectedProvider(e.target.value)}
            >
              {providers.map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="to_language" margin="dense">
                {lookUp({ key: 'CONSOLE_TO_LANGUAGE' })}
              </InputLabel>
              <Select
                labelId="to_language"
                label={lookUp({ key: 'CONSOLE_TO_LANGUAGE' })}
                margin="dense"
                className={classes.tabSelect}
                value={selectedLanguage}
                defaultValue={selectedLanguage}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={(event) => setSelectedLanguage(event.target.value)}
              >
                {Object.keys(translateLangs).map((langCode, index) => (
                  <MenuItem value={langCode} key={`${index}_${langCode}`}>
                    {translateLangs[langCode]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <SaveButton
            
            onClick={() => {
              startSubtitling();
              setOpen('subtitling', false);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </SaveButton>
        </Grid>
      </DialogActions>
    </>
  );
};

export default withSnackbar(Subtitling);
