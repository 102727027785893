import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  styled,
  Grid,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import markerService from 'services/markerService';
import AlertService from 'services/alertService';

const SaveButton = styled(Button)({
  color: '#ffffff',
});

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.5, 2, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    padding: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    padding: theme.spacing(1.5),
  },
}));

function GenerateSubtitle(props) {
  const { id, setOpen, enqueueSnackbar } = props;
  const [subtitle, setSubtitle] = useState('');
  const [subtitleLangs, setSubtitleLangs] = useState([]);
  const classes = useStyles();

  const startSubtitle = () => {
    const reqBody = {
      provider: 'Markers',
      ReferenceObjectId: id,
      name: `Subtitling_${Date.now()}`,
      type: 'SUBTITLE',
      Language: subtitle,
    };
    jobManagerService
      .subtitle(reqBody)
      .then((resp) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_SUBTITLE_STARTED_TEMPLATE', lang: lookUp({ key: `CONSOLE_LANG_${subtitle}` }) })
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  };

  useEffect(() => {
    const getSubtitleTags = async (id, markerType) => {
      const markerS = {
        tags: [],
        idOrToken: id,
        finished: false,
      };
      try {
        while (!markerS.finished) {
          const queryParams = { type: markerType, itemLimit: 1000 };
          const reqResp = await markerService.search(markerS.idOrToken, queryParams);
          if (reqResp?.pageContent?.length > 0) {
            markerS.tags = markerS.tags.concat(reqResp.pageContent);
          }
          markerS.idOrToken = reqResp?.pagingToken;
          markerS.finished = reqResp?.finished;
        }
        return Promise.resolve(markerS.tags);
      } catch (error) {
        console.error(error);
        return Promise.reject(null);
      }
    }
    getSubtitleTags(id, 'SubtitleTag')
      .then((response) => {
        const langCodes = Object.keys(response?.[0]?.tags);
        setSubtitleLangs(langCodes
          .map(e => ({code: e, label: lookUp({ key: `CONSOLE_LANG_${e}` })}))
          .sort((a, b) => a.label.localeCompare(b.label))
        );
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_GENERATE_SUBTITLE_HEADING' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('subtitle', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>
        {lookUp({ key: 'CONSOLE_GENERATE_SUBTITLE_HELPERTEXT' })}
      </span>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2} direction="row" className={classes.transModalGrid}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="language" margin="dense">
                {lookUp({ key: 'CONSOLE_LANGUAGE' })}
              </InputLabel>
              <Select
                labelId="language"
                label={lookUp({ key: 'CONSOLE_LANGUAGE' })}
                className={classes.tabSelect}
                margin="dense"
                value={subtitle}
                defaultValue={subtitle}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={(event) => setSubtitle(event.target.value)}
              >
                {subtitleLangs.map((lang, index) => (
                  <MenuItem value={lang.code} key={`${index}_${lang.code}`}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <SaveButton
            disabled={!subtitle}
            onClick={() => {
              startSubtitle();
              setOpen('subtitle', false);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </SaveButton>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(GenerateSubtitle);
