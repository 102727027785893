import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, makeStyles, TextField, Tooltip, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Build, Dns, Http } from '@material-ui/icons';
import { EditorInfo, FormFooter, Loader, Tabbing } from 'components';
import Settings from './components/Settings';
import PublishModal from './components/PublishModal';
import PushModal from './components/PushModal';
import SpecificDesigns from './components/SpecificDesigns';
import EndPointSettings from './components/EndPointSettings';
import brandsService from 'services/brandsService';
import { goBackOrStay } from 'helpers/common';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import SectionContainer from 'components/SectionContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
}));

const BrandsForm = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.brand;

  const { id, tab } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = useState({
    name: '',
    defaultLocale: '',
    projectName: '',
    published: '',
    defaultDesign: [],
    specificDesigns: [],
    endpointSettings: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showPushModal, setShowPushModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [tabTitleFirstLoad, setTabTitleFirstLoad] = useState({
    defaultDesign: true,
    specificDesigns: true,
    endpointSettings: true,
  });
  const [isPublishing, setIsPublishing] = useState(false);

  const tabs = [
    {
      name: 'basic',
      buttonGridSize: {
        xs: 6,
        sm: 3,
      },
      content: (
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {model.id && (
                <TextField
                  label={lookUp({ key: 'CONSOLE_ID' })}
                  fullWidth
                  value={model.id}
                  disabled
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_NAME' })}
                required
                fullWidth
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {model.id && model.projectName && (
                <TextField
                  label={lookUp({ key: 'CONSOLE_PROJECTNAME' })}
                  disabled
                  fullWidth
                  value={model.projectName || ''}
                  onChange={(e) => handleModelChange('projectName', e.target.value)}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_DEFAULTLOCALE' })}
                fullWidth
                value={model.defaultLocale || ''}
                onChange={(e) => handleModelChange('defaultLocale', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'default-design',
      icon: <Build />,
      buttonGridSize: {
        xs: 6,
        sm: 3,
      },
      content: (
        <Settings
          type={'design'}
          data={model.defaultDesign || []}
          handleChange={(data) => handleModelChange('defaultDesign', data)}
        />
      ),
    },
    {
      name: 'specific-design',
      icon: <Dns />,
      buttonGridSize: {
        xs: 6,
        sm: 3,
      },
      content: (
        <SpecificDesigns
          data={model.specificDesigns || []}
          handleChange={(data) => handleModelChange('specificDesigns', data)}
        />
      ),
    },
    {
      name: 'endpoints',
      icon: <Http />,
      buttonGridSize: {
        xs: 6,
        sm: 3,
      },
      content: (
        <EndPointSettings
          type={'endpoint'}
          data={model.endpointSettings || []}
          handleChange={(data) => handleModelChange('endpointSettings', data)}
        />
      ),
    },
  ];

  const initialize = async () => {
    try {
      setIsPublishing(true);
      await brandsService.initialize(id, model.awsRegion);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_INITIALIZED_MESSAGE_TEMPLATE', name: model.name }),
      });
      // setTimeout(() => navigate('/configuration/brands'), 50);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      setIsPublishing(false);
    }
  };

  const handleModelChange = (key, value) => {
    if (!tabTitleFirstLoad[key] && !isNavigationBlocked) {
      dispatch(navigationAction.blockNavigation());
    }
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
    if (key == 'name') {
      handleModelChange('projectName', value);
    }
    setTabTitleFirstLoad((tabTitleFirstLoad) => {
      tabTitleFirstLoad[key] = false;
      return tabTitleFirstLoad;
    });
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {
      if (!model.name) {
        throw new Error('Name field is required');
      }
      if (!model.id) {
        if (!model.projectName) {
          throw new Error('Product Name is required');
        } else {
          await brandsService.verify(model.projectName);
        }
      }

      if (publish) {
        if (model.defaultDesign && model.defaultDesign.length === 0) {
          throw new Error('Default design is required');
        }
        if (model.defaultDesign && model.defaultDesign.length !== 0) {
          model.defaultDesign.forEach((design) => {
            if (!design.key) {
              throw new Error('Default design key is required');
            }
            if (!design.value) {
              throw new Error('Default design value is required');
            }
          });
        }
        if (model.specificDesigns && model.specificDesigns.length !== 0) {
          model.specificDesigns.forEach((specificDesign) => {
            if (!specificDesign.name) {
              throw new Error('Specific design name is required');
            }
            if (specificDesign.setting && specificDesign.setting.length === 0) {
              throw new Error('Specific design settings is required');
            }
            if (specificDesign.setting && specificDesign.setting.length !== 0) {
              specificDesign.setting.forEach((design) => {
                if (!design.key) {
                  throw new Error('Specific design settings key is required');
                }
                if (!design.value) {
                  throw new Error('Specific design settings value is required');
                }
              });
            }
          });
        }
        if (model.endpointSettings && model.endpointSettings.length === 0) {
          throw new Error('Endpoint settings is required');
        }
        if (model.endpointSettings && model.endpointSettings.length !== 0) {
          model.endpointSettings.forEach((endpoint) => {
            if (!endpoint.name) {
              throw new Error('Endpoint name is required');
            }
            if (!endpoint.value) {
              throw new Error('Endpoint value is required');
            }
          });
        }
        if (model.id) {
          setShowPublishModal(true);
        } else {
          throw new Error('Only saved brands can be deployed');
        }
      } else {
        try {
          let saveModel;
          if (publish) {
            saveModel = {
              ...model,
              enabled: !model.enabled,
            };
          } else {
            saveModel = { ...model };
          }

          model.specificDesigns &&
            model.specificDesigns.length === 0 &&
            delete saveModel.specificDesigns;

          id ? await brandsService.edit(saveModel) : await brandsService.create(saveModel);
          isNavigationBlocked && dispatch(navigationAction.allowNavigation());
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message:
              publish && model.enabled === false
                ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE' })
                : publish && model.enabled === true
                ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE' })
                : id
                ? lookUp({ key: 'CONSOLE_SAVED_MESSAGE' })
                : lookUp({ key: 'CONSOLE_CREATED_MESSAGE' }),
          });
          goBackOrStay(navigate, '/configuration/brands');
        } catch (error) {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
          });
        }
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await brandsService.getById(id);
      setModel(data);
      return data.projectName;
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_BRAND' }),
        }),
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublishModalClose = () => {
    setIsPublishing(false);
    setShowPublishModal(false);
  };

  const handlePushModalClose = () => {
    setIsPublishing(false);
    setShowPushModal(false);
  };

  useEffect(() => {
    let pageTitle;
    if (id) {
      getData().then((title) => {
        pageTitle = lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title });
        dispatch(navigationAction.setPageTitle(pageTitle));
      });
    } else {
      pageTitle = lookUp({
        key: 'CONSOLE_CREATE_TEMPLATE',
        type: lookUp({ key: 'CONSOLE_BRAND' }),
      });
      dispatch(navigationAction.setPageTitle(pageTitle));
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  const deleteBrand = async () => {
    setIsDeleting(true);
    try {
      await brandsService.destroy(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/configuration/brands'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <SectionContainer flex={1}>
      <HeaderActionsContainer>
        {
          // Publish action button
          // only render publish/unpublish if the asset already exists
          id && (
            <Tooltip
              title={lookUp({
                key:
                  model.published === 'Published'
                    ? 'CONSOLE_FIREBASE_APPLE_HELPERTEXT'
                    : 'CONSOLE_PUBLISH_TO_ACCESS_HELPERTEXT',
              })}
            >
              <ThemedButton
                color={model.published ? 'secondary' : 'success'}
                disabled={isSaving}
                onClick={initialize}
                loading={isPublishing}
              >
                {lookUp({ key: 'CONSOLE_PUSH_SETTINGS_BUTTON' })}
              </ThemedButton>
            </Tooltip>
          )
        }
        {
          // Delete action button
          id && <DeleteButton onDelete={deleteBrand} loading={isDeleting} disabled={isDeleting} />
        }
        <Box flex={1} />
        {/* Cancel action Button */}
        <ThemedButton
          onClick={(e) => {
            e.preventDefault();
            dispatch(navigationAction.allowNavigation());
            window.history?.state?.idx === 0
              ? navigate('/content')
              : navigate(window.history?.state?.idx === 0 ? '/' : -1);
          }}
          disabled={isSaving || isPublishing}
        >
          {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
        </ThemedButton>

        {/* Save action Button */}
        <ThemedButton
          color="success"
          onClick={(e) => {
            e.preventDefault();
            saveData(false);
          }}
          disabled={isSaving || isPublishing}
          loading={isSaving}
        >
          {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
        </ThemedButton>
      </HeaderActionsContainer>
      <Tabbing
        tabs={tabs
          .sort(
            (a, b) =>
              allowedTabs.indexOf(a.name?.replace(' ', '-')) -
              allowedTabs.indexOf(b.name?.replace(' ', '-'))
          )
          .filter((tab) => allowedTabs?.includes(tab.name?.replace(' ', '-')))}
        addTopMargin={HEADER_MIN_HEIGHT}
        scrollButtons="on"
      />

      {showPublishModal && (
        <PublishModal
          enqueueSnackbar={enqueueSnackbar}
          handleClose={() => handlePublishModalClose()}
          saveData={saveData}
        />
      )}

      {showPushModal && (
        <PushModal
          enqueueSnackbar={enqueueSnackbar}
          handleClose={() => handlePushModalClose()}
          saveData={saveData}
        />
      )}
      {id && (
        <>
          <Typography variant="caption" color="text.success" gutterBottom>
            {lookUp({ key: 'CONSOLE_STATUS' })}: {lookUp({ key: `CONSOLE_${model.published}` })}
          </Typography>
          <EditorInfo
            createdAt={model.createdDate}
            brandId={model.projectName}
            modifiedAt={model.lastModifiedDate}
            modifiedBy={model.lastModifiedBy}
          />
        </>
      )}
    </SectionContainer>
  );
}

BrandsForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(BrandsForm);
