import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, createSearchParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CreatedOn from 'components/CreatedOn';
import Lister from 'components/Lister';
import Toolbar from 'components/Toolbar';
import AddButton from 'components/AddButton';
import DeleteButton from 'components/DeleteButton';
import HoverMenu from 'components/HoverMenu';
import rewardsService from 'services/rewardsService';
import alertService from 'services/alertService';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';

// NOTE category colors should come from the BE theme
import purple from '@material-ui/core/colors/purple';
import amber from '@material-ui/core/colors/amber';
import blueGrey from '@material-ui/core/colors/blueGrey';
import cyan from '@material-ui/core/colors/cyan';

const NO_CATEGORY = 'None';
const DEFAULT_CATEGORY = NO_CATEGORY;

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    wrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  deleteDialogHighlightedTitle: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    padding: theme.spacing(1),
  },
  categoryChip: {
    background: theme.palette.common.white,
    color: 'inherit',
  },
  None: {
    background: theme.palette.grey[100],
    borderColor: theme.palette.grey[300],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
  },
  ContentPlayback: {
    background: purple[500],
    borderColor: purple[800],
    color: theme.palette.getContrastText(purple[800]),
  },
  CustomerAction: {
    background: amber[500],
    borderColor: amber[800],
    color: theme.palette.getContrastText(amber[900]),
  },
  SocialActivity: {
    background: cyan[500],
    borderColor: cyan[800],
    color: theme.palette.getContrastText(cyan[800]),
  },
  Payment: {
    background: blueGrey[500],
    borderColor: blueGrey[800],
    color: theme.palette.getContrastText(blueGrey[800]),
  },
}));

const Rewards = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [trigger, setTrigger] = useState(Symbol());
  const [debounce, setDebounce] = useState(undefined);
  const [categoryTypes, setCategoryTypes] = useState([]);
  const [selection, setSelection] = useState([]);
  const [hovered, setHovered] = useState();
  const [deletedIds, setDeletedIds] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  
  const classes = useStyles();

  useEffect(() => {
    getTypeEnums();
  }, []);

  useEffect(() => {
    // TODO implement debounce
    getData();
  }, [search, trigger]);

  const getSearchParams = () => {
    const {
      category,
      page,
      q,
      pageSize: perPage,
      ...restQueryParams
    } = { ...Object.fromEntries(search.entries()) };

    return {
      ...(perPage && { perPage }),
      ...(page && { page }),
      ...(category && { category }),
      ...(q && { q }),
      ...restQueryParams,
    };
  };

  const getData = () => {
    const { q: keyPrefix, ...searchConfig } = getSearchParams();

    setIsLoading(true);
    rewardsService
      .search({
        ...searchConfig,
        keyPrefix,
      })
      .then((res = {}) => {
        const rewardItems = res.data?.map?.((item) => ({ ...item, id: item.key }));
        
        setTotalCount(res.totalCount ?? rewardItems.length);
        setItems(rewardItems);
      })
      .catch((error) => {
        alertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONTENT' }),
          }),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTypeEnums = async () => {
    try {
      
      const typeEnums = (await rewardsService.getTypeEnums()) ?? [];
      setCategoryTypes(typeEnums);
    } catch (error) {
      alertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CONTENT_ENUMS' }),
        }),
      });
    }
  };

  const handleSearch = () => {
    setTrigger(Symbol('refresh'));
  };

  const clearAllSearchFields = () => {
    setSearch((search) => {
      const params = omit({ ...Object.fromEntries(search.entries()) }, ['category', 'q']);
      return {
        ...params,
      };
    });
  };

  const onSearchUpdate = (type, value) => {
    const searchConfig = getSearchParams();

    // reset category
    if (type === 'category' && value === NO_CATEGORY) {
      return setSearch((search) => search.delete('category'));
    }

    setSearch(
      createSearchParams({
        ...searchConfig,
        [type]: value,
      }),
    );
  };

  const handleDelete = (id, title) => {
    setDeletedIds(id);
    setIsDeleting(true);

    rewardsService
      .remove(id)
      .then((e) => {
        alertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        alertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: title }),
        });
      })
      .finally(() => setIsDeleting(false));
  };

  const handleMassDelete = () => {
    const ids = [...selection];
    setDeletedIds(ids);
    setIsDeleting(true);

    Promise.allSettled(ids.map((id) => rewardsService.remove(id)))
      .then((e) => {
        alertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_ITEMS_SUCCESS_TEMPLATE', number: ids.length }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_contents'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        alertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', number: ids.length }),
        });
      })
      .finally(() => {
        setSelection([]);
        setIsDeleting(false);
      });
  };

  const toolbarButtons = [
    {
      label: lookUp({ key: 'CONSOLE_CLEAR_ALL' }),
      startIcon: <ClearAllIcon />,
      onClick: clearAllSearchFields,
      variant: 'text',
      size: 'medium',
    },
    {
      component: <AddButton tooltipText={lookUp({ key: 'CONSOLE_CREATE_REWARD_HELPERTEXT' })} />,
    },
  ];

  const selectToolbarButtons = [
    {
      component: (
        <DeleteButton
          label={lookUp({ key: 'CONSOLE_DELETE_BUTTON_TEMPLATE', number: selection.length })}
          onDelete={handleMassDelete}
          loading={isDeleting}
          disabled={isDeleting}
          size="small"
        />
      ),
    },
  ];

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'category',
      headerName: lookUp({ key: 'CONSOLE_CATEGORY' }),
      align: 'left',
      flex: 1.5,
      renderCell: (item) => (
        <Link to={`./${item.id}/edit`} component={RouterLink}>
          <Chip
            className={classNames(classes.categoryChip, {
              [classes[item.value]]: item.value in classes,
            })}
            label={lookUp({ key: `CONSOLE_${item.value}` })}
            size="small"
          />
        </Link>
      ),
    },
    {
      field: 'key',
      headerName: lookUp({ key: 'CONSOLE_KEY' }),
      align: 'left',
      flex: 2,
      renderCell: (item) => (
        <Tooltip title={item.value}>
          <Link to={`./${item.id}/edit`} component={RouterLink}>
            {item.value}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'friendlyName',
      headerName: lookUp({ key: 'CONSOLE_FRIENDLY_NAME' }),
      align: 'left',
      flex: 2,
      renderCell: (item) => isEmpty(item.value) ? '--' : (
        <Tooltip title={item.value}>
          <Link to={`./${item.id}/edit`} component={RouterLink}>
            {item.value}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'internalPrice',
      headerName: lookUp({ key: 'CONSOLE_PRICE' }),
      description: lookUp({ key: 'CONSOLE_PRICE' }),
      align: 'left',
      flex: 1,
    },
    {
      field: 'tokenPrice',
      headerName: lookUp({ key: 'CONSOLE_TOKEN' }),
      align: 'left',
      flex: 1,
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => (
        <>
          {hovered === item.id && (
            <HoverMenu
              buttons={[
                {
                  component: (
                    <DeleteButton
                      onDelete={() => handleDelete(item.id, item.row.key)}
                      dialogContent={
                        item.row?.key ? (
                          <Box component="span" className={classes.deleteDialogHighlightedTitle}>
                            {item.row.key}
                          </Box>
                        ) : null
                      }
                      variant="icon"
                    />
                  ),
                },
              ]}
            />
          )}
          <CreatedOn date={item.value} />
        </>
      ),
    },
  ];

  return (
    <Box flex={1} xs={12}>
      <Grid className={classes.filtersContainer} container spacing={1}>
        <Grid item className={classes.toolbarContainer}>
          <Grid xs={12} sm={4} md={2}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="category">{lookUp({ key: 'CONSOLE_CATEGORY' })}</InputLabel>
              <Select
                labelId="category"
                label={lookUp({ key: 'CONSOLE_CATEGORY' })}
                value={search.get('category') ?? DEFAULT_CATEGORY}
                onChange={(e) => {
                  e.preventDefault();
                  onSearchUpdate('category', e.target.value);
                }}
                size="small"
              >
                <MenuItem value={DEFAULT_CATEGORY}>
                  {lookUp({ key: `CONSOLE_${DEFAULT_CATEGORY}` })}
                </MenuItem>
                {categoryTypes.map?.((category = '') => (
                  <MenuItem key={category} value={category}>
                    {lookUp({ key: `CONSOLE_${category}` })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Toolbar
            queryBy="q"
            buttons={selection.length ? selectToolbarButtons : toolbarButtons}
            triggerSearch={handleSearch}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Lister
          loading={isLoading}
          columns={columns}
          rows={items}
          totalCount={totalCount}
          selectionModel={selection}
          setSelectionModel={setSelection}
          dataGridProps={{
            onRowEnter: (params) => setHovered(params.id),
            onRowLeave: () => setHovered(null),
          }}
        />
      </Grid>
      <Grid item container sm={12} justifyContent="flex-end">
        <AddButton tooltipText={lookUp({ key: 'CONSOLE_CREATE_REWARD_HELPERTEXT' })} />
      </Grid>
    </Box>
  );
};

export default withSnackbar(Rewards);
