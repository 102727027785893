const SET_LAST_CONTENT_TAB_OPENED = 'SET_LAST_CONTENT_TAB_OPENED';
const SET_CONTENT_STREAMS = 'SET_STREAMS';
const SET_CONTENT_DRMS = 'SET_DRMS';
const SET_CONTENT_TYPES = 'SET_TYPES';
const SET_CONTENT_FORMATS = 'SET_FORMATS';
const SET_AUTO_CLOSE = 'SET_AUTO_CLOSE';
const SET_IMPORT_EXTRA_FIELDS = 'SET_IMPORT_EXTRA_FIELDS';
const SET_TITLE_LINK = 'SET_TITLE_LINK';
const SET_EDITABLE_PLAYLIST = 'SET_EDITABLE_PLAYLIST';
const SET_CHIME_DEFAULT_BCKG = 'SET_CHIME_DEFAULT_BCKG';
const SET_REMIX_SUBASSET_REDIRECT = 'SET_REMIX_SUBASSET_REDIRECT';
const SET_CONTENT_TYPE = 'SET_CONTENT_TYPE';
const SET_CONTENT_DATA = 'SET_CONTENT_DATA';
const SET_AUTO_CUT = 'SET_AUTO_CUT';
const SET_JOB_STATUS_KEYS = 'SET_JOB_STATUS_KEYS';


const setLastContentTabOpened = (lastContentTabOpened) => ({
  type: SET_LAST_CONTENT_TAB_OPENED,
  lastContentTabOpened
});

const setContentStreams = (contentStreams) => ({
  type: SET_CONTENT_STREAMS,
  contentStreams
});

const setContentDrms = (contentDrms) => ({
  type: SET_CONTENT_DRMS,
  contentDrms
});

const setContentTypes = (contentTypes) => ({
  type: SET_CONTENT_TYPES,
  contentTypes
});

const setContentFormats = (contentFormats) => ({
  type: SET_CONTENT_FORMATS,
  contentFormats
});

const setAutoClose = (autoClose) => ({
  type: SET_AUTO_CLOSE,
  autoClose
});

const setImportExtraFields = (importDialogExtraFields) => ({
  type: SET_IMPORT_EXTRA_FIELDS,
  importDialogExtraFields
});

const setTitleLink = (titleLink) => ({
  type: SET_TITLE_LINK,
  titleLink
});

const setEditablePlaylist = (editablePlaylist) => ({
  type: SET_EDITABLE_PLAYLIST,
  editablePlaylist
});

const setContentType = (contentType) => ({
  type: SET_CONTENT_TYPE,
  contentType
});

const setContentData = (contentData) => ({
  type: SET_CONTENT_DATA,
  contentData
});

const setIsDefaultBckgForChime = (isDefaultBckgForChime) => ({
  type: SET_CHIME_DEFAULT_BCKG,
  isDefaultBckgForChime
});

const setIsChildContentRedirect = (isChildContentRedirect) => ({
  type: SET_REMIX_SUBASSET_REDIRECT,
  isChildContentRedirect
});

const setAutoCut = (isAutoCut) => ({
  type: SET_AUTO_CUT,
  isAutoCut
});

const setJobStatusKeys = (jobStatusKeys) => ({
  type: SET_JOB_STATUS_KEYS,
  jobStatusKeys
});

export {
  SET_LAST_CONTENT_TAB_OPENED,
  SET_CONTENT_TYPES,
  SET_CONTENT_DRMS,
  SET_CONTENT_STREAMS,
  SET_CONTENT_FORMATS,
  SET_AUTO_CLOSE,
  SET_IMPORT_EXTRA_FIELDS,
  SET_TITLE_LINK,
  SET_EDITABLE_PLAYLIST,
  SET_CHIME_DEFAULT_BCKG,
  SET_REMIX_SUBASSET_REDIRECT,
  SET_CONTENT_TYPE,
  SET_CONTENT_DATA,
  SET_AUTO_CUT,
  SET_JOB_STATUS_KEYS,
  setLastContentTabOpened,
  setContentTypes,
  setContentDrms,
  setContentFormats,
  setContentStreams,
  setAutoClose,
  setImportExtraFields,
  setTitleLink,
  setEditablePlaylist,
  setIsDefaultBckgForChime,
  setIsChildContentRedirect,
  setContentData,
  setContentType,
  setAutoCut,
  setJobStatusKeys,
};

export default {
  SET_LAST_CONTENT_TAB_OPENED,
  SET_CONTENT_TYPES,
  SET_CONTENT_DRMS,
  SET_CONTENT_STREAMS,
  SET_CONTENT_FORMATS,
  SET_AUTO_CLOSE,
  SET_IMPORT_EXTRA_FIELDS,
  SET_TITLE_LINK,
  SET_EDITABLE_PLAYLIST,
  SET_CHIME_DEFAULT_BCKG,
  SET_REMIX_SUBASSET_REDIRECT,
  SET_CONTENT_TYPE,
  SET_CONTENT_DATA,
  SET_AUTO_CUT,
  SET_JOB_STATUS_KEYS,
  setLastContentTabOpened,
  setContentTypes,
  setContentDrms,
  setContentFormats,
  setContentStreams,
  setAutoClose,
  setImportExtraFields,
  setTitleLink,
  setEditablePlaylist,
  setIsDefaultBckgForChime,
  setIsChildContentRedirect,
  setContentData,
  setContentType,
  setAutoCut,
  setJobStatusKeys,
};
