import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { shape, bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import previewAction from 'store/actions/previewAction';
import { getTypes } from 'helpers/content';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Loader } from 'components';


const ImageLoader = (props) => {
  
  const { item } = props;
  const [image, setImage] = useState(null);

  useEffect(() => {
    const imageLoader = new Image();

    imageLoader.src = item.hRef;

    imageLoader.onload = () => {
      setImage(item.hRef);
    };
  }, []);

  return image ? (
    <img src={image} alt={item.name} {...props} />
  ) : (
    <Grid container justifyContent={'center'} alignItems={'center'}>
      <Loader inline />
    </Grid>
  );
};

ImageLoader.propTypes = {
  item: shape({
    name: string,
    hRef: string
  })
};

const useStyles = makeStyles((theme) => ({
  head: {
    padding: theme.spacing(2, 3)
  },
  pipHead: {
    marginBottom: theme.spacing(1)
  },
  pip: {
    width: 350,
    position: 'fixed',
    top: 80,
    right: 40
  },
  contentSize: {
    width: '100%',
    maxHeight: '50vh',
    minWidth: '30vw'
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  }
}));

const Preview = (props) => {
  
  const classes = useStyles();
  const { preview, setPicInPicStatus, setPreviewItem } = props;

  const handleClose = () => setPreviewItem(null);

  const toggleExpandPreview = () => setPicInPicStatus(!preview.isPicInPic);

  const { contentType, fileType } = getTypes(preview.item.hRef);

  let content = null;

  switch (contentType) {
    case 'audio':
      content = (
        <audio controls autoPlay key={preview.item.hRef} className={classes.contentSize}>
          <source src={preview.item.hRef} type={`audio/${fileType || 'mp3'}`} />
        </audio>
      );
      break;
    case 'video':
      content = (
        <video controls autoPlay key={preview.item.hRef} className={classes.contentSize}>
          <source src={preview.item.hRef} type={`video/${fileType || 'mp4'}`} />
        </video>
      );
      break;
    case 'image':
      content = <ImageLoader item={preview.item} className={classes.contentSize} />;
      break;
    default:
      break;
  }

  // useEffect(() => {
  //   function onScroll() {
  //     if (!preview.isPicInPic) setPicInPicStatus(true);
  //   }
  
  //   window.addEventListener('scroll', onScroll, false);
  
  //   return function cleanup() {
  //     window.removeEventListener('scroll', onScroll, false);
  
  //     // setPreviewItem(null);
  //     // setPicInPicStatus(false);
  //   };
  // });

  const header = (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      className={preview.isPicInPic ? classes.pipHead : classes.head}
    >
      <Grid item xs={6}>
        <Typography variant={'h6'}>{preview.item.name}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
          {/* <IconButton size={'small'} onClick={toggleExpandPreview}>
            {preview.isPicInPic ? <FullscreenExit /> : <Fullscreen />}
          </IconButton> */}
          <IconButton size={'small'} onClick={handleClose} className={classes.marginLeft}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return preview.isPicInPic ? (
    <Card className={classes.pip}>
      <CardContent>
        {header}
        {content}
      </CardContent>
    </Card>
  ) : (
    <Dialog open onClose={handleClose} maxWidth={false}>
      {header}
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

Preview.propTypes = {
  preview: shape({
    item: shape({
      name: string,
      hRef: string
    }),
    isPicInPic: bool
  }).isRequired,
  setPreviewItem: func.isRequired,
  setPicInPicStatus: func.isRequired
};

const mapStateToProps = ({ preview }) => ({ preview });

const mapDispatchToProps = (dispatch) => ({
  setPreviewItem: (item) => dispatch(previewAction.setPreviewItem(item)),
  setPicInPicStatus: (value) => dispatch(previewAction.setPicInPicStatus(value))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
