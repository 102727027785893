import { PageCard } from 'components';

import { SwapHoriz, List } from '@material-ui/icons';

const pages = [
  {
    name: 'Workflows',
    link: '/automation/workflows',
    icon: <SwapHoriz fontSize={'large'} />
  },
  {
    name: 'Job',
    link: '/automation/job',
    icon: <List fontSize={'large'} />
  },
];

const Automation = () => <PageCard pages={pages} />;

export default Automation;
