import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import skuService from 'services/skuService';

const Monetization = ({ model, handleAuthGroupChange = () => {}, size = 'medium' }) => {
  const [isMonetizationPackageLoading, setIsMonetizationPackageLoading] = useState(false);
  const [monetizationPackageOptions, setMonetizationPackageOptions] = useState([]);

  useEffect(() => {
    const getMonetizationData = async () => {
      setIsMonetizationPackageLoading(true);

      try {

        const { data } = await skuService.search( { page: 0, perPage: -1 });
        setMonetizationPackageOptions(data.map(({ id, name }) => ({ label: name, value: id })));
        setIsMonetizationPackageLoading(false);
      } catch (e) {
        console.error(`Can\`t load monetization options: ${e.message}`);
        setIsMonetizationPackageLoading(false);
      }
    }

    getMonetizationData();
  }, []);

  const getSelectedValue = () => {
    if (!model.authGroupIds || !Array.isArray(model.authGroupIds)) {
      return;
    }

    return monetizationPackageOptions.filter?.(({ value }) => model.authGroupIds.includes(value));
  };

  return isMonetizationPackageLoading ? (
    <Loader inline />
  ) : (
    <Autocomplete
      label={lookUp({ key: 'CONSOLE_MONETIZATION' })}
      onChange={(e, value) => {
        e.preventDefault();
        handleAuthGroupChange(value);
      }}
      value={getSelectedValue()}
      options={monetizationPackageOptions}
      getOptionLabel={({ label }) => label}
      noOptionsText={lookUp({ key: 'CONSOLE_NO_OPTIONS' })}
      renderInput={params => (
        <TextField
          {...params}
          label={lookUp({ key: 'CONSOLE_MONETIZATION' })}
          size={size}
        />
      )}
      size={size}
      multiple
    />
  );
};

Monetization.propTypes = {
  model: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default (Monetization);
