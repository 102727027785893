import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
  },
}));

const WorkflowTemplatesDropdown = (props) => {
  
  const classes = useStyles();
  const { enqueueSnackbar, onAttribChange, nodeId } = props;

  const [workflowTemplates, setWorkflowTemplates] = useState([]);
  const [selectedWf, setSelectedWf] = useState([]);

  const handleSelect = (value) => {
    if (!value) return;
    setSelectedWf(value)
    onAttribChange(value, 'text', nodeId, 'WorkflowTemplateId');
  };


  useEffect(() => {
    jobManagerService
      .searchWfTemplates()
      .then((templates) => {
        setWorkflowTemplates(templates.data);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_WORKFLOW_TEMPLATES'}) }),
        });
      });
  }, []);

  return (
    <>
      <TextField
        className={classes.input}
        select
        label={lookUp({ key: 'CONSOLE_WORKFLOWS'})}
        value={selectedWf}
        onChange={(event) => handleSelect(event.target.value)}
      >
        {workflowTemplates.map((wf) => (
          <MenuItem key={wf.id} value={wf.id}>
            {wf.name}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default WorkflowTemplatesDropdown;
