import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import TabPanel from 'components/TabPanel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Link from '@material-ui/core/Link';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import SwitchVideoIcon from '@material-ui/icons/SwitchVideo';
import ChatIcon from '@material-ui/icons/Chat';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import TranslateIcon from '@material-ui/icons/Translate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import LockIcon from '@material-ui/icons/Lock';
import { DataGrid } from '@material-ui/data-grid';
import uuid from 'uuid';
import Transcode from '../Editor/AwsLaunchers/Transcode';
import Transcribe from '../Editor/AwsLaunchers/Transcribe';
import Comprehend from '../Editor/AwsLaunchers/Comprehend';
import NftMinting from '../Editor/AwsLaunchers/NftMinting';
import Subtitling from '../Editor/AwsLaunchers/Subtitling';
import Translation from '../Editor/AwsLaunchers/Translation';
import CreatedOn from 'components/CreatedOn';
import DurationPicker from 'components/DurationPicker';
import IngestFile from 'components/IngestFile';
import { lookUp } from 'services/stringService';
import AlertService from 'services/alertService';
import jobManagerService from 'services/jobManagerService';
import contentService from 'services/contentService';
import { fileSize } from 'helpers/content';
import DeleteButton from 'components/DeleteButton';
import NoResultsDataGridOverlay from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';
import { default as DialogV2 }  from 'components/Dialog';
import AddEditAssetForm from 'pages/Content/Form/AddEditAssetForm';
import AddButton from 'components/AddButton';
import parametersService from 'services/parametersService';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
  mainTable: {
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
  workFlowSelector: {
    width: '350px',
    margin: '10px 0 15px 0',
  },
  runWfButton: {
    margin: '10px 0 15px 10px',
  },
  dialogActionsOptionContainer: {
    paddingLeft: theme.spacing(1),
  },
  dialogHeaderTabs: {
    position: 'absolute',
    left: 0,
    top: theme.spacing(1),
    width: '100%',
    zIndex: theme.zIndex.modal + 1,
  },
}));

const Actions = (props) => {
  const {
    contentId,
    item,
    hasTranscode = false,
    audioUrl,
    model,
    handleChange,
    setAssetsModalOpen,
    setAssetModel,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState({
    transcode: false,
    nftMinting: false,
    transcribe: false,
    comprehend: false,
    subtitling: false,
    translate: false,
  });

  const switchModalOpen = (modalType, open) => {
    setIsModalOpen((o) => ({ ...o, [modalType]: open }));
  };

  const toggleDelete = (id) => {
    const copyAssets = model.assets.filter((e) => e.id !== id);
    const changedAsset = model.assets.find((e) => e.id === id);
    changedAsset.isDeleted = !changedAsset.isDeleted;
    handleChange('assets', [...copyAssets, changedAsset]);
  };

  return (
    <Grid container spacing={1} justifyContent="flex-end">
      <ButtonGroup>
        <Tooltip title={lookUp({ key: 'CONSOLE_COPY_TO_CLIPBOARD_HELPERTEXT' })}>
          <IconButton
            size="small"
            onClick={() => navigator.clipboard.writeText(item.publicUrl || item.objectUrl)}
          >
            <ImageIcon />
          </IconButton>
        </Tooltip>
        <IconButton size="small" onClick={() => switchModalOpen('transcode', true)}>
          <Tooltip title={lookUp({ key: 'CONSOLE_TRANSCODE' })}>
            <SwitchVideoIcon />
          </Tooltip>
        </IconButton>
        <Dialog
          open={isModalOpen.transcode}
          fullWidth
          maxWidth="xs"
          onClose={() => switchModalOpen('transcode', false)}
        >
          <Transcode
            url={item.url || item.objectUrl}
            id={item.Boxid || item.id}
            setOpen={switchModalOpen}
          />
        </Dialog>
        <IconButton size="small" onClick={() => switchModalOpen('nftMinting', true)}>
          <Tooltip title={lookUp({ key: 'CONSOLE_NFTMINTING' })}>
            <WebAssetIcon />
          </Tooltip>
        </IconButton>
        <Dialog
          open={isModalOpen.nftMinting}
          fullWidth
          maxWidth="xs"
          onClose={() => switchModalOpen('nftMinting', false)}
        >
          <NftMinting
            url={item.url || item.objectUrl}
            id={item.Boxid || item.id}
            setOpen={switchModalOpen}
            audioUrl={audioUrl}
          />
        </Dialog>
        <IconButton size="small" onClick={() => switchModalOpen('transcribe', true)}>
          <Tooltip title={lookUp({ key: 'CONSOLE_TRANSCRIBE' })}>
            <ChatIcon />
          </Tooltip>
        </IconButton>
        <Dialog
          open={isModalOpen.transcribe}
          fullWidth
          maxWidth="xs"
          onClose={() => switchModalOpen('transcribe', false)}
        >
          <Transcribe
            url={item.url || item.objectUrl}
            locale={item.locale}
            contentId={contentId}
            audioUrl={audioUrl || ''}
            setOpen={switchModalOpen}
          />
        </Dialog>
        {hasTranscode && (
          <>
            <IconButton size="small" onClick={() => switchModalOpen('comprehend', true)}>
              <Tooltip title={lookUp({ key: 'CONSOLE_COMPREHEND' })}>
                <AnnouncementIcon />
              </Tooltip>
            </IconButton>
            <Dialog
              open={isModalOpen.comprehend}
              fullWidth
              maxWidth="xs"
              onClose={() => switchModalOpen('comprehend', false)}
            >
              <Comprehend source={hasTranscode} id={item.id} setOpen={switchModalOpen} />
            </Dialog>
            <IconButton size="small" onClick={() => switchModalOpen('subtitling', true)}>
              <Tooltip title={lookUp({ key: 'CONSOLE_SUBTITLING' })}>
                <SubtitlesIcon />
              </Tooltip>
            </IconButton>
            <Dialog
              open={isModalOpen.subtitling}
              fullWidth
              maxWidth="xs"
              onClose={() => switchModalOpen('subtitling', false)}
            >
              <Subtitling
                url={item.url || item.objectUrl}
                id={item.Boxid || item.id}
                setOpen={switchModalOpen}
              />
            </Dialog>
            <IconButton size="small" onClick={() => switchModalOpen('translate', true)}>
              <Tooltip title={lookUp({ key: 'CONSOLE_TRANSLATE' })}>
                <TranslateIcon />
              </Tooltip>
            </IconButton>
            <Dialog
              open={isModalOpen.translate}
              fullWidth
              maxWidth="xs"
              onClose={() => switchModalOpen('translate', false)}
            >
              <Translation
                url={item.url || item.objectUrl}
                id={item.Boxid || item.id}
                setOpen={switchModalOpen}
              />
            </Dialog>
          </>
        )}
        <IconButton
          size="small"
          onClick={() => {
            setAssetModel(item);
            setAssetsModalOpen(true);
          }}
        >
          <Tooltip title={lookUp({ key: 'CONSOLE_EDIT' })}>
            <EditIcon />
          </Tooltip>
        </IconButton>
        {!!model.assets.find((e) => e.id === item.id) &&
          (item.isDeleted ? (
            <IconButton size="small" onClick={() => toggleDelete(item.id)}>
              <Tooltip title={lookUp({ key: 'CONSOLE_RESTORE' })}>
                <RestoreFromTrashIcon />
              </Tooltip>
            </IconButton>
          ) : (
            <DeleteButton
              onDelete={() => toggleDelete(item.id)}
              dialogTitle={lookUp({ key: 'CONSOLE_CONFIRMATION_DELETE' })}
              variant="icon"
            />
          ))}
      </ButtonGroup>
    </Grid>
  );
};

const Assets = (props) => {
  const { handleChange, model, enqueueSnackbar, upload } = props;
  const protoAssetModel = {
    id: '',
    fileName: '',
    type: '',
    subType: '',
    workflowStatus: '',
    lifeCyclePolicies: ['archive-after-60days', 'delete-no'],
    objectUrl: '',
    publicUrl: '',
    locale: '',
    duration: 0,
    createdDate: '',
    modifiedDate: '',
    downloadUrl: '',
    fileSize: 0,
    isPublic: true,
  };

  const { id: contentId } = useParams();

  const [search, setSearch] = useSearchParams();
  const anchorRef = useRef(null);
  const classes = useStyles();
  const assets = useMemo(() => model.assets?.map((e) => ({ ...e, id: e.id || uuid() })), [model]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [selectedWf, setSelectedWf] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const runTargetOptions = ['Original asset', 'Self'];
  const [assetsModalOpen, setAssetsModalOpen] = useState(false);
  const [resultsFromServer, setResultsFromServer] = useState([]);
  const [isPublic, setIsPublic] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [localeOptions, setLocaleOptions] = useState({});
  const [subtypeOptions, setSubtypeOptions] = useState([]);
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [assetModel, setAssetModel] = useState(protoAssetModel);
  const [isHovered, setIsHovered] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const [useOriginalAssetOnly, setUseOriginalAssetOnly] = useState(true)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const runWf = () => {
    const reqBody = useOriginalAssetOnly
      ? { ReferenceObjectId: model.id, ReferenceObjectName: model.originalTitle }
      : selectedIndex !== 1 && model.assets.find((a) => a.subType === 'Original')
      ? model.assets.reduce((reqParams, asset) => {
          if (asset.subType === 'Original') {
            reqParams.ReferenceObjectId = asset.id;
            reqParams.ReferenceObjectName = model.originalTitle;
            reqParams.InputFileLocation = asset.objectUrl;
          }
          return reqParams;
        }, {})
      : { ReferenceObjectId: model.id, ReferenceObjectName: model.originalTitle };

    reqBody.WorkflowTemplateId = selectedWf;
    jobManagerService
      .runWorkflow(reqBody)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_START_WORKFLOW_SUCCESS' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_FAIL_TEMPLATE',
            type: lookUp({ key: `CONSOLE_WORKFLOW` }),
          }),
        });
      });
  };

  const columns = [
    {
      field: 'isPublic',
      headerName: lookUp({ key: 'CONSOLE_PUBLIC' }),
      width: 90,
      renderCell: (item) =>
        item.row.isDeleted ? (
          <span style={{ color: 'red', fontWeight: 900, textTransform: 'uppercase' }}>
            {lookUp({ key: 'CONSOLE_DELETED_SHORT' })}
          </span>
        ) : item.row.isPublic ? (
            <PublicIcon color="primary" fontSize="small" />
          ) : (
            <LockIcon color="disabled" fontSize="small" />
        ),
    },
    {
      field: 'workflowStatus',
      width: 90,
      headerName: lookUp({ key: 'CONSOLE_STATUS' }),
      renderCell: (item) => lookUp({ key: `CONSOLE_${item.value}` }),
    },
    {
      field: 'fileName',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      minWidth: 320,
      flex: 1,
      renderCell: (item) => (
        <DownloadFile item={item} model={model} enqueueSnackbar={enqueueSnackbar} />
      ),
    },
    {
      field: 'actions',
      headerName: ' ',
      minWidth: 250,
      sortable: false,
      renderCell: (item) =>
        item['id'] === isHovered && (
          <Actions
            contentId={contentId}
            item={item.row}
            model={model}
            handleChange={handleChange}
            setAssetsModalOpen={setAssetsModalOpen}
            setAssetModel={setAssetModel}
            hasTranscode={
              item.row.subType === 'Transcript' && item.row.workflowStatus === 'Succeeded'
            }
            audioUrl={
              (item.row.type === 'Audio' && item.row.workflowStatus === 'Succeeded')?.objectUrl
            }
          />
        ),
    },
    {
      field: 'type',
      headerName: lookUp({ key: 'CONSOLE_TYPE' }),
      renderCell: (item) => lookUp({ key: `CONSOLE_${item.value}` }),
    },
    {
      field: 'subType',
      headerName: lookUp({ key: 'CONSOLE_SUBTYPE' }),
      minWidth: 140,
      renderCell: (item) => lookUp({ key: `CONSOLE_${item.value}` }),
    },
    {
      field: 'locale',
      headerName: lookUp({ key: 'CONSOLE_LOCALE' }),
    },
    {
      field: 'duration',
      headerName: lookUp({ key: 'CONSOLE_DURATION' }),
      minWidth: 100,
      renderCell: (item) =>
        ['Video', 'Audio'].includes(item.row.type) ? (
          <DurationPicker duration={item.value} />
        ) : null,
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'fileSize',
      headerName: lookUp({ key: 'CONSOLE_FILESIZE' }),
      valueFormatter: (item) => fileSize(item.value),
    },
  ];

  useMemo(() => {
    if (!resultsFromServer.length) return;
    if (!isPublic) {
      resultsFromServer[0].isPublic = false;
    }
    handleChange('assets', [...assets, resultsFromServer[0].assets[0] ?? resultsFromServer[0]]);
    setAssetsModalOpen(false);
  }, [resultsFromServer]);

  useEffect(() => {
    jobManagerService
      .searchWfTemplates()
      .then((response) => {
        const workflows = response.data;
        setWorkflows(workflows);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    jobManagerService
      .getLanguageCodes('Translate')
      .then((codes) => setLocaleOptions({ '--': lookUp({ key: 'CONSOLE_DEFAULT' }), ...codes }))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_LANGUAGE_CODES' }),
          }),
        });
      });
  }, []);

  useEffect(() => {
    parametersService
      .getByKey('Console:Usecase:Original:Asset:Only')
      .then((response) => {
        setUseOriginalAssetOnly(response?.value === 'true');
      })
      .catch(() => {
        setUseOriginalAssetOnly(true);
      });
  }, []);

  useEffect(() => {
    contentService
      .getAssetTypeEnums()
      .then((assetTypes) =>
        setAssetTypeOptions([...assetTypes.map((assetType) => Object.keys(assetType)[0])].sort())
      )
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_ASSET_TYPE' }),
          }),
        });
      });
  }, []);

  useEffect(() => {
    contentService
      .getSubtypeEnums()
      .then((subtypes) =>
        setSubtypeOptions([...subtypes.map((subtype) => Object.keys(subtype)[0])].sort())
      )
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SUBTYPE_CODES' }),
          }),
        });
      });
  }, []);

  useEffect(() => {
    if (search.get('section') === 'workflows') {
      // FIXME do not access DOM directly
      document.getElementById('workflows')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assets]);

  // FIXME cleanup
  const handleAdd = () => {
    setResultsFromServer([]);
    setAssetModel(protoAssetModel);
    setAssetsModalOpen(true);
  };

  const originalAsset = model.assets?.find?.(({ subType }) => subType === 'Original') || {};
  const doesAssetExist = !isEmpty(assetModel.id);

  return (
    <Box flex={1}>
      <Grid
        container
        spacing={4}
        className={classes.mainTable}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {assets.find((e) => e.subType === 'Original') && (
          <Grid item lg={12} xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_ORIGINAL_ASSET_TITLE' })}
            </Typography>
            <DataGrid
              columns={columns}
              rows={[...assets.filter((e) => e.subType === 'Original')]}
              onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
              onRowLeave={(params, event) => setIsHovered(null)}
              autoHeight
              hideFooter
              pagination
              disableColumnFilter
              disableColumnMenu
              getRowClassName={classes.isDeleted}
              disableSelectionOnClick
              components={{
                LoadingOverlay: DataGridOverlayLoaderLinear,
                NoRowsOverlay: NoResultsDataGridOverlay,
                NoResultsOverlay: NoResultsDataGridOverlay,
              }}
              componentsProps={{
                noRowsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
                noResultsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
                pagination: {
                  classes: null,
                  labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
                  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
                  SelectProps: {
                    MenuProps: { disableScrollLock: true },
                  },
                },
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item md={6} xs={12} flex={1}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_ASSETS_TITLE' })}
              </Typography>
            </Grid>
            <Box flex={1} />
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_SHOW_DELETED' })}>
                <Switch
                  onChange={() => setShowDeleted(!showDeleted)}
                  checked={showDeleted}
                  color={showDeleted ? 'primary' : 'default'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_SHOW_DELETED' })}</Typography>
            </Grid>
            <Grid item>
              <AddButton
                tooltipText={lookUp({ key: 'CONSOLE_ADD_ASSET_HELPERTEXT' })}
                onAdd={handleAdd}
              />
            </Grid>
          </Grid>
          <DataGrid
            columns={columns}
            rows={
              showDeleted
                ? assets.filter((e) => e.subType !== 'Original')
                : assets.filter((e) => !e.isDeleted && e.subType !== 'Original')
            }
            onRowEnter={(params, event) => setIsHovered(params['row']['id'])}
            onRowLeave={(params, event) => setIsHovered(null)}
            autoHeight
            pagination
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
            components={{
              LoadingOverlay: DataGridOverlayLoaderLinear,
              NoRowsOverlay: NoResultsDataGridOverlay,
              NoResultsOverlay: NoResultsDataGridOverlay,
            }}
            componentsProps={{
              noRowsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
              noResultsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
              pagination: {
                classes: null,
                labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
              },
            }}
          />
          <Grid container sm={12} justifyContent="flex-end">
            <AddButton
              tooltipText={lookUp({ key: 'CONSOLE_ADD_ASSET_HELPERTEXT' })}
              onAdd={handleAdd}
            />
          </Grid>
          <Grid item xs={12} id="workflows">
            <Typography variant="h6">{lookUp({ key: 'CONSOLE_WORKFLOWS' })}</Typography>
            <TextField
              select
              className={classes.workFlowSelector}
              label={lookUp({ key: 'CONSOLE_SELECT_WORKFLOW' })}
              disabled={!workflows?.length}
              value={selectedWf}
              onChange={(event) => setSelectedWf(event.target.value)}
              size="small"
            >
              {workflows.map((wf) => (
                <MenuItem key={wf.id} value={wf.id}>
                  {wf.name}
                </MenuItem>
              ))}
            </TextField>
            <ButtonGroup
              className={classes.runWfButton}
              disabled={!selectedWf}
              color="primary"
              ref={anchorRef}
              aria-label="split button"
            >
              <Button onClick={runWf}>
                {lookUp({
                  key: useOriginalAssetOnly
                    ? 'CONSOLE_RUN_BUTTON'
                    : !originalAsset || originalAsset.id === model.id
                    ? 'CONSOLE_RUN_BUTTON'
                    : `CONSOLE_RUN_ON_${runTargetOptions[selectedIndex]}`,
                })}
              </Button>
              {useOriginalAssetOnly
                ? null
                : originalAsset &&
                  originalAsset.id !== model.id && (
                    <Button
                      color="primary"
                      size="small"
                      aria-controls={open ? 'split-button-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-label="select target"
                      aria-haspopup="menu"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  )}
            </ButtonGroup>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: (placement = 'right'),
                    position: 'absolute',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        {runTargetOptions.map((option, index) => (
                          <MenuItem
                            key={option}
                            selected={index === selectedIndex}
                            onClick={(event) => handleMenuItemClick(event, index)}
                          >
                            {lookUp({ key: `CONSOLE_RUN_ON_${option}` })}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Grid>
        {/* upload asset dialog */}
        <DialogV2
          open={assetsModalOpen}
          onClose={() => {
            setAssetsModalOpen(false);
            setAssetModel({});
          }}
          title={
            // display title only if asset already exists
            doesAssetExist &&
            lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: lookUp({ key: 'CONSOLE_ASSET' }) })
          }
          actions={
            // display actions only on upload asset tab selected, since Forms have own actions
            currentTab === 0 ? (
              <>
                <FormControl
                  className={classes.dialogActionsOptionContainer}
                  disabled={isUploading}
                >
                  <Tooltip title={lookUp({ key: 'CONSOLE_IS_PUBLIC_HELPERTEXT' })} placement="top">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isPublic}
                          onChange={() => setIsPublic(!isPublic)}
                          name="isPublic"
                          color="primary"
                        />
                      }
                      label={lookUp({ key: 'CONSOLE_PUBLIC' })}
                    />
                  </Tooltip>
                </FormControl>
                <Box flex={1} />
              </>
            ) : null
          }
          maxWidth="sm"
          fullWidth
          // full width content only on upload asset tab selected
          fullWidthContent={!doesAssetExist && assetsModalOpen && currentTab === 0}
        >
          {
            // display upload tabs only if the asset does not already exist
            !doesAssetExist && (
              <Box className={classes.dialogHeaderTabs}>
                <Tabs
                  value={currentTab}
                  onChange={(e, newTab) => {
                    e.preventDefault?.();
                    setCurrentTab(newTab);
                  }}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <Tab label={lookUp({ key: 'CONSOLE_UPLOAD_ASSET_TAB' })} />
                  <Tab label={lookUp({ key: 'CONSOLE_CREATE_ASSET_TAB' })} />
                </Tabs>
              </Box>
            )
          }
          {doesAssetExist ? (
            <AddEditAssetForm
              mainDuration={model.duration}
              assetModel={assetModel}
              setAssetModel={setAssetModel}
              assets={assets}
              handleChange={handleChange}
              assetTypeOptions={assetTypeOptions}
              subtypeOptions={subtypeOptions}
              localeOptions={localeOptions}
              setAssetsModalOpen={setAssetsModalOpen}
            />
          ) : (
            <>
              <Box flex={1}>
                <TabPanel value={currentTab} index={0}>
                  <IngestFile
                    contentId={model.id}
                    isPublic={isPublic}
                    // FIXME dropdown={false} should be dropdown or no prop
                    dropdown={false}
                    upload={upload}
                    enqueueSnackbar={enqueueSnackbar}
                    setResultsFromServer={setResultsFromServer}
                    onUploadStatusChange={setIsUploading}
                    fullWidthContent
                  />
                  {resultsFromServer[0]?.assets?.length && (
                    <Typography>{lookUp({ key: 'CONSOLE_FILE_UPLOADED' })}</Typography>
                  )}
                </TabPanel>
              </Box>
              <TabPanel value={currentTab} index={1}>
                <AddEditAssetForm
                  assetModel={assetModel}
                  setAssetModel={setAssetModel}
                  assets={assets}
                  handleChange={handleChange}
                  assetTypeOptions={assetTypeOptions}
                  subtypeOptions={subtypeOptions}
                  localeOptions={localeOptions}
                  setAssetsModalOpen={setAssetsModalOpen}
                />
              </TabPanel>
            </>
          )}
        </DialogV2>
      </Grid>
    </Box>
  );
};

export default withSnackbar(Assets);

const DownloadFile = ({ item, model, enqueueSnackbar }) => {
  return (
    <Grid container alignItems={'center'}>
      <Grid item xs>
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() =>
            contentService
              .downloadFile(item.row.downloadUrl, item.row.fileName)
              .then(() => {
                AlertService.displaySuccess({
                  msgBar: enqueueSnackbar,
                  message: lookUp({ key: 'CONSOLE_DOWNLOAD_STARTED_MESSAGE' }),
                });
              })
              .catch((error) => {
                AlertService.displayError({
                  msgBar: enqueueSnackbar,
                  error,
                  context: lookUp({ key: 'CONSOLE_DOWNLOAD_FAILED_MESSAGE' }),
                });
              })
          }
        >
          {item.row.fileName || item.value?.split('/')?.pop() || item.value}
        </Link>
      </Grid>
    </Grid>
  );
};
