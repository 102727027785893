import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { ChromePicker } from 'react-color';
import { Popover, InputAdornment, TextField, makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  smallCircle: {
    width: 30,
    height: 30
  }
}));

function ColorPicker(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [color, setColor] = useState(props.color || '#000000');
  const [opacityBg, setOpacityBg] = useState('1');
  const [opacity, setOpacity] = useState('');
  const [background, setBackground] = useState(props.color.hsl || {});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color) => {
    const hexRgbOrRgbaPattern = /^#(?:(?:[\da-f]{3}){1,2}|(?:[\da-f]{4}){1,2})$/i;

    if (typeof color === 'string' ) {
      let pastedColor = '#' + color.split('#').pop();
      if (!pastedColor.match(hexRgbOrRgbaPattern)) return;
      setColor(pastedColor.substr(0, 8));
      setBackground(pastedColor)
      setOpacity(pastedColor.substr(8,2))
      props.handleChange(pastedColor);
      return;
    }

    setColor(color.hex);
    setBackground(color.hsl);
    setOpacityBg(color.hsl.a);
    if (color.hsl.a < 1 || color.hsl.a > 0) {
      setOpacity(Math.ceil(color.hsl.a * 100))
    }
    if (color.hsl.a === 1 || color.hsl.a === 0) {
      setOpacity('')
    }
    props.handleChange(color.hex.concat(opacity));
  };

  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  return (
    <>
      <TextField
        label={props.label}
        onChange={(event) => handleChange(event.target.value)}
        className={classes.textField}
        value={color.concat(opacity)}
        size={props.size}
        InputProps={{
          //readOnly: true,
          endAdornment: (
            <InputAdornment position='start'>
              <IconButton
                aria-describedby={id}
                size={'small'}
                onClick={handleClick}
                style={{ backgroundColor: color, opacity: opacityBg }}
                className={classes.smallCircle}
              />
            </InputAdornment>
          )
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      > {color}
        <ChromePicker color={background} onChange={handleChange} />
      </Popover>
    </>
  );
};

ColorPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  color: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

ColorPicker.defaultProps = {
  color: '#000000',
  label: lookUp({ key: 'CONSOLE_COLOR' }),
  size: 'small',
};

export default ColorPicker;
