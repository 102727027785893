import React from 'react';
import { lookUp } from 'services/stringService';
import {  Avatar,  Tooltip, makeStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

const useStyles = makeStyles(theme => ({
  wrap: {
    backgroundColor: 'transparent',
  },
  done: {
    color: red[900],
    backgroundColor: red[200],
    borderRadius: 999,
  }
}))


const UnPublishedComponent = ({title=lookUp({ key: 'CONSOLE_UNPUBLISHED' })}) => {
  const classes = useStyles();
    return (
        <Tooltip title={title}>
            <Avatar className={classes.wrap}>
                <NotInterestedIcon className={classes.done}/>
            </Avatar>
        </Tooltip>
    )
} 

export default UnPublishedComponent