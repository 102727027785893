import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, func, array } from 'prop-types';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  makeStyles,
  Divider,
  MenuList,
  Popper,
  TextField,
  MenuItem,
  ClickAwayListener,
  ButtonGroup,
  Grow,
  Paper,
  Box
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Add, Delete } from '@material-ui/icons';
import { Loader, PropsSelect } from 'components';
import libraryEnumsService from 'services/libraryEnumsService';
import { setSortDirections, setValueFormats } from 'store/actions/libraryEnumsAction';
import AlertService from 'services/alertService';
import DeleteButton from 'components/DeleteButton';


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginX: {
    marginTop: '4px',
  },
  addButton: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 1
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0 0 0',
  },
  divider: {
    width: '70%',
  },
  dividerText: {
    fontStyle: 'italic',
  },
  orderGroupContainer: {
    border: '1px solid #dadada',
    width: '100%',
    padding: '20px',
    marginTop: '30px'
  }
}));

const OrderRules = (props) => {

  const classes = useStyles();
  const { handleChange, orderingGroups, enqueueSnackbar, setSortDirections, sortDirections, setValueFormats } = props;

  const [orderingGroupItems, setOrderingGroupItems] = useState(orderingGroups ?? []);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [directionOptions, setDirectionOptions] = useState([]);
  const [isDirectionOptionsLoading, setIsDirectionOptionsLoading] = useState(false);
  const [refreshPeriodOptions, setRefreshPeriodOptions] = useState([]);
  const [isRefreshPeriodOptionsLoading, setIsRefreshPeriodLoading] = useState(false);

  const [groupMenuOpenStatus, setGroupMenuOpenStatus] = useState([].constructor(orderingGroups?.length ?? 0).fill(false));
  const [groupSelectedIndex, setGroupSelectedIndex] = useState([].constructor(orderingGroups?.length ?? 0).fill(0));


  const [anchorEl, setAnchorEl] = React.useState(null);

  const newOptions = ['Ordering', 'Trending now', 'Coming soon', 'Most Watched']


  const handleMenuItemClick = (event, index, groupIndex) => {
    groupSelectedIndex[groupIndex] = index;
    setGroupSelectedIndex([...groupSelectedIndex]);
    closeMenuOptions();
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.currentTarget)) {
      return;
    }
    closeMenuOptions();
  };

  const closeMenuOptions = () => {
    setAnchorEl(null);
    setGroupMenuOpenStatus([...groupMenuOpenStatus.map(x => false)]);
  }

  const handleToggle = (menuIndex, event) => {
    groupMenuOpenStatus[menuIndex] = !groupMenuOpenStatus[menuIndex];
    setAnchorEl(anchorEl ? null : event.currentTarget.parentElement);
    setGroupMenuOpenStatus([...groupMenuOpenStatus]);
  };

  const toOptions = (items) => items.map((i) => ({ label: lookUp({ key: `CONSOLE_${i}` }), value: i }));

  const handleItemsChange = (index, groupIndex, key, value) => {
    orderingGroupItems[groupIndex].orderRules = orderingGroupItems[groupIndex].orderRules.map((p, i) => (i === index ? { ...p, [key]: value } : p));
    setOrderingGroupItems([...orderingGroupItems]);
  }

  const handleGroupItemChange = (groupIndex, key, value) => {
    setOrderingGroupItems((prev) => prev.map((p, i) => (i === groupIndex ? { ...p, [key]: value } : p)));
  }

  const handleItemsRemove = (groupIndex, itemIndexToRemove) => {
    setOrderingGroupItems((prev) => {
      return prev.filter((groupItem, index) => {
      if (groupIndex === index) {
        return groupItem.orderRules = groupItem.orderRules.filter((p, i) => i !== itemIndexToRemove);
      } else {
        return groupItem;
      }
    })});
  }

  const handleGroupItemRemove = (groupIndex) => {
    setOrderingGroupItems(() => [...orderingGroupItems.filter((p, i) => i !== groupIndex)]);
  }

  const handleItemsAdd = (groupIndex) => {
    switch (groupSelectedIndex[groupIndex]) {
      case (0):
        orderingGroupItems[groupIndex].orderRules = [
          ...orderingGroupItems[groupIndex].orderRules,
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: '',
            direction: directionOptions.length ? directionOptions[1].value : null,
            valueList: []
          }];
        return setOrderingGroupItems([...orderingGroupItems]);
      case (1):
        orderingGroupItems[groupIndex].orderRules = [
          ...orderingGroupItems[groupIndex].orderRules,
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:Popularity',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Counter:Hit:Day',
            direction: directionOptions.length ? 'Ascending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:Plays:Count',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:PublishedDate',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
        ];
        return setOrderingGroupItems([...orderingGroupItems]);
      case (2):
        orderingGroupItems[groupIndex].orderRules = [
          ...orderingGroupItems[groupIndex].orderRules,
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:Available:From',
            direction: directionOptions.length ? 'Ascending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:PublishedDate',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:CreatedDate',
            direction: directionOptions.length ? 'Ascending' : null,
            valueFormat: 'Text'
          },
        ];
        return setOrderingGroupItems([...orderingGroupItems]);
      case (3):
        orderingGroupItems[groupIndex].orderRules = [
          ...orderingGroupItems[groupIndex].orderRules,
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Counter:Hit:10Days',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Counter:Hit:Day',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:Plays:Count',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
          {
            index: orderingGroupItems[groupIndex].orderRules.length + 1,
            propertyName: 'Content:PublishedDate',
            direction: directionOptions.length ? 'Descending' : null,
            valueFormat: 'Text'
          },
        ];
        return setOrderingGroupItems([...orderingGroupItems]);
      default:
        return '';
    }
  }

  const handleGroupItemAdd = () => {
    setOrderingGroupItems((prev) => [...prev, {
      id: null,
      index: orderingGroupItems.length + 1,
      name: '',
      refreshPeriod: 0,
      orderRules: []
    }]);
    setGroupMenuOpenStatus((prev) => [...prev, false]);
    setGroupSelectedIndex((prev) => [...prev, 0]);
  }

  const getDirections = async () => {
    setIsDirectionOptionsLoading(true);
    try {
      const options =
        sortDirections.length === 0
          ? await libraryEnumsService.getSortDirections()
          : sortDirections;

      sortDirections.length === 0 && setSortDirections(options);
      setDirectionOptions([...options].map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_DIRECTION' }) })
      });
      setIsDirectionOptionsLoading(false);
    }
  };

  const getRefreshPeriods = async () => {
    setIsRefreshPeriodLoading(true);
    try {
      const options = await libraryEnumsService.getRefreshPeriod();
      setRefreshPeriodOptions([...Object.keys(options)].map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_REFRESHPERIOD' }) })
      });
      setIsRefreshPeriodLoading(false);
    }
  };

  useEffect(() => {
    let groupMenuStatus = [];
    if (orderingGroups && orderingGroups.length > 0) {
      orderingGroups.forEach(() => {
        groupMenuStatus.push(false);
      });
    }
    const getFormattingOptions = async () => {
      try {
        const options = await libraryEnumsService.getValueFormats();
        setValueFormats(options);
        setFormattingOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_FORMAT' }) })
        });
      }
    }
    getFormattingOptions()
  }, [])

  useEffect(() => {
    getDirections().then(() => setIsDirectionOptionsLoading(false));
  }, []);

  useEffect(() => {
    getRefreshPeriods().then(() => setIsRefreshPeriodLoading(false));
  }, []);

  useEffect(() => {
    handleChange(orderingGroupItems);
  }, [orderingGroupItems]);

  return (
    <>
      <Grid container alignItems={'center'} className={classes.marginX}>
        {
          orderingGroupItems && orderingGroupItems.length !== 0 && (
            orderingGroupItems.map((orderGroupItem, groupIndex) => (
              <Grid className={classes.orderGroupContainer}>
                <Box sx={{ display: 'flex', justifyContent: 'end', paddingRight: '10px', marginBottom: '20px' }}>
                  <DeleteButton onDelete={() => handleGroupItemRemove(groupIndex)} />
                </Box>
                <Grid xs={12} container>
                  <Grid item xs={6} style={{ paddingRight: 10 }}>
                    <TextField
                      fullWidth
                      id={`GroupName-${groupIndex}`}
                      label={lookUp({ key: 'CONSOLE_NAME' })}
                      placeholder={lookUp({ key: 'CONSOLE_NAME' })}
                      onChange={({ target }) => handleGroupItemChange(groupIndex, 'name', target.value)}
                      value={orderGroupItem.name}
                    ></TextField>
                  </Grid>

                  <Grid item xs={6} style={{ paddingRight: 10 }}>
                    {isRefreshPeriodOptionsLoading ? (
                      <Loader inline />
                    ) : (
                      <TextField
                        select
                        fullWidth
                        id={`GroupRefreshPeriod-${groupIndex}`}
                        label={lookUp({ key: 'CONSOLE_REFRESH_PERIOD' })}
                        placeholder={lookUp({ key: 'CONSOLE_REFRESH_PERIOD' })}
                        value={refreshPeriodOptions.find((o) => o.value === orderGroupItem.refreshPeriod)?.value || null}
                        onChange={({ target }) => handleGroupItemChange(groupIndex, 'refreshPeriod', target.value)}
                      >
                        {refreshPeriodOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} key={`${orderGroupItem.name}-${groupIndex}`}>
                  {
                    orderGroupItem.orderRules && orderGroupItem.orderRules.length > 0 && (
                      <Grid container alignItems={'center'} className={classes.marginX}>
                        {orderGroupItem.orderRules.map((item, index) => (
                          <Grid item xs={12} key={`${item.name}-${index}`}>
                            <Typography gutterBottom>{lookUp({ key: 'CONSOLE_RULE_TEMPLATE', number: index + 1 })}</Typography>
                            <Grid container alignItems={'center'}>
                              <Grid item xs={11}>
                                <Grid container alignItems={'center'} spacing={1}>
                                  <Grid item xs={12} sm={4} md={4}>
                                    <PropsSelect
                                      label={lookUp({ key: 'CONSOLE_PROPERTY_NAME' })}
                                      value={item.propertyName}
                                      handleChange={(value) => handleItemsChange(index, groupIndex, 'propertyName', value)}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4}>
                                    {isDirectionOptionsLoading ? (
                                      <Loader inline />
                                    ) : (
                                      <TextField
                                        select
                                        fullWidth
                                        id={`${item.direction}-${index}`}
                                        label={lookUp({ key: 'CONSOLE_DIRECTIONS' })}
                                        placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                                        value={directionOptions.find((o) => o.value === item.direction)?.value || null}
                                        onChange={({ target }) => handleItemsChange(index, groupIndex, 'direction', target.value)}
                                      >
                                        {directionOptions.map((e, i) => (
                                          <MenuItem key={i} value={e.value}>
                                            {e.label}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4}>
                                    <TextField
                                      select
                                      fullWidth
                                      id={`formatting-${index}`}
                                      label={lookUp({ key: 'CONSOLE_FORMATTING' })}
                                      InputLabelProps={{ shrink: 'true' }}
                                      placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                                      value={formattingOptions.find((o) => o.value === item.valueFormat)?.value || null}
                                      onChange={({ target }) =>
                                        handleItemsChange(index, groupIndex, 'valueFormat', target.value)
                                      }
                                    >
                                      {formattingOptions.map((e, i) => (
                                        <MenuItem key={i} value={e.value}>
                                          {e.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={1} className={classes.textCenter}>
                                <IconButton onClick={() => handleItemsRemove(groupIndex, index)}>
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Grid>
                            {index !== orderGroupItem.orderRules.length - 1 && (
                              <div className={classes.dividerWrapper}>
                                <Divider variant="middle" className={classes.divider} />
                                <Typography
                                  color="textPrimary"
                                  display="block"
                                  variant="caption"
                                  className={classes.dividerText}
                                >
                                  {lookUp({ key: 'CONSOLE_AND' })}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    )
                  }
                </Grid>

                <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.addButton}>
                  <ButtonGroup color="primary" aria-label="split button">
                    <Button color="primary" onClick={() => handleItemsAdd(groupIndex)} startIcon={<Add />}>
                      {lookUp({ key: `CONSOLE_${newOptions[groupSelectedIndex[groupIndex]]}_BUTTON` })}
                    </Button>
                    <Button
                      color="primary"
                      size="small"
                      aria-controls={groupMenuOpenStatus[groupIndex] ? 'split-button-menu' : undefined}
                      aria-expanded={groupMenuOpenStatus[groupIndex] ? 'true' : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={(e) => handleToggle(groupIndex, e)}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper open={groupMenuOpenStatus[groupIndex]} anchorEl={anchorEl} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement = 'right', position: 'absolute'
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" >
                              {newOptions.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  selected={index === groupSelectedIndex[groupIndex]}
                                  onClick={(event) => handleMenuItemClick(event, index, groupIndex)}
                                >
                                  {lookUp({ key: `CONSOLE_${option}_BUTTON` })}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Grid>
              </Grid>
            ))
          )
        }
        <Box sx={{ marginTop: '30px' }}>
          <Button color="primary" onClick={handleGroupItemAdd} startIcon={<Add />}>
            {lookUp({ key: 'CONSOLE_ADD_ORDER_GROUPING_BUTTON' })}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

OrderRules.propTypes = {
  orderRules: arrayOf(
    shape({
      propertyName: string,
      direction: string,
      valueList: arrayOf(string)
    })
  ).isRequired,
  handleChange: func.isRequired,
  enqueueSnackbar: func.isRequired,
  sortDirections: array.isRequired,
  setSortDirections: func.isRequired
};

const mapStateToProps = ({ libraryEnums: { sortDirections } }) => ({ sortDirections });

const mapDispatchToProps = (dispatch) => ({
  setValueFormats: (data) => dispatch(setValueFormats(data)),
  setSortDirections: (data) => dispatch(setSortDirections(data))
});

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(OrderRules);
