import { getConsoleApiEndpoint } from './apiEndpoints';


const getImageProcessingEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('ImageProcessor');
  if (!endpoint) throw Error('No Image processing endpoints.');
  return endpoint;
};

const processedImageUrl = (imageUrl, parameters) => {
  return `${getImageProcessingEndpoint()}?url=${imageUrl}&${new URLSearchParams(parameters).toString()}`;
}

export { processedImageUrl };

export default { processedImageUrl };