import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { string, shape, func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { IconButton, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import PublishedComponent from 'assets/icons/PublishedComponent';
import UnPublishedComponent from 'assets/icons/UnPublishedComponent';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import CheckBox from '@material-ui/icons/CheckBox';
import { CreatedOn, ListToolBar } from 'components';
import { FavouriteIcon, UnFavouriteIcon } from 'assets/icons/FavouriteIcons';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderMinus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import catalogService from 'services/catalogService';
import manualCatalogService from 'services/manualCatalogService';
import {
  getCustomerRelation,
  addCustomerRelation,
  deleteCustomerRelation,
} from 'services/collectionsServiceV2';
import 'styles/Visibility.css';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  makeStyles,
  Link,
  Grid,
  Input,
  Toolbar,
  Button,
  Fab,
  Checkbox,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ConfirmDialog, Loader } from 'components';
import {
  setVisibilityGroups,
  setParentName,
  setParentId,
  setBreadCrumbsLinks,
} from 'store/actions/libraryEnumsAction';
import {
  getBreadcrumbsLinks,
  getParentName,
  getVisibilitesGroups,
} from 'store/reducers/libraryEnumsReducer';
import FavouriteCatalogs from './components/FavouriteCatalogs';
import LogButton from 'components/Audit/LogButton';

import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
  },
  smallColumn: {
    width: '40px',
  },
  actions: {
    width: '160px',
  },
  addButton: {
    position: 'relative',
    zIndex: 1,
  },
  dividerText: {
    fontSize: '110%',
    margin: theme.spacing(2, 0, 1, 0),
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const DraggableComponent = (item, arrInd) => (props) => {
  return (
    <Draggable draggableId={item.id} index={arrInd} key={item.id}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  );
};

const DroppableComponent = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'catalogContainer'} direction="vertical">
        {(provided) => {
          return (
            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
              {props.children}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

function Catalogs(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    enqueueSnackbar,
  } = props;

  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  const breadcrumbsLinks = useSelector(getBreadcrumbsLinks);
  const parentName = useSelector(getParentName);
  const visibilitiesGroups = useSelector(getVisibilitesGroups);
  const ownId = useSelector((state) => state.auth.user.id);

  const anchorRef = React.useRef(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [favourites, setFavourites] = useState([]);
  const [parentData, setParentData] = useState({});
  const [elementsToDownload, setElementsToDownload] = useState([]);
  const [checkboxVisible, setCheckboxVisible] = useState(false);


const catalogIcon = (kind, type, protection) => {
  switch (true) {
    case protection:
      return <FontAwesomeIcon icon={faFolder} color={theme.palette?.primary?.main || "#1b6ca8"} size="2x" />;
    case kind === 'Manual':
      return <FontAwesomeIcon icon={faFolderMinus} size="2x" />;
    case kind === 'Automatic' && type === 'Folder':
      return <FontAwesomeIcon icon={faFolder} color={theme.palette?.secondary?.main || "#fbd46d"} size="2x" />;
    case kind === 'Automatic' && type === 'Group':
      return <FontAwesomeIcon icon={faFolder} color={theme.palette?.primary?.main || "#1b6ca8"} size="2x" />;
    default:
      return '';
  }
};

  const iconsCell = {
    width: id ? 200 : 120,
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  };

  const getFavouriteCatalogs = () =>
    getCustomerRelation(ownId, 'FavCatalog', 'List')
      .then((favouriteItems) => {
        const favPromises = favouriteItems.pageContent.map((fav) => catalogService.get(fav.targetId));
        return Promise.all(favPromises);
      })
      .then((favourites) => setFavourites(favourites))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_FAVOURITES' }),
          }),
        });
      });

  const fetchVisibilityGroups = () =>
    catalogService
      .catalogTypes()
      .then((visibilityGroups) => {
        dispatch(setVisibilityGroups(visibilityGroups));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONTENT' }),
          }),
        });
      });

  useEffect(() => {
    setIsLoading(true);
    getFavouriteCatalogs()
      .then(() => fetchVisibilityGroups())
      .finally(() => setIsLoading(false));
    return () => dispatch(setBreadCrumbsLinks(null));
  }, []);

  useEffect(() => {
    id && getSecondLevel();
  }, [id]);

  useEffect(() => {
    if (visibilitiesGroups.length) {
      if (!id) dispatch(setBreadCrumbsLinks(makeRootCrumbs()));
      else if (!breadcrumbsLinks || breadcrumbsLinks.length === 0) breadBuilder(id);
    }
  }, [visibilitiesGroups, id]);

  const getSecondLevel = async () => {
    setIsLoading(true);
    try {
      const { data } = await catalogService.search({
        page: 0,
        perPage: 100,
        q: '',
        p: id,
        order: 'Asc',
        sortBy: 'index',
      });
      setData(data);
      const parentData = await catalogService.get(id);
      setParentData(parentData);
      dispatch(setParentName(parentData.name));
      dispatch(setParentId(parentData.id));
    } catch (error) {
      if (error.status === 404 || error.message?.includes('404')) {
        error.response.data.message = 'Catalog not found.';
      }
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
      navigate('/apps/catalogs');
    } finally {
      setIsLoading(false);
    }
  };

  const makeRootCrumbs = () => {
    const breads = [];
    breads.push({
      text: lookUp({ key: 'CONSOLE_APPS' }),
      link: '/apps',
      isCurrent: false,
      order: 1,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs',
      isCurrent: true,
      order: 2,
    });
    return breads;
  };

  const makeFinalCrumbsSequence = (breads, vGroupInd) => {
    const vGroup = visibilitiesGroups[vGroupInd];
    breads.push({
      text: vGroup.name,
      link: `/apps/catalogs/${vGroup.id}`,
      isCurrent: false,
      order: null,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs',
      isCurrent: false,
      order: null,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_APPS' }),
      link: '/apps',
      isCurrent: false,
      order: null,
    });
    breads.reverse();
    breads.forEach((bread, index) => (bread.order = index + 1));
    breads[breads.length - 1].isCurrent = true;
    dispatch(setBreadCrumbsLinks(breads));
  };

  const breadBuilder = (parentId, breads = []) => {
    const vGroupInd = visibilitiesGroups.findIndex((vGroup) => vGroup.id === parentId);
    if (vGroupInd > -1) {
      makeFinalCrumbsSequence(breads, vGroupInd);
    } else if (parentId) {
      catalogService
        .get(parentId)
        .then((response) => {
          breads.push({
            text: response.name,
            link: `/apps/catalogs/${response.id}`,
            isCurrent: false,
            order: null,
          });
          breadBuilder(response.parentId, breads);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_build_breadcrumbs_ERROR' }),
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      return;
    }
  };

  const addCrumbListLink = (item, bread, breads) => {
    bread.text = `${item.name}`;
    bread.link = `/apps/catalogs/${item.id}`;
    bread.isCurrent = true;
    bread.order = breads[breads.length - 1].order + 1;
    breads.push(bread);
    dispatch(setBreadCrumbsLinks(breads));
  };

  const addCrumbNewLink = (item, bread, breads) => {
    bread.text = lookUp({
      key: 'CONSOLE_CREATE_TEMPLATE',
      type: lookUp({ key: 'CONSOLE_CATALOG' }),
    });
    bread.link = item;
    bread.isCurrent = true;
    bread.order = breads[breads.length - 1].order + 1;
    breads.push(bread);
    dispatch(setBreadCrumbsLinks(breads));
  };

  const addCrumbEditLink = (item, bread, breads) => {
    bread.text = lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: item.name });
    bread.link = `/apps/catalogs/${item.id}/edit`;
    bread.isCurrent = true;
    bread.order = breads[breads.length - 1].order + 1;
    breads.push(bread);
    dispatch(setBreadCrumbsLinks(breads));
  };

  const addCrumbLink = (item, type) => {
    const breads = breadcrumbsLinks;
    breads[breads.length - 1].isCurrent = false;
    const bread = {
      text: '',
      link: '',
      isCurrent: true,
      order: null,
    };
    switch (type) {
      case 'list':
        addCrumbListLink(item, bread, breads);
        break;
      case 'new':
        addCrumbNewLink(item, bread, breads);
        break;
      case 'edit':
        addCrumbEditLink(item, bread, breads);
        break;
    }
  };

  const addToFavourites = async (id) => {
    addCustomerRelation(ownId, 'FavCatalog', id)
      .then((addedFavourite) => catalogService.get(addedFavourite.targetId))
      .then((newFavourite) => setFavourites((favs) => [...favs, newFavourite]))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_add_favourite_error' }),
        });
      });
  };

  const removeFromFavourite = async (id) => {
    deleteCustomerRelation(ownId, 'FavCatalog', id)
    .then(() => setFavourites((favs) => favs.filter((fav) => fav.id !== id)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_remove_favourites_error' }),
        });
      });
  };

  const reorder = async (data, startIndex, endIndex) => {
    const dataCopy = [...data];
    const [removed] = dataCopy.splice(startIndex, 1);
    dataCopy.splice(endIndex, 0, removed);
    setData(dataCopy);
    const catalog = Object.values(dataCopy).reduce((acc, data, index) => {
      data.index = index;
      return { ...acc, [data.id]: data.index };
    }, {});
    await catalogService.setPosition(catalog);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    reorder(data, result.source.index, result.destination.index).catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_drag_drop_error' }),
      });
    });
  };

  const copyCatalog = async (item) => {
    if (item.kind === 'Manual') {
      await manualCatalogService.get(item.id).then((copyManual) => {
        setIsLoading(true);
        copyManual.name = `${item.name} (copy)`;
        copyManual.enabled = false;
        manualCatalogService.create(copyManual).then((newData) => {
          newData.name = `${item.name} (copy)`;
          newData.contentType = 'Content';
          newData.enabled = false;
          const newList = [...data, newData];
          setData(newList);
          setIsLoading(false);
        });
      });
    } else {
      await catalogService.get(item.id).then((copyCatalog) => {
        setIsLoading(true);
        copyCatalog.name = `${item.name} (copy)`;
        copyCatalog.enabled = false;
        catalogService.create(copyCatalog).then((newData) => {
          newData.name = `${item.name} (copy)`;
          newData.contentType = 'Content';
          newData.enabled = false;
          const newList = [...data, newData];
          setData(newList);
          setIsLoading(false);
        });
      });
    }
  };

  const exportJSON = () => {
    const manualCatalogs = data.filter((catalog) => catalog.kind === 'Manual');
    const manualCtgPromises = manualCatalogs.map((manCtg) => manualCatalogService.get(manCtg.id));
    Promise.all(manualCtgPromises)
      .then((manualCtgs) => {
        const exportCatalogs = [...data, ...manualCtgs];
        return catalogService.downloadJSON(parentName, exportCatalogs);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_export_json_ERROR' }),
        });
      });
  };

  const selecterClicked = () => {
    setCheckboxVisible((o) => !o);
  };

  const exportSelected = () => {
    const manualCatalogs = elementsToDownload.filter((catalog) => catalog.kind === 'Manual');
    const manualCtgPromises = manualCatalogs.map((manCtg) => manualCatalogService.get(manCtg.id));
    Promise.all(manualCtgPromises)
      .then((manualCtgs) => {
        const exportCatalogs = [...elementsToDownload, ...manualCtgs];
        return catalogService.downloadJSON(parentName, exportCatalogs);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_export_selected_error' }),
        });
      })
      .finally(() => {
        setElementsToDownload([]);
        setCheckboxVisible(false);
      });
  };

  const importJSON = (event) => {
    event.preventDefault();
    setIsLoading(true);
    catalogService
      .uploadJSON(event.target.files[0], id)
      .then((response) => getSecondLevel())
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_import_json_error' }),
        });
      })
      .finally(() => setIsLoading(false));
  };

  const uploadClick = () => {
    const uploadInput = document.getElementById('uploadCatalogJSON');
    uploadInput.click();
  };

  const catalogCategoryBody = [
    {
      name: 'type',
      align: 'left',
      render: (item) => (
        <Tooltip
          title={
            item.protected
              ? lookUp({ key: 'CONSOLE_SYSTEM_FOLDER' })
              : lookUp({ key: 'CONSOLE_FOLDER' })
          }
          key={item.id}
        >
          <Link
            to={`/apps/catalogs/${item.id}`}
            component={RouterLink}
            onClick={(event) => addCrumbLink(item, 'list')}
          >
            {catalogIcon(item.kind, item.type, item.protected)}
          </Link>
        </Tooltip>
      ),
    },
    {
      name: 'name',
      align: 'left',
      render: (item) => {
        return (
          <Link
            to={`/apps/catalogs/${item.id}`}
            component={RouterLink}
            onClick={(event) => addCrumbLink(item, 'list')}
          >
            {item.name}
          </Link>
        );
      },
    },
    {
      name: 'date',
      align: 'center',
      style: { whiteSpace: 'nowrap', width: 100 },
      render: (item) => <CreatedOn date={item.createdDate} />,
    },
    {
      name: 'icons',
      align: 'right',
      style: iconsCell,
      render: (item) => (
        <>
          {item.enabled ? <PublishedComponent /> : <UnPublishedComponent />}
          <LogButton type={'Catalog'} id={item.id} />
        </>
      ),
    },
  ];

  const tableBody = [
    {
      name: 'type',
      align: 'left',
      render: (item) => (
        <Tooltip title={item.kind === 'Automatic' ? 'Folder' : 'Manual Folder'} key={item.id}>
          <Link
            to={`/apps/catalogs/${item.id}`}
            component={RouterLink}
            onClick={(event) => addCrumbLink(item, 'list')}
          >
            {catalogIcon(item.kind, item.type)}
          </Link>
        </Tooltip>
      ),
    },
    {
      name: 'editAction',
      align: 'left',
      render: (item) => (
        <>
          <Tooltip title={lookUp({ key: 'CONSOLE_EDIT' })}>
            <Link
              to={{ pathname: `/apps/catalogs/${item.id}/edit` }}
              component={RouterLink}
              onClick={() => addCrumbLink(item, 'edit')}
            >
              <EditIcon
                style={{ cursor: 'pointer', verticalAlign: 'middle', display: 'inline-flex' }}
              />
            </Link>
          </Tooltip>
        </>
      ),
    },
    {
      name: 'name',
      align: 'left',
      render: (item) => (
        <Link
          to={`/apps/catalogs/${item.id}`}
          component={RouterLink}
          onClick={(event) => addCrumbLink(item, 'list')}
        >
          <p style={{ color: 'black', width: 'auto' }}> {item.name}</p>
        </Link>
      ),
    },
    {
      name: 'createdDate',
      align: 'left',
      style: { whiteSpace: 'nowrap', width: 100 },
      render: (item) => <CreatedOn date={item.createdDate} />,
    },
    {
      name: 'icons',
      align: 'right',
      style: iconsCell,
      render: (item) => (
        <>
          {item.enabled ? <PublishedComponent /> : <UnPublishedComponent />}
          {favourites.find((favourite) => favourite.id === item.id) ? (
            <UnFavouriteIcon
              removeFavourite={() => removeFromFavourite(item.id)}
            />
          ) : (
            <FavouriteIcon
              addFavourite={() => addToFavourites(item.id)}
            />
          )}
          <Tooltip title={lookUp({ key: 'CONSOLE_COPY' })}>
            <IconButton onClick={() => copyCatalog(item)} size="small">
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={lookUp({ key: 'CONSOLE_LOGS' })}>
            <LogButton type={'Catalog'} id={item.id} />
          </Tooltip>
          <ConfirmDialog
            onConfirm={() => handleDelete(item)}
            message={lookUp({
              key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE',
              title: item.name || item.key || 'item',
            })}
            disabled={isDeleting}
            dialogText={lookUp({ key: 'CONSOLE_DELETE_HEADING' })}
          />
        </>
      ),
    },
  ];

  const handleDelete = async (item) => {
    if (!isDeleting) {
      setIsDeleting(true);
      catalogService
        .remove(item)
        .then(() => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE',
              title: item.name || item.key || lookUp({ key: 'CONSOLE_item' }),
            }),
          });
          const newList = data.filter((it) => it.id !== item.id);
          setData(newList);
          const isItFavourite = favourites.find((fav) => fav.id === item.id);
          isItFavourite && removeFromFavourite(item.id);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
              title: lookUp({ key: 'CONSOLE_CATALOG' }),
            }),
          });
        })
        .finally(() => setIsDeleting(false));
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleCheckbox = (item, checked) => {
    if (checked) {
      setElementsToDownload([...elementsToDownload, item]);
    } else {
      const filtered = elementsToDownload.filter((e) => e.id !== item.id);
      setElementsToDownload(filtered);
      if (!filtered.length) setCheckboxVisible(false);
    }
  };

  const handleMainCheckbox = (checked) => {
    const idsOnThisPage = data.map((element) => element.id);
    const elementsToDownloadCopy = elementsToDownload.length
      ? [...elementsToDownload].filter((element) => !idsOnThisPage.includes(element.id))
      : [];
    !checked
      ? setElementsToDownload(elementsToDownloadCopy)
      : setElementsToDownload(elementsToDownloadCopy.concat(data));
    setMainChecked((o) => !o);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {favourites.length > 0 && (
        <>
          <Typography
            color="textPrimary"
            display="block"
            variant="h6"
            className={classes.dividerText}
          >
            {lookUp({ key: 'CONSOLE_FAVOURITES' })}
          </Typography>
          <FavouriteCatalogs
            favourites={favourites}
            catalogIcon={catalogIcon}
            removeFromFavourite={removeFromFavourite}
            breadBuilder={breadBuilder}
            addCrumbLink={addCrumbLink}
            handleDelete={handleDelete}
            root={!id}
          />
        </>
      )}
      {!id && (
        <div onClick={() => addCrumbLink('/apps/catalogs/create', 'new')} style={{ marginTop: '15px'}}>
          <ListToolBar create={{ link: '/apps/catalogs/create' }}/>
        </div>
      )}
      {id && (
        <>
          <Toolbar className={classes.root}>
            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Tooltip title={lookUp({ key: 'CONSOLE_DOWNLOAD' })}>
                  <Fab
                    aria-label="elementsToDownload"
                    color={'primary'}
                    size={'small'}
                    onClick={exportJSON}
                  >
                    <GetAppIcon />
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={
                    !elementsToDownload.length
                      ? 'Select downloads'
                      : `Download ${elementsToDownload.length} item${
                          elementsToDownload.length > 1 ? 's' : ''
                        }`
                  }
                >
                  {!elementsToDownload.length ? (
                    <Fab
                      aria-label={'Select downloads'}
                      color={'primary'}
                      size={'small'}
                      onClick={selecterClicked}
                    >
                      <CheckBox />
                    </Fab>
                  ) : (
                    <Button
                      aria-label={'Download selected'}
                      color={'primary'}
                      variant={'contained'}
                      size={'small'}
                      onClick={exportSelected}
                    >
                      {`Download ${elementsToDownload.length} item${
                        elementsToDownload.length > 1 ? 's' : ''
                      }`}
                    </Button>
                  )}
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={lookUp({ key: 'CONSOLE_UPLOAD' })}>
                  <Fab aria-label="toUpload" color={'primary'} size={'small'} onClick={uploadClick}>
                    <Input
                      id="uploadCatalogJSON"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={importJSON}
                    />
                    <PublishIcon />
                  </Fab>
                </Tooltip>
              </Grid>
              <Grid item className={clsx(classes.margin, classes.addButton)}>
                <Tooltip title={`Edit ${parentName}`}>
                  <Link
                    to={{ pathname: `/apps/catalogs/${id}/edit` }}
                    component={RouterLink}
                  >
                    <Fab
                      aria-label={`Edit ${parentName}`}
                      color="primary"
                      size="small"
                      onClick={(event) => {
                        const item = {
                          id,
                          name: parentName,
                          kind: parentData.kind,
                        };
                        addCrumbLink(item, 'edit');
                      }}
                    >
                      <EditIcon />
                    </Fab>
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item className={clsx(classes.margin, classes.addButton)}>
                <Tooltip title={`Create Catalog`}>
                  <Link to={{ pathname: '/apps/catalogs/create' }} component={RouterLink}>
                    <Fab
                      aria-label="Create catalog"
                      color="primary"
                      size="small"
                      onClick={(event) => addCrumbLink('/apps/catalogs/create', 'new')}
                    >
                      <AddIcon />
                    </Fab>
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
          </Toolbar>
        </>
      )}
      {!id && (
        <>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.smallColumn} align="left" padding="normal">
                  {lookUp({ key: 'CONSOLE_TYPE' })}
                </TableCell>

                <TableCell padding="normal">{lookUp({ key: 'CONSOLE_NAME' })}</TableCell>
                <TableCell className={classes.smallColumn} padding="normal" align="center">
                  {lookUp({ key: 'CONSOLE_CREATED_ON' })}
                </TableCell>
                <TableCell style={{ width: iconsCell.width }} padding="normal"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibilitiesGroups.map((item, index) => (
                <TableRow key={item.id}>
                  {catalogCategoryBody.map((element) => (
                    <TableCell
                      align={element.align || 'right'}
                      style={element.style}
                      key={`${element.name}_${index}`}
                      component={element.name === 'name' ? 'th' : ''}
                      scope={element.name === 'name' ? 'row' : ''}
                    >
                      {element.render ? element.render(item) : item[element.name].toISOString()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      {id && (
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <TableHead>
            <TableRow>
              {checkboxVisible && <TableCell padding="checkbox"></TableCell>}
              <TableCell className={classes.smallColumn} align="left" padding="normal">
                {lookUp({ key: 'CONSOLE_TYPE' })}
              </TableCell>
              <TableCell className={classes.smallColumn} padding="normal"></TableCell>
              <TableCell padding="normal">{lookUp({ key: 'CONSOLE_NAME' })}</TableCell>
              <TableCell className={classes.smallColumn} padding="normal" align="center">
                {lookUp({ key: 'CONSOLE_CREATED_ON' })}
              </TableCell>
              <TableCell className={classes.actions} padding="normal"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody component={DroppableComponent(onDragEnd)}>
            {data &&
              data.map((item, arrInd) => (
                <TableRow
                  component={DraggableComponent(item, arrInd)}
                  hover
                  key={item.id || arrInd}
                >
                  {checkboxVisible && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        id={item.id}
                        checked={!!elementsToDownload.filter((e) => e.id === item.id).length}
                        onClick={(event) => handleCheckbox(item, event.target.checked)}
                      />
                    </TableCell>
                  )}
                  {tableBody.map((element) => (
                    <TableCell
                      align={element.align || 'right'}
                      style={element.style}
                      key={element.name}
                      component={element.name === 'name' ? 'th' : ''}
                      scope={element.name === 'name' ? 'row' : ''}
                    >
                      {element.render ? element.render(item) : item[element.name].toISOString()}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

Catalogs.propTypes = {
  enqueueSnackbar: func.isRequired,
  setParentName: func,
  parentName: string,
};


export default withSnackbar(Catalogs);
