import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { lookUp } from 'services/stringService';
import { string, arrayOf, func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  makeStyles,
  useScrollTrigger,
  Button,
  Grid,
  Slide,
} from '@material-ui/core';
import { Loader } from 'components';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import ContentSelector from 'components/ContentSelector';
import CustomerSelector from 'components/CustomerSelector';
import ContentCardsContainer from './ContentCardsContainer';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  searchSidebar: {
    position: 'fixed',
    top: props => props.header ? 130 : 0,
    zIndex: 9,
    right: 0,
    width: 320,
    backgroundColor: theme.palette.info.main,
    height: '100%',
  },
}));

function ManualCatalogContent(props) {
  const { 
    model,
    setNewCollection,
    enqueueSnackbar,
  } = props;

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchBarOn, setIsSearchBarOn] = useState(false);

  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const trigger = useScrollTrigger();
  const classes = useStyles({ collapsed: isSidebarCollapsed, header: !trigger });

  const onDelete = (item) => {
    const itemIndex = items.findIndex((itm) => itm.id === item.id);
    items.splice(itemIndex, 1);
    setItems([...items]);
    const newCollection = items.map(item => {
      return {
        contentId: item.id || item.contentId || item.customerId,
        properties: item.properties
      };
    });
    setNewCollection(newCollection);
  };

  useEffect(() => {
    const getAndSetItems = async () => {
      setIsLoading(true);
      return Promise.allSettled(model.contentCollection
        .map((content) => {
          return model.contentType === 'Content'
            ? contentService.getById(content.contentId)
            : customersService.getCustomer(content.contentId)
        }))
        .then((contColl) => {
          setItems(contColl
            .filter((result) => result.status === 'fulfilled')
            .map((result) => {
              const img = result?.value?.assets?.find((ast) => (
                ast.subType === 'Landscape' ||
                ast.subType === 'Square' ||
                (ast.type === 'Image' && ast.subType === 'Original')
              ));
              return ({
                ...result.value,
                source: model.contentType,
                imageUrl: img ? img.publicUrl : ''
              });
            })
          );
        })
        .catch((error) => {
          const errorKeyMap = {
            Artist: 'CONSOLE_ARTIST_DATA',
            Content: 'CONSOLE_CONTENT_DATA',
            Festival: 'CONSOLE_EVENT_DATA', 
            Label: 'CONSOLE_ORGANIZATION',
            Partner: 'CONSOLE_SPONSOR', 
          };

          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: errorKeyMap[model.contentType] }),
            })
          });
        })
        .finally(() => setIsLoading(false));
    };

    getAndSetItems();
  }, []);

  const getListStyle = (isDraggingOver) => ({
    height: '100%'
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (
      source.droppableId === 'dragDropContainer' &&
      destination.droppableId === 'dragDropContainer'
    ) {
      setItems((items) => {
        const startInd = source.index;
        const endInd = destination.index;
        [items[endInd], items[startInd]] = [items[startInd], items[endInd]];
        return [...items]
      });
    }
    return;
  };

  return isLoading ? (
    <Loader />
  ) : (
      <>            
        <Grid container alignItems={'center'}>
          <Grid item xs={2}>
            <Button color="primary" onClick={(event) => {
              setIsSearchBarOn(true);
            }}>{lookUp({ key: 'CONSOLE_SEARCH_BUTTON' })}</Button>
          </Grid>
        </Grid>

        <DragDropContext onDragEnd={onDragEnd}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Droppable droppableId="dragDropContainer" style={{ overflow: 'auto'}}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    <ContentCardsContainer
                      title={model.contentType}
                      items={items}
                      handlePropertiesChange={(itemId, properties) => {
                        items.find(i => i.id === itemId || i.contentId === itemId || i.customerId === itemId).properties = properties;
                        const newCollection = items.map(item =>
                        { 
                          return {
                            contentId: item.id || item.contentId || item.customerId,
                            properties: item.properties
                          }
                        })
                        setNewCollection(newCollection);
                        AlertService.displaySuccess({
                          msgBar: enqueueSnackbar,
                          message: lookUp({ key: 'CONSOLE_DECORATION_ELEMENTS_SAVE_MESSAGE' })
                        });
                      }}
                      onDragStyle={getListStyle(snapshot.isDraggingOver)}
                      onDelete={onDelete}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </DragDropContext>

        <Slide appear={false} direction="left" in={isSearchBarOn}>
          <div className={classes.searchSidebar}>
            {model.contentType === 'Content' ? (
              <ContentSelector
                selectedRelation={model.targetType}
                targetType={model.contentType}
                onClose={() => setIsSearchBarOn(false)}
                onSelect={(id) => {
                  const isAlreadyThere = items.find((item) => item.id === id);
                  if (!isAlreadyThere) {
                    contentService.getById(id)
                      .then((item) => {
                         const img = item?.assets?.find((ast) => (
                           ast.subType === 'Landscape' ||
                           ast.subType === 'Square' ||
                           (ast.type === 'Image' && ast.subType === 'Original')
                         ));
                         item.source = model.contentType;
                         item.imageUrl =  img ? img.publicUrl : '';
                         const newItems = [...items, item];
                         setItems(newItems);
                         const newCollection = {
                            contentId: item.id || item.contentId || item.customerId,
                            properties: item.properties
                         };
                         setNewCollection([...model.contentCollection, newCollection]);
                      })
                      .catch(console.error);
                  }
                }}
              /> 
            ) : (
              <CustomerSelector
                selectedRelation={model.targetType}
                targetType={model.contentType}
                onClose={() => setIsSearchBarOn(false)}
                onSelect={(id) => {
                  const isAlreadyThere = items.find((item) => item.id === id);
                  if (!isAlreadyThere) {
                    customersService.getCustomer(id)
                      .then((item) => {
                         const img = item?.assets?.find((ast) => (
                           ast.subType === 'Landscape' ||
                           ast.subType === 'Square' ||
                           (ast.type === 'Image' && ast.subType === 'Original')
                         ));
                         item.source = model.contentType;
                         item.imageUrl =  img ? img.publicUrl : '';
                         const newItems = [...items, item];
                         setItems(newItems);
                         const newCollection = {
                            contentId: item.id || item.contentId || item.customerId,
                            properties: item.properties
                         };
                         setNewCollection([...model.contentCollection, newCollection]);
                      })
                      .catch(console.error);
                  }
                }}
              />
            )}
          </div>
        </Slide>
      </>
    );
};

ManualCatalogContent.propTypes = {
  data: arrayOf(string).isRequired,
  enqueueSnackbar: func
};

export default withSnackbar(ManualCatalogContent);
