import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSearchParams } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import DataGridOverlayNoResults from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';

const Lister = ({
  columns,
  rows,
  totalCount,
  loading,
  toolbar,
  dataGridProps,
  selectionModel,
  setSelectionModel,
  sortKey = 'sortExpr',
}) => {
  const toSortObj = sortExpr => {
    if (!sortExpr) return [];
    const obj= {field: sortExpr.split(' ')?.[0], sort: sortExpr.split(' ')?.[1]?.toLowerCase()}
    return [ obj ];
  };

  const firstRun = useRef(true);
  const [search, setSearch] = useSearchParams();
  const [page, setPage] = useState(+search.get('page') || 0);
  const [pageSize, setPageSize] = useState(+search.get('pageSize') || 25);
  const sortExpr = search.get(sortKey);
  const [sortModel, setSortModel] = useState(toSortObj(sortExpr));

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined) delete searchObj[key];
    setSearch(searchObj);
  };

  const changeSortModel = newModel => {
    const expr = newModel[0];
    if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
      if (!expr) setParam(sortKey, undefined);
      if (!!expr?.field) setParam(sortKey, `${expr.field} ${expr.sort}`);
      setSortModel(newModel);
    }
  };

  useEffect(() => {
    if (!firstRun.current) {
      setParam('page', page)
    };
  }, [page]);

  useEffect(() => {
    if (!firstRun.current) {
      setParam('pageSize', pageSize);
      setPage(0);
    } else {
      firstRun.current = false;
    };
  }, [pageSize]);

  return (
    <DataGrid 
      loading={loading}
      rowCount={totalCount}
      columns={columns}
      rows={rows.filter((row) => !!row.id)}
      components={{
        Toolbar: toolbar,
        LoadingOverlay: DataGridOverlayLoaderLinear,
        NoRowsOverlay: DataGridOverlayNoResults,
        NoResultsOverlay: DataGridOverlayNoResults,
      }}
      autoHeight
      autoPageSize
      disableColumnMenu
      disableSelectionOnClick
      hideFooterSelectedRowCount
      checkboxSelection={!!selectionModel}
      selectionModel={selectionModel}
      onSelectionModelChange={setSelectionModel}
      pagination
      paginationMode="server"
      page={page}
      pageSize={pageSize}
      onPageChange={newPage => {
        setPage(newPage);
      }}
      onPageSizeChange={newPage => {
        setPageSize(newPage);
      }}
      sortingMode="server"
      sortModel={sortModel}
      onSortModelChange={changeSortModel}
      rowsPerPageOptions={[10, 25, 50, 100]}
      componentsProps={{
        noRowsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
        noResultsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
        pagination: {
          classes: null,
          labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
          labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
        },
      }}
      {...dataGridProps}
    />
  );
};

Lister.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  toolbar: PropTypes.node,
  dataGridProps: PropTypes.object,
  selectionModel: PropTypes.array,
  setSelectionModel: PropTypes.func,
  sortKey: PropTypes.string,
};

export default Lister;
