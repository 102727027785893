import { useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserMenu from './userMenu';
import HideOnScroll from 'components/HideOnScroll';
import Breadcrumbs from 'components/Breadcrumbs';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.background.paper,
  },
  headerContainer: {
    zIndex: theme.zIndex.appBar,
  },
  userMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  offset: theme.mixins.toolbar,
}));

const Header = () => {
  const isFullWidth = useSelector((state) => state.navigation.isSidebarCollapsed);
  const classes = useStyles({ isFullWidth });

  return (
    <>
      <HideOnScroll>
        <AppBar position="sticky" className={classNames(classes.appBar, classes.headerContainer)}>
          <Toolbar>
            <Box edge="start">
              <Breadcrumbs />
            </Box>
            <Box flex={1} />
            <Box edge="end" className={classes.userMenuContainer}>
              <UserMenu />
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <div className={classes.offset} />
    </>
  );
};

export default Header;
