import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getLibraryEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('LibraryEnums');
  if (!endpoint) throw Error('No LibraryEnums endpoint.');
  return endpoint;
};

const getActionGroups = () => axios.get(`${getLibraryEnumsEndpoint()}/ActionGroups`)
  .then((res) => res?.data);

const getActionTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/ActionTypes`)
  .then((res) => res?.data);

const getCatalogKinds = () => axios.get(`${getLibraryEnumsEndpoint()}/CatalogKinds`)
  .then((res) => res?.data);

const getCatalogTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/CatalogTypes`)
  .then((res) => res?.data);

const getConditionNames = () => axios.get(`${getLibraryEnumsEndpoint()}/ConditionNames`)
  .then((res) => res?.data);

const getDataGroupTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/DataGroupTypes`)
  .then((res) => res?.data);

const getFeedTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/FeedTypes`)
  .then((res) => res?.data);

const getHttpMethods = () => axios.get(`${getLibraryEnumsEndpoint()}/HttpMethods`)
  .then((res) => res?.data);

const getPluginTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/PluginTypes`)
  .then((res) => res?.data);

const getSortDirections = () => axios.get(`${getLibraryEnumsEndpoint()}/SortDirections`)
  .then((res) => res?.data);

const getStringMatching = () => axios.get(`${getLibraryEnumsEndpoint()}/StringMatching`)
  .then((res) => res?.data);

const getValueFormats = () => axios.get(`${getLibraryEnumsEndpoint()}/ValueFormats`)
  .then((res) => res?.data);

const getVisibilityGroups = () => axios.get(`${getLibraryEnumsEndpoint()}/VisibilityGroups`)
  .then((res) => res?.data);

const getScheduleTypes = () => axios.get(`${getLibraryEnumsEndpoint()}/ScheduleTypes`)
  .then((res) => res?.data);

const getRefreshPeriod = () => axios.get(`${getLibraryEnumsEndpoint()}/RefreshPeriods`)
  .then((res) => res?.data);

export {
  getScheduleTypes,
  getActionTypes,
  getHttpMethods,
  getCatalogKinds,
  getCatalogTypes,
  getConditionNames,
  getDataGroupTypes,
  getFeedTypes,
  getPluginTypes,
  getSortDirections,
  getStringMatching,
  getValueFormats,
  getVisibilityGroups,
  getActionGroups,
  getRefreshPeriod
};

export default {
  getScheduleTypes,
  getActionTypes,
  getHttpMethods,
  getCatalogKinds,
  getCatalogTypes,
  getConditionNames,
  getDataGroupTypes,
  getFeedTypes,
  getPluginTypes,
  getSortDirections,
  getStringMatching,
  getValueFormats,
  getVisibilityGroups,
  getActionGroups,
  getRefreshPeriod
};
