import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getBrandsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Brands');
  if (!endpoint) throw Error('No Brands endpoint.');
  return endpoint;
};

const getAwsRegions = () =>
  axios.get(`${getBrandsEndpoint().slice(0, -6)}/Enums/Awsregions`) // /'Brands' is not needed in the URL in this case
    .then(res => res?.data);

const getById = (brandId) => axios.get(`${getBrandsEndpoint()}/${brandId}`)
  .then((res) => res?.data);

  const searchQuery = (search, pageSize = 25, pageNumber = 0) => {
    search.delete('page');
    search.delete('pageSize');
    return axios
    .get(`${getBrandsEndpoint()}/Search/${pageSize}/${pageNumber}?${search}`)
    .then(res => res.data);
  };

const search = (
  { page = 0, perPage = 10, q = '', sortExpr = 'name desc,published desc,', d = false, ...rest } = {}
) => {
  return axios
    .get(
      makeApiWithQuery(`${getBrandsEndpoint()}/Search/${perPage}/${page}`, {
        sortExpr,
        q,
        d,
        ...rest
      })
    )
    .then((r) => r?.data);
}

const getAll = ({ page = 0, perPage = 1000, query = '', sortBy = 'name', order = 'asc' } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getBrandsEndpoint()}/Search/${perPage}/${page}`, {
        q: query,
        sortExpr: `${sortBy || 'name'} ${order || 'asc'}`
      })
    )
    .then((res) => res?.data);

const getPublishedBrands = ({ page = 0, perPage = 10, q = '', sortBy = 'name', p = true, order = 'asc', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getBrandsEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        p,
        q,
        ...rest
      })
    )
    .then((r) => r?.data);

const create = async (model) => axios.post(`${getBrandsEndpoint()}`, model)
  .then((res) => res?.data);

const verify = (projectName) =>
  axios
    .get(`${getBrandsEndpoint()}/Verify/${projectName}`)
    .then((res) => res?.data)

const publish = (brandId) => axios.post(`${getBrandsEndpoint()}/Publish/${brandId}`)
  .then((res) => res?.data);

const edit = (body) => axios.put(`${getBrandsEndpoint()}/${body.id}`, body)
  .then((res) => res?.data)

const destroy = (id) => axios.delete(`${getBrandsEndpoint()}/${id}`);

const initialize = (id, awsRegion) => axios.post(`${getBrandsEndpoint()}/Initialize/${id}`, { awsRegion: awsRegion })

export { search, getById, getAll, publish, create, edit, destroy, verify, initialize, getPublishedBrands, getAwsRegions };
export default { search, searchQuery, getById, publish, getAll, create, edit, destroy, verify, initialize, getPublishedBrands, getAwsRegions };
