import React from 'react';
import { lookUp } from 'services/stringService';
import { Button, Typography } from '@material-ui/core';
import { CenteredBlock } from 'components';

const NetworkError = () => (
  <CenteredBlock>
    <Typography variant={'h1'} gutterBottom>
      {lookUp({ key: 'CONSOLE_Network_Error' })}
    </Typography>
    <Typography variant={'h5'} color={'textSecondary'} gutterBottom>
      {lookUp({ key: 'CONSOLE_Connection_interrupted_TEXT' })}
    </Typography>
    <Typography variant={'h6'} color={'textSecondary'} gutterBottom>
      {lookUp({ key: 'CONSOLE_Check_network_text' })}
    </Typography>
    <Button
      variant={'contained'}
      color={'primary'}
      onClick={() => window.location.reload()}
      style={{ marginTop: 24 }}
    >
      {lookUp({ key: 'CONSOLE_Reload_BUTTON' })}
    </Button>
  </CenteredBlock>
);

export default NetworkError;
