import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, func, object, bool } from 'prop-types';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { Draggable } from 'react-beautiful-dnd';
import { CenteredBlock, Loader } from 'components';
import ContentCard from './ContentCard';
import ArtistCard from './ArtistCard';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    height: '100%'
  },
  overflow: {
    maxHeight: `calc(70vh - ${theme.spacing(8)}px)`,
    overflowY: 'auto'
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  border: isDragging ? '2px solid #00acc1' : '',
  borderRadius: isDragging ? '6px' : '',
  background: isDragging ? '#ffffff' : '',
  ...draggableStyle
});

function ContentCardsContainer(props) {
  const classes = useStyles();
  const {
    items,
    title,
    onDelete,
    onAdd,
    onDragStyle,
    isContentOptionsLoading,
    decorationModal,
    handleChange,
    handlePropertiesChange,
  } = props;

  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (!items.length) return;
    setContents(items);
  }, [items])

  return (
    <Paper className={classes.paper} style={onDragStyle}>
      <Typography variant={'subtitle1'} gutterBottom>
        {title}
      </Typography>
      {contents?.length === 0 ? (
        <CenteredBlock height={'80vh'}>
          {isContentOptionsLoading ? (
            <Loader inline />
          ) : (
            <>
              <Typography variant={'subtitle1'}>{lookUp({ key: 'CONSOLE_NO_CONTENT'})}</Typography>
              <Typography variant={'caption'}>{lookUp({ key: 'CONSOLE_ADD_CONTENT_HELPERTEXT'})}</Typography>
            </>
          )}
        </CenteredBlock>
      ) : (
        <Grid
          container
          direction={'column'}
          wrap={'nowrap'}
          spacing={2}
          className={classes.overflow}
        >
          {isContentOptionsLoading ? (
            <Loader height={'80vh'} />
          ) : (
            Array.isArray(contents) && contents.length && contents?.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <Grid
                    item
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    {item.source === 'Content' ? (
                      <ContentCard
                        item={item}                 
                        handleChange={handleChange} 
                        handlePropertiesChange={handlePropertiesChange}     
                        {...(!!onAdd && { onAdd })}
                        {...(!!onDelete && { onDelete })}
                        {...(!!decorationModal && { decorationModal })}
                      />
                    ) : (
                      <ArtistCard
                        item={item}
                        handleChange={handleChange} 
                        handlePropertiesChange={handlePropertiesChange}   
                        {...(!!onAdd && { onAdd })}
                        {...(!!onDelete && { onDelete })}
                        {...(!!decorationModal && { decorationModal })}
                      />
                    )}
                  </Grid>
                )}
              </Draggable>
            ))
          )}
        </Grid>
      )}
    </Paper>
  );
};

ContentCardsContainer.propTypes = {
  title: string,
  onDelete: func,
  onDragStyle: object,
  onAdd: func,
  items: arrayOf(shape({})),
  isContentOptionsLoading: bool
};

export default ContentCardsContainer;
