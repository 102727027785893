import {
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS,
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_SONGS,
  SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_ARTISTS,
  SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_PLAYLISTS,
  SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS,
  SET_USER_LOGS,
  SET_USER_LOGS_LOADING_STATUS
} from '../actions/insightsAction';

const initialState = {
  playoutPlaybacks: [],
  isPlayoutPlaybacksLoading: false,
  topSongs: [],
  isTopSongsLoading: false,
  topArtists: [],
  isTopArtistsLoading: false,
  topPlaylists: [],
  isTopPlaylistsLoading: false,
  userLogs: [],
  isUserLogsLoading: false
};

const insightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSUMPTION_PLAYOUT_PLAYBACKS:
      return { ...state, playoutPlaybacks: action.consumption };
    case SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS:
      return { ...state, isPlayoutPlaybacksLoading: action.status };

    case SET_CONSUMPTION_TOP_SONGS:
      return { ...state, topSongs: action.songs };
    case SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS:
      return { ...state, isTopSongsLoading: action.status };

    case SET_CONSUMPTION_TOP_ARTISTS:
      return { ...state, topArtists: action.artists };
    case SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS:
      return { ...state, isTopArtistsLoading: action.status };

    case SET_CONSUMPTION_TOP_PLAYLISTS:
      return { ...state, topPlaylists: action.playlists };
    case SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS:
      return { ...state, isTopPlaylistsLoading: action.status };

    case SET_USER_LOGS:
      return { ...state, userLogs: action.userLogs };
    case SET_USER_LOGS_LOADING_STATUS:
      return { ...state, isUserLogsLoading: action.status };

    default:
      return state;
  }
};

export default insightsReducer;
