import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  makeStyles,
  Avatar,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  IconButton,
} from '@material-ui/core';
import { Adjust, ErrorOutline, AddPhotoAlternate, Close } from '@material-ui/icons';
import { Loader, AutoTabbing, FormFooter, EditorInfo, ImageCropUpload } from 'components';
import { get, create, update, remove, getRegions } from 'services/countryService';
import navigationAction from 'store/actions/navigationAction';
import { goBackOrStay } from 'helpers/common';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  marginX: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginL: {
    marginLeft: '1px',
    marginBottom: '3px',
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const CountriesForm = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { code, tab } = useParams();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.countries;

  const modelDefinition = {
    code: '', // text; ID (ISO 3166-1 alpha-2) cannot be modified,
    name: '', // text; display name of the country -- required,
    regions: [''], // containing regions (eg. EU, YU) -- optional,
    enabled: false, // whether this country is accessible -- optional,
    categories: [
      {
        code: '', // code of the category (usually the text in the symbol) -- required,
        name: '', // display name of the category (the one customers see) -- required,
        description: '', // text; detailed description of the category -- optional,
        iconUrl: '', // text; category symbol image url -- optional,
        minAge: 0, // number; rating range number -- required
      },
    ],
    advisories: [
      {
        code: '', // text; advisory code -- required,
        name: '', // text; display name (e.g., harsh language) -- required,
        iconUrl: '', // text; avisory symbol image url -- optional,
      },
    ],
  };

  const [model, setModel] = useState(modelDefinition);
  const [regions, setRegions] = useState([]);

  const [iconModalOpen, setIconModalOpen] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const pageTitle = code
      ? lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: lookUp({ key: `CONSOLE_COUNTRY_${code}` }) })
      : lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_COUNTRY` }) });
    dispatch(navigationAction.setPageTitle(pageTitle));
    getRegions().then((regions) => setRegions(Object.keys(regions)));
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  const handleIconUrlChange = async (url) => {
    const copyModel = [...model[iconModalOpen]];
    copyModel[rowId] = { ...copyModel[rowId], iconUrl: url };
    handleModelChange(iconModalOpen, copyModel);
  };

  // You can override AutoTabbing's automated layout by the object below.

  // Give label: to display to any property instead of its key. Set hidden: true to hide.
  // Arrays of strings (see for example "regions") get cast into a multi-choice Autocomplete component with Chips.
  // You can give options: an initial set of values to pick from. freeSolo: true makes it extendable.
  // Arrays of objects (see for example "categories") get transformed into an editable DataGrid on a separate Tab.
  // Give "props: {}" there as you would to a DataGrid column definition.

  const customFields = {
    regions: {
      break: true,
      label: lookUp({ key: 'CONSOLE_REGIONS' }),
      options: regions,
      props: { freeSolo: true },
    }, // Has a two-column layout on larger screens by default: break: true makes a line break.
    enabled: { hidden: true },
    code: {
      label: lookUp({ key: 'CONSOLE_COUNTRY_CODE' }),
      props: {
        fullWidth: false,
        style: { width: 50 },
        disabled: !!code,
        inputProps: { maxlength: 2 },
      },
    }, // Give props as you would to a TextField component.
    name: {
      break: true,
      label: lookUp({ key: 'CONSOLE_NAME' }),
      props: { helperText: lookUp({ key: 'CONSOLE_COUNTRY_NAME_HELPERTEXT' }) },
    },
    categories: {
      tabIcon: <Adjust />,
      name: 'ratings',
      label: lookUp({ key: 'CONSOLE_RATINGS' }),
      props: {
        code: {
          width: 40,
          headerName: lookUp({ key: 'CONSOLE_ID' }),
          sortable: false,
        },
        iconUrl: {
          headerName: lookUp({ key: 'CONSOLE_ICON' }),
          width: 40,
          sortable: false,
          renderCell: (item) =>
            item.value ? (
              <Avatar
                variant="square"
                src={item.value}
                onClick={() => {
                  setRowId(item.row.id);
                  setImageUrl(item.value);
                  setIconModalOpen('categories');
                }}
              />
            ) : (
              <Avatar
                variant="square"
                onClick={() => {
                  setRowId(item.row.id);
                  setImageUrl(item.value);
                  setIconModalOpen('categories');
                }}
              >
                <AddPhotoAlternate />
              </Avatar>
            ),
        },
        name: { flex: 2, headerName: lookUp({ key: 'CONSOLE_NAME' }) },
        description: { flex: 8, headerName: lookUp({ key: 'CONSOLE_DESCRIPTION' }) },
        minAge: { headerName: lookUp({ key: 'CONSOLE_AGE' }), width: 70, sortable: false },
      },
    },
    advisories: {
      tabIcon: <ErrorOutline />,
      props: {
        name: { flex: true, headerName: lookUp({ key: 'CONSOLE_NAME' }) },
        code: { headerName: lookUp({ key: 'CONSOLE_CODE' }) },
        iconUrl: {
          headerName: lookUp({ key: 'CONSOLE_ICON' }),
          width: 40,
          sortable: false,
          renderCell: (item) =>
            item.value ? (
              <Avatar
                variant="square"
                src={item.value}
                onClick={() => {
                  setRowId(item.row.id);
                  setImageUrl(item.value);
                  setIconModalOpen('advisories');
                }}
              />
            ) : (
              <Avatar
                variant="square"
                onClick={() => {
                  setRowId(item.row.id);
                  setImageUrl(item.value);
                  setIconModalOpen('advisories');
                }}
              >
                <AddPhotoAlternate />
              </Avatar>
            ),
        },
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const propertiesNotToDisplay = ['ownerId', 'createdDate', 'lastModifiedDate', 'lastModifiedBy'];

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await get(code);
        setModel({
          ...modelDefinition,
          ...Object.keys(data)
            .filter((key) => {
              return (
                (Array.isArray(data[key]) ? !!data[key][0] : !!data[key]) &&
                !propertiesNotToDisplay.includes(key)
              );
            })
            .reduce((formData, key) => {
              formData[key] =
                Array.isArray(data[key]) &&
                data[key][0] &&
                typeof data[key][0] === 'object' &&
                !Array.isArray(data[key][0])
                  ? [...modelDefinition[key], ...data[key]]
                  : data[key];
              return formData;
            }, {}),
        });
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_COUNTRY' }),
          }),
        });
        setIsLoading(false);
      }
    };
    code && getData();
  }, [code]);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    let saveModel = { ...model, enabled: publish ? !model.enabled : model.enabled };

    // For display reasons keys with no values (incl. objects on arrays), are on the model,
    // let's pluck these first.

    saveModel.regions = saveModel.regions.filter((e) => !!e);
    saveModel.categories = saveModel.categories.slice(1, saveModel.categories.length);
    saveModel.advisories = saveModel.advisories.slice(1, saveModel.advisories.length);

    try {
      code ? await update(code, saveModel) : await create(model.code, saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : code
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      goBackOrStay(navigate, '/configuration/countries');
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
      setIsSaving(false);
      setIsPublishing(false);
    }
  };

  const deleteData = async () => {
    setIsDeleting(true);
    try {
      await remove(code);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/configuration/countries'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            code && (
              <ThemedButton
                color={model.enabled ? 'secondary' : 'success'}
                disabled={
                  isPublishing ||
                  isSaving
                }
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({
                  key: model.enabled ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON',
                })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            code && <DeleteButton onDelete={deleteData} loading={isDeleting} disabled={isDeleting} />
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/configuration/countries')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: code ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>
        <AutoTabbing
          model={model}
          handleChange={handleModelChange}
          tab={tab}
          allowedTabs={allowedTabs}
          customFields={customFields}
        />
      </Box>

      <Dialog open={iconModalOpen} fullWidth maxWidth={'md'} onClose={() => setIconModalOpen(null)}>
        <div className={classes.xButtonWrapper}>
          <Typography className={classes.modalTitle}>
            {lookUp({ key: 'CONSOLE_SELECT_ICON_TITLE' })}
          </Typography>
          <IconButton aria-label="close" onClick={(event) => setIconModalOpen(null)}>
            <Close />
          </IconButton>
        </div>
        <DialogContent>
          <DialogContentText>
            <TextField
              value={imageUrl}
              label={lookUp({ key: 'CONSOLE_URL' })}
              fullWidth
              onChange={(e) => {
                handleIconUrlChange(e.target.value);
                setImageUrl(e.target.value);
              }}
            />
            <ImageCropUpload
              fileUrl={imageUrl || ''}
              handleChange={(url) => {
                handleIconUrlChange(url);
                setImageUrl(url);
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              setIconModalOpen(null);
            }}
          >
            {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
          </Button>
        </DialogActions>
      </Dialog>

      {code && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

export default withSnackbar(CountriesForm);
