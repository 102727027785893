import { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import { green, red } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import jobManagerService from 'services/jobManagerService';
import contentAction from 'store/actions/contentAction';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { lookUp } from 'services/stringService';


const useStyles = makeStyles((theme) => ({
  jobStatusesWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: 0,
    padding: 0,
  },
  done: {
    color: green[900],
    borderRadius: 999,
  },
  notDone: {
    color: red[900],
    borderRadius: 999,
  },
  wfStatusBox: {
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));


function JobStatus({
  order,
  content,
  intervalRefs,
  enqueueSnackbar,
}) {

  const dispatch = useDispatch();

  const [lastWorkFlow, setLastWorkFlow] = useState(null);
  const jobStatusKeys = useSelector((state) => state.content.jobStatusKeys);
  const classes = useStyles();

  const WfStatus = useMemo(() => {
    switch (lastWorkFlow?.workflowStatus?.toUpperCase()) {
      case 'SCHEDULED':
        return (
          <>
            <CircularProgress size={30}/>
            <Typography variant="caption">
              {lookUp({ key: 'CONSOLE_ENUMS_JOBSTATUS_SCHEDULED' })}
            </Typography>
          </>
        );
      case 'RUNNING':
        return (
          <>
            <CircularProgress size={30}/>
            <Typography variant="caption">
              {lookUp({ key: 'CONSOLE_ENUMS_JOBSTATUS_RUNNING' })}
            </Typography>
          </>
        );
      case 'SUCCEED':
        return (
          <>
            <CheckCircleOutlinedIcon fontSize="large" className={classes.done}/>
            <Typography variant="caption">
              {lookUp({ key: 'CONSOLE_ENUMS_JOBSTATUS_SUCCEED' })}
            </Typography>
          </>
        );
      case 'FAILED':
        return (
          <>
            <HighlightOffIcon fontSize="large" className={classes.notDone}/>
            <Typography variant="caption">
              {lookUp({ key: 'CONSOLE_ENUMS_JOBSTATUS_FAILED' })}
            </Typography>
          </>
        );
      case 'NOTRAN':
        return null;
      case 'NONE':
        return null;
      default:
        return null;
    };
  }, [lastWorkFlow]);

  useEffect(() => {
    const checkStoreJobNotifStatuses = () => {
      if (Object.keys(jobStatusKeys).length === 0) {
        return jobManagerService.getJobStatusesEnum()
          .then((jobStatusEnum) => {
            const jobStatusKeys = Object.keys(jobStatusEnum).reduce((jbKeys, key) => {
              jbKeys[key.toUpperCase()] = jobStatusEnum[key];
              return jbKeys;
            }, {})
            dispatch(contentAction.setJobStatusKeys(jobStatusKeys));
            return jobStatusEnum;
          })
      } else {
        return Promise.resolve(jobStatusKeys);
      }
    };

    checkStoreJobNotifStatuses()
      .then(() => {
        return jobManagerService.getLatestWorkflowByContent(content.contentId, '')
          .then((jobs) => {
            if (jobs.pageContent.length) {
              const lastWorkFlow = jobs.pageContent[0];
              if (lastWorkFlow) {
                setLastWorkFlow(lastWorkFlow);

                intervalRefs[order] = window.setInterval(() => {
                  jobManagerService.getLatestWorkflowByContent(content.contentId, '')
                    .then((jobs) => {
                      if (jobs.pageContent.length) {
                        const lastWorkFlow = jobs.pageContent[0];
                        if (lastWorkFlow) setLastWorkFlow(lastWorkFlow);
                      }
                    })
                }, [60000]);
              }
            }
          });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });

    return () => {
      intervalRefs.forEach((iRef) => window.clearInterval(iRef));
    };
  }, []);

  return <>{lastWorkFlow ? <Box className={classes.wfStatusBox}>{WfStatus}</Box> : null}</>;
};

JobStatus.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  content: PropTypes.array,
};

export default withSnackbar(JobStatus);
