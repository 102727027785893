import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import skuService from 'services/skuService';
import alertService from 'services/alertService';
import ControlBox from 'components/ControlBox';
import DropdownWithSearch from 'components/DropdownWithSearch';
import ThemedButton from 'components/ThemedButton';
import get from 'lodash/get';
import noop from 'lodash/noop';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    overscrollBehavior: 'contain',
  },
  results: {
    height: '100%',
  },
  resultRow: {
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  closeButton: {
    display: 'flex',
    alignSelf: 'center',
    color: theme.palette.grey[500],
    marginRight: `-${theme.spacing(.5)}px`,
    marginLeft: theme.spacing(.5),
    zIndex: theme.zIndex.drawer + 2,
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
}));

const preselectType = {
  Merchandizes: 'Merchandise',
};

const DEFAULT_SERVICE_ID = 'content';
const DEFAULT_RELATION = 'content';

const AVAILABLE_TYPES = [
  'keyword',
  'type',
  'owner',
  'platform',
  'genre',
  'category'
]
const DEFAULT_TYPES = ['keyword', 'type', 'genre', 'category'];

// normalize services api based on selectedRelation to allow data source switching
// FIXME - move to service utils for reuse
const serviceByRelationType = {
  content: {
    search: contentService.searchQuery,
    get: contentService.getById,
    types: contentService.getTypeEnums,
  },
  customer: {
    search: customersService.searchQuery,
    get: customersService.getCustomer,
    types: customersService.getCustomerTypes,
  },
  sku: {
    search: skuService.search,
    get: skuService.get,
    types: skuService.getProductTypes,
  },
};
const baseUrlByRelationType = {
  content: ['content'],
  customer: ['access', 'customer'],
  sku: ['monetization', 'skus'],
};
const itemPropsMappingByRelation = {
  content: {
    name: 'originalTitle',
    id: 'id',
    image: '',
  },
  customer: {
    name: 'username',
    id: 'customerId',
    image: '',
  },
  sku: {
    name: 'name',
    id: 'id',
    image: '',
  },
};

const ContentSelector = ({
  enqueueSnackbar,
  onSelect = noop,
  onClose = noop,
  selectedRelationTargetType = 'Content',
  selectedRelation = 'Content',
  targetType = 'Content',
  enabledTypes = DEFAULT_TYPES,
}) => {
  const classes = useStyles();

  // initial parameters
  const [q, setQ] = useState('');
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [ownerId, setOwnerId] = useState('');
  const [genre, setGenre] = useState('');
  const [category, setCategory] = useState('');
  const [platforms, setPlatforms] = useState([]);
  const [platform, setPlatform] = useState();
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [service, setService] = useState({});
  const [relationMatch, setRelationMatch] = useState(DEFAULT_RELATION);

  useEffect(() => {
    if (selectedRelation) {
      console.log(enabledTypes);
      setService(getServiceByRelationType(selectedRelation))
      switch(selectedRelation.toLowerCase()) {
        case 'content':
          enabledTypes.push('owner'); 
        break;
        case 'sku':
          enabledTypes.push('platform'); 
        break;
      }
    };
  }, [selectedRelation]);

  useEffect(() => {
    const relationTarget = determineRelationTarget([selectedRelation, selectedRelationTargetType, targetType]);
    if (relationTarget) setRelationMatch(relationTarget);
  }, [selectedRelation, selectedRelationTargetType, targetType]);

  useEffect(() => {
    if (enabledTypes.includes('platform')) {
      const getPlatforms = async () => {
        try {
          const platformTypes = await skuService.getStoreTypes();
          setPlatforms(platformTypes);
        } catch (error) {
          alertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_PLATFORM'}) })
          });
        }        
      };

      getPlatforms();
    }
  }, [enabledTypes]);

  const getPlatformLabel = (platform = '') => {
    switch (platform) {
      case 'Apple':
        return lookUp({ key: 'CONSOLE_APPLE_ITUNES' });
      case 'Google':
        return lookUp({ key: 'CONSOLE_GOOGLE_PLAY_STORE' });
      default:
        return platform;
    };
  };

  // attempts to determine a relation target based on selectedRelation, if none found use the default one
  // TODO move into data sources utils
  const determineRelationTarget = (targets = []) => {
    const normalizedAvailableRelations = [
      ...targets,
      DEFAULT_RELATION,
    ]
      .filter((target) => target)
      .map((target) => target.toLowerCase?.());

    return normalizedAvailableRelations.find((relation) => `${relation}` in itemPropsMappingByRelation);
  };

  // get item id based on relationMatch
  const getItemId = (item, relation = relationMatch) => {
    return get(item, [itemPropsMappingByRelation[relation]?.id]) || baseUrlByRelationType[DEFAULT_RELATION]?.id;
  };

  // get item edit url based on relationMatch
  const getItemEditLink = (item, relation = relationMatch) => {
    const editPath = baseUrlByRelationType[relation] || baseUrlByRelationType[DEFAULT_RELATION];
    return `/${editPath.join?.('/')}/${getItemId(item)}/edit`;
  };

  // get item title based on relationMatch
  const getItemTitle = (item, relation = relationMatch) => {
    return get(item, itemPropsMappingByRelation[relation]?.name) || baseUrlByRelationType[DEFAULT_RELATION]?.name;
  };

  /**
   * Selects the service to use based on the selectedRelation
   * FIXME - make utils for reuse
   * @param {string} relation one of [Content, Customer, Sku]
   * returns object a service definition
   */
  const getServiceByRelationType = (relation = selectedRelation) => {
    const normalizedRelation = relation.toLowerCase?.();
    let targetService = serviceByRelationType[normalizedRelation];

    if (!targetService || typeof targetService?.search !== 'function') {
      targetService = serviceByRelationType[DEFAULT_SERVICE_ID];
    }

    return targetService;
  };

  const getContentOptions = async () => {
    let formatQuery = '';
    if (genre.length > 0) {
      formatQuery = `genre=${genre}`
    }
    if (category.length > 0) {
      formatQuery = `${formatQuery}&category=${category}&`
    }
    if (q.length > 0) {
      formatQuery = `${formatQuery}&keyword=${q}`
    }
    setResultsLoading(true);
    const searchParams = {
      q: formatQuery,
      ...(type && { type }),
      ...(platform && { platform }),
      ...(ownerId && { ownerId }),
    };

    try {

      const { data } = await service.search(
        searchParams,
        100,
        0,
      );
      
      setResults(data);
    } catch (error) {
      alertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CONTENT_SEARCH_RESULTS' }),
        }),
      });
    } finally {
      setResultsLoading(false);
      setHasSearched(true);
    }
  };

  const handleSearch = async () => {
    setSearchTimeout((timeout) => clearTimeout(searchTimeout));
    const timeout = setTimeout(async () => {
      await getContentOptions();
    }, 500);
    setSearchTimeout(timeout);
  };

  const handleAddItem = item => {
    onSelect(getItemId(item), selectedRelation, selectedRelationTargetType, item);
  };

  useEffect(() => {
    service.types?.()
      .then((serviceTypes = []) => {
        const types = serviceTypes.map?.(type => {
          switch (typeof type) {
            case 'object':
              let label = '-';

              if (!Array.isArray(type)) {
                let [_label] = Object.keys(type);
                label = _label;
              }

              return label;
            case 'string':
            default:
              return type;
          }
        });

        setTypes(types.sort?.((a, b) => a < b ? -1 : 1));
      });
  }, [service]);

  useEffect(() => {
    if (preselectType[selectedRelation] || types.includes(selectedRelation)) {
      setType(preselectType[selectedRelation] || selectedRelation);
    } else { setType('')};
  }, [types, selectedRelation]);

  // FIXME cleanup
  const handleClose = () => {
    setResults([]);
    setHasSearched(false);
    onClose();
  };

  const isTypeSelectorEnabled = (type) => enabledTypes.includes?.(type);

  return (
    <Grid
      className={classes.root}
      spacing={2}
      alignContent="flex-start"
      justifyContent="flex-end"
      container
    >
      <Grid item xs={12} className={classes.titleContainer}>
        {selectedRelation && (
          <Typography variant="button" className={classes.title} gutterBottom>
            {lookUp({ key: `CONSOLE_${selectedRelation}` })}
          </Typography>
        )}
        <Box flex={1} />
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {isTypeSelectorEnabled('keyword') && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={lookUp({ key: 'CONSOLE_KEYWORD' })}
            value={q || ''}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleSearch();
                ev.preventDefault();
              }
            }}
            onChange={({ target }) => {
              setQ(target.value);
            }}
          />
        </Grid>
      )}
      {isTypeSelectorEnabled('type') && (
        <Grid item xs={12}>
          <Autocomplete
            disabled={types.length < 1}
            disablePortal
            selectOnFocus
            handleHomeEndKeys
            options={types}
            value={type || ''}
            onChange={(event, selection) => setType(selection)}
            getOptionLabel={(option) => option && lookUp({ key: `CONSOLE_${option}` })}
            renderInput={(params) => <TextField fullWidth {...params} label={lookUp({ key: 'CONSOLE_TYPE' })}/>}
          />
        </Grid>
      )}
      {isTypeSelectorEnabled('platform') && (
        <Grid item xs={12}>
          <Autocomplete
            disabled={platforms.length < 1}
            options={platforms}
            value={platform || ''}
            onChange={(e, platform) => {
              setPlatform(platform)
            }}
            getOptionLabel={(platform) => getPlatformLabel(platform)}
            renderInput={(params) => <TextField fullWidth {...params} label={lookUp({ key: 'CONSOLE_PLATFORM' })}/>}
            disablePortal
            selectOnFocus
            handleHomeEndKeys
          />
        </Grid>
      )}
      {isTypeSelectorEnabled('owner') && (
        <Grid item xs={12}>
          <DropdownWithSearch
            label={lookUp({ key: 'CONSOLE_OWNER' })}
            onSelect={(e) => setOwnerId(e?.id)}
            getOptions={(req) => customersService.searchQuery(new URLSearchParams(req), 100, 0)}
            params={{ query: 'query', id: 'customerId', label: 'username' }}
          />
        </Grid>
      )}
      {isTypeSelectorEnabled('genre') && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={lookUp({ key: 'CONSOLE_GENRE' })}
            value={genre || ''}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleSearch();
                ev.preventDefault();
              }
            }}
            onChange={({ target }) => {
              setGenre(target.value);
            }}
          />
        </Grid>
      )}
      {isTypeSelectorEnabled('category') && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={lookUp({ key: 'CONSOLE_CATEGORY' })}
            value={category || ''}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                handleSearch();
                ev.preventDefault();
              }
            }}
            onChange={({ target }) => {
              setCategory(target.value);
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} container justifyContent="flex-end">
        <ThemedButton
          onClick={getContentOptions}
          loading={resultsLoading}
        >
          {lookUp({ key: 'CONSOLE_SEARCH_BUTTON' })}
        </ThemedButton>
      </Grid>
      {results.length > 0 ? (
        <Grid item xs={12} container>
          {results.map(item => (
            <Grid
              key={`${getItemId(item)}-${selectedRelation}`}
              container
              item
              justifyContent="flex-start"
              spacing={1}
              xs={12}
              className={classes.listItem}
            >
              <Grid item xs={4}>
                <ControlBox
                  width="100%"
                  height={75}
                  offset={4}
                  topButtons={[
                    {
                      selector: 'iconButton',
                      icon: <>i</>,
                      tooltip: lookUp({ key: 'CONSOLE_OPEN_IN_NEW_HELPERTEXT' }),
                      onClick: e => {
                        e.preventDefault();
                        window.open(getItemEditLink(item), '_blank');
                      },
                    },
                  ]}
                  bottomButtons={[
                    {
                      selector: 'iconButton',
                      icon: <AddIcon />,
                      tooltip: lookUp({ key: 'CONSOLE_ADD_ITEM' }),
                      onClick: e => {
                        e.preventDefault();
                        handleAddItem(item);
                      },
                    },
                  ]}
                  backgroundImage={
                    item.assets?.find(({ subType }) => subType === 'Landscape')?.publicUrl ||
                    item.assets?.find(({ type }) => type === 'Image')?.publicUrl
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{getItemTitle(item) || '--'}</Typography>
                <Typography variant="caption">
                  {item.type ? lookUp({ key: `CONSOLE_${item.type}`.toUpperCase?.() }) : '--'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : !hasSearched ? (
        <></>
      ) : (
        <Grid item xs={12}>
          {lookUp({ key: 'CONSOLE_NO_ROWS' })}
        </Grid>
      )}
    </Grid>
  );
};

ContentSelector.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  selectedRelation: PropTypes.oneOf(['Content', 'Sku', 'Customer', 'Moderator', 'Notification', 'Organization']).isRequired,
  selectedRelationTargetType: PropTypes.string,
  targetType: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  enabledTypes: PropTypes.oneOf([
    'keyword',
    'type',
    'owner',
    'platform',
    'genre',
    'category'
  ]),
};

export default withSnackbar(ContentSelector);
