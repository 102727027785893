import PropTypes from 'prop-types';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ImageIcon from '@material-ui/icons/Image';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const TYPE_VIDEO = 'Video';
const TYPE_AUDIO = 'Audio';
const TYPE_IMAGE = 'Image';

const AssetTypeIcon = ({ type, size = 'small', ...rest }) => {
  const iconProps = { size, ...rest };

  switch (type) {
    case TYPE_VIDEO:
      return <PlayCircleFilledIcon {...iconProps} />;
    case TYPE_AUDIO:
      return <MusicNoteIcon {...iconProps} />;
    case TYPE_IMAGE:
      return <ImageIcon {...iconProps} />;
    default:
      return <InsertDriveFileIcon {...iconProps} />;
  }
};

AssetTypeIcon.propTypes = {
  type: PropTypes.oneOf([TYPE_VIDEO, TYPE_AUDIO, TYPE_IMAGE]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default AssetTypeIcon;
