import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { useSearchParams, createSearchParams, Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Lister from 'components/Lister';
import Toolbar from 'components/Toolbar';
import QueryBar from 'components/QueryBar';
import CreatedOn from 'components/CreatedOn';
import merchandiseService from 'services/merchandiseService';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import alertService from 'services/alertService';
import isEmpty from 'lodash/isEmpty';

// NOTE status colors should come from the BE theme
import amber from '@material-ui/core/colors/amber';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

const EMPTY_DATA_INDICATOR = '--';
const NO_STATUS = 'None';
const STATUSES = [NO_STATUS, 'NotProcessed', 'OrderCompleted', 'OrderRejected'];

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    wrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
  },
  filtersContainer: {
    marginBottom: theme.spacing(2),
  },
  filtersTagsContainer: {
    marginTop: theme.spacing(1),
  },
  deleteDialogHighlightedTitle: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    padding: theme.spacing(1),
  },
  statusChip: {
    background: theme.palette.common.white,
    color: 'inherit',
  },
  NotProcessed: {
    background: amber[500],
    borderColor: amber[800],
    color: theme.palette.getContrastText(amber[900]),
  },
  OrderCompleted: {
    background: green[500],
    borderColor: green[800],
    color: theme.palette.getContrastText(green[800]),
  },
  OrderRejected: {
    background: red[500],
    borderColor: red[800],
    color: theme.palette.getContrastText(red[800]),
  },
}));

const Merchandise = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();

  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(Symbol());

  const classes = useStyles();

  useEffect(() => {
    const searchConfig = getSearchParams();

    setIsLoading(true);
    const getItems = () => {
      merchandiseService
        .search(searchConfig)
        .then((rows) => hydrateItems(rows))
        .then((items = []) => {
          setItems(items);
        })
        .catch((error) => {
          alertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_CONTENT_SEARCH_RESULTS' }),
            }),
          });
        })
        .finally(() => setIsLoading(false));
    };

    getItems();
  }, [search, trigger]);

  const getSearchParams = () => {
    const {
      status,
      customerId,
      productCode,
      createdAfter,
      createdBefore,
      page,
      pageSize: perPage,
      ...restQueryParams
    } = { ...Object.fromEntries(search.entries()) };

    return {
      ...(!isEmpty(status) && status !== NO_STATUS && { status }),
      ...(!isEmpty(customerId) && { customerId }),
      ...(!isEmpty(productCode) && { productCode }),
      ...(!isEmpty(createdAfter) && { createdAfter }),
      ...(!isEmpty(createdBefore) && { createdBefore }),
      ...(!isEmpty(perPage) && { perPage }),
      ...(!isEmpty(page) && { page }),
      ...restQueryParams,
    };
  };

  const hydrateItems = (rows) =>
    Promise.all(
      rows.pageContent.map(async (row) => {
        let productData = {};
        let customerData = {};

        try {
          productData = await contentService.getById(row.productId);
          customerData = await customersService.getCustomer(row.customerId);
        } catch (error) {
          // TODO handle error, most common one is 404 customerId could not be found
          console.error('hydrateItems error', error);
        }

        return {
          ...row,
          productName: productData.originalTitle ?? EMPTY_DATA_INDICATOR,
          price: productData.internalPrice ?? EMPTY_DATA_INDICATOR,
          customerName: customerData.username ?? EMPTY_DATA_INDICATOR,
          customerEmail: customerData.emailAddress ?? EMPTY_DATA_INDICATOR,
        };
      }),
    );

  const handleSearch = () => {
    setTrigger(Symbol('refresh'));
  };

  const clearAllSearchFields = () => {
    setSearch({});
  };

  const onSearchUpdate = (type, value) => {

    if (type === 'status' && value === NO_STATUS) {
      search.delete('status');
      return;
    }

    const searchConfig = getSearchParams();

    setSearch(() =>
      createSearchParams({
        ...searchConfig,
        [type]: value,
      }),
    );
  };

  const toolbarButtons = [
    {
      label: lookUp({ key: 'CONSOLE_CLEAR_ALL' }),
      startIcon: <ClearAllIcon />,
      onClick: clearAllSearchFields,
      variant: 'text',
      size: 'medium',
    },
  ];

  const filters = [
    {
      label: lookUp({ key: 'CONSOLE_PURCHASE_STATUS' }),
      key: 'status',
      value: search.get('status'),
      change: (value) => {
        onSearchUpdate('status', value);
      },
      options: STATUSES.map((value) => ({
        label: lookUp({ key: `CONSOLE_${value}` }),
        value,
      })),
    },
    {
      component: (
        <TextField
          label={lookUp({ key: 'CONSOLE_PRODUCTCODE' })}
          name="productCode"
          value={search.get('productCode')}
          size="small"
          onChange={(e) => {
            e.preventDefault?.();
            onSearchUpdate('productCode', e.target?.value);
          }}
          fullWidth
        />
      ),
    },
    {
      component: (
        <KeyboardDatePicker
          label={lookUp({ key: 'CONSOLE_CREATEDAFTER' })}
          value={search.get('createdAfter')}
          format="MM/DD/yyyy"
          variant="inline"
          onChange={(date) => {
            const time = new Date(date);
            if (search.get('createdBefore') && time > search.get('createdBefore')) {
              return;
            }

            time.setUTCHours(0, 0, 0, 0);
            onSearchUpdate('createdAfter', time.toISOString());
          }}
          fullWidth
        />
      ),
    },
    {
      component: (
        <KeyboardDatePicker
          label={lookUp({ key: 'CONSOLE_CREATEDBEFORE' })}
          value={search.get('createdBefore')}
          format="MM/DD/yyyy"
          variant="inline"
          onChange={(date) => {
            const time = new Date(date);
            if (search.get('createdAfter') && time < search.get('createdAfter')) {
              return;
            }

            time.setUTCHours(23, 59, 59, 999);
            onSearchUpdate('createdBefore', time.toISOString());
          }}
          fullWidth
        />
      ),
    },
  ];

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'status',
      headerName: lookUp({ key: 'CONSOLE_STATUS' }),
      align: 'left',
      flex: 1,
      renderCell: (item) => (
        <Link to={`./${item.id}/edit#order`} component={RouterLink}>
          <Chip
            className={classNames(classes.statusChip, {
              [classes[item.value]]: item.value in classes,
            })}
            label={lookUp({ key: `CONSOLE_${item.value}` })}
            size="small"
          />
        </Link>
      ),
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      minWidth: 140,
      flex: 1,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'customerName',
      headerName: lookUp({ key: 'CONSOLE_CUSTOMERNAME' }),
      align: 'left',
      flex: 2.5,
      renderCell: (item) => (
        <Tooltip title={item.value}>
          <Link to={`./customers/${item.row.customerId}/details`} component={RouterLink}>
            {item.value ?? '--'}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'productName',
      headerName: lookUp({ key: 'CONSOLE_PRODUCTNAME' }),
      align: 'left',
      flex: 3,
      renderCell: (item) => (
        <Link to={`/content/${item.row.productId}/edit`} component={RouterLink}>
          {item.value}
        </Link>
      ),
    },
    {
      field: 'price',
      headerName: lookUp({ key: 'CONSOLE_PRICE' }),
      type: 'number',
      flex: 1.5,
    },
  ];

  return (
    <Box flex={1} xs={12}>
      <Grid className={classes.filtersContainer} container spacing={1}>
        <Grid item className={classes.toolbarContainer}>
          <QueryBar
            queryKey="customerId"
            label={lookUp({ key: 'CONSOLE_CUSTOMER_ID' })}
            queryOptions={['productCode', 'createdAfter', 'createdBefore']}
            filters={filters}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar
            queryBy="customerId"
            label={lookUp({ key: 'CONSOLE_CUSTOMER_ID' })}
            buttons={toolbarButtons}
            triggerSearch={handleSearch}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Lister loading={isLoading} columns={columns} rows={items} totalCount={totalCount} />
      </Grid>
    </Box>
  );
};

export default withSnackbar(Merchandise);
