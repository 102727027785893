import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { Box, IconButton, Dialog, makeStyles } from '@material-ui/core';
import { PlayArrow, Edit, Clear, Add, LockOpen, LockSharp, SkipNext } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import ClipEditor from './ClipEditor';
import ControlBox from 'components/ControlBox';
import markerService from 'services/markerService';


function RemixTracks({
  duration,
  setDuration,
  setMainTrack,
  clipToMarker,
  setIsFirstLoad,
  width,
  tracks,
  setTracks,
  deleteTrack,
  childContents,
  setVideoUrl,
  chapters,
  setSecondaryVidUrl,
  parseBE,
  setTrackOrderSelectorOpen,
}) {
  const [bindDuration, setBindDuration] = useState('main');
  const [snap, setSnap] = useState(false);
  const [zoomOut, setZoomOut] = useState(true);
  const [behaviours, setBehaviours] = useState([]);
  const [audioSettings, setAudioSettings] = useState([]);
  const [lastClipEnd, setLastClipEnd] = useState(0);

  useEffect(() => {
    const getSettings = async () => {
      setBehaviours(await markerService.getVideoSettings());
      setAudioSettings(await markerService.getAudioSettings());
    };
    getSettings();
  }, []);

  const factor = (zoomOut ? lastClipEnd : duration) / width;

  const getEnd = (contentId) => {
    const lastMoment = tracks
      .filter((tr) => (contentId ? tr.contentId === contentId : true))
      ?.reduce((lastMoment, tr) => {
        if (!tr?.clips) return lastMoment;
        return Math.max(lastMoment, ...tr.clips.map((c) => (c.start || 0) + (c.duration || 0)));
      }, 0);
    return lastMoment;
  };

  useEffect(() => {
    setLastClipEnd(getEnd());
    if (bindDuration) {
      switch (bindDuration) {
        case 'sum':
          setDuration(getEnd());
          break;
        case 'main':
          setDuration(getEnd(tracks.find((e) => e?.main)?.contentId));
          break;
        default:
          break;
      }
    }
  }, [tracks, bindDuration]);

  return (
    <>
      {tracks
        .filter((e) => !!e)
        .map((e, i) => { 
          const cContent = childContents.find((cC) => cC.contentId === e.contentId);
          return (
            <Track
              key={`Track${i}`}
              tracks={tracks}
              setTracks={setTracks}
              factor={factor}
              clipToMarker={clipToMarker}
              behaviours={behaviours}
              audioSettings={audioSettings}
              setVideoUrl={setVideoUrl}
              setSecondaryVidUrl={setSecondaryVidUrl}
              setIsFirstLoad={setIsFirstLoad}
              viewport={zoomOut ? lastClipEnd : duration}
              assetDuration={childContents?.find((a) => a.contentId === e.contentId)?.duration}
              LODuration={duration}
              chapters={chapters}
              deleteTrack={deleteTrack}
              setTrackOrderSelectorOpen={setTrackOrderSelectorOpen}
              setMainTrack={setMainTrack}
              track={{
                ...e,
                title: e?.title || cContent?.originalTitle,
                chapters: e?.chapters || cContent?.chapters,
                thumbnail: e?.thumbnail || cContent?.thumbnail,
                videoUrl: e?.videoUrl || cContent?.videoUrl,
              }}
              snaps={
                snap
                  ? tracks.reduce((arr, t) => {
                      t.clips
                        .map((c) => [c.start, c.start + c.cueOut - c.cueIn])
                        .forEach((s) => {
                          arr.push({ time: s[0], contentId: t.contentId });
                          arr.push({ time: s[1], contentId: t.contentId });
                        });
                      return arr;
                    }, [])
                  : []
              }
              parseBE={parseBE}
            />
          )
        })
      }
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    border: '2px solid #333',
    backgroundColor: 'white',
    zIndex: 100,
    height: 32,
    width: 32,
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  dialog: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  rail: {
    flex: 7,
    display: 'inline-block',
    position: 'relative',
    height: 130,
    marginTop: 18,
    marginBottom: 18,
    backgroundColor: '#666',
    padding: 0,
    outline: '1px solid #444',
  },
  mainPart: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: (props) => (100 * props.LODuration) / props.viewport + '%',
    backgroundColor: 'rgba(0,0,0,.3)',
    borderRight: '2px dotted black',
  },
}));

const Track = ({
  factor,
  clipToMarker,
  tracks,
  track,
  snaps,
  viewport,
  assetDuration,
  LODuration,
  setIsFirstLoad,
  setTracks,
  deleteTrack,
  setTrackOrderSelectorOpen,
  behaviours,
  audioSettings,
  setVideoUrl,
  setSecondaryVidUrl,
  parseBE,
}) => {
  const theme = useTheme();
  const [selectedClip, setSelectedClip] = useState(null);
  const [selectedProp, setSelectedProp] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [gaps, setGaps] = useState([]);

  const [saveTimeout, setSaveTimeout] = useState(undefined);

  const colorByIndex = (i, a = '100%') => `hsla(${12 + i * 140}, 40%, 80%, ${a})`;

  const classes = useStyles({
    backgroundColor: track.main
      ? theme.palette?.primary?.main || '#666'
      : colorByIndex(track.index),
    LODuration,
    viewport,
  });

  const collectGaps = () =>
    setGaps(
      track.clips?.length < 1
        ? [0]
        : [
            0,
            ...track.clips?.reduce((arr, c) => {
              arr.push(c.start + c.duration);
              return arr;
            }, []),
          ]
            .sort((a, b) => a - b)
            .filter((e, i) => {
              if (i === 0 && (track.clips[0]?.start || 0) < viewport / 20) return;
              return (track.clips[i]?.start || viewport) - viewport / 20 > e;
            })
    );

  const saveClip = (alteredTrack, alteredClip) => {
    //debounces
    setSaveTimeout((timeout) => clearTimeout(saveTimeout));
    const delay = 1000;
    const timeout = setTimeout(
      () => markerService.edit(clipToMarker(alteredTrack, alteredClip)),
      delay
    );
    setSaveTimeout(timeout);
  };

  const addClip = async (gap) => {
    const mainTrack = tracks.find((track) => track.main)

    let duration = mainTrack?.duration || assetDuration;
    if (mainTrack?.clips?.length) {
      duration -= track.clips.reduce((sumDur, clip) => {
        sumDur += clip.duration;
        return sumDur;
      }, 0);
    }

    const newClip = {
      start: gap,
      cueIn: 0,
      cueOut: duration,
      duration,
      title: 'new Clip',
      behavior: 1,
      audioSetting: 1,
      volumeLevel: 90,
      open: true,
      contentId: track.contentId,
    };
    const alteredTrack = { ...track };
    const newId = (await markerService.create(clipToMarker(alteredTrack, newClip))).id;
    newClip.id = newId;
    alteredTrack.clips = [...track.clips, newClip]
      .sort((a, b) => a.start - b.start)
      .map((c, i) => ({ ...c, index: i }));
    setTracks(trx => {
      trx.splice(trx.findIndex((tr) => tr.contentId === track.contentId), 1,  alteredTrack)
      return [...trx];
    });
    setSelectedClip(newId);
    setEditorOpen(true);
  };

  const updateTrack = (alteredClip) => {
    const alteredTrack = { ...track };
    let alteredClips = [
      ...track.clips?.filter((e) => {
        return e.id !== alteredClip.id;
      }),
      {
        ...alteredClip,
        behaviour: {volumeLevel: alteredClip.volumeLevel,
        videoSettings: parseBE('video', alteredClip.behavior),
        audioSettings: parseBE('audio', alteredClip.audio)
      }},
    ];
    alteredTrack.clips = alteredClips;
    setTracks(trx => {
      trx.splice(trx.findIndex(tr => tr.contentId === track.contentId), 1,  alteredTrack)
      return [...trx];
    });
    saveClip(alteredTrack, alteredClip);
  };

  const deleteClip = (id) => {
    let alteredTrack = { ...track };
    alteredTrack.clips = track.clips.filter((e) => e.id !== id);
    markerService.remove(id);
    setTracks(trx => {
      trx.splice(trx.findIndex(tr => tr.contentId === track.contentId), 1,  alteredTrack);
      return [...trx.filter(tr => tr.clips.length > 0)];
    });
  };

  const changeClip = (prep, id, value) => {
    if (!value && value !== 0) return;
    const prop = prep || 'start';
    if (typeof prop !== 'string') return;
    const alteredClip = { ...track.clips?.find((e) => e.id === id) };
    if (id !== selectedClip || (prop === 'start' && (prop !== selectedProp || !selectedProp))) {
      return;
    }
    const { start } = alteredClip;

    if (prop === 'open') alteredClip.open = !!alteredClip.open;

    alteredClip[prop] = +value
      ? +value
      : typeof alteredClip[prop] === 'boolean'
      ? !alteredClip[prop]
      : value;

    const sanitize = () => {
      alteredClip.start = Math.max(alteredClip.start, 0);
      alteredClip.start = Math.min(alteredClip.start, viewport - 500);
      return;
      // let prev = [...(track.clips || [])]
      //   .filter((c) => c.start < start)
      //   ?.sort((a, b) => a.start - b.start)
      //   ?.pop();
      // let next = [...(track.clips || [])]
      //   .filter((c) => c.start > start)
      //   ?.sort((a, b) => a.start - b.start)?.[0];
      // if (alteredClip.open) alteredClip.duration = alteredClip.cueOut - alteredClip.cueIn;
      // if (prop === 'duration') {
      //   alteredClip.duration = Math.min(
      //     alteredClip.duration,
      //     (next?.start || viewport) - alteredClip.start
      //   );
      //   alteredClip.duration = Math.max(
      //     alteredClip.duration,
      //     alteredClip.cueOut - alteredClip.cueIn
      //   );
      // }
      // if (prop === 'cueOut') {
      //   alteredClip.cueOut = Math.min(alteredClip.cueOut, assetDuration);
      //   alteredClip.cueOut = Math.max(alteredClip.cueOut, alteredClip.cueIn + 1000);
      //   if (Number.isNaN(!alteredClip.cueOut)) alteredClip.cueOut = assetDuration;
      // }
      // if (prop === 'cueIn') {
      //   alteredClip.cueIn = Math.max(alteredClip.cueIn, 0);
      //   alteredClip.cueIn = Math.min(
      //     alteredClip.cueIn,
      //     alteredClip.cueOut - 1000,
      //     assetDuration - 1000
      //   );
      //   if (Number.isNaN(!alteredClip.cueIn)) alteredClip.cueIn = assetDuration;
      // }
      // alteredClip.start = Math.max(alteredClip.start, prev?.start + prev?.duration || 0);
      // alteredClip.start = Math.min(
      //   alteredClip.start,
      //   (next?.start || viewport) - alteredClip.duration
      // );
    };

    if (snaps.length && prop === 'start') {
      alteredClip[prop] =
        snaps
          .filter((e) => e.contentId !== track.contentId)
          .map((s) => s.time)
          .find((n) => Math.abs(value - n) < 5000) || value;
    }

    if (prop === 'cueOut') {
      alteredClip.duration = Math.max(alteredClip.duration, +value - alteredClip.cueIn);
      if (alteredClip.open) alteredClip.duration = alteredClip.cueOut - alteredClip.cueIn;
    }

    if (prop === 'behavior') {
      alteredClip[prop] = +value;
      alteredClip.duration =
        +value === 3 || alteredClip.open
          ? alteredClip.cueOut - alteredClip.cueIn
          : alteredClip.duration === alteredClip.cueOut - alteredClip.cueIn
          ? (track.clips.find((c) => c.index === alteredClip.index + 1)?.start || viewport) -
            alteredClip.start
          : alteredClip.duration;
      if (+value === 3) alteredClip.open = true;
    }

    if (prop === 'open' && !alteredClip[prop]) {
      alteredClip.duration =
        (track.clips.find((e) => e.index === alteredClip.index + 1)?.start || viewport) -
        alteredClip.start;
    }

    sanitize();
    updateTrack(alteredClip);
  };

  useEffect(() => {
    collectGaps();
  }, [track]);

  const saveByButton = (clip) => {
    updateTrack(clip);
  };

  const topButtons = [
    {
      selector: 'iconButton',
      icon: <Box component="span">P</Box>,
      onClick: () => setSecondaryVidUrl(track.videoUrl),
      tooltip: lookUp({ key: 'CONSOLE_PLAY_IN_SECONDARY_HELPERTEXT' }),
    },
    {
      selector: 'iconButton',
      icon: <Clear />,
      onClick: () => {
        if (track.lock) return;
        (track.main && tracks.length > 1) ? setTrackOrderSelectorOpen(true) : deleteTrack(track.contentId);
      },
      tooltip: lookUp({ key: 'CONSOLE_DELETE_TRACK_HELPERTEXT' }),
    },
  ];

  const bottomButtons = [
    !track.main
      ? {
          selector: 'iconButton',
          icon: <Box component="span">M</Box>,
          onClick: () => setTrackOrderSelectorOpen(true),
          tooltip: lookUp({ key: 'CONSOLE_SET_MAIN_TRACK_HELPERTEXT' }),
        }
      : { selector: 'placeholder' },
    {
      selector: 'insetButton',
      icon: <PlayArrow />,
      onClick: () => {
        setIsFirstLoad(false);
        setVideoUrl(track.videoUrl);
      },
      tooltip: lookUp({ key: 'CONSOLE_PLAY_MAIN_HELPERTEXT' }),
    },
    {
      selector: 'iconButton',
      icon: track.lock ? <LockSharp /> : <LockOpen />,
      onClick: () => {
        setTracks(trx => {
          trx.splice(trx.findIndex(tr => tr.contentId === track.contentId), 1,  { ...track, lock: !track.lock })
          return [...trx];
        });
      },
      tooltip: lookUp({ key: 'CONSOLE_LOCK_TRACK_HELPERTEXT' }),
    },
  ];

  return (
    <div>
      <Dialog open={editorOpen && selectedClip} fullWidth className={classes.dialog} maxWidth="xl">
        <ClipEditor
          behaviours={behaviours}
          audioSettings={audioSettings}
          track={track}
          selectedClip={track.clips.find((t) => t.id === selectedClip)}
          saveByButton={saveByButton}
          setEditorOpen={setEditorOpen}
          assetDuration={assetDuration}
        />
      </Dialog>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <ControlBox
          width={230}
          height={130}
          title={track.title}
          duration={assetDuration}
          other={[
            track.main
              ? lookUp({ key: 'CONSOLE_MAIN_TRACK' })
              : lookUp({ key: 'CONSOLE_TRACK_NUMBER_TEMPLATE', number: track.index + 1 }),
            track.clips?.length > 1 &&
              lookUp({ key: 'CONSOLE_N_CLIPS_TEMPLATE', number: track.clips.length }),
          ]}
          topButtons={topButtons}
          bottomButtons={bottomButtons}
        />
        <div
          className={classes.rail}
          onMouseMove={(e) => {
            if (selectedClip && e.buttons) {
              const clip = track.clips.find((c) => c.id === selectedClip);
              changeClip(
                'start',
                clip.id,
                clip.start + e.movementX * factor * (1 + Math.abs(e.movementX / 65))
              );
            }
          }}
        >
          <div className={classes.mainPart} />
          {track.clips
            .sort((a, b) => a.index - b.index)
            .map((clip, i) => (
              <>
                <div
                  key={clip.id}
                  className="duration"
                  onMouseMove={(e) => {
                    if (selectedClip && e.buttons) {
                      changeClip(
                        'start',
                        clip.id,
                        clip.start + e.movementX * factor * (1 + Math.abs(e.movementX / 65))
                      );
                    }
                  }}
                  style={{
                    position: 'absolute',
                    transition: 'left .2s ease-out',
                    left: `calc(100% * (${clip.start / viewport}))`,
                    width: (clip.duration / viewport) * 100 + '%',
                    outline: '1px solid #444',
                  }}
                >
                  <ControlBox
                    width="100%"
                    background={
                      track.main
                        ? theme.palette?.primary?.main || '#666'
                        : colorByIndex(track.index, '75%')
                    }
                    title={clip.title}
                    duration={clip.duration}
                    other={[clip.behaviour?.videoSettings]}
                    onMouseDown={(e) => {
                      !track.lock && setSelectedClip(clip.id);
                      !track.lock && setSelectedProp('start');
                    }}
                    topButtons={[
                      { selector: 'placeholder' },
                      {
                        selector: 'iconButton',
                        icon: <Clear />,
                        onClick: () => deleteClip(clip.id),
                        tooltip: lookUp({ key: 'CONSOLE_DELETE_CLIP_HELPERTEXT' }),
                      },
                    ]}
                    sideButtons={
                      !track.main &&
                      i === track.clips.length - 1 && [
                        {
                          selector: 'insetButton',
                          icon: <SkipNext style={{ marginRight: -5 }} />,
                          onClick: () => {
                            changeClip('duration', clip.id, LODuration - clip.start);
                          },
                          tooltip: lookUp({ key: 'CONSOLE_SET_TO_END' }),
                        },
                      ]
                    }
                    bottomButtons={[
                      { selector: 'placeholder' },
                      {
                        selector: 'iconButton',
                        icon: <Edit />,
                        onClick: () => setEditorOpen(true),
                        tooltip: lookUp({ key: 'CONSOLE_EDIT_CLIP_HELPERTEXT' }),
                      },
                    ]}
                  />
                </div>
                {gaps.length > 0 &&
                  gaps.map((gap, i) => (
                    <div
                      key={i}
                      style={{
                        position: 'absolute',
                        zIndex: 100,
                        left: (100 * gap) / viewport + 1 + '%',
                        top: 45,
                      }}
                    >
                      <IconButton
                        onClick={() => addClip(gap)}
                        size="small"
                        style={{ color: '#fff' }}
                      >
                        <Add />
                      </IconButton>
                    </div>
                  ))}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RemixTracks;
