import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getUserThumbail } from 'utils/user';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[800],
  },
}));

const UserAvatar = ({ user, ...restProps }) => {
  const classes = useStyles();

  return (
    <Avatar
      className={classes.root}
      alt={user?.username}
      src={getUserThumbail(user?.properties)}
      sx={(theme) => ({ backgroundColor: theme.palette.common.black, color: theme.palette.common.white })}
      {...restProps}
    />
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserAvatar;
