import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { UAParser } from 'ua-parser-js';
import platform from 'platform';
import { parseUserFromToken } from 'helpers/common';
import {
  setUser,
  resetUser,
  setLastRoute,
  setLoadingStatus,
  setSelectedLocale,
} from 'store/actions/authAction';
import {
  makeApiWithQuery, 
  setTokenToCookies, 
  getTokenFromCookies, 
  removeTokenFromCookies 
} from 'helpers/common'
import { getConsoleApiEndpoint } from './apiEndpoints';
import { v4 as guid } from 'uuid';

const searchQuery = (searchParams, pageSize = 25, page = 0) => {
  const safeParams = !searchParams instanceof URLSearchParams ? new URLSearchParams(searchParams) : searchParams;

  if (safeParams.has?.('page')) {
    safeParams.delete?.('page');
  }

  if (safeParams.has?.('pageSize')) {
    safeParams.delete?.('pageSize');
  }
  
  return axios
    .get(`${getConsoleApiEndpoint('Customer')}/Search/${pageSize}/${page}`, { params: safeParams })
    .then(res => res.data);
};

const handleUserResponse = async (user, dispatch) => {
  setTokenToCookies(user.accessToken);
  setTokenToCookies(user.refreshToken, 'refreshToken');
  const locale = localStorage.getItem('locale') || user.defaultLocale;
  dispatch(setSelectedLocale(locale));
  localStorage.setItem('locale', locale);
  const fromToken = parseUserFromToken(user.accessToken);
  const fromBE = await getCustomer(user.customerId);
  if (fromToken.role === 'SysAdmin') localStorage.removeItem('selectedOrganization');
  dispatch(setUser({
    ...user, 
    ...fromBE, 
    role: fromToken.role, 
    organizations: fromToken.organizations ? JSON.parse(fromToken.organizations) : null,
  }));
};

const labelOrganizations = (organizations) => 
  Promise.allSettled(organizations.map(e => getCustomer(e)))
  .then(r => r.map(e => e.value))

const detectDeviceType = () => {
  const ua = navigator.userAgent;
  let type, subType;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    type =  "TABLET";
  };
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    type = "MOBILE";
  };
  if (
    /AppleTV|GoogleTV|NetCast|HbbTV|SMART-TV|Opera TV|InettvBrowser|SonyDTV|SmartTV|SmartHub|Roku|NETTV|CrKey/.test(
      ua
    )
  ) {
    type = "TV";
  };

  type = type || "DESKTOP";

  if (type === "TABLET") {
    if (/android/i.test(ua)) subType = "ANDROIDT";
    if (/ipad/i.test(ua)) subType = "IPAD";
  };
  if (type === "TV") {
    if (/android/i.test(ua)) subType = "ANDROIDTV";
    if (/AmazonWebAppPlatform/.test(ua)) subType = "FIRETV";
    if (/roku/i.test(ua)) subType = "ROKUTV";
    if (/CrKey/.test(ua)) subType = "CHROMECAST";
    if (/apple/i.test(ua)) subType = "APPLETV";
  };
  if (type === "MOBILE") {
    if (/android/i.test(ua)) subType = "ANDROIDP";
    if (/iphone/i.test(ua)) subType = "IPHONE";
  };

  subType = subType || type;

  return ({type, subType});

};

const signIn = ({ provider, userId, token}, dispatch) => {
  const authEndpoint = `${getConsoleApiEndpoint('Auth')}/authenticate`;
  let deviceId, 
    deviceName,
    deviceType,
    deviceSubType,
    deviceBrand,
    deviceLocale;

  deviceId = getTokenFromCookies('deviceId');
  if (!deviceId) {
    deviceId = guid();
    setTokenToCookies(deviceId, 'deviceId');
  };
  const userInfo = new UAParser().getResult();
  deviceName = `${userInfo?.browser?.name || "Browser"} | ${userInfo?.os?.name || "OS"}`;
  const { type, subType } = detectDeviceType();
  deviceType = type;
  deviceSubType = subType;
  deviceBrand = platform?.manufacturer;
  deviceLocale = navigator?.language;

  return axios
  .post(authEndpoint, { 
    network: {
      type: decodeURIComponent(provider),
      userId,
      accessToken: token
    },
    device: {
      deviceId,
      deviceName,
      deviceType,
      deviceSubType,
      deviceBrand,
      deviceLocale,
    }
  })
  .then(r => handleUserResponse(r.data, dispatch))
};

const signUp = ({ email, password }, dispatch) => {
  dispatch(setLoadingStatus(true));
  return axios
    .post(`${getConsoleApiEndpoint('Auth')}`, { network: {type: 'password', userId: email, accessToken: password }})
    .then(res => res.data)
    .then(user => handleUserResponse(user, dispatch))
};

const signOut = (dispatch) => {
  const currentRoute = window.location.href.split(window.location.host)[1];
  if (currentRoute !== '/sign-in') dispatch(setLastRoute(currentRoute));
  localStorage.removeItem('selectedOrganization');
  removeTokenFromCookies();
  removeTokenFromCookies('refreshToken');
  dispatch(resetUser());
};

const forgotPassword = (email) => {
  const endpoint = `${getConsoleApiEndpoint('Auth')}/Password/Forgot/${email}`;
  return axios
    .post(endpoint)
    .then(res => res.data)
};

const passwordOnReset = (password, token) => axios.post(`${getConsoleApiEndpoint('Auth')}/Password/Reset/${token}`, { password });

let isRefreshing = false;

const refreshToken = () => {
  if (isRefreshing) return new Promise((resolve, reject) => { resolve(null) });
  isRefreshing = true;
  const token = getTokenFromCookies();
  const refreshToken = getTokenFromCookies('refreshToken');
  const endpoint = `${getConsoleApiEndpoint('Auth')}/refreshtoken`;
  const deviceId = jwtDecode(token).DeviceId;
  const requestData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    body: JSON.stringify({
      accessToken: token,
      refreshToken: refreshToken,
      device: {deviceId}
    })
  };
  return fetch(endpoint, requestData)
    .then(response => { isRefreshing = false; return response.json()})
};


const search = (params) => {
  const { page = 0, perPage = 1000, sortExpr, q = '', ...rest} = params || {};
  return axios.get(
    makeApiWithQuery(`${getConsoleApiEndpoint('Customer')}/Search/${perPage}/${page}`, {
      sortExpr,
      q,
      ...rest,
    })
    )
    .then(r => r.data);
};

const create = (model) => axios.post(`${getConsoleApiEndpoint('Customer')}`, model);
  
const getCustomer = (id) => !id ? Promise.reject(new Error()) : axios.get(`${getConsoleApiEndpoint('Customer')}/${id}`).then(r => r.data);

const getIsBanned = (customerId, targetId) => {
  return new Promise((resolve, reject) => {
    const universalBanId = '00000000-0000-0000-0000-000000000000';
    axios({
      method: 'GET',
      baseURL: `${getConsoleApiEndpoint('CollectionsV2')}`,
      url: `/Relation/${customerId}/Customer/BanDiscussion/${targetId || universalBanId}`,
    })
    .then(() => resolve(true))
    .catch((error) => {
      error?.response?.status === 404
        ? resolve(false)
        : reject(`Can't access relation api!`); 
    })
  });
};

const addBan = (customerId, targetId) => {
  const universalBanId = '00000000-0000-0000-0000-000000000000';
  return axios({
    method: 'POST',
    baseURL: `${getConsoleApiEndpoint('CollectionsV2')}`,
    url: `/Relation/${customerId}/Customer/BanDiscussion/${targetId || universalBanId}`,
    data: null
  });
};

const removeBan = (customerId, targetId) => {
  const universalBanId = '00000000-0000-0000-0000-000000000000';
  return axios({
    method: 'DELETE',
    baseURL: `${getConsoleApiEndpoint('CollectionsV2')}`,
    url: `/Relation/${customerId}/Customer/BanDiscussion/${targetId || universalBanId}`,
    data: null
  });
};

const update = (id, model) => axios.put(`${getConsoleApiEndpoint('Customer')}/${id}`, model);

const deleteCustomer = (id) => axios.delete(`${getConsoleApiEndpoint('Customer')}/${id}/0`);

const forceReindex = () => axios.put(`${getConsoleApiEndpoint('Customer')}/ForceReindex`);

const getDeviceById = (id) => [];

const getReportById = (id) => axios.get(`${getConsoleApiEndpoint('CustomerReport')}/${id}`)
  .then((r) => r?.data);

const getCustomerTypes = () => axios.get(`${getConsoleApiEndpoint('Auth')}/Enums/CustomerTypes`)
.then(r => r.data);

const getProviderTypes = () => axios.get(`${getConsoleApiEndpoint('Auth')}/Enums/SocialNetworkTypes`)
.then(r => r.data);

const registerOnboarding = ({ awsRegion, projectName, brandName, userName, userEmail, token }, dispatch) => {
  const authEndp = `${getConsoleApiEndpoint('Onboarding')}/Register`;
  removeTokenFromCookies();
  return axios
    .post(authEndp, { awsRegion, projectName, brandName, userName, userEmail }, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => res.data)
    .then(token => refreshToken(token, dispatch))
    .then(userData => { setTokenToCookies(userData.token); return userData.token })
};

const getSubscriptions = id => axios.get(`${getConsoleApiEndpoint('License')}/${id}`);

export { 
  signUp,
  signIn,
  signOut,
  forgotPassword,
  passwordOnReset,
  refreshToken,
  handleUserResponse,
  labelOrganizations,
  search,
  create,
  getCustomer,
  getIsBanned,
  addBan,
  removeBan,
  update,
  deleteCustomer,
  forceReindex,
  getReportById,
  getDeviceById,
  getCustomerTypes,
  getProviderTypes,
  registerOnboarding,
};

export default {
  addBan,
  create,
  deleteCustomer,
  forceReindex,
  forgotPassword,
  getCustomer,
  getCustomerTypes,
  getDeviceById,
  getIsBanned,
  getProviderTypes,
  getReportById,
  getSubscriptions,
  handleUserResponse,
  labelOrganizations,
  passwordOnReset,
  refreshToken,
  registerOnboarding,
  removeBan,
  search,
  searchQuery,
  signIn,
  signOut,
  signUp,
  update,
};
