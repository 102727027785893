import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import reportingSrv from 'services/reportingService';
import AlertService from 'services/alertService';


const useStyles = makeStyles(theme => ({
  chartGrid: {
    height: '100%',
    margin: theme.spacing(6, 8, 0, 0),
    marginBottom: theme.spacing(8),
  },
  titleItem: {
    marginTop: theme.spacing(2),
  }
}));


function RewardsReports(props) {
  const { enqueueSnackbar } = props;


  const [metrics, setMetrics] = useState([]);
  const [topLineData, setTopLineData] = useState([]);
  const [bottomLineData, setBottomLineData] = useState([]);
  const [topBarData, setTopBarData] = useState([]);
  const [bottomBarData, setBottomBarData] = useState([]);

  const [searchParams] = useSearchParams();

  const chartColors = useSelector((state) => state.brandStyle.chartColors);

  const classes = useStyles();

  const reqPTop = useRef({});
  const reqPBottom = useRef({});

  const avoidDuplicateRequest = (reqParams, type) => {
    if (
      Object.values(reqParams).length === 0 &&
      Object.values(requestParamsRef.current).length !== 0
    ) return true;

    if (!(searchParams.has('from') && searchParams.has('to'))) return true; 
    const rPRef = (type === 'TopTenRewardsRank') ? reqPTop.current : reqPBottom;

    return Object.keys(reqParams).every(key => reqParams[key] === rPRef[key]);
  };

  const CustomTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={5} textAnchor="end" fill="#666" transform="rotate(270)">
        {payload.value}
      </text>
    </g>
  );

  const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

  const makeLineChartLabels = useCallback(() => metrics.map((metricName, i) => {
    const value = useRawMetricNames
      ? metricName
      : lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` });
    return ({
      id: `${i}_${metricName}`,
      value,
      type: 'square',
      color: chartColors[i],
    })
  }), [metrics]);

  const TopLines = useMemo(() => metrics.map((metricName, index) => (
    <Line
      key={`${metricName}_${index}`}
      dataKey={metricName}
      stroke={chartColors[index]}
      strokeWidth={2}
      type="monotone"
      name={metricName}
    />
  )), [metrics, topLineData]);

  const TopBars = useMemo(() => (
    <Bar dataKey="value">
      {topBarData.map((barCh, i) =>
        <Cell key={`${barCh.name}_i`} fill={chartColors[i]}/>
      )}
    </Bar>
  ), [topBarData]);

  const BottomLines = useMemo(() => metrics.map((metricName, index) => (
    <Line
      key={`${metricName}_${index}`}
      dataKey={metricName}
      stroke={chartColors[index]}
      strokeWidth={2}
      type="monotone"
      name={metricName}
    />
  )), [metrics, bottomLineData]);

  const BottomBars = useMemo(() => (
    <Bar dataKey="value">
      {bottomBarData.map((barCh, i) =>
        <Cell key={`${barCh.name}_i`} fill={chartColors[i]}/>
      )}
    </Bar>
  ), [bottomBarData]);

  useEffect(() => {
    const parseAndSetChartData = (type) => {
      const reqParams = {
        reportRoute: `CommercialMetrics/Daily/${type}`,
        from: searchParams.has('from') ? searchParams.get('from') : undefined,
        to: searchParams.has('to') ? searchParams.get('to') : undefined,
      }
      if (avoidDuplicateRequest(reqParams, type)) return;

      reportingSrv.getDailyReports(reqParams)
        .then(reportData => {
          setMetrics(reportData.metrics);

          const aggregateData = {}

          const chartData = reportData?.dataItems.map((dataItem) => {
            const dataPoint = dataItem.data?.reduce((dtPoint, elem) => {
              const qntInt = parseInt(elem.quantity, 10);
              const quantity = (Number.isNaN(qntInt) || qntInt === undefined || qntInt === null)
                ? 0
                : qntInt;

              if (!aggregateData[elem.id]) {
                aggregateData[elem.id] = quantity;
              } else {
                aggregateData[elem.id] += quantity;
              }

              dtPoint[elem.id] = quantity;
              return dtPoint;
            }, {});

            let date = new Date(dataItem.date);
            date = date.toString() === 'Invalid Date'
              ? undefined
              : date.toISOString();

            return ({
              date,
              labelDate: date && dataItem.date?.substr(5),
              ...dataPoint,
            });
          });
          if (type === 'TopTenRewardsRank') {
            setTopLineData(chartData);
            setTopBarData(() => Object.keys(aggregateData)
              .reduce((barData, metricName) => {
                barData.push({
                  name: lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` }),
                  value: aggregateData[metricName],
                });
                return barData;
              }, [])
            );
          } else {
            setBottomLineData(chartData);
            setBottomBarData(() => Object.keys(aggregateData)
              .reduce((barData, metricName) => {
                barData.push({
                  name: lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` }),
                  value: aggregateData[metricName],
                });
                return barData;
              }, [])
            );
          }
        })
        .catch(error => {
          setMetrics([]);
          if (type === 'TopTenRewardsRank') {
            setTopLineData([]);
            setTopBarData([]);
          } else {
            setBottomLineData([]);
            setBottomBarData([]);
          }

          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error: error.message,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_REPORT' })
            })
          });
        });
    };

    parseAndSetChartData('TopTenRewardsRank');
    parseAndSetChartData('WorstTenRewardsRank');

  }, [searchParams]);

  return (
    <>
      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {lookUp({
            key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
            type: lookUp({ key: `CONSOLE_REPORTING_TOPREWARDS`})
          })}
        </Typography>
      </Grid>

      <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
        <ResponsiveContainer aspect={2} width="85%">
          <LineChart data={topLineData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="labelDate" height={70} interval={0} tick={<CustomTick />} hide={true}/>
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              formatter={(value, name) => (
                [
                  value?.toLocaleString ? value.toLocaleString() : value,
                  lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${name}` })
                ]
              )}
              labelFormatter={(originalLabel) => {
                const dateLabel = new Date(`${new Date().getFullYear()}-${originalLabel}`);
                return (dateLabel && dateLabel !== 'Invalid Date')
                  ? dateLabel.toLocaleDateString()
                  : originalLabel;
              }}
            />
            <Legend
              align="left"
              verticalAlign="bottom"
              wrapperStyle={{ marginLeft: '60px', paddingTop: '20px' }}
              payload={makeLineChartLabels()}
            />
            {TopLines}
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {lookUp({
            key: 'CONSOLE_AGGREGATE_REPORTING_TEMPLATE',
            type: lookUp({ key: `CONSOLE_REPORTING_TOPREWARDS`})
          })}
        </Typography>
      </Grid>

      <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
        <ResponsiveContainer aspect={2} width="85%">
          <BarChart data={topBarData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              separator={''}
              formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
            />
            {TopBars}
          </BarChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {lookUp({
            key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
            type: lookUp({ key: `CONSOLE_REPORTING_BOTTOMREWARDS`})
          })}
        </Typography>
      </Grid>

      <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
        <ResponsiveContainer aspect={2} width="85%">
          <LineChart data={bottomLineData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="labelDate" height={70} interval={0} tick={<CustomTick />} hide={true}/>
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              formatter={(value, name) => (
                [
                  value?.toLocaleString ? value.toLocaleString() : value,
                  lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${name}` })
                ]
              )}
              labelFormatter={(originalLabel) => {
                const dateLabel = new Date(`${new Date().getFullYear()}-${originalLabel}`);
                return (dateLabel && dateLabel !== 'Invalid Date')
                  ? dateLabel.toLocaleDateString()
                  : originalLabel;
              }}
            />
            <Legend
              align="left"
              verticalAlign="bottom"
              wrapperStyle={{ marginLeft: '60px', paddingTop: '20px' }}
              payload={makeLineChartLabels()}
            />
            {BottomLines}
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {lookUp({
            key: 'CONSOLE_AGGREGATE_REPORTING_TEMPLATE',
            type: lookUp({ key: `CONSOLE_REPORTING_BOTTOMREWARDS`})
          })}
        </Typography>
      </Grid>

      <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
        <ResponsiveContainer aspect={2} width="85%">
          <BarChart data={bottomBarData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              separator={''}
              formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
            />
            {BottomBars}
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </>
  );
}

export default RewardsReports;
