import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getPlaybackPolicyEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('PlaybackPolicy');
  if (!endpoint) throw Error('No Playback Policy endpoint.');
  return endpoint;
};

const getPlaybackPolicyEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('PlaybackPolicyEnums');
  if (!endpoint) throw Error('No Playback Policy Enums endpoint.');
  return endpoint;
};

const search = ({ page = 0, perPage = 1000, q = '', sortBy = 'name asc', ...rest } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getPlaybackPolicyEndpoint()}/Search/${perPage}/${page}`, {
      sortBy,
      q,
      ...rest
    }))
    .then(response => response?.data)
};

const getAll = ({ page = 0, perPage = 10, query = '', sortBy, order } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getPlaybackPolicyEndpoint()}/Search/${perPage}/${page}`, {
      q: query,
      sortBy,
      order
    })
  )
    .then(response => response?.data)
};

const create = (model) => axios.post(getPlaybackPolicyEndpoint(), model)
  .then(response => response?.data)

const get = (id) => axios.get(`${getPlaybackPolicyEndpoint()}/${id}`)
  .then(response => response?.data)

const edit = (model) => axios.put(`${getPlaybackPolicyEndpoint()}/${model.id}`, model)
  .then(response => response?.data)

const remove = (id) => axios.delete(`${getPlaybackPolicyEndpoint()}/${id}`)
  .then(response => response?.data)

const getVideoQualityTypes = () => axios.get(`${getPlaybackPolicyEnumsEndpoint()}/VideoQuality`)
  .then(response => response?.data)

export { search, getAll, create, get, edit, remove, getVideoQualityTypes };

export default {
  search,
  getAll,
  create,
  get,
  edit,
  remove,
  getVideoQualityTypes,
};
