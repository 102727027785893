import React, {useState} from 'react';
import { lookUp } from 'services/stringService';
import {
  Box,
  Tooltip,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const useStyles = makeStyles(() => (
  {
    dialogText: {
      color: '#000000',
    }
  }
))

const DialogModal = (
  {
    button = true,
    dialogTitle,
    title,
    divEl,
    children,
    extraButtons,
    buttonText,
  }
) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
    { divEl ? (
        <Box sx={{cursor: 'pointer'}} onClick={handleClickOpen('paper')}>
          <Tooltip title={title} placement="bottom-start">
              <span>{title}</span>
          </Tooltip>
        </Box>
      ) : (
        <Button onClick={handleClickOpen('paper')} color={'primary'} variant={'contained'}>
          {title}
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth={'md'}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              {dialogTitle}
            </Grid>
            <Grid item xs={1} container alignContent="flex-start" justifyContent="flex-end">
              <IconButton size="small" onClick={() => handleClose(false)}>
                <Clear />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.dialogText}
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {extraButtons &&
            extraButtons.map((button, index) => (
              <Button
                key={index}
                color={button.color || ''}
                variant={button.variant || 'default'}
                onClick={() => {
                  button.onClick()
                  button.closeAfterClick && handleClose()
                }}
              >
                {button.text || lookUp({ key: 'CONSOLE_OK_BUTTON' })}
              </Button>
            ))}
          {button && 
            <Button onClick={handleClose} color="primary">
              {buttonText || lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
            </Button>            
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DialogModal