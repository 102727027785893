import { lookUp } from 'services/stringService';
import { func, object } from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Grid,
  Typography,
  TextField,
  Chip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RelatedComboBox from 'components/RelatedComboBox';

function TagsTab(props) {
  const {
    model,
    handleChange,
    triggers,
    setSelectedRelation,
    setTargetType,
  } = props;

  const contentRelationsOptions = [
    'Promos',
    'Highlights',
    'Awards',
    'Chat',
  ];
  const customerRelationsOptions = [
    'Organization',
    'InvitedCreators',
    'PermanentCreators',
    'Contracts',
    'Project'
  ];
  const customerReferencesOptions = [
    'Genre',
    'Organization',
    'Category',
    'Segmentation',
    'Role',
    'Tag',
    'SKU',
    'Location'
  ];

  return (
    <>
      <Grid container direction="column" spacing={4}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CONTENT_REFERENCES_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={2} alignContent="flex-start">
            {contentRelationsOptions.map((relation, index) => (
              <Grid item xs={12} md={6} key={`${relation}_${index}`}>
                <RelatedComboBox
                  sourceType='Customer'
                  targetType='Content'
                  relation={relation}
                  id={model.customerId}
                  onFocus={() => {
                    setSelectedRelation(relation);
                    setTargetType('Content');
                  }}
                  trigger={triggers?.[relation]}
                  size="small"
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CUSTOMER_REFERENCES_TITLE' })}
            </Typography>
          </Grid>

          <Grid item xs={12} container spacing={2} alignContent="flex-start">
            {customerRelationsOptions.map((relation, index) => (
              <Grid item xs={12} md={6} key={`${relation}_${index}`}>
                <RelatedComboBox
                  sourceType='Customer'
                  targetType='Customer'
                  relation={relation}
                  id={model.customerId}
                  onFocus={() => {
                    setSelectedRelation(relation);
                    setTargetType('Customer');
                  }}
                  trigger={triggers?.[relation]}
                  size="small"
                />
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CATEGORIES_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={2} alignContent="flex-start">
            {customerReferencesOptions.map((cRef, index) => (
              <Grid item xs={12} md={6} key={`${cRef}_${index}`}>
                <Autocomplete
                  multiple
                  freeSolo
                  id={cRef}
                  filterSelectedOptions
                  options={[]}
                  value={model.labels[cRef] || []}
                  onChange={(e, value) => {
                    e.preventDefault();
                    handleChange('labels', { ...model.labels, [cRef]: value });
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={lookUp({ key: `CONSOLE_TAGLABELS_${cRef}` })}
                    />
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

TagsTab.propTypes = {
  model: object.isRequired,
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(TagsTab);
