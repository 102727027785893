import { GridOverlay } from '@material-ui/data-grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'absolute',
    top: 0,
    minWidth: '100%',
    width: '100%',
    flexGrow: 1,
  },
}));

const DataGridOverlayLoaderLinear = () => {
  const classes = useStyles();

  return (
    <GridOverlay>
      <LinearProgress className={classes.loader} />
    </GridOverlay>
  );
};

export default DataGridOverlayLoaderLinear;
