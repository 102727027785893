import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import contentAction from 'store/actions/contentAction';


const FORM_FIELD_SIZE = 'small';
const TYPES = ['Remix', 'Playlist'];

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  gutterTop: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
}));

const BasicInfo = ({
  langCodes = [],
  hideTitle = false,
}) => {
  const classes = useStyles();

  const contentData = useSelector((state) => state.content.contentData);
  const dispatch = useDispatch();

  const [originalTitle, setOriginalTitle] = useState(contentData.originalTitle);
  const [originalLanguage, setOriginalLanguage] = useState(contentData.originalLanguage);
  const [description, setDescription] = useState(contentData.localizations?.[contentData.originalLanguage]?.description ?? '');
  const [formErrors, setFormErrors] = useState({
    originalTitle: null,
    description: null,
    originalLanguage: null,
  });

  const updateModel = (prop, value) => {
    const localizations = {
      ...contentData.localizations,
      [contentData.originalLanguage]: {
        ...contentData.localizations?.[contentData.originalLanguage],
        ...(prop === 'originalTitle' && { name: value }),
        ...(prop === 'description' && { description: value }),
      },
    };

    dispatch(contentAction.setContentData({
      ...contentData,
      ...(prop === 'originalTitle' && { originalTitle: value }),
      ...(prop === 'originalLanguage' && { originalLanguage: value }),
      localizations,
    }));
  };

  return (
    <Box flex={1} className={classes.root}>
      <Grid container direction="column" spacing={2}>
        {!hideTitle && (
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_BASIC_INFO' })}
            </Typography>
          </Grid>
        )}
        <Grid
          // FIXME normalize layout in order to remove custom gutterTop the entire wrapper has a negative offset top, fix and remove
          className={classNames({
            [classes.gutterTop]: hideTitle,
          })}
          item
        >
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="originalTitle"
              label={lookUp({ key: 'CONSOLE_ORIGINALTITLE' })}
              aria-describedby="originalTitle-helper"
              autoFocus={!contentData.originalTitle && !contentData.id}
              value={originalTitle}
              onChange={(e) => {
                if (formErrors.originalTitle && e.target.value?.length > 0) {
                  setFormErrors({ ...formErrors, originalTitle: null })
                } else if (!e.target.value.length) {
                  setFormErrors({
                    ...formErrors,
                    originalTitle: lookUp({ key: 'CONSOLE_MISSING_TITLE_ERROR' })
                  });
                }
                setOriginalTitle(e.target.value);
              }}
              required
              error={formErrors.originalTitle}
              onBlur={() => !formErrors.originalTitle && updateModel('originalTitle', originalTitle)}
            />
            <FormHelperText id="originalTitle-helper" error={formErrors.originalTitle} >
              {formErrors.originalTitle
                ? formErrors.originalTitle
                : lookUp({ key: 'CONSOLE_ORIGINALTITLE_HELPERTEXT' })
              }
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="description"
              label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
              aria-describedby="description-helper"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              minRows={4}
              onBlur={() => updateModel('description', description)}
            />
            <FormHelperText id="description-helper">
              {lookUp({ key: 'CONSOLE_DESCRIPTION_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <TextField
              size={FORM_FIELD_SIZE}
              id="originalLanguage"
              label={lookUp({ key: 'CONSOLE_ORIGINALLANGUAGE' })}
              aria-describedby="description-helper"
              value={originalLanguage}
              onChange={(e) => setOriginalLanguage(e.target.value)}
              select
              required
              onBlur={() => updateModel('originalLanguage', originalLanguage)}
            >
              {langCodes.map?.((languageCode) => (
                <MenuItem label={languageCode} value={languageCode} key={languageCode}>
                  {lookUp({ key: `CONSOLE_LANG_${languageCode}` })}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText id="originalLanguage-helper">
              {lookUp({ key: 'CONSOLE_ORIGINALLANGUAGE_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

BasicInfo.propTypes = {
  langCodes: PropTypes.array.isRequired,
  hideTitle: PropTypes.bool,
};

export default BasicInfo;
