import { useState, useCallback } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { Orientation as ORIENTATION } from 'get-orientation/browser'
import { getCroppedImg } from 'components/ImageCropping/canvasUtils'
import { withSnackbar } from 'notistack';
import Cropper from 'react-easy-crop';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CropIcon from '@material-ui/icons/Crop';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ThemedButton from 'components/ThemedButton';
import withFileUpload from 'helpers/withFileS3Upload';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    gap: theme.spacing(1),
  },
}));

const CroppingModal = ({
  enqueueSnackbar,
  upload,
  assetType,
  width,
  height,
  fileUrl,
  aspectRatio,
  handleChange = () => {},
  isPublic = true,
}) => {
  const [open, setOpen] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isIngestLoading, setIsIngestLoading] = useState(false);
  const [uploadProgressValue, setUploadProgressValue] = useState(0);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const uploadFile = async (file) => {
    setIsUploading(true)
    const onUploadProgress = (event) =>
      setUploadProgressValue(Math.round((event.loaded * 100) / event.total));

    try {
      const { publicUrl } = await upload({
        file,
        assetType,
        isPublic,
        onUploadProgress,
        onFileIngestStart: () => setIsIngestLoading(true),
        onFileIngestEnd: () => setIsIngestLoading(false)
      });

      handleChange(publicUrl);
      setIsUploading(false);

      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_FILE_UPLOAD_SUCCESS' })
      });

      handleClose();
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' })
      })
    } finally {
      setUploadProgressValue(0);
    }
  };

  const showCroppedImage = useCallback(async () => {
    setIsUploading(true);
    try {
      const croppedImage = await getCroppedImg(
        fileUrl,
        croppedAreaPixels,
        width,
        height,
        rotation,
      );
      setCroppedImage(croppedImage);

      let blob = await fetch(croppedImage).then(r => r.blob());
      const file = new File([blob], "my_image.jpg", { type: "image/jpg", lastModified: new Date() });
      uploadFile(file);
    } catch (error) {
      setIsUploading(false);
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' })
      })
      handleClose()
      console.error(error);
    }
  }, [fileUrl, croppedAreaPixels, width, height, rotation]);

  return (
    <>
      <Button
        onClick={handleClickOpen}
        startIcon={<CropIcon />}
        color="primary"
        size="small"
      >
        {lookUp({ key: 'CONSOLE_CROP_BUTTON' })}
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar color="transparent" elevation={0}>
          <Toolbar className={classes.toolbar}>
            <Box flex={1} />
            <ThemedButton
              onClick={handleClose}
              aria-label={lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
              color="info"
            >
              {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
            </ThemedButton>
            <ThemedButton
              onClick={showCroppedImage}
              loading={isUploading}
              color="success"
            >
              {lookUp({ key: 'CONSOLE_SAVE_BUTTON' })}
            </ThemedButton>
          </Toolbar>
        </AppBar>
        <Cropper
          image={fileUrl}
          crop={crop}
          rotation={rotation}
          zoom={zoom}
          aspect={aspectRatio}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Dialog>
    </>
  );
}

CroppingModal.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  upload: PropTypes.func.isRequired,
  assetType: PropTypes.oneOf([
    'FileAsset',
    'Content',
    'Partner',
    'Artist',
    'Label',
    'Festival',
    'Catalog',
  ]),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fileUrl: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  handleChange: PropTypes.func,
  isPublic: PropTypes.bool,
};

export default compose(
  withFileUpload,
  withSnackbar
)(CroppingModal);
