import { useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Typography,
  Button,
} from '@material-ui/core';
import { IngestFile } from 'components';
import { addContentRelation } from 'services/collectionsServiceV2';
import AlertService from 'services/alertService';


function IngestModal({
  close,
  upload,
  parentId,
  parentContentType,
  parentLocale,
  enqueueSnackbar,
}) {
  const [resultsFromServer, setResultsFromServer] = useState([]);
  
  const addFiles = () => {
    if (!resultsFromServer?.length) return;
    const reqs = resultsFromServer.map(e => addContentRelation(parentId, 'Playlist', e.id));
    Promise.allSettled(reqs)
    .then(() => {
      close();
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: lookUp({ key: 'CONSOLE_REMIX' }) }),
      });
    });
  }

  return (
    <Dialog open maxWidth={'md'} fullWidth onClose={() => setIngestModalOpen(false)}>
      <DialogTitle>{lookUp({ key: 'CONSOLE_INGEST_TO_REMIX' })}</DialogTitle>
      <DialogContent>
        <IngestFile
          upload={upload}
          enqueueSnackbar={enqueueSnackbar}
          setResultsFromServer={setResultsFromServer}
          parentContentType={parentContentType}
          parentLocale={parentLocale}
        />
        {resultsFromServer?.map((fileUploaded) => (
          <Card key={fileUploaded.id} style={{ backgroundColor: '#BBE1CF' }}>
            <CardContent>
              <Typography>{`${fileUploaded.originalTitle}`}</Typography>
            </CardContent>
          </Card>
        ))}
      </DialogContent>
      <DialogActions>
       <Button onClick={close} variant={'contained'}>
         {lookUp({ key: 'CONSOLE_CLOSE_BUTTON' })}
       </Button>
       <Button
         onClick={addFiles}
         variant={'contained'}
         autoFocus
         disabled={resultsFromServer.length ? false : true}
       >
         {lookUp({ key: 'CONSOLE_ADD_FILE_TO_REMIX' })}
       </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withSnackbar(IngestModal);
