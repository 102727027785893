import { styled } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';


const NormalTextField = styled(TextField)({
  width: '100%'
})
NormalTextField.defaultProps = {
    variant: 'outlined',
    size: "small",
  };

export default NormalTextField