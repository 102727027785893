import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { func, object, array } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, TextField, FormHelperText, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { SettingsInputSvideo } from '@material-ui/icons';
import { EditorInfo, FormFooter, Loader, Tabbing } from 'components';
import PropsForm from '../components/PropsForm';
import DecorationForm from '../components/DecorationForm';
import RulesForm from '../components/RulesForm';
import { goBackOrStay } from 'helpers/common';
import libraryEnumsService from 'services/libraryEnumsService';
import dataGroupService from 'services/dataGroupService';
import dataSourcesService from 'services/dataSourcesService';
import parametersService from 'services/parametersService';
import { setFeedTypes, setParametersItems } from 'store/actions/libraryEnumsAction';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';

function DataSourcesForm(props) {
  const { enqueueSnackbar } = props;

  const { id, tab } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.datasource;
  const feedTypes = useSelector((state) => state.libraryEnums.feedTypes);
  const parametersItems = useSelector((state) => state.libraryEnums.parametersItems);

  const [model, setModel] = useState({
    name: '',
    productId: window.__RUNTIME_CONFIG__.BRAND_ID,
    type: 'Retrieve',
    enabled: true,
    locale: '',
    dataGroupIds: [],
    readerFeedUrl: '',
    writerFeedUrl: '',
    invalidationUrl: '',
    reloadFrequency: 0,
    processorReference: '',
    properties: [],
    forwardProperties: [],
    decoration: {
      properties: [],
      actions: [],
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [feedTypeOptions, setFeedTypeOptions] = useState([]);
  const [isFeedTypeOptionsLoading, setIsFeedTypeOptionsLoading] = useState(false);
  const [selectedDataGroups, setSelectedDataGroups] = useState([]);
  const [isSelectedDataGroupsLoading, setIsSelectedDataGroupsLoading] = useState(false);
  const [dataGroupOptions, setDataGroupOptions] = useState([]);
  const [isDataGroupOptionsLoading, setIsDataGroupOptionsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const tabs = [
    {
      name: 'basic',
      content: (
        <Grid container alignItems={'center'} spacing={2} justifyContent={'center'}>
          {model.id && (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_ID' })}
                  fullWidth
                  value={model.id}
                  disabled
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                required
                label={lookUp({ key: 'CONSOLE_NAME' })}
                helperText={lookUp({ key: 'CONSOLE_DATA_SOURCE_NAME_HELPERTEXT' })}
                fullWidth
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {isFeedTypeOptionsLoading ? (
                <Loader inline />
              ) : (
                <>
                  <Autocomplete
                    label={lookUp({ key: 'CONSOLE_SOURCETYPE' })}
                    options={feedTypeOptions}
                    getOptionLabel={(option) => option.label}
                    placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                    value={
                      (feedTypeOptions && feedTypeOptions.find((o) => o.value === model.type)) ||
                      null
                    }
                    onChange={(event, value) => handleModelChange('type', value.value)}
                    renderInput={(options) => (
                      <TextField
                        {...options}
                        label={lookUp({ key: 'CONSOLE_TYPE' })}
                        variant="outlined"
                        size="medium"
                      />
                    )}
                  />
                  <FormHelperText style={{ marginLeft: '14px' }}>
                    {lookUp({ key: 'CONSOLE_DATASOURCE_TYPE_HELPERTEXT' })}
                  </FormHelperText>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {isDataGroupOptionsLoading || isSelectedDataGroupsLoading ? (
                <Loader inline />
              ) : (
                <>
                  <Autocomplete
                    label={lookUp({ key: 'CONSOLE_DATA_GROUPS' })}
                    multiple
                    options={dataGroupOptions}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, value) => handleSelectedDataGroupsChange(value)}
                    placeholder={lookUp({ key: 'CONSOLE_SELECT_DATA_GROUPS_HELPERTEXT' })}
                    value={
                      dataGroupOptions &&
                      dataGroupOptions.filter((item) =>
                        selectedDataGroups.map((d) => d.value).includes(item.value)
                      )
                    }
                    renderInput={(options) => (
                      <TextField
                        {...options}
                        label={lookUp({ key: 'CONSOLE_DATA_GROUPS' })}
                        variant="outlined"
                      />
                    )}
                  />
                  <FormHelperText style={{ marginLeft: '14px' }}>
                    {lookUp({ key: 'CONSOLE_select_internal_db_name' })}
                  </FormHelperText>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_LOCALE' })}
                helperText={lookUp({ key: 'CONSOLE_DATA_SOURCE_LANGUAGE_SETTINGS_HELPERTEXT' })}
                size="small"
                variant="outlined"
                fullWidth
                value={model.locale || ''}
                onChange={(e) => handleModelChange('locale', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_IO_CONFIGURATION_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_READERFEEDURL' })}
                helperText={lookUp({ key: 'CONSOLE_READERFEEDURL_HELPERTEXT' })}
                size="small"
                variant="outlined"
                fullWidth
                disabled={parametersItems.length === 0}
                value={model.readerFeedUrl || ''}
                onChange={(e) => handleModelChange('readerFeedUrl', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_WRITERFEEDURL' })}
                helperText={lookUp({ key: 'CONSOLE_WRITERFEEDURL_HELPERTEXT' })}
                size="small"
                variant="outlined"
                fullWidth
                disabled={parametersItems.length === 0}
                value={model.writerFeedUrl || ''}
                onChange={(e) => handleModelChange('writerFeedUrl', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_INVALIDATIONURL' })}
                helperText={lookUp({ key: 'CONSOLE_INVALIDATIONURL_HELPERTEXT' })}
                size="small"
                variant="outlined"
                fullWidth
                value={model.invalidationUrl || ''}
                onChange={(e) => handleModelChange('invalidationUrl', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_SCHEDULE_AND_PROCESSING_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_RELOADFREQUENCY' })}
                helperText={lookUp({ key: 'CONSOLE_RELOADFREQUENCY_HELPERTEXT' })}
                type="number"
                min={0}
                max={9999}
                size="small"
                variant="outlined"
                fullWidth
                value={model.reloadFrequency || ''}
                onChange={(e) => handleModelChange('reloadFrequency', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_PROCESSORREFERENCE' })}
                helperText={lookUp({ key: 'CONSOLE_PROCESSORREFERENCE_HELPERTEXT' })}
                size="small"
                variant="outlined"
                fullWidth
                value={model.processorReference || ''}
                onChange={(e) => handleModelChange('processorReference', e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'properties',
      content: (
        <PropsForm
          properties={model.properties}
          handleChange={(data) => handleModelChange('properties', data)}
        />
      ),
    },
    {
      name: 'callback-properties',
      icon: <SettingsInputSvideo />,
      content: (
        <PropsForm
          properties={model.forwardProperties}
          handleChange={(data) => handleModelChange('forwardProperties', data)}
        />
      ),
    },
    {
      name: 'filtering',
      content: (
        <RulesForm
          rules={model.filteringRules}
          handleChange={(data) => handleModelChange('filteringRules', data)}
        />
      ),
    },
    {
      name: 'decoration',
      content: (
        <DecorationForm
          itemDecorations={model.itemDecorations}
          handleChange={(data) => handleModelChange('itemDecorations', data)}
        />
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      getData().then(() => setIsLoading(false));
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_DATA_SOURCE` }) })
        )
      );
    }
    parametersItems.length === 0 && getParameters().then(() => false);
    getFeedTypeOptions().then(() => setIsFeedTypeOptionsLoading(false));
    getDataGroupOptions().then(() => setIsDataGroupOptionsLoading(false));
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSelectedDataGroupsChange = (options) => {
    options
      ? handleModelChange(
          'dataGroupIds',
          options.map(({ value }) => value)
        )
      : handleModelChange('dataGroupIds', options);
    setSelectedDataGroups(options);
  };

  const getParameters = async () => {
    try {
      const { totalCount } = await parametersService.search({ page: 0, perPage: 1 });
      const { data } = await parametersService.search({ page: 0, perPage: totalCount });
      let par = [];
      data.map((p) => p.key.toLowerCase().split(':')).map((p) => par.push(...p));
      dispatch(setParametersItems([...new Set(par)]));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_PARAMETERS' }),
        }),
      });
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await dataSourcesService.get(id);
      dispatch(
        navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name }))
      );
      getSelectedDataGroups(data.dataGroupIds).then(() => setIsSelectedDataGroupsLoading(false));
      setModel(data);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_SELECTED_DATA_GROUPS' }),
        }),
      });
      setIsLoading(false);
    }
  };

  const getFeedTypeOptions = async () => {
    setIsFeedTypeOptionsLoading(true);
    try {
      const options = feedTypes.length === 0 ? await libraryEnumsService.getFeedTypes() : feedTypes;
      feedTypes.length === 0 && dispatch(setFeedTypes(options));
      setFeedTypeOptions(
        options.map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o }))
      );
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_DATA_GROUP_TYPES' }),
        }),
      });
      setIsFeedTypeOptionsLoading(false);
    }
  };

  const getDataGroupOptions = async () => {
    setIsDataGroupOptionsLoading(true);
    try {
      const { totalCount } = await dataGroupService.search({ page: 0, perPage: 1 });
      const { data } = await dataGroupService.search({ page: 0, perPage: totalCount });
      setDataGroupOptions(data.map((p) => ({ label: p.name, value: p.id })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_DATA_GROUP_OPTIONS' }),
        }),
      });
      setIsDataGroupOptionsLoading(false);
    }
  };

  const getSelectedDataGroups = async (items) => {
    setIsSelectedDataGroupsLoading(true);

    await Promise.all(
      items.map((dgId) =>
        dataGroupService.get(dgId).then((dgModel) => ({
          value: dgModel.id,
          label: dgModel.name,
        }))
      )
    )
      .then((res) => {
        setSelectedDataGroups(res);
      })
      .catch((error) =>
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SELECTED_DATA_GROUP_OPTIONS' }),
          }),
        })
      );
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {
      const saveModel = JSON.parse(JSON.stringify(model));
      if (publish) {
        saveModel.enabled = !model.enabled;
      }

      saveModel.properties && saveModel.properties.length === 0 && delete saveModel.properties;
      saveModel.forwardProperties &&
        saveModel.forwardProperties.length === 0 &&
        delete saveModel.forwardProperties;
      saveModel.decoration &&
        saveModel.decoration.properties &&
        saveModel.decoration.properties.length === 0 &&
        delete saveModel.decoration.properties;
      saveModel.decoration &&
        saveModel.decoration.actions &&
        saveModel.decoration.actions.length === 0 &&
        delete saveModel.decoration.actions;
      saveModel.decoration &&
        saveModel.decoration.actions &&
        saveModel.decoration.actions.forEach((action, index) => {
          if (action.schedule && action.schedule.type === '') {
            delete saveModel.decoration.actions[index].schedule;
          }
        });
      id ? await dataSourcesService.edit(saveModel) : await dataSourcesService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      const toWhere = window.history.state.idx === 0 ? '/apps/data-sources' : -1;
      goBackOrStay(navigate, toWhere);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const deleteDataSource = async () => {
    setIsDeleting(true);
    try {
      await dataSourcesService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/apps/data-sources'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', type: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <div fullWidth>
      <Tabbing
        tabs={tabs
          .sort(
            (a, b) =>
              allowedTabs.indexOf(a.name?.replace(' ', '-')) -
              allowedTabs.indexOf(b.name?.replace(' ', '-'))
          )
          .filter((e) => allowedTabs.includes(e.name))}
      />
      <FormFooter
        isSaving={isSaving}
        isPublishing={model.enabled}
        existPublishing={true}
        isDeleting={isDeleting}
        deleteData={deleteDataSource}
        onSave={() => saveData(false)}
        onCancel={() => navigate(window.history.state.idx === 0 ? '/' : -1)}
        onPublish={() => saveData(true)}
        saveButtonText={
          id ? lookUp({ key: 'CONSOLE_SAVE_BUTTON' }) : lookUp({ key: 'CONSOLE_CREATE_BUTTON' })
        }
        isNewItem={!id}
        publishButtonText={
          model.enabled
            ? lookUp({ key: 'CONSOLE_UNPUBLISH_BUTTON' })
            : lookUp({ key: 'CONSOLE_PUBLISH_BUTTON' })
        }
      />
      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </div>
  );
}

DataSourcesForm.propTypes = {
  enqueueSnackbar: func.isRequired,
  selectedBrand: object.isRequired,
  feedTypes: array.isRequired,
  catalogItems: array.isRequired,
  parametersItems: array.isRequired,
};

export default withSnackbar(DataSourcesForm);
