import React from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Link, Typography, CardMedia } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import PlayBackHistoryTable from 'components/PlayBackHistoryTable';
import CreatedOn from 'components/CreatedOn'
import { getCollection } from 'services/collectionsService';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200
  },
  enabled: {
    backgroundColor: green[500]
  },
  notEnabled: {
    backgroundColor: red[500]
  },
  image: {
    width: '80px',
    height: '45px',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '70%',
 },
}));

const PlayBackHistory = ({id}) => {
  
  const classes = useStyles();

  const theadElements = [
    {
      name: 'cover',
      label: '',
      align: 'left',
      smallColumn: true
    },
    {
      name: 'title',
      label: lookUp({ key: 'CONSOLE_TITLE' }),
      align: 'left'
    },
    {
      name: 'createdDate',
      label: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'right'
    },
  ];

  const tbodyElements = [
    {
      name: 'title',
      align: 'left',
      render: (item) => (
       <Link to={`/content/${item.value.content.id}/edit`} component={RouterLink}>
         <CardMedia className={classes.image} image={item.value.content.assets.find(e => e.subType === 'Landscape' && e.workflowStatus === 'Succeeded') ? item.value.content.assets.find(e => e.subType === 'Landscape' && e.workflowStatus === 'Succeeded').publicUrl : ''}  title={item.value.content.originalTitle}>
				</CardMedia>
      </Link>
       
      )
    },
    {
      name: '',
      align: 'left',
      render: (item) => (
        <Link to={`/content/${item.value.content.id}/edit`} component={RouterLink}>
        <Typography>
         {item.value.content.originalTitle}
        </Typography> 
      </Link>
      )
    },
    {
      name: 'createdDate',
      render: (item) =>  (
        <Typography><CreatedOn date={item.value.content.createdDate} /></Typography>
      )
    }
  ];

  return (
    <PlayBackHistoryTable
      customerId={id}
      pageTitle={lookUp({ key: 'CONSOLE_PLAYBACK_HISTORY_TITLE' })}
      tableHeadElements={theadElements}
      tableBodyElements={tbodyElements}
      loadData={() => getCollection(id, 'Playback', 'Content')}
    />
  );
};

export default PlayBackHistory