const TOGGLE_MOBILE_SIDEBAR = 'TOGGLE_MOBILE_SIDEBAR';
const OPEN_MOBILE_SIDEBAR = 'OPEN_MOBILE_SIDEBAR';
const CLOSE_MOBILE_SIDEBAR = 'CLOSE_MOBILE_SIDEBAR';
const TOGGLE_DESKTOP_SIDEBAR = 'TOGGLE_DESKTOP_SIDEBAR';
const OPEN_DESKTOP_SIDEBAR = 'OPEN_DESKTOP_SIDEBAR';
const CLOSE_DESKTOP_SIDEBAR = 'CLOSE_DESKTOP_SIDEBAR';
const SET_COLLAPSE_SIDEBAR = 'SET_COLLAPSE_SIDEBAR';
const CHANGE_SIDEBAR_ITEM_HIGHLIGHT = 'CHANGE_SIDEBAR_ITEM_HIGHLIGHT';
const BLOCK_NAVIGATION = 'BLOCK_NAVIGATION';
const ALLOW_NAVIGATION = 'ALLOW_NAVIGATION';
const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
const CHANGE_SIDEBAR_OPEN_SUBMENUS = 'CHANGE_SIDEBAR_OPEN_SUBMENUS';
const RETURN_TO_LMS_MENU_OPTION = 'RETURN_TO_LMS_MENU_OPTION';

const toggleMobileSidebar = () => ({ type: TOGGLE_MOBILE_SIDEBAR });

const openMobileSidebar = () => ({ type: OPEN_MOBILE_SIDEBAR });

const closeMobileSidebar = () => ({ type: CLOSE_MOBILE_SIDEBAR });

const openDesktopSidebar = () => ({ type: OPEN_DESKTOP_SIDEBAR });

const closeDesktopSidebar = () => ({ type: CLOSE_DESKTOP_SIDEBAR });

const setCollapseSidebar = (collapsed) => ({ type: SET_COLLAPSE_SIDEBAR, collapsed });

const changeSidebarItemHighlight = (highlightedSidebarItem) => ({ type: CHANGE_SIDEBAR_ITEM_HIGHLIGHT, highlightedSidebarItem});

const setPageTitle = (pageTitle) => ({ type: SET_PAGE_TITLE, pageTitle });

const blockNavigation = () => ({ type: BLOCK_NAVIGATION });

const allowNavigation = () => ({ type: ALLOW_NAVIGATION });

const changeSidebarOpenSubmenus = (openSidebarSubs) => ({ type: CHANGE_SIDEBAR_OPEN_SUBMENUS, openSidebarSubs })

const setLMSReturnURL = (lmsReturnURL) => ({ type: RETURN_TO_LMS_MENU_OPTION, lmsReturnURL });

export {
  TOGGLE_MOBILE_SIDEBAR,
  OPEN_MOBILE_SIDEBAR,
  CLOSE_MOBILE_SIDEBAR,
  TOGGLE_DESKTOP_SIDEBAR,
  OPEN_DESKTOP_SIDEBAR,
  CLOSE_DESKTOP_SIDEBAR,
  SET_COLLAPSE_SIDEBAR,
  CHANGE_SIDEBAR_ITEM_HIGHLIGHT,
  BLOCK_NAVIGATION,
  ALLOW_NAVIGATION,
  SET_PAGE_TITLE,
  CHANGE_SIDEBAR_OPEN_SUBMENUS,
  RETURN_TO_LMS_MENU_OPTION,
};

export default {
  toggleMobileSidebar,
  openMobileSidebar,
  closeMobileSidebar,
  openDesktopSidebar,
  closeDesktopSidebar,
  setCollapseSidebar,
  changeSidebarItemHighlight,
  blockNavigation,
  allowNavigation,
  setPageTitle,
  changeSidebarOpenSubmenus,
  setLMSReturnURL,
};
