import { lookUp } from 'services/stringService';
import { IconButton, makeStyles } from '@material-ui/core';
import { Clear, Edit, Update, FirstPage, LastPage } from '@material-ui/icons';
import { segmentTypes, markerTitleMapping } from 'constants/index.js';
import { getMs, msToTime } from 'helpers/time';

const useStyles = makeStyles((theme) => { 
  return ({
  card: {
    color: theme.palette.primary?.contrastText,
    fontWeight: 700,
    minWidth: props => props.fullWidth ? '100%' : 200,
    maxWidth: props => props.fullWidth ? '100%' : 200,
    height: 120,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 5px 5px 10px',
    backgroundColor: props => props.isNewMarker ? theme.palette.primary?.light : props.isSelected ? theme.palette?.secondary?.main : theme.palette.primary?.main,
    marginRight: 5,
    cursor: 'pointer',
    borderRadius: props => props.square ? 0 : 10,
    overflow: 'hidden',
    '&:hover': {
      opacity: .9,
    },
    '& svg': { fill: theme.palette.primary?.contrastText },
  },
  title: {
    width: '80%',
    padding: 0,
    marginLeft: '-20%',
    height: '3em',
    overflow: 'hidden',
  },
  topRight: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  bottomRight: {
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
});

const MarkerCard = ({
  marker,
  isSelected,
  isNewMarker,
  setOpenModal,
  removeMarker,
  seek,
  loop,
  setLoop,
  fullWidth,
  square
}) => {
  const classes = useStyles({isSelected, isNewMarker, fullWidth, square});
  const { type, text, id, position, end } = marker;
  const title = marker.title || marker[markerTitleMapping[type]]
  
  return (
    <article>
      <div className={classes.card}>
        <div
          className={classes.title}
        >
          {title || text || lookUp({ key: `CONSOLE_${type}` })}
        </div>
        <div className={classes.topRight}>
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              removeMarker(id);
            }}
          >
            <Clear />
          </IconButton>
        </div>
        <div className={classes.footer}>
          <div>
            <IconButton 
              onClick={(event) => 
                { 
                  event.stopPropagation();
                  seek && seek(msToTime(Math.max(0, (getMs(position) - 2000)))); 
                }
              } 
              size="small"
              style={{
                backgroundColor: (
                  loop && getMs(loop[0]) + 1000 === getMs(position) && getMs(loop[1]) - 1000 === getMs(position)) 
                ? '#e57b1e' 
                : '' 
              }}
              >
              <FirstPage />
            </IconButton>
            {position.split('.')[0]}
            <br/>
            { segmentTypes.includes(type) && 
              <>
                <IconButton 
                  onClick={(event) => 
                    { 
                      event.stopPropagation();
                      seek && seek(msToTime(Math.max(0, (getMs(end) - 2000)))); 
                    }
                  } 
                  size="small"
                  style={{
                    backgroundColor: (
                      loop && ((getMs(loop[0]) + 1000) === getMs(end)) && ((getMs(loop[1]) - 1000) === getMs(end))) 
                    ? '#e57b1e' 
                    : '' 
                  }}
                  >
                  <LastPage />
                </IconButton>{isNewMarker ? '––:––:––' : end.split('.')[0]}
              </>
            }
          </div>
          <div className={classes.bottomRight}>          
            {setLoop && 
              <IconButton
                size="small"
                onClick={() => {
                  setLoop(l => 
                  +  (!l || l?.[0] !== position || l?.[1] !== end) 
                    ? [position, end] 
                    : null);
                }}
                style={{
                  backgroundColor: `rgba(255,255,255,${!(!loop || loop?.[0] !== position || loop?.[1] !== end) ? .4 : 0}`
                  // ${!(!loop || loop?.[0] !== position || loop?.[1] !== end) 
                //   ? 1 
                //   : 0})
                // }`
              }}
              >
                <Update />
              </IconButton>
            }
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                setOpenModal(id);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        </div>
      </div>
    </article>
  );
};

export default MarkerCard;
