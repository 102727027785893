import React, { useState } from 'react';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import {
  Button,
  Grid,
  TextField,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import { lookUp } from 'services/stringService';
import { createVODtoLive } from 'services/contentService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: 12,
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
}));

const VodToLive = ({ id, asset, setOpen, enqueueSnackbar }) => {
  const classes = useStyles();

  const startTime = new Date(new Date().setMinutes(new Date().getMinutes() + 10));
  const [timeValidation, setTimeValidation] = useState(false);
  const [liveEvent, setLiveEvent] = useState({
    liveStreamTitle: '',
    startTime: startTime,
    objectUrl: asset?.objectUrl,
    duration: asset?.duration,
  });

  const createLiveEvent = async () => {
    try {
      await createVODtoLive(id, liveEvent);
      setOpen('vodToLive', false);
      setLiveEvent((prev) => ({ ...prev, liveStreamTitle: '', startTime })); // reset fields
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_CREATED_LIVE' }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_CREATE_LIVE_ERROR' }),
      });
    }
  };

  const changeStartDateTime = (time) => {
    setTimeValidation(false);
    if (new Date(time) - new Date() < 0) { // if the selected time is in the past
      setTimeValidation(true);
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        context: lookUp({ key: 'CONSOLE_PAST_TIME_ERROR' }),
      });
    }
    setLiveEvent({ ...liveEvent, startTime: new Date(time) });
  };

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_CREATE_LIVE_EVENT' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('vodToLive', false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <Grid container xs={12} direction="column" spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={lookUp({ key: 'CONSOLE_EVENT_TITLE' })}
              value={liveEvent.liveStreamTitle || ''}
              onChange={(event) =>
                setLiveEvent({ ...liveEvent, liveStreamTitle: event.target.value })
              }
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_EVENTDATE' })}
                variant="dialog"
                inputVariant="outlined"
                size="small"
                value={liveEvent.startTime || new Date()}
                placeholder={lookUp({ key: 'CONSOLE_EVENTDATE' })}
                autoOk={false}
                disablePast
                onChange={changeStartDateTime}
                format="DD/MM/YYYY"
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardTimePicker
                label={lookUp({ key: 'CONSOLE_EVENTTIME' })}
                variant="dialog"
                inputVariant="outlined"
                size="small"
                value={liveEvent.startTime || new Date()}
                error={timeValidation}
                placeholder={lookUp({ key: 'CONSOLE_EVENTTIME' })}
                ampm={false}
                autoOk={false}
                onChange={changeStartDateTime}
                format="HH:mm"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
        <DialogActions>
          <Grid item xs={12} className={classes.dialogActions}>
            <Button  onClick={createLiveEvent} color="primary">
              {lookUp({ key: 'CONSOLE_CREATE' })}
            </Button>
          </Grid>
        </DialogActions>
    </>
  );
};

export default withSnackbar(VodToLive);
