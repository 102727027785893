import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HideOnScroll from 'components/HideOnScroll';
import { sidebarWidth } from 'constants/index.js';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    width: '100%',
    alignItems: 'middle',
    wrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  container: (props) => ({
    display: 'flex',
    position: 'fixed',

    // FIXME calc top dynamically based on Header clientHeight
    top: HEADER_MIN_HEIGHT,
    minHeight: HEADER_MIN_HEIGHT,
    right: '100%',
    left: props.isFullWidth ? sidebarWidth.closed : sidebarWidth.open,
    width: `calc(100% - ${props.isFullWidth ? sidebarWidth.closed : sidebarWidth.open}px)`,
    zIndex: theme.zIndex.appBar,
  }),
  offset: theme.mixins.toolbar,
}));

const HeaderActionsContainer = ({ children }) => {
  const isFullWidth = useSelector((state) => state.navigation.isSidebarCollapsed);
  const classes = useStyles({ isFullWidth });

  return (
    <>
      <div className={classes.offset} />
      <HideOnScroll>
        <Box className={classes.container}>
          <Toolbar className={classes.toolbar}>
            {children}
          </Toolbar>
        </Box>
      </HideOnScroll>
    </>
  );
};

HeaderActionsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderActionsContainer;
