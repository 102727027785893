import { parseBlob } from 'music-metadata-browser';

const MB = 1024 * 1024;

const getMetadata = (file) =>
  new Promise(async (resolve) => {
    if (file.size > 100 * MB) {
      return resolve({});
    }

    const metadata = await parseBlob(file);

    return resolve(metadata);
  });

const getDuration = (file) =>
  new Promise((resolve, reject) => {
    const audio = document.createElement('audio');

    audio.onloadedmetadata = () => {
      resolve(audio.duration);
    };

    audio.onerror = (err) => reject(err);

    audio.src = URL.createObjectURL(file);
  });

export { getMetadata, getDuration };

export default {
  getMetadata,
  getDuration
};
