import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLastRoute } from 'store/actions/authAction';
import navigationAcion from 'store/actions/navigationAction';
import { signIn } from 'services/customersService';
import AlertService from 'services/alertService';
import { withSnackbar } from 'notistack';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search, [search]));
};

const SSOLite = ({ enqueueSnackbar }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    redirectUrl,
    provider,
    userId,
    token,
    launch_presentation_return_url,
  } = Object.fromEntries(query.entries());

  useEffect(() => {
    signIn({ provider, userId, token }, dispatch)
      .then(() => {
        if (launch_presentation_return_url) {
          const lmsURL = decodeURIComponent(launch_presentation_return_url);
          dispatch(navigationAcion.setLMSReturnURL(lmsURL));
        }
        navigate(decodeURIComponent(redirectUrl) || '/');
        dispatch(setLastRoute(decodeURIComponent(redirectUrl)));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
        navigate('/');
      })
  }, []);

  return <></>;
};

export default withSnackbar(SSOLite);
