import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    // Container by default is centered, remove margin override in order to center the content
    marginLeft: 0,
  },
}));

const SectionContainer = ({
  children,
  maxWidth,
  disableGutters = true,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.root} maxWidth={maxWidth} disableGutters={disableGutters} {...rest}>
      {children}
    </Container>
  );
};

SectionContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  disableGutters: PropTypes.bool,
};

export default SectionContainer;
