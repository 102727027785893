import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';
import AlertService from 'services/alertService';
import contentService from 'services/contentService';

const useStyles = makeStyles(() => ({
  input: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
  },
}));

const ContentTypeDropdown = (props) => {
  
  const classes = useStyles();
  const { enqueueSnackbar, onAttribChange, selectedNode } = props;

  const [contentTypeOptions, setContentTypeOptions] = useState([]);

  const handleSelect = (value) => {
    if (!value) return;
    
    //TODO: check the correct property to be set and fix it
    onAttribChange(value, 'text', selectedNode.id, 'contentType'); 
  };

  useEffect(() => {
    contentService
      .getTypeEnums()
      .then((types) => {
        const contentTypes = types.map((type) => {
          for (let key in type) {
            return key;
          }
        });
        setContentTypeOptions(contentTypes);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_CONTENT_TYPE'} ) }),
        });
      });
  }, []);

  return (
    <>
      <TextField
        className={classes.input}
        select
        label={lookUp({ key: 'CONSOLE_CONTENT_TYPE'} )}
        fullWidth
        value={selectedNode.contentType}
        onChange={(event) => handleSelect(event.target.value)}
      >
        {contentTypeOptions.map((o, index) => (
          <MenuItem label={o} key={`${o}_${index}`} value={o}>
            {o}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default ContentTypeDropdown;
