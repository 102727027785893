import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getAuthGroupEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('AuthGroup');
  if (!endpoint) throw Error('No Monetization endpoint.')
  return endpoint;
};

const getAuthGroupEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('AuthGroupEnums');
  if (!endpoint) throw Error('No Monetization Enum endpoint.')
  return endpoint;
};

const search = (
  { page = 0, perPage = 1000, sortBy = 'name asc', q = '', ...rest } = {}
) =>
  axios
    .get(
      makeApiWithQuery(`${getAuthGroupEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        q,
        ...rest
      })
    )
    .then((res) => res?.data);

const create = (model) => axios.post(getAuthGroupEndpoint(), model)
  .then((r) => r?.data);

const get = (id) => axios.get(`${getAuthGroupEndpoint()}/${id}`)
  .then((r) => r?.data);

const edit = (id, model) => axios.put(`${getAuthGroupEndpoint()}/${id}`, model)
  .then((r) => r?.data);

const remove = (id) => axios.delete(`${getAuthGroupEndpoint()}/${id}`)
  .then((r) => r?.data);

export { search, create, get, edit, remove };

export default {
  search,
  create,
  get,
  edit,
  remove
};