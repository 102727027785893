import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { func, string } from 'prop-types';
import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
);

const PublishModal = (props) => {
  

  const { enqueueSnackbar, handleClose, saveData } = props;
  const classes = useStyles();
  const [model, setModel] = useState({
    domainName: '',
    awsAccountId: '',
    awsAccessKeyId: '',
    awsSecretAccessKey: '',
    networking: {
      vpcCidr: '',
      subnetCidr1: '',
      subnetCidr2: ''
    }
  });

  const handleNetworkingChange = (key, value) => {
    setModel((prev) => ({
      ...prev, networking: { ...prev.networking, [key]: value }
    }))
  }

  const handleModelChange = (key, value) => {
    if (key === 'awsAccountId') {
      const re = /^[0-9\b]+$/;
      if (value === '' || !re.test(value)) {
        return;
      }
    }

    setModel((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  return (
    <Dialog open onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <Typography variant="h5">AWS Configuration</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_DOMAINNAME' })}
              required
              fullWidth
              value={model.domainName}
              helperText={<small>Used domain name (e.g. entmusic.io) </small>}
              onChange={(e) => handleModelChange('domainName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_AWSACCOUNTID' })}
              required
              type="text"
              pattern="[0-9]*"
              fullWidth
              value={model.awsAccountId}
              helperText={<small>AWS Account (Only Numbers) </small>}
              onChange={(e) => handleModelChange('awsAccountId', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_AWSACCESSKEY' })}
              required
              fullWidth
              value={model.awsAccessKeyId}
              helperText={<small>AWS Account Secret Key</small>}
              onChange={(e) => handleModelChange('awsAccessKeyId', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_AWSSECRETACCESSKEY' })}
              required
              fullWidth
              value={model.awsSecretAccessKey}
              helperText={<small>AWS Account Secret Access Key</small>}
              onChange={(e) => handleModelChange('awsSecretAccessKey', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5">Networking Configuration</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_VPCCIDR' })}
              required
              fullWidth
              helperText={<small>(e.g. 10.1.0.0/16)</small>}
              value={model.vpcCidr}
              onChange={(e) => handleNetworkingChange('vpcCidr', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_SUBNETCIDR1' })}
              required
              fullWidth
              value={model.subnetCidr1}
              helperText={<small>(e.g. 10.1.2.0/24)</small>}
              onChange={(e) => handleNetworkingChange('subnetCidr1', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_SUBNETCIDR2' })}
              required
              fullWidth
              helperText={<small>(e.g. 10.1.2.0/24)</small>}
              value={model.subnetCidr2}
              onChange={(e) => handleNetworkingChange('subnetCidr2', e.target.value)}
            />
          </Grid>
          The publishing procedure will take approx. 30-35 minutes. You will get an e-mail notification when it has been done!
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button onClick={() => saveData(false)} color="primary" >
            Publish
          </Button>
      </DialogActions>
    </Dialog>
  );
};

PublishModal.defaultProps = {
  enqueueSnackbar: func,
};

export default PublishModal;
