import {
  Avatar,
  Chip,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { Clear } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  filtersBoxClearButton: {
    padding: 0,
  },
}));

const FiltersBox = (props) => {
  
  const { additionalFiltersState, filtersboxCustomization, setFilters } = props;
  const classes = useStyles();

  const handleRemove = (key, value) => {
    const resetAll = (obj) =>
      Object.keys(obj).reduce((acc, curr) => {
        acc[curr] = '';
        return acc;
      }, {});
    setFilters(
      key
        ? {
            ...additionalFiltersState,
            [key]: value ? additionalFiltersState[key].filter((e) => e !== value) : '',
          }
        : { ...resetAll(additionalFiltersState), contentType: additionalFiltersState.contentType }
    );
  };

  const customized = filtersboxCustomization;

  const labelFormat = (key) => {
    return customized[key] && customized[key].label
      ? customized[key].label
      : additionalFiltersState[key];
  };

  return (
    <>
      {additionalFiltersState &&
        Object.keys(additionalFiltersState)
          .filter((key) => {
            if (key === 'type') return false;
            return !!additionalFiltersState[key];
          })
          .map((key, index) =>
            Array.isArray(additionalFiltersState[key]) ? (
              additionalFiltersState[key].map((value, i) => (
                <Chip
                  variant="outlined"
                  size="small"
                  style={{ margin: '.15em' }}
                  key={`${i}-${key}-${value}`}
                  label={decodeURI(value).replaceAll('+', ' ')}
                  avatar={
                    customized[key] && customized[key].icon ? (
                      <Avatar>{customized[key].icon}</Avatar>
                    ) : (
                      <Avatar>{value.toUpperCase()}</Avatar>
                    )
                  }
                  onDelete={() => handleRemove(key, value)}
                />
              ))
            ) : (
              <Chip
                variant="outlined"
                size="small"
                style={{ margin: '.15em' }}
                key={`${index}-${key}`}
                label={decodeURI(labelFormat(key)).replaceAll('+', ' ')}
                avatar={
                  customized[key] && customized[key].icon ? (
                    <Avatar>{customized[key].icon}</Avatar>
                  ) : (
                    <Avatar>{[...key][0].toUpperCase()}</Avatar>
                  )
                }
                onDelete={() => handleRemove(key)}
              />
            )
          )}
      {additionalFiltersState &&
        Object.keys(additionalFiltersState).filter((key) => {
          if (key === 'contentType') return false;
          return !!additionalFiltersState[key];
        }).length > 0 && (
          <IconButton
            size="small"
            onClick={() => handleRemove()}
            className={classes.filtersBoxClearButton}
          >
            <Clear />
          </IconButton>
        )}
    </>
  );
};

export default FiltersBox;