import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Link } from 'react-router-dom';
import { arrayOf, bool, func, oneOfType, shape, string, object, number } from 'prop-types';
import { withSnackbar } from 'notistack';
import {initialState} from 'store/reducers/previewReducer'
import { connect } from 'react-redux';
import { compose } from 'redux';


import {
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  InputAdornment,
  InputLabel,
  Fab,
  FormControl,
  Grid,
  FilledInput,
  makeStyles, 
  Button
} from '@material-ui/core';
import { Add, Search, ClearAll } from '@material-ui/icons';

import {  CenteredBlock, ConfirmDialog, Loader } from './index';

function EnhancedTableHead(props) {
  const { classes, order, sortBy, onRequestSort, headCells, deleteItem } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          headCell.isSortable ? (
            <TableCell
              key={index}
              align={headCell.align || 'right'}
              padding={'default'}
              sortDirection={sortBy === headCell.name ? order : false}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
              <TableSortLabel
                active={sortBy === headCell.name}
                direction={order}
                onClick={createSortHandler(headCell.name)}
              >
                <strong>{headCell.label}</strong>
                {sortBy === headCell.name ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              align={headCell.align || 'right'}
              key={headCell.name}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
              <strong>{headCell.label}</strong>
            </TableCell>
          )
        )}
        {deleteItem && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  onRequestSort: func.isRequired,
  order: string.isRequired,
  sortBy: string.isRequired,
  headCells: arrayOf(object).isRequired,
  deleteItem: bool
};





const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  emptyCell: {
    border: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  smallColumn: {
    width: '5%'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
}));

const EnhancedTable = (props) => {
  
  const {  selectedBrand } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(props.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [order, setOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('');
  const [count, setCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagingToken, setPagingToken] = useState('');
  const [isClearFiltersActive, setIsClearFiltersActive] = useState(false);

  const {
    loadData,
    pageTitle,
    tableBodyElements,
    tableHeadElements,
    additionalFilters,
    additionalFiltersState,
    requestAdditionalFiltersState,
    clearAdditionalFilters,
    customToolbar,
    enqueueSnackbar,
    rowColorFunc,
    onPageChange,
    handleChangePage,
    PageChange,
    pageNumber
  } = props;


  const getMore = async () => {
    setIsLoading(true);
    try {
        const result = await handleChangePage(pagingToken);

        setPagingToken(result.pagingToken)
        
        const newObj = data.concat(result.pageContent)
        //setData(result.pageContent);

        setData(newObj);
        setError(null);
        setIsLoading(false);
    } catch (err) {
      let error = err;

      if (err.response && [401, 403].includes(err.response.status))
        error = 'You have no access for this operation';

      setError(error);
      setIsLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setSortBy(property);
    setIsClearFiltersActive(true);
  };
 

  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && query) {
      e.preventDefault();

      handleSearch();
    }
  };

  const handleSearch = () => {
    getData().then(() => {
      setIsLoading(false);
      setIsSearchActive(false);
      setIsClearFiltersActive(true);
    });
    setQuery('');
  };

  const clearFilters = () => {
    setOrder('asc');
    setQuery('');
    setIsSearchActive(false);
    setSortBy('');
    onPageChange(0);
    setIsClearFiltersActive(false);

    clearAdditionalFilters && clearAdditionalFilters();

    getData(true).then(() => {
      setIsSearchActive(false);
    });
  };

  const getData = async () => {
    setIsLoading(true);
    try {
        const { pagingToken, pageContent, count, totalCount } = await loadData();
        setPagingToken(pagingToken)
        setData(pageContent);
        setCount(count || totalCount);
        setError(null);
        setIsLoading(false);
    } catch (err) {
      let error = err;

      if (err.response && [401, 403].includes(err.response.status))
        error = 'You have no access for this operation';

      setError(error);
      setIsLoading(false);
    }
  };


  const addFilters = JSON.stringify(additionalFiltersState);

  useEffect(() => {
    !isSearchActive && setIsSearchActive(true);
  }, [query, requestAdditionalFiltersState]);

  useEffect(() => {
    if (!isDeleting) {
      getData().then(() => {
        setIsLoading(false);
        setIsSearchActive(false);
      });
    }
  }, [order, sortBy, page, addFilters]);

  if (error) {
    return (
      <CenteredBlock>
        <Typography variant={'h3'} color={'error'}>
          Error
        </Typography>
        <Typography variant={'h4'} color={'error'}>
          {JSON.stringify(
            error && error.response && error.response.data && error.response.data.message,
            null,
            2
          )}
        </Typography>
      </CenteredBlock>
    );
  }

  return (
   
    <div className={classes.root}>
      <div>
       
        <div className={classes.tableWrapper}>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
               <EnhancedTableHead
                classes={classes}
                order={order}
                sortBy={sortBy}
                onRequestSort={handleRequestSort}
                headCells={tableHeadElements}
              />
              {data && data.length !== 0 && (
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      hover
                      key={item.id || index}
                      {...(rowColorFunc && { className: rowColorFunc(item) })}
                    >
                      {tableBodyElements.map((element) => (
                        <TableCell
                          align={element.align || 'right'}
                          key={element.name}
                          component={element.name === 'name' ? 'th' : ''}
                          scope={element.name === 'name' ? 'row' : ''}
                        >
                          {element.render ? element.render(item) : item[element.name].toISOString()}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          )}
          {!isLoading && (!data || data.length === 0) && (
            <CenteredBlock height="10vh">
              <Typography variant="h5">No records found</Typography>
            </CenteredBlock>
          )}
        </div> 
        {pagingToken && (
          <Grid item xs={12} style={{textAlign:'center'}}>
            <Button  onClick={getMore}  color="primary"  className={classes.marginTop}>Load More</Button>
          </Grid>
        )}
      </div>
    </div>
  );
};

EnhancedTable.propTypes = {
  pageTitle: string,
  pageNumber: number,
  selectedBrand: shape({
	  brandId: string,
}),
  tableHeadElements: arrayOf(
    shape({
      name: string,
      label: string.isRequired,
      isSortable: bool,
      align: string,
      smallColumn: bool
    })
  ).isRequired,
  tableBodyElements: arrayOf(
    oneOfType([
      shape({
        name: string.isRequired,
        align: string,
        render: func // item => result in td. Can be omit. By default renders item[name]
      })
    ])
  ).isRequired,
  loadData: func,
  deleteItem: func,
  additionalFilters: arrayOf(func),
  additionalFiltersState: object,
  requestAdditionalFiltersState: object,
  clearAdditionalFilters: func,
  customToolbar: func,
  enqueueSnackbar: func.isRequired,
  rowColorFunc: func,
};

EnhancedTable.defaultProps = {
  pageTitle: '',
  tableClassName: '',
  rowColorFunc: null
};

const mapStateToProps = ({ auth: {  selectedBrand } }) => ({
	selectedBrand
  });

export default withSnackbar(compose(
    connect(
      mapStateToProps,
    ),
    )(EnhancedTable));