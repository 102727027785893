import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Link } from 'react-router-dom';
import { arrayOf, bool, func, oneOfType, shape, string, object, number } from 'prop-types';
import { withSnackbar } from 'notistack';
import {initialState} from 'store/reducers/previewReducer'
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  InputAdornment,
  InputLabel,
  Fab,
  FormControl,
  Grid,
  FilledInput,
  makeStyles,
  Button,
  OutlinedInput
} from '@material-ui/core';
import { Add, Search, ClearAll } from '@material-ui/icons';
import { BreadcrumbsComponent, CenteredBlock, ConfirmDialog, Loader } from './index';
import AlertService from 'services/alertService'


function EnhancedTableHead(props) {
  const { classes, order, sortBy, onRequestSort, headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          headCell.isSortable ? (
            <TableCell
              key={index}
              align={headCell.align || 'right'}
              padding={'default'}
              sortDirection={sortBy === headCell.name ? order : false}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
              <TableSortLabel
                active={sortBy === headCell.name}
                direction={order}
                onClick={createSortHandler(headCell.name)}
              >
                <strong>{headCell.label}</strong>
                {sortBy === headCell.name ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              align={headCell.align || 'right'}
              key={headCell.name}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
              <strong>{headCell.label}</strong>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  onRequestSort: func.isRequired,
  order: string.isRequired,
  sortBy: string.isRequired,
  headCells: arrayOf(object).isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 2, 0)
  },
  margin: {
    marginTop: theme.spacing(2)
  }
}));

const EnhancedTableToolbar = (props) => {
  
  const classes = useToolbarStyles();
  const {
    create,
    query,
    handleKeyUp,
    handleQueryChange,
    handleSearch,
    clearFilters,
    additionalFilters,
    isSearchActive,
    isClearFiltersActive
  } = props;

  return (
    <Toolbar className={classes.root}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={7} sm={9}>
          {/* <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={2}>
            <Grid item>
              
            </Grid>
            {additionalFilters &&
              additionalFilters.map((filter, index) => (
                <Grid item key={index}>
                  {filter()}
                </Grid>
              ))}
          </Grid> */}
        </Grid>
        <Grid item xs={5} sm={3}>
          <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
            {additionalFilters && !!additionalFilters.length && (
              <Grid item className={classes.margin}>
                <IconButton aria-label={'Search'} onClick={handleSearch} disabled={!isSearchActive}>
                  <Tooltip title={'Search'}>
                    <Search />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}
            <Grid item className={classes.margin}>
              <IconButton
                aria-label={'Clear Filters'}
                onClick={clearFilters}
                disabled={!isClearFiltersActive}
              >
                <Tooltip title={'Clear Filters'}>
                  <ClearAll />
                </Tooltip>
              </IconButton>
            </Grid>
            {create && (
              <Grid item className={classes.margin}>
                <Tooltip title={create.text}>
                  <Link to={create.link}>
                    <Fab aria-label={create.text} color={'primary'} size={'small'}>
                      <Add />
                    </Fab>
                  </Link>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  create: shape({
    link: string,
    text: string
  }),
  handleQueryChange: func.isRequired,
  handleKeyUp: func.isRequired,
  handleSearch: func.isRequired,
  clearFilters: func.isRequired,
  additionalFilters: arrayOf(func),
  query: string,
  isSearchActive: bool,
  isClearFiltersActive: bool
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  emptyCell: {
    border: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  smallColumn: {
    width: '5%'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
}));

const VouchersTable = (props) => {
  
  const {  selectedBrand, voucherId } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(props.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [order, setOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('');
  const [count, setCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pagingToken, setPagingToken] = useState('');

  const [isClearFiltersActive, setIsClearFiltersActive] = useState(false);

  const {
    breadcrumbs,
    create,
    loadData,
    loadCodeData,
    pageTitle,
    tableBodyElements,
    tableHeadElements,
    additionalFilters,
    additionalFiltersState,
    requestAdditionalFiltersState,
    clearAdditionalFilters,
    customToolbar,
    enqueueSnackbar,
    rowColorFunc,
    onPageChange,
    handleChangePage,
    pageNumber
  } = props;


  const getMore = async () => {
    setIsLoading(true);
    try {
        const result = await handleChangePage(pagingToken);

        setPagingToken(result.pagingToken)
       
        const newObj = data.concat(result.pageContent)
        //setData(result.pageContent);

        setData(newObj);
        setError(null);
        setIsLoading(false);
    } catch (err) {
      let error = err;

      if (err.response && [401, 403].includes(err.response.status))
        error = 'You have no access for this operation';

      setError(error);
      setIsLoading(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setSortBy(property);
    setIsClearFiltersActive(true);
  };


  const handleQuery = (e) => {
    setQuery(e.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13 && query) {
      
      e.preventDefault();
      
      handleCodeSearch();
    }
  };

  const handleCodeSearch = () => {
    getSelectedCodeData().then(() => {
      setIsLoading(false);
      setIsSearchActive(false);
      setIsClearFiltersActive(true);
    });
    setQuery('');
  };

  const handleSearch = () => {
    getData().then(() => {
      setIsLoading(false);
      setIsSearchActive(false);
      setIsClearFiltersActive(true);
    });
  };

  const clearFilters = () => {
    setOrder('asc');
    setQuery('');
    setIsSearchActive(false);
    setSortBy('');
    //setPage(0);
    onPageChange(0);
    setIsClearFiltersActive(false);

    clearAdditionalFilters && clearAdditionalFilters();

    getData().then(() => {
      setIsSearchActive(false);
    });
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const {pagingToken, pageContent } = await loadData(voucherId,{
        ...additionalFiltersState,
        ...requestAdditionalFiltersState
      });
      setPagingToken(pagingToken)
      setData(pageContent);
      setError(null);
      setIsLoading(false);
      
    } catch (err) {
      let error = err;

      if (err.response && [401, 403].includes(err.response.status))
        error = 'You have no access for this operation';

      setError(error);
      setIsLoading(false);
    }
  };

  const getSelectedCodeData = async () => {
    setIsLoading(true);
    try {
      const data = await loadCodeData(query);
      setData([data]);
      setError(null);
      setIsLoading(false);
      setQuery('');
      setIsClearFiltersActive(true);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };
  

  const addFilters = JSON.stringify(additionalFiltersState);

  useEffect(() => {
    !isSearchActive && setIsSearchActive(true);
  }, [requestAdditionalFiltersState]);


  useEffect(() => {
      getData().then(() => {
        setIsLoading(false);
        setIsSearchActive(false);
      });
  }, [addFilters]);


  if (error) {
    return (
      <CenteredBlock>
        <Typography variant={'h3'} color={'error'}>
          Error
        </Typography>
        <Typography variant={'h4'} color={'error'}>
          {JSON.stringify(
            error && error.response && error.response.data && error.response.data.message,
            null,
            2
          )}
        </Typography>
      </CenteredBlock>
    );
  }

  return (
   
    <div className={classes.root}>
      <div>
        {customToolbar ? (
          customToolbar(handleSearch, isSearchActive, clearFilters, isClearFiltersActive)
        ) : (
          <EnhancedTableToolbar
            create={create}
            pageTitle={pageTitle}
            handleSearch={handleSearch}
            handleQueryChange={handleQuery}
            handleKeyUp={handleKeyUp}
            clearFilters={clearFilters}
            additionalFilters={additionalFilters}
            query={query}
            isSearchActive={isSearchActive}
            isClearFiltersActive={isClearFiltersActive}
          />
         
        )}
        <div className={classes.tableWrapper}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
            <FormControl variant="outlined">
                <OutlinedInput
                  type="text"
                  name="table-search"
                  label={lookUp({ key: 'CONSOLE_TABLE_SEARCH' })}
                  InputLabelProps={{ shrink: true }}
                  variant="small"
                  id="table-search"
                  placeholder={lookUp({ key: 'CONSOLE_TABLE_SEARCH_HELPERTEXT' })}
                  value={query}
                  onChange={(value) => handleQuery(value)}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      getSelectedCodeData()
                    }
                  }}
                  endAdornment={
                    (!additionalFilters || !additionalFilters.length) && (
                      <InputAdornment position="end">
                        <Tooltip title={lookUp({ key: 'CONSOLE_GET_SELECTED_CODE_HELPERTEXT' })}>
                          <IconButton
                            aria-label={lookUp({ key: 'CONSOLE_SEARCH'})}
                            onClick={getSelectedCodeData}
                            size="small"
                           // disabled={!isSearchActive}
                          >
                            <Search />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                sortBy={sortBy}
                onRequestSort={handleRequestSort}
                headCells={tableHeadElements}
              />
              {data && data.length !== 0 && (
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      hover
                      key={item.id || index}
                      {...(rowColorFunc && { className: rowColorFunc(item) })}
                    >
                      {tableBodyElements.map((element) => (
                        <TableCell
                          align={element.align || 'right'}
                          key={element.name}
                          component={element.name === 'name' ? 'th' : ''}
                          scope={element.name === 'name' ? 'row' : ''}
                        >
                          {element.render ? element.render(item) : item[element.name].toISOString()}
                        </TableCell>
                      ))}
                     
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            </>
          )}
          {!isLoading && (!data || data.length === 0) && (
            <CenteredBlock height="10vh">
              <Typography variant="h5">No records found</Typography>
            </CenteredBlock>
          )}
        </div>
        {pagingToken && (
          <Grid item xs={12} style={{textAlign:'center'}}>
            <Button  onClick={getMore}  color="primary"  className={classes.marginTop}>Load More</Button>
          </Grid>
        )}
      </div>
    </div>
  );
};

VouchersTable.propTypes = {
  pageTitle: string,
  pageNumber: number,
  selectedBrand: shape({
	  brandId: string,
}),
  create: shape({
    link: string,
    text: string
  }),
  breadcrumbs: arrayOf(
    shape({
      text: string.isRequired,
      link: string,
      isCurrent: bool,
      order: number
    })
  ),
  tableHeadElements: arrayOf(
    shape({
      name: string,
      label: string.isRequired,
      isSortable: bool,
      align: string,
      smallColumn: bool
    })
  ).isRequired,
  tableBodyElements: arrayOf(
    oneOfType([
      shape({
        name: string.isRequired,
        align: string,
        render: func // item => result in td. Can be omit. By default renders item[name]
      })
    ])
  ).isRequired,
  loadData: func,
  additionalFilters: arrayOf(func),
  additionalFiltersState: object,
  requestAdditionalFiltersState: object,
  clearAdditionalFilters: func,
  customToolbar: func,
  enqueueSnackbar: func.isRequired,
  rowColorFunc: func,
 // onPageChange: func.isRequired
};

VouchersTable.defaultProps = {
  pageTitle: '',
  create: null,
  breadcrumbs: [],
  tableClassName: '',
  rowColorFunc: null
};

const mapStateToProps = ({ auth: {  selectedBrand } }) => ({
	selectedBrand
  });

export default withSnackbar(compose(
    connect(
      mapStateToProps,
    ),
    )(VouchersTable));
