import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Drawer from '@material-ui/core/Drawer';

const DRAWER_WIDTH = 320;
const useStyles = makeStyles((theme) => ({
  paper: {
    width: `${DRAWER_WIDTH}px !important`,
    height: (props) => props.height,
  },
}));

const SectionDrawer = ({
  containerRef,
  children,
  variant = 'temporary',
  anchor = 'right',
  open = false,
  onClose = () => {},
  ...rest
}) => {
  const [height, setHeight] = useState(0);
  const classes = useStyles({ height });

  useEffect(() => {
    if (open) {
      setHeight(containerRef?.current?.clientHeight || '100%');
    } else {
      setHeight(0);
    }
  }, [open]);

  return (
    <Drawer
      classes={{
        paper: classes.paper,
      }}
      variant={variant}
      anchor={anchor}
      open={open}
      onClose={onClose}
      {...rest}
    >
      {children}
    </Drawer>
  );
};

SectionDrawer.propTypes = {
  containerRef: PropTypes.node,
  variant: PropTypes.oneOf(['persistent', 'permanent', 'temporary']),
  anchor: PropTypes.oneOf(['right', 'bottom', 'left', 'top']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default SectionDrawer;
