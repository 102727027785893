import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import PublishedComponent from 'assets/icons/PublishedComponent'
import UnPublishedComponent from 'assets/icons/UnPublishedComponent'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Lister, Toolbar, Spacing, CreatedOn, ConfirmDialog } from 'components';
import LogButton from 'components/Audit/LogButton';
import uniformServices from 'services/uniformServices';

const Vouchers = ({ enqueueSnackbar }) => {

  const [search, setSearch] = useSearchParams();
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [debounce, setDebounce] = useState();
  const [trigger, setTrigger] = useState();

  const columns = [
    {
      field: 'enabled',
      headerName: ' ',
      align: 'left',
      width: 40,
      renderCell: item =>
        item.value ? (
          <PublishedComponent />
        ) : (
          <UnPublishedComponent />
        )
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      flex: true,
      renderCell: (item) => (
        <Link
          to={`/monetization/campaigns/${item.id}/edit`}
          component={RouterLink}
        >
         {item.value}
        </Link>
      )
    },
    {
      field: 'productId',
      headerName: lookUp({ key: 'CONSOLE_PRODUCTID' }),
      align: 'left',
      flex: true,
      renderCell: (item) => (
        <Link
          to={`/monetization/campaigns/${item.id}/edit`}
          component={RouterLink}
        >
         {item.value}
        </Link>
      )
    },
    {
      field: 'basePriceUSD',
      headerName: lookUp({ key: 'CONSOLE_PRICE' }),
      align: 'left',
      width: 60,
      renderCell: (item) => 
          item.value ? <p>${item.value}</p> : 
          <p>$0</p> 
      
    },
    {
      field: 'hasVouchers',
      headerName: lookUp({ key: 'CONSOLE_VOUCHERS' }),
      align: 'left',
      width: 100,
      renderCell: (item) => 
        item.value ? (
          <Tooltip title={lookUp({ key: 'CONSOLE_HAS_VOUCHERS' })}>  
              <CheckOutlinedIcon style={{ color: green[500] }}/>
          </Tooltip>
        ) : (
            <Tooltip title={lookUp({ key: 'CONSOLE_NO_VOUCHER' })}>
                <CloseOutlinedIcon style={{ color: red[500] }}/>
            </Tooltip>
          )
    },
    {
      field: 'redeemPeriod',
      headerName: lookUp({ key: 'CONSOLE_REDEEMPERIOD' }),
      align: 'left',
      width: 300,
      renderCell: (item) => <><CreatedOn date={item.row.activeFrom} />&nbsp;–&nbsp;<CreatedOn date={item.row.activeUntil} /></>
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'type',
      headerName: ' ',
      align: 'center',
      width: 40,
      filterable: false,
      sortable: false,
      renderCell: (item) => (
        <LogButton type="Campaign" id={item.id} />
      )
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      align: 'center',
      width: 40,
      renderCell: (item) => (
        <ConfirmDialog
          onConfirm={() => handleDelete(item.id)}
          message={lookUp({
            key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE',
            title: item.row.name || 'item',
          })}
          dialogText={lookUp({ key: 'CONSOLE_DELETE' })}
          buttonText={lookUp({ key: 'CONSOLE_DELETE' })}
          hint={lookUp({ key: 'CONSOLE_DELETE_ITEM' })}
        />
      ),
    },
  ];

  const handleDelete = (id, name) => {
    setDeletedIds(id);
    uniformServices
      .remove('Campaign', id)
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: name }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: name }),
        });
      });
  };

  const getData = () => {
    setLoading(true);
    uniformServices
      .search('Campaign', search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res.totalCount);
        setItems([
          ...res.data
            .filter((item) => !deletedIds?.includes(item.id)),
        ]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_CAMPAIGNS` }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [search, trigger]);

  return (
    <>
      <Toolbar create triggerSearch={() => setTrigger(Symbol('refresh'))} />
      <Spacing height={1} />
      <Lister loading={loading} columns={columns} rows={items || []} totalCount={totalCount} />
    </>
  );
};

export default withSnackbar(Vouchers);
