import { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { Box, Grid, Divider } from '@material-ui/core';
import { lookUp } from 'services/stringService';
import AlertService from 'services/alertService';
import moment from 'moment';
import { justifyContent } from 'styled-system';

const TimeBox = (props) => {
  
  const {
    leaveSession,
    recordingTime,
    isUserActive,
    isRecording,
    isLocalUserSharing,
    isVideoEnabled,
    enqueueSnackbar,
  } = props;

  const [clock, setClock] = useState(new Date());
  const [inactivityTime, setInactivityTime] = useState(0);

  useEffect(() => {
    const clockId = setInterval(() => {
      setClock(new Date());
    }, 1000);
    return function cleanup() {
      clearInterval(clockId);
    };
  }, []);

  useEffect(() => {
    if (!isUserActive && !isRecording && !isLocalUserSharing && !isVideoEnabled) {
      if (!inactivityTime) {
        return setInactivityTime(clock);
      }
      if (clock - inactivityTime >= 120000) {
        AlertService.displayWarning({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_INACTIVE_SESSION_FINISHED' }),
          autoHideDuration: 500000,
        });
        return leaveSession();
      }
      return;
    }
    return setInactivityTime(0);
  }, [clock]);

  return (
    <Grid container item justifyContent={'flex-end'} spacing={2} xs={9}>
      <Box display={'flex'}>
        {recordingTime ? (
          <Box style={{ fontSize: '16px', marginRight: '8px' }}>
            {moment.utc(moment(new Date()).diff(recordingTime)).format('HH:mm:ss')}
          </Box>
        )
        : null}
        {recordingTime ? 
        <Divider orientation="vertical" flexItem /> 
        : null}
        <Box style={{ fontSize: '16px', marginLeft: '8px' }}>
          {clock.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </Box>
      </Box>
    </Grid>
  );
};

export default withSnackbar(TimeBox);
