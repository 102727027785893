import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { setSelectedLexicon } from 'store/actions/localizationAction';
import parametersService from 'services/parametersService';
import { setConsoleLanguages } from 'store/actions/localizationAction';
import { lookUp } from 'services/stringService';
import AlertService from 'services/alertService';
import { MenuItem, TextField } from '@material-ui/core';

function LanguageSelector({ enqueueSnackbar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((s) => s.auth);
  const selectedLocale =
    useSelector((s) => s.lexicons.selected) || localStorage.getItem('locale') || 'en-US';
  const consoleLanguages = useSelector((state) => state.lexicons.consoleLanguages);

  useEffect(() => {
    if (!Object.keys(user).length || consoleLanguages.length) return;
    const getLangs = async () => {
      let langs = undefined;
      try {
        langs = await parametersService.getByKey('console%3Alanguage');
        langs = JSON.parse(langs.value);
      } catch (error) {
        AlertService.displayWarning({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_ALL_LANGUAGES' }),
          }),
        });
        langs = [
          { value: 'Magyar', locale: 'hu-HU' },
          { value: 'English', locale: 'en-US' },
        ];
      } finally {
        dispatch(setConsoleLanguages(langs));
      }
    };
    getLangs();
  }, []);

  return consoleLanguages.length > 0 ? (
    <TextField
      select
      value={selectedLocale}
      onChange={(e) => {
        dispatch(setSelectedLexicon(e.target.value));
        navigate(location.pathname + location.search);
      }}
    >
      {consoleLanguages.map((langObj) => (
        <MenuItem value={langObj.locale} key={langObj.locale}>
          {langObj.value}
        </MenuItem>
      ))}
    </TextField>
  ) : null;
}

export default withSnackbar(LanguageSelector);
