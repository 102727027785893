import React, { useState, useRef } from 'react';
import { lookUp } from 'services/stringService';
import { DatePicker } from '@material-ui/pickers';
import { TextField, IconButton, Grid } from '@material-ui/core';
import { Clear } from '@material-ui/icons/';

const SearchBox = (props) => {
  const {
    search = '',
    setSearch,
    searchCustom,
    isLoading,
    setUrlQuery,
    mainDropdownSelector,
    qInput = true,
    dateFilters,
  } = props;

  const textQuery = useRef();
  textQuery.current = searchCustom?.key || 'q';
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [searchInput, setSearchInput] = useState(
    search || {
      [textQuery.current]: search || '',
      from: null,
      to: null,
      page: 0,
    }
  );

  const handleTextSearch = async (event) => {
    setSearchTimeout(() => clearTimeout(searchTimeout));
    const timeout = setTimeout(
      async () => {
        if (!isLoading) {
          setSearch( e => ({...e, [textQuery.current]: event.target.value, page: 0 }));
          setSearchInput( e => ({...e, [textQuery.current]: event.target.value, page: 0 }));
          setUrlQuery({ [textQuery.current]: event.target.value, page: 0 });
        }
      },
      event.key === 'Enter' ? 0 : 500
    );
    setSearchTimeout(timeout);
  };

  const handleDateSearch = () => {
    setSearch({ ...searchInput, page: 0 });
    setUrlQuery({ ...searchInput, page: 0 });
  };

  const clearSearch = () => {
    const clearedParams = {
      [textQuery.current]: '',
      from: null,
      to: null,
    };
    if (search.id) {
      clearedParams.id = '';
    }
    if (search.name) {
      clearedParams.name = '';
    }
    setSearch({ ...search, ...clearedParams });
    setSearchInput({ ...search, ...clearedParams });
    setUrlQuery({ ...search, ...clearedParams });
  };

  return (
    <>
      <Grid container xs={12} spacing={2}>
        {qInput && (
          <Grid item xs={3}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              margin="dense"
              label={searchCustom?.label || lookUp({ key: 'CONSOLE_SEARCH' })}
              value={searchInput[textQuery?.current] === '' ? '' : searchInput[textQuery?.current] || search.q || ''}
              onChange={(event) =>
                setSearchInput({ ...search, [textQuery.current]: event.target.value })
              }
              onKeyUp={handleTextSearch}
              helperText={searchCustom?.helperText || searchCustom?.helperText === '' ? null : lookUp({ key: 'CONSOLE_SEARCH_HELPERTEXT' })}
            />
          </Grid>
        )}
        {dateFilters && (
          <>
            <Grid item xs={2}>
              <DatePicker
                label={lookUp({ key: 'CONSOLE_FROM' })}
                value={searchInput.from}
                format="MM/DD/yyyy"
                margin="dense"
                variant="inline"
                inputVariant="outlined"
                disableFuture
                onChange={(event) => {
                  if (event) {
                    const time = new Date(event);
                    if (searchInput.to && time > searchInput.to) return;
                    time.setUTCHours(0, 0, 0, 0);
                    setSearchInput({ ...search, from: time.toISOString() });
                  }
                }}
                onClose={handleDateSearch}
                style={{ width: '100%' }}
                helperText={lookUp({ key: 'CONSOLE_START_DATE_HELPERTEXT' })}
                fullWidth
              />
            </Grid>

            <Grid item xs={2}>
              <DatePicker
                label={lookUp({ key: 'CONSOLE_UNTIL' })}
                value={searchInput.to}
                format="MM/DD/yyyy"
                variant="inline"
                inputVariant="outlined"
                margin="dense"
                disableFuture
                onChange={(event) => {
                  if (event) {
                    const time = new Date(event);
                    if (search.from && time < search.from) return;
                    time.setUTCHours(23, 59, 59, 999);
                    setSearchInput({ ...search, to: time.toISOString() });
                  }
                }}
                onClose={handleDateSearch}
                style={{ width: '100%' }}
                helperText={lookUp({ key: 'CONSOLE_END_DATE_HELPERTEXT' })}
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <IconButton
            disableRipple
            disableFocusRipple
            disabled={!Object.values(search).find((param) => !!param)}
            onClick={clearSearch}
          >
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
      {!!mainDropdownSelector && mainDropdownSelector(clearSearch)}
    </>
  );
};

export default SearchBox;
