import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { func, string } from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  makeStyles,
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CreatedOn, ConfirmDialog } from 'components';
import collectionsService from 'services/collectionsService';
import collectionsServiceV2 from 'services/collectionsServiceV2';
import customersService from 'services/customersService';
import AlertService from 'services/alertService';
import NoResults from 'components/NoResults';

const useStyles = makeStyles((theme) => ({
  textField: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

// FIXME this component should use a DataGrid as other sections do for consistency
const DiscussionTab = (props) => {
  
  const { id, enqueueSnackbar } = props;
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [messageLikes, setMessageLikes] = useState([]);
  const [trigger, setTrigger] = useState(true);

  const columns = [
    {
      name: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      width: 180,
    },
    {
      name: lookUp({ key: 'CONSOLE_COMMENT' }),
      align: 'left',
    },
    {
      name: lookUp({ key: 'CONSOLE_NUMBER_LIKES' }),
      align: 'left',
      width: 180,
    },
    {
      name: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 180,
    },
    {
      name: '',
      align: 'right',
      width: '40',
    },
  ];

  const handleDelete = (child) => {
    const { id, customerId } = child
    collectionsService
      .moderateDiscussion(id, customerId)
      .then(() => {
        setTrigger((o) => !o);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: lookUp({ key: `CONSOLE_${`Comment`}` }) }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: lookUp({ key: `CONSOLE_${`Comment`}` }) }),
        });
      });
  };

  const getData = async () => await collectionsService.getDiscussionCollection('Content', id);

  const getCustomersIdList = (data) => {
    let customersIdList = [];
    const iterate = (data) => {
      data.forEach((child) => {
        customersIdList.push(child.customerId);
        if (!!child.children?.length) {
          iterate(child.children);
        }
        return;
      });
      return;
    };
    iterate(data);
    return [...new Set(customersIdList)];
  };

  useEffect(() => {
    if (id) {
      getData()
        .then((data) => {
          setData(data.pageContent);
          setIsLoading(false);
          if (!data?.pageContent?.length) return
          return Promise.allSettled(
            getCustomersIdList(data.pageContent).map((id) => customersService.getCustomer(id))
          );
        })
        .then((customersList) => {
          setCustomers(
            customersList?.reduce((acc, customer) => {
              if (customer.status === 'fulfilled') {
                acc.push(customer.value);
              }
              return acc;
            }, [])
          );
          getMessageLikes().then((msgDataList) => {
            return setMessageLikes(
              msgDataList?.reduce((acc, likes) => {
                if (likes.pageContent.length > 0) {
                  acc.push({messageId: likes.pageContent[0].sourceId, count: likes.pageContent.length});
                }
                return acc;
              }, [])
            );
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
  }, [trigger]);

  const getMessageLikes = async () => {
    try {
      return Promise.allSettled(data.map((content) => collectionsServiceV2.getRelationshipList(content.messageId, 'Like')))
      .then((messageLikeList) => {
        return messageLikeList.reduce((acc, msgLike) => {
          if (msgLike.status === 'fulfilled') {
            acc.push(msgLike.value);
          }
          return acc;
        }, []);
      });
    } catch (error) {
    }
  }

  // FIXME replace Table with DataGrid
  const DiscussionTable = ({ data }) =>
    data.map((child, index) => (
      <>
        <TableRow key={`comment-${index}`} style={{ borderBottom: '1px solid red' }}>
          <TableCell>
            <Box
              style={
                !!child.parentId
                  ? {
                      paddingLeft: '10px',
                      marginLeft: `${child.parentId.split(':')?.length * 10}px`,
                      borderLeft: '1px solid rgba(224, 224, 224)',
                    }
                  : null
              }
            >
              {customers.find((customer) => customer.customerId === child.customerId)?.properties?.[
                'Custom:User:Name'
              ] || 'User'}
            </Box>
          </TableCell>
          <TableCell>{child.deleted === "Live" ? child.payload : (
            <span style={{opacity: 0.6}}>
              {lookUp({ key: `CONSOLE_${child.deleted}` })}
            </span>
            )}
            </TableCell>
            <TableCell>{(
            <span style={{opacity: 0.6}}>
              {messageLikes.find((message) => message.messageId === child.messageId)?.count || 0}
            </span>
            )}
            </TableCell>
          <TableCell>
            <CreatedOn date={child.createdDate} />
          </TableCell>
          <TableCell>
          <ConfirmDialog
            disabled={child.deleted !== "Live"}
            onConfirm={() => handleDelete(child)}
            message={lookUp({ key: 'CONSOLE_MODERATEITEM_MESSAGE' })}
            buttonText={lookUp({ key: 'CONSOLE_MODERATE_BUTTON' })}
            hint={lookUp({ key: "CONSOLE_MODERATE"})}
          />
          </TableCell>
        </TableRow>
        {!!child.children?.length && <DiscussionTable data={child.children} />}
      </>
    ));

  return (
    <Box flex={1}>
        {/* FIXME replace Table with DataGrid */}
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={`column-${index}`} align={column.align} width={column.width}>
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {false ? (
            <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
              <LinearProgress />
            </div>
          ) : (
            <TableBody>
              {!data?.length ? (
                <TableRow>
                  <TableCell colSpan={6} style={{ borderBottom: 'none' }}>
                    <Box flex={1} mt={1} justifiContent="centeer" alignItems="center" alignContent="center">
                      <NoResults />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                <DiscussionTable data={data} />
              )}
            </TableBody>
          )}
        </Table>
    </Box>
  );
};

DiscussionTab.propTypes = {
  id: string.isRequired,
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(DiscussionTab);
