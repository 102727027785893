import { createTheme } from '@material-ui/core';
import typography from "./typography";
import overrides from "./overrides";
import breakpoints from "./breakpoints";
import props from "./props";
import shadows from "./shadows";
import { THEMES } from 'constants/index';
import parametersService from 'services/parametersService';

const base = { typography, overrides, breakpoints, props, shadows };

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor) => augmentColor({ main: mainColor || '#f36', contrastText: '#FFF' });

export const brandedTheme = () =>
  parametersService.getTheme()
    .then((theme) => {
      const {
        brand,
        colors,
        typography,
        overrides,
        breakpoints,
        props,
        shadows = [],
      } = theme;

      return createTheme({
        name: THEMES.DEFAULT,
        palette: {
          type: 'light',
          primary: createColor(colors.primaryColor),
          secondary: createColor(colors.secondaryColor),
          error: createColor(colors.errorColor),
          warning: createColor(colors.warningColor),
          info: { ...createColor(colors.infoColor), contrastText: '#111' },
          success: createColor(colors.successColor),
          background: {
            default: '#FFF',
            paper: '#FFF',
          },
          sidebar: colors.sidebar,
        },
        typography: { ...base.typography, ...typography },
        overrides: { ...base.overrides, ...overrides },
        breakpoints: { ...base.breakpoints, ...breakpoints },
        props: { ...base.props, ...props },
        shadows: [ ...base.shadows, ...shadows ],
        brand,
      })
  });

export const defaultTheme = () => createTheme({
  props,
  overrides,
});

const variants = [
  brandedTheme,
  defaultTheme,
];

export default variants;
