import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import CreatedOn from 'components/CreatedOn'
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { Avatar, Link, Tooltip, makeStyles } from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { LinkOff, } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
import { Listing } from 'components';
import { search, remove } from 'services/messageCenterService';

const useStyles = makeStyles((theme) => ({
  published: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1),
    backgroundColor: green[200],
  },
  notPublished: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1),
    backgroundColor: red[200]
  }
}));


const MessageCenter = ({history}) => {
  const [languages, setLanguages] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState({l: new URLSearchParams(location.search).get('l') || 'en-US'});
  const classes = useStyles();

const create = {
  text: lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: 'CONSOLE_MESSAGE' }) }),
  link: `/engagement/message-center/${selectedLanguage?.l}/create`
};

const columns = [
  {
    field: 'published',
    headerName: ' ',
    align: 'left',
    width: 40,
    renderCell: item =>
    item.value ? (
      <Tooltip title={lookUp({ key: 'CONSOLE_PUBLISHED' })}>
        <Avatar className={classes.published}>
          <DoneIcon style={{ color: green[900] }}/>
        </Avatar>
      </Tooltip>
    ) : (
        <Tooltip title={lookUp({ key: 'CONSOLE_UNPUBLISHED' })}>
          <Avatar className={classes.notPublished}>
            <NotInterestedIcon style={{ color: red[900] }}/>
          </Avatar>
        </Tooltip>
      )
  },
  {
    field: 'name',
    headerName: lookUp({ key: 'CONSOLE_NAME' }),
    align: 'left',
    flex: true,
    renderCell: (item) =>
        item.row.eventName && item.row.actionType ? (
          <Link
            to={`/engagement/message-center/${item.row.language}/${item.row.eventName}/${item.row.actionType}/edit`}
            component={RouterLink}
          >
            {item.value}
          </Link>
        ) : (
            item.value || <LinkOff />
          ),
  },
  {
    field: 'actionType',
    headerName: lookUp({ key: 'CONSOLE_ACTION'}),
    align: 'left',
    width: 90,
    renderCell: (item) => (
      <>
        {item.value === 'SendEmail' && <MailIcon />}
        {item.value === 'PushNotification' && <NotificationsIcon />}
        {item.value === 'BackgroundUpdate' && <SettingsIcon />}
      </>
    ),
  },
  {
    field: 'eventName',
    headerName: lookUp({ key: 'CONSOLE_EVENTNAME'}),
    align: 'left',
    flex: true,
    renderCell: item => <>{item.value}</>,
  },
  {
    field: 'availableFrom',
    headerName: lookUp({ key: 'CONTENT_AVAILABLEFROM' }),
    align: 'left',
    width: 140,
    renderCell: (item) => <CreatedOn date={item.value} />
  },
  {
    field: 'createdDate',
    headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
    align: 'left',
    width: 140,
    renderCell: (item) => <CreatedOn date={item.value} />
  },
];

const languagesFilter = {
  label: lookUp({ key: 'CONSOLE_LANGUAGES' }),
  key: 'l',
  options: languages,
  change: value => setSelectedLanguage({l: value}),
 };

const handleDelete = async ({ eventName, actionType, language }) => {
  return await remove({ eventName, actionType, language });
};

useEffect(() => { 
  setLanguages(['en-US', 'hu-HU', 'de-DE']
  .map(e => ({
    value: e,
    label: lookUp({ key: `CONSOLE_LANG_${e}`}),
  })))
}, [])

  return (
    <Listing
      create={create}
      tableHeadElements={columns}
      loadData={search}
      deleteItem={(item) => handleDelete(item)}
      deleteByItem={true}
      multiSort={true}
      defaultSorting={[{field: "eventName", sort: "asc"}]}
      dropdownFilters={[languagesFilter]}
      additionalFiltersState={selectedLanguage}
    />
  );
};


export default (MessageCenter);