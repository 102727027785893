const SET_LEXICONS = 'SET_LEXICONS';
const SET_PROJECT_LANGUAGES = 'SET_PROJECT_LANGUAGES';
const SET_CONSOLE_LANGUAGES = 'SET_CONSOLE_LANGUAGES';
const SET_SELECTED_LEXICON = 'SET_SELECTED_LEXICON';

const setLexicons = (lexicons) =>  ({ type: SET_LEXICONS, lexicons});
const setProjectLanguages = (languages) =>  ({ type: SET_PROJECT_LANGUAGES, languages});
const setConsoleLanguages = (languages) =>  ({ type: SET_CONSOLE_LANGUAGES, languages});
const setSelectedLexicon = (selectedLexicon) =>  {
  localStorage.setItem('locale', selectedLexicon);
  return ({ type: SET_SELECTED_LEXICON, selectedLexicon});
};

export {
  SET_LEXICONS,
  SET_PROJECT_LANGUAGES,
  SET_CONSOLE_LANGUAGES,
  SET_SELECTED_LEXICON,
  setLexicons,
  setProjectLanguages,
  setConsoleLanguages,
  setSelectedLexicon,
};