import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';

import { DataGrid } from '@material-ui/data-grid';

import {
  IconButton,
  Checkbox,
} from '@material-ui/core';

import { Clear } from '@material-ui/icons';

const FaceGrid = ({ faces, select, setBoundingBoxes, booleans }) => {
  

  const gridData = (face) => {
    const awsMeta = JSON.parse(face.awsMeta);
    delete awsMeta.Landmarks;
    Object.keys(booleans).forEach(tag => awsMeta[tag] = awsMeta[tag]?.Value)
    return awsMeta;
  };

  const rows = faces.reduce((rows, face, index) => {
    rows.push({ id: index, data: face, ...gridData(face) })
    return rows
  }, []);

  const [rowsFiltered, setRowsFiltered] = useState(rows);

  const [switches, setSwitches] = useState({});

  const switchesStr = JSON.stringify(switches);

  const toggleSwitch = (e) => {
    if (Object.keys(switches).indexOf(e) === -1) {
      let newSwitches = { ...switches, [e]: true };
      setSwitches(newSwitches);
    } else if (switches[e] === true) {
      let newSwitches = { ...switches, [e]: false };
      setSwitches(newSwitches);
    } else {
      let newSwitches = { ...switches };
      delete newSwitches[e];
      setSwitches(newSwitches);
    }
  };

  const toggleGenderSwitch = () => {
    if (Object.keys(switches).indexOf('Gender') === -1) {
      let newSwitches = { ...switches, Gender: 'Female' };
      setSwitches(newSwitches);
    } else if (switches['Gender'] === 'Female') {
      let newSwitches = { ...switches, Gender: 'Male' };
      setSwitches(newSwitches);
    } else {
      let newSwitches = { ...switches };
      delete newSwitches.Gender;
      setSwitches(newSwitches);
    }
  };

  const filterByBools = (rows) => {
    if (Object.keys(switches).length === 0) return rows;
    const filtered =
      rows.filter(row => {
        let okay = true;
        for (let key of Object.keys(switches)) {
          if (key === 'Gender' && row.Gender?.Value?.Value !== switches.Gender) return;
          if (!switches[key] === row[key]) okay = false;
        }
        return okay;
      })
    return filtered;
  };

  useEffect(() => {
    const filtered = filterByBools(rows)
    setBoundingBoxes(filtered.map(e => e.BoundingBox))
    setRowsFiltered(filtered)
  }, [switchesStr])


  const columns = [
    {
      field: 'Confidence',
      width: 130,
      renderCell: item => item.value.toString().substr(0, 6) + '%'
    },
    {
      field: 'agemin',
      headerName: 'Age min',
      type: 'number',
      width: 130,
      valueGetter: item => item.row.AgeRange?.Low
    },
    {
      field: 'agemax',
      headerName: 'Age max',
      type: 'number',
      width: 130,
      valueGetter: item => item.row.AgeRange?.High,
      // renderCell: item => item.row.AgeRange?.High
    },
    {
      field: 'Gender',
      valueGetter: item => item.value.Value.Value,
      renderCell: item => `${item.value} (${item.row.Gender?.Confidence.toString().substr(0, 5)}%)`,
      flex: true
    },
  ]

  return (
    <div style={{display: "block"}}>
      {Object.keys(booleans).includes("Male") && Object.keys(booleans).includes("Female") && 
      <>
        <Checkbox
          color={switches.Gender ? "primary" : "secondary"}
          checked={(Object.keys(switches).includes('Gender'))}
          label={'Gender'}
          size="small"
          onChange={() => toggleGenderSwitch()}
        />
        <span style={{
          color: Object.keys(switches).includes('Gender') ? 'black' : 'grey',
        }}>{switches.Gender || 'Gender'}
        </span>
      </>}
      {Object.keys(booleans).filter(e => !["Male", "Female"].includes(e)).map((e, idx) => (
        <>
          <Checkbox
            color={switches[e] ? "primary" : "secondary"}
            checked={(Object.keys(switches).includes(e))}
            label={e}
            size="small"
            key={idx}
            onChange={() => toggleSwitch(e)}
          />
          <span style={{
            color: Object.keys(switches).includes(e) ? 'black' : 'grey',
            textDecoration: switches[e] === false && 'line-through',
          }}>{`${booleans[e].displayName} (${booleans[e].occurrence})`}</span>
        </>
      ))}
      {Object.keys(switches).length > 0 &&
        <IconButton size='small' onClick={() => setSwitches({})} ><Clear /></IconButton>
      }
      {rowsFiltered.length > 0 &&
        <DataGrid
          style={{ border: 0 }}
          columns={columns}
          rows={rowsFiltered.map((e, index) => ({...e, id: e.id || index}))}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          pageSize={5}
          onRowClick={e => select(e.row.data)}
        />
      }
    </div>
  )
}

export default FaceGrid;