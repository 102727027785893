import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { array, arrayOf, func, shape, string, bool, oneOf } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import {
  Button,
  Grid,
  Divider,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  MenuItem,
  Switch,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { PropsSelect } from 'components';
import libraryEnumsService from 'services/libraryEnumsService';
import {
  setConditionNames,
  setStringMatching,
  setValueFormats,
} from 'store/actions/libraryEnumsAction';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  root: {
    width: '100%',
    padding: '5px',
  },
  textCenter: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  secondRow: {
    paddingTop: '10px',
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '40px 0 15px 0',
  },
  divider: {
    width: '90%',
  },
  dividerText: {
    fontStyle: 'italic',
  },
}));

const VisibilityForm = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, handleChange, displayRules, setConditionNames, setValueFormats, title } =
    props;

  const [items, setItems] = useState(displayRules);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [containsConditionOptions, setContainsConditionOptions] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const valueSeparatorOptions = [
    { label: lookUp({ key: 'CONSOLE_COMMA' }), value: ',' },
    { label: lookUp({ key: 'CONSOLE_SEMICOLON' }), value: ';' },
    { label: lookUp({ key: 'CONSOLE_COLON' }), value: ':' },
    { label: lookUp({ key: 'CONSOLE_PERIOD' }), value: '.' },
    { label: lookUp({ key: 'CONSOLE_HASHMARK' }), value: '#' },
    { label: lookUp({ key: 'CONSOLE_NA' }), value: null },
  ];

  const toOptions = (items) => items.map((i) => ({ label: i, value: i }));

  const handleItemsChange = (index, key, value) =>
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));

  const handleItemsRemove = (index) => {
    const newList = items.filter((item, i) => i !== index);
    setExpanded((actual) => actual.filter((e) => e !== index));
    setItems(newList);
  };

  const handleItemsAdd = () => {
    setItems((prev) => [
      ...prev,
      {
        index: items.length,
        propertyName: '',
        formatting: '',
        condition: '',
        propertyValue: '',
        valueIsReference: false,
        valueCollectionSeparator: null,
        propertyCollectionSeparator: null,
        containsCondition: '',
      },
    ]);
    setExpanded((actual) => [...actual, items.length]);
  };

  useEffect(() => {
    handleChange(items);
  }, [items]);

  // useEffect(() => {
  //   handlePageChange(pageSize);
  // }, [pageSize]);

  useEffect(() => {
    const getFormattingOptions = async () => {
      try {
        const options = await libraryEnumsService.getValueFormats();
        setValueFormats(options);
        setFormattingOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_FORMAT' }),
          }),
        });
      }
    };
    getFormattingOptions();
  }, []);

  useEffect(() => {
    const getConditionOptions = async () => {
      try {
        const options = await libraryEnumsService.getConditionNames();
        setConditionNames(options);

        setConditionOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONDITION' }),
          }),
        });
      }
    };
    getConditionOptions();
  }, []);

  useEffect(() => {
    const getContainsConditionOptions = async () => {
      try {
        const options = await libraryEnumsService.getStringMatching();
        setStringMatching(options);
        setContainsConditionOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_STRING_MATCHING' }),
          }),
        });
      }
    };
    getContainsConditionOptions();
  }, []);

  useEffect(() => {
    setExpanded(
      items
        .map((e, index) => {
          if (e.propertyCollectionSeparator || e.valueCollectionSeparator) {
            return index;
          }
        })
        .filter((e) => !!e || e === 0)
    );
  }, []);

  const handlePanel = (index) => {
    setExpanded((actual) =>
      actual.includes(index) ? actual.filter((e) => e !== index) : [...actual, index]
    );
  };

  return (
    <>
      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
          {items.map((item, index) => (
            <Grid className={classes.root} key={`${index}`}>
              <Grid item container xs={12}>
                <Grid item container xs={11} alignItems={'center'} spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <PropsSelect
                      variant="outlined"
                      label={lookUp({ key: 'CONSOLE_PROPERTYNAME' })}
                      value={item.propertyName || null}
                      className={classes.textField}
                      handleChange={(value) => handleItemsChange(index, 'propertyName', value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      select
                      disabled={!(conditionOptions?.length)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      id={`condition-${index}`}
                      label={lookUp({ key: 'CONSOLE_CONDITION' })}
                      required
                      placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                      value={item.condition}
                      onChange={({ target }) => handleItemsChange(index, 'condition', target.value)}
                    >
                      {conditionOptions.map((e, i) => (
                        <MenuItem key={i} value={e.value}>
                          {lookUp({ key: `CONSOLE_${e.label}` })}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id={`propertyValue-${index}`}
                      label={lookUp({ key: 'CONSOLE_PROPERTYVALUE' })}
                      className={classes.textField}
                      value={item.propertyValue}
                      onChange={(e) => handleItemsChange(index, 'propertyValue', e.target.value)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Tooltip title={lookUp({ key: 'CONSOLE_VALUEISREFERENCE_HELPERTEXT' })}>
                      <Switch
                        checked={item.valueIsReference}
                        onChange={() =>
                          handleItemsChange(index, 'valueIsReference', !item.valueIsReference)
                        }
                        color={'primary'}
                      />
                    </Tooltip>
                    {lookUp({ key: 'CONSOLE_REFERENCE' })}
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.textCenter}>
                  <ExpandMoreIcon
                    onClick={() => handlePanel(index)}
                    aria-expanded={expanded.includes(index)}
                    aria-label="show more"
                    style={{ marginRight: '20px', alignItems: 'center', verticalAlign: 'middle' }}
                  />
                  <IconButton onClick={() => handleItemsRemove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded.includes(index)} timeout="auto">
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={11}
                    alignItems={'center'}
                    spacing={2}
                    className={classes.secondRow}
                  >
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(valueSeparatorOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        label={lookUp({ key: 'CONSOLE_SELECT_COLLECTION' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                        value={item.propertyCollectionSeparator}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'propertyCollectionSeparator', target.value)
                        }
                      >
                        {valueSeparatorOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(formattingOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`formatting-${index}`}
                        label={lookUp({ key: 'CONSOLE_FORMATTING' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                        value={item.formatting || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'formatting', target.value)
                        }
                      >
                        {formattingOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(valueSeparatorOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`value-separator-${index}`}
                        label={lookUp({ key: 'CONSOLE_VALUE_SEPARATOR' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                        value={item.valueCollectionSeparator || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'valueCollectionSeparator', target.value)
                        }
                      >
                        {valueSeparatorOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(containsConditionOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`containsCondition-${index}`}
                        label={lookUp({ key: 'CONSOLE_CONTAINS_CONDITION' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                        value={item.containsCondition || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'containsCondition', target.value)
                        }
                      >
                        {containsConditionOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.textCenter}></Grid>
                </Grid>
              </Collapse>
              {index !== items.length - 1 && (
                <div className={classes.dividerWrapper}>
                  <Divider variant="middle" className={classes.divider} />
                  <Typography
                    color="textPrimary"
                    display="block"
                    variant="caption"
                    className={classes.dividerText}
                  >
                    {lookUp({ key: 'CONSOLE_AND' })}
                  </Typography>
                </div>
              )}
            </Grid>
          ))}
        </Grid>
      )}
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        className={classes.marginX}
      >
        <Button  color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
          {lookUp({ key: 'CONSOLE_ADD_VISIBILITY_BUTTON' })}
        </Button>
      </Grid>
    </>
  );
};

VisibilityForm.propTypes = {
  title: oneOf(['Filtering Rules', 'Displaying Rules', 'Decision Rules']),
  handleChange: func.isRequired,
  enqueueSnackbar: func.isRequired,
  setConditionNames: func.isRequired,
  setValueFormats: func.isRequired,
  displayRules: arrayOf(
    shape({
      propertyName: string,
      formatting: string,
      condition: string,
      propertyValue: string,
      valueIsReference: bool,
      valueCollectionSeparator: string,
      propertyCollectionSeparator: string,
      containsCondition: string,
    })
  ).isRequired,
};

VisibilityForm.defaultProps = {
  title: 'Displaying Rules',
};

const mapStateToProps = ({ libraryEnums: { valueFormats, stringMatching, conditionNames } }) => ({
  conditionNames,
  stringMatching,
  valueFormats,
});

const mapDispatchToProps = (dispatch) => ({
  setValueFormats: (data) => dispatch(setValueFormats(data)),
  setStringMatching: (data) => dispatch(setStringMatching(data)),
  setConditionNames: (data) => dispatch(setConditionNames(data)),
});

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(VisibilityForm);
