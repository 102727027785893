import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DeleteButton from 'components/DeleteButton';
import AddButton from 'components/AddButton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getRoleEnums } from 'services/contentService';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    alignContent: 'baseline',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  row: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  rowItemWithRemove: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'baseline',
  },
  removeButtonContainer: {
    marginLeft: theme.spacing(.5),
  },
}));

const CastAndCrew = ({ handleChange, model = {}, size = 'medium' }) => {
  const classes = useStyles();
  const [roles, setRoles] = useState([]);

  const emptyRecord = {
    name: '', // required
    roles: [], // at least one of Director|Writer|Producer|Cast|Photograpy|Production|Editor|Costume|Music|Casting|Effects|Stunts|Crew (getRoleEnums service)
    character: '', // required if roles includes 'Cast'
  };

  useEffect(() => {
    const getRoles = async () => {
      const enums = await getRoleEnums();
      setRoles(enums.map(k => ({ "label": Object.keys(k)[0], "value": Object.keys(k)[0] })))
    }
    getRoles();
  }, [])

  const handleRolesChange = (event, index) => {
    const newCredits = model.credits;
    if (event) {
      newCredits[index].roles = event.map(({ value }) => value);
    } else newCredits[index].roles = [];
    handleChange('credits', newCredits);
  };

  const handleNameChange = (value, index) => {
    const newCredits = model.credits;
    newCredits[index].name = value;
    handleChange('credits', newCredits)
  }

  const handleCharacterChange = (value, index) => {
    const newCredits = model.credits;
    newCredits[index].character = value;
    handleChange('credits', newCredits)
  }

  const addNewRecord = () => {
    handleChange('credits', [...model.credits, emptyRecord]);
  }

  const removeRecord = (index) => {
    let records = [...model.credits];
    records.splice(index, 1);
    handleChange('credits', records);
  }

  return (
    <Box className={classes.root}>
      {model.credits && model.credits.map?.((credit, index) => (
        <Grid container spacing={2} key={index} className={classes.row}>
          <Grid item sm={12} md={3} key={`name${index}`}>
            <TextField
              label={lookUp({ key: 'CONSOLE_CREATOR_NAME' })}
              placeholder={lookUp({ key: 'CONSOLE_CREATOR_NAME_HELPERTEXT' })}
              id={`artistName ${index}`}
              value={credit.name}
              className={classes.textField}
              onChange={(event) => handleNameChange(event.target.value, index)}
              size={size}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6} key={`roles${index}`}>
            <Autocomplete
              id={`Roles${index}`}
              label={lookUp({ key: 'CONSOLE_ROLES' })}
              options={roles}
              size={size}
              getOptionLabel={(option) => lookUp({ key: `CONSOLE_${option.label}` })}
              onChange={(event, value) => handleRolesChange(value, index)}
              placeholder={lookUp({ key: 'CONSOLE_ROLES_HELPERTEXT' })}
              multiple
              value={
                credit.roles
                  ? roles.filter(l => credit.roles.includes(l.value))
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder={lookUp({ key: 'CONSOLE_ROLES_HELPERTEXT' })}
                  size={size}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item sm={12} md={3} className={classes.rowItemWithRemove}>
            <Box flex={1}>
              <TextField
                label={lookUp({ key: 'CONSOLE_CHARACTER' })}
                placeholder={lookUp({ key: 'CONSOLE_CHARACTER_HELPERTEXT' })}
                id={`character ${index}`}
                value={credit.character}
                className={classes.textField}
                onChange={(event) => handleCharacterChange(event.target.value, index)}
                fullWidth
              />
            </Box>
            <Box className={classes.removeButtonContainer}>
              <DeleteButton
                onDelete={() => removeRecord(index)}
                variant="icon"
                size="small"
              />
            </Box>
          </Grid>
        </Grid>
      ))}
      <AddButton
        title={lookUp({ key: 'CONSOLE_CAST_AND_CREW_HELPERTEXT' })}
        onAdd={() => addNewRecord()}
      />
    </Box>
  );
};

CastAndCrew.propTypes = {
  model: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default CastAndCrew;
