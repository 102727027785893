import { useEffect, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {
  BackgroundBlurProvider,
  BackgroundReplacementProvider,
  MeetingProvider,
  lightTheme,
  UserActivityProvider,
} from 'amazon-chime-sdk-component-library-react';
import parametersService from 'services/parametersService';
import contentAction from 'store/actions/contentAction';
import { withSnackbar } from 'notistack';
import Meeting from './Meeting';


function ContentRecord(props) {
  const {
    enqueueSnackbar,
  } = props;

  const { id } = useParams();
  const location = useLocation();

  const selectedOrganization = useSelector((state) => state.auth.selectedOrganization);
  const orgsDetails = useSelector((state) => state.auth.orgsDetails);
  const isDefaultBckgForChime = useSelector((state) => state.content.isDefaultBckgForChime);

  const dispatch = useDispatch();

  useEffect(() => {
    const getRecordingBckg = async () => {
      try {
        const response = await parametersService.getByKey('Console:Camera:Default:Background');
        const isDefault = response?.value?.toLowerCase ? response.value.toLowerCase() === 'true' : false;
        dispatch(contentAction.setIsDefaultBckgForChime({ isDefault, paramChecked: true }))
      } catch (error) {
        dispatch(contentAction.setIsDefaultBckgForChime({ ...isDefaultBckgForChime, paramChecked: true }))
      }
    };

    if (!isDefaultBckgForChime.paramChecked) getRecordingBckg();
  }, [isDefaultBckgForChime]);

  const getDefaultBckgImageURL = useCallback(() => {
    const imgUrl = orgsDetails[selectedOrganization]?.properties['Customer:Image:LandscapeUrl'];
    if (isDefaultBckgForChime.paramChecked && isDefaultBckgForChime.isDefault && imgUrl) return imgUrl;
    return '';
  }, [isDefaultBckgForChime.paramChecked]);

  return (
    <ThemeProvider theme={lightTheme}>
      <BackgroundBlurProvider>
        <BackgroundReplacementProvider>
          <MeetingProvider>
            <UserActivityProvider>
              <Meeting
                id={id}
                defaultBckg={getDefaultBckgImageURL()}
                orgName={orgsDetails[selectedOrganization]?.userName}
                enqueueSnackbar={enqueueSnackbar}
                history={history}
                location={location}
              />
            </UserActivityProvider>
          </MeetingProvider>
        </BackgroundReplacementProvider>
      </BackgroundBlurProvider>
    </ThemeProvider>
  );
}

export default withSnackbar(ContentRecord);
