import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getFeedEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Feed');
  if (!endpoint) throw Error('No Feed endpoint.');
  return endpoint;
};

const search = ({ page = 0, perPage = 10, sortBy = 'name', order = 'asc', q = '', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getFeedEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        q,
        ...rest
      })
    )
    .then((r) => r.data);

const create = async (model) => axios.post(getFeedEndpoint(), model)
  .then((r) => r?.data);

const get = (id) => axios.get(`${getFeedEndpoint()}/${id}`)
  .then((r) => r?.data);

const edit = async (model) => axios.put(`${getFeedEndpoint()}/${model.id}`, model)
  .then((r) => r?.data)

const remove = (id) => axios.delete(`${getFeedEndpoint()}/${id}`)
  .then((r) => r?.data);

export { search, create, get, edit, remove };

export default {
  search,
  create,
  get,
  edit,
  remove
};
