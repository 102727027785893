const SET_PARAMETERS_ROWS_NUMBER = 'SET_PARAMETERS_ROWS_NUMBER';
const SET_PARAMETERS_PAGE_NUMBER = 'SET_PARAMETERS_PAGE_NUMBER';
const SET_ERROR_HIGHLIGHT = 'SET_ERROR_HIGHLIGHT';
const SET_LO_INHERIT_LANG = 'SET_LO_INHERIT_LANG';
const SET_PL_INHERIT_LANG = 'SET_PL_INHERIT_LANG';
const SET_DEFAULT_LOCALELANG = 'SET_DEFAULT_LOCALELANG';

const setParametersRowsNumber = (rowsNumber) => ({
  type: SET_PARAMETERS_ROWS_NUMBER,
  rowsNumber
});

const setParametersPageNumber = (pageNumber) => ({
  type: SET_PARAMETERS_PAGE_NUMBER,
  pageNumber
});

const setErrorHighlight = (isErrHighlight) => ({
  type: SET_ERROR_HIGHLIGHT,
  isErrHighlight 
});

const setInheritLOLang = (isInheritLOLang) => ({
  type: SET_LO_INHERIT_LANG,
  isInheritLOLang 
});

const setInheritPLLang = (isInheritPLLang) => ({
  type: SET_PL_INHERIT_LANG,
  isInheritPLLang 
});

const setDefaultLocaleLang = (defaultLocale) => ({
  type: SET_DEFAULT_LOCALELANG,
  defaultLocale, 
});


export {
  SET_PARAMETERS_PAGE_NUMBER,
  SET_PARAMETERS_ROWS_NUMBER,
  SET_ERROR_HIGHLIGHT,
  SET_LO_INHERIT_LANG,
  SET_PL_INHERIT_LANG,
  SET_DEFAULT_LOCALELANG,
  setParametersRowsNumber,
  setParametersPageNumber,
  setErrorHighlight,
  setInheritLOLang,
  setInheritPLLang,
  setDefaultLocaleLang,
};

export default {
  SET_PARAMETERS_ROWS_NUMBER,
  SET_PARAMETERS_PAGE_NUMBER,
  SET_ERROR_HIGHLIGHT,
  SET_LO_INHERIT_LANG,
  SET_PL_INHERIT_LANG,
  SET_DEFAULT_LOCALELANG,
  setParametersRowsNumber,
  setParametersPageNumber,
  setErrorHighlight,
  setInheritLOLang,
  setInheritPLLang,
  setDefaultLocaleLang,
};
