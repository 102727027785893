import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { number, string, node, arrayOf, shape, any, func } from 'prop-types';
import clsx from 'clsx';
import { Button, Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    flexGrow: 1,
    margin: theme.spacing(4, 0),
    width: 'auto',
    borderRadius: 3,
    height: '100%',
    background: '#00AEDB',
    boxShadow: '0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgb(0, 30, 233,.4)'
  },
  tabButton: {
    color: '#fff',
    width: '100%',
    height: '100%',
    //padding: '6px 24px'
     padding: '16px 26px'
  },
  activeTabButton: {
    height: '100%',
    borderBottom: '4px solid #004F7C',
    backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
  }
}));

const TabPanel = (props) => {
  
  const { children, index, value } = props;
  return value === index && children;
};

TabPanel.propTypes = {
  children: node,
  index: number.isRequired,
  value: any.isRequired
};

const TabsPanel = (props) => {
  
  const classes = useStyles();
  const { tabs, activeTab, setActiveTab } = props;

  return (
    <>
      <Grid container justifyContent={'flex-start'} alignItems={'flex-start'} className={classes.tabsContainer}>
        {tabs.map((tab) => (
          <Grid item key={tab.number} className={classes.tabButtonContainer}>
            <Button
              onClick={() => setActiveTab(tab.number)}
              startIcon={tab.icon}
              className={clsx(
                classes.tabButton,
                activeTab === tab.number && classes.activeTabButton
              )}
            >
              {tab.name}
            </Button>
          </Grid>
        ))}
      </Grid>
      {tabs.map((tab) => (
        <TabPanel index={tab.number} value={activeTab} key={tab.number}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

TabsPanel.propTypes = {
  tabs: arrayOf(
    shape({
      number: number.isRequired,
      name: string.isRequired,
      icon: node.isRequired,
      content: node.isRequired
    })
  ).isRequired,
  activeTab: number,
};

export default TabsPanel;
