import { makeStyles, IconButton } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  hoverMenu: {
    backgroundColor: 'white',
    position: 'absolute',
    right: 0,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    minWidth: 140,
    height: 50,
    justifyContent: 'flex-end',
    spacing: 40,
  },
}));

const HoverMenu = ({ buttons }) => {
  const classes = useStyles();
  return !buttons?.length > 0 ? null : (
    <div className={classes.hoverMenu}>
      {buttons?.map(
        (e, i) =>
          e.component || (
            <IconButton key={i} {...e}>
              {e.icon}
            </IconButton>
          )
      )}
    </div>
  );
};

export default HoverMenu;
