import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, oneOfType, number, func } from 'prop-types';
import { compose, withProps } from 'recompose';

import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';

import Loader from './Loader';

import CircleIcon from '../images/circle.svg';
import mapDarkStyle from 'helpers/mapDarkStyle.json';

const GOOGLE_API_KEY = 'AIzaSyBLi-9M7ef-6E6fLSMGgEMDvWXXoswVwMs';

/**
 * @return {null}
 */
function Map(props) {
	const [markers, setMarkers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		function retrieveData() {
			const { markers, geocode } = props;

			Promise.all(
				markers.map((marker) =>
					geocode(marker.address).then(({ results, status }) => {
  
						if (!results || !results.length) return null;

						const [r] = results;

						return {
							...marker,
							position: {
								lat: r.geometry.location.lat(),
								lng: r.geometry.location.lng()
							}
						};
					})
				)
			)
				.then((parsedMarkers) => {
					setMarkers(parsedMarkers.filter((m) => m));
					setIsLoading(false);
				})
				.catch((err) => console.error(err));
		}

		retrieveData();
	}, []);

	return isLoading ? null : (
		<GoogleMap
			defaultZoom={2}
			defaultCenter={markers.length ? markers[0].position : { lat: 0, lng: 0 }}
			defaultOptions={{
				styles: mapDarkStyle
			}}
		>
			<MarkerClusterer>
				{markers.map((marker) => (
					<MarkerWithLabel
						key={marker.id}
						position={marker.position}
						icon={CircleIcon}
						labelAnchor={new window.google.maps.Point(-12, 20)}
						labelStyle={{
							backgroundColor: '#f2be35',
							fontSize: 14,
							padding: '2px 4px',
							borderRadius: 5
						}}
					>
						<div>{marker.label}</div>
					</MarkerWithLabel>
				))}
			</MarkerClusterer>
		</GoogleMap>
	);
}

Map.propTypes = {
	markers: arrayOf(
		shape({
			id: oneOfType([string, number]),
			label: string,
			address: string
		})
	),
	geocode: func.isRequired
};

Map.defaultProps = {
	markers: []
};

export default compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing`,
		loadingElement: <Loader />,
		containerElement: <div style={{ height: 512 }} />,
		mapElement: <div style={{ height: '100%', borderRadius: 4 }} />,
		geocode: (address) =>
			new Promise((resolve) =>
				new window.google.maps.Geocoder().geocode({ address }, (results, status) =>
					resolve({ results, status })
				)
			)
	}),
	withScriptjs,
	withGoogleMap
)(Map);
