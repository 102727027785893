import React, { useEffect, useState, useRef } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { Button, LinearProgress } from '@material-ui/core';
import markerService from 'services/markerService';
import FaceGrid from './MarkerRenderers/FaceGrid';
import AlertService from 'services/alertService';


const RenderTags = ({
  id,
  markerTags,
  setMarkerTags,
  type,
  select,
  setBoundingBoxes,
  enqueueSnackbar,
}) => {
  

  const getMarker = async (markerId) => {
    const markerS = {
      marks: [],
      idOrToken: id,
      finished: false,
    };

    try {
      while (!markerS.finished) {
        const queryParams = { type, tagCloudParam: markerId, itemLimit: 500 };
        const reqResp = await markerService.search(markerS.idOrToken, queryParams);
        if (reqResp?.pageContent?.length > 0) {
          markerS.marks = markerS.marks.concat(reqResp.pageContent);
        }
        markerS.idOrToken = reqResp?.pagingToken;
        markerS.finished = reqResp?.finished;
        if (markerS.finished && markerS.marks.length > 0) return markerS.marks;
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: `CONSOLE_MARKER` }),
        }),
      });
    }
  };

  const selectMarker = (markerId) =>
    getMarker(markerId).then((markers) => {
      select(markers[0]);
      if (markers.length > 1) {
        setBoundingBoxes(
          markers.map(
            (e) =>
              JSON.parse(e.awsMeta).BoundingBox ||
              JSON.parse(e.awsMeta).Face?.BoundingBox ||
              JSON.parse(e.awsMeta).Geometry?.BoundingBox
          )
        );
      }
    });

  const basicDisplay = (markerTags) => {
    if (!markerTags || !markerTags[0]) return '';
    return Object.entries(markerTags[0])?.map((e, index) => (
      <Button
        key={index}
        onClick={() => {
          selectMarker(e[0]);
        }}
      >
        {e[1].displayName} {e[1].occurrence > 1 && `(${e[1].occurrence})`}
      </Button>
    ));
  };

  const [faces, setFaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFaces = async () => {
    if (!type === 'Face') return;
    setIsLoading(true);
    let idOrToken = id;
    let finished = false;
    try {
      while (!finished) {
        const queryParams = { type: 'Face' };
        const reqResp = await markerService.search(idOrToken, queryParams);
        setFaces((prev) => [...prev, ...reqResp.pageContent]);
        setMarkerTags((prev) => [...prev, ...reqResp.pageContent]);
        idOrToken = reqResp.pagingToken;
        finished = reqResp.finished;
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_load_error' })
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (type !== 'Face') return;
    getFaces();
  }, [type]);

  useEffect(() => {
    if (!markerTags[0]) return;
    let boxes = [];
    //   boxes = markerTags.filter(e => e.type === type).map(e => JSON.parse(e.awsMeta).BoundingBox || JSON.parse(e.awsMeta).Face?.BoundingBox || JSON.parse(e.awsMeta).Geometry?.BoundingBox )
    //   .filter(e => !!e);
    // setBoundingBoxes(boxes);
  }, [type]);

  switch (type) {
    case 'Face':
      return isLoading ? (
        <LinearProgress />
      ) : (
        faces.length > 0 && !isLoading && (
          <FaceGrid
            faces={faces}
            booleans={markerTags[0]}
            select={select}
            setBoundingBoxes={setBoundingBoxes}
          />
        )
      );
    case 'Celebrity':
    case 'Caption':
    case 'Label':
    case 'ContentModeration':
    default:
      return basicDisplay(markerTags);
  }
};

export default withSnackbar(RenderTags);
