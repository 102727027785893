import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  Popover,
  TextField,
  makeStyles,
  Typography,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    padding: theme.spacing(2),
  },
  popover: {
    maxWidth: '65%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PushModal = (props) => {
  
  let fileReader;
  const { handleClose, saveData } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState({firebase: false, apple: false});

  const handleClick = (event, button) => {
    setOpen(o => ({...open, [button]: true}));
    setAnchorEl(event.currentTarget);
  }

  const handlePopClose = () => {
    setOpen({firebase: false, apple: false});
    setAnchorEl(null);
  }

  const [model, setModel] = useState({
    firebasePushApiKey: '',
    applePushPrivateKey: '',
    applePushCertificate: '',
  });

  const handleModelChange = (key, value) => {
    if (key === 'awsAccountId') {
      const re = /^[0-9\b]+$/;
      if (value === '' || !re.test(value)) {
        return;
      }
    }

    setModel((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const handleFileChosen = (key, file) => {
    fileReader = new FileReader();
    if (key == 'applePushPrivateKey') {
      fileReader.onloadend = handleApplePushPrivateKeyFileRead;
    } else {
      fileReader.onloadend = handleApplePushCertificateFileRead;
    }
    fileReader.readAsText(file);
  };

  const handleApplePushPrivateKeyFileRead = () => {
    handleModelChange('applePushPrivateKey', fileReader.result);
  };

  const handleApplePushCertificateFileRead = () => {
    handleModelChange('applePushCertificate', fileReader.result);
  };

  return (
    <Dialog open onClose={handleClose} maxWidth={'md'}>
      <DialogTitle>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h5">Firebase configuration</Typography>
            </Grid>
            <TextField
              label={lookUp({ key: 'CONSOLE_FIREBASEPUSHAPIKEY' })}
              required
              fullWidth
              value={model.firebasePushApiKey}
              helperText={lookUp({ key: 'CONSOLE_FIREBASEPUSHAPIKEY_HELPERTEXT' })}
              onChange={(e) => handleModelChange('firebasePushApiKey', e.target.value)}
            />
            <Button size="small" onClick={(event) => handleClick(event, 'firebase')}>Where can I find this?</Button>
            <Popover
              id={"Firebase Helper"}
              open={open.firebase}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Typography className={classes.typography}>
                FireBase helper texts.
              </Typography>
            </Popover>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="h5">Apple notification configuration</Typography>
            </Grid>
            <TextField
              type="file"
              name="file"
              size="medium"
              helperText={"Apple Push private key"}
              fullWidth
              onChange={(e) => handleFileChosen('applePushPrivateKey', e.target.files[0])}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="file"
              name="file"
              size="medium"
              helperText={"Apple Push certificate"}
              fullWidth
              onChange={(e) => handleFileChosen('applePushCertificate', e.target.files[0])}
            />
            <Button size="small" onClick={(event) => handleClick(event, 'apple')}>Where can I find these?</Button>
            <Popover
              className={classes.popover}
              id={"Apple Push Helper"}
              open={open.apple}
              anchorEl={anchorEl}
              onClose={handlePopClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
            >
              <Typography className={classes.typography}>
                Apple notification helper text. The publishing procedure will take approx. 10-15 minutes. You will get an e-mail notification when it has been done!
              </Typography>
            </Popover>
          </Grid>
          The publishing procedure will take approx. 10-15 minutes. You will get an e-mail notification when it has been done!
        </Grid>
      </DialogContent>
      <DialogActions>
          <Button onClick={() => saveData(false)} color="primary" >
            Save changes
          </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PushModal;
