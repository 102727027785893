import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, bool, shape, number, string, func } from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';
import { ExpandMore, Clear } from '@material-ui/icons';

import PropsForm from './PropsForm';
import ActionsForm from './ActionsForm';
import RulesForm from './RulesForm';

const DecorationForm = (props) => {
  
  const { itemDecorations, handleChange } = props;
  const [decorations, setDecorations] = useState(itemDecorations);
  const [openTabs, setOpenTabs] = useState({});

  const emptyDecoration = {
    properties: [],
    displayRules: [],
    actions: []
  };

  const handleDecorationsChange = (index, key, value) => {
    const alterDecorations = [...decorations];
    alterDecorations[index] = { ...alterDecorations[index], [key]: value };
    setDecorations(alterDecorations);
    handleChange(alterDecorations);
  }

  const addDecoration = () => {
    const copyDecorations = !!decorations ? [...decorations, emptyDecoration] : [emptyDecoration];
    setDecorations(copyDecorations);
  }

  const removeDecoration = (event, index) => {
    event.stopPropagation();
    const copyDecorations = [...decorations];
    copyDecorations.splice(index, 1);
    setDecorations(copyDecorations);
    handleChange(copyDecorations);
  }

  const accordionTabbing = (event, index, tab) => {
    event.stopPropagation();
    setOpenTabs({ ...openTabs, [index]: `${openTabs[index] === tab ? '' : tab}` });
  }

  const openClose = (index) => {
    const value = openTabs[index] ? '' : 'actions';
    setOpenTabs({ ...openTabs, [index]: value });
  }

  return (
    <>
      {Array.isArray(decorations) && !!decorations.length && decorations.map((decoration, index) => (
        <Accordion expanded={!!openTabs[index]}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            id={`decoration${index}`}
            onClick={() => openClose(index)}
          >
            <IconButton onClick={(event) => removeDecoration(event, index)}>
              <Clear />
            </IconButton>

            <Button>
              {(decoration.name || lookUp({ key: 'CONSOLE_DECORATION'})) + ":"}
            </Button>

            <Button color={openTabs[index] === 'actions' ? 'primary' : 'default'} onClick={(event) => accordionTabbing(event, index, 'actions')}>{decoration.actions?.length != 1 ? lookUp({ key: 'CONSOLE_ACTIONS' }) : lookUp({ key: 'CONSOLE_ACTION' })}</Button>
            <Button color={openTabs[index] === 'properties' ? 'primary' : 'default'} onClick={(event) => accordionTabbing(event, index, 'properties')}>{decoration.properties?.length != 1 ? lookUp({ key: 'CONSOLE_PROPERTIES' }) : lookUp({ key: 'CONSOLE_PROPERTY' })}</Button>
            <Button color={openTabs[index] === 'filters' ? 'primary' : 'default'} onClick={(event) => accordionTabbing(event, index, 'filters')}>{decoration.filters?.length != 1 ? lookUp({ key: 'CONSOLE_DISPLAY_RULES' }) : lookUp({ key: 'CONSOLE_DISPLAY_RULE' })}</Button>
            
          </AccordionSummary>
          <AccordionDetails style={{ display: "inherit" }}>
            <TextField
              label={lookUp({ key: 'CONSOLE_DECORATION_NAME' })}
              size="medium"
              //fullWidth
              value={decoration.name || ""}
              onChange={(e) => handleDecorationsChange(index, 'name', e.target.value)}
              />
            {openTabs[index] === 'actions' &&
              <ActionsForm
                key={index}
                actions={decoration.actions}
                handleChange={(data) => handleDecorationsChange(index, 'actions', data)}
              />
            } {openTabs[index] === 'properties' &&
              <PropsForm
                key={index}
                properties={decoration.properties}
                handleChange={(data) => handleDecorationsChange(index, 'properties', data)}
              />
            } {openTabs[index] === 'filters' &&
              <RulesForm
                key={index}
                rules={decoration.displayRules}
                handleChange={(data) => handleDecorationsChange(index, 'displayRules', data)}
              />
            }
          </AccordionDetails>
        </Accordion>
      ))}
      <p/>
      <Button  color="primary" onClick={addDecoration}>{lookUp({ key: 'CONSOLE_ADD_DECORATION_BUTTON' })}</Button>
    </>
  );
};

DecorationForm.propTypes = {
  handleChange: func.isRequired,
  itemDecorations: arrayOf(
    shape({
      properties: arrayOf(
        shape({
          collection: string,
          name: string,
          value: string
        })
      ),
      displayRules: arrayOf(
        shape({
          index: number,
          propertyName: string,
          formatting: string,
          condition: string,
          propertyValue: string,
          valueIsReference: bool,
          valueCollectionSeparator: string,
          propertyCollectionSeparator: string,
          containsCondition: string
        })
      ),
      actions: arrayOf(
        shape({
          name: string,
          enabled: bool,
          actionType: string,
          displayRules: arrayOf(
            shape({
              propertyName: string,
              formatting: string,
              condition: string,
              propertyValue: string,
              valueIsReference: bool,
              valueCollectionSeparator: string,
              propertyCollectionSeparator: string,
              containsCondition: string
            })
          ),
          groupName: string,
          httpMethod: string,
          url: string,
          parameters: arrayOf(
            shape({
              collection: string,
              name: string,
              value: string
            })
          ),
          schedule: shape({
            type: string,
            delay: string,
            interval: string,
            eventName: string
          })
        })
      )
    })
  )
};

export default DecorationForm;
