import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ClearIcon from '@material-ui/icons/Clear';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import EditIcon from '@material-ui/icons/Edit';
import { msToTime, getMs } from 'helpers/time';
import AlertService from 'services/alertService';
import markerService from 'services/markerService';
import MarkerEditorDialog from '../common/MarkerEditorDialog';
import ControlBox from 'components/ControlBox';
import AddButton from 'components/AddButton';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(1),
    maxHeight: `calc(100% - ${theme.spacing(1.5)}px)`,
    width: '100%',
  },
  contentContainer: {
    gap: theme.spacing(1),
    height: '100%',
  },
  scrollingArea: {
    height: `calc(100% - ${theme.spacing(8)}px + ${theme.spacing(1)}px)`,
    overflowY: 'auto',
  },
  chaptersContainer: {
    height: 'auto',
  },
  chapterCard: {
    // FIXME the top/bottom padding is only to account for the corner buttons that go outside of the content box, remove once the those corner buttons are in the main content card
    padding: theme.spacing(1, .5),
    margin: 0,
    background: theme.palette.background.paper,
  },
}));

function RemixChapters ({
  video,
  remixId,
  childContents,
  setChildContents,
  chapters = [],
  setChapters,
  setMainVideoUrl,
  duration,
  setDuration,
  seek,
  hideTitle,
  contentType,
  enqueueSnackbar,
}) {
  const classes = useStyles();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedChapterId, setSelectedChapterId] = useState(null);
  const [selAssetIndx, setSelAssetIndx] = useState(0);

  const getCurrentTime = () => Math.floor(video?.currentTime || 0) * 1000;

  const calcPosition = () => {
    if (chapters.length === 0) return '00:00:00';
    if (getCurrentTime() === 0 || chapters.map((e) => getMs(e.position))?.includes(getCurrentTime())) {
      return msToTime(getMs(chapters[chapters.length-1]?.end || Math.round(duration / 2)));
    } else {
      return msToTime(getCurrentTime());
    }
  };

  const addChapter = async () => {
    const newChapter = {
      type: 'Chapter',
      title: lookUp({ key: 'CONSOLE_NEW_CHAPTER' }),
      contentId: contentType === 'Remix' ? remixId : childContents[selAssetIndx]?.contentId,
      position: calcPosition(),
      duration: '00:00:05',
    };

    markerService.create(newChapter)
      .then((respChapter) => {
        if (respChapter?.id) {
          setChapters((ch) => [...ch, respChapter]);
          setChildContents((childContents) => {
            const chaptersOnAsset = [...childContents[selAssetIndx]?.chapters];
            chaptersOnAsset.push(respChapter);
            childContents[selAssetIndx].chapters = chaptersOnAsset;
            return [...childContents];
          });
          setSelectedChapterId(respChapter.id)
          setIsEditModalOpen(true);
        }
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: `${lookUp({ key: 'CONSOLE_NEW_CHAPTER' })} — `,
        });
      });
  };

  const removeChapter = (id) => {
    markerService.remove(id)
      .then(() => {
        setChapters((chpt) => [...chpt].filter((e) => e.id !== id));
        setChildContents((childContents) => {
          const chaptersOnAsset = [...childContents[selAssetIndx]?.chapters];
          const chptIndx = chapters.findIndex((chapter) => chapter.id === id);
          chaptersOnAsset.splice(chptIndx, 1);
          childContents[selAssetIndx].chapters = [...chaptersOnAsset];
          return [...childContents];
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: `${lookUp({ key: 'CONSOLE_DELETE_CHAPTER_HELPERTEXT' })} — `,
        });
      });
  };

  const updateChapter = (_, chapterData) => {
    markerService.edit(chapterData)
      .then((response) => {
        const updChapIndx = chapters.findIndex((chapter) => chapter.id === selectedChapterId);
        setChapters((chapters) => {
          const updChapters = [...chapters];
          updChapters[updChapIndx] = { ...response };
          return updChapters;
        });
        setChildContents((childContents) => {
          const chaptersOnAsset = [...childContents[selAssetIndx]?.chapters];
          chaptersOnAsset[updChapIndx] = { ...response };
          childContents[selAssetIndx].chapters = [...chaptersOnAsset];
          return [...childContents];
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: `${lookUp({ key: 'CONSOLE_EDIT_CHAPTER' })} — `,
        });
      })
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedChapterId(null);
  };

  useEffect(() => {
    if (
      selAssetIndx !== undefined &&
      contentType === 'Playlist' &&
      childContents?.length
    ) {
      setChapters(childContents[selAssetIndx]?.chapters);
      setDuration(childContents[selAssetIndx]?.duration);
    }
  }, [selAssetIndx]);

  return (
    <Box flex={1} className={classes.root}>
      <Grid container className={classes.contentContainer}>
        {!hideTitle && (
          <Grid item>
            {!hideTitle && (
              <Typography variant="h6" gutterBottom>{lookUp({ key: 'CONSOLE_CHAPTERS_TAB' })}</Typography>
            )}
          </Grid>
        )}
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
        >
          {(contentType === 'Playlist' && childContents?.length > 1)
            ? (
                <FormControl>
                  <TextField
                    id="condition"
                    label={lookUp({ key: 'CONSOLE_CONDITION' })}
                    placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                    aria-describedby="condition-helper"
                    value={selAssetIndx}
                    onChange={({ target }) => {
                      setSelAssetIndx(target.value);
                      if (childContents[target.value]?.videoUrl) {
                        setMainVideoUrl(childContents[target.value]?.videoUrl);
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    size="small"
                    select
                  >
                    {childContents.map?.((asset, i) => (
                      <MenuItem
                        key={`${asset.contentId}_${i}`}
                        label={asset.originalTitle || asset.contentId}
                        value={i}
                      >
                        {asset.originalTitle || asset.contentId}
                      </MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText id="condition-helper">
                    {lookUp({ key: 'CONSOLE_CHAPTER_SELECT_CONDITION_HELPERTEXT' })}
                  </FormHelperText>
                </FormControl>
              )
            : null
          }
          <Box flex={1} />
          <AddButton
            tooltipText={`${lookUp({ key: 'CONSOLE_ADD_BUTTON' })} ${lookUp({ key: 'CONSOLE_CHAPTER' })}`}
            onAdd={() => addChapter()}
            disabled={contentType === 'Playlist' && childContents.length < 1}
          />
        </Grid>
        <Grid xs={12} className={classes.scrollingArea}>
          <Grid direction="column" className={classes.chaptersContainer}>
            {/* FIXME do not map twice */}
              {chapters
                .map((chapter) => {
                  return {
                    ...chapter,
                    position: msToTime(getMs(chapter.position)),
                    duration: msToTime(getMs(chapter.duration)),
                    end: msToTime(getMs(chapter.end)),
                  };
                })
                .map((chapter) => (
                  <Grid
                    className={classes.chapterCard}
                    key={`${chapter.id}`}
                    xs={12}
                    spacing={1}
                    container
                  >
                    <Grid item md={3} xs={12}>
                      {/* FIXME refactor <ControlBox /> into <ListItem /> */}
                      <ControlBox
                        width="100%"
                        height={100}
                        offset={6}
                        topButtons={[
                          {
                            selector: 'iconButton',
                            icon: <EditIcon fontSize="small" />,
                            onClick: () => {
                              setSelectedChapterId(chapter?.id);
                              setIsEditModalOpen(true);
                            },
                            tooltip: lookUp({ key: 'CONSOLE_EDIT_CHAPTER_HELPERTEXT' }),
                          },
                          {
                            selector: 'iconButton',
                            icon: <ClearIcon fontSize="small" />,
                            onClick: () => removeChapter(chapter?.id),
                            tooltip: lookUp({ key: 'CONSOLE_DELETE_CHAPTER_HELPERTEXT' }),
                          },
                        ]}
                        centerButton={{
                          icon: <PlayArrowIcon fontSize="small" />,
                          onClick: () => {
                            seek(chapter.position);
                            video.play();
                          },
                          tooltip: lookUp({ key: 'CONSOLE_PLAY_CHAPTER_HELPERTEXT' }),
                          selector: 'centerButton',
                        }}
                        bottomButtons={[
                          {
                            selector: 'iconButton',
                            icon: <SkipPreviousIcon fontSize="small" />,
                            onClick: () => {
                              seek(chapter.position);
                              video.play();
                            },
                            tooltip: lookUp({ key: 'CONSOLE_CHAPTER_CUE_IN_HELPERTEXT' }),
                          },
                          {
                            selector: 'iconButton',
                            icon: <SkipNextIcon fontSize="small" />,
                            onClick: () => {
                              seek(chapter.end);
                              video.play();
                            },
                            tooltip: lookUp({ key: 'CONSOLE_CHAPTER_CUE_OUT_HELPERTEXT' }),
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <Typography variant="body1" noWrap gutterBottom>
                        {chapter.title}
                      </Typography>
                      <Typography component="div" variant="caption">
                        {chapter.position?.split('.')?.[0]} -- {chapter.end?.split('.')?.[0]}
                      </Typography>
                      <Typography variant="caption">
                        {chapter.description}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Grid>
      {isEditModalOpen && (
        <MarkerEditorDialog
          open={isEditModalOpen}
          onClose={handleEditModalClose}
          selectedBrPt={chapters.find((chapter) => chapter.id === selectedChapterId) || {}}
          updateBreakpoint={updateChapter}
        />
      )}
    </Box>
  );
}

RemixChapters.propTypes = {
  video: PropTypes.any,
  remixId: PropTypes.string,
  childContents: PropTypes.array,
  setChildContents: PropTypes.func,
  chapters: PropTypes.array,
  setChapters: PropTypes.func,
  setMainVideoUrl: PropTypes.func,
  duration: PropTypes.number,
  setDuration: PropTypes.func,
  seek: PropTypes.func,
  hideTitle: PropTypes.bool,
  contentType: PropTypes.oneOf(['Collection', 'Playlist', 'Remix']).isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(RemixChapters);
