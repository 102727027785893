import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getCountryEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Country');
  if (!endpoint) throw Error('No Country endpoints.');
  return endpoint;
};

const searchQuery = (search, pageSize = 25, page = 0) => {
  search.delete('page');
  search.delete('pageSize');
  return axios
    .get(`${getConsoleApiEndpoint('Country')}/Search/${pageSize}/${page}?${search}`)
    .then(res => res.data);}

const search = ({ page = 0, perPage = 1000, sortExpr = 'code asc, enabled desc', ...rest } = {}) => {
    const endpoint = getCountryEndpoint();
    if (!endpoint) throw Error('No Country endpoints.')
    return axios
    .get(
      makeApiWithQuery(`${endpoint}/Search/${perPage}/${page}`, {
        sortExpr,
        ...rest
      })
    )
    .then((r) => r?.data);
};

const remove = (code) => axios.delete(`${getCountryEndpoint()}/${code}`)
  .then((r) => r?.data);

const get = (code) => axios.get(`${getCountryEndpoint()}/${code}`)
  .then((r) => r?.data);

const create = (code, model) => axios.post(`${getCountryEndpoint()}/${code}`, model)
  .then((r) => r?.data);

const update = (code, model) => axios.put(`${getCountryEndpoint()}/${code}`, model)
  .then((r) => r?.data);

const getRegions = (e) => {
  const endpoint = getCountryEndpoint();
  if (!endpoint) throw Error('No Country endpoints.');
  return axios
    .get(`${endpoint}/Search/1000/0${e ? '?e=true' : ''}`)
    .then((r) => r?.data?.data
      .reduce((regions, country) => {
        if (country.regions.length > 0) {
          country.regions
          .forEach(region => regions[region] = Array.from(regions[region] ? [...regions[region], country.code] : [country.code]))
        }
        return regions;
      }, {})
    )
}

export { search, get, create, update, remove, getRegions };
export default { search, searchQuery, get, create, update, remove, getRegions };
