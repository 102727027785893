import { lookUp } from 'services/stringService';
import { Grid, Typography } from '@material-ui/core';
import WebsiteInput from 'components/WebsiteInput';

const SocialTab = ({ model, handlePropertiesChange }) => {
  
  return (
    <Grid container xs={6} spacing={2}>
      <Grid item>
        <Typography variant="h6">{lookUp({ key: 'CONSOLE_WEBSITE_TITLE' })}</Typography>
      </Grid>
      <WebsiteInput
        url={model.properties?.['Custom:User:WebsiteURL'] || ''}
        handleChange={(url) => handlePropertiesChange({ 'Custom:User:WebsiteURL': url })}
      />
      <Grid item xs={12}>
        <Typography variant="h6">{lookUp({ key: 'CONSOLE_PROFILE_URLS_TITLE' })}</Typography>
      </Grid>
      {Object.entries(model.properties)
        .filter((e) => e[0].indexOf('Custom:Social:') > -1)
        ?.map((e) => (
          <Grid item xs={12}>
            <WebsiteInput
              url={model.properties?.[e[0]] || ''}
              handleChange={(url) => handlePropertiesChange({ [e[0]]: url })}
              label={e[0].replace('Custom:Social:', '')}
              handleChange={(url) => handlePropertiesChange({ [e[0]]: url })}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default SocialTab;
