import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const brandId = window.__RUNTIME_CONFIG__.BRAND_ID;
const emptyGUID = '00000000-0000-0000-0000-000000000000';

const getParameterEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Parameter');
  if (!endpoint) throw Error('No Parameter endpoints.');
  return endpoint;
};

const searchQuery = (search, pageSize = 25, page = 0) => {
  const isGlobalSearch = search.get('isGlobalSearch');
  search.delete('page');
  search.delete('pageSize');
  return axios
    .get(`${getParameterEndpoint()}/Search/${pageSize}/${page}?${search}`, { headers: { brandId: isGlobalSearch === 'true' ? '00000000-0000-0000-0000-000000000000' : brandId } })
    .then(res => res.data);}

function search({ page = 0, perPage = 10, sortExpr = 'key', order, ...rest } = {}) {
  return axios.get(makeApiWithQuery(`${getParameterEndpoint()}/Search/${perPage}/${page}`, {
      sortExpr,
      ...rest
    }),
    { headers: { brandId } },
  )
  .then((r) => r.data);
}

function globalSearch({ page = 0, perPage = 10, sortExpr = 'key', order, ...rest } = {}) {
  return axios({
    method: 'get',
    url: makeApiWithQuery(`${getParameterEndpoint()}/Search/${perPage}/${page}`, { sortExpr, ...rest }),
    headers: { 'brandId':  emptyGUID }
  })
  .then((r) => r.data)
}

const create = (model, isGlobal) => axios.post(
    getParameterEndpoint(),
    model,
    { headers: { brandId: isGlobal ? emptyGUID : brandId }}
  )
  .then((r) => r?.data);

const getByKey = (key, isGlobal) => axios.get(
    `${getParameterEndpoint()}/${key}`,
    { headers: { brandId: isGlobal ? emptyGUID : brandId } }
  )
  .then((r) => r?.data);

const edit = (model, isGlobal) => axios.put(
    getParameterEndpoint(),
    model,
    { headers: { brandId: isGlobal ? emptyGUID : brandId }}
  )
  .then((response) => response?.data);
 
const remove = (key, isGlobal) => axios.delete(
    `${getParameterEndpoint()}/${key}`,
    { headers: { brandId: isGlobal ? emptyGUID : brandId }}
  )
  .then((r) => r?.data);

const getAccess = () => axios.get(process.env.PUBLIC_URL + '/access.json').then(res => res.data);

const getTheme = () => axios.get(process.env.PUBLIC_URL + '/theme.json').then(res => res.data);

export { search, create, remove, getByKey, getAccess, getTheme };

export default {
  search,
  searchQuery, 
  globalSearch,
  getByKey,
  create,
  edit,
  remove,
  getAccess,
  getTheme,
};
