import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';

// regular expression that captures the category from the Enum value i.e Enum:RewardCategory:ContentPlayback will return ContentPlayback
const CATEGORY_ENUM_REGEXP = /([^:]+$)/g;

const getRewardsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Reward');
  if (!endpoint) throw Error('No Rewards endpoint.');
  return endpoint;
};

const search = ({ page = 0, perPage = 1000, q = '', sortBy = 'name asc', ...rest } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getRewardsEndpoint()}/Credit/Search/${perPage}/${page}`, {
      sortBy,
      q,
      ...rest
    }))
    .then(response => response?.data)
};

const getAll = ({ page = 0, perPage = 1000, query = '', sortBy, order } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getRewardsEndpoint()}/Credit/Search/${perPage}/${page}`, {
      q: query,
      sortBy,
      order
    })
  )
    .then(response => response?.data)
};

const create = (model) => {
  return axios.post(`${getRewardsEndpoint()}/Credit/${encodeURIComponent(model.key)}`, model)
    .then(response => response?.data)
};

const get = (id) => {
  return axios.get(`${getRewardsEndpoint()}/Credit/${id}`)
    .then(response => response?.data)
};

const edit = (model) => {
  return axios.put(`${getRewardsEndpoint()}/Credit/${encodeURIComponent(model.key)}`, model)
    .then(response => response?.data)
};

const remove = (id) => {
  return axios.delete(`${getRewardsEndpoint()}/Credit/${id}`)
    .then(response => response?.data)
};

const getRewardHistory = (customerId) => {
  return axios.get(`${getRewardsEndpoint()}/History/${customerId}`)
  .then((r) => r?.data);
}

const getRewardPagingToken = (PagingToken) => {
  return axios.get(`${getRewardsEndpoint()}/History/${PagingToken}`)
  .then((r) => r?.data);
}

const getReward = (customerId) => {
  return axios.get(`${getRewardsEndpoint()}/${customerId}`)
    .then(r => r.data)
}

const addReward = (customerId, internalPrice, tokenPrice, fromCustomerId, friendlyName) => {
  return axios
    .post(
      `${getRewardsEndpoint()}/${customerId}`,
      {
        internalPrice,
        tokenPrice,
        fromCustomerId,
        friendlyName,
      },
      {
        params: {
          projectId: fromCustomerId,
        },
      },
    )
    .then((r) => r?.data);
};

const editReward = (customerId, internalPrice, tokenPrice) => {
  return axios.post(`${getRewardsEndpoint()}/${customerId}/Refresh`, {
    internalPrice,
    tokenPrice
  })
  .then((r) => r?.data);
}

// FIXME replace hardcoded endpoitn with dynamic brand one, was unable the required endpoint using getConsoleApiEndpoint
/**
 * fetch reward category enums, map the returned hasmap into an array of category ids as string
 * i.e. Enum:RewardCategory:ContentPlayback into ContentPlayback
 * 
 * @returns string[]
 */
const getTypeEnums = async () => {
  const { data } = await axios.get(
    `https://gxo.imaginaru.ent360.blue/api/v1.0/Enums/RewardCategories`,
  );

  const categories = Object.entries(data).map(([categoryEnum = '']) =>
    categoryEnum.match?.(CATEGORY_ENUM_REGEXP).toString?.(),
  );

  return categories ?? [];
};

export { search, getAll, create, get, edit, remove, getRewardHistory, getRewardPagingToken, getReward, addReward, editReward };

export default {
  search,
  getAll,
  create,
  get,
  edit,
  remove,
  getRewardHistory,
  getRewardPagingToken,
  getReward,
  addReward,
  editReward,
  getTypeEnums,
};
