
const style = { width: "50px", height: "50px" }

const AudioOnly = () => {
  return (
    <svg width="261" height="346" viewBox="0 0 261 346" fill="#none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M254.784 62.7866C253.344 62.7866 251.905 62.24 250.798 61.1333C218.641 28.9733 175.819 11.2533 130.21 11.2533C84.6013 11.2533 41.7657 28.9733 9.60893 61.1333C7.40915 63.3333 3.84961 63.3333 1.64983 61.1333C-0.549945 58.9333 -0.549945 55.3733 1.64983 53.1733C35.9397 18.8799 81.5883 -3.05176e-05 130.21 -3.05176e-05C178.819 -3.05176e-05 224.481 18.8799 258.771 53.1733C260.97 55.3733 260.97 58.9333 258.771 61.1333C257.664 62.24 256.224 62.7866 254.784 62.7866Z" fill="#100F0D"/>
      <path d="M228.239 89.334C226.8 89.334 225.346 88.7873 224.253 87.6807C199.176 62.6007 165.779 48.7873 130.209 48.7873C94.6394 48.7873 61.2428 62.6007 36.1653 87.6807C33.9655 89.8807 30.3926 89.8807 28.1928 87.6807C25.993 85.4807 25.993 81.9206 28.1928 79.7206C55.4034 52.5073 91.6263 37.5206 130.209 37.5206C168.779 37.5206 205.015 52.5073 232.212 79.7206C234.412 81.9206 234.412 85.4807 232.212 87.6807C231.119 88.7873 229.679 89.334 228.239 89.334Z" fill="#100F0D"/>
      <path d="M201.683 115.894C200.243 115.894 198.803 115.334 197.71 114.241C160.487 77.0141 99.9331 77.0141 62.7101 114.241C60.5104 116.441 56.9374 116.441 54.7509 114.241C52.5511 112.041 52.5511 108.467 54.7509 106.267C96.3601 64.6541 164.06 64.6541 205.669 106.267C207.869 108.467 207.869 112.041 205.669 114.241C204.576 115.334 203.123 115.894 201.683 115.894Z" fill="#100F0D"/>
      <path d="M130.209 123.827C98.2788 123.827 72.295 149.8 72.295 181.747C72.295 213.68 98.2788 239.654 130.209 239.654C162.139 239.654 188.11 213.68 188.11 181.747C188.11 149.8 162.139 123.827 130.209 123.827ZM130.209 250.92C92.0661 250.92 61.0293 219.894 61.0293 181.747C61.0293 143.6 92.0661 112.56 130.209 112.56C168.352 112.56 199.375 143.6 199.375 181.747C199.375 219.894 168.352 250.92 130.209 250.92Z" fill="#100F0D"/>
      <path d="M132.929 250.921C88.5336 250.921 52.4306 287.041 52.4306 331.428C52.4306 332.534 52.4305 333.441 52.4705 334.268L213.374 334.121C213.401 333.214 213.428 332.321 213.428 331.428C213.428 287.041 177.311 250.921 132.929 250.921ZM47.2978 345.534C44.458 345.534 42.0584 343.414 41.7117 340.601C41.2718 337.068 41.165 335.188 41.165 331.428C41.165 280.828 82.3209 239.654 132.929 239.654C183.524 239.654 224.68 280.828 224.68 331.428C224.68 334.374 224.547 337.361 224.253 340.281C223.973 343.174 221.56 345.374 218.654 345.374L47.3112 345.534C47.3112 345.534 47.3111 345.534 47.2978 345.534Z" fill="#100F0D"/>
    </svg>
  )
}

const CameraRecording = () => {
  return (
    <svg width="349" height="319" viewBox="0 0 349 319" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M221.359 262.587H125.003C114.563 262.587 106.068 254.094 106.068 243.654V222.814C106.068 219.707 108.589 217.187 111.699 217.187C114.811 217.187 117.332 219.707 117.332 222.814V243.654C117.332 247.88 120.773 251.32 125.003 251.32H221.359C225.589 251.32 229.031 247.88 229.031 243.654V222.814C229.031 219.707 231.553 217.187 234.663 217.187C237.773 217.187 240.294 219.707 240.294 222.814V243.654C240.294 254.094 231.801 262.587 221.359 262.587Z" fill="#100F0D"/>
      <path d="M229.736 318.68C228.294 318.68 226.853 318.12 225.753 317.026L173.651 264.92L121.784 316.786C119.584 318.986 116.019 318.986 113.819 316.786C111.619 314.586 111.619 311.026 113.819 308.826L169.668 252.973C171.868 250.773 175.434 250.773 177.633 252.973L233.718 309.066C235.918 311.266 235.918 314.826 233.718 317.026C232.618 318.12 231.177 318.68 229.736 318.68Z" fill="#100F0D"/>
      <path d="M18.9368 45.4002C14.7065 45.4002 11.2642 48.8403 11.2642 53.0803V209.507C11.2642 213.747 14.7065 217.187 18.9368 217.187H329.304C333.534 217.187 336.975 213.747 336.975 209.507V53.0803C336.975 48.8403 333.534 45.4002 329.304 45.4002H237.18C234.987 45.4002 232.993 44.1337 232.071 42.147L220.523 17.2269C218.846 13.6003 215.186 11.2669 211.197 11.2669H135.37C131.301 11.2669 127.611 13.6669 125.967 17.4002L115.082 42.0536C114.181 44.0936 112.161 45.4002 109.93 45.4002H18.9368ZM329.304 228.454H18.9368C8.49515 228.454 0 219.96 0 209.507V53.0803C0 42.6403 8.49515 34.1336 18.9368 34.1336H106.26L115.664 12.8403C119.108 5.04034 126.843 0.000244141 135.37 0.000244141H211.197C219.557 0.000244141 227.228 4.90698 230.742 12.4803L240.777 34.1336H329.304C339.746 34.1336 348.24 42.6403 348.24 53.0803V209.507C348.24 219.96 339.746 228.454 329.304 228.454Z" fill="#100F0D"/>
      <path d="M46.4631 185.266H215.421V80.1195H46.4631V185.266ZM221.052 196.52H40.8316C37.7213 196.52 35.2002 194 35.2002 190.893V74.4928C35.2002 71.3728 37.7213 68.8529 40.8316 68.8529H221.052C224.163 68.8529 226.685 71.3728 226.685 74.4928V190.893C226.685 194 224.163 196.52 221.052 196.52Z" fill="#100F0D"/>
      <path d="M282.065 126.587C269.385 126.587 259.069 136.907 259.069 149.587C259.069 162.267 269.385 172.587 282.065 172.587C294.745 172.587 305.063 162.267 305.063 149.587C305.063 136.907 294.745 126.587 282.065 126.587ZM282.065 183.854C263.174 183.854 247.805 168.48 247.805 149.587C247.805 130.694 263.174 115.32 282.065 115.32C300.957 115.32 316.326 130.694 316.326 149.587C316.326 168.48 300.957 183.854 282.065 183.854Z" fill="#100F0D"/>
      <path d="M271.739 97.9604C268.629 97.9604 266.108 95.4405 266.108 92.3205V80.1205C266.108 77.0138 268.629 74.4938 271.739 74.4938C274.85 74.4938 277.372 77.0138 277.372 80.1205V92.3205C277.372 95.4405 274.85 97.9604 271.739 97.9604Z" fill="#100F0D"/>
      <path d="M296.145 97.9604C293.034 97.9604 290.513 95.4405 290.513 92.3205V80.1205C290.513 77.0138 293.034 74.4938 296.145 74.4938C299.255 74.4938 301.776 77.0138 301.776 80.1205V92.3205C301.776 95.4405 299.255 97.9604 296.145 97.9604Z" fill="#100F0D"/>
      <path d="M127.151 110.214C116.074 110.214 107.063 119.227 107.063 130.307C107.063 141.387 116.074 150.387 127.151 150.387C138.227 150.387 147.24 141.387 147.24 130.307C147.24 119.227 138.227 110.214 127.151 110.214ZM127.151 161.654C109.862 161.654 95.7983 147.587 95.7983 130.307C95.7983 113.014 109.862 98.9472 127.151 98.9472C144.439 98.9472 158.504 113.014 158.504 130.307C158.504 147.587 144.439 161.654 127.151 161.654Z" fill="#100F0D"/>
      <path d="M163.114 196.52C160.004 196.52 157.483 194 157.483 190.893C157.483 174.773 144.37 161.653 128.251 161.653C112.133 161.653 99.0196 174.773 99.0196 190.893C99.0196 194 96.4972 196.52 93.3868 196.52C90.2764 196.52 87.7554 194 87.7554 190.893C87.7554 168.56 105.922 150.386 128.251 150.386C150.581 150.386 168.747 168.56 168.747 190.893C168.747 194 166.225 196.52 163.114 196.52Z" fill="#100F0D"/>
      <path d="M127.151 120.214C116.074 120.214 107.063 129.227 107.063 140.307C107.063 151.387 116.074 160.387 127.151 160.387C138.227 160.387 147.24 151.387 147.24 140.307C147.24 129.227 138.227 120.214 127.151 120.214ZM127.151 171.654C109.862 171.654 95.7983 157.587 95.7983 140.307C95.7983 123.014 109.862 108.947 127.151 108.947C144.439 108.947 158.504 123.014 158.504 140.307C158.504 157.587 144.439 171.654 127.151 171.654Z" fill="#100F0D"/>
    </svg>
  )
}

const ScreenRecording = () => {
  return (
    <svg width="349" height="307" viewBox="0 0 349 307" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M150.344 81.2932C146.117 81.2932 142.678 84.7467 142.678 88.9734V156.427C142.678 160.653 146.117 164.107 150.344 164.107H268.292C272.518 164.107 275.971 160.653 275.971 156.427V88.9734C275.971 84.7467 272.518 81.2932 268.292 81.2932H150.344ZM268.292 175.36H150.344C139.905 175.36 131.412 166.867 131.412 156.427V88.9734C131.412 78.5334 139.905 70.0401 150.344 70.0401H268.292C278.731 70.0401 287.223 78.5334 287.223 88.9734V156.427C287.223 166.867 278.731 175.36 268.292 175.36Z" fill="#100F0D"/>
      <path d="M137.039 145.96H115.148C104.709 145.96 96.2168 137.467 96.2168 127.027V59.574C96.2168 49.134 104.709 40.6407 115.148 40.6407H233.096C243.535 40.6407 252.028 49.134 252.028 59.574V75.6672C252.028 78.7739 249.508 81.2938 246.402 81.2938C243.282 81.2938 240.762 78.7739 240.762 75.6672V59.574C240.762 55.3473 237.323 51.8938 233.096 51.8938H115.148C110.922 51.8938 107.482 55.3473 107.482 59.574V127.027C107.482 131.254 110.922 134.707 115.148 134.707H137.039C140.159 134.707 142.679 137.227 142.679 140.334C142.679 143.441 140.159 145.96 137.039 145.96Z" fill="#100F0D"/>
      <path d="M150.345 175.36H78.0721C67.6331 175.36 59.1406 166.867 59.1406 156.427V88.9734C59.1406 78.5334 67.6331 70.0401 78.0721 70.0401H101.843C104.963 70.0401 107.483 72.56 107.483 75.6666C107.483 78.7733 104.963 81.2932 101.843 81.2932H78.0721C73.8458 81.2932 70.4062 84.7467 70.4062 88.9734V156.427C70.4062 160.653 73.8458 164.107 78.0721 164.107H150.345C153.465 164.107 155.984 166.627 155.984 169.733C155.984 172.84 153.465 175.36 150.345 175.36Z" fill="#100F0D"/>
      <path d="M194.46 112.493V145.387L221.671 126.693L194.46 112.493ZM191.793 159.067C187.047 159.067 183.194 155.214 183.194 150.48V108.067C183.194 103.334 187.047 99.4802 191.78 99.4802C193.153 99.4802 194.527 99.8136 195.753 100.454L230.83 118.774C233.536 120.174 235.243 122.827 235.429 125.867C235.616 128.894 234.229 131.734 231.723 133.454L196.633 157.56C195.193 158.547 193.513 159.067 191.793 159.067Z" fill="#100F0D"/>
      <path d="M29.4239 11.2532C19.4115 11.2532 11.2655 19.3998 11.2655 29.4131V228.253C11.2655 238.253 19.4115 246.4 29.4239 246.4H318.821C328.834 246.4 336.979 238.253 336.979 228.253V29.4131C336.979 19.3998 328.834 11.2532 318.821 11.2532H29.4239ZM318.821 257.667H29.4239C13.1988 257.667 0 244.467 0 228.253V29.4131C0 13.1865 13.1988 -0.000147402 29.4239 -0.000147402H318.821C335.046 -0.000147402 348.245 13.1865 348.245 29.4131V228.253C348.245 244.467 335.046 257.667 318.821 257.667Z" fill="#100F0D"/>
      <path d="M342.606 212.747H5.63954C2.51985 212.747 0 210.213 0 207.107C0 204 2.51985 201.48 5.63954 201.48H342.606C345.725 201.48 348.245 204 348.245 207.107C348.245 210.213 345.725 212.747 342.606 212.747Z" fill="#100F0D"/>
      <path d="M125.827 295.614H223.364L213.418 257.667H135.76L125.827 295.614ZM230.67 306.88H230.657H118.535C116.788 306.88 115.135 306.067 114.068 304.68C113.002 303.307 112.642 301.507 113.082 299.814L125.961 250.614C126.614 248.134 128.854 246.4 131.413 246.4H217.765C220.324 246.4 222.564 248.134 223.218 250.614L235.989 299.36C236.189 299.947 236.309 300.587 236.309 301.24C236.309 304.347 233.79 306.88 230.67 306.88" fill="#100F0D"/>
      <path d="M261.267 306.88H87.9244C84.8181 306.88 82.2852 304.347 82.2852 301.24C82.2852 298.134 84.8181 295.614 87.9244 295.614H261.267C264.374 295.614 266.893 298.134 266.893 301.24C266.893 304.347 264.374 306.88 261.267 306.88Z" fill="#100F0D"/>
    </svg>
  )
}

const AudioAndScreen = () => {
  return (
    <svg width="349" height="313" viewBox="0 0 349 313" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M29.4157 11.2667C19.406 11.2667 11.2642 19.4132 11.2642 29.4266V228.253C11.2642 238.267 19.406 246.413 29.4157 246.413H318.824C328.834 246.413 336.975 238.267 336.975 228.253V29.4266C336.975 19.4132 328.834 11.2667 318.824 11.2667H29.4157ZM318.824 257.68H29.4157C13.196 257.68 0 244.48 0 228.253V29.4266C0 13.1999 13.196 -9.1712e-07 29.4157 -9.1712e-07H318.824C335.044 -9.1712e-07 348.24 13.1999 348.24 29.4266V228.253C348.24 244.48 335.044 257.68 318.824 257.68Z" fill="#100F0D"/>
      <path d="M342.607 54.72H5.63143C2.52108 54.72 0 52.1998 0 49.0931C0 45.9865 2.52108 43.4533 5.63143 43.4533H342.607C345.719 43.4533 348.24 45.9865 348.24 49.0931C348.24 52.1998 345.719 54.72 342.607 54.72Z" fill="#100F0D"/>
      <path d="M313.079 33.4397H293.055C289.944 33.4397 287.423 30.9198 287.423 27.8131C287.423 24.7065 289.944 22.1866 293.055 22.1866H313.079C316.19 22.1866 318.711 24.7065 318.711 27.8131C318.711 30.9198 316.19 33.4397 313.079 33.4397Z" fill="#100F0D"/>
      <path d="M133.756 100.453C133.285 100.453 132.59 100.827 132.59 101.613V199.226C132.59 200.106 133.386 200.4 133.772 200.4C133.99 200.4 134.193 200.32 134.406 200.173L215.145 144.72C215.591 144.413 215.665 143.987 215.646 143.693C215.629 143.4 215.506 142.987 215.026 142.733L134.286 100.587C134.105 100.493 133.932 100.453 133.756 100.453ZM133.772 211.653C126.91 211.653 121.326 206.08 121.326 199.226V101.613C121.326 94.7599 126.902 89.1867 133.756 89.1867C135.734 89.1867 137.721 89.6666 139.499 90.5999L220.238 132.747C224.137 134.787 226.624 138.627 226.889 143.013C227.156 147.4 225.149 151.52 221.523 154L140.783 209.467C138.694 210.893 136.269 211.653 133.772 211.653Z" fill="#100F0D"/>
      <path d="M342.607 294.146H101.061C97.9511 294.146 95.4287 291.627 95.4287 288.507C95.4287 285.4 97.9511 282.88 101.061 282.88H342.607C345.718 282.88 348.239 285.4 348.239 288.507C348.239 291.627 345.718 294.146 342.607 294.146" fill="#100F0D"/>
      <path d="M64.7668 294.146H5.63143C2.52108 294.146 0 291.627 0 288.507C0 285.4 2.52108 282.88 5.63143 282.88H64.7668C67.8772 282.88 70.3996 285.4 70.3996 288.507C70.3996 291.627 67.8772 294.146 64.7668 294.146Z" fill="#100F0D"/>
      <path d="M82.9142 275.986C76.0136 275.986 70.3995 281.613 70.3995 288.506C70.3995 295.413 76.0136 301.026 82.9142 301.026C89.8149 301.026 95.429 295.413 95.429 288.506C95.429 281.613 89.8149 275.986 82.9142 275.986ZM82.9142 312.293C69.8022 312.293 59.1353 301.626 59.1353 288.506C59.1353 275.4 69.8022 264.733 82.9142 264.733C96.0262 264.733 106.693 275.4 106.693 288.506C106.693 301.626 96.0262 312.293 82.9142 312.293Z" fill="#100F0D"/>
    </svg>
  )
}

const ScreenAndCamera = () => {
  return (
    <svg width="349" height="258" viewBox="0 0 349 258" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path d="M29.4157 11.2664C19.4074 11.2664 11.2642 19.413 11.2642 29.4263V228.253C11.2642 238.266 19.4074 246.413 29.4157 246.413H318.825C328.834 246.413 336.975 238.266 336.975 228.253V29.4263C336.975 19.413 328.834 11.2664 318.825 11.2664H29.4157ZM318.825 257.68H29.4157C13.196 257.68 0 244.48 0 228.253V29.4263C0 13.1996 13.196 -0.000244141 29.4157 -0.000244141H318.825C335.044 -0.000244141 348.24 13.1996 348.24 29.4263V228.253C348.24 244.48 335.044 257.68 318.825 257.68Z" fill="#100F0D"/>
      <path d="M342.608 54.7207H5.63277C2.52241 54.7207 0 52.2007 0 49.094C0 45.9873 2.52241 43.454 5.63277 43.454H342.608C345.719 43.454 348.24 45.9873 348.24 49.094C348.24 52.2007 345.719 54.7207 342.608 54.7207Z" fill="#100F0D"/>
      <path d="M313.079 33.4404H293.054C289.944 33.4404 287.423 30.9204 287.423 27.8137C287.423 24.7071 289.944 22.187 293.054 22.187H313.079C316.189 22.187 318.712 24.7071 318.712 27.8137C318.712 30.9204 316.189 33.4404 313.079 33.4404Z" fill="#100F0D"/>
      <path d="M342.608 222.641H101.062C97.9513 222.641 95.4302 220.121 95.4302 217.014C95.4302 213.907 97.9513 211.387 101.062 211.387H342.608C345.718 211.387 348.239 213.907 348.239 217.014C348.239 220.121 345.718 222.641 342.608 222.641Z" fill="#100F0D"/>
      <path d="M64.7682 222.641H5.63277C2.52241 222.641 0 220.121 0 217.014C0 213.907 2.52241 211.387 5.63277 211.387H64.7682C67.8785 211.387 70.3996 213.907 70.3996 217.014C70.3996 220.121 67.8785 222.641 64.7682 222.641Z" fill="#100F0D"/>
      <path d="M82.9147 204.494C76.014 204.494 70.3999 210.107 70.3999 217.014C70.3999 223.921 76.014 229.534 82.9147 229.534C89.8166 229.534 95.4308 223.921 95.4308 217.014C95.4308 210.107 89.8166 204.494 82.9147 204.494ZM82.9147 240.801C69.8027 240.801 59.1357 230.134 59.1357 217.014C59.1357 203.894 69.8027 193.227 82.9147 193.227C96.0267 193.227 106.695 203.894 106.695 217.014C106.695 230.134 96.0267 240.801 82.9147 240.801Z" fill="#100F0D"/>
      <path d="M172.44 84.1867C153.891 84.1867 138.799 99.28 138.799 117.827C138.799 136.373 153.891 151.467 172.44 151.467C190.99 151.467 206.082 136.373 206.082 117.827C206.082 99.28 190.99 84.1867 172.44 84.1867ZM172.44 162.733C147.68 162.733 127.535 142.587 127.535 117.827C127.535 93.0666 147.68 72.92 172.44 72.92C197.201 72.92 217.346 93.0666 217.346 117.827C217.346 142.587 197.201 162.733 172.44 162.733Z" fill="#100F0D"/>
      <path d="M121.297 222.641C118.501 222.641 116.077 220.561 115.717 217.721C115.41 215.294 115.255 212.814 115.255 210.347C115.255 177.881 141.662 151.467 174.12 151.467C206.579 151.467 232.986 177.881 232.986 210.347C232.986 212.241 232.894 214.147 232.712 216.027C232.412 219.121 229.655 221.387 226.564 221.094C223.467 220.787 221.2 218.041 221.5 214.947C221.647 213.427 221.722 211.881 221.722 210.347C221.722 184.094 200.368 162.734 174.12 162.734C147.872 162.734 126.518 184.094 126.518 210.347C126.518 212.334 126.644 214.347 126.892 216.307C127.282 219.387 125.097 222.214 122.012 222.601C121.772 222.627 121.533 222.641 121.297 222.641Z" fill="#100F0D"/>
    </svg>
  )
}

export { AudioOnly, CameraRecording, ScreenRecording, AudioAndScreen, ScreenAndCamera };