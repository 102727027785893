import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { lookUp } from 'services/stringService';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Header, Sidebar } from 'components';
import { NotFound, Preview } from './pages';
import { sidebarWidth } from 'constants/index.js';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import pages from './pages';
import PrivateRoute from './routes/PrivateRoute';
import Footer from 'components/Footer';
import AppVersion from 'components/AppVersion';
import Copyright from 'components/Copyright';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  main: {
    marginBottom: theme.spacing(8),
  },
  mainContainer: props => ({
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',

    // FIXME we should remove the calculation using sidebar open/close, replace sidebar with MUI Drawer
    left: props.isFullWidth ? sidebarWidth.closed : sidebarWidth.open,
    width: `calc(100% - ${props.isFullWidth ? sidebarWidth.closed : sidebarWidth.open}px)`,
  }),
  mainRoutesContainer: {
    // Container by default is centered, remove margin override in order to center the content
    marginLeft: 0,
  },
}));

const App = () => {
  const isFullWidth = useSelector((state) => state.navigation.isSidebarCollapsed);
  const classes = useStyles({ isFullWidth });

  const previewItem = useSelector((state) => state.preview.item);
  const routingBase = useSelector(
    (state) => state.auth.access
  ).find((e) => e.role === 'SysAdmin')?.menu;

  const RouteComponents = useMemo(() => {
    const collectRoutes = (arr, route) => {
      if (!!route.component) arr.push({ component: route.component, path: route.url?.split('?')?.[0] });
    };

    return routingBase
      .reduce((arr, e) => {
        collectRoutes(arr, e);
        if (e.subs) e.subs.forEach((sub) => collectRoutes(arr, sub))
        if (e.menuitems) {
          e.menuitems.forEach((menuItem) => {
            collectRoutes(arr, menuItem);
            if (menuItem.subs) menuItem.subs.forEach((sub) => collectRoutes(arr, sub))
          });
        }
        return arr;
      }, [])
      .map((route, ind) =>
        <Route
          key={`${route.path}_${ind}`}
          path={route.path}
          element={<PrivateRoute component={pages[route.component] || pages.NotFound} />}
        />
    );
  }, [routingBase]);

  return (
    <Box className={classes.root}>
      <Helmet
        titleTemplate={`%s | ${lookUp({
          key: 'CONSOLE_ENT360_DEFAULT_HEADING',
          defaultString: ' ',
        })}`}
        defaultTitle={lookUp({ key: 'CONSOLE_ENT360_DEFAULT_HEADING' })}
      />
      <Sidebar />
      <Box className={classes.mainContainer}>
        <Box component="main" className={classes.main}>
          <Box flex={1}>
            {/* NOTE header should be contained in order to apply correct styling, this way theres not need to compute the sidebar open/close width */}
            <Header />
            {/* NOTE use container in order to add consistency to all pages wrapper */}
            <Container className={classes.mainRoutesContainer} maxWidth={false}>
              <Routes>
                {RouteComponents}
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Container>
          </Box>
        </Box>
        <Footer>
          <AppVersion />
          <Copyright />
        </Footer>
      </Box>
      {!!previewItem && <Preview />}
    </Box>
  );
};

export default App;
