import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles, IconButton, Popover, Link, Grid } from '@material-ui/core';
import { 
  ExpandMore, 
  ExpandLess,
  PlayCircleFilled,
  Extension,
  QueueMusic,
  TextFields,
  Image,
  SwitchVideo,
 } from '@material-ui/icons';
import contentService from 'services/contentService';
import collectionsService from 'services/collectionsServiceV2';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  pop: {
    padding: theme.spacing(2),
    overflow: 'hidden',
  },
  icon: {
    backgroundColor: 'white',
    "&:hover": {
      backgroundColor: 'white'
    }
  }
}));

const SubItems = ({ id }) => {
  const [items, setItems] = useState([]);
  const [itemsData, setItemsData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!open) return;
    setMessage(lookUp({ key: 'CONSOLE_LOADING' }))
    collectionsService
      .getContentRelation(id, 'Playlist')
      .then((subRows) => {
        if (!!subRows.pageContent.length) {
          setItems(subRows.pageContent.map((content) => content.targetId));
        } else {
          setMessage(lookUp({ key: 'CONSOLE_NO_SUBITEMS' }))
        };
      })
      .catch((error) => {
        setMessage(lookUp({ key: 'CONSOLE_LOAD_ERROR' }))
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_REFERENCE_ITEMS' }),
          }),
        });
      });
  }, [open]);

  useEffect(() => {
    if (!items?.length || !open) return;
    Promise.allSettled(items.map(id => contentService.getById(id)))
    .then(res => {
      const newDetails = res
      .filter(e => e.status === 'fulfilled')
      .map(e => e.value)
      .reduce((obj, e) => {
        obj[e.id] = e;
        return obj;
      }, {});
      setItemsData(o => ({...o, ...newDetails}))
      }
    );
  }, [items, open]);

  return (
    <>
      <IconButton className={classes.icon} size="small" onClick={handleClick}>
        {open ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        PaperProps={{className: classes.pop, variant: 'outlined'}}
        onClose={handleClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container direction="column" spacing={2}>
          {!items?.length ? (<>{message}</>) : 
            items.map((e, i) => (
              <Grid key={i} item xs>
                {<IconButton size="small" component="a" href={`content/${e}/editor`}>{editorIcon(itemsData[e]?.type)}</IconButton>}<Link href={`content/${e}/edit`}>{itemsData[e]?.originalTitle || e}</Link>
              </Grid>
            ))
          }
        </Grid>
      </Popover>
    </>
  );
};

export default SubItems;

const editorIcon = (type) => {
  switch (type) {
    case 'Image':
      return <Image />;
    case 'Video':
      return <PlayCircleFilled />;
    case 'Audio':
      return <QueueMusic />;
    case 'Remix':
      return <SwitchVideo />;
    case 'Pdf':
    case 'Text':
    case 'Word':
      return <TextFields />;
    default:
      return <Extension />;
  }
};
