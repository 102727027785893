import React, {useState, useEffect, useRef} from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Listing, CreatedOn } from 'components';
import LogButton from 'components/Audit/LogButton';
import dataGroupService from 'services/dataGroupService';
import PublishedComponent from 'assets/icons/PublishedComponent'
import UnPublishedComponent from 'assets/icons/UnPublishedComponent'

const DataGroups = (props) => {
  

  const create = {
    text: lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp( {key: 'CONSOLE_DATA_GROUP'} )}),
    link: '/apps/data-groups/create'
  };

  const {
    history,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [type, setType] = useState({t: new URLSearchParams(location.search).get('t')} || {t: ''});

  const [dataGroupsTypeOptions, setDataGroupsTypeOptions] = useState([]);

  const isFirstRun = useRef(true);

  const getDataGroupsTypes = async () => {
    setDataGroupsTypeOptions([
      { label: lookUp({ key: 'CONSOLE_PROXY' }) },
      { label: lookUp({ key: 'CONSOLE_BUFFER' }) }
    ]);
  };

  useEffect(() => {
    getDataGroupsTypes();
    isFirstRun.current = false;
  }, []);

  const typeString = JSON.stringify(type);

  useEffect(() => {
    if (isFirstRun.current) return;
    let params = new URLSearchParams(location.search);
    params.set('t', type.t || '');
    navigate({ pathname: location.pathname, search: params.toString()})
  }, [typeString]);

  const typeFilter = {
    label: lookUp({ key: 'CONSOLE_TYPES' }),
    key: 't',
    options: dataGroupsTypeOptions,
    change: (value) => setType({t: value}),
   };

  const columns = [
    {
      field: 'enabled',
      headerName: ' ',
      align: 'left',
      width: 40,
      renderCell: item =>
        item.value ? (
          <PublishedComponent title="Enabled" />
        ) : (
          <UnPublishedComponent title="Disabled" />
        )
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      flex: 2,
      align: 'left',
      renderCell: (item) => (
        <Link
          to={`/apps/data-groups/${item.id}/edit`}
          component={RouterLink}
        >
          {item.value}
        </Link>
      )
    },
    { 
      field: 'type', 
      headerName: lookUp({ key: 'CONSOLE_TYPE' }),
      width: 80,
      align: 'left', 
      renderCell: (item) => item.value }
    ,
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'log',
      headerName: ' ',
      align: 'center',
      width: 40,
      filterable: false,
      sortable: false,
      renderCell: (item) => (
        <LogButton type="DataGroup" id={item.id} />
      )
    },
  ];

  return (
    <Listing
      multiSort
      create={create}
      tableHeadElements={columns}
      loadData={dataGroupService.search}
      deleteItem={dataGroupService.remove}
      dropdownFilters={[typeFilter]}
      additionalFiltersState={type}
      dataGridProps={{ density: 'standard', disableDensitySelector: true }}
    />
  );
}

export default DataGroups;
