import axios from 'axios';
import { 
  getConsoleApiEndpoint,
} from './apiEndpoints';
import moment from 'moment';

const getContentEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Content');
  if (!endpoint) throw Error('No Content endpoint.');
  return endpoint;
};

const getTopTrendsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('ConsumptionTopItems');
  if (!endpoint) throw Error('No Top Trends endpoint.');
  return endpoint;
};

const getConsumptionRankings = () => {
  const endpoint = getConsoleApiEndpoint('ConsumptionRankings');
  if (!endpoint) throw Error('No Consumption Rankings endpoint.');
  return endpoint;
};

const getArtistEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Artist');
  if (!endpoint) throw Error('No Artist endpoint.');
  return endpoint;
}

const topTrendsEndpoint = (date) => axios.get(`${getConsumptionRankings()}/Top/Content/${date}?topBy=TotalPlayCount&count=10`)
  .then(response => response?.data?.items);

const topTrendsYesterdayEndpoint = async (date) => axios.get(`${getConsumptionRankings()}/Top/Content/${date}?topBy=TotalPlayCount&count=10`)
  .then(response => response?.data?.items);

export const getContentName = (id) => axios.get(`${getContentEndpoint()}/${id}`)
  .then(response => response?.data);

const lastThirtyDays = [...new Array(30)].map((i, idx) => moment()
  .startOf('day')
  .subtract(idx, 'days')
  .format('YYYY-MM-DD')
);

const allDays = () => {
  const list = []
  const promises = lastThirtyDays.map(item => 
     axios.get(`${getTopTrendsEndpoint()}/Top/Content/${item}?topBy=TotalPlayCount&count=10`)
      .then(response => response?.data)
  );

  const result = Promise.all(promises)
    .then(results => {
      results.map(result => result.items);
      const days = results.map(result => result.items);
      return list.concat(days)
    });

  return result
};

const topTrendsArtistEndpoint = (date) => axios.get(`${getConsumptionRankings()}/Top/Artist/${date}?topBy=TotalPlayCount&count=10`)
  .then(response => response?.data?.items);

const topTrendsYesterdayArtistEndpoint = (date) => axios.get(`${getConsumptionRankings()}/Top/Artist/${date}?topBy=TotalPlayCount&count=10`)
  .then((response) => response?.data?.items);

export const getArtistName = (id) => axios.get(`${getArtistEndpoint()}/${id}`)
   .then((response) => response?.data);

export const getTopCountry = (date) => axios.get(`${getConsumptionRankings()}/Top/Country/${date}?topBy=TotalPlayCount&count=10`)
  .then((response) => response?.data?.items?.map((res) => ({ id: res.item, value: res.value })));

export const getPreviousTopCountry = (date) => axios.get(`${getConsumptionRankings()}/Top/Country/${date}?topBy=TotalPlayCount&count=10`)
  .then((response) => response?.data?.items?.map((res) => ({ id: res.item, value: res.value })))

export { 
  topTrendsEndpoint,
  topTrendsYesterdayEndpoint,
  topTrendsArtistEndpoint,
  topTrendsYesterdayArtistEndpoint,
  allDays
};
