import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles, Tooltip, TextField, Chip } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import noop from 'lodash/noop';
import {
  getContentRelation,
  addContentRelation,
  deleteContentRelation,
  getCustomerRelation,
  addCustomerRelation,
  deleteCustomerRelation,
  addCatalogRelation,
  deleteCatalogRelation,
  getCatalogRelation,
} from 'services/collectionsServiceV2';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import AlertService from 'services/alertService';
import { lookUp } from 'services/stringService';


const useStyles = makeStyles(theme => ({
  chipIcon: {
    '&:hover': {
      color: theme.palette?.primary?.dark,
    },
  },
  errorTooltip: props => {
    const error = props.isErrHlOn && !!props.formError;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
}));

const preselectType = {
  Projects: 'Project',
};

const Related = ({
  id,
  label,
  sourceType,
  targetType,
  // FIXME change ALL instances of the consumers and pass in onCLick cb instead of onFocus, should use on Click, otherwise the actions will trigger onFocus which geenrates issues when clicking outside
  onFocus,
  relation,
  source,
  overrideHandleChange,
  onChange,
  enqueueSnackbar,
  trigger,
  lookup=false,
  size='medium',
  formErrorKey=null,
  formErrors={},
  setFormErrors=noop,
  required=false,
}) => {
  const [items, setItems] = useState([]);
  const [labels, setLabels] = useState({});

  const isErrHighlight = useSelector((state) => state.parameters.isErrHighlight);

  const classes = useStyles({
    formError: formErrors[formErrorKey],
    isErrHlOn: isErrHighlight.on
  });

  const navigate = useNavigate();

  const relationService = {
    Content: {
      add: addContentRelation,
      get: getContentRelation,
      delete: deleteContentRelation,
    },
    Customer: {
      add: addCustomerRelation,
      get: getCustomerRelation,
      delete: deleteCustomerRelation,
    },
    Notification: {
      add: addCustomerRelation,
      get: getCustomerRelation,
      delete: deleteCustomerRelation,
    },
    Moderator: {
      add: addCustomerRelation,
      get: getCustomerRelation,
      delete: deleteCustomerRelation,
    },
    Catalog: {
      add: addCatalogRelation,
      get: getCatalogRelation,
      delete: deleteCatalogRelation
    }
  };

  const labelService = {
    Content: {
      get: contentService.getById,
      label: 'originalTitle'
    },
    Customer: {
      get: customersService.getCustomer,
      label: 'username',
    },
    Notification: {
      get: customersService.getCustomer,
      label: 'username',
    },
    Moderator: {
      get: customersService.getCustomer,
      label: 'username',
    },
    Catalog: {
      get: customersService.getCustomer,
      label: "username"
    }
  };

  const getItems = async () => {
    if (!!source) return setItems(source);
    const params = [id, preselectType[relation] || relation];
    if (lookup) params.push("Lookup");
    const items =
      (await relationService[sourceType]?.get(...params))?.pageContent?.map((e) =>
        lookup ? e.sourceId : e.targetId,
      ) || [];
    setItems(items);
    onChange?.(items);
  };

  const handleChange = overrideHandleChange || (async (value) => {
    try {
      if (!items || value.length > items?.length) {
        await lookup
          ? relationService[sourceType]?.add(value.slice(-1).pop(), preselectType[relation] || relation, id)
          : relationService[sourceType]?.add(id, preselectType[relation] || relation, value.slice(-1).pop());
      } else {
        const deletedIds = items?.filter((id) => !value.includes(id));
        deletedIds.forEach((deletedId) => lookup
          ? relationService[sourceType]?.delete(deletedId, preselectType[relation] || relation, id)
          : relationService[sourceType]?.delete(id, preselectType[relation] || relation, deletedId));
      }
      setItems(value);
      onChange?.(value);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp(`CONSOLE_${relation}CONTENT_ERROR`),
      });
    }
  });

  const getLabel = (e) => labelService[targetType]?.get(e).then(res => res[labelService[targetType]?.label]);

  const editUrl = (id) => {
    switch (targetType) {
      case 'Content':
        return `/content/${id}/edit`;
      case 'Customer':
      default:
        return `/access/customer/${id}/edit`;
    }
  };

  useEffect(() => {
    getItems();
  }, [id, trigger, source]);

  useEffect(() => {
    const getLabels = () => {
      const labeled = { ...labels };
      items?.forEach(async (e) => {
        if (labeled[e]) return;
        const title = await getLabel(e);
        labeled[e] = title;
        navigate(location.pathname + location.search);
      });
      setLabels(labeled);
    };
    getLabels();
  }, [items]);

  useEffect(() => {
    if (formErrors[formErrorKey] && items.length) {
      setFormErrors((formErrors) => ({ ...formErrors, [formErrorKey]: null }));
    }
  }, [formErrors[formErrorKey], items.length]);

  return (
    <Autocomplete
      multiple
      freeSolo
      filterSelectedOptions
      options={[]}
      value={items}
      size={size}
      onChange={(event, value) => handleChange(value)}
      renderInput={(params) => (
        <Tooltip title={formErrors[formErrorKey]} classes={{ tooltip: classes.errorTooltip }}>
          <TextField
            {...params}
            // FIXME change ALL instances of the consumers and pass in onCLick cb instead of onFocus, should use on Click, otherwise the actions will trigger onFocus which geenrates issues when clicking outside
            onClick={() => onFocus?.()}
            size={size}
            label={label !== '' && lookUp({ key: `CONSOLE_${relation}` })}
            error={!!formErrors[formErrorKey]}
            required={required}
          />
        </Tooltip>
      )}
      renderTags={(options, getTagProps) => options.map((option, index) => {
        return (
          <Chip
            key={option}
            size={size}
            label={labels[option]}
            className={getTagProps(index)?.className}
            onDelete={(event) => {
              handleChange([...items].filter(e => e !== option))
            }}
            icon={(
              <span onClick={e => e.stopPropagation()}>
                <a href={editUrl(option)} target="_blank">
                  <OpenInNewIcon className={classes.chipIcon} fontSize={size} />
                </a>
              </span>
            )}
          />
        )}
      )}
    />
  )
};

Related.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  sourceType: PropTypes.string.isRequired,
  targetType: PropTypes.string.isRequired,
  // FIXME change ALL instances of the consumers and pass in onCLick cb instead of onFocus, should use on Click, otherwise the actions will trigger onFocus which geenrates issues when clicking outside
  onFocus: PropTypes.func,
  relation: PropTypes.string,
  lookup: PropTypes.bool,
  source: PropTypes.string,
  overrideHandleChange: PropTypes.func,
  onChange: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  trigger: PropTypes.any,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default withSnackbar(Related);
