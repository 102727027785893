import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.grey[100],
  },
}));

const Footer = ({ children }) => {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.root}>
      {children}
    </Box>
  );
};

Footer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
};

export default Footer;
