import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getContentQueryEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('ContentQuery');
  if (!endpoint) throw Error('No Content Query endpoint.');
  return endpoint;
};

const getCatalogEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Catalog');
  if (!endpoint) throw Error('No Catalog endpoint.');
  return endpoint;
};

const get = async (model, datagroupId) => {
  model.actions.map(action => {
    if (action?.schedule?.type === 'None') {
      return action.schedule = null
    } return
  })
  try {
    const result = await axios.post(`${getCatalogEndpoint()}/ToQuery`, model)
    const data = await axios.post(`${getContentQueryEndpoint()}/en-us/--/${datagroupId}`, result.data)
    return data.data.ChildItems;
  } catch (error) {
    throw error;
  }
}

export { get };
export default { get };