import { useEffect, useState, useRef } from 'react';
import { lookUp } from 'services/stringService';
import ShakaPlayer from 'shaka-player-react';
import {
  Grid,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  Slider,
  MenuItem,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import TimePicker from 'components/TimePicker';
import { Clear, VolumeDown, VolumeUp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import 'shaka-player/dist/controls.css';
import { getMs, msToTime } from 'helpers/time';
import contentService from 'services/contentService';
import ThemedButton from 'components/ThemedButton';

const shakaStyles = makeStyles({
  shakaVideo: (props) => ({
    width: '100%',
    height: '100%',
    zIndex: 1,
    marginTop: 'auto',
    paddingTop: 0,
    '& .shaka-ad-markers': {
      background: props.markers,
    },
  }),
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 4,
    minHeight: '29em',
    backgroundColor: theme.palette.background.paper,
    '& .MuiTab-root': {
      minWidth: 'initial',
    },
  },
  tabBar: {
    position: 'absolute',
    top: '3.3em',
    zIndex: 999,
  },
  tabBox: {
    marginTop: '1em',
    height: '25em',
    padding: 0,
  },
  actions: {
    padding: '1em',
    backgroundColor: 'rgba(0,0,0,0)'
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabBox} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `clip-editor-tab-${index}`,
    'aria-controls': `clip-editor-tabpanel-${index}`,
  };
}

function ClipEditor({
  selectedClip,
  assetDuration,
  setEditorOpen,
  behaviours,
  audioSettings,
  track,
  saveByButton,
}) {

  if (!selectedClip) {
    setEditorOpen(false);
    return null;
  }

  const [copyClip, setCopyClip] = useState({
    ...selectedClip,
    cueIn: msToTime(selectedClip.cueIn || 0),
    cueOut: msToTime(selectedClip.cueOut || 0),
    duration: msToTime(selectedClip.duration || 0),
    start: msToTime(selectedClip.start || 0),
    end: msToTime(selectedClip.start + selectedClip.duration),
  });

  const theme = useTheme();
  const markerColor = theme.palette.secondary?.light || '#ff0000';

  const { videoUrl } = track;
  const [authVidUrl, setAuthVidUrl] = useState('');
  const vidRef = useRef(null);
  const [loop, setLoop] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [tabNumber, setTabNumber] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabNumber(newValue);
  };

  const changeCopyClip = (prop, value) => {
    const changed = { ...copyClip };
    changed[prop] = value;
    if (prop === 'start' || prop === 'end')
      changed.duration = msToTime(getMs(changed.end) - getMs(changed.start));
    if (prop === 'duration') changed.end = msToTime(getMs(changed.start) + getMs(changed.duration));
    setCopyClip(changed);
  };

  useEffect(() => {
    if (
      loop &&
      isPlaying &&
      (currentTime < getMs(copyClip.cueIn) || currentTime > getMs(copyClip.cueOut)) &&
      vidRef.current?.videoElement
    ) {
      vidRef.current.videoElement.currentTime = getMs(copyClip.cueIn) / 1000;
    }
  }, [currentTime, loop]);

  useEffect(() => {
    if (vidRef.current?.videoElement) {
      vidRef.current.videoElement.volume = copyClip.volumeLevel / 100;
    }
  }, [copyClip.volumeLevel]);

  useEffect(() => {
    const setAuth = () => {
      contentService.getPlaybackUrl(videoUrl).then(u => setAuthVidUrl(u));
    }
    setAuth();
  }, []);

  const makePercentGradient = () => {
    if (!assetDuration) return '';
    const transparent = ' rgba(0,0,0,0) ';
    const color = ` ${markerColor} `;
    let gradientString =
      'linear-gradient(90deg, rgba(0,0,0,0) 0%,' +
      transparent +
      ((getMs(copyClip.cueIn) / assetDuration) * 100).toFixed(1) +
      '%,' +
      color +
      ((getMs(copyClip.cueIn) / assetDuration) * 100).toFixed(1) +
      '%,' +
      color +
      ((getMs(copyClip.cueOut) / assetDuration) * 100).toFixed(1) +
      '%,' +
      transparent +
      ((getMs(copyClip.cueOut) / assetDuration) * 100).toFixed(1) +
      '%,' +
      'rgba(0,0,0,0) 100%) !important';
    if (gradientString === 'linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 100%) !important')
      return;
    return gradientString;
  };

  const shakaClasses = shakaStyles({ markers: makePercentGradient() });
  const classes = useStyles();

  return (
    <>
      <DialogTitle style={{ zIndex: 1, height: '6em' }}>
        <Grid container justifyContent="space-between">
          <Typography style={{ fontSize: 'large' }}>
            {lookUp({ key: 'CONSOLE_EDIT_CLIP_TITLE' })}
          </Typography>
          <IconButton
            onClick={() => {
              setEditorOpen(false);
            }}
            size="small"
          >
            <Clear />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          justifyItems="flex-start"
          justifyContent="flex-start"
          style={{ paddingBottom: '1em' }}
        >
          <Grid item xs={9} style={{ display: 'block', position: 'relative' }}>
            <ShakaPlayer
              src={authVidUrl}
              ref={vidRef}
              onTimeUpdate={(event) => setCurrentTime(event.currentTarget.currentTime * 1000)}
              className={shakaClasses.shakaVideo}
            />
            <div style={{ zIndex: 2, position: 'absolute', top: 20, left: 'calc(50% - 145px)' }}>
              <ThemedButton
                style={{ width: 140, marginRight: 10 }}
                onClick={(e) => {
                  e.stopPropagation();
                  changeCopyClip('cueIn', msToTime(currentTime));
                }}
                color="secondary"
              >
                {lookUp({ key: 'CONSOLE_CUE_IN_BUTTON' })}
              </ThemedButton>
              <ThemedButton
                style={{ width: 140 }}
                onClick={(e) => {
                  e.stopPropagation();
                  changeCopyClip('cueOut', msToTime(currentTime));
                }}
                color="secondary"
              >
                {lookUp({ key: 'CONSOLE_CUE_OUT_BUTTON' })}
              </ThemedButton>
            </div>
          </Grid>
          <Grid item xs={3} container spacing={1}>
            <div className={classes.root}>
              <Tabs
                value={tabNumber}
                className={classes.tabBar}
                variant="scrollable"
                onChange={handleTabChange}
                aria-label="clip editor tabs"
              >
                <Tab wrapped label={lookUp({ key: 'CONSOLE_CLIP' })} {...a11yProps(0)} />
                <Tab wrapped label={lookUp({ key: 'CONSOLE_BEHAVIOR' })} {...a11yProps(1)} />
                <Tab wrapped label={lookUp({ key: 'CONSOLE_MISC' })} {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={tabNumber} index={0}>
                <Grid item xs={12} container style={{marginTop: '-.275em'}}>
                  <Grid item xs={6}>
                    <TimePicker
                      value={copyClip.cueIn}
                      label={lookUp({ key: 'CONSOLE_CUEIN' })}
                      onChange={(value) => changeCopyClip('cueIn', value)}
                    />
                    <TimePicker
                      value={copyClip.cueOut}
                      label={lookUp({ key: 'CONSOLE_CUEOUT' })}
                      onChange={(value) => changeCopyClip('cueOut', value)}
                    />
                  </Grid>
                  <Grid item xs={6} container justifyContent="center" alignContent="center">
                    <ThemedButton
                      onClick={() => {
                        setLoop((o) => !o);
                        vidRef.current.videoElement.play();
                        setIsPlaying(true);
                      }}
                      onDoubleClick={() => {
                        vidRef.current.videoElement.currentTime = getMs(copyClip.cueIn) / 1000;
                      }}
                      color={loop ? 'secondary' : 'primary'}
                    >
                      {lookUp({ key: 'CONSOLE_LOOP_BUTTON' })}
                    </ThemedButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="button" style={{ fontWeight: 900, paddingBottom: 15 }}>
                      {lookUp({ key: 'CONSOLE_Position' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TimePicker
                      value={copyClip.start}
                      label={lookUp({ key: 'CONSOLE_START' })}
                      onChange={(value) => changeCopyClip('start', value)}
                    />
                    <TimePicker
                      value={copyClip.end}
                      label={lookUp({ key: 'CONSOLE_END' })}
                      onChange={(value) => changeCopyClip('end', value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="button" style={{ fontWeight: 900 }}>
                      {lookUp({ key: 'CONSOLE_Duration' })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TimePicker
                      value={copyClip.duration}
                      label={lookUp({ key: 'CONSOLE_DURATION' })}
                      onChange={(value) => changeCopyClip('duration', value)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabNumber} index={1}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      select
                      value={copyClip.behavior || ''}
                      label={lookUp({ key: 'CONSOLE_VIDEO_BEHAVIOR' })}
                      fullWidth
                      style={{ marginBottom: 10 }}
                      onChange={(e) => changeCopyClip('behavior', e.target.value)}
                    >
                      {Object.entries(behaviours)
                        .sort((a, b) => a[1] - b[1])
                        .map((e, idx) => (
                          <MenuItem key={e[0]} value={e[1]}>
                            {lookUp({ key: e[0] })}
                          </MenuItem>
                        ))}
                    </TextField>                  
                  </Grid>
                  <Grid item xs={12}>

                  <TextField
                    select
                    value={copyClip.audio || ''}
                    label={lookUp({ key: 'CONSOLE_AUDIO' })}
                    fullWidth
                    style={{ marginBottom: 10 }}
                    onChange={(e) => {
                      if (
                        (e.target.value === 2 || e.target.value === 3) &&
                        vidRef.current?.videoElement
                        ) {
                          vidRef.current.videoElement.muted = true;
                        }
                        if (e.target.value === 1 && vidRef.current?.videoElement) {
                          vidRef.current.videoElement.muted === false;
                          vidRef.current.videoElement.volume === copyClip.volumeLevel / 100;
                        }
                        changeCopyClip('audio', e.target.value);
                      }}
                      >
                    {Object.entries(audioSettings)
                      .sort((a, b) => a[1] - b[1])
                      .map((e, idx) => (
                        <MenuItem key={e[0]} value={e[1]}>
                          {lookUp({ key: e[0] })}
                        </MenuItem>
                      ))}
                  </TextField>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Typography
                    fullWidth
                    variant="button"
                    style={{ fontWeight: 900, marginBottom: 20 }}
                  >
                    {lookUp({ key: 'CONSOLE_VOLUME' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={2}>
                    <VolumeDown />
                  </Grid>
                  <Grid item xs={8}>
                    <Slider
                      value={copyClip.volumeLevel || ''}
                      size="small"
                      fullWidth
                      disabled={copyClip.audio === 2 ? true : false}
                      onChange={(e, value) => {
                        if (vidRef.current?.videoElement)
                          vidRef.current.videoElement.volume = value / 100;
                        changeCopyClip('volumeLevel', value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <VolumeUp />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    fullWidth
                    variant="button"
                    style={{ fontWeight: 900, marginBottom: 20 }}
                  >
                    {lookUp({ key: 'CONSOLE_MAIN_CLIP' })}
                  </Typography>
                  <Switch
                    checked={copyClip.main}
                    variant="outlined"
                    size="small"
                    onChange={(e) => changeCopyClip('main', !copyClip.main)}
                  />
                </Grid>
              </TabPanel>
              <TabPanel value={tabNumber} index={2}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      value={copyClip.title || ''}
                      label={lookUp({ key: 'CONSOLE_TITLE' })}
                      fullWidth
                      onChange={(e) => changeCopyClip('title', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value={copyClip.description || ''}
                      label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
                      fullWidth
                      size="medium"
                      multiline
                      rows={6}
                      onChange={(e) => changeCopyClip('description', e.target.value)}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <ThemedButton
          className={classes.saveButton}
          onClick={() => {
            const copied = { ...copyClip };
            ['cueIn', 'cueOut', 'start', 'duration'].forEach((e) => {
              copied[e] = Math.floor(getMs(copied[e]));
            });
            saveByButton(copied);
            setEditorOpen(false);
          }}
          color="primary"
        >
          {lookUp({ key: 'CONSOLE_SAVE_BUTTON' })}
        </ThemedButton>
      </DialogActions>
    </>
  );
}

export default ClipEditor;
