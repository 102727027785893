import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { string, shape, func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Grid, makeStyles, TextField, MenuItem } from '@material-ui/core';
import { EditorInfo, FormFooter, Loader, Tabbing } from 'components';
import { goBackOrStay } from 'helpers/common';
import DecorationForm from '../components/DecorationForm';
import PropsForm from '../components/PropsForm';
import RulesForm from '../components/RulesForm';
import libraryEnumsService from 'services/libraryEnumsService';
import dataGroupService from 'services/dataGroupService';
import catalogService from 'services/catalogService';
import parametersService from 'services/parametersService';
import {
  setCatalogItems,
  setParametersItems,
  setDataGroupTypes,
} from 'store/actions/libraryEnumsAction';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
}));

function DataGroupsForm(props) {
  const { enqueueSnackbar } = props;

  const classes = useStyles();
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.datagroup;
  const dataGroupTypes = useSelector((state) => state.libraryEnums.dataGroupTypes);
  const catalogItems = useSelector((state) => state.libraryEnums.catalogItems);
  const parametersItems = useSelector((state) => state.libraryEnums.parametersItems);

  const [model, setModel] = useState({
    name: '',
    productId: window.__RUNTIME_CONFIG__.BRAND_ID,
    productCode: '',
    type: 'Buffer',
    properties: [],
    filteringRules: [],
    enabled: false,
    itemDecorations: [
      {
        index: 0,
        displayRules: [],
        properties: [],
        actions: [],
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [dataGroupTypeOptions, setDataGroupTypeOptions] = useState([]);
  const [isDataGroupTypeOptions, setIsDataGroupTypeOptionsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const tabs = [
    {
      name: 'basic',
      content: (
        <Grid container alignItems={'center'} spacing={2} justifyContent={'center'}>
          {model.id && (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <TextField
                  id="data-group-id"
                  label={lookUp({ key: 'CONSOLE_ID' })}
                  fullWidth
                  value={model.id}
                  disabled
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                id="data-group-name"
                required
                label={lookUp({ key: 'CONSOLE_NAME' })}
                fullWidth
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {isDataGroupTypeOptions ? (
                <Loader inline />
              ) : (
                <TextField
                  select
                  disabled={!dataGroupTypeOptions?.length}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  id="data-group-type"
                  label={lookUp({ key: 'CONSOLE_TYPE' })}
                  placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                  value={model.type || null}
                  onChange={({ target }) => handleModelChange('type', target.value)}
                >
                  {dataGroupTypeOptions.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'properties',
      content: (
        <PropsForm
          properties={model.properties}
          handleChange={(data) => handleModelChange('properties', data)}
        />
      ),
    },
    {
      name: 'filtering',
      content: (
        <RulesForm
          rules={model.filteringRules}
          model={model}
          handleChange={(data) => handleModelChange('filteringRules', data)}
        />
      ),
    },
    {
      name: 'decorations',
      content: (
        <DecorationForm
          itemDecorations={model.itemDecorations}
          handleChange={(data) => handleModelChange('itemDecorations', data)}
        />
      ),
    },
  ];

  useEffect(() => {
    parametersItems.length === 0 && getParameters().then(() => false);
    catalogItems.length === 0 && getCatalogs().then(() => false);
    getDataGroupTypeOptions().then(() => setIsDataGroupTypeOptionsLoading(false));

    if (id) {
      getData().then(() => setIsLoading(false));
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_DATA_GROUP` }) })
        )
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await dataGroupService.get(id);
      dispatch(
        navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name }))
      );
      const newModel = { ...model, ...data };
      setModel(newModel);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: `Failed load data: `,
      });
      setIsLoading(false);
    }
  };

  const getDataGroupTypeOptions = async () => {
    setIsDataGroupTypeOptionsLoading(true);

    try {
      const options =
        dataGroupTypes.length === 0
          ? await libraryEnumsService.getDataGroupTypes()
          : dataGroupTypes;

      dataGroupTypes.length === 0 && dispatch(setDataGroupTypes(options));
      setDataGroupTypeOptions(options.map((o) => ({ label: o, value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_DATA_GROUPS' }),
        }),
      });
      setIsDataGroupTypeOptionsLoading(false);
    }
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {
      const saveModel = JSON.parse(JSON.stringify(model));
      if (publish) {
        saveModel.enabled = !model.enabled;
      }
      saveModel.properties && saveModel.properties.length === 0 && delete saveModel.properties;
      saveModel.filteringRules &&
        saveModel.filteringRules.length === 0 &&
        delete saveModel.filteringRules;
      saveModel.itemDecorations &&
        saveModel.itemDecorations.properties &&
        saveModel.itemDecorations.properties.length === 0 &&
        delete saveModel.itemDecorations.properties;
      saveModel.itemDecorations &&
        saveModel.itemDecorations.actions &&
        saveModel.itemDecorations.actions.length === 0 &&
        delete saveModel.itemDecorations.actions;
      saveModel.itemDecorations &&
        saveModel.itemDecorations.actions &&
        saveModel.itemDecorations.actions.forEach((action, index) => {
          if (action.schedule && action.schedule.type === '') {
            delete saveModel.itemDecorations.actions[index].schedule;
          }
        });

      id ? await dataGroupService.edit(saveModel) : await dataGroupService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      const toWhere = window.history.state.idx === 0 ? '/apps/data-groups' : -1;
      goBackOrStay(navigate, toWhere);
    } catch (error) {
      publish ? setIsPublishing(false) : setIsSaving(false);
      AlertService.displayError({ msgBar: enqueueSnackbar, error });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const getCatalogs = async () => {
    try {
      const int32MaxValue = 2147483647;
      const { data } = await catalogService.search({ page: 0, perPage: int32MaxValue });
      dispatch(setCatalogItems([...new Set(data.map((d) => d.name))]));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CATALOGS' }),
        }),
      });
    }
  };

  const getParameters = async () => {
    try {
      const int32MaxValue = 2147483647;
      const { data } = await parametersService.search({ page: 0, perPage: int32MaxValue });
      let par = [];
      data.map((p) => p.key.toLowerCase().split(':')).map((p) => par.push(...p));
      dispatch(setParametersItems([...new Set(par)]));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_PARAMETERS' }),
        }),
      });
    }
  };

  const deleteDataGroup = async () => {
    setIsDeleting(true);
    try {
      await dataGroupService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/apps/data-groups'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <div className={classes.fullWidth}>
      <Helmet
        title={
          id
            ? lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: model.name })
            : lookUp({
                key: 'CONSOLE_CREATE_TEMPLATE',
                type: lookUp({ key: `CONSOLE_${'Data Group'}` }),
              })
        }
      />
      <Tabbing
        tabs={tabs
          .sort(
            (a, b) =>
              allowedTabs.indexOf(a.name?.replace(' ', '-')) -
              allowedTabs.indexOf(b.name?.replace(' ', '-'))
          )
          .filter((e) => allowedTabs.includes(e.name))}
      />
      <FormFooter
        isSaving={isSaving}
        isPublishing={model.enabled}
        existPublishing={true}
        isDeleting={isDeleting}
        deleteData={deleteDataGroup}
        onSave={() => saveData(false)}
        onCancel={() => navigate(window.history.state.idx === 0 ? '../' : -1)}
        onPublish={() => saveData(true)}
        saveButtonText={
          id ? lookUp({ key: 'CONSOLE_SAVE_BUTTON' }) : lookUp({ key: 'CONSOLE_CREATE_BUTTON' })
        }
        publishButtonText={
          model.enabled
            ? lookUp({ key: 'CONSOLE_UNPUBLISH_BUTTON' })
            : lookUp({ key: 'CONSOLE_PUBLISH_BUTTON' })
        }
        isNewItem={!id}
      />
      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </div>
  );
}

DataGroupsForm.propTypes = {
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(DataGroupsForm);
