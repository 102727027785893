import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, func, array } from 'prop-types';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
  Grid, 
  Typography, 
  Button, 
  IconButton, 
  makeStyles, 
  Divider, 
  MenuList,
  Popper,
  TextField,
  MenuItem,
  ClickAwayListener,
  ButtonGroup,
  Grow,
  Paper, } from '@material-ui/core';
  import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Add, Delete } from '@material-ui/icons';
import { Loader, PropsSelect } from 'components';
import libraryEnumsService from 'services/libraryEnumsService';
import { setSortDirections, setValueFormats } from 'store/actions/libraryEnumsAction';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginX: {
    marginTop: '4px',
  },
  addButton: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    position: 'relative', 
    zIndex: 1
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0 0 0',
  },
  divider: {
    width: '70%',
  },
  dividerText: {
    fontStyle: 'italic',
  }
}));

const OrderRules = (props) => {
  
  const classes = useStyles();
  const { handleChange, orderRules, enqueueSnackbar, setSortDirections, sortDirections, setValueFormats } = props;

  const [items, setItems] = useState(orderRules);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [directionOptions, setDirectionOptions] = useState([]);
  const [isDirectionOptionsLoading, setIsDirectionOptionsLoading] = useState(false);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const newOptions = ['Ordering', 'Trending now', 'Coming soon', 'Most Watched']


  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const toOptions = (items) => items.map((i) => ({ label: lookUp({ key: `CONSOLE_${i}` }), value: i }));

  const handleItemsChange = (index, key, value) =>
  {
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));
  }

  const handleItemsRemove = (index) => {
    setItems((prev) => prev.filter((p, i) => {
     return i !== index
    })
    )
  }

    const handleItemsAdd = () => {
      switch (selectedIndex) {
        case (0):
  
          return (
            setItems((prev) => [
              ...prev,
              {
                index: items.length + 1,
                propertyName: '',
                direction: directionOptions.length ? directionOptions[1].value : null,
                valueList: []
              }
            ])
          )
        case (1):
          return (
            setItems((prev) => [
              ...prev,
              {
                index: items.length + 1,
                propertyName: 'Content:Popularity',
                direction: directionOptions.length ? 'Descending' : null,
                valueFormat: 'Text'
              },
              {
                index: items.length + 1,
                propertyName: 'Counter:Hit:Day',
                direction: directionOptions.length ? 'Ascending' : null,
                valueFormat: 'Text'
              },
              {
                index: items.length + 1,
                propertyName: 'Content:Plays:Count',
                direction: directionOptions.length ? 'Descending' : null,
                valueFormat: 'Text'
              },
              {
                index: items.length + 1,
                propertyName: 'Content:PublishedDate',
                direction: directionOptions.length ? 'Descending' : null,
                valueFormat: 'Text'
              },
            ])
          )
          case (2):
            return (
              setItems((prev) => [
                ...prev,
                {
                  index: items.length + 1,
                  propertyName: 'Content:Available:From',
                  direction: directionOptions.length ? 'Ascending' : null,
                  valueFormat: 'Text'
                },
                {
                  index: items.length + 1,
                  propertyName: 'Content:PublishedDate',
                  direction: directionOptions.length ? 'Descending' : null,
                  valueFormat: 'Text'
                },
                {
                  index: items.length + 1,
                  propertyName: 'Content:CreatedDate',
                  direction: directionOptions.length ? 'Ascending' : null,
                  valueFormat: 'Text'
                },
              ])
            )
          case (3):
            return (
              setItems((prev) => [
                ...prev,
                {
                  index: items.length + 1,
                  propertyName: 'Counter:Hit:10Days',
                  direction: directionOptions.length ? 'Descending' : null,
                  valueFormat: 'Text'
                },
                {
                  index: items.length + 1,
                  propertyName: 'Counter:Hit:Day',
                  direction: directionOptions.length ? 'Descending' : null,
                  valueFormat: 'Text'
                },
                {
                  index: items.length + 1,
                  propertyName: 'Content:Plays:Count',
                  direction: directionOptions.length ? 'Descending' : null,
                  valueFormat: 'Text'
                },
                {
                  index: items.length + 1,
                  propertyName: 'Content:PublishedDate',
                  direction: directionOptions.length ? 'Descending' : null,
                  valueFormat: 'Text'
                },
              ])
            )
        default:
          return '';
      }
    }
    
  const getDirections = async () => {
    setIsDirectionOptionsLoading(true);
    try {
      const options =
        sortDirections.length === 0
          ? await libraryEnumsService.getSortDirections()
          : sortDirections;

      sortDirections.length === 0 && setSortDirections(options);
      setDirectionOptions([...options].map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({key: 'CONSOLE_DIRECTION'}) })
      });
      setIsDirectionOptionsLoading(false);
    }
  };

  useEffect(() => {
    const getFormattingOptions = async () => {
      try {
        const options = await libraryEnumsService.getValueFormats();
        setValueFormats(options);
        setFormattingOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE', type: lookUp({key: 'CONSOLE_FORMAT'}) })
        });
      }
    }
    getFormattingOptions()
  }, [])

  useEffect(() => {
    getDirections().then(() => setIsDirectionOptionsLoading(false));
  }, []);

  useEffect(() => {
    handleChange(items);
  }, [items]);

  return (
    <>
      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX}>
          {items.map((item, index) => (
            <Grid item xs={12} key={`${item.name}-${index}`}>
              <Typography gutterBottom>{lookUp({ key: 'CONSOLE_RULE_TEMPLATE', number: index+1})}</Typography>
              <Grid container alignItems={'center'}>
                <Grid item xs={11}>
                  <Grid container alignItems={'center'} spacing={1}>
                    <Grid item xs={12} sm={4} md={4}>
                      <PropsSelect
                        label={lookUp({ key: 'CONSOLE_PROPERTY_NAME' })}
                        value={item.propertyName}
                        handleChange={(value) => handleItemsChange(index, 'propertyName', value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      {isDirectionOptionsLoading ? (
                        <Loader inline />
                      ) : (
                        <TextField
                          select
                          fullWidth
                          id={`${item.direction}-${index}`}
                          label={lookUp({ key: 'CONSOLE_DIRECTIONS' })}
                          placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                          value={directionOptions.find((o) => o.value === item.direction)?.value || null}
                          onChange={({ target }) => handleItemsChange(index, 'direction', target.value)}
                        >
                          {directionOptions.map((e, i) => (
                            <MenuItem key={i} value={e.value}>
                              {e.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <TextField
                        select
                        fullWidth
                        id={`formatting-${index}`}
                        label={lookUp({ key: 'CONSOLE_FORMATTING' })}
                        InputLabelProps={{ shrink: 'true' }}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                        value={formattingOptions.find((o) => o.value === item.valueFormat)?.value || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'valueFormat', target.value)
                        }
                        >
                        {formattingOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.textCenter}>
                  <IconButton onClick={() => handleItemsRemove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
              {index !== items.length-1 && (
              <div className={classes.dividerWrapper}>
                <Divider variant="middle" className={classes.divider}/>
                  <Typography
                    color="textPrimary"
                    display="block"
                    variant="caption"
                    className={classes.dividerText}
                  >
                    {lookUp({ key: 'CONSOLE_AND' })}
                  </Typography>
              </div>
                      )}
            </Grid>
          ))}
        </Grid>
      )}

      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.addButton}>
        <ButtonGroup  color="primary" ref={anchorRef} aria-label="split button">
          <Button color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
            {lookUp({ key: `CONSOLE_${newOptions[selectedIndex]}_BUTTON` })}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement = 'right', position: 'absolute'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" >
                    {newOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {lookUp({ key: `CONSOLE_${option}_BUTTON` })}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
         
      </Grid>
    </>
  );
};

OrderRules.propTypes = {
  orderRules: arrayOf(
    shape({
      propertyName: string,
      direction: string,
      valueList: arrayOf(string)
    })
  ).isRequired,
  handleChange: func.isRequired,
  enqueueSnackbar: func.isRequired,
  sortDirections: array.isRequired,
  setSortDirections: func.isRequired
};

const mapStateToProps = ({ libraryEnums: { sortDirections } }) => ({ sortDirections });

const mapDispatchToProps = (dispatch) => ({
  setValueFormats: (data) => dispatch(setValueFormats(data)),
  setSortDirections: (data) => dispatch(setSortDirections(data))
});

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(OrderRules);
