import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, makeStyles, TextField, Typography, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { EditorInfo, FormFooter, Loader } from 'components';
import { goBackOrStay } from 'helpers/common';
import messageCenterService from 'services/messageCenterService';
import navigationAction from 'store/actions/navigationAction';
import TemplateHints from './TemplateHints';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

const MessageCenterForm = ({ enqueueSnackbar }) => {
  const { locale, eventName, actionType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const [model, setModel] = useState({
    eventName: '',
    actionType: '',
    name: '',
    subject: '',
    message: '',
    command: '',
    availableFrom: null,
    availableUntil: null,
    published: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [eventOptions, setEventOptions] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [actionOptions, setActionOptions] = useState([]);
  const [isActionsLoading, setIsActionsLoading] = useState(true);
  const [isLanguagesLoading, setIsLanguagesLoading] = useState(false);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [commandValue, setCommandValue] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);

  useEffect(() => {
    getActionOptions().then(() => setIsActionsLoading(false));
    getEventOptions().then(() => setIsEventsLoading(false));
    getLanguages().then(() => setIsLanguagesLoading(false));

    if (eventName && actionType) {
      getMessageData().then(() => setIsLoading(false));
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({
            key: 'CONSOLE_CREATE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_MESSAGE_TEMPLATE` }),
          })
        )
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [eventName, actionType]);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const getMessageData = async () => {
    setIsLoading(true);
    try {
      const data = await messageCenterService.get(locale, eventName, actionType);
      setCommandValue(JSON.stringify(data.command));
      dispatch(
        navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name }))
      );
      setModel(data);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_MESSAGE' }),
        }),
      });
      setIsLoading(false);
    }
  };

  const getLanguages = async () => {
    setIsLanguagesLoading(true);
    try {
      setLanguages(
        ['en-US', 'hu-HU', 'de-DE'].map((e) => ({
          value: e,
          label: lookUp({ key: `CONSOLE_LANG_${e}` }),
        }))
      );
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_LANGUAGES' }),
        }),
      });
      setIsLanguagesLoading(false);
    }
  };

  const getEventOptions = async () => {
    try {
      const data = await messageCenterService.getEventNames();
      data.sort();
      setEventOptions(data.map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_EVENT' }),
        }),
      });
      setIsEventsLoading(false);
    }
  };

  const getActionOptions = async () => {
    try {
      const data = await messageCenterService.getActionTypes();
      setActionOptions(data.map((o) => ({ label: lookUp({ key: `CONSOLE_${o}` }), value: o })));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_ACTION' }),
        }),
      });
      setIsActionsLoading(false);
    }
  };

  const saveData = async (toPublish = false) => {
    if (model.actionType === 'BackgroundUpdate') {
      try {
        model.command = JSON.parse(commandValue);
      } catch {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_COMMAND_VALUE_MUST_JSON_STRING_MESSAGE' }),
        });
        return;
      }
    } else {
      model.command = null;
    }

    toPublish ? setIsPublishing(true) : setIsSaving(true);

    let saveModel;
    if (toPublish) {
      saveModel = { ...model, published: !model.published };
    } else {
      saveModel = { ...model };
    }

    try {
      eventName && actionType
        ? await messageCenterService.edit(locale, model.eventName, model.actionType, saveModel)
        : await messageCenterService.create(
            selectedLocale,
            model.eventName,
            model.actionType,
            saveModel
          );

      toPublish
        ? AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE',
              title: lookUp({ key: `CONSOLE_MESSAGE` }),
            }),
          })
        : AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: lookUp({ key: `CONSOLE_MESSAGE` }),
              title: lookUp({ key: `CONSOLE_MESSAGE` }),
            }),
          });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      const toWhere = window.history.state.idx === 0 ? '/engagement/message-center' : -1;
      goBackOrStay(navigate, toWhere);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE', type: lookUp({ key: 'CONSOLE_MESSAGE' }) }),
      });
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    };
  };

  const deleteMessageCenter = async () => {
    setIsDeleting(true);
    try {
      await messageCenterService.remove({ language: locale, eventName, actionType });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/engagement/message-center'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_MESSAGE' }),
        }),
      });
      setIsDeleting(false);
    }
  };

  const handleCommandChange = (value) => {
    setCommandValue(value);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            eventName && (
              <ThemedButton
                color={model.published ? 'secondary' : 'success'}
                disabled={
                  isPublishing ||
                  isSaving
                }
                onClick={e => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({ key: model.published ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON' })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            eventName && actionType && (
              <DeleteButton onDelete={deleteMessageCenter} loading={isDeleting} disabled={isDeleting} />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={e => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/engagement/message-center')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing || isDeleting}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={e => {
              e.preventDefault();
              saveData();
            }}
            disabled={isSaving || isPublishing || isDeleting}
            loading={isSaving}
          >
            {lookUp({ key: eventName && actionType ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>

        <Grid container spacing={2}>
          <Grid item xs={12}>
              {isActionsLoading ? (
                <Loader inline />
              ) : (
                <TextField
                  select
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  id="message-action-type"
                  label={lookUp({ key: 'CONSOLE_ACTION' })}
                  disabled={!!actionType || !actionOptions?.length}
                  onChange={({ target }) => handleModelChange('actionType', target.value)}
                  value={model.actionType || ''}
                >
                  {actionOptions.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              {isEventsLoading ? (
                <Loader inline />
              ) : (
                <TextField
                  select
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  id="message-event-name"
                  label={lookUp({ key: 'CONSOLE_EVENT' })}
                  disabled={!!eventName || !eventOptions?.length}
                  onChange={({ target }) => handleModelChange('eventName', target.value)}
                  value={model.eventName || ''}
                >
                  {eventOptions.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={8}>
              <TextField
                label={lookUp({ key: 'CONSOLE_NAME' })}
                required
                fullWidth
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label={lookUp({ key: 'CONSOLE_LANGUAGES' })}
                required
                fullWidth
                value={selectedLocale || ''}
                disabled={!!model.name}
                onChange={(e) => setSelectedLocale(e.target.value)}
              >
                {!!languages && languages.map((l, i) => <MenuItem key={i} value={l.value}>{l.label}</MenuItem>)}
              </TextField>
            </Grid>
            {(model.actionType === 'SendEmail' || model.actionType === 'PushNotification') && (
              <Grid item xs={12}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_SUBJECT' })}
                  fullWidth
                  value={model.subject || ''}
                  onChange={(e) => handleModelChange('subject', e.target.value)}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <TemplateHints template={model.eventName} />
          </Grid>
          {(model.actionType === 'SendEmail' || model.actionType === 'PushNotification') && (
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_MESSAGE' })}
                multiline
                minRows={4}
                fullWidth
                value={model.message || ''}
                onChange={(e) => handleModelChange('message', e.target.value)}
              />
            </Grid>
          )}

          {model.actionType === 'BackgroundUpdate' && (
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_COMMAND' })}
                multiline
                minRows={4}
                fullWidth
                value={commandValue}
                onChange={(e) => handleCommandChange(e.target.value)}
              />
            </Grid>
          )}
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_AVAILABILITY' })}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_AVAILABLEFROM' })}
                variant="inline"
                inputVariant="outlined"
                autoOk
                size="small"
                value={model.availableFrom}
                placeholder={lookUp({ key: 'CONSOLE_AVAILABLEFROM_HELPERTEXT' })}
                onChange={(date) => handleModelChange('availableFrom', date)}
                {...(model.availableUntil && { maxDate: model.availableUntil })}
                format="MM/DD/YYYY"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_AVAILABLEUNTIL' })}
                variant="inline"
                inputVariant="outlined"
                autoOk
                size="small"
                value={model.availableUntil}
                placeholder={lookUp({ key: 'CONSOLE_AVAILABLEUNTIL_HELPERTEXT' })}
                onChange={(date) => handleModelChange('availableUntil', date)}
                format="MM/DD/YYYY"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {eventName && actionType && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

MessageCenterForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(MessageCenterForm);
