import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';
import { makeApiWithQuery } from 'helpers/common';

const create = (jobType, reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/${jobType}`, reqBody)
    .then((response) => response?.data)
};

/* TODO: Implement GetLifecycleJobTypes in Video Editor and in Workflows - Archive/Deletion */
const getLifecycleJobTypes = (jobType) => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/LifecycleJob/GetLifecycleJobTypes`)
    .then((res) => res?.data);
};

const getLanguageCodes = (jobType) => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/${jobType}Job/GetLanguageCodes`)
    .then((res) => res?.data);
};

const translate = (reqBody) => {
  return axios
    .put(`${getConsoleApiEndpoint('Content')}/RequestLocalization/`, reqBody)
    .then((response) => response?.data)
};

const transcode = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/TranscodeJob/`, reqBody)
    .then((response) => response?.data)
};

const cutVideo = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/CutVideoJob`, reqBody)
    .then((response) => response?.data)
};

const mediaProcessor = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/MediaProcessorJob`, reqBody)
    .then((response) => response?.data)
};

const getMediaProcesingTypes = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/MediaProcessorJob/GetMediaProcessingTypes`)
    .then((response) => response?.data);
};

const generateWaveform = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/GenerateWaveformJob`, reqBody)
    .then((response) => response?.data)
};

const mintNft = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/MintNFtJob`, reqBody)
    .then((response) => response?.data)
};

const comprehendTypes = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/TextAnalysisJob/GetTextAnalysisDetectionTypes`)
    .then((response) => response?.data)
};

const comprehendProviders = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/TextAnalysisJob/TextAnalysisProvider`)
    .then((response) => response?.data)
};

const subtitle = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/SubtitlingJob`, reqBody)
    .then((response) => response?.data)
};

const transcriptProviders = () =>
  axios
    .get(`${getConsoleApiEndpoint('JobManager')}/TranscriptJob/GetProviders`)
    .then((response) => response?.data)

const getJobTypes = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/GenericJob/GetJobTypes`)
    .then((response) => response?.data)
};

const getStatuses = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/GenericJob/GetStatuses`)
    .then((response) => response?.data)
};

const search = (search, pageSize = 25, page = 0) => {
  search.delete('page');
  search.delete('pageSize');
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/GenericJob/Search/${pageSize}/${page}?${search}`)
    .then(res => res.data);}

const searchJobs = ({
  page = 0,
  perPage = 1000,
  q = null,
  sortExpr = 'name asc',
  ...rest
}) => 
  axios.get(makeApiWithQuery(`${getConsoleApiEndpoint('JobManager')}/GenericJob/Search/${perPage}/${page}`,
  {
    sortExpr,
    q,
    ...rest,
  }
  ))
  .then((res) => res?.data)

const searchWfTemplates = ({
  page = 0,
  perPage = 1000,
  q = '',
  sortExpr = 'name asc',
  ...rest
} = {}) =>
  axios
    .get(
      makeApiWithQuery(
        `${getConsoleApiEndpoint('JobManager')}/WorkflowTemplate/Search/${perPage}/${page}`,
        {
          sortExpr,
          q,
          ...rest,
        }
      )
    )
    .then((res) => res?.data);

const getJobStatusesEnum = () => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/GenericJob/GetStatuses/`)
    .then((response) => response?.data)
};

const getWfTemplate = (id) => {
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/WorkflowTemplate/${id}`)
    .then((response) => response?.data)
};

const clone = (workflowId) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/WorkflowTemplate/Clone/${workflowId}`)
    .then((response) => response?.data)
};

const makeWfTemplate = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/WorkflowTemplate`, reqBody)
    .then((response) => response?.data)
};

const removeWfTemplate = (id) => {
  return axios
    .delete(`${getConsoleApiEndpoint('JobManager')}/WorkflowTemplate/${id}`)
};

const getLatestWorkflowByContent = (referenceObjectId, status) => {
  let queryString =`itemLimit=1&reverse=true&refOId=${referenceObjectId}`;
  if (status) {
    queryString = `${queryString}&status=${status}`;
  }
  return axios
    .get(`${getConsoleApiEndpoint('JobManager')}/UseCase/Search?${queryString}`)
    .then((response) => response?.data)
};

const runWorkflow = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/UseCase`, reqBody)
    .then((response) => response?.data)
};

const requestRemixAudioJob = (reqBody) => {
  return axios
    .post(`${getConsoleApiEndpoint('JobManager')}/RemixAudioJob`, reqBody)
    .then((response) => response?.data)
};

export default {
  create,
  clone,
  getLifecycleJobTypes,
  getLanguageCodes,
  translate,
  transcode,
  cutVideo,
  mediaProcessor,
  generateWaveform,
  mintNft,
  subtitle,
  transcriptProviders,
  comprehendTypes,
  comprehendProviders,
  getJobTypes,
  getStatuses,
  search,
  searchJobs,
  searchWfTemplates,
  getJobStatusesEnum,
  getLatestWorkflowByContent,
  getWfTemplate,
  makeWfTemplate,
  removeWfTemplate,
  runWorkflow,
  requestRemixAudioJob,
  getMediaProcesingTypes
};

export {
  getLanguageCodes,
  getMediaProcesingTypes
};