import React from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import PublishedComponent from 'assets/icons/PublishedComponent'
import UnPublishedComponent from 'assets/icons/UnPublishedComponent'
import { search, remove } from 'services/dataSourcesService';
import {Listing, CreatedOn} from 'components';
import LogButton from 'components/Audit/LogButton';

const DataSources = () => {

  const create = {
    text: lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp( {key: 'CONSOLE_DATA_SOURCE'} )}),
    link: '/apps/data-sources/create'
  };

  const columns = [
    {
      field: 'enabled',
      headerName: ' ',
      align: 'left',
      width: 40,
      renderCell: item =>
        item.value ? (
          <PublishedComponent />
        ) : (
          <UnPublishedComponent />
        )
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      flex: true,
      align: 'left',
      renderCell: (item) => (
        <Link
          to={`/apps/data-sources/${item.id}/edit`}
          component={RouterLink}
        >
          {item.value}
        </Link>
      )
    },
    { 
      field: 'type', 
      headerName: lookUp({ key: 'CONSOLE_TYPE' }),
      align: 'left',
      renderCell: (item) => (
      item.value === 'TransmitAndRetrieve' ? <p>TX</p> : <p>RX</p>
      )
    },
    { 
      field: 'reloadFrequency',
      headerName: lookUp({ key: 'CONSOLE_RELOADFREQUENCY' }),
      width: 160,
      align: 'left',  
      renderCell: (item) => item.value < 0 ? lookUp({ key: 'CONSOLE_NA' }) : `${item.value} s` 
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'log',
      headerName: ' ',
      align: 'center',
      width: 40,
      filterable: false,
      sortable: false,
      renderCell: (item) => (
        <LogButton type="Feed" id={item.id} />
      )
    },
  ];

  return (
    <Listing
      create={create}
      tableHeadElements={columns}
      loadData={search}
      deleteItem={remove}
      multiSort={true}
    />
  );
};

export default DataSources;
