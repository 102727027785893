import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const UploadProgress = ({ value, withIndicator = false, ...rest }) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={withIndicator ? 1 : 0}>
        {value > 0 ? (
          <LinearProgress variant="determinate" value={value} {...rest} />
        ) : (
          <LinearProgress variant="indeterminate" />
        )}
      </Box>
      {withIndicator && (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

UploadProgress.propTypes = {
  value: PropTypes.number.isRequired,
  withIndicator: PropTypes.bool,
};

export default UploadProgress;
