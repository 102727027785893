import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Lister, Toolbar, QueryBar, Spacing, CreatedOn, ConfirmDialog } from 'components';
import { Link, Button, Input } from '@material-ui/core';
import { GetApp, Publish } from '@material-ui/icons';
import stringService from 'services/stringService';
import uniformServices from 'services/uniformServices';
import AlertService from 'services/alertService';

const Localization = ({ enqueueSnackbar }) => {
  const [locales, setLocales] = useState(useSelector((s) => s.lexicons.projectLanguages) || []);
  const [search, setSearch] = useSearchParams();
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [trigger, setTrigger] = useState();

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined) delete searchObj[key];
    setSearch(searchObj);
  };

  const langFilter = {
    label: lookUp({ key: 'CONSOLE_ALL_LANGUAGES' }),
    key: 'l',
    options: locales.map((e) => ({
      label: lookUp({ key: `CONSOLE_LANG_${e.value}` }),
      value: e.value,
    })),
    change: (value) => setParam('l', value),
  };


  const importFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const dataToUpload = formData;
    return stringService
      .uploadXLS(dataToUpload)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_FILE_UPLOAD_SUCCESS' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' }),
        });
      })
      .finally(() => {
        const uploadInput = document.getElementById('uploadFileInput');
        uploadInput.value = '';
      });
  };

  const uploadClick = () => {
    const uploadInput = document.getElementById('uploadFileInput');
    uploadInput.click();
  };

  const importButton = {
    child: (
      <>
        {lookUp({ key: 'CONSOLE_UPLOAD_BUTTON' })}
        <Input id="uploadFileInput" type="file" style={{ display: 'none' }} onChange={importFile} />
      </>
    ),
    onClick: uploadClick,
    startIcon: <Publish />
  };
  const exportButton = {
    label: lookUp({ key: 'CONSOLE_EXPORT_BUTTON' }),
    startIcon: <GetApp />,
    onClick: () => stringService.downloadXLS('LocalizationStrings.xlsx'),
  };

  const columns = [
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      width: 260,
      renderCell: (item) => {
        if (item.row.flavor) {
          return (
            <Link
              to={`/configuration/localization/${item.row.locale}/${item.row.name}/${item.row.flavor}/edit`}
              component={RouterLink}
            >
              {item.row.name}
            </Link>
          );
        } else {
          return (
            <Link
              to={`/configuration/localization/${item.row.locale}/${item.row.name}/edit`}
              component={RouterLink}
            >
              {item.row.name}
            </Link>
          );
        }
      },
    },
    {
      field: 'locale',
      headerName: lookUp({ key: 'CONSOLE_LOCALE' }),
      align: 'left',
      renderCell: (item) => item.locale,
    },
    {
      field: 'value',
      headerName: lookUp({ key: 'CONSOLE_VALUE' }),
      align: 'left',
      editable: true,
      flex: true,
      renderCell: (item) => item.row.value,
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      filterable: false,
      isSortable: true,
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'del',
      headerName: ' ',
      filterable: false,
      sortable: false,
      align: 'center',
      width: 40,
      renderCell: (item) => (
        <ConfirmDialog
          onConfirm={() => handleDelete(item.row)}
          message={lookUp({
            key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE',
            title: item.row.name || 'item',
          })}
          dialogText={lookUp({ key: 'CONSOLE_DELETE' })}
          buttonText={lookUp({ key: 'CONSOLE_DELETE' })}
          hint={lookUp({ key: 'CONSOLE_DELETE_ITEM' })}
        />
      ),
    },
  ];

  const handleDelete = async (item) => {
    try {
      if (item.flavor) {
        await stringService.remove(item.locale, item.name, item.flavor);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE',
            type: item.name || item.key || lookUp({ key: 'CONSOLE_item' }),
          }),
        });
        setTrigger(Symbol('refresh'));
      } else {
        await stringService.remove(item.locale, item.name);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE',
            type: item.name || item.key || lookUp({ key: 'CONSOLE_item' }),
          }),
        });
        setTrigger(Symbol('refresh'));
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_ENTRY' }),
        }),
      });
    }
  };

  const handleValueChange = (params) => {
    const name = params.id.split('|')[0];
    const locale = params.id.split('|')[1];
    const { value } = params;
    stringService.edit({ name, locale, value });
  };

  const getData = () => {
    setLoading(true);
    uniformServices
      .search('Strings', search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res.totalCount);
        setItems([
          ...res.data
            .filter((item) => !deletedIds?.includes(item.id))
            .map((e) => ({ ...e, id: `${e.name}|${e.locale}` })),
        ]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_STRINGS` }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (locales.length) return;
    const getLangs = async () => {
      const langs = await stringService.getProjectLanguages();
      setLocales(langs);
    };
    getLangs();
  }, []);

  useEffect(() => {
    getData();
  }, [search, trigger]);

  return (
    <>
      <Toolbar
        create
        buttons={[importButton, exportButton]}
        triggerSearch={() => setTrigger(Symbol('refresh'))}
      />
      <Spacing height={1} />
      <QueryBar filters={[langFilter]} />
      <Spacing height={2} />
      <Lister
        loading={loading}
        columns={columns}
        rows={items || []}
        totalCount={totalCount}
        dataGridProps={{ onCellEditCommit: (p, e) => handleValueChange(p, e) }}
      />
    </>
  );
};

export default withSnackbar(Localization);
