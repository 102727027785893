import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';

export const reloadService = (endpoint) => { return axios.get(endpoint + '/reload')
  .then(r => r?.data)
}

export const reindexCustomers = () => axios.put(`${getConsoleApiEndpoint('Customer')}/ForceReindex`).then(r => r.data);

export const reindexCatalog = () => axios
  .put(`${getConsoleApiEndpoint('CatalogV3')}/Reindex`)
  .then(({ data }) => data);

export const reindexSkug = () =>
  axios.put(`${getConsoleApiEndpoint('MediaHelper')}/sku/ForceReindex`).then(({ data }) => data);

export const reindexPlaybackPolicyText = () =>
  axios.put(`${getConsoleApiEndpoint('MediaHelper')}/PlaybackPolicy/ForceReindex`).then(({ data }) => data);
