import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import customersService from 'services/customersService';
import alertService from 'services/alertService';
import { enumToOrderedArray } from 'helpers/common';
import ControlBox from 'components/ControlBox';
import ThemedButton from 'components/ThemedButton';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    overscrollBehavior: 'contain',
  },
  results: {
    height: '100%',
  },
  resultRow: {
    marginBottom: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  closeButton: {
    display: 'flex',
    alignSelf: 'center',
    color: theme.palette.grey[500],
    marginRight: `-${theme.spacing(.5)}px`,
    marginLeft: theme.spacing(.5),
    zIndex: theme.zIndex.drawer + 2,
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
}));

const preselectType = {
  Project: 'Project',
};

const DEFAULT_RELATION = 'customer';
const baseUrlByRelationType = {
  customer: ['access', 'customer'],
  sku: ['monetization', 'skus'],
  moderator: ['access', 'customer'],
};
const itemPropsMappingByRelation = {
  customer: {
    name: 'username',
    id: 'customerId',
  },
  sku: {
    name: 'name',
    id: 'id',
  },
  moderator: {
    name: 'username',
    id: 'customerId',
  },
};

const CustomerSelector = ({
  enqueueSnackbar,
  onSelect,
  onClose,
  selectedRelationTargetType = 'Content',
  selectedRelation = 'Customer',
  targetType = 'Customer',
}) => {
  const classes = useStyles();

  // initial parameters
  const [q, setQ] = useState('');
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [hasSearched, setHasSearched] = useState(false);
  const [results, setResults] = useState([]);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [relationMatch, setRelationMatch] = useState(DEFAULT_RELATION);

  useEffect(() => {
    let relationTarget = determineRelationTarget([selectedRelation, selectedRelationTargetType, targetType]);

    if (relationTarget) {
      setRelationMatch(relationTarget);
    }
  }, [selectedRelation, selectedRelationTargetType, targetType]);

  // attempts to determine a relation target based on selectedRelation, if none found use the default one
  // TODO move into data sources utils
  const determineRelationTarget = (targets = []) => {
    const normalizedAvailableRelations = [
      ...targets,
      DEFAULT_RELATION,
    ]
      .filter((target) => target)
      .map((target) => target.toLowerCase?.());

    return normalizedAvailableRelations.find((relation) => `${relation}` in itemPropsMappingByRelation);
  };

  // get item id based on relationMatch
  const getItemId = (item, relation = relationMatch) => {
    return get(item, [itemPropsMappingByRelation[relation]?.id]) || baseUrlByRelationType[DEFAULT_RELATION]?.id;
  };

  // get item edit url based on relationMatch
  const getItemEditLink = (item, relation = relationMatch) => {
    let editPath = baseUrlByRelationType[relation] || baseUrlByRelationType[DEFAULT_RELATION];

    return `/${editPath.join?.('/')}/${getItemId(item)}/edit`;
  };

  // get item title based on relationMatch
  const getItemTitle = (item, relation = relationMatch) => {
    return get(item, itemPropsMappingByRelation[relation]?.name) || baseUrlByRelationType[DEFAULT_RELATION]?.name;
  };

  const getCustomerOptions = async () => {
    setResultsLoading(true);
    
    const searchParams = {
      ...(q && { q }),
      ...(type && { type }),
    };

    try {

      const { data } = await customersService.searchQuery(
        searchParams,
        100,
        0,
      );
      
      setResults(data);
    } catch (error) {
      alertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CUSTOMER_SEARCH_RESULTS' }),
        }),
      });
    } finally {
      setResultsLoading(false);
      setHasSearched(true);
    }
  };

  const handleSearch = async () => {
    setSearchTimeout((timeout) => clearTimeout(searchTimeout));
    const timeout = setTimeout(async () => {
      await getCustomerOptions();
    }, 500);
    setSearchTimeout(timeout);
  };

  const handleAddItem = item => {
    onSelect?.(getItemId(item), selectedRelation, selectedRelationTargetType);
  };

  useEffect(() => {
    customersService.getCustomerTypes()
      .then(res => {
        const types = enumToOrderedArray(res);

        setTypes(types.sort?.((a, b) => a < b ? -1 : 1));
      })
  }, [selectedRelation]);

  useEffect(() => {
    if (preselectType[selectedRelation] || types.includes(selectedRelation)) {
      setType(preselectType[selectedRelation] || selectedRelation);
    } else { 
      setType('');
    }
  }, [types, selectedRelation]);

  // FIXME cleanup
  const handleClose = () => {
    setResults([]);
    setHasSearched(false);
    onClose?.();
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-end"
      alignContent="flex-start"
      className={classes.root}
    >
      <Grid item xs={12} className={classes.titleContainer}>
        {selectedRelation && (
          <Typography variant="button" className={classes.title} gutterBottom>
            {lookUp({ key: `CONSOLE_${selectedRelation}` })}
          </Typography>
        )}
        <Box flex={1} />
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={lookUp({ key: 'CONSOLE_KEYWORD' })}
          value={q || ''}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              handleSearch();
              ev.preventDefault();
            }
          }}
          onChange={({ target }) => {
            setQ(target.value);
          }}
        />
      </Grid>
      {types?.length > 0 && (
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            selectOnFocus
            handleHomeEndKeys
            options={types}
            value={type}
            onChange={(event, selection) => setType(selection)}
            getOptionLabel={(option) => option && lookUp({ key: `CONSOLE_${option}` })}
            renderInput={(params) => <TextField fullwidth {...params} label={lookUp({ key: 'CONSOLE_TYPE' })}/>}
          />
        </Grid>
      )}
      <Grid item xs={12} container justifyContent="flex-end">
        <ThemedButton
          onClick={getCustomerOptions}
          loading={resultsLoading}
        >
          {lookUp({ key: 'CONSOLE_SEARCH_BUTTON' })}
        </ThemedButton>
      </Grid>
      {results.length > 0 ? (
        <Grid item xs={12} container>
          {results.map(item => (
            <Grid
              key={`${getItemId(item)}-${selectedRelation}`}
              container
              item
              justifyContent="flex-start"
              spacing={1}
              xs={12}
              className={classes.listItem}
            >
              <Grid item xs={4}>
                <ControlBox
                  width="100%"
                  height={75}
                  offset={4}
                  topButtons={[
                    {
                      selector: 'iconButton',
                      icon: <>i</>,
                      tooltip: lookUp({ key: 'CONSOLE_OPEN_IN_NEW_HELPERTEXT' }),
                      onClick: e => {
                        e.preventDefault();
                        window.open(getItemEditLink(item), '_blank');
                      },
                    },
                  ]}
                  bottomButtons={[
                    {
                      selector: 'iconButton',
                      icon: <AddIcon />,
                      tooltip: lookUp({ key: 'CONSOLE_ADD_ITEM' }),
                      onClick: e => {
                        e.preventDefault();
                        handleAddItem(item);
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" noWrap>{getItemTitle(item) || '--'}</Typography>
                <Typography variant="caption" noWrap>
                  {item.customerType ? lookUp({ key: `CONSOLE_${item.customerType}` }) : '--'}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : !hasSearched ? (
        <></>
      ) : (
        <Grid item xs={12}>
          {lookUp({ key: 'CONSOLE_NO_ROWS' })}
        </Grid>
      )}
    </Grid>
  );
};

CustomerSelector.propTypes = {
  enqueueSnackbar: PropTypes.any,
  selectedRelation: PropTypes.oneOf(['Content', 'Sku', 'Customer', 'Moderator', 'Notification', 'Organization']).isRequired,
  selectedRelationTargetType: PropTypes.string,
  targetType: PropTypes.string,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
};

export default withSnackbar(CustomerSelector);
