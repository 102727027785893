import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const search = ({
  page = 0,
  perPage = 25,
  sort = 'eventName asc',
  q = '',
  e = '',
  a = '',
  ...rest
} = {}) => {
  return axios
    .get(
      makeApiWithQuery(`${getConsoleApiEndpoint('MessageCenterTemplate')}/Search/${perPage}/${page}`, {
        q,
        e,
        a,
        sort,
        ...rest
      })
    )
    .then((r) => r?.data);
}

const create = async (locale, eventName, actionType, model) => axios.post(`${getConsoleApiEndpoint('MessageCenterTemplate')}/${eventName}/${actionType}/${locale}`, model)
  .then((r) => r?.data);

const get = (locale, eventName, actionType) => axios.get(`${getConsoleApiEndpoint('MessageCenterTemplate')}/${eventName}/${actionType}/${locale}`)
  .then((r) => r?.data);

const edit = async (locale, eventName, actionType, model) => axios.put(`${getConsoleApiEndpoint('MessageCenterTemplate')}/${eventName}/${actionType}/${locale}`, model)
  .then((r) => r?.data);

const remove = ({ language, eventName, actionType }) => axios.delete(`${getConsoleApiEndpoint('MessageCenterTemplate')}/${eventName}/${actionType}/${language}`)
  .then((r) => r?.data);

const getEventNames = () => axios.get(`${getConsoleApiEndpoint('MessageCenterEnums')}/EventNames`)
  .then((r) => r?.data);

const getActionTypes = () => axios.get(`${getConsoleApiEndpoint('MessageCenterEnums')}/ActionTypes`)
  .then((r) => r?.data);

export { search, create, get, edit, remove };

export default {
  search,
  create,
  get,
  edit,
  remove,
  getEventNames,
  getActionTypes
};
