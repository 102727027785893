import { lookUp } from 'services/stringService';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Breadcrumbs, Typography, Link, makeStyles } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2)
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));

const BreadcrumbsComponent = (
) => {
  const classes = useStyles();
  const location = useLocation();
  const pageTitle = useSelector(state => state.navigation.pageTitle);
  const breadcrumbs = useSelector(state => state.libraryEnums.breadcrumbsLinks);
  const userRole = useSelector(state => state.auth.user.role);
  const { menu } = useSelector(state => state.auth.access)?.find(e => e.role === userRole ) || [];
  const main = menu?.filter(e => !e.root)?.find(e => location.pathname.indexOf(e.url) !== -1);
  const sub = main?.menuitems?.find(e => (location.pathname+location.search).indexOf(e.url) !== -1);
  
  const getCrumbsFromUrl = () => {
    const breadcrumbs = [
      main,
      sub,
      pageTitle ? ({ text: pageTitle, isCurrent: true}) : null
    ];
    return breadcrumbs
      .filter(e => !!e)
      .map(e => (
        {
          ...e,
          link: e.url,
          text: e.text || lookUp({ key: e.key })
        }));
  };

  const crumbsFromUrl = getCrumbsFromUrl();

  const crumbs = breadcrumbs || crumbsFromUrl;

  return (
    <>
      <Helmet title={pageTitle || [...crumbs]?.pop()?.text}/>
      <div className={classes.root}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {crumbs.map((crumb, index) =>
            crumb.isCurrent ? (
              <Typography key={index} className={classes.capitalize}>
                {crumb.text}
              </Typography>
            ) : (
              <Link
                key={index}
                component={RouterLink}
                to={crumb.link}
                className={classes.capitalize}
              >
                {crumb.text}
              </Link>
            )
          )}
        </Breadcrumbs>
      </div>
    </>
  );
}

export default BreadcrumbsComponent;
