import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TimePicker from 'components/TimePicker';
import Dialog from 'components/Dialog';
import ThemedButton from 'components/ThemedButton';
import { msToTime, getMs } from 'helpers/time';
import { segmentTypes, markerTitleMapping } from 'constants/index.js';


const FORM_FIELD_SIZE = 'small';
// FIXME determine functionality, cleanup prio implementation, use inmutable data instead of mutating props
function MarkerEditor({
  open,
  selectedBrPt = {},
  updateBreakpoint = () => {},
  onClose = () => {}
}) {
  const [copyBrPt, setCopyBrPt] = useState({ title: '', ...selectedBrPt });
  const {
    type,
    position = 0,
    end = 0,
    description = '',
  } = copyBrPt;

  const handleSaveClick = () => {
    updateBreakpoint(selectedBrPt.type, copyBrPt);
    onClose();
  };

  const handleInputChange = (prop, value) => {
    // FIXME send the updated property, do not send over the entire chapter model
    setCopyBrPt((chapter) => ({
      ...chapter,
      [prop]: value,
    }));
  };

  const titleType = markerTitleMapping[type] || 'title';
  const shouldDisplayEditField = segmentTypes.includes(type);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={lookUp({
        key: 'CONSOLE_EDIT_TEMPLATE',
        title: lookUp({ key: `CONSOLE_${selectedBrPt.type}` }),
      })}
      actions={
        <>
          <Box flex={1} />
          <ThemedButton color="success" onClick={handleSaveClick}>
            {lookUp({ key: 'CONSOLE_SAVE_BUTTON' })}
          </ThemedButton>
        </>
      }
      maxWidth="sm"
      fullWidth
    >
      <Grid direction="column" spacing={2} container>
        {/* Don't "fix" this: copyBrPt[titleType] != null -> https://262.ecma-international.org/12.0/#sec-abstract-equality-comparison */}
        {copyBrPt[titleType] != null && (
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                id="title"
                label={lookUp({ key: `CONSOLE_${titleType}` })}
                aria-describedby="title-helper"
                value={copyBrPt[titleType]}
                onChange={(e) => handleInputChange('title', e.target.value)}
              />
              <FormHelperText id="title-helper">
                {lookUp({ key: `CONSOLE_${titleType}_HELPERTEXT` })}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="description"
              label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
              aria-describedby="decsription-helper"
              value={description}
              onChange={(e) => handleInputChange('description', e.target.value)}
              minRows={4}
              multiline
            />
            <FormHelperText id="description-helper">
              {lookUp({ key: 'CONSOLE_DESCRIPTION_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TimePicker
                label={lookUp({ key: 'CONSOLE_START' })}
                value={msToTime(getMs(position))}
                onChange={(value) => handleInputChange('position', value)}
              />
              <FormHelperText id="duration-start-helper">
                {lookUp({ key: 'CONSOLE_START_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          {shouldDisplayEditField && (
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TimePicker
                  label={lookUp({ key: 'CONSOLE_END' })}
                  value={msToTime(getMs(end))}
                  onChange={(value) => handleInputChange('end', value)}
                />
                <FormHelperText id="duration-end-helper">
                  {lookUp({ key: 'CONSOLE_END_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

MarkerEditor.propTypes = {
  open: PropTypes.bool,
  selectedBrPt: PropTypes.object.isRequired,
  updateBreakpoint: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default MarkerEditor;
