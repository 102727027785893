import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import {
    TableCell,
    Table,
    TableBody,
    TableHead,
    TableRow, } from '@material-ui/core';
import CreatedOn from 'components/CreatedOn'
import customersService from 'services/customersService';


const Devices = ({id}) => {
  


  const [deviceModel, setDeviceModel] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const data = await customersService.getDeviceById(id)
      setDeviceModel(data)
    }
    getData()
  }, [id])

  return (
    <div>
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left" padding={'default'}>Device Type</TableCell>
            <TableCell align="left" padding={'default'}>Device Mode</TableCell>
            <TableCell align="left" padding={'default'}>Date Added</TableCell>
            <TableCell align="left" padding={'default'}>Last Seen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {deviceModel.map((item, index) => (                   
            <TableRow key={`${item.deviceId}-${index}`}>
              <TableCell align="left" component="th" scope="row">
                {item.deviceType}
              </TableCell>
              <TableCell align="left">
                {item.deviceModel}
              </TableCell>
              <TableCell align="left">
                  <CreatedOn date={item.dateAdded} />
              </TableCell>
              <TableCell align="left">
                  <CreatedOn date={item.lastSeen} />
              </TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
</div>
  );
};

export default Devices;