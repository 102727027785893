import {
  SET_PARAMETERS_ROWS_NUMBER,
  SET_PARAMETERS_PAGE_NUMBER,
  SET_ERROR_HIGHLIGHT,
  SET_LO_INHERIT_LANG,
  SET_PL_INHERIT_LANG,
  SET_DEFAULT_LOCALELANG,
} from '../actions/parametersAction';


export const initialState = {
  pageNumber: 0,
  rowsNumber: 10,
  isErrHighlight: { on: false, paramChecked: false },
  isInheritLOLang: { on: false, paramChecked: false },
  isInheritPLLang: { on: false, paramChecked: false },
  defaultLocale: { lang: window.navigator.language, paramChecked: false },
};

const parametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PARAMETERS_ROWS_NUMBER:
      return {
        ...state,
        rowsNumber: action.rowsNumber
      };
    case SET_PARAMETERS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber
      };
    case SET_ERROR_HIGHLIGHT:
      return {
        ...state,
        isErrHighlight: action.isErrHighlight
      };
    case SET_LO_INHERIT_LANG:
      return {
        ...state,
        isInheritLOLang: action.isInheritLOLang,
      };
    case SET_PL_INHERIT_LANG:
      return {
        ...state,
        isInheritPLLang: action.isInheritPLLang,
      };
    case SET_DEFAULT_LOCALELANG:
      return {
        ...state,
        defaultLocale: action.defaultLocale,
      };
    default:
      return state;
  }
};

export default parametersReducer;
