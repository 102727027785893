import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import transcodingProfilesService from 'services/transcodingProfilesService';
import AlertService from 'services/alertService';
import TranscodeWithCut from '../../../Automation/Workflows/TranscodeWithCut';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 0.5, 1, 0.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.6),
  },
}));

const Cut = (props) => {
  const { id, url, setOpen, contentType, brPtContainer, selectedBrPt, enqueueSnackbar } =
    props;
  const [availableTranscodeProfiles, setAvailableTranscodeProfiles] = useState({});
  const [cutModel, setCutModel] = useState({
    cutEvents: [],
    profileUrl: '',
    streamKind: '',
    locale: '',
    inputFileLocation: '',
    type: 'CUT_VIDEO',
    referenceObjectId: '',
    markerType: selectedBrPt.type,
    markerKeyword: selectedBrPt.displayName || selectedBrPt.labelId || selectedBrPt.markerKeyword,
    notificationEvents: ['SUCCEED', 'SCHEDULED', 'FAILED'],
  });

  const classes = useStyles();

  const handleChange = (value, format, id, property) => {
    setCutModel((cutModel) => {
      cutModel[property] = value;
      return { ...cutModel };
    });
  };

  const startCut = async () => {
    const reqBody = cutModel;
    if (!cutModel.markerKeyword) {
      if (!brPtContainer[cutModel.markerType].length) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          context: lookUp({ key: 'CONSOLE_NO_CUT_EVENTS' }),
        });
        return;
      }
      reqBody.cutEvents = brPtContainer[cutModel.markerType]?.reduce((acc, brPt) => {
        if (brPt.position) {
          acc.push({ startTimeCode: brPt.position, endTimeCode: brPt.end });
        }
        return acc;
      }, []);
    }
    reqBody.name = `CutJob_${Date.now()}`;
    reqBody.referenceObjectId = id;
    reqBody.inputFileLocation = url;
    jobManagerService
      .cutVideo(reqBody)
      .then((jobResponse) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} ${jobResponse.streamKind} -> ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`,
        });
        setOpen('cut', false);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
        });
      });
  };

  useEffect(() => {
    if (Object.keys(availableTranscodeProfiles).length > 0) return;
    transcodingProfilesService.getAll().then((profiles) => {
      setAvailableTranscodeProfiles(
        profiles.reduce((acc, profile) => {
          acc[profile.streamKind] = {
            ...acc[profile.streamKind],
            [profile.name]: profile.profileUrl,
          };
          return acc;
        }, {})
      );
    });
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({
            key: 'CONSOLE_CUT_TITLE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${contentType}` })
          })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('cut', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>
        {lookUp({
          key: 'CONSOLE_CUT_TEXT_TEMPLATE',
          title: lookUp({ key: `CONSOLE_${contentType}` })
        })}
      </span>
      <DialogContent className={classes.dialogBody}>
        <TranscodeWithCut
          drawMarkers={true}
          selectedBrPtType={cutModel.markerType}
          keyword={cutModel.markerKeyword}
          model={cutModel}
          onAttribChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <Button
            onClick={() => {
              startCut();
              setOpen('cut', false);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
};

export default withSnackbar(Cut);
