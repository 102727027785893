const camelCaseToSentenceCase = (str) => {

  if (!str || (typeof str !== 'string')) return "";
  const withSpaces = str.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
}

const validateEmail = email => // true if wellformed email address, false if not, null if no email address is provided.
  !email ? null :
    String(email)
      .toLowerCase()
      .match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);

const substituteVariables = (baseString, variables) => {
  if (!variables || !baseString) return null;
  let templated = '' + baseString;
  Object.keys(variables)?.forEach(variable => {
    templated = templated.split(`{{${variable}}}`).join(variables?.[variable]);
  });
  return templated;
};

const randomArrayOfRandomStrings = (max = 10, drop = 0, length = 8) =>
[...new Array(Math.floor(Math.random() * max))]
  .map((e) =>
    Math.random() > drop ? (Math.random() + 1).toString(36).substring(length) : null
  )
  .filter((e) => !!e);

const getSubtLang = (location) => {
  if (location.hash && /#Subtitle_[a-z]{2}-[A-Z]{2}/.test(location.hash)) {
    const subtAndLang = location.hash.split('|')[0];
    const langCodeIndex = subtAndLang.indexOf('#Subtitle_') + 10;
    return subtAndLang.slice(langCodeIndex);
  }
  return '';
};

export {
  camelCaseToSentenceCase,
  validateEmail,
  substituteVariables,
  randomArrayOfRandomStrings,
  getSubtLang,
}