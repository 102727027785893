import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';

const COLORS = [
  'primary',
  'secondary',
  'info',
  'error',
  'warning',
  'success',
];
const DEFAULT_COLOR = 'inherit';
const LOADER_SIZE = 24;
const LOADER_POSITION = LOADER_SIZE / 2;

const useStyles = makeStyles(theme => {
  const styles = COLORS.reduce((obj, color) => {
    obj[color] = {
      backgroundColor: theme.palette[color]?.main,
      color: theme.palette[color]?.contrastText || theme.palette.getContrastText(theme.palette[color]?.main),
      '&:hover': {
        backgroundColor: theme.palette[color]?.dark,
        color: theme.palette.getContrastText(theme.palette[color]?.dark),
      },
      '&[class*="outlined"]': {
        backgroundColor: 'transparent',
        borderColor: theme.palette[color]?.main,
        color: theme.palette[color]?.main,
      }
    }
    return obj;
  }, {})

  return {
    ...styles,
    loaderContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: theme.palette.text.divider,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: `-${LOADER_POSITION}px`,
      marginLeft: `-${LOADER_POSITION}px`,
    },
  };
})

const ThemedButton = ({
  children,
  color = DEFAULT_COLOR,
  loading = false,
  ...rest
}) => {
  const classes = useStyles();

  // exclude [color, children] from additional button props
  const buttonProps = Object.keys(rest)
    .filter(prop => !['color', 'children'].includes(prop))
    .reduce((acc, propKey) => {
      acc[propKey] = rest[propKey];
      return acc;
    }, {});

  const variant = COLORS.includes(color) ? 'contained' : 'text';

  return (
    <>
      <Button {...buttonProps} className={classes[color]} variant={rest.variant ? rest.variant : variant}>
        {loading && (
          <Box className={classes.loaderContainer}>
            <CircularProgress
              className={classes.buttonProgress}
              color="inherit"
              size={LOADER_SIZE}
            />
          </Box>
        )}
        {children}
      </Button>
    </>
  );
};

ThemedButton.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default ThemedButton;
