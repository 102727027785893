import get from 'lodash/get';
import {
  SET_ACTION_TYPES,
  SET_ACTION_GROUPS,
  SET_CATALOG_KINDS,
  SET_CATALOG_TYPES,
  SET_CONDITION_NAMES,
  SET_DATA_GROUP_TYPES,
  SET_FEED_TYPES,
  SET_HTTP_METHODS,
  SET_PLUGIN_TYPES,
  SET_SORT_DIRECTIONS,
  SET_STRING_MATCHING,
  SET_VALUE_FORMATS,
  SET_VISIBILITY_GROUPS,
  SET_SCHEDULE_TYPES,
  SET_PROPERTY_OPTIONS,
  SET_PARAMETERS_ITEMS,
  SET_CATALOG_ITEMS,
  SET_BREADCRUMBS_LINKS,
  SET_PARENT_NAME,
  SET_PARENT_ID,
  SET_PAGE_NUMBER
} from '../actions/libraryEnumsAction';

const initialState = {
  actionTypes: [],
  actionGroups: [],
  catalogKinds: [],
  catalogTypes: [],
  conditionNames: [],
  dataGroupTypes: [],
  feedTypes: [],
  httpMethods: [],
  pluginTypes: [],
  sortDirections: [],
  stringMatching: [],
  valueFormats: [],
  visibilityGroups: [],
  scheduleTypes: [],
  propertyOptions: [],
  catalogItems: [],
  parametersItems: [],
  breadcrumbsLinks: null,
  parentName: '',
  parentId: '',
  pageNumber: 0
};

export default function libraryEnumsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTION_GROUPS:
      return { ...state, actionGroups: action.actionGroups };
    case SET_ACTION_TYPES:
      return { ...state, actionTypes: action.actionTypes };
    case SET_CATALOG_KINDS:
      return { ...state, catalogKinds: action.catalogKinds };
    case SET_CATALOG_TYPES:
      return { ...state, catalogTypes: action.catalogTypes };
    case SET_CONDITION_NAMES:
      return { ...state, conditionNames: action.conditionNames };
    case SET_DATA_GROUP_TYPES:
      return { ...state, dataGroupTypes: action.dataGroupTypes };
    case SET_FEED_TYPES:
      return { ...state, feedTypes: action.feedTypes };
    case SET_HTTP_METHODS:
      return { ...state, httpMethods: action.httpMethods };
    case SET_PLUGIN_TYPES:
      return { ...state, pluginTypes: action.pluginTypes };
    case SET_SORT_DIRECTIONS:
      return { ...state, sortDirections: action.sortDirections };
    case SET_STRING_MATCHING:
      return { ...state, stringMatching: action.stringMatching };
    case SET_VALUE_FORMATS:
      return { ...state, valueFormats: action.valueFormats };
    case SET_VISIBILITY_GROUPS:
      return { ...state, visibilityGroups: action.visibilityGroups };
    case SET_SCHEDULE_TYPES:
      return { ...state, scheduleTypes: action.scheduleTypes };
    case SET_PROPERTY_OPTIONS:
      return { ...state, propertyOptions: action.propertyOptions };
    case SET_CATALOG_ITEMS:
      return { ...state, catalogItems: action.catalogItems };
    case SET_PARAMETERS_ITEMS:
      return { ...state, parametersItems: action.parametersItems };
    case SET_BREADCRUMBS_LINKS:
      return { ...state, breadcrumbsLinks: action.breadcrumbsLinks };
    case SET_PARENT_NAME:
        return { ...state, parentName: action.parentName };
    case SET_PARENT_ID:
          return { ...state, parentId: action.parentId };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber
      };
    default:
      return state;
  }
};


//selectors
export const getBreadcrumbsLinks = (state) => get(state, ['libraryEnums', 'breadcrumbsLinks'], []);
export const getParentName = (state) => state.libraryEnums.parentName;
export const getParentId = (state) => state.libraryEnums.parentId;
export const getCatalogTypes = (state) => state.libraryEnums.catalogTypes;
export const getVisibilitesGroups = (state) => state.libraryEnums.visibilityGroups;