import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  AreaChart,
  Area,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import { Grid, Typography, Tooltip as MUITooltip, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Loader } from 'components';
import { getContentReport } from 'services/reportingService';
import { msToTime } from 'helpers/time';
import AlertService from 'services/alertService';
import NoResults from 'components/NoResults';
import SectionContainer from 'components/SectionContainer';

const CHART_MIN_HEIGHT = 320;

const useStyles = makeStyles({
  yLabel: {
    transform: 'rotate(-90deg)',
    textAlign: 'center',
    position: 'relative',
    left: '-42%',
    top: '50%',
  },
  xLabel: {
    width: '100%',
    textAlign: 'center',
    marginTop: -30,
  },
  chartContainer: {
    minHeight: CHART_MIN_HEIGHT,
  },
});

const ConsumptionTab = ({ id, duration, contentType, enqueueSnackbar }) => {
  const [dailyConsumption, setDailyConsumption] = useState([]);
  const [dailyTotalWatch, setDailyTotalWatch] = useState([]);
  const [heatMap, setHeatMap] = useState([]);
  const [dailyContentLike, setDailyContentLike] = useState([]);
  const [dailyNumberContentAccess, setDailyNumberContentAccess] = useState([]);
  const [aggregatedNumberContentAccess, setAggregatedNumberContentAccess] = useState([]);
  const [dailyContentQualityTime, setDailyContentQualityTime] = useState([]);
  const [aggregatedContentQualityTime, setAggregatedContentQualityTime] = useState([]);
  const [dailyPlaybackDevice, setDailyPlaybackDevice] = useState([]);
  const [aggregatedPlaybackDevice, setAggregatedPlaybackDevice] = useState([]);
  const [dailyPlaybackLocation, setDailyPlaybackLocation] = useState([]);
  const [aggregatedPlaybackLocation, setAggregatedPlaybackLocation] = useState([]);
  const [dailySingleLiveChatKPIS, setDailySingleLiveChatKPIS] = useState([]);
  const [aggregatedSingleLiveChatKPIS, setAggregatedSingleLiveChatKPIS] = useState([]);
  const [concurrentVisitorsCounter, setConcurrentVisitorsCounter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

  const chartColors = useSelector((state) => state.brandStyle.chartColors);

  const digestData = (data) =>
    data.dataItems?.map((e) => ({
      ...e,
      date: e.date.substr(5),
      quantity: e.data.reduce((q, d) => (q += d.quantity), 0),
    }));

  const getConsumptionData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ClientReports/Daily/ContentConsumption',
        contentId: id,
      });
      setDailyConsumption(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getTotalWatchData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ClientReports/Daily/TotalWatchMinutes',
        contentId: id,
      });
      setDailyTotalWatch(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getHeatMapData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ClientReports/Daily/ContentConsumptionHeatMap',
        contentId: id,
      });
      setHeatMap(fetchedData.dataItems);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getCommercialDailyContentLikeData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'CommercialMetrics/Daily/ContentLike',
        contentId: id,
      });
      setDailyContentLike(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getConsumptionDailyNumberContentAccessData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Daily/NumberOfContentAccess',
        contentId: id,
      });
      setDailyNumberContentAccess(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getConsumptionAggregatedNumberContentAccessData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Aggregated/NumberOfContentAccess',
        contentId: id,
      });
      const formattedData = formatAggregatedReportToBarData(digestData(fetchedData));
      setAggregatedNumberContentAccess(formattedData);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getConsumptionDailyContentQualityTime = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Daily/ContentQualityTime',
        contentId: id,
      });
      setDailyContentQualityTime(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getConsumptionAggregatedContentQualityTimeData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Aggregated/NumberOfContentAccess',
        contentId: id,
      });
      const formattedData = formatAggregatedReportToBarData(digestData(fetchedData));
      setAggregatedContentQualityTime(formattedData);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getDailyPlaybackDevice = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Daily/PlaybackDevice',
        contentId: id,
      });
      setDailyPlaybackDevice(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getAggregatedPlaybackDeviceData = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ConsumptionMetrics/Aggregated/PlaybackDevice',
        contentId: id,
      });
      const formattedData = formatAggregatedReportToBarData(digestData(fetchedData));
      setAggregatedPlaybackDevice(formattedData);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getDailyPlaybackLocation = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'GeoTaggingMetrics/Daily/ContentPlaybackLocation',
        contentId: id,
      });
      setDailyPlaybackLocation(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getAggregatedPlaybackDeviceLocation = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'GeoTaggingMetrics/Aggregated/ContentPlaybackLocation',
        contentId: id,
      });
      const formattedData = formatAggregatedReportToBarData(digestData(fetchedData));
      setAggregatedPlaybackLocation(formattedData);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getDailySignleLiveChatKPIS = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ContentCreationMetrics/Daily/SingleLiveChatKpis',
        contentId: id,
      });
      setDailySingleLiveChatKPIS(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getAggregatedSignleLiveChatKPIS = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ContentCreationMetrics/Aggregated/SingleLiveChatKpis',
        contentId: id,
      });
      const formattedData = formatAggregatedReportToBarData(digestData(fetchedData));
      setAggregatedSingleLiveChatKPIS(formattedData);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const getConcurrentVisitorsCounter = async () => {
    try {
      const fetchedData = await getContentReport({
        type: 'ActivityMetrics/ConcurrentVisitorsCounter',
        contentId: id,
      });
      setConcurrentVisitorsCounter(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    }
  };

  const formatAggregatedReportToBarData = (reportData) => {
    try {
      let barDataInFomat = [];
      reportData[0].data.forEach((reportItem) => {
        barDataInFomat.push({ name: reportItem.id, value: reportItem.quantity });
      });
      return barDataInFomat;
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const BarsNumberContentAccessData = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedNumberContentAccess.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
        )}
      </Bar>
    );
  }, [aggregatedNumberContentAccess]);

  const BarsNumberContentQualityTime = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedContentQualityTime.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
        )}
      </Bar>
    );
  }, [aggregatedContentQualityTime]);

  const BarsPlaybackDevice = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedPlaybackDevice.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
        )}
      </Bar>
    );
  }, [aggregatedPlaybackDevice]);

  const BarsPlaybackLocation = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedPlaybackLocation.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
        )}
      </Bar>
    );
  }, [aggregatedPlaybackLocation]);

  const BarsSingleLiveChatKPIS = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedSingleLiveChatKPIS.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
        )}
      </Bar>
    );
  }, [aggregatedSingleLiveChatKPIS]);

  useEffect(() => {
    setIsLoading(true);
    getConsumptionData()
      .then(() => getTotalWatchData())
      .then(() => getHeatMapData())
      .then(() => getHeatMapData())
      .then(() => getCommercialDailyContentLikeData())
      .then(() => getConsumptionDailyNumberContentAccessData())
      .then(() => getConsumptionAggregatedNumberContentAccessData())
      .then(() => getConsumptionDailyContentQualityTime())
      .then(() => getConsumptionAggregatedContentQualityTimeData())
      .then(() => getDailyPlaybackDevice())
      .then(() => getAggregatedPlaybackDeviceData())
      .then(() => getDailyPlaybackLocation())
      .then(() => getAggregatedPlaybackDeviceLocation())
      .then(() => getConcurrentVisitorsCounter())
      .then(() => { contentType === 'LiveChat' && getDailySignleLiveChatKPIS() })
      .then(() => { contentType === 'LiveChat' && getAggregatedSignleLiveChatKPIS() })
      .then(() => setIsLoading(false));
  }, []);

  const CustomTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={5} textAnchor="end" fill="#666" transform="rotate(270)">
        {payload.value}
      </text>
    </g>
  );

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Grid container spacing={4} justifyContent="flex-start">
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_CONTENT_CONSUMPTION_HELPERTEXT' })}
          </Typography>
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_NUMBER_OF_PLAYS' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyConsumption}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CONTENT_CONSUMPTION_HELPERTEXT' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_TOTAL_WATCH_HELPERTEXT' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_MINUTES_WATCHED' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyTotalWatch}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_TOTAL_WATCH_HELPERTEXT' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_CONTENT_LIKE' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_NUMBER_OF_LIKES' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyContentLike}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CONTENT_LIKE' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>

        {/* Number of content access Report starts */}
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_NUMBER_OF_CONTENT_ACCESS' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_NUMBER_OF_CONTENT_ACCESS' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyNumberContentAccess}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CONTENT_LIKE' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>

        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_NUMBER_OF_CONTENT_ACCESS' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <BarChart data={aggregatedNumberContentAccess}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {BarsNumberContentAccessData}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        {/* Number of content access Report ends */}

        {/* Number of content quality Report starts */}
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_CONTENT_QUALITY_TIME' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_CONTENT_QUALITY_TIME' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyContentQualityTime}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CONTENT_LIKE' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>

        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_AGGREGATED_CONTENT_QUALITY_TIME' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <BarChart data={aggregatedContentQualityTime}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {BarsNumberContentQualityTime}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        {/* Number of content quality Report ends */}

        {/* Playback device Report starts */}
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_PLAYBACK_DEVICE' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_PLAYBACK_DEVICE' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyPlaybackDevice}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_PLAYBACK_DEVICE' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>

        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_AGGREGATED_PLAYBACK_DEVICE' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <BarChart data={aggregatedPlaybackDevice}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {BarsPlaybackDevice}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        {/* Playback device Report ends */}

        {/* Playback location Report starts */}
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_PLAYBACK_LOCATION' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_PLAYBACK_LOCATION' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={dailyPlaybackLocation}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_PLAYBACK_LOCATION' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>

        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_AGGREGATED_PLAYBACK_LOCATION' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <BarChart data={aggregatedPlaybackLocation}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {BarsPlaybackLocation}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
        {/* Playback location Report ends */}

        {/* ConcurrentVisitorsCounter Report starts */}
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_CONCURRENT_VISITORS_COUNTER' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_UNIT_CONCURRENT_VISITORS_COUNTER' })}</div>
          <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
            <AreaChart
              data={concurrentVisitorsCounter}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis width={100} />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_CONCURRENT_VISITORS_COUNTER' })}
              />
            </AreaChart>
          </ResponsiveContainer>
          <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
        </Grid>
        {/* ConcurrentVisitorsCounter Report ends */}


        {/* Signle Live Chat Report starts */}
        {
          contentType === 'LiveChat' && (
            <>
              <Grid item sm={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_DAILY_SINGLE_LIVE_CHAT' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_SINGLE_LIVE_CHAT' })}</div>
                <ResponsiveContainer aspect={4.0 / 2.0} width="103%">
                  <AreaChart
                    data={dailySingleLiveChatKPIS}
                    margin={{
                      top: 25,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                    <YAxis width={100} />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Area
                      dataKey="quantity"
                      type="monotone"
                      fill={theme.palette?.primary?.main}
                      stroke={theme.palette?.primary?.dark}
                      name={lookUp({ key: 'CONSOLE_SINGLE_LIVE_CHAT' })}
                    />
                  </AreaChart>
                </ResponsiveContainer>
                <div className={classes.xLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
              </Grid>

              <Grid item sm={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_AGGREGATED_SINGLE_LIVE_CHAT' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
                  <BarChart data={aggregatedSingleLiveChatKPIS}>
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" />
                    <YAxis tickFormatter={tickFormatter} />
                    <Tooltip
                      cursor={{ fill: 'transparent' }}
                      separator={''}
                      formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
                    />
                    {BarsSingleLiveChatKPIS}
                  </BarChart>
                </ResponsiveContainer>
              </Grid>
            </>
          )
        }
        {/* Signle Live Chat Report ends */}



        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_CONTENT_CONSUMPTION_HEATMAP_HELPERTEXT' })}
          </Typography>
        </Grid>
        {heatMap &&
          heatMap.reduce((bool, e) => {
            bool = bool || !!e.data?.length;
            return bool;
          }, false) ? (
          <Grid container item xs={12} justifyContent="center">
            <div className={classes.yLabel}>{lookUp({ key: 'CONSOLE_DATE' })}</div>
            <Grid container item xs={12}>
              {heatMap.map((e, i) => (
                <div key={i} style={{ display: 'flex', width: '100%' }}>
                  <MUITooltip title={e.date}>
                    <div style={{ flex: 2, textAlign: 'right', paddingRight: '.5em' }}>
                      {e.date.substr(5)}
                    </div>
                  </MUITooltip>
                  {e.data?.[0]?.quantity?.map((q, key) => (
                    <MUITooltip key={key} title={`${Math.floor(q * 100) / 100 + '%'}`}>
                      <div
                        style={{
                          flex: 1,
                          margin: 1,
                          borderRadius: 3,
                          backgroundColor: `hsl(${160 - (q / 100) * 180}, ${q}%, ${90 - q / 2.5}%)`,
                        }}
                      >
                        &nbsp;
                      </div>
                    </MUITooltip>
                  ))}
                </div>
              ))}
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ flex: 2, textAlign: 'center' }}></div>
                {Array.from(Array(10).keys()).map((e, i) => (
                  <div
                    style={{
                      flex: 1,
                      textAlign: 'center',
                    }}
                  >
                    {msToTime(duration * (e + 1) * 0.1)
                      .split('.')[0]
                      .substr(3)}
                  </div>
                ))}
              </div>
              <div style={{ textAlign: 'center', width: '100%' }}>
                {lookUp({ key: 'CONSOLE_PERCENTAGE_WATCHED' })}
              </div>
              <Grid container item xs={12} justifyContent="flex-end">
                <Grid
                  item
                  xs={10}
                  container
                  justifyContent="space-between"
                  style={{
                    borderRadius: 3,
                    padding: '.25em',
                    marginTop: '1em',
                    height: '2em',
                    background:
                      'linear-gradient(90deg, rgba(230,230,230,1) 0%, rgba(193,217,191,1) 25%, rgba(210,217,140,1) 50%, rgba(230,140,76,1) 75%, rgba(255,0,85,1) 100%)',
                  }}
                >
                  <div>0%</div>
                  <div>50%</div>
                  <div>100%</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={10} lg={8} className={classes.chartContainer}>
            <NoResults title={lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' })} />
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
};

ConsumptionTab.propTypes = {
  id: PropTypes.string,
  duration: PropTypes.number,
  contentType: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ConsumptionTab);
