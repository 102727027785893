import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import withFileUpload from 'helpers/withFileS3Upload';
import { Link, IconButton, Tooltip, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FavouriteIcon, UnFavouriteIcon } from 'assets/icons/FavouriteIcons';
import {
  Add,
  PlayCircleFilled,
  Extension,
  QueueMusic,
  TextFields,
  Image,
  GetApp,
  Publish,
  FilterList,
  SwitchVideo,
  Clear,
} from '@material-ui/icons';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { CreatedOn, Lister, Toolbar, QueryBar, HoverMenu, Favs } from 'components';
import SubItems from './SubItems';
import IngestFileModal from './IngestModal';
import parametersService from 'services/parametersService';
import contentService from 'services/contentService';
import markerService from 'services/markerService';
import customersService from 'services/customersService';
import collectionsService from 'services/collectionsServiceV2';
import parametersAction from 'store/actions/parametersAction';
import imageProcessingService from 'services/imageProcessingService';
import PublishedComponent from 'assets/icons/PublishedComponent';
import UnPublishedComponent from 'assets/icons/UnPublishedComponent';
import contentAction from 'store/actions/contentAction';
import LogButton from 'components/Audit/LogButton';
import AlertService from 'services/alertService';
import uniformServices from 'services/uniformServices';
import TagCloud from './TagCloud';
import Grid from '@material-ui/core/Grid';
import AddButton from 'components/AddButton';
import DeleteButton from 'components/DeleteButton';
import CreateLiveStreamModal from './CreateLiveStreamModal';

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    marginBottom: theme.spacing(2),
  },
  filtersTagsContainer: {
    marginTop: theme.spacing(1),
  },
  deleteDialogHighlightedTitle: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    padding: theme.spacing(1),
  },
}));

const editorIcon = (type) => {
  switch (type) {
    case 'Image':
      return <Image />;
    case 'Video':
      return <PlayCircleFilled />;
    case 'Audio':
      return <QueueMusic />;
    case 'Remix':
      return <SwitchVideo />;
    case 'Pdf':
    case 'Text':
    case 'Word':
      return <TextFields />;
    default:
      return <Extension />;
  }
};

const ContentIndex = ({ enqueueSnackbar, upload }) => {
  const [search, setSearch] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const type = search.get('type');
  const classes = useStyles();

  const ownId = useSelector((state) => state.auth.user.id);
  const auth = useSelector((state) => state.auth);
  const selectedLocale =
    useSelector((s) => s.lexicons.selected) || localStorage.getItem('locale') || 'en-US';

  const titleLink = useSelector((state) => state.content.titleLink);
  const editablePlaylist = useSelector((state) => state.content.editablePlaylist);
  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);

  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [favs, setFavs] = useState([]);
  const [contentTypes, setContentTypes] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [images, setImages] = useState({});
  const [tagTypes, setTagTypes] = useState([]);
  const [selectedTagType, setSelectedTagType] = useState();
  const [showFilter, setShowFilter] = useState(+localStorage.getItem('showFilter') === 0 ? 0 : 1);
  const [ingestModalOpen, setIngestModalOpen] = useState(false);
  const [ingestToRemix, setIngestToRemix] = useState(null);
  const [selection, setSelection] = useState([]);
  const [hovered, setHovered] = useState();
  const [deletedIds, setDeletedIds] = useState([]);
  const [trigger, setTrigger] = useState(Symbol());
  const [debounce, setDebounce] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const [createLiveStreamModalOpen, setCreateLiveStreamModalOpen] = useState(false);

  const addToFavourites = (id) => {
    collectionsService
      .addCustomerRelation(ownId, 'FavContent', id)
      .then((addedFavourite) => uniformServices.get('Content', addedFavourite.targetId))
      .then((newFavourite) => setFavs((favs) => [...favs, { ...newFavourite.data }]))
      .catch((error) => {
        if (error.response.status === 409) {
          setTrigger(Symbol('add_fav_error'));
        }
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = (id) => {
    collectionsService
      .deleteCustomerRelation(ownId, 'FavContent', id)
      .then(() => setFavs((favs) => favs.filter((fav) => fav.id !== id)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const checkRole = () => {
    if (auth.user.role === 'Creator') {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: lookUp({ key: 'CONSOLE_ACCESS_ERROR' }),
      });
      return false;
    }
    return true;
  };

  const handleDelete = (id, originalTitle) => {
    setDeletedIds(id);
    setIsDeleting(true);

    if (checkRole()) {
      uniformServices
        .remove('Content', id)
        .then((e) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: originalTitle }),
          });
          setDebounce(clearTimeout(debounce));
          const delay = 500;
          const timeout = setTimeout(() => {
            setTrigger(Symbol('remove_content'));
          }, delay);
          setDebounce(timeout);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error: error,
            context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: originalTitle }),
          });
        })
        .finally(() => setIsDeleting(false));
    } else {
      setIsDeleting(false);
    }
  };

  const handleMassDelete = () => {
    const ids = [...selection];
    setDeletedIds(ids);
    setIsDeleting(true);

    if (checkRole()) {
      Promise.allSettled(ids.map((id) => uniformServices.remove('Content', id)))
        .then((e) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: 'CONSOLE_DELETE_ITEMS_SUCCESS_TEMPLATE', number: ids.length }),
          });
          setDebounce(clearTimeout(debounce));
          const delay = 500;
          const timeout = setTimeout(() => {
            setTrigger(Symbol('remove_contents'));
          }, delay);
          setDebounce(timeout);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error: error,
            context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', number: ids.length }),
          });
        })
        .finally(() => {
          setSelection([]);
          setIsDeleting(false);
        });
    } else {
      setSelection([]);
      setIsDeleting(false);
    }
  };

  const startRecording = (remixData) => {
    contentService
      .createRecordingContent(
        remixData.id,
        `${remixData.originalTitle} - Recording ${new Date(Date.now()).toLocaleString()}`,
        remixData.originalLanguage || defaultLocale.lang,
      )
      .then((res) => {
        const meetingId = res.data?.properties['Content:Process:SessionId'];
        navigate({
          pathname: `/content/content-record/${meetingId}/stream-recording`,
          search: new URLSearchParams({ parentId: remixData.id }).toString(),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          autoHideDuration: 50000,
          context: lookUp({ key: 'CONSOLE_START_RECORDING_ERROR' }),
        });
      });
  };

  const createLiveStream = (liveStreamData) => {
    contentService
      .createLiveStream(liveStreamData)
      .then((res) => {
        setCreateLiveStreamModalOpen(false);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          autoHideDuration: 50000,
          context: lookUp({ key: 'CONSOLE_CREATE_LIVE_STREAM_ERROR' }),
        });
      });
  };

  const toolbarButtons = [
    {
      label: lookUp({ key: 'CONSOLE_UPLOAD_BUTTON' }),
      startIcon: <Publish />,
      onClick: () => {},
    },
    {
      label: lookUp({ key: 'CONSOLE_EXPORT_BUTTON' }),
      startIcon: <GetApp />,
      onClick: () => {},
    },
    {
      label: lookUp({ key: 'CONSOLE_FILTERS_BUTTON' }),
      startIcon: <FilterList />,
      onClick: () => setShowFilter((o) => (o + 1) % 2),
      color: showFilter ? 'primary' : 'inherit',
    },
    {
      component: (
        <AddButton
          onAdd={type === 'LiveStream' ? () => setCreateLiveStreamModalOpen(true) : null}
          tooltipText={lookUp({ key: 'CONSOLE_CREATE_ASSET_HELPERTEXT' })}
        />
      ),
    },
  ];

  const selectToolbarButtons = [
    {
      component: (
        <DeleteButton
          label={lookUp({ key: 'CONSOLE_DELETE_BUTTON_TEMPLATE', number: selection.length })}
          onDelete={handleMassDelete}
          loading={isDeleting}
          disabled={isDeleting}
          size="small"
        />
      ),
    },
    {
      label: lookUp({ key: 'CONSOLE_EXPORT_BUTTON_TEMPLATE', number: selection.length }),
      startIcon: <GetApp />,
      onClick: () => {},
      variant: 'contained',
      color: 'secondary',
    },
    {
      label: lookUp({ key: 'CONSOLE_FILTERS_BUTTON' }),
      startIcon: <FilterList />,
      onClick: () => setShowFilter((o) => (o + 1) % 2),
      variant: showFilter ? 'contained' : 'text',
    },
  ];

  const tagButtons = [
    ...tagTypes.map((e) => ({
      label: lookUp({ key: `CONSOLE_MARKERTYPE_${e.replace('Tag', '')}` }),
      onClick: () => setSelectedTagType(selectedTagType !== e && e),
      variant: selectedTagType === e ? 'contained' : 'text',
    })),
    [...search.keys()].find((e) => !['q', 'type', 'ownerId', 'published', 'orgs'].includes(e)) && {
      label: lookUp({ key: 'CONSOLE_CLEAR_TAG_FILTERS' }),
      onClick: () => clearTagSearch(),
      endIcon: <HighlightOffIcon />,
      variant: 'outlined',
    },
  ].filter((e) => !!e);

  const makeTitleLink = (item, tLink) => {
    const contentType = item?.row?.type?.toLowerCase();
    const route =
      contentType === 'remix' || contentType === 'playlist'
        ? `${tLink.remix === 'metadata' ? 'edit' : 'editor'}`
        : `${tLink.content === 'metadata' ? 'edit' : 'editor'}`;
    return `/content/${item.id}/${route}`;
  };

  const columns = [
    {
      field: 'leftIcons',
      headerName: ' ',
      filterable: false,
      width: 150,
      renderCell: (item) => (
        <>
          {item.row.published ? <PublishedComponent /> : <UnPublishedComponent />}
          {item.row?.type !== 'Playlist' || editablePlaylist.toOpen ? (
            <Link to={`/content/${item.id}/editor`} component={RouterLink}>
              <IconButton size="small">{editorIcon(item.row.type)}</IconButton>
            </Link>
          ) : (
            <IconButton disabled={true} size="small">
              <Image opacity={0} />
            </IconButton>
          )}
          <img src={images[item.id]} />
          {['Remix'].includes(item.row.type) && <SubItems id={item.id} />}
        </>
      ),
    },
    {
      field: 'originalTitle',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      flex: 7,
      renderCell: (item) => (
        <Tooltip title={item.value}>
          <Link to={makeTitleLink(item, titleLink)} component={RouterLink}>
            {item.value}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'duration',
      headerName: lookUp({ key: 'CONSOLE_DURATION' }),
      description: lookUp({ key: 'CONSOLE_DURATION' }),
      isSortable: true,
      width: 100,
      valueFormatter: (item) =>
        item.value
          ? new Date(item.value).toISOString().slice(11, 19)
          : lookUp({ key: 'CONSOLE_NA' }),
    },
    {
      field: 'ownerId',
      headerName: lookUp({ key: 'CONSOLE_CREATEDBY' }),
      flex: 2,
      sortable: false,
      valueGetter: ({ value }) =>
        customersList.find((e) => e.customerId === value)?.username ||
        lookUp({ key: 'CONSOLE_NA' }),
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) =>
        hovered === item.id ? (
          <>
            <HoverMenu
              buttons={[
                item.row.type === 'Remix' && {
                  component: (
                    <IconButton
                      key="startRec"
                      onClick={() => startRecording(item.row)}
                      size="small"
                    >
                      <Tooltip title={lookUp({ key: 'CONSOLE_START_RECORDING_HELPERTEXT' })}>
                        <Add />
                      </Tooltip>
                    </IconButton>
                  ),
                },
                item.row.type === 'Remix' && {
                  component: (
                    <IconButton
                      key="openIngest"
                      onClick={() => {
                        setIngestModalOpen(true);
                        setIngestToRemix(item.row);
                      }}
                      size="small"
                    >
                      <Tooltip title={lookUp({ key: 'CONSOLE_INGEST_FILE_HELPERTEXT' })}>
                        <Publish />
                      </Tooltip>
                    </IconButton>
                  ),
                },
                {
                  component: favs.find((favourite) => favourite.id === item.id) ? (
                    <UnFavouriteIcon
                      key="unfav"
                      removeFavourite={() => removeFromFavourite(item.id)}
                    />
                  ) : (
                    <FavouriteIcon key="fav" addFavourite={() => addToFavourites(item.id)} />
                  ),
                },
                {
                  component: <LogButton key="log" type="Content" id={item.id} />,
                },
                {
                  component: (
                    <DeleteButton
                      onDelete={() => handleDelete(item.id, item.row.originalTitle)}
                      dialogContent={
                        item.row?.originalTitle ? (
                          <Box component="span" className={classes.deleteDialogHighlightedTitle}>
                            {item.row.originalTitle}
                          </Box>
                        ) : null
                      }
                      variant="icon"
                    />
                  ),
                },
              ].filter((e) => !!e)}
            />
            <CreatedOn date={item.value} />
          </>
        ) : (
          <CreatedOn date={item.value} />
        ),
    },
  ].map((e) => ({ ...e, width: e.width || 40 }));

  const filters = [
    {
      label: lookUp({ key: 'CONSOLE_CONTENT_TYPE' }),
      key: 'type',
      change: (value) => {
        setParam('type', value);
      },
      options: contentTypes
        .map((value) => ({
          label: lookUp({ key: `CONSOLE_${value}` }),
          value,
        }))
        // sort options in asc order
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    },
    {
      label: lookUp({ key: 'CONSOLE_OWNER' }),
      key: 'ownerId',
      dynamic: true,
      getOptions: (req) => customersService.search(req),
      params: { query: 'query', id: 'customerId', label: 'username' },
      onSelect: (value) => setParam('ownerId', value?.id || undefined),
    },
    {
      label: lookUp({ key: 'CONSOLE_ORGANIZATIONS' }),
      key: 'orgs',
      dynamic: true,
      getOptions: (req) => customersService.search({ ...req, type: 'Organization' }),
      params: { query: 'query', id: 'customerId', label: 'username' },
      onSelect: (value) => setParam('orgs', value?.id || undefined),
    },
    {
      label: lookUp({ key: 'CONSOLE_STATUS' }),
      key: 'published',
      options: [
        { label: lookUp({ key: `CONSOLE_PUBLISHED` }), value: 'true' },
        { label: lookUp({ key: `CONSOLE_UNPUBLISHED` }), value: 'false' },
      ],
      change: (value) => {
        setParam('published', value);
      },
    },
  ];

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined) delete searchObj[key];
    setSearch(searchObj);
  };

  const clearTagSearch = () => {
    const tagKeys = [...search.keys()].filter(
      (e) => !['q', 'type', 'ownerId', 'published', 'orgs'].includes(e),
    );
    const searchObj = { ...Object.fromEntries(search.entries()) };
    tagKeys.forEach((e) => delete searchObj[e]);
    setSearch(searchObj);
  };

  const getTypeEnum = () => {
    contentService
      .getTypeEnums()
      .then((types) => {
        setContentTypes(types.map((e) => Object.keys(e)?.[0]));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONTENT_ENUMS' }),
          }),
        });
      });
  };

  const getFav = async (id) => {
    let fav;
    try {
      fav = await uniformServices.get('Content', id);
    } catch (error) {}
    return fav || null;
  };

  const getCustomersList = (content) => {
    const ownerIdsList = [
      ...new Set(
        [...content, ...favs].reduce((acc, row) => {
          if (!!row.ownerId) {
            acc.push(row.ownerId);
          }
          return acc;
        }, []),
      ),
    ];
    return Promise.allSettled(ownerIdsList.map((id) => uniformServices.get('Customer', id)));
  };

  const getImages = (content) => {
    if (!content?.length) return;
    const sqrImages = content
      .reduce((acc, curr) => {
        const sqrImg = curr.assets?.find((e) => e.type === 'Image' && e.subType === 'Square');
        if (!!sqrImg) acc.push({ id: curr.id, publicUrl: sqrImg.publicUrl });
        return acc;
      }, [])
      .filter((e) => !!e);

    sqrImages.forEach((e) => {
      const { id, publicUrl } = e;
      if (!id || !publicUrl) return;
      const processedUrl = imageProcessingService.processedImageUrl(publicUrl, {
        r1f: 'h',
        r1h: 50,
        c: 'Center',
        cw: 50,
      });
      setImages((o) => ({ ...o, [id]: processedUrl }));
    });
  };

  useEffect(() => {
    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) getDefaultLocaleLang();
  }, [defaultLocale]);

  useEffect(() => {
    const getTitleLinksSettings = async () => {
      try {
        const contentTitleLinkSettings = await parametersService.getByKey(
          'Console:Content:Open:Content',
        );
        const remixTitleLinkSettings = await parametersService.getByKey(
          'Console:Content:Open:Remix',
        );
        const cntLinkType = contentTitleLinkSettings.value?.toLowerCase();
        const rmxLinkType = remixTitleLinkSettings.value?.toLowerCase();
        const validValues = ['metadata', 'editor'];
        if (validValues.includes(cntLinkType) && validValues.includes(rmxLinkType)) {
          dispatch(
            contentAction.setTitleLink({
              content: cntLinkType,
              remix: rmxLinkType,
              paramChecked: true,
            }),
          );
        } else {
          dispatch(contentAction.setTitleLink({ ...titleLink, paramChecked: true }));
        }
      } catch (error) {
        dispatch(contentAction.setTitleLink({ ...titleLink, paramChecked: true }));
      }
    };

    if (!titleLink.paramChecked) getTitleLinksSettings();
  }, [titleLink]);

  useEffect(() => {
    const getPlaylistEditorOpen = async () => {
      try {
        const response = await parametersService.getByKey('Console:Playlist:Editor');
        const toOpen = response?.value?.toLowerCase
          ? response.value.toLowerCase() === 'true'
          : false;
        dispatch(contentAction.setEditablePlaylist({ toOpen, paramChecked: true }));
      } catch (error) {
        dispatch(contentAction.setEditablePlaylist({ ...editablePlaylist, paramChecked: true }));
      }
    };

    if (!editablePlaylist.paramChecked) getPlaylistEditorOpen();
  }, [editablePlaylist.paramChecked]);

  useEffect(() => {
    const getFavourites = () => {
      collectionsService
        .getCustomerRelation(ownId, 'FavContent', 'List')
        .then((favouriteItems) =>
          Promise.allSettled(favouriteItems.pageContent.map((e) => getFav(e.targetId))),
        )
        .then((favourites) => {
          const successFavs = favourites
            .filter((e) => e.status === 'fulfilled')
            .map((e) => e.value?.data);
          setFavs(successFavs.filter((fav) => !!fav && (!type || fav.type === type)));
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_FAVOURITES' }),
            }),
          });
        });
    };
    getFavourites();
  }, [type]);

  const getData = () => {
    setLoading(true);
    uniformServices
      .search('Content', search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res?.totalCount || 0);
        setItems(res?.data.filter((item) => !deletedIds?.includes(item.id)) || []);
        return res?.data;
      })
      .then((items) => {
        return getCustomersList(items);
      })
      .then((customers) =>
        setCustomersList(
          customers.reduce((acc, item) => {
            if (item.status === 'fulfilled') {
              acc.push(item.value?.data);
            }
            return acc;
          }, []),
        ),
      )
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONTENT' }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTags = () => {
    return markerService.searchGlobalTags(selectedTagType).then((res) =>
      res
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
        .map((e) => ({
          searchQueryParam: e.searchQueryParam,
          value: e.displayName,
          count: e.occurrence,
        })),
    );
  };

  const setTagParam = (e) => {
    const [key, value] = e.searchQueryParam.split('=');
    setParam(key, value);
  };

  useEffect(() => {
    getTypeEnum();
  }, []);

  useEffect(() => {
    getImages(items);
  }, [items]);

  useEffect(() => {
    getData();
  }, [search, trigger]);

  useEffect(() => {
    markerService
      .getTags()
      .then((types) => setTagTypes(types))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_CANT_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TAG_TYPES' }),
          }),
        });
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('showFilter', showFilter);
  }, [showFilter]);

  return (
    <Box flex={1} xs={12}>
      {titleLink.paramChecked ? (
        <>
          <Grid className={classes.filtersContainer} container spacing={1}>
            {Boolean(favs?.length) && (
              <Grid item container xs={12}>
                <Favs
                  columns={columns}
                  rows={favs || []}
                  dataGridProps={{
                    headerHeight: 0,
                    onRowEnter: (params) => setHovered(params.id),
                    onRowLeave: () => setHovered(null),
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Toolbar
                queryBy="q"
                buttons={selection.length ? selectToolbarButtons : toolbarButtons}
                triggerSearch={() => setTrigger(Symbol('refresh'))}
              />
            </Grid>
            {Boolean(showFilter) && (
              <Grid item xs={12}>
                <QueryBar
                  hideSearch
                  queryKey="q"
                  queryOptions={['q', 'referenceId', 'externalId']}
                  filters={filters}
                />
                <Toolbar hideSearch buttons={tagButtons} />
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Lister
              loading={loading}
              columns={columns}
              rows={items || []}
              totalCount={totalCount}
              selectionModel={selection}
              setSelectionModel={setSelection}
              dataGridProps={{
                onRowEnter: (params) => setHovered(params.id),
                onRowLeave: () => setHovered(null),
              }}
            />
          </Grid>
          <Grid item container sm={12} justifyContent="flex-end">
            <AddButton tooltipText={lookUp({ key: 'CONSOLE_CREATE_ASSET_HELPERTEXT' })} />
          </Grid>
          {!!selectedTagType && (
            <Dialog
              open={!!selectedTagType}
              maxWidth={'md'}
              fullWidth
              onClose={() => setSelectedTagType(null)}
            >
              <DialogTitle>
                {lookUp({ key: `CONSOLE_MARKERTYPE_${selectedTagType.replace('Tag', '')}` })}
                <IconButton
                  style={{ position: 'absolute', right: 0, top: 0 }}
                  aria-label="close"
                  onClick={() => setSelectedTagType(null)}
                >
                  <Clear />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TagCloud getTags={getTags} onSelect={setTagParam} />
              </DialogContent>
            </Dialog>
          )}
          {ingestModalOpen && (
            <IngestFileModal
              model={{
                id: '',
                customerId: '',
                productId: '',
                comment: '',
                createdDate: '',
                notifyCustomer: true,
                status: '',
                emailAddress: '',
                rewardsPoint: '',
                item: '',
                price: 0,
                localizations: {},
              }}
              close={() => setIngestModalOpen(false)}
              reload={() => setTrigger(Symbol('add_content'))}
              parentId={ingestToRemix.id}
              parentContentType="Remix"
              parentLocale={ingestToRemix.originalLanguage}
              upload={upload}
            />
          )}
          {createLiveStreamModalOpen && (
            <CreateLiveStreamModal
              locale={defaultLocale}
              submit={createLiveStream}
              close={() => setCreateLiveStreamModalOpen(false)}
            />
          )}
        </>
      ) : null}
    </Box>
  );
};

export default withFileUpload(withSnackbar(ContentIndex));
