import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { PlayArrow, Clear } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  ddList : {
    background: '#dcdcdc',
    padding: theme.spacing(5, 4, 4, 4),
    width: '100%',
  },
  ddElem: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(0, 0, 1, 0),
  },
  elemOrder: {
    width: '50px',
    textAlign: 'center',
  },
  decorVideoBox: {
    width: '80px',
    height: '45px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    margin: theme.spacing(0, 2, 0, 0),
    padding: 0,
  },
  playArrow: {
    color: 'white',
  },
  modalButtons: {
    marginTop: theme.spacing(2),
  },
  confirmButton: {
    marginRight: theme.spacing(2),
  },
  mainTitleBox: {
    width: 'calc(100% - 130px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainTitleText: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  mainLabel: {
    fontStyle: 'italic',
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#fffaf0' : '#fffafa',
  ...draggableStyle
});


function TrackOrderSelector({
  isOpen,
  contentType,
  childContents,
  tracks,
  close,
  confirm,
}) {

  const classes = useStyles();

  const [items, setItems] = useState(contentType === 'Remix' ? tracks : childContents);
  const [mainId, setMainId] = useState(items.map(e => e.contentId)[0]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const itemsCopy = [...items];
    const [removed] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, removed);
    setItems(itemsCopy);
    if (contentType === 'Remix') setMainId(itemsCopy[0]?.contentId)
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth disableScrollLock>
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Typography style={{ fontSize: 'large' }}>
            {lookUp({ key: 'CONSOLE_CHANGE_TRACK_ORDER' })}&nbsp;
            {contentType === 'Remix' && `(${lookUp({ key: 'CONSOLE_SELECT_MAIN_TRACK_TITLE' })})`}
          </Typography>
          <IconButton onClick={close} size="small">
            <Clear />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {items && items.length
          ? (
            <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(droppableProvided) => (
                    <section
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                      className={classes.ddList}
                    >
                      {items.map((track, index) => (
                        <Draggable key={track.contentId} draggableId={track.contentId} index={index}>
                          {(draggableProvided, draggableSnapshot) => (
                            <article
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              className={classes.ddElem}
                              style={getItemStyle(
                                draggableSnapshot.isDragging,
                                draggableProvided.draggableProps.style
                              )}
                            >
                              <Typography className={classes.elemOrder} variant="h4">{`${index + 1}.`}</Typography>
                              <Box className={classes.decorVideoBox}>
                                <PlayArrow className={classes.playArrow}/>
                              </Box>
                              {(contentType === 'Remix' && index === 0)
                                ? <div className={classes.mainTitleBox}>
                                    <Typography className={classes.mainTitleText} variant="body2">{track.originalTitle}</Typography>
                                    <span className={classes.mainLabel}>Main</span>
                                  </div>
                                : <Typography variant="body2">{track.originalTitle}</Typography>
                              }
                            </article>
                          )}
                        </Draggable>
                      ))}
                      {droppableProvided.placeholder}
                    </section>
                  )}
                </Droppable>
              </DragDropContext>

              <Grid xs={12} container justifyContent="flex-end" className={classes.modalButtons}>
                <Grid item >
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => confirm({ items, closeModal: close, mainId })}
                    className={classes.confirmButton}
                  >
                    {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
                  </Button>

                  <Button
                    size="small"
                    color="primary"
                    onClick={close}
                  >
                    {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : null
         }
      </DialogContent>
    </Dialog>
  );
};

export default TrackOrderSelector;
