import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Grid,
  CssBaseline,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
  Paper,
  FormControl,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { setLastRoute, setLoadingStatus } from 'store/actions/authAction';
import { forgotPassword, signIn } from 'services/customersService';
import { withSnackbar } from 'notistack';
import { version } from '../../package.json';
import AlertService from 'services/alertService';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  backgroundDiv: {
    minWidth: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
  },
  root: {
    position: 'absolute',
    maxWidth: 520,
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  wrapper: {
    padding: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: 20,
  },
  version: {
    fontSize: '100%',
    fontStyle: 'italic',
  }
}));

function SignIn(props) {
  const { enqueueSnackbar, reset } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const lastRoute = useSelector((state) => state.auth.lastRoute);
  const images = useSelector((state) => state.brandStyle.images);

  const [state, setState] = useState({
    email: '',
    password: '',
    passwordVisible: false,
    forgotPassword: reset || false,
  });

  const handleChange = (prop) => (event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (isLoading) return;
    const { email, password } = state;
    dispatch(setLoadingStatus(true));
    signIn({ provider: 'password', userId: email, token: password }, dispatch)
      .then(() => {
        navigate(lastRoute !== '/sign-in' && lastRoute || '/');
        dispatch(setLastRoute(lastRoute));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(() => {
        dispatch(setLoadingStatus(false));
      });
  };

  const resetPassword = async () => {
    if (!state.forgotPassword) {
      setState({ ...state, ['forgotPassword']: !state.forgotPassword });
      navigate('/reset-password');
      return;
    }
    const { email } = state;
    forgotPassword(email)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_EMAIL_SENT_MESSAGE' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  };

  useEffect(() => {
    if (Object.keys(user)?.length > 0) navigate('/dashboard');
  }, []);

  return (
    <div className={classes.backgroundDiv}>
      <Helmet
        title={lookUp({
          key: 'CONSOLE_SIGN_IN_PAGE_TITLE',
          page: lookUp({ key: 'CONSOLE_SIGN_IN' }),
        })}
      />
      {images.backdrop &&
        <img src={images.backdrop} style={{ width: '100vw', height: '100vh' }} />
      }
      <div className={classes.root}>
        <CssBaseline />
        <Paper className={classes.wrapper}>
          {images.primaryLogo ? (
              <div className={classes.imageWrapper}>
                <img src={images.primaryLogo} height="80" />
              </div>
            ) : (
              <Typography variant="h6" className={classes.imageWrapper}>
                {lookUp({ key: 'CONSOLE_ENT360_DEFAULT_HEADING', defaultString: 'ENT.360'})}
              </Typography>
            )
          }
          {isLoading ? (
            <Grid item align="center">
              <CircularProgress color={'primary'} />
            </Grid>
          ) : (
            <Formik
              initialValues={{
                email: '',
                password: '',
                submit: false,
              }}
              onSubmit={state.forgotPassword ? resetPassword : handleSubmit}
            >
              {({ errors, handleBlur, handleSubmit, isSubmitting, touched, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert mt={2} mb={1} severity="warning">
                      {errors.submit}
                    </Alert>
                  )}
                  <TextField
                    className={classes.marginBottom}
                    type="email"
                    name="email"
                    label={lookUp({ key: 'CONSOLE_EMAIL' })}
                    error={Boolean(touched.email && errors.email)}
                    id="email"
                    fullWidth
                    helperText={touched.email && errors.email}
                    value={state.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur}
                    my={2}
                  />
                  <FormControl
                    style={{ width: '100%', display: state.forgotPassword ? 'none' : '' }}
                  >
                    <TextField
                      id="standard-password"
                      className={classes.marginBottom}
                      label={lookUp({ key: 'CONSOLE_PASSWORD' })}
                      type={state.showPassword ? 'text' : 'password'}
                      value={state.password}
                      onChange={handleChange('password')}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onKeyUp={(e) => handleKeyUp(e)}
                      fullWidth
                      onBlur={handleBlur}
                      my={2}
                      InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {state.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>

                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    className={classes.marginBottom}
                    style={{ display: state.forgotPassword ? 'none' : '' }}
                  >
                    {lookUp({ key: 'CONSOLE_SIGN_IN_BUTTON' }).toUpperCase()}
                  </Button>
                  <ThemedButton
                    fullWidth
                    variant={state.forgotPassword ? 'contained' : 'text'}
                    className={classes.marginBottom}
                    color={state.forgotPassword ? 'primary' : 'default'}
                    onClick={resetPassword}
                  >
                    {lookUp({ key: 'CONSOLE_RESET_PASSWORD_BUTTON' }).toUpperCase()}
                  </ThemedButton>
                  {state.forgotPassword && (
                    <Button
                      fullWidth
                      onClick={() => {
                        setState({ ...state, ['forgotPassword']: !state.forgotPassword });
                        navigate('/sign-in');
                      }}
                    >
                      {lookUp({ key: 'CONSOLE_BACK_BUTTON' }).toUpperCase()}
                    </Button>
                  )}
                  <Typography
                    color={'textSecondary'}
                    align="right"
                    className={classes.version}
                  >
                    {lookUp({ key: 'CONSOLE_APP_VERSION_TEMPLATE', version })}
                  </Typography>
                </form>
              )}
            </Formik>
          )}
        </Paper>
      </div>
    </div>
  );
}


export default withSnackbar(SignIn);
