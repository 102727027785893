import { SET_STORE_TYPES } from '../actions/skuAction';

const initialState = {
  storeTypes: []
};

const skuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STORE_TYPES:
      return {
        ...state,
        storeTypes: action.storeTypes
      };
    default:
      return state;
  }
};

export default skuReducer;
