import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Draggable } from 'react-beautiful-dnd';
import { CenteredBlock, Loader } from 'components';
import ContentCard from './ContentCard';
import ArtistCard from './ArtistCard';
import SkuCard from './SkuCard';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  overflow: {
    maxHeight: `calc(70vh - ${theme.spacing(8)}px)`,
    overflowY: 'auto'
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  border: isDragging ? '2px solid #00acc1' : '',
  borderRadius: isDragging ? '6px' : '',
  background: isDragging ? '#ffffff' : '',
  ...draggableStyle
});

const itemPropsMappingByRelation = {
  content: {
    name: 'originalTitle',
    id: 'id',
  },
  customer: {
    name: 'username',
    id: 'customerId',
  },
  sku: {
    name: 'name',
    id: 'id',
  },
};

const ContentCardsContainer = ({
  items,
  title,
  onDelete,
  onAdd,
  onDragStyle,
  isContentOptionsLoading,
  decorationModal,
  handleChange,
  handlePropertiesChange,
  dataSource,
}) => {
  const classes = useStyles();
  const [contents, setContents] = useState([]);

  useEffect(() => {
    if (Array.isArray(items) && items.length > 0) {
      setContents(items);
    }
  }, [items]);

  // gets an item id based on the current relation
  // FIXME - move to service utils for reuse
  const getItemId = (item, relation = dataSource) => {
    let normalizedRelation = relation.toLowerCase?.();

    return get(item, [itemPropsMappingByRelation[normalizedRelation]?.id], item.id);
  };

  const getCardBySelectedRelation = (item, relation = dataSource) => {
    let normalizedRelation = relation.toLowerCase?.();
    const cardProps = {
      ...(typeof onAdd === 'function' && { onAdd }),
      ...(typeof onDelete === 'function' && { onDelete }),
      ...(typeof decorationModal === 'function' && { decorationModal }),
      handlePropertiesChange,
      handleChange,
      item,
    };

    switch (normalizedRelation) {
      case 'customer':
        return <ArtistCard {...cardProps} />;
      case 'sku':
        return <SkuCard {...cardProps} />;
      case 'content':
      default:
        return <ContentCard {...cardProps} />
    }
  };

  return (
    <Box
      className={classes.root}
      style={onDragStyle}
    >
      {contents.length < 1 ? (
        <CenteredBlock height="80vh">
          {isContentOptionsLoading ? (
            <Loader inline />
          ) : (
            <>
              <Typography variant="subtitle1">{lookUp({ key: 'CONSOLE_NO_CONTENT' })}</Typography>
              <Typography variant="caption">
                {lookUp({ key: 'CONSOLE_ADD_CONTENT_HELPERTEXT' })}
              </Typography>
            </>
          )}
        </CenteredBlock>
      ) : (
        <Grid
          direction="column"
          wrap="nowrap"
          spacing={2}
          className={classes.overflow}
          container
        >
          {isContentOptionsLoading ? (
            <Loader height="80vh" />
          ) : (
            contents.map?.((item, index) => {
              let itemId = getItemId(item);

              return (
                <Draggable
                  key={itemId}
                  draggableId={itemId}
                  index={index}
                  isDragDisabled={!handlePropertiesChange}
                >
                  {(provided, snapshot) => (
                    <Grid
                      item
                      key={index}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    >
                      {getCardBySelectedRelation(item)}
                    </Grid>
                  )}
                </Draggable>
              );
            })
          )}
        </Grid>
      )}
    </Box>
  );
};

ContentCardsContainer.propTypes = {
  title: PropTypes.string,
  onDelete: PropTypes.func,
  onDragStyle: PropTypes.object,
  onAdd: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  isContentOptionsLoading: PropTypes.bool,
};

export default ContentCardsContainer;
