import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, func, object } from 'prop-types';

import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  makeStyles,
  Divider
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const SpecificDesigns = (props) => {
  
  const classes = useStyles();
  const { handleChange, data } = props;

  const [items, setItems] = useState(data);

  const handleItemsChange = (index, key, value) =>
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));

  const handleItemsRemove = (index) => setItems((prev) => prev.filter((p, i) => i !== index));

  const handleItemsAdd = () =>
    setItems((prev) => [
      ...prev,
      {
        name: '',
        settings: []
      }
    ]);

  useEffect(() => {
    handleChange(items);
  }, [items]);

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.marginX}>
        <Button  color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
         {lookUp({ key: 'CONSOLE_DESIGN_GROUP_BUTTON'})}
        </Button>
      </Grid>
      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Typography gutterBottom>Design {index + 1}</Typography>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={11}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={12}>
                     <Grid item xs={6}>
                      <TextField
                        label={lookUp({ key: 'CONSOLE_NAME' })}
                        required
                        className={classes.textField}
                        value={item.name || ''}
                        onChange={(e) => handleItemsChange(index, 'name', e.target.value)}
                      />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Settings
                        type={'design'}
                        title={lookUp({ key: 'CONSOLE_SETTINGS'})}
                        data={item.settings}
                        handleChange={(data) => handleItemsChange(index, 'settings', data)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.textCenter}>
                  <IconButton onClick={() => handleItemsRemove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider className={classes.marginX} />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

SpecificDesigns.propTypes = {
  data: arrayOf(object).isRequired,
  handleChange: func.isRequired
};

export default SpecificDesigns;
