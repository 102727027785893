import {
  TOGGLE_MOBILE_SIDEBAR,
  OPEN_MOBILE_SIDEBAR,
  CLOSE_MOBILE_SIDEBAR,
  TOGGLE_DESKTOP_SIDEBAR,
  OPEN_DESKTOP_SIDEBAR,
  CLOSE_DESKTOP_SIDEBAR,
  SET_COLLAPSE_SIDEBAR,
  CHANGE_SIDEBAR_ITEM_HIGHLIGHT,
  BLOCK_NAVIGATION,
  ALLOW_NAVIGATION,
  SET_PAGE_TITLE,
  CHANGE_SIDEBAR_OPEN_SUBMENUS,
  RETURN_TO_LMS_MENU_OPTION,
} from '../actions/navigationAction';

const initialState = {
  isSidebarMobileOpen: false,
  isSidebarDesktopOpen: !(localStorage.getItem('isSidebarDesktopCollapsed') === 'true'),
  isSidebarCollapsed: localStorage.getItem('isSidebarCollapsed') === 'true',
  highlightedSidebarItem: '',
  pageTitle: '',
  isNavigationBlocked: false,
  openSidebarSubs: {},
  lmsReturnURL: '',
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MOBILE_SIDEBAR:
      return {
        ...state,
        isSidebarMobileOpen: !state.isSidebarMobileOpen
      };
    case OPEN_MOBILE_SIDEBAR:
      return {
        ...state,
        isSidebarMobileOpen: true
      };
    case CLOSE_MOBILE_SIDEBAR:
      return {
        ...state,
        isSidebarMobileOpen: false
      };
    case TOGGLE_DESKTOP_SIDEBAR:
      return {
        ...state,
        isSidebarDesktopOpen: !state.isSidebarDesktopOpen
      };
    case OPEN_DESKTOP_SIDEBAR:
      return {
        ...state,
        isSidebarDesktopOpen: true
      };
    case CLOSE_DESKTOP_SIDEBAR:
      return {
        ...state,
        isSidebarDesktopOpen: false
      };
    case SET_COLLAPSE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.collapsed
      };
    case CHANGE_SIDEBAR_ITEM_HIGHLIGHT:
      return {
        ...state,
        highlightedSidebarItem: action.highlightedSidebarItem
      };
    case BLOCK_NAVIGATION:
      return {
        ...state,
        isNavigationBlocked: true
      };
    case ALLOW_NAVIGATION:
      return {
        ...state,
        isNavigationBlocked: false
      };
    case SET_PAGE_TITLE:
      return {
        ...state,
        pageTitle: action.pageTitle
      };
    case CHANGE_SIDEBAR_OPEN_SUBMENUS:
      return {
        ...state,
        openSidebarSubs: action.openSidebarSubs
      };
    case RETURN_TO_LMS_MENU_OPTION:
      return {
        ...state,
        lmsReturnURL: action.lmsReturnURL
      };
    default:
      return state;
  }
}
