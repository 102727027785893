import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ColorPicker from 'components/ColorPicker';
import SectionContainer from 'components/SectionContainer';

const FORM_WRAPPER_SPACING = 4;
const DEFAULT_COLOR_HEX = '#000000';

// FIXME we should have a calback handler for the theme
const DesignProps = ({
  darkDesign,
  lightDesign,
  handleDarkChange = () => {},
  handleLightChange = () => {},
}) => {
  const [items, setItems] = useState(darkDesign);
  const [lightItems, setLightItems] = useState(lightDesign);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
    } else {
      handleLightChange(lightItems);
      handleDarkChange(items);
    }
  }, [lightItems, items]);

  const handleColorPropChange = (key, value) => {
    setItems((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLightColor = (key, value) => {
    setLightItems((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <SectionContainer flex={1}>
      <Grid container direction="column" xs={12} md={FORM_WRAPPER_SPACING} spacing={4}>
        {lightItems && (
          <>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_LIGHT_MODE_TITLE' })}
              </Typography>
            </Grid>
            {Object.entries(lightItems).map(([key, lightColor]) => (
              <Grid item key={key}>
                <FormControl fullWidth>
                  <ColorPicker
                    label={lookUp({ key: `CONSOLE_${key}` })}
                    handleChange={(lightColor) => handleLightColor(key, lightColor)}
                    color={lightColor || DEFAULT_COLOR_HEX}
                  />
                  <FormHelperText>{lookUp({ key: `CONSOLE_${key}_HELPERTEXT` })}</FormHelperText>
                </FormControl>
              </Grid>
            ))}
          </>
        )}

        {items && (
          <>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_DARK_MODE_TITLE' })}
              </Typography>
            </Grid>
            {Object.entries(items).map(([key, propColor]) => (
              <Grid item key={key}>
                <FormControl fullWidth>
                  <ColorPicker
                    label={lookUp({ key: `CONSOLE_${key}` })}
                    handleChange={(propColor) => handleColorPropChange(key, propColor)}
                    color={propColor || DEFAULT_COLOR_HEX}
                  />
                  <FormHelperText>{lookUp({ key: `CONSOLE_${key}_HELPERTEXT` })}</FormHelperText>
                </FormControl>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </SectionContainer>
  );
};

DesignProps.propTypes = {
  darkDesign: PropTypes.object,
  lightDesign: PropTypes.object,
  handleDarkChange: PropTypes.func,
  handleLightChange: PropTypes.func,
};

export default DesignProps;
