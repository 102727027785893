import React from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import { arrayOf, shape, string, func, object, bool } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { CenteredBlock, Loader } from 'components';
import ContentCard from './ContentCard';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(0),
    height: '100%',
    border: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  overflow: {
    maxHeight: `calc(70vh - ${theme.spacing(8)}px)`,
    overflowY: 'scroll'
  }
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? 'lightgreen' : '',
  ...draggableStyle
});

function ContentCardsContainer(props) {
  const classes = useStyles();
  const { items, title, onDelete, onAdd, onDragStyle, isContentOptionsLoading } = props;
  return (
    <Paper className={classes.paper} style={onDragStyle}>
      {title && (
        <Typography variant={'subtitle1'} gutterBottom>
          {title}
        </Typography>
      )}
      {items.length === 0 ? (
        <CenteredBlock height={'20vh'} style={{ flexGrow: 1 }}>
          {isContentOptionsLoading ? (
            <Loader inline />
          ) : (
            <>
              <Typography variant={'subtitle1'}>{lookUp({ key: 'CONSOLE_NO_CONTENT'})}</Typography>
              <Typography variant={'caption'}>{lookUp({ key: 'CONSOLE_ADD_CONTENT_HELPERTEXT'})}</Typography>
            </>
          )}
        </CenteredBlock>
      ) : (
        <Grid
          container
          direction={'column'}
          wrap={'nowrap'}
          spacing={2}
          className={classes.overflow}
        >
          {isContentOptionsLoading ? (
            <Loader height={'80vh'} />
          ) : (
            items.map((item, index) => (
              <Draggable key={`${item.id}-${index}`} draggableId={`${item.id}-${index}`} index={index}>
                {(provided, snapshot) => (
                  <Grid
                    item
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                  >
                    <ContentCard
                      items={items}
                      item={item}
                      index={index}
                      {...(!!onAdd && { onAdd })}
                      {...(!!onDelete && { onDelete })}
                    />
                  </Grid>
                )}
              </Draggable>
            ))
          )}
        </Grid>
      )}
    </Paper>
  );
}

ContentCardsContainer.propTypes = {
  title: string,
  onDelete: PropTypes.func,
  onDragStyle: object,
  onAdd: func,
  items: arrayOf(shape({})),
  isContentOptionsLoading: bool
};

export default ContentCardsContainer;
