// Theme
export const THEME_SET = 'THEME_SET';
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO',
};

export const sidebarWidth = {
  open: 260,
  closed: 80,
}

// Video Editor Rekognition job types
export const EJobType = {
  NONE: -1,
  TRANSCRIPT: 0,
  SUBTITLE: 1,
  TRANSLATE: 2,
  REKOGNITION: 3,
  TEXTRACT: 4,
  TRANSCODE: 5,
	REKOGNITION_CELEBRITY: 6,
	REKOGNITION_FACE: 7,
	REKOGNITION_FACE_SEARCH: 8,
	REKOGNITION_CONTENT_MODERATION: 9,
	REKOGNITION_LABEL_DETECTION: 10,
	REKOGNITION_PERSON_TRACKING: 11,
	REKOGNITION_SEGMENT_DETECTION: 12,
	REKOGNITION_TEXT_DETECTION: 13
};

// Video Editor Markers
export const EBreakpoint = {
  INTRO: 'Intro',
  AD_BREAK: 'Advertisement',
  EASTER_EGG: 'EasterEgg',
  CHAPTER: 'Chapter',
  SUBTITLE: 'Subtitle',
  CREDIT_ROLL: 'CreditRoll',
  CUT: 'Cut',
  CAPTION: 'Caption',
  CELEBRITY: 'Celebrity',
  FACE: 'Face',
  LABEL: 'Label',
  CONTENT_MODERATION: 'ContentModeration',
  NFT: 'NFT',
};

export const segmentTypes = [ // Marker types with start and end
  EBreakpoint.INTRO,
  EBreakpoint.SUBTITLE, 
  EBreakpoint.CHAPTER, 
  EBreakpoint.CUT,
  EBreakpoint.CAPTION, 
  EBreakpoint.CELEBRITY, 
  EBreakpoint.FACE, 
  EBreakpoint.LABEL, 
  EBreakpoint.CONTENT_MODERATION, 
  EBreakpoint.NFT,
];

export const markerTitleMapping = {
  Subtitle: null,
  Caption: 'text',
  Celebrity: 'displayName',
  Label: 'labelId',
  Face: 'faceId',
  Emotion: 'emotionId',
};

// Video Editor Markers
const markerTypes = [
  { Intro: [] },
  { Advertisement: [] },
  { EasterEgg: [] },
  { Chapter: [] },
  { Subtitle: [] },
  { Celebrity: [] },
  { CreditRoll: [] },
  { Label: [] },
  { Face: [] },
  { Shot: [] },
  { BlackFrame: [] },
  { ColorBars: [] },
  { Caption: [] },
  { ContentModeration: [] },
  { PersonTracking: [] },
];

// Video Editor timeline colormap
export const EColorMap = {
  Intro: 'rgba(50, 205, 50, 0.7)', // limegreen
  Advertisement: 'rgba(255, 99, 71, 0.7)', // tomato
  Subtitle: 'rgba(46, 139, 87, 0.7)', // seagreen,
  EasterEgg: 'rgba(255, 218, 185, 0.7)', // peachpuff
  Chapter: 'rgba(64, 224, 208, 0.7)', // turquoise
  Celebrity: 'rgba(148, 0, 211, 0.7)', // darkviolet
  CreditRoll: 'rgba(47, 79, 79, 0.7)', // darkslategray
  Label: 'rgba(255, 215, 0, 0.7)', // gold
  Face: 'rgba(0, 250, 154, 0.7)', // mediumspringgreen
  Shot: 'rgba(70, 130, 180, 0.7)', // steelblue
  BlackFrame: 'rgba(0, 0, 0, 0.7)', // black,
  ColorBars: 'rgba(255, 140, 0, 0.7)', // darkorange
  Caption: 'rgba(189, 183, 107, 0.7)', // khaki,
  ContentModeration: 'rgba(220, 20, 60, 0.7)', // crimson
  PersonTracking: 'rgba(127, 255, 212, 0.7)', // aquamarine
  Cut: 'rgba(250, 128, 114, 0.8)', // salmon
};
