import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 360,
  },
  mainTitle: {
    marginBottom: theme.spacing(4),
  },
}));

const WelcomeRemix = ({
  startRecording = () => {},
  setIngestModalOpen = () => {},
  model = {},
}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        <Grid item>
          <Typography variant="h6" className={classes.mainTitle} gutterBottom>
            {model?.type === 'Playlist'
              ? lookUp({ key: 'CONSOLE_WELCOME_PLAYLIST_TEXT' })
              : lookUp({ key: 'CONSOLE_WELCOME_REMIX_TEXT' })
            }
          </Typography>
        </Grid>
        <Grid xs={12} container spacing={4}>
          <Grid item xs={6} container direction="column" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="caption" gutterBottom>{lookUp({ key: 'CONSOLE_REMIX_RECORDING_TEXT' })}</Typography>
            </Grid>
            <Grid item>
              <ThemedButton
                onClick={startRecording}
                startIcon={<FiberManualRecordIcon />}
                size="large"
                color="success"
                fullWidth
              >
                {lookUp({ key: 'CONSOLE_RECORD' })}
              </ThemedButton>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="column" justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="caption" gutterBottom>{lookUp({ key: 'CONSOLE_REMIX_IMPORT_TEXT' })}</Typography>
            </Grid>
            <Grid item>
              <ThemedButton
                onClick={() => setIngestModalOpen?.(true)}
                startIcon={<AddIcon />}
                size="large"
                color="success"
                fullWidth
              >
                {lookUp({ key: 'CONSOLE_UPLOAD_BUTTON' })}
              </ThemedButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

WelcomeRemix.propTypes = {
  model: PropTypes.object.isRequired,
  startRecording: PropTypes.func,
  setIngestModalOpen: PropTypes.func,
};

export default WelcomeRemix;
