const parseUrlParams = (queryString = "") => {
  if (!queryString || queryString === '?') return {};
  return Object.fromEntries(new URLSearchParams(queryString).entries());
};

const stringifyUrlParams = (queryObject = {}) => {
  if (Object.keys(queryObject).length === 0) return "";
  const filteredQuery = Object.fromEntries(Object.entries(queryObject).filter(e => e[1]))
  const urlPar = new URLSearchParams(filteredQuery);
  return "?" + urlPar.toString();
};

const parseSortExpr = (sortExpr = "") => {
  if (!sortExpr) return [];
  return sortExpr.split(',').reduce((sortArr, expr) => {
    const split = expr.split(' ');
    sortArr.push({field: split[0], sort: split[1]});
    return sortArr;
  }, [])
}

const stringifySortExpr = (sortArr = []) => {
  if (!sortArr[0]) return '';
  return sortArr.reduce((sortExpr, rule) => {
    sortExpr += `${rule.field}%20${rule.sort},`
    return sortExpr;
  }, '');
}

export { parseUrlParams, stringifyUrlParams, parseSortExpr, stringifySortExpr };
