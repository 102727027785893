import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { getConsoleEnvName } from 'helpers/common';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ImageCropUpload from 'components/ImageCropUpload';
import ImageCroppingModal from 'components/ImageCropping/CroppingModal';
import Loader from 'components/Loader';

const PROPERTY_TYPE_IMAGE_PREFIX = 'Customer:Image:';
const LAYOUT_NAME_LANDSCAPE = 'LandscapeUrl';
const LAYOUT_NAME_PORTRAIT = 'PortraitUrl';
const LAYOUT_NAME_SQUARE = 'SquareUrl';
const LAYOUT_NAME_CIRCLE = 'CircleUrl';
const LAYOUT_NAME_AVATAR2D = 'Avatar2DUrl';
const LAYOUT_NAME_AVATAR3D = 'Avatar3DUrl';

const IMAGE_RATIOS = {
  [LAYOUT_NAME_LANDSCAPE]: { aspectRatio: 16 / 9, width: 1280, height: 720 },
  [LAYOUT_NAME_PORTRAIT]: { aspectRatio: 2 / 3, width: 800, height: 1200 },
  [LAYOUT_NAME_SQUARE]: { aspectRatio: 1 / 1, width: 1200, height: 1200 },
  [LAYOUT_NAME_CIRCLE]: { aspectRatio: 1 / 1, width: 800, height: 800 },
  [LAYOUT_NAME_AVATAR2D]: { aspectRatio: 1 / 1, width: 800, height: 800 },
  [LAYOUT_NAME_AVATAR3D]: { aspectRatio: 1 / 1, width: 800, height: 800 },
};

function ImagesTab({ model, onChange = () => {} }) {
  const [images, setImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [imageLayouts, setImageLayouts] = useState([ LAYOUT_NAME_LANDSCAPE ]);

  const setImage = (layout, url) => {
    const safeImageProperties = images || {};

    onChange?.({
      ...(model.properties && { ...model.properties }),
      ...safeImageProperties,
      [`${PROPERTY_TYPE_IMAGE_PREFIX}${layout}`]: url,
    });
  };

  // attempts to get an image public url from one of the avaailable filtered images
  const getUrl = (property) => images[`${PROPERTY_TYPE_IMAGE_PREFIX}${property}`] ?? '';

  const getL10nKey = (imageType = LAYOUT_NAME_LANDSCAPE) =>
    lookUp({ key: `CONSOLE_${imageType.replace?.('Url', '')}` });

  useEffect(() => {
    if (model.properties) {
      const filteredProperties = Object.entries(model.properties)
        .reduce((acc, [ property, url ]) => {
          if (!property.includes?.(PROPERTY_TYPE_IMAGE_PREFIX)) {
            return acc;
          }

          return {
            ...acc,
            [property]: url,
          };
      }, {});

      setImages(filteredProperties);
    }

    setIsLoading(false);
  }, [model]);

  useEffect(() => {
    if (getConsoleEnvName() !== 'Nexius') {
      setImageLayouts((imageLayouts) => {
        const layouts = [
          LAYOUT_NAME_PORTRAIT,
          LAYOUT_NAME_SQUARE,
          LAYOUT_NAME_CIRCLE,
          LAYOUT_NAME_AVATAR2D,
          LAYOUT_NAME_AVATAR3D,
        ];
        return imageLayouts.concat(layouts);
      });
    }
  }, []);

  return (
    <Box flex={1}>
      <Grid container spacing={2} direction="column" justifyContent="flex-start">
        {isLoading ? (
          <Loader />
        ) : (
          imageLayouts.map((layout) => {
            const imageUrl = getUrl(layout);
            const title = getL10nKey(layout);

            // FIXME defined crop enabled condition
            const shouldDisplayCropTools = !isEmpty(imageUrl);

            return (
              <Grid item container spacing={1} key={layout}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom>
                    {title}
                  </Typography>
                  <TextField
                    label={title}
                    fullWidth
                    size="small"
                    value={imageUrl}
                    onChange={(e) => {
                      setImage(layout, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ImageCropUpload
                    fileUrl={imageUrl}
                    handleChange={(url, id) => {
                      setImage(layout, url);
                    }}
                  />
                </Grid>
                {shouldDisplayCropTools && (
                  <Grid item xs={12}>
                    <ImageCroppingModal
                      fileUrl={imageUrl}
                      handleChange={(url, id) => setImage(layout, url)}
                      {...IMAGE_RATIOS[layout]}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })
        )}
      </Grid>
    </Box>
  );
};

ImagesTab.propTypes = {
  model: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImagesTab;
