import Typography from '@material-ui/core/Typography';
import { lookUp } from 'services/stringService';
import { version } from '../../package.json';

const AppVersion = () => (
  <Typography variant="subtitle2">
    {lookUp({ key: 'CONSOLE_APP_VERSION_TEMPLATE', version })}
  </Typography>
);

export default AppVersion;
