import { createTheme } from '@material-ui/core';
import { purple, grey } from '@material-ui/core/colors';
import blue from '@material-ui/core/colors/blue';
import green from '@material-ui/core/colors/green';
//#00E676
export const theme = createTheme({
  typography: {
    fontSize: 14,
    subtitle1: {
      fontWeight: 500
    }
  },
  palette: {
    // type: 'dark',
    success: {
      main : "#bac778",
    },
    primary: {
      main: blue[600]
    },
    status: {
      danger: '#e53e3e',
    },
    secondary: grey,
    
      neutral: {
        main: '#5c6ac4',
      },
  
    background: {
      default: '#fff'
    }
  }
});
