import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, func, bool, oneOf, string, array } from 'prop-types';
import { withSnackbar } from 'notistack';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  makeStyles,
  Switch,
  Divider,
  Collapse,
  MenuList,
  Popper,
  MenuItem,
  ClickAwayListener,
  ButtonGroup,
  Grow,
  Paper,
  CardMedia,
  Card,
  CardContent,
  Tooltip,
} from '@material-ui/core';
import { Add, Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DialogModal from 'components/DialogModal';
import { PropsSelect } from 'components';
import libraryEnumsService from 'services/libraryEnumsService';
import catalogPreviewService from 'services/catalogPreviewService';
import {
  setConditionNames,
  setStringMatching,
  setValueFormats,
} from 'store/actions/libraryEnumsAction';
import 'styles/Divider.css';
import AlertService from 'services/alertService';
import Loader from 'components/Loader';
import noop from 'lodash/noop';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '5px',
  },
  textCenter: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  secondRow: {
    paddingTop: '10px',
  },
  title: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    color: '#fff',
  },
  card: {
    display: 'flex',
  },
  content: {
    flex: '1 0 auto',
    display: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative',
    zIndex: 1,
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '30px 0 10px 0',
  },
  divider: {
    width: '70%',
  },
  dividerText: {
    fontStyle: 'italic',
  },
}));

const RulesForm = ({
  rules,
  model,
  enqueueSnackbar,
  setConditionNames = noop,
  setStringMatching = noop,
  handleChange = noop,
  setValueFormats = noop,
  title = 'Filtering Rules',
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [reviewItems, setReviewItems] = useState([]);
  const [formattingOptions, setFormattingOptions] = useState([]);
  const [conditionOptions, setConditionOptions] = useState([]);
  const [containsConditionOptions, setContainsConditionOptions] = useState([]);
  const [expanded, setExpanded] = useState(undefined);
  
  // listen for pre existing rules and update items on first load
  useEffect(() => {
    if (Array.isArray(rules) && rules.length > 0) {
      setItems(rules);
    }
  }, [rules?.length]);

  const imgStyle = {
    width: '100%',
    height: '100%',
    filter: 'grayscale(40%) brightness(60%) contrast(110%)',
  };

  const imageTitle = {
    fontSize: '24px',
    color: '#F5FFFA',
    margin: 0,
    position: 'absolute',
    left: 0,
    right: 0,
    padding: '0 16px',
    fontWeight: 500,
    transition: 'all 200ms cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  };

  const valueSeparatorOptions = [
    { label: lookUp({ key: 'CONSOLE_COMMA' }), value: ',' },
    { label: lookUp({ key: 'CONSOLE_SEMICOLON' }), value: ';' },
    { label: lookUp({ key: 'CONSOLE_COLON' }), value: ':' },
    { label: lookUp({ key: 'CONSOLE_PERIOD' }), value: '.' },
    { label: lookUp({ key: 'CONSOLE_HASHMARK' }), value: '#' },
    { label: lookUp({ key: 'CONSOLE_NA' }), value: null },
  ];

  const newOptions = [
    title === 'Displaying Rules' ? 'Displaying Rule' : 'Filtering',
    'Video Audio',
  ];

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const toOptions = (items) =>
    items.map((i) => ({ label: lookUp({ key: `CONSOLE_${i}` }), value: i }));

  const handleItemsChange = (index, key, value) => {
    setItems((prev) =>
      prev.map((p, i) => {
        return i === index ? { ...p, [key]: value } : p;
      })
    );
  };

  const handleItemsRemove = (index) => {
    const data = items.filter((item) => {
      return item.index !== index;
    });
    setItems(data);
  };

  const handleItemsAdd = () => {
    switch (selectedIndex) {
      case 0:
        return setItems((prev) => [
          ...prev,
          {
            index: items.length + 1,
            propertyName: null,
            formatting: 'Text',
            condition: 'Equal',
            propertyValue: null,
            valueIsReference: false,
            valueCollectionSeparator: '',
            propertyCollectionSeparator: '',
            containsCondition: 'Equal',
          },
        ]);
      case 1:
        return setItems((prev) => [
          ...prev,
          {
            index: items.length + 1,
            propertyName: 'Content:SubType',
            formatting: 'Text',
            condition: 'Exists',
            propertyValue: '',
            valueIsReference: false,
            valueCollectionSeparator: ',',
            propertyCollectionSeparator: '',
            containsCondition: 'Equal',
          },
          {
            index: items.length + 1,
            propertyName: 'Content:SubType',
            formatting: 'Text',
            condition: 'Any',
            propertyValue: 'Audio, Video',
            valueIsReference: false,
            valueCollectionSeparator: ',',
            propertyCollectionSeparator: '',
            containsCondition: 'Equal',
          },
        ]);
      default:
        return '';
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  useEffect(() => {
    handleChange(items);
  }, [items]);

  useEffect(() => {
    const getFormattingOptions = async () => {
      try {
        const options = await libraryEnumsService.getValueFormats();
        setValueFormats(options);
        setFormattingOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_FORMAT_OPTIONS' }),
          }),
        });
      }
    };
    getFormattingOptions();
  }, []);

  useEffect(() => {
    const getConditionOptions = async () => {
      try {
        const options = await libraryEnumsService.getConditionNames();
        setConditionNames(options);
        setConditionOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONDITION_OPTIONS' }),
          }),
        });
      }
    };
    getConditionOptions();
  }, []);

  useEffect(() => {
    const getContainsConditionOptions = async () => {
      try {
        const options = await libraryEnumsService.getStringMatching();
        setStringMatching(options);
        setContainsConditionOptions(toOptions(options));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_STRING_MATCHING' }),
          }),
        });
      }
    };
    getContainsConditionOptions();
  }, []);

  const handlePanel = (index) => {
    setExpanded(expanded === index ? undefined : index);
  };

  useEffect(() => {
    setIsLoading(true);
    const review = async () => {
      try {
        const result = await catalogPreviewService.get(model, model.dataGroupId);
        const newArray = result.map((re) => re.Properties);
        const resultValues = newArray.map((items) => {
          const name = items.filter((item) => item.Name === 'Content:Title')[0]?.Value;
          const value = items.filter((item) => item.Name === 'Content:Thumbnail:Url')[0]?.Value;
          return { name: name, picture: value };
        });

        setReviewItems(resultValues);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      } finally {
        setIsLoading(false);
      }
    };
    model?.dataGroupId && review();
  }, []);

  const List = ({ list }) => (
    <Card className={classes.card}>
      <div className={classes.con}>
        {list.map((item) => (
          <CardContent className={classes.content}>
            <div className={classes.cardCorner} style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                className={classes.media}
                image={item.picture}
                style={imgStyle}
              />
              <div className={classes.title} style={imageTitle}>
                {item.name}
              </div>
            </div>
          </CardContent>
        ))}
      </div>
    </Card>
  );

  return (
    <>
      {title === 'Displaying Rules' && (
        <Typography variant="h6" gutterBottom>
          {lookUp({ key: 'CONSOLE_DISPLAYING_RULES_TITLE' })}
        </Typography>
      )}
      <Grid container alignItems="center" spacing={2}>
        {items &&
          items.map((item, index) => (
            <Grid className={classes.root} key={`${title}-${index}`}>
              <Grid item container xs={12}>
                <Grid item container xs={11} alignItems={'center'} spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <PropsSelect
                      label={lookUp({ key: 'CONSOLE_PROPERTY_NAME' })}
                      value={item.propertyName || null}
                      className={classes.textField}
                      handleChange={(value) => handleItemsChange(index, 'propertyName', value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      select
                      disabled={!(conditionOptions?.length)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      id={`condition-${index}`}
                      label={lookUp({ key: 'CONSOLE_CONDITION' })}
                      required
                      placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                      value={item.condition || null}
                      onChange={({ target }) => handleItemsChange(index, 'condition', target.value)}
                    >
                      {conditionOptions.map((e, i) => (
                        <MenuItem key={i} value={e.value}>
                          {e.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      id={`propertyValue-${index}`}
                      label={lookUp({ key: 'CONSOLE_PROPERTY_VALUE' })}
                      fullWidth
                      value={item.propertyValue}
                      onChange={(e) => handleItemsChange(index, 'propertyValue', e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      select
                      disabled={!(formattingOptions?.length)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      id={`formatting-${index}`}
                      label={lookUp({ key: 'CONSOLE_FORMATTING' })}
                      placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                      value={item.formatting || null}
                      onChange={({ target }) =>
                        handleItemsChange(index, 'formatting', target.value)
                      }
                    >
                      {formattingOptions.map((e, i) => (
                        <MenuItem key={i} value={e.value}>
                          {e.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.textCenter}>
                  <IconButton>
                    {expanded === index ? (
                      <ExpandLess
                        onClick={() => handlePanel(index)}
                        aria-expanded={expanded === index}
                        aria-label="show more"
                        style={{ alignItems: 'center', verticalAlign: 'middle', cursor: 'pointer' }}
                      />
                    ) : (
                      <ExpandMore
                        onClick={() => handlePanel(index)}
                        aria-expanded={expanded === index}
                        aria-label="show more"
                        style={{ alignItems: 'center', verticalAlign: 'middle', cursor: 'pointer' }}
                      />
                    )}
                  </IconButton>
                  <IconButton onClick={() => handleItemsRemove(item.index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
              <Collapse in={expanded === index} timeout="auto">
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={11}
                    alignItems={'center'}
                    spacing={2}
                    className={classes.secondRow}
                  >
                    <Grid item sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(valueSeparatorOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`property-separator-${index}`}
                        label={lookUp({ key: 'CONSOLE_PROPERTY_SEPARATOR' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                        value={item.propertyCollectionSeparator || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'propertyCollectionSeparator', target.value)
                        }
                      >
                        {valueSeparatorOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Grid item xs={3} className={classes.textCenter}>
                        <Tooltip title={'valueIsReference'}>
                          <Switch
                            checked={item.valueIsReference}
                            onChange={() =>
                              handleItemsChange(index, 'valueIsReference', !item.valueIsReference)
                            }
                            color={'primary'}
                          />
                        </Tooltip>
                        {lookUp({ key: 'CONSOLE_REFERENCE' })}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(valueSeparatorOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`value-separator-${index}`}
                        label={lookUp({ key: 'CONSOLE_VALUE_SEPARATOR' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_PLACEHOLDER' })}
                        value={item.valueCollectionSeparator || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'valueCollectionSeparator', target.value)
                        }
                      >
                        {valueSeparatorOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        select
                        disabled={!(containsConditionOptions?.length)}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{ displayEmpty: true }}
                        id={`containsCondition-${index}`}
                        label={lookUp({ key: 'CONSOLE_CONTAINS_CONDITION' })}
                        placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                        value={item.containsCondition || null}
                        onChange={({ target }) =>
                          handleItemsChange(index, 'containsCondition', target.value)
                        }
                      >
                        {containsConditionOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.textCenter}></Grid>
                </Grid>
              </Collapse>
              {index !== items.length - 1 && (
                <div className={classes.dividerWrapper}>
                  <Divider variant="middle" className={classes.divider} />
                  <Typography
                    color="textPrimary"
                    display="block"
                    variant="caption"
                    className={classes.dividerText}
                  >
                    {lookUp({ key: 'CONSOLE_AND' })}
                  </Typography>
                </div>
              )}
              <Grid item xs={1} className={classes.textCenter}></Grid>
            </Grid>
          ))}
      </Grid>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        className={classes.addButton}
      >
        <ButtonGroup  color="primary" ref={anchorRef} aria-label="split button">
          <Button color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
            {lookUp({ key: `CONSOLE_${newOptions[selectedIndex]}` })}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: (placement = 'right'),
                position: 'absolute',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {newOptions.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {lookUp({ key: `CONSOLE_${option}` })}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {reviewItems.length > 0 ? (
          <DialogModal button={false} title={lookUp({ key: 'CONSOLE_PREVIEW' })}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <List list={reviewItems} />
              </>
            )}
          </DialogModal>
        ) : (
          <span></span>
        )}
      </Grid>
    </>
  );
}

RulesForm.propTypes = {
  enqueueSnackbar: func.isRequired,
  title: oneOf(['Filtering Rules', 'Displaying Rules', 'Decision Rules']),
  handleChange: func.isRequired,
  rules: arrayOf(
    shape({
      propertyName: string,
      formatting: string,
      condition: string,
      propertyValue: string,
      valueIsReference: bool,
      valueCollectionSeparator: string,
      propertyCollectionSeparator: string,
      containsCondition: string,
    })
  ).isRequired,
  valueFormats: array.isRequired,
  stringMatching: array.isRequired,
  conditionNames: array.isRequired,
  setConditionNames: func.isRequired,
  setStringMatching: func.isRequired,
  setValueFormats: func.isRequired,
};

const mapStateToProps = ({ libraryEnums: { valueFormats, stringMatching, conditionNames } }) => ({
  conditionNames,
  stringMatching,
  valueFormats,
});

const mapDispatchToProps = (dispatch) => ({
  setValueFormats: (data) => dispatch(setValueFormats(data)),
  setStringMatching: (data) => dispatch(setStringMatching(data)),
  setConditionNames: (data) => dispatch(setConditionNames(data)),
});

export default compose(withSnackbar, connect(mapStateToProps, mapDispatchToProps))(RulesForm);
