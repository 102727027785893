const getDuration = (file) =>
  new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.onerror = (err) => reject(err);
    video.onloadedmetadata = () => {
      resolve(video.duration);
    };
  });

export { getDuration };

export default {
  getDuration,
};
