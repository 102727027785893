import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getSubscriptionEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('License');
  if (!endpoint) throw Error('No SKU endpoints.');
  return endpoint;
};

const getPurchaseHistoryEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('PurchaseHistory');
  if (!endpoint) throw Error('No Purchase history endpoints.');
  return endpoint;
};

const search = ({ page = 0, perPage = 50, sortBy = 'purchaseDate desc', q = '', ...rest } = {}) => {
  return axios
    .get(
      makeApiWithQuery(`${getSubscriptionEndpoint()}/Search/${perPage}/${page}/`, {
        sortBy,
        q,
        ...rest
      })
    )
    .then((r) => r?.data);
}

const getAllProducts = () => axios.get(`${getSubscriptionEndpoint()}/Search/${1}/${0}/`)
  .then((r) => axios.get(`${getSubscriptionEndpoint()}/Search/${r.data.totalCount}/${0}/`))
  .then((r) => r?.data?.data)

const history = (id) => axios.get(`${getPurchaseHistoryEndpoint()}/${id}?itemLimit=10&ascending=false`)
  .then((r) => r?.data);

const historyPage = (token) => axios.get(`${getPurchaseHistoryEndpoint()}/${token}`)
  .then((r) => r?.data);

export { search, history, historyPage, getAllProducts, };

export default {
  search,
  history,
  historyPage,
  getAllProducts,
};
