import AccessHome from './Access';
import Activity from './Dashboard/Activity';
import AppsHome from './Apps';
import AudioVideoEditor from './Content/Editor/AudioVideoEditor';
import AuditLogs from './Access/AuditLogs';
import Automation from './Automation';
import Brands from './Configuration/Brands';
import BrandsForm from './Configuration/Brands/form';
import Catalogs from './Apps/Catalogs';
import CatalogsForm from './Apps/Catalogs/form';
import CatalogsV3 from './Apps/CatalogsV3';
import CatalogsFormV3 from './Apps/CatalogsV3/form';
import Commercial from './Dashboard/Commercial';
import ConfigurationHome from './Configuration';
import Consumption from './Dashboard/Consumption';
import Content from './Content';
import ContentForm from './Content/Form';
import ContentRecord from './Content/ContentRecord';
import ContentReporting from './Dashboard/ContentReporting';
import Countries from './Configuration/Countries';
import CountriesForm from './Configuration/Countries/form';
import Customer from './CustomerPages';
import CustomerForm from './CustomerPages/form';
import CustomerReporting from './Dashboard/CustomerReporting';
import Dashboard from './Dashboard';
import DataGroups from './Apps/DataGroups';
import DataGroupsForm from './Apps/DataGroups/form';
import DataSources from './Apps/DataSources';
import DataSourcesForm from './Apps/DataSources/form';
import Devices from './Engagement/Devices';
import DevicesForm from './Engagement/Devices/form';
import Editors from './Content/Editor';
import Engagement from './Engagement';
import ImageEditor from './Content/Editor/ImageEditor';
import Ingest from './Content/Ingest';
import Job from './Automation/Job';
import Localization from './Configuration/Localization';
import LocalizationForm from './Configuration/Localization/form';
import LoginWithTemporaryToken from './LoginWithTemporaryToken';
import Logs from './Access/Logs';
import Main from './Main';
import Maintenance from './Configuration/Maintenance';
import Merchandise from './Engagement/Merchandise';
import MerchandiseForm from './Engagement/Merchandise/form';
import MessageCenter from './Engagement/MessageCenter';
import MessageCenterForm from './Engagement/MessageCenter/form';
import MonetizationPackage from './Monetization/MonetizationPackage'
import MonetizationPackageForm from './Monetization/MonetizationPackage/form'
import MyAccount from './Main/MyAccount';
import NetworkError from './NetworkError';
import NotFound from './NotFound';
import Parameters from './Configuration/Parameters';
import ParametersForm from './Configuration/Parameters/form';
import PlaybackPolicy from './Monetization/PlaybackPolicy';
import PlaybackPolicyForm from './Monetization/PlaybackPolicy/form';
import Preview from './Content/Preview';
import ProductHome from './Monetization';
import Register from './Register';
import RemixEditor from './Content/Editor/RemixEditor';
import ResetPassword from './ResetPassword';
import ReturnLMS from './ReturnLMS';
import Rewards from './Engagement/Rewards';
import RewardsForm from './Engagement/Rewards/form';
import ServiceVersion from './Configuration/ServiceVersion';
import SignIn from './SignIn';
import Sku from './Monetization/Sku';
import SkuForm from './Monetization/Sku/form';
import SSOLite from './SSOLite';
import SubscriptionDetails from './Engagement/Subscriptions/SubscriptionDetails';
import Subscriptions from './Engagement/Subscriptions';
import TranscodingProfiles from './Configuration/TranscodingProfiles';
import TranscodingProfilesForm from './Configuration/TranscodingProfiles/form';
import Vouchers from './Monetization/Campaigns';
import VouchersForm from './Monetization/Campaigns/form';
import Workflows from './Automation/Workflows';
import WorkflowsForm from './Automation/Workflows/form';

export {
  AccessHome,
  Activity,
  AppsHome,
  AudioVideoEditor,
  AuditLogs,
  Automation,
  Brands,
  BrandsForm,
  Commercial,
  Catalogs,
  CatalogsForm,
  CatalogsV3,
  CatalogsFormV3,
  ConfigurationHome,
  Consumption,
  Content,
  ContentForm,
  ContentRecord,
  ContentReporting,
  Countries,
  CountriesForm,
  Customer,
  CustomerForm,
  CustomerReporting,
  Dashboard,
  DataGroups,
  DataGroupsForm,
  DataSources,
  DataSourcesForm,
  Devices,
  DevicesForm,
  Editors,
  Engagement,
  ImageEditor,
  Ingest,
  Job,
  Localization,
  LocalizationForm,
  LoginWithTemporaryToken,
  Logs,
  Main,
  Maintenance,
  Merchandise,
  MerchandiseForm,
  MessageCenter,
  MessageCenterForm,
  MonetizationPackage,
  MonetizationPackageForm,
  MyAccount,
  NetworkError,
  NotFound,
  Parameters,
  ParametersForm,
  PlaybackPolicy,
  PlaybackPolicyForm,
  Preview,
  ProductHome,
  Register,
  RemixEditor,
  ResetPassword,
  ReturnLMS,
  Rewards,
  RewardsForm,
  ServiceVersion,
  SignIn,
  Sku,
  SkuForm,
  SSOLite,
  SubscriptionDetails,
  Subscriptions,
  TranscodingProfiles,
  TranscodingProfilesForm,
  Vouchers,
  VouchersForm,
  Workflows,
  WorkflowsForm,
};

export default {
  AccessHome,
  Activity,
  AppsHome,
  AudioVideoEditor,
  AuditLogs,
  Automation,
  Brands,
  BrandsForm,
  Catalogs,
  CatalogsForm,
  CatalogsV3,
  CatalogsFormV3,
  Commercial,
  ConfigurationHome,
  Consumption,
  Content,
  ContentForm,
  ContentRecord,
  ContentReporting,
  Countries,
  CountriesForm,
  Customer,
  CustomerForm,
  CustomerReporting,
  Dashboard,
  DataGroups,
  DataGroupsForm,
  DataSources,
  DataSourcesForm,
  Devices,
  DevicesForm,
  Editors,
  Engagement,
  ImageEditor,
  Ingest,
  Job,
  Localization,
  LocalizationForm,
  LoginWithTemporaryToken,
  Logs,
  Main,
  Maintenance,
  Merchandise,
  MerchandiseForm,
  MessageCenter,
  MessageCenterForm,
  MonetizationPackage,
  MonetizationPackageForm,
  MyAccount,
  NetworkError,
  NotFound,
  Parameters,
  ParametersForm,
  PlaybackPolicy,
  PlaybackPolicyForm,
  Preview,
  ProductHome,
  Register,
  RemixEditor,
  ResetPassword,
  ReturnLMS,
  Rewards,
  RewardsForm,
  ServiceVersion,
  SignIn,
  Sku,
  SkuForm,
  SSOLite,
  SubscriptionDetails,
  Subscriptions,
  TranscodingProfiles,
  TranscodingProfilesForm,
  Vouchers,
  VouchersForm,
  Workflows,
  WorkflowsForm,
};