import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import StandardReports from '../StandardReports';
import ChartFromTo from '../ChartFromTo';


const reportTypes = {
  newcontentnumber: 'ContentCreationMetrics/Daily/NewContentNumber',
  numberofcamerashare: 'ContentCreationMetrics/Daily/NumberOfCameraShare',
  singlelivechatkpis: 'ContentCreationMetrics/Daily/SingleLiveChatKpis',
  totallivechatkpis: 'ContentCreationMetrics/Daily/TotalLiveChatKpis',
  superChatMessageType: 'ActivityMetrics/Daily/SuperChatMessageType',
  globalTag: 'GlobalTagMetrics/Daily/GlobalTag',
  chatlocation: 'GeoTaggingMetrics/Daily/ChatLocation',
  totalcontentplaybacklocation: 'GeoTaggingMetrics/Daily/TotalContentPlaybackLocation',
  contentcreation: 'GeoTaggingMetrics/Daily/GeoContentCreation',
  contentweeklylist: 'GeoTaggingMetrics/ContentWeeklyList',
  jobType: 'PlatformMetrics/Daily/JobType',
  jobActivities: 'PlatformMetrics/Daily/JobActivities',
  marker: 'PlatformMetrics/Daily/Marker',
  contentDistribution: 'PlatformMetrics/Daily/ContentDistribution',
  contentDuration: 'PlatformMetrics/Daily/ContentDuration',
  s3Serialization: 'PlatformMetrics/Daily/S3Serialization',
};

function ContentReporting(props) {
  const { enqueueSnackbar } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedReport = searchParams.get('report') || 'newcontentnumber';
  const reportTypeToShowRawMetircs = ['globalTag', 'chatlocation', 'superChatMessageType',
  'totalcontentplaybacklocation', 'contentcreation', 'contentweeklylist',
  'jobType', 'jobActivities', 'marker', 'contentDistribution', 'contentDuration', 's3Serialization'];

  const ReportCharts = useMemo(() => {
    const props = {
      selectedReport,
      reportTypes,
      enqueueSnackbar,
      useRawMetricNames: reportTypeToShowRawMetircs.includes(selectedReport),
      hideDailyReport: selectedReport === 'contentweeklylist',
      hideReportTemplate: selectedReport === 'contentweeklylist',
    };
    return <StandardReports {...props} />
  }, [location.search]);

  useEffect(() => {
    if (!searchParams.has('report')) {
      searchParams.set('report', 'newcontentnumber');
      setSearchParams(searchParams);
    }
  });

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <TextField
          select
          label={lookUp({ key: 'CONSOLE_MENU_ACTIVITY' })}
          fullWidth
          value={selectedReport}
          onChange={(event) => navigate(`${location.pathname}?report=${event.target.value}`)}
        >
          {Object.keys(reportTypes).map((reportType) =>
            <MenuItem key={reportType} value={reportType}>
              {lookUp({ key: `CONSOLE_REPORTING_${reportType}` })}
            </MenuItem>
          )}
        </TextField>
      </Grid>

      <ChartFromTo />

      {ReportCharts}

    </Grid>
  );
}

export default withSnackbar(ContentReporting);
