import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
  TextField,
  MenuItem,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.2, 1, 1.2),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  }
}));

function MediaProcessor(props) {
  const { contentData, setOpen, enqueueSnackbar } = props;
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);

  const getData = async () => {
    try {
      jobManagerService.getMediaProcesingTypes().then((res) => {
        const jobs = Object.values(res).filter(
          (value) => !['TRIM', 'CUT', 'WHISPER_AI_TRANSCRIPT'].includes(value),
        );
        setJobs(jobs);
        setSelectedJob(jobs?.[0]);
      });
    } catch (err) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: lookUp({ key: 'CONSOLE_MEDIA_PROCESSING_TYPES_FETCH_ERROR' }),
        context: selectedJob,
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const classes = useStyles();

  const startProcess = () => {
    let inputFileLocation;

    inputFileLocation =
      contentData.assets.find((asset) => asset.subType === 'Original')?.objectUrl;

    if (!inputFileLocation) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
        context: selectedJob,
      });
      return;
    }

    const reqBod = {
      name: contentData.originalTitle,
      inputFileLocation,
      referenceObjectId: contentData.id,
      notificationEvents: ['SUCCEED', 'FAILED'],
    };

    jobManagerService
      .mediaProcessor({
        ...reqBod,
        mediaProcessingType: selectedJob,
        type: 'MEDIA_PROCESSOR',
        name: `${reqBod.name}_${selectedJob}`,
      })
      .then((jobResponse) => {
        const jobType = lookUp({ key: `CONSOLE_${jobResponse.type}` });
        const jobStatus = lookUp({ key: `CONSOLE_${jobResponse.status}` });
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: `${jobType} -> ${jobStatus}`,
        });
      })
      .catch(() => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
          context: selectedJob,
        });
      });
  };

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_MEDIA_PROCESSOR' })}
        </Typography>
        <IconButton aria-label="close" onClick={() => setOpen('mediaProcessor', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>{lookUp({ key: 'CONSOLE_MEDIA_PROCESSOR_HELPERTEXT' })}</span>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              disabled={jobs.length === 0}
              value={selectedJob}
              onChange={({ target }) => setSelectedJob(target.value)}
            >
              {jobs.map((job) => (
                <MenuItem value={job}>{lookUp({ key: `CONSOLE_${job}_JOB` })}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <Button
            onClick={() => {
              startProcess();
              setTimeout(() => setOpen('mediaProcessor', false), 400);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(MediaProcessor);
