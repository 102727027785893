import React, { useState } from 'react';
import { string, shape, func } from 'prop-types';
import { connect } from 'react-redux';
import Youtube from 'react-youtube';
import {
  IconButton,
  Dialog,
  DialogContent,
  makeStyles,
  Grid,
  Typography,
  Tooltip
} from '@material-ui/core';
import { Close, PlayArrow, PlayCircleFilled } from '@material-ui/icons';
import previewActions from 'store/actions/previewAction';


// todo - providerService
const YOUTUBE_PROVIDER_ID = '9898450a-5139-4c34-98b1-0746a789c06c';
const SPOTIFY_PROVIDER_ID = '62135445-2f68-4a6d-ac16-47ba2d3dbc61';
const JUSTMUSIC_PROVIDER_ID = '23bc039e-a6df-a06d-800b-e58709a5f479';

const useStyles = makeStyles((theme) => ({
  head: {
    padding: theme.spacing(2, 3)
  }
}));

const PlayContentComponent = (props) => {
  
  const classes = useStyles();
  const { item, setPreviewItem, buttonClass } = props;
  const [isModelOpen, setIsModalOpen] = useState(false);
  if (!item.provider) return null;

  return (
    <>
      {item.provider.providerId === JUSTMUSIC_PROVIDER_ID ? (
        <Tooltip title={'Play Content'}>
          <IconButton
            onClick={() => setPreviewItem({ name: item.name, hRef: item.provider.hRef })}
            className={buttonClass}
          >
            <PlayArrow />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          <Tooltip title={'Play Content'}>
            <IconButton onClick={() => setIsModalOpen(!isModelOpen)} className={buttonClass}>
              <PlayCircleFilled />
            </IconButton>
          </Tooltip>
          <Dialog
            open={isModelOpen}
            maxWidth={false}
            onClose={() => setIsModalOpen(!isModelOpen)}
            aria-labelledby="play-dialog"
          >
            <Grid
              container
              wrap={'nowrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
              className={classes.head}
            >
              <Typography variant={'h6'}>{item.name || 'Playing content'}</Typography>
              <IconButton size={'small'} onClick={() => setIsModalOpen(!isModelOpen)}>
                <Close />
              </IconButton>
            </Grid>
            <DialogContent>
              {item.provider.providerId === SPOTIFY_PROVIDER_ID && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://open.spotify.com/embed/track/${item.provider.trackId}" width="768" height="384" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>`
                  }}
                />
              )}
              {item.provider.providerId === YOUTUBE_PROVIDER_ID && (
                <Youtube
                  videoId={item.provider.trackId}
                  opts={{ width: 768, height: 384, playerVars: { autoplay: 1 } }}
                />
              )}
            </DialogContent>
          </Dialog>
        </>
      )}
    </>
  );
};

PlayContentComponent.propTypes = {
  item: shape({
    provider: shape({}),
    name: string
  }).isRequired,
  setPreviewItem: func.isRequired,
  buttonClass: string
};

const mapDispatchToProps = (dispatch) => ({
  setPreviewItem: (item) => dispatch(previewActions.setPreviewItem(item))
});

export default connect(
  null,
  mapDispatchToProps
)(PlayContentComponent);
