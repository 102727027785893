import React from 'react';
import { lookUp } from 'services/stringService';

const DurationPicker = ({ duration, editor = null }) => {
  
  
  const value = duration ? new Date(duration).toISOString().split('T')[1].split('.')[0] : ''

  return editor ? (
    <input 
      type="time" 
      step="1" 
      value={value}
      required
      style={
        {
          borderWidth: 0,
          fontFamily: "inherit",
          background: "transparent",
        }
      }
      {...editor}
    />
  ) : (
    <>{value}</>
  )
}

export default DurationPicker;