import React from 'react';
import { lookUp } from 'services/stringService';
import { Typography } from '@material-ui/core';
import { CenteredBlock } from 'components';

const Main = () => {
  return (
    <CenteredBlock height={'88vh'}>
      <Typography variant={'h4'}>Welcome to Entertainment 2.0</Typography>
    </CenteredBlock>
  );
};

export default Main;
