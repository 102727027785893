import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControl from '@material-ui/core/FormControl';
import EditorInfo from 'components/EditorInfo';
import ThemedButton from 'components/ThemedButton';
import { lookUp } from 'services/stringService';
import { formatDuration } from 'utils/timezoneDate';

const FORM_FIELD_SIZE = 'small';
const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(6),
  },
  actions: {
    position: 'absolute',
    bottom: 0,
    left: theme.spacing(1),
    right: theme.spacing(1),
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.modal + 1,
  },
}));

const AddEditAssetForm = ({
  mainDuration,
  assetModel = {},
  assets = [],
  assetTypeOptions,
  subtypeOptions,
  localeOptions,
  setAssetModel = noop,
  setAssetsModalOpen = noop,
  handleChange = noop,
}) => {
  const classes = useStyles();

  const handleCreateOrEdit = () => {
    let newAssets = [];

    if (!isEmpty(assetModel?.id)) {
      newAssets = assets?.map?.((asset = {}) => (asset.id === assetModel?.id ? assetModel : asset));
    } else {
      newAssets = Array.isArray(assets) ? [...assets, assetModel] : [assetModel];
    }

    handleChange?.('assets', newAssets);
    setAssetsModalOpen?.(false);
  };

  const handleSetOriginalDuration = () => {
    const duration =
      assetModel.subType === 'Original' && !isEmpty(mainDuration)
        ? mainDuration
        : assets.find((e) => e.subType === 'Original')?.duration;

    setAssetModel({
      ...assetModel,
      duration,
    });
  };

  const getDuration = (value = '') => {
    if (!value || !value.includes?.(':')) {
      return 0;
    }

    return value.split(':').reduce((acc, time) => 60 * acc + parseInt(time)) * 1000;
  };

  const isAssetEditting = !isEmpty(assetModel.id);

  return (
    <Box flex={1}>
      <Grid className={classes.container} direction="column" spacing={2} container>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="asset-filename"
              label={lookUp({ key: 'CONSOLE_FILENAME' })}
              aria-describedby="asset-filename-helper"
              value={assetModel.fileName}
              onChange={(event) => setAssetModel({ ...assetModel, fileName: event.target.value })}
            />
            <FormHelperText id="asset-filename-helper">
              {lookUp({ key: 'CONSOLE_FILENAME_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
        {isAssetEditting && (
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                id="asset-id"
                label={lookUp({ key: 'CONSOLE_ASSETID' })}
                aria-describedby="asset-id-helper"
                value={assetModel.id}
                disabled
              />
              <FormHelperText id="asset-id-helper">
                {lookUp({ key: 'CONSOLE_ASSETID_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item container direction="row" spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                id="asset-type"
                label={lookUp({ key: 'CONSOLE_TYPE' })}
                aria-describedby="asset-type-helper"
                value={assetModel.type || ''}
                onChange={(event) => setAssetModel({ ...assetModel, type: event.target.value })}
                select
                required
              >
                {assetTypeOptions.map?.((o) => (
                  <MenuItem label={o} key={o} value={o}>
                    {lookUp({ key: `CONSOLE_${o}` })}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="asset-type-helper">
                {lookUp({ key: 'CONSOLE_TYPE_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                id="asset-subType"
                label={lookUp({ key: 'CONSOLE_SUBTYPE' })}
                aria-describedby="asset-subType-helper"
                value={assetModel.subType || ''}
                onChange={(event) => setAssetModel({ ...assetModel, subType: event.target.value })}
                select
                required
              >
                {subtypeOptions.map?.((o) => (
                  <MenuItem label={o} key={o} value={o}>
                    {lookUp({ key: `CONSOLE_${o}` })}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="asset-subType-helper">
                {lookUp({ key: 'CONSOLE_SUBTYPE_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item>
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="asset-locale"
              label={lookUp({ key: 'CONSOLE_LOCALE' })}
              aria-describedby="asset-locale-helper"
              value={assetModel.locale || ''}
              onChange={(event) => {
                setAssetModel({ ...assetModel, locale: event.target.value });
              }}
              select
              required
            >
              {Object.entries(localeOptions).map(([code, label]) => (
                <MenuItem label={label} key={label} value={code}>
                  {code === '--'
                    ? lookUp({ key: 'CONSOLE_SET_AS_DEFAULT' })
                    : lookUp({ key: `CONSOLE_LANG_${code}` })}
                </MenuItem>
              ))}
            </TextField>
            <FormHelperText id="asset-locale-helper">
              {lookUp({ key: 'CONSOLE_LOCALE_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
        {['Video', 'Audio'].includes(assetModel.type) && (
          <Grid item container direction="row" spacing={1}>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  size={FORM_FIELD_SIZE}
                  id="asset-duration"
                  label={lookUp({ key: 'CONSOLE_DURATION' })}
                  aria-describedby="asset-duration-helper"
                  InputLabelProps={{ shrink: true }}
                  type="time"
                  required
                  inputProps={{
                    step: '1',
                    value: assetModel.duration
                      ? formatDuration(assetModel.duration)
                      : '00:00:00',
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    setAssetModel({
                      ...assetModel,
                      duration: getDuration(e.target.value),
                    });
                  }}
                />
                <FormHelperText id="asset-duration-helper">
                  {lookUp({ key: 'CONSOLE_DURATION_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <ThemedButton onClick={handleSetOriginalDuration}>
                  {lookUp({ key: 'CONSOLE_SET_TO_ORIGINAL' })}
                </ThemedButton>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {isAssetEditting && (
          <>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  size={FORM_FIELD_SIZE}
                  id="asset-workflowStatus"
                  label={lookUp({ key: 'CONSOLE_WORKFLOWSTATUS' })}
                  aria-describedby="asset-workflowStatus-helper"
                  value={assetModel.workflowStatus}
                  disabled
                />
                <FormHelperText id="asset-workflowStatus-helper">
                  {lookUp({ key: 'CONSOLE_WORKFLOWSTATUS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  size={FORM_FIELD_SIZE}
                  id="asset-lifecyclePolicies"
                  label={lookUp({ key: 'CONSOLE_LIFECYCLEPOLICIES' })}
                  aria-describedby="asset-lifecyclePolicies-helper"
                  value={
                    !!assetModel.lifeCyclePolicies.length ? assetModel.lifeCyclePolicies : 'None'
                  }
                  disabled
                />
                <FormHelperText id="asset-lifecyclePolicies-helper">
                  {lookUp({ key: 'CONSOLE_LIFECYCLEPOLICIES_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  size={FORM_FIELD_SIZE}
                  id="object-url"
                  label={lookUp({ key: 'CONSOLE_OBJECTURL' })}
                  aria-describedby=""
                  value={assetModel.objectUrl}
                  onChange={(event) =>
                    setAssetModel({ ...assetModel, objectUrl: event.target.value })
                  }
                />
                <FormHelperText id="asset-object-url-helper">
                  {lookUp({ key: 'CONSOLE_OBJECTURL_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item>
          <FormControl fullWidth>
            <TextField
              size={FORM_FIELD_SIZE}
              id="asset-public-url"
              label={lookUp({ key: 'CONSOLE_PUBLICURL' })}
              aria-describedby="asset-public-url-helper"
              value={assetModel.publicUrl || ''}
              onChange={(event) => setAssetModel({ ...assetModel, publicUrl: event.target.value })}
              required
            />
            <FormHelperText id="asset-public-url-helper">
              {lookUp({ key: 'CONSOLE_PUBLICURL_HELPERTEXT' })}
            </FormHelperText>
          </FormControl>
        </Grid>
        {isAssetEditting && (
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                id="asset-ipfs-hash"
                label={lookUp({ key: 'CONSOLE_IPFSHASH' })}
                aria-describedby="asset-ipfs-hash-helper"
                value={assetModel.ipfsHash || ''}
                onChange={(event) => setAssetModel({ ...assetModel, ipfsHash: event.target.value })}
              />
              <FormHelperText id="asset-ipfs-hash-helper">
                {lookUp({ key: 'CONSOLE_IPFSHASH_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <EditorInfo
            createdAt={assetModel.createdDate}
            modifiedAt={assetModel.modifiedDate}
            modifiedBy={assetModel.lastModifiedBy}
          />
        </Grid>
      </Grid>
      <DialogActions className={classes.actions}>
        <FormControlLabel
          // NOTE disable if we are editting since public status cant be changed on existing
          disabled={isAssetEditting}
          control={
            <Checkbox
              checked={assetModel.isPublic}
              onChange={
                isAssetEditting
                  ? () => {
                      setAssetModel({ ...assetModel, isPublic: !assetModel.isPublic });
                    }
                  : null
              }
              name="isPublic"
              color="primary"
            />
          }
          label={lookUp({ key: 'CONSOLE_PUBLIC' })}
        />
        <Box flex={1} />
        <ThemedButton color="success" onClick={handleCreateOrEdit}>
          {isAssetEditting
            ? lookUp({ key: 'CONSOLE_OK_BUTTON' })
            : lookUp({ key: 'CONSOLE_CREATE_BUTTON' })}
        </ThemedButton>
      </DialogActions>
    </Box>
  );
};

AddEditAssetForm.propTypes = {
  mainDuration: PropTypes.number,
  assets: PropTypes.array,
  assetTypeOptions: PropTypes.array,
  subtypeOptions: PropTypes.array,
  assetModel: PropTypes.object,
  localeOptions: PropTypes.object,
  setAssetsModalOpen: PropTypes.func,
  setAssetModel: PropTypes.func,
  handleChange: PropTypes.func,
};

export default AddEditAssetForm;
