import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape, string, arrayOf } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, makeStyles, TextField, IconButton, MenuItem } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { EditorInfo, Loader, FormFooter } from 'components';
import { goBackOrStay } from 'helpers/common';
import authGroupsService from 'services/authGroupsService';
import skuService from 'services/skuService';
import voucherService from 'services/vouchersService';
import navigationAction from 'store/actions/navigationAction';

import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  main: {
    width: '100%',
  },
  root: {
    width: 800,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },

  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: '2px',
  },
}));

function MonetizationPackageForm(props) {
  const {
    enqueueSnackbar,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const [model, setModel] = useState({
    name: '',
    productGroups: [[{ refType: '', refId: '' }]],
    enabled: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [vouchersOptions, setVouchersOptions] = useState([]);
  const [skuOptions, setSkuOptions] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getAuthGroup = async () => {
      try {
        const data = await authGroupsService.get(id);
        setModel(data);
        dispatch(
          navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name }))
        );
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: `Couldn't load monetization package data: `,
        });
        setIsLoading(false);
      }
    };
    if (id) {
      getAuthGroup();
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({
            key: 'CONSOLE_CREATE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_Monetization_Package` }),
          })
        )
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  useEffect(() => {
    const loadNextProducts = async () => {
      setIsLoading(true);
      try {
        const { totalCount } = await voucherService.search({ page: 0, perPage: 1 });
        const { data } = await voucherService.search({ page: 0, perPage: totalCount });
        setVouchersOptions(
          data.map(({ id, name }) => ({
            label: ['(V) ', name],
            value: id,
            name: lookUp({ key: 'CONSOLE_VOUCHER' }),
          }))
        );
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${`voucher`}` }),
          }),
        });
        setIsLoading(false);
      }
    };
    loadNextProducts();
  }, []);

  useEffect(() => {
    const loadNextProducts = async () => {
      setIsLoading(true);
      try {
        const { totalCount } = await skuService.search({ page: 0, perPage: 1 });
        const { data } = await skuService.search({ page: 0, perPage: totalCount });
        setSkuOptions(
          data.map(({ id, name, store }) => ({ label: [name, ' ', store], value: id, name: 'Sku' }))
        );
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${`SKU`}` }),
          }),
        });
        setIsLoading(false);
      }
    };
    loadNextProducts();
  }, []);

  const handleChangeName = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChange = (key, index, value) => {
    model.productGroups[index] = value.map((x) => ({ refType: x.name, refId: x.value }));
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel({ ...model });
  };

  const handleProductChange = (index, key, options) => {
    if (options) {
      handleChange('productGroups', index, options);
    }
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);
    // setIsSaving(true);
    let saveModel;
    if (publish) {
      saveModel = {
        ...model,
        enabled: !model.enabled,
      };
    } else {
      saveModel = { ...model };
    }

    try {
      id ? await authGroupsService.edit(id, saveModel) : await authGroupsService.create(saveModel);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      goBackOrStay(navigate, '/monetization/monetization-packages');
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const handleAuthGroupAdd = () => {
    model.productGroups.push([{ refType: '', refId: '' }]);
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel({ ...model });
  };

  const handleAuthRemove = (index) => {
    model.productGroups = model.productGroups.filter((item, i) => i !== index);
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel({ ...model });
  };

  const deleteAuthGroup = async () => {
    setIsDeleting(true);
    try {
      await authGroupsService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/monetization/monetization-packages'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Grid item className={classes.main}>
      <Grid container>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid container alignItems={'center'} spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="authGroup-name"
                label={lookUp({ key: 'CONSOLE_NAME' })}
                required
                fullWidth
                value={model.name}
                onChange={(e) => handleChangeName('name', e.target.value)}
              />
            </Grid>

            {model.productGroups.map((product, index) => (
              <Grid
                container
                xs={12}
                alignItems={'right'}
                alignContent={'right'}
                className={classes.marginX}
                spacing={2}
              >
                <Grid item xs={11} alignItems={'right'} spacing={2}>
                  <TextField
                    select
                    disabled={!(vouchersOptions?.length)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    label={lookUp({ key: 'CONSOLE_PRODUCTGROUPS' })}
                    placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                    value={
                      model.productGroups
                        ? vouchersOptions
                            .concat(skuOptions)
                            .filter((f) => product.map((pro) => pro.refId).includes(f.value))
                        : null
                    }
                    onChange={({ target }) =>
                      handleProductChange(index, 'productGroups', target.value)
                    }
                    options={vouchersOptions.concat(skuOptions)}
                    multiple
                  >
                    {vouchersOptions.concat(skuOptions).map((e, i) => (
                      <MenuItem key={i} value={e.value}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <IconButton onClick={() => handleAuthRemove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            ))}

            <IconButton color="primary" onClick={handleAuthGroupAdd}>
              <Add fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <FormFooter
        isDeleting={isDeleting}
        deleteData={deleteAuthGroup}
        existPublishing={true}
        isSaving={isSaving}
        isPublishing={model.enabled}
        onSave={() => saveData(false)}
        onCancel={() => navigate(window.history.state.idx === 0 ? '/' : -1)}
        onPublish={() => saveData(true)}
        saveButtonText={
          id ? lookUp({ key: 'CONSOLE_SAVE_BUTTON' }) : lookUp({ key: 'CONSOLE_CREATE_BUTTON' })
        }
        publishButtonText={
          model.enabled
            ? lookUp({ key: 'CONSOLE_UNPUBLISH_BUTTON' })
            : lookUp({ key: 'CONSOLE_PUBLISH_BUTTON' })
        }
        isNewItem={!id}
      />
      {id && <EditorInfo createdAt={model.createdDate} modifiedAt={model.lastModifiedDate} modifiedBy={model.lastModifiedBy} />}
    </Grid>
  );
}

MonetizationPackageForm.propTypes = {
  enqueueSnackbar: func.isRequired,
  productGroups: arrayOf(
    arrayOf(
      shape({
        refType: string,
        refId: string,
      })
    )
  ),
};

export default withSnackbar(MonetizationPackageForm);
