import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getVouchersEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Vouchers');
  if (!endpoint) throw Error('No Vouchers endpoint.');
  return endpoint;
};
const getCampaignsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Campaign');
  if (!endpoint) throw Error('No Campaign endpoint.');
  return endpoint;
};

const getById = (id) => axios.get(`${getCampaignsEndpoint()}/${id}`)
  .then((res) => res?.data);

const search = (
  { page = 0, perPage = 1000, sortBy = 'name asc', q = '', d = false, ...rest } = {}
) => {
  return axios
    .get(
      makeApiWithQuery(`${getCampaignsEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        q,
        d,
        ...rest
      })
    )
    .then((res) => res?.data);
}

const getVouchers = (id, { ...rest } = {}) => axios.get(makeApiWithQuery(`${getVouchersEndpoint()}/Search/${id}`, { ...rest }))
  .then((response) => response?.data);

const getVoucherByCode = (code) => axios.get(`${getVouchersEndpoint()}/${code}`)
  .then((response) => response?.data);

const historyPage = (token) => axios.get(`${getVouchersEndpoint()}/Search/${token}`)
  .then((r) => r?.data);

const create = async (body) => axios.post(`${getCampaignsEndpoint()}/`, body)
  .then((res) => res?.data);

const edit = (id, body) => axios.put(`${getCampaignsEndpoint()}/${id}`, body)
  .then((res) => res?.data);

const addVouchers = async (id, body) => axios.put(`${getCampaignsEndpoint()}/${id}/AddVouchers`, body)
  .then((res) => res?.data);

const remove = async (id) => axios.delete(`${getCampaignsEndpoint()}/${id}`)
  .then((r) => r?.data);

const generate = (body) => axios.post(`${getCampaignsEndpoint()}/Generate`, body)
  .then((response) => response);

export { remove, create, edit, getById, search, generate, addVouchers, getVouchers };

export default {
  remove,
  getVouchers,
  getVoucherByCode,
  historyPage,
  addVouchers,
  create,
  edit,
  getById,
  search,
  generate
};
