import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Avatar, Typography, makeStyles, LinearProgress } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import customersService from 'services/customersService';
import CreatedOn from 'components/CreatedOn';
import collectionsService from 'services/collectionsService';

const useStyles = makeStyles((theme) => ({
  mainTable: {
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
}));

const Followed = ({ id }) => {
  
  const classes = useStyles();
  const [artists, setArtists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const noRows = () => {
    return (
      <GridOverlay>
        <Typography variant="h5">No records found</Typography>
      </GridOverlay>
    );
  };

  const LinearLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      flex: true,
      valueGetter: item => item.row.value.name
    },
    {
      field: 'imageUrl',
      headerName: ' ',
      align: 'left',
      flex: true,
      renderCell: (item) => {
      return <Avatar src={item.row.value.imageUrl} alt={item.row.value.name} />},
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.row.value.createdDate} />,
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await collectionsService.getCollection(id, 'Follow', 'Artist');
        if (data?.length > 0) {
          const artists = await Promise.allSettled(data.map((artist) => customersService.getCustomer(artist.targetId)));
          const filteredArtist = artists.filter((artist) => artist.status === 'fulfilled')
          setData(filteredArtist)
        }
        setIsLoading(false)
      } catch (e) { 
        console.error(e);
      }
    }
    getData()
  }, [])


  return (
    <div className={classes.mainTable}>
      <DataGrid
        loading={isLoading}
        style={{ border: 0 }}
        columns={columns}
        rows={
          data
            ? data
                .map((artist, i) => {
                  return { ...artist, id: i };
                })
                .reverse()
            : []
        }
        components={{
          NoRowsOverlay: noRows,
          LoadingOverlay: LinearLoadingOverlay,
        }}
        autoHeight
      />
    </div>
  );
};

export default Followed;
