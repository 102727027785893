import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Lister, CreatedOn, ConfirmDialog, Toolbar, Spacing } from 'components';
import { Link } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import PublishedComponent from 'assets/icons/PublishedComponent'
import UnPublishedComponent from 'assets/icons/UnPublishedComponent'
import uniformServices from 'services/uniformServices';
import AlertService from 'services/alertService';

const TranscodingProfiles = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [debounce, setDebounce] = useState();
  const [trigger, setTrigger] = useState();
  
  const columns = [
    {
      field: 'enabled',
      headerName: ' ',
      align: 'left',
      width: 40,
      renderCell: item =>
      item.value ? (
        <PublishedComponent />
      ) : (
        <UnPublishedComponent/>
        )
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      flex: true,
      renderCell: (item) => {
        return <Link to={`/configuration/transcoding-profiles/${item.id}/edit`} component={RouterLink}>
          {item.value}
          </Link>
        }
    },
    {
      field: 'streamKind',
      headerName: lookUp({ key: 'CONSOLE_STREAMKIND' }),
      align: 'left',
      width: 140,
      renderCell: (item) => item.value
    },
    {
      field: 'lastModifiedDate',
      headerName: lookUp({ key: 'CONSOLE_LAST_MODIFIED' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'id',
      headerName: ' ',
      align: 'right',
      width: 40,
      sortable: false,
      renderCell: (item) => <ConfirmDialog
          key="del"
          onConfirm={() => handleDelete(item.id, item.row.name)}
          message={lookUp({
            key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE',
            title: item.row.name || lookUp({ key: 'CONSOLE_ITEM' }),
          })}
          hint={lookUp({ key: 'CONSOLE_DELETE_ITEM' })}
          buttonText={lookUp({ key: 'CONSOLE_DELETE_BUTTON' })}
          dialogText={lookUp({ key: 'CONSOLE_DELETE_HEADING' })}
        />
    },
  ];

  const handleDelete = (id, name) => {
    setDeletedIds(id);
    uniformServices
      .remove('MediaProfile', id)
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: name }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: name }),
        });
      });
  };

  const getData = () => {
    setLoading(true);
    uniformServices
      .search('MediaProfile', search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res.totalCount);
        setItems([...res.data.filter((item) => !deletedIds?.includes(item.id))]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TRANSCODING_PROFILES' }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [search, trigger]);

  return (
    <>
      <Toolbar create triggerSearch={() => setTrigger(Symbol('refresh'))} />
      <Spacing height={1} />
      <Lister loading={loading} columns={columns} rows={items || []} totalCount={totalCount} />
    </>
  )
};

export default withSnackbar(TranscodingProfiles);
