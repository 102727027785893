import { useState, useEffect } from 'react';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  IconButton,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Clear } from '@material-ui/icons';
import { Lister, CreatedOn, QueryBar, Spacing, HoverMenu } from 'components';
import jobManagerService from 'services/jobManagerService';
import { dateFormatter } from 'helpers/time';
import contentService from 'services/contentService';
import AlertService from 'services/alertService';
import LogButton from 'components/Audit/LogButton';
import { sortArrayWithKey } from 'helpers/common';

const Job = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();
  const [trigger, setTrigger] = useState();
  const [items, setItems] = useState([]);
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const today = new Date();
  const to = search.get('to') || new Date();
  const from = search.get('from') || new Date(today.setDate(today.getDate() - 7));
  const [totalCount, setTotalCount] = useState(0);
  const [isJobDetailsOpen, setIsJobDetailsOpen] = useState(false);
  const [jobOpen, setJobOpen] = useState({});
  const [jobTypes, setJobTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [refIds, setRefIds] = useState({});
  const [workflows, setWorkflows] = useState({});
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(null)

  const columns = [
    {
      field: 'type',
      headerName: lookUp({ key: 'CONSOLE_TYPE' }),
      align: 'left',
      width: 180,
      renderCell: (item) => jobTypes.find((job) => job.value === item.value)?.label,
    },
    {
      field: 'status',
      headerName: lookUp({ key: 'CONSOLE_STATUS' }),
      align: 'left',
      width: 140,
    },
    {
      field: 'id',
      headerName: lookUp({ key: 'CONSOLE_ID' }),
      align: 'left',
      width: 320,
    },
    {
      field: 'referenceObjectId',
      headerName: lookUp({ key: 'CONSOLE_CONTENTTITLE' }),
      align: 'left',
      flex: true,
      renderCell: (item) => {
        if (!refIds[item.value]) {
          return 'NA';
        }
        return (
          <div>
            {hovered === item.id && (
              <HoverMenu
                minWidth="unset"
                buttons={[
                  {
                    component: <LogButton key="log" type="Content" id={item.value} />,
                  },
                ].filter((e) => !!e)}
              />
            )}
            <Link
              to={`/content/${item.value}/edit#basic`}
              component={RouterLink}
              target="_blank"
              rel="noreferrer"
            >
              {refIds[item.value]}
            </Link>
          </div>
        );
      },
    },
    {
      field: 'workflowId',
      headerName: lookUp({ key: 'CONSOLE_WORKFLOW_NAME' }),
      align: 'left',
      flex: true,
      renderCell: (item) => {
        if (!workflows[item.value]) {
          return 'NA';
        }
        return (
          <Link
            to={`/automation/workflows/${item.value}/edit#basic`}
            component={RouterLink}
            target="_blank"
            rel="noreferrer"
          >
            {workflows[item.value]}
          </Link>
        );
      },
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
  ];

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (!value) delete searchObj[key];
    setSearch(searchObj);
  };

  useEffect(() => {
    setLoading(true);
    jobManagerService
      .search(search, pageSize || 25, page || 0)
      .then(async (response) => {
        setTotalCount(response.totalCount);
        setItems(response.data);
        const contents = await Promise.allSettled(
          response.data.map((item) => contentService.getById(item.referenceObjectId)),
        );

        const workflows = await Promise.allSettled(
          response.data
            .filter(({ workflowId }) => Boolean(workflowId))
            .map((item) => jobManagerService.getWfTemplate(item.workflowId)),
        );

        return { contents, workflows };
      })
      .then(({ contents, workflows }) => {
        setRefIds(
          contents.reduce((acc, item) => {
            if (item.status === 'fulfilled') {
              acc = { ...acc, [item.value.id]: item.value.originalTitle };
            }
            return acc;
          }, {}),
        );

        setWorkflows(
          workflows.reduce((acc, item) => {
            if (item.status === 'fulfilled') {
              acc = { ...acc, [item.value.id]: item.value.name };
            }
            return acc;
          }, {}),
        );
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(() => setLoading(false));
  }, [search, trigger]);

  useEffect(() => {
    jobManagerService.getJobTypes().then((jobs) => {
      let formattedJobTypes = Object.values(jobs).reduce((acc, job) => {
        let formattedJob = job.replace('ENUMS:JOBTYPE:', '');
        acc.push({ value: formattedJob, label: lookUp({ key: `CONSOLE_${formattedJob}` }) });
        return acc;
      }, []);
      formattedJobTypes = sortArrayWithKey('value', formattedJobTypes);
      setJobTypes(formattedJobTypes);
    });

    jobManagerService.getStatuses().then((statuses) => {
      setStatuses(
        Object.values(statuses).reduce((acc, st) => {
          let formattedStatus = st.replace('ENUMS:JOBSTATUS:', '');
          acc.push({ value: formattedStatus, label: lookUp({ key: `CONSOLE_${formattedStatus}` }) });
          return acc;
        }, [])
      );
    });
  }, []);

  const typeFilter = {
    label: lookUp({ key: 'CONSOLE_ALL_JOB_TYPES' }),
    key: 'type',
    options: jobTypes,
    change: job => {
      setParam('type', job.value);
    },
  };

  const statusFilter = {
    label: lookUp({ key: 'CONSOLE_STATUS' }),
    key: 'status',
    options: statuses,
    change: status => {
      setParam('status', status.value);
    },
  };

  const createdFrom = {
    component: (
      <DatePicker
        label={lookUp({ key: 'CONSOLE_FROM' })}
        value={from}
        maxDate={to}
        format="MM/DD/yyyy"
        variant="inline"
        disableFuture
        onChange={(date) => {
          const time = new Date(date);
          setParam('from', time.toISOString().split('T')[0]);
        }}
        helperText={lookUp({ key: 'CONSOLE_START_DATE_HELPERTEXT' })}
      />
    ),
  };
  const createdTo = {
    component: (
      <DatePicker
        label={lookUp({ key: 'CONSOLE_UNTIL' })}
        value={to}
        minDate={from}
        format="MM/DD/yyyy"
        variant="inline"
        onChange={(date) => {
          const time = new Date(date);
          setParam('to', time.toISOString());
        }}
        helperText={lookUp({ key: 'CONSOLE_END_DATE_HELPERTEXT' })}
      />
    ),
  };

  return (
    <>
      <QueryBar
        triggerSearch={() => setTrigger(Symbol('refresh'))}
        queryKey="query"
        filters={[typeFilter, statusFilter, createdFrom, createdTo]}
      />
      <Spacing height={2} />
      <Lister
        loading={loading}
        columns={columns}
        rows={items || []}
        totalCount={totalCount}
        dataGridProps={{
          onRowEnter: (params) => setHovered(params.id),
          onRowLeave: () => setHovered(null),
          style: { cursor: 'pointer' },
          onRowClick: (item) => {
            setJobOpen(item.row);
            setIsJobDetailsOpen(true);
          },
        }}
      />
      <Dialog
        open={isJobDetailsOpen}
        maxWidth="md"
        fullWidth
        onClose={() => setIsJobDetailsOpen(false)}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between">
            <Grid item xs={11}>
              {lookUp({ key: 'CONSOLE_JOB_DETAIL' })}
            </Grid>

            <Grid item xs={1} container alignContent="flex-start" justifyContent="flex-end">
              <IconButton size="small" onClick={() => setIsJobDetailsOpen(false)}>
                <Clear />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <>
            <Grid container direction="column" spacing={2} xs={12}>
              <Grid item xs={12}>
                <Typography variant="h6">{lookUp({ key: 'CONSOLE_JOBID' })}</Typography>
                {jobOpen.id}
              </Grid>
              {jobTypes.find((job) => job.value == jobOpen?.type) && (
                <Grid item xs={12}>
                  <Typography variant="h6">{lookUp({ key: 'CONSOLE_JOB_TYPE' })}</Typography>
                  {jobTypes.find((job) => job.value == jobOpen?.type).label}
                </Grid>
              )}
              <Grid item xs={3}>
                <Typography variant="h6">{lookUp({ key: 'CONSOLE_STATUS' })}</Typography>
                {jobOpen.status}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{lookUp({ key: 'CONSOLE_STATUS_MESSAGE' })}</Typography>
                {jobOpen.statusMessage}
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h6">{lookUp({ key: 'CONSOLE_CREATED_ON' })}</Typography>
                {dateFormatter(jobOpen.createdDate)}
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default withSnackbar(Job);
