import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getDataGroupEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('DataGroup');
  if (!endpoint) throw Error('No DataGroup endpoint.');
  return endpoint;
};
const search = ({ page = 0, perPage = 1000, sortBy = 'name asc', q = '', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getDataGroupEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        q,
        ...rest
      })
    )
    .then((r) => r?.data);

const create = async (model) => axios.post(getDataGroupEndpoint(), model)
  .then((r) => r?.data)

const get = (id) => axios.get(`${getDataGroupEndpoint()}/${id}`)
  .then((r) => r?.data);

const edit = async (model) => axios.put(`${getDataGroupEndpoint()}/${model.id}`, model)
  .then((r) => r?.data)

const remove = (id) => axios.delete(`${getDataGroupEndpoint()}/${id}`)
  .then((r) => r?.data);

export { search, create, get, edit, remove };

export default {
  search,
  create,
  get,
  edit,
  remove
};
