import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PublishStatusIcon from 'components/PublishStatusIcon';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import { PlayContent } from 'components';
import { dateFormatter, msToHHMMSS } from 'helpers/time';
import DeleteButton from 'components/DeleteButton';
import AddButton from 'components/AddButton';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cover: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    backgroundSize: 'cover',
    // FIXME use theme.pallete need to indetify theme config and overrides and add background light
    backgroundColor: '#F5F5F5',
  },
  greyCoverBg: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    // FIXME use theme.pallete need to indetify theme config and overrides and add background light
    backgroundColor: '#F5F5F5',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 65,
    height: 65,
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  relative: {
    position: 'relative',
  },
  playButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  noWrapEllipsisContainer: {
    minWidth: 0,
    width: '100%',
  },
}));

const LAYOUT_TYPE_LANDSCAPE = 'Landscape';
const LAYOUT_TYPE_PORTRAIT = 'Portrait';
const LAYOUT_TYPE_SQUARE = 'Square';

const DEFAULT_IMAGE_TYPE = LAYOUT_TYPE_PORTRAIT;
const IMAGE_LAYOUTS = [LAYOUT_TYPE_PORTRAIT, LAYOUT_TYPE_SQUARE, LAYOUT_TYPE_LANDSCAPE];

const EMPTY_VALUE_INDICATOR = '-';

const ContentCard = ({ item, onDelete, onAdd }) => {
  const classes = useStyles();
  const [images, setImages] = useState([]);

  // set available images
  useEffect(() => {
    if (item.assets) {
      const imageAssets = item.assets?.filter?.(
        // TODO simplify filter logic
        (asset) =>
          asset.type === 'Image' && IMAGE_LAYOUTS.includes(asset.subType) && !asset.isDeleted,
      );

      setImages(imageAssets);
    }
  }, [item]);

  // attempts to get an image public url from one of the avaailable filtered images
  const getUrl = (subType) => {
    const targetImage = images.find((image = {}) => image.subType === subType);
    return targetImage?.publicUrl;
  };

  /**
   * looks into the item object for desired image types
   *
   * @return string
   */
  const getImageUrl = () => {
    let url = getUrl(DEFAULT_IMAGE_TYPE);

    if (url) {
      return url;
    }

    IMAGE_LAYOUTS.forEach((type) => {
      let _url = getUrl(type);

      if (_url) {
        url = _url;
      }
    });

    return url;
  };

  const imageUrl = getImageUrl();

  return (
    <Card className={classes.card}>
      <Box component="span" className={classes.relative}>
        <PlayContent item={item} buttonClass={classes.playButton} />
        {imageUrl ? (
          <CardMedia image={imageUrl} title={item.originalTitle} className={classes.cover} />
        ) : (
          <Box className={classes.greyCoverBg}>
            <GraphicEqIcon className={classes.icon} />
          </Box>
        )}
      </Box>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems={'flex-start'} wrap={'nowrap'} justifyContent={'space-between'}>
          <Grid item xs={10} container direction="column" className={classes.column}>
            <Grid item className={classes.noWrapEllipsisContainer}>
              {item.type && <Typography variant="body2">{item.type}</Typography>}
              {item.originalTitle && (
                <Tooltip title={item.originalTitle}>
                  <Typography variant="h6" gutterBottom>
                    <Link
                      to={`/content/${item.id}/edit`}
                      component={RouterLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.originalTitle}
                    </Link>
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {`${lookUp({ key: 'CONSOLE_UPLOAD' })} ${lookUp({ key: 'CONSOLE_DATE' })} `}
                {item.createdDate ? (
                  <strong>{dateFormatter(item.createdDate)}</strong>
                ) : (
                  EMPTY_VALUE_INDICATOR
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {lookUp({ key: 'CONSOLE_DURATION' })}{' '}
                {item.duration ? (
                  <strong>{msToHHMMSS(item.duration)}</strong>
                ) : (
                  EMPTY_VALUE_INDICATOR
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            spacing={1}
            alignItems="baseline"
            wrap="nowrap"
            justifyContent="flex-end"
          >
            <Grid item>
              <PublishStatusIcon isPublished={item.published} />
            </Grid>
            {onAdd && (
              <AddButton
                tooltipText={lookUp({ key: 'CONSOLE_ADD_ITEM' })}
                onAdd={() => onAdd(item)}
              />
            )}
            {onDelete && (
              <DeleteButton
                tooltipText={lookUp({ key: 'CONSOLE_DELETE_ITEM_FROM_LIST_HELPERTEXT' })}
                onDelete={() => onDelete(item)}
                variant="icon"
                size="small"
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ContentCard.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default ContentCard;
