import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { setLastRoute, setLoadingStatus } from 'store/actions/authAction';
import { passwordOnReset } from 'services/customersService';
import {
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
  Paper,
  Typography,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { version } from '../../package.json';
import AlertService from 'services/alertService';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  backgroundDiv: {
    minWidth: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
  },
  root: {
    position: 'absolute',
    maxWidth: 520,
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  wrapper: {
    minWidth: '520px',
    minHeight: '360px',
    padding: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: 20,
  },
  version: {
    fontSize: '100%',
    fontStyle: 'italic',
  },
  title: {
    marginBottom: '2em',
  },
}));

function ResetPassword(props) {
  const {
    enqueueSnackbar,
  } = props;

  const classes = useStyles();
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const lastRoute = useSelector((state) => state.auth.lastRoute);
  const images = useSelector((state) => state.brandStyle.images);

  const [showPassword, setShowPassword] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });

  const [errors, setErrors] = useState({
    password: null,
    confirmPassword: null,
    submit: null,
  });

  const passwordFormat = {
    conforms: (value) => {
      return !/^(.{0,7}|[^0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*|[^A-Z]*|[^a-z]*|[^a-zA-Z0-9]*)$/.test(
        value
      );
    },
    message: lookUp({ key: 'CONSOLE_PASSWORD_HELPERTEXT' }),
  };

  const notEmpty = {
    conforms: (value) => {
      return !!value.length;
    },
    message: lookUp({ key: 'CONSOLE_Mandatory_field' }),
  };

  const equalsPassword = {
    conforms: (value) => {
      return value === formData.password;
    },
    message: lookUp({ key: 'CONSOLE_PASSWORD_MISMATCH' }),
  };

  const validation = {
    password: notEmpty,
    confirmPassword: equalsPassword,
  };

  const validate = (field, value) => {
    let error = validation[field].conforms(value) ? null : validation[field].message;
    setErrors({ ...errors, [field]: error });
    return error;
  };

  const validateAll = () => {
    let allValid = true;
    let touchAll = touched;
    let checkAll = errors;
    Object.keys(formData).forEach((field) => {
      touched[field] = true;
      if (validate(field, formData[field])) {
        checkAll[field] = validate(field, formData[field]);
        allValid = false;
      }
    });
    setTouched(touchAll);
    setErrors(checkAll);
    return allValid;
  };

  const handleChange = (field) => (event) => {
    event.persist();
    const value = event.target.value || '';
    setFormData({ ...formData, [field]: value });
    if (
      event.type === `blur` ||
      (field == 'password' && value.length > 8) ||
      (field == 'confirmPassword' && value.length >= formData.password.length)
    ) {
      setTouched({ ...touched, [field]: true });
    }
    setErrors({ ...errors, [field]: validate(field, value) });
  };

  const handleClickShowPassword = () => {
    setShowPassword((o) => !o);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      newPassword();
    }
  };

  const newPassword = async (event) => {
    event.preventDefault();
    const { password } = formData;
    if (!validateAll()) return;
    if (isLoading) return;
    dispatch(setLoadingStatus(true));
    passwordOnReset(password, token)
      .then(() => {
        navigate('/sign-in');
        dispatch(setLastRoute(lastRoute));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_set_password_error' }),
        });
      })
      .finally(() => dispatch(setLoadingStatus(false)));
  };

  return (
    <div className={classes.backgroundDiv}>
      <img src="/static/img/blur.jpg" style={{ width: '100vw', height: '100vh' }} />
      <div className={classes.root}>
        <CssBaseline />
        <Paper className={classes.wrapper}>
        {images.primaryLogo && (
              <div className={classes.imageWrapper}>
                <img src={images.primaryLogo} height="80" />
              </div>
            )
          }
          <Grid item align="center" className={classes.title}>
            <Typography component="h1" variant="h4" align="center" >
              {lookUp({ key: 'CONSOLE_SET_PASSWORD' })}
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid item align="center">
              <CircularProgress color={'primary'} />
            </Grid>
          ) : (
            <Grid item>
              <form onSubmit={newPassword}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <TextField
                      id="password"
                      name="password"
                      label={lookUp({ key: 'CONSOLE_PASSWORD' })}
                      type={showPassword ? 'text' : 'password'}
                      value={formData.password}
                      onChange={handleChange('password')}
                      error={Boolean(touched.password && errors.password)}
                      helperText={(touched.password && errors.password) || ' '}
                      onKeyUp={(e) => handleKeyUp(e)}
                      fullWidth
                      onBlur={handleChange('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      id="confirmPassword"
                      name="confirmPassword"
                      label={lookUp({ key: 'CONSOLE_CONFIRM_PASSWORD' })}
                      type={showPassword ? 'text' : 'password'}
                      value={formData.confirmPassword}
                      onChange={handleChange('confirmPassword')}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={(touched.confirmPassword && errors.confirmPassword) || ' '}
                      onKeyUp={(e) => handleKeyUp(e)}
                      fullWidth
                      onBlur={handleChange('confirmPassword')}
                    />
                  </Grid>
                </Grid>
                <ThemedButton fullWidth type="submit" color="primary" style={{ marginTop: '35px' }}>
                  {lookUp({ key: 'CONSOLE_NEW_PASSWORD_BUTTON' })}
                </ThemedButton>
                <Typography
                  color={'textSecondary'}
                  align="right"
                  style={{ margin: '20px 0 0 0', fontStyle: 'italic' }}
                >
                  {lookUp({ key: 'CONSOLE_APP_VERSION_TEMPLATE', version })}
                </Typography>
              </form>
            </Grid>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default withSnackbar(ResetPassword);
