const SET_PREVIEW_ITEM = 'SET_PREVIEW_ITEM';
const SET_PIC_IN_PIC_STATUS = 'SET_PIC_IN_PIC_STATUS';
const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
const SET_ROWS_NUMBER = 'SET_ROWS_NUMBER';

const setPreviewItem = (item) => ({
  type: SET_PREVIEW_ITEM,
  item
});

const setPageNumber = (pageNumber) => ({
  type: SET_PAGE_NUMBER,
  pageNumber
});

const setRowsNumber = (rowsNumber) => ({
  type: SET_ROWS_NUMBER,
  rowsNumber
});

const setPicInPicStatus = (status) => ({
  type: SET_PIC_IN_PIC_STATUS,
  status
});

export {SET_ROWS_NUMBER, SET_PAGE_NUMBER, SET_PREVIEW_ITEM, SET_PIC_IN_PIC_STATUS, setPreviewItem, setPicInPicStatus, setPageNumber, setRowsNumber };

export default {
  SET_PAGE_NUMBER,
  SET_ROWS_NUMBER,
  SET_PREVIEW_ITEM,
  SET_PIC_IN_PIC_STATUS,
  setPreviewItem,
  setPicInPicStatus,
  setPageNumber,
  setRowsNumber,
};
