import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  styled,
  Grid,
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Link,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Close } from '@material-ui/icons'
import { sortLangCodesByLangNames } from 'helpers/common';
import IngestFile from 'components/IngestFile';
import NoResultsDataGridOverlay from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';
import AssetTypeIcon from 'components/AssetTypeIcon';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import { dateFormatter } from 'helpers/time';
import { utcToLocalTimeZone } from 'utils/timezoneDate';



const SaveButton = styled(Button)({
  color: '#ffffff',
});

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.5, 2, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  tabSelect: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    padding: theme.spacing(1, 1.5),
  },
  modalSubtitle: {
    width: '100%',
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
  },
  subtUploadModal: {
    padding: theme.spacing(1.5),
  },
  link: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

function SubtUpload(props) {
  const {
    contentData,
    setOpen,
    upload,
    enqueueSnackbar,
    defaultLocale,
  } = props;


  const [selectedLang, setSelectedLang] = useState(defaultLocale);
  const [translateLangs, setTranslateLangs] = useState([]);
  const [subtitleFiles, setSubtitleFiles] = useState([]);
  const [assetReqParams, setAssetReqParams] = useState([]);

  const classes = useStyles();

  const startSubtfileProcessing = () => {
    if (assetReqParams.length && subtitleFiles.length) {
      const subtitlesForJob = assetReqParams.reduce((subsForJob, assetParams) => {
        const subtitleFile = subtitleFiles.find((subFile) => (
          subFile.objectUrl === assetParams.objectUrl &&
          subFile.publicUrl === assetParams.publicUrl
        ));
        if (subtitleFile) subsForJob.push(subtitleFile);
        return subsForJob;
      }, []);

      Promise.allSettled(subtitlesForJob.map((asset) => {
        const reqBody = {
          provider: 'File',
          id: asset.id,
          ownerId: contentData.ownerId,
          ReferenceObjectId: contentData.id,
          scheduled: new Date().toISOString(),
          type: 'SUBTITLE',
          name: 'Subtitling',
          Language: selectedLang,
          inputFileLocation: asset.objectUrl,
          notificationEvents: [ 'SUCCEED', 'FAILED' ],
          uiData: {
            id: null,
            x: 0,
            y: 0,
            height: 40,
            width: 150,
            type: 'default'
          }
        };
       return jobManagerService.subtitle(reqBody);
      }))
        .then((promiseResults) => {
          promiseResults.forEach((result) => {
            if (result?.status === 'fulfilled') {
              AlertService.displaySuccess({
                msgBar: enqueueSnackbar,
                message:  lookUp({ key: 'CONSOLE_SUBTITLING_STARTED_TEMPLATE', provider: 'File' })
              });
            } else {
              AlertService.displayWarning({
                msgBar: enqueueSnackbar,
                message: result?.status === 'rejected' && result.reason,
              });
            }
          })
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
          });
        })
        .finally(() => {
          setSubtitleFiles([]);
          setOpen('subtUpload', false);
        });
      }
  };

  const columnsDefinition = [
    {
      field: 'type',
      headerName: ' ',
      width: 24,
      renderCell: (item) => <AssetTypeIcon type={item.row.type} color="primary" />
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      minWidth: 142,
      renderCell: (item) => dateFormatter(utcToLocalTimeZone({ date: item.row.createdDate })),
    },
    {
      field: 'originalTitle',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      flex: 3,
      renderCell: (item) => {
        let title = item.row.originalTitle || item.row.fileName
        if (title?.length > 33) {
          title = `${title.slice(0, 33)}...`;
        }
        return (
          <Tooltip title={lookUp({ key: 'CONSOLE_OPEN_IN_NEW_HELPERTEXT'})}>
            <Link
              className={classes.link}
              href={`/content/${contentData.id}/edit#assets`}
              target={'_blank'}
            >
              {title}
            </Link>
          </Tooltip>
        )
      }
    },
  ];

  useEffect(() => {
    jobManagerService.getLanguageCodes('Translate')
      .then((translateLangs) => setTranslateLangs(sortLangCodesByLangNames(translateLangs)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  }, []);

  useEffect(() => {
    setSelectedLang(defaultLocale);
  }, [defaultLocale]);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography variant="button" className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_UPLOAD_SUBTITLE' })}
        </Typography>

        <IconButton aria-label="close" onClick={() => setOpen('subtUpload', false)}>
          <Close />
        </IconButton>
      </div>

      <Typography variant="body2" className={classes.modalSubtitle}>
        {lookUp({ key: 'CONSOLE_SUBTITLEFILEUPLOAD_HELPERTEXT' })}
      </Typography>

      <DialogContent className={classes.subtUploadModal}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              label={lookUp({ key: 'CONSOLE_LANGUAGE' })}
              value={selectedLang}
              onChange={(e) => setSelectedLang(e.target.value)}
            >
              {Object.keys(translateLangs).map((langC) =>
                <MenuItem key={langC} value={langC}>{translateLangs[langC]}</MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12}>
            <IngestFile
              contentId={contentData.id}
              isPublic={true}
              dropdown={false}
              upload={upload}
              enqueueSnackbar={enqueueSnackbar}
              setResultsFromServer={setSubtitleFiles}
              fullWidthContent
              isSubtitleFile={true}
              setAssetReqParams={setAssetReqParams}
              parentContentType='Subtitle'
              parentLocale={selectedLang}
            />
          </Grid>
        {subtitleFiles.length > 0 ? (
          <Grid item xs={12}>
            <DataGrid
              columns={columnsDefinition}
              rows={subtitleFiles}
              components={{
                LoadingOverlay: DataGridOverlayLoaderLinear,
                NoRowsOverlay: NoResultsDataGridOverlay,
                NoResultsOverlay: NoResultsDataGridOverlay,
              }}
              componentsProps={{
                noRowsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
                noResultsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
              }}
              rowHeight={64}
              autoHeight
              autoPageSize
              disableSelectionOnClick
            />
          </Grid>
        ) : null}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container>
          <Grid item xs={12} className={classes.dialogActions}>
            <SaveButton onClick={() => startSubtfileProcessing()} color="primary" >
              {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
            </SaveButton>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(SubtUpload);
