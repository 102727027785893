import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import StandardReports from '../StandardReports';
import ChartFromTo from '../ChartFromTo';


const reportTypes = {
  totalplaybackdevice: 'ConsumptionMetrics/Daily/TotalPlaybackDevice',
  digitalPerformance: 'ConsumptionMetrics/Daily/DigitalPerformance',
  totalContentTimeSpent: 'ClientReports/Daily/TotalContentTimeSpent',
  connectivity: 'EnvironmentMetrics/Daily/Connectivity',
  watchedTime: 'ConsumptionMetrics/Daily/WatchedTime'
};

function Consumption(props) {
  const { enqueueSnackbar } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedReport = searchParams.get('report') || 'totalplaybackdevice';
  const reportTypeToShowRawMetircs = ['digitalPerformance', 'watchedTime'];

  const ReportCharts = useMemo(() => {
    const props = {
      selectedReport,
      reportTypes,
      enqueueSnackbar,
      useRawMetricNames: reportTypeToShowRawMetircs.includes(selectedReport),
      showVerticleBar: selectedReport === 'watchedTime'
    };
    switch (selectedReport) {
      default:
        return <StandardReports {...props} />
    }
  }, [location.search]);

  useEffect(() => {
    if (!searchParams.has('report')) {
      searchParams.set('report', 'totalplaybackdevice');
      setSearchParams(searchParams);
    }
  });

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <TextField
          select
          label={lookUp({ key: 'CONSOLE_MENU_CONSUMPTION' })}
          fullWidth
          value={selectedReport}
          onChange={(event) => navigate(`${location.pathname}?report=${event.target.value}`)}
        >
          {Object.keys(reportTypes).map((reportType) =>
            <MenuItem key={reportType} value={reportType}>
              {lookUp({ key: `CONSOLE_REPORTING_${reportType}` })}
            </MenuItem>
          )}
        </TextField>
      </Grid>

      <ChartFromTo />

      {ReportCharts}

    </Grid>
  );
}

export default withSnackbar(Consumption);
