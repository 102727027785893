import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, makeStyles, TextField, MenuItem, Switch, Tooltip } from '@material-ui/core';
import { FormFooter, EditorInfo } from 'components';
import { goBackOrStay } from 'helpers/common';
import navigationAction from 'store/actions/navigationAction';
import transcodingProfilesService from 'services/transcodingProfilesService';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

import 'styles/localization.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  textField: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginDown: {
    marginBottom: '30px',
  },
}));

const TranscodingProfilesForm = ({ enqueueSnackbar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const [model, setModel] = useState({
    name: '',
    description: '',
    profileUrl: '',
    streamKind: '',
    enabled: true,
    isDefault: true,
  });

  const [isSaving, setIsSaving] = useState(false);
  const [streamKindOptions, setStreamKindOptions] = useState([]);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let pageTitle;
    const getData = async () => {
      try {
        const data = await transcodingProfilesService.get(id);
        setModel(data);
        pageTitle = lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name });
        dispatch(navigationAction.setPageTitle(pageTitle));
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      getData();
    } else {
      pageTitle = lookUp({
        key: 'CONSOLE_CREATE_TEMPLATE',
        type: lookUp({ key: 'CONSOLE_TRANSCODING_PROFILE', type: '' }),
      });
      dispatch(navigationAction.setPageTitle(pageTitle));
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  useEffect(() => {
    transcodingProfilesService
      .getProfiles()
      .then((resp) => {
        setStreamKindOptions(resp.map((e) => Object.keys(e)[0]));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${'Transcoding profiles'}` }),
          }),
        });
      });
  }, []);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const saveData = async (enabled = false) => {
    setHasError(false);

    if (!model.name || !model.streamKind || !model.profileUrl) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: 'Please fill in all the necessary fields!',
      });
      setHasError(true);
      return;
    }
    enabled ? setIsPublishing(true) : setIsSaving(true);

    let saveModel = { ...model, enabled: enabled ? !model.enabled : model.enabled };
    try {
      id
        ? await transcodingProfilesService.edit(id, saveModel)
        : await transcodingProfilesService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: id
          ? lookUp({ key: 'CONSOLE_UPDATED_MESSAGE_TEMPLATE', type: model.name })
          : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      goBackOrStay(navigate, '/configuration/transcoding-profiles');
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      enabled ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const deleteTranscodingProfile = async () => {
    try {
      await transcodingProfilesService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
      setTimeout(() => navigate('/configuration/transcoding-profiles'), 50);
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_TRANSCODING_PROFILE' }),
        }),
      });
    }
  };

  return (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            id && (
              <ThemedButton
                color={model.enabled ? 'secondary' : 'success'}
                disabled={isPublishing || isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({
                  key: model.enabled ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON',
                })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            id && (
              <DeleteButton
                onDelete={deleteTranscodingProfile}
                loading={isDeleting}
                disabled={isDeleting}
              />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/configuration/transcoding-profiles')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>

        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <TextField
                select
                fullWidth
                label={lookUp({ key: 'CONSOLE_STREAMKIND' })}
                required
                error={hasError && !model.streamKind}
                value={model.streamKind || ''}
                onChange={(event) => handleModelChange('streamKind', event.target.value)}
              >
                {streamKindOptions.map((kind) => (
                  <MenuItem key={kind} value={kind}>
                    {kind}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item>
              <TextField
                id="name"
                label={lookUp({ key: 'CONSOLE_NAME' })}
                helperText={lookUp({ key: 'CONSOLE_TRANSCODING_PROFILES_NAME_HELPERTEXT' })}
                required
                error={hasError && !model.name}
                className={classes.textField}
                value={model.name}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="profileUrl"
              helperText={lookUp({ key: 'CONSOLE_ELEMENTAL_PROFILEURL_HELPERTEXT' })}
              label={lookUp({ key: 'CONSOLE_PROFILEURL' })}
              required
              error={hasError && !model.profileUrl}
              className={classes.textField}
              value={model.profileUrl}
              onChange={(e) => handleModelChange('profileUrl', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="description"
              label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
              helperText={lookUp({ key: 'CONSOLE_TRANSCODE_DESCRIPTION_HELPERTEXT' })}
              multiline
              rows={4}
              className={classes.textField}
              value={model.description}
              onChange={(e) => handleModelChange('description', e.target.value)}
            />
          </Grid>

          <Grid item xs={3} className={classes.textCenter}>
            {lookUp({ key: 'CONSOLE_DEFAULT' })}
            <Tooltip title={'isDefault'}>
              <Switch
                checked={model.isDefault}
                onChange={() => handleModelChange('isDefault', !model.isDefault)}
                color={'primary'}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
};

TranscodingProfilesForm.propTypes = {
  enqueueSnackbar: func,
};

export default withSnackbar(TranscodingProfilesForm);
