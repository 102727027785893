import mime from 'mime-types';

const getTypes = (src) => {
  const fullType = mime.lookup(src);
  const [contentType, fileType] = fullType ? fullType.split('/') : [];

  return { contentType, fileType }; // for example { contentType: 'image', fileType: 'jpeg' }
};

const fileSize = (kilobytes) => {
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (!kilobytes) return 'N/A';
  const i = parseInt(Math.floor(Math.log(kilobytes) / Math.log(1024)), 10);
  if (i === 0) return `${kilobytes} ${sizes[i]}`;
  return `${(kilobytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export { getTypes, fileSize };

export default {
  getTypes,
  fileSize,
};
