import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { EditorInfo, Loader, Tabbing } from 'components';
import { History, ListAlt } from '@material-ui/icons';
import * as merchandiseService from 'services/merchandiseService';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import rewardsService from 'services/rewardsService';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import SectionContainer from 'components/SectionContainer';
import ThemedButton from 'components/ThemedButton';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;
const EMPTY_MODEL = {
  id: null,
  customerId: null,
  productId: null,
  comment: null,
  createdDate: null,
  notifyCustomer: true,
  status: null,
  emailAddress: null,
  rewardsPoint: null,
  item: null,
  price: 0,
};

const MerchandiseForm = ({ enqueueSnackbar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  // TODO -> set up the model
  const [model, setModel] = useState(EMPTY_MODEL);
  const [purchHistory, setPurchHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [tab, setTab] = useState();

  // NOTE tab is not working from useParams, is always undefined, we need to use location hash in order to parse it correctly
  // FIXME either add the tab param to routes or create a utility in order to make it available on required pages
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      // TODO make common utility since useParams is not working for tab
      setTab(hash?.replace?.('#', ''));
    }
  }, [hash]);

  const navigateBack = () => {
    dispatch(navigationAction.allowNavigation());
    window.history?.state?.idx === 0
      ? navigate('/engagement')
      : navigate(window.history?.state?.idx === 0 ? '/' : -1);
  };

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const saveData = async (publish = false) => {
    const saveModel = publish ? { ...model, enabled: !model.enabled } : { ...model };
    const editOrCreate = id ? 'edit' : 'create';

    setIsSaving(true);
    merchandiseService[editOrCreate](saveModel)
      .then(() => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message:
            publish && model.enabled === false
              ? lookUp({
                  key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE',
                  title: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
                })
              : publish && model.enabled === true
              ? lookUp({
                  key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE',
                  title: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
                })
              : id
              ? lookUp({
                  key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE',
                  type: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
                })
              : lookUp({
                  key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE',
                  title: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
                }),
        });
        setTimeout(() => navigateBack(), 300);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${`merchandise`}` }),
          }),
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const tabs = [
    {
      name: 'order',
      icon: <ListAlt />,
      content: isLoading ? (
        <Loader inline />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_PURCHASE_STATUS' })}
              helperText={lookUp({ key: 'CONSOLE_PURCHASE_STATUS_HELPERTEXT' })}
              value={model.status || ''}
              onChange={(event) => handleModelChange('status', event.target?.value)}
              required
              select
              fullWidth
            >
              <MenuItem value={'NotProcessed'}>{lookUp({ key: 'CONSOLE_Notprocessed' })}</MenuItem>
              <MenuItem value={'OrderCompleted'}>
                {lookUp({ key: 'CONSOLE_orderCompleted' })}
              </MenuItem>
              <MenuItem value={'OrderRejected'}>
                {lookUp({ key: 'CONSOLE_orderRejected' })}
              </MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_PURCHASE_DATE' })}
              helperText={lookUp({ key: 'CONSOLE_PURCHASE_DATE_HELPERTEXT' })}
              value={new Date(model.createdDate).toLocaleString() || ''}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>{lookUp({ key: 'CONSOLE_CUSTOMER' })}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_FULLNAME' })}
              helperText={lookUp({ key: 'CONSOLE_CUSTOMER_FULLNAME_HELPERTEXT' })}
              value={model.customer?.displayName || model.customer?.username || ''}
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_CUSTOMER_EMAIL' })}
              helperText={lookUp({ key: 'CONSOLE_CUSTOMER_EMAIL_HELPERTEXT' })}
              value={model.customer?.emailAddress || ''}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_REWARD_INTERNALPRICE' })}
              helperText={lookUp({ key: 'CONSOLE_REWARD_INTERNALPRICE_HELPERTEXT' })}
              value={model.reward?.internalPrice ?? ''}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_PRODUCT' })}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_PRODUCT_NAME' })}
              helperText={lookUp({ key: 'CONSOLE_PRODUCT_NAME_HELPERTEXT' })}
              value={model.product?.originalTitle || ''}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_PRODUCT_INTERNALPRICE' })}
              helperText={lookUp({ key: 'CONSOLE_PRODUCT_INTERNALPRICE_HELPERTEXT' })}
              value={model.product?.internalPrice ?? ''}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_COMMENT' })}
              helperText={lookUp({ key: 'CONSOLE_COMMENT_HELPERTEXT' })}
              minRows={10}
              maxRows={20}
              size="small"
              value={model.comment || ''}
              onChange={(event) => handleModelChange('comment', event.target?.value)}
              fullWidth
              required
              multiline
            />
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'history',
      icon: <History />,
      content: isLoading ? (
        <Loader inline />
      ) : (
        <Grid container>
          {purchHistory?.map((pHist, index, array) => (
            <Grid item xs={12} key={`${index}_${pHist.createdDate}`}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={3}>
                  <TextField
                    label={lookUp({ key: 'CONSOLE_PURCHASEDATE' })}
                    value={pHist.createdDate || ''}
                    fullWidth
                    readOnly
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    label={lookUp({ key: 'CONSOLE_STATUS' })}
                    value={pHist.status || ''}
                    fullWidth
                    readOnly
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label={lookUp({ key: 'CONSOLE_COMMENT' })}
                    minRows={1}
                    maxRows={5}
                    size="small"
                    value={pHist.comment || ''}
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    readOnly
                  />
                </Grid>
              </Grid>
              {index !== array.length - 1 && <Divider variant="middle" />}
            </Grid>
          ))}
        </Grid>
      ),
    },
  ];

  const getPurchaseData = async (data = {}) => {
    const [product, customer, reward] = await Promise.all([
      contentService.getById(data.productId),
      customersService.getCustomer(data.customerId),
      rewardsService.getReward(data.customerId),
    ]);

    return {
      ...data,
      customer,
      product,
      reward,
    };
  };

  useEffect(() => {
    const getData = () => {
      if (tab === 'history') {
        setIsLoading(true);

        merchandiseService
          .getHistory(id)
          .then((data) => {
            setPurchHistory(data);
          })
          .catch((error) => {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error,
              context: lookUp({
                key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
                type: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
              }),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        merchandiseService
          .get(id)
          .then((data) => getPurchaseData(data))
          .then((result) => {
            setModel(result);
          })
          .catch((error) => {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error,
              context: lookUp({
                key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
                type: lookUp({ key: 'CONSOLE_MERCHANDISE' }),
              }),
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    };

    if (id) {
      getData();
    }
  }, [id, tab]);

  return (
    <SectionContainer flex={1}>
      <HeaderActionsContainer>
        <Box flex={1} />
        {/* Cancel action Button */}
        <ThemedButton
          onClick={(e) => {
            e.preventDefault();
            navigateBack();
          }}
          disabled={isSaving || isLoading}
        >
          {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
        </ThemedButton>

        {/* Save action Button */}
        <ThemedButton
          color="success"
          onClick={saveData}
          disabled={isSaving || isLoading}
          loading={isSaving}
        >
          {lookUp({ key: 'CONSOLE_SAVE_BUTTON' })}
        </ThemedButton>
      </HeaderActionsContainer>

      <Tabbing addTopMargin={HEADER_MIN_HEIGHT} tabs={tabs} />

      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

MerchandiseForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(MerchandiseForm);
