import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  makeStyles,
  useScrollTrigger,
  Slide,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Loader from 'components/Loader';
import ThemedButton from 'components/ThemedButton';
import contentService from 'services/contentService';
import customersService from 'services/customersService';
import collectionsServiceV2 from 'services/collectionsServiceV2';
import SectionDrawer from 'components/SectionDrawer';
import ContentSelector from 'components/ContentSelector';
import CustomerSelector from 'components/CustomerSelector';
import ContentCardsContainer from './ContentCardsContainer';
import AlertService from 'services/alertService';
import uniformServices from 'services/uniformServices';
import skuService from 'services/skuService';
import get from 'lodash/get';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  searchSidebar: {
    position: 'fixed',
    top: props => props.header ? 130 : 0,
    zIndex: 9,
    right: 0,
    width: 320,
    backgroundColor: theme.palette.info.main,
    height: '100%',
  },
}));

const DEFAULT_SERVICE_ID = 'content';

// normalize services api based on selectedRelation to allow data source switching
// FIXME - move to service utils for reuse
const serviceByRelationType = {
  content: {
    search: contentService.searchQuery,
    get: contentService.getById,
    types: contentService.getTypeEnums,
  },
  customer: {
    search: customersService.searchQuery,
    get: customersService.getCustomer,
    types: customersService.getCustomerTypes,
  },
  sku: {
    search: skuService.search,
    get: skuService.get,
    types: skuService.getProductTypes,
  },
};

const itemPropsMappingByRelation = {
  content: {
    name: 'originalTitle',
    id: 'id',
  },
  customer: {
    name: 'username',
    id: 'customerId',
  },
  sku: {
    name: 'name',
    id: 'id',
  },
};

const CatalogContent = ({ 
  model,
  enqueueSnackbar,
}) => {
  const { id, kind, contentType, dataSource, targetType } = model;
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const trigger = useScrollTrigger();

  const onDelete = (item) => {
    let itemIdKey = 'id';

    // if cant find the default item.id prop, check additional ids based on the dataSource type
    if (!item[itemIdKey]) {
      // use switch statement to accomodate other sources [sku, content, customer]
      if (dataSource === 'Customer') {
        itemIdKey = 'customerId';
      }
    }

    const itemIndex = items.findIndex((itm) => itm.id === item[itemIdKey]);
    items.splice(itemIndex, 1);
    setItems([...items]);
    collectionsServiceV2.deleteCatalogRelation(id, dataSource, item[itemIdKey])
  };

  useEffect(() => {
    const getAndSetItems = async () => {
      setIsLoading(true);
      let collection = await collectionsServiceV2.getCatalogRelation(id, dataSource);
      return Promise.allSettled(collection.pageContent
        ?.map((content) => uniformServices.get(dataSource, content.targetId)))
        .then((coll) => {
          setItems(coll
            .filter((result) => result.status === 'fulfilled')
            .map((result) => {
              const img = result?.value?.data?.assets?.find((ast) => (
                ast.subType === 'Landscape' ||
                ast.subType === 'Square' ||
                (ast.type === 'Image' && ast.subType === 'Original')
              ));
              return ({
                ...result.value.data,
                source: dataSource,
                index: collection.pageContent?.find(e => e.targetId === result.value.data.id)?.index,
                imageUrl: img ? img.publicUrl : ''
              });
            }).sort((a, b) => a.index - b.index)
          );
        })
        .catch((error) => {
          const errorKeyMap = {
            None: 'CONSOLE_NONE',
            Catalog: 'CONSOLE_CATALOG',
            Sku: 'CONSOLE_SKU',
            Customer: 'CONSOLE_CUSTOMER',
            Content: 'CONSOLE_CONTENT',
            Search: 'CONSOLE_SEARCH',
          };

          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: errorKeyMap[dataSource] }),
            })
          });
        })
        .finally(() => setIsLoading(false));
    };

    getAndSetItems();
  }, []);

  const getListStyle = (isDraggingOver) => ({
    height: '100%'
  });

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (
      source.droppableId === 'dragDropContainer' &&
      destination.droppableId === 'dragDropContainer'
    ) {
      setItems((items) => {
        const startInd = source.index;
        const endInd = destination.index;
        [items[endInd], items[startInd]] = [items[startInd], items[endInd]];
        collectionsServiceV2.addCatalogRelation(id, dataSource, items[endInd].id, { index: endInd });
        collectionsServiceV2.addCatalogRelation(id, dataSource, items[startInd].id, { index: startInd });
        return [...items]
      });
    }
    return;
  };

  const handleDroppablePropertiesChange = (itemId, properties) => {
    if (kind === 'Automatic' || !Array.isArray(items)) {
      return;
    }

    let targetItem = items.find(draggableItem => getItemId(draggableItem) === itemId);

    // set target item props with draggable props
    targetItem.properties = properties;

    AlertService.displaySuccess({
      msgBar: enqueueSnackbar,
      message: lookUp({ key: 'CONSOLE_DECORATION_ELEMENTS_SAVE_MESSAGE' }),
    });
  }

  /**
   * Selects the service to use based on the selectedRelation
   * FIXME - move to service utils for reuse
   *
   * @param {string} relation one of [Content, Customer, Catalog, Sku]
   *
   * returns object a service definition
   */
  const getServiceByRelationType = (relation = dataSource) => {
    const normalizedRelation = relation.toLowerCase?.();
    let targetService = serviceByRelationType[normalizedRelation];

    if (!targetService || typeof targetService?.search !== 'function') {
      targetService = serviceByRelationType[DEFAULT_SERVICE_ID];
    }

    return targetService;
  };

  // gets an item id based on the current relation
  // FIXME - move to service utils for reuse
  const getItemId = (item, relation = dataSource) => {
    let normalizedRelation = relation.toLowerCase?.();

    return get(item, [itemPropsMappingByRelation[normalizedRelation]?.id], item.id);
  };

  const getAssetContainingImageUrl = (assets = []) =>
    assets.find?.(
      (asset = {}) =>
        ['Landscape', 'Square', 'Original'].includes(asset.subType) && asset.type === 'Image',
    );

  /**
   * Adds a relathionsip based on selectedRelation
   * FIXME on this ContentForm component values are assigned incorrectly, targetType should be selectedRelation and viceversa
   *
   * @param {string} selectedId a content id
   * 
   * @returns void
   */
  const handleAddRelationship = (selectedId) => {
    const itemExists = items.find?.((item) => item.id === selectedId);

    if (!itemExists) {
      getServiceByRelationType(dataSource)
        .get(selectedId)
        .then((item = {}) => {
          
          const { publicUrl: imageUrl = '' } = getAssetContainingImageUrl(item.assets) ?? {};

          setItems([...items, {
            ...item,
            source: dataSource,
            imageUrl,
          }]);

          collectionsServiceV2.addCatalogRelation(id, dataSource, selectedId, { index: items.length });
        })
        .catch(console.error);
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box flex={1}>
      <Grid
        container
        direction="column"
        spacing={1}
      >
          { kind !== 'Automatic' &&
          <Grid item xs={12}>
            <ThemedButton
              onClick={handleOpenDrawer}
              color="primary"
            >
              {lookUp({ key: 'CONSOLE_SEARCH_BUTTON' })}
            </ThemedButton>
          </Grid>
          }
          <Grid item xs={12}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Droppable droppableId="dragDropContainer" style={{ overflow: 'auto'}}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        <ContentCardsContainer
                          // FIXME remove dataSource prop after moving helpers based on selected relation into utils
                          dataSource={dataSource}
                          title={dataSource}
                          items={items}
                          handlePropertiesChange={handleDroppablePropertiesChange}
                          onDragStyle={getListStyle(snapshot.isDraggingOver)}
                          onDelete={kind !== 'Automatic' && onDelete}
                        />
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            </DragDropContext>
          </Grid>

          <SectionDrawer
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
            >
              {
                // FIXME on this ContentForm component values are assigned incorrectly, targetType should be selectedRelation and viceversa
                contentType === 'Content' && dataSource !== 'Customer' ? (
                  <ContentSelector
                    // NOTE we need to invert the values
                    selectedRelation={dataSource}
                    targetType={targetType}
                    onClose={handleCloseDrawer}
                    onSelect={handleAddRelationship}
                    enabledTypes={['keyword', 'type', 'platform']}
                  />
                ) : (
                  <CustomerSelector
                    // NOTE we need to invert the values
                    selectedRelation={dataSource}
                    targetType={targetType}
                    onClose={handleCloseDrawer}
                    onSelect={handleAddRelationship}
                  />
                )
              }
            </SectionDrawer>
        </Grid>
      </Box>
    );
};

export default withSnackbar(CatalogContent);
