import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  Line,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import reportingSrv from 'services/reportingService';
import AlertService from 'services/alertService';


const useStyles = makeStyles(theme => ({
  chartGrid: {
    height: '100%',
    margin: theme.spacing(6, 8, 0, 0),
    marginBottom: theme.spacing(8),
  },
  titleItem: {
    marginTop: theme.spacing(2),
  }
}));


function ActiveHoursReports(props) {
  const {
    selectedReport,
    reportTypes,
    enqueueSnackbar,
    useRawMetricNames = false,
  } = props;

  const [metrics, setMetrics] = useState([]);
  const [commentsBarData, setCommentsBarData] = useState([]);
  const [chatMessageBarData, setChatMessageBarData] = useState([]);
  const [loginsBarData, setLoginsBarData] = useState([]);
  const [totalBarData, setPlaybackBarData] = useState([]);

  // NumberOfComments: "0", NumberOfChatMessage: "0", NumberOfLogins: "0", TotalPlayback: "37"

  const [searchParams] = useSearchParams();

  const chartColors = useSelector((state) => state.brandStyle.chartColors);

  const classes = useStyles()

  const reqPRef = useRef({});

  const avoidDuplicateRequest = (reqParams) => {
    if (
      Object.values(reqParams).length === 0 &&
      Object.values(reqPRef.current).length !== 0
    ) return true;

    if (!(searchParams.has('from') && searchParams.has('to'))) return true; 

    return Object.keys(reqParams).every(key => reqParams[key] === reqPRef.current[key]);
  };

  const CustomTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={5} textAnchor="end" fill="#666" transform="rotate(270)">
        {payload.value}
      </text>
    </g>
  );

  const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

  // const Bars = useMemo(() => {
  //   return (
  //     <Bar dataKey="value">
  //       {barData.map((barCh, i) =>
  //         <Cell key={`${barCh.name}_i`} fill={chartColors[i]}/>
  //       )}
  //     </Bar>
  //   );
  // }, [barData]);

  useEffect(() => {
    const reqParams = {
      reportRoute: reportTypes[selectedReport],
      from: searchParams.has('from') ? searchParams.get('from') : undefined,
      to: searchParams.has('to') ? searchParams.get('to') : undefined,
    }
    if (avoidDuplicateRequest(reqParams)) return;

    reqPRef.current = { ...reqParams };
    reportingSrv.getDailyReports(reqParams)
      .then(reportData => {
        setMetrics(reportData.metrics);

        const aggregateData = {}

        const chartData = reportData?.dataItems.map((dataItem) => {
          const dataPoint = dataItem.data?.reduce((dtPoint, elem) => {
            const qntInt = parseInt(elem.quantity, 10);
            const quantity = (Number.isNaN(qntInt) || qntInt === undefined || qntInt === null)
              ? 0
              : qntInt;

            if (!aggregateData[elem.id]) {
              aggregateData[elem.id] = quantity;
            } else {
              aggregateData[elem.id] += quantity;
            }

            dtPoint[elem.id] = quantity;
            return dtPoint;
          }, {});

          let date = new Date(dataItem.date);
          date = date.toString() === 'Invalid Date'
            ? undefined
            : date.toISOString();

          return ({
            date,
            labelDate: date && dataItem.date?.substr(5),
            ...dataPoint,
          });
        });
        setCommentsBarData(() => Object.keys(aggregateData)
          .reduce((commentsBarData, metricName) => {
            commentsBarData.push({
              name: useRawMetricNames ? metricName : lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` }),
              value: aggregateData[metricName],
            });
            return commentsBarData;
          }, [])
        );
      })
      .catch(error => {
        setCommentsBarData([]);
        setMetrics([]);
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error.message,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_REPORT' })
          })
        });
      });
  }, [searchParams]);

  return (
    <>
      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {lookUp({
            key: 'CONSOLE_AGGREGATE_REPORTING_TEMPLATE',
            type: lookUp({ key: `CONSOLE_REPORTING_${selectedReport}`})
          })}
        </Typography>
      </Grid>

      <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
        <ResponsiveContainer aspect={2} width="85%">
          <BarChart data={commentsBarData}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={tickFormatter}/>
            <Tooltip
              cursor={{ fill: 'transparent' }}
              separator={''}
              formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
            />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </>
  );
}

export default ActiveHoursReports;
