import { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  getAggregatedBestContent,
  getAggregatedWorstContent,
} from 'services/reportingService';
import contentService from 'services/contentService';
import AlertService from 'services/alertService';
import Ranking from './Ranking';


const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}));


function ContentBillboard({ enqueueSnackbar }) {
  const classes = useStyles();
  const [bestsDetails, setBestsDetails] = useState([]);
  const [worstsDetails, setWorstsDetails] = useState([]);

  useEffect(() => {
    const displayError = (error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: error.message,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_REPORT' }),
        }),
      });
    };

    const getBestWorstDetails = (type, data, bWsetter) => {
      const bestWorstStats = data
        .dataItems[0]?.data?.filter((item) => Object.keys(item.quantity)?.length)
        .map(async (item, ind) => {
          const itemStats = item.quantity;
          const contentDetails = await contentService.getById(itemStats.ContentId)
          itemStats.id = itemStats.ContentId;
          itemStats.OriginalTitle = contentDetails.originalTitle;
          itemStats.rank = ind + 1;
          return itemStats;
        });

        Promise.allSettled(bestWorstStats)
        .then((bWstats) => {
          const successfulStats = bWstats.filter(stats => stats.status === 'fulfilled').map(stats => stats.value);
          const unsuccessfulStats = bWstats.filter(stats => stats.status === 'rejected' && (
            stats.reason.response.status !== 403 && stats.reason.response.status !== 404
          )).length;
          if (unsuccessfulStats > 0) {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error: `${unsuccessfulStats} pieces of data is missing for ${type} content list.`,
              context: lookUp({
                key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
                type: lookUp({ key: 'CONSOLE_REPORT' }),
              }),
            });
          }
          bWsetter(successfulStats)
        })
        .catch(displayError);
    };

    getAggregatedBestContent()
      .then((data) => getBestWorstDetails('top-watch', data, setBestsDetails))
      .catch(displayError);

    getAggregatedWorstContent()
      .then((data) => getBestWorstDetails('least-watched', data, setWorstsDetails))
      .catch(displayError);
  }, []);

  return (
    <Grid item container spacing={6} alignItems='flex-start'>
      {(!!worstsDetails?.length || !!bestsDetails?.length) && (
        <>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                {lookUp({ key: 'CONSOLE_TOP_CONTENT_BILLBOARD' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Ranking items={[...bestsDetails]} />
            </Grid>
          </Grid>
          <Grid item xs={6} container>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                {lookUp({ key: 'CONSOLE_WORST_CONTENT_BILLBOARD' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Ranking items={[...worstsDetails]} selector="worst" />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withSnackbar(ContentBillboard);
