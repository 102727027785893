import { lookUp } from 'services/stringService';
import { PageCard } from 'components';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ScreenLockPortraitOutlinedIcon from '@material-ui/icons/ScreenLockPortraitOutlined';
import FeaturedPlayList from '@material-ui/icons/FeaturedPlayList';
import PlaylistPlay from '@material-ui/icons/PlaylistPlay';

const pages = [
  {
    name: lookUp({ key: 'CONSOLE_VERSION' }),
    link: '/monetization/skus',
    icon: <FeaturedPlayList fontSize={'large'} />
  },
  {
    name: lookUp({ key: 'CONSOLE_PLAYBACK_POLICY' }),
    link: '/monetization/playback-policies',
    icon: <PlaylistPlay fontSize={'large'} />
  },
  {
    name: lookUp({ key: 'CONSOLE_CAMPAIGN' }),
    link: '/monetization/campaigns',
    icon: <ShoppingCartIcon fontSize={'large'} rotate={90}/>
  },
  {
    name: lookUp({ key: 'CONSOLE_MONETIZATION_PACKAGES' }),
    link: '/monetization/monetization-packages',
    icon: <ScreenLockPortraitOutlinedIcon fontSize={'large'} />
  },
];

const ProductHome = () => <PageCard pages={pages} />;

export default ProductHome;