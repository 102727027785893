import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Container,
  Grid,
  Slide,
  Link,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  useScrollTrigger,
} from '@material-ui/core';
import {
  ArtTrack,
  ListAlt,
  ViewList,
  Star,
  StarOutline,
  PlayCircleFilled,
  PlayCircleOutline,
  SlowMotionVideo
} from '@material-ui/icons';
import contentConst from 'constants/contentConst';
import Panel from '../common/BrPtPanel';
import MarkerBar from '../common/MarkerBar';
import SubtitleEditor from './SubtitleEditor';
import Timeline from '../common/BrPtTimeline';
import Selector from '../common/Selector';
import AudioVideoModal from './AudioVideoModal';
import VideoPlayer from './VideoPlayer';
import AudioPlayer from './AudioPlayer';
import {
  msToHHMMSS,
  HHMMSSToMs,
  HHMMSSMsToMs,
  getMs,
  msToTime,
} from 'helpers/time';
import { binaryFindIndex } from 'helpers/common';
import { EBreakpoint, segmentTypes, markerTitleMapping } from 'constants/index.js';
import markerService from 'services/markerService';
import parametersService from 'services/parametersService';
import jobManagerService from 'services/jobManagerService';
import transcodingProfilesService from 'services/transcodingProfilesService';
import {
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
} from 'services/collectionsServiceV2';
import { lookUp } from 'services/stringService';
import AlertService from 'services/alertService';
import navigationAction from 'store/actions/navigationAction';
import contentAction from 'store/actions/contentAction';
import { getSubtLang } from 'helpers/strings';
import { sidebarWidth } from 'constants/index.js';
import contentService from 'services/contentService';
import ThemedButton from 'components/ThemedButton';


const brPtProto = {
  id: undefined,
  index: null,
  type: EBreakpoint.INTRO,
  title: '',
  text: '',
  duration: '00:00:01',
  position: undefined,
  end: undefined,
  contentId: undefined,
  createdDate: '',
  ownerId: '',
  celebrityId: undefined,
  lastModifiedDate: '',
  lastModifiedBy: '',
  lang: undefined,
  hRef: '',
  awsMeta: '',
};


// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;
const useStyles = makeStyles((theme) => ({
  root: {
    // FIXME should normalize ALL sections who apply padding based on existing toolbars
    paddingTop: HEADER_MIN_HEIGHT + theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  appBar: (props) => ({
    top: HEADER_MIN_HEIGHT,
    width: `calc(100% - ${props.collapsed ? sidebarWidth.closed : sidebarWidth.open}px)`,
  }),
  editorFrame: {
    width: '100%',
    padding: 0,
    paddingTop: theme.spacing(2),
  },
  highlightFavoriteIcon: {
    color: theme.palette.secondary?.main || 'yellow'
  },
  highlightIcon: {
    color: theme.palette?.primary?.main,
  },
  highlightTab: {
    color: theme.palette.primary.main,
  },
  fromUntilButtons: {
    position: 'absolute',
    top: theme.spacing(.5),
    zIndex: 100,
  },
  shakaContainer: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    height: '100%',
    justifyContent: 'center',
    backgroundColor: 'black',
  },
  shakaPlayerGrid: {
    width: '100%',
    minHeight: 220,
    display: 'flex',
    justifyContent: 'center',
    '& .shaka-video-container' : {
      height: '100%',
      width: '100%'
    }
  },
  markPlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    maxHeight: '36px',
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0, 1),
    cursor: 'pointer',
  },
  markButton: {
    margin: theme.spacing(0, 1),
    minWidth: theme.spacing(12),
    boxShadow: 'none',
  },
  subLine: {
    marginTop: '2%',
  },
  tabSelect: {
    width: '100%',
  },
  timelineWrapper: {
    marginTop: theme.spacing(2),
  },
  metadataContainer: {
    maxWidth: '20%',
  },
  sectionHeader: {
    fontWeight: 'bold',
    margin: '20px 0 10px',
  },
  backToRemixRow: {
    marginTop: theme.spacing(3),
  },
  rightPanelFrame: (props) => ({
    width: '100%',
    position: 'relative',
    bottom: '4px',
    minHeight: props.isSubtitle ? 'calc(100vh - 185px)' : '140px',
    maxHeight: props.isSubtitle ? 'calc(100vh - 185px)' : props.panelHeight,
    overflow: props.isSubtitle ? 'hidden' : 'auto',
  }),
  subtEditCont: {
    width: '100%',
    height: 'calc(100% - 40px)',
    margin: 0,
    padding: 0,
  },

  subtitleOpen: {
    marginTop: '50px',
  }
}));


const AudioVideoEditor = ({
  enqueueSnackbar,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [markerType, markerId] = window.location.hash?.substring(1).split('|');
  if (markerType.includes('Subtitle')) {
    markerType = EBreakpoint.SUBTITLE;
  }

  brPtProto.type = markerType || EBreakpoint.INTRO;
  const subtLang = getSubtLang(window.location);

  const contentType = useSelector((state) => state.content.contentType);
  const contentData = useSelector((state) => state.content.contentData);
  const isChildContentRedirect = useSelector((state) => state.content.isChildContentRedirect);
  const isAutoCut  = useSelector((state) => state.content.isAutoCut);
  const userRole = useSelector((state) => state.auth.user.role);
  const ownId = useSelector((state) => state.auth.user.id);
  const { editor } = useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  let allowedTabs = editor?.content.filter(tab => tab !== 'transcribe' && tab !== "subtUpload" && tab !== "subtitle");
  allowedTabs.push('subtitle');
  if (contentData.type === 'Audio') {
    allowedTabs = allowedTabs.filter((tab) => tab !== 'vodToLive');
  }
  let subtitleTabs = ["transcribe", "subtUpload", "subtitling"];
  const isThereAWavFile = contentData.assets?.find((asset) => (
    (typeof asset.type === 'string' && asset.type.toLowerCase() === 'audio') &&
    (typeof asset.subType === 'string' && asset.subType.toLowerCase() === 'wav')
  ));
  const audioVisInd = allowedTabs.indexOf('audiovisualizer');
  if (!isThereAWavFile && audioVisInd > -1) allowedTabs.splice(audioVisInd, 1);

  if (markerType) {
    brPtProto.type = markerType;
  }
  if (markerId) {
    brPtProto.id = markerId;
  }
  const { id } = contentData;

  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);

  const trigger = useScrollTrigger();

  const [contentURL, setContentUrl] = useState('');    
  const [page, setPage] = useState(0);
  const [isFavourite, setIsFavourite] = useState(false);
  const [hasSubtitleMarkers, setHasSubtitleMarkers] = useState(true);
  const [autoPlay, setAutoPlay] = useState(false);
  const [skip, setSkip] = useState(false);
  const [selectedBrPt, setSelectedBrPt] = useState(brPtProto);
  const [brPtTypes, setBrPtTypes] = useState([]);
  const [brPtContainer, setBrPtContainer] = useState({ Subtitle: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [isBrPtsLineLoading, setIsBrPtsLineLoading] = useState(false);
  const [isAudioVideoModalOpen, setIsAudioVideoModalOpen] = useState({
    transcode: false,
    transcribe: false,
    translate: false,
    subtUpload: false,
    subtitle: false,
    enrichment: false,
    comprehend: false,
    cut: false,
    audiovisualizer: false,
    mediaProcessor: false,
    nftMinting: false,
    vodToLive: false,
    subtitling: false,
  });
  const [isHotKeyListenerOn, setIsHotKeyListenerOn] = useState(true);
  const [shakaPlayerRef, setShakaPlayerRef] = useState(null);
  const [panelWidth, setPanelWidth] = useState(0);
  const [panelHeight, setPanelHeight] = useState(0);
  const [overlayWidth, setOverlayWidth] = useState(0);
  const [overlayHeight, setOverlayHeight] = useState(0);
  const [subtEditorWidth, setSubtEditorWidth] = useState(0);
  const [subtEditorHeight, setSubtEditorHeight] = useState(0);
  const [selectedTag, setSelectedTag] = useState({
    displayName: '',
    tagId: markerId || markerType || '',
  });
  const [featureWhitelist, setFeatureWhitelist] = useState([]);
  const [featureBlacklist, setFeatureBlacklist] = useState([]);
  const [loop, setLoop] = useState(null);
  const [newMarker, setNewMarker] = useState(null);
  const [showSubtitleTabs, setShowSubtitleTabs] = useState(false);

  const brPtPanelListRef = useRef(null);
  const subtitleVlistRef = useRef(null);
  const overlayRef = useRef(null);
  const timelineListRef = useRef(null);
  const virtualImgListRef = useRef(null);
  const shakaResizeObserverInit = useRef(false);
  const subtEditorResizeObserverInit = useRef(false);

  const [subtitleFilter, setSubtitleFilter] = useState('')

  const classes = useStyles({
    panelHeight,
    collapsed: isSidebarCollapsed,
    isSubtitle: selectedBrPt?.type === EBreakpoint.SUBTITLE,
  });

  // set the size of the breakpoint panel and the canvas responsively
  const shakaCallbackRef = useCallback((shakaPlayer) => {
    if (shakaPlayer && shakaPlayer.videoElement) {
      if (!shakaResizeObserverInit.current) {
        shakaPlayer.videoElement.volume = .6;
        const resizeObs = new ResizeObserver((entries) => {
          for (const entry of entries) {
            if (entry.target.offsetWidth) {
              // setting the width of the <canvas> element
              setOverlayWidth(entry.target.offsetWidth);
            }
            if (entry.target.offsetHeight) {
              // setting the height of the breakpoint panel
              setPanelHeight(entry.target.offsetHeight);
              // setting the height of the <canvas> element
              setOverlayHeight(entry.target.offsetHeight - 2);
            }
            brPtPanelListRef.current?.resetAfterColumnIndex(0);
          }
        });
        resizeObs.observe(shakaPlayer.videoElement);
        setShakaPlayerRef(shakaPlayer);
        shakaResizeObserverInit.current = true;
      }
    }
  }, []);

  const subtEditorCallbackRef = useCallback((subtEditorCont) => {
    if (subtEditorCont) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target.offsetWidth) {
            setSubtEditorWidth(entry.target.offsetWidth);
          }
          if (entry.target.offsetHeight) {
            setSubtEditorHeight(entry.target.offsetHeight);
          }
          subtitleVlistRef.current?.resetAfterIndex(0);
        }
      });
      resizeObs.observe(subtEditorCont);
      subtEditorResizeObserverInit.current = true;
    }
  }, []);

  const getCurrentTime = () => shakaPlayerRef?.videoElement?.currentTime;

  const addToFavourites = () => {
    addCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavourite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = () => {
    deleteCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavourite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    switch (isFavourite) {
      case true:
        removeFromFavourite(id);
        break;
      case false:
        addToFavourites(id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const getIsAutoCut = async () => {
      try {
        const response = await parametersService.getByKey('Console:Content:Automated:Cut');
        const on = response?.value?.toLowerCase ? response.value.toLowerCase() === 'true' : false;
        dispatch(contentAction.setAutoCut({ on, paramChecked: true }));
      } catch (error) {
        dispatch(contentAction.setAutoCut({ ...isAutoCut, paramChecked: true }));
      }
    };

    if (!isAutoCut.paramChecked) getIsAutoCut();
  }, [isAutoCut.paramChecked]);

  useEffect(() => {
    if (!id) {
      setModel({ ...model });
      return;
    };
    const setAuth = () => {
      const videoUrl = contentData.assets?.find(e => e.subType === 'Original')?.downloadUrl;
      
      contentService.getPlaybackUrl(videoUrl)
      .then(u => setContentUrl(u));
    }
    setAuth();
    getCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavourite(true))
      .catch(() => setIsFavourite(false));
  }, []);

  const registerSubAssetRedirect = (redirParams) => {
    dispatch(contentAction.setIsChildContentRedirect(redirParams));
  };

  useEffect(() => {
    const getRemixToSubAssetRedirect = async () => {
      try {
        const response = await parametersService.getByKey('Console:Content:Redirect:Remix');
        const on = response?.value?.toLowerCase
          ? response.value.toLowerCase() === 'true'
          : false;
        registerSubAssetRedirect({ ...isChildContentRedirect, on, paramChecked: true });
      } catch (error) {
        registerSubAssetRedirect({ ...isChildContentRedirect, paramChecked: true });
      }
    };

    if (!isChildContentRedirect.paramChecked) getRemixToSubAssetRedirect();
  }, [isChildContentRedirect.paramChecked]);

  const panelWrapperCallback = useCallback((panelWrapper) => {
    if (panelWrapper) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          // set the width of the panel depending on the grid item of the shaka player
          if (entry.target.offsetWidth) setPanelWidth(entry.target.offsetWidth - 5);
          brPtPanelListRef.current?.resetAfterColumnIndex(0);
        }
      });
      resizeObs.observe(panelWrapper);
    }
  }, []);

  useEffect(() => {
    dispatch(
      navigationAction.setPageTitle(
        lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: contentData.originalTitle })
      )
    );
    setIsLoading(true);
    markerService
      .getMarkerTypes()
      .then((markerTypes) => {
        markerTypes.sort();
        const markerTypesMap = makeMarkerTypeLabels(markerTypes);
        if (!markerType || !markerTypes.includes(markerType)) {
          window.history.replaceState(null, "", `/content/${contentData.id}/editor#${markerTypes[0]}`);
        }
        setBrPtTypes(markerTypesMap);
        setBrPtContainer(() => {
          return Object.keys(markerTypesMap).reduce((container, type) => {
            container[type] = [];
            return container;
          }, {});
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_MARKERTYPES' }),
          }),
        });
        navigate('../');
      })
      .finally(() => setIsLoading(false));

    return () => {
     dispatch(navigationAction.setPageTitle(''))
    };
  }, []);

  useEffect(() => {
    setLoop(null);
    setSelectedBrPt((p) => ({
      ...p,
      markerKeyword: selectedTag.displayName || selectedTag.labelId,
    }));
    setFeatureWhitelist([]);
  }, [selectedTag]);

  useEffect(() => {
    isHotKeyListenerOn && document.addEventListener('keydown', keyDownHandler, true);
    return () => document.removeEventListener('keydown', keyDownHandler, true);
  }, [shakaPlayerRef, isHotKeyListenerOn]);

  useEffect(() => {
    if (loop) setSkip(false);
    if (skip) setLoop(false);
    if (loop) switchPlayPause('play');
  }, [loop, skip]);

  useEffect(() => {
    const checkSubs = () => {
      markerService
        .search(contentData.id, ({type: 'SubtitleTag'}))
        .then(res => setHasSubtitleMarkers(!!res.pageContent?.length > 0));
    };
     checkSubs();
  }, [])

  const makeMarkerTypeLabels = (markerTypes) => {
    return markerTypes.reduce((markerTypesMap, marker) => {
      markerTypesMap[marker] = lookUp({ key: `CONSOLE_MARKERTYPE_${marker}` });
      return markerTypesMap;
    }, {});
  };

  /**\
   * Listens to certain (video controlling) key down events.
   * Calls the 'handleCueButton()' depending on the key pressed down.
   * @param {*} event keydown event
   * @return undefined
  \**/
  const keyDownHandler = (event) => {
    if(event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') return;
    switch (event.key) {
      case 'I':
      case 'i':
        handleCueButton('from');
        break;
      case 'O':
      case 'o':
        handleCueButton('until');
        break;
      case 'P':
      case 'p':
        if (shakaPlayerRef) {
          const { videoElement } = shakaPlayerRef;
          videoElement.paused ? switchPlayPause('play') : switchPlayPause('pause');
        }
        break;
      default:
        return;
    }
  };

  /**\
   * Calculates the widths (ink: inner and wrapper: outer) of the break point -> ink dot
   * or ink stripe.
   * @param {*} point on break point
   * @param {*} brPtsOfType array of breakpoints of given type
   * @param {*} prevInd index of the previous breakpoint
   * @return a point object
  \**/
  const calcInkDotWidth = (brPt, brPtsOfType, prevInd = -1) => {
    // if no start position skip the breakpoint
    if (brPt.position) {
      if (brPt.position.split('.')[1] && brPt.position.split('.')[1].length > 3) {
        brPt.position =
          brPt.position.split('.')[0] + '.' + brPt.position.split('.')[1].substr(0, 3);
      }
      // if end position -> ink stripe: (<-------------->)
      if (brPt.end) {
        if (brPt.end.split('.')[1] && brPt.end.split('.')[1].length > 3) {
          brPt.end = brPt.end.split('.')[0] + '.' + brPt.end.split('.')[1].substr(0, 3);
        }
        // calculate wrapperWidth if ink stripe:
        // 200px -> 10,000Ms (10sec) ==> 50Ms -> 1px
        const untilMs = HHMMSSMsToMs(brPt.end);
        if (prevInd === -1) {
          brPt.wrapperWidth = untilMs / 50;
        } else {
          const fromMs = HHMMSSMsToMs(brPtsOfType[prevInd].end);
          brPt.wrapperWidth = (untilMs - fromMs) / 50;
        }
        // calculate width of inkDot
        const start = HHMMSSMsToMs(brPt.position);
        const end = HHMMSSMsToMs(brPt.end);
        brPt.inkDotWidth = `${(end - start) / 50}px`;
        // if no end position -> ink dot: (<>)
      } else {
        // calculate wrapperWidth if ink dot:
        // 200px -> 10,000Ms (10sec) ==> 50Ms -> 1px
        const untilMs = HHMMSSMsToMs(brPt.position);
        // calculate width of inkDot
        brPt.inkDotWidth = '20px';
        if (prevInd === -1) {
          brPt.wrapperWidth = untilMs / 50 + 10;
        } else {
          const fromMs = HHMMSSMsToMs(brPtsOfType[prevInd].position);
          brPt.wrapperWidth = (untilMs - fromMs) / 50;
        }
      }
      return brPt;
    }
    return null;
  };

  /**\
   * Checks where the last breakpoint is and creates (or replaces) a virtual (invisible)
   * breakpoint to adjust the total width of the timeline container (timelineListRef)
   * to the total width of the image container (virtualImgListRef).
   * @param {*} brPts array of breakpoints of given type
   * @return brPtsOfType
  \**/
  const adjustTimelineEnd = (brPts) => {
    if (brPts.length === 0) return brPts;
    // take out the last breakpoint if that is the invisible
    const filteredBrPts = brPts.filter(brPts => !brPts.isInvisible)
    const lastElem = filteredBrPts[filteredBrPts.length - 1];
    const lastElemEnd = lastElem.end ? lastElem.end : lastElem.position;
    const lastElemEndMs = HHMMSSMsToMs(lastElemEnd);
    if (contentData.duration > lastElemEndMs) {
      // make a last virtual breakpoint to assure that the two virtual grids
      // (scrubbing images + breakpoint dots) are of equal widths
      filteredBrPts[filteredBrPts.length] = {
        wrapperWidth: (contentData.duration - lastElemEndMs) / 50,
        isInvisible: true,
      };
    }
    return filteredBrPts;
  };

  /**\
   * Make requests with markerService iteratively until response finished === false.
   * Calls 'addInkWidth()' helper function to fill up 'brPtsOfType' array with
   * breakpoints with calculated width properties (for the ink dots).
   * Calls 'adjustTimelineEnd()' helper to reset virtual lists and add a last element.
   * @param {*} brPtType type of the breakpoint (listed in brPtTypes)
   * @param {*} tagCloudParam query string param to get the tags of a type (listed in tagCategories)
   * @return undefined
  \**/
  const loadMarkers = async (brPtType, tagCloudParam) => {
    const brPtsOfType = [];
    let idOrToken = id;
    let finished = false;
    setBrPtContainer((container) => ({ ...container, [brPtType]: brPtsOfType }));
    setIsBrPtsLineLoading(true);
    try {
      while (!finished) {
        const queryParams = { type: brPtType, itemLimit: 1000, ...tagCloudParam };
        const reqResp = await markerService.search(idOrToken, queryParams);
        reqResp.pageContent.forEach((point) => {
          const calcedPoint = calcInkDotWidth(point, brPtsOfType, brPtsOfType.length - 1);
          calcedPoint && brPtsOfType.push(calcedPoint);
        }, []);
        idOrToken = reqResp.pagingToken;
        finished = reqResp.finished;
        if (finished) {
          if (brPtsOfType.length > 0) {
            setBrPtContainer((container) => {
              return { ...container, [brPtType]: adjustTimelineEnd(brPtsOfType) };
            });
            timelineListRef.current?.resetAfterColumnIndex(0);
          }
        }
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: `CONSOLE_MARKER` }),
        }),
      });
    } finally {
      setIsBrPtsLineLoading(false);
      return;
    }
  };

  /**\
   * Inserts a new object to a sorted array  of objects (in place) while keeping it sorted.
   * @param {*} objArray sorted array of objects
   * @param {*} property the property based on the array has been sorted
   * @param {*} element the object which is being inserted
   * @return undefined
  \**/
  const insertElement = (objArray, property, element) => {
    // if the last breakpoint is the space filling one (invisible): take it out
    let len = objArray.length;
    const lastElem = objArray[len - 1];
    if (lastElem && lastElem.isInvisible) {
      objArray.pop();
      len = objArray.length;
    }
    let brPtIndex = 0;
    // first breakpoint to put into the (empty) array
    if (len === 0) {
      objArray.push(calcInkDotWidth(element, []));
      // put a breakpoint before all the others
    } else if (objArray[0][property] >= element[property]) {
      objArray.unshift(calcInkDotWidth(element, objArray));
      objArray[1] = calcInkDotWidth(objArray[1], objArray, 0);
      // put a breakpoint after all the others
    } else if (objArray[len - 1][property] <= element[property]) {
      objArray.push(calcInkDotWidth(element, objArray, len - 1));
      brPtIndex = objArray.length-1;
      // put a breakpoint anywhere else
    } else {
      const insertPtInd = binaryFindIndex(objArray, property, element[property]);
      objArray.splice(insertPtInd, 0, calcInkDotWidth(element, objArray, insertPtInd - 1));
      objArray[insertPtInd + 1] = calcInkDotWidth(objArray[insertPtInd + 1], objArray, insertPtInd);
      brPtIndex = insertPtInd;
    }
    return brPtIndex;
  };

  const switchPlayPause = (type) => {
    const { videoElement } = shakaPlayerRef || {};
    if (videoElement) videoElement[type]();
  };

  const resetSelectedBrPt = (type = EBreakpoint.INTRO) => {
    const base = { ...brPtProto, type };
    setSelectedBrPt(base);
  };

  const switchAudioVideoModal = (modalType, open) => {
    if (modalType === 'cut' && isAutoCut.on) {
      if (!brPtContainer.Cut.length) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          context: lookUp({ key: 'CONSOLE_NO_CUT_EVENTS' }),
        });
        return;
      }
      const reqBody = {
        name: `CutJob_${Date.now()}`,
        referenceObjectId: contentData.id,
        inputFileLocation: contentData.assets?.find((e) => e.subType === 'Original')?.objectUrl,
        streamKind: 'MP4',
        type: 'CUT_VIDEO',
        markerType: 'Cut',
        notificationEvents: ['SUCCEED', 'SCHEDULED', 'FAILED'],
        locale: '',
      };
      reqBody.cutEvents = brPtContainer.Cut.reduce((acc, brPt) => {
        if (brPt.position) acc.push({ startTimeCode: brPt.position, endTimeCode: brPt.end });
        return acc;
      }, []);
      transcodingProfilesService
        .getAll()
        .then((profiles) => {
          const mp4Profile = profiles.find((profile) => profile.name === 'MP4 Cut');
          reqBody.profileUrl = mp4Profile.profileUrl;
          return jobManagerService.cutVideo(reqBody);
        })
        .then((jobResponse) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} ${jobResponse.streamKind} -> ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`,
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
          });
        });
    } else {
      setIsAudioVideoModalOpen((AudioVideoModal) => ({ ...AudioVideoModal, [modalType]: open }));
    }
  };

  /**\
   * Handles pressing '[CUE IN' 'CUE OUT]' buttons (or 'i' 'o' keys).
   * @param {*} type 'from' or 'until' strings
   * @return undefined
  \**/
  const handleCueButton = (type) => {
    const { videoElement } = shakaPlayerRef;
    const rawTime = videoElement.currentTime;
    const decimals = (rawTime - Math.floor(rawTime)).toFixed(3).toString().slice(2);
    const HHMMSSTime = msToHHMMSS(rawTime * 1000);
    const formattedTime = `${HHMMSSTime}.${decimals}`;
    if (selectedBrPt.id) {
      setNewMarker(null);
      switch (type) {
        case 'from':
          setSelectedBrPt((brPt) => ({ ...brPt, position: formattedTime }));
          updateBreakpoint(selectedBrPt.type, { position: formattedTime });
          break;
        case 'until':
          setSelectedBrPt((brPt) => ({ ...brPt, end: formattedTime }));
          updateBreakpoint(selectedBrPt.type, { end: formattedTime });
          if (newMarker) resetSelectedBrPt(selectedBrPt.type);
          break;
        default:
          break;
      }
    } else {
      segmentTypes.includes(selectedBrPt.type) && setNewMarker(true);
      addBreakpoint(selectedBrPt.type);
    }
  };

  /**\
   * Handles breakpoint change, sets selected brPt with new value given.
   * @param {*} type type of the brPt that
   * @param {*} value the value ot the type to be updated
   * @return undefined
  \**/
  const handleBrPtChange = (type, value) => {
    switch (type) {
      case 'from':
        setSelectedBrPt((brPt) => ({ ...brPt, position: value }));
        break;
      case 'until':
        setSelectedBrPt((brPt) => ({ ...brPt, end: value }));
        break;
      case 'title':
        setSelectedBrPt((brPt) => ({ ...brPt, title: value }));
        break;
      case 'description':
        setSelectedBrPt((brPt) => ({ ...brPt, description: value }));
        break;
      case 'text':
        setSelectedBrPt((brPt) => ({ ...brPt, text: value }));
        break;
      default:
        break;
    }
  };

  /**\
   * Called when 'play' event is fired. Sets the index ('nextBrPtInd') of the next highlighted
   * item.
   * @param {*} event 'play' event
   * @return undefined
  \**/
  const startAutoHighlight = (event) => {
    // DANGER: I've commented the code. Seems unnecessary. Looks good in my testing but please check further.

    // const now = Math.floor(event.currentTarget.currentTime * 1000);
    // const brPtsOfType = brPtContainer[selectedBrPt.type];
    // // don't proceed if there are no brPts displayed
    // if (brPtsOfType?.length > 0) {
    //   const playPosition = msToHHMMSS(now);
    //   const ind = binaryFindIndex(brPtsOfType, 'position', playPosition);
    //   if (isBoundingBoxed) clearBoundingBox();
    //   // don't proceed if 'ind' is the last index (the empty brPt) of 'brPtContainer'
    //   if (ind !== undefined && ind < brPtContainer[selectedBrPt.type].length - 1) {
    //     const nextBrPt = brPtsOfType[ind];
    //     const nextPosition = nextBrPt.position;
    //     const regx = /^\d\d:\d\d:\d\d/g;
    //     const playPosShort = playPosition?.match(regx);
    //     const nextPosShort = nextPosition?.match(regx);
    //     const isPosEqual = playPosShort?.[0] === nextPosShort?.[0];
    //     // if we start the video precisely at a brPt -> higlight it and scroll to it
    //     if (isPosEqual) {
    //       drawBoundingBox(brPtsOfType[ind]);
    //       if (selectedBrPt.type === 'Subtitle') {
    //         subtitleVlistRef.current.scrollToItem(ind, 'center');
    //       } else {
    //         timelineListRef.current?.scrollToItem({ align: 'center', columnIndex: ind });
    //         brPtPanelListRef.current?.scrollToItem({
    //           align: 'center',
    //           columnIndex: ind,
    //           rowIndex: ind,
    //         });
    //       }
    //       setSelectedBrPt(nextBrPt);
    //       if (brPtsOfType.length !== ind + 2) setNextBrPtInd(ind + 1);
    //     } else {
    //       setNextBrPtInd(ind);
    //     }
    //   }
    // }
  };

  /**\
   * Called when 'timeupdate' event fires (periodically after the playback started).
   * @param {*} event 'timeupdate' event
   * @return undefined
  \**/
  const autoHighlight = (event) => {
    // return; // TODO: enable this and prevent messing with on-the-fly marker edition
    const now = Math.floor(event.currentTarget.currentTime * 1000);
    const brPtsOfType = brPtContainer[selectedBrPt.type];

    // Check if selected brpt is stil included in current time then return.
    if(selectedBrPt.id && now >= HHMMSSMsToMs(selectedBrPt.position) && now <= HHMMSSMsToMs(selectedBrPt.end)){
      return;
    }

    // Clear the canvas regardless
    clearBoundingBox()

    const thisBrPt = brPtsOfType.find(
      (brpt) => now >= HHMMSSMsToMs(brpt.position) && now <= HHMMSSMsToMs(brpt.end),
    );

    if(!thisBrPt){
      if (
        selectedBrPt.position && selectedBrPt.end &&
        ((getMs(selectedBrPt.end) - getMs(selectedBrPt.position)) !== 500)
      ) {
        resetSelectedBrPt(selectedBrPt.type);
        setSelectedBrPt(brPtProto);
      }

      return;
    }
    
    const thisBrptIndex = brPtsOfType.indexOf(thisBrPt);

    setSelectedBrPt(thisBrPt)

    drawBoundingBox(thisBrPt);
    if (thisBrPt.type === EBreakpoint.SUBTITLE) {
      subtitleVlistRef.current.scrollToItem(thisBrptIndex, 'center');
    } else {
      timelineListRef.current?.scrollToItem({ align: 'center', columnIndex: thisBrptIndex });
      brPtPanelListRef.current?.scrollToItem({
        align: 'center',
        columnIndex: thisBrptIndex,
        rowIndex: thisBrptIndex,
      });
    }
        
  };

  /**\
   * Wipes down the whole canvas.
   * @return undefined
  \**/
  const clearBoundingBox = () => {
    const canvas = overlayRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
  };

  /**\
   * Clears the canvas then draws a bounding box around the recognized item in the video.
   * E.g. draws a rectangle around the face of a celeb.
   * @param {*} breakpoint a breakpoint object (like 'brPtProto')
   * @return undefined
  \**/
  const drawBoundingBox = (breakpoint) => {
    const awsMetaString = breakpoint.awsMeta;
    if (breakpoint.type !== EBreakpoint.SUBTITLE && !awsMetaString) return;

    const canvas = overlayRef.current;
    const canvasWidth = canvas.clientWidth;
    const canvasHeight = canvas.clientHeight;
    const ctx = canvas.getContext('2d');

    if (breakpoint.type === EBreakpoint.SUBTITLE) {
      ctx.font = '16px Helvetica';
      ctx.textAlign ='center';

      const lines = breakpoint.text.split('\n')
      
      lines.forEach((line, i) => {
        ctx.letterSpacing = '-1px';
        ctx.fillStyle = '#000';
        const blackText = Array(parseInt(canvasWidth))
          .fill('█')
          .join('');
          ctx.textAlign ='left';
          ctx.fillText(blackText, 0, canvasHeight - 60 - 18 * (lines.length - i));
          
          ctx.textAlign ='center';
        ctx.letterSpacing = '0px';
        ctx.fillStyle = '#ffffff';
        ctx.fillText(line, canvasWidth / 2, canvasHeight - 60 - 18 * (lines.length - i));
      });
      
    } else {
      const awsMetaData = JSON.parse(awsMetaString);
      let boundingBox = null;

      switch (breakpoint.type) {
        case 'Celebrity':
          boundingBox = awsMetaData.Face.BoundingBox;
          break;
        case 'Label':
          const instances = awsMetaData.Instances;
          if (instances.length) {
            boundingBox = instances[0].BoundingBox;
          }
          break;
        default:
          break;
      }
      if (!boundingBox) return;
      const xPos = canvasWidth * boundingBox.Left;
      const yPos = canvasHeight * boundingBox.Top;
      const frameWidth = canvasWidth * boundingBox.Width;
      const frameHeight = canvasHeight * boundingBox.Height;
      ctx.strokeStyle = 'orange';
      ctx.strokeRect(
        Math.round(xPos) + 0.5,
        Math.round(yPos) + 0.5,
        Math.round(frameWidth),
        Math.round(frameHeight)
      );
    }
  };

  // TODO -> check up on this
  const handlePause = (event) => {};

  // TODO -> refactor this
  const seek = (value = '') => {
    let splitted = '';
    if (value.indexOf('-->') !== -1) {
      const temp = value.split('-->');
      splitted = temp[0].slice(0, temp[0].length - 1);
    } else {
      splitted = value.replace(',', '.');
    }
    const converted = moment.duration(splitted).asSeconds();
    shakaPlayerRef.videoElement.currentTime = converted;
  };

  /**\
   * Sets the brPt with the given 'index' to selected + calls 'seek()' to navigate to the
   * position of the breakpoint in the video.
   * @param {*} type brPt type
   * @param {*} index newly selected brPt index
   * @return undefined
  \**/
  const handleBreakpointSelect = (type, index, id) => {
    if (newMarker) handleCueButton('until'); // if a new marker is being defined, set "end" on deselect.
    switchPlayPause('pause');
    const newSelectedBrPt = id
      ? brPtContainer[type].find((e) => e.id === id)
      : brPtContainer[type][index];
    newSelectedBrPt.index = index;
    setSelectedBrPt(newSelectedBrPt);
    seek(newSelectedBrPt?.position);
    clearBoundingBox()
    drawBoundingBox(newSelectedBrPt)
    autoPlay && switchPlayPause('play');
  };

  const handleBreakpointDeselect = (type) => {
    if (newMarker) handleCueButton('until'); // if a new marker is being defined, set "end" on deselect.
    setSelectedBrPt({ ...brPtProto, type });
  };

  const showNotValidMessage = (message) => {
    AlertService.displayError({
      msgBar: enqueueSnackbar,
      error: message,
    });
    return true;
  };

  /**\
   * Checks the validity of a brPt based on the 'validity' object params.
   * @param {*} validity an object with the validation params
   * @return a bool (true if not valid, false if it is)
  \**/
  const checkBrPtValidity = (validity) => {
    if (!validity.fromMs) {
      return showNotValidMessage(lookUp({ key: 'CONSOLE_CUE_IN_EMPTY' }));
    } else if (validity.tooBigFrom) {
      return showNotValidMessage(lookUp({ key: 'CONSOLE_CUE_IN_OFF' }));
    } else if (validity.tooBigUntil) {
      return showNotValidMessage(lookUp({ key: 'CONSOLE_CUE_OUT_OFF' }));
    } else if ((validity.isChapter || validity.isIntro) && validity.fromBiggerThanUntil) {
      return showNotValidMessage(lookUp({ key: 'CONSOLE_CUE_IN_INVALID' }));
    } else if ((validity.isChapter || validity.isIntro) && !selectedBrPt.end) {
      return false; //showNotValidMessage(`Cue out shouldn't be empty!`);
    }
    return false;
  };

  /**\
   * Sets up the 'validity' params and calls 'checkBrPtValidity()' to check if a brPt is
   * valid or not. Checks if a brPt is a dot point or one with a length.
   * @return checkBrPtValidity() result (a boolean)
  \**/
  const notValidBreakpoint = (brPt) => {
    const { videoElement } = shakaPlayerRef;
    const validity = {
      fromMs: HHMMSSToMs(brPt.position) || HHMMSSToMs(brPt.position) === 0,
      tooBigFrom: false,
      tooBigUntil: false,
      fromBiggerThanUntil: false,
      isIntro: brPt.type === EBreakpoint.INTRO,
      isChapter: brPt.type === EBreakpoint.CHAPTER,
      videoDurationInMilliSecs: videoElement.duration * 1000,
    };
    if (brPt.end) {
      validity.untilMs = HHMMSSToMs(brPt.end);
      validity.tooBigFrom = validity.fromMs >= validity.videoDurationInMilliSecs;
      validity.tooBigUntil = validity.untilMs >= validity.videoDurationInMilliSecs;
      validity.fromBiggerThanUntil = validity.fromMs > validity.untilMs;
    }
    return checkBrPtValidity(validity);
  };

  /**\
   * Adds a new breakpoint. Calls 'insertElement()'. Adjusts the end of the timeline by calling
   * 'adjustTimelineEnd()'. Resets the timeline - 'resetAfterColumnIndex()'.
   * @param {*} type type of the breakpoint
   * @return undefined
  \**/
  const addBreakpoint = (type) => {
    const copyBrPt = { ...brPtProto, type };
    const { videoElement } = shakaPlayerRef;
    const rawTime = videoElement.currentTime;
    const formattedTime = msToTime(rawTime * 1000);

    const thisOrNextGap = (formattedTime) => {
      const coincidence = brPtContainer[copyBrPt.type]?.find((e) => {
        return getMs(e.position) === getMs(formattedTime);
      });
      return coincidence?.end ? thisOrNextGap(coincidence.end) : formattedTime;
    }

      copyBrPt.position = thisOrNextGap(formattedTime) || '00:00:00';

    if (type === EBreakpoint.SUBTITLE) {
      let lastBrPt = brPtContainer.Subtitle[brPtContainer.Subtitle.length - 1];
      if (lastBrPt.isInvisible) {
        lastBrPt = brPtContainer.Subtitle[brPtContainer.Subtitle.length - 2];
      }
      if (copyBrPt.position === '00:00:00') {
        copyBrPt.position = lastBrPt.end;
      }
      if (subtLang) {
        copyBrPt.lang = subtLang;
      }
      copyBrPt.text = '—'
    }

    if (
      markerTitleMapping[copyBrPt.type] !== null &&
      !copyBrPt[markerTitleMapping[copyBrPt.type] || 'title']
    ) {
      copyBrPt[markerTitleMapping[copyBrPt.type] || 'title'] = `${
        (brPtContainer[copyBrPt.type]?.length || 1)
      }. ${lookUp({ key: `CONSOLE_${copyBrPt.type}` })}`;
    }
    if (segmentTypes.includes(copyBrPt.type) && !copyBrPt.end) {
      copyBrPt.end = msToTime(getMs(copyBrPt.position) + 500);
    }
    if (notValidBreakpoint(copyBrPt)) return Promise.resolve(null);

    return markerService
      .create({ ...copyBrPt, contentId: id })
      .then((newBrPt) => {
        let brPtIndex = 0;
        setBrPtContainer((brPtContainer) => {
          brPtIndex = insertElement(brPtContainer[type], 'position', newBrPt);
          return { ...brPtContainer, [type]: adjustTimelineEnd(brPtContainer[type]) };
        });
        setSelectedBrPt(newBrPt);
        setNewMarker((o) => (o === true ? newBrPt.id : null));
        if (type === EBreakpoint.SUBTITLE) {
          subtitleVlistRef.current?.resetAfterIndex(brPtIndex);
          subtitleVlistRef.current.scrollToItem(brPtIndex, 'center');
        } else {
          timelineListRef.current?.resetAfterColumnIndex(brPtIndex);
        }
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_CREATE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_BREAKPOINT' }),
          }),
        });
      });
  };

  /**\
   * Updates breakpoint data. Takes out the breakpoint to be updated + the last (invisible)
   * breakpoint. Calls 'insertElement()'. Resets the timeline.
   * @param {*} updatedIndex index of the breakpoint to be updated
   * @param {*} type type of the breakpoint
   * @return undefined
  \**/
  const updateBreakpoint = (type, updateProps) => {
    const updatedBrPt = { ...selectedBrPt, ...updateProps };
    if (notValidBreakpoint(updatedBrPt)) return Promise.resolve(null);
    if (
      JSON.stringify(brPtContainer[type].find((e) => e.id === updatedBrPt?.id)) ===
      JSON.stringify(updatedBrPt)
    )
      return Promise.resolve(null);

    const isOverlapTime = getMs(updatedBrPt.position) > getMs(updatedBrPt.end);
    if (segmentTypes.includes(updatedBrPt.type) && isOverlapTime) {
      updatedBrPt.end = msToTime(getMs(updatedBrPt.position) + 500);
    }
      
    return markerService
      .edit(updatedBrPt)
      .then(() => {
        setBrPtContainer((brPtContainer) => {
          const newBrPts = [...brPtContainer[type]].filter((e) => e.id !== updatedBrPt.id);
          insertElement(newBrPts, 'position', updatedBrPt);
          return { ...brPtContainer, [type]: adjustTimelineEnd(newBrPts) };
        });
        timelineListRef.current?.resetAfterColumnIndex(0);
        isOverlapTime && setSelectedBrPt(updatedBrPt);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${markerType}` }),
          }),
        });
      });
  };

  /**\
   * Deletes a breakpoint. Recalculates the last (invisible) breakpoint. Recalculates the timeline.
   * @param {*} removedIndex index of the breakpoint to be removed
   * @param {*} id id of the breakpoint
   * @param {*} type type of the breakpoint
   * @return undefined
  \**/
  const deleteBreakpoint = (removedIndex, id, type) => {
    markerService
      .remove(id)
      .then(() => {
        let removed = null;
        setBrPtContainer((brPtContainer) => {
          const brPts = brPtContainer[type];
          // take out the breakpoint we want to remove + the filler (invisible) one
          removed = brPts.splice(removedIndex, 1);
          // update the wrapperWidth of the next breakpoint after the removed one
          if (brPts.length > 0 && brPts.length > removedIndex) {
            calcInkDotWidth(brPts[removedIndex], brPts, removedIndex - 1);
          }
          // insert the space filler (invisible) breakpoint as the last one
          return { ...brPtContainer, [type]: adjustTimelineEnd(brPts) };
        });
        // if the selected breakpoint is removed reset the selection
        if ((removed.length && removed[0].id === selectedBrPt.id) || id === selectedBrPt.id)
          resetSelectedBrPt(selectedBrPt.type);
        timelineListRef.current?.resetAfterColumnIndex(0);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_BREAKPOINT' }),
          }),
        });
      });
  };

  const filterByFeature = (e) => {
    if (featureBlacklist.length === 0 && featureWhitelist.length === 0) return e;
    const filtered = {
      ...e,
      [selectedBrPt.type]: e[selectedBrPt.type].filter((m) => {
        if (!m.awsMeta) return true;
        const meta = JSON.parse(m.awsMeta);
        const features = Object.keys(meta).filter((k) => meta[k].Value === true);
        features.push(meta.Gender?.Value?.Value);
        let i = features.length;
        while (i--) {
          if (
            (featureWhitelist.length === 0 ||
              featureWhitelist.map((e) => e.toLowerCase()).includes(features[i].toLowerCase())) &&
            !featureBlacklist.includes(features[i])
          )
            return true;
        }
        return false;
      }),
    };
    return filtered;
  };

  return (
    <Box flex={1}>
      <Grid container direction="row" item>
        <Grid item xs={12} className={classes.backToRemixRow}>
         {isChildContentRedirect.on && isChildContentRedirect.url && (
            <ThemedButton
              color="secondary"
              component={RouterLink}
              to={isChildContentRedirect.url}
              onClick={() => registerSubAssetRedirect({ ...isChildContentRedirect, url: '' })}
            >
              {lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_BUTTON' })}
            </ThemedButton>
          )}
        </Grid>
      </Grid>
      <section className={classes.editorFrame}>
        <Grid className={showSubtitleTabs ? classes.subtitleOpen : ''} container direction="row" spacing={1}>
          <Grid item xs={12} className={classes.tabBar}>
            <Slide appear={false} direction="down" in={!trigger}>
              <AppBar position="absolute" className={classes.appBar} color="inherit">
                <Tabs
                  className={classes.tabs}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="on"
                  aria-label="Edit page tabs"
                >
                  <Tooltip title={lookUp({ key: 'CONSOLE_AUTOPLAY_HELPERTEXT' })}>
                    <Tab
                      className={autoPlay && classes.highlightTab}
                      onClick={() => setAutoPlay(o => !o)}
                      label={lookUp({ key: 'CONSOLE_AUTOPLAY' })}
                      icon={!autoPlay ? <PlayCircleOutline /> : <PlayCircleFilled className={classes.highlightIcon} />}
                    />
                  </Tooltip>
                  <Tooltip title={lookUp({ key: 'CONSOLE_PLAY_TRIMMED_HELPERTEXT' })}>
                    <Tab
                      className={skip && classes.highlightTab}
                      onClick={() => setSkip(o => !o)}
                      label={lookUp({ key: 'CONSOLE_PLAY_TRIMMED' })}
                      icon={!skip ? <SlowMotionVideo /> : <SlowMotionVideo className={classes.highlightIcon} />}
                    />
                  </Tooltip>
                  {Object.keys(isAudioVideoModalOpen)
                    .filter((e) => allowedTabs.includes(e))
                    .map((e) => (
                      <AudioVideoModal
                        modalType={e}
                        key={e}
                        isOpen={isAudioVideoModalOpen}
                        switchModal={switchAudioVideoModal}
                        contentData={contentData}
                        brPtContainer={brPtContainer}
                        selectedBrPt={selectedBrPt}
                        contentType={contentType}
                        hasSubtitleMarkers={hasSubtitleMarkers}
                        showSubtitleTabs={showSubtitleTabs}
                        setShowSubtitleTabs={setShowSubtitleTabs}
                      />
                    ))}
                  <div style={{flex: 1}}> </div>
                  {isChildContentRedirect.on && isChildContentRedirect.url && (
                    <Tooltip title={lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_TOOLTIP' })}>
                      <Tab
                        label={lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_ICON' })}
                        icon={<ArtTrack />}
                        component={RouterLink}
                        to={isChildContentRedirect.url}
                        onClick={() => registerSubAssetRedirect({ ...isChildContentRedirect, url: '' })}
                      />
                    </Tooltip>
                  )}
                  {allowedTabs.includes('favorite') && (
                    <Tooltip
                      title={
                        isFavourite
                          ? lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' })
                          : lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' })
                      }
                    >
                      <Tab
                        onClick={setFavourite}
                        label={lookUp({ key: 'CONSOLE_FAVOURITE' })}
                        icon={!isFavourite ? <StarOutline /> : <Star className={classes.highlightFavoriteIcon} />}
                      />
                    </Tooltip>
                  )}
                  {allowedTabs.includes('metadata') && (
                    <Tooltip title={lookUp({ key: 'CONSOLE_METADATA_EDITOR_HELPERTEXT' })}>
                      <Link component={RouterLink} to={`/content/${contentData.id}/edit#basic`}>
                        <Tab
                          label={lookUp({ key: 'CONSOLE_METADATA_EDITOR' })}
                          icon={<ListAlt />}
                        />
                      </Link>
                    </Tooltip>
                  )}
                  {allowedTabs.includes('logs') && (
                    <Tooltip title={lookUp({ key: 'CONSOLE_LOGS_HELPERTEXT' })}>
                      <a href={`/access/logs/Content/${id}`}>
                        <Tab
                          label={lookUp({ key: 'CONSOLE_LOGS' })}
                          icon={<ViewList />}
                        />
                      </a>
                    </Tooltip>
                  )}
                </Tabs>
                {showSubtitleTabs && 
                  <Tabs
                    className={classes.tabs}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    aria-label="Edit page tabs"
                  >
                    {Object.keys(isAudioVideoModalOpen)
                      .filter((e) => subtitleTabs.includes(e))
                      .map((e) => (
                        <AudioVideoModal
                          modalType={e}
                          key={e}
                          isOpen={isAudioVideoModalOpen}
                          switchModal={switchAudioVideoModal}
                          contentData={contentData}
                          brPtContainer={brPtContainer}
                          selectedBrPt={selectedBrPt}
                          contentType={contentType}
                          hasSubtitleMarkers={hasSubtitleMarkers}
                        />
                    ))}
                  </Tabs>
                }
              </AppBar>
            </Slide>
          </Grid>

          <Grid item xs={12} sm={7} xl={4} container className={classes.shakaContainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              className={classes.fromUntilButtons}
            >
              <Grid item>
                <Tooltip arrow={true} title={lookUp({ key: 'CONSOLE_CUEIN_HOTKEY_HELPERTEXT' })}>
                  <ThemedButton
                    onClick={() => handleCueButton('from')}
                    color="secondary"
                  >
                    {lookUp({ key: 'CONSOLE_CUE_IN_BUTTON' })}
                  </ThemedButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip arrow={true} title={lookUp({ key: 'CONSOLE_CUEOUT_HOTKEY_HELPERTEXT' })}>
                  <ThemedButton
                    color="secondary"
                    onClick={() => handleCueButton('until')}
                  >
                    {lookUp({ key: 'CONSOLE_CUE_OUT_BUTTON' })}
                  </ThemedButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item className={classes.shakaPlayerGrid}>
              {contentConst.videoTypes.includes(contentType) ? (
                <VideoPlayer
                  shakaCallbackRef={shakaCallbackRef}
                  loop={loop}
                  setLoop={setLoop}
                  skip={skip}
                  contentURL={contentURL}
                  overlayWidth={overlayWidth}
                  overlayHeight={overlayHeight}
                  startAutoHighlight={startAutoHighlight}
                  autoHighlight={autoHighlight}
                  handlePause={handlePause}
                  overlayRef={overlayRef}
                  breakpoints={filterByFeature(brPtContainer)?.[selectedBrPt.type]}
                  duration={
                    contentData.duration ||
                    contentData.assets?.find((e) => e.subType === 'Original')?.duration
                  }
                />
              ) : (
                <AudioPlayer
                  shakaCallbackRef={shakaCallbackRef}
                  contentURL={contentURL}
                  overlayWidth={overlayWidth}
                  overlayHeight={overlayHeight}
                  startAutoHighlight={startAutoHighlight}
                  autoHighlight={autoHighlight}
                  handlePause={handlePause}
                  overlayRef={overlayRef}
                  breakpoints={brPtContainer[selectedBrPt.type]}
                  duration={contentData.duration}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            xl={8}
            direction="column"
            className={classes.rightPanelFrame}
          >
            <Selector
              contentId={id}
              contentURL={contentURL}
              markerId={markerId}
              selectedBrPt={selectedBrPt}
              switchPlayPause={switchPlayPause}
              resetSelectedBrPt={resetSelectedBrPt}
              setSelectedTag={setSelectedTag}
              loadMarkers={loadMarkers}
              brPtTypes={brPtTypes}
              brPtPanelListRef={brPtPanelListRef}
              timelineListRef={timelineListRef}
              featureWhitelist={featureWhitelist}
              setFeatureWhitelist={setFeatureWhitelist}
              featureBlacklist={featureBlacklist}
              setFeatureBlacklist={setFeatureBlacklist}
              enqueueSnackbar={enqueueSnackbar}
              hasSubtitleMarkers={hasSubtitleMarkers}
              isBrPtsLineLoading={isBrPtsLineLoading}
              addBreakpoint={addBreakpoint}
              subtitleFilter={subtitleFilter}
              setSubtitleFilter={setSubtitleFilter}
            />

          {selectedBrPt.type === EBreakpoint.SUBTITLE && hasSubtitleMarkers && (
            <Container maxWidth="100%" ref={subtEditorCallbackRef} className={classes.subtEditCont}>
              <SubtitleEditor
                subtitleVlistRef={subtitleVlistRef}
                subtEditorWidth={subtEditorWidth}
                subtEditorHeight={subtEditorHeight}
                brPtContainer={brPtContainer}
                setBrPtContainer={setBrPtContainer}
                selectedBrPt={selectedBrPt}
                handleBreakpointSelect={handleBreakpointSelect}
                deleteBreakpoint={deleteBreakpoint}
                updateBreakpoint={updateBreakpoint}
                setIsHotKeyListenerOn={setIsHotKeyListenerOn}
                subtitleFilter={subtitleFilter}
              />
            </Container>
          )}

          </Grid>
          {(!!shakaPlayerRef?.videoElement && selectedBrPt.type !== EBreakpoint.SUBTITLE) && (
            <>
              <Grid item xs>
                <Typography variant="h6">
                  {lookUp({ key: 'CONSOLE_TIMELINE_TITLE' }).toUpperCase()}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ height: 100, overflowX: 'hidden' }}>
                <MarkerBar
                  brPtContainer={filterByFeature(brPtContainer)}
                  selectedBrPt={selectedBrPt}
                  duration={
                    contentData.duration ||
                    contentData.assets?.find((e) => e.subType === 'Original')?.duration
                  }
                  handleBreakpointSelect={handleBreakpointSelect}
                  getCurrentTime={getCurrentTime}
                  handleBreakpointDeselect={handleBreakpointDeselect}
                  updateBreakpoint={updateBreakpoint}
                  handleBrPtChange={handleBrPtChange}
                />
              </Grid>
            </>
          )}
          {(!isLoading && selectedBrPt.type !== EBreakpoint.SUBTITLE) && (
            <Grid item xs={12} ref={panelWrapperCallback}>
              <Typography variant="h6">
                {lookUp({
                  key: 'CONSOLE_MARKERS_TITLE_TEMPLATE',
                  markerType: lookUp({ key: `CONSOLE_${selectedBrPt?.type}` }),
                })}
              </Typography>
              <Panel
                selectedBrPt={selectedBrPt}
                newMarker={newMarker}
                selectedTag={selectedTag}
                addBreakpoint={addBreakpoint}
                brPtContainer={filterByFeature(brPtContainer)}
                brPtTypes={brPtTypes}
                resetSelectedBrPt={resetSelectedBrPt}
                handleBreakpointSelect={handleBreakpointSelect}
                handleBreakpointDeselect={handleBreakpointDeselect}
                setIsHotKeyListenerOn={setIsHotKeyListenerOn}
                deleteBreakpoint={deleteBreakpoint}
                updateBreakpoint={updateBreakpoint}
                setBrPtContainer={setBrPtContainer}
                handleBrPtChange={handleBrPtChange}
                thumbnailsUrl={
                  contentData.assets?.find(
                    (e) => e.subType === 'ScrubbingImages' && e.workflowStatus === 'Succeeded'
                  ) &&
                  contentData.assets?.find(
                    (e) => e.subType === 'ScrubbingImages' && e.workflowStatus === 'Succeeded'
                  ).publicUrl
                }
                width={panelWidth}
                isBrPtsLineLoading={isBrPtsLineLoading}
                timelineListRef={timelineListRef}
                brPtPanelListRef={brPtPanelListRef}
                autoPlay={autoPlay}
                setAutoPlay={setAutoPlay}
                seek={seek}
                loop={loop}
                setLoop={setLoop}
              />
            </Grid>
          )}
        </Grid>
        {false && !isLoading && (
          <Grid item xs={12} className={classes.timelineWrapper}>
            <Typography variant="h6">{lookUp({ key: 'CONSOLE_TIMELINE_TITLE' })}</Typography>
            <Timeline
              seek={seek}
              contentId={id}
              contentType={contentType}
              selectedBrPt={selectedBrPt}
              brPtTypes={brPtTypes}
              brPtContainer={brPtContainer}
              setBrPtContainer={setBrPtContainer}
              thumbnailsUrl={
                contentData.assets?.find(
                  (e) => e.subType === 'ScrubbingImages' && e.workflowStatus === 'Succeeded'
                ) &&
                contentData.assets?.find(
                  (e) => e.subType === 'ScrubbingImages' && e.workflowStatus === 'Succeeded'
                ).publicUrl
              }
              resetSelectedBrPt={resetSelectedBrPt}
              handleBreakpointSelect={handleBreakpointSelect}
              setSelectedTag={setSelectedTag}
              isBrPtsLineLoading={isBrPtsLineLoading}
              setIsBrPtsLineLoading={setIsBrPtsLineLoading}
              switchPlayPause={switchPlayPause}
              loadMarkers={loadMarkers}
              timelineListRef={timelineListRef}
              virtualImgListRef={virtualImgListRef}
              brPtPanelListRef={brPtPanelListRef}
            />
          </Grid>
        )}
      </section>
    </Box>
  );
}

export default withSnackbar(AudioVideoEditor);
