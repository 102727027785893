import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  makeStyles,
  Box,
  IconButton,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { msToTime } from 'helpers/time';


const defaultOffset = 9;
const defaultHeight = 130;
const defaultWidth = '20%';
const iconSize = 24;
const iconStyles = {
  height: iconSize,
  width: iconSize,
  zIndex: 1,
};

const useStyles = makeStyles({
  iconButton: {
    boxShadow: '0 0 0 2px #444',
    backgroundColor: 'white',
    ...iconStyles,
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  placeholder: {
    width: iconSize,
  },
  insetButton: {
    margin: (props) => props.offset || defaultOffset,
    color: 'white',
  },
  controlBox: {
    width: (props) => props.width || defaultWidth,
    border: '1px solid black',
    height: (props) => props.height || defaultHeight,
    position: 'relative',
    top: 0,
    left: 0,
    padding: (props) => 22 - (props.offset || defaultOffset),
    paddingTop: (props) => 22 - (props.offset || defaultOffset),
    lineHeight: 1.2,
    marginRight: 16,
    color: 'white',
    background: (props) => props.background || 'black',
  },
  controlIconsBox: {
    position: 'absolute',
    top: (props) => -(props.offset || defaultOffset),
    left: (props) => -(props.offset || defaultOffset),
    display: 'flex',
    flexDirection: 'column',
    height: (props) => (props.height || defaultHeight) + 2 * (props.offset || defaultOffset),
    width: (props) => `calc(100% + ${2 * (props.offset || defaultOffset)}px)`,
    padding: 0,
    alignContent: 'space-between',
  },
  header: {
    display: 'inline-block',
    color: 'white',
    backgroundColor: (props) => props.headerBgColor || 'salmon',
    fontWeight: 900,
    textShadow: '1px 1px 2px rgba(0,0,0,.4)',
    position: 'relative',
    textAlign: 'center',
    padding: '.25em 0 .5em 0',
    width: (props) => `calc(100% + ${2 * (props.offset || defaultOffset)}px)`,
    top: (props) => -2 * (props.offset || defaultOffset),
    left: (props) => -(props.offset || defaultOffset),
  },
  title: {
    maxHeight: '2lh',
    overflow: 'hidden',
    fontWeight: 900,
  },
  topButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  bottomButtons: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  sideButtons: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  centerButton: {
    position: 'relative',
    left: `calc(100% / 2 - ${iconSize / 2}px)`,
    top: `calc(100% / 2 - ${iconSize / 2}px)`,
    color: 'white',
    ...iconStyles,
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

function ControlBox({
  width,
  height,
  offset,
  title,
  duration,
  other = [],
  topButtons = [],
  sideButtons = [],
  bottomButtons = [],
  centerButton,
  header,
  headerBgColor,
  background,
  backgroundImage,
  ...rest
}) {
  const classes = useStyles({ width, background, height, headerBgColor, offset });

  const makeButton = (btnProp, i) => (
    <Tooltip title={btnProp.tooltip} key={`${i}_${btnProp.tooltip}`}>
      <IconButton
        size="small"
        className={classes[btnProp.selector]}
        onClick={btnProp.onClick}
      >
        {btnProp.icon}
      </IconButton>
    </Tooltip>
  );

  const makeButtons = (buttons) => {
    return buttons.map((btnProp, i) => {
      if (typeof btnProp.link === 'string' && btnProp.link.length > 3) {
        return <Link to={btnProp.link} component={RouterLink}>{makeButton(btnProp, i)}</Link>
      } else {
        return makeButton(btnProp, i);
      }
    });
  };

  const TopButtons = useMemo(() => {
    return topButtons?.length > 0 ? makeButtons(topButtons) : null;
  }, [topButtons]);

  const SideButtons = useMemo(() => {
    return sideButtons?.length > 0 ? makeButtons(sideButtons) : null;
  }, [sideButtons]);

  const BottomButtons = useMemo(() => {
    return bottomButtons?.length > 0 ? makeButtons(bottomButtons) : null;
  }, [bottomButtons]);

  return (
    <Grid container className={classes.controlBox} direction="column" {...rest}>
      {backgroundImage && <img src={backgroundImage} className={classes.image} alt="Cover Image" />}
      {header && (
        <Typography variant="caption" className={classes.header}>
          {header}
        </Typography>
      )}
      <Grid item container direction="column">
        {title && (
          <Typography variant="caption" className={classes.title}>
            {title}
          </Typography>
        )}
        {other && other.map((e, i) => <Typography key={i} variant="caption">{e}</Typography>)}
        {duration && (
          <Typography variant="caption">{msToTime(duration)?.split('.')?.[0]}</Typography>
        )}
      </Grid>

      <Box className={classes.controlIconsBox}>
        <div className={classes.topButtons}>{TopButtons}</div>
        <div className={classes.sideButtons}>{SideButtons}</div>
        <div className={classes.bottomButtons}>{BottomButtons}</div>
      </Box>
      {centerButton && makeButton(centerButton, 999)}

    </Grid>
  );
}

export default ControlBox;
