import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const THUMBNAIL_POSSIBLE_MATCHES = [
  'Customer:Image:PortraitUrl',
  'Customer:Image:SquareUrl',
  'Custom:User:Avatar',
  'Customer:Image:CircleUrl',
  'Customer:Image:Avatar2DUrl',
];

// default user avatar value
const DEFAULT_THUMBNAIL_PROPERTY_PATH = 'Customer:Image:SquareUrl';

/**
 * search for the default user avatar property, if it does not find the default avatar, searches for matches from 
 * THUMBNAIL_POSSIBLE_MATCHES sequentially until an image is found
 * 
 * @param {object} properties the user properties
 * 
 * @returns string
 */
export const getUserThumbail = (properties = {}) => {
  let thumbnail = get(properties, DEFAULT_THUMBNAIL_PROPERTY_PATH, '');

  if (isEmpty(thumbnail)) {
    for (let possibleMatch of THUMBNAIL_POSSIBLE_MATCHES) {
      const tempMatch = properties[possibleMatch];

      if (!isEmpty(tempMatch)) {
        thumbnail = tempMatch;
        break;
      }
    }
  }

  return thumbnail;
};