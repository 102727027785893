import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { arrayOf, bool, func, oneOfType, shape, string, object, number } from 'prop-types';
import { withSnackbar } from 'notistack';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import {
  Button,
  Divider,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Typography,
  Toolbar,
  Tooltip,
  InputAdornment,
  Fab,
  Input,
  Grid,
  makeStyles,
  TextField,
  Checkbox
} from '@material-ui/core';
import { Add, Search, ClearAll, CheckBox } from '@material-ui/icons';
import {
  BreadcrumbsComponent,
  CenteredBlock,
  ConfirmDialog,
  Loader,
} from './index';
import LogButton from './Audit/LogButton';
import FavouriteContents from 'pages/Content/FavouriteContents';
import AlertService from 'services/alertService'


function EnhancedTableHead(props) {
  const {
    classes,
    order,
    sortBy,
    onRequestSort,
    headCells,
    deleteItem,
    data,
    elementsToDownload,
    setElementsToDownload,
    checkboxVisible,
    tracewindType,
    setCheckboxVisible
  } = props;

  const [mainChecked, setMainChecked] = useState(false);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleMainCheckbox = (checked) => {
    const idsOnThisPage = data.map(element => element.id);
    const elementsToDownloadCopy = elementsToDownload.length ? [...elementsToDownload].filter(element => !idsOnThisPage.includes(element.id)) : [];
    !checked
      ? setElementsToDownload(elementsToDownloadCopy)
      : setElementsToDownload(elementsToDownloadCopy.concat(data))
    setMainChecked(o => !o)
  }

  return (
    <TableHead padding="none">
      <TableRow>
        {checkboxVisible &&
          <TableCell padding="checkbox">
            <Checkbox
              checked={mainChecked}
              onClick={(event) => handleMainCheckbox(event.target.checked)} />
          </TableCell>}
        {headCells.map((headCell, index) =>
          headCell.isSortable ? (
            <TableCell
              key={index}
              align={headCell.align || 'right'}
              sortDirection={sortBy === headCell.name ? order : false}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
              {...(headCell.label === lookUp({ key: 'CONSOLE_CREATED_ON' }) && { className: classes.createdOn })}
            >
              <TableSortLabel
                active={sortBy === headCell.name}
                direction={order}
                onClick={createSortHandler(headCell.name)}
              >
                {/* Table Head Cell Font <strong></strong> */}
                {headCell.label}
                {sortBy === headCell.name ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? lookUp({ key: 'CONSOLE_DESCENDING' }) : lookUp({ key: 'CONSOLE_ASCENDING' })}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              align={headCell.align || 'left'}
              key={headCell.name}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
              {...(headCell.label === lookUp({ key: 'CONSOLE_CREATED_ON' }) && { className: classes.createdOn })}
            >
              {/* Table Head Cell Font */}
              {headCell.label}
            </TableCell>
          )
        )}
        {deleteItem && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  onRequestSort: func,
  order: string.isRequired,
  sortBy: string.isRequired,
  headCells: arrayOf(object),
  deleteItem: bool,
  tracewindType: string,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 2, 0)
  },
  searchInput: {
    height: '40px',
  },
}));

function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    create,
    toUpload,
    uploadType,
    uploadFile,
    downloadFile,
    checkboxVisible,
    setCheckboxVisible,
    elementsToDownload,
    setElementsToDownload,
    pageTitle,
    data,
    query,
    handleQueryChange,
    handleSearch,
    setIsDeleting,
    clearFilters,
    additionalFilters,
  } = props;

  const exportAll = () => {
    const fileName = uploadType === 'localizationXLSX' ? 'LocalizationStrings.xlsx' : pageTitle.replace(' ', '_');
    const fileText = uploadType === 'json' ? data : undefined;
    return downloadFile(fileName, fileText)
      .catch(error => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_file_download_error' })
        });
      })
  };

  const selecterClicked = () => {
    setCheckboxVisible(o => !o);
  }

  const exportSelected = () => {
    const fileName = uploadType === 'localizationXLSX' ? 'LocalizationStrings.xlsx' : pageTitle.replace(' ', '_');
    const fileText = uploadType === 'json' ? elementsToDownload : undefined;
    return downloadFile(fileName, fileText)
      .then(() => {
        setElementsToDownload([]);
        setCheckboxVisible(false)
      })
      .catch(error => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_file_download_error' })
        });
      })
  };

  const importFile = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();
    uploadType === 'localizationXLSX' && formData.append('file', file);
    const dataToUpload = uploadType === 'localizationXLSX' ? formData : file;
    return uploadFile(dataToUpload)
      .then(() => {
        setIsDeleting(true);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_file_upload_success' })
        });
      })
      .catch(error => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_file_upload_error' })
        });
      })
      .finally(() => {
        setIsDeleting(false);
        const uploadInput = document.getElementById('uploadFile_246897531');
        uploadInput.value = "";
      });
  };

  const uploadClick = (event) => {
    const uploadInput = document.getElementById('uploadFile_246897531');
    uploadInput.click();
  };

  return (
    <Toolbar className={classes.root}>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={12}>
          <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={2}>
            <Grid item md={4} lg={3}>
              <TextField
                className={classes.searchInput}
                type="text"
                name="table-search"
                label={lookUp({ key: 'CONSOLE_SEARCH'})}
                id="table-search"
                placeholder={lookUp({ key: 'CONSOLE_SEARCH_HELPERTEXT' })}
                value={query}
                onChange={handleQueryChange}
                onKeyUp={handleSearch}
                fullWidth={true}
                InputProps={{
                  endAdornment:
                    (<InputAdornment position="end">
                      <Tooltip title={lookUp({ key: 'CONSOLE_QUERY_HELPERTEXT' })}>
                        <IconButton
                          aria-label={lookUp({ key: 'CONSOLE_SEARCH'})}
                          size="small"
                        >
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>)
                  }}
              />
            </Grid>
            {additionalFilters &&
              additionalFilters.map((filter, index) => (
                <Grid item key={index}>
                  {filter()}
                </Grid>
              ))}
            <Grid item className={classes.createIcon} style={{ padding: '4px' }}>
              <Tooltip title={'Clear Filters'}>
                <IconButton
                  aria-label={'Clear Filters'}
                  onClick={clearFilters}
                >
                  <ClearAll />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Grid container alignItems={'center'} spacing={2}>
                {elementsToDownload && (
                  <>
                    <Grid item>
                      <Tooltip title={lookUp({ key: 'CONSOLE_EXPORT_ALL_HELPERTEXT' })}>
                        <Fab
                          aria-label={create.text}
                          color={'primary'}
                          size={'small'}
                          onClick={exportAll}
                        >
                          <GetAppIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                    { uploadType !== "localizationXLSX" &&
                    <Grid item>
                      <Tooltip title={!elementsToDownload.length ? 'Select downloads' : `Download ${elementsToDownload.length} item${elementsToDownload.length > 1 ? 's' : ''}`}>
                        {!elementsToDownload.length ?
                          <Fab
                            aria-label={create.text}
                            color={'primary'}
                            size={'small'}
                            onClick={selecterClicked}
                          >
                            <CheckBox />
                          </Fab>
                          :
                          <Button
                            aria-label={create.text}
                            color={'primary'}
                            variant={'contained'}
                            size={'small'}
                            onClick={exportSelected}>
                            {`Download ${elementsToDownload.length} item${elementsToDownload.length > 1 ? 's' : ''}`}
                          </Button>
                        }
                      </Tooltip>
                    </Grid>
                    }
                    <Grid item>
                      <Tooltip title={lookUp({ key: 'CONSOLE_CREATE_TEXT_HELPERTEXT' })}>
                        <Fab
                          aria-label={create.text}
                          color={'primary'}
                          size={'small'}
                          onClick={uploadClick}
                        >
                          <Input
                            id="uploadFile_246897531"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={importFile}
                          />
                          <PublishIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </>
                )}
                {create && (
                  <Grid item>
                    <Tooltip title={create.text}>
                      <RouterLink to={create.link}>
                        <Fab aria-label={create.text} color={'primary'} size={'small'}>
                          <Add />
                        </Fab>
                      </RouterLink>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  create: shape({
    link: string,
    text: string
  }),
  toUpload: bool,
  uploadType: string,
  uploadFile: func,
  downloadFile: func,
  handleQueryChange: func.isRequired,
  handleSearch: func.isRequired,
  clearFilters: func.isRequired,
  setIsDeleting: func.isRequired,
  additionalFilters: arrayOf(func),
  query: string,
  tracewindType: string,
};

EnhancedTableToolbar.defaultProps = {
  toUpload: false,
  uploadType: undefined,
  uploadFile: undefined,
  downloadFile: undefined,
  tracewindType: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  emptyCell: {
    border: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  smallColumn: {
    width: '10px'
  },
  createdOn: {
    width: '160px'
  },
  dividerText: {
    fontSize: '110%',
    margin: theme.spacing(2, 0, 1, 0),
  },
  favouriteDivider: {
    margin: theme.spacing(4, 12),
    border: '1px solid #a9a9a9'
  }
}));

function EnhancedTable(props) {
  const {
    breadcrumbs,
    create,
    toUpload,
    uploadType,
    uploadFile,
    downloadFile,
    deleteItem,
    loadData,
    pageTitle,
    tableBodyElements,
    tableHeadElements,
    additionalFilters,
    additionalFiltersState,
    requestAdditionalFiltersState,
    clearAdditionalFilters,
    customToolbar,
    enqueueSnackbar,
    rowColorFunc,
    onPageChange,
    onRowsChange,
    favourites,
    removeFromFavourite,
    favouriteProps,
    elementsToDownload,
    setElementsToDownload,
    enablePagination,
    tracewindType
  } = props;

  const classes = useStyles();
  const selectedBrand = useSelector(state => state.auth.selectedBrand);

  const [data, setData] = useState([]);
  const [tablePageNumber, setTablePageNumber] = useState(props.pageNumber || 0);
  const [avoidReload, setAvoidReload] = useState(false);
  const [inRowsChange, setInRowsChange] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsNumber || 10);
  const [query, setQuery] = useState('');
  const [order, setOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('');
  const [count, setCount] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [checkboxVisible, setCheckboxVisible] = useState(false);

  useEffect(() => {
    clearFilters(true);
  }, [props.pageTitle])

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setSortBy(property);
  };

  const handleChangePage = async (event, newPage) => {
    if (onPageChange) onPageChange(newPage);
    await getData(newPage, query);
  };

  const handleChangeRowsPerPage = (event, rowsNumber) => {
    if (onRowsChange) {
      onRowsChange(rowsNumber.props.value)
    }
    setInRowsChange(true);
    setRowsPerPage(rowsNumber.props.value)
  };

  const handleQuery = (event) => {
    setQuery(event.target.value);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setSearchTimeout(timeout => clearTimeout(searchTimeout));
    const filterElem = event.target;
    if (filterElem) {
      const timeout = setTimeout(async () => {
        await getData(0, filterElem.value);
      }, 500);
      setSearchTimeout(timeout);
    }
  };

  const clearFilters = (isInitialClear = false) => {
    setOrder('asc');
    setQuery('');
    setSortBy('');
    onPageChange && onPageChange(0);
    let parentState = null;
    if (clearAdditionalFilters) {
      !isInitialClear && setAvoidReload(true);
      parentState = clearAdditionalFilters();
    }
    if (isInitialClear) {
      parentState && parentState.searchOptionsClear(parentState.searchOptions);
    } else {
      getData(0, '', true, true)
        .then(() => {
          parentState && parentState.searchOptionsClear(parentState.searchOptions);
          setAvoidReload(false);
        });
    }
  };

  const addParams = (isInitialData, loadParams, clearAllFilters) => {
    if (!isInitialData) {
      if (requestAdditionalFiltersState) {
        Object.keys(requestAdditionalFiltersState).forEach(key => {
          loadParams[key] = requestAdditionalFiltersState[key];
        });
      }
    }
    if (additionalFiltersState) {
      if (clearAllFilters) {
        // keep only the format (Content/ pages -> format=Video)
        loadParams.format = additionalFiltersState.format;
      } else {
        Object.keys(additionalFiltersState).forEach(key => {
          loadParams[key] = additionalFiltersState[key];
        });
      }
    }
  };

  const getData = async (page, queryParam, isInitialData = false, clearAllFilters = false) => {
    setIsLoading(true);
    const loadParams = {
      page,
      perPage: rowsPerPage,
      q: queryParam,
      order,
      sortBy,
    };
    addParams(isInitialData, loadParams, clearAllFilters);
    try {
      const { data, count, totalCount } = await loadData(loadParams);
      setData(data);
      setCount(count || totalCount);
      setError(null);
    } catch (err) {
      let error = err;
      if (err.response && [401, 403].includes(err.response.status)) {
        error = 'You have no access for this operation';
      }
      if (err.response && err.response.status != 404) setError(error);
    } finally {
      setTablePageNumber(page);
      setIsLoading(false);
    }
  };

  const handleDelete = async (item) => {
    if (!isDeleting) {
      setIsDeleting(true);
      deleteItem(item)
        .then(() => {
          const isItFavourite = favourites.find(fav => fav.id === item.id);
          isItFavourite && removeFromFavourite(item.id);
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: item.name || item.originalTitle ||  item.key || lookUp({ key: 'CONSOLE_item' }) })
          });
        })
        .catch(error => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_FILE_DELETE_ERROR' })
          });
        })
        .finally(() => setIsDeleting(false));
    }
  };

  const handleCheckbox = (item, checked) => {
    if (checked) {
      setElementsToDownload([...elementsToDownload, item]);
    } else {
      const filtered = elementsToDownload.filter(e => e.id !== item.id);
      setElementsToDownload(filtered);
      if (!filtered.length) setCheckboxVisible(false);
    }

  }

  const addFilters = JSON.stringify(additionalFiltersState);

  useEffect(() => {
    const loadRecords = async () => !avoidReload && await getData(0, query);
    if (!isDeleting) loadRecords();
  }, [addFilters]);

  useEffect(() => {
    const loadRecords = async (pageNum) => await getData(pageNum, query);
    if (!isDeleting) {
      if (inRowsChange) {
        setInRowsChange(false);
        loadRecords(0);
      } else {
        loadRecords(tablePageNumber);
      }
    }
  }, [order, sortBy, rowsPerPage, isDeleting]);

  if (error) {
    return (
      <CenteredBlock>
        <Typography variant={'h3'} color={'error'}>
          Error
        </Typography>
        <Typography variant={'h4'} color={'error'}>
          {JSON.stringify(
            error && error.response && error.response.data && error.response.data.message,
            null,
            2
          )}
        </Typography>
      </CenteredBlock>
    );
  }
  return (
    <div className={classes.root}>
      <Helmet title={pageTitle} />
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} goToCrumb={() => { }} />
      {favourites.length > 0 &&
        <>
          <Typography
            color="textPrimary"
            display="block"
            variant="h6"
            className={classes.dividerText}
          >
            {lookUp({ key: 'CONSOLE_FAVOURITES' })}
          </Typography>
          <FavouriteContents
            favourites={favourites}
            favouriteProps={favouriteProps}
            isDeleting={isDeleting}
            setIsDeleting={setIsDeleting}
          />
          <Divider className={classes.favouriteDivider} variant="middle" />
        </>
      }
      <div>
        {customToolbar ? (
          customToolbar(handleSearch, true, clearFilters, true)
        ) : (
          <EnhancedTableToolbar
            checkboxVisible={checkboxVisible}
            setCheckboxVisible={setCheckboxVisible}
            create={create}
            toUpload={toUpload}
            uploadType={uploadType}
            uploadFile={uploadFile}
            downloadFile={downloadFile}
            elementsToDownload={elementsToDownload}
            setElementsToDownload={setElementsToDownload}
            data={data}
            setIsDeleting={setIsDeleting}
            pageTitle={pageTitle}
            handleSearch={handleSearch}
            handleQueryChange={handleQuery}
            clearFilters={clearFilters}
            additionalFilters={additionalFilters}
            query={query}
          />
        )}
        <div className={classes.tableWrapper}>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  checkboxVisible={checkboxVisible}
                  order={order}
                  sortBy={sortBy}
                  onRequestSort={handleRequestSort}
                  headCells={tableHeadElements}
                  deleteItem={!!deleteItem}
                  data={data}
                  elementsToDownload={elementsToDownload}
                  setElementsToDownload={setElementsToDownload}
                  tracewindType={tracewindType}
                />
                {data && data.length !== 0 && (
                  <TableBody padding="none">
                    {data.map((item, index) => (
                      <TableRow
                        hover
                        key={item.id || index}
                        {...(rowColorFunc && { className: rowColorFunc(item) })}
                      >{checkboxVisible && 
                        <TableCell padding="checkbox">
                          <Checkbox
                            id={item.id}
                            checked={!!elementsToDownload.filter(e => e.id === item.id).length}
                            onClick={(event) => handleCheckbox(item, event.target.checked)}
                          />
                        </TableCell>}
                        {tableBodyElements.map((element) => (
                          <TableCell
                            align={element.align || 'right'}
                            key={element.name}
                            style={element.style}
                            component={element.name === 'name' ? 'th' : ''}
                            scope={element.name === 'name' ? 'row' : ''}
                          >
                            {element.render ? element.render(item) : item[element.name].toISOString()}
                          </TableCell>
                        ))}
                        { tracewindType &&
                          (<TableCell align={'right'} className={classes.smallColumn}>
                            <LogButton type={tracewindType} id={item.id} />
                          </TableCell>)
                        }
                        {deleteItem && (
                          <TableCell align={'right'} className={classes.smallColumn}>
                            <ConfirmDialog
                              onConfirm={() => handleDelete(item)}
                              padding="none"
                              message={lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: item.name || item.key || 'item'})}
                              disabled={isDeleting}
                              dialogText='Delete'
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </>
          )}
          {!isLoading && (!data || data.length === 0) && (
            <CenteredBlock height="10vh">
              <Typography variant="h5">No records found</Typography>
            </CenteredBlock>
          )}
        </div>
        {enablePagination &&
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count || 1}
            rowsPerPage={rowsPerPage}
            page={tablePageNumber}
            labelRowsPerPage={lookUp({ key: 'CONSOLE_Rows_per_page' })}
            labelDisplayedRows={({from, to, count}) => `${from}–${to} / ${count !== -1 ? count : `> ${to}`}`}
            backIconButtonProps={{
              'aria-label': 'previous page'
            }}
            nextIconButtonProps={{
              'aria-label': 'next page'
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      </div>
    </div>
  );
};

EnhancedTable.propTypes = {
  pageTitle: string,
  pageNumber: number,
  selectedBrand: shape({
    brandId: string,
  }),
  create: shape({
    link: string,
    text: string
  }),
  breadcrumbs: arrayOf(
    shape({
      text: string,
      link: string,
      isCurrent: bool,
      order: number
    })
  ),
  tableHeadElements: arrayOf(
    shape({
      name: string,
      label: string,
      isSortable: bool,
      align: string,
      smallColumn: bool
    })
  ).isRequired,
  tableBodyElements: arrayOf(
    oneOfType([
      shape({
        name: string,
        align: string,
        render: func
      })
    ])
  ).isRequired,
  loadData: func,
  deleteItem: func,
  additionalFilters: arrayOf(func),
  additionalFiltersState: object,
  requestAdditionalFiltersState: object,
  clearAdditionalFilters: func,
  customToolbar: func,
  enqueueSnackbar: func.isRequired,
  rowColorFunc: func,
  favouriteProps: object,
  toUpload: bool,
  uploadType: string,
  uploadFile: func,
  downloadFile: func,
  enablePagination: bool,
};

EnhancedTable.defaultProps = {
  pageTitle: '',
  create: null,
  breadcrumbs: [],
  tableClassName: '',
  rowColorFunc: null,
  favourites: [],
  favouriteProps: null,
  toUpload: false,
  uploadType: undefined,
  uploadFile: undefined,
  downloadFile: undefined,
  enablePagination: true,
};

export default withSnackbar(EnhancedTable);
