import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { lookUp } from 'services/stringService';

const useStyles = makeStyles((theme) => ({
  addButton: {
    color: theme.palette.primary.main,
  },
}));

const AddButton = ({
  onAdd,
  tooltipText,
  navigateTo = 'create',
  size = 'medium',
  disabled = false,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [ pathname, search ] = navigateTo.includes?.('?') ? navigateTo.split?.('?') : [navigateTo, ''];

  const handleAdd = e => {
    e.preventDefault();

    // used for listings by default navigate to current_path/create if no onAdd callback provided
    if (!onAdd || typeof onAdd !== 'function') {
      navigate({ pathname, search });
    }

    // use provided onAdd callback
    onAdd?.();
  };

  const getButtonText = () => {
    if (!tooltipText) {
      return lookUp({ key: 'CONSOLE_CREATE' });
    }

    return typeof tooltipText === 'string' && tooltipText.trim() !== ''
      ? tooltipText
      : lookUp({ key: 'CONSOLE_CREATE' });
  };

  return (
    <Tooltip item title={getButtonText()}>
      <IconButton
        className={classes.addButton}
        onClick={handleAdd}
        size={size}
        disabled={disabled}
      >
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
};

AddButton.propTypes = {
  onAdd: PropTypes.func,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  navigateTo: PropTypes.string,
};

export default AddButton;
