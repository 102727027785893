import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { func } from 'prop-types';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import {
  Button,
  Divider,
  FilledInput,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
  FormControlLabel
} from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Loader, EditorInfo } from 'components';
import devicesService from 'services/devicesService';
import channelService from 'services/channelService';
import venuesService from 'services/venuesService';
import resortsService from 'services/resortsService';
import messagingService from 'services/messagingService';
import { MMSSToMs, HHMMSSToMMSS } from 'helpers/time';

import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  textField: {
    width: '100%'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginX: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  formControl: {
    width: '100%'
  }
}));

const DevicesForm = (props) => {
  
  const classes = useStyles();
  const {
    enqueueSnackbar,
  } = props;

  const { id } = useParams();
  const navigate = useNavigate();

  const [model, setModel] = useState({
    name: '',
    brand: '',
    model: '',
    os: '',
    swVersion: '',
    status: {
      batteryLevel: '',
      channelId: '',
      networkLevel: '',
      resortId: '',
      temperature: '',
      venueId: '',
      volumeLevel: ''
    }
  });
  const [isLoading, setIsLoading] = useState(true);

  const [maintenance, setMaintenance] = useState(false);
  const [volume, setVolume] = useState(0);
  const [ticker, setTicker] = useState({
    message: '',
    duration: 0
  });
  const [isTickerSending, setIsTickerSending] = useState(false);

  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [isChannelOptionsLoading, setIsChannelOptionsLoading] = useState(true);

  const [resortStatus, setResortStatus] = useState(null);
  const [isResortStatusLoading, setIsResortStatusLoading] = useState(false);
  const [venueStatus, setVenueStatus] = useState(null);
  const [isVenueStatusLoading, setIsVenueStatusLoading] = useState(false);
  const [channelStatus, setChannelStatus] = useState(null);
  const [isChannelStatusLoading, setIsChannelStatusLoading] = useState(false);

  const statusMarks = {
    battery: [
      {
        label: `${model.status ? model.status.batteryLevel * 10 : 0}%`,
        value: model.status ? model.status.batteryLevel * 10 : 0
      }
    ],
    network: [
      {
        label: `${model.status ? model.status.networkLevel * 10 : 0}%`,
        value: model.status ? model.status.networkLevel * 10 : 0
      }
    ],
    temperature: [
      {
        label: `${model.status ? model.status.temperature * 10 : 0}°C`,
        value: model.status ? model.status.temperature * 10 : 0
      }
    ],
    volume: [
      {
        label: `${model.status ? model.status.volumeLevel * 10 : 0}%`,
        value: model.status ? model.status.volumeLevel * 10 : 0
      }
    ]
  };

  const handleMaintenanceChange = () => {
    setMaintenance(!maintenance);
    changeMaintenanceMode(!maintenance);
  };

  const handleTickerChange = (key, value) =>
    setTicker({
      ...ticker,
      [key]: value
    });

  const momentTimeToUnix = (time) => {
    const validTime = moment(time).format('HH:mm:ss');
    return MMSSToMs(HHMMSSToMMSS(validTime));
  };

  const handleVolumeChange = (value) => {
    if (volume === value) {
      return false;
    } else {
      setVolume(value);
      changeVolume(value);
    }
  };

  const handleChannelChange = (channelId) => {
    setModel((prev) => ({
      ...prev,
      channel: channelId
    }));

    setSelectedChannel(channelOptions.find((item) => item.value === channelId));
    changeChannel(channelId);
  };

  const sendTicker = async () => {
    setIsTickerSending(true);
    if (ticker.message && ticker.duration) {
      try {
        await messagingService.create('Device', id, {
          kRemoteAction: 'showTicker',
          kActionValue: ticker.message,
          kActionDuration: momentTimeToUnix(ticker.duration)
        });

        setTicker({
          message: '',
          duration: 0
        });
        setIsTickerSending(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_send_ticker_error' })
        });
      }
    } else {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: `Fill Ticker ${ticker.message ? 'Duration' : 'Message'} field: `
      });
    }
  };

  const sendMessage = (kRemoteAction, value) =>
    devicesService.sendMessage({
      id,
      body: {
        sound: 'default',
        kRemoteAction,
        kActionValue: value
      }
    });

  const changeVolume = (volume) => sendMessage('changeVolume', volume / 100);

  const changeChannel = (channel) => sendMessage('changePlaylist', channel);

  const changeMaintenanceMode = (mode) => sendMessage('maintenanceMode', mode);

  const getChannels = async () => {
    try {
      const { totalCount } = await channelService.list();
      const { data } = await channelService.list({ page: 0, perPage: totalCount });

      setChannelOptions(data.map(({ name, id }) => ({ label: name, value: id })));
      setSelectedChannel(data.length ? { label: data[0].name, value: data[0].id } : null);
      setModel((prev) => ({
        ...prev,
        channel: data[0].id || ''
      }));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_CHANNELS'}) })
      });
    }
  };

  const getDevice = async () => {
    try {
      const device = await devicesService.getById(id);

      setModel((prev) => ({
        ...prev,
        ...device
      }));

      if (device.status) {
        throw new Error('status undefined');
      }

      getChannelStatus(device.status.channelId).then(() => setIsChannelStatusLoading(false));
      getResortStatus(device.status.resortId).then(() => setIsResortStatusLoading(false));
      getVenueStatus(device.status.venueId).then(() => setIsVenueStatusLoading(false));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_DEVICE'}) })
      });
    }
  };

  const getResortStatus = async (id) => {
    setIsResortStatusLoading(true);
    try {
      const resort = await resortsService.getById(id);
      setResortStatus(resort);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_RESORT'}) })
      });
    }
  };

  const getVenueStatus = async (id) => {
    setIsVenueStatusLoading(true);
    try {
      const venue = await venuesService.get(id);

      setVenueStatus(venue);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_VENUE'}) })
      });
    }
  };

  const getChannelStatus = async (id) => {
    setIsChannelStatusLoading(true);
    try {
      const channel = await channelService.show(id);

      setChannelStatus(channel);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_CHANNEL'}) })
      });
    }
  };

  const getData = () => {
    getChannels().then(() => setIsChannelOptionsLoading(false));
    getDevice().then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.root}>
      <Typography variant={'h6'} gutterBottom>
        Basic information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="device-name"
            label={lookUp({ key: 'CONSOLE_NAME' })}
            variant="filled"
            size="medium"
            disabled
            className={classes.textField}
            value={model.name || 'No information'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="device-brand"
            label={lookUp({ key: 'CONSOLE_BRAND' })}
            variant="filled"
            size="medium"
            disabled
            className={classes.textField}
            value={model.brand || 'No information'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="device-model"
            label={lookUp({ key: 'CONSOLE_MODEL' })}
            variant="filled"
            size="medium"
            disabled
            className={classes.textField}
            value={model.model || 'No information'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="device-os"
            label={lookUp({ key: 'CONSOLE_OS' })}
            disabled
            variant="filled"
            size="medium"
            className={classes.textField}
            value={model.os || 'No information'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="device-sw"
            label={lookUp({ key: 'CONSOLE_SW' })}
            variant="filled"
            size="medium"
            disabled
            className={classes.textField}
            value={model.swVersion || 'No information'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {isChannelOptionsLoading ? (
            <Loader inline />
          ) : (
            <FormControl className={classes.formControl} variant="filled">
              <InputLabel htmlFor="device-channels" variant="filled">
                Channel
              </InputLabel>
              <Select
                value={selectedChannel.value}
                renderValue={() => selectedChannel.label}
                onChange={(e) => handleChannelChange(e.target.value)}
                input={<FilledInput id="device-channels" />}
                className={classes.textField}
              >
                {channelOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.marginX} />
      <Typography variant={'h6'} gutterBottom>
        Status information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          {isResortStatusLoading ? (
            <Loader inline />
          ) : (
            <TextField
              id="device-status-resort"
              label={lookUp({ key: 'CONSOLE_RESORT' })}
              disabled
              variant="filled"
              size="medium"
              className={classes.textField}
              value={resortStatus ? resortStatus.name : 'No information'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isVenueStatusLoading ? (
            <Loader inline />
          ) : (
            <TextField
              id="device-status-venue"
              label={lookUp({ key: 'CONSOLE_VENUE' })}
              disabled
              variant="filled"
              size="medium"
              className={classes.textField}
              value={venueStatus ? venueStatus.name : 'No information'}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4}>
          {isChannelStatusLoading ? (
            <Loader inline />
          ) : (
            <TextField
              id="device-status-channel"
              label={lookUp({ key: 'CONSOLE_CHANNEL' })}
              disabled
              variant="filled"
              size="medium"
              className={classes.textField}
              value={channelStatus ? channelStatus.name : 'No information'}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.marginTop}>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom id="device-status-battery">
            Battery
          </Typography>
          <Slider
            value={model.status ? model.status.batteryLevel * 10 : 0}
            aria-labelledby="device-status-battery"
            valueLabelDisplay={'auto'}
            className={classes.marginTop}
            marks={statusMarks.battery}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom id="device-status-network">
            Network
          </Typography>
          <Slider
            value={model.status ? model.status.networkLevel * 10 : 0}
            aria-labelledby="device-status-network"
            valueLabelDisplay={'auto'}
            className={classes.marginTop}
            marks={statusMarks.network}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom id="device-status-temperature">
            Temperature
          </Typography>
          <Slider
            value={model.status ? model.status.temperature * 10 : 0}
            aria-labelledby="device-status-temperature"
            valueLabelDisplay={'auto'}
            className={classes.marginTop}
            marks={statusMarks.temperature}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography gutterBottom id="device-status-volume">
            Volume
          </Typography>
          <Slider
            value={model.status ? model.status.volumeLevel * 10 : 0}
            aria-labelledby="device-status-volume"
            valueLabelDisplay={'auto'}
            className={classes.marginTop}
            marks={statusMarks.volume}
          />
        </Grid>
      </Grid>
      <Divider className={classes.marginX} />
      <Typography variant={'h6'} gutterBottom>
        Actions
      </Typography>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={maintenance} onChange={handleMaintenanceChange} />}
            label={'Maintenance Mode'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems={'center'} className={classes.marginTop}>
        <Grid item xs={12} md={2}>
          <Typography>Ticker</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="device-ticker-message"
            label={lookUp({ key: 'CONSOLE_MESSAGE' })}
            variant="filled"
            size="medium"
            className={classes.textField}
            value={ticker.message}
            onChange={(e) => handleTickerChange('message', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <KeyboardTimePicker
            label={lookUp({ key: 'CONSOLE_DURATION' })}
            ampm={false}
            inputVariant="filled"
            className={classes.textField}
            views={['hours', 'minutes', 'seconds']}
            placeholder={lookUp({ key: 'CONSOLE_DURATION_HELPERTEXT' })}
            mask="__:__:__"
            format="HH:mm:ss"
            value={moment(ticker.duration).utc(false) || null}
            onChange={(time) => handleTickerChange('duration', time)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            onClick={sendTicker}
            startIcon={<Send />}
            variant={'contained'}
            color={'primary'}
            className={classes.formControl}
          >
            {isTickerSending ? <Loader inline /> : 'Send'}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems={'center'} className={classes.marginTop}>
        <Grid item xs={12} md={2}>
          <Typography>Volume</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Slider
            defaultValue={model.status ? model.status.volumeLevel * 10 : 0}
            valueLabelDisplay={'auto'}
            className={classes.marginTop}
            max={100}
            min={0}
            onChange={handleVolumeChange}
          />
        </Grid>
      </Grid>
      <Divider className={classes.marginTop} />
      <Grid container alignItems={'center'} justifyContent={'flex-end'} className={classes.marginX}>
        <Button variant={'contained'} onClick={() => navigate(window.history.state.idx === 0 ? '/' : -1)} color={'primary'}>
          Return
        </Button>
      </Grid>
      <EditorInfo createdAt={model.createdDate} modifiedAt={model.lastModifiedDate} modifiedBy={model.lastModifiedBy} />
    </div>
  );
};

DevicesForm.propTypes = {
  enqueueSnackbar: func
};

export default withSnackbar(DevicesForm);
