import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  styled,
  Grid,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { sortLangCodesByLangNames } from 'helpers/common';
import jobManagerService from 'services/jobManagerService';
import markerService from 'services/markerService';
import AlertService from 'services/alertService';


const SaveButton = styled(Button)({
  color: '#ffffff',
});

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.5, 2, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    padding: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    padding: theme.spacing(0, 1.5, 1.5, 1.5),
  },
}));

function Translation(props) {
  const { id, transcriptAsset, setOpen, enqueueSnackbar } = props;

  const [translate, setTranslate] = useState({ from: transcriptAsset?.locale || '', to: '' });
  const [translateLangs, setTranslateLangs] = useState([]);
 
  const classes = useStyles();

  const handleSelectLanguage = (target, value) => {
    setTranslate({ ...translate, [target]: value });
  };

  const startTranslate = (fromLang, toLang) => {
    const reqBody = {
      ReferenceObjectId: id,
      Name: `TranscriptJob_${Date.now()}`,
      SourceLanguageCode: fromLang,
      TargetLanguageCode: toLang,
      InputFileLocation: transcriptAsset?.objectUrl,
    };
    markerService
      .translate(reqBody)
      .then((resp) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_TRANSLATE_STARTED_TEMPLATE', to: lookUp({ key: `CONSOLE_LANG_${toLang}` }), from: lookUp({ key: `CONSOLE_LANG_${fromLang}` })})
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  };

  useEffect(() => {
     jobManagerService.getLanguageCodes('Translate')
      .then((translateLangs) => setTranslateLangs(sortLangCodesByLangNames(translateLangs)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_TRANSLATE_HEADING' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('translate', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>
        {lookUp({ key: 'CONSOLE_TRANSLATE_HELPERTEXT' })}
      </span>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2} direction="row" className={classes.transModalGrid}>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="from_language" margin="dense">
                {lookUp({ key: 'CONSOLE_FROM_LANGUAGE' })}
              </InputLabel>
              <Select
                labelId="from_language"
                label={lookUp({ key: 'CONSOLE_FROM_LANGUAGE' })}
                className={classes.tabSelect}
                margin="dense"
                value={translate.from}
                defaultValue={translate.from}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={(event) => handleSelectLanguage('from', event.target.value)}
              >
                {Object.keys(translateLangs).map((langCode, index) => (
                  <MenuItem value={langCode} key={`${index}_${langCode}`}>
                    {translateLangs[langCode]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="to_language" margin="dense">
                {lookUp({ key: 'CONSOLE_TO_LANGUAGE' })}
              </InputLabel>
              <Select
                labelId="to_language"
                label={lookUp({ key: 'CONSOLE_TO_LANGUAGE' })}
                margin="dense"
                className={classes.tabSelect}
                value={translate.to}
                defaultValue={translate.to}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                onChange={(event) => handleSelectLanguage('to', event.target.value)}
              >
                {Object.keys(translateLangs).map((langCode, index) => (
                  <MenuItem value={langCode} key={`${index}_${langCode}`}>
                    {translateLangs[langCode]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <SaveButton
            
            onClick={() => {
              startTranslate(translate.from, translate.to);
              setOpen('translate', false);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </SaveButton>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(Translation);
