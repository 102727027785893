import React, { useState, useEffect, useRef, useCallback } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tooltip,
  AppBar,
  Tabs,
  Tab,
  LinearProgress,
  Button,
  Typography,
} from '@material-ui/core';
import { 
  AddPhotoAlternate,
  ListAlt, 
  ViewList,
  SortByAlpha,
  BeachAccess,
  Face,
  LocalActivity,
  Bathtub, 
  ArrowForward,
} from '@material-ui/icons';
import Modal from './Modal';
import markerService from 'services/markerService';
import contentService from 'services/contentService';
import MarkerInfo from './MarkerInfo';
import RenderTags from './RenderTags';
import AlertService from 'services/alertService';
import navigationAction from 'store/actions/navigationAction';


const useStyles = makeStyles((theme) => ({
  editorFrame: {
    width: '100%',
    padding: theme.spacing(1),
    overflow: 'hidden',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  imageGrid: {
    bacgkroundColor: '#000000',
    marginLeft: '3px'
  },
  image: {
    width: "100%",
  },
  overlay: {
    position: "absolute",
    zIndex: 10,
  },
  renderTags: {
    maxHeight: "29.5em",
    overflow: "auto",
  },
  tabBar: {
    flexGrow: 1,
    padding: 0,
    marginBottom: '.5em',
    "& .MuiTab-root": {
      minWidth: 'initial',
    },
  },
  tabBox: {
    marginTop: '1em',
    padding: 0,
  },
}));


function ImageEditor(props) {
  const {
    enqueueSnackbar,
    markerType,
    markerId 
  } = props;
  
  const dispatch = useDispatch();
  const imageData = useSelector((state) => state.content.contentData);
  const { id } = imageData;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [panelWidth, setPanelWidth] = useState(0);
  const [panelHeight, setPanelHeight] = useState(0);
  const [imageURL, setImageURL] = useState('');
  const classes = useStyles({panelWidth, panelHeight});
  const overlayRef = useRef(null);
  const isFirstRun = useRef(true);
  const modals = ['enrichment'];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [markerTags, setMarkerTags] = useState([]);
  const [markerTypes, setMarkerTypes] = useState(["CaptionTag","CelebrityTag","FaceTag","LabelTag","ContentModerationTag"]);

  const markerTypeCustom = {
    url: {
      object: "LabelTag",
      moderation: "ContentModerationTag",
      caption: "CaptionTag",
      celebrity: "CelebrityTag",
      face: "FaceTag",
    },
    toUrl: {
      LabelTag: "object",
      ContentModerationTag: "moderation",
      CaptionTag: "caption",
      CelebrityTag: "celebrity",
      FaceTag: "face",
    },
    icon: {
      CaptionTag: <SortByAlpha/>,
      CelebrityTag: <LocalActivity/>,
      FaceTag: <Face/>,
      LabelTag: <BeachAccess/>,
      ContentModerationTag: <Bathtub/>,
    },
    label: {
      LabelTag: "Object",
      ContentModerationTag: "Moderation",
      CaptionTag: "Caption",
      CelebrityTag: "Celebrity",
      FaceTag: "Face",
    }
  };

  const [selectedType, setSelectedType] = useState(markerTypeCustom.url[markerType]);
  const [boundingBoxes, setBoundingBoxes] = useState([]);
  const [hoveredBox, setHoveredBox] = useState();
  const [selectedMarker, setSelectedMarker] = useState();
  const [markerData, setMarkerData] = useState();
  const [dimensions, setDimensions] = useState({})
  const [overlayWidth, setOverlayWidth] = useState(0);
  const [overlayHeight, setOverlayHeight] = useState(0);

  const setUrl = ({type, markerId}) => {
    const to = `/content/${id}/editor?markerType=${markerTypeCustom.toUrl[type || selectedType]}${markerId ? '&tag=' + markerId : ''}`;
    navigate(to, { replace: true });
  }

  const imageCallback = useCallback((image) => {
    if (image) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target.offsetHeight) {
            // setting the height of the breakpoint panel
            setPanelHeight(entry.target.offsetHeight);
            // setting the height of the <canvas> element
            setOverlayHeight(entry.target.offsetHeight);
          }
          if (entry.target.offsetWidth) {
            // setting the width of the <canvas> element
            setOverlayWidth(entry.target.offsetWidth);
          }
          //if (brPtPanelListRef.current) brPtPanelListRef.current.resetAfterColumnIndex(0);
        }
      });
      resizeObs.observe(image);
    }
  }, []);

  const panelWrapperCallback = useCallback((panelWrapper) => {
    if (panelWrapper) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          // set the width of the panel depending on the grid item of the image
          if (entry.target.offsetWidth) setPanelWidth(entry.target.offsetWidth);
        }
      });
      resizeObs.observe(panelWrapper);
    }
  }, []);

  const loadMarkerTags = async (markerType) => {
    if (!markerType) return;
    setIsLoading(true);
    let idOrToken = id;
    let finished = false;
    try {
      while (!finished) {
        const queryParams = { type: markerType};
        const reqResp = await markerService.search(idOrToken, queryParams);
        setMarkerTags(markerTags => [...markerTags, reqResp.pageContent[0]?.tags]);
        idOrToken = reqResp.pagingToken;
        finished = reqResp.finished;
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getMarkerById = markerId => markerService.get(markerId).then(marker => setSelectedMarker(marker));

  useEffect(() => {
    const assetId = imageData.assets.find(e => {
      return e.type === "Image" && e.subType === "Original"
    })?.id;

    contentService.downloadAsset(id, assetId)
      .then((assetUrl) => setImageURL(assetUrl?.data))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_FILE_DOWNLOAD_ERROR' }),
        });
      });
  }, []);

  useEffect(() => {
    if (!selectedType) return;
    setMarkerTags([]);
    setSelectedMarker(null);
    setMarkerData(null);
    setBoundingBoxes([]);
    loadMarkerTags(selectedType);
    isFirstRun.current = false;
  }, [selectedType]);

  useEffect(() => {
    dispatch(navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: imageData.originalTitle })));
    if (!markerId) return;
    getMarkerById(markerId);
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [])

  const boxes = JSON.stringify(boundingBoxes);

  useEffect(() => {
    if (isFirstRun.current) return;
    if (!overlayRef.current) return;
    clearBoundingBox();
    boundingBoxes.forEach(e => drawBoundingBox(e));
  }, [boxes])


  /**\
   * Wipes down the whole canvas.
   * @return undefined
  \**/
  const clearBoundingBox = () => {
    const canvas = overlayRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
  };

  /**\
   * Clears the canvas then draws a bounding box around the recognized item in the video.
   * E.g. draws a rectangle around the face of a celeb.
   * @param {*} marker a marker object (like 'brPtProto')
   * @return undefined
  \**/
  const drawBoundingBox = (boundingBox, color = 'orange') => {
    if (!boundingBox) return;
    const canvas = overlayRef.current;
    const canvasWidth = canvas.clientWidth;
    const canvasHeight = canvas.clientHeight;
    const xPos = canvasWidth * boundingBox.Left;
    const yPos = canvasHeight * boundingBox.Top;
    const frameWidth = canvasWidth * boundingBox.Width;
    const frameHeight = canvasHeight * boundingBox.Height;
    const ctx = canvas?.getContext('2d');
    if (!ctx) return;
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.strokeRect(Math.round(xPos)+.5, Math.round(yPos)+.5, Math.round(frameWidth), Math.round(frameHeight));
  };

  const selectMarker = async (marker) => {
    if (!marker) {setMarkerData(); setSelectedMarker({}); return};
    clearBoundingBox();
    if (boundingBoxes.length > 0) {boundingBoxes.forEach(e => drawBoundingBox(e))};
    const markerData = await JSON.parse(marker.awsMeta);
    drawBoundingBox(
      markerData?.BoundingBox || 
      markerData?.Geometry?.BoundingBox || 
      markerData?.Face?.BoundingBox, "white");
    setMarkerData(markerData || '');
    setUrl({markerId: marker.id})
    setSelectedMarker(marker);
  }

  const breadcrumbs = [
    {
      text: lookUp({ key: 'CONSOLE_CONTENT' }),
      link: '/content'
    },
    {
     text: lookUp({ key: 'CONSOLE_IMAGES' }),
      link: `/content/images`
    },
    {
     text: lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: lookUp({ key: 'CONSOLE_IMAGE' }) }),
      isCurrent: true
    }
  ];

  const redrawBoxes = () => {

    clearBoundingBox();
    boundingBoxes.forEach(e => drawBoundingBox(e));
    if (selectedMarker && Object.keys(selectedMarker).length > 0) {
      const box = JSON.parse(selectedMarker.awsMeta).Face?.BoundingBox || JSON.parse(selectedMarker.awsMeta).BoundingBox;
      if (!box) return;
      drawBoundingBox(box, "white")
    }
  }

  const browsePic = (x, y) => {
    if (!boundingBoxes[0]) return;
    const selectedBox = boundingBoxes?.find(e => 
      e.Left < x &&
      x  < (e.Left+e.Width) &&
      e.Top < y &&
      y  < (e.Top+e.Height)
      );        
    
    setHoveredBox(selectedBox);

    if (!selectedBox && hoveredBox) {
      drawBoundingBox(hoveredBox);
      setHoveredBox()
    }
    
    if (JSON.stringify(hoveredBox) !== JSON.stringify(selectedBox)) {
      drawBoundingBox(hoveredBox)
      drawBoundingBox(selectedBox, "yellow")
      let highlight = JSON.parse(selectedMarker?.awsMeta).Face?.BoundingBox || JSON.parse(selectedMarker?.awsMeta).BoundingBox;
      highlight && drawBoundingBox(highlight, "white")
      setHoveredBox(selectedBox);
    };
    

    let markerPick = markerTags.find(e => {
      return (e.awsMeta && 
        (JSON.stringify
          (JSON.parse(e.awsMeta).Face?.BoundingBox || JSON.parse(e.awsMeta).BoundingBox) 
        === JSON.stringify(selectedBox)))
      })
    if (!markerPick) { 
      setMarkerData(selectedMarker && Object.keys(selectedMarker).length > 0 ? 
        JSON.parse(selectedMarker.awsMeta) :
        {});
        return;
      }
    
    setMarkerData(JSON.parse(markerPick?.awsMeta));
  }

  const selectFromPic = (x, y) => {
    if (!boundingBoxes[0]) return;
    
    const selectedBox = boundingBoxes?.find(e => 
      e.Left < x &&
      x  < (e.Left+e.Width) &&
      e.Top < y &&
      y  < (e.Top+e.Height)
      );
    
    if (!selectedBox) return;
    let markerPick = markerTags.find(e => {
      return (e.awsMeta && 
        (JSON.stringify
          (JSON.parse(e.awsMeta).BoundingBox) 
        === JSON.stringify(selectedBox)))
      })
    if (!markerPick) {
      selectMarker({awsMeta: JSON.stringify({BoundingBox: selectedBox})});
      return;
    }
    selectMarker(markerPick);
  }

  return (
    <>
      <section className={classes.editorFrame}>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} className={classes.tabBar}>
            <AppBar position="static">
              <Tabs
                variant="scrollable"
                value={modals.length + markerTypes.indexOf(selectedType) + 1}
                indicatorColor="primary"
              >
                {modals.map((e) => (
                  <Modal
                    modalType={e}
                    key={e}
                    isOpen={isModalOpen}
                    setOpen={setIsModalOpen}
                    imageData={imageData}
                  />
                ))}
                <Tab label="" disabled icon={<ArrowForward />} />
                {markerTypes.map((e) => (
                  <Tab
                    wrapped
                    key={e}
                    onClick={() => {setSelectedType(e); setUrl({type: e})}}
                    label={markerTypeCustom.label[e] || e}
                    icon={markerTypeCustom.icon[e] || <ViewList />}
                  />
                ))}
                <Tooltip title={lookUp({ key: 'CONSOLE_METADATA_EDITOR' })}>
                  <Tab
                    wrapped
                    textColor="#ffffff"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => navigate(`/content/${id}/edit#basic`)}
                    label={lookUp({ key: 'CONSOLE_METADATA_EDITOR' })}
                    icon={<ListAlt />}
                  />
                </Tooltip>
                <Tooltip title={lookUp({ key: 'CONSOLE_LOGS_HELPERTEXT' })}>
                  <Tab
                    wrapped
                    textColor="#ffffff"
                    style={{ marginRight: 0 }}
                    onClick={() => window.open(`/access/logs/Content/${id}`, '_blank')}
                    label={lookUp({ key: 'CONSOLE_LOGS' })}
                    icon={<ViewList />}
                  />
                </Tooltip>
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={6} className={classes.imageContainer}>
            <Grid
              item
              className={classes.imageGrid}
              onMouseEnter={redrawBoxes}
              onMouseMove={(e) =>
                browsePic(
                  e.nativeEvent.offsetX / overlayWidth,
                  e.nativeEvent.offsetY / overlayHeight
                )
              }
              onClick={(e) =>
                selectFromPic(
                  e.nativeEvent.offsetX / overlayWidth,
                  e.nativeEvent.offsetY / overlayHeight
                )
              }
              onMouseLeave={redrawBoxes}
            >
              <>
                <canvas
                  ref={overlayRef}
                  height={overlayHeight}
                  width={overlayWidth}
                  style={{ width: overlayWidth, height: overlayHeight }}
                  className={classes.overlay}
                ></canvas>
                <img
                  width="60%"
                  onLoad={(e) =>
                    setDimensions({ width: e.target.naturalWidth, height: e.target.naturalHeight })
                  }
                  className={classes.image}
                  src={imageURL}
                  ref={imageCallback}
                />
              </>
            </Grid>
          </Grid>
          <Grid container direction="row-reverse" item xs={6}>          
            <Grid item xs={12} className={classes.renderTags}>
              {isLoading ? (
                <LinearProgress/>
              ) : (markerTags?.length === 0 || !markerTags[0])  && selectedType ? (
                <>
                <p>
                  <Typography variant="caption">No tags of</Typography>&nbsp;
                  <Typography variant="button">{markerTypeCustom.label[selectedType] || selectedType}</Typography>&nbsp;
                  <Typography variant="caption">type.</Typography>
                </p>
                <Button startIcon={<AddPhotoAlternate/>}  color="primary" onClick={()=>setIsModalOpen(true)}>
                  Launch indexing
                </Button>
                </>
                ) : (
                  <RenderTags
                    id={id}
                    markerTags={markerTags}
                    type={selectedType?.replace('Tag', '')}
                    setBoundingBoxes={(e) => setBoundingBoxes(e)}
                    setMarkerTags={setMarkerTags}
                    select={selectMarker}
                  />
                )}
            </Grid>
            <Grid item xs={12} ref={panelWrapperCallback}>
              <Grid item xs={12}>
                {!isLoading &&
                  (markerData || (selectedMarker && Object.keys(selectedMarker).length > 0)) && (
                    <MarkerInfo
                      data={markerData || JSON.parse(selectedMarker?.awsMeta)}
                      imageURL={imageURL}
                      dimensions={dimensions}
                    />
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default withSnackbar(ImageEditor);
