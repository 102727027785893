import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ConfirmDialog from 'components/ConfirmDialog';
import Loader from 'components/Loader';
import ThemedButton from 'components/ThemedButton';
import navigationAction from 'store/actions/navigationAction';
import noop from 'lodash/noop';

const FormFooter = ({
  isDeleting,
  isPublishing,
  isSaving,
  existPublishing,
  publishButtonText,
  saveButtonText,
  extraButtons,
  isNewItem,
  deleteData = noop,
  onPublish = noop,
  onSave = noop,
  onCancel = noop,
}) => {
  const dispatch = useDispatch();

  const cancelClick = (e) => {
    e.preventDefault();

    dispatch(navigationAction.allowNavigation());
    onCancel(e);
  };

  return (
    <Grid container alignItems="baseline" wrap="nowrap" justifyContent="flex-end">
      <Grid item xs={12} sm={6}>
        <Grid item container alignItems={'center'} justifyContent={'flex-start'} spacing={1}>
          {!isNewItem && (
            <>
              <Grid item style={{ display: !existPublishing ? 'none' : '' }}>
                {isPublishing ? (
                  <ThemedButton color={'secondary'} onClick={onPublish}>
                    {' '}
                    {publishButtonText}
                  </ThemedButton>
                ) : (
                  <ThemedButton color={'primary'} onClick={onPublish}>
                    {' '}
                    {publishButtonText}
                  </ThemedButton>
                )}
              </Grid>

              <Grid item>
                <ConfirmDialog
                  onConfirm={deleteData}
                  buttonType={'Button'}
                  buttonText={
                    isDeleting ? <Loader inline /> : lookUp({ key: 'CONSOLE_DELETE_BUTTON' })
                  }
                  dialogText="Delete"
                />
              </Grid>
            </>
          )}
          {extraButtons && extraButtons.length > 0 && (
            <Grid item>
              {extraButtons.map((button, index) => (
                <Tooltip key={index} title={button.tooltip}>
                  <span key={index}>
                    <ThemedButton
                      key={index}
                      color={button.color || 'info'}
                      disabled={button.disabled}
                      onClick={button.onClick || ''}
                    >
                      {button.text || 'OK'}
                    </ThemedButton>
                  </span>
                </Tooltip>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        container
        alignItems={'center'}
        justifyContent={'flex-end'}
        spacing={1}
      >
        <Grid item>
          <ThemedButton color="info" onClick={cancelClick}>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>
        </Grid>
        <Grid item>
          <ThemedButton color="success" onClick={onSave}>
            {isSaving ? <Loader inline /> : saveButtonText}
          </ThemedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormFooter.propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isPublishing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  saveButtonText: PropTypes.string.isRequired,
  publishButtonText: PropTypes.string.isRequired,
};

export default FormFooter;
