import { lookUp } from 'services/stringService';
import { useNavigate } from 'react-router';
import TotalAggregated from './TotalAggregated';
import ContentBillboard from './ContentBillboard';
import { Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useEffect } from 'react';

function Dashboard() {
  
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/dashboard');
  }, [])

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="h6">{lookUp({ key: 'CONSOLE_LAST_30_DAYS' })}</Typography>
        </Grid>
        <TotalAggregated />
        <ContentBillboard />
      </Grid>
    </>
  );
}

const mapStateToProps = ({ auth: { selectedBrand, user } }) => ({
  selectedBrand,
  user,
});

export default compose(connect(mapStateToProps))(Dashboard);
