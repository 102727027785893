const SET_LOADING_STATUS = 'USERS/SET_LOADING_STATUS';
const SET_ERROR_MESSAGE = 'USERS/SET_ERROR_MESSAGE';
const SET_USER = 'USERS/SET_USER';
const RESET_USER = 'USERS/RESET_USER';
const SET_ACCESS = 'SET_ACCESS';
const SET_LAST_ROUTE = 'SET_LAST_ROUTE';
const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';
const SET_ORGANIZATIONS_DETAILS = 'SET_ORGANIZATIONS_DETAILS';
const SET_SELECTED_LOCALE = 'SET_SELECTED_LOCALE';
const SET_ENDPOINTS = 'SET_ENDPOINTS';

const setEndpoints = (endpoints) => ({ type: SET_ENDPOINTS, endpoints });

const setLoadingStatus = (status) => ({ type: SET_LOADING_STATUS, status });

const setErrorMessage = (message) => ({ type: SET_ERROR_MESSAGE, message });

const setUser = (user) => ({ type: SET_USER, user });

const resetUser = () => ({ type: RESET_USER });

const setAccess = (access) => ({ type: SET_ACCESS, access });

const setLastRoute = (lastRoute) => {
  if (lastRoute.includes('return-lms')) {
    return ({ type: SET_LAST_ROUTE, lastRoute: '/' });
  } else {
    return ({ type: SET_LAST_ROUTE, lastRoute });
  }
};

const setSelectedBrand = (brand) => ({ type: SET_SELECTED_BRAND, brand });

const setSelectedOrganization = (org) => ({ type: SET_SELECTED_ORGANIZATION, org });

const setOrganizationsDetails = (orgsDetails) => ({ type: SET_ORGANIZATIONS_DETAILS, orgsDetails });

const setSelectedLocale = (locale) => ({ type: SET_SELECTED_LOCALE, locale });

export {
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  SET_USER,
  RESET_USER,
  SET_ACCESS,
  SET_LAST_ROUTE,
  SET_SELECTED_BRAND,
  SET_SELECTED_ORGANIZATION,
  SET_ORGANIZATIONS_DETAILS,
  SET_SELECTED_LOCALE,
  SET_ENDPOINTS,
  setLoadingStatus,
  setErrorMessage,
  setUser,
  setAccess,
  resetUser,
  setLastRoute,
  setSelectedBrand,
  setSelectedOrganization,
  setOrganizationsDetails,
  setSelectedLocale,
  setEndpoints
};

export default {
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  SET_USER,
  RESET_USER,
  SET_LAST_ROUTE,
  SET_SELECTED_BRAND,
  SET_SELECTED_ORGANIZATION,
  SET_ORGANIZATIONS_DETAILS,
  SET_ENDPOINTS,
  setLoadingStatus,
  setErrorMessage,
  setUser,
  resetUser,
  setLastRoute,
  setSelectedBrand,
  setSelectedOrganization,
  setOrganizationsDetails,
  setEndpoints
};
