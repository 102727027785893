import React from 'react';
import { lookUp } from 'services/stringService';
import { IconButton, Tooltip } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import tracewindService from 'services/tracewindService';

const LogButton = (props) => {
  
  const { id, type } = props;
  const tracewindType = tracewindService.mapTypes(type);

  return (
    <Tooltip title={lookUp({ key: 'CONSOLE_CHECK_CONTENT_LOG' })}>
      <IconButton component="a" href={`/access/logs/${tracewindType}/${id}`} size="small">
        <ListAlt />
      </IconButton>
    </Tooltip>
  )
}

export default LogButton;