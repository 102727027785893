import React from 'react';
import { lookUp } from 'services/stringService';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { arrayOf, bool, func, object, } from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Link,
  makeStyles,
} from '@material-ui/core';
import {
  PlayCircleFilled,
} from '@material-ui/icons';
import { ConfirmDialog, CreatedOn } from 'components';
import LogButton from 'components/Audit/LogButton';
import { UnFavouriteIcon } from 'assets/icons/FavouriteIcons';
import PublishedComponent from 'assets/icons/PublishedComponent'
import UnPublishedComponent from 'assets/icons/UnPublishedComponent'
import contentConst from 'constants/contentConst';
import { msToHHMMSS } from 'helpers/time';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  favouritesContainer: {
    borderTop: '1px solid #C0C0C0',
    borderBottom: '1px solid #C0C0C0',
    borderRadius: '5px',
    margin: theme.spacing(2, 0, 3, 0),
    padding: theme.spacing(0, 1),
  },
  favouriteItem: {
    padding: theme.spacing(0.3, 1.5),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  favouriteText: {
    paddingTop: theme.spacing(0.5),
  },
}));


function FavouriteContents(props) {
  const {
    favourites,
    favouriteProps,
    setIsDeleting,
    isDeleting,
    enqueueSnackbar,
   } = props;

  const {
    contentType,
    deleteItem,
    contentPreviewIcon,
    setPreviewItem,
    removeFromFavourite,
   } = favouriteProps;

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDelete = async (item) => {
    if (!isDeleting) {
      setIsDeleting(true);
      deleteItem(item)
        .then(() => removeFromFavourite(item.id))
        .then(() => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: item.name || item.key || lookUp({ key: 'CONSOLE_item' }) })
          });
        })
        .catch(error => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', title: item.name || item.key || lookUp({ key: 'CONSOLE_ITEM' }) }),
          });
        })
        .finally(() => setIsDeleting(false));
    }
  };

  const tableBodyElements = [
    {
      name: 'preview',
      align: 'center',
      render: (item) => (
        contentType === contentConst.types.Audio ||
        contentType === contentConst.types.Podcast
      ? (
          <Tooltip title={lookUp({ key: 'CONSOLE_PREVIEW' })} className={classes.preview} >
            <IconButton onClick={() => dispatch(setPreviewItem(item))} >
              {contentPreviewIcon()}
            </IconButton>
          </Tooltip>
      ) : contentType === contentConst.types.Video ? (
          <Link
            to={`/content/${item.id}/editor`}
            component={RouterLink}
          >
            <IconButton>
              <PlayCircleFilled />
            </IconButton>
          </Link>
        ) : (
        ''
        )
      )
    },
    {
      name: 'name',
      align: 'left',
      render: (item) => (
        <Link
          to={`/content/${item.id}/edit`}
          component={RouterLink}
        >
          {item.name}
        </Link>
      )
    },
    {
      name: 'createdDate',
      align: 'left',
      render: (item) =>
        item.createdDate ? <CreatedOn date={item.createdDate} /> : <Remove />
    },
    {
      name: 'favourite',
      align: 'left',
      render: (item) => {
        return (
          <UnFavouriteIcon
            removeFavourite={() => removeFromFavourite(item.id)}
          />
        );
      }    
    },
    {
      name: 'published',
      align: 'left',
      render: (item) => item.published ? <PublishedComponent /> : <UnPublishedComponent />
    },
    {
      name: 'logs',
      align: 'center',
      render: (item) => <LogButton type='Content' id={item.id} />
    },
  ];

  if (contentType !== contentConst.types.Image) {
    tableBodyElements.splice(2, 0, {
      name: 'duration',
      align: 'left',
      render: (item) =>  item.duration ? msToHHMMSS(item.duration) : lookUp({ key: 'CONSOLE_NA' })
    });
  }

  return (
    <>
      {favourites.length > 0 && (
        <>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            padding="none"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  >
                </TableCell>
                <TableCell
                  align="left"
                  >
                    Name
                </TableCell>
                {contentType !== 'images' && 
                  <TableCell
                    >
                     Duration
                  </TableCell>
                }
                <TableCell
                  >
                   Created On
                </TableCell>
                <TableCell
                  >
                </TableCell>
                <TableCell
                  >
                </TableCell>
                <TableCell
                  >
                </TableCell>
                <TableCell
                  >
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {favourites.map((fav, index) => {
                return (
                  <TableRow
                    hover
                    padding="none"
                    key={fav.id || index}
                  >
                    {tableBodyElements.map((element) => (
                      <TableCell
                        align={element.align || 'right'}
                        key={element.name}
                        component={element.name === 'name' ? 'th' : ''}
                        scope={element.name === 'name' ? 'row' : ''}
                      >
                        {element.render ? element.render(fav) : fav[element.name].toISOString()}
                      </TableCell>
                    ))}
                    <TableCell align={'center'} padding="none" className={classes.smallColumn}>
                      <ConfirmDialog
                        onConfirm={() => handleDelete(fav)}
                        message={lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: fav.name || fav.key || 'item'})}
                        dialogText='Delete'
                        disabled={isDeleting}
                      />
                    </TableCell>
                  </TableRow>
              )})}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
};

FavouriteContents.propTypes = {
  favourites: arrayOf(object),
  favouriteProps: object,
  setIsDeleting: func.isRequired,
  isDeleting: bool,
  enqueueSnackbar: func.isRequired
};

export default withSnackbar(FavouriteContents);
