import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  MenuItem,
  TextField,
  Chip,
  Grid,
  FormControl,
  ListSubheader,
  InputLabel,
  Select,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCategorizationTypeEnums } from 'services/contentService';
import { getContentRelationsEnum } from 'services/collectionsServiceV2';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '96%',
    marginLeft: '2%',
    marginRight: '2%',
    paddingTop: '2%',
  },
  formControl: {
    width: '100%',
  },
}));

const EditTags = (props) => {
  
  const { onAttribChange, selectedNode } = props;
  const classes = useStyles();

  const [inputList, setInputList] = useState({...selectedNode.categorizations, ...selectedNode.referenceItems});
  const [tagsOptions, setTagsOptions] = useState([]);
  const [categorizationsOptions, setCategorizationsOptions] = useState([])
  const [referencesOptions, setReferencesOptions] = useState([])
  const [selectedType, setSelectedType] = useState('');

  const handleTypeChange = (event) => {
    const type = event.target.value
    setSelectedType(type);
    setInputList((inputList) => ({...inputList, [type]: '' }))
  };

  const handleAttributeChange = (value, type) => {
    const attribute = categorizationsOptions.includes(type) ? 'categorizations' : 'referenceItems';
    onAttribChange(
      { ...selectedNode[attribute], [type]: value },
      'text',
      selectedNode.id,
      attribute
    );
    setInputList((inputList) => ({...inputList, [type]: value }))
  };

  useEffect(() => {
    const availableCategories = categorizationsOptions?.filter(category => !Object.keys(inputList).includes(category))
    const availableReferences = referencesOptions?.filter(reference => !Object.keys(inputList).includes(reference))
    setTagsOptions({ Categories: availableCategories, References: availableReferences })

  }, [inputList, categorizationsOptions, referencesOptions])

  useEffect(() => {
    const getOptions = async () => {
      const getCategories = await getCategorizationTypeEnums()
      const getReferences = await getContentRelationsEnum()
      const arrayCategories = getCategories.map((category) => Object.keys(category)[0])
      setCategorizationsOptions(arrayCategories)
      setReferencesOptions(getReferences)
    };
    getOptions();
  }, [selectedNode.id]);

  return (
    <Grid container>
      <FormControl className={classes.input} margin="dense" variant="outlined">
        <InputLabel htmlFor="grouped-tag-types">Tag type</InputLabel>
        <Select
          id="grouped-tag-types"
          label={lookUp({ key: 'CONSOLE_TAG_TYPE'} )}
          value={selectedType || ''}
          onChange={handleTypeChange}
        >
          {Object.entries(tagsOptions).map((options, optionsIndex) => [
              <ListSubheader key={optionsIndex}>{options[0]}</ListSubheader>,
              options[1].map((option, optionIndex) => (
                <MenuItem key={optionIndex} value={option}>
                  {option}
                </MenuItem>
              )),
            ])}
        </Select>
      </FormControl>
      {!!inputList &&
        Object.entries(inputList).map(((input, index) => (
          <TagInputs key={`tagInput_${index}`} handleAttributeChange={handleAttributeChange} input={input} index={index} />
        )))}
    </Grid>
  );
};

export default EditTags;

const TagInputs = ({ handleAttributeChange, input, index }) => {

  const classes = useStyles();
  const type = input[0]
  const value = input[1]

  return (
    <Grid item className={classes.formControl}>
      <Autocomplete
        multiple
        freeSolo
        id={`tagValue_${index}`}
        filterSelectedOptions
        size="small"
        margin="dense"
        className={classes.input}
        options={[]}
        value={value|| []}
        onChange={(event, value) => handleAttributeChange(value, type)}
        getOptionSelected={(option, value) => option === value}
        renderTags={(value, getTagProps) =>
          value.map((option, chipIndex) => (
            <Chip key={`chip_${chipIndex}`} color="primary" label={option} {...getTagProps({ chipIndex })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={type} />
        )}
      />
    </Grid>
  );
};
