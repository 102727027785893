import axios from 'axios';
import {
  setConsumptionPlayoutPlaybacks,
  setConsumptionPlayoutPlaybacksLoadingStatus,
  setConsumptionTopSongs,
  setConsumptionTopSongsLoadingStatus,
  setConsumptionTopArtists,
  setConsumptionTopArtistsLoadingStatus,
  setConsumptionTopPlaylists,
  setConsumptionTopPlaylistsLoadingStatus,
  setUserLogs,
  setUserLogsLoadingStatus
} from 'store/actions/insightsAction';

import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getConsumptionPlayoutPlaybacks = () => (dispatch) => {
  dispatch(setConsumptionPlayoutPlaybacksLoadingStatus(true));
  return axios.get(`${getConsoleApiEndpoint('InsightsConsumption')}/PlayoutPlaybacks`)
    .then((response) => dispatch(setConsumptionPlayoutPlaybacks(response?.data)))
    .catch((error) => error)
    .finally(() => dispatch(setConsumptionPlayoutPlaybacksLoadingStatus(false)));
};

const getConsumptionTopSongs = (filters) => async (dispatch) => {
  dispatch(setConsumptionTopSongsLoadingStatus(true));
  return axios.get(makeApiWithQuery(`${getConsoleApiEndpoint('InsightsConsumption')}/TopSongs`, filters))
    .then((response) => dispatch(setConsumptionTopSongs(response?.data)))
    .catch((error) => error)
    .finally(() => dispatch(setConsumptionTopSongsLoadingStatus(false)));
};

const getConsumptionTopArtists = () => async (dispatch) => {
  dispatch(setConsumptionTopArtistsLoadingStatus(true));
  return axios.get(`${getConsoleApiEndpoint('InsightsConsumption')}/TopArtists`)
    .then((response) => dispatch(setConsumptionTopArtists(response?.data)))
    .catch((error) => error)
    .finally(() => dispatch(setConsumptionTopArtistsLoadingStatus(false)));
};

const getConsumptionTopPlaylists = () => async (dispatch) => {
  dispatch(setConsumptionTopPlaylistsLoadingStatus(true));
  return axios.get(`${getConsoleApiEndpoint('InsightsConsumption')}/TopPlaylists`)
    .then((response) => dispatch(setConsumptionTopPlaylists(response?.data)))
    .catch((error) => error)
    .finally(() => dispatch(setConsumptionTopPlaylistsLoadingStatus(false)));
};

const search = (
  { page = 0, perPage = 1000, q = '', sortBy = 'createdDate', order = 'desc', ...rest } = {}
) =>
  axios
    .get(
      makeApiWithQuery(`${getConsoleApiEndpoint('InsightsUserLogs')}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        q,
        ...rest
      })
    )
    .then((r) => r.data);


const getUserLogs = () => async (dispatch) => {
  dispatch(setUserLogsLoadingStatus(true));
  return axios.get(getConsoleApiEndpoint('InsightsUserLogs'))
    .then((response) => dispatch(setUserLogs(response?.data)))
    .catch((error) => error)
    .finally(() => dispatch(setUserLogsLoadingStatus(false)));
};

export {
  getConsumptionPlayoutPlaybacks,
  getConsumptionTopSongs,
  getConsumptionTopArtists,
  getConsumptionTopPlaylists,
  search,
  getUserLogs
};

export default {
  getConsumptionPlayoutPlaybacks,
  getConsumptionTopSongs,
  getConsumptionTopArtists,
  getConsumptionTopPlaylists,
  search,
  getUserLogs
};
