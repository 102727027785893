import { store } from 'store/configureStore';
import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';
import { addContentRelation } from './collectionsServiceV2';


const selectedOrg = () => {
  if (store.getState().auth?.user?.role === 'SysAdmin') return undefined;
  const selectedOrg = store.getState().auth?.selectedOrganization ||
    localStorage.getItem('selectedOrganization');
  return (!selectedOrg || selectedOrg === 'undefined') ? undefined : selectedOrg;
}

const subTitleUrl = '/sample.vtt';
const subSrtUrl = '/example.srt';

const getContentEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Content');
  if (!endpoint) throw Error('No Content endpoint.');
  return endpoint;
};

const getContentEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('ContentEnums');
  if (!endpoint) throw Error('No ContentEnums endpoint.');
  return endpoint;
};

const getAccessEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Access');
  if (!endpoint) throw Error('No Access endpoint.');
  return endpoint;
};

const getAssetEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Asset');
  if (!endpoint) throw Error('No Asset endpoint.');
  return endpoint;
};

const getRecordingEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Recording');
  if (!endpoint) throw Error('No Recording endpoint.');
  return endpoint;
};

const getVODToLiveEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('VodToLive');
  if (!endpoint) throw Error('No VODToLive endpoint.');
  return endpoint;
};

const getLiveStreamEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('LiveStream');
  if (!endpoint) throw Error('No LiveStream endpoint.');
  return endpoint;
}

const create = async (model) => {
  return axios.post(`${getContentEndpoint()}`, model)
    .then((res) => res?.data)
    .then(async newContent => {
      return (store.getState().auth?.user?.role === 'SysAdmin' || !selectedOrg())
        ? newContent
        : ({
          ...newContent,
          relation: await addContentRelation(newContent.id, 'Organization', selectedOrg())
        });
    });
};

const search = ({
  page = 0,
  perPage = 100,
  sortBy,
  q = '',
  orgs = selectedOrg(),
  ...rest } = {}
) => {
  const { from, to } = rest;
  delete rest.from;
  delete rest.to;
  return axios
    .get(
      makeApiWithQuery(`${getContentEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        q,
        createdFrom: from,
        createdUntil: to,
        orgs,
        ...rest,
      })
    )
    .then((res) => res?.data)
};

const searchQuery = (searchParams, pageSize = 25, page = 0) => {
  const safeParams = !searchParams instanceof URLSearchParams
    ? new URLSearchParams(searchParams) : searchParams;

  if (store.getState().auth?.user?.role !== 'SysAdmin' && selectedOrg()) {
    safeParams.append?.('orgs', selectedOrg());
  }

  if (safeParams.has?.('page')) {
    safeParams.delete?.('page');
  }

  if (safeParams.has?.('pageSize')) {
    safeParams.delete?.('pageSize');
  }

  return axios
    .get(`${getContentEndpoint()}/Search/${pageSize}/${page}`, { params: safeParams })
    .then(res => res.data);
};

const searchOnlyName = ({
  page = 0,
  perPage = 1000,
  hasVideo = 'true',
  sortBy = 'originalTitle',
  order = 'asc',
  q = '',
  ...rest
} = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getContentEndpoint()}/Search/${perPage}/${page}`, {
        hasVideo,
        sortBy,
        order,
        q,
        ...rest,
      })
    )
    .then((res) => {
      return res?.data?.data?.map((re) => re.originalTitle);
    });

const searchName = (
  originalTitle,
  {
    page = 0,
    perPage = 1000,
    hasVideo = 'true',
    sortBy = 'originalTitle',
    order = 'asc',
    q = originalTitle,
    ...rest
  } = {}
) =>
  axios
    .get(
      makeApiWithQuery(`${getContentEndpoint()}/Search/${perPage}/${page}`, {
        hasVideo,
        sortBy,
        order,
        q,
        ...rest,
      })
    )
    .then((res) => {
      return res.data;
    });

const searchManual = ({
  page = 0,
  perPage = 1000,
  sortBy = 'originalTitle',
  order = 'asc',
  q = '',
  ...rest
} = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getContentEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        q,
        ...rest,
      })
    )
    .then((res) => res.data);

const searchSponsorPromoVideo = (id) =>
  axios
    .get(
      `${getContentEndpoint()}/Search/100/0?type=Video&sponsored=${id}&sortBy=originalTitle%20DESC`
    )
    .then((res) => res.data);

const searchPromoVideo = (id) =>
  axios
    .get(
      `${getContentEndpoint()}/Search/100/0?type=Video&byArtist=${id}&sortBy=originalTitle%20DESC`
    )
    .then((res) => res.data);

const searchTrack = ({
  page = 0,
  perPage = 1000,
  sortBy = 'originalTitle',
  order = 'asc',
  q = '',
  ...rest
} = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getContentEndpoint()}/Search/${perPage}/${page}`, {
        order,
        q,
        ...rest,
      })
    )
    .then((res) => res.data);

const searchVideo = () =>
  axios
    .get(`${getContentEndpoint()}/Search/10/0?type=Video&sortBy=originalTitle%20ASC`)
    .then((res) => res.data);

const getById = (id) =>
  axios
    .get(`${getContentEndpoint()}/${id}`)
    .then((res) => res.data)

const getSrtSubtitles = async () => {
  const res = await axios.get(`${subSrtUrl}`);
  const result = res.data.split('\n').map((item) => {
    const parts = item.split('\n');
    return {
      time: parts[0],
      text: parts[1],
    };
  });
  result.shift();
  result.pop();
  const splitResult = result.map((a) => {
    const time = a.time;
    const text = a.text;
    return {
      time: time,
      text: text,
    };
  });
  return splitResult;
};

const getSubtitles = async () => {
  const res = await axios.get(`${subTitleUrl}`);
  const result = res.data.split('\n').map((item) => {
    const parts = item.split('\n');
    return {
      time: parts[0],
      text: parts[1],
    };
  });
  result.shift();
  result.pop();

  const splitResult = result.map((a) => {
    const time = a.time;
    const text = a.text;
    return {
      time: a.time,
      text: text,
    };
  });
  return splitResult;
};

const getVttFile = async (url) => {
  if (!url) return [];
  const res = await axios.get(`${url}`, {
    headers: {
      'content-type': 'text/html',
      Accept: 'text/html,application/xhtml+xml',
    },
  });

  const serviceEndpointArr = url.split('//').pop().split('/');
  serviceEndpointArr.pop();
  const serviceEndpoint = serviceEndpointArr.join('/');
  const result = await res.data.split('\n\n').map((item) => {
    const parts = item.split('\n');
    return {
      time: parts[0],
      thumbnail: parts[1],
    };
  });
  result.shift();
  result.pop();

  const splitResult = result.map((a) => {
    const fileName = a.thumbnail;
    const splitUrl = `https://${serviceEndpoint}/${fileName}`;
    return {
      time: a.time,
      thumbnail: splitUrl,
    };
  });
  return splitResult;
};

const getImage = (id) => axios.get(`${getContentEndpoint()}/Image/${id}`).then((res) => res.data);

const edit = (model) => {
  return axios.put(`${getContentEndpoint()}/${model.id} `, model);
};

const remove = (id) => axios.delete(`${getContentEndpoint()}/${id}`);

const ingest = (body) => axios.post(`${getContentEndpoint()}/Ingest`, body)
  .then(async newContent => {
    return store.getState().auth?.user?.role === 'SysAdmin'
      ? newContent
      : ({
        ...newContent,
        relation: await addContentRelation(newContent.data?.id, 'Organization', selectedOrg())
      });
  });

const ingestAsset = (body) => axios.post(`${getContentEndpoint()}/IngestAsset`, body)
  .then(async newContent => {
    return store.getState().auth?.user?.role === 'SysAdmin'
      ? newContent
      : ({
        ...newContent,
        relation: await addContentRelation(newContent.data?.id, 'Organization', selectedOrg())
      });
  });

const getDrmData = async (contentId) => {
  let drmresult = {};
  try {
    let result = await axios.get(`${getContentEndpoint()}/${contentId}/DrmData`);
    if (result.data.HLS) {
      drmresult['HLS'] = result.data.HLS.workflowStatus;
    }

    if (result.data.DASH) {
      drmresult['DASH'] = result.data.DASH.workflowStatus;
    }
    return drmresult;
  } catch (error) {
    throw error;
  }
};

// defined default query parameters in order send over the S3 upload service, isPublic used to determine the asset cdn
const DEFAULT_PARAMS_INGEST_ASSET = { isPublic: true };
const createIngestAccess = (filename, assetType, queryParams = DEFAULT_PARAMS_INGEST_ASSET) => {
  const params = new URLSearchParams(Object.entries(queryParams));
  const url = `${getAssetEndpoint()}/Upload/${assetType}/${filename}`;

  return axios.get(url, { params }).then((res) => res.data);
}

const getDrmEnums = () => axios.get(`${getContentEnumsEndpoint()}/DRM`).then((res) => res.data);

const getTypeEnums = () => axios.get(`${getContentEnumsEndpoint()}/Type`).then((res) => res.data);

const getAssetTypeEnums = () => axios.get(`${getContentEnumsEndpoint()}/AssetType`).then((res) => res.data);

const getSubtypeEnums = () =>
  axios.get(`${getContentEnumsEndpoint()}/SubType`).then((res) => res.data);

const getCategorizationTypeEnums = () =>
  axios.get(`${getContentEnumsEndpoint()}/CategorizationType`).then((res) => res.data);

const getReferenceListTypeEnums = () =>
  axios.get(`${getContentEnumsEndpoint()}/ReferenceListType`).then((res) => res.data);

const getMusimapMoodEnums = () =>
  axios.get(`${getContentEnumsEndpoint()}/MusimapMood`).then((res) => res.data);

const getRoleEnums = () => axios.get(`${getContentEnumsEndpoint()}/Role`).then((res) => res.data);

const getFavouriteContents = async () => {
  const res = await axios.get(`${getAccessEndpoint()}/Settings/Favorite/Content`);
  return res.data;
};

const getFavouriteContent = async (id) => {
  const res = await axios.get(`${getAccessEndpoint()}/Settings/Favorite/Content/${id}`);
  return res.data;
};

const addFavouriteContent = async (id) => {
  const res = await axios.post(`${getAccessEndpoint()}/Settings/Favorite/Content/${id}`);
  return res.data;
};

const deleteFavouriteContent = async (id) => {
  const res = await axios.delete(`${getAccessEndpoint()}/Settings/Favorite/Content/${id}`);
  return res.data;
};

const downloadFile = (url, fileName) => {
  let filename;
  return axios
    .get(url)
    .then((response) => {
      filename = response.data.split('?')[0].split('/').pop();
      return fetch(response.data);
    })
    .then((response) => {
      if (({ url } = response)) filename = url.split('%3B%20filename%3D')[1]?.split('&')[0];
      const reader = response.body.getReader();
      return new ReadableStream({
        start(controller) {
          return pump();
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                return;
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);
              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => {
      const file = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = file;
      link.setAttribute('download', fileName || filename);
      document.body.appendChild(link);
      link.click();
    })
};

const downloadJSON = (fileName, contentJSON) => {
  return new Promise((resolve, reject) => {
    try {
      const fileBlob = new Blob([JSON.stringify(contentJSON)], { type: 'application/json' });
      const url = window.URL.createObjectURL(fileBlob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${fileName}.json`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      resolve(null);
    } catch (error) {
      reject(error);
    }
  });
};

const downloadAsset = (contentId, assetId) => axios
  .get(`${getContentEndpoint()}/DownloadAsset/${contentId}/${assetId}`);

const exportData = (loadParams, itemIds) => {
  const { pageSize, page } = loadParams;
  return axios
    .put(
      makeApiWithQuery(`${getContentEndpoint()}/Export/${pageSize || 25}/${page || 0}`, loadParams),
      itemIds ? {
        outputFileExtension: 'json',
        itemIds,
      } : {outputFileExtension: 'json'}
    )
};

const uploadJSON = (contentText) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const contentItems = JSON.parse(event.target.result);
      resolve(contentItems);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(contentText);
  })
    .then((contentItems) => {
      const contentsUpload = contentItems.map((cItem) => create(cItem));
      return Promise.all([...contentsUpload]);
    })
};

const refreshAll = () => axios.put(`${getContentEndpoint()}/RefreshAll`);

const createRecordingContent = (parentId, originalTitle, originalLanguage, workflowId) => {
  return axios.post(`${getRecordingEndpoint()}/Content/${parentId}`, {
    originalTitle,
    workflowId,
    originalLanguage,
    type: "None",
  })
  .then(async newContent => {
    return store.getState().auth?.user?.role === 'SysAdmin'
      ? newContent
      : ({
        ...newContent,
        relation: await addContentRelation(newContent.data?.id, 'Organization', selectedOrg())
      });
  });
};

const getMeetingData = (meetingId) => {
  return axios
  .get(`${getRecordingEndpoint()}/${meetingId}`)
  .then((res) => res.data);
}

const getAttendeeData = (meetingId) => {
  return axios
  .put(`${getRecordingEndpoint()}/${meetingId}/Connect`)
  .then((res) => res.data);
}

const startRecording = (meetingId) => {
  return axios
  .post(`${getRecordingEndpoint()}/${meetingId}`)
  .then((res) => {
    return res.data;
  });
}

const finishRecording = (meetingId) => {
  return axios
  .delete(`${getRecordingEndpoint()}/${meetingId}`)
  .then((res) => {
    return res.data;
  });
}

const createVODtoLive = (contentId, body) => {
  return axios.post(`${getVODToLiveEndpoint()}/${contentId}`, body)
  .then((res) => res.data);
}

const getPlaybackUrl = (url) => axios.get(url).then(res => res.data);

const createLiveStream = (body) => {
  return axios.post(`${getLiveStreamEndpoint()}/Start`, body)
  .then((res) => res.data);
}

/**
 * fetch notification category enums, map the returned hasmap into an array of notifications strings
 * 
 * @returns string[]
 */
const getContentNotificationEnums = async () => {
  const { data = [] } = await axios.get(`${getContentEnumsEndpoint()}/GetContentNotifications`);
  const notifications = data.map?.((notification) => {
    const [[ name ]] = Object.entries(notification);
    return name;
  });

  return notifications ?? [];
}

export {
  create,
  search,
  searchOnlyName,
  searchManual,
  searchPromoVideo,
  searchSponsorPromoVideo,
  searchVideo,
  searchTrack,
  searchName,
  searchQuery,
  getById,
  getVttFile,
  getSubtitles,
  getSrtSubtitles,
  getFavouriteContents,
  getFavouriteContent,
  addFavouriteContent,
  deleteFavouriteContent,
  getImage,
  getDrmData,
  edit,
  remove,
  ingest,
  ingestAsset,
  createIngestAccess,
  getDrmEnums,
  getMusimapMoodEnums,
  getTypeEnums,
  getAssetTypeEnums,
  getCategorizationTypeEnums,
  getReferenceListTypeEnums,
  getSubtypeEnums,
  getRoleEnums,
  uploadJSON,
  downloadJSON,
  downloadFile,
  downloadAsset,
  exportData,
  refreshAll,
  createRecordingContent,
  getMeetingData,
  getAttendeeData,
  startRecording,
  finishRecording,
  createVODtoLive,
  getContentNotificationEnums,
  createLiveStream,
};

export default {
  create,
  search,
  searchOnlyName,
  searchManual,
  searchPromoVideo,
  searchSponsorPromoVideo,
  searchVideo,
  searchTrack,
  searchName,
  searchQuery,
  getById,
  getVttFile,
  getSrtSubtitles,
  getSubtitles,
  getFavouriteContents,
  getFavouriteContent,
  addFavouriteContent,
  deleteFavouriteContent,
  getImage,
  getDrmData,
  edit,
  remove,
  ingest,
  ingestAsset,
  createIngestAccess,
  getDrmEnums,
  getMusimapMoodEnums,
  getTypeEnums,
  getAssetTypeEnums,
  getCategorizationTypeEnums,
  getReferenceListTypeEnums,
  getSubtypeEnums,
  getRoleEnums,
  getPlaybackUrl,
  uploadJSON,
  downloadJSON,
  downloadFile,
  downloadAsset,
  exportData,
  refreshAll,
  createRecordingContent,
  getMeetingData,
  getAttendeeData,
  startRecording,
  finishRecording,
  createVODtoLive,
  getContentNotificationEnums,
  createLiveStream,
};
