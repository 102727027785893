import { 
  SET_LEXICONS, 
  SET_PROJECT_LANGUAGES, 
  SET_CONSOLE_LANGUAGES,
  SET_SELECTED_LEXICON 
} from '../actions/localizationAction';

export const initialState = {
  consoleLanguages: [],
};

const localizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEXICONS:
      return {
        ...state,
        ...action.lexicons,
      };
    case SET_PROJECT_LANGUAGES:
    return {
      ...state,
      projectLanguages: action.languages,
    };
    case SET_CONSOLE_LANGUAGES:
    return {
      ...state,
      consoleLanguages: action.languages,
    };
    case SET_SELECTED_LEXICON:
      return {
        ...state,
        selected: action.selectedLexicon,
      };
    default:
      return state;
  }
};

export default localizationReducer;
