import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Switch,
  Chip,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { AttachMoney, BarChart } from '@material-ui/icons';
import { ColorPicker, EditorInfo, Loader, Tabbing, TimePicker } from 'components';
import { goBackOrStay } from 'helpers/common';
import skuService from 'services/skuService';
import playbackPolicyService from 'services/playbackPolicyService';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';
import ReportTab from './ReportTab';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const SkuForm = ({ enqueueSnackbar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const location = useLocation();
  const tab = location.hash?.substring(0);

  const [model, setModel] = useState({
    productId: '',
    name: '',
    labels: {
      Tag: [],
      Project: [],
      Organization: []
    },
    store: '',
    type: '',
    availableFrom: '',
    availableUntil: '',
    enabled: false,
    color: '#000000',
    popular: false,
    details: '',
    rentalPeriod: {
      unit: '',
      amount: 1,
    },
    playbackPolicyId: null,
    basePriceUSD: null,
    index: undefined,
    activateAfterFirstUsage: false,
    gracePeriod: ""
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [storeTypeOptions, setStoreTypeOptions] = useState([]);
  const [isPbPolicyOptionsLoading, setIsPbPolicyOptionsLoading] = useState(false);
  const [pbPolicyOptions, setPbPolicyOptions] = useState([]);
  const [skuTypeOptions, setSkuTypeOptions] = useState([]);
  const [rentalPeriodTypes, setRentalPeriodTypes] = useState([]);

  const tabs = [
    {
      name: 'basic',
      content: (
        <>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_NAME' })}
                  required
                  value={model.name || ''}
                  aria-describedby="name"
                  onChange={(e) => handleModelChange('name', e.target.value)}
                />
                <FormHelperText id="name">
                  {lookUp({ key: 'CONSOLE_SKU_NAME_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  freeSolo
                  blurOnSelect={true}
                  filterSelectedOptions
                  options={[]}
                  value={model.labels.Tag || []}
                  onChange={(_, tagNames) => handleLabelsChange('Tag', [...tagNames])}
                  getOptionSelected={(option, value) => option === value}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={lookUp({ key: `CONSOLE_SKU_TAGS` })} />
                  )}
                  aria-describedby="tags"
                />
                <FormHelperText id="tags">
                  {lookUp({ key: 'CONSOLE_MONETIZATION_SKU_TAGS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  freeSolo
                  blurOnSelect={true}
                  filterSelectedOptions
                  options={[]}
                  value={model.labels.Project || []}
                  onChange={(_, tagNames) => handleLabelsChange('Project', [...tagNames])}
                  getOptionSelected={(option, value) => option === value}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={lookUp({ key: `CONSOLE_SKU_PROJECTS_TAGS` })} />
                  )}
                  aria-describedby="tags"
                />
                <FormHelperText id="tags">
                  {lookUp({ key: 'CONSOLE_MONETIZATION_SKU_PROJECTS_TAGS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  freeSolo
                  blurOnSelect={true}
                  filterSelectedOptions
                  options={[]}
                  value={model.labels.Organization || []}
                  onChange={(_, tagNames) => handleLabelsChange('Organization', [...tagNames])}
                  getOptionSelected={(option, value) => option === value}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip color="primary" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField {...params} label={lookUp({ key: `CONSOLE_SKU_ORGANIZATION_TAGS` })} />
                  )}
                  aria-describedby="tags"
                />
                <FormHelperText id="tags">
                  {lookUp({ key: 'CONSOLE_MONETIZATION_SKU_ORGANIZATION_TAGS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  disabled={!storeTypeOptions?.length}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  required
                  label={lookUp({ key: 'CONSOLE_PLATFORM' })}
                  value={model.store}
                  onChange={({ target }) => handleModelChange('store', target.value)}
                  aria-describedby="platform"
                >
                  {storeTypeOptions.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText id="platform">
                  {lookUp({ key: 'CONSOLE_MONETIZATION_PLATFORM_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  disabled={!skuTypeOptions?.length}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  required
                  label={lookUp({ key: 'CONSOLE_TYPE' })}
                  value={model.type}
                  onChange={({ target }) => handleModelChange('type', target.value)}
                >
                  {skuTypeOptions.map((e, i) => (
                    <MenuItem key={i} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_MONETIZATION_TYPE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_PRODUCTID' })}
                  required
                  fullWidth
                  value={model.productId || ''}
                  onChange={(e) => handleModelChange('productId', e.target.value)}
                />
                <FormHelperText>{lookUp({ key: 'CONSOLE_PRODUCTID_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={10}>
                <FormControl fullWidth>
                  <TextField
                    label={lookUp({ key: 'CONSOLE_PRICE' })}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney color={'action'} fontSize={'small'} />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ min: 0.01, step: 0.01 }}
                    fullWidth
                    value={model.basePriceUSD || null}
                    onChange={(e) => handleModelChange('basePriceUSD', e.target.value)}
                  />
                  <FormHelperText>
                    {lookUp({ key: 'CONSOLE_BASEPRICEUSD_HELPERTEXT' })}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title={lookUp({ key: 'CONSOLE_POPULAR' })}>
                  <Switch
                    checked={model.popular}
                    onChange={() => handleModelChange('popular', !model.popular)}
                    color={'primary'}
                  />
                </Tooltip>
                {lookUp({ key: 'CONSOLE_POPULAR_HEADING' })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ColorPicker
                color={model.color || '#000000'}
                label={lookUp({ key: 'CONSOLE_COLOR' })}
                handleChange={(color) => handleModelChange('color', color)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_PRODUCT_INDEX' })}
                  type="number"
                  fullWidth
                  value={model.index ? model.index : null}
                  onChange={(e) => handleModelChange('index', e.target.value)}
                />
                <FormHelperText>{lookUp({ key: 'CONSOLE_PRODUCT_INDEX_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_PLAYBACK_POLICY_TITLE' })}
                </Typography>
              </Grid>
              <Grid xs={12}>
                {isPbPolicyOptionsLoading ? (
                  <Loader inline />
                ) : (
                  <FormControl fullWidth>
                    <TextField
                      select
                      disabled={!pbPolicyOptions?.length}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      required
                      label={lookUp({ key: 'CONSOLE_PLAYBACKPOLICYID' })}
                      placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                      value={model.playbackPolicyId || null}
                      onChange={({ target }) => handleModelChange('playbackPolicyId', target.value)}
                    >
                      {pbPolicyOptions.map((e, i) => (
                        <MenuItem key={i} value={e.value}>
                          {e.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={1}>
              <Grid item>
                <Typography variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_RENTAL_PERIOD_TITLE' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label={lookUp({ key: 'CONSOLE_AMOUNT' })}
                    type="number"
                    value={model.rentalPeriod ? model.rentalPeriod.amount : null}
                    onChange={(e) => handleRentalPeriodChange('amount', e.target.value)}
                  />
                  <FormHelperText>{lookUp({ key: 'CONSOLE_AMOUNT_HELPERTEXT' })}</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item container alignItems={'center'} spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField
                      select
                      disabled={!rentalPeriodTypes?.length}
                      InputLabelProps={{ shrink: true }}
                      SelectProps={{ displayEmpty: true }}
                      required
                      label={lookUp({ key: 'CONSOLE_UNIT' })}
                      value={model.rentalPeriod?.unit || null}
                      onChange={({ target }) => handleRentalPeriodChange('unit', target.value)}
                    >
                      {rentalPeriodTypes.map((e, i) => (
                        <MenuItem key={i} value={e.value}>
                          {e.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <FormHelperText>
                      {lookUp({ key: 'CONSOLE_MONETIZATION_PERIOD_HELPERTEXT' })}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_AVAILABILITY_TITLE' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_AVAILABLEFROM' })}
                variant="inline"
                inputVariant="outlined"
                size="small"
                autoOk
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={model.availableFrom || null}
                placeholder={lookUp({ key: 'CONSOLE_AVAILABLEFROM_HELPERTEXT' })}
                onChange={(date) => handleModelChange('availableFrom', date)}
                {...(model.availableUntil && { maxDate: model.availableUntil })}
                format="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={12}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_AVAILABLEUNTIL' })}
                variant="inline"
                inputVariant="outlined"
                size="small"
                fullWidth
                autoOk
                InputLabelProps={{ shrink: true }}
                value={model.availableUntil || null}
                placeholder={lookUp({ key: 'CONSOLE_AVAILABLEUNTIL_HELPERTEXT' })}
                onChange={(date) => handleModelChange('availableUntil', date)}
                format="MM/DD/YYYY"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_DETAILS' })}
                multiline
                minRows={4}
                InputLabelProps={{ shrink: true }}
                value={model.details || ''}
                onChange={(e) => handleModelChange('details', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip title={lookUp({ key: 'CONSOLE_ACTIVATE_AFTER_FIRST_USAGE' })}>
                <Switch
                  checked={model.activateAfterFirstUsage}
                  onChange={() =>
                    handleModelChange('activateAfterFirstUsage', !model.activateAfterFirstUsage)
                  }
                  color={'primary'}
                />
              </Tooltip>
              {lookUp({ key: 'CONSOLE_ACTIVATE_AFTER_FIRST_USAGE_HEADING' })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TimePicker
              label={lookUp({ key: 'CONSOLE_GRACE_PERIOD' })}
              type="time"
              InputLabelProps={{ shrink: true }}
              value={model.gracePeriod || ''}
              onChange={(value) => handleModelChange('gracePeriod', value)}
              fullWidth
            />
          </Grid>
        </>
      )
    },
    {
      name: 'consumption',
      icon: <BarChart />,
      content: (
        <ReportTab id={id}></ReportTab>
      )
    }
  ];

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const data = await skuService.get(id);
        setModel(data);
        dispatch(
          navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name })),
        );
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SKU' }),
          }),
        });
        setIsLoading(false);
      }
    };
    if (id) {
      getData();
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_SKU` }) }),
        ),
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  useEffect(() => {
    const getStoreTypes = () => {
      skuService
        .getStoreTypes()
        .then((storeTypes) => {
          setStoreTypeOptions(() =>
            storeTypes.map((type) => {
              let label = type;
              switch (type) {
                case 'Apple':
                  label = lookUp({ key: 'CONSOLE_APPLE_ITUNES' });
                  break;
                case 'Google':
                  label = lookUp({ key: 'CONSOLE_GOOGLE_PLAY_STORE' });
                  break;
                default:
                  label = lookUp({ key: `CONSOLE_${label}` });
              }
              return { label, value: type };
            }),
          );
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_SKU' }),
            }),
          });
        });
    };
    getStoreTypes();
  }, []);

  useEffect(() => {
    const getSkuTypes = async () => {
      try {
        const types = await skuService.getProductTypes();
        setSkuTypeOptions(types.map((t) => ({ label: lookUp({ key: `CONSOLE_${t}` }), value: t })));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SKU' }),
          }),
        });
      }
    };
    getSkuTypes();
  }, []);

  useEffect(() => {
    const getRentalPeriodTypes = () => {
      skuService
        .getPeriodUnits()
        .then((periods) => {
          setRentalPeriodTypes(
            periods.map((period) => ({
              label: lookUp({ key: `CONSOLE_${period}` }),
              value: period,
            })),
          );
        })
        .catch((error) => {});
    };
    getRentalPeriodTypes();
  }, []);

  useEffect(() => {
    const getPbPolicyOptions = async () => {
      setIsPbPolicyOptionsLoading(true);
      try {
        const response = await playbackPolicyService.search();
        let data = response.data;
        if (response.totalCount > 1000) {
          const secondQuery = await playbackPolicyService.search(undefined, {
            page: 0,
            perPage: response.totalCount,
          });
          data = secondQuery.data;
        }
        setPbPolicyOptions(data.map((pbPolicy) => ({ label: pbPolicy.name, value: pbPolicy.id })));
        setIsPbPolicyOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_POLICY' }),
          }),
        });
        setIsPbPolicyOptionsLoading(false);
      }
    };
    getPbPolicyOptions();
  }, []);

  const handleRentalPeriodChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      rentalPeriod: {
        ...prev.rentalPeriod,
        [key]: value,
      },
    }));
  };

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const handleLabelsChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, labels: { ...prev.labels, [key]: value } }));
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    let saveModel;
    if (publish) {
      saveModel = {
        ...model,
        enabled: !model.enabled,
      };
    } else {
      saveModel = { ...model };
    }

    try {
      id ? await skuService.edit(saveModel) : await skuService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      goBackOrStay(navigate, '/monetization/skus');
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const deleteSKU = async () => {
    setIsDeleting(true);
    try {
      await skuService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/monetization/skus'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            id && (
              <ThemedButton
                color={model.enabled ? 'secondary' : 'success'}
                disabled={isPublishing || isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({
                  key: model.enabled ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON',
                })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            id && <DeleteButton onDelete={deleteSKU} loading={isDeleting} disabled={isDeleting} />
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/monetization/skus')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>

          <Tabbing
            // FIXME replace tabs with props left and right in order to render into Toolbar edges('start', 'end')
            tabs={tabs}
            tab={tab}
            addTopMargin={HEADER_MIN_HEIGHT}
            scrollButtons="on"
          />
      </Box>

      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
};

SkuForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(SkuForm);
