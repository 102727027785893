import React from 'react';
import { lookUp } from 'services/stringService';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
  favouriteIcon: {},
  unFavouriteIcon: {
    color: theme.palette?.secondary?.main || yellow[700],
  },
}));

export const FavouriteIcon = ({
  title = lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' }),
  addFavourite,
}) => {
  const classes = useStyles();
  return (
      <Tooltip title={title}>
        <IconButton size="small" onClick={addFavourite} >
          <StarBorderIcon className={classes.favouriteIcon}/>
        </IconButton>
      </Tooltip>
  );
};

export const UnFavouriteIcon = ({
  title = lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' }),
  removeFavourite,
}) => {
  const classes = useStyles();
  return (
      <Tooltip title={title}>
        <IconButton size="small" onClick={removeFavourite}>
          <StarIcon className={classes.unFavouriteIcon} />
        </IconButton>
      </Tooltip>
  );
};
