import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getDevicesEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('CustomerDevices');
  if (!endpoint) throw Error('No Devices endpoint.');
  return endpoint;
}

const getById = (id) => axios.get(`${getDevicesEndpoint()}/${id}`)
  .then((res) => res?.data);

const search = ({ page = 0, perPage = 10, query = '', sortBy, order } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getDevicesEndpoint()}/Search/${perPage}/${page}`, {
        q: query,
        sortBy,
        order
      })
    )
    .then((res) => res.data);

const create = (model) => axios.post(`${getDevicesEndpoint()}`, model)
  .then((res) => res?.data);

const sendMessage = ({ deviceId, body }) => axios.post(`${getDevicesEndpoint()}/sendMessage/${deviceId}`, body)
  .then((res) => res?.data);

const deleteDevice = (customerId, deviceId) => {
  const endpoint = getDevicesEndpoint();
  return axios.delete(`${endpoint.substr(0, endpoint.length-5)}/${customerId}/${deviceId}`);
}

export { getById, search, create, sendMessage, deleteDevice };
export default { getById, search, create, sendMessage, deleteDevice };
