import React, { useCallback, useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import { Link, makeStyles, Typography, LinearProgress, Box } from '@material-ui/core';

import { DataGrid, GridOverlay } from '@material-ui/data-grid';

import { CreatedOn } from 'components';
import { getRewardHistory } from 'services/rewardsService';

import { getById } from 'services/contentService';
import AddRewardModal from './AddRewardModal';

const useStyles = makeStyles((theme) => ({
  mainTable: {
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
  buttonWrapper: {
    marginBottom: theme.spacing(2)
  }
}));

const RewardHistory = ({ id }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const noRows = () => {
    return (
      <GridOverlay>
        <Typography variant="h5">{lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' })}</Typography>
      </GridOverlay>
    );
  };

  const LinearLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const getData = useCallback(()=>{
    setIsLoading(true);
    getRewardHistory(id)
      .then((history) => {
        if (!history) return;
        setData(history.pageContent);
      })
      .finally(() => setIsLoading(false));
  },[id, setIsLoading])

  useEffect(() => {
    getData()
  }, []);

  const columns = [
    {
      field: 'category',
      headerName: lookUp({ key: 'CONSOLE_CATEGORY' }),
      hide: true,
      flex: true,
    },
    {
      field: 'key',
      headerName: lookUp({ key: 'CONSOLE_KEY' }),
      align: 'left',
      flex: true,
    },
    {
      field: 'internalPrice',
      headerName: lookUp({ key: 'CONSOLE_PRICE' }),
      align: 'left',
      flex: true,
    },
    {
      field: 'tokenPrice',
      headerName: lookUp({ key: 'CONSOLE_TOKEN' }),
      align: 'left',
      flex: true,
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'right',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
  ];

  return (
    <div className={classes.mainTable}>
      <Box display="flex" alignItems="center" justifyContent="flex-end" className={classes.buttonWrapper}>
      <AddRewardModal id={id} getData={getData} />
      </Box>
      <DataGrid
        loading={isLoading}
        style={{ border: 0 }}
        columns={columns}
        rows={
          data
            ? data.map((e, i) => {
                return { ...e, id: i };
              })
            : []
        }
        components={{
          NoRowsOverlay: noRows,
          LoadingOverlay: LinearLoadingOverlay,
        }}
        componentsProps={{
          pagination: {
            classes: null,
            labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
            SelectProps: {
              MenuProps: { disableScrollLock: true }
            }
          },
        }}
        autoHeight
        pagination
      />
    </div>
  );
};

export default RewardHistory;
