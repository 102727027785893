import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getManualCatalogEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('ManualCatalog');
  if (!endpoint) throw Error('No Manual Catalog endpoint.');
  return endpoint;
};

const get = (id) => axios.get(`${getManualCatalogEndpoint()}/${id}`).then((r) => r.data);

const search = ({ page = 0, perPage = 10, sortBy = 'name', order = 'asc', q = '', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getManualCatalogEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        q,
        ...rest
      })
    )
    .then((r) => r.data);

const create = (model) => {
  return axios
    .post(getManualCatalogEndpoint(), model)
    .then(response => response.data)
};

const edit = (model) => {
  return axios
    .put(`${getManualCatalogEndpoint()}/${model.id}`, model)
    .then(response => response.data)
};

const remove = (id) => axios.delete(`${getManualCatalogEndpoint()}/${id}`).then((r) => r.data);

export { search, create, get, edit, remove };

export default {
  search,
  create,
  get,
  edit,
  remove
};
