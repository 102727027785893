import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import {
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AlertService from 'services/alertService';
import { getConsoleEnvName } from 'helpers/common';
import { getLanguageCodes } from 'services/jobManagerService';
import { getReward } from 'services/rewardsService';
import parametersService from 'services/parametersService';
import parametersAction from 'store/actions/parametersAction';
import RelatedComboBox from 'components/RelatedComboBox';
import SectionContainer from 'components/SectionContainer';

// NOTE define form groups and fields sizes in MUI 12 grid system
// TODO move into constants file
const FORM_GROUP_WIDTH = 12;
const FORM_FIELD_MEDIUM = 6;
const FORM_FIELD_SMALL = 3;
const FORM_FIELD_SPACING = 1;
const FORM_SECTION_SPACING = 2;
const TEXT_FIELD_SIZE = 'small';

const useStyles = makeStyles((theme) => ({
  idpMargin: {
     marginBottom: theme.spacing(2),
  },
  providerMargin: {
    marginBottom: theme.spacing(2.5),
  },
  customerTypeTooltip: props => {
    const error = props.isErrHlOn && !!props.formErrors?.customerType;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
  usernameTooltip: props => {
    const error = props.isErrHlOn && !!props.formErrors?.username;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
  customerUsernameTooltip: props => {
    const error = props.isErrHlOn && !!props.formErrors?.username;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
  emailAddressTooltip: props => {
    const error = props.isErrHlOn && !!props.formErrors?.emailAddress;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
  idpEmailTooltip: props => {
    const error = props.isErrHlOn && !!props.formErrors?.idpEmail;
    return ({
      backgroundColor: error ? '#b22222' : 'transparent',
      color: '#ffffff',
      width: error ? 'auto' : 0,
      height: error ? 'auto' : 0,
    });
  },
}));

function BasicTab({
  model,
  providers,
  customerOrganizations,
  setCustomerOrganizations,
  customerTypes,
  setSelectedRelation,
  setTargetType,
  triggers,
  handleModelChange,
  handlePropertiesChange,
  formErrors,
  setFormErrors,
  enqueueSnackbar,
}) {
  const id = model.customerId;
  const dispatch = useDispatch();

  const userRole = useSelector((state) => state.auth.user?.role);
  const isErrHighlight = useSelector((state) => state.parameters.isErrHighlight);
  const [languages, setLanguages] = useState([]);
  const [rewardPoints, setRewardPoints] = useState(0);

  const classes = useStyles({ formErrors, isErrHlOn: isErrHighlight.on });

  useEffect(() => {
    if (model.customerId) {
      const setRewards = async () => {
        const points = await getReward(model.customerId);
        setRewardPoints(points.internalPrice);
      };
      setRewards();
    }
  }, [model]);

  useEffect(() => {
    if (Object.keys(languages).length < 1) {
      getLanguageCodes('Translate')
        .then((langs) => {
          setLanguages(Object.keys(langs));
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_LANGUAGES' }),
            }),
          });
        });
    }
  }, []);

  useEffect(() => {
    const getErrorHighlightSetting = async () => {
      try {
        const response = await parametersService.getByKey('Console:HighlightedFields:Check');
        const on = response?.value?.toLowerCase ? response.value.toLowerCase() === 'true' : false;
        dispatch(parametersAction.setErrorHighlight({ on, paramChecked: true }));
      } catch (error) {
        dispatch(parametersAction.setErrorHighlight({ ...isErrHighlight, paramChecked: true }));
      }
    };

    if (!isErrHighlight.paramChecked) getErrorHighlightSetting();
  }, [isErrHighlight.paramChecked]);

  return (
    <SectionContainer flex={1}>
      <Grid container direction="column" spacing={FORM_SECTION_SPACING}>
        {model.customerId && (
          <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_CUSTOMER_ID' })}
              fullWidth
              value={model.customerId || ''}
              inputProps={{ readOnly: true }}
              disabled
            />
          </Grid>
        )}
        <Grid item md={FORM_FIELD_SMALL} sm={12}>
          <Tooltip
            title={formErrors.customerType}
            classes={{ tooltip: classes.customerTypeTooltip }}
          >
            <TextField
              select
              label={lookUp({ key: 'CONSOLE_CUSTOMERTYPE' })}
              fullWidth
              value={model.customerType || ''}
              onChange={(e) => {
                handleModelChange('customerType', e.target.value);
                if (formErrors.customerType) setFormErrors({ ...formErrors, customerType: null });
              }}
              required
              error={!!formErrors.customerType}
            >
              {customerTypes?.sort().map((e) => (
                <MenuItem key={e} value={e}>
                  {lookUp({ key: `CONSOLE_${e}` })}
                </MenuItem>
              ))}
            </TextField>
          </Tooltip>
        </Grid>

        {!(getConsoleEnvName() === 'Nexius' && model.customerType === 'Organization') &&
          (userRole === 'SysAdmin' || userRole === 'Admin') && (
            <>
              <Grid item sm={12}>
                <Typography variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_ORGANIZATIONS' })}
                </Typography>
              </Grid>
              <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
                <RelatedComboBox
                  sourceType="Customer"
                  targetType="Customer"
                  relation="Organization"
                  id={id}
                  source={id ? null : customerOrganizations}
                  overrideHandleChange={
                    id ? null : (newValues) => setCustomerOrganizations(newValues)
                  }
                  onChange={(newValues) => {
                    setCustomerOrganizations(newValues);
                  }}
                  onFocus={() => {
                    setSelectedRelation('Organization');
                    setTargetType('Customer');
                  }}
                  trigger={triggers?.['Organization']}
                  size="small"
                  formErrors={formErrors}
                  formErrorKey="organizations"
                  setFormErrors={setFormErrors}
                  required={true}
                />
              </Grid>
            </>
          )}

        {getConsoleEnvName() !== 'Nexius' && (
          <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_REWARDPOINTS' })}
              type="number"
              value={rewardPoints}
              inputProps={{ readOnly: true }}
            />
          </Grid>
        )}

        <Grid item sm={12}>
          <Typography variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_INFO_HEADING' })}
          </Typography>
        </Grid>

        <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
          <Tooltip title={formErrors.username} classes={{ tooltip: classes.usernameTooltip }}>
            <TextField
              label={lookUp({ key: 'CONSOLE_NAME' })}
              fullWidth
              value={model.username || ''}
              onChange={(e) => {
                if (formErrors.username && e.target.value?.length > 0) {
                  setFormErrors({ ...formErrors, username: null });
                } else if (!e.target.value.length) {
                  setFormErrors({
                    ...formErrors,
                    username: lookUp({ key: 'CONSOLE_MISSING_FIELD_VALUE' }),
                  });
                }
                handleModelChange('username', e.target.value);
              }}
              error={isErrHighlight.on && !!formErrors.username}
              required
            />
          </Tooltip>
        </Grid>

        <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
          <Tooltip title={formErrors.customerUsername} classes={{ tooltip: classes.customerUsernameTooltip }}>
            <TextField
              label={lookUp({ key: 'CONSOLE_CUSTOMER_USERNAME' })}
              fullWidth
              value={model.customerUsername || ''}
              onChange={(e) => {
                if (formErrors.customerUsername && e.target.value?.length > 0) {
                  setFormErrors({ ...formErrors, customerUsername: null });
                } else if (!e.target.value.length) {
                  setFormErrors({
                    ...formErrors,
                    customerUsername: lookUp({ key: 'CONSOLE_MISSING_FIELD_VALUE' }),
                  });
                }
                handleModelChange('customerUsername', e.target.value);
              }}
              error={isErrHighlight.on && !!formErrors.customerUsername}
              required
            />
          </Tooltip>
        </Grid>

        {!(getConsoleEnvName() === 'Nexius' && model.customerType === 'Organization') &&
          (userRole === 'SysAdmin' || userRole === 'Admin') && (
            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <Tooltip
                title={formErrors.emailAddress}
                classes={{ tooltip: classes.emailAddressTooltip }}
              >
                <TextField
                  label={lookUp({ key: 'CONSOLE_EMAIL' })}
                  type="email"
                  value={model.emailAddress}
                  onChange={(event) => {
                    if (formErrors.emailAddress && event.target.value?.length > 0) {
                      setFormErrors({ ...formErrors, emailAddress: null });
                    } else if (!event.target.value.length) {
                      setFormErrors({
                        ...formErrors,
                        emailAddress: lookUp({ key: 'CONSOLE_MISSING_FIELD_VALUE' }),
                      });
                    }
                    handleModelChange('emailAddress', event.target.value);
                    if (getConsoleEnvName() === 'Nexius') {
                      handlePropertiesChange({ [`Custom:SocialIds:Password`]: event.target.value });
                    }
                  }}
                  error={isErrHighlight.on && !!formErrors.emailAddress}
                  fullWidth
                  required
                />
              </Tooltip>
            </Grid>
          )}

        <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
          <TextField
            select
            value={model['defaultLocale'] || ''}
            onChange={(e) => handleModelChange('defaultLocale', e.target.value)}
            label={lookUp({ key: 'CONSOLE_DEFAULT_LANGUAGE' })}
            fullWidth
          >
            {languages.map((e) => (
              <MenuItem value={e} key={e}>
                {lookUp({ key: `CONSOLE_LANG_${e}` })}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
          <TextField
            label={lookUp({ key: 'CONSOLE_SHORTINFO' })}
            size="small"
            fullWidth
            multiline
            minRows={4}
            value={model.properties?.['Content:Info'] || ''}
            onChange={(e) => handlePropertiesChange({ ['Content:Info']: e.target.value })}
          />
        </Grid>

        {!(getConsoleEnvName() === 'Nexius' && !id) && (
          <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
              size="small"
              fullWidth
              multiline
              minRows={4}
              value={model.properties?.['Content:Description'] || ''}
              onChange={(e) => handlePropertiesChange({ ['Content:Description']: e.target.value })}
            />
          </Grid>
        )}

        <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
          {!(getConsoleEnvName() === 'Nexius' && userRole === 'Admin') && (
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_PROVIDERS_HEADING' })}
              </Typography>
            </Grid>
          )}
          {providers
            ?.map?.((provider, index) => {
              if (provider === 'Password') {
                if (getConsoleEnvName() === 'Nexius' && model.customerType === 'Organization')
                  return null;
                return (
                  <Grid item sm={12} className={classes.providerMargin}>
                    <Tooltip
                      title={formErrors.idpEmail}
                      classes={{ tooltip: classes.idpEmailTooltip }}
                    >
                      <TextField
                        key={`${provider}_${index}`}
                        type="email"
                        value={model.properties?.[`Custom:SocialIds:${provider}`]}
                        onChange={(event) => {
                          if (formErrors.idpEmail && event.target.value?.length > 0) {
                            setFormErrors({ ...formErrors, idpEmail: null });
                          } else if (!event.target.value.length) {
                            setFormErrors({
                              ...formErrors,
                              idpEmail: lookUp({ key: 'CONSOLE_MISSING_FIELD_VALUE' }),
                            });
                          }
                          handlePropertiesChange({
                            [`Custom:SocialIds:${provider}`]: event.target.value,
                          });
                        }}
                        label={lookUp({ key: 'CONSOLE_PASSWORDPROVIDER' })}
                        InputLabelProps={{ shrink: true }}
                        error={isErrHighlight.on && !!formErrors.idpEmail}
                        fullWidth
                        required
                        style={{
                          display:
                            getConsoleEnvName() === 'Nexius' && model.customerType === 'Admin'
                              ? 'none'
                              : 'inline-block',
                        }}
                      />
                    </Tooltip>
                  </Grid>
                );
              } else if (userRole === 'SysAdmin') {
                return (
                  <Grid item sm={12} className={classes.providerMargin}>
                    <TextField
                      key={`${provider}_${index}`}
                      value={model.properties?.[`Custom:SocialIds:${provider}`] || ''}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      onChange={(event) =>
                        handlePropertiesChange({
                          [`Custom:SocialIds:${provider}`]: event.target.value,
                        })
                      }
                      label={lookUp({ key: `CONSOLE_CUSTOM_SOCIALIDS_${provider}` })}
                    />
                  </Grid>
                );
              } else {
                return null;
              }
            })
            .filter((field) => field !== null)}
        </Grid>

        {model.customerType === 'Event' && (
          <>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_EVENT_HEADING' })}
              </Typography>
            </Grid>

            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_EVENTLOCATION' })}
                fullWidth
                value={model.properties?.['Event:Location'] || ''}
                onChange={(e) => handlePropertiesChange({ ['Event:Location']: e.target.value })}
              />
            </Grid>

            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_EVENTDATE' })}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                fullWidth
                inputProps={{ step: 60 }}
                value={model.properties?.['Event:Date']?.split('+')[0] || ''}
                onChange={(e) => handlePropertiesChange({ ['Event:Date']: e.target.value })}
              />
            </Grid>
          </>
        )}
        {id && model.customerType === 'Organization' && (
          <>
            <Grid item sm={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_MEMBERS' })}
              </Typography>
            </Grid>

            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <RelatedComboBox
                sourceType="Customer"
                targetType="Customer"
                relation="Organization"
                lookup={true}
                id={id}
                label={''}
                onFocus={() => {
                  setTargetType('Customer');
                  setSelectedRelation('Members');
                }}
                trigger={triggers?.['Members']}
              />
            </Grid>
          </>
        )}
      </Grid>
    </SectionContainer>
  );
};

export default withSnackbar(BasicTab);
