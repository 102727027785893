import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getCollectionsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Collections');
  if (!endpoint) throw Error('No Collections endpoint.');
  return endpoint;
};

const getRatings = (id, page, limit) =>
  axios
    .get(`${getCollectionsEndpoint()}/Collections/Content/Rating?targetId=${id}`, {
      params: { page, limit },
    })
    .then((r) => {
      return r?.data;
    });

const getCollection = (id, collection, type ) => axios.get(`${getCollectionsEndpoint()}/${id}/Lists/${collection}/${type}`)
  .then((r) => {
    return r?.data
  });

  const deleteRating = (customerId, contentId) => axios.delete(`${getCollectionsEndpoint()}/${customerId}/Rating/Content/${contentId}`)
  .then((r) => r?.pageContent);

  const getDiscussionCollection = (targetType, targetId, providerId='') => axios.get(`${getCollectionsEndpoint()}/Discussion/List/${targetType}/${targetId}/${providerId}`)
    .then((r) => {
      return r?.data
    });

  const moderateDiscussion = (entryId, customerId) => axios.delete(`${getCollectionsEndpoint()}/Discussion/${customerId}/${entryId}`)
  .then((r) => r?.pageContent);

export { getCollection, getDiscussionCollection, deleteRating, moderateDiscussion, getRatings };

export default {
  getCollection,
  getDiscussionCollection,
  deleteRating,
  moderateDiscussion,
  getRatings
};
