import React from 'react';
import { lookUp } from 'services/stringService';
import { dateFormatter } from 'helpers/time';

const CreatedOn = ({ date = '', editor = null }) => {
  
  
  return (
    <div style={{display: "inline-block"}}>
      {date ? dateFormatter(date) : 'Unset'}
      {editor &&
        <div style={
          { 
            display: "inline-block", 
            float: "right",
            width: "20px",
            height: "20px",
            margin: "16px 0 0 5px",
            padding: 0,
            overflow: "hidden",
          }}>
          <input
            style={{ float: "right", border: 0, margin: 0, padding: 0}}
            type="datetime-local"
            value={date ? new Date(date).toISOString().split('.')[0] : ''}
            {...editor}
          />
        </div>
      }
    </div>
  )
}

export default CreatedOn;