import { store } from 'store/configureStore';
import axios from 'axios';
import { getConsoleApiEndpoint } from 'services/apiEndpoints';
import { makeApiWithQuery } from 'helpers/common';
import { substituteVariables } from 'helpers/strings';
import { setLexicons, setProjectLanguages } from 'store/actions/localizationAction';

const brandId = window.__RUNTIME_CONFIG__.BRAND_ID;

const getStringsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Strings');
  if (!endpoint) throw Error('No Strings endpoint.');
  return endpoint;
};

const getParameterEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Parameter');
  if (!endpoint) throw Error('No Parameter endpoint.');
  return endpoint;
};

const getLocale = () => localStorage.getItem('locale') || 
  store.getState().lexicons?.selected || 
  store.getState().user?.defaultLocale || 
  'en-US';

const getProjectLanguages = () => 
  axios.get(`${getParameterEndpoint()}/lms%3Aapp%3Alanguage`, { headers: { brandId } })
  .then((req) => {
    const langs = req?.data?.value ? JSON.parse(req.data.value) : [{ label: 'English (US)', locale: 'en-US' }];
    const keys = langs.map(e => ({ label: e.value, value: e.locale }));
    store.dispatch(setProjectLanguages(keys));
    return keys;
  });

  const searchQuery = (search, pageSize = 25, page = 0) => {
    search.delete('page');
    search.delete('pageSize');
    return axios
      .get(`${getStringsEndpoint()}/Search/${pageSize}/${page}?${search}`)
      .then(res => res.data);
    }

const search = ({ page = 0, perPage = 10, q = '', order = 'desc', sortBy = '', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getStringsEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        q,
        ...rest,
      })
    )
    .then((r) => {
      const dataGivenId = r.data.data.map((e) => ({ ...e, id: `${e.name}|${e.locale}` }));
      r.data.data = dataGivenId;
      return r?.data;
    });

const getAll = () =>
  axios
    .get(`${getStringsEndpoint()}/Search/1/0`)
    .then((res) => {
      const totalCount = res.data?.totalCount;
      return axios.get(`${getStringsEndpoint()}/Search/${totalCount}/0`);
    })
    .then((res) => res.data.data);

const resultsToReduxObject = (results) => {
  if (!results?.length) return {};
  return (
    results &&
    results.reduce((obj, result) => {
      obj[result.locale] = { ...obj[result.locale], [result.name]: result.value };
      return obj;
    }, {})
  );
};

let isRefreshing = false;

const refreshLexicons = async () => {
  if (isRefreshing) return;
  isRefreshing = true;
  const now = new Date().getTime();
  const in30mins = now + 30 * 60 * 1000;
  const fromBE = resultsToReduxObject(await getAll());
  localStorage.setItem('lexicons', JSON.stringify({ ...fromBE }));
  localStorage.setItem('lexiconsExpiry', in30mins);
  localStorage.setItem('locale', getLocale() || 'en-US');
  await store.dispatch(setLexicons({...fromBE, expiry: in30mins, selected: localStorage.getItem('locale') }));
  isRefreshing = false;
  return;
};

const formatKey = key => key?.replace(/[^a-z0-9]/gi, '_').toUpperCase();

const lookUp = ({ key, defaultString, ...variables }) => {
  if (!key) return '';

  const { lexicons } = store.getState();
  const now = new Date().getTime();
  if (lexicons.expiry < now) refreshLexicons();
  const formattedKey = formatKey(key);
  const value = lexicons?.[getLocale()]?.[formattedKey];
  const varbsLen = Object.keys(variables)?.length;

  if (!value) {
    const keyVarbsStr = `${formattedKey}${varbsLen ? JSON.stringify(variables) : '' }`;
    return defaultString || keyVarbsStr;
  }

  return varbsLen === 0
    ? value || defaultString || formattedKey || ''
    : substituteVariables(value, variables) ||
      defaultString ||
      formattedKey + JSON.stringify(variables) ||
      '';
};

const create = async (model) => axios.post(getStringsEndpoint(), model).then((r) => r?.data);

const get = (locale, name, flavor = null) => {
  return flavor
    ? axios.get(`${getStringsEndpoint()}/${locale}/${name}/${flavor}`).then((resp) => resp?.data)
    : axios.get(`${getStringsEndpoint()}/${locale}/${name}`).then((resp) => resp?.data);
};

const edit = async (model) =>
  axios.put(`${getStringsEndpoint()}`, model).then((response) => response?.data);

const remove = (locale, name, flavor = null) => {
  return flavor
    ? axios.delete(`${getStringsEndpoint()}/${locale}/${name}/${flavor}`).then((resp) => resp?.data)
    : axios.delete(`${getStringsEndpoint()}/${locale}/${name}`).then((resp) => resp?.data);
};

const downloadXLS = (name) => {
  return axios({
    url: `${getStringsEndpoint()}/Export`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: { Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
  }).then((file) => {
    const fileBlob = new Blob([file.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${name}`);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  });
};

const uploadXLS = (formData) => {
  return axios
    .post(`${getStringsEndpoint()}/Import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((data) => data);
};

export { 
  search, 
  create, 
  get, 
  getAll, 
  getProjectLanguages, 
  getLocale, 
  edit, 
  uploadXLS,
  downloadXLS,
  remove, 
  lookUp, 
  resultsToReduxObject,
 };

export default {
  search,
  searchQuery,
  create,
  get,
  edit,
  remove,
  downloadXLS,
  uploadXLS,
  getProjectLanguages, 
};
