import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { Close, AddPhotoAlternate } from '@material-ui/icons';
import {
  styled,
  Typography,
  Tooltip,
  Button,
  Grid,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slider,
  Input,
  IconButton,
  Tab,
} from '@material-ui/core';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  enrichModal: {
    width: '500px',
    height: '520px',
  },
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  modalButton: {
    margin: 0,
    color: '#ffffff',
    height: theme.spacing(6),
    width: '100%',
  },
  modalTooltip: {
    height: theme.spacing(6),
    width: '100%',
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
  sliderGrid: {
    marginTop: theme.spacing(3.5),
  },
  slider: {
    marginTop: 0,
    paddingTop: 0,
  },
  sliderInput: {
    width: theme.spacing(6),
  },
  narrowTopMargin: {
    marginTop: theme.spacing(-4),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
}));

function Modal(props) {
  const { modalType, isOpen, setOpen, imageData } = props;

  const hRef = imageData?.assets?.find((e) => {
    return e.type === 'Image' && e.subType === 'Original';
  })?.objectUrl;
  const classes = useStyles();

  const [rekogSliders, setRekogSliders] = useState({
    LabelDetection: 95,
    ContentModeration: 95,
    FaceDetection: 95,
    TextDetection: 90,
  });

  const [rekogSwitch, setRekogSwitch] = useState({
    RekognitionCelebrityJob: false,
    RekognitionFaceDetectionJob: false,
    RekognitionTextDetectionJob: false,
    RekognitionContentModerationJob: false,
    RekognitionLabelDetectionJob: false,
  });

  const SaveButton = styled(Button)({
    color: '#ffffff',
  });

  const handleRekogSliders = (sliderType, newValue) => {
    setRekogSliders((sliders) => ({ ...sliders, [sliderType]: parseInt(newValue) }));
  };

  const handleRekognitionJobs = (jobType, isTurnedOn) => {
    setRekogSwitch((rekogSwitch) => ({ ...rekogSwitch, [jobType]: isTurnedOn }));
  };

  const processRekognition = () => {
    const rekogJobs = Object.keys(rekogSwitch);
    if (rekogJobs.length < 1) return;
    const rekogJobsPromises = rekogJobs.reduce((apiCalls, rekogJob) => {
      if (!rekogSwitch[rekogJob]) return apiCalls;
      const reqBody = {
        InputFileLocation: hRef,
        ReferenceObjectId: imageData.id,
        Name: `${rekogJob}_${Date.now()}`,
      };
      switch (rekogJob) {
        case 'RekognitionContentModerationJob':
          reqBody.minConfidence = rekogSliders.ContentModeration;
          break;
        case 'RekognitionLabelDetectionJob':
          reqBody.minConfidence = rekogSliders.LabelDetection;
          break;
        case 'RekognitionFaceDetectionJob':
          reqBody.minConfidence = rekogSliders.FaceDetection;
          break;
        case 'RekognitionTextDetectionJob':
          reqBody.minConfidence = rekogSliders.TextDetection;
          break;
        default:
          break;
      }
      apiCalls.push(jobManagerService.create(rekogJob, reqBody));
      return apiCalls;
    }, []);
    Promise.all(rekogJobsPromises)
      .then((jobResponses) => {
        jobResponses.forEach((jobResponse) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} -> ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`
          });
        });
      })
      .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
      })
      .finally(() => setOpen(false));
  };

  const transModal = {};

  transModal.enrichment = (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_INDEXING_TEXT' })}>
        <Tab
          wrapped
          textColor="#ffffff"
          label={lookUp({ key: 'CONSOLE_INDEXING' })}
          icon={<AddPhotoAlternate />}
          onClick={(event) => setOpen(true)}
        />
      </Tooltip>
      {isOpen ? (
        <Dialog
          open={isOpen}
          maxWidth="xl"
          classes={{ paper: classes.enrichModal }}
          onClose={(event) => setOpen(false)}
        >
          <div className={classes.xButtonWrapper}>
            <Typography className={classes.modalTitle}>Content Indexing</Typography>
            <IconButton aria-label="close" onClick={(event) => setOpen(false)}>
              <Close />
            </IconButton>
          </div>
          <span className={classes.modalSubtitle}>
            Analyse and detect object in the current media file
          </span>
          <DialogContent>
            <Grid container spacing={2} direction="row" justifyContent="flex-start">
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rekogSwitch.RekognitionCelebrityJob}
                        onChange={(event) => {
                          handleRekognitionJobs('RekognitionCelebrityJob', event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={lookUp({ key: 'CONSOLE_REKOGNITIONCELEBRITYJOB' })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rekogSwitch.RekognitionTextDetectionJob}
                        onChange={(event) => {
                          handleRekognitionJobs(
                            'RekognitionTextDetectionJob',
                            event.target.checked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={lookUp({ key: 'CONSOLE_REKOGNITIONTEXTDETECTIONJOB' })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7} className={classes.sliderGrid}>
                <Slider
                  className={classes.slider}
                  defaultValue={95}
                  value={rekogSliders.TextDetection}
                  step={1}
                  marks
                  track="inverted"
                  onChange={(event, value) => handleRekogSliders('TextDetection', value)}
                  disabled={!rekogSwitch.RekognitionTextDetectionJob}
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  className={classes.sliderInput}
                  value={rekogSliders.TextDetection}
                  margin="dense"
                  onChange={(event) => handleRekogSliders('TextDetection', event.target.value)}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                  }}
                  disabled={!rekogSwitch.RekognitionTextDetectionJob}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL'})}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.narrowTopMargin}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rekogSwitch.RekognitionFaceDetectionJob}
                        onChange={(event) => {
                          handleRekognitionJobs(
                            'RekognitionFaceDetectionJob',
                            event.target.checked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={lookUp({ key: 'CONSOLE_REKOGNITIONFACEDETECTIONJOB' })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7} className={classes.sliderGrid}>
                <Slider
                  className={classes.slider}
                  defaultValue={95}
                  value={rekogSliders.FaceDetection}
                  step={1}
                  marks
                  track="inverted"
                  onChange={(event, value) => handleRekogSliders('FaceDetection', value)}
                  disabled={!rekogSwitch.RekognitionFaceDetectionJob}
                />
              </Grid>

              <Grid item xs={2}>
                <Input
                  className={classes.sliderInput}
                  value={rekogSliders.FaceDetection}
                  margin="dense"
                  onChange={(event) => handleRekogSliders('FaceDetection', event.target.value)}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                  }}
                  disabled={!rekogSwitch.RekognitionFaceDetectionJob}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL'})}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.narrowTopMargin}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rekogSwitch.RekognitionContentModerationJob}
                        onChange={(event) => {
                          handleRekognitionJobs(
                            'RekognitionContentModerationJob',
                            event.target.checked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={lookUp({ key: 'CONSOLE_REKOGNITIONCONTENTMODERATIONJOB' })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7} className={classes.sliderGrid}>
                <Slider
                  className={classes.slider}
                  defaultValue={95}
                  value={rekogSliders.ContentModeration}
                  step={1}
                  marks
                  track="inverted"
                  onChange={(event, value) => handleRekogSliders('ContentModeration', value)}
                  disabled={!rekogSwitch.RekognitionContentModerationJob}
                />
              </Grid>

              <Grid item xs={2}>
                <Input
                  className={classes.sliderInput}
                  value={rekogSliders.ContentModeration}
                  margin="dense"
                  onChange={(event) => handleRekogSliders('ContentModeration', event.target.value)}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                  }}
                  disabled={!rekogSwitch.RekognitionContentModerationJob}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL'})}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.narrowTopMargin}>
                <FormControl className={classes.formControl}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={rekogSwitch.RekognitionLabelDetectionJob}
                        onChange={(event) => {
                          handleRekognitionJobs(
                            'RekognitionLabelDetectionJob',
                            event.target.checked
                          );
                        }}
                        color="primary"
                      />
                    }
                    label={lookUp({ key: 'CONSOLE_REKOGNITIONLABELDETECTIONJOB' })}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7} className={classes.sliderGrid}>
                <Slider
                  className={classes.slider}
                  defaultValue={50}
                  value={rekogSliders.LabelDetection}
                  step={1}
                  marks
                  track="inverted"
                  onChange={(event, value) => handleRekogSliders('LabelDetection', value)}
                  disabled={!rekogSwitch.RekognitionLabelDetectionJob}
                />
              </Grid>

              <Grid item xs={2}>
                <Input
                  className={classes.sliderInput}
                  value={rekogSliders.LabelDetection}
                  margin="dense"
                  onChange={(event) => handleRekogSliders('LabelDetection', event.target.value)}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 100,
                    type: 'number',
                  }}
                  disabled={!rekogSwitch.RekognitionLabelDetectionJob}
                />
              </Grid>

              <Grid item xs={3}>
                <Typography variant="caption">{lookUp({ key: 'CONSOLE_CONFIDENCE_LEVEL'})}</Typography>
              </Grid>

              <Grid item xs={12} className={classes.dialogActions}>
                <SaveButton
                  
                  onClick={() => {
                    processRekognition();
                    //setOpen(false);
                  }}
                  color="primary"
                >
                  {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
                </SaveButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : null}
    </>
  );

  return <> {transModal[modalType]} </>;
}

export default Modal;
