import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';

const getResortsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Resorts');
  if (!endpoint) throw Error('No Resorts endpoint.');
  return endpoint;
};

const search = ({ page = 0, perPage = 10, sortBy = 'name', order = 'asc', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getResortsEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        ...rest
      })
    )
    .then((res) => res?.data);

const getById = (id) => axios.get(`${getResortsEndpoint()}/${id}`)
  .then((res) => res?.data);

const create = (body) => axios.post(`${getResortsEndpoint()}`, body)
  .then((res) => res?.data);

const edit = (body) => axios.put(`${getResortsEndpoint()}/${body.id}`, body)
  .then((res) => res?.data);

const destroy = ({ id }) => axios.delete(`${getResortsEndpoint()}/${id}`);

export { getById, search, create, edit, destroy };
export default { getById, search, create, edit, destroy };
