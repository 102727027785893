const SET_CONSUMPTION_PLAYOUT_PLAYBACKS = 'SET_CONSUMPTION_PLAYOUT_PLAYBACKS';
const SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS =
  'SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS';

const SET_CONSUMPTION_TOP_SONGS = 'SET_CONSUMPTION_TOP_SONGS';
const SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS = 'SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS';

const SET_CONSUMPTION_TOP_ARTISTS = 'SET_CONSUMPTION_TOP_ARTISTS';
const SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS = 'SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS';

const SET_CONSUMPTION_TOP_PLAYLISTS = 'SET_CONSUMPTION_TOP_PLAYLISTS';
const SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS = 'SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS';

const SET_USER_LOGS = 'SET_USER_LOGS';
const SET_USER_LOGS_LOADING_STATUS = 'SET_USER_LOGS_LOADING_STATUS';

const setConsumptionPlayoutPlaybacks = (consumption) => ({
  type: SET_CONSUMPTION_PLAYOUT_PLAYBACKS,
  consumption
});

const setConsumptionPlayoutPlaybacksLoadingStatus = (status) => ({
  type: SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS,
  status
});

const setConsumptionTopSongs = (songs) => ({
  type: SET_CONSUMPTION_TOP_SONGS,
  songs
});

const setConsumptionTopSongsLoadingStatus = (status) => ({
  type: SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS,
  status
});

const setConsumptionTopArtists = (artists) => ({
  type: SET_CONSUMPTION_TOP_ARTISTS,
  artists
});

const setConsumptionTopArtistsLoadingStatus = (status) => ({
  type: SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS,
  status
});

const setConsumptionTopPlaylists = (playlists) => ({
  type: SET_CONSUMPTION_TOP_PLAYLISTS,
  playlists
});

const setConsumptionTopPlaylistsLoadingStatus = (status) => ({
  type: SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS,
  status
});

const setUserLogs = (userLogs) => ({
  type: SET_USER_LOGS,
  userLogs
});

const setUserLogsLoadingStatus = (status) => ({
  type: SET_USER_LOGS_LOADING_STATUS,
  status
});

export {
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS,
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_SONGS,
  SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_ARTISTS,
  SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_PLAYLISTS,
  SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS,
  SET_USER_LOGS,
  SET_USER_LOGS_LOADING_STATUS,
  setConsumptionPlayoutPlaybacks,
  setConsumptionPlayoutPlaybacksLoadingStatus,
  setConsumptionTopSongs,
  setConsumptionTopSongsLoadingStatus,
  setConsumptionTopArtists,
  setConsumptionTopArtistsLoadingStatus,
  setConsumptionTopPlaylists,
  setConsumptionTopPlaylistsLoadingStatus,
  setUserLogs,
  setUserLogsLoadingStatus
};

export default {
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS,
  SET_CONSUMPTION_PLAYOUT_PLAYBACKS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_SONGS,
  SET_CONSUMPTION_TOP_SONGS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_ARTISTS,
  SET_CONSUMPTION_TOP_ARTISTS_LOADING_STATUS,
  SET_CONSUMPTION_TOP_PLAYLISTS,
  SET_CONSUMPTION_TOP_PLAYLISTS_LOADING_STATUS,
  SET_USER_LOGS,
  SET_USER_LOGS_LOADING_STATUS,
  setConsumptionPlayoutPlaybacks,
  setConsumptionPlayoutPlaybacksLoadingStatus,
  setConsumptionTopSongs,
  setConsumptionTopSongsLoadingStatus,
  setConsumptionTopArtists,
  setConsumptionTopArtistsLoadingStatus,
  setConsumptionTopPlaylists,
  setConsumptionTopPlaylistsLoadingStatus,
  setUserLogs,
  setUserLogsLoadingStatus
};
