import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { green, red } from '@material-ui/core/colors';
import StorefrontIcon from '@material-ui/icons/Storefront';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CreateIcon from '@material-ui/icons/Create';
import { Button, Grid, makeStyles, TextField, MenuItem } from '@material-ui/core';
import { Tooltip, IconButton, Fab, Switch } from '@material-ui/core';
import { ClearAll, Search } from '@material-ui/icons';
import VouchersTable from 'components/VouchersTable';
import { EditorInfo, Loader, Tabbing, FormFooter } from 'components';
import vouchersService from 'services/vouchersService';
import playbackPolicyService from 'services/playbackPolicyService';
import moment from 'moment';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  addVoucher: {
    marginTop: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2, 0),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
}));

function CampaignsForm(props) {
  const { enqueueSnackbar } = props;

  const { id, tab } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const activeUntilDefault = moment().add(10, 'day').format('YYYY-MM-DD');
  const activeFromDefault = moment().format('YYYY-MM-DD');

  const [model, setModel] = useState({
    name: '',
    productId: '',
    activeFrom: activeFromDefault,
    activeUntil: activeUntilDefault,
    usageDays: 10,
    prefix: '',
    type: 'Subscription',
    basePriceUSD: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPbPolicyOptionsLoading, setIsPbPolicyOptionsLoading] = useState(false);
  const [pbPolicyOptions, setPbPolicyOptions] = useState([]);

  useEffect(() => {
    if (id) {
      getVoucher().then(() => setIsLoading(false));
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_CAMPAIGN` }) }),
        ),
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  useEffect(() => {
    const getStoreTypes = async () => {
      setTypeOptions([
        { label: lookUp({ key: 'CONSOLE_SUBSCRIPTION' }), value: 'Subscription' },
        { label: lookUp({ key: 'CONSOLE_RENTAL' }), value: 'Rental' },
      ]);
    };
    getStoreTypes();
  }, []);

  useEffect(() => {
    const getPbPolicyOptions = async () => {
      setIsPbPolicyOptionsLoading(true);
      try {
        const response = await playbackPolicyService.search();
        let data = response.data;
        if (response.totalCount > 1000) {
          const secondQuery = await playbackPolicyService.search(undefined, {
            page: 0,
            perPage: response.totalCount,
          });
          data = secondQuery.data;
        }
        setPbPolicyOptions(data.map((pbPolicy) => ({ label: pbPolicy.name, value: pbPolicy.id })));
        setIsPbPolicyOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_POLICY_OPTIONS' }),
          }),
        });
        setIsPbPolicyOptionsLoading(false);
      }
    };
    getPbPolicyOptions();
  }, []);

  const [searchOptions, setSearchOptions] = useState({
    redeemed: false,
    redeemedDateAfter: '',
    redeemedDateBefore: '',
  });

  const clearSearchOptions = () => {
    setSearchOptions({
      redeemed: '',
      redeemedDate: '',
    });
  };

  const handleSearchOptionsChange = (key, value) =>
    setSearchOptions((prev) => ({
      ...prev,
      [key]: value,
    }));

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleModelDateChange = (key, value) => {
    value = moment(value).format('YYYY-MM-DD') + 'T00:00:00';
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const saveData = async () => {
    setIsSaving(true);
    try {
      id ? await vouchersService.edit(id, model) : await vouchersService.create(model);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setIsSaving(false);
      let successMsg = id ? 'Campaign successfully changed' : 'Campaign successfully added';
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: successMsg,
      });
      setTimeout(() => navigate('/monetization/campaigns'), 50);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        valueToLabel: {
          activefrom: 'Active From',
          activeuntil: 'Active Until',
          basepriceusd: '$ Price',
          name: 'Name *',
          playbackpolicyid: 'Related Playback Policy *',
          prefix: 'Prefix',
          productid: 'Code *',
          type: 'Type',
          usagedays: 'Usage Days',
        },
      });
    } finally {
      setIsSaving(false);
    }
  };

  let voucher = {
    count: vouchers,
  };

  const addVoucher = async () => {
    try {
      await vouchersService.addVouchers(id, voucher);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_add_voucher_success' }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_add_voucher_ERROR' }),
      });
    }
  };

  const getVoucher = async () => {
    try {
      const data = await vouchersService.getById(id);
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(data);
      dispatch(
        navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name })),
      );
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_VOUCHER' }),
        }),
      });
      setIsLoading(false);
    }
  };

  const deleteVoucher = async () => {
    setIsDeleting(true);
    try {
      await vouchersService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/monetization/campaigns'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_VOUCHER' }),
        }),
      });
      setIsDeleting(false);
    }
  };

  const theadElements = [
    {
      name: 'code',
      label: lookUp({ key: 'CONSOLE_VOUCHER_CODE' }),
      align: 'left',
    },
    {
      name: 'redeemed',
      label: lookUp({ key: 'CONSOLE_REDEEMED' }),
      align: 'right',
    },
  ];

  const tbodyElements = [
    {
      name: 'code',
      align: 'left',
      render: (item) => <p>{item.code}</p>,
    },
    {
      name: 'redeemed',
      align: 'right',
      render: (item) => (item.redeemed ? <CheckOutlinedIcon /> : <CloseOutlinedIcon />),
    },
  ];

  const customToolbar = (handleSearch, isSearchActive, clearFilters, isClearFiltersActive) => (
    <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
      <Grid item xs={9} className={classes.padding}>
        <Grid container alignItems={'center'} justifyContent={'flex-start'} spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid item className={classes.textCenter}>
              {lookUp({ key: 'CONSOLE_REDEEMED' })}
              <Tooltip title={'redeemed'}>
                <Switch
                  checked={searchOptions.redeemed}
                  onChange={() => handleSearchOptionsChange('redeemed', !searchOptions.redeemed)}
                  color={'primary'}
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              style={{ alignItems: 'center' }}
              variant="inline"
              label={lookUp({ key: 'CONSOLE_REDEEMEDDATEAFTER' })}
              value={searchOptions.redeemedDateAfter || null}
              onChange={(date) => handleSearchOptionsChange('redeemedDateAfter', date)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              style={{ alignItems: 'center' }}
              variant="inline"
              label={lookUp({ key: 'CONSOLE_REDEEMEDDATEBEFORE' })}
              value={searchOptions.redeemedDateBefore || null}
              onChange={(date) => handleSearchOptionsChange('redeemedDateBefore', date)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid container alignItems={'center'} justifyContent={'flex-end'} spacing={2}>
          <Grid item className={classes.margin}>
            <IconButton
              aria-label={'Clear Filters'}
              onClick={clearFilters}
              disabled={!isClearFiltersActive}
            >
              <Tooltip title={'Clear Filters'}>
                <ClearAll />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item className={classes.margin}>
            <Fab
              aria-label={'Search'}
              color={'primary'}
              size={'small'}
              onClick={handleSearch}
              disabled={!isSearchActive}
            >
              <Tooltip title={'Search'}>
                <Search />
              </Tooltip>
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const tabs = [
    {
      name: 'campaign',
      icon: <StorefrontIcon />,
      content: (
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="voucher-name"
              label={lookUp({ key: 'CONSOLE_NAME' })}
              helperText={lookUp({ key: 'CONSOLE_CAMPAIGN_NAME_HELPERTEXT' })}
              required
              fullWidth
              value={model.name}
              onChange={(e) => handleModelChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="campaign-code"
              label={lookUp({ key: 'CONSOLE_PRODUCTID' })}
              helperText={lookUp({ key: 'CONSOLE_PRODUCTID_HELPERTEXT' })}
              required
              fullWidth
              margin="dense"
              value={model.productId}
              onChange={(e) => handleModelChange('productId', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              label={lookUp({ key: 'CONSOLE_ACTIVEFROM' })}
              variant="inline"
              inputVariant="outlined"
              size="small"
              helperText={lookUp({ key: 'CONSOLE_ACTIVEFROM_HELPERTEXT' })}
              autoOk
              fullWidth
              value={model.activeFrom || activeFromDefault}
              placeholder={lookUp({ key: 'CONSOLE_ACTIVEFROM_HELPERTEXT' })}
              onChange={(date) => handleModelDateChange('activeFrom', date)}
              format="MM/DD/YYYY"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              label={lookUp({ key: 'CONSOLE_ACTIVEUNTIL' })}
              variant="inline"
              inputVariant="outlined"
              size="small"
              helperText={lookUp({ key: 'CONSOLE_ACTIVEUNTIL_HELPERTEXT' })}
              autoOk
              fullWidth
              value={model.activeUntil || activeUntilDefault}
              placeholder={lookUp({ key: 'CONSOLE_ACTIVEUNTIL_HELPERTEXT' })}
              onChange={(date) => handleModelDateChange('activeUntil', date)}
              format="MM/DD/YYYY"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="usageDays"
              helperText={lookUp({ key: 'CONSOLE_USAGEDAYS_HELPERTEXT' })}
              label={lookUp({ key: 'CONSOLE_USAGEDAYS' })}
              type="number"
              fullWidth
              value={model.usageDays || 10}
              onChange={(e) => handleModelChange('usageDays', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="prefix"
              label={lookUp({ key: 'CONSOLE_VOUCHER_PREFIX' })}
              helperText={lookUp({ key: 'CONSOLE_VOUCHER_PREFIX_HELPERTEXT' })}
              type="text"
              fullWidth
              value={model.prefix}
              onChange={(e) => handleModelChange('prefix', e.target.value)}
              disabled={!!id}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              select
              disabled={!typeOptions?.length}
              fullWidth
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              label={lookUp({ key: 'CONSOLE_TYPE' })}
              onChange={({ target }) => handleModelChange('type', target.value)}
              value={model.type || 'Subscription'}
            >
              {typeOptions.map((e, i) => (
                <MenuItem key={i} value={e.value}>
                  {e.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="basePriceUSD"
              label={lookUp({ key: 'CONSOLE_BASEPRICEUSD' })}
              defaultValue={0}
              // type="double"
              fullWidth
              value={model.basePriceUSD || null}
              onChange={(e) => handleModelChange('basePriceUSD', e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            {isPbPolicyOptionsLoading ? (
              <Loader inline />
            ) : (
              <TextField
                select
                disabled={!pbPolicyOptions?.length}
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                label={lookUp({ key: 'CONSOLE_PLAYBACKPOLICYID' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECT_OR_SEARCH_PLACEHOLDER' })}
                value={model.playbackPolicyId || null}
                onChange={({ target }) => handleModelChange('playbackPolicyId', target.value)}
              >
                {pbPolicyOptions.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>
        </Grid>
      ),
    },
    !!id && {
      name: 'vouchers',
      icon: <BookmarkIcon />,
      content: (
        <VouchersTable
          voucherId={id}
          loadData={vouchersService.getVouchers}
          tableHeadElements={theadElements}
          tableBodyElements={tbodyElements}
          pageNumber={props.pageNumber}
          handleChangePage={(token) => vouchersService.historyPage(token)}
          loadCodeData={async (query) => await vouchersService.getVoucherByCode(query)}
          onPageChange={() => {}}
          customToolbar={customToolbar}
          clearAdditionalFilters={clearSearchOptions}
          requestAdditionalFiltersState={{
            u: searchOptions.redeemed,
            ra:
              searchOptions.redeemedDateAfter &&
              moment(searchOptions.redeemedDateAfter).format('YYYY-MM-DD') + 'T00:00:00',
            rb:
              searchOptions.redeemedDateBefore &&
              moment(searchOptions.redeemedDateBefore).format('YYYY-MM-DD') + 'T00:00:00',
          }}
        />
      ),
    },
    !!id && {
      name: 'create-vouchers',
      icon: <CreateIcon />,
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="voucherNumbers"
              label={lookUp({ key: 'CONSOLE_VOUCHERNUMBERS' })}
              type="number"
              fullWidth
              value={vouchers}
              onChange={(e) => setVouchers(e.target.value)}
            />
          </Grid>
          <Grid item>
            <ThemedButton
              color="primary"
              onClick={addVoucher}
              disabled={vouchers.length < 1 || isSaving || isDeleting}
              loading={isSaving}
            >
              {lookUp({ key: 'CONSOLE_ADD_VOUCHER' })}
            </ThemedButton>
          </Grid>
        </Grid>
      ),
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Delete action button
            id && (
              <DeleteButton onDelete={deleteVoucher} loading={isDeleting} disabled={isDeleting} />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/monetization/campaigns')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isDeleting}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isDeleting}
            loading={isSaving}
          >
            {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>
        <Tabbing
          tabs={tabs.filter((e) => !!e)}
          addTopMargin={HEADER_MIN_HEIGHT}
          scrollButtons="on"
        />
      </Box>

      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

CampaignsForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(CampaignsForm);
