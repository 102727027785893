import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';
import manualCatalogService from './manualCatalogService';
import {
  makeApiWithQuery,
  getTokenFromCookies
} from 'helpers/common';


const getCatalogEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Catalog');
  if (!endpoint) throw Error('No Catalog endpoint.');
  return endpoint;
};

const getAccessEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Access');
  if (!endpoint) throw Error('No Access endpoints.');
  return endpoint;
};

const get = (id) => 
  axios
  .get(`${getCatalogEndpoint()}/${id}`)
  .then((r) => r?.data)

const search = ({ page = 0, perPage = 10, sortBy = 'name',v = '', order = 'asc', q = '', ...rest } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getCatalogEndpoint()}/Search/${perPage}/${page}`, {
        sortBy,
        order,
        q,
        v,
        ...rest
      })
    )
    .then((r) => r?.data)

const catalogTypes = ({ page = 0, perPage = 10, sortBy = 'index',np = true, order = 'asc', q = '', ...rest } = {}) =>
    axios
      .get(
        makeApiWithQuery(`${getCatalogEndpoint()}/Search/${perPage}/${page}`, {
          sortBy,
          order,
          q,
          np,
          ...rest
        })
      )
      .then((r) => {
       return r?.data?.data
      })

const create = (model) => axios.post(getCatalogEndpoint(), model)
  .then(response => response?.data)

const edit = (model) => axios.put(`${getCatalogEndpoint()}/${model.id}`, model)
  .then(response => response?.data)

const changeIndex = (id, data) => axios.put(`${getCatalogEndpoint()}/ChangeIndex/${id}`, data)
  .then((r) => r?.data)

const setPosition = (data) => axios.put(`${getCatalogEndpoint()}/SetPosition`, data)
  .then((r) => r?.data)

const remove = (item) => axios.delete(`${getCatalogEndpoint()}/${item.id}`)
  .then((r) => r?.data)

const downloadJSON = (name, catalogJSON) => {
  const fileBlob = new Blob([JSON.stringify(catalogJSON)], { type: 'application/json' });
  const url = window.URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.setAttribute('href', url); 
  link.setAttribute('download', `${name}.json`);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

const uploadJSON = (catalogsText, parentId) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const catalogsCollage = JSON.parse(event.target.result);
      const separatedCatalogs = catalogsCollage.reduce((separatedCats, catalog) => {
        catalog.parentId = parentId;
        if (catalog.kind === 'Automatic') separatedCats.general.push(catalog);
        else if (!catalog.kind) separatedCats.manual.push(catalog);
        return separatedCats;
      },
      {
        general: [],
        manual: []
      });
      resolve(separatedCatalogs);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsText(catalogsText);
  })
    .then((separatedCatalogs) => {
      const generalUpload = separatedCatalogs.general.map((catalog) => create(catalog));
      const manualUpload = separatedCatalogs.manual.map((catalog) => {
        return manualCatalogService.create(catalog);
      });
      return Promise.all([ ...generalUpload, ...manualUpload ])
    })
};
  

export {
  search,
  create,
  get,
  edit,
  remove,
  catalogTypes,
  downloadJSON,
  uploadJSON,
};

export default {
  catalogTypes,
  search,
  create,
  setPosition,
  get,
  edit,
  remove,
  downloadJSON,
  uploadJSON,
  changeIndex,
};
