import { store } from 'store/configureStore';
import { setEndpoints } from 'store/actions/authAction';
import axios from 'axios';


function setConsoleEndpoints() {
  const parameterStoreEndpoint = window.__RUNTIME_CONFIG__.PARAMETER_STORE_ENDPOINT;
  return fetch(`${parameterStoreEndpoint}/Console/Endpoints`)
    .then(response => response.json())
    .then(data => store.dispatch(setEndpoints(data)))
    .catch(error => error);
}

function getConsoleApiEndpoint(endpointName) {
  const auth = store.getState().auth;
  const conslEnd = 'Console:Endpoints';
  const brandId = window.__RUNTIME_CONFIG__.BRAND_ID;
  const brandURL = auth.endpoints[`${brandId}:${conslEnd}:${endpointName}`];
  const globalURL = auth.endpoints[`${conslEnd}:${endpointName}`];
  return brandURL?.replace(/\/$/, '') || globalURL?.replace(/\/$/, ''); // remove trailing slashes
}

const getVersion = async (apiUrl) => { 
  try {
    const info = await axios.get(`${apiUrl}/Version`);
    const data = info.data;
    return (data.applicationVersion || data.version || data.Version || data.versionNumber || data.VersionNumber || 'N/A');
  } catch (err) {
    return console.error(err);
  }
}

const getServicesInformation = async (id, getVersions = true) => {

  const endpoints = Object.entries(store.getState().auth.endpoints)
    .filter(endpoint => {return ((endpoint[0].split(':')[0] === id || endpoint[0].split(':')[0] === 'Console') && endpoint[0].split(':').pop() !== 'Tracewind')});

  const basicEndpoints = Array.from(new Set(endpoints.map(endpoint => endpoint[1].split('/').slice(0, 3).join('/'))));

  const collectServices = (url) => {
    return endpoints
    .filter(endpoint => endpoint[1].indexOf(url) > -1)
    .map(endpoint => endpoint[0].split(':').pop())
  }

  const endpointsInfo = await Promise.all(basicEndpoints.map(async url => {
    const version = getVersions ? await getVersion(url) : '';
    const services = Array.from(new Set(collectServices(url)));
    return {
      url, 
      services,
      version,
    }
  }));

  return endpointsInfo;
}

const getSubscriptionHistory = () => {
  const auth = store.getState().auth;
  return auth.endpoints[`${auth.selectedBrand.brandId}:Custom:Subscription:Status:Url`];
}

export {
  setConsoleEndpoints,
  getConsoleApiEndpoint,
  getServicesInformation,
  getSubscriptionHistory,
};
