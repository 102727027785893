import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Box,
  TextField,
  Typography,
  ListItem,
  TextareaAutosize,
} from '@material-ui/core';
import { VariableSizeList as SubtitleVList } from 'react-window';
import { withSnackbar } from 'notistack';
import DeleteButton from 'components/DeleteButton';

const useStyles = makeStyles((theme) => ({
  subtitleItemsRow: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  subLine1: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtLineLeft: {
    width: '275px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subtPosition: {
    width: '125px',
    backgroundColor: '#fffa',
    '& fieldset': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
    '& .Mui-disabled': {
      color: '#000000',
      cursor: 'text',
    },
  },
  subtEnd: {
    width: '125px',
    backgroundColor: '#fffa',
    '& fieldset': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
    '& .Mui-disabled': {
      color: '#000000',
      cursor: 'text',
    },
  },
  subtLineRight: {
    width: 'calc(100%-275px)',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subtDel: {
    width: '40px',
    color: '#8c8c8c',
  },
  subLine2: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subtText: {
    width: '100%',
    resize: 'none',
    border: 'none',
    fontSize: 16,
    lineHeight: 1.5,
    padding: theme.spacing(1.3) + 'px ' + theme.spacing(1.75) + 'px',
    outline: 'none',
    background: 'transparent',
    position: 'relative',
    zIndex: 1,
    borderBottom: '2px solid #42a5f5',

    '&:read-only': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.26)',
    },

    fontFamily: 'inherit',
    '& fieldset': {
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
    },
    '& .Mui-disabled': {
      color: '#000000',
      cursor: 'text',
    },
  },
  deleteDialogHighlightedTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    padding: theme.spacing(1),
  },
}));

const EditField = (props) => {
  const {
    rowIndex,
    selectedBrPt,
    subtitleFieldType,
    commitEditedChanges,
    inheritedClassName,
    type = 'text',
    enqueueSnackbar,
  } = props;

  const classes = useStyles();

  const [editedValue, setEditedValue] = useState(selectedBrPt[subtitleFieldType]);

  useEffect(() => {
    return () => setEditedValue('');
  }, []);

  if (type === 'textarea') {
    return (
      <TextareaAutosize
        value={editedValue}
        minRows={3}
        maxRows={3}
        className={classes[inheritedClassName]}
        onChange={(event) => {
          const value = event.target.value;
          if (value.split('\n').length <= 3) {
            setEditedValue(event.target.value);
          }
        }}
        onBlur={(event) => commitEditedChanges(rowIndex, event, subtitleFieldType)}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            commitEditedChanges(rowIndex, event, subtitleFieldType);
          }
        }}
        autoFocus
      />
    );
  }

  return (
    <TextField
      value={editedValue}
      className={classes[inheritedClassName]}
      onChange={(event) => setEditedValue(event.target.value)}
      onBlur={(event) => commitEditedChanges(rowIndex, event, subtitleFieldType)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') commitEditedChanges(rowIndex, event, subtitleFieldType);
      }}
      autoFocus
    />
  );
};

function SubtitleEditor(props) {
  const {
    subtEditorWidth,
    subtEditorHeight,
    subtitleVlistRef,
    brPtContainer,
    selectedBrPt,
    handleBreakpointSelect,
    deleteBreakpoint,
    updateBreakpoint,
    setIsHotKeyListenerOn,
    enqueueSnackbar,
    subtitleFilter
  } = props;

  const classes = useStyles();

  const [subtEditing, setSubtEditing] = useState({
    position: null,
    end: null,
    text: null,
  });

  const switchToEdit = (rowId, rowIndex, event, fieldType, brPtType) => {
    setIsHotKeyListenerOn(false);
    handleBreakpointSelect(brPtType, rowIndex);
    setSubtEditing((subtEditing) => ({ ...subtEditing, [fieldType]: rowId }));
  };

  const commitEditedChanges = (rowIndex, event, fieldType) => {
    updateBreakpoint(selectedBrPt.type, { [fieldType]: event.target.value })
      .finally(() => {
        subtitleVlistRef.current.resetAfterIndex(rowIndex);
        setSubtEditing((subtEditing) => ({ ...subtEditing, [fieldType]: null }));
        setIsHotKeyListenerOn(true);
      });
  };

  const subtitleLine = ({ data, index:i, style }) => {
    const subtitle = data[i];
    const index = subtitle.originalIndex;

    if (!subtitle || !subtitle.position || !subtitle.end) return null;
    if (subtitle.position.length < 9) {
      subtitle.position = subtitle.position.slice(0, 9).concat('.000');
    }
    if (subtitle.end.length < 9) {
      subtitle.end = subtitle.end.slice(0, 9).concat('.000');
    }

    const calculatedStyle = { ...style };
    calculatedStyle.backgroundColor = subtitle?.id === selectedBrPt.id
      ? '#e6e6e6'
      : 'transparent';
    calculatedStyle.borderBottom = '1px solid #999999';
    calculatedStyle.margin = 0;
    calculatedStyle.padding = '1.25% 0.65%';
    calculatedStyle.cursor = 'pointer';

    return (
      <ListItem
        style={calculatedStyle}
        onClick={() => {
          if (selectedBrPt.id !== subtitle.id) {
            handleBreakpointSelect(subtitle.type, index);
          }
        }}
      >
        <Box className={classes.subtitleItemsRow}>
          <Box className={classes.subLine1}>
            <div className={classes.subtLineLeft}>
              {subtEditing.position === subtitle.id ? (
                <EditField
                  rowIndex={index}
                  selectedBrPt={selectedBrPt}
                  subtitleFieldType="position"
                  commitEditedChanges={commitEditedChanges}
                  setIsHotKeyListenerOn={setIsHotKeyListenerOn}
                  inheritedClassName="subtPosition"
                  enqueueSnackbar={enqueueSnackbar}
                />
              ) : (
                <TextField
                  value={subtitle.position}
                  className={classes.subtPosition}
                  onDoubleClick={(event) =>
                    switchToEdit(subtitle.id, index, event, 'position', subtitle.type)
                  }
                  disabled
                />
              )}

              <span>&nbsp;—&nbsp;</span>

              {subtEditing.end === subtitle.id ? (
                <EditField
                  rowIndex={index}
                  selectedBrPt={selectedBrPt}
                  subtitleFieldType="end"
                  commitEditedChanges={commitEditedChanges}
                  setIsHotKeyListenerOn={setIsHotKeyListenerOn}
                  inheritedClassName="subtEnd"
                  enqueueSnackbar={enqueueSnackbar}
                />
              ) : (
                <TextField
                  value={subtitle.end}
                  className={classes.subtEnd}
                  onDoubleClick={(event) =>
                    switchToEdit(subtitle.id, index, event, 'end', subtitle.type)
                  }
                  disabled
                />
              )}
            </div>

            <div className={classes.subtLineRight}>
              <DeleteButton
                onDelete={() => {
                  const rowInd = brPtContainer.Subtitle.findIndex(
                    (line) => line.id === subtitle.id,
                  );
                  deleteBreakpoint(rowInd, subtitle.id, selectedBrPt.type);
                }}
                dialogContent={
                  <Box component="span" className={classes.deleteDialogHighlightedTitle}>
                    <Typography variant="body2">{`${subtitle.position} — ${subtitle.end}`}</Typography>
                    <Typography variant="body2">{subtitle.text}</Typography>
                  </Box>
                }
                variant="icon"
                stopPropag
              />
            </div>
          </Box>

          <Box className={classes.subLine2}>
            {subtEditing.text === subtitle.id ? (
              <EditField
                rowIndex={index}
                selectedBrPt={selectedBrPt}
                subtitleFieldType="text"
                commitEditedChanges={commitEditedChanges}
                setIsHotKeyListenerOn={setIsHotKeyListenerOn}
                inheritedClassName="subtText"
                enqueueSnackbar={enqueueSnackbar}
                type="textarea"
              />
            ) : (
              <TextareaAutosize
                minRows={3}
                maxRows={3}
                value={subtitle.text}
                className={classes.subtText}
                onDoubleClick={(event) =>
                  switchToEdit(subtitle.id, index, event, 'text', subtitle.type)
                }
                readOnly
              />
            )}
          </Box>
        </Box>
      </ListItem>
    );
  };

  const subtitleItems = brPtContainer[selectedBrPt.type]
    .filter((e) => !e?.isInvisible)
    .map((val, i) => ({ ...val, originalIndex: i }))
    .filter((val) => val.text?.toLowerCase().includes(subtitleFilter.toLowerCase()));

  return (
    <SubtitleVList
      ref={subtitleVlistRef}
      width="100%"
      height={subtEditorHeight}
      itemCount={subtitleItems.length}
      itemData={subtitleItems}
      itemSize={() => 180}
      style={{ overflowX: 'hidden' }}
    >
      {subtitleLine}
    </SubtitleVList>
  );
}

export default withSnackbar(SubtitleEditor);



/*

              <IconButton
                className={classes.subtDel}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  delSubtLine(event, subtitle.id);
                }}
              >
                <Delete />
              </IconButton>
*/


// handleDelete(item.id, item.row.originalTitle)