import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Grid,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { lookUp } from 'services/stringService';
import { KeyboardDatePicker } from '@material-ui/pickers';


const useStyles = makeStyles(() => ({
  periodPickerButton: {
    textTransform: 'none',
  },
  periodListItem: {
    '&:hover': {
      backgroundColor: '#d9d9d9',
    }
  },
}));

function ChartFromTo() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedLocale = useSelector(s => s.lexicons.selected) ||
    localStorage.getItem('locale') ||
    'en-US';

  const [anchorElement, setAnchorElement] = useState(null);
  const [period, setPeriod] = useState('Last 7 days');

  const classes = useStyles();

  const openPeriodPicker = (event) => {
    setAnchorElement(event.currentTarget);
  };
 
  const closePeriodPicker = () => {
    setAnchorElement(null);
  };

  const handlePick = (event) => {
    const period = event.target.textContent;
    const newSearchParams = { ...searchParams };
    const startOfToday = new Date().setHours(0, 0, 0, 0);
    const endOfToday = new Date().setHours(23, 59, 59, 999);
    const thisMonthFirstDay = new Date(startOfToday).setDate(1);
    const thisMonth = new Date(thisMonthFirstDay).getMonth();
    switch (period) {
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_TODAY' }):
        newSearchParams.from = new Date(startOfToday);
        newSearchParams.to = new Date(endOfToday);
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_YESTERDAY' }):
        newSearchParams.from = new Date(startOfToday - (24 * 3600000));
        newSearchParams.to = new Date(newSearchParams.from.setHours(23, 59, 59, 999));
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTSEVENDAYS' }):
        newSearchParams.from = new Date(startOfToday - (6 * 24 * 3600000));
        newSearchParams.to = new Date(endOfToday);
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTTHIRTYDAYS' }):
        newSearchParams.from = new Date(startOfToday - (29 * 24 * 3600000));
        newSearchParams.to = new Date(endOfToday);
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_THISMONTH' }):
        newSearchParams.from = new Date(thisMonthFirstDay);
        newSearchParams.to = new Date(endOfToday);
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTMONTH' }):
        const lastMonthFirstDay = new Date(thisMonthFirstDay).setMonth(thisMonth - 1);
        const lastMonthLastDay = new Date(thisMonthFirstDay - (24 * 3600000)).setHours(23, 59, 59, 999);
        newSearchParams.from = new Date(lastMonthFirstDay);
        newSearchParams.to = new Date(lastMonthLastDay);
        break;
      case lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTTHREEMONTHS' }):
        const threeMonthsAgo = new Date(endOfToday).setMonth(thisMonth - 3);
        newSearchParams.from = new Date(threeMonthsAgo);
        newSearchParams.to = new Date(endOfToday);
        break;
      default:
        newSearchParams.from = new Date(new Date().setHours(0, 0, 0, 0));
        newSearchParams.to = new Date(new Date().setHours(23, 59, 59, 999));
        break;
    }
    setPeriod(period);
    searchParams.set('from', newSearchParams.from.toISOString());
    searchParams.set('to', newSearchParams.to.toISOString());
    setSearchParams(searchParams);
    setAnchorElement(null);
  };

  const calcPeriod = (from, to) => {
    if (from == null || to == null) return;
    const diffInSecs = Math.ceil((to.valueOf() - from.valueOf()) / 1000);
    const diffInDays = Math.floor(diffInSecs / (60 * 60 * 24));
    setPeriod(`${diffInDays} day${diffInDays > 1 ? 's' : ''}`);
  };

  const onReportSearch = (type, value) => {
    searchParams.set(type, value.toISOString());
    setSearchParams(searchParams);
  };

  const makeDateFormat = useCallback(() => {
    return 'MM/DD/YYYY';
    /*
    if (selectedLocale === 'en-US') {
      return 'MMM/DD/yyyy';
    } else if (selectedLocale === 'hu-HU') {
      return 'yyyy/MMM/dd';
    } else {
      return 'DD/MMM/yyyy';
    }
    */
  }, [selectedLocale]);

  const isPeriodPopOpen = !!anchorElement;
  const id = isPeriodPopOpen ? 'period-selector-popover' : undefined;

  useEffect(() => {
    if (!searchParams.has('report')) return;

    if (!(searchParams.has('from') && searchParams.has('to'))) {
      searchParams.set(
        'from',
         (new Date(new Date().setHours(0, 0, 0, 0) - (6 * 24 * 3600000))).toISOString()
      );
      searchParams.set(
        'to',
        (new Date(new Date().setHours(23, 59, 59, 999))).toISOString()
      );
      setPeriod(lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTSEVENDAYS' }));
      if (searchParams.get('report') === 'totalContentTimeSpent') {
        const startOfToday = new Date().setHours(0, 0, 0, 0);
        const endOfToday = new Date().setHours(23, 59, 59, 999);
        const thisMonthFirstDay = new Date(startOfToday).setDate(1);
        searchParams.set('from', (new Date(thisMonthFirstDay)).toISOString());
        searchParams.set('to', (new Date(endOfToday)).toISOString());
        setPeriod(lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_THISMONTH' }));
      }
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          label={lookUp({ key: 'CONSOLE_REPORTING_FROMDATE' })}
          value={moment(searchParams.get('from'))}
          size="small"
          autoOk
          variant="inline"
          format={makeDateFormat()}
          inputVariant="outlined"
          margin="normal"
          onChange={(date) => {
            if (date) {
              if (date > moment(searchParams.get('to'))) return;
              date.set({ millisecond: 0, second: 0, minute: 0, hour: 0 });
              calcPeriod(date, searchParams.to);
              onReportSearch('from', date);
            }
          }}
          style={{ margin: 0 }}
        />
      </Grid>

      <Grid item xs={2}>
        <KeyboardDatePicker
          label={lookUp({ key: 'CONSOLE_REPORTING_TODATE' })}
          value={moment(searchParams.get('to'))}
          size="small"
          autoOk
          format={makeDateFormat()}
          variant="inline"
          inputVariant="outlined"
          margin="normal"
          onChange={(date) => {
            if (date) {
              if (date < moment(searchParams.get('from'))) return;
              date.set({ millisecond: 999, second: 59, minute: 59, hour: 23 });
              calcPeriod(searchParams.from, date);
              onReportSearch('to', date);
            }
          }}
          style={{ margin: 0 }}
        />
      </Grid>

      <Grid item xs={2}>
        <Button
          aria-describedby={id}
          className={classes.periodPickerButton}
          variant="contained"
          color="primary"
          onClick={openPeriodPicker}
        >
          {period}
        </Button>
        <Popover
          id={id}
          open={isPeriodPopOpen}
          anchorEl={anchorElement}
          onClose={closePeriodPicker}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List id="period-list" dense>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_TODAY' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_YESTERDAY' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTSEVENDAYS' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTTHIRTYDAYS' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_THISMONTH' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTMONTH' })}
            </ListItem>
            <ListItem className={classes.periodListItem} onClick={handlePick} button>
              {lookUp({ key: 'CONSOLE_REPORTING_INTERVALS_LASTTHREEMONTHS' })}
            </ListItem>
          </List>
        </Popover>
      </Grid>
    </>
  );
}

export default ChartFromTo;
