import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';
import { makeApiWithQuery } from 'helpers/common';


const getContentEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Content');
  if (!endpoint) throw Error('No Content endpoints');
  return endpoint;
};

const getMediaProfilesEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('MediaProfile');
  if (!endpoint) throw Error('No transcoding profiles endpoint');
  return endpoint;
}

const getMediaProfileEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('MediaProfileEnums');
  if (!endpoint) throw Error('No transcoding profile enums endpoint');
  return endpoint;
}


const searchQuery = (search, pageSize = 25, page = 0) => {
  search.delete('page');
  search.delete('pageSize');
  return axios
    .get(`${getMediaProfilesEndpoint()}/Search/${pageSize}/${page}?${search}`)
    .then(res => res.data);}

const search = (
    { page = 0, perPage = 1000, q = '', sortExpr = 'name asc', ...rest } = {}
  ) => axios
      .get(
        makeApiWithQuery(`${getMediaProfilesEndpoint()}/Search/${perPage}/${page}`, {
          sortExpr,
          q,
          ...rest
        })
      )
      .then((res) => res?.data);

const create = async (model) => axios.post(getMediaProfilesEndpoint(), model)
  .then((r) => r?.data);

const get = (id) => axios.get(`${getMediaProfilesEndpoint()}/${id}`)
  .then((r) => r?.data);

const getAll = (e = true) => axios.get(`${getMediaProfilesEndpoint()}/Search/1000/0${e ? `?e=${e.toString()}`: ''}`)
  .then((r) => r?.data?.data);

const edit = async (id, model) => axios.put(`${getMediaProfilesEndpoint()}/${id}`, model)
  .then((r) => r?.data)

const remove = (id) => axios.delete(`${getMediaProfilesEndpoint()}/${id}`)
  .then((r) => r?.data);

const transcode = (contentId, body) => axios.put(`${getContentEndpoint()}/${contentId}/DrmData/Transcode`, body)
    .then(response => response);

const updateDrm = (contentId, fieldKey, body) => axios.put(`${getContentEndpoint()}/${contentId}/DrmData/${fieldKey}`, body)
    .then(response => response);

const getProfiles = () => axios.get(`${getMediaProfileEnumsEndpoint()}/StreamKinds`)
  .then(r => r?.data);

export { search, create, get, edit, remove, transcode, updateDrm, getAll, getProfiles };

export default {
  search,
  searchQuery,
  create,
  get,
  edit,
  remove,
  transcode,
  updateDrm,
  getAll,
  getProfiles,
};
