import PropTypes from 'prop-types';
import { GridOverlay } from '@material-ui/data-grid';
import NoResults from 'components/NoResults';

const DataGridOverlayNoResults = ({ title, message, children, ...rest }) => (
  <GridOverlay>
    <NoResults title={title} message={message} {...rest}>
      {children}
    </NoResults>
  </GridOverlay>
);

DataGridOverlayNoResults.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default DataGridOverlayNoResults;
