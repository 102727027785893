import React, { useState, } from 'react';
import { withSnackbar } from 'notistack';
import {
  makeStyles,
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { Add, Delete, Close } from '@material-ui/icons';
import { lookUp } from 'services/stringService';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    minHeight: '3.5em',
  },
  mainTable: {
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
  gridInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderStyle: 'none',
    },
    '& .MuiOutlinedInput-input': {
      paddingLeft: 0,
    },
  },
  textField: {
    width: '100%',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
  extraPropButton: {
    marginTop: theme.spacing(4),
  },
  addButton: {
    position: 'relative',
    zIndex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  linkItem: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
}));

const WhereToWatch = (props) => {
  
  const { handleChange, model } = props;
  const classes = useStyles();

  const [isAddOpen, setIsAddOpen] = useState(false);

  const columns = [
    {
      field: 'providerName',
      headerName: lookUp({ key: 'CONSOLE_Provider' }),
      width: 165,
    },
    {
      field: 'watchUrl',
      headerName: lookUp({ key: 'CONSOLE_URL' }),
      flex: 3,
    },
    {
      field: 'del',
      headerName: ' ',
      sortable: false,
      width: 40,
      renderCell: (item) => (
        <IconButton size="small" onClick={(e) => deleteItem(item)}>
          <Delete />
        </IconButton>
      ),
    },
  ];

  const addNewProviders = (input) => {
    const { inputProviderName, inputUrl } = input;
    setIsAddOpen(false);
    const copyProviders = [...model.whereToWatch, {providerName: inputProviderName, watchUrl: inputUrl}];
    handleChange('whereToWatch', copyProviders)
    return;
  };

  const deleteItem = (item) => {
    const copyProviders = model.whereToWatch.filter(el => item.row.providerName !== el.providerName)
    handleChange('whereToWatch', copyProviders)
  };

  const handleClose = () => setIsAddOpen(false);

  return (
    <Grid container className={classes.mainTable}>
      <Dialog open={isAddOpen} fullWidth maxWidth={'lg'} onClose={handleClose}>
        <DialogTitle className={classes.root}>
          {lookUp({ key: 'CONSOLE_Add_Provider_Window_TITLE' })}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={false}>
          <AddProvider addNewProviders={addNewProviders} />
        </DialogContent>
      </Dialog>
      <Grid item>
        <Button
          startIcon={<Add />}
          aria-haspopup="true"
          onClick={() => setIsAddOpen(true)}
          className={classes.extraPropButton}
          
          color="primary"
        >
          {lookUp({ key: 'CONSOLE_Add_New_BUTTON' })}
        </Button>
      </Grid>
      {model.whereToWatch && <ListProviders columns={columns} whereToWatch={model.whereToWatch} />}
    </Grid>
  );
};

export default withSnackbar(WhereToWatch);

const ListProviders = ({ columns, whereToWatch }) => {
  
  const rows = Object.keys(whereToWatch).map((e) => ({ ...whereToWatch[e], id: e }));
  
  return (
    <Grid item lg={12} xs={12}>
      <DataGrid
        style={{ border: 'none' }}
        columns={columns}
        rows={rows || []}
        autoHeight
        pagination
        disableColumnFilter
        disableColumnMenu
        localeText={{
          noRowsLabel: lookUp({ key: 'CONSOLE_no_rows' }),
        }}
        disableSelectionOnClick
        componentsProps={{
          pagination: { 
            classes: null,
            labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
            labelDisplayedRows: ({from, to, count}) => `${from}-${to} / ${count}`,
            SelectProps: {
              MenuProps: { disableScrollLock: true }
            }
          },
        }} 
      />
    </Grid>
  );
};

const AddProvider = ({ addNewProviders }) => {
  
  const [inputProviderName, setInputProviderName] = useState('');
  const [inputUrl, setInputUrl] = useState('');

  return (
    <Grid container>
      <Grid container spacing={2} item>
        <Grid item lg={12} xs={12}>
          <TextField
            label={lookUp({ key: 'CONSOLE_INPUTPROVIDERNAME' })}
            size="medium"
            blurOnSelect={false}
            onChange={(event) => setInputProviderName(event.currentTarget.value)}
            style={{ border: 'none' }}
            value={inputProviderName}
            id="add_provider_name"
            type="text"
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <TextField
            label={lookUp({ key: 'CONSOLE_INPUTURL' })}
            size="medium"
            fullWidth
            onChange={(event) => setInputUrl(event.currentTarget.value)}
            style={{ border: 'none' }}
            value={inputUrl}
            id="add_provider_url"
            type="text"
          />
        </Grid>
        <Grid item lg={12} xs={12} align="right">
        <Button
          onClick={() => addNewProviders({ inputProviderName, inputUrl })}
          
          disabled={!inputProviderName || !inputUrl}
          color="primary"
        >
          {lookUp({ key: 'CONSOLE_ADD_PROVIDER_BUTTON' })}
        </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
