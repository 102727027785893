import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { getById } from 'services/contentService';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PublishStatusIcon from 'components/PublishStatusIcon';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import { PlayContent } from 'components';
import { dateFormatter, msToHHMMSS } from 'helpers/time';


const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cover: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
  },
  greyCoverBg: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    backgroundColor: theme.palette.secondary?.light,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 65,
    height: 65,
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  relative: {
    position: 'relative',
  },
  playButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  noWrapEllipsisContainer: {
    minWidth: 0,
    width: '18rem',
  },
}));

const EMPTY_VALUE_INDICATOR = '-';

function ContentCard(props) {
  const classes = useStyles();
  const { items, item, onDelete, onAdd, index } = props;
  const [imageUrl, setImageUrl] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);

  const pickImage = (imageAssets) =>
    imageAssets.find((e) => e.subType === 'Landscape')?.publicUrl ||
    imageAssets.find((e) => e.subType === 'Landscape')?.objectUrl;

  useEffect(() => {
    if (item.id) { 
      getById(item.id)
        .then((result = {}) => {
          setImageUrl(pickImage(result.assets?.filter(asset => asset?.type === 'Image')));
        })
        .finally(() => setIsImageLoading(false));
    } else {
      setIsImageLoading(false);
    }
  }, []);

  return (
    <Card className={classes.card}>
      <span className={classes.relative}>
        <PlayContent item={item} buttonClass={classes.playButton} />
        {isImageLoading ? (
          <div className={classes.cover}>
            <LinearProgress />
          </div>
        ) : imageUrl ? (
          <CardMedia image={imageUrl} title={item.originalTitle} className={classes.cover} />
        ) : (
          <div className={classes.greyCoverBg}>
            <GraphicEqIcon className={classes.icon} />
          </div>
        )}
      </span>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems={'flex-start'} wrap={'nowrap'} justifyContent={'space-between'}>
          <Grid item xs={9} container direction="column" className={classes.column}>
            <Grid item className={classes.noWrapEllipsisContainer}>
              {item.type && <Typography variant="body2">{item.type}</Typography>}
              {item.originalTitle && (
                <Tooltip title={item.originalTitle}>
                  <Typography variant="h6" gutterBottom noWrap>
                    <Link
                      to={`/content/${item.id}/edit`}
                      component={RouterLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.originalTitle}
                    </Link>
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {`${lookUp({ key: 'CONSOLE_UPLOAD' })} ${lookUp({ key: 'CONSOLE_DATE' })} `}
                {item.createdDate ? (
                  <strong>{dateFormatter(item.createdDate)}</strong>
                ) : (
                  EMPTY_VALUE_INDICATOR
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {lookUp({ key: 'CONSOLE_DURATION' })}{' '}
                {item.duration ? (
                  <strong>{msToHHMMSS(item.duration)}</strong>
                ) : (
                  EMPTY_VALUE_INDICATOR
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={2}
            spacing={1}
            alignItems={'baseline'}
            wrap={'nowrap'}
            justifyContent={'flex-end'}
          >
            {item.isTrack && (
              <Tooltip
                title={item.isMainTrack
                  ? lookUp({ key: 'CONSOLE_MAIN_TRACK' })
                  : lookUp({ key: 'CONSOLE_TRACK_NUMBER_TEMPLATE', number: '' }
                )}
              >
                <VideoLibraryIcon style={{color: item.isMainTrack ? '#000000' : '#808080', marginRight: '10px' }}/>
              </Tooltip>
            )}

            <PublishStatusIcon isPublished={item.published} />

            {onAdd && (
              <Tooltip title={lookUp({ key: 'CONSOLE_ADD_ITEM' })}>
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    onAdd(item);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {onDelete && (
              <Tooltip title={lookUp({ key: 'CONSOLE_DELETE_ITEM_FROM_LIST_HELPERTEXT' })}>
                <IconButton
                  disabled={item.isMainTrack && (items.filter((item) => item.isTrack)?.length) > 1}
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete(item, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

ContentCard.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default ContentCard;
