import { useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const SearchBar = ({ label, icon, onChange = () => {}, size = 'medium' }) => {
  const [searchValue, setSearchValue] = useState('');

  const search = (value = searchValue) => {
    onChange?.(value);
  };

  const handleChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);
    search(value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
    search('');
  };

  // handles return key pressed
  const handleReturnPressed = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      search();
    }
  };

  return (
    <TextField
      type="text"
      variant="outlined"
      label={label || lookUp({ key: 'CONSOLE_SEARCH' })}
      value={searchValue}
      onChange={handleChange}
      size={size}
      InputLabelProps={{ shrink: true }}
      onKeyPress={handleReturnPressed}
      InputProps={{
        endAdornment: (
          <>
            {searchValue && (
              <IconButton size="small" onClick={() => handleClearSearch()}>
                <ClearIcon />
              </IconButton>
            )}
            <IconButton edge="end" size="small" onClick={() => search()}>
              {icon ? icon : <SearchIcon />}
            </IconButton>
          </>
        ),
      }}
      fullWidth
    />
  );
};

SearchBar.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default SearchBar;
