import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core';
import { ConfirmDialog, CreatedOn } from 'components';
import devicesService from 'services/devicesService';
import PublishedComponent from 'assets/icons/PublishedComponent';
import UnPublishedComponent from 'assets/icons/UnPublishedComponent';
import AlertService from 'services/alertService';


const Devices = ({ id, enqueueSnackbar }) => {

  const [deviceModel, setDeviceModel] = useState([]);
  const [trigger, setTrigger] = useState(false);

  const handleDelete = (deviceId, name = "Item") => {
    devicesService.deleteDevice(id, deviceId)
    .then(response => {
      setTrigger(o => !o)
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: lookUp({ key: `CONSOLE_${name}` }) })
      });
    })
    .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: name })
      });
    })
  };

  const handleDeleteAll = () => {
    Promise.all(deviceModel.map(e => devicesService.deleteDevice(id, e.deviceId)))
    .then(response => {
      setTrigger(o => !o)
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: lookUp({ key: `CONSOLE_${`Devices`}` }) })
      });
    })
    .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', title: lookUp({ key: `CONSOLE_${`Devices`}` }) })
      });
    })
  };


  useEffect(() => {
    const getData = async () => {
      const data = await devicesService.getById(id);
      data?.sort((deviceA, deviceB) => new Date(deviceB.lastSeen) - new Date(deviceA.lastSeen));
      setDeviceModel(data);
    }
    getData();
  }, [id, trigger]);

  return (
    <div>
      <>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left" padding={'checkbox'}>{lookUp({ key: 'CONSOLE_Active' })}</TableCell>
              <TableCell align="left" padding={'normal'}>{lookUp({ key: 'CONSOLE_DeviceType' })}</TableCell>
              <TableCell align="left" padding={'normal'}>{lookUp({ key: 'CONSOLE_DeviceModel' })}</TableCell>
              <TableCell align="left" padding={'normal'}>{lookUp({ key: 'CONSOLE_DateAdded' })}</TableCell>
              <TableCell align="left" padding={'normal'}>{lookUp({ key: 'CONSOLE_LastSeen' })}</TableCell>
              <TableCell align="right" padding={'dense'}>
                {deviceModel.length > 0 && 
                  <ConfirmDialog
                    buttonText={lookUp({ key: 'CONSOLE_DELETEALL_BUTTON' })}
                    buttonType="Button"
                    message={lookUp({ key: 'CONSOLE_DELETEALL_MESSAGE_TEMPLATE', type: lookUp({ key: 'CONSOLE_DEVICES'})})}
                    dialogText={lookUp({ key: 'CONSOLE_DELETE_HEADING' })}
                    onConfirm={() => handleDeleteAll()}
                  />
                }
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deviceModel.map((item, index) => (
              <TableRow key={`${item.deviceId}-${index}`}>
                <TableCell align="left" padding={'checkbox'} component="th" scope="row">
                  {item.isActive ? <PublishedComponent title="Active" /> : <UnPublishedComponent title="Inactive" />}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {item.deviceType}
                </TableCell>
                <TableCell align="left">
                  {item.deviceModel}
                </TableCell>
                <TableCell align="left">
                  <CreatedOn date={item.dateAdded} />
                </TableCell>
                <TableCell align="left">
                  <CreatedOn date={item.lastSeen} />
                </TableCell>
                <TableCell align="right">
                  <ConfirmDialog
                    onConfirm={() => handleDelete(item.deviceId, `${item.deviceType}`)}
                    message={lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: item.deviceType})}
                    dialogText='Delete'
                  />
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    </div>
  );
};

export default withSnackbar(Devices);