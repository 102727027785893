import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { func, shape } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Grid, makeStyles, TextField, MenuItem, Button } from '@material-ui/core';
import { FormFooter, EditorInfo } from 'components';
import stringService from 'services/stringService';
import navigationAction from 'store/actions/navigationAction';
import { goBackOrStay } from 'helpers/common';
import 'styles/localization.css';
import Box from '@material-ui/core/Box';
import AlertService from 'services/alertService';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  textField: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(4),
  },
  marginDown: {
    marginBottom: '30px',
  },
}));

function LocalizationForm(props) {
  const {
    enqueueSnackbar,
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();
  const { locale, name, flavor } = useParams();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const [model, setModel] = useState({
    locale: 'en-US',
    name: '',
    value: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const [locales, setLocales] = useState(useSelector((s) => s.lexicons.projectLanguages) || []);

  useEffect(() => {
    if (locales.length) return;
    const getLangs = async () => {
      const langs = await stringService.getProjectLanguages();
      setLocales(langs);
    };
    getLangs();
  }, []);

  useEffect(() => {
    const pageTitle = name
      ? lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: name })
      : lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_Localization` }) });
    dispatch(navigationAction.setPageTitle(pageTitle));
    const getData = async () => {
      try {
        const data = await stringService.get(locale, name, flavor);
        setModel(data);
      } catch (error) {
        console.error(error);
      }
    };
    getData();
    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const saveData = async () => {
    setIsSaving(true);

    let saveModel = { ...model };
    const itemExists = model.locale;

    try {

      setIsPublishing(itemExists);
      itemExists ? await stringService.edit(saveModel) : await stringService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: locale
          ? lookUp({
              key: 'CONSOLE_UPDATED_MESSAGE_TEMPLATE',
              title: `${model.locale} - ${model.name}`,
            })
          : lookUp({
              key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE',
              title: `${model.locale} - ${model.name}`,
            }),
      });
      const toWhere = window.history.state.idx === 0 ? '/configuration/localization' : -1;
      goBackOrStay(navigate, toWhere);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteLocale = async () => {
    try {
      if (flavor) {
        await stringService.remove(locale, name, flavor);
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: name }),
        });
        setTimeout(() => navigate('/configuration/localization'), 50);
      } else {
        await stringService.remove(locale, name);
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: name }),
        });
        setTimeout(() => navigate('/configuration/localization'), 50);
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_LOCALE' }),
        }),
      });
    }
  };

  return (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Delete action button
            name && (
              <DeleteButton onDelete={deleteLocale} loading={isDeleting} disabled={isDeleting} />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={e => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/configuration/localization')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={e => {
              e.preventDefault();
              saveData();
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: locale ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>
        
        <Grid container alignItems={'center'} spacing={2}>
          <Grid item xs={12}>
            <Grid item>
              <TextField
                select
                fullWidth
                id="locale"
                label={lookUp({ key: 'CONSOLE_LOCALE' })}
                InputLabelProps={{ shrink: true }}
                onChange={({ target }) => handleModelChange('locale', target.value)}
                value={model.locale}
              >
                {locales &&
                  locales.map((e, i) => (
                    <MenuItem value={e.value} key={e.value}>
                      {e.value} — {lookUp({ key: `CONSOLE_LANG_${e.value}` })}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <TextField
                id="name"
                label={lookUp({ key: 'CONSOLE_NAME' })}
                required
                className={classes.textField}
                value={model.name}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={10}>
              <TextField
                id="value"
                label={lookUp({ key: 'CONSOLE_VALUE' })}
                required
                multiline
                rows={4}
                className={classes.textField}
                value={model.value}
                onChange={(e) => handleModelChange('value', e.target.value)}
              />
            </Grid>
            <Grid item xs={2} container>
              <Button
                onClick={() => handleModelChange('value', model.value?.toUpperCase())}
                size="small"
                fullWidth
              >
                {lookUp({ key: 'CONSOLE_UPPERCASE' })}
              </Button>
              <Button
                onClick={() => handleModelChange('value', model.value?.toLowerCase())}
                size="small"
                fullWidth
              >
                {lookUp({ key: 'CONSOLE_LOWERCASE' })}
              </Button>
              <Button
                onClick={() =>
                  handleModelChange(
                    'value',
                    model.value?.charAt(0)?.toUpperCase() + model.value?.slice(1)?.toLowerCase()
                  )
                }
                size="small"
                fullWidth
              >
                {lookUp({ key: 'CONSOLE_CAPITALIZE' })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      
      {locale && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
}

LocalizationForm.propTypes = {
  history: shape({}).isRequired,
  enqueueSnackbar: func,
};

export default withSnackbar(LocalizationForm);
