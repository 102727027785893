import axios from 'axios';
import { lookUp } from 'services/stringService';
import { getConsoleApiEndpoint } from './apiEndpoints';

const create = (serviceName, model) => {
  if (!getConsoleApiEndpoint(serviceName)) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  return axios.post(getConsoleApiEndpoint(serviceName), model);
};

const get = (serviceName, id) => {
  if (!getConsoleApiEndpoint(serviceName)) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  return axios.get(`${getConsoleApiEndpoint(serviceName)}/${id}`);
};

const edit = (serviceName, id, model) => {
  if (!getConsoleApiEndpoint(serviceName)) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  return axios.put(`${getConsoleApiEndpoint(serviceName)}/${id}`, model);
};

const remove = (serviceName, id, subpath) => {
  if (!getConsoleApiEndpoint(serviceName)) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  return axios.delete(`${getConsoleApiEndpoint(serviceName)}/${subpath ? subpath + '/': ''}${id}`);
};

const search = (serviceName, search, pageSize = 25, page = 0, subpath) => {
  if (!getConsoleApiEndpoint(serviceName)) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  search.delete('page');
  search.delete('pageSize');
  return axios
    .get(`${getConsoleApiEndpoint(serviceName)}${subpath ? '/' + subpath : ''}/Search/${pageSize}/${page}?${search}`)
    .then((res) => res.data);
};

export default {
  create,
  get,
  edit,
  remove,
  search,
};
