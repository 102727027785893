import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Link } from 'react-router-dom';
import { arrayOf, bool, func, oneOfType, shape, string, object, number } from 'prop-types';
import { withSnackbar } from 'notistack';
import {initialState} from 'store/reducers/previewReducer'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getContentName } from 'services/topTrendsService'

import {
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
  TableSortLabel,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';

import {  CenteredBlock, Loader } from './index';

function EnhancedTableHead(props) {
  const { classes, headCells, } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) =>
          headCell.isSortable ? (
            <TableCell
              key={index}
              align={headCell.align || 'right'}
              padding={'default'}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
            </TableCell>
          ) : (
            <TableCell
              align={headCell.align || 'right'}
              key={headCell.name}
              {...(headCell.smallColumn && { className: classes.smallColumn })}
            >
              <strong>{headCell.label}</strong>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  headCells: arrayOf(object).isRequired,
};



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: 'auto'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  emptyCell: {
    border: 0,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1)
  },
  smallColumn: {
    width: '5%'
  }
}));

const EnhancedTable = (props) => {
  
  const { customerId } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [contentcustomer, setContentcustomer] = useState([]);
 // const [pageIndex, setPageIndex] = useState(props.pageNumber || 0);
  const [page, setPage] = useState(props.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [content, setContent] = useState([]);


  const {
    breadcrumbs,
    create,
    loadData,
    pageTitle,
    tableBodyElements,
    tableHeadElements,
    enqueueSnackbar,
    rowColorFunc,
    onPageChange,
    pageNumber
  } = props;

  


  const handleChangePage = (event, newPage) => {
    if(onPageChange) {
      onPageChange(newPage);
    }
    setPage(newPage)
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    
    if(onPageChange) {
      onPageChange(0);
    }
    setPage(0)
  };

  // const historyResult = await Promise.all(data.map(async (res) => {
    
  //   return{ date: res.createdDate, content: await getContentName(res.targetId) }

useEffect(() => {
  const getData = async () => {
    setIsLoading(true);
    try {
        const {data, count, totalCount} = await loadData(customerId,  {
          page: page,
          perPage: rowsPerPage,
        })
       
        const historyResult = await Promise.allSettled(data.map(async (res) => {
          const result = { date: res.createdDate, content: await getContentName(res.targetId) }
          return result
        }))
        const filteredHistory = historyResult.filter((history) => history.status === 'fulfilled')
        setContentcustomer(filteredHistory)
        setCount(count || totalCount);
        setError(null);
        setIsLoading(false);
      
    } catch (err) {
      let error = err;

      if (err.response && [401, 403].includes(err.response.status))
        error = 'You have no access for this operation';

      setError(error);
      setIsLoading(false);
    }
  }
  getData()
},[page, rowsPerPage])


  if (error) {
    return (
      <CenteredBlock>
        <Typography variant={'h3'} color={'error'}>
          Error
        </Typography>
        <Typography variant={'h4'} color={'error'}>
          {JSON.stringify(
            error && error.response && error.response.data && error.response.data.message,
            null,
            2
          )}
        </Typography>
      </CenteredBlock>
    );
  }

  return (
   
    <div className={classes.root}>
      <div>
       
        <div className={classes.tableWrapper}>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
               
                headCells={tableHeadElements}
               
              />
              {contentcustomer && contentcustomer.length !== 0 && (
                <TableBody>
                  {contentcustomer.map((item, index) => (
                    <TableRow
                      hover
                      key={item.id || index}
                      {...(rowColorFunc && { className: rowColorFunc(item) })}
                    >
                      {tableBodyElements.map((element) => (
                        <TableCell
                          align={element.align || 'right'}
                          key={element.name}
                          component={element.name === 'name' ? 'th' : ''}
                          scope={element.name === 'name' ? 'row' : ''}
                        >
                          {element.render ? element.render(item) : item[element.name].toISOString()}
                        </TableCell>
                      ))}
                     
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          )}
          {!isLoading && (!contentcustomer || contentcustomer.length === 0) && (
            <CenteredBlock height="10vh">
              <Typography variant="h5">No records found</Typography>
            </CenteredBlock>
          )}
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={count || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Rows:"
          backIconButtonProps={{
            'aria-label': 'previous page'
          }}
          nextIconButtonProps={{
            'aria-label': 'next page'
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

EnhancedTable.propTypes = {
  pageTitle: string,
  pageNumber: number,
  selectedBrand: shape({
	  brandId: string,
}),
  create: shape({
    link: string,
    text: string
  }),
  breadcrumbs: arrayOf(
    shape({
      text: string.isRequired,
      link: string,
      isCurrent: bool,
      order: number
    })
  ),
  tableHeadElements: arrayOf(
    shape({
      name: string,
      label: string.isRequired,
      isSortable: bool,
      align: string,
      smallColumn: bool
    })
  ).isRequired,
  tableBodyElements: arrayOf(
    oneOfType([
      shape({
        name: string.isRequired,
        align: string,
        render: func // item => result in td. Can be omit. By default renders item[name]
      })
    ])
  ).isRequired,
  loadData: func,
  customToolbar: func,
  enqueueSnackbar: func.isRequired,
  rowColorFunc: func,
 // onPageChange: func.isRequired
};

EnhancedTable.defaultProps = {
  pageTitle: '',
  create: null,
  breadcrumbs: [],
  tableClassName: '',
  rowColorFunc: null
};

const mapStateToProps = ({ auth: {  selectedBrand } }) => ({
	selectedBrand
  });

export default withSnackbar(compose(
    connect(
      mapStateToProps,
    ),
    )(EnhancedTable));