import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'uuid';
import { withSnackbar } from 'notistack';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'react-flow-renderer';
import {
  makeStyles,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { NotificationsActive, VerticalSplit } from '@material-ui/icons';
import { lookUp } from 'services/stringService';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import { Loader, EditorInfo, FormFooter, Tabbing } from 'components';
import { goBackOrStay } from 'helpers/common';
import supportedJobs from 'services/hardcodePatch/jobManager-supportedJobs.json';
import { getFromSupportedJobs } from 'helpers/workflow';
import TranscodeWithCut from './TranscodeWithCut';
import TranslateDropdown from './TranslateDropdown';
import LifecycleDropdown from './LifecycleDropdown';
import EditTags from './EditTags';
import WorkflowTemplatesDropdown from './WorkflowTemplatesDropdown';
import ContentTypeDropdown from './ContentTypeDropdown';
import navigationAction from 'store/actions/navigationAction';
import contentAction from 'store/actions/contentAction';
import NoAccess from 'pages/NoAccess';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';
import './index.scss';


// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const workFlowTemplate = {
  id: '',
  jobOwnerEmailAddress: '',
  name: '',
  createdDate: '',
  modifiedDate: '',
  ownerId: '',
  ownerEmail: '',
  workflowType: 'TEMPLATE',
  workflowStatus: undefined,
  triggerType: undefined,
  correlationId: undefined,
  brandId: undefined,
  jobRequests: [
    {
      id: '',
      name: '',
      ownerId: '',
      scheduled: '',
      type: '',
      index: 0,
      position: {
        x: 0,
        y: 0,
      },
      dependencies: [],
      subJobRequests: [],
    },
  ],
};

const excluded = [
  // 'type',
  'index',
  'uiData',
  'name',
  'subJobRequests',
  //  'dependencies',
  //  'expectedDebugStatus',
  //  'id',
  //  'isDebug',
  //  'referenceObjectId',
  //  'isPositiveCase',
  //  'inputFileLocation',
  //  'ownerId',
  //  'scheduled',
  //  'dependencies',
  //  'isPositiveCase',
  //  'provider',
];

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: '100%',
    padding: '10px',
  },
  topBar: {
    marginBottom: '5px',
  },
  aiContainer: {
    height: 'calc(100vh - 260px)',
    width: 'calc(100vw - 300px)',
    border: '1px solid rgb(0, 203, 230)',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  create: {
    textAlign: 'right',
  },
  tagsFrame: {
    width: '18%',
    height: '100%',
    flexBasis: '250px',
    borderRight: '2px dashed rgb(0, 203, 230)',
    paddingTop: '15px',
    paddingBottom: '20px',
    overflow: 'auto',
  },
  jobsTitle: {
    textAlign: 'center',
    marginBottom: '30px',
  },
  jobsCategory: {
    marginTop: '30px',
    marginBottom: '10px',
    textIndent: '6%',
    '&:first-of-type': {
      marginTop: '0',
    },
  },
  tagButton: {
    width: '90%',
    height: '50px',
    display: 'block',
    marginTop: '10px',
    marginLeft: '5%',
    border: '2px solid rgb(0, 203, 230)',
    borderRadius: '6px',
    backgroundColor: 'transparent',
    cursor: 'grab',
    color: 'rgb(80, 80, 80)',
    fontSize: '14px',
    letterSpacing: '1px',
    transitionDuration: '0.5s',
    '&:hover': {
      backgroundColor: 'rgb(0, 203, 230)',
      color: 'rgb(255, 255, 255)',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  diagramFrame: {
    height: '100%',
    flexGrow: 1,
  },
  tagAttributeBar: {
    height: '100%',
    borderLeft: '2px dashed rgb(0, 203, 230)',
    flexBasis: '250px',
    backgroundColor: 'rgb(245, 245, 245)',
    overflow: 'scroll',
  },
  tagAttrTitle: {
    margin: '15px 0 15px 0',
    textIndent: '2%',
  },
  inputDisabled: {
    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
  attrList: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  attrListElem: {
    width: '100%',
    display: 'block',
    margin: 0,
    padding: 0,
  },
  sliderCaption: {
    margin: '6% 0 0 4%',
  },
  sliderWrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 3% 4%',
    padding: 0,
  },
  sliderInput: {
    marginLeft: '20px',
    width: '100px',
  },
  inputContainer: {
    width: '93%',
    margin: theme.spacing(1, 1),
  },
  input: {
    width: '93%',
    margin: theme.spacing(1, 1),
  },
  switchOnOff: {
    cursor: 'alias',
  },
}));

const delKeyCodes = ['Backspace', 'Delete'];

const startNode = {
  id: '0_Start',
  type: 'input',
  data: {
    label: (
      <div>
        <p style={{ margin: 0, padding: 0 }}>Start</p>
      </div>
    ),
  },
  position: { x: 0, y: 0 },
  style: {
    background: 'rgb(36, 36, 36)',
    color: 'rgb(255, 255, 255)',
    borderRadius: '50%',
    width: 120,
    height: 40,
  },
};


function WorkflowsForm(props) {
  const { enqueueSnackbar } = props;

  const classes = useStyles();
  const { id, tab } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const jobStatusKeys = useSelector((state) => state.content.jobStatusKeys);
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.workflows;

  const [nodes, setNodes, onNodesChange] = useNodesState([startNode]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [contentJobs, setContentJobs] = useState({});
  const [decisionJobs, setDecisionJobs] = useState({});
  const [visionJobs, setVisionJobs] = useState({});
  const [nlpJobs, setNlpJobs] = useState({});
  const [selectedNode, setSelectedNode] = useState(null);
  const [sliderValues, setSliderValues] = useState({});
  const [waitTime, setWaitTime] = useState('');
  const [workflowData, setWorkflowData] = useState(workFlowTemplate);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [showImageProcProps, setShowImageProcProps] = useState({
    enableProps: false,
    resizeN1FixedDimension: false,
    resizeN1Width: false,
    resizeN1Height: false,
    resizeN2FixedDimension: false,
    resizeN2Width: false,
    resizeN2Height: false,
    gammaRed: false,
    gammaGreen: false,
    gammaBlue: false,
    blur: false,
    cropCropMode: false,
    cropX: false,
    cropY: false,
    cropWidth: false,
    cropHeight: false,
    cropGhost: false,
    boxHeight: false,
    boxWidth: false,
    boxColor: false,
    invert: false,
    grayScale: false,
    filterColor: false,
    tintColor: false,
    format: false,
    pixelSpace: false,
    detectColor: false,
  });
  const [isDeleting, setIsDeleting] = useState(false);

  const reactFlowWrapper = useRef(null);

  const handleNotificationSwitch = (notifKey, isChecked) => {
    const updatedNotifEvents = [...workflowData.notificationEvents];
    if (isChecked) {
      updatedNotifEvents.push(notifKey);
    } else {
      const notifInd = updatedNotifEvents.indexOf(notifKey);
      if (notifInd > -1) updatedNotifEvents.splice(notifInd, 1)
    }
    setWorkflowData((wfData) => ({ ...wfData, notificationEvents: updatedNotifEvents }));
  };

  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      const targetNode = nodes.find((node) => node.id === target);
      const sourceNode = nodes.find((node) => node.id === source);
      if (targetNode?.data.type === 'SUBTITLE') {
        if (sourceNode?.data.type === 'TRANSCRIPT') {
          onAttribChange(sourceNode.data.provider, 'text', targetNode.id, 'provider');
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            context: lookUp({ key: 'CONSOLE_SUBTITLING_ERROR_MESSAGE' }),
          });
        }
      }
      return setEdges((eds) => {
        return addEdge({ ...params, type: 'default', animated: false }, eds);
      });
    },
    [nodes]
  );

  const onDragStart = (event, jobProperties) => {
    jobProperties.label = event.target.textContent;
    event.dataTransfer.setData('text/plain', JSON.stringify(jobProperties));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const nodeData = JSON.parse(event.dataTransfer.getData('text/plain'));
      const id = uuid();
      nodeData.id = id;
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });

      if (nodeData.label === lookUp({ key: 'CONSOLE_DECISION_MAKING' })) {
        nodeData.dataSource = 0;
        nodeData.languageCode = null;
        delete nodeData.decision;
      }

      const newNode = {
        id,
        type: 'default',
        position,
        data: nodeData,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance]
  );

  const selectElem = (selectedElems) => {
    if (selectedElems.nodes.length === 1) {
      const selectedNode = selectedElems.nodes[0];
      if (selectedNode.id !== '0_Start') {
        let jobModel = null;
        if (selectedNode.data.processingMode === 'ImageSelection') {
          jobModel = Object.values(supportedJobs)
            .map((e) =>
              Object.values(e)?.find(
                (f) => f.type === selectedNode.data.type && f.processingMode === 'ImageSelection'
              )
            )
            ?.filter((g) => !!g)?.[0];
            jobModel = {
              ...jobModel,
              ...selectedNode.data,
            };
        } else if (
            selectedNode.data.type === 'MEDIA_PROCESSOR' &&
            selectedNode.data.mediaProcessingType === 'WHISPER_AI_TRANSCRIPT'
          ) {
            jobModel = Object.values(supportedJobs)
              .map((e) => Object.values(e)?.find((f) => f.type === 'TRANSCRIPT'))
              ?.filter((g) => !!g)?.[0];
            jobModel = {
              ...jobModel,
              ...selectedNode.data,
              provider: 'OpenAI',
              type: 'TRANSCRIPT'
            }
            delete jobModel.mediaProcessingType;
            setNodes((nodes) => {
              return nodes.map((node) => {
                if (node.id === selectedNode.id) {
                  node.data = { ...jobModel };
                }
                return node;
              });
            });
        } else {
            jobModel = Object.values(supportedJobs)
              .map((e) => Object.values(e)?.find((f) => f.type === selectedNode.data.type))
              ?.filter((g) => !!g)?.[0];
            jobModel = {
              ...jobModel,
              ...selectedNode.data,
            };
          }
        setSelectedNode({ ...jobModel });
      }
    }
  };

  const onDelete = (deletedItems) => {
    if (deletedItems.length === 0) {
      return;
    } else if (deletedItems[0].id === '0_Start') {
      // TODO -> forbid Start Workflow deletion
    } else if (deletedItems[0].id === selectedNode.id) {
      setSelectedNode(null);
    }
  };

  const keepWithinBounds = (key) => {
    if (sliderValues[key] < 0) {
      onSliderChange(key, 0);
    } else if (sliderValues[key] > 100) {
      onSliderChange(key, 100);
    }
  };

  const onSliderChange = (key, value) => {
    setSliderValues((values) => ({ ...values, [key]: parseInt(value, 10) }));
  };

  const onAttribChange = (value, type, tagId, key) => {
    let newValue = undefined;
    switch (type) {
      case 'switch':
        newValue = value;
        break;
      case 'number':
        newValue = parseInt(value, 10);
        break;
      case 'text':
        newValue = value;
        break;
      case 'slider':
        newValue = parseInt(value, 10);
        break;
      default:
        newValue = '';
    }
    setSelectedNode((node) => {
      node[key] = newValue;
      return { ...node };
    });
    setNodes((nodes) => {
      return nodes.map((node) => {
        if (node.id === tagId) {
          node.data[key] = newValue;
        }
        return node;
      });
    });
  };

  const validateWorkflow = () => {
    if (!workflowData.name)
      return { isValid: false, reason: lookUp({ key: 'CONSOLE_WORKFLOWS_INVALID_NAME_MESSAGE' }) };
    // const edgeCounter = edges.reduce((edgeC, edge) => {
    //   if (edgeC[edge.target]) {
    //     edgeC[edge.target] += 1;
    //   } else {
    //     edgeC[edge.target] = 1;
    //   }
    //   return edgeC;
    // }, {});
    const edgesTargetIds = edges.map((edge) => edge.target);
    const nodesIds = nodes.map((node) => node.id);
    const noInputNodes = nodesIds.filter((node) => !edgesTargetIds.includes(node));

    if (noInputNodes.length > 1) {
      return {
        isValid: false,
        reason: lookUp({ key: 'CONSOLE_WORKFLOWS_INCORRECT_CONNECTIONS_MESSAGE' }),
      };
    }

    // if (Object.values(edgeCounter).some((count) => count > 1)) {
    //   return {
    //     isValid: false,
    //     reason: lookUp({ key: 'CONSOLE_WORKFLOWS_INCORRECT_CONNECTIONS_MESSAGE' }),
    //   };
    // }
    // if (edgesCounter > nodes.length) {
    //   return { isValid: false, reason: lookUp({ key: 'CONSOLE_WORKFLOWS_WRONG_EDGES_MESSAGE' }) };
    // }

    return { isValid: true, reason: null };
  };

  const makeJob = (edge, jobIndex) => {
    const node = nodes.find((node) => node.id === edge.target);
    const newJob = {
      ...node.data,
      id: node.id,
      name: node.data.label,
      index: jobIndex++,
      ownerId: workflowData.id,
      subJobRequests: [],
      uiData: {
        id: uuid(),
        x: node.position.x,
        y: node.position.y,
        height: 40,
        width: 150,
        type: 'default',
      },
    };
    if (node.data.label === 'Decision Making') {
      newJob.decision = {};
      newJob.decision.dataSource = node.data.dataSource;
      newJob.decision.languageCode = node.data.languageCode;
      delete newJob.dataSource;
      delete newJob.languageCode;
    } else if (node.data.label === 'Image Processor') {
      Object.keys(newJob).forEach((key) => {
        if (showImageProcProps[key] === false) {
          newJob[key] = undefined;
        }
      });
    } else if (newJob.type === 'TRANSCRIPT' && newJob.provider === 'OpenAI') {
      if (newJob.languageCode) delete newJob.languageCode;
      newJob.type = 'MEDIA_PROCESSOR';
      newJob.mediaProcessingType = 'WHISPER_AI_TRANSCRIPT';
    }
    delete newJob.label;
    return newJob;
  };

  const makeMainJobs = (jobIndex) => {
    return edges.reduce((jobs, edge) => {
      if (edge.source === '0_Start') {
        const mainJob = makeJob(edge, jobIndex);
        jobs.push(mainJob);
      }
      return jobs;
    }, []);
  };

  const makeSubJobs = (job, jobIndex) => {
    edges.forEach((edge) => {
      if (edge.source === job.id) {
        const subJob = makeJob(edge, jobIndex);
        job.subJobRequests.push(subJob);
        makeSubJobs(subJob);
      }
    });
  };

  const pushUpWorkFlow = () => {
    const { isValid, reason } = validateWorkflow();
    if (!isValid) {
      AlertService.displayWarning({
        msgBar: enqueueSnackbar,
        message: reason,
      });
      return;
    }
    setIsSaving(true);
    let jobIndex = 0;
    const mainJobs = makeMainJobs(jobIndex);
    mainJobs.forEach((job) => makeSubJobs(job, jobIndex));
    workflowData.jobRequests = mainJobs;
    workflowData.modifiedDate = new Date().toISOString();
    const startNode = nodes.find((node) => node.id === '0_Start');
    workflowData.uiData = {
      id: uuid(),
      x: startNode.position.x,
      y: startNode.position.y,
      type: 'default',
      width: 40,
      height: 120,
    };

    jobManagerService
      .makeWfTemplate(workflowData)
      .then((response) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: id
            ? lookUp({ key: 'CONSOLE_UPDATED_MESSAGE_TEMPLATE', title: workflowData.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: workflowData.name }),
        });
        goBackOrStay(navigate, `/automation/workflows/`);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_WORKFLOWS' }),
          }),
        });
      })
      .finally(() => setIsSaving(false));
  };

  /* BUILDING WORKFLOW: Here it renders the workflow box: parentId; job: name, position; nodesEdges */
  const depthFirstAdd = (parentId, job, nodesEdges) => {
    const data = Object.keys(job).reduce(
      (jobData, key) => {
        if (!excluded.includes(key)) {
          if (key === 'decision') {
            jobData.dataSource = job.decision.dataSource;
            jobData.languageCode = job.decision.languageCode;
          } else {
            jobData[key] = job[key];
          }
        }
        return jobData;
      },
      /* BUILDING WORKFLOW: Here it renders the name of the workflow box */
      {
        label: job.name,
      }
    );
    const newNode = {
      id: job.id,
      data,
      position: {
        x: job.uiData.x,
        y: job.uiData.y,
      },
      height: job.uiData.height,
      width: job.uiData.width,
    };
    nodesEdges.nodes.push(newNode);

    const newEdge = {
      animated: false,
      source: parentId,
      sourceHandle: null,
      target: job.id,
      targetHandle: null,
      type: 'default',
    };
    nodesEdges.edges = addEdge(newEdge, nodesEdges.edges);

    if (job.subJobRequests.length) {
      job.subJobRequests.forEach((subJob) => depthFirstAdd(job.id, subJob, nodesEdges));
    }
  };

  const drawImageProcessorFields = (selectedNode, propList, key, index, attribute) => {
    if (typeof selectedNode[key] === 'boolean' && key !== 'isPositiveCase') {
      const formattedKey = key.split(/(?=[A-Z])/).join(' ');
      attribute = (
        <li key={`${key}_${index}`} className={classes.attrListElem}>
          <Switch
            id={`${key}_${index}`}
            color={'primary'}
            checked={selectedNode[key]}
            onChange={(event, value) => {
              onAttribChange(value, 'switch', selectedNode.id, key);
              if (key === 'enableProps') {
                setShowImageProcProps((showImgProps) => {
                  return Object.keys(showImgProps).reduce((imgProps, prop) => {
                    imgProps[prop] = value;
                    return imgProps;
                  }, {});
                });
              }
            }}
            disabled={key === 'enableProps' ? false : !showImageProcProps[key]}
          />
          <Typography
            variant="caption"
            onClick={() => {
              if (key !== 'enableProps') {
                setShowImageProcProps({ ...showImageProcProps, [key]: !showImageProcProps[key] });
              }
            }}
            className={key !== 'enableProps' && classes.switchOnOff}
          >
            {key === 'enableProps'
              ? lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_SHOW_PROPERTIES' })
              : formattedKey.slice(0, 1).toUpperCase().concat(formattedKey.slice(1))}
          </Typography>
        </li>
      );
    } else if (
      typeof selectedNode[key] === 'number' &&
      key !== 'groupPeriodLength' &&
      key !== 'index'
    ) {
      const formattedKey = key.split(/(?=[A-Z])/).join(' ');
      attribute = (
        <li key={`${key}_${index}`} className={classes.attrListElem}>
          <div className={classes.sliderCaption}>
            <Typography
              id="input-slider"
              variant="caption"
              onClick={() => {
                setShowImageProcProps({ ...showImageProcProps, [key]: !showImageProcProps[key] });
              }}
              className={classes.switchOnOff}
            >
              {formattedKey.slice(0, 1).toUpperCase().concat(formattedKey.slice(1))}
            </Typography>
          </div>
          <div className={classes.sliderWrapper}>
            <Slider
              value={selectedNode[key] ?? 95}
              step={1}
              marks
              track="inverted"
              onChange={(event, value) => {
                onSliderChange(key, value);
              }}
              onMouseUp={(event) => {
                onAttribChange(sliderValues[key], 'slider', selectedNode.id, key);
              }}
              disabled={!showImageProcProps[key]}
            />
            <Input
              className={classes.sliderInput}
              value={selectedNode[key] ?? 95}
              margin="dense"
              onChange={(event) => {
                onSliderChange(key, event.target.value);
                onAttribChange(event.target.value, 'slider', selectedNode.id, key);
              }}
              endAdornment={<InputAdornment position="end">%</InputAdornment>}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
              }}
              onBlur={() => keepWithinBounds(key)}
              disabled={!showImageProcProps[key]}
            />
          </div>
        </li>
      );
    } else if (typeof selectedNode[key] === 'string' && key.toLowerCase().includes('color')) {
      const formattedKey = key.split(/(?=[A-Z])/).join(' ');
      attribute = (
        <div key={`${key}_${index}`} className={classes.sliderCaption}>
          <Typography
            id="input-slider"
            variant="caption"
            onClick={() => {
              setShowImageProcProps({ ...showImageProcProps, [key]: !showImageProcProps[key] });
            }}
            className={classes.switchOnOff}
          >
            {formattedKey.slice(0, 1).toUpperCase().concat(formattedKey.slice(1))}
          </Typography>
          <div className={classes.sliderWrapper}>
            <TextField
              variant="standard"
              disabled={!showImageProcProps[key]}
              value={selectedNode[key]}
              style={{
                width: '100%',
              }}
            />
            <div style={{ display: 'flex', justifyContent: 'center', width: '60%' }}>
              <input
                type="color"
                value={selectedNode[key]}
                onChange={(event) =>
                  onAttribChange(event.target.value, 'text', selectedNode.id, key)
                }
                disabled={!showImageProcProps[key]}
              />
            </div>
          </div>
        </div>
      );
    } else if (key.toLowerCase() === 'contentsubtype') {
      const contentSubTypeOptions = ['Square', 'Portrait', 'Landscape'];
      attribute = (
        <TextField
          className={classes.input}
          select
          size="medium"
          label={lookUp({ key: 'CONSOLE_CONTENT_SUBTYPE' })}
          value={selectedNode[key]}
          onChange={(event) =>
            onAttribChange(event.target.value, 'text', selectedNode.id, 'contentSubType')
          }
        >
          {contentSubTypeOptions?.map((o, index) => (
            <MenuItem label={o} key={`${o}_${index}`} value={o}>
              {o}
            </MenuItem>
          ))}
        </TextField>
      );
    } else if (key.toLowerCase() === 'targetformat') {
      const targetformatOptions = ['Default', 'WebP', 'ProgressivePNG'];
      attribute = (
        <TextField
          className={classes.input}
          select
          size="medium"
          label={lookUp({ key: 'CONSOLE_CONTENT_TARGET_FORMAT' })}
          value={selectedNode[key]}
          onChange={(event) =>
            onAttribChange(event.target.value, 'text', selectedNode.id, 'targetFormat')
          }
        >
          {targetformatOptions?.map((o, index) => (
            <MenuItem label={o} key={`${o}_${index}`} value={o}>
              {o}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    propList.push(attribute);
  };

  const drawInputField = (selectedNode, propList, key, index) => {
    
    const onlyDrawThese = [
      'streamKind',
      'sourceLanguageCode',
      'targetLanguageCode',
      'languageCode',
      'language',
      'technicalCue',
      'provider',
      'mediaProcessingType',
    ];
    let attribute = null;

    if (selectedNode.type === 'IMAGE_PROCESSOR') {
      return drawImageProcessorFields(selectedNode, propList, key, index, attribute);
    }

    if (
      typeof selectedNode[key] === 'boolean' &&
      key !== 'isDebug' &&
      key !== 'isPositiveCase' &&
      key !== 'add'
    ) {
      const formattedKey = key.split(/(?=[A-Z])/).join(' ');
      attribute = (
        <li key={`${key}_${index}`} className={classes.attrListElem}>
          <Switch
            id={`${key}_${index}`}
            color={'primary'}
            checked={selectedNode[key]}
            onChange={(event, value) => {
              onAttribChange(value, 'switch', selectedNode.id, key);
            }}
          />
          <Typography variant="caption">
            {formattedKey.slice(0, 1).toUpperCase().concat(formattedKey.slice(1))}
          </Typography>
        </li>
      );
      propList.push(attribute);
      return;
    }
    if (key === 'contentType') {
      attribute = (
        <ContentTypeDropdown
          enqueueSnackbar={enqueueSnackbar}
          onAttribChange={onAttribChange}
          selectedNode={selectedNode}
        />
      );
      propList.push(attribute);
      return;
    }
    if (key === 'add') {
      attribute = <EditTags onAttribChange={onAttribChange} selectedNode={selectedNode} />;
      propList.push(attribute);
      return;
    }
    if (key === 'workflowTemplateId') {
      attribute = (
        <WorkflowTemplatesDropdown
          enqueueSnackbar={enqueueSnackbar}
          onAttribChange={onAttribChange}
          nodeId={selectedNode.id}
        />
      );
      propList.push(attribute);
      return;
    }
    if (key === 'waitTime') {
      attribute = (
        <TextField
          id="wait-input"
          label={lookUp({ key: 'CONSOLE_AWAIT_TIME' })}
          type="time"
          inputProps={{ step: 0.001 }}
          InputLabelProps={{ shrink: true }}
          value={waitTime || selectedNode.waitTime || '00:00:00'}
          size="normal"
          variant="standard"
          className={classes.datePicker}
          onChange={(event) => {
            let timePoint = event.target.value;
            timePoint = timePoint.length === 5 ? `${timePoint}:00` : timePoint;
            setWaitTime(timePoint);
            onAttribChange(timePoint, 'text', selectedNode.id, 'waitTime');
          }}
          style={{
            width: '80%',
            marginLeft: '10%',
            marginRight: '10%',
          }}
        />
      );
      propList.push(attribute);
      return;
    }
    if (
      onlyDrawThese.includes(key) ||
      key.includes('Confidence') ||
      key.includes('LifecycleType')
    ) {
      if (key === 'mediaProcessingType') {
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <TextField
              select
              fullWidth
              value={selectedNode[key]}
              onChange={({ target }) => onAttribChange(target.value, 'text', selectedNode.id, key)}
            >
              {['GENERATE_SPECTOGRAM', 'CONVERT_TO_WAV', 'MEDIA_INFO'].map((e) => (
                <MenuItem value={e}>{lookUp({ key: `CONSOLE_${e}_JOB` })}</MenuItem>
              ))}
            </TextField>
          </li>
        );
      }
      if (key === 'streamKind') {
        {
          /* TranscodeProfiles Grouped Dropdown - working with streamKind key
          TODO: change the key if we will have better key than 'streamKind' to filter */
        }
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <TranscodeWithCut
              drawMarkers={Object.keys(selectedNode).includes('markerType') ? true : false}
              model={selectedNode}
              onAttribChange={onAttribChange}
              enqueueSnackbar={enqueueSnackbar}
            />
          </li>
        );
      } else if (
        key === 'sourceLanguageCode' ||
        key === 'targetLanguageCode' ||
        key === 'language'
      ) {
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <TranslateDropdown
              languageCode={selectedNode[key] || ''}
              onAttribChange={(value) => onAttribChange(value, 'text', selectedNode.id, key)}
              nodeId={selectedNode.id}
              label={'Select ' + key.split('Language')[0]}
            />
          </li>
        );
      } else if (key === 'languageCode') {
        if (selectedNode.provider === 'OpenAI' && selectedNode.type === 'TRANSCRIPT') {
          attribute = null;
        } else {
          attribute = (
            <li key={`${key}_${index}`} className={classes.attrListElem}>
              <TranslateDropdown
                languageCode={selectedNode[key] || ''}
                onAttribChange={(value) => onAttribChange(value, 'text', selectedNode.id, key)}
                nodeId={selectedNode.id}
                label={'Select Language'}
              />
            </li>
          );
        }
      } else if (key.includes('LifecycleType') && selectedNode[key] !== '-') {
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <LifecycleDropdown
              lifecycleType={key}
              selectedNode={selectedNode}
              onAttribChange={onAttribChange}
            />
          </li>
        );
      } else if (key.includes('Confidence')) {
        let labelKeyword = key.replace(/min|Confidence/gi, '');
        let formattedLabelKeyword = labelKeyword.split(/(?=[A-Z])/).join(' ');
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <div className={classes.sliderCaption}>
              <Typography id="input-slider" variant="caption">
                {`${formattedLabelKeyword} Confidence Level`}
              </Typography>
            </div>
            <div className={classes.sliderWrapper}>
              <Slider
                value={selectedNode[key] ?? 95}
                step={1}
                marks
                track="inverted"
                onChange={(event, value) => {
                  onSliderChange(key, value);
                }}
                onMouseUp={(event) => {
                  onAttribChange(sliderValues[key], 'slider', selectedNode.id, key);
                }}
              />
              <Input
                className={classes.sliderInput}
                value={selectedNode[key] ?? 95}
                margin="dense"
                onChange={(event) => {
                  onSliderChange(key, event.target.value);
                  onAttribChange(event.target.value, 'slider', selectedNode.id, key);
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                inputProps={{
                  step: 10,
                  min: 0,
                  max: 100,
                  type: 'number',
                }}
                onBlur={() => keepWithinBounds(key)}
              />
            </div>
          </li>
        );
      } else if (typeof selectedNode[key] === 'boolean') {
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <Switch
              id={`${key}_${index}`}
              color={'primary'}
              checked={selectedNode[key]}
              onChange={(event, value) => {
                onAttribChange(value, 'switch', selectedNode.id, key);
              }}
            />
            <Typography variant="caption">
              {key.slice(0, 1).toUpperCase().concat(key.slice(1))}
            </Typography>
          </li>
        );
      }
      if (key === 'technicalCue') {
        attribute = (
          <li key={`${key}_${index}`} className={classes.attrListElem}>
            <TextField
              id={`${key}_${index}`}
              margin="dense"
              type={typeof selectedNode[key] === 'number' ? 'number' : 'text'}
              value={selectedNode[key]}
              name={key}
              label={key.slice(0, 1).toUpperCase().concat(key.slice(1))}
              onChange={(event) => {
                const type = typeof selectedNode[key] === 'number' ? 'number' : 'text';
                onAttribChange(event.target.value, type, selectedNode.id, key);
              }}
              inputProps={typeof selectedNode[key] === 'number' ? { min: 0, max: 100 } : {}}
              InputProps={{ classes: { disabled: classes.inputDisabled } }}
              className={classes.inputContainer}
              fullWidth
            />
          </li>
        );
      }
      if (key === 'provider') {
        let providerOptions = selectedNode.providerOptions;
        if (!selectedNode.providerOptions) {
          providerOptions = getFromSupportedJobs('providerOptions', selectedNode);
        }
        if (providerOptions) {
          attribute = (
            <TextField
              select
              className={classes.inputContainer}
              size="medium"
              labelId="provider"
              label={key.slice(0, 1).toUpperCase().concat(key.slice(1))}
              margin="dense"
              value={selectedNode[key] || ''}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              onChange={(event) => onAttribChange(event.target.value, 'text', selectedNode.id, key)}
            >
              {providerOptions?.map((provider, index) => (
                <MenuItem value={provider} key={`${index}_${provider}`}>
                  {provider}
                </MenuItem>
              ))}
            </TextField>
          );
        }
      }
      propList.push(attribute);
    }
  };

  const drawTagAttributes = () => {
    if (typeof selectedNode.label !== 'string') {
      selectedNode.label = lookUp({ key: 'CONSOLE_START' });
      selectedNode.index = 0;
      selectedNode.type = 104;
    }

    const keys = Object.keys(selectedNode);

    return (
      <>
        <h4 className={classes.tagAttrTitle}>{selectedNode.label}</h4>
        <ul className={classes.attrList}>
          {keys.reduce((propList, key, index) => {
            if (selectedNode.type === 'IMAGE_PROCESSOR') {
              if (key === 'resizeN1FixedDimension') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_RESIZE' })}
                  </h5>,
                );
              } else if (key === 'gammaRed') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_GAMMA' })}
                  </h5>,
                );
              } else if (key === 'cropCropMode') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_CROP' })}
                  </h5>,
                );
              } else if (key === 'boxHeight') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_BOX' })}
                  </h5>,
                );
              } else if (key === 'boxColor') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_COLOR' })}
                  </h5>,
                );
              } else if (key === 'blur') {
                propList.push(
                  <h5 className={classes.tagAttrTitle}>
                    {lookUp({ key: 'CONSOLE_WORKFLOW_IMGPROC_BLUR' })}
                  </h5>,
                );
              }
            }
            drawInputField(selectedNode, propList, key, index);
            return propList;
          }, [])}
        </ul>
      </>
    );
  };

  useEffect(() => {
    setIsLoading(true);
    setContentJobs(supportedJobs['GetSupportedContentJobs']);
    setDecisionJobs(supportedJobs['GetSupportedDecisionJobs']);
    setVisionJobs(supportedJobs['GetSupportedComputerVisionJobs']);
    setNlpJobs(supportedJobs['GetSupportedNlpJobs']);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const checkStoreJobNotifStatuses = () => {
      if (Object.keys(jobStatusKeys).length === 0) {
        return jobManagerService.getJobStatusesEnum()
          .then((jobStatusEnum) => {
            const jobStatusKeys = Object.keys(jobStatusEnum).reduce((jbKeys, key) => {
              jbKeys[key.toUpperCase()] = jobStatusEnum[key];
              return jbKeys;
            }, {})
            dispatch(contentAction.setJobStatusKeys(jobStatusKeys));
            return jobStatusEnum;
          })
          .catch((error) => {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error,
              context: lookUp({
                key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
                type: lookUp({ key: 'CONSOLE_WORKFLOWS' }),
              }),
            });
          });
      } else {
        return Promise.resolve(jobStatusKeys);
      }
    };

    const getData = async () => {
      try {
        const wfData = await jobManagerService.getWfTemplate(id);
        wfData.name = wfData.name.slice(0, 9) === 'template-' ? wfData.name.slice(9) : wfData.name;
        if (!wfData.notificationEvents) wfData.notificationEvents = [];
        setWorkflowData(wfData);
        const pageTitle = wfData.name || lookUp({ key: 'CONSOLE_WORKFLOW' });
        dispatch(navigationAction.setPageTitle(pageTitle));
        const mainJobs = wfData.jobRequests;

        const { nodes, edges } = mainJobs.reduce(
          (nodesEdges, mainJob) => {
            depthFirstAdd('0_Start', mainJob, nodesEdges);
            return nodesEdges;
          },
          {
            nodes: [],
            edges: [],
          }
        );

        setNodes((nds) => {
          nds[0].position.x = wfData.uiData.x;
          nds[0].position.y = wfData.uiData.y;
          return nds.concat(nodes);
        });
        setEdges((edgs) => edgs.concat(edges));
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_WORKFLOWS' }),
          }),
        });
      }
    };

    checkStoreJobNotifStatuses()
      .then(() => {
        if (id) {
          getData();
        } else {
          const pageTitle = lookUp({
            key: 'CONSOLE_CREATE_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_WORKFLOW' })
          });
          dispatch(navigationAction.setPageTitle(pageTitle));
          setWorkflowData((wfData) => ({
            ...wfData,
            id: uuid(),
            createdDate: new Date().toISOString(),
            modifiedDate: new Date().toISOString(),
            ownerId: user.id,
            ownerEmail: user.emailAddress,
            jobOwnerEmailAddress: user.emailAddress,
            notificationEvents: ['SUCCEED', 'FAILED'],
          }));
        }
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_WORKFLOWS' }),
          }),
        });
      });

    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  const tabs = [
    {
      name: 'basic',
      content: (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_WORKFLOW_NAME' })}
                type="text"
                inputProps={{ maxLength: 50 }}
                value={workflowData.name}
                onChange={(event) => {
                  setWorkflowData((wfData) => ({ ...wfData, name: event.target.value }));
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_JOBOWNEREMAILADDRESS' })}
                type="email"
                inputProps={{ maxLength: 100 }}
                value={workflowData.jobOwnerEmailAddress}
                onChange={(event) => {
                  setWorkflowData((wfData) => ({
                    ...wfData,
                    jobOwnerEmailAddress: event.target.value,
                  }));
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      ),
    },
    {
      name: 'workflows',
      icon: <VerticalSplit />,
      content: (
        <>
          <ReactFlowProvider>
            <section className={classes.aiContainer}>
              <aside className={classes.tagsFrame}>
                <h4 className={classes.jobsCategory}>Content</h4>
                {Object.keys(contentJobs).map((jobName, index) => (
                  <button
                    key={`${jobName}_${index}`}
                    className={classes.tagButton}
                    draggable
                    onDragStart={(event) => onDragStart(event, contentJobs[jobName])}
                  >
                    {jobName}
                  </button>
                ))}

                <h4 className={classes.jobsCategory}>Decision</h4>
                {Object.keys(decisionJobs).map((jobName, index) => (
                  <button
                    key={`${jobName}_${index}`}
                    className={classes.tagButton}
                    draggable
                    onDragStart={(event) => onDragStart(event, decisionJobs[jobName])}
                  >
                    {jobName}
                  </button>
                ))}

                <h4 className={classes.jobsCategory}>Computer Vision</h4>
                {Object.keys(visionJobs).map((jobName, index) => (
                  <button
                    key={`${jobName}_${index}`}
                    className={classes.tagButton}
                    draggable
                    onDragStart={(event) => onDragStart(event, visionJobs[jobName])}
                  >
                    {jobName}
                  </button>
                ))}

                <h4 className={classes.jobsCategory}>NLP</h4>
                {Object.keys(nlpJobs).map((jobName, index) => (
                  <button
                    key={`${jobName}_${index}`}
                    className={classes.tagButton}
                    draggable
                    onDragStart={(event) => onDragStart(event, nlpJobs[jobName])}
                  >
                    {jobName}
                  </button>
                ))}
              </aside>

              <article className={classes.diagramFrame} ref={reactFlowWrapper}>
                <ReactFlow
                  onInit={setReactFlowInstance}
                  fitView
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  connectionLineType="default"
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onSelectionChange={selectElem}
                  deleteKeyCode={delKeyCodes}
                  onNodesDelete={onDelete}
                  attributionPosition="bottom-right"
                >
                  <MiniMap
                    nodeStrokeColor={(n) => {
                      if (n.style?.background) return n.style.background;
                      if (n.type === 'input') return 'rgb(36, 36, 36)';
                      if (n.type === 'output') return '#ff0072';
                      if (n.type === 'default') return '#1a192b';
                      return '#eee';
                    }}
                    nodeColor={(n) => {
                      if (n.style?.background) return n.style.background;
                      return '#fff';
                    }}
                    nodeBorderRadius={2}
                  />
                  <Controls />
                  <Background color="#aaa" gap={16} />
                </ReactFlow>
              </article>

              {useMemo(
                () => (
                  <aside className={classes.tagAttributeBar}>
                    {selectedNode && drawTagAttributes()}
                  </aside>
                ),
                [selectedNode, showImageProcProps]
              )}
            </section>
          </ReactFlowProvider>
        </>
      ),
    },
    {
      name: 'notifications',
      icon: <NotificationsActive />,
      content: (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_JOB_NOTIFICATIONS' })}
            </Typography>

            {workflowData?.notificationEvents && Object.keys(jobStatusKeys).map((jSKey) => (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={workflowData.notificationEvents.includes(jSKey)}
                      onClick={(event) => {
                        handleNotificationSwitch(jSKey, event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={lookUp({ key: `CONSOLE_${jobStatusKeys[jSKey]}` })}
                />
              </FormGroup>
            ))}
          </Grid>
        </Grid>
      )
    },
  ];

  const deleteWf = async () => {
    setIsDeleting(true);
    try {
      await jobManagerService.removeWfTemplate(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate('/automation/workflows'), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: workflowData.name }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_WORKFLOWS' }),
        }),
      });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
        <NoAccess />
      ) : (
        <SectionContainer flex={1}>
          <Box flex={1} sx={{ mb: 4 }}>
            <HeaderActionsContainer>
              {
                // Delete action button
                id && <DeleteButton onDelete={deleteWf} loading={isDeleting} disabled={isDeleting} />
              }
              <Box display="flex" flex={1} />
              {/* Cancel action Button */}
              <ThemedButton
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(navigationAction.allowNavigation());
                  window.history?.state?.idx === 0
                    ? navigate('/automation/workflows')
                    : navigate(window.history?.state?.idx === 0 ? '/' : -1);
                }}
                disabled={isSaving}
              >
                {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
              </ThemedButton>

              {/* Save action Button */}
              <ThemedButton
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  pushUpWorkFlow();
                }}
                disabled={isSaving}
                loading={isSaving}
              >
                {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
              </ThemedButton>
            </HeaderActionsContainer>
            <Tabbing
              tabs={tabs}
              addTopMargin={HEADER_MIN_HEIGHT}
              scrollButtons="on"
            />
          </Box>

          {id && (
            <EditorInfo
              createdAt={workflowData.createdDate}
              modifiedAt={workflowData.lastModifiedDate}
              modifiedBy={workflowData.lastModifiedBy}
            />
          )}
        </SectionContainer>
      )}
    </>
  );
}

export default withSnackbar(WorkflowsForm);