import React from 'react';
import { PageCard } from 'components';
import { SupervisorAccount, Description, ConfirmationNumber } from '@material-ui/icons';

const pages = [
  {
    name: 'Users',
    link: '/access/admins?type=Admin',
    icon: <SupervisorAccount fontSize={'large'} />
  },
  {
    name: 'Logs',
    link: '/access/logs',
    icon: <ConfirmationNumber fontSize={'large'} />
  },
  {
    name: 'Audit-Logs',
    link: '/access/audit-logs',
    icon: <Description fontSize={'large'} />
  }
];

const AccessHome = () => <PageCard pages={pages} />;

export default AccessHome;
