import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getChannelEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Channel');
  if (!endpoint) throw Error('No Channel endpoint.');
  return endpoint;
};


const create = (model) => axios.post(getChannelEndpoint(), model)
  .then((response) => response?.data);

const list = ({ perPage = 10, page = 0, q = '', sortBy = '', order = '' } = {}) =>
  axios
    .get(
      `${getChannelEndpoint()}/Search/${perPage}/${page}?q=${q}${sortBy &&
        '&sortBy='}${sortBy}${order && '&order='}${order}`
    )
    .then((response) => response?.data);

const show = (id) => axios.get(`${getChannelEndpoint()}/${id}`)
  .then((response) => response?.data);

const edit = (model) => axios.put(`${getChannelEndpoint()}/${model.id}`, model)
  .then((response) => response?.data);

const copy = (id) => axios.post(`${getChannelEndpoint()}/Copy/${id}`)
  .then((res) => res?.data);

const destroy = (brand, { id }) => axios.delete(`${getChannelEndpoint()}/${id}`);

export { create, list, show, edit, copy, destroy };

export default {
  create,
  list,
  show,
  edit,
  copy,
  destroy
};
