import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  MenuItem,
  FormHelperText,
  Checkbox,
  Switch,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Visibility,
  Collections,
  DoubleArrow,
  Colorize,
  Star,
  StarOutline,
  ViewList,
} from '@material-ui/icons';
import {
  goBackOrStay,
  getNavigationBreadcrumbsPath,
} from 'helpers/common';
import ImageCroppingModal from 'components/ImageCropping/CroppingModal';
import CatalogContent from '../components/CatalogContent';
import DesignProps from '../../components/DesignProps';
import PropsForm from '../../components/PropsForm';
import VisibilityForm from '../components/VisibilityForm';
import ActionsForm from '../../components/ActionsForm';
import catalogServiceV3 from 'services/catalogServiceV3';
import {
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
} from 'services/collectionsServiceV2';
import libraryEnumsService from 'services/libraryEnumsService';
import libraryPropertyService from 'services/libraryPropertyService';
import {
  setCatalogTypes,
  setBreadCrumbsLinks,
  setVisibilityGroups,
} from 'store/actions/libraryEnumsAction';
import NormalTextField from 'styles/inputs/textfields';
import navigationAction from 'store/actions/navigationAction';
import {
  getBreadcrumbsLinks,
  getParentName,
  getParentId,
  getCatalogTypes,
  getVisibilitesGroups,
} from 'store/reducers/libraryEnumsReducer';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';

import Box from '@material-ui/core/Box';
import EditorInfo from 'components/EditorInfo';
import FileUpload from 'components/FileUpload';
import Loader from 'components/Loader';
import Tabbing from 'components/Tabbing';
import ThemedButton from 'components/ThemedButton';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import SectionContainer from 'components/SectionContainer';
import isEmpty from 'lodash/isEmpty';
import TagsTab from '../components/TagsTab';
import { DatePicker } from '@material-ui/pickers';
import { utcToLocalTimeZone } from 'utils/timezoneDate';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  }
}));

// used in order to toggle items tab based on selected dataSource
const enabledRelations = ['content', 'customer', 'sku'];

const ManualCatalogsForm = (props) => {
  const classes = useStyles();
  const { id, data, enqueueSnackbar, isAutomatic, setIsAutomatic } = props;
  const location = useLocation();
  const tab = location.hash?.substring(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const ownId = useSelector((state) => state.auth.user.id);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.catalog;
  const breadcrumbsLinks = useSelector(getBreadcrumbsLinks);
  const visibilitiesGroups = useSelector(getVisibilitesGroups);
  const parentName = useSelector(getParentName);
  const parentId = useSelector(getParentId);

  const [model, setModel] = useState({
    id: id || undefined,
    kind: 'Manual',
    index: 0,
    availabilityFrom: null,
    availabilityTo: null,
    type: 'Folder',
    contentType: 'Content',
    dataSource: 'Content',
    parentId: parentId,
    imageUrl: null,
    protected: false,
    displayRules: [],
    actions: [],
    filterRules: [],
    orderRules: [],
    paging: null,
    name: '',
    enabled: false,
    properties: [],
    cacheable: '',
    cacheControlInSeconds: 0,
    labels: {
      Category: [],
      Tag: [],
      Place: [],
      Location: [],
      Album: [],
      Keyword: [],
      Genre: [],
      Person: [],
      Mood: [],
    },
  });

  const [isFavourite, setIsFavourite] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [contentFormats, setContentFormats] = useState([]);
  const [isParentOptionsLoading, setIsParentOptionsLoading] = useState(true);
  const [libraryPropertyEnums, setLibraryPropertyEnums] = useState([]);
  const [isEnumsTypeLoading, setIsEnumsTypeLoading] = useState([]);
  const [cardValue, setCardValue] = useState('');
  const [bigScreenCardValue, setBigScreenCardValue] = useState('');
  const [mobileCardValue, setMobileCardValue] = useState('');
  const [mobileWebCardValue, setMobileWebCardValue] = useState('');
  const [desktopCardValue, setDesktopCardValue] = useState('');
  const [shortInfoValue, setShortInfoValue] = useState('');
  const [pageSizeChecked, setPageSizeChecked] = useState(false);
  const [contentTitle, setContentTitle] = useState('');
  const [defaultParentValue, setDefaultParentValue] = useState([
    { label: parentName, value: parentId },
  ]);
  const [isDataSourceOptionsLoading, setIsDataSourceOptionsLoading] = useState(false);
  const [dataSourceOptions, setDataSourceOptions] = useState([]);

  const addToFavourites = () => {
    addCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = () => {
    deleteCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    switch (isFavourite) {
      case true:
        removeFromFavourite(id);
        break;
      case false:
        addToFavourites(id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!id) {
      setModel({ ...model });
      return;
    }
    getCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(true))
      .catch(() => setIsFavourite(false));
  }, []);

  useEffect(() => {
    fetchVisibilityGroups();
  }, []);

  useEffect(() => {
    const getData = async () => {
      dispatch(navigationAction.setPageTitle(data.name));
      setIsLoading(true);
      try {
        const generalData = { ...data };
        const manualData = await catalogServiceV3.get(id);
        const modelMerge = { ...generalData, ...manualData };
        const actions = modelMerge.actions.map((action) => {
          if (action.schedule == null) {
            action.schedule = { type: 'None', value: '' };
          }
          return action;
        });
        modelMerge.actions = actions;
        setModel(modelMerge);
        if (data.paging != null) {
          setPageSizeChecked(true);
        } else {
          setPageSizeChecked(false);
        }
        const cardProp = data.properties?.filter((pr) => pr.name === 'Content:CardSize');
        const bigScreenCardSizeProp = data.properties?.filter(
          (pr) => pr.name === 'Content:BigScreenCardSize'
        );
        const mobileCardSizeProp = data.properties?.filter(
          (pr) => pr.name === 'Content:MobileCardSize'
        );
        const mobileWebCardSizeProp = data.properties?.filter(
          (pr) => pr.name === 'Content:MobileWebCardSize'
        );
        const desktopCardSizeProp = data.properties?.filter(
          (pr) => pr.name === 'Content:DesktopCardSize'
        );
        const shortInfoProp = data.properties?.filter((pr) => pr.name === 'Carousel:ShortInfo');
        const contentTitleProp = data.properties?.filter((pr) => pr.name === 'Content:Title');

        if (
          cardProp &&
          bigScreenCardSizeProp &&
          mobileCardSizeProp &&
          mobileWebCardSizeProp &&
          desktopCardSizeProp &&
          shortInfoProp &&
          contentTitleProp
        ) {
          if (cardProp && cardProp.length != 0) {
            setCardValue(cardProp[0].value);
          } else {
            setCardValue('');
          }
          if (bigScreenCardSizeProp.length != 0) {
            setBigScreenCardValue(bigScreenCardSizeProp[0].value);
          } else {
            setBigScreenCardValue('');
          }
          if (desktopCardSizeProp.length != 0) {
            setDesktopCardValue(desktopCardSizeProp[0].value);
          } else {
            setDesktopCardValue('');
          }
          if (mobileCardSizeProp.length != 0) {
            setMobileCardValue(mobileCardSizeProp[0].value);
          } else {
            setMobileCardValue('');
          }
          if (mobileWebCardSizeProp.length != 0) {
            setMobileWebCardValue(mobileWebCardSizeProp[0].value);
          } else {
            setMobileWebCardValue('');
          }
          if (shortInfoProp.length != 0) {
            setShortInfoValue(shortInfoProp[0].value);
          } else {
            setShortInfoValue('');
          }
          if (contentTitleProp.length != 0) {
            setContentTitle(contentTitleProp[0].value);
          } else {
            setContentTitle('');
          }
        }
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CATALOG_DATA' }),
          }),
        });
        setIsLoading(false);
      }
    };
    if (id) {
      getData();
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG' }) })
        )
      );
    }
    return () => {
      dispatch(navigationAction.setPageTitle(''));
      dispatch(setBreadCrumbsLinks([]));
    };
  }, []);

  useEffect(() => {
    const getParentOptions = async () => {
      try {
        const { totalCount: catalogTC } = await catalogServiceV3.search(
          {},
          1,
          0
        );
        const { data: catalogData } = await catalogServiceV3.search(
          {},
          catalogTC,
          0
        );
        setDefaultParentValue([
          { label: lookUp({ key: 'CONSOLE_NONE' }), value: '' },
          ...catalogData.map((p) => ({ label: p.name, value: p.id })),
        ]);
        setIsParentOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_PARENT' }),
          }),
        });
      }
    };
    getParentOptions();
  }, []);

  useEffect(() => {
    const getDataSourceOptions = async () => {
      setIsDataSourceOptionsLoading(true);

      try {

        const response = await catalogServiceV3.dataSourcesEnum();
        setDataSourceOptions(
          response.map(value => ({
            label: lookUp({ key: `CONSOLE_${value}`.toUpperCase() }),
            value,
          }))
        );
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_DATA_SOURCES' }),
          }),
          error,
        });
      } finally {
        setIsDataSourceOptionsLoading(false);
      }
    };

    getDataSourceOptions();
  }, []);

  useEffect(() => {
    const getLibraryPropertyEnums = async () => {
      setIsEnumsTypeLoading(true);
      try {
        let options = await libraryPropertyService.getPropertyEnums();
        let enumsTypes = options['Content:CardSize'];
        setLibraryPropertyEnums(enumsTypes.map((o) => ({ label: o, value: o })));
        setIsEnumsTypeLoading(false);
      } catch (error) {
        console.error(error);
        setIsEnumsTypeLoading(false);
      }
    };
    getLibraryPropertyEnums();
  }, []);

  useEffect(() => {
    if (!breadcrumbsLinks?.length) {
      visibilitiesGroups.length && breadBuilder(id);
    }
  }, [visibilitiesGroups]);

  const fetchVisibilityGroups = async () => {
    const emptyVisiG = visibilitiesGroups.length === 0;
    const options = emptyVisiG
      ? await catalogServiceV3.search({ np: true })
      : visibilitiesGroups;
    emptyVisiG && dispatch(setVisibilityGroups(options));
  };

  const makeFinalCrumbsSequence = (breads, vGroupInd) => {
    const vGroup = visibilitiesGroups[vGroupInd];
    breads.push({
      text: vGroup.name,
      link: `/apps/catalogs-v3/${vGroup.id}`,
      isCurrent: false,
      order: null,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs-v3',
      isCurrent: false,
      order: null,
    });
    breads.reverse();
    const lastInd = breads?.length - 1;
    breads.push({
      text: lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: lookUp({ key: 'CONSOLE_CATALOGS' }) }),
      link: `/apps/catalogs-v3/${id}/edit`,
      isCurrent: true,
      order: null,
    });
    breads.forEach((bread, index) => (bread.order = index + 1));
    dispatch(setBreadCrumbsLinks(breads));
  };

  const makeCrumbOfCreate = () => {
    const breads = [];
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs-v3',
      isCurrent: false,
      order: 1,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG' }) }),
      link: '/apps/catalogs-v3/create',
      isCurrent: true,
      order: 2,
    });
    dispatch(setBreadCrumbsLinks(breads));
  };

  const breadBuilder = (parentId, breads = []) => {
    if (parentId === undefined) return makeCrumbOfCreate();
    const vGroupInd = visibilitiesGroups.findIndex((vGroup) => vGroup.id === parentId);
    if (vGroupInd > -1) {
      makeFinalCrumbsSequence(breads, vGroupInd);
    } else if (parentId) {
      catalogServiceV3.get(parentId).then((response) => {
        breads.push({
          text: response.name,
          link: `/apps/catalogs-v3/${response.id}`,
          isCurrent: false,
          order: null,
        });
        breadBuilder(response.parentId, breads);
      });
    } else {
      return;
    }
  };

  const goBackCrumbLink = (crumb) => {
    const breads = breadcrumbsLinks.filter((bcLink) => bcLink.order <= crumb.order);
    breads[breads.length - 1].isCurrent = true;
    dispatch(setBreadCrumbsLinks(breads));
  };

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePageSizeChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      paging: {
        ...prev.paging,
        [key]: value,
      },
    }));
  };

  const handleCardSizePropChange = (key, value) => {
    setCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleDesktopCardPropChange = (key, value) => {
    setDesktopCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleBigScreenCardPropChange = (key, value) => {
    setBigScreenCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleMobileCardPropChange = (key, value) => {
    setMobileCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleMobileWebCardPropChange = (key, value) => {
    setMobileWebCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleContentTitlePropChange = (key, value) => {
    setContentTitle(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleShortInfoPropChange = (key, value) => {
    setShortInfoValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleChange = (event) => {
    let saveModel = { ...model, paging: pageSizeChecked ? null : { pageSize: 0 } };
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setPageSizeChecked(event.target.checked);
    setModel(saveModel);
  };

  const setNewCollection = (newCollection) => {
    setModel((model) => {
      model.contentCollection = newCollection;
      return model;
    });
  };

  // Updates data source
  const handleContentSourceChange = e => {
    e.preventDefault();

    const { value } = e.target;
    if (!value) {
      return;
    }

    setModel(modelState => ({
      ...modelState,
      dataSource: value,
    }));
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {
      const copyModel = { ...model };
      const actions = copyModel.actions.map((action) => {
        if (action.schedule && action.schedule.type === 'None') {
          action.schedule = null;
        }
        return action;
      });
      copyModel.actions = actions;
      const saveModel = JSON.parse(JSON.stringify(copyModel));
      if (publish) {
        saveModel.enabled = !model.enabled;
      }

      id
        ? await catalogServiceV3.edit(id, saveModel)
        : await catalogServiceV3.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
      });
      goBackOrStay(
        navigate,
        model.parentId ? `/apps/catalogs-v3/${model.parentId}` : '/apps/catalogs-v3',
      );
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const deleteCatalog = async () => {
    setIsDeleting(true);
    try {
      await catalogServiceV3.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      dispatch(setBreadCrumbsLinks([]));
      setTimeout(() => {
        if (parentId && parentId !== id) {
          navigate(`/apps/catalogs-v3/${parentId}`);
        } else {
          navigate('/apps/catalogs-v3');
        }
      }, 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', type: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  const getLocalizedDateString = (value) => {
    if (!value) {
      return;
    }

    return utcToLocalTimeZone({
      date: value,
      asISO: true,
    });
  };

  const tabs = [
    {
      name: 'basic',
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_POSITION_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title={lookUp({ key: 'CONSOLE_TYPE' })}>
              <Switch
                checked={!isAutomatic}
                onChange={() => setIsAutomatic((isAutomatic) => !isAutomatic)}
                color={'primary'}
                disabled={!!id}
              />
            </Tooltip>
            {lookUp({ key: 'CONSOLE_MANUAL_CATALOG' })}
          </Grid>
          <Grid item xs={12}>
            {isParentOptionsLoading ? (
              <Loader inline />
            ) : (
              <>
                <TextField
                  label={lookUp({ key: 'CONSOLE_PARENT' })}
                  placeholder={parentName}
                  onChange={({ target }) => handleModelChange('parentId', target.value)}
                  value={model.parentId}
                  select
                  fullWidth
                >
                  {defaultParentValue.map((e) => (
                    <MenuItem key={e.value} value={e.value}>
                      {e.label}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText style={{ marginLeft: '14px' }}>Pick parent item</FormHelperText>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <NormalTextField
              label={lookUp({ key: 'CONSOLE_CATALOG_POSITION' })}
              helperText={lookUp({ key: 'CONSOLE_CATALOG_POSITION_HELPERTEXT' })}
              required
              type="number"
              value={model.index || ''}
              onChange={(e) => handleModelChange('index', e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_NAME_AND_DESCRIPTION' })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <NormalTextField
              label={lookUp({ key: 'CONSOLE_REFERENCE_NAME' })}
              required
              value={model.name || ''}
              onChange={(e) => handleModelChange('name', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_CONTENTTITLE' })}
              placeholder={lookUp({ key: 'CONSOLE_CONTENT_TITLE_HELPERTEXT' })}
              value={contentTitle || ''}
              onChange={(e) => handleContentTitlePropChange('Content:Title', e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_SHORTINFO' })}
              multiline
              minRows={3}
              helperText={lookUp({ key: 'CONSOLE_CATALOG_SHORTINFO_HELPERTEXT' })}
              placeholder={lookUp({ key: 'CONSOLE_CATALOG_SHORTINFO_HELPERTEXT' })}
              value={shortInfoValue || ''}
              onChange={(e) => handleShortInfoPropChange('Carousel:ShortInfo', e.target.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_SETTINGS_HEADING' })}
            </Typography>
          </Grid>

          {model.id && (
            <Grid item xs={12}>
              <NormalTextField label={lookUp({ key: 'CONSOLE_ID' })} value={model.id} disabled />
            </Grid>
          )}

          <Grid item xs={12}>
            {/* <DatePicker */}
          </Grid>

          <Grid item xs={12}>
            {isDataSourceOptionsLoading ? (
              <Loader inline />
            ) : (
              <TextField
                disabled={dataSourceOptions.length < 1}
                label={lookUp({ key: 'CONSOLE_SOURCE' })}
                placeholder={lookUp({ key: 'CONSOLE_SOURCE_PLACEHOLDER' })}
                helperText={lookUp({ key: 'CONSOLE_SOURCE_HELPERTEXT' })}
                onChange={handleContentSourceChange}
                value={model.dataSource}
                fullWidth
                select
              >
                {dataSourceOptions.map((option) => (
                  <MenuItem key={`${option.value}-${option.label}`} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Grid>

          <Grid item xs={12}>
            <TextField
              type="number"
              label={lookUp({ key: 'CONSOLE_CACHE_CONTROL' })}
              helperText={lookUp({ key: 'CONSOLE_CACHE_CONTROL_HELPERTEXT' })}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={model.cacheControlInSeconds}
              name="cacheControlInSeconds"
              onChange={(e) => {
                e.preventDefault();
                handleModelChange('cacheControlInSeconds', e.target.value);
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <>
              <Checkbox
                checked={pageSizeChecked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              {lookUp({ key: 'CONSOLE_Limit_Catalog_items' })}
            </>
          </Grid>
          <Grid item xs={2}>
            {pageSizeChecked && (
              <TextField
                label={lookUp({ key: 'CONSOLE_PAGESIZE' })}
                type="number"
                helperText={lookUp({ key: 'CONSOLE_PAGESIZE_HELPERTEXT_TEMPLATE', number: 20 })}
                fullWidth
                value={model.paging ? model.paging.pageSize : null}
                onChange={(e) => handlePageSizeChange('pageSize', e.target.value)}
              />
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_AVAILABILITY_HEADING' })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_AVAILABILITY_FROM' })}
                  type="datetime-local"
                  helperText={lookUp({ key: 'CONSOLE_AVAILABILITY_FROM_HELPERTEXT' })}
                  className={classes.fullWidth}
                  value={getLocalizedDateString(model.availabilityFrom)}
                  onChange={(e) => handleModelChange('availabilityFrom', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    step: 1,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_AVAILABILITY_TO' })}
                  type="datetime-local"
                  helperText={lookUp({ key: 'CONSOLE_AVAILABILITY_TO_HELPERTEXT' })}
                  className={classes.fullWidth}
                  value={getLocalizedDateString(model.availabilityTo)}
                  onChange={(e) => handleModelChange('availabilityTo', e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    step: 1,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    },
    enabledRelations.includes(model.dataSource?.toLowerCase?.()) && {
      name: 'content',
      icon: <Collections />,
      content:
        !isEmpty(model).name && !isEmpty(model.entityType) ? (
          <CatalogContent model={model} />
        ) : null,
    },
    {
      name: 'design',
      icon: <Colorize />,
      content: (
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_CARD_SIZE_TITLE' })}
              </Typography>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                label={lookUp({ key: 'CONSOLE_GENERAL' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={cardValue}
                onChange={({ target }) =>
                  handleCardSizePropChange('Content:CardSize', target.value)
                }
                fullWidth
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>Size of Catalog items</FormHelperText>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled={!libraryPropertyEnums?.length}
                label={lookUp({ key: 'CONSOLE_DESKTOP' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={desktopCardValue}
                onChange={({ target }) =>
                  handleDesktopCardPropChange('Content:DesktopCardSize', target.value)
                }
                select
                fullWidth
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_desktop_Catalog_helpertext' })}
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled={!libraryPropertyEnums?.length}
                label={lookUp({ key: 'CONSOLE_BIG_SCREEN' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={bigScreenCardValue}
                onChange={({ target }) =>
                  handleBigScreenCardPropChange('Content:BigScreenCardSize', target.value)
                }
                select
                fullWidth
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_BIGSCREEN_Catalog_helpertext' })}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled={!libraryPropertyEnums?.length}
                label={lookUp({ key: 'CONSOLE_MOBILEPHONE' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={mobileCardValue}
                onChange={({ target }) =>
                  handleMobileCardPropChange('Content:MobileCardSize', target.value)
                }
                select
                fullWidth
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_MOBILECARD_Catalog_helpertext' })}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <TextField
                disabled={!libraryPropertyEnums?.length}
                label={lookUp({ key: 'CONSOLE_MOBILEWEB' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={mobileWebCardValue}
                onChange={({ target }) =>
                  handleMobileWebCardPropChange('Content:MobileWebCardSize', target.value)
                }
                select
                fullWidth
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_MOBILEWEB_Catalog_helpertext' })}
              </FormHelperText>
            </Grid>
          </Grid>
          <DesignProps
            properties={model.properties}
            handleChange={(data) => handleModelChange('properties', data)}
          />

          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_IMAGE_TITLE' })}
            </Typography>
          </Grid>
          {model.type === 'Folder' && (
            <Grid item xs={12}>
              <FileUpload
                fileUrl={model.imageUrl || ''}
                handleChange={(url) => handleModelChange('imageUrl', url)}
              />
            </Grid>
          )}
          {model.imageUrl && (
            <Grid item xs={12}>
              <ImageCroppingModal
                fileUrl={model.imageUrl}
                handleChange={(url) => handleModelChange('imageUrl', url)}
                aspectRatio={16 / 9}
                width={1210}
                height={720}
              />
            </Grid>
          )}
        </>
      ),
    },
    {
      name: 'tags',
      content: (
        <TagsTab
          model={model}
          handleChange={handleModelChange}
          setIsTagCloudLoading={props.setIsTagCloudLoading}
          isTagCloudLoading={props.isTagCloudLoading}
          selectedRelation={props.selectedRelation}
          setSelectedRelation={props.setSelectedRelation}
          setTargetType={props.setTargetType}
          triggers={props.triggers}
          openSelector={props.openSelector}
        />
      ),
    },
    {
      name: 'visibility',
      icon: <Visibility />,
      content: (
        <VisibilityForm
          handleChange={(data) => handleModelChange('displayRules', data)}
          displayRules={model.displayRules}
        />
      ),
    },
    {
      name: 'properties',
      content: (
        <PropsForm
          properties={model.properties}
          handleChange={(data) => handleModelChange('properties', data)}
        />
      ),
    },
    {
      name: 'actions',
      icon: <DoubleArrow />,
      content: (
        <ActionsForm
          actions={model.actions}
          handleChange={(data) => handleModelChange('actions', data)}
        />
      ),
    },
  ];

  const buttons = {
    favourite: !allowedTabs?.includes('favorite')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_FAVOURITE' }),
          action: setFavourite,
          icon: isFavourite ? (
            <Tooltip title={lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' })}>
              <Star color="secondary" />
            </Tooltip>
          ) : (
            <Tooltip title={lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' })}>
              <StarOutline />
            </Tooltip>
          ),
        },
    logs: !allowedTabs?.includes('logs')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_LOGS_TAB' }),
          link: `/access/logs/Catalog/${model.id}`,
          icon: <ViewList />,
        },
  };

  return isLoading ? (
    <Loader />
  ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <Box flex={1}>
      <HeaderActionsContainer>
        {
          // Publish action button
          // only render publish/unpublish if the asset already exists
          id && (
            <ThemedButton
              color={model.enabled ? 'secondary' : 'success'}
              disabled={isPublishing || isSaving || isDeleting}
              onClick={e => {
                e.preventDefault();
                saveData(true);
              }}
              loading={isPublishing}
            >
              {lookUp({ key: model.enabled ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON' })}
            </ThemedButton>
          )
        }
        {
          // Delete action button
          id && (
            <DeleteButton onDelete={deleteCatalog} loading={isDeleting} disabled={isDeleting || isSaving || isPublishing} />
          )
        }
        <Box flex={1} />
        {/* Cancel action Button */}
        <ThemedButton
          onClick={e => {
            e.preventDefault();
            dispatch(navigationAction.allowNavigation());
            if (model.parentId) {
              navigate(`/apps/catalogs-v3/${model.parentId}`);
            } else {
              navigate('/apps/catalogs-v3');
            }
          }}
          disabled={isSaving || isPublishing || isDeleting}
        >
          {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
        </ThemedButton>

        {/* Save action Button */}
        <ThemedButton
          color="success"
          onClick={e => {
            e.preventDefault();
            saveData(false);
          }}
          disabled={isSaving || isPublishing || isDeleting}
          loading={isSaving}
        >
          {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
        </ThemedButton>
      </HeaderActionsContainer>
      
      <SectionContainer flex={1}>
        <Tabbing
          // FIXME replace tabs with props left and right in order to render into Toolbar edges('start', 'end')
          tabs={tabs
            .sort(
              (a, b) =>
                allowedTabs.indexOf(a.name?.replace(' ', '-')) -
                allowedTabs.indexOf(b.name?.replace(' ', '-'))
            )
            .filter((tab) => allowedTabs?.includes(tab.name))}
          tab={tab}
          addTopMargin={HEADER_MIN_HEIGHT}
          buttons={[buttons.favourite, buttons.logs]}
          scrollButtons="on"
        />
        
        {id && (
          <EditorInfo
            createdAt={model.createdDate}
            modifiedAt={model.lastModifiedDate}
            modifiedBy={model.lastModifiedBy}
          />
        )}
      </SectionContainer>
    </Box>
  );
}

ManualCatalogsForm.propTypes = {
  history: PropTypes.object.isRequired,
  id: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
  isAutomatic: PropTypes.bool,
  setIsAutomatic: PropTypes.func.isRequired,
};

export default withSnackbar(ManualCatalogsForm);
