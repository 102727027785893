import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dropzone from 'components/Dropzone';
import ThemedButton from 'components/ThemedButton';
import Dialog from 'components/Dialog';
import UploadProgress from 'components/UploadProgress';
import audioHelper from 'helpers/audio';
import videoHelper from 'helpers/video';
import jobManagerService from 'services/jobManagerService';
import parametersService from 'services/parametersService';
import parametersAction from 'store/actions/parametersAction';
import AlertService from 'services/alertService';
import { lookUp } from 'services/stringService';
import { ingest, ingestAsset } from 'services/contentService';
import customersService from 'services/customersService';
import { addContentRelation } from 'services/collectionsServiceV2';

const VIDEO_WITH_SOUND = 'VIDEO_WITH_SOUND';
const useStyles = makeStyles((theme) => ({
  // TOOD create Files upload stack component
  filesListContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    '& > *': {
      boxShadow: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderRadius: 0,
      '&:first-of-type': {
        borderTop: 'none',
      },
    }
  },
  hightlightedText: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    padding: theme.spacing(1),
  },
}));

function IngestFile({
  upload,
  enqueueSnackbar,
  setResultsFromServer,
  contentId,
  isPublic=false,
  dropdown=true,
  onUploadStatusChange=()=>{},
  fullWidthContent=false,
  isSubtitleFile=false,
  setAssetReqParams,
  parentContentType='',
  parentLocale,
}) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [videoForUploading, setVideoForUploading] = useState([]);
  const [isDropZoneActive, setIsDropZoneActive] = useState(false);
  const [isNameDuplicate, setIsNameDuplicate] = useState(false);
  const [currentFile, setCurrentFile] = useState({});
  const [currentFileName, setCurrentFileName] = useState('');
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState('empty_workflow_slot');
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const [genres, setGenres] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(null);

  const dispatch = useDispatch();

  const isInheritLOLang = useSelector((state) => state.parameters.isInheritLOLang);
  const isInheritPLLang = useSelector((state) => state.parameters.isInheritPLLang);
  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);

  const selectOriginalLanguage = () => {
    switch (parentContentType) {
      case 'Playlist':
        return isInheritPLLang.on ? parentLocale : defaultLocale.lang;
      case 'Remix':
        return isInheritLOLang.on ? parentLocale : defaultLocale.lang;
      case ('Subtitle'):
        return parentLocale;
      default:
        return defaultLocale.lang;
    };
  };

  const onUploadProgress = (id) => (event) => {
    setFiles((prev) =>
      prev.map((f) =>
        f.id === id ? { ...f, progress: Math.round((event.loaded * 100) / event.total) } : f
      )
    );
  };

  const onFileIngestStart = (id) => {
    setIsUploading(true);
    setFiles((prev) => prev.map((f) => (f.id === id ? { ...f, isIngestLoading: true } : f)));
  };

  const onFileIngestEnd = (id) => {
    setFiles((prev) => prev.map((f) => (f.id === id ? { ...f, isIngestLoading: false } : f)));
  };

  const onFileError = (id, error) => {
    setFiles((prev) => prev.map((f) => (f.id === id ? { ...f, error: error } : f)));
    setIsUploading(false);
  };

  const uploadWithS3 = async ({ file, id }) =>
    await upload({
      file,
      assetType: 'Content',
      onUploadProgress: onUploadProgress(id),
      onFileIngestStart: onFileIngestStart(id),
      onFileIngestEnd: onFileIngestEnd(id),
      isPublic,
  });

  const uploadImage = async ({ file, id }) => {
    try {
      const data = await uploadWithS3({ file, id });
      const lang = selectOriginalLanguage();
      let title = data.key;
      const fileNameWithCapitals = data.originalFileName?.replaceAll('_', ' ')
        .split(' ')
        .map((word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)))
        .join(' ') ;
      if (typeof fileNameWithCapitals === 'string' && fileNameWithCapitals?.length) {
        const dotIndex = fileNameWithCapitals.lastIndexOf('.');
        title = fileNameWithCapitals.slice(0, dotIndex);
      }

      if (!contentId) {
        return await ingest({
          ...data,
          workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
          originalTitle: title,
          originalLanguage: lang,
          localizations: {
            [lang]: {
              name: title,
            }
          },
          type: 'Image',
          originalFileName: data.originalFileName,
          exhibitionWindow: {
            '--': {
              advisoryCodes: data.advisoryCodes,
              ageLimit: data.ageLimit,
              availableFrom: data.availableFrom?.toISOString &&
                data.availableFrom.toISOString(),
              availableUntil: data.availableUntil?.toISOString &&
                data.availableUntil.toISOString(),
            }
          },
          allowMinting: true,
          allowRemix: true,
          allowComments: true,
          labels: {
            Genre: genres,
            Category: categories,
          },
        });
      } else {
        return await ingestAsset({
          ...data,
          type: 'Image',
          contentId: contentId,
          isPublic,
          originalLanguage: lang,
          locale: lang,
        })
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' }),
      });
      onFileError(id, error.message || `Couldn't upload file`);
      onFileIngestEnd(id);
      return await Promise.reject(error);
    }
  };

  const uploadFile = async ({ file, id }) => {
    try {
      const data = await uploadWithS3({ file, id });
      if (isSubtitleFile && setAssetReqParams) setAssetReqParams((reqData) => [...reqData, data]);
      let type = 'File';
      let subType = undefined;
      const extension = file.name.split('.').pop();
      const lang = selectOriginalLanguage();
      let title = data.key;
      const fileNameWithCapitals = data.originalFileName?.replaceAll('_', ' ')
        .split(' ')
        .map((word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)))
        .join(' ') ;
      if (typeof fileNameWithCapitals === 'string' && fileNameWithCapitals?.length) {
        const dotIndex = fileNameWithCapitals.lastIndexOf('.');
        title = fileNameWithCapitals.slice(0, dotIndex);
      }

      switch (extension.toLowerCase()) {
        case 'sol':
          type = 'SmartContract';
          break;
        case 'srt':
          type = 'Text';
          subType = 'SubtitleSrt'
          break;
        case 'vtt':
          type = 'Text';
          subType = 'SubtitleVtt'
          break;
        case 'dfxp':
          type = 'Text';
          subType = 'SubtitleDFXP'
          break;
      }

      switch (file.type) {
        case 'application/pdf':
          type = 'PDF';
          break;
        case 'text/plain':
          type = 'Text';
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          type = 'Word';
          break;
        default:
          break;
      }
      if (!contentId) {
        return await ingest({
          ...data,
          workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
          type,
          originalTitle: title,
          originalLanguage: lang,
          localizations: {
            [lang]: {
              name: title,
            }
          },
          originalFileName: data.originalFileName,
          exhibitionWindow: {
            '--': {
              advisoryCodes: data.advisoryCodes,
              ageLimit: data.ageLimit,
              availableFrom: data.availableFrom?.toISOString &&
                data.availableFrom?.toISOString(),
              availableUntil: data.availableUntil?.toISOString &&
                data.availableUntil.toISOString(),
            }
          },
          allowMinting: true,
          allowRemix: true,
          allowComments: true,
          labels: {
            Genre: genres,
            Category: categories,
          },
        });
      } else {
        return await ingestAsset({
          ...data,
          type,
          subType,
          contentId: contentId,
          isPublic,
          originalLanguage: lang,
          locale: lang,
        })
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' }),
      });
      onFileError(id, error.message || `Couldn't upload file`);
      onFileIngestEnd(id);
      return await Promise.reject(error);
    }
  };

  const uploadVideo = async ({ file, id }) => {
    let rest = { type: 'Video', hasAudio: true, hasVideo: true };
    let duration = 0;
    try {
      duration = parseInt(await videoHelper.getDuration(file), 10) * 1000;
    } catch (error) {
      enqueueSnackbar('Duration was set 0', {
        variant: 'warning',
        autoHideDuration: 5000,
      });
    };

    try {
      const s3Data = await uploadWithS3({ file, id });
      const lang = selectOriginalLanguage();
      let title = s3Data.key;
      const fileNameWithCapitals = s3Data.originalFileName?.replaceAll('_', ' ')
        .split(' ')
        .map((word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)))
        .join(' ') ;
      if (typeof fileNameWithCapitals === 'string' && fileNameWithCapitals?.length) {
        const dotIndex = fileNameWithCapitals.lastIndexOf('.');
        title = fileNameWithCapitals.slice(0, dotIndex);
      }
      if (!contentId) {
        return await ingest({
          ...s3Data,
          duration,
          type: 'Video',
          workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
          originalTitle: title,
          originalLanguage: lang,
          localizations: {
            [lang]: {
              name: title,
            }
          },
          originalFileName: s3Data.originalFileName,
          exhibitionWindow: {
            '--': {
              advisoryCodes: s3Data.advisoryCodes,
              ageLimit: s3Data.ageLimit,
              availableFrom: s3Data.availableFrom?.toISOString &&
                s3Data.availableFrom.toISOString(),
              availableUntil: s3Data.availableUntil?.toISOString &&
                s3Data.availableUntil.toISOString(),
            }
          },
          allowMinting: true,
          allowRemix: true,
          allowComments: true,
          labels: {
            Genre: genres,
            Category: categories,
          },
        });
      } else {
        return await ingestAsset({
          ...s3Data,
          ...rest,
          duration,
          workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
          contentId: contentId,
          isPublic,
          originalLanguage: lang,
          locale: lang,
        });
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' }),
      });
      onFileError(id, error.message || `Couldn't upload file`);
      onFileIngestEnd(id);
      return await Promise.reject(error);
    }
  };

  const uploadAudio = async ({ file, id }) => {
    let duration = 0;
    try {
      duration = parseInt(await audioHelper.getDuration(file), 10) * 1000;
    } catch (error) {
      enqueueSnackbar('Duration was set 0', {
        variant: 'warning',
        autoHideDuration: 5000,
      });
    }
    try {
      const s3Data = await uploadWithS3({ file, id });
      const lang = selectOriginalLanguage();
      let title = s3Data.key;
      const fileNameWithCapitals = s3Data.originalFileName?.replaceAll('_', ' ')
        .split(' ')
        .map((word) => word.slice(0, 1).toUpperCase().concat(word.slice(1)))
        .join(' ') ;
      if (typeof fileNameWithCapitals === 'string' && fileNameWithCapitals?.length) {
        const dotIndex = fileNameWithCapitals.lastIndexOf('.');
        title = fileNameWithCapitals.slice(0, dotIndex);
      }
      if (!contentId) {
        return await ingest({
          ...s3Data,
          workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
          originalTitle: s3Data.key,
          type: 'Audio',
          originalTitle: title,
          originalLanguage: lang,
          localizations: {
            [lang]: {
              name: title,
            }
          },
          originalFileName: s3Data.originalFileName,
          exhibitionWindow: {
            '--': {
              advisoryCodes: s3Data.advisoryCodes,
              ageLimit: s3Data.ageLimit,
              availableFrom: s3Data.availableFrom?.toISOString
                && s3Data.availableFrom.toISOString(),
              availableUntil: s3Data.availableUntil?.toISOString
                && s3Data.availableUntil.toISOString(),
            }
          },
          allowMinting: true,
          allowRemix: true,
          allowComments: true,
          labels: {
            Genre: genres,
            Category: categories,
          },
        });
      } else {
          return await ingestAsset({
            ...s3Data,
            album,
            artists,
            duration,
            workflowId: (selectedWorkflow === 'empty_workflow_slot') ? undefined : selectedWorkflow,
            contentId: contentId,
            // releaseDate: year, -- Ingest wants a date string.
            hasAudio: true,
            hasVideo: false,
            isPublic,
            originalLanguage: lang,
            locale: lang,
          });
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_FILE_UPLOAD_ERROR' }),
      });
      onFileError(id, error.message || `Couldn't upload file`);
      onFileIngestEnd(id);
      return await Promise.reject(error);
    }
  };

  const handleSuccessUpload = async (file, result) => {
    setFiles((prev) => prev.filter((prevF) => prevF.id !== file.id));
    if (!contentId) {
      setResultsFromServer((prev) => [...prev, result.data]);
    } else {
      setResultsFromServer((prev) => [result.data.assets[result.data?.assets?.length - 1]]);
    }

    try {
      // Ingest content to project
      await Promise.all(
        selectedProjects.map((selectedProject) =>
          addContentRelation(result.data.id, 'Project', selectedProject.id),
        ),
      );
    } catch {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        message: `${file.file.name || result?.data?.originalFileName || ''} ${lookUp({
          key: 'CONSOLE_PROJECT_ASSIGN_ERROR',
        })}`,
      });
    }
    AlertService.displaySuccess({
      msgBar: enqueueSnackbar,
      message: `${file.file.name || result?.data?.originalFileName || ''} ${lookUp({
        key: 'CONSOLE_UPLOADED',
      })}`,
    });
    return result;
  };

  const handleVideoTypeChoose = (video, value) => {
    setVideoForUploading(videoForUploading.filter((v) => v.id !== video.id));
    setIsNameDuplicate(false);
    uploadVideo({
      ...video,
      hasVideo: true,
      hasAudio: value === VIDEO_WITH_SOUND,
    }).then((result) => {
      setIsUploading(false);
      handleSuccessUpload(video, result);
    });
    setCurrentFile({});
  };

  const onDrop = async (newFiles, rejectedFiles) => {
    if (isSubtitleFile) {
      const subtFileExtensions = ['srt', 'vtt', 'dfxp'];
      const areProperSubtFiles = newFiles.every((file) => {
        const lastDotIndex = file.name.lastIndexOf('.');
        if (lastDotIndex > -1) {
          return subtFileExtensions.includes((file.name?.slice(lastDotIndex+1)?.toLowerCase()));
        } else {
          return false;
        }
      });
      if (!areProperSubtFiles) {
        AlertService.displayWarning({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_NOT_PROPER_SUBTITLE_EXTENSION' })
        });
        return;
      }
    }

    if (rejectedFiles && rejectedFiles.length) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error: 'File type not acceptable',
      });
    }

    const filesWithId = newFiles.map((f) => ({
      file: new File([f], f.name.replace(/\[|\]/g, ''), { type: f.type }),
      id: uuid(),
    }));

    setIsDropZoneActive(false);
    setFiles((prev) => [
      ...prev,
      ...filesWithId.map((f) => ({
        id: f.id,
        name: f.file.name,
        preview: f.file.preview,
        progress: 0,
        isIngestLoading: true,
      })),
    ]);

    const videoForUploadingQueue = [];

    Promise.all(
      filesWithId.map((f) => {
        let upload;
        Object.assign(f, { format: f.file.type.split('/')[0] });
        switch (f.format) {
          case 'audio':
            upload = uploadAudio;
            break;
          case 'video':
            return handleVideoTypeChoose(f, 'VIDEO WITH SOUND');
          case 'image':
            upload = uploadImage;
            break;
          default:
            upload = uploadFile;
            break;
        }
        return upload(f).then((result) => {
          setIsUploading(false);
          handleSuccessUpload(f, result)
        });
      })
    ).catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    });

    if (videoForUploadingQueue.length) {
      setVideoForUploading(videoForUploadingQueue);
    }
  };

  const onDropZoneEnter = () => setIsDropZoneActive(true);

  const onDropZoneLeave = () => setIsDropZoneActive(false);

  const cancelUpload = (id) => {
    setCurrentFile({});
    setCurrentFileName('');
    setIsNameDuplicate(false);
    setFiles(files.filter((video) => video.id !== id));
    setVideoForUploading(videoForUploading.filter((video) => video.id !== id));
  };

  useEffect(() => {
    jobManagerService
      .searchWfTemplates()
      .then((response) => {
        const workflows = [{ id: 'empty_workflow_slot', name: '—' }].concat(response?.data);
        setWorkflows(workflows);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    customersService
      .searchQuery({ type: 'Project' }, 25, 0)
      .then((response) => {
        const projects = response?.data?.reduce((result, project) => {
          if (project.customerId && project.username) {
            result.push({ id: project.customerId, name: project.username });
          }

          return result;
        }, []);
        setProjects(projects);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (isUploading !== null) {
      onUploadStatusChange?.(isUploading);
    }
  }, [isUploading]);

  useEffect(() => {
    const getIsInheritPLLang = async () => {
      try {
        const response = await parametersService.getByKey('Console:Content:Inherit:PL:Lang');
        const on = response?.value?.toLowerCase ? response.value.toLowerCase() === 'true' : false;
        dispatch(parametersAction.setInheritPLLang({ on, paramChecked: true }));
      } catch (error) {
        dispatch(parametersAction.setInheritPLLang({ ...isInheritPLLang, paramChecked: true }));
      }
    };

    if (!isInheritPLLang.paramChecked) getIsInheritPLLang();
  }, [isInheritPLLang]);


  useEffect(() => {
    const getIsInheritLOLang = async () => {
      try {
        const response = await parametersService.getByKey('Console:Content:Inherit:LO:Lang');
        const on = response?.value?.toLowerCase ? response.value.toLowerCase() === 'true' : false;
        dispatch(parametersAction.setInheritLOLang({ on, paramChecked: true }));
      } catch (error) {
        dispatch(parametersAction.setInheritLOLang({ ...isInheritLOLang, paramChecked: true }));
      }
    };

    if (!isInheritLOLang.paramChecked) getIsInheritLOLang();
  }, [isInheritLOLang]);

  useEffect(() => {
    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) getDefaultLocaleLang();
  }, [defaultLocale]);

  return (
    <Box flex={1}>
      <Grid direction="column" container spacing={dropdown ? 1 : 0}>
        {dropdown && workflows?.length > 0 && (
          <>
            <Grid item md={fullWidthContent ? 12 : 4} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_WORKFLOWS' })}
                  aria-describedby="workflows-helper"
                  value={selectedWorkflow}
                  onChange={(e) => setSelectedWorkflow(e.target.value)}
                  size="small"
                  select
                >
                  {workflows.map?.((workflow = {}) => (
                    <MenuItem key={workflow.id} value={workflow.id}>
                      {workflow.name}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText id="workflows-helper">
                  {lookUp({ key: 'CONSOLE_WORKFLOWS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={fullWidthContent ? 12 : 4} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={projects}
                  value={selectedProjects}
                  name="selectedProject"
                  getOptionLabel={(option) => option.name}
                  aria-describedby="selected-project-helper"
                  onChange={(e, value) => setSelectedProjects(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={lookUp({ key: 'CONSOLE_PROJECTS' })}
                    />
                  )}
                  renderTags={(options, getTagProps) =>
                    options.map?.((option, index) => (
                      <Chip
                        key={option.id}
                        label={option.name}
                        className={getTagProps(index)?.className}
                        onDelete={() =>
                          setSelectedProjects(
                            selectedProjects.filter((project) => project.id !== option?.id),
                          )
                        }
                        size="small"
                      />
                    ))
                  }
                  multiple
                />

                <FormHelperText id="projects-helper">
                  {lookUp({ key: 'CONSOLE_PROJECTS_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={fullWidthContent ? 12 : 8} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={[]}
                  value={genres}
                  name="genre"
                  aria-describedby="genre-helper"
                  onChange={(e, value) => setGenres(value)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label={lookUp({ key: 'CONSOLE_GENRE' })} />
                  )}
                  renderTags={(options, getTagProps) =>
                    options.map?.((option, index) => (
                      <Chip
                        key={option}
                        label={option}
                        className={getTagProps(index)?.className}
                        onDelete={() => setGenres([...genres].filter((genre) => genre !== option))}
                        size="small"
                      />
                    ))
                  }
                  multiple
                  freeSolo
                  filterSelectedOptions
                />
                <FormHelperText id="genre-helper">
                  {lookUp({ key: 'CONSOLE_GENRE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={fullWidthContent ? 12 : 8} xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={[]}
                  value={categories}
                  name="category"
                  aria-describedby="category-helper"
                  onChange={(e, value) => setCategories(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label={lookUp({ key: 'CONSOLE_CATEGORY' })}
                    />
                  )}
                  renderTags={(options, getTagProps) =>
                    options.map((option, index) => (
                      <Chip
                        key={option}
                        label={option}
                        className={getTagProps(index)?.className}
                        onDelete={() =>
                          setCategories([...categories].filter((category) => category !== option))
                        }
                        size="small"
                      />
                    ))
                  }
                  multiple
                  freeSolo
                  filterSelectedOptions
                />
                <FormHelperText id="category-helper">
                  {lookUp({ key: 'CONSOLE_CATEGORY_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Box flex={1}>
            <Dropzone
              isActive={isDropZoneActive}
              onDrop={onDrop}
              onDragEnter={onDropZoneEnter}
              onDragLeave={onDropZoneLeave}
            />
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.filesListContainer}>
          {files.map?.((file) => (
            <Card key={file.id}>
              <CardContent>
                <Typography variant="body2" noWrap gutterBottom>
                  {file.name}
                </Typography>
                {file.isIngestLoading && !file.progress ? (
                  <UploadProgress />
                ) : (
                  <UploadProgress value={file.progress} withIndicator />
                )}
                {file.error && <Typography color="error">{file.error}</Typography>}
              </CardContent>
            </Card>
          ))}
        </Grid>
        {isNameDuplicate && (
          <Dialog
            title={lookUp({ key: 'CONSOLE_FILE_EXISTS_TEXT' })}
            actions={
              <>
                <ThemedButton onClick={(e) => cancelUpload(currentFile.id)} disabled={isUploading}>
                  {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
                </ThemedButton>
                <ThemedButton
                  color="success"
                  onClick={(e) => handleVideoTypeChoose(currentFile, 'VIDEO WITH SOUND')}
                  disabled={isUploading}
                  loading={isUploading}
                >
                  {lookUp({ key: 'CONSOLE_UPLOAD_BUTTON' })}
                </ThemedButton>
              </>
            }
            maxWidth="sm"
            isConfirm
            fullWidth
            open
          >
            {currentFileName && (
              <Typography className={classes.hightlightedText}>{currentFileName}</Typography>
            )}
          </Dialog>
        )}
      </Grid>
    </Box>
  );
}

IngestFile.propTypes = {
  upload: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  setResultsFromServer: PropTypes.func.isRequired,
  contentId: PropTypes.string.isRequired,
  isPublic: PropTypes.bool,
  dropdown: PropTypes.bool,
  onUploadStatusChange: PropTypes.func,
  fullWidthContent: PropTypes.bool,
};

export default IngestFile;
