import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  spacing: {
    height: (props) => theme.spacing(props.height),
  },
}));

const Spacing = ({ height = 1 }) => {
  const classes = useStyles({ height });
  return (<div className={classes.spacing} />);
};

export default Spacing;
