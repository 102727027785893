import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import ShakaPlayer from 'shaka-player-react';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ViewListIcon from '@material-ui/icons/ViewList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import StarIcon from '@material-ui/icons/Star';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import ListIcon from '@material-ui/icons/List';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import { withSnackbar } from 'notistack';
import InitDialog from './InitDialog';
import BasicInfo from './BasicInfo';
import ImportDialog from './ImportDialog';
import Chapters from './Chapters';
import ChildContents from './ChildContents';
import Tracks from './Tracks';
import WelcomeRemix from './WelcomeRemix';
import MarkerBar from '../common/MarkerBar';
import TrackOrderSelector from './TrackOrderSelector';
import { getConsoleApiEndpoint } from 'services/apiEndpoints';
import parametersService from 'services/parametersService';
import contentService from 'services/contentService';
import markerService from 'services/markerService';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import {
  batchUpdate,
  getContentRelation,
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
} from 'services/collectionsServiceV2';
import { getProjectLanguages } from 'services/stringService';
import { msToTime, getMs } from 'helpers/time';
import { getTokenFromCookies } from 'helpers/common';
import { sidebarWidth } from 'constants/index.js';
import navigationAction from 'store/actions/navigationAction';
import contentAction from 'store/actions/contentAction';
import parametersAction from 'store/actions/parametersAction';
import ThemedButton from 'components/ThemedButton';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import Dialog from 'components/Dialog';
import 'shaka-player/dist/controls.css';


// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;
const PLAYER_DEFAULT_HEIGHT = 420;
const DEFAULT_TAB = 'remixItems';

// values to secondary area, accessible from left side group at Tabs. 'basic' is at right side button group.
// NOTE these are tabs, should refactor in order to use <Tabbing /> reusable component
const CONTROLS = ['remixItems', 'chapters', 'secondaryVid'];

// FIXME cleanup styles, use reusable components
const useStyles = makeStyles((theme) => ({
  appBar: (props) => ({
    top: HEADER_MIN_HEIGHT * 2,
    width: `calc(100% - ${props.collapsed ? sidebarWidth.closed : sidebarWidth.open}px)`,
  }),
  tabbingContainer: {
    // FIXME should normalize ALL sections who apply padding based on existing toolbars
    paddingTop: HEADER_MIN_HEIGHT / 2,
  },
  contentContainer: {
    minHeight: (props) => props.panelHeight,
  },
  rowsUnderPlayer: {
    marginTop: theme.spacing(1),
  },
  secondaryAreaContainer: {
    width: '100%',
    height: (props) => props.panelHeight || PLAYER_DEFAULT_HEIGHT,
    minHeight: (props) => props.panelHeight || PLAYER_DEFAULT_HEIGHT,
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: (props) => theme.spacing(props.isSecondaryPlayerActive ? .5 : 2),
  },
  playerContainer: {
    minHeight: (props) => props.panelHeight || PLAYER_DEFAULT_HEIGHT,
    height: '100%',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playerContainerInvalidHolder: {
    minHeight: (props) => props.panelHeight || PLAYER_DEFAULT_HEIGHT,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    color: theme.palette.divider,
    background: theme.palette.grey[50],
    border: `4px dashed ${theme.palette.grey[200]}`,
  },
  shakaContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    background: 'black',
  },
  secondaryArea: {
    minHeight: (props) => props.panelHeight || PLAYER_DEFAULT_HEIGHT,
    height: '100%',
  },
  welcomeScreen: {
    overflow: 'hidden',
  },
  tracksHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function RemixEditor({
  id,
  history,
  enqueueSnackbar,
}) {
  const [qString] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.user.role);
  const contentType = useSelector((state) => state.content.contentType);
  const contentData = useSelector((state) => state.content.contentData);
  const ownId = useSelector((state) => state.auth.user.id);
  const { editor } = useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editor?.remix;
  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);
  const scrollTrigger = useScrollTrigger();

  const [isFavorite, setIsFavorite] = useState(false);
  const [projectLanguages, setProjectLanguages] = useState([]);
  const [childContents, setChildContents] = useState([]);
  const [mainVideoUrl, setMainVideoUrl] = useState('');
  const [authVidUrl, setAuthVidUrl] = useState('');
  const [shakaPlayerRef, setShakaPlayerRef] = useState(null);
  const [secondaryVidUrl, setSecondaryVidUrl] = useState('');
  const [authSecondVidUrl, setAuthSecondVidUrl] = useState('');
  const [secondaryPlayerRef, setSecondaryPlayerRef] = useState(null);
  const [secondaryTrigger, setSecondaryTrigger] = useState(false);
  const [loadChildContentsTrigger, setLoadChildContentsTrigger] = useState(false);
  const [panelHeight, setPanelHeight] = useState(0);
  const [panelWidth, setPanelWidth] = useState(0);
  const [chapters, setChapters] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [finalizeWorkflowId, setFinalizeWorkflowId] = useState(undefined);
  const [control, setControl] = useState(DEFAULT_TAB);
  // const [saveTimeout, setSaveTimeout] = useState(undefined);
  const [langCodes, setLangCodes] = useState([]);
  const [trackOrderSelectorOpen, setTrackOrderSelectorOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  // FIXME refactor in order to use reusable <Tabbing /> component
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);

  const classes = useStyles({
    collapsed: isSidebarCollapsed,
    isSecondaryPlayerActive: control === 'secondaryVid',
    panelHeight
  });

  const isFirstRun = useRef(true);
  const timelineRef = useRef(null);
  const isFirstLoad = useRef(true);
  const saveTimeout = useRef();
  const tempPanelWidthStore = useRef(PLAYER_DEFAULT_HEIGHT);

  const tabIcons = {
    basic: <WebAssetIcon />,
    remixItems: <ListIcon />,
    chapters: <SubscriptionsIcon />,
    secondaryVid: <FeaturedVideoIcon />,
  };

  const [ingestModalOpen, setIngestModalOpen] = useState(undefined);
  const [duration, setDuration] = useState(undefined);

  const videoSettingsParseBEResponse = ['', 'Pause', 'Loop', 'Blackout', 'Hide'];
  const audioSettingsParseBEResponse = ['', 'On', 'Off', 'OverridableOff', 'VolumeLevel'];

  const tracksSort = tracks => tracks.sort(
    (trA, trB) => {
      const areProperNumbers = typeof trA.relationData?.index === 'number' && typeof trB.relationData?.index === 'number';
      return areProperNumbers ? trA.relationData.index - trB.relationData.index : 0;
    });

  const addToFavourites = () => {
    addCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavorite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = () => {
    deleteCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavorite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    switch (isFavorite) {
      case true:
        removeFromFavourite(id);
        break;
      case false:
        addToFavourites(id);
        break;
      default:
        break;
    }
  };

  // set the size of the breakpoint panel and the canvas responsively
  const shakaCallbackRef = useCallback((shakaPlayer) => {
    if (shakaPlayer && shakaPlayer.videoElement) {
      shakaPlayer.videoElement.volume = 0.6;
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry?.target?.id === 'Remix_Shaka-video_playerContainer') {
            if (document.fullscreenElement === null) setPanelWidth(entry.target.offsetWidth);
          } else if (entry?.target?.tagName?.toLowerCase() === 'video') {
            setPanelHeight(entry.target.offsetHeight);
          }
        }
      });
      resizeObs.observe(shakaPlayer.videoElement);
      resizeObs.observe(shakaPlayer.videoElement.parentElement.parentElement);
    }
    setShakaPlayerRef(shakaPlayer);
  }, []);

  const getCurrentTime = () => shakaPlayerRef?.videoElement?.currentTime;

  const secondaryVidCallback = useCallback((shakaPlayer) => {
    if (shakaPlayer && shakaPlayer.videoElement) {
      shakaPlayer.videoElement.volume = 0.6;
      setSecondaryPlayerRef(shakaPlayer);
    }
  }, []);

  const seek = (value = '') => {
    shakaPlayerRef.videoElement.currentTime = getMs(value) / 1000;
  };

  const setMainTrack = (mainId, updateDuration = false) => {
    if (!mainId) {
      const mainTrack = tracks.find((e) => e.main);
      mainTrack.clips?.forEach((e) => {
        markerService.remove(e.id);
      });
      setTracks([]);
      return;
    }

    setTracks((trx) => trx
      .map((tr, index) => ({
        ...tr,
        main: tr.contentId === mainId,
        index,
        clips: [...(tr.clips || [])]?.map((c) => {
          const newClip = {
            ...c,
            track: {
              ...c.track,
              main: tr.contentId === mainId,
              index,
            },
          };
          markerService.edit(clipToMarker({ ...tr, main: tr.contentId === mainId }, newClip));
          return newClip;
        }),
      }))
    );
    if (updateDuration) {
      const lastClip = [...(tracks?.find((e) => e?.contentId === mainId)?.clips || [])]
        ?.sort((a, b) => a.position - b.position)
        ?.pop();
      if (!lastClip) {
        setDuration(
          getAssetProperties(childContents.find((a) => a?.contentId === mainId).assets)?.duration
        );
        return;
      }
      setDuration(Math.floor(lastClip.start + lastClip.duration));
    }
  };

  const deleteTrack = (contentId) => {
    const track = tracks.find((e) => e?.contentId === contentId);
    track.clips?.forEach((clip) => markerService.remove(clip.id));
    setTracks((trx) => trx.filter((e) => e?.contentId !== contentId));
  };

  const deleteAllChapters = async () => {
    if (!chapters || !chapters.length > 0) return;
    const deletionHappened = await Promise.allSettled(
      chapters?.map((chapter) => markerService.remove(chapter.id))
    );
    return deletionHappened;
  };

  // const overwriteChapters = async (assetId) => {
  //   const assetChapters = childContents.find(
  //     (e) => e?.contentId === assetId || e.id === assetId
  //   )?.chapters;
  //   if (!assetChapters) return;
  //   deleteAllChapters().then(async () => {
  //     const newChapters = await Promise.all(
  //       assetChapters.map(async (e) => await markerService.create({ ...e, contentId: id }))
  //     );
  //     setChapters(newChapters);
  //   });
  // };

  const clipToMarker = (tr, clip) => {
    if (!tr || !clip) return {};
    return {
      type: 'Clip',
      contentId: id,
      title: clip.title,
      description: clip.description,
      main: clip.main,
      id: clip.id,
      cueIn: msToTime(clip.cueIn),
      cueOut: msToTime(clip.cueOut),
      position: msToTime(clip.start),
      duration: msToTime(clip.duration),
      behaviour: {
        videoSettings: clip.behavior || 1,
        audioSettings: clip.audio || 1,
        volumeLevel: clip.volumeLevel || 90,
      },
      track: {
        contentId: tr?.contentId,
        main: tr.main,
        index: tr.index,
      },
    };
  };

  const markerToClip = (marker) => {
    return {
      ...marker,
      contentId: marker.track?.contentId,
      id: marker.id,
      cueIn: getMs(marker.cueIn),
      cueOut: getMs(marker.cueOut),
      start: getMs(marker.position),
      end: getMs(marker.end),
      duration: getMs(marker.duration),
      main: marker.main,
      behavior: videoSettingsParseBEResponse.indexOf(marker.behaviour?.videoSettings) || 1,
      audio: audioSettingsParseBEResponse.indexOf(marker.behaviour?.audioSettings) || 1,
      volumeLevel: marker.behaviour?.volumeLevel || 70,
    };
  };

  const clipsToTracks = (clips) => {
    if (!clips.length) return;

    // no multiple mains: if markers have contradictions, first one is main
    let maintrackFound = false; 

    const trx = clips.reduce((tracksObj, clip, idx) => {
      const { contentId, main } = clip.track || {};
      const { clips } = tracksObj[clip.track?.contentId] || {};
      tracksObj[clip.track?.contentId] = {
        ...(tracksObj[clip.track?.contentId] || {}),
        contentId,
        main: main && (!maintrackFound || maintrackFound === contentId),
        lock: false,
        index: idx,
        clips: [...(clips || []), clip],
      };
      if (main && maintrackFound && maintrackFound !== contentId) {
        // correct clashing data on BE right away
        markerService.edit(clipToMarker({ ...clip.track, main: false }, clip));
      }
      if (main) maintrackFound = clip.track.contentId;
      return tracksObj;
    }, {});
    return Object.values(trx);
  };

  const getAssetProperties = (assets) => {
    if (!assets) return {};

    const videoAssets = [...assets].filter(
      (e) => e.workflowStatus === 'Succeeded' && e.type === 'Video'
    );
    const audioAssets = [...assets].filter(
      (e) => e.workflowStatus === 'Succeeded' && e.type === 'Audio'
    );
    if (!videoAssets && !audioAssets) return {};
    const video = videoAssets?.find((asset) => asset.subType === 'Original');
    const audio = audioAssets?.find((asset) => asset.subType === 'Original');

    return {
      videoUrl: video?.downloadUrl || '',
      audioUrl: audio?.downloadUrl,
      duration: video?.duration || 0,
      processingStatus: video?.duration || audio?.duration,
    };
  };

  const associateAssetInfo = (tr) => {
    if (!childContents.length) return;
    
    const content = [...childContents].find((e) => e?.contentId === tr?.contentId);
    const { originalTitle, relationData } = content || {};
    const { videoUrl, audioUrl, duration, processingStatus } = getAssetProperties(content?.assets);
    
    if (contentType === 'Remix' && !mainVideoUrl && tr.main) {
      setMainVideoUrl(videoUrl || audioUrl);
    }

    return {
      ...tr,
      originalTitle,
      processingStatus,
      videoUrl,
      duration,
      relationData,
    };
  };

  const confirmTrackOrderChange = ({ items, closeModal, mainId }) => {
    const remixAssets = {};
    if (contentType === 'Remix') {
      const itemIds = items.map((item) => item.contentId);
      remixAssets.ordered = items.reduce((ordered, item) => {
        const content = childContents.find((asset) => asset.contentId === item.contentId);
        ordered.push(content);
        return ordered;
      }, []);
      remixAssets.notInItems = childContents.filter((asset) => !itemIds.includes(asset.contentId));
      remixAssets.newOrder = [ ...remixAssets.ordered, ...remixAssets.notInItems ];
    }
    const updatedRelations = contentType === 'Playlist'
      ? items.map((item, index) => ({ ...item.relationData, index }))
      : remixAssets.newOrder.map((item, index) => ({ ...item.relationData, index }));

    const updateRel = (updRel, item, i) => ({ ...item, relationData: { ...updRel[i] }});

    batchUpdate(updatedRelations)
      .then(() => {
        if (contentType === 'Remix') {
          setTracks(items.map((item, i) => updateRel(updatedRelations, item, i)));
          setChildContents(remixAssets.newOrder.map((item, i) => updateRel(updatedRelations, item, i)));
          setMainTrack(mainId);
        } else {
          setChildContents(items.map((item, i) => updateRel(updatedRelations, item, i)));
        }
      })
      .catch((error) => {
         AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_ASSETS' }),
            }),
         })
      })
      .finally(() => closeModal());
  };

  useEffect(() => {
    setTimelineWidth(timelineRef.current?.getBoundingClientRect().width);
  }, [duration]);

  useEffect(() => {
    setTracks(clipsToTracks([...markers.map((m) => markerToClip(m))]));
  }, [markers]);

  useEffect(() => {
    if (!tracks?.length) return;
    setTracks((trs) => tracksSort(trs.map((tr) => associateAssetInfo(tr))));
  }, [childContents]);

  const getContentDetails = async (relationData) => {
    const allDetails = await Promise.allSettled(
      relationData?.map(async (cntRel) => {
        const details = await contentService.getById(cntRel.targetId);
        const {
          id,
          assets,
          originalTitle,
          type,
          processingStatus,
          labels,
        } = details;

        // Get the Chapters of the child content
        const markerS = {
          chapters: [],
          idOrToken: id,
          finished: false,
        };
        try {
          while (!markerS.finished) {
            const queryParams = { type: 'Chapter', itemLimit: 200 };
            const reqResp = await markerService.search(markerS.idOrToken, queryParams);
            if (reqResp?.pageContent?.length > 0) {
              markerS.chapters = markerS.chapters.concat(reqResp.pageContent);
            }
            markerS.idOrToken = reqResp?.pagingToken;
            markerS.finished = reqResp?.finished;
          }
        } catch (error) {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: `CONSOLE_MARKER` }),
            }),
          });
        } finally {
          const detailed = {
            contentId: id,
            assets,
            originalTitle,
            processingStatus,
            labels,
            type,
            duration: getAssetProperties(assets)?.duration,
            chapters: markerS.chapters,
            relationData: { ...cntRel }
          };
          return detailed;
        }
      })
    );
    return allDetails;
  };

  const getChildContents = async () => {
    if (!id) {
      setControl('welcome');
      return;
    }

    if (contentType === 'Remix') {
      // Get the Chapters of the Remix (parent) content
      const markerS = {
        chapters: [],
        idOrToken: id,
        finished: false,
      };
      try {
        while (!markerS.finished) {
          const queryParams = { type: 'Chapter', itemLimit: 200 };
          const reqResp = await markerService.search(markerS.idOrToken, queryParams);
          if (reqResp?.pageContent?.length > 0) {
            markerS.chapters = markerS.chapters.concat(reqResp.pageContent);
          }
          markerS.idOrToken = reqResp?.pagingToken;
          markerS.finished = reqResp?.finished;
          if (markerS.finished && markerS.chapters.length > 0) {
            setChapters(markerS.chapters);
          }
        }
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_MARKER` }),
          }),
        });
      }
    }

    dispatch(navigationAction.setPageTitle(contentData?.originalTitle));
    setDuration(contentData?.duration || null);

    try {
      const contentsRelationData = (await getContentRelation(id, 'Playlist'))?.pageContent;
      const decoratedChildContents =  await getContentDetails(contentsRelationData);
      const sortedChildContents = tracksSort(
        decoratedChildContents
          .filter((content) => !!content?.value)
          .map((content) => {
            const elem = content.value;
            const { videoUrl, audioUrl, duration } = getAssetProperties(elem.assets);
            return { ...elem, videoUrl, audioUrl, duration };
          })
      );

      if (!sortedChildContents.length) setControl('welcome');
      if (contentType === 'Playlist' && sortedChildContents[0]?.videoUrl) {
        setMainVideoUrl(sortedChildContents[0]?.videoUrl);
      } 
      setChildContents(sortedChildContents);
      isFirstRun.current = false;
    } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_CONTENT` }),
          }),
        });
    }
  };

  useEffect(() => {
    try {
      getChildContents();
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_ASSETS' }),
        }),
      });
    }

    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id, loadChildContentsTrigger]);

  useEffect(() => {
    if (Object.keys(langCodes).length > 0) return;

    jobManagerService
      .getLanguageCodes('Translate')
      .then((langs) => {
        const sortedRevLangCodes = Object.entries(langs).sort((a, b) => {
          if (a[1] < b[1]) return -1;
          else if (a[1] > b[1]) return 1;
          return 0;
        });
        const langCodesMap = sortedRevLangCodes.reduce((lCodes, keyValue) => {
          lCodes[keyValue[0]] = keyValue[1];
          return lCodes;
        }, {});
        const langCodes = Object.keys(langCodesMap);
        const browserDefaultLanguage = navigator.languages.find((langCode) =>
          langCodes.includes(langCode)
        );
        setLangCodes(langCodes);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_LANGUAGES' }),
          }),
        });
      });
  }, []);

  const saveWithDebounce = (model, delay) => {
    clearTimeout(saveTimeout.current);
    saveTimeout.current = setTimeout(() => contentService.edit(model), delay);
  };

  const finalize = () => {
    if (!tracks?.find((t) => t.main)) {
      AlertService.displayWarning({
        msgBar: enqueueSnackbar,
        message: lookUp({
          key: 'CONSOLE_REMIX_NO_MAIN_TRACK_ERROR',
        }),
      });
      return;
    }

    setIsSaving(true);

    jobManagerService
      .runWorkflow({
        workflowTemplateId: finalizeWorkflowId,
        name: contentData.originalTitle,
        referenceObjectId: contentData.id,
        referenceObjectName: contentData.originalTitle,
        ownerId: contentData.ownerId,
      })
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_REMIX_FINALIZE_LAUNCH_MESSAGE' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMIX_FINALIZE_ERROR' }),
        });
      })
      .finally(() => setIsSaving(false));
  };

  const disablePublish = () => {
    return contentType === 'Playlist'
      ? !contentData?.published && childContents.length < 1
      : !contentData?.published && !finalizeWorkflowId;
  };

  const publish = () => {
    if (contentType === 'Remix') {
      finalize();
    } else {
      setIsPublishing(true);
      contentService
        .edit({ ...contentData, published: true })
        .then(() => {
          dispatch(contentAction.setContentData({ ...contentData, published: true }));
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE',
              title: contentData.originalTitle || lookUp({ key: 'CONSOLE_PLAYLIST' }),
            }),
          });
        })
        .catch(() =>
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_FAIL_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_PUBLISH' }),
            }),
          })
        )
        .finally(() => setIsPublishing(false));
    }
  };

  const unpublish = () => {
    setIsPublishing(true);

    contentService
      .edit({ ...contentData, published: false })
      .then((e) => {
        dispatch(contentAction.setContentData({ ...contentData, published: false }));

        const title = contentType === 'Remix'
          ? lookUp({ key: 'CONSOLE_REMIX' })
          : lookUp({ key: 'CONSOLE_PLAYLIST' })
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE',
            title: contentData.originalTitle || title,
          }),
        });
      })
      .catch(() =>
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_FAIL_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_UNPUBLISH' }),
          }),
        })
      )
      .finally(() => setIsPublishing(false));
  };

  const getFinalizeWorkflowId = () => {
    parametersService.getByKey('Finalize:Workflow:Id')
      .then((k) => setFinalizeWorkflowId(JSON.parse(k.value)[contentData?.originalLanguage]))
      .catch(() => setFinalizeWorkflowId(undefined));
  };

  useEffect(() => {
    if (!id) return;
    const getClipMarkers = async () => {
      const markerS = {
        clips: [],
        idOrToken: id,
        finished: false,
      };

      try {
        while (!markerS.finished) {
          const queryParams = { type: 'Clip', itemLimit: 1000 };
          const reqResp = await markerService.search(markerS.idOrToken, queryParams);
          if (reqResp?.pageContent?.length > 0) {
            markerS.clips = markerS.clips.concat(reqResp.pageContent);
          }
          markerS.idOrToken = reqResp?.pagingToken;
          markerS.finished = reqResp?.finished;
          if (markerS.finished && markerS.clips.length > 0) setMarkers(markerS.clips);
        }
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_MARKER` }),
          }),
        });
      }
    };
    getClipMarkers();
  }, [id]);

  useEffect(() => {
    if (!duration) return;
    contentService.edit({ ...contentData, duration: Math.floor(duration) });
  }, [duration]);

  useEffect(() => {
    // fallback: if no LO duration has been saved, set it to main track's asset's duration.
    if (!!duration) return;
    const mainTrackContentId = tracks?.find((e) => e?.main)?.contentId;
    if (!mainTrackContentId) return;
    const assetDuration = childContents?.find((e) => e?.contentId === mainTrackContentId)?.duration;
    if (!assetDuration) return;
    setDuration(assetDuration);
  }, [tracks, childContents]);

  useEffect(() => {
    if (!id) return;
    getCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavorite(true))
      .catch(() => setIsFavorite(false));
  }, [id]);

  useEffect(() => {
    getFinalizeWorkflowId();
  }, []);

  useEffect(() => {
    if (!secondaryVidUrl) return;
      setControl('secondaryVid');
  }, [secondaryVidUrl, secondaryTrigger]);

  useEffect(() => {
    if (projectLanguages.length) return;
    const getLangs = async () => {
      const langs = await getProjectLanguages();
      setProjectLanguages(langs);
    };
    getLangs();
  }, []);

  useEffect(() => {
    const setAuth = () => {
      contentService.getPlaybackUrl(mainVideoUrl).then((u) => setAuthVidUrl(u));
    };
    if (mainVideoUrl) setAuth();
  }, [mainVideoUrl]);

  useEffect(() => {
    const setAuth = () => {
      contentService.getPlaybackUrl(secondaryVidUrl).then((u) => setAuthSecondVidUrl(u));
    };
    if (secondaryVidUrl) setAuth();
  }, [secondaryVidUrl]);

  useEffect(() => {
    !isFirstRun.current && saveWithDebounce(contentData, 500);
  }, [contentData]);

  useEffect(() => {
    getFinalizeWorkflowId();
  }, [contentData?.originalLanguage]);

  useEffect(() => {
    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) getDefaultLocaleLang();
  }, [defaultLocale]);

  const startRecording = () => {
    if (!id) return;
    contentService.createRecordingContent(
      contentData.id,
      `${contentData.originalTitle || 'Remix'} - Recording ${new Date(Date.now()).toLocaleString()}`,
      contentData?.originalLanguage || defaultLocale.lang
    )
      .then((res) => {
        const meetingId = res.data.properties['Content:Process:SessionId'];
        navigate({
          pathname: `/content/content-record/${meetingId}/stream-recording`,
          search: new URLSearchParams({ parentId: contentData.id, to: 'editor' }).toString(),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          autoHideDuration: 50000,
          context: lookUp({ key: 'CONSOLE_START_RECORDING_ERROR' }),
        });
      });
  };

  const isAudio = (type = 'main') => {
    if (type === 'main') return childContents.find((e) => e.videoUrl === mainVideoUrl)?.type === 'Audio';
    else return childContents.find((e) => e.videoUrl === secondaryVidUrl)?.type === 'Audio';
  };

  const makeRemixSelectorTracks = useCallback(() => {
    if (contentType === 'Remix') {
      return [...tracks].map((e, i) => ({
        ...e,
        originalTitle: childContents.find((a) => a.contentId === e.contentId)?.originalTitle || `Track ${i + 1}`,
      }));
    }
    return [];
  }, [tracks]);

  const drawPreview = (part) => {
    if (contentType === 'Remix') {
      if (part === 'label') {
        return tracks?.length
          ? lookUp({ key: 'CONSOLE_PREVIEW' })
          : lookUp({ key: 'CONSOLE_NO_PREVIEW' });
      } else if (part === 'icon') {
        return tracks?.length ? <VisibilityIcon /> : <VisibilityOffIcon />;
      }
    } else {
      if (part === 'label') {
        return childContents?.length
          ? lookUp({ key: 'CONSOLE_PREVIEW' })
          : lookUp({ key: 'CONSOLE_NO_PREVIEW' });
      } else if (part === 'icon') {
        return childContents?.length ? <VisibilityIcon /> : <VisibilityOffIcon />;
      }
    }
  };

  const getPreviewLink = () => {
    const lmsP = getConsoleApiEndpoint('Lms:Player');
    
    if (!lmsP) {
      return undefined;
    }

    const token = getTokenFromCookies();
    const href = encodeURIComponent(window.location?.href);
    const link = `${lmsP}/${id}?token=${token}&isPreview=true&returnUrl=${href}`;
    
    if ((contentType === 'Remix' && tracks?.length) || (contentType === 'Playlist' && childContents?.length)) {
      return link;
    } else {
      return undefined;
    }
  };

  // FIXME refactor in order to use reusable <Tabbing /> component
  const onTabChange = (newTab) => {
    switch (newTab) {
      case 'record':
        startRecording();
        break;
      case 'import':
        setIngestModalOpen(true);
        break;
      case 'favorite':
        setFavourite();
        break;
      case 'preview':
        let previewLink = getPreviewLink();

        if (previewLink && !`${previewLink}`.includes('undefined')) {
          window.location.assign(previewLink);
        }
        break;
      case 'metadata':
        navigate(`/content/${id}/edit#basic`);
        break;
      case 'logs':
        navigate(`/access/logs/Content/${id}`);
        break;
      default:
        // FIXME replace current setControl with new activeTab
        setControl(newTab);
        setActiveTab(newTab);
    }
  };

  // FIXME refactor in order to use reusable <Tabbing /> component
  const a11yProps = (tabId) => ({
    id: `tab-${tabId}`,
    'aria-controls': `tabpanel-${tabId}`,
  });

  // TODO tabs definition in order to use <Tabbing />
  const TABS = [
    [
      {
        name: 'record',
        label: lookUp({ key: `CONSOLE_RECORD` }),
        icon: <FiberManualRecordIcon />,
        content: (<>record</>),
      },
      {
        name: 'import',
        label: lookUp({ key: `CONSOLE_IMPORT_TAB` }),
        icon: <AddIcon />,
        content: (<>import</>),
      },
      {
        name: 'remixItems',
        label: lookUp({ key: `CONSOLE_REMIXITEMS_TAB` }),
        icon: <ListIcon />,
        content: (<>remixItems</>),
      },
      {
        name: 'chapters',
        label: lookUp({ key: `CONSOLE_CHAPTERS_TAB` }),
        icon: <SubscriptionsIcon />,
        content: (<>chapters</>),
      },
      {
        name: 'secondaryVid',
        label: lookUp({ key: `CONSOLE_SECONDARYVID_TAB` }),
        icon: <FeaturedVideoIcon />,
        content: (<>secondaryVid</>),
      },
    ],
    [
      {
        name: 'basic',
        label: lookUp({ key: `CONSOLE_BASIC_TAB` }),
        icon: <WebAssetIcon />,
        content: (
          <BasicInfo
            hideTitle={true}
            langCodes={langCodes}
          />
        ),
      },
      {
        name: 'preview',
        label: lookUp({ key: tracks?.length ? 'CONSOLE_PREVIEW' : 'CONSOLE_NO_PREVIEW' }),
        icon: tracks?.length ? <VisibilityIcon /> : <VisibilityOffIcon />,
        content: (<>preview</>),
      },
      {
        name: 'favorite',
        tooltip: lookUp({
          key: isFavorite
            ? 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT'
            : 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT',
        }),
        label: lookUp({ key: 'CONSOLE_FAVOURITE' }),
        icon: isFavorite ? <StarIcon color="secondary" /> : <StarOutlineIcon />,
        content: (<>favorite</>),
      },
      {
        name: 'metadata',
        label: lookUp({ key: `CONSOLE_METADATA_EDITOR` }),
        tooltip: lookUp({ key: 'CONSOLE_METADATA_EDITOR_HELPERTEXT' }),
        icon: <ListAltIcon />,
        content: (<>metadata</>),
      },
      {
        name: 'logs',
        label: lookUp({ key: `CONSOLE_LOGS` }),
        icon: <ViewListIcon />,
        content: (<>logs</>),
      },
    ]
  ];

  useEffect(() => {
    const elem = document.fullscreenElement;
    if (elem !== null && elem.className === 'shaka-video-container') {
      tempPanelWidthStore.current = panelWidth;
      setPanelWidth(elem.offsetWidth);
    } else if (!isFirstRun.current) {
      setPanelWidth(tempPanelWidthStore.current);
    }
  }, [document.fullscreenElement]);

  return (
    <Box flex={1}>
      <HeaderActionsContainer>
        <Box flex={1} />

        {/* Save action Button */}
        <ThemedButton
          color={contentData?.published ? 'secondary' : 'success'}
          disabled={disablePublish()}
          onClick={(e) => {
            e.preventDefault();
            contentData?.published ? unpublish() : publish();
          }}
          loading={isSaving || isPublishing}
        >
          {lookUp({
            key: contentData?.published
              ? 'CONSOLE_UNPUBLISH_BUTTON'
              : 'CONSOLE_PUBLISH_BUTTON',
          })}
        </ThemedButton>
      </HeaderActionsContainer>

      {/* TODO refactor in order to use the reusable <Tabbing /> starts */}
      <Box className={classes.tabbingContainer}>
        {/* FIXME replace with <Tabbging /> */}
        <Slide appear={false} direction="down" in={!scrollTrigger}>
          <AppBar position="fixed" className={classes.appBar} color="inherit">
            <Tabs
              className={classes.tabs}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
              // FIXME rename control to activeTab
              value={control}
              onChange={(e, newTab) => {
                e.preventDefault();
                onTabChange(newTab);
              }}
              aria-label="Remix Editor Controls"
            >
              <Tab
                value={'record'}
                label={lookUp({ key: `CONSOLE_RECORD` })}
                icon={<FiberManualRecordIcon />}
                {...a11yProps('record')}
              />
              <Tab
                value={'import'}
                label={lookUp({ key: `CONSOLE_IMPORT_TAB` })}
                icon={<AddIcon />}
                {...a11yProps('import')}
              />
              {CONTROLS.map((tabId) => (
                <Tab
                  value={tabId}
                  label={lookUp({ key: `CONSOLE_${tabId}_TAB` })}
                  icon={tabIcons[tabId] || <WebAssetIcon />}
                  {...a11yProps(tabId)}
                />
              ))}
              <Box flex={1} />
              <Tab
                value="basic"
                label={lookUp({ key: `CONSOLE_BASIC_TAB` })}
                icon={tabIcons['basic'] || <WebAssetIcon />}
                {...a11yProps('basic')}
              />

              {/* FIXME cleanup */}
              {allowedTabs.includes('preview') && (
                <Tooltip title={lookUp({ key: 'CONSOLE_PREVIEW' })}>
                  <Tab
                    value="preview"
                    disabled={!getPreviewLink()}
                    label={drawPreview('label')}
                    icon={drawPreview('icon')}
                    {...a11yProps('preview')}
                  />
                </Tooltip>
              )}
             {allowedTabs.includes('favorite') && (
                <Tooltip
                  title={lookUp({
                    key: isFavorite
                      ? 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT'
                      : 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT',
                  })}
                >
                  <Tab
                    value="favorite"
                    label={lookUp({ key: 'CONSOLE_FAVOURITE' })}
                    icon={isFavorite ? <StarIcon color="secondary" /> : <StarOutlineIcon />}
                    {...a11yProps('favorite')}
                  />
                </Tooltip>
              )}
              {allowedTabs.includes('metadata') && (
                <Tooltip title={lookUp({ key: 'CONSOLE_METADATA_EDITOR_HELPERTEXT' })}>
                  <Tab
                    value="metadata"
                    label={lookUp({ key: 'CONSOLE_METADATA_EDITOR' })}
                    icon={<ListAltIcon />}
                    {...a11yProps('metadata')}
                  />
                </Tooltip>
              )}
              {allowedTabs.includes('logs') && (
                <Tooltip title={lookUp({ key: 'CONSOLE_LOGS' })}>
                  <Tab
                    value="logs"
                    label={lookUp({ key: 'CONSOLE_LOGS' })}
                    icon={<ViewListIcon />}
                    {...a11yProps('logs')}
                  />
                </Tooltip>
              )}
            </Tabs>
          </AppBar>
        </Slide>
        {/* TODO CONTROLS should be renamed into TABS with the expected shape for <Tabbing /> */}
        {/* {CONTROLS.map((tab, index) => (
          <TabPanel value={activeTab} index={tab} key={`${tab}-${index}`}>
            {tab}
          </TabPanel>
        ))} */}

        <Grid container className={classes.contentContainer}> {/* Content Container ends TODO replace Grid container with <Tabbing />  */}
          {/* player area starts */}
          {control !== 'welcome' && (
            <Grid
              id="Remix_Shaka-video_playerContainer"
              item
              xs={control === 'secondaryVid' ? 6 : 7}
              className={classNames(classes.playerContainer, {
                [classes.shakaContainer]: control === 'secondaryVid',
              })}
            >
              {/* FIXME define main Loader logic, video player displays for a while while determining asset type, when asset is Audio, video player appears, then audio player appears */}
              {
                isAudio('main') ? (
                  <audio 
                    controls 
                    key={authVidUrl}
                    ref={shakaCallbackRef}
                    autoPlay={false}
                    onPlay={() => (isFirstLoad.current = false)}
                  >
                    <source src={authVidUrl} />
                  </audio>
                ) : (
                  <ShakaPlayer
                    src={authVidUrl}
                    style={{ width: panelWidth }}
                    ref={shakaCallbackRef}
                    autoPlay={false}
                    onPlay={() => (isFirstLoad.current = false)}
                  />
                )
              }
            </Grid>
          )}
          {/* player area ends */}

          {/* sub section below player start */}
          <Grid
            // FIXME reduce complexity, remove orignal nested ternaries
            xs={control === 'welcome' ? 12 : control === 'secondaryVid' ? 6 : 5}
            container
            className={classNames(classes.secondaryAreaContainer, {
              [classes.welcomeScreen]: control === 'welcome',
              [classes.secondaryArea]: control !== 'welcome'
            })}
          >
            {contentData?.originalTitle !== undefined && control === 'basic' && (
              <BasicInfo
                langCodes={langCodes}
                hideTitle={true}
              />
            )}
            {control === 'welcome' && id && (
              <WelcomeRemix
                model={contentData}
                startRecording={startRecording}
                setIngestModalOpen={setIngestModalOpen}
              />
            )}
            {control === 'remixItems' && (
              <ChildContents
                remixId={contentData?.id}
                contentType={contentType}
                childContents={childContents}
                setChildContents={setChildContents}
                // FIXME reduce complexity once requirements are clear, using original setVideoUrl function
                setVideoUrl={(url) => {
                  if (url !== mainVideoUrl) setMainVideoUrl(url);
                  if (shakaPlayerRef?.videoElement?.paused) {
                    shakaPlayerRef.videoElement?.play();
                  } else {
                    if (shakaPlayerRef?.videoElement) shakaPlayerRef.videoElement.currentTime = 0;
                  }
                }}
                duration={duration}
                setDuration={setDuration}
                clipToMarker={clipToMarker}
                markerToClip={markerToClip}
                getAssetProperties={getAssetProperties}
                setIsFirstLoad={() => (isFirstLoad.current = false)}
                id={id}
                history={history}
                tracks={tracks}
                setTracks={setTracks}
                deleteTrack={deleteTrack}
                setTrackOrderSelectorOpen={setTrackOrderSelectorOpen}
                hideTitle={true}
              />
            )}
            {control === 'chapters' && (
              <Chapters
                video={shakaPlayerRef?.videoElement}
                remixId={id}
                childContents={childContents}
                setChildContents={setChildContents}
                chapters={chapters?.sort((a, b) => getMs(a.position) - getMs(b.position))}
                setChapters={setChapters}
                setMainVideoUrl={setMainVideoUrl}
                duration={duration}
                setDuration={setDuration}
                seek={seek}
                hideTitle={true}
                contentType={contentType}
              />
            )}
            {
              control === 'secondaryVid' && (
                <>
                  {Boolean(secondaryVidUrl) ? (
                    <Box flex={1} className={classes.playerContainer}>
                      {isAudio('secondary') ? (
                        <audio 
                          controls 
                          key={authSecondVidUrl}
                          ref={secondaryVidCallback} 
                          autoPlay={!isFirstLoad.current} 
                          onPlay={() => (isFirstLoad.current = false)}
                        >
                          <source src={authSecondVidUrl} />
                        </audio>
                      ) : (
                        <ShakaPlayer
                          src={authSecondVidUrl}
                          ref={secondaryVidCallback}
                          style={{ width: panelWidth }}
                          onPlay={() => (isFirstLoad.current = false)}
                          autoPlay={!isFirstLoad.current}
                        />
                      )
                    }
                    </Box>
                  ) : (
                    <Box flex={1} className={classes.playerContainerInvalidHolder}>
                      <Typography variant="caption" gutterBottom>
                        {lookUp({ key: 'CONSOLE_EDITOR_SECONDARY_VIDEO_URL_EMPTY' })}
                      </Typography>
                    </Box>
                  )}
                </>
              )
            }
          </Grid>
          {/* sub section below player ends */}
        </Grid>

        <Grid container spacing={2} className={classes.rowsUnderPlayer}>
          {/* timeline start */}
          {(contentType !== 'Playlist' && !!shakaPlayerRef?.videoElement) &&
            (contentData?.duration || contentData?.assets?.find((e) => e.subType === 'Original')?.duration) && (
              <>
                <Grid item>
                  <Typography variant="h6" gutterBottom>{lookUp({ key: 'CONSOLE_TIMELINE_TITLE' })}</Typography>
                </Grid>
                <Grid item xs={12} style={{ height: 100, overflowX: 'hidden' }}>
                  <MarkerBar
                    brPtContainer={{}}
                    selectedBrPt={{}}
                    duration={
                      contentData?.duration || contentData?.assets?.find((e) => e.subType === 'Original')?.duration
                    }
                    handleBreakpointSelect={() => null}
                    getCurrentTime={getCurrentTime}
                    handleBreakpointDeselect={() => null}
                    updateBreakpoint={() => null}
                    handleBrPtChange={() => null}
                  />
                </Grid>
              </>
          )}
          {/* timeline ends */}

          {/* tracks start */}
          {contentType !== 'Playlist' && !!tracks?.filter((e) => !!e)?.length && (
            <>
              <Grid item xs={12} ref={timelineRef}>
                <Box flex={1} className={classes.tracksHeader}>
                  <Typography variant="h6" gutterBottom>{lookUp({ key: 'CONSOLE_TRACKS_TITLE' })}</Typography>
                  <Box flex={1} />
                  <ThemedButton
                    color="primary"
                    disabled={!tracks.length}
                    onClick={() => setTrackOrderSelectorOpen(true)}
                  >
                    {lookUp({ key: 'CONSOLE_CHANGE_TRACK_ORDER' })}
                  </ThemedButton>
                </Box>
                <Tracks
                  childContents={childContents}
                  tracks={tracks}
                  setTracks={setTracks}
                  duration={duration}
                  setDuration={setDuration}
                  setMainTrack={setMainTrack}
                  trackOrderSelectorOpen={trackOrderSelectorOpen}
                  setTrackOrderSelectorOpen={setTrackOrderSelectorOpen}
                  clipToMarker={clipToMarker}
                  video={shakaPlayerRef?.videoElement}
                  setVideoUrl={(url) => {
                    if (url !== mainVideoUrl) setMainVideoUrl(url);
                    if (shakaPlayerRef?.videoElement?.paused) {
                      shakaPlayerRef.videoElement?.play();
                    } else {
                      if (shakaPlayerRef?.videoElement) shakaPlayerRef.videoElement.currentTime = 0;
                    }
                  }}
                  width={timelineWidth}
                  setIsFirstLoad={() => (isFirstLoad.current = false)}
                  chapters={chapters}
                  deleteTrack={deleteTrack}
                  setSecondaryVidUrl={(url) => {
                    setSecondaryTrigger(o=>!o);
                    if (url !== secondaryVidUrl) setSecondaryVidUrl(url);
                    if (secondaryPlayerRef?.videoElement?.paused) {
                      secondaryPlayerRef.videoElement?.play();
                    } else {
                      if (secondaryPlayerRef?.videoElement) secondaryPlayerRef.videoElement.currentTime = 0;
                    }
                  }}
                  parseBE={(prop, index) =>
                    prop === 'video'
                      ? videoSettingsParseBEResponse[index]
                      : audioSettingsParseBEResponse[index]
                  }
                />
              </Grid>
            </>
          )}
          {/* tracks end */}
        </Grid> {/* Content Container ends TODO replace Grid container with <Tabbing />  */}
      </Box>
      {/* FIXME refactor in order to use the reusable <Tabbing /> ends */}

      {/* track selectors start */}
      {trackOrderSelectorOpen && (
        <TrackOrderSelector
          isOpen={trackOrderSelectorOpen}
          contentType={contentType}
          childContents={childContents}
          tracks={makeRemixSelectorTracks()}
          close={() => setTrackOrderSelectorOpen(false)}
          confirm={confirmTrackOrderChange}
        />
      )}
      {/* track selectors ends */}

      {/* dialogs start */}
      <Dialog
        open={ingestModalOpen}
        onClose={() => setIngestModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <ImportDialog
          contentId={contentData?.id}
          contentTitle={contentData?.originalTitle}
          originLang={contentData?.originalLanguage}
          closeModal={() => setIngestModalOpen(false)}
          trigger={() => setLoadChildContentsTrigger(currentValue => !currentValue)}
        />
      </Dialog>
      {!id && qString.has('type') && (
        <InitDialog remixType={qString.get('type')} />
      )}
      {/* dialogs ends */}
    </Box>
  );
}

RemixEditor.propTypes = {
  id: PropTypes.string.isRequired,
  history: PropTypes.any,
  enqueueSnackbar: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  contentData: PropTypes.object.isRequired,
};

export default withSnackbar(RemixEditor);
