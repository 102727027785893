import React, { Component } from 'react';
import AWS from 'aws-sdk';
import * as contentService from 'services/contentService';


const withFileUpload = (ExtendedComponent) =>
  class WithFileS3Upload extends Component {
    upload = ({
      file,
      onUploadProgress,
      assetType = 'FileAsset',
      isPublic = true,
      onFileIngestStart = null,
      onFileIngestEnd = null
    }) =>
      new Promise((resolve, reject) => {
        if (onFileIngestStart) onFileIngestStart();

        contentService
          .createIngestAccess(
            encodeURIComponent(file.name),
            assetType,
            { isPublic },
          )
          .then((ingestAccess) => {
            const {
              assetId,
              bucketName,
              key,
              credentials: { accessKeyId, secretAccessKey, sessionToken },
              fileAssetUrl: objectUrl,
              cdnUrl: publicUrl,
            } = ingestAccess;

            if (onFileIngestEnd) onFileIngestEnd();

            AWS.config.credentials = new AWS.Credentials(
              accessKeyId,
              secretAccessKey,
              sessionToken
            );

            const s3 = new AWS.S3({ region: ingestAccess?.region || 'eu-central-1' });
            const params = {
              Body: file,
              Bucket: bucketName,
              Key: key,
              ACL: 'private'
            };

            const options = { partSize: 20 * 1024 * 1024, queueSize: 1 };

            s3.upload(params, options)
              .on('httpUploadProgress', (event) => onUploadProgress(event))
              .send((err) => {
                if (err) {
                  reject(err);
                }

                return resolve({
                  key,
                  assetId,
                  originalFileName: file.name,
                  publicUrl,
                  objectUrl,
                  availableFrom: new Date(),
                  availableUntil: new Date('01/01/2030'),
                });
              });
          })
          .catch(reject);
      });

    render() {
      const { upload } = this;

      return <ExtendedComponent {...this.props} upload={upload} />;
    }
  };

export default withFileUpload;
