import axios from 'axios';
import { lookUp } from 'services/stringService';
import { getConsoleApiEndpoint } from './apiEndpoints';

const getCatalogEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('CatalogV3');
  return endpoint;
};

const create = (model) => axios.post(getCatalogEndpoint(), model).then(res => res.data);

const get = (id) => axios.get(`${getCatalogEndpoint()}/${id}`).then(res => res.data);

const edit = (id, model) => axios.put(`${getCatalogEndpoint()}/${id}`, model).then(res => res.data);

const remove = (id) => axios.delete(`${getCatalogEndpoint()}/${id}`).then(res => res.data);

const search = (search, pageSize = 25, page = 0) => {
  if (!getCatalogEndpoint()) return Promise.reject(lookUp({ key: 'CONSOLE_NO_ENDPOINT' }));
  if (search?.pageSize) {
    delete search.page;
    delete search.pageSize;
  };
  return axios
    .get(`${getCatalogEndpoint()}/Search/${pageSize}/${page}${!!search ? `?${new URLSearchParams(search)}` : ''}`)
    .then((res) => res.data);
};

const dataSourcesEnum = () => axios.get(`${getConsoleApiEndpoint('LibraryEnums')}/DataSources`).then(res => res.data);

export default {
  create,
  get,
  edit,
  remove,
  search,
  dataSourcesEnum,
};
