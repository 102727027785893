import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { VariableSizeGrid as VirtualVariableList } from 'react-window';
import { func, object } from 'prop-types';
import { Loader } from 'components';
import { getMs } from 'helpers/time';
import MarkerCard from '../common/MarkerCard';
import MarkerEditorDialog from './MarkerEditorDialog';
import contentService from 'services/contentService';
import { processedImageUrl } from 'services/imageProcessingService';
import markerService from 'services/markerService';
import AlertService from 'services/alertService';
import { withSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  addWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  cues: {
    width: '10em',
  },
  cardButtonsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1em',
  },
  formItem: {
    width: '100%',
  },
  editButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 2,
    justifyContent: 'flex-start',
  },
  editButtonsFormControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'flex-start',
  },
  frame: {
    padding: 0,
    width: '100%',
  },
  addBreakPoint: {
    paddingBottom: theme.spacing(3),
  },
  brPanelRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    overflow: 'overlay',
  },
  brPtTextDiv: {
    minWidth: '20%',
    paddingLeft: theme.spacing(0.5),
    maxHeight: theme.spacing(14),
  },
  brPtTitle: {
    width: '100%',
    padding: '0 10px 0',
    fontSize: 'small',
    backgroundColor: 'rgb(30, 136, 229)',
  },
  loaderWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thumbnail: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
  },
}));

function BrPtPanel(props) {
  const classes = useStyles();
  const {
    selectedBrPt,
    newMarker,
    brPtContainer,
    addBreakpoint,
    handleBrPtChange,
    handleBreakpointSelect,
    handleBreakpointDeselect,
    setBrPtContainer,
    deleteBreakpoint,
    updateBreakpoint,
    setIsHotKeyListenerOn,
    isBrPtsLineLoading,
    brPtPanelListRef,
    timelineListRef,
    thumbnailsUrl,
    width,
    seek,
    loop,
    setLoop,
    enqueueSnackbar,
  } = props;

  const [delConfirmDialog, setDelConfirmDialog] = useState(false);
  const [delBrPtData, setDelBrPtData] = useState({
    index: null,
    id: null,
    type: null,
  });

  const [vttImages, setVttImages] = useState([]);
  const [vttLoading, setVttLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedEditId, setSelectedEditId] = useState(null);
  const [subSearch, setSubSearch] = useState('');
  const [filtered, setFiltered] = useState([]);

  const handleEditItem = (itemId) => {
    setSelectedEditId(itemId);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };
  
  const deleteMarker = (id) => {
    markerService.remove(id)
    .then(() => {
      setBrPtContainer(o => ({...o, [selectedBrPt.type]: o[selectedBrPt.type]?.filter(e => e.id !== id) || []}))
    })
    .catch(error => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      })
    })
  }

  useEffect(() => {
    setVttLoading(true);
    const getVttImages = async () => {
      try {
        const images = await contentService.getVttFile(thumbnailsUrl);
        setVttImages(images);
      } catch (error) {
        // TODO: error handling
        console.error(error);
      } finally {
        setVttLoading(false);
      }
    };
    getVttImages();
  }, []);

  const PanelToolbar = (
    <>
      {isEditModalOpen && (
        <MarkerEditorDialog
          open={isEditModalOpen}
          onClose={handleEditModalClose}
          selectedBrPt={selectedBrPt}
          updateBreakpoint={updateBreakpoint}
        />
      )}
    </>
  );

  const brPtPanelLine = (props) => {
    const { columnIndex, data, style } = props;
    const brPt = data[columnIndex];
    if (!brPt || brPt.isInvisible) return null;

    let { thumbnail } = vttImages.find(
      (e) => getMs(e.time?.split(' --> ')[0]) > getMs(data[columnIndex].position)
    ) ||
      vttImages[vttImages?.length - 1] || { thumbnail: '' };
    if (thumbnail) {
      thumbnail = processedImageUrl(thumbnail, {
        r1f: 'w', // fixed aspect ratio, width specified
        r1w: 200, // width px
      });
    }

    const isSelected = brPt.id === selectedBrPt.id;
    const isNewMarker = brPt.id === newMarker;
    const calculatedStyle = isSelected ? { ...style, opacity: 1 } : { ...style };

    return (
      <>
        <article
          onClick={() => {
            // timelineListRef.current.scrollToItem({ align: 'center', columnIndex }); BUGSY
            !isSelected 
              ? handleBreakpointSelect(brPt.type, columnIndex, brPt.id)
              : handleBreakpointDeselect(brPt.type)
          }}
          className={classes.brPanelRow}
          style={{ ...calculatedStyle }}
        >
          <MarkerCard
            marker={brPt}
            isSelected={isSelected}
            isNewMarker={isNewMarker}
            seek={seek}
            getCurrentTime={(e) => 0}
            removeMarker={(id) => deleteMarker(id)}
            editMarker={(marker, property, value) => {
              handleBrPtChange(property, value);
              updateBreakpoint(selectedBrPt.type, { [property]: value });
            }}
            setOpenModal={(id) => {
              // timelineListRef.current.scrollToItem({ align: 'center', columnIndex }); BUGSY
              handleBreakpointSelect(brPt.type, columnIndex, brPt.id);
              handleEditItem(id);
            }}
            loop={loop}
            setLoop={setLoop}
          />
        </article>
      </>
    );
  };

  useEffect(() => {
    if (!brPtContainer[selectedBrPt.type]?.length > 0) return;
    setFiltered(
      brPtContainer[selectedBrPt.type]
        ?.map((e, i) => ({ ...e, idx: i }))
        .filter(
          (e) => subSearch === '' || e.text?.toUpperCase().indexOf(subSearch.toUpperCase()) > -1
        )
    );
  }, [subSearch]);

  return (
    <>
      {PanelToolbar}
      <Grid container direction="row" alignContent="flex-start" className={classes.frame}>
        <Grid item xs={12} container justifyItems="center">
          {brPtContainer[selectedBrPt.type]?.length > 0 && (
            <Grid item>
              {isBrPtsLineLoading || vttLoading || !width ? (
                <div className={classes.loaderWrapper}>
                  <Loader inline />
                </div>
              ) : (
                <VirtualVariableList
                  ref={brPtPanelListRef}
                  height={140}
                  columnCount={brPtContainer[selectedBrPt.type].filter((e) => !e?.isInvisible).length}
                  columnWidth={() => 210}
                  width={Math.min(width, (brPtContainer[selectedBrPt.type].filter((e) => !e?.isInvisible).length) * 210)}
                  layout={'horizontal'}
                  rowCount={1}
                  rowHeight={() => 140}
                  itemData={{
                    ...[
                      ...brPtContainer[selectedBrPt.type]
                        .filter((e) => !!e && !e?.isInvisible)
                        .sort((a, b) => getMs(a.position) - getMs(b.position))
                        .map((e, i) => ({...e, index: i})),
                    ],
                  }}
                  style={{ overflowY: 'hidden' }}
                >
                  {brPtPanelLine}
                </VirtualVariableList>
              )}
            </Grid>
          )}
          <IconButton onClick={() => addBreakpoint(selectedBrPt.type)}>
            <Add />
          </IconButton>
        </Grid>
      </Grid>

      <Dialog
        open={delConfirmDialog}
        onClose={() => setDelConfirmDialog(false)}
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">{lookUp({ key: 'CONSOLE_ARE_YOU_SURE' })}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDelConfirmDialog(false)} color="primary" autoFocus>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </Button>
          <Button
            onClick={() => {
              deleteBreakpoint(delBrPtData.index, delBrPtData.id, delBrPtData.type);
              setDelConfirmDialog(false);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

BrPtPanel.propTypes = {
  selectedBrPt: object,
  handleBrPtChange: func,
  resetSelectedBrPt: func,
  handleBreakpointSelect: func,
  addBreakpoint: func,
  updateBreakpoint: func,
  deleteBreakpoint: func,
  setIsHotKeyListenerOn: func,
};

export default withSnackbar(BrPtPanel);
