import React from 'react';
import { lookUp } from 'services/stringService';
import {  Avatar,  Tooltip, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
  wrap: {
    backgroundColor: 'transparent'
  },
  done: {
    color: green[900],
    backgroundColor: green[200],
    borderRadius: 999,
  }
}))

const PublishedComponent = ({title = lookUp({ key: 'CONSOLE_PUBLISHED' })}) => {
  const classes = useStyles();
    return (
        <Tooltip title={title}>
          <Avatar className={classes.wrap}>
            <DoneIcon className={classes.done}/>
          </Avatar>
        </Tooltip>
    )
} 

export default PublishedComponent