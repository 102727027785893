import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { shape } from 'prop-types';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CssBaseline,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { setLastRoute, setLoadingStatus } from 'store/actions/authAction';
import { registerOnboarding } from 'services/customersService';
import { getAwsRegions } from 'services/brandsService';
import { withSnackbar } from 'notistack';
import { version } from '../../package.json';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  backgroundDiv: {
    minWidth: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    minHeight: '100%',
  },
  root: {
    position: 'absolute',
    width: 520,
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  wrapper: {
    padding: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: 20,
  },
  version: {
    fontSize: '100%',
    fontStyle: 'italic',
  },
  title: {
    marginBottom: '2em',
  },
}));

function Register(props) {
  const { enqueueSnackbar } = props;

  const navigate = useNavigate();
  const classes = useStyles();

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const lastRoute = useSelector((state) => state.auth.lastRoute);
  const defaultRegion = { 'US East (Virginia)': 'us-east-1' };
  const [awsRegions, setAwsRegions] = useState(defaultRegion);

  useEffect(() => {
    const getRegions = async () => {
      setAwsRegions(await getAwsRegions());
    };
    getRegions();
  }, []);

  const { token } = useParams();

  const [formData, setFormData] = useState({
    AwsRegion: defaultRegion,
  });

  const [touched, setTouched] = useState({
    projectName: false,
    userEmail: false,
  });

  const [errors, setErrors] = useState({
    projectName: null,
    userEmail: null,
  });

  const notEmpty = {
    conforms: (value) => {
      return !!value.length;
    },
    message: lookUp({ key: 'CONSOLE_Mandatory_field' }),
  };

  const noValidation = {
    conforms: (value) => {
      return true;
    },
    message: lookUp({ key: 'CONSOLE_NO_VALIDATION' }),
  };

  const brandNameFormat = {
    conforms: (value) => {
      return /^[a-zA-z0-9][a-zA-Z0-9|\-]{1,23}[a-zA-Z0-9]$/.test(value);
    },
    message: lookUp({ key: 'CONSOLE_BRAND_NAME_HELPERTEXT' }),
  };

  const maxTen = {
    conforms: (value) => value.length < 11,
    message: lookUp({ key: 'CONSOLE_MAX_CHARS_TEMPLATE', number: 10 }),
  };

  const validation = {
    AwsRegion: noValidation,
    projectName: maxTen,
    userEmail: notEmpty,
  };

  const validate = (field, value) => {
    let error = validation[field].conforms(value) ? null : validation[field].message;
    setErrors({ ...errors, [field]: error });
    return error;
  };

  const validateAll = () => {
    let allValid = true;
    let touchAll = touched;
    let checkAll = errors;
    Object.keys(formData).forEach((field) => {
      touched[field] = true;
      if (validate(field, formData[field])) {
        checkAll[field] = validate(field, formData[field]);
        allValid = false;
      }
    });
    setTouched(touchAll);
    setErrors(checkAll);
    return allValid;
  };

  const handleInput = (field) => (event) => {
    event.persist();
    const value = event.target.value || '';
    setFormData({ ...formData, [field]: value });
    if (event.type === `blur` || value.length > 2) {
      setTouched({ ...touched, [field]: true });
    }
    setErrors({ ...errors, [field]: validate(field, value) });
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { AwsRegion, projectName, userEmail } = formData;
    if (!validateAll()) return;
    if (isLoading) return;
    const brandName = projectName;
    const userName = userEmail.split('@')[0];
    const awsRegion = Object.values(AwsRegion)[0];
    dispatch(setLoadingStatus(true));
    registerOnboarding({ awsRegion, projectName, brandName, userName, userEmail, token }, dispatch)
      .then((newToken) => {
        navigate(`/newpassword/${newToken}`);
        dispatch(setLastRoute(lastRoute));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(() => {
        dispatch(setLoadingStatus(false));
      });
  };

  return (
    <div className={classes.backgroundDiv}>
      <img src="../static/img/blur.jpg" style={{ width: '100vw', height: '100vh' }} />
      <div className={classes.root}>
        <CssBaseline />
        <Paper className={classes.wrapper}>
          <Grid item className={classes.title}>
            <Typography component="h1" variant="h4" align="center" gutterBottom>
              {lookUp({ key: 'CONSOLE_ONBOARDING_PAGE_TITLE' })}
            </Typography>
          </Grid>
          {isLoading ? (
            <Grid item align="center">
              <CircularProgress color={'primary'} />
            </Grid>
          ) : (
            <form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert mt={2} mb={1} severity="warning">
                  {errors.submit}
                </Alert>
              )}
              <Grid item container direction="column" spacing={3}>
                <Grid item>
                  <FormControl>
                    <Select
                      id="selectRegion"
                      autoWidth
                      variant="outlined"
                      value={formData.AwsRegion}
                      renderValue={(value) => Object.keys(value)[0]}
                      onChange={handleInput('AwsRegion')}
                    >
                      {Object.entries(awsRegions).map((r) => (
                        <MenuItem key={r[0]} value={{ [r[0]]: r[1] }}>
                          {r[0]}: {r[1]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    name="projectName"
                    label={lookUp({ key: 'CONSOLE_PROJECTNAME' })}
                    error={Boolean(touched.projectName && errors.projectName)}
                    id="projectName"
                    fullWidth
                    size="medium"
                    helperText={touched.projectName && errors.projectName}
                    onChange={handleInput('projectName')}
                    onBlur={handleInput('projectName')}
                    onKeyUp={(e) => handleKeyUp(e)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    type="email"
                    name="userEmail"
                    label={lookUp({ key: 'CONSOLE_EMAIL' })}
                    error={Boolean(touched.userEmail && errors.userEmail)}
                    id="userEmail"
                    fullWidth
                    size="medium"
                    helperText={touched.userEmail && errors.userEmail}
                    onChange={handleInput('userEmail')}
                    onBlur={handleInput('userEmail')}
                    onKeyUp={(e) => handleKeyUp(e)}
                  />
                </Grid>
                <Grid item>
                  <div style={{ height: '2em' }} />
                  <Button type="submit" fullWidth color="primary">
                    {lookUp({ key: 'CONSOLE_REGISTER_BUTTON' })}
                  </Button>
                </Grid>
                <Grid item>
                  <Button fullWidth color="primary" onClick={() => navigate('/sign-in')}>
                    {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
                  </Button>
                </Grid>
                <Grid item>
                  <Typography
                    color={'textSecondary'}
                    align="right"
                    style={{ margin: '20px 0 0 0', fontStyle: 'italic' }}
                  >
                    {lookUp({ key: 'CONSOLE_APP_VERSION_TEMPLATE', version })}
                  </Typography>
                </Grid>
              </Grid>
            </form>
          )}
        </Paper>
      </div>
    </div>
  );
}

Register.propTypes = {
  history: shape({}).isRequired,
};

export default withSnackbar(Register);
