import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withSnackbar } from 'notistack';
import classNames from 'classnames';
import AlertService from 'services/alertService';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ClearIcon from '@material-ui/icons/Clear';
import { msToTime } from 'helpers/time';
import markerService from 'services/markerService';
import contentAction from 'store/actions/contentAction';
import parametersService from 'services/parametersService';
import { lookUp } from 'services/stringService';
import { deleteContentRelation } from 'services/collectionsServiceV2';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import ControlBox from 'components/ControlBox';
import ThemedButton from 'components/ThemedButton';
import JobStatus from './JobStatus';


const DEFAULT_TAB = 'all';
const types = ['ScreenCapture', 'CameraCapture', 'Video', 'Audio'];
const tagsValidation = [
  {
    key: 'Originalassetexist',
    errorTag: 'Originalassetnotexist',
    severity: 'error',
  },
  {
    key: 'Transcodeexist',
    errorTag: 'Transcodenotexist',
    severity: 'error',
  },
  {
    key: 'Subtitleexist',
    errorTag: 'Subtitlenotexist',
    severity: 'warning',
    ignore: (asset) => asset.type === 'ScreenCapture',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    maxHeight: '100%',
    width: '100%',
  },
  contentContainer: {
    height: '100%',
  },
  scrollingArea: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    overflowY: 'auto',
  },
  itemsContainer: {
    height: 'auto',
  },
  itemCard: {
    // FIXME the top/bottom padding is only to account for the corner buttons that go outside of the content box, remove once the those corner buttons are in the main content card
    padding: theme.spacing(1, .5),
    margin: 0,
    background: theme.palette.background.paper,
  },
  itemCardTopRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
    padding: 0,
  },
  tagsContainer: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    gap: theme.spacing(.5),
  },
  tag: {
    '& [class*="label"]': {
      paddingLeft: `${theme.spacing(.5)}px !important`,
      paddingRight: `${theme.spacing(.5)}px !important`,
    },
  },
  primaryTag: {
    color: `${theme.palette.primary.contrastText}`,
    borderColor: `${theme.palette.primary.contrastText}`,
  },
  success: {
    color: theme.palette.success.contrastText,
    borderColor: theme.palette.success.main,
    background: alpha(theme.palette.success.main, .8),
    '&.primaryTag': {
      color: theme.palette.success.contrastText,
    },
  },
  error: {
    color: theme.palette.error.contrastText,
    borderColor: theme.palette.error.main,
    background: alpha(theme.palette.error.main, .8),
    '&.primaryTag': {
      color: theme.palette.error.contrastText,
    },
  },
  warning: {
    color: `${theme.palette.getContrastText(theme.palette.warning.main)} !important`,
    borderColor: theme.palette.warning.main,
    background: theme.palette.warning.main,
  },
  letterTopBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: theme.spacing(0.2),
  },
  letterBottomBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: theme.spacing(0.1),
  },
}));

function RemixChildContents({
  remixId,
  contentType,
  childContents,
  setChildContents,
  getAssetProperties,
  setDuration,
  clipToMarker,
  tracks = [],
  setTracks,
  deleteTrack,
  setVideoUrl,
  setIsFirstLoad,
  setTrackOrderSelectorOpen,
  hideTitle,
  enqueueSnackbar,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();

  const isChildContentRedirect = useSelector((state) => state.content.isChildContentRedirect);
  const [filterType, setFilterType] = useState(DEFAULT_TAB);
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isTrackToDelete, setIsTrackToDelete] = useState(false);

  const jobIntervalRefs = useRef([]);

  const addTrack = (childContent) => {
    const mainTrack = tracks.find((track) => track.main);
    const mainDuration = mainTrack?.duration;
    const { videoUrl, duration } = getAssetProperties(childContent?.assets);

    const newClip = {
      title: childContent.originalTitle,
      behavior: 1,
      audio: 1,
      volumeLevel: 70,
      contentId: childContent.contentId,
      description: '',
      cueIn: 0,
      start: 0,
      duration,
      cueOut: (mainDuration < duration) ? mainDuration : duration,
      open: true,
    };

    const trackIndexer = () => {
      const takenIndexes = tracks.map((e) => e.index) || [-1];
      let newIndex;
      for (let i = 0; takenIndexes.indexOf(i) > -1; i++) {
        newIndex = i + 1;
      }
      return newIndex || 0;
    };

    const newTrack = {
      title: childContent.originalTitle,
      contentId: childContent.contentId,
      thumbnail: childContent.assets.find((e) => e.subType === 'Landscape')?.publicUrl || '',
      videoUrl,
      chapters: childContent.chapters,
      duration,
      index: trackIndexer(),
      main: !tracks || tracks?.length === 0,
      lock: false,
    };

    if (!newTrack.duration) {
      AlertService.displayWarning({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DURATION_0_ERROR' })
      });
      return;
    };

    if (!tracks || tracks?.length === 0) setDuration(duration);

    markerService
      .create(clipToMarker(newTrack, newClip))
      .then((newMarker) => {
        newClip.id = newMarker?.id;
        if (!tracks?.map((tr) => tr.contentId)?.includes(childContent.contentId))
          setTracks((tracks) => [...(tracks || []), { ...newTrack, clips: [newClip] }]);
      })
      .catch((error) =>
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        })
      );
  };

  const registerSubAssetRedirect = async (toWhere) => {
    if (isChildContentRedirect.paramChecked) {
      if (isChildContentRedirect.on) {
        dispatch(contentAction.setIsChildContentRedirect({ ...isChildContentRedirect, url: toWhere }));
      }
    } else {
      try {
        const response = await parametersService.getByKey('Console:Content:Redirect:Remix');
        const on = response?.value?.toLowerCase
          ? response.value.toLowerCase() === 'true'
          : false;

        dispatch(contentAction.setIsChildContentRedirect({
          on,
          url: on ? toWhere : '',
          paramChecked: true
        }));
      } catch (error) {
        console.error(error);
        dispatch(contentAction.setIsChildContentRedirect({
          ...isChildContentRedirect,
          paramChecked: true
        }));
      }
    }
  };

  const colorByIndex = (i) =>
    i || i === 0 ? `hsl(${12 + i * 140}, 40%, 80%)` : theme.palette?.info?.main || '#ddd';

  const handleTrackAddRemove = (id, trackItem) => {
    if (trackItem) {
      if (trackItem.main && tracks.length > 1) {
        setTrackOrderSelectorOpen(true);
      } else {
        deleteTrack(id);
      }
    } else {
      addTrack(childContents.find((e) => e.contentId === id))
    }
  };

  const handleDelete = (id) => {
    const track = tracks?.find((t) => t.contentId === id);
    if (track) {
      if (track.main) return
      setIsTrackToDelete(true)
    }
    setIdToDelete(id);
    setConfirmDialog(true);
  };
  
  const deleteChildContent = () => {
    if (!idToDelete) return;
    const track = tracks?.find((t) => t.contentId === idToDelete);
    if (track) { 
      deleteTrack(idToDelete);
      setIsTrackToDelete(false);
    }
    deleteContentRelation(remixId, 'Playlist', idToDelete);
    setChildContents((a) => [...a].filter((e) => e.contentId !== idToDelete));
    setConfirmDialog(false);
    setIdToDelete(undefined);
  };

  const handleTabChange = (event, newValue) => {
    setFilterType(newValue);
  };

  const makeTagLabels = (asset, isMainTrackItem) => {
    const baseTagProps = {
      size: 'small',
      variant: 'outlined',
    };

    return tagsValidation
      .reduce((statuses, rule) => {
        if (rule.ignore && rule.ignore(asset)) {
          return statuses;
        } else if (asset.labels?.Tag) {
          if (asset.labels.Tag.includes(rule.key)) {
            statuses.push({ type: true, data: rule });
          } else {
            statuses.push({ type: false, data: rule });
          }
        }
        return statuses;
      }, [])
      .map((tagInfo, i) => {
        let tagProps = {
          ...baseTagProps,
          className: classNames(classes.tag, {
            [classes.primaryTag]: isMainTrackItem,
            [classes[tagInfo.data.severity]]: !tagInfo.type,
          }),
        };
        
        return tagInfo.type ? (
            <Chip
              key={`${tagInfo.data.key}_${i}`}
              label={lookUp({ key: `CONSOLE_${tagInfo.data.key}` })}
              color={isMainTrackItem ? 'default': 'inherit'}
              {...tagProps}
            />
          ) : (
            <Chip
              key={`${tagInfo.data.errorTag}_${i}`}
              label={lookUp({ key: `CONSOLE_${tagInfo.data.errorTag}` })}
              {...tagProps}
            />
          )
      });
  };

  // FIXME refactor in order to use reusable <Tabbing /> component
  const a11yProps = (tabId) => ({
    id: `tab-${tabId}`,
    'aria-controls': `tabpanel-${tabId}`,
  });

  return (
    <Box flex={1} className={classes.root}>
      <Grid container className={classes.contentContainer}>
        <Grid item>
          {!hideTitle && (
            <Typography
              variant="h6"
              gutterBottom
            >
              {lookUp({ key: 'CONSOLE_REMIXITEMS_TAB' })}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignContent="center"
          alignItems="flex-start"
        >
          {contentType === 'Playlist'
            ? (
              <Typography
                variant="body2"
                gutterBottom
              >
                {lookUp({ key: 'CONSOLE_CONTENT_SELECTOR' })}
              </Typography>
            ) : (
              <Tabs
                value={contentType === 'Playlist' ? null : filterType}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  value={'all'}
                  label={lookUp({ key: `CONSOLE_ALL` })}
                  disabled={contentType === 'Playlist'}
                  {...a11yProps('all')}
                />
                {types.map?.((type) => (
                  <Tab
                    key={`${type}-tab`}
                    value={type}
                    label={lookUp({ key: `CONSOLE_${type}` })}
                    disabled={contentType === 'Playlist'}
                    {...a11yProps(type)}
                  />
                ))}
              </Tabs>
            )
          }
        </Grid>
        <Grid xs={12} className={classes.scrollingArea}>
          <Grid direction="column" className={classes.itemsContainer}>
            {childContents
              .filter?.((e) => filterType === DEFAULT_TAB || filterType === e.type)
              .sort((a, b) => (tracks?.find((t) => t.contentId === a.contentId)?.main ? -1 : 0))
              .map((childContent, index) => {
                const assetProperties = getAssetProperties(childContent.assets);
                const trackItem = tracks?.find((track) => track.contentId === childContent.contentId);
                const isMainTrackItem = trackItem?.main;
                const background = isMainTrackItem ? theme.palette.primary.light : theme.palette.common.white;
                const color = isMainTrackItem ? theme.palette.secondary.contrastText : theme.palette.text.primary;

                return (
                  <Grid
                    className={classes.itemCard}
                    key={childContent.contentId}
                    style={{
                      background,
                      color,
                    }}
                    xs={12}
                    spacing={1}
                    container
                  >
                    <Grid item md={3} xs={12}>
                      <ControlBox
                        width="100%"
                        height={100}
                        offset={6}
                        topButtons={[
                          {
                            selector: 'iconButton',
                            icon: <Box component="span" className={classes.letterTopBox}>W</Box>,
                            onClick: () => registerSubAssetRedirect(location.pathname),
                            tooltip: lookUp({ key: 'CONSOLE_REMIX_ASSET_WORKFLOWS_HELPERTEXT' }),
                            link: `/content/${childContent.contentId}/edit?section=workflows#assets`
                          },
                          {
                            selector: 'iconButton',
                            icon: <Box component="span" className={classes.letterTopBox}>S</Box>,
                            onClick: () => registerSubAssetRedirect(location.pathname),
                            link: `/content/${childContent.contentId}/editor#Subtitle`,
                            tooltip: lookUp({ key: 'CONSOLE_REMIX_ASSET_EDITOR_SUBTITLE_HELPERTEXT' }),
                          },
                          {
                            selector: 'iconButton',
                            icon: <ClearIcon fontSize="small" />,
                            onClick: () => handleDelete(childContent.contentId),
                            tooltip: lookUp({ key: 'CONSOLE_DELETE_REMIX_ASSET_HELPERTEXT' }),
                          },
                        ]}
                        centerButton={{
                          icon: <PlayArrowIcon fontSize="small" />,
                          onClick: () => {
                            setVideoUrl?.(
                              assetProperties?.videoUrl || assetProperties?.audioUrl
                            );
                            setIsFirstLoad(false);
                          },
                          tooltip: lookUp({ key: 'CONSOLE_PLAY_MAIN_HELPERTEXT' }),
                          selector: 'centerButton',
                        }}
                        bottomButtons={[
                          {
                            selector: 'iconButton',
                            icon: <Box className={classes.letterBottomBox} component="span">i</Box>,
                            onClick: () => registerSubAssetRedirect(location.pathname),
                            tooltip: lookUp({ key: 'CONSOLE_METADATA_EDITOR_HELPERTEXT' }),
                            link: `/content/${childContent.contentId}/edit`,
                          },
                          {
                            selector: 'iconButton',
                            icon: <Box className={classes.letterBottomBox} component="span">C</Box>,
                            onClick: () => registerSubAssetRedirect(location.pathname),
                            link: `/content/${childContent.contentId}/editor#Cut`,
                            tooltip: lookUp({ key: 'CONSOLE_REMIX_ASSET_EDITOR_CUT_HELPERTEXT' }),
                          },
                          contentType === 'Playlist'
                            ? ({
                              selector: 'iconButton',
                              icon: <Box className={classes.letterBox} component="span">O</Box>,
                              onClick: () => setTrackOrderSelectorOpen(true),
                              tooltip: lookUp({ key: 'CONSOLE_ORDER_CHANGE_BUTTON_HELPERTEXT' }),
                            }) : ({
                              selector: 'iconButton',
                              icon: trackItem ? <RemoveIcon /> : <AddIcon />,
                              onClick: () => handleTrackAddRemove(childContent.contentId, trackItem),
                              tooltip: trackItem
                                ? lookUp({ key: 'CONSOLE_DELETE_TRACK_HELPERTEXT' })
                                : lookUp({ key: 'CONSOLE_ADD_TRACK' }),
                            }),
                        ]}
                      />
                    </Grid>
                    <Grid container direction="column" item md={9} xs={12}>
                      <Grid container direction="row" item>
                        <Grid item xs={9}>
                          <Box className={classes.itemCardTopRow}>
                            <Typography variant="body1" noWrap gutterBottom>
                              {childContent.originalTitle} {childContent.type}
                            </Typography>
                          </Box>

                          <Typography component="div" variant="caption">
                            {`${lookUp({ key: 'CONSOLE_STATUS' })}: ${lookUp({ key: `CONSOLE_${childContent.processingStatus}` })}`}
                          </Typography>

                          <Typography variant="caption" gutterBottom color={!assetProperties?.duration ? 'error' : 'inherit'}>
                            {
                              assetProperties?.duration
                                ? (msToTime(getAssetProperties(childContent.assets)?.duration)?.split('.')?.[0])
                                : lookUp({ key: 'CONSOLE_DURATION_0_ERROR' })
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          <JobStatus
                            order={index}
                            content={childContent}
                            intervalRefs={jobIntervalRefs.current}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        className={classes.tagsContainer}
                        flex={1}
                        container
                      >
                        {makeTagLabels(childContent, isMainTrackItem)}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
            }
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={confirmDialog}
        onClose={() => {
          setIdToDelete(undefined);
          setIsTrackToDelete(false);
          setConfirmDialog(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {lookUp({ key: 'CONSOLE_CONFIRMATION_DELETE' })}
        </DialogTitle>
        <DialogContent>
          {isTrackToDelete && 
            <DialogContentText>
              {lookUp({ key: 'CONSOLE_DELETE_TRACK_WARNING_SUMMARY' })}
            </DialogContentText>
          }
        </DialogContent>
        <DialogActions className={classes.dialogPad}>
          <ThemedButton
            onClick={() => {
              setIdToDelete(undefined);
              setIsTrackToDelete(false);
              setConfirmDialog(false);
            }}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>
          <ThemedButton color="error" onClick={deleteChildContent} autoFocus>
            {lookUp({ key: 'CONSOLE_DELETE_BUTTON' })}
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

RemixChildContents.propTypes = {
  remixId: PropTypes.string.isRequired,
  contentType: PropTypes.oneOf(['Collection', 'Playlist', 'Remix']).isRequired,
  childContents: PropTypes.array,
  setChildContents: PropTypes.func,
  getAssetProperties: PropTypes.func,
  setDuration: PropTypes.func,
  clipToMarker: PropTypes.func,
  tracks: PropTypes.array,
  setTracks: PropTypes.func,
  deleteTrack: PropTypes.func,
  setVideoUrl: PropTypes.func,
  setIsFirstLoad: PropTypes.func,
  setTrackOrderSelectorOpen: PropTypes.func,
  hideTitle: PropTypes.bool,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(RemixChildContents);
