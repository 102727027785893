import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles, MenuItem, Grid, Tooltip, Button, TextField } from '@material-ui/core';
import { Listing } from 'components';
import DialogModal from 'components/DialogModal';
import { CreatedOn } from 'components';
import tracewindService from 'services/tracewindService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 0, 2, 0),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
  },
  select: {
    minWidth: 200,
  },
  margin: {
    marginTop: theme.spacing(2),
  },
  marginTopSmall: {
    marginTop: theme.spacing(1),
  },
  textField: {},
  iconButton: {
    padding: '5px',
  },
  expandIcon: {
    marginLeft: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2, 0),
  },
  searchInput: {
    height: '40px',
    width: '100%',
  },
}));

function Logs(props) {
  const {
    enqueueSnackbar,
  } = props;

  const { type, id } = useParams();
  
  const today = new Date();
  const defaultToolbarValues = {
    q: '',
    objectType: type,
    objectId: id,
    from: new Date(today.setDate(today.getDate() - 7)).toISOString().split('T')[0] + 'T00:00:00Z',
    to: new Date().toISOString().split('T')[0] + 'T23:59:59Z',
  };
  
  const [objectTypes, setObjectTypes] = useState([type]);
  const [filterParams, setFilterParams] = useState(defaultToolbarValues);
  const [filters, setFilters] = useState(defaultToolbarValues);
  const [trigger, setTrigger] = useState(1);
  const [clicked, setClicked] = useState('');
  const preventSearch = useRef(
    Boolean(!(filterParams && filterParams.objectId && filterParams.objectType))
  );


  useEffect(() => {
    tracewindService
      .getObjectTypes()
      .then((types) => {
        if (!filterParams.objectType) {
          setFilterParams({...filterParams, objectType: types?.[0]})
        }
        setObjectTypes(types.sort());
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_object_types' }) })
        });
      });
    if (type && id) submit();
  }, []);

  const submit = () => {
    setFilters(filterParams);
    preventSearch.current = Boolean(
      !(filterParams && filterParams.objectId && filterParams.objectType)
    );
    setTrigger((o) => (+o % 2) + 1);
  };

  const theadElements = [
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      filterable: false,
      sortable: true,
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'description',
      headerName: lookUp({ key: 'CONSOLE_DESCRIPTION' }),
      align: 'left',
      flex: true,
      width: 500,
      renderCell: (item) => (
        <DialogModal
          title={item.value}
          dialogTitle={lookUp({ key: 'CONSOLE_LOG_DETAIL' })}
          divEl={true}
          button={false}
          item={item}
        >
          <span>{item.value}</span>
        </DialogModal>
      ),
    },
    {
      field: 'source',
      headerName: lookUp({ key: 'CONSOLE_SOURCE' }),
      align: 'left',
      width: 250,
      renderCell: (item) => (
        <Tooltip title={item.value} placement="bottom-start">
          <span>{item.value.replace(/"." /g, ' ')}</span>
        </Tooltip>
      ),
    },
    {
      field: 'correlationId',
      headerName: lookUp({ key: 'CONSOLE_CORRELATIONID' }),
      align: 'left',
      width: 320,
      renderCell: (item) => (
        /* For Correlation ID -> copy to clipboard function is applied here: */
        <Tooltip
          title={
            clicked
              ? lookUp({ key: 'CONSOLE_COPY_CORRELATION_ID_HELPERTEXT' })
              : lookUp({ key: 'CONSOLE_CLICK_TO_COPY_HELPERTEXT_TEMPLATE', value: item.value })
          }
          placement="bottom-start"
        >
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setClicked(`${item.value}`);
              navigator.clipboard.writeText(item.value);
            }}
          >
            {item.value}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'sourceVersion',
      headerName: lookUp({ key: 'CONSOLE_VERSION' }),
      type: 'number',
      width: 80,
      filterable: false,
      sortable: false,
    },
  ];

  return (
    <>
      <Listing
        CustomToolbar={
          <CustomToolbar
            objectTypes={objectTypes}
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            submit={submit}
          />
        }
        tableHeadElements={theadElements}
        loadData={tracewindService.getLogs}
        defaultPageSize={50}
        sortable={false}
        noSearchBox
        setFilters={setFilterParams}
        additionalFiltersState={{ ...filters }}
        preventSearch={preventSearch.current}
        externalTrigger={trigger}
      />
    </>
  );
}

export default withSnackbar(Logs);

const CustomToolbar = ({ objectTypes, filterParams = {}, setFilterParams, submit }) => {
    const classes = useStyles();

  const handleFilter = (key, value) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      [key]: value,
    }));
  };

  const isEnter = (key) => {
    if (key === 'Enter') submit();
  };

  return (
    <Grid container alignItems={'flex-start'} justifyContent={'space-between'} spacing={2}>
      <Grid container alignItems={'center'} spacing={1} item lg={10} md={12}>
        <Grid item md={3} lg={3} xl={3}>
          <DatePicker
              label={lookUp({ key: 'CONSOLE_LOG_FROM' })}
              value={filterParams.from || null}
              format="MM/DD/yyyy"
              margin="dense"
              variant="inline"
              inputVariant="outlined"
              onChange={(event) => {
                if (event) {
                  const time = new Date(event);
                  if (filterParams.to && time > filterParams.to) return;
                  time.setUTCHours(0, 0, 0, 0);
                  handleFilter('from', time.toISOString());
                }
              }}
              style={{ width: '100%' }}
              fullWidth
            />
        </Grid>
        <Grid item md={3} lg={3} xl={3}>
           <DatePicker
              label={lookUp({ key: 'CONSOLE_LOG_UNTIL' })}
              value={filterParams.to || null}
              format="MM/DD/yyyy"
              variant="inline"
              inputVariant="outlined"
              margin="dense"
              onChange={(event) => {
                if (event) {
                  const time = new Date(event);
                  if (filterParams.from && time < filterParams.from) return;
                  time.setUTCHours(23, 59, 59, 999);
                  handleFilter('to', time.toISOString());
                }
              }}
              style={{ width: '100%' }}
              fullWidth
            />
        </Grid>
        <Grid item md={2} lg={2} xl={2}>
          <TextField
            select
            margin="dense"
            fullWidth
            label={lookUp({ key: 'CONSOLE_OBJECT_TYPE' })}
            labelId="select-outlined-label"
            value={filterParams.objectType || objectTypes?.[0]}
            error={!filterParams.objectType}
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            onChange={(e) => {
              handleFilter('objectType', e.target.value);
            }}
            onKeyDown={(e) => isEnter(e.key)}
            inputProps={{
              name: 'objectType',
              id: 'logs-objectType',
            }}
          >
            {!!objectTypes.length &&
              objectTypes.map((item) => (
                <MenuItem value={item} key={item}>
                  {lookUp({ key: `CONSOLE_${item}` })}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs>
          <TextField
            id={`objectId`}
            label={lookUp({ key: 'CONSOLE_OBJECT_ID' })}
            margin="dense"
            className={classes.textField}
            value={filterParams.objectId}
            error={!filterParams.objectId}
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            onChange={(e) => handleFilter('objectId', e.target.value)}
            onKeyDown={(e) => isEnter(e.key)}
          />
        </Grid>
        <Grid item md={1}>
          <Button  color="primary" onClick={submit}>
            {lookUp({ key: 'CONSOLE_GO_BUTTON' })}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
