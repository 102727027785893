import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';

const useStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  titleContainer: {
    display: 'flex',
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginTop: theme.spacing(0.5),
  },
  closeButton: {
    display: 'flex',
    alignSelf: 'flex-end',
    color: theme.palette.grey[500],
    marginRight: `-${theme.spacing(1)}px`,
    marginLeft: theme.spacing(.5),
    zIndex: theme.zIndex.modal + 2,
  },
});

const DialogTitle = withStyles(useStyles)(({ children, classes, onClose, isConfirm, ...other }) => (
  <MuiDialogTitle disableTypography className={classes.titleContainer} {...other}>
    <Typography variant="h6" className={classes.title}>
      {children}
    </Typography>
    <Box flex={1} />
    {onClose && !isConfirm && (
      <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    )}
  </MuiDialogTitle>
));

const DialogContent = withStyles((theme) => ({
  root: ({ fullwidthcontent }) => ({
    padding: fullwidthcontent ? 0 : theme.spacing(2),
  }),
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Dialog = ({
  title,
  children,
  actions,
  open,
  onClose,
  dividers = false,
  isConfirm = false,
  fullWidthContent = false,
  ...rest
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} aria-labelledby="dialog-title" {...rest}>
      <DialogTitle id="dialog-title" onClose={onClose} isConfirm={isConfirm}>
        {title}
      </DialogTitle>
      {children && <DialogContent fullwidthcontent={fullWidthContent} dividers={dividers}>{children}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  dividers: PropTypes.bool,
  children: PropTypes.node,
  actions: PropTypes.node,
  isConfirm: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
};

export default Dialog;
