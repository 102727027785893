import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import EditorInfo from 'components/EditorInfo';
import Loader from 'components/Loader';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import SectionContainer from 'components/SectionContainer';
import ThemedButton from 'components/ThemedButton';
import { goBackOrStay } from 'helpers/common';
import rewardsService from 'services/rewardsService';
import navigationAction from 'store/actions/navigationAction';
import alertService from 'services/alertService';

const NO_CATEGORY = 'None';
const DEFAULT_CATEGORY = NO_CATEGORY;

const RewardsForm = ({ enqueueSnackbar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector(state => state.navigation.isNavigationBlocked);
  const [categoryTypes, setCategoryTypes] = useState([]);

  // TODO -> set up the model
  const [model, setModel] = useState({
    id: null,
    key: null,
    category: DEFAULT_CATEGORY,
    friendlyName: null,
    createdDate: null,
    description: null,
    internalPrice: 0,
    tokenPrice: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getTypeEnums();
  }, []);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const saveData = async (publish = false) => {
    const saveModel = publish ? { ...model, enabled: !model.enabled } : { ...model };
    const editOrCreate = id ? 'edit' : 'create';
    setIsSaving(true);
    rewardsService[editOrCreate](saveModel)
      .then(() => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        alertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: 
            publish && model.published === false
              ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: id })
              : publish && model.published === true
                ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: id })
                : id
                  ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: id })
                  : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: id })
        });
        const toWhere = window.history.state.idx === 0 ? '/engagement/rewards' : -1;
        goBackOrStay(navigate, toWhere);
      })
      .catch(error => {
        alertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(() => setIsSaving(false));
  };
  useEffect(() => {

    const getData = () => {
      setIsLoading(true);
      rewardsService.get(id)
        .then(data => {
          setModel(data);
          dispatch(navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: id})))
        })
        .catch(error => {
          alertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_REWARDS'}) })
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
     if (id) {
       getData();
     } else {
       dispatch(navigationAction.setPageTitle(lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: `CONSOLE_REWARD` }) })))
     };
     return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  const navigateBack = () => {
    dispatch(navigationAction.allowNavigation());
    window.history?.state?.idx === 0
      ? navigate('/engagement/rewards')
      : navigate(window.history?.state?.idx === 0 ? '/' : -1);
  };

  const getTypeEnums = async () => {
    try {
      const typeEnums = (await rewardsService.getTypeEnums()) ?? [];
      setCategoryTypes(typeEnums);
    } catch (error) {
      alertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CONTENT_ENUMS' }),
        }),
      });
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <HeaderActionsContainer>
        <Box flex={1} />
        {/* Cancel action Button */}
        <ThemedButton
          onClick={(e) => {
            e.preventDefault();
            navigateBack();
          }}
          disabled={isSaving || isLoading}
        >
          {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
        </ThemedButton>

        {/* Save action Button */}
        <ThemedButton
          color="success"
          onClick={saveData}
          disabled={isSaving || isLoading}
          loading={isSaving}
        >
          {lookUp({ key: 'CONSOLE_SAVE_BUTTON' })}
        </ThemedButton>
      </HeaderActionsContainer>

      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="category">{lookUp({ key: 'CONSOLE_CATEGORY' })}</InputLabel>
              <Select
                labelId="category"
                label={lookUp({ key: 'CONSOLE_CATEGORY' })}
                aria-describedby="category-helper"
                value={model.category ?? DEFAULT_CATEGORY}
                onChange={(e) => {
                  e.preventDefault();
                  handleModelChange('category', e.target?.value);
                }}
                size="small"
              >
                <MenuItem value={DEFAULT_CATEGORY}>
                  {lookUp({ key: `CONSOLE_${DEFAULT_CATEGORY}` })}
                </MenuItem>
                {categoryTypes.map?.((category = '') => (
                  <MenuItem key={category} value={category}>
                    {lookUp({ key: `CONSOLE_${category}` })}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="category-helper">
                {lookUp({ key: 'CONSOLE_CATEGORY_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              label={lookUp({ key: 'CONSOLE_KEYWORD' })}
              helperText={lookUp({ key: 'CONSOLE_KEYWORD_HELPERTEXT' })}
              value={model.key || ''}
              onChange={(event) => handleModelChange('key', event.target?.value)}
              disabled={!!id}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_FRIENDLY_NAME' })}
              helperText={lookUp({ key: 'CONSOLE_FRIENDLY_NAME_HELPERTEXT' })}
              value={model.friendlyName ?? ''}
              onChange={(event) => handleModelChange('friendlyName', event.target?.value)}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
              fullWidth
              helperText={lookUp({ key: 'CONSOLE_REWARDS_DESCRIPTION_HELPERTEXT' })}
              rows={10}
              maxRows={20}
              multiline
              size="medium"
              value={model.description || ''}
              onChange={(event) => handleModelChange('description', event.target?.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'}>{lookUp({ key: 'CONSOLE_PRICE' })}</Typography>
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_INTERNALPRICE' })}
              fullWidth
              helperText={lookUp({ key: 'CONSOLE_INTERNALPRICE_HELPERTEXT' })}
              value={model.internalPrice || ''}
              onChange={(event) => handleModelChange('internalPrice', event.target?.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              label={lookUp({ key: 'CONSOLE_TOKENPRICE' })}
              fullWidth
              helperText={lookUp({ key: 'CONSOLE_TOKENPRICE_HELPERTEXT' })}
              value={model.tokenPrice || ''}
              onChange={(event) => handleModelChange('tokenPrice', event.target?.value)}
            />
          </Grid>

          {id && (
            <Grid item xs={12}>
              <EditorInfo
                createdAt={model.createdDate}
                modifiedAt={model.lastModifiedDate}
                modifiedBy={model.lastModifiedBy}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

RewardsForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(RewardsForm);
