import React from 'react';
import { lookUp } from 'services/stringService';
import { Link } from 'react-router-dom';
import { arrayOf, node, shape, string } from 'prop-types';

import { CardContent, Card, makeStyles, Typography, Avatar, Button, Grid } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

import { CenteredBlock } from './index';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: blue[500],
    color: '#ffffff',
    width: '100%',
    marginTop: theme.spacing(3),
    '&:hover': {
      backgroundColor: blue[700]
    }
  },
  avatar: {
    color: blue[500],
    backgroundColor: 'transparent',
    border: '1px solid blue[600]',
    padding: theme.spacing(4.5),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 320
  },
  gridItem: {
    maxWidth: 250,
    minHeight: 320
  }
}));

const PageCard = (props) => {
  
  const classes = useStyles();
  const { pages } = props;

  return (
    pages &&
    pages.length !== 0 && (
      <CenteredBlock height={'88vh'}>
        <Grid container justifyContent={'center'} alignItems={'center'} spacing={3}>
          {pages.map((page) => (
            <Grid item xs={12} md={3} xl={4} key={page.link} className={classes.gridItem}>
              <Card>
                <CardContent className={classes.card}>
                  <Avatar className={classes.avatar}>
                    {page.icon ? page.icon : page.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant="h4" component="h2" align={'center'}>
                    {page.name}
                  </Typography>
                  <Button component={Link} to={page.link} className={classes.button}>
                    Go To
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CenteredBlock>
    )
  );
};

PageCard.propTypes = {
  pages: arrayOf(
    shape({
      name: string.isRequired,
      link: string.isRequired,
      icon: node
    })
  ).isRequired
};

export default PageCard;
