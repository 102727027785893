import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Loader } from 'components';
import catalogServiceV3 from 'services/catalogServiceV3';
import AutomaticCatalogsForm from './AutomaticCatalogs/form';
import ManualCatalogsForm from './ManualCatalogs/form';
import AlertService from 'services/alertService';
import { useNavigate, useParams } from 'react-router-dom';
import SectionDrawer from 'components/SectionDrawer';
import ContentSelector from 'components/ContentSelector';
import CustomerSelector from 'components/CustomerSelector';
import { addCatalogRelation, addCustomerRelation } from 'services/collectionsServiceV2';


function CatalogsForm(props) {
  const {
    enqueueSnackbar,
  } = props;

  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAutomatic, setIsAutomatic] = useState(undefined);
  const [catalogData, setCatalogData] = useState([]);

  const [isTagCloudLoading, setIsTagCloudLoading] = useState(false);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [targetType, setTargetType] = useState(null);
  const [triggers, setTriggers] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleAddRelationship = (
    selectedId,
    relation = targetType,
    relationTargetType = selectedRelation,
  ) => {
    // NOTE adding check from prior implementation, commit SHA 35f9027365269edde216d1d4875b2d07a6dc51a5
    // FIXME why do we need to assign Projects to Project, for consistency we use should use Project
    if (relation === 'Projects') {
      reference = reference.replace('s', '');
    }

    addCatalogRelation(id, relationTargetType, selectedId).then(() =>
      setTriggers((o) => ({ ...o, [relationTargetType]: !o[relationTargetType] })),
    );
  };

  const handleCloseDrawer = ()=>{
    setIsDrawerOpen(false);
  }


  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      catalogServiceV3.get(id)
        .then(data => {
          setCatalogData(data);
          if (data.kind === 'Automatic') {
            setIsAutomatic(true);
          } else {
            setIsAutomatic(false);
          }
        })
        .catch((error) => {
          if (error.status === 404 || error.message?.includes('404')) {
            error.response.data.message = lookUp({ key: 'CONSOLE_NOT_FOUND_MESSAGE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG'})});
          }
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG'}) })
          });
          navigate('/apps/catalogs-v3');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (id) getData();
    else setIsAutomatic(true);
  }, []);

  return isLoading || isAutomatic === undefined ? (
    <Loader />
  ) : (
    <>
      {isAutomatic ? (
        <AutomaticCatalogsForm
          id={id}
          tab={tab}
          data={catalogData}
          history={history}
          isAutomatic={isAutomatic}
          setIsAutomatic={setIsAutomatic}

          setIsTagCloudLoading={setIsTagCloudLoading}
          isTagCloudLoading={isTagCloudLoading}
          selectedRelation={selectedRelation}
          setSelectedRelation={setSelectedRelation}
          setTargetType={setTargetType}
          triggers={triggers}
          openSelector={handleOpenDrawer}
        />
      ) : (
        <ManualCatalogsForm
          id={id}
          tab={tab}
          data={catalogData}
          history={history}
          isAutomatic={isAutomatic}
          setIsAutomatic={setIsAutomatic}

          setIsTagCloudLoading={setIsTagCloudLoading}
          isTagCloudLoading={isTagCloudLoading}
          selectedRelation={selectedRelation}
          setSelectedRelation={setSelectedRelation}
          setTargetType={setTargetType}
          triggers={triggers}
          openSelector={handleOpenDrawer}
        />
      )}
      <SectionDrawer open={isDrawerOpen} onClose={handleCloseDrawer}>
        {
          // FIXME on this ContentForm component values are assigned incorrectly, targetType should be selectedRelation and viceversa
          targetType === 'Content' ? (
            <ContentSelector
              // NOTE we need to invert the values
              selectedRelation={targetType}
              selectedRelationTargetType={selectedRelation}
              targetType={selectedRelation}
              onClose={handleCloseDrawer}
              onSelect={handleAddRelationship}
            />
          ) : (
            <CustomerSelector
              // NOTE we need to invert the values
              selectedRelation={targetType}
              selectedRelationTargetType={selectedRelation}
              targetType={targetType}
              onClose={handleCloseDrawer}
              onSelect={handleAddRelationship}
            />
          )
        }
      </SectionDrawer>
    </>
  );
};

CatalogsForm.propTypes = {
  enqueueSnackbar: func.isRequired,
};


export default withSnackbar(CatalogsForm);
