import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import ReactDropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    minHeight: '30vh',
    transition: 'all .24s ease-in-out',
    outline: 'none',
    background: theme.palette.grey[100],
    border: `4px dashed ${theme.palette.grey[300]}`,
    color: theme.palette.getContrastText(theme.palette.grey[100]),
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey[200],
      borderColor: theme.palette.primary.light,
    },
    '& img': {
      maxHeight: 480,
      maxWidth: '100%',
    },
  },
}));

const Dropzone = ({ children, isActive = false, ...options }) => {
  const classes = useStyles();

  return (
    <ReactDropzone {...options}>
      {({ getRootProps, getInputProps }) => (
        <Grid
          {...getRootProps()}
          className={classes.root}
          justifyContent="center"
          direction="column"
          alignItems="center"
          container
        >
          <input {...getInputProps()} />
          {isActive ? (
            <CloudUploadIcon fontSize="large" color="disabled" />
          ) : (
            children || (
              <>
                <CloudUploadIcon fontSize="large" color="disabled" />
                <Typography variant="body2">
                  {lookUp({
                    key: 'CONSOLE_INGEST_DROP_TEXT_TEMPLATE',
                    fileType: lookUp({ key: 'CONSOLE_A_FILE' }),
                  })}
                </Typography>
              </>
            )
          )}
        </Grid>
      )}
    </ReactDropzone>
  );
};

Dropzone.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default Dropzone;
