import { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import { msToTime } from 'helpers/time';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'horizontal',
    justifyContent: 'space-between',
    minHeight: 60,
    marginBottom: -40,
    borderLeftStyle: '1px solid black',
  },
  markerDiv: {
    borderLeft: '1px dotted grey',
    width: 1,
  },
  timeLabel: {
    position: 'relative',
    left: 1,
    top: -6,
  },
  cursor: {
    position: 'relative',
    transition: 'left linear 0.25s',
    marginTop: -50,
    height: 50,
    width: 1,
    top: 60,
    backgroundColor: 'black'
  }
}))

const TimeLabeler = ({ duration, getCurrentTime }) => {

  const classes = useStyles();
  const timeTrim = duration > 60 * 60 * 1000 ? [0, 5] : [3, 5];
  const X = 20;
  const zeroToX = (x) => Object.keys(Array.from(Array(x+1))).map((e) => +e);
  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    setCurrentTime(getCurrentTime() || 0);
    const updateTime = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 250);
    return () => clearInterval(updateTime);
  }, [])


  return (
    <>
    <div className={classes.cursor} style={{left: `calc(100% * ${currentTime * 1000} / ${duration})`}}/>
    <div className={classes.root}>
      { zeroToX(X).map((n, i) => 
        <div key={i} className={classes.markerDiv}>
          <div className={classes.timeLabel}>
            {!duration ? '' : i !== X && msToTime(duration * (n/X))?.split('.')[0].substr(...timeTrim)}
          </div>
        </div>)
      }
      </div>
    </>
  )
};

export default TimeLabeler;