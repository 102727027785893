import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getObjectTypes = () => {
  return axios
    .get(`${getConsoleApiEndpoint('Tracewind')}/GetObjectTypes`)
    .then((response) => response?.data)
}

const typeMap = () => {  // This is where we could query type mappings
  return {
    Asset: 'Content',
    Feed: 'Content',
    Text: 'Content',
    Stream: 'Content',
    Advert: 'Content',
    Trailer: 'Content',
    Subscription: 'Sku',
    Rental: 'Sku',
    Purchase: 'Sku',
    Artist: 'Artist',
  }
}

const mapTypes = (objectType) => {
  const tracewindType = typeMap()[objectType]; // Backend response turned out to be not general enough timeFrameEnd do this.
  return objectType;
};

const getAuditLogs = (objectType, objectId, timeFrameStart, timeFrameEnd) => {
  const path = `Search/${objectType}/${objectId}?timeFrameStart=${timeFrameStart}&timeFrameEnd=${timeFrameEnd}`;
  return axios.get(`${getConsoleApiEndpoint('Tracewind')}/${path}`)
    .then(response => response?.data)
};

const getLogs = (loadParams) => {
  const {
    objectType, objectId,
    perPage = 10, page = 0,
    from, to } = loadParams;

  if (!objectType || !objectId) return [];

  if (!/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/.test(objectId)) return;

  if ((from && !to) || (!from && to)) return [];

  let path = `Search/${objectType}/${objectId}/${perPage}/${page}?sortBy=CreatedDate DESC`;
  
  if (from && to) {
    path += `&timeFrameStart=${from}&timeFrameEnd=${to}`;
  }
  
  return axios.get(`${getConsoleApiEndpoint('Tracewind')}/${path}`)
    .then(response => response?.data)
}

export default {
  getAuditLogs,
  getObjectTypes,
  getLogs,
  mapTypes,
};
