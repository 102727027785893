import React from 'react';
import { lookUp } from 'services/stringService';

import { Grid } from '@material-ui/core';
import { string } from 'prop-types';

const CenteredBlock = (props) => {
  
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: props.height }}
      {...props}
    >
      {props.children}
    </Grid>
  );
};

CenteredBlock.propTypes = {
  height: string
};

CenteredBlock.defaultProps = {
  height: '100vh'
};

export default CenteredBlock;
