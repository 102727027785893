import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { lookUp } from 'services/stringService';
import { DataGrid } from '@material-ui/data-grid';
import {
  Link,
} from '@material-ui/core';
import CreatedOn from 'components/CreatedOn';
import DeleteButton from 'components/DeleteButton';
import Loader from 'components/Loader';
import DataGridOverlayNoResults from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';
import customersService from 'services/customersService';
import alertService from 'services/alertService';

const SORT_BY_COLUMN = 'purchaseDate';
const SORT_RULE = 'desc';

const SubscriptionsTab = ({
  id,
  sortBy = SORT_BY_COLUMN,
  sortRule = SORT_RULE,
}) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortModel, setSortModel] = useState([
    {
      field: sortBy,
      sort: sortRule,
    },
  ]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      try {

        const { data = [] } = await customersService.getSubscriptions(id);
        const subscriptionsWithId = data.map?.((subscription = {}) => ({
          ...subscription,
          id: subscription.productId,
        }));

        setSubscriptions(subscriptionsWithId);
      } catch (error) {
        alertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_BRANDS' }),
          }),
        });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const handleDelete = (item) => {
    // TODO implement delete
  };

  const isSortableColumn = (fieldName = '') => sortBy === fieldName;

  const columns = [
    {
      field: 'store',
    },
    {
      field: 'name',
      flex: 2,
    },
    {
      field: 'productId',
      flex: 1,
    },
    {
      field: 'productType',
      flex: 1,
    },
    {
      field: 'status',
      flex: 1,
    },
    {
      field: 'purchaseDate',
      flex: 1,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'expiresDate',
      flex: 1,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'invoiceUrl',
      flex: 1,
      renderCell: (item) => item.row.invoiceUrl ? (
        <Link target="_blank" href={`${item.row.invoiceUrl}`}>
          <span nowrap="true">{item.value}</span>
        </Link>
      ) : null
    },
    {
      field: 'id',
      headerName: ' ',
      width: 50,
      renderCell: (item) => (
        <DeleteButton onDelete={() => handleDelete(item)} variant="icon" size="small" />
      ),
    },
  ];

  return isLoading ? (
    <Loader inline />
  ) : (

    // TODO implement reusable normalized component <Lister />
    <DataGrid
      rows={subscriptions}
      sortModel={sortModel}
      components={{
        LoadingOverlay: DataGridOverlayLoaderLinear,
        NoRowsOverlay: DataGridOverlayNoResults,
        NoResultsOverlay: DataGridOverlayNoResults,
      }}
      componentsProps={{
        noRowsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
        noResultsOverlay: {
          title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
        },
        pagination: {
          classes: null,
          labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
          labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
        },
      }}
      columns={columns.map?.((column = {}) => ({
        ...column,
        sortable: false,
        filtrable: false,
        headerName: column.headerName === ' ' ? ' ' : lookUp({ key: `CONSOLE_${column.field}` }),
        ...(column.width && { width: column.width }),
      }))}
      disableSelectionOnClick
      autoHeight
      pagination
    />
  );
};

SubscriptionsTab.propTypes = {
  id: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,

  // https://v4.mui.com/components/data-grid/sorting/#sort-order
  sortRule: PropTypes.oneOf(['asc', 'desc', null]),
};

export default withSnackbar(SubscriptionsTab);
