import TimePicker from 'react-time-picker';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: (props) => ({
    color: 'rgba(0,0,0,0.54)',
    display: 'block',
    position: 'absolute',
    top: props.isButton ? -8 : -3,
    left: 8,
    backgroundColor: props.backgroundColor || 'white',
    zIndex: 100,
    fontSize: '12px',
    padding: '0px 6px',
    whiteSpace: 'nowrap',
  }),
  timePicker: {
    width: '100%',
    marginBottom: 5,
    marginTop: 5,
    '& .react-time-picker__wrapper': {
      padding: '6px 10px',
      border: '1px solid rgba(0,0,0,0.23)',
      borderRadius: '4px',
      '&:hover': {
        border: '1px solid black',
      },
    },
    '& .react-time-picker__inputGroup': {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      padding: 0,
    },
    '& .react-time-picker__inputGroup__leadingZero': {
      paddingBottom: 1,
    },
    '& .react-time-picker__inputGroup__divider': {},
  },
  button: {
    width: '100%',
    marginBottom: 5,
    marginTop: 5,
    padding: '6px 10px',
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '4px',
    '&:hover': {
      border: '1px solid black',
    },
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '16px',
  }
});

const MuiStyleTimePicker = ({
  label,
  isButton = false,
  value,
  onChange = () => null,
  backgroundColor = 'white',
}) => {
  const classes = useStyles({ backgroundColor: backgroundColor, isButton });

  return (
    <div style={{ position: 'relative' }}>
      <div className={classes.label}>{label}</div>
      {!isButton ? (
        <TimePicker
          disableClock
          format="HH:mm:ss"
          clearIcon={null}
          className={classes.timePicker}
          maxDetail="second"
          value={value}
          style={{ marginBottom: 15, backgroundColor: backgroundColor }}
          onChange={onChange}
        />
      ) : (
        <div
          className={classes.button}
          onClick={(e) => {
            e.stopPropagation();
            e.target.blur();
            e.stopPropagation();
            isButton(e);
          }}
        >{value?.split('.')[0]}</div>
      )}
    </div>
  );
};

export default MuiStyleTimePicker;
