import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, shape, string, func, oneOf } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import { styled } from '@material-ui/core/styles';
import { ColorPicker, PropsSelect } from 'components'
import {
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Divider,
  Button,
  Typography
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center'
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  paper: {
    height: '500px',
},
right: {
  marginLeft: 'auto'
},
left: {
  marginRight: 'auto'
}
}));

const SaveButton = styled(Button)({
    background: '#00897b',
    color: 'white',
  });
  const CancelButton = styled(Button)({
    background: '#f57f17',
    color: 'white',
  });

function DecorationFormModal(props) {
  
  const classes = useStyles();
  const { handlePropertiesChange, item } = props;  

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState(item.properties);
  const [designFilteredList, setDesignFilteredList] = useState([])
  const [notDesignList, setNotDesignList] = useState([])
  const [saveData, setSaveData] = useState([])

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleItemsChange = (index, key, value) => {
    setNotDesignList((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value} : p)));
  };
   
  const handleNotDesignItemRemove = (index) => {
    setNotDesignList((prev) => prev.filter((p, i) => i !== index));
  };

  const handleItemsAdd = () => {
    if (notDesignList.length < 0) {
      setNotDesignList(() => [
        {
          collection: '',
          name: '',
          value: '',
        }
    ]);
    } else {
     setNotDesignList((prev) => [
       ...prev,
       {
         collection: '',
         name: '',
         value: '',
       }
     ]);
    }
  };

  const contentDesign = [
    {'collection': '', 'name': 'Content:Design:Light:FontColor:Main', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Dark:FontColor:Main', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Light:FontColor:ShortInfo', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Dark:FontColor:ShortInfo', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Light:FontColor:Artist', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Dark:FontColor:Artist', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Light:Background:Color', 'value': ''},
    {'collection': '', 'name': 'Content:Design:Dark:Background:Color', 'value': ''}
  ];

  useEffect(() => {
    const getDesignProps = async () => {
      if (items && Array.isArray(items)) {
        const filteredList = items.filter(props => props.name.startsWith('Content:Design'))
        const notDesignList = items.filter(props => !props.name.startsWith('Content:Design'))
        let filler = filteredList.map(f => f.name)
          const freeBrandOptions = contentDesign?.filter((o) => !filler.includes(o.name));
          const newResult = [...filteredList, ...freeBrandOptions];
          setDesignFilteredList(newResult);
          const existsValues = newResult?.filter(item => item.value !== '');
          setSaveData(existsValues);
          setNotDesignList(notDesignList);
      } else {
        setDesignFilteredList(contentDesign);
      }
    }
    getDesignProps()
  }, []);

  const handleColorPropChange = (key, value) => {
    let exists = false;
    const newItems = designFilteredList.map(item => {
      if (item.name === key) {
        exists = true;
        item.value = value;
        return item
      } else {
        return {...item}
      }
    });
    if (!exists) {
      newItems.push({'collection': '', 'name': key, 'value': value})
    }
    const result = newItems?.filter(item => item.value !== '')
    setSaveData(result)
  }

  return (
    <>
     <Button color="primary" onClick={handleClickOpen}>
       Decoration
     </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
        maxWidth="md"
      >
        <DialogContent>
          <Typography variant={'subtitle1'} className={classes.marginTop} gutterBottom>
              {props.decorationTitle}
          </Typography>
          <Divider className={classes.marginX} />

          {designFilteredList &&  (
            <Grid container alignItems={'center'}  className={classes.marginX} spacing={2}>
              {designFilteredList.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    className={item.name.includes('Light') ? classes.right :  classes.left}
                  >
                    <Grid>
                      <Grid>
                        <Grid>
                          <Grid>
                            <ColorPicker
                              label={item.name}
                              handleChange={( value ) => handleColorPropChange(item.name, value)}
                              color={item.value}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
              )
            })
          }
            </Grid>
          )}

          {notDesignList && notDesignList.length !== 0 && (
            <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
              {notDesignList.map((item, index) => {
                return (
                  <Grid item xs={12} key={index}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={11}>
                      <Grid container alignItems={'center'} spacing={2}>
                        <Grid item xs={12} md={6}>
                          <PropsSelect
                            label={lookUp({ key: 'CONSOLE_NAME' })}
                            value={item.name}
                            handleChange={(value) => handleItemsChange(index, 'name', value)}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            label={lookUp({ key: 'CONSOLE_VALUE' })}
                            fullWidth
                            value={item.value || ''}
                            onChange={(e) => handleItemsChange(index, 'value', e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1} className={classes.textCenter}>
                      <IconButton onClick={() => handleNotDesignItemRemove(index)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                )}
              )}
            </Grid>
          )}
    
          <Grid>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleItemsAdd}
              startIcon={<Add />}
            >
              Decor Item
            </Button>
          </Grid> 
      
        </DialogContent>
        <Divider className={classes.marginX} />
        <DialogActions style={{marginTop: "30px"}}>
          <CancelButton
            onClick={handleClose}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </CancelButton>
          <SaveButton 
            color="primary"
            onClick={() => {
              handlePropertiesChange(item.id, [...saveData, ...notDesignList]);
              handleClose()
            }}
          >
            {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
          </SaveButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

DecorationFormModal.propTypes = {
    contentCollection: arrayOf(
        shape({
            properties: arrayOf(
                shape({
                  collection: string,
                  name: string,
                  value: string,
            }))
        }) 
  ),
  handleChange: func
};

export default DecorationFormModal;
