import { withSnackbar } from 'notistack';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import {
    AreaChart,
    Area,
    Bar,
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Cell,
    ResponsiveContainer,
} from 'recharts';
import AlertService from 'services/alertService';
import { lookUp } from 'services/stringService';
import { Loader } from 'components';
import { getProductReport } from 'services/reportingService';
import SectionContainer from 'components/SectionContainer';

const CHART_MIN_HEIGHT = 320;

const useStyles = makeStyles({
    yLabel: {
        transform: 'rotate(-90deg)',
        textAlign: 'center',
        position: 'relative',
        left: '-42%',
        top: '50%',
    },
    xLabel: {
        width: '100%',
        textAlign: 'center',
        marginTop: -30,
    },
    chartContainer: {
        minHeight: CHART_MIN_HEIGHT,
    },
});

const ReportTab = ({ id, enqueueSnackbar }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dailyUserSubscription, setDailyUserSubscription] = useState([]);
    const [dailyLoginPlayBackSubscription, setDailyLoginPlayBackSubscription] = useState([]);
    const [aggregatedLoginPlayBackSubscription, setAggregatedLoginPlayBackSubscription] = useState([]);
    const [dailyGiftTracking, setDailyGiftTracking] = useState([]);
    const [aggregatedGiftTracking, setAggregatedGiftTracking] = useState([]);
    const theme = useTheme();
    const classes = useStyles();

    const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

    const chartColors = useSelector((state) => state.brandStyle.chartColors);

    const digestData = (data) =>
        data.dataItems?.map((e) => ({
            ...e,
            date: e.date.substr(5),
            quantity: e.data.reduce((q, d) => (q += d.quantity), 0),
        }));

    const getUserSubscriptionData = async () => {
        try {
            const fetchedData = await getProductReport({
                type: 'CommercialMetrics/Daily/UserSubscriptionSegmentation',
                productId: id,
            });
            setDailyUserSubscription(digestData(fetchedData));
        } catch (error) {
            AlertService.displayError({
                msgBar: enqueueSnackbar,
                error,
            });
        }
    };

    const getDailyLoginPlaybackSubscriptionData = async () => {
        try {
            const fetchedData = await getProductReport({
                type: 'ConsumptionMetrics/Daily/LoginPlayback',
                productId: id,
            });
            setDailyLoginPlayBackSubscription(digestData(fetchedData));
        } catch (error) {
            AlertService.displayError({
                msgBar: enqueueSnackbar,
                error,
            });
        }
    };

    const getaggregatedLoginPlayBackSubscriptionData = async () => {
        try {
            const fetchedData = await getProductReport({
                type: 'ConsumptionMetrics/Aggregated/LoginPlayback',
                productId: id,
            });
            setAggregatedLoginPlayBackSubscription(digestData(fetchedData));
        } catch (error) {
            AlertService.displayError({
                msgBar: enqueueSnackbar,
                error,
            });
        }
    };

    const getDailyGiftTrackingData = async () => {
        try {
            const fetchedData = await getProductReport({
                type: 'CommercialMetrics/Daily/GiftTracking',
                productId: id,
            });
            setDailyGiftTracking(digestData(fetchedData));
        } catch (error) {
            AlertService.displayError({
                msgBar: enqueueSnackbar,
                error,
            });
        }
    };

    const getaggregatedGiftTrackingData = async () => {
        try {
            const fetchedData = await getProductReport({
                type: 'CommercialMetrics/Aggregated/GiftTracking',
                productId: id,
            });
            setAggregatedGiftTracking(digestData(fetchedData));
        } catch (error) {
            AlertService.displayError({
                msgBar: enqueueSnackbar,
                error,
            });
        }
    };

    const Bars = useMemo(() => {
        return (
            <Bar dataKey="value">
                {aggregatedLoginPlayBackSubscription.map((barCh, i) =>
                    <Cell key={`${barCh.name}_i`} fill={chartColors[i]} />
                )}
            </Bar>
        );
    }, [aggregatedLoginPlayBackSubscription]);

    useEffect(() => {
        setIsLoading(true);
        getUserSubscriptionData()
            .then(() => getDailyLoginPlaybackSubscriptionData())
            .then(() => getaggregatedLoginPlayBackSubscriptionData())
            .then(() => getDailyGiftTrackingData())
            .then(() => getaggregatedGiftTrackingData())
            .then(() => setIsLoading(false));
    }, []);

    const CustomTick = ({ x, y, stroke, payload }) => (
        <g transform={`translate(${x},${y})`}>
            <text x={-10} y={5} textAnchor="end" fill="#666" transform="rotate(270)">
                {payload.value}
            </text>
        </g>
    );

    return isLoading ? (
        <Loader />
    ) :
        (
            <SectionContainer flex={1}>
                <Grid item xs={12} className={classes.titleItem}>
                    <Typography align="center" variant="h6">
                        {lookUp({
                            key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
                            type: lookUp({ key: `CONSOLE_DAILY_USER_SUBSCRIPTION` })
                        })}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.chartGrid}>
                    <ResponsiveContainer aspect={2} width="100%">
                        <AreaChart
                            data={dailyUserSubscription}
                            margin={{
                                top: 25,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                            <YAxis width={100} />
                            <Tooltip cursor={{ fill: 'transparent' }} />
                            <Area
                                dataKey="quantity"
                                type="monotone"
                                fill={theme.palette?.primary?.main}
                                stroke={theme.palette?.primary?.dark}
                                name={lookUp({ key: 'CONSOLE_DAILY_USER_SUBSCRIPTION_HELPERTEXT' })}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </Grid>

                <Grid item xs={12} className={classes.titleItem}>
                    <Typography align="center" variant="h6">
                        {lookUp({
                            key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
                            type: lookUp({ key: `CONSOLE_DAILY_LOGIN_PLAYBACK` })
                        })}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.chartGrid}>
                    <ResponsiveContainer aspect={2} width="100%">
                        <AreaChart
                            data={dailyLoginPlayBackSubscription}
                            margin={{
                                top: 25,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                            <YAxis width={100} />
                            <Tooltip cursor={{ fill: 'transparent' }} />
                            <Area
                                dataKey="quantity"
                                type="monotone"
                                fill={theme.palette?.primary?.main}
                                stroke={theme.palette?.primary?.dark}
                                name={lookUp({ key: 'CONSOLE_DAILY_LOGIN_PLAYBACK_HELPERTEXT' })}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </Grid>

                <Grid item xs={12} className={classes.titleItem}>
                    <Typography align="center" variant="h6">
                        {lookUp({ key: `CONSOLE_AGGREGATED_LOGIN_PLAYBACK` })}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.chartGrid} style={{ paddingLeft: '35px' }}>
                    <ResponsiveContainer aspect={2} width="100%">
                        <BarChart data={aggregatedLoginPlayBackSubscription}
                            margin={{
                                top: 25,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="name" />
                            <YAxis tickFormatter={tickFormatter} />
                            <Tooltip
                                cursor={{ fill: 'transparent' }}
                                separator={''}
                                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
                            />
                            {Bars}
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>

                {/* CommercialMetrics Gift Tracking Report Start*/}
                <Grid item xs={12} className={classes.titleItem}>
                    <Typography align="center" variant="h6">
                        {lookUp({
                            key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
                            type: lookUp({ key: `CONSOLE_DAILY_GIFT_TRACKING` })
                        })}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.chartGrid}>
                    <ResponsiveContainer aspect={2} width="100%">
                        <AreaChart
                            data={dailyGiftTracking}
                            margin={{
                                top: 25,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                            <YAxis width={100} />
                            <Tooltip cursor={{ fill: 'transparent' }} />
                            <Area
                                dataKey="quantity"
                                type="monotone"
                                fill={theme.palette?.primary?.main}
                                stroke={theme.palette?.primary?.dark}
                                name={lookUp({ key: 'CONSOLE_DAILY_GIFT_TRACKING_HELPERTEXT' })}
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </Grid>

                <Grid item xs={12} className={classes.titleItem}>
                    <Typography align="center" variant="h6">
                        {lookUp({ key: `CONSOLE_AGGREGATED_GIFT_TRACKING` })}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.chartGrid} style={{ paddingLeft: '35px' }}>
                    <ResponsiveContainer aspect={2} width="100%">
                        <BarChart data={aggregatedGiftTracking}
                            margin={{
                                top: 25,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="name" />
                            <YAxis tickFormatter={tickFormatter} />
                            <Tooltip
                                cursor={{ fill: 'transparent' }}
                                separator={''}
                                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
                            />
                            {Bars}
                        </BarChart>
                    </ResponsiveContainer>
                </Grid>
                {/* CommercialMetrics Gift Tracking Report Ends*/}
            </SectionContainer>
        );
};

ReportTab.propTypes = {
    id: PropTypes.string,
    enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ReportTab);
