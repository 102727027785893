import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { lookUp } from 'services/stringService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'middle',
    alignContent: 'center',
    width: '100%',
    height: 'auto',
    minHeight: '100%',
    color: theme.palette.text.disabled,
  },
}));

const NoResults = ({
  children,
  message,
  title = lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
}) => {
  const classes = useStyles();

  const getText = (text) => {
    return typeof text === 'string' && text.trim() !== ''
      ? text
      : lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' });
  };

  return (
    <Grid container className={classes.root}>
      {children && (
        <Grid item sm={12} justfifyContent="center" alignItems="middle" alignContent="center">
          {children}
        </Grid>
      )}
      <Grid item>
        {title && <Typography gutterBottom>{title}</Typography>}
      </Grid>
      <Grid item>
        {message && <Typography variant="caption">{getText(message)}</Typography>}
      </Grid>
    </Grid>
  );
};

NoResults.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default NoResults;
