import { useState, useEffect, useCallback } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  Link as RouterLink,
} from 'react-router-dom';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { getContentTypes } from 'store/reducers/contentReducer';
import { func } from 'prop-types';
import { withSnackbar } from 'notistack';
import isBoolean from 'lodash/isBoolean';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import {
  ArtTrack,
  BarChart,
  Language,
  Archive,
  Colorize,
  SlowMotionVideo,
  ViewList,
  Comment,
  Queue,
  Tv,
  Forum,
  Star,
  StarOutline,
} from '@material-ui/icons';
import { EditorInfo, Loader, Tabbing } from 'components';
import { lookUp } from 'services/stringService';
import { getConsoleEnvName, goBackOrStay } from 'helpers/common';
import BasicTab from './BasicTab';
import Localization from './Localization';
import ExhibitionWindow from './ExhibitionWindow';
import TagsTab from './TagsTab';
import ImagesTab from './ImagesTab';
import AssetsTab from './AssetsTab';
import DesignProps from './DesignProps';
import ArchiveTab from './ArchiveTab';
import ConsumptionTab from './ConsumptionTab';
import ReviewsTab from './ReviewsTab';
import WhereToWatch from './WhereToWatch';
import DiscussionTab from './DiscussionTab';
import Items from './Items';
import { getTypes } from 'helpers/content';
import withFileUpload from 'helpers/withFileS3Upload';
import contentService from 'services/contentService';
import parametersService from 'services/parametersService';
import parametersAction from 'store/actions/parametersAction';
import jobManagerService from 'services/jobManagerService';
import contentAction from 'store/actions/contentAction';
import navigationAction from 'store/actions/navigationAction';
import contentConst from 'constants/contentConst';
import {
  getContentRelation,
  addContentRelation,
  deleteContentRelation,
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
  getContentRelationsEnum,
  updateMultipleRelation,
} from 'services/collectionsServiceV2';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';
import ThemedButton from 'components/ThemedButton';
import ContentSelector from 'components/ContentSelector';
import CustomerSelector from 'components/CustomerSelector';
import { sidebarWidth } from 'constants/index.js';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import SectionDrawer from 'components/SectionDrawer';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  buttonPanel: {
    position: 'fixed',
    top: HEADER_MIN_HEIGHT,
    zIndex: 9,
    backgroundColor: theme.palette?.background?.default || 'white',
    padding: 0,
    paddingRight: theme.spacing(4),
    width: (props) => `calc(100% - ${props.collapsed ? sidebarWidth.closed : sidebarWidth.open}px)`,
  },
  searchSidebar: {
    position: 'fixed',
    right: 0,
    width: 320,
    height: '100%',
    // FIXME should use a ref in order to capture the header clientHeight when header slides hides/appear instead of magic value 188
    top: props => props.header ? (HEADER_MIN_HEIGHT * 3) + theme.spacing(1) : 0,
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.divider,
    zIndex: theme.zIndex.appBar - 1,
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },
  textField: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginTopLarge: {
    marginTop: theme.spacing(5),
  },
  highlightIcon: {
    color: theme.palette.secondary?.main || 'yellow',
  },
  backToParentButton: {
    marginTop: theme.spacing(4),
  },
}));


function ContentForm({
  enqueueSnackbar,
  upload,
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = useSearchParams();
  const tab = location.hash?.substring(1);
  const returnUrl = search.get('returnUrl');
  const type = search.get('type');

  const emptyDesign = {
    mainTextColor: '#000000',
    shortInfoColor: '#000000',
    artistTextColor: '#000000',
    backgroundColor: '#000000',
  };

  const modelProto = {
    type,
    originalFileName: '',
    originalTitle: '',
    originalLanguage: '',
    localizations: {},
    exhibitionWindow: {},
    color: '',
    credits: [],
    labels: {
      Category: [],
      Tag: [],
      Place: [],
      Location: [],
      Album: [],
      Keyword: [],
      Genre: [],
      Person: [],
      Mood: [],
    },
    themes: {
      LightDesign: emptyDesign,
      DarkDesign: emptyDesign,
    },
    allowComments: false,
    allowUserRating: false,
    allowRemix: false,
    allowSlideshow: false,
    allowLyrics: false,
    assets: [],
    authGroupIds: [],
    createdDate: '',
    downloadable: false,
    duplicate: false,
    externalCurrency: null,
    externalId: '',
    externalPrice: null,
    forceIndexed: '',
    internalPrice: null,
    lastModifiedBy: '',
    lastModifiedDate: '',
    ownerId: '',
    properties: {},
    published: false,
    publishedDate: '',
    referenceId: '',
    releaseDate: '',
    referenceItems: {}, // Technical property with array of playlist items, to be saved on save by collections service.
  };

  const trigger = useScrollTrigger();
  const classes = useStyles({ collapsed: isSidebarCollapsed, header: !trigger });

  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const contentTypes = useSelector(getContentTypes);
  const userRole = useSelector((state) => state.auth.user.role);
  const ownId = useSelector((state) => state.auth.user.id);
  const { editpage } = useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const editablePlaylist = useSelector((state) => state.content.editablePlaylist);
  const isChildContentRedirect = useSelector((state) => state.content.isChildContentRedirect);
  const allowedTabs = editpage?.content;

  const [selectedRelation, setSelectedRelation] = useState(null);
  const [targetType, setTargetType] = useState(null);
  const [triggers, setTriggers] = useState({});
  const [isFavourite, setIsFavourite] = useState(false);
  const [contentType, setContentType] = useState(
    Object.values(contentConst.types).find((type) => location.pathname.includes(type)) || ''
  );
  const [model, setModel] = useState(modelProto);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isTranscoding, setIsTranscoding] = useState(false);
  const [contentTypeOptions, setContentTypeOptions] = useState([]);
  const [isContentTypeOptionsLoading, setIsContentTypeOptionsLoading] = useState(true);
  const [selectedContentType, setSelectedContentType] = useState('Video');
  const [isTagCloudLoading, setIsTagCloudLoading] = useState(false);
  const [finalizeWorkflowId, setFinalizeWorkflowId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({
    contentType: null,
    originalTitle: null,
    originalLanguage: null,
  });
  const [localeOptions, setLocaleOptions] = useState({});

  const itemIds = {};

  const playCollection = contentType === 'collections' || contentType === 'playlists';
  const createTabs = ['basic', 'localization', 'images', 'assets', 'exhibition window'];

  const registerSubAssetRedirect = (redirParams) => {
    dispatch(contentAction.setIsChildContentRedirect(redirParams));
  };

  useEffect(() => {
    jobManagerService
      .getLanguageCodes('Translate')
      .then((codes) => {
        const sortedCodes = Object.entries(codes).sort((a, b) => {
          if (a[1] < b[1]) return -1;
          if (a[1] > b[1]) return 1;

          return 0;
        }).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

        setLocaleOptions({ ...sortedCodes });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_LANGUAGE_CODES' }),
          }),
        });
      });
  }, []);

  // TODO: call this from saveData if needed to force the user to click Save Changes before saving changes
  const onUpdateReference = async (key, value) => {
    if (!id) return;
    let deletedIds = model.referenceItems?.[key] || [];
    try {
      value?.map(async (id) => {
        if (!model.referenceItems[key]?.includes(id)) {
          await addContentRelation(model.id, key, id);
        }
        deletedIds.splice(deletedIds.indexOf(id), 1);
      });
      if (!!deletedIds.length) {
        await Promise.all(deletedIds.map((id) => deleteContentRelation(model.id, key, id)));
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_RELATION' }),
        }),
      });
    }
  };

  const saveContentBatchData = async (key, value) => {
    try {
      let updatePlaylistRequest = {
        "relation": {
          "sourceType": "Content",
          "RelationEnum": key,
          "strength": 0,
          "index": 0,
          "longitude": 0,
          "latitude": 0,
          "country": "",
          "city": ""
        },
        "sourceId": model.id,
        "updateStrategy": "Override",
        "clonedSourceId": "",
        "targets": []
      }
      const updatedRelations = value.map((id, index) => {
        return {
          "targetId": id,
          "extraData": "",
          "createdDate": "",
          "strength": 0,
          "index": index,
          "longitude": 0,
          "latitude": 0,
          "country": "",
          "city": "",
          "relation": key
        }
      });
      updatePlaylistRequest.targets = updatedRelations;
      const batchResponse = await updateMultipleRelation(updatePlaylistRequest);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_RELATION' }),
        }),
      });
    }
  }

  const handleModelChange = (key, value, block = true) => {
    block && !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    if (key === 'referenceItems') {
      Object.entries(value).map((item) => {
        if (JSON.stringify(model.referenceItems[item[0]]) !== JSON.stringify(item[1])) {
          onUpdateReference(item[0], item[1]);
        }
      });
    }
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleItemsChange = async (key, value, block = true) => {
    if (key === 'referenceItems') {
      Object.entries(value).map((item) => {
        itemIds[item[0]] = item[1];
      });
    }
  }

  const handleAuthGroupChange = (options) => {
    options
      ? handleModelChange(
          'authGroupIds',
          options.map((o) => o.value)
        )
      : handleModelChange('festivalIds', options);
  };

  const handleCloseDrawer = () => {
    setSelectedRelation(null);
    setTargetType(null);
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const tabs = [
    {
      name: 'basic',
      content: (
        <BasicTab
          handleChange={handleModelChange}
          handleAuthGroupChange={handleAuthGroupChange}
          model={model}
          triggers={triggers}
          contentTypeOptions={contentTypeOptions}
          setSelectedRelation={setSelectedRelation}
          setTargetType={setTargetType}
          openSelector={handleOpenDrawer}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          localeOptions={localeOptions}
        />
      ),
    },
    ['Collection', 'Playlist', 'Remix'].includes(model.type) && {
      name: 'items',
      icon: <Queue />,
      content: <Items contentId={id} model={model} handleChange={handleItemsChange} />,
    },
    {
      name: 'localization',
      content: (
        <Localization
          handleChange={handleModelChange}
          model={model}
          id={id}
          isImage={selectedContentType === 'Image'}
          handleAuthGroupChange={handleAuthGroupChange}
        />
      ),
    },
    {
      name: 'exhibition window',
      icon: <Language />,
      content: (
        <ExhibitionWindow
          handleChange={handleModelChange}
          model={model}
          id={id}
          handleAuthGroupChange={handleAuthGroupChange}
        />
      ),
    },
    {
      name: 'tags',
      content: (
        <TagsTab
          model={model}
          handleChange={handleModelChange}
          setIsTagCloudLoading={setIsTagCloudLoading}
          isTagCloudLoading={isTagCloudLoading}
          selectedRelation={selectedRelation}
          setSelectedRelation={setSelectedRelation}
          setTargetType={setTargetType}
          triggers={triggers}
          openSelector={handleOpenDrawer}
        />
      ),
    },
    {
      name: 'images',
      content: (
        <ImagesTab
          model={model}
          isTagCloudLoading={isTagCloudLoading}
          handleChange={handleModelChange}
          playCollection={playCollection}
        />
      ),
    },
    {
      name: 'assets',
      content: <AssetsTab model={model} handleChange={handleModelChange} upload={upload} />,
    },
    {
      name: 'appearance',
      icon: <Colorize />,
      content: (
        <DesignProps
          darkDesign={model.themes.DarkDesign || emptyDesign}
          lightDesign={model.themes.LightDesign || emptyDesign}
          handleDarkChange={(data) =>
            handleModelChange('themes', { ...model.themes, DarkDesign: data })
          }
          handleLightChange={(data) =>
            handleModelChange('themes', { ...model.themes, LightDesign: data })
          }
        />
      ),
    },
    {
      name: 'archive',
      icon: <Archive />,
      content: <ArchiveTab model={model} id={id} />,
    },
    !!id && {
      name: 'consumption',
      icon: <BarChart />,
      content: <ConsumptionTab duration={model.duration} id={id} contentType={model.type} />,
    },
    !!id && {
      name: 'reviews',
      icon: <Comment />,
      content: <ReviewsTab model={model} id={id} />,
    },
    {
      name: 'where-to-watch',
      icon: <Tv />,
      content: <WhereToWatch handleChange={handleModelChange} model={model} id={id} />,
    },
    {
      name: 'discussion',
      icon: <Forum />,
      content: <DiscussionTab handleChange={handleModelChange} model={model} id={id} />,
    },
  ];

  const transcodeContent = async () => {
    setIsTranscoding(true);
    try {
      await contentService.reIngest(model.id);
      setIsTranscoding(false);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({
          key: 'CONSOLE_JOB_REQ_SENT_TEMPLATE',
          title: model.originalTitle,
          job: lookUp({ key: 'CONSOLE_TRANSCODE' }),
        }),
      });
    } catch (error) {
      setIsTranscoding(false);
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_PROCESS_TRANSCODE_ERROR_MESSAGE_TEMPLATE',
          title: model.originalTitle || lookUp({ key: 'CONSOLE_ITEM' }),
        }),
      });
    }
  };

  const setUpContentTypes = async () => {
    try {
      const types = contentTypes.length === 0 ? await contentService.getTypeEnums() : contentTypes;
      contentTypes.length === 0 && dispatch(contentAction.setContentTypes(types));

      // sort types asc
      const sortedTypes = types.map?.((typeObject) => {
          const [[label, _unused_type_value]] = Object.entries(typeObject);
          return { label, value: label };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));

      setContentTypeOptions(sortedTypes ?? []);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CONTENT_TYPES' }),
        }),
      });
      setIsContentTypeOptionsLoading(false);
    }
  };

  const filterOutPlaceholders = (newModel) => {
    let filteredModel = Object.keys(newModel).reduce((acc, key) => {
      if (JSON.stringify(newModel[key]) !== JSON.stringify(modelProto[key])) {
        acc[key] = newModel[key];
      }
      return acc;
    }, {});
    return filteredModel;
  };

  const validateModel = (model) => {
    const formErrors = {
      contentType: null,
      originalTitle: null,
      originalLanguage: null,
    };
    const errors = [];
    if (!model.type) {
      errors.push(lookUp({ key: 'CONSOLE_MISSING_CONTENTTYPE_ERROR' }));
      formErrors.contentType = lookUp({ key: 'CONSOLE_MISSING_CONTENTTYPE_ERROR' });
    }
    if (["Remix","Playlist"].includes(model.type) &&!model.originalLanguage) {
      errors.push(lookUp({ key: 'CONSOLE_MISSING_LANGUAGE_ERROR' }));
      formErrors.originalLanguage = lookUp({ key: 'CONSOLE_MISSING_LANGUAGE_ERROR' });
    }
    if (!model.originalTitle) {
      errors.push(lookUp({ key: 'CONSOLE_MISSING_TITLE_ERROR' }));
      formErrors.originalTitle = lookUp({ key: 'CONSOLE_MISSING_TITLE_ERROR' });
    }
    setFormErrors(formErrors);
    return errors;
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    const copyModel = {
      ...model,
      published: publish ? !model.published : model.published,
    };

    const validationErrors = validateModel(copyModel);
    if (validationErrors?.length > 0) {
      validationErrors.forEach((errorMessage) => {
        if (errorMessage.length) {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: errorMessage
          })
        }
      });
      publish ? setIsPublishing(false) : setIsSaving(false);
      return;
    }

    const modelToSave = filterOutPlaceholders(copyModel);

    const runWorkflow = () => {
      jobManagerService
      .runWorkflow({
        workflowTemplateId: finalizeWorkflowId?.[model.originalLanguage],
        name: model.originalTitle,
        referenceObjectId: model.id,
        referenceObjectName: model.originalTitle,
        ownerId: model.ownerId,
      })
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_REMIX_FINALIZE_LAUNCH_MESSAGE' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMIX_FINALIZE_ERROR' }),
        });
      });
    }

    try {
      const savedModel = id
        ? await contentService.edit(modelToSave)
        : await contentService.create(modelToSave);
      if (!id && !!modelToSave.referenceItems) {
        modelToSave.referenceItems.Playlist?.forEach((itemId) =>
          addCustomerRelation(id || savedModel.id, 'Playlist', itemId)
        );
      }
      if (modelToSave.referenceItems) {
        Object.keys(itemIds).forEach(async (key) => {
          saveContentBatchData(key, itemIds[key]);
        });
      }
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message:
          publish && model.published === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.originalTitle })
            : publish && model.published === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.originalTitle })
            : id
            ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.originalTitle })
            : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.originalTitle }),
      });
      if (publish && model.type === 'Remix') runWorkflow();
      const toWhere = returnUrl
        ? decodeURIComponent(returnUrl)
        : window.history.state.idx === 0 ? '/content' : -1;
      goBackOrStay(navigate, toWhere, 500);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      setIsSaving(false);
      setIsPublishing(false);
    }
  };

  const deleteData = async () => {
    setIsDeleting(true);
    try {
      await contentService.remove(id);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      setTimeout(() => navigate(`/content?type=${contentType}`), 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.originalTitle }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
      });
      setIsDeleting(false);
    }
  };

  const getReferenceData = () => {
    getContentRelationsEnum()
      .then((types) => {
        return Promise.allSettled(
          types.map((type) => {
            return getContentRelation(id, type);
          })
        );
      })
      .then((res) => {
        const references = res
          .filter((e) => e.status === 'fulfilled')
          .reduce((acc, refData) => {
            if (!!refData.value.pageContent.length) {
              refData.value.pageContent.sort((a, b) => {
                return a.index - b.index;
              });
              const list = refData.value.pageContent.map((content) => {
                return content.targetId;
              });
              acc = { ...acc, [refData.value.pageContent[0].relation]: list };
            }
            return acc;
          }, {});
        setModel((prev) => ({ ...prev, referenceItems: references }));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_REFERENCE_DATA' }),
          }),
        });
      });
  };

  const getContentData = async () => {
    setIsLoading(true);
    try {
      const data = await contentService.getById(id);
      setContentType(data.type);
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.originalTitle })
        )
      );
      setModel((prev) => ({ ...prev, ...data }));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CONTENT_DATA' }),
        }),
      });
      navigate('../../');
    } finally {
      setIsLoading(false);
    }
  };
  
  const getFinalizeWorkflowId = () => {
    parametersService.getByKey('Finalize:Workflow:Id')
      .then((k) => {
        setFinalizeWorkflowId(JSON.parse(k.value));
      })
      .catch(() => setFinalizeWorkflowId(undefined));
  };

  useEffect(() => {
    const getPlaylistEditorOpen = async () => {
      try {
        const response = await parametersService.getByKey('Console:Playlist:Editor');
        const toOpen = response?.value === 'true';
        dispatch(contentAction.setEditablePlaylist({ toOpen, paramChecked: true }))
      } catch (error) {
        dispatch(contentAction.setEditablePlaylist({ ...editablePlaylist, paramChecked: true }))
      }
    };

    if (!editablePlaylist.paramChecked) getPlaylistEditorOpen()
  }, [editablePlaylist]);

  useEffect(() => {
    if (model.type !== 'Remix' || !!finalizeWorkflowId?.[model.originalLanguage]) return;

    // Initially we are setting it to null, if api throws error we set it to undefined, so we don't fetch twice if finalizedWorkflowId is not null.
    if (finalizeWorkflowId !== null) return;
    getFinalizeWorkflowId();
  }, [model]);

  useEffect(() => {
    setIsContentTypeOptionsLoading(true);
    setUpContentTypes()
      .finally(() => setIsContentTypeOptionsLoading(false));

    try {

      setIsLoading(true);
      
      if (id) {
        getContentData()
          .then(() => getReferenceData())
          .then(() => setIsLoading(false));
      } else {
        dispatch(
          navigationAction.setPageTitle(
            lookUp({
              key: 'CONSOLE_CREATE_TEMPLATE',
              type: lookUp({ key: `CONSOLE_ASSET` }),
            })
          )
        );
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }

    return () => dispatch(navigationAction.setPageTitle(''));
  }, []);

  useEffect(() => {
    if (!id) return;
    getCustomerRelation(ownId, 'FavContent', id)
      .then((e) => setIsFavourite(true))
      .catch((e) => setIsFavourite(false));
  }, [id]);

  useEffect(() => {
    setSelectedRelation(null);
  }, [tab])

  useEffect(() => {
    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) getDefaultLocaleLang();
  }, [defaultLocale]);

  const addToFavourites = (id) => {
    addCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavourite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = (id) => {
    deleteCustomerRelation(ownId, 'FavContent', id)
      .then(() => setIsFavourite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    switch (isFavourite) {
      case true:
        removeFromFavourite(model.id);
        break;
      case false:
        addToFavourites(model.id);
        break;
      default:
        break;
    }
  };

  const startRecording = async () => {
    if (!id) {
      try {
        const data = await contentService.create(model);
        id = data.id;
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      }
    }
    contentService
      .createRecordingContent(
        id,
        `${model.originalTitle} - Recording ${new Date(Date.now()).toLocaleString()}`,
        model.originalLanguage || defaultLocale.lang
      )
      .then((res) => {
        const meetingId = res.data.properties['Content:Process:SessionId'];
        navigate(`/content/content-record/${meetingId}/stream-recording?parentId=${id}`);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          autoHideDuration: 50000,
          context: lookUp({ key: 'CONSOLE_START_RECORDING_ERROR' }),
        });
      });
  };


  const toShowBackToParentButton = isChildContentRedirect.on && isChildContentRedirect.url;
  // TODO -> we shouldn't reload (i.e. hard reset) the whole app by clicking on the 'link'
  const buttons = {
    editor: !allowedTabs?.includes('editor')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_EDITOR_TAB' }),
          link: `/content/${id}/editor`,
          icon: <SlowMotionVideo />,
        },
    logs: !allowedTabs?.includes('logs')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_LOGS_TAB' }),
          link: `/access/logs/Content/${model.id}`,
          icon: <ViewList />,
        },
    favourite: (!allowedTabs?.includes('favorite') || !id)
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_FAVOURITE' }),
          action: setFavourite,
          icon: isFavourite ? (
            <Tooltip title={lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' })}>
              <Star className={classes.highlightIcon} />
            </Tooltip>
          ) : (
            <Tooltip title={lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' })}>
              <StarOutline />
            </Tooltip>
          ),
        },
      redirToParent: !toShowBackToParentButton
        ? null
        : {
          label: lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_ICON' }),
          link: isChildContentRedirect.url,
          icon: (
            <Tooltip title={lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_TOOLTIP' })}>
              <ArtTrack />
            </Tooltip>
          )
        }
  };

 const createTabButtons = useCallback(() => {
  if (model.type === 'Collection' || (model.type === 'Playlist' && !editablePlaylist.toOpen)) {
    return [buttons.favourite, buttons.logs, buttons.redirToParent];
  } else {
    return [buttons.favourite, buttons.editor, buttons.logs, buttons.redirToParent]
  }
  }, [editablePlaylist.paramChecked, model]);

  /**
   * Adds a relathionsip based on selectedRelation
   * FIXME on this ContentForm component values are assigned incorrectly, targetType should be selectedRelation and viceversa
   *
   * @param {string} selectedId a content id
   * @return void
   */
  const handleAddRelationship = (selectedId, relation = targetType, relationTargetType = selectedRelation) => {
    // NOTE adding check from prior implementation, commit SHA 35f9027365269edde216d1d4875b2d07a6dc51a5
    // FIXME why do we need to assign Projects to Project, for consistency we use should use Project
    if (relation === 'Projects') {
      reference = reference.replace('s', '');
    }

    switch (relation) {
      case 'Content':
      case 'Customer':
        addContentRelation(model.id, relationTargetType, selectedId)
          .then(() => setTriggers(o => ({ ...o, [relationTargetType]: !o[relationTargetType] })));
        break;
      case 'Moderator':
      case 'Notification':
      case 'Project':
      default:
        addCustomerRelation(model.id, relationTargetType, selectedId)
          .then(() => setTriggers(o => ({ ...o, [relationTargetType]: !o[relationTargetType] })));
    }
  };


  return (
    <>
      {isLoading && <Loader />}
      {
        !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
          <NoAccess />
        ) : (
          <Box flex={1}>
            <HeaderActionsContainer>
              {
                // Publish action button
                // only render publish/unpublish if the asset already exists
                id && (
                  <ThemedButton
                    color={model.published ? 'secondary' : 'success'}
                    disabled={
                      isPublishing ||
                      isSaving ||
                      (model.type === 'Remix' && !finalizeWorkflowId?.[model.originalLanguage]) ||
                      (model.type === 'Playlist' && !model.referenceItems?.Playlist?.length)
                    }
                    onClick={e => {
                      e.preventDefault();
                      saveData(true);
                    }}
                    loading={isPublishing}
                  >
                    {lookUp({ key: model.published ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON' })}
                  </ThemedButton>
                )
              }
              {
                // Delete action button
                id && (
                  <DeleteButton onDelete={deleteData} loading={isDeleting} disabled={isDeleting} />
                )
              }
              {
                // Transcode action button
                ['Audio', 'Video'].includes(getTypes(model.hRef).contentType) && (
                  <ThemedButton
                    onClick={e => {
                      e.preventDefault();
                      transcodeContent();
                    }}
                    loading={isTranscoding}
                  >
                    {lookUp({ key: 'CONSOLE_TRANSCODE' })}
                  </ThemedButton>
                )
              }
              <Box display="flex" flex={1} justifyContent="center">
                {
                  // Record action button
                  contentType === 'Remix' && (
                    <ThemedButton color="error" onClick={startRecording}>
                      {lookUp({ key: 'CONSOLE_START_RECORDING_BUTTON' })}
                    </ThemedButton>
                  )
                }
              </Box>
              {/* Cancel action Button */}
              <ThemedButton
                onClick={e => {
                  e.preventDefault();
                  dispatch(navigationAction.allowNavigation());
                  if (getConsoleEnvName() === 'Nexius') {
                    navigate(`/content/${id}/editor`);
                  } else {
                    window.history?.state?.idx === 0
                      ? navigate('/content')
                      : navigate(window.history?.state?.idx === 0 ? '/' : -1);
                  }
                }}
                disabled={isSaving || isPublishing}
              >
                {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
              </ThemedButton>

              {/* Save action Button */}
              <ThemedButton
                color="success"
                onClick={e => {
                  e.preventDefault();
                  saveData(false);
                }}
                disabled={isSaving || isPublishing}
                loading={isSaving}
              >
                {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
              </ThemedButton>
            </HeaderActionsContainer>

            {toShowBackToParentButton && (
               <Box className={classes.backToParentButton}>
                 <ThemedButton
                   color="secondary"
                   component={RouterLink}
                   to={isChildContentRedirect.url}
                   onClick={() => registerSubAssetRedirect({ ...isChildContentRedirect, url: '' })}
                 >
                   {lookUp({ key: 'CONSOLE_SUBASSET_REDIRECT_BUTTON' })}
                 </ThemedButton>
               </Box>
            )}

            <Tabbing
              // FIXME replace tabs with props left and right in order to render into Toolbar edges('start', 'end')
              tabs={tabs
                  .sort(
                    (a, b) =>
                      allowedTabs.indexOf(a.name?.replace(' ', '-')) -
                      allowedTabs.indexOf(b.name?.replace(' ', '-'))
                  )
                  .filter((tab) => location.pathname.includes('content/create') ? createTabs.includes(tab.name) : 
                  !isBoolean(tab) && allowedTabs?.includes(tab.name?.replace(' ', '-')))}
              tab={tab}
              buttons={createTabButtons()}
              paddingTop={toShowBackToParentButton ? 10 : 30}
              addTopMargin={HEADER_MIN_HEIGHT}
              scrollButtons="on"
            />

            <SectionDrawer
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
            >
              {
                // FIXME on this ContentForm component values are assigned incorrectly, targetType should be selectedRelation and viceversa
                targetType === 'Content' ? (
                  <ContentSelector
                    // NOTE we need to invert the values
                    selectedRelation={targetType}
                    selectedRelationTargetType={selectedRelation}
                    targetType={selectedRelation}
                    onClose={handleCloseDrawer}
                    onSelect={handleAddRelationship}
                  />
                ) : (
                  <CustomerSelector
                    // NOTE we need to invert the values
                    selectedRelation={targetType}
                    selectedRelationTargetType={selectedRelation}
                    targetType={targetType}
                    onClose={handleCloseDrawer}
                    onSelect={handleAddRelationship}
                  />
                )
              }
            </SectionDrawer>
            
            {id && (
              <EditorInfo
                createdAt={model.createdDate}
                modifiedAt={model.lastModifiedDate}
                modifiedBy={model.lastModifiedBy}
              />
            )}
          </Box>
        )
      }
    </>
  );
}

ContentForm.propTypes = {
  enqueueSnackbar: func,
};

export default compose(withFileUpload, withSnackbar)(ContentForm);
