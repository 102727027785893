import React, { useMemo, useState } from 'react';
import { lookUp } from 'services/stringService';

import { Grid, TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '93%',
    margin: theme.spacing(1, 1),
  },
}));

const LifecycleDropdown = (props) => {
  
  const classes = useStyles();
  const { onAttribChange, selectedNode, lifecycleType, enqueueSnackbar } = props;

  const { nodeId, label } = selectedNode;

  const [options, setOptions] = useState([]);
  const [jobTime, setJobTime] = useState('');

  const typesRule = {
    archivationLifecycleType: 1,
    deletionLifecycleType: 2,
  };

  const typesMatch = {
    "archive-no": 100,
     "archive-after-7days":  101,
     "archive-after-14days":  102,
     "archive-after-30days":  103,
     "archive-after-60days":  104,
     "delete-no": 200,
     "delete-after-30days":  201,
     "delete-after-60days":  202,
  }

  const handleSelect = (value) => {
    if (!value) return;
    setJobTime(value)
    onAttribChange(value, 'text', nodeId, lifecycleType);
  };

  useMemo(() => {
    jobManagerService.getLifecycleJobTypes()
    .then((types) => {
      let options = Object.entries(types).reduce((acc, type) => {
        if (type[0][0] == typesRule[lifecycleType]) {
          acc = {...acc, [type[0]]: type[1]}
        } return acc
      }, {});
      setOptions(options);
      if (selectedNode.lifecycleType !== null) {
        setJobTime(typesMatch[selectedNode[lifecycleType]])
    }
    })
    .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_JOB_TYPES'}) }),
      });
    })
  }, [lifecycleType]);

  return (
    <Grid item xs={12}>
      <TextField
        className={classes.input}
        select
        label={`${label} Policy`}
        size="medium"
        fullWidth
        value={jobTime}
        onChange={(e) => handleSelect(e.target.value)}
      >
        {Object.entries(options).map((type) => (
          <MenuItem key={type[0]} value={type[0]}>
            {type[1]}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default LifecycleDropdown;
