import React, { useEffect, useState, useRef } from 'react';
import { lookUp } from 'services/stringService';
import { string, func, oneOf, objectOf } from 'prop-types';
import { withSnackbar } from 'notistack';
import uuid from 'uuid';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Loader } from 'components';
import contentService from 'services/contentService';
import markerService from 'services/markerService';
import ContentCardsContainer from './ContentCardsContainer';
import AlertService from 'services/alertService';
import ThemedButton from 'components/ThemedButton';
import ContentSelector from 'components/ContentSelector';
import SectionDrawer from 'components/SectionDrawer';
import DeleteButton from 'components/DeleteButton';


const useStyles = makeStyles((theme) => ({
  contentDataContainer: {
    // display: 'flex',
    // flexGrow: 1,
    flexDirection: 'column',
  },
  dropAreaContainer: {
    margin: 0,
    padding: theme.spacing(2, 0),
    maxWidth: '100%',
    minHeight: 420,
  },
  dialogPad: {
    padding: theme.spacing(3)
  },
  searchSidebar: {
    position: 'fixed',
    top: props => props.header ? 130 : 0,
    zIndex: 1100,
    right: 0,
    width: 320,
    height: '100%',
  },
  deleteAllGrid: {
    textAlign: 'end'
  }
}));

function Items(props) {
  const {
    contentId,
    model,
    handleChange,
    enqueueSnackbar,
    parentComponent='ContentForm',
  } = props;
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [isContentDataLoading, setIsContentDataLoading] = useState(false);
  const [isTrackDeleteDialogOn, setIsTrackDeleteDialogOn] = useState(false);
  const [isTrackDeleting, setIsTrackDeleting] = useState(false);
  const [isSearchBarOn, setIsSearchBarOn] = useState(false);

  const itemToDelete = useRef(null);

  useEffect(() => {
    const markerToClip = (marker) => ({
      ...marker,
      contentId: marker.track?.contentId,
      id: marker.id,
    });

    const clipsToTracks = (clips) => {
      if (!clips.length) return;
      let maintrackFound = false; 
      const trx = clips.reduce((tracksObj, clip, idx) => {
        const { contentId, main } = clip.track || {};
        const { clips } = tracksObj[clip.track?.contentId] || {};
        tracksObj[clip.track?.contentId] = {
          ...(tracksObj[clip.track?.contentId] || {}),
          contentId,
          main: main && (!maintrackFound || maintrackFound === contentId),
          lock: false,
          index: idx,
          clips: [...(clips || []), clip],
        };
        if (main) {
          maintrackFound = clip.track.contentId;
        }
        return tracksObj;
      }, {});
      return Object.values(trx);
    };

    const getTracks = () => {
      return markerService
        .search(contentId, { type: 'Clip' })
        .then((res) => clipsToTracks([...res?.pageContent?.map((m) => markerToClip(m))]))
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_REMIX' }),
            }),
          });
        });
    };

    const getItems = async () => {
      setIsContentDataLoading(true);
      try {
        if (!model.referenceItems?.Playlist?.length) {
          setItems([]);
          setIsContentDataLoading(false);
          return;
        }
        const tracks = await getTracks();
        const itemsPromises = await Promise.allSettled(
          model.referenceItems.Playlist.map((id) => contentService.getById(id))
        );
        const items = itemsPromises.reduce((acc, content) => {
          if (content.status === 'fulfilled') {
            const item = content.value;
            item.isTrack = false;
            const track = tracks?.find((track) => track.contentId === item.id);
            if (track) {
              item.isTrack = true;
              item.isMainTrack = track.main;
              item.clips = track.clips;
            }
            acc.push(item);
          }
          return acc;
        }, []);
        setItems(items);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CONTENT_DATA' }),
          }),
        });
      } finally {
        setIsContentDataLoading(false);
      }
    };

    getItems();
  }, [model.referenceItems.Playlist]);

  const onAdd = async (item) => {
    const newList = [...items, item];
    setItems(newList);
    handleChange('referenceItems', {
      ...model.referenceItems,
      Playlist: newList.map((item) => item.id),
    });
  };

  const onDeleteItem = async (item, index) => {
    if (item.isTrack) {
      itemToDelete.current = item;
      setIsTrackDeleteDialogOn(true);
    } else {
      items.splice(index, 1);
      setItems([...items]);
      handleChange('referenceItems', {
        ...model.referenceItems,
        Playlist: items.map((item) => item.id),
      });
    }
  };

  const deleteTrack = (items, item) => {
    setIsTrackDeleting(true);
    try {
      item?.clips?.forEach((clip) => markerService.remove(clip.id));
      const itemsLeft = [...items].filter((p) => p.id !== item.id);
      setItems(itemsLeft);
      handleChange('referenceItems', {
        ...model.referenceItems,
        Playlist: itemsLeft.map((item) => item.id),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_REMIX' }),
        }),
      });
    } finally {
      itemToDelete.current = null;
      setIsTrackDeleting(false);
      setIsTrackDeleteDialogOn(false);
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '',
    height: '100%',
  });

  const reorder = (list, sourceIndex, destinationIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, { ...removed, uuid: uuid() });
    return destClone;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || destination.droppableId === 'contentOptions') return;
    if (source.droppableId === destination.droppableId) {
      const dragItems = reorder(items, source.index, destination.index);
      setItems(dragItems.filter((e) => !!e));
      handleChange('referenceItems', {
        ...model.referenceItems,
        Playlist: dragItems.filter((e) => !!e).map((item) => item.id),
      });
    }
  };

  const onDeleteAll = async () => {
    setItems([]);
    handleChange('referenceItems', {
      ...model.referenceItems,
      Playlist: [],
    });
  };

  return (
    <Box flex={1}>
      <Grid container spacing={1} alignItems="center" justifyContent="space-between">
        <Grid
          item
          container
          spacing={1}
          xs={12}
          md={8}
          justifyContent="space-between"
          alignContent="flex-end"
        >
          <Grid item xs={4} md={3} lg={2}>
            <Tooltip title={lookUp({ key: 'CONSOLE_CLICK_TO_SEARCH_HELPERTEXT' })}>
              <ThemedButton color="primary" onClick={() => setIsSearchBarOn(true)} fullWidth>
                {lookUp({ key: 'CONSOLE_SEARCH_BUTTON' })}
              </ThemedButton>
            </Tooltip>
          </Grid>
          {
            items.length > 0 &&
            <Grid item xs={4} md={3} lg={2} className={classes.deleteAllGrid}>
              <DeleteButton 
                label={lookUp({ key: 'CONSOLE_DELETE_ALL_BUTTON' })}
                dialogTitle={lookUp({ key: 'CONSOLE_DELETE_ALL_DIALOG_TITLE' })}
                onDelete={() => onDeleteAll()}
              />
            </Grid>
          }
        </Grid>
      </Grid>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={1} className={classes.dropAreaContainer}>
          <Grid
            xs={12}
            md={parentComponent === 'ImportDialog' ? 8 : 8}
          >
            <Droppable droppableId="contentData">
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={classes.contentDataContainer}
                >
                  <Typography variant={'subtitle1'} gutterBottom>
                    {lookUp({ key: 'CONSOLE_PLAYLIST_ITEMS' })}
                  </Typography>
                  {isContentDataLoading ? (
                    <Loader />
                  ) : (
                    <ContentCardsContainer
                      items={items}
                      onDragStyle={getListStyle(snapshot.isDraggingOver)}
                      onDelete={onDeleteItem}
                    />
                  )}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Grid>
        </Grid>
      </DragDropContext>

      <SectionDrawer open={isSearchBarOn} onClose={() => setIsSearchBarOn(false)}>
        <ContentSelector
          onClose={() => setIsSearchBarOn(false)}
          onSelect={(_, __, ___, item) => {
            onAdd(item);
          }}
        />
      </SectionDrawer>

      <Dialog
        open={isTrackDeleteDialogOn}
        onClose={() => setIsTrackDeleteDialogOn(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {lookUp({ key: 'CONSOLE_CONFIRMATION_DELETE' })}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {lookUp({ key: 'CONSOLE_DELETE_TRACK_WARNING_SUMMARY' })}
          </DialogContentText>
        </DialogContent>

        <DialogActions className={classes.dialogPad}>
          <ThemedButton color="info" onClick={() => setIsTrackDeleteDialogOn(false)}>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          <ThemedButton
            color="error"
            onClick={() => deleteTrack(items, itemToDelete.current)}
            autoFocus
          >
            {isTrackDeleting ? <Loader inline /> : lookUp({ key: 'CONSOLE_DELETE_BUTTON' })}
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

Items.propTypes = {
  type: oneOf(['playlist', 'collection']),
  model: objectOf(string).isRequired,
  handleChange: func.isRequired,
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(Items);
