import { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TranslateIcon from '@material-ui/icons/Translate';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import JoditEditor from 'jodit-react';
import ImageCropUpload from 'components/ImageCropUpload';
import ImageCroppingModal from 'components/ImageCropping/CroppingModal';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';
import Loader from 'components/Loader';
import Dialog from 'components/Dialog';
import DataGridOverlayNoResults from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';

const FORM_FIELD_SIZE = 'small';
const LAYOUT_TYPE_LANDSCAPE = 'Landscape';
const LAYOUT_TYPE_PORTRAIT = 'Portrait';
const LAYOUT_TYPE_SQUARE = 'Square';
const LAYOUT_TYPE_THUMBNAILS = 'ScrubbingImages';

// define enabled layouts
const IMAGE_LAYOUTS = [LAYOUT_TYPE_LANDSCAPE, LAYOUT_TYPE_PORTRAIT, LAYOUT_TYPE_SQUARE];
const IMAGE_RATIOS = {
  [LAYOUT_TYPE_LANDSCAPE]: { aspectRatio: 16 / 9, width: 1280, height: 720 },
  [LAYOUT_TYPE_PORTRAIT]: { aspectRatio: 2 / 3, width: 800, height: 1200 },
  [LAYOUT_TYPE_SQUARE]: { aspectRatio: 1 / 1, width: 1200, height: 1200 },
};
const EDITOR_CONFIG = {
  readonly: false,
  spellcheck: false,
  placeholder: lookUp({ key: 'CONSOLE_DESCRIPTION' }),
  minHeight: 280,
  buttons: [
    'undo', 'redo', '|',
    'bold', 'italic', 'underline', 'strikethrough',  '|',
    'paragraph', 'font', 'fontsize', 'brush', '|',
    'align', 'ul', 'ol', '|',
    'find', 'source'
  ]
};
const EMPTY_ENTRY = {
  name: '',
  shortInfo: '',
  description: '',
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

const Localization = ({
  id,
  model,
  enqueueSnackbar,
  handleChange = () => {},
}) => {
  const classes = useStyles();

  const [languageCodes, setLanguageCodes] = useState();
  const [open, setOpen] = useState({ info: false, translate: false, images: false });
  const [activeId, setActiveId] = useState(null);
  const [localizations, setLocalizations] = useState();
  const [translateLanguage, setTranslateLanguage] = useState({ from: '', to: '' });
  const [images, setImages] = useState(model.assets.filter?.((e) => IMAGE_LAYOUTS.includes(e.subType)));
  const [isLoading, setIsLoading] = useState(true);

  const joditRef = useRef(null);
  const joditConfig = useMemo(() => EDITOR_CONFIG, [joditRef]);

  useEffect(() => {
    if (model.localizations) {
      setLocalizations(model.localizations);
      setIsLoading(false);
    }
  }, [model]);

  // attempts to get an image public url from one of the avaailable filtered images
  const getUrl = (subType) => {
    const targetImage = images.find((image) => image.subType === subType && image.locale === activeId);

    return targetImage?.objectUrl || '';
  };

  useEffect(() => {
    jobManagerService
      .getLanguageCodes('Translate')
      .then((codes) => setLanguageCodes({ '--': lookUp({ key: 'CONSOLE_DEFAULT' }), ...codes }))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: `CONSOLE_${'language codes'}` }) }),
        });
      });
  }, [handleChange]);

  const handleClickOpen = (id = activeId, modal = 'info') => {
    setActiveId(id);
    setTranslateLanguage({ ...translateLanguage, from: id });
    setOpen({ ...open, [modal]: true });
  };

  const handleClose = () => {
    setOpen({ info: false, translate: false });
  };

  const handleLocalizationChange = (id, key, value) => {
    const newItems = { ...localizations };
    newItems[id][key] = value;
    setLocalizations(newItems);
  };

  const removeLocalization = (id) => {
    const newItems = { ...model.localizations };
    delete newItems[id];
    setLocalizations(newItems);
    handleChange('localizations', newItems);
  };

  const selectLanguage = (id = activeId) => {
    setActiveId(id);
    setLocalizations({ ...localizations, [id]: { ...EMPTY_ENTRY, ...localizations[id] } });
  };

  const selectTranslateLanguage = (key, value) => {
    setTranslateLanguage({ ...translateLanguage, [key]: value });
  };

  // FIXME do not use delete to mutate objects, filter instead
  const submitChange = () => {
    handleClose();
    const emptyEntries = Object.keys(localizations).filter(
      (item) => !Object.keys(EMPTY_ENTRY).some((key) => localizations[item][key])
    );
    emptyEntries.forEach((id) => delete localizations[id]);
    handleChange('localizations', localizations);
  };

  const handleImagesChange = (locale, subType, objectUrl) => {
    const newImages = [...images].filter((e) => !(e.locale === locale && e.subType === subType));
    newImages.push({ locale, subType, objectUrl, type: 'Image' });
    setImages(newImages);
  };

  const submitImagesChange = () => {
    handleClose();
    const couples = images
      .filter((e) => !!e.locale)
      .map((e) => JSON.stringify([e.locale, e.subType]));
    const copyAssets = [...model.assets].filter(
      (e) => !couples.includes(JSON.stringify([e.locale, e.subType]))
    );
    handleChange('assets', [...copyAssets, ...images]);
  };

  const startTranslate = (fromLang, toLang) => {
    const reqBody = {
      ReferenceObjectId: model.id,
      Name: `TranslateJob_${Date.now()}`,
      SourceLanguageCode: fromLang,
      TargetLanguageCode: toLang,
      InputFileLocation: 's3://',
    };
    jobManagerService
      .translate(reqBody)
      .then((resp) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_TRANSLATION_REQUEST_RECEIVED' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(handleClose);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    handleClickOpen('--', 'info');
  };

  const columns = [
    {
      field: 'id',
      headerName: lookUp({ key: 'CONSOLE_LOCALE' }),
      minWidth: 90,
      renderCell: (item) => (
        <Link onClick={() => handleClickOpen(item.value, 'info')} color="primary">
          {item.value}
        </Link>
      ),
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_TITLE' }),
      flex: 1,
      renderCell: (item) => (
        <Link onClick={() => handleClickOpen(item.row.id, 'info')} color="primary">
          {item.value}
        </Link>
      ),
    },
    {
      field: 'shortInfo',
      headerName: lookUp({ key: 'CONSOLE_SHORTINFO' }),
      flex: 2,
    },
    {
      field: 'actions',
      headerName: ' ',
      minWidth: (52 * 4),
      sortable: false,
      renderCell: (item) => (
        <Box flex={1} display="flex" justifyContent="flex-end">
          <IconButton onClick={() => handleClickOpen(item.row.id, 'translate')}>
            <TranslateIcon />
          </IconButton>
          <IconButton onClick={() => handleClickOpen(item.row.id, 'images')}>
            <WallpaperIcon />
          </IconButton>
          <IconButton onClick={() => handleClickOpen(item.row.id, 'info')}>
            <EditIcon />
          </IconButton>
          <DeleteButton
            onDelete={() => removeLocalization(item.row.id)}
            variant="icon"
          />
        </Box>
      )
    },
  ];

  const rows = Object.entries(localizations ?? [])
    .map(([id, locale = {}]) => ({
      ...locale,
      id,
    }));

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Grid
        container
        spacing={1}
        className={classes.mainTable}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item container xs={12} justifyContent="flex-end">
          <Typography variant="h6" gutterBottom>{lookUp({ key: 'CONSOLE_LOCALIZATION_TITLE' })}</Typography>
          <Box flex={1} />
          <Tooltip item title={lookUp({ key: 'CONSOLE_ADD_LOCALIZATION_HELPERTEXT' })}>
            <IconButton
              color="primary"
              aria-label={lookUp({ key: 'CONSOLE_ADD_LOCALIZATION_BUTTON' })}
              onClick={handleAdd}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item lg={12} xs={12}>
          <DataGrid
            columns={columns}
            rows={rows}
            components={{
              LoadingOverlay: DataGridOverlayLoaderLinear,
              NoRowsOverlay: DataGridOverlayNoResults,
              NoResultsOverlay: DataGridOverlayNoResults,
            }}
            componentsProps={{
              noRowsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
              noResultsOverlay: {
                title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
              },
            }}
            autoHeight
            pagination
            disableColumnFilter
            disableColumnMenu
            disableSelectionOnClick
          />
        </Grid>
        <Grid item container sm={12} justifyContent="flex-end">
          <Tooltip item title={lookUp({ key: 'CONSOLE_ADD_LOCALIZATION_HELPERTEXT' })}>
            <IconButton
              color="primary"
              aria-label={lookUp({ key: 'CONSOLE_ADD_LOCALIZATION_BUTTON' })}
              onClick={handleAdd}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      {/* Dialogs */}
      {/* Add/Edit */}
      <Dialog
        open={Boolean(open.info)}
        onClose={handleClose}
        title={lookUp({ key: 'CONSOLE_EDIT_LANGUAGE' })}
        actions={(
          <>
            <Box flex={1} />
            <ThemedButton onClick={submitChange}>
              {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
            </ThemedButton>
          </>
        )}
        maxWidth="sm"
        fullWidth
      >
        <Grid className={classes.container} direction="column" spacing={2} container>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                name="language"
                label={lookUp({ key: 'CONSOLE_LANGUAGE' })}
                aria-describedby="localization-language-helper"
                value={activeId || ''}
                onChange={(e) => {
                  if (e && e.target) {
                    selectLanguage(e.target?.value)
                  }
                }}
                select
                required
              >
                {Object.keys(languageCodes ?? []).map((code) => (
                  <MenuItem value={code} key={code} label={code}>
                    {lookUp({ key: code === '--' ? 'CONSOLE_LANG_DEFAULT' : `CONSOLE_LANG_${code}` })}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="localization-language-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_LANGUAGE_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
              label={lookUp({ key: 'CONSOLE_TITLE' })}
              name="title"
              aria-describedby="localization-title-helper"
              value={localizations[activeId]?.name || ''}
              onChange={(e) => handleLocalizationChange(activeId, 'name', e.target?.value)}
            />
            <FormHelperText id="localization-title-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_TITLE_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                label={lookUp({ key: 'CONSOLE_SHORTINFO' })}
                name="shortinfo"
                aria-describedby="localization-shortinfo-helper"
                value={localizations[activeId]?.shortInfo || ''}
                onChange={(e) => handleLocalizationChange(activeId, 'shortInfo', e.target.value)}
                multiline
              />
              <FormHelperText id="localization-shortinfo-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_SHORTINFO_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <JoditEditor
                ref={joditRef}
                value={localizations[activeId]?.description}
                name="description"
                aria-describedby="localization-description-helper"
                config={joditConfig}
                tabIndex={1}
                onBlur={(newContent) => {
                  handleLocalizationChange(activeId, 'description', newContent);
                }}
              />
              <FormHelperText id="localization-description-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_DESCRIPTION_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Dialog>

      {/* Translate */}
      <Dialog
        open={Boolean(open.translate)}
        onClose={handleClose}
        title={lookUp({ key: 'CONSOLE_TRANSLATE_TITLE' })}
        actions={(
          <>
            <Box flex={1} />
            <ThemedButton onClick={() => startTranslate(translateLanguage?.from, translateLanguage?.to)}>
              {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
            </ThemedButton>
          </>
        )}
        maxWidth="sm"
        fullWidth
      >
        <Grid className={classes.container} direction="column" spacing={2} container>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                name="from"
                label={lookUp({ key: 'CONSOLE_SELECT_LANGUAGE' })}
                aria-describedby="localization-from-helper"
                value={translateLanguage?.from}
                onChange={(e) => {
                  if (e && e.target) {
                    selectTranslateLanguage('from', e.target.value)
                  }
                }}
                select
              >
                {Object.keys(languageCodes ?? []).map((code) => (
                  <MenuItem value={code} key={code} label={code}>
                    {languageCodes[code]}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="localization-from-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_FROM_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                size={FORM_FIELD_SIZE}
                name="to"
                label={lookUp({ key: 'CONSOLE_SELECT_LANGUAGE' })}
                aria-describedby="localization-to-helper"
                value={translateLanguage?.to}
                onChange={(e) => {
                  if (e && e.target) {
                    selectTranslateLanguage('to', e.target.value)
                  }
                }}
                select
              >
                {Object.keys(languageCodes ?? []).map((code) => (
                  <MenuItem value={code} key={code} label={code}>
                    {languageCodes[code]}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="localization-to-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_TO_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Dialog>

      {/* Images */}
      <Dialog
        open={Boolean(open.images)}
        onClose={handleClose}
        title={lookUp({ key: 'CONSOLE_LOCALIZATION_IMAGES_TITLE' })}
        actions={(
          <>
            <Box flex={1} />
            <ThemedButton onClick={submitImagesChange}>
              {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
            </ThemedButton>
          </>
        )}
        maxWidth="sm"
        fullWidth
      >
        <Grid className={classes.container} direction="column" spacing={2} container>
          <Grid item>
            <FormControl>
              <TextField
                size={FORM_FIELD_SIZE}
                name="image"
                label={lookUp({ key: 'CONSOLE_SELECT_LANGUAGE' })}
                aria-describedby="localization-image-helper"
                value={activeId}
                onChange={(e) => {
                  if (e && e.target) {
                    selectLanguage(e.target.value)
                  }
                }}
                select
              >
                {Object.keys(languageCodes ?? []).map((code) => (
                  <MenuItem value={code} key={code} label={code}>
                    {languageCodes[code]}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="localization-image-helper">
                {lookUp({ key: 'CONSOLE_LOCALIZATION_IMAGES_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
          {IMAGE_LAYOUTS.map((layout) => {
            const imageUrl = getUrl(layout);
            // FIXME defined crop enabled condition
            const shouldDisplayCropTools = imageUrl && imageUrl.trim?.() !== '';

            return (
              <Grid item container direction="column" spacing={2} key={layout}>
                <Grid item>
                  <TextField
                    label={lookUp({ key: `CONSOLE_${layout}` })}
                    fullWidth
                    size="small"
                    value={imageUrl}
                    onChange={(e) => {
                      handleImagesChange(activeId, layout, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item>
                  <ImageCropUpload
                    fileUrl={imageUrl}
                    handleChange={(url, id) => {
                      handleImagesChange(activeId, layout, url);
                    }}
                  />
                </Grid>
                {shouldDisplayCropTools && (
                  <Grid item xs={12}>
                    <ImageCroppingModal
                      fileUrl={imageUrl}
                      handleChange={(url, id) => handleImagesChange(activeId, subType, url)}
                      {...IMAGE_RATIOS[layout]}
                    />
                  </Grid>
                )}
              </Grid>
            );
          })}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

Localization.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  handleChange: PropTypes.func,
};

export default withSnackbar(Localization);
