import React, { useEffect } from 'react';
import { any } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { signOut } from 'services/customersService';
import { getTokenFromCookies } from 'helpers/common';
import NoAccess from 'pages/NoAccess';
import NotFound from 'pages/NotFound';

function PrivateRoute({ component, ...rest }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useSelector(state => state.auth.user);
  const { access } = useSelector(state => state.auth);
  const userAccess = access?.find(e => e.role === role);
  const allAccess = access?.find(e => e.role === 'SysAdmin');
  
  const allowedRoutes = userAccess?.menu?.reduce((arr, e) => {
    e.url && arr.push(e.url);
    e.menuitems?.length && e.menuitems.forEach(item => arr.push(item.url))
    return arr;
  }, []);
  
  const allRoutes = allAccess?.menu?.reduce((arr, e) => {
    e.url && arr.push(e.url);
    e.menuitems?.length && e.menuitems.forEach(item => arr.push(item.url))
    return arr;
  }, []);
  
  const pathname =  location.pathname;
  const letpass = ['', '/', '/myaccount', '/account', 'return-lms'];
  const isAllowed = !!([...(allowedRoutes || []), ...letpass]?.filter(r => r !== '/').filter(r => pathname.indexOf(r) > -1).length) || letpass.includes(pathname);
  const isDefined = !!(allRoutes?.filter(r => r !== '/').filter(r => pathname.indexOf(r) > -1).length) || pathname === '' || pathname === '/';

  const signOutAndRedirectToSignIn = () => {
    signOut(dispatch);
    navigate('/sign-in');
  };
  const hasToken = getTokenFromCookies();

  useEffect(() => {
    if (!hasToken || !allowedRoutes) {
      signOutAndRedirectToSignIn();
    };
  }, [hasToken, allowedRoutes]);

  return (
    <Routes>
    <Route {...rest} path='/*' element=
      { !!allowedRoutes && isAllowed 
          ? React.createElement(component)
          : isDefined 
            ? <NoAccess />
            : <NotFound />
      }/>
    </Routes>
  );
};

PrivateRoute.propTypes = {
  component: any.isRequired,
};

export default PrivateRoute;
