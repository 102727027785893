import { useTheme } from '@material-ui/core';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import TimeLabeler from './TimeLabeler';
import { segmentTypes } from 'constants/index.js';
import { getMs } from 'helpers/time';


function MarkerBar({
  brPtContainer,
  duration,
  selectedBrPt,
  getCurrentTime,
  handleBreakpointSelect,
  handleBreakpointDeselect,
  updateBreakpoint,
  handleBrPtChange,
}) {
  const theme = useTheme();
  const primaryColor = theme.palette?.primary?.main;
  const secondaryColor = theme.palette?.secondary?.main;

  const markersToRanges = () => {
  if (!selectedBrPt.type || !brPtContainer[selectedBrPt?.type]?.length) return [];
  return brPtContainer[selectedBrPt.type].filter(e => !!e).reduce((arr, e) => {
    if (e.position) {
      arr.push(getMs(e.position));
      arr.push(segmentTypes.includes(selectedBrPt.type) ? getMs(e.end) : getMs(e.position)+1);
    };
    return arr;
  }, []);
  };

  const zebraStripe = () => 
    markersToRanges().map((e, i) => ({
      background: getMs(selectedBrPt?.position) === e ? secondaryColor || '#e57b1e' : primaryColor || 'rgb(33,150,243)',
      height: 20,
      opacity: i % 2 ? 0 : .9,
      borderRadius: 0,
  }));

  return (
    <>
      <TimeLabeler duration={duration} getCurrentTime={getCurrentTime}/>
      <Slider
        min={0} 
        max={duration} 
        range 
        value={markersToRanges(brPtContainer[selectedBrPt.type])}
        handleStyle={{height: 30, width: 8, borderRadius: 4, border: '1px solid black', background: 'rgba(0,0,0,.25)'}}
        railStyle={{background: '#000', opacity: .75, height: 20, borderRadius: 0}}
        step={0.001}
        trackStyle={zebraStripe()} 
      />
    </>
  );
};

export default MarkerBar;
