import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getSkuEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Sku');
  if (!endpoint) throw Error('No Sku endpoint.');
  return endpoint;
};
const getSkuEnumsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('SkuEnums');
  if (!endpoint) throw Error('No Sku Enums endpoint.');
  return endpoint;
};

const DEFAULT_PAGE_SIZE = 25;
const PAGE_SIZE_LIMIT = 100;

// define dict of verbose model properties to BE service query params, so we do not use single name variables in order to reduce complexity
// the api params change quite a bit between versions, keep a dictionary here in order to use on ALL methods to map the parameters in order to make it less inconvenient and error prone to update them when the api changes
const SKU_SERVICE_QUERY_PARAMS_MAPPING = {
  'platform': 'p',
  'type': 't',
  'query': 'q',
};

/**
 * Normalizes verbose params into BE service api params using dict SKU_SERVICE_QUERY_PARAMS_MAPPING
 * 
 * @param {object} params
 * 
 * @returns object
 */
const mapSearchQueryParams = (params) => {
  if (params instanceof URLSearchParams) {

    if (params.has('perPage') && Number(params.get('perPage')) > PAGE_SIZE_LIMIT) {
      params.set('perPage', PAGE_SIZE_LIMIT)
    }

    params.forEach?.((value, key) => {
      if (SKU_SERVICE_QUERY_PARAMS_MAPPING[key]) {
        params.delete(key);
        params.set(SKU_SERVICE_QUERY_PARAMS_MAPPING[key], value);
      }
    });

    return params;
  } else {

    // if perPage value is greater than allowed maximum value PAGE_SIZE_LIMIT, use DEFAULT_PAGE_SIZE
    if (Number(params.perPage) > PAGE_SIZE_LIMIT) {
      mappedParams = {
        ...mappedParams,
        perPage: PAGE_SIZE_LIMIT,
      }
    }
  
    const mappedParams = Object.entries(params)
      .map(([ key, value ]) => [[SKU_SERVICE_QUERY_PARAMS_MAPPING[key] ?? key], value]);
  
    return Object.fromEntries(mappedParams);
  }

};

const search = ({
  page = 0,
  perPage = DEFAULT_PAGE_SIZE,
  ...params
} = {}) =>
  axios
    .get(`${getSkuEndpoint()}/Search/${perPage}/${page}`, { params: mapSearchQueryParams(params) })
    .then((res) => res?.data);

const getAll = ({ page = 0, perPage = 10, query = '', sortBy, order } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getSkuEndpoint()}/Search/${perPage}/${page}`, {
        q: query,
        sortBy,
        order
      })
    )
    .then((res) => res?.data);

const create = async (model) => axios.post(getSkuEndpoint(), model)
  .then((r) => r?.data);

const get = (id) => axios.get(`${getSkuEndpoint()}/${id}`)
  .then((r) => r?.data);

const edit = async (model) => axios.put(`${getSkuEndpoint()}/${model.id}`, model)
  .then((r) => r?.data)

const remove = (id) => axios.delete(`${getSkuEndpoint()}/${id}`)
  .then((r) => r?.data);

const getStoreTypes = () => axios.get(`${getSkuEnumsEndpoint()}/StoreTypes`)
  .then((r) => r?.data);

const getProductTypes = () => axios.get(`${getSkuEnumsEndpoint()}/ProductTypes`)
  .then((r) => r?.data);

const getPeriodUnits = () => axios.get(`${getSkuEnumsEndpoint()}/PeriodUnits`)
  .then((r) => r?.data);

export { search, getAll, create, get, edit, remove, getProductTypes, getStoreTypes, getPeriodUnits };

export default {
  search,
  getAll,
  create,
  get,
  edit,
  remove,
  getStoreTypes,
  getProductTypes,
  getPeriodUnits
};
