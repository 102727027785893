import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { Link } from '@material-ui/core';
import { LinkOff } from '@material-ui/icons';
import CreatedOn from 'components/CreatedOn';
import TableComponent from 'components/Table';
import devicesService from 'services/devicesService';
import venuesService from 'services/venuesService';
import { Loader } from 'components';

const Devices = () => {
  
  const [venues, setVenues] = useState([]);
  const [isVenuesLoading, setIsVenuesLoading] = useState(false);

  const theadElements = [
    {
      name: 'name',
      label: lookUp({ key: 'CONSOLE_NAME' }),
      isSortable: true,
      align: 'left',
    },
    {
      name: 'venue',
      label: lookUp({ key: 'CONSOLE_VENUE' }),
    },
    {
      name: 'createdDate',
      label: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      isSortable: true,
    },
  ];

  const getVenues = async () => {
    setIsVenuesLoading(true);
    const { totalCount } = await venuesService.search({ page: 0, perPage: 1 });
    const { data } = await venuesService.search({ page: 0, perPage: totalCount });

    setVenues(data);
  };

  useEffect(() => {
    getVenues().then(() => setIsVenuesLoading(false));
  }, []);

  const venueItem = (item) => {
    if (item.status && item.status.venueId) {
      if (isVenuesLoading) {
        return <Loader inline />;
      } else {
        return venues.find((venue) => venue.id === item.status.venueId) ? (
          venues.find((venue) => venue.id === item.status.venueId).name
        ) : (
          <LinkOff />
        );
      }
    } else {
      return <LinkOff />;
    }
  };

  const tbodyElements = [
    {
      name: 'name',
      align: 'left',
      render: (item) => (
        <Link to={`/activities/devices/${item.id}/edit`} component={RouterLink}>
          {item.name}
        </Link>
      ),
    },
    {
      name: 'venue',
      render: (item) => venueItem(item),
    },
    {
      name: 'createdDate',
      render: (item) => <CreatedOn date={item.createdDate} />,
    },
  ];

  return (
    <>
      <TableComponent
        pageTitle={lookUp({ key: 'CONSOLE_DEVICES_TITLE' })}
        tableHeadElements={theadElements}
        tableBodyElements={tbodyElements}
        loadData={devicesService.search}
        deleteItem={devicesService.destroy}
      />
    </>
  );
};

export default Devices;
