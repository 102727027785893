import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { Add, Clear, FilterList } from '@material-ui/icons';
import { FavouriteIcon, UnFavouriteIcon } from 'assets/icons/FavouriteIcons';
import {
  Lister,
  CreatedOn,
  ConfirmDialog,
  QueryBar,
  Spacing,
  Favs,
  Toolbar,
  HoverMenu,
} from 'components';
import customersService from 'services/customersService';
import collectionsService from 'services/collectionsServiceV2';
import AlertService from 'services/alertService';
import { enumToOrderedArray } from 'helpers/common';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AddButton from 'components/AddButton';
import DeleteButton from 'components/DeleteButton';
import UserAvatar from 'components/UserAvatar';

const Customers = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const type = search.get('type');
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [favs, setFavs] = useState([]);
  const ownId = useSelector((state) => state.auth.user.id);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [showFilter, setShowFilter] = useState(+localStorage.getItem('showFilter') === 0 ? 0 : 1);
  const [selection, setSelection] = useState([]);
  const [deletedIds, setDeletedIds] = useState([]);
  const [hovered, setHovered] = useState([]);
  const [trigger, setTrigger] = useState(Symbol());
  const [debounce, setDebounce] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const addToFavourites = (id) => {
    collectionsService
      .addCustomerRelation(ownId, 'FavCustomer', id)
      .then((addedFavourite) => customersService.getCustomer(addedFavourite.targetId))
      .then((newFavourite) =>
        setFavs((favs) => [...favs, { ...newFavourite, id: newFavourite.customerId }])
      )
      .catch((error) => {
        if (error.response.status === 409) {
          setTrigger((o) => !o);
        }
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = (id) => {
    collectionsService
      .deleteCustomerRelation(ownId, 'FavCustomer', id)
      .then(() => setFavs((favs) => favs.filter((fav) => fav.id !== id)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const handleDelete = (id, username) => {
    setDeletedIds(id);
    customersService
      .deleteCustomer(id)
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: username }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: username }),
        });
      });
  };

  const handleMassDelete = () => {
    const ids = [...selection];
    setDeletedIds(ids);
    setIsDeleting(true);

    Promise.allSettled(ids.map((id) => customersService.deleteCustomer(id)))
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_ITEMS_SUCCESS_TEMPLATE', number: ids.length }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_contents'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', number: ids.length }),
        });
      })
      .finally(() => {
        setSelection([]);
        setIsDeleting(false);
      });
  };

  const getTypeEnum = async () => {
    try {
      const data = await customersService.getCustomerTypes();
      setCustomerTypes(enumToOrderedArray(data));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CUSTOMER_ENUMS' }),
        }),
      });
    }
  };

  useEffect(() => {
    getTypeEnum();
  }, []);

  const getFav = async (id) => {
    let fav;
    try {
      fav = await customersService.getCustomer(id);
    } catch (error) {}
    return fav || null;
  };

  useEffect(() => {
    const getFavourites = () => {
      collectionsService
        .getCustomerRelation(ownId, 'FavCustomer', 'List')
        .then((favouriteItems) =>
          Promise.all(favouriteItems.pageContent.map((e) => getFav(e.targetId)))
        )
        .then((favourites) => {
          setFavs(
            favourites
              .filter((fav) => (!!fav && !!fav && !type) || fav?.customerType === type)
              .map((e) => ({ ...e, id: e?.customerId }))
          );
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_FAVOURITES' }),
            }),
          });
        });
    };
    getFavourites();
  }, [type]);

  const getData = () => {
    setLoading(true);
    customersService
      .searchQuery(search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res.totalCount);
        setItems([
          ...res.data
            .filter((item) => !deletedIds?.includes(item.id))
            .map((e) => ({ ...e, id: e.customerId })),
        ]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_CUSTOMERS` }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, [search, trigger]);

  useEffect(() => {
    localStorage.setItem('showFilter', showFilter);
  }, [showFilter]);

  const columns = [
    {
      field: 'customerType',
      headerName: lookUp({ key: 'CONSOLE_TYPE' }),
      width: 120,
      renderCell: (item) => lookUp({ key: `CONSOLE_${item.row.customerType}` }),
    },
    {
      field: 'thumbnail',
      headerName: ' ',
      align: 'left',
      width: 64,
      renderCell: ({ row = {} }) => <UserAvatar user={row} />
    },
    {
      field: 'username',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      flex: 2,
      renderCell: (item) => (
        <Link to={`${item.row.customerId}/edit`} component={RouterLink}>
          {item.value || 'N/A'}
        </Link>
      ),
    },
    {
      field: 'registrationDate',
      headerName: lookUp({ key: 'CONSOLE_REGISTRATIONDATE' }),
      align: 'left',
      filterable: false,
      sortable: true,
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'lastSeenDate',
      headerName: lookUp({ key: 'CONSOLE_LASTSEENDATE' }),
      align: 'left',
      filterable: false,
      sortable: true,
      width: 140,
      renderCell: (item) =>
        hovered === item.id ? (
          <>
            <HoverMenu
              buttons={[
                {
                  component: favs.find((favourite) => favourite.id === item.id) ? (
                    <UnFavouriteIcon
                      key="unfav"
                      removeFavourite={() => removeFromFavourite(item.id)}
                    />
                  ) : (
                    <FavouriteIcon key="fav" addFavourite={() => addToFavourites(item.id)} />
                  ),
                },
                {
                  component: (
                    <DeleteButton
                      onDelete={() => handleDelete(item.id, item.row.username)}
                      dialogContent={item.row?.username ? (
                        <Box component="span">
                          {item.row.username}
                        </Box>
                      ) : null}
                      variant="icon"
                    />
                  ),
                },
              ]}
            />
            <CreatedOn date={item.value} />
          </>
        ) : (
          <CreatedOn date={item.value} />
        ),
    },
  ];

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined) delete searchObj[key];
    setSearch(searchObj);
  };

  const typeFilter = {
    label: lookUp({ key: 'CONSOLE_ALL_CUSTOMER_TYPES' }),
    key: 'type',
    options: customerTypes.map((e) => ({ label: lookUp({ key: `CONSOLE_${e}` }), value: e })),
    change: (value) => {
      setParam('type', value);
    },
  };

  const showFilterButton = {
    label: lookUp({ key: 'CONSOLE_FILTERS_BUTTON' }),
    startIcon: <FilterList />,
    onClick: () => setShowFilter((o) => (o + 1) % 2),
    color: showFilter ? 'primary' : 'inherit',
  }

  const create = {
    component: (
      <AddButton
        tooltipText={lookUp({ key: 'CONSOLE_ADD_BUTTON' })}
        navigateTo={type ? `../create?type=${type}` : '../create'}
      />
    )
  };

  const deleteSelected = {
    component: (
      <DeleteButton
        label={lookUp({ key: 'CONSOLE_DELETE_BUTTON_TEMPLATE', number: selection.length })}
        onDelete={handleMassDelete}
        loading={isDeleting}
        disabled={isDeleting}
        size="small"
        color="error"
      />
    ),
  };
  
  return (
    <Box flex={1} xs={12}>
      {Boolean(favs?.length) && (
        <Favs columns={columns} rows={favs || []} dataGridProps={{ headerHeight: 0 }} />
      )}
      <Toolbar
        queryBy="q"
        buttons={selection.length ? [deleteSelected, showFilterButton, create] : [showFilterButton, create]}
        triggerSearch={() => setTrigger(Symbol('refresh'))}
        size="small"
      />
      {Boolean(showFilter) && (
        <QueryBar filters={[typeFilter]} />
      )}
      <Lister
        loading={loading}
        columns={columns}
        rows={items || []}
        totalCount={totalCount}
        selectionModel={selection}
        setSelectionModel={setSelection}
        dataGridProps={{
          onRowEnter: (params) => setHovered(params.id),
          onRowLeave: () => setHovered(null),
        }}
      />
      <Grid item container sm={12} justifyContent="flex-end">
        <AddButton
          tooltipText={lookUp({ key: 'CONSOLE_CREATE_ASSET_HELPERTEXT' })}
          navigateTo={type ? `../create?type=${type}` : '../create'}
        />
      </Grid>
    </Box>
  );
}

export default withSnackbar(Customers);
