import React, { useState, useEffect, useRef } from 'react';
import { lookUp } from 'services/stringService';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { withSnackbar } from 'notistack';
import {
  makeStyles,
  Typography,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Tooltip,
} from '@material-ui/core';
import { Clear, FileCopy } from '@material-ui/icons/';
import { BreadcrumbsComponent, ListToolBar, SearchBox, FiltersBox } from './index';
import { ConfirmDialog } from './';
import { parseSortExpr, stringifySortExpr } from 'helpers/handleUrlParams';
import AlertService from 'services/alertService';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useStyles = makeStyles((theme) => ({
  mainTable: {
    paddingTop: '1em',
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  search: {
    marginLeft: theme.spacing(.5)
  }
}));

function Listing(props) {
  const {
    additionalFilters = [],
    additionalFiltersState = [],
    breadcrumbs,
    cloneItem,
    contentFormatAndType,
    create,
    CustomToolbar,
    dataGridProps = {},
    dateFilters,
    defaultPageSize = 25,
    defaultSorting = [],
    deleteByItem,
    deleteByProperty,
    deleteItem,
    downloadFile,
    dropdownFilters,
    enqueueSnackbar,
    exportData,
    externalTrigger,
    extraDataGridComponents = {},
    extraToolbarButtons,
    favourites,
    filtersboxCustomization,
    loadData,
    mainDropdownSelector,
    multiSort = false,
    noSearchBox = false,
    pageTitle,
    preventSearch = false,
    qInput = true,
    queryState,
    resetFilters,
    searchCustom = {},
    setFilters,
    setTagCloudOpen,
    sortable = true,
    tableHeadElements,
    TagCloud,
    tagCloudOpen,
    toolbarSwitches,
    uploadFile,
    uploadType,
  } = props;

  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const setUrlQuery = (query) => {
    const urlQuery = new URLSearchParams(location.search);
    Object.keys(query).map((key) => {
      if (key === 'page') {
        urlQuery.set('page', query[key]);
      } else if (query[key] != null && query[key] != undefined && query[key] != '') {
        urlQuery.set(key, query[key]);
      } else {
        urlQuery.delete(key);
      }
    });
    navigate({ pathname: location.pathname, search: urlQuery.toString() });
  };

  const classes = useStyles();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(100);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState({
    table: +query.get('pageSize') || defaultPageSize,
    favs: 5,
  });
  const [page, setPage] = useState(+query.get('page') || 0);
  const [search, setSearch] = useState({
    [searchCustom.key || 'q']: query.get(searchCustom.key) || query.get('q') || '',
  });
  const [trigger, setTrigger] = useState(true);
  const [isCheckboxVisible, setCheckboxVisible] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [sortingModel, setSortingModel] = useState(
    query.get('sortBy') ? parseSortExpr(query.get('sortBy')) : defaultSorting || []
  );

  const isFirstRun = useRef(true);

  const handleDelete = (id, name = 'Item') => {
    deleteItem(id)
      .then((e) => {
        setTrigger((o) => !o);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: name }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', title: name }),
        });
      });
  };

  const handleClone = (id, name = 'Item') => {
    cloneItem(id).then((e) => {
      setTrigger((o) => !o);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_CLONE_SUCCESS_TEMPLATE', title: name }),
      }).catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_CLONE_ERROR_TEMPLATE', title: name }),
        });
      });
    });
  };

  const cloneColumn = {
    field: 'clone',
    headerName: ' ',
    filterable: false,
    sortable: false,
    align: 'center',
    width: 40,
    renderCell: (item) => (
      <IconButton size="small" onClick={() => handleClone(item.id, item.name)}>
        <Tooltip title={lookUp({ key: 'CONSOLE_CLONE' })}>
          <FileCopy />
        </Tooltip>
      </IconButton>
    ),
  };

  if (cloneItem) tableHeadElements.push(cloneColumn);

  const deleteColumn = {
    field: 'del',
    headerName: ' ',
    filterable: false,
    sortable: false,
    align: 'center',
    width: 40,
    renderCell: (item) => (
      <ConfirmDialog
        onConfirm={() =>
          handleDelete(
            deleteByItem ? item.row : item.row[deleteByProperty] || item.row.id || item.row.key,
            item.row.name
          )
        }
        message={lookUp({
          key: 'CONSOLE_CONFIRMATION_DELETE',
        })}
        dialogText="Delete"
        buttonText="Delete"
        hint={lookUp({ key: 'CONSOLE_DELETE' })}
      />
    ),
  };

  if (deleteItem) tableHeadElements.push(deleteColumn);

  const getData = async (page, search) => {
    if (preventSearch) return [{}];
    const sortExpr = multiSort
      ? sortingModel
          .filter((e) => !!e.field)
          .map((s) => `${s.field} ${s.sort}`)
          .join(',')
      : false;
    const sortBy = !multiSort && sortingModel[0] && sortingModel[0].field;
    const order = !multiSort && sortingModel[0] && sortingModel[0].sort;
    setIsLoading(true);
    const loadParams = {
      page,
      perPage: pageSize.table,
      q: search.q,
      from: search.from,
      to: search.to,
      ...queryState,
    };

    if (searchCustom?.key) loadParams[searchCustom.key] = search[searchCustom.key];

    if (sortBy) {
      loadParams.sortBy = sortBy;
      loadParams.order = order;
    }

    if (multiSort) {
      loadParams.sortExpr = sortExpr;
      loadParams.sort = sortExpr;
      loadParams.sortBy = sortExpr;
      delete loadParams.order;
    }

    additionalFiltersState &&
      Object.keys(additionalFiltersState).forEach((key) => {
        loadParams[key] = additionalFiltersState[key];
      });

    try {
      const { data, totalCount } = await loadData(loadParams);
      setCount(totalCount || 0);
      setData(
        data?.map((d, index) => {
          return { ...d, id: d.id || index };
        }) || []
      );
    } catch (err) {
      let error = err;
      if (err.response && [401, 403].includes(err.response.status)) {
        error = lookUp({ key: 'CONSOLE_ACCESS_ERROR' });
      }
      if (err.response && err.response.status != 404) setError(error);
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_LOAD_ERROR' }),
      });
    } finally {
      setIsLoading(false);
      isFirstRun.current = false;
    }
  };

  useEffect(() => {
    getData(page, search);
  }, [page, trigger, queryState]);

  useEffect(() => {
    if (!externalTrigger) return;
    if (preventSearch) return;
    setTrigger((o) => !o);
  }, [externalTrigger]);

  useEffect(() => {
    if (isFirstRun.current) return;
    setPage((p) => 0);
    setUrlQuery({ page: null });
    setTrigger((o) => !o);
  }, [contentFormatAndType, pageSize]);

  useEffect(() => {
    if (isFirstRun.current) return;
    setPage((p) => 0);
    setTrigger((o) => !o);
  }, [search, sortingModel]);

  useEffect(() => {
    if (isFirstRun.current) return;
    setUrlQuery({ sortBy: stringifySortExpr(sortingModel) });
  }, [sortingModel]);

  useEffect(() => {
    if (isFirstRun.current) return;
    clearFilters();
    setSearch('');
  }, [contentFormatAndType]);

  const addFilters = JSON.stringify(additionalFiltersState);

  useEffect(() => {
    if (externalTrigger) return;
    if (isFirstRun.current) return;
    Object.entries(additionalFilters).forEach((e) => {
      setUrlQuery({ [e[0]]: e[1] });
    });
    const loadRecords = async () => await getData(0, search);
    loadRecords();
  }, [addFilters]);

  useEffect(() => {
    if (isFirstRun.current) return;
    setUrlQuery({ ...search, ...queryState });
  }, [search, queryState]);

  const noRows = () => {
    return (
      <GridOverlay>
        <Typography variant="h5">{lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND_TEXT' })}</Typography>
      </GridOverlay>
    );
  };

  const LinearLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const toolbar = () => {
    return (
      <ListToolBar
        additionalFilters={additionalFilters}
        isCheckboxVisible={isCheckboxVisible}
        setCheckboxVisible={setCheckboxVisible}
        selectionModel={selectionModel}
        toolbarSwitches={toolbarSwitches}
        downloadFile={downloadFile}
        dropdownFilters={dropdownFilters}
        resetFilters={resetFilters}
        additionalFiltersState={additionalFiltersState}
        uploadFile={uploadFile}
        exportData={exportData}
        create={create}
        extraToolbarButtons={extraToolbarButtons}
        search={search}
        contentFormatAndType={contentFormatAndType}
        pageSize={pageSize}
        page={page}
        uploadType={uploadType}
        enqueueSnackbar={enqueueSnackbar}
      />
    );
  };

  const filterLog = (e) => console.info('filtering :>> ', e);

  // If we change to XGrid (== DataGrid's rich cousin) the sorting logic penned below is built in, remove onColumHeaderClick from DataGrid's props.

  const sorting = (field, modKeys) => {
    let column = sortingModel.filter((e) => e.field === field)[0] || false;
    const newColumn = { field: field, sort: 'asc' };

    if (!column) {
      column = newColumn;
      if (!modKeys) {
        setSortingModel([column]);
        return;
      } else {
        setSortingModel([...sortingModel, column]);
        return;
      }
    } else {
      switch (column.sort) {
        case 'asc':
          setSortingModel(
            modKeys
              ? sortingModel.map((e) => {
                  return { ...e, sort: e.field === field ? 'desc' : e.sort };
                })
              : [{ field: field, sort: 'desc' }]
          );
          break;
        case 'desc':
          setSortingModel(modKeys ? sortingModel.filter((e) => e.field !== field) : []);
          break;
      }
    }
  };

  const clearFilters = () => {
    if (!setFilters) return;
    setFilters();
  };

  const selectionsToDownloadList = (selectedIds) => {
    const actualList = Object.keys(data).filter((e) => selectedIds.includes(e));
    setSelectionModel(Array.from(new Set([...actualList, ...selectedIds])));
  };

  return (
    <>
      {breadcrumbs && <BreadcrumbsComponent breadcrumbs={breadcrumbs} goToCrumb={() => {}} />}
      {!!favourites && favourites.length > 0 && (
        <>
          <Typography variant="h6">{lookUp({ key: 'CONSOLE_FAVOURITES' })}</Typography>
          <div className={classes.mainTable}>
            <DataGrid
              style={{ border: 'none' }}
              columns={tableHeadElements}
              disableColumnMenu
              rows={favourites}
              pageSize={pageSize.favs}
              onPageSizeChange={(newPageSize) =>
                setPageSize((o) => ({ ...pageSize, favs: newPageSize }))
              }
              autoHeight
              pagination
              // headerHeight={0}
              checkboxSelection={!!downloadFile && isCheckboxVisible}
              onSelectionModelChange={selectionsToDownloadList}
              selectionModel={selectionModel}
              rowsPerPageOptions={[5, 10, 50, 100]}
              hideFooter={favourites.length < 6}
              disableSelectionOnClick
              localeText={{
                noRowsLabel: lookUp({ key: 'CONSOLE_no_rows' }),
              }}
              componentsProps={{
                pagination: {
                  classes: null,
                  labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
                  labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
                },
              }} // because otherwise, rows per page dropdown disappears under 960px.
            />
          </div>
          <div style={{ height: '1.5rem' }} />
        </>
      )}
      <Typography variant="h6">{pageTitle}</Typography>
      <div className={classes.mainTable}>
        <Grid container spacing={1}>
          {CustomToolbar && (
            <Grid item md={12} xs={12}>
              {CustomToolbar}
            </Grid>
          )}
          <Grid container item alignItems="center" spacing={2} className={classes.search}>
            {!noSearchBox && (
              <SearchBox
                history={history}
                setUrlQuery={setUrlQuery}
                search={search}
                setSearch={setSearch}
                isLoading={isLoading}
                isFirstRun={isFirstRun}
                searchCustom={searchCustom}
                mainDropdownSelector={mainDropdownSelector}
                dateFilters={dateFilters}
                qInput={qInput}
              />
            )}
          </Grid>
          <Grid item md={8} xs={12} container alignContent="flex-end">
            {additionalFiltersState && filtersboxCustomization && (
              <FiltersBox
                additionalFiltersState={additionalFiltersState}
                filtersboxCustomization={filtersboxCustomization}
                setFilters={setFilters}
              />
            )}
          </Grid>
        </Grid>
        <DataGrid
          loading={isLoading}
          style={{ border: 'none' }}
          columns={
            sortable
              ? tableHeadElements
              : tableHeadElements.map((column) => ({ ...column, sortable: false }))
          }
          disableColumnMenu
          rows={data}
          components={{
            Toolbar: toolbar,
            NoRowsOverlay: noRows,
            LoadingOverlay: LinearLoadingOverlay,
            ...extraDataGridComponents,
          }}
          filterMode="server"
          onFilterModelChange={(filterModel) => filterLog(filterModel)}
          sortingMode="server"
          sortModel={sortingModel}
          onColumnHeaderClick={(params, event) =>
            sortable &&
            params.colDef.sortable &&
            sorting(params.field, multiSort && (event.shiftKey || event.altKey))
          }
          rowCount={count}
          pagination
          paginationMode="server"
          page={page}
          onPageChange={(newPage) => {
            setPage(newPage);
            setUrlQuery({ page: newPage });
          }}
          rowsPerPageOptions={[10, 25, 50, 100]}
          pageSize={pageSize.table}
          onPageSizeChange={(newPageSize) => {
            setPageSize((o) => ({ ...pageSize, table: newPageSize }));
            setUrlQuery({ pageSize: newPageSize });
          }}
          disableSelectionOnClick
          checkboxSelection={!!downloadFile && isCheckboxVisible}
          onSelectionModelChange={selectionsToDownloadList}
          selectionModel={selectionModel}
          autoHeight
          componentsProps={{
            Checkbox: { disableRipple: true },
            pagination: {
              classes: null,
              labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
              SelectProps: {
                MenuProps: { disableScrollLock: true }
              }
            },
          }} // because otherwise, rows per page dropdown disappears under 960px.
          {...dataGridProps}
        />
        {TagCloud && (
          <Dialog
            open={tagCloudOpen}
            maxWidth={'md'}
            fullWidth
            onClose={() => setTagCloudOpen(false)}
          >
            <DialogTitle>
              Tags
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setTagCloudOpen(false)}
              >
                <Clear />
              </IconButton>
            </DialogTitle>
            <DialogContent>{TagCloud}</DialogContent>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default withSnackbar(Listing);
