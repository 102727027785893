import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import jobManagerService from 'services/jobManagerService';
import { sortLangCodesByLangNames } from 'helpers/common';
import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const TranslateDropdown = (props) => {
  
  const classes = useStyles();
  const { onAttribChange, languageCode, label } = props;

  const [translateLangs, setTranslateLangs] = useState([]);

  useEffect(() => {
    if (Object.keys(translateLangs).length > 0) return;
    jobManagerService
      .getLanguageCodes('Translate')
      .then((translateLangs) => setTranslateLangs(sortLangCodesByLangNames(translateLangs)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_LANGUAGES'}) }),
        });
      });
  }, []);

  return (
    <>
      <Tooltip title={lookUp({ key: 'CONSOLE_AUTOMATIC_TRANSLATION_FROM_HELPERTEXT' })}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{
            width: '96%',
            marginLeft: '2%',
            marginRight: '2%',
          }}
          fullWidth
          size="small"
          margin="dense"
        >
          <InputLabel id="language" margin="dense">
            {label}
          </InputLabel>
          <Select
            labelId="language"
            label={label}
            className={classes.tabSelect}
            margin="dense"
            value={languageCode}
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            onChange={(event) => onAttribChange(event.target.value)}
          >
            {Object.keys(translateLangs).map((langCode, index) => (
              <MenuItem
                value={langCode}
                key={`${index}_${langCode}`}
              >
                {translateLangs[langCode]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </>
  );
};

export default TranslateDropdown;
