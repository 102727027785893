import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getCollectionsEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('CollectionsV2');
  if (!endpoint) throw Error('No Collections endpoint.');
  return endpoint;
};

const getContentRelationsEnum = () => {
  return axios
    .get(`${getCollectionsEndpoint()}/Enums/Content`)
    .then((r) => r?.data);
}

const getCustomerRelationsEnum = () => {
  return axios
    .get(`${getCollectionsEndpoint()}/Enums/Customer`)
    .then((r) => r?.data);
};

const getSourceTypeEnums = () => {
  return axios
    .get(`${getCollectionsEndpoint()}/Enums/SourceType`)
    .then((r) => r?.data);
};

const getCustomerRelation = (sourceId, relation, targetId = 'List') => {
  if (!sourceId || !relation) return [];
  return axios
    .get(`${getCollectionsEndpoint()}/Relation/${sourceId}/Customer/${relation}/${targetId}`)
    .then((r) => r?.data);
};

const addCustomerRelation = (sourceId, relation, targetId) =>
  !targetId
    ? Promise.resolve({ id: sourceId })
    : axios
        .post(`${getCollectionsEndpoint()}/Relation/${sourceId}/Customer/${relation}/${targetId}`)
        .then((r) => r?.data);

const deleteCustomerRelation = (sourceId, relation, targetId) =>
  axios
    .delete(`${getCollectionsEndpoint()}/Relation/${sourceId}/Customer/${relation}/${targetId}`)
    .then((r) => r?.data);

const getContentRelation = (sourceId, relation, targetId = 'List') => {
  if (!sourceId || !relation) return [];
  return axios
    .get(`${getCollectionsEndpoint()}/Relation/${sourceId}/Content/${relation}/${targetId}`)
    .then((r) => r?.data);
};

const addContentRelation = (sourceId, relation, targetId) => {
  return !targetId
    ? Promise.resolve({ id: sourceId })
    : axios
        .post(`${getCollectionsEndpoint()}/Relation/${sourceId}/Content/${relation}/${targetId}`)
        .then((r) => r?.data);
};

const deleteContentRelation = (sourceId, relation, targetId) =>
  axios
    .delete(`${getCollectionsEndpoint()}/Relation/${sourceId}/Content/${relation}/${targetId}`)
    .then((r) => r?.data);
    
const getCatalogRelation = (sourceId, relation, targetId = 'List') => {
  if (!sourceId || !relation) return [];
  return axios
    .get(`${getCollectionsEndpoint()}/Relation/${sourceId}/Catalog/${relation}/${targetId}`)
    .then((r) => r?.data);
};

const addCatalogRelation = (sourceId, relation, targetId, params) => {
  return !targetId
    ? Promise.resolve({ id: sourceId })
    : axios
        .post(`${getCollectionsEndpoint()}/Relation/${sourceId}/Catalog/${relation}/${targetId}${params ? `?${new URLSearchParams(params)}` : ''}`)
        .then((r) => r?.data);
};

const deleteCatalogRelation = (sourceId, relation, targetId) => {
  return axios
    .delete(`${getCollectionsEndpoint()}/Relation/${sourceId}/Catalog/${relation}/${targetId}`)
    .then((r) => r?.data);
};

const updateMultipleRelation = (relations) => {
  return axios
    .put(`${getCollectionsEndpoint()}/Relation/MultiRelationshipUpdate`, relations)
    .then((r) => r?.data);
};

const batchUpdate = (relations) => {
  return axios
    .post(`${getCollectionsEndpoint()}/Relation/BatchProcess`, relations)
    .then((r) => r?.data);
};

const getRelationshipList = (sourceId, relation) => {
  if (!sourceId || !relation) return [];
  return axios
    .get(`${getCollectionsEndpoint()}/Relation/${sourceId}/Relationship/${relation}/List`)
    .then((r) => r?.data);
};

export {
  getContentRelationsEnum,
  getCustomerRelationsEnum,
  getSourceTypeEnums,
  getContentRelation,
  addContentRelation,
  deleteContentRelation,
  getCustomerRelation,
  addCustomerRelation,
  deleteCustomerRelation,
  getCatalogRelation,
  addCatalogRelation,
  deleteCatalogRelation,
  updateMultipleRelation,
  batchUpdate,
  getRelationshipList,
};

export default {
  getContentRelation,
  addContentRelation,
  deleteContentRelation,
  getCustomerRelation,
  addCustomerRelation,
  deleteCustomerRelation,
  getCatalogRelation,
  addCatalogRelation,
  deleteCatalogRelation,
  deleteCatalogRelation,
  updateMultipleRelation,
  batchUpdate,
  getRelationshipList,
};
