import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { string, func, oneOf, bool } from 'prop-types';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(3)
  },
  danger: {
    backgroundColor: red[500],
    color: '#ffffff',
    '&:hover': {
      backgroundColor: red[600]
    }
  },
  marginRight: {
    marginRight: theme.spacing(1),
    fontWeight: 500
  }
}));

export default function ConfirmDialog(props) {
  
  const [isOpen, setIsOpen] = useState(false);

  const { 
    message = lookUp({ key: 'CONSOLE_ARE_YOU_SURE' }), 
    buttonType,
    buttonColor, 
    buttonVariant,
    buttonSize,
    buttonStartIcon,
    onConfirm,
    dialogText, 
    hint = lookUp({ key: 'CONSOLE_DELETE_ITEM' }), 
    buttonText = lookUp({ key: 'CONSOLE_DELETE_BUTTON' }),
    disabled, 
    buttonFullWidth,
    icon,
    iconButtonClassName,
  } = props;

  const classes = useStyles();

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const button = () =>
    !['Button', 'Plain'].includes(buttonType) ? (
      <IconButton onClick={handleOpen} disabled={disabled} size="small" className={iconButtonClassName || undefined }>
        <Tooltip title={hint}>
          {icon || <Delete />}
        </Tooltip>
      </IconButton>
    ) : dialogText === 'Approve' ? (
      buttonType === 'Plain' ? (
      <ThemedButton
        onClick={handleOpen}
        variant={buttonVariant || 'contained'}
        color={buttonColor || 'error'}
        size={buttonSize || 'medium'}
        startIcon={null || buttonStartIcon}
        disabled={disabled}
        fullWidth={buttonFullWidth}>
        {buttonText}
      </ThemedButton>
      ) : (
      <ThemedButton
        onClick={handleOpen}
        variant={'contained'}
        color={buttonColor || 'error'}
        disabled={disabled}
        fullWidth={buttonFullWidth}
      >
        {buttonText}
      </ThemedButton>
      )
    ) : (
      <ThemedButton
        onClick={handleOpen}
        variant={buttonVariant || 'contained'}
        color={buttonColor || 'error'}
        disabled={disabled}
        fullWidth={buttonFullWidth}
      >
        {buttonText}
      </ThemedButton>
    )

  return (
    <>
      {button()}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogActions className={classes.padding}>
          <ThemedButton color="info" onClick={handleClose}>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>
          <ThemedButton
            color="error"
            onClick={handleConfirm}
            autoFocus
          >
            {buttonText}
          </ThemedButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

ConfirmDialog.propTypes = {
  message: string,
  onConfirm: func,
  buttonType: oneOf(['IconButton', 'Button', 'Plain']),
  hint: string,
  buttonText: string,
  disabled: bool,
  buttonFullWidth: bool
};

ConfirmDialog.defaultProps = {
  buttonType: 'IconButton',
  disabled: false,
  buttonFullWidth: false
};
