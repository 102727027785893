import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { create } from 'services/contentService';
import { getLanguageCodes } from 'services/jobManagerService';
import AlertService from 'services/alertService';
import parametersService from 'services/parametersService';
import parametersAction from 'store/actions/parametersAction';
import { lookUp } from 'services/stringService';
import OrganizationSelector from 'components/OrganizationSelector';
import ThemedButton from 'components/ThemedButton';
import Dialog from 'components/Dialog';
import contentAction from 'store/actions/contentAction';


const DEFAULT_TYPE = 'Remix';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
  },
  gutterTop: {
    paddingTop: `${theme.spacing(2)}px !important`,
  },
}));


function InitDialog({
  enqueueSnackbar,
  remixType = DEFAULT_TYPE,
}) {

  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(true);
  const [langCodes, setLangCodes] = useState([]);
  const [originalTitle, setOriginalTitle] = useState('');
  const [originalLanguage, setOriginalLanguage] = useState('');
  const [description, setDescription] = useState('');
  const [formErrors, setFormErrors] = useState({
    originalTitle: null,
    description: null,
    originalLanguage: null,
  });

  const classes = useStyles();

  const createContent = () => {
    if (!originalTitle) {
      setFormErrors({ ...formErrors, originalTitle: lookUp({ key: 'CONSOLE_MISSING_TITLE_ERROR' })})
    } else {
      const contentDataToPost = {
        type: remixType,
        originalTitle,
        originalLanguage,
        localizations: {
          [originalLanguage]: {
            name: originalTitle,
            description
          },
        }
      };
      create(contentDataToPost)
        .then((contData) => {
          dispatch(contentAction.setContentData({ ...contData }));
          navigate(`/content/${contData.id}/editor`);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_CREATE_ERROR_MESSAGE_TEMPLATE',
              type: remixType === 'Remix'
                ? lookUp({ key: 'CONSOLE_REMIX' })
                : lookUp({ key: 'CONSOLE_PLAYLIST' })
            }),
          });
        })
    };
  };

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick') return;
    navigate(`/content?type=${remixType === 'Remix' ? 'Remix' : 'Playlist'}`);
    setOpen(false);
  };

  useEffect(() => {
    dispatch(contentAction.setContentData({}));

    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
          setOriginalLanguage(lang);
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) {
      getDefaultLocaleLang();
    } else {
      setOriginalLanguage(defaultLocale.lang);
    }
  }, [defaultLocale]);

  useEffect(() => {
    if (Object.keys(langCodes).length > 0) return;

    let lCodes = [];
    getLanguageCodes('Translate')
      .then((langs) => {
        const sortedRevLangCodes = Object.entries(langs).sort((a, b) => {
          if (a[1] < b[1]) return -1;
          else if (a[1] > b[1]) return 1;
          return 0
        });
        const langCodesMap = sortedRevLangCodes.reduce((lCodes, keyValue) => {
          lCodes[keyValue[0]] = keyValue[1];
          return lCodes;
        }, {});
        lCodes = Object.keys(langCodesMap);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_LANGUAGES'})
          }),
        });
      })
      .finally(() => {
        setLangCodes(lCodes);
        dispatch(contentAction.setContentType(remixType));
      });

      return () => {
        dispatch(contentAction.setContentData({}));
        dispatch(contentAction.setContentType(''));
      };
  }, []);

  const title = lookUp({
    key: remixType === DEFAULT_TYPE
      ? 'CONSOLE_CREATE_NEW_LO'
      : 'CONSOLE_CREATE_NEW_PLAYLIST'
  }).toUpperCase?.();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      title={title}
      actions={
        <>
          <Box flex={1} />
          <ThemedButton onClick={handleClose}>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>
          <ThemedButton color="success" onClick={createContent}>
            {lookUp({ key: 'CONSOLE_CONTINUE_BUTTON' })}
          </ThemedButton>
        </>
      }
      maxWidth="sm"
      fullWidth
    >
      {originalLanguage &&
        <Box flex={1} className={classes.root}>
          <Grid container direction="column" spacing={2}>
            <Grid
              // FIXME normalize layout in order to remove custom gutterTop the entire wrapper has a negative offset top, fix and remove
              className={classes.gutterTop}
              item
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="originalTitle"
                  label={lookUp({ key: 'CONSOLE_ORIGINALTITLE' })}
                  aria-describedby="originalTitle-helper"
                  autoFocus={!originalTitle}
                  value={originalTitle}
                  onChange={(e) => {
                    if (formErrors.originalTitle && e.target.value?.length > 0) {
                      setFormErrors({ ...formErrors, originalTitle: null })
                    }
                    setOriginalTitle(e.target.value);
                  }}
                  required
                  error={formErrors.originalTitle}
                />
                <FormHelperText id="originalTitle-helper" error={formErrors.originalTitle} >
                  {formErrors.originalTitle
                    ? formErrors.originalTitle
                    : lookUp({ key: 'CONSOLE_ORIGINALTITLE_HELPERTEXT' })
                  }
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  id="description"
                  label={lookUp({ key: 'CONSOLE_DESCRIPTION' })}
                  aria-describedby="description-helper"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  minRows={4}
                />
                <FormHelperText id="description-helper">
                  {lookUp({ key: 'CONSOLE_DESCRIPTION_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <TextField
                  size="small"
                  id="originalLanguage"
                  label={lookUp({ key: 'CONSOLE_DEFAULT_LANGUAGE' })}
                  aria-describedby="description-helper"
                  value={originalLanguage}
                  onChange={(e) => setOriginalLanguage(e.target.value)}
                  select
                  required
                >
                  {langCodes.map?.((languageCode) => (
                    <MenuItem label={languageCode} value={languageCode} key={languageCode}>
                      {lookUp({ key: `CONSOLE_LANG_${languageCode}` })}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText id="originalLanguage-helper">
                  {lookUp({ key: 'CONSOLE_ORIGINALLANGUAGE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              {user?.customerType !== 'SysAdmin' && <OrganizationSelector />}
            </Grid>
          </Grid>
        </Box>
      }
    </Dialog>
  );
}

InitDialog.propTypes = {
  remixType: PropTypes.string,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(InitDialog);
