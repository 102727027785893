import { useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';
import { DataGrid } from '@material-ui/data-grid';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IngestFile from 'components/IngestFile';
import NoResultsDataGridOverlay from 'components/DataGridOverlayNoResults';
import DataGridOverlayLoaderLinear from 'components/DataGridOverlayLoaderLinear';
import AssetTypeIcon from 'components/AssetTypeIcon';
import withFileUpload from 'helpers/withFileS3Upload';
import { dateFormatter } from 'helpers/time';
import { utcToLocalTimeZone } from 'utils/timezoneDate';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const Ingest = ({ upload, enqueueSnackbar }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const classes = useStyles();

  const columnsDefinition = [
    {
      field: 'type',
      headerName: ' ',
      width: 24,
      renderCell: (item) => <AssetTypeIcon type={item.row.type} color="primary" />
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      minWidth: 142,
      renderCell: (item) => dateFormatter(utcToLocalTimeZone({ date: item.row.createdDate })),
    },
    {
      field: 'originalTitle',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      minWidth: 320,
      flex: 3,
      renderCell: (item) => (
        <Tooltip title={lookUp({ key: 'CONSOLE_OPEN_IN_NEW_HELPERTEXT'})}>
          <Link
            className={classes.link}
            href={`/content/${item.row.id}/edit#basic`}
            target={'_blank'}
          >
            {item.row.originalTitle}
          </Link>
        </Tooltip>
      )
    },
  ];

  return (
    <Box flex={1}>
      <Grid container direction="column" spacing={1}>
        <Grid item md={8} xs={12}>
          <IngestFile
            upload={upload}
            enqueueSnackbar={enqueueSnackbar}
            setResultsFromServer={setUploadedFiles}
          />
        </Grid>
        {Boolean(uploadedFiles.length) && (
          <Grid item md={8} xs={12}>
            <DataGrid
              columns={columnsDefinition}
              rows={uploadedFiles}
              components={{
                LoadingOverlay: DataGridOverlayLoaderLinear,
                NoRowsOverlay: NoResultsDataGridOverlay,
                NoResultsOverlay: NoResultsDataGridOverlay,
              }}
              componentsProps={{
                noRowsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
                noResultsOverlay: {
                  title: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
                },
              }}
              rowHeight={64}
              autoHeight
              autoPageSize
              disableSelectionOnClick
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Ingest.propTypes = {
  upload: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(withFileUpload, withSnackbar)(Ingest);
