import React from 'react';
import { lookUp } from 'services/stringService';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { ArrowBack, ExitToApp } from '@material-ui/icons';
import { signOut } from 'services/customersService';
import { CenteredBlock } from 'components';

const NetworkError = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <CenteredBlock>
      <Typography variant={'h6'} gutterBottom>
        {lookUp({ key: 'CONSOLE_NO_ACCESS' })}
      </Typography>
      <Typography variant={'h5'} color={'textSecondary'} gutterBottom>
        {lookUp({ key: 'CONSOLE_NO_ACCESS_TEXT' })}
      </Typography>
      <Typography variant={'h5'} color={'textSecondary'} gutterBottom>
        {lookUp({ key: 'CONSOLE_TRY_SIGN_OUT_TEXT' })}
      </Typography>
      <Grid container spacing={2} style={{width: '33%'}} justifyContent="center">
        <Grid item xs={6}>
        <Button
          fullWidth
          color="primary"
          style={{height: '100%'}}
          onClick={() => navigate(window.history.state.idx === 0 ? '/' : -1)}
          startIcon={<ArrowBack />}
        >
          {lookUp({ key: 'CONSOLE_BACK_BUTTON' })}
        </Button>
        </Grid>
        <Grid item xs={6}>
        <Button
          fullWidth
          color="primary"
          style={{height: '100%'}}
          onClick={() => signOut(dispatch)}
          endIcon={<ExitToApp />}
        >
          {lookUp({ key: 'CONSOLE_SIGN_OUT_BUTTON' })}
        </Button>
        </Grid>
      </Grid>
    </CenteredBlock>
  )
};

export default NetworkError;
