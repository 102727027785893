import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
  Grid,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    padding: 16,
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
}));

function NftMinting(props) {
  const { url, id, setOpen, open, enqueueSnackbar } = props;
  
  const classes = useStyles();

  const startMinting = () => {
    const body = {
      referenceObjectId: id,
      inputFileLocation: url,
      name: `MintNftJob${Date.now()}`,
    };

    jobManagerService
      .mintNft(body)
      .then((jobResponse) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: `${lookUp({ key: `CONSOLE_${jobResponse.type}` })} -> ${lookUp({ key: `CONSOLE_${jobResponse.status}` })}`,
        });
        setOpen('nftMinting', false);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_FAIL_TEMPLATE', type: lookUp({ key: `CONSOLE_${'Minting'}` }) }),
        });
      });
  };

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_NFTMINTING_TITLE' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('nftMinting', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>{lookUp({ key: 'CONSOLE_start_minting_text' })}</span>
      <DialogContent></DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <Button  onClick={startMinting} color="primary">
            {lookUp({ key: 'CONSOLE_Start_Minting_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(NftMinting);
