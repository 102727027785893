import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TagCloud as ReactTagCloud } from 'react-tagcloud';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Loader from 'components/Loader';

const useInputStyles = makeStyles((theme) => ({
  root: {
    gap: theme.spacing(1),
  },
  cloudTags: {
    margin: theme.spacing(0, 1, 1, 0),
  },
}));

const TagCloud = ({
  getTags,
  loadedTags,
  onSelect,
  clickable = false,
}) => {
  const [tags, setTags] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useInputStyles();

  useEffect(() => {
    setLoading(true);

    const fetchTags = async () => {
      try {

        const fetchedTags = await getTags();
        setTags(fetchedTags);
      } catch (error) {
        // FIXME implement logging
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (getTags) {
      fetchTags();
    } else {
      setTags(loadedTags || []);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(tags)) {
      setLoading(false);
    }
  }, [tags]);

  const customRenderer = (tag) => {
    return (
      <Chip
        className={classes.cloudTags}
        label={tag.value}
        clickable={clickable || !!onSelect}
        variant="default"
        color="info"
        size="small"
      />
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <ReactTagCloud
      className={classes.root}
      style={{
        width: '100%',
        cursor: (clickable || !!onSelect) ? 'pointer' : 'unset',
      }}
      tags={tags || []}
      renderer={customRenderer}
      minSize={30}
      maxSize={20}
      shuffle={false}
      colorOptions={{ luminosity: 'bright', hue: '#00BBFF' }}
      onClick={(tag) => onSelect?.(tag)}
    />
  );
};

TagCloud.propTypes = {
  clickable: PropTypes.bool,
  getTags: PropTypes.func,
  loadedTags: PropTypes.array,
  onSelect: PropTypes.func,
};

export default TagCloud;
