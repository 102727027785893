import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Link, IconButton, Tooltip } from '@material-ui/core';
import { Lister, CreatedOn, Toolbar } from 'components';
import brandsService from 'services/brandsService';
import uniformServices from 'services/uniformServices';
import { MobileFriendly, MobileOff, Autorenew } from '@material-ui/icons';
import AlertService from 'services/alertService';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddButton from 'components/AddButton';
import DeleteButton from 'components/DeleteButton';
import classNames from 'classnames';

const DEFAULT_PAGE_SIZE = 25;
const STATUS_COLORS = {
  Uninitialized: '#F0FFFF',
  InitStarted: '#F5F5DC',
  InitRunning: '#FF7F50',
  InitFailed: '#B22222',
  Initialized: '#FFD700',
  PublishStarted: '#ADFF2F',
  PublishRunning: '#32CD32',
  PublishFailed: '#FF4500',
  Published: '#00FF00',
};

const useStyles = makeStyles((theme) => {
  let statusRules = Object.entries(STATUS_COLORS)
    .reduce((rules, [status, mainColor]) => {
      if (!rules[status]) {
        rules[status] = {
            backgroundColor: mainColor,
            color: theme.palette.getContrastText(mainColor),
        }
      }
      return rules;
  }, {});

  return {
    ...statusRules,
    rowIndicator: {
      padding: theme.spacing(.5, 1),
    }
  };
});

const Brands = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [search, setSearch] = useSearchParams();
  const [items, setItems] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);

  const pageSize = search.get('pageSize') ?? DEFAULT_PAGE_SIZE;
  const page = search.get('page') ?? 0;

  useEffect(() => {
    const getData = () => {
      setLoading(true);
      uniformServices
        .search('Brands', search, pageSize ?? DEFAULT_PAGE_SIZE, page ?? 0)
        .then((res) => {
          setTotalCount(res.totalCount);
          setItems([...res.data.filter((item) => !deletedIds?.includes(item.id))]);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_MESSAGE_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_BRANDS' }),
            }),
          });
        })
        .finally(() => setLoading(false));
    };

    getData();
  }, [search, trigger]);

  const retry = ({ id, name, published, awsRegion }) => {
    switch (published) {
      case 'InitFailed':
        brandsService
          .initialize(id, awsRegion)
          .then(() => {
            AlertService.displaySuccess({
              msgBar: enqueueSnackbar,
              message: lookUp({ key: 'CONSOLE_REINIT_ERROR_TEMPLATE', title: name }),
            });
            setTrigger(Symbol('reload'));
          })
          .catch((error) => {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error,
              context: lookUp({ key: 'CONSOLE_REINIT_ERROR_TEMPLATE', title: name }),
            });
          });
        break;
      case 'PublishFailed':
        brandsService
          .publish(id)
          .then(() => {
            AlertService.displaySuccess({
              msgBar: enqueueSnackbar,
              message: lookUp({ key: 'CONSOLE_REPUBLISH_SUCCESS_TEMPLATE', title: name }),
            });
            setTrigger(Symbol('reload'));
          })
          .catch((error) => {
            AlertService.displayError({
              msgBar: enqueueSnackbar,
              error,
              context: lookUp({ key: 'CONSOLE_REPUBLISH_ERROR_TEMPLATE', title: name }),
            });
          });
        break;
      default:
        break;
    }
  };

  const handleDelete = (id, name) => {
    setDeletedIds(id);
    uniformServices
      .delete('Brands', id)
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: name }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: name }),
        });
      });
  };

  const columns = [
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      align: 'left',
      sortable: true,
      flex: true,
      renderCell: (item) => (
        <Link to={`/configuration/brands/${item.row.id}/edit#`} component={RouterLink}>
          {item.value}
        </Link>
      ),
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      sortable: true,
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'published',
      headerName: lookUp({ key: 'CONSOLE_STATUS' }),
      align: 'left',
      width: 160,
      renderCell: (item) => (
        <>
          <Typography component="span" variant="body2" className={classNames(classes.rowIndicator, classes[item.row.published])}>
            {item.row.deleted ? 'Unsubscribed' : item.row.published}
          </Typography>
          {['PublishFailed', 'InitFailed'].includes(item.row.published) && (
            <IconButton size="small" color="inherit" onClick={() => retry(item.row)}>
              <Autorenew />
            </IconButton>
          )}
        </>
      ),
    },
    {
      field: 'id',
      headerName: ' ',
      align: 'right',
      width: 40,
      sortable: false,
      renderCell: (item) => {
        return item.row.firebasePushApiKey &&
          item.row.applePushPrivateKey &&
          item.row.applePushCertificate ? (
          <Tooltip title={lookUp({ key: 'CONSOLE_MOBILE_FRIENDLY' })}>
            <MobileFriendly />
          </Tooltip>
        ) : (
          <Tooltip title={lookUp({ key: 'CONSOLE_NOT_MOBILE_FRIENDLY' })}>
            <MobileOff />
          </Tooltip>
        );
      },
    },
    {
      field: 'id',
      headerName: ' ',
      align: 'right',
      width: 80,
      sortable: false,
      renderCell: (item) => (
        <DeleteButton
          onDelete={() => handleDelete(item.id, item.row.name)}
          dialogContent={item.row?.name ? (
            <Box component="span">
              {item.row.name}
            </Box>
          ) : null}
          variant="icon"
        />
      ),
    },
  ];

  return (
    <Box flex={1} xs={12}>
      <Toolbar triggerSearch={() => setTrigger(Symbol('refresh'))} />
      <Grid item container xs={12} justifyContent="flex-end">
        <AddButton tooltipText={lookUp({ key: 'CONSOLE_CREATE_ASSET_HELPERTEXT' })} />
      </Grid>
      <Lister loading={loading} columns={columns} rows={items} totalCount={totalCount} />
      <Grid item container xs={12} justifyContent="flex-end">
        <AddButton tooltipText={lookUp({ key: 'CONSOLE_CREATE_ASSET_HELPERTEXT' })} />
      </Grid>
    </Box>
  );
};

export default withSnackbar(Brands);
