import { lookUp } from 'services/stringService';

const props = {
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiButton: {
    variant: "contained",
  },
  MuiChip: {
    variant: "contained",
    color: "primary",
  },
  MuiLink: {
    color: 'textPrimary'
  },
  MuiPaper: {
    variant: 'outlined'
  },
  MuiCardHeader: {
    titleTypographyProps: { variant: "h6" },
  },
  MuiTextField: {
    variant: 'outlined',
    size: 'small',
  },
  MuiDataGrid: {
    disableColumnMenu: true,
    localeText: {
      columnHeaderSortIconLabel: lookUp({ key: 'CONSOLE_SORT' })
    }
  }
};

export default props;
