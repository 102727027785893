import { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { lookUp } from 'services/stringService';
import { makeStyles, Grid, Card, CardHeader } from '@material-ui/core';
import { PeopleAlt, DonutLarge, DonutSmall, OndemandVideo } from '@material-ui/icons';
import { getTotalAggregatedReport } from 'services/reportingService';
import AlertService from 'services/alertService';


const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    boxShadow: `0px 0px 2px 0px ${theme.palette.info.dark}`,
  },
  cardHeader: {
    textAlign: 'center',
  }
}));


function TotalAggregated({ enqueueSnackbar }) {
  const [data, setData] = useState();
  const classes = useStyles();
  
  useEffect(() => {
    getTotalAggregatedReport()
    .then(data => setData(data))
    .catch(error => {
      AlertService.displayError(
        {
          msgBar: enqueueSnackbar,
          error: error.message,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_REPORT' }) })
        }
      )}
    )
  }, []);

  const icons = {
    totalUsers: <PeopleAlt />,
    totalContentWatched: <OndemandVideo />,
    totalContentWatchedP50: <DonutLarge />,
    totalContentWatchedP90: <DonutSmall />,
  }

  return (
    <Grid item container spacing={3} justifyContent="space-evenly">
      {data &&
          Object.entries(data)?.map(e => (
            <Grid item key={e[0]} md={Math.floor(12 / Object.keys(data).length)} xs={Math.floor(24 / Object.keys(data).length)}>
              <Card className={classes.card}>
                <CardHeader 
                  className={classes.cardHeader}
                  title={e[1]} 
                  subheaderTypographyProps={{variant: 'body2', color: "textSecondary"}}
                  subheader={lookUp({ key: `CONSOLE_${e[0]}` })} 
                  titleTypographyProps={{variant: 'h1', color: "textPrimary"}}
                />
              </Card>              
            </Grid>
          )
        )        
      }
    </Grid>
  )
}

export default withSnackbar(TotalAggregated);