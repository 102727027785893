import React from 'react';
import { lookUp } from 'services/stringService';
import { bool, string } from 'prop-types';

import { CircularProgress } from '@material-ui/core';

import CenteredBlock from './CenteredBlock';

const Loader = ({ inline, height }) => (
  <CenteredBlock height={inline ? 'auto' : height}>
    <CircularProgress />
  </CenteredBlock>
);

Loader.propTypes = {
  inline: bool,
  height: string
};

Loader.defaultProps = {
  inline: false,
  height: '100vh'
};

export default Loader;
