import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getLibraryPropertyEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('LibraryProperty');
  if (!endpoint) throw Error('No LibraryProperty endpoint.');
  return endpoint;
};

const getProperty = () => axios.get(`${getLibraryPropertyEndpoint()}`)
  .then((res) => res?.data);

const getDesignProperty = () => axios.get(`${getLibraryPropertyEndpoint()}`)
  .then((res) => {
    let filtered = res.data.filter(props => props.startsWith('Design'))
    return filtered
});

const getPropertyComplete = (prefix) => axios.get(`${getLibraryPropertyEndpoint()}/Complete/${prefix}`)
  .then((res) => res?.data);

const getPropertySuggestCardSize = () => axios.get(`${getLibraryPropertyEndpoint()}/Suggest/LibraryProperty%3ACardSize`)
  .then((res) => res?.data);

const getPropertyEnums = () => axios.get(`${getLibraryPropertyEndpoint()}/Enums`)
  .then((res) => res.data);  

export { getProperty, getPropertyComplete };

export default {
  getProperty,
  getPropertyComplete,
  getPropertyEnums,
  getPropertySuggestCardSize,
  getDesignProperty
};
