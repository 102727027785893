import { lookUp } from 'services/stringService';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { shape, string } from 'prop-types';
import _ from 'lodash';

import {
  makeStyles,
  Grid,
  Avatar,
  CardContent,
  Card,
  Typography,
  Button,
} from '@material-ui/core';
import { Person, Edit } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    overflow: 'unset',
    marginTop: 50
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 130,
    height: 130,
    marginTop: -50,
    marginBottom: 30,
    boxShadow:
      '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)'
  },
}));

const MyAccount = (props) => {
  
  const classes = useStyles();
  const { user } = props;

  return (
    <>
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid item xs={12} md={5}>
          <Card className={classes.cardRoot} elevation={0}>
            <CardContent className={classes.card}>
              {user.properties?.['Customer:Image:Avatar2DUrl'] ? (
                <Avatar src={user.properties['Customer:Image:Avatar2DUrl']} className={classes.avatar} />
              ) : (
                <Avatar className={classes.avatar}>
                  <Person fontSize={'large'} />
                </Avatar>
              )}
              <Typography variant={'h5'} color={'secondary'} gutterBottom>
                {user.username}
              </Typography>
              <Typography gutterBottom>
                {user.emailAddress}
              </Typography>
              <p/>
              <Button
                variant={'contained'}
                color={'primary'}
                component={Link}
                to={`/account/${user.customerId || user.id}/edit#basic`}
                startIcon={<Edit />}
              >
                {lookUp({ key: 'CONSOLE_EDIT_BUTTON' })}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

MyAccount.propTypes = {
  user: shape({
    name: string,
    emailAddress: string,
  }).isRequired,
};

const mapStateToProps = ({ auth: { user } }) => ({ user });

export default connect(mapStateToProps)(MyAccount);
