import React, { useState, useEffect, useCallback } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles, Grid, Tooltip, Zoom } from '@material-ui/core';
import {
  FixedSizeGrid as VirtualFixedList,
  VariableSizeGrid as VirtualVariableList,
} from 'react-window';
import { Loader } from 'components';
import { EColorMap } from 'constants/index';
import { videoTypes } from 'constants/contentConst';
import contentService from 'services/contentService';
import { processedImageUrl } from 'services/imageProcessingService';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    marginLeft: '3px',
    width: '100%',
  },
  brTypesContainer: {
    paddingTop: theme.spacing(14.5),
  },
  brTypes: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0, 1),
    height: '25px',
    backgroundColor: 'papayawhip',
    borderRadius: '1px',
    cursor: 'pointer',
  },
  gridList: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    transform: 'translateZ(0)',
  },
  gridListItem: {
    width: 'auto',
    height: 'auto',
    marginRight: '1px',
  },
  listImage: {
    width: '199px',
    height: 'auto',
  },
  eventContainer: {
    height: theme.spacing(36),
    display: 'flex',
    flexDirection: 'row',
  },
  timepoint: {
    position: 'absolute',
    width: '100%',
    height: '25px',
    bottom: '14px',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: 'large',
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  timelineBrPoints: {
    margin: theme.spacing(2, 0),
  },
  tagCloud: {
    marginTop: theme.spacing(3),
  },
  tagChips: {
    margin: theme.spacing(0.3),
  },
  breakpointTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: theme.spacing(30),
    margin: theme.spacing(0, 0, 1, 1),
  },
  decorationSqure: {
    width: '25px',
    height: '25px',
    borderRadius: '15px',
    marginRight: theme.spacing(0.5),
  },
  brPtTypeSelector: {
    height: '80px',
  },
  formControl: {
    width: '100%',
  },
  brInkDotsFrame: {
    marginLeft: '2px',
    background: `repeating-linear-gradient(90deg, transparent, transparent 99px, #d3d3d3 99px, #d3d3d3 100px, transparent 100px, transparent 199px, #000000 199px, #000000 200px), repeating-linear-gradient(0deg, transparent, transparent 50%, #d3d3d3 50%, #d3d3d3 51%)`,
  },
  brPtWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1.5),
    maxHeight: theme.spacing(2.8),
  },
  brPtInkDot: {
    borderRadius: '15px',
    cursor: 'pointer',
  },
}));

function BrPtTimeline(props) {
  const {
    selectedBrPt,
    thumbnailsUrl,
    seek,
    brPtContainer,
    handleBreakpointSelect,
    isBrPtsLineLoading,
    timelineListRef,
    virtualImgListRef,
    brPtPanelListRef,
    contentType,
  } = props;

  const classes = useStyles();
  const [vttImages, setVttImages] = useState([]);
  const [vttLoading, setVttLoading] = useState(false);
  const [timelineWidth, setTimelineWidth] = useState(500);

  const scrubsCbRef = useCallback((scrubImgGrid) => {
    if (scrubImgGrid) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target.offsetWidth) setTimelineWidth(entry.target.offsetWidth - 2);
        }
      });
      resizeObs.observe(scrubImgGrid);
    }
  }, []);

  useEffect(() => {
    setVttLoading(true);
    const getVttImages = async () => {
      try {
        const images = await contentService.getVttFile(thumbnailsUrl);
        setVttImages(images);
      } catch (error) {
        // TODO: error handling
        console.error(error);
      } finally {
        setVttLoading(false);
      }
    };
    getVttImages();
  }, []);

  if (vttImages.length > 0) {
    const images = [...vttImages];
    for (let i = 0; i < vttImages.length; i += 1) {
      const imageTime = images[i].time;
      if (imageTime.indexOf(' -->') !== -1) {
        images[i].time = imageTime.slice(0, imageTime.indexOf(' -->'));
      }
    }
  }

  const imageBox = ({ columnIndex, data, rowIndex, style }) => {
    let timePoint = data[columnIndex].time.slice(0, -4);
    timePoint = timePoint.slice(0, 2) === '00' ? timePoint.slice(3) : timePoint;
    const processedUrl = processedImageUrl(data[columnIndex].thumbnail, {
      r1f: 'w', // fixed aspect ratio, width specified
      r1w: 200, // width px
    });
    return (
      <div
        style={{ ...style, cursor: 'pointer' }}
        onClick={(event) => seek(data[columnIndex].time)}
      >
        <img className={classes.listImage} src={processedUrl} alt={data[columnIndex].time} />
        <div className={classes.timepoint}>{timePoint}</div>
      </div>
    );
  };

  const brPtDot = ({ columnIndex, data, rowIndex, style }) => {
    const brPt = data[columnIndex];
    return (
      <div className={classes.brPtWrapper} style={style}>
        {brPt?.position && (
          <Tooltip
            key={`${brPt.position}_${columnIndex}`}
            title={brPt.position}
            arrow
            TransitionComponent={Zoom}
            leaveDelay={1000}
            placement="top"
          >
            <span
              className={classes.brPtInkDot}
              onClick={(event) => {
                brPtPanelListRef.current.scrollToItem({ align: 'center', rowIndex: columnIndex });
                handleBreakpointSelect(brPt.type, columnIndex);
              }}
              style={{
                backgroundColor: brPt.id === selectedBrPt.id ? '#00cbe6' : EColorMap[brPt.type],
                width: brPt.inkDotWidth,
              }}
            >
              {'\u00A0'}
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  const getItemSize = (index) => {
    return brPtContainer[selectedBrPt.type]?.[index]?.wrapperWidth;
  };

  const TimelineBrPts = (
    <section>
      {isBrPtsLineLoading ? (
        <Loader inline />
      ) : (
        <VirtualVariableList
          ref={timelineListRef}
          className={classes.brInkDotsFrame}
          height={50}
          rowCount={1}
          rowHeight={() => 50}
          width={timelineWidth}
          columnCount={brPtContainer[selectedBrPt.type]?.length}
          estimatedColumnWidth={(vttImages.length * 200) / brPtContainer[selectedBrPt.type]?.length}
          columnWidth={getItemSize}
          itemData={brPtContainer[selectedBrPt.type]}
          style={{ overflowY: 'hidden' }}
          onScroll={({ scrollLeft }) => {
            if (virtualImgListRef.current) {
              virtualImgListRef.current.scrollTo({ scrollLeft, scrollTop: 0 });
            }
          }}
        >
          {brPtDot}
        </VirtualVariableList>
      )}
    </section>
  );

  return (
    <>
      <div ref={scrubsCbRef}></div>
      {videoTypes.includes(contentType) && (
        <Grid container direction="column">
            <Grid item className={classes.imageContainer}>
              {!vttLoading && timelineWidth && (
                <VirtualFixedList
                  ref={virtualImgListRef}
                  height={125}
                  rowHeight={125}
                  rowCount={1}
                  width={timelineWidth}
                  columnWidth={200}
                  columnCount={vttImages.length}
                  itemData={vttImages}
                  style={{ overflowY: 'hidden' }}
                  onScroll={({ scrollLeft }) => {
                    if (timelineListRef.current) {
                      timelineListRef.current.scrollTo({ scrollLeft, scrollTop: 0 });
                    }
                  }}
                >
                  {imageBox}
                </VirtualFixedList>
              )}
            </Grid>
          </Grid>
        )}
      <Grid container direction="column">
        <Grid item className={classes.timelineBrPoints}>
          {TimelineBrPts}
        </Grid>
      </Grid>
    </>
  );
}

export default BrPtTimeline;
