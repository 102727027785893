import { useEffect, useState } from 'react';
import { create } from 'jss';
import { createGlobalStyle } from 'styled-components/macro';
import { StylesProvider, ThemeProvider, jssPreset } from '@material-ui/core/styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUser, setAccess } from 'store/actions/authAction';
import { signOut, getCustomer } from 'services/customersService';
import { getAll, resultsToReduxObject } from 'services/stringService';
import PrivateRoute from './PrivateRoute';
import { parseUserFromToken } from 'helpers/common';
import {
  Register,
  SignIn,
  NotFound,
  NetworkError,
  ResetPassword,
  LoginWithTemporaryToken,
  SSOLite,
} from 'pages';
import { Loader } from 'components';
import App from '../App';
import { setConsoleEndpoints } from 'services/apiEndpoints';
import { setLexicons } from 'store/actions/localizationAction';
import { setBrandStyle } from 'store/actions/brandStyleAction';
import { getAccess } from 'services/parametersService';
import { brandedTheme } from 'theme/variants';

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
});

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }
  body {
    background: ${(props) => props.theme?.palette?.background?.default};
  }
`;

function RouterWrapper() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setStateTheme] = useState();
  const [isNetworkError, setIsNetworkError] = useState(false);
  const [areEndpointsLoaded, setAreEndpointsLoaded] = useState(false);

  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const setUserToStore = async (user) => {
    dispatch(setUser(user));
  };

  const setAccessToStore = async (access) => {
    dispatch(setAccess(access));
  };

  const setLexiconsToStore = async (lexicons) => {
    dispatch(setLexicons(lexicons));
  };

  const retrieveUserData = async () => {
    const userFromCookies = parseUserFromToken();
    if (userFromCookies) {
      try {
        const user = await getCustomer(userFromCookies.id);
        await setUserToStore(user);
      } catch (err) {
        if (err.message === 'Request failed with status code 404') {
          signOut(dispatch);
          navigate('/sign-in');
          return null;
        }
      }
    }
  };

  const retrieveAccess = async () => setAccessToStore(await getAccess());

  const retrieveLexicons = async () => {
    const now = new Date().getTime();
    const in30mins = now + 30 * 60 * 1000;
    const fromLocalStorage = JSON.parse(localStorage.getItem('lexicons'));
    const lexiconsExpiry = localStorage.getItem('lexiconsExpiry');
    const selectedLexicon = localStorage.getItem('selectedLexicon');
    if (fromLocalStorage && Object.keys(fromLocalStorage)?.length > 0 && lexiconsExpiry > now) {
      await setLexiconsToStore({
        ...fromLocalStorage,
        expiry: in30mins,
        selected: selectedLexicon,
      });
      return 'from Local Storage';
    }
    try {
      const fromBE = resultsToReduxObject(await getAll());
      localStorage.setItem('lexicons', JSON.stringify({ ...fromBE }));
      localStorage.setItem('lexiconsExpiry', in30mins);
      await setLexiconsToStore({ ...fromBE, expiry: in30mins });
      return 'from BE Strings Service';
    } catch (err) {
      if (err.message === 'Request failed with status code 404') {
        signOut(dispatch);
        navigate('/sign-in');
        return null;
      }
    }
  };

  useEffect(() => {
    const getEndpoints = async () => {
      setConsoleEndpoints()
        .then(() => setAreEndpointsLoaded(true))
        .catch((error) => {
          if (error.message === 'Network Error') setIsNetworkError(true);
          setIsLoading(false);
        });
    };
    getEndpoints();
  }, []);

  useEffect(() => {
    if (areEndpointsLoaded) {
      brandedTheme()
        .then((theme) => {
          if (theme?.brand) dispatch(setBrandStyle(theme.brand));
          setStateTheme(theme);
          return Promise.resolve(null);
        })
        .then(() => retrieveUserData())
        .then(() => retrieveAccess())
        .then(() => retrieveLexicons())
        .then((l) => console.info(!l ? 'Lexicons could not be loaded.' : `Lexicons loaded ${l}.`))
        .then(() => setIsLoading(false))
        .catch(console.error);
    }
  }, [areEndpointsLoaded]);

  // useEffect(() => {
  //   return history.block((nextLocation, action) => {

  //     const getParentUrl = (pathname) => {
  //       let pathArray = pathname.split('/');
  //       pathArray.pop();
  //       return pathArray.join('/');
  //     }

  //     const samePageDifferentTab = (getParentUrl(location.pathname) === getParentUrl(nextLocation.pathname));

  //     if (isNavigationBlocked && !samePageDifferentTab) {
  //       const question = lookUp({ key: 'CONSOLE_UNSAVED_CHANGES_ALERT' });
  //       const toContinueForward = window.confirm(question);
  //       if (toContinueForward === true) dispatch(navigationAction.allowNavigation());
  //       return toContinueForward;
  //     } else {
  //       return true;
  //     }
  //   });
  // }, [isNavigationBlocked]);

  return isLoading || !theme ? (
    <Loader />
  ) : isNetworkError ? (
    <NetworkError />
  ) : (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <Routes>
            <Route path={'/register/:token'} element={<Register />} />
            <Route path={'/sign-in'} element={<SignIn />}/>
            <Route path={'/sso'} element={<SSOLite />}/>
            <Route path={'/reset-password'} element={<SignIn reset={true} />} />
            <Route path={'/resetPassword/:token'} element={<ResetPassword />}/>
            <Route path={'/login/:token'} element={<LoginWithTemporaryToken />}/>
            <Route path="/*" element={<PrivateRoute isAuthenticated={!!user.id} path="/" component={App} />} />
            <Route element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default RouterWrapper;
