import moment from 'moment';

const tToTT = (time) => parseInt(time, 10) < 10 ? `0${parseInt(time, 10)}` : time;

const secondsToMMSS = (totalSeconds) => {
  if (totalSeconds.toString().trim() === '') return '';

  const minutes = tToTT(Math.floor(totalSeconds / 60));
  const seconds = tToTT(Math.floor(totalSeconds % 60));

  return `${minutes}:${seconds}`;
};

const msToMMSS = (ms) => {
  if ((typeof ms === 'string' && ms.trim() === '') || Number.isNaN(+ms)) {
    return '';
  }

  return secondsToMMSS(ms / 1000);
};

const MMSSToMs = (value) => {
  let MMSS = value;
  if (!MMSS.match(/:/)) MMSS = `${value}:00`;

  let [minutes, seconds] = MMSS.split(':');

  if (!minutes) {
    minutes = 0;
  }

  if (seconds > 59) {
    seconds = 59;
  }

  if (!seconds) {
    seconds = 0;
  }

  return (minutes * 60 + +seconds) * 1000;
};

const MMSSToHHMMSS = (MMSS) => {
  const [minutes, seconds] = MMSS.split(':');

  const hours = Math.floor(minutes / 60);

  return `${tToTT(hours)}:${tToTT(minutes % 60)}:${tToTT(seconds)}`;
};

const msToHHMMSS = (ms) => new Date(ms).toISOString().slice(11, -5);

const HHMMSSToMMSS = (HHMMSS) => {
  const [hours, minutes, seconds] = HHMMSS.split(':').map((time) => parseInt(time, 10));
  return `${tToTT(hours * 60 + minutes)}:${tToTT(seconds)}`;
};

const HHMMSSToMs = (HHMMSS) => {
  if (!HHMMSS) return;
  const [hours, minutes, seconds] = HHMMSS.split(':').map((time) => parseInt(time, 10));
  return Date.UTC(1970, 0, 1, hours, minutes, seconds);
};

const HHMMSSMsToMs = (HHMMSSMs = "0") => {
  const millisInd = HHMMSSMs.indexOf('.');
  const millis = millisInd === -1 ? 0 : parseInt(HHMMSSMs.slice(millisInd+1), 10);
  const [hours, minutes, seconds] = HHMMSSMs.split(':').map((time) => parseInt(time, 10));
  return Date.UTC(1970, 0, 1, hours, minutes, seconds, millis);
};


const momentHHMMSSToUnix = (time) => {
  const validTime = moment(time).utc().format('HH:mm:ss');
  return MMSSToMs(HHMMSSToMMSS(validTime));
};

const getMs = (timeStr) => {
  if (!timeStr) return 0;
  const toArr = timeStr.split(":");
  return toArr.reduce((ms, timeByUnit, index) => {
    ms += +timeByUnit * Math.pow(60, toArr.length-1-index);
    return ms;
  }, 0) * 1000;
};

const msToTime = (ms) => {
  return new Date(ms)?.toUTCString().split(' ')[4]+`.${('00'+(Math.floor(ms)%1000)).slice(-3)}` || "00:00:00";
}


const dateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  hourCycle: 'h23',
  minute: '2-digit'
}

const dateFormatter = date => new Date(date).toLocaleString("en-US", dateFormat);

const todayISODate = new Date().toISOString().split('T')[0];
const last30DaysISODate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().split('T')[0];
const currentMonthStart = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('T')[0];
const currentMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth()+ 1, 1).toISOString().split('T')[0];

export {
  tToTT,
  secondsToMMSS,
  msToMMSS,
  MMSSToMs,
  MMSSToHHMMSS,
  msToHHMMSS,
  HHMMSSToMMSS,
  HHMMSSToMs,
  HHMMSSMsToMs,
  momentHHMMSSToUnix,
  getMs,
  msToTime,
  dateFormat,
  dateFormatter,
  todayISODate,
  last30DaysISODate,
  currentMonthStart,
  currentMonthEnd,
};

export default {
  tToTT,
  secondsToMMSS,
  msToMMSS,
  msToHHMMSS,
  MMSSToMs,
  MMSSToHHMMSS,
  HHMMSSToMMSS,
  HHMMSSToMs,
  HHMMSSMsToMs,
  momentHHMMSSToUnix,
  getMs,
  msToTime,
  dateFormat,
  dateFormatter,
};
