import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  makeStyles,
  Grid,
  TextField,
  Link,
  Chip,
  IconButton,
  ButtonGroup,
  LinearProgress,
} from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Clear, Refresh } from '@material-ui/icons';
import { getServicesInformation } from 'services/apiEndpoints';
import { useSearchParams } from 'react-router-dom';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      justifyContent: 'center',
    },
  },
  mainTable: {
    paddingTop: '1em',
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      whiteSpace: 'normal',
    },
  },
  search: {
    margin: theme.spacing(1, 0.5, 1.5),
  },
  services: {
    maxHeight: '100%',
    overflowY: 'auto',
    padding: theme.spacing(1.5,0)
  }
}));

const ServiceVersion = (props) => {
  const { enqueueSnackbar } = props;

  const brandId = window.__RUNTIME_CONFIG__.BRAND_ID;

  const [isLoading, setIsLoading] = useState(false);
  const [backendInfo, setBackendInfo] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [search, setSearch] = useSearchParams();
  const filterText = search.get('filter') || '';
  const [trigger, setTrigger] = useState();

  const classes = useStyles();

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined || value === '') delete searchObj[key];
    setSearch(searchObj);
  };

  const requestSearch = (searchValue) => {
    setParam('filter', searchValue);
    if (searchValue === '') {
      setFiltered(backendInfo);
      return;
    }
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = backendInfo.filter((row) => {
      return Object.keys(row).some((field) => {
        return row[field] && searchRegex.test(row[field].toString());
      });
    });
    setFiltered(filteredRows);
  };

  useEffect(() => {
    setIsLoading(true);
    getServicesInformation(brandId)
      .then((versions) => {
        const indexed = versions.map((e, index) => ({ ...e, id: index }));
        setBackendInfo(indexed);
        requestSearch(filterText || '');
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_SERVICES` }),
          }),
        });
      })
      .finally(() => setIsLoading(false));
  }, [trigger]);

  const columns = [
    {
      field: 'services',
      headerName: lookUp({ key: 'CONSOLE_SERVICES' }),
      width: 300,
      sortable: false,
      renderCell: (items) => (
        <div width="100%" className={classes.services}>
          {items.value.map((e, index) => (
            <Chip key={index} size="small" style={{ margin: 1 }} label={e} />
          ))}
        </div>
      ),
    },
    {
      field: 'url',
      headerName: lookUp({ key: 'CONSOLE_API_URL' }),
      width: 300,
      renderCell: (item) => <span nowrap="true">{item.value}</span>,
    },
    {
      field: 'version',
      headerName: lookUp({ key: 'CONSOLE_VERSION' }),
      type: 'number',
      align: 'right',
      width: 400,
      sortable: false,
      renderCell: (item) => (
        <Link target="_blank" href={`${item.row.url}/swagger`}>
          <span nowrap="true">{item.value}</span>
        </Link>
      ),
    },
  ];

  const LinearLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={6} style={{ marginLeft: -4 }}>
        <QuickSearchToolbar
          value={filterText}
          onChange={(e) => requestSearch(e.target.value)}
          clearSearch={() => requestSearch('')}
          refresh={() => setTrigger(Symbol('refresh'))}
        />
      </Grid>
      <Grid item xs={12} className={classes.mainTable}>
        <DataGrid
          style={{ border: 'none' }}
          components={{
            LoadingOverlay: LinearLoadingOverlay,
          }}
          rows={filtered.length > 0 ? filtered : backendInfo}
          loading={isLoading}
          columns={columns}
          pageSize={pageSize}
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 50]}
          autoHeight
          rowHeight={80}
          disableColumnFilter
          disableColumnMenu
          disableSelectionOnClick
          componentsProps={{
            Checkbox: { disableRipple: true },
            pagination: {
              classes: null,
              labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} / ${count}`,
              SelectProps: {
                MenuProps: { disableScrollLock: true },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      className={classes.search}
      margin="dense"
      value={props.value}
      onChange={props.onChange}
      label={lookUp({ key: 'CONSOLE_SEARCH' })}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        endAdornment: (
          <ButtonGroup size="small">
            {props.value && (
              <IconButton size="small" onClick={props.clearSearch}>
                <Clear />
              </IconButton>
            )}
            <IconButton onClick={props.refresh} color="primary">
              <Refresh />
            </IconButton>
          </ButtonGroup>
        ),
      }}
      helperText={lookUp({ key: 'CONSOLE_SEARCH_HELPERTEXT' })}
    />
  );
}

export default withSnackbar(ServiceVersion);
