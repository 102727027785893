import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  Button,
  makeStyles,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { reloadService, reindexCustomers, reindexCatalog, reindexSkug, reindexPlaybackPolicyText } from 'services/maintenanceService';
import { recalculateGlobalTags, refreshIndexes } from 'services/markerService';
import { refreshAll } from 'services/contentService';
import { getServicesInformation } from 'services/apiEndpoints';
import AlertService from 'services/alertService';

import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import ThemedButton from 'components/ThemedButton';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    width: '100%',
    padding: '10px',
  },
  root: {
    width: '100%',
  },
  formControl: {
    minWidth: 200,
  },
  fullHeight: {
    height: '100%',
  },
}));

const ACTION_REINDEX_CATALOG = 'ReindexCatalog';
const ACTION_REINDEX_SKUG = "ReindexSkug";
const ACTION_REINDEX_PLAYBACKPOLICY_TEXT = "ReindexPlaybackPolicyText";

const Maintenance = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar } = props;

  const [backendInfo, setBackendInfo] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedAction, setSelectedAction] = useState('');
  const brandId = window.__RUNTIME_CONFIG__.BRAND_ID;

  const action = async () => {
    try {
      switch (selectedAction) {
        case 'RefreshIndexes':
          await refreshIndexes();
          break;
        case 'RefreshAll':
          await refreshAll();
          break;
        case 'RecalculateGlobalTags':
          await recalculateGlobalTags();
          break;
        case 'ReindexCustomers':
          await reindexCustomers();
          break;
        case ACTION_REINDEX_CATALOG:
          await reindexCatalog();
          break;
        case ACTION_REINDEX_SKUG:
          await reindexSkug();
          break;
        case ACTION_REINDEX_PLAYBACKPOLICY_TEXT:
          await reindexPlaybackPolicyText();
          break;
        default:
          break;
      }
      setSelectedAction('');
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({
          key: 'CONSOLE_LAUNCH_SUCCESS_TEMPLATE',
          title: selectedAction.replace(/([A-Z])/g, ' $1'),
        }),
      });
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LAUNCH_ERROR_TEMPLATE',
          title: selectedAction.replace(/([A-Z])/g, ' $1'),
        }),
      });
    }
  };

  useEffect(() => {
    getServicesInformation(brandId, false)
      .then((versions) => {
        const indexed = versions.map((e, index) => {
          return { ...e, id: index };
        });
        setBackendInfo(indexed);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_BACKEND_INFO' }),
          }),
        });
      });
  }, []);

  const reload = () => {
    selectedServices.forEach((service) => {
      reloadService(service)
        .then((e) => {
          setSelectedServices([]);
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: 'CONSOLE_SERVICE_RELOAD_MESSAGE_TEMPLATE', service }),
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_BACKEND_INFO' }),
            }),
          });
        });
    });
  };

  const handleDelete = (value) => {
    setSelectedServices((o) => [...o].filter((e) => e !== value));
  };

  return (
    <SectionContainer flex={1}>
      <Grid container spacing={3} direction="column">
        <Grid item container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_SELECT_ACTION_TO_TRIGGER_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              fullWidth
              label={lookUp({ key: 'CONSOLE_ACTIONS' })}
              size="small"
              value={selectedAction}
              onChange={(e) => {
                e.preventDefault();
                setSelectedAction(e.target.value);
              }}
            >
              <MenuItem value={'RefreshAll'}>
                {lookUp({ key: 'CONSOLE_TRIGGER_REINDEX_ALL_CONTENT_TEXT' })}
              </MenuItem>
              <MenuItem value={'RefreshIndexes'}>
                {lookUp({ key: 'CONSOLE_TRIGGER_REINDEX_ALL_AI_TAG_TEXT' })}
              </MenuItem>
              <MenuItem value={'RecalculateGlobalTags'}>
                {lookUp({ key: 'CONSOLE_RECALCULATE_GLOBAL_TAGS_TEXT' })}
              </MenuItem>
              <MenuItem value={'ReindexCustomers'}>
                {lookUp({ key: 'CONSOLE_REINDEX_CUSTOMERS_TEXT' })}
              </MenuItem>
              <MenuItem value={ACTION_REINDEX_CATALOG}>
                {lookUp({ key: 'CONSOLE_REINDEX_CATALOG_TEXT' })}
              </MenuItem>
              <MenuItem value={ACTION_REINDEX_SKUG}>
                {lookUp({ key: 'CONSOLE_REINDEX_SKU_TEXT' })}
              </MenuItem>
              <MenuItem value={ACTION_REINDEX_PLAYBACKPOLICY_TEXT}>
                {lookUp({ key: 'CONSOLE_REINDEX_PLAYBACKPOLICY_TEXT' })}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <ThemedButton
              disabled={!selectedAction}
              onClick={action}
              color={selectedAction ? 'primary' : null}
            >
              {lookUp({ key: 'CONSOLE_LAUNCH_BUTTON' })}
            </ThemedButton>
          </Grid>
        </Grid>
        {backendInfo && (
          <Grid item container spacing={1} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_SELECT_SERVICES_TO_RELOAD_TITLE' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                disabled={!backendInfo?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_SERVICES' })}
                size="small"
                style={{ padding: 0 }}
                value={selectedServices}
                onChange={(e) => setSelectedServices(e.target.value)}
                InputProps={
                  selectedServices.length > 0 && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton xs={{ mr: 2 }} onClick={() => setSelectedServices([])}>
                          <Clear fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }
                }
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => (
                    <Box flex={1} style={{ display: 'flex', gap: 8 }}>
                      {selected.map((value) => (
                        <Tooltip
                          title={backendInfo.find((e) => e.url === value)?.services.join(', ')}
                        >
                          <Chip
                            key={value}
                            size="small"
                            label={value.split('://')[1].split('.')[0]}
                            onDelete={() => handleDelete(value)}
                          />
                        </Tooltip>
                      ))}
                    </Box>
                  ),
                }}
              >
                {backendInfo.map((service) => (
                  <MenuItem key={service.url} value={service.url}>
                    <strong>{service.url.split('://')[1].split('.')[0]}</strong>:{' '}
                    {service.services.join(', ')}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <ThemedButton
                disabled={!selectedServices.length}
                onClick={reload}
                color={selectedServices.length ? 'primary' : null}
              >
                {lookUp({ key: 'CONSOLE_RELOAD_BUTTON_TEMPLATE', number: selectedServices.length })}
              </ThemedButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
};

export default withSnackbar(Maintenance);
