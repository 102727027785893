import React from 'react';
import { lookUp } from 'services/stringService';

function TemplateHints({template}) {
  const templates = {
    General: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_GENERAL_RECIPIENT_HELPERTEXT' }),
      placeholders: {
        Placeholders: lookUp({ key: 'CONSOLE_TEMPLATES_GENERAL_PLACEHOLDERS_HELPERTEXT' })
      }
    },
    TranscodingFinished: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_RECIPIENT_HELPERTEXT' }),
      placeholders: {
          objectId: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_OBJECTID_HELPERTEXT' }),
          objectName: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_OBJECTNAME_HELPERTEXT' }),
          streamKind: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_STREAMKIND_HELPERTEXT' }),
          workflowStatus: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_WORKFLOWSTATUS_HELPERTEXT' }),
          errorMessage: lookUp({ key: 'CONSOLE_TEMPLATES_TRANSCODING_ERRORMESSAGE_HELPERTEXT' }),
        }
    },
    UserRegistered: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_USERREGISTERED_RECIPIENT_HELPERTEXT' }),
      placeholders: {
          artistName: lookUp({ key: 'CONSOLE_TEMPLATES_ARTISTNAME_HELPERTEXT' }),
          contactEmail: lookUp({ key: 'CONSOLE_TEMPLATES_CONTACTEMAIL_HELPERTEXT' }),
          brandName: lookUp({ key: 'CONSOLE_TEMPLATES_BRANDNAME_HELPERTEXT' }),
        }
    },
    ContentUploaded: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_CONTENTUPLOADED_RECIPIENT_HELPERTEXT' }),
      placeholders: {
          fileName: lookUp({ key: 'CONSOLE_TEMPLATES_CONTENTUPLOADED_FILENAME_HELPERTEXT' }),
          objectName: lookUp({ key: 'CONSOLE_TEMPLATES_CONTENTUPLOADED_OBJECTNAME_HELPERTEXT' }),
          objectId: lookUp({ key: 'CONSOLE_TEMPLATES_CONTENTUPLOADED_OBJECTID_HELPERTEXT' }),
        }
    },
    ResetConsolePassword: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_RESETCONSOLEPASSWORD_RECIPIENT_HELPERTEXT' }),
      placeholders: {
          fullName: lookUp({ key: 'CONSOLE_TEMPLATES_RESETCONSOLEPASSWORD_FULLNAME_HELPERTEXT' }),
          userToken: lookUp({ key: 'CONSOLE_TEMPLATES_RESETCONSOLEPASSWORD_USERTOKEN_HELPERTEXT' }),
          ipAddress: lookUp({ key: 'CONSOLE_TEMPLATES_RESETCONSOLEPASSWORD_IPADDRESS_HELPERTEXT' }),
        }
    },
    SubtitleJobFinished: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_RECIPIENT_HELPERTEXT' }),
      placeholders: {
          objectType: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_OBJECTTYPE_HELPERTEXT' }),
          jobStatus: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_JOBSTATUS_HELPERTEXT' }),
          jobMessage: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_JOBMESSAGE_HELPERTEXT' }),
          fileName: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_FILENAME_HELPERTEXT' }),
          objectName: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_OBJECTNAME_HELPERTEXT' }),
          objectId: lookUp({ key: 'CONSOLE_TEMPLATES_SUBTITLEJOBFINISHED_OBJECTID_HELPERTEXT' }),
        }
    },
    OrderChanged: {
      recipient: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_RECIPIENT_HELPERTEXT' }),
      placeholders: {
        customerName: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_CUSTOMERNAME_HELPERTEXT' }),
        transactionId: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_TRANSACTIONID_HELPERTEXT' }),
        transactionStatus: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_TRANSACTIONSTATUS_HELPERTEXT' }),
        message: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_MESSAGE_HELPERTEXT' }),
        objectId: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_OBJECTID_HELPERTEXT' }),
        objectName: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_OBJECTNAME_HELPERTEXT' }),
        internalPrice: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_INTERNALPRICE_HELPERTEXT' }),       
        tokenPrice: lookUp({ key: 'CONSOLE_TEMPLATES_ORDERCHANGED_TOKENPRICE_HELPERTEXT' }),
        }
    },
  }

  const hints = templates[template] || templates['General'];

  return (
    <>
      <div>
        <strong>{lookUp({ key: 'CONSOLE_MESSAGES_HELPER_TEMPLATE', template: lookUp({ key: `CONSOLE_${template}` }) })}</strong><p/>
        <strong>{`{{Recipient}}`}</strong> {hints.recipient}<br/>
        {Object.entries(hints.placeholders).map((placeholder, i) => (
          <React.Fragment key={i}>
            <strong>{`{{${placeholder[0]}}}`}</strong>: {placeholder[1]}<br/>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default TemplateHints;