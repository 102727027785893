import { makeStyles } from '@material-ui/core/styles';
import 'shaka-player/dist/controls.css';
import { HHMMSSMsToMs } from 'helpers/time';

const useStyles = makeStyles({
  overlay: {
    position: 'absolute',
    zIndex: 9,
  },
});

const shakaStyles = makeStyles(theme => ({
  shakaAudio: (props) => ({
    position: 'relative',
    top: '11em',
    // overrides the class with the same name in node_modules/shaka-player/dist/controls.css
    // sets its z-index to 10 to the top of the canvas overlay
    '& .shaka-controls-container': {
      zIndex: 10,
    },
    '& .shaka-ad-markers': {
      background: props.markers,
    },
  }),
}));

function AudioPlayer(props) {
  const {
    overlayRef,
    shakaCallback,
    contentURL,
    overlayWidth,
    overlayHeight,
    autoHighlight,
    startAutoHighlight,
    breakpoints,
    duration,
  } = props;

  const makePercentGradient = (breakpoints, duration) => {
    if (!breakpoints || !duration) return '';
    const transparent = ' rgba(0,0,0,0) ';
    const color = ' rgba(255,255,0,1) ';
    let gradientString =
      'linear-gradient(90deg, rgba(0,0,0,0) 0%,' +
      breakpoints
        .filter((e) => !e.isInvisible)
        .reduce((markers, e) => {
          markers +=
            transparent +
            ((HHMMSSMsToMs(e.position) / duration) * 100).toFixed(1) +
            '%,' +
            color +
            ((HHMMSSMsToMs(e.position) / duration) * 100).toFixed(1) +
            '%,' +
            color +
            ((HHMMSSMsToMs(e.end || e.position) / duration) * 100).toFixed(1) +
            '%,' +
            transparent +
            ((HHMMSSMsToMs(e.end || e.position) / duration) * 100).toFixed(1) +
            '%,';
          return markers;
        }, '');
    gradientString += 'rgba(0,0,0,0)' + ' 100%) !important';
    if (gradientString === 'linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 100%) !important')
      return;
    return gradientString;
  };

  const classes = shakaStyles({ markers: makePercentGradient(breakpoints, duration) });
  const overlayStyle = useStyles();
  return (
    <>
      <canvas
        ref={overlayRef}
        height={overlayHeight}
        width={overlayWidth}
        className={overlayStyle.overlay}
      ></canvas>
      <audio
        controls
        id="shakaAudio"
        ref={shakaCallback}
        key={contentURL}
        onPlay={startAutoHighlight}
        poster="static/img/blur.jpg"
        onTimeUpdate={autoHighlight}
        className={classes.shakaAudio}
      >
        <source src={contentURL} />
      </audio>
    </>
  );
}

export default AudioPlayer;
