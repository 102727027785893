import React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { lookUp } from 'services/stringService';
import { shape, func } from 'prop-types';
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Delete, Add, GraphicEq } from '@material-ui/icons';
import { PlayContent } from 'components';
import DecorationFormModal from './DecorationFormModal';


const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cover: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
  },
  greyCoverBg: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    backgroundColor: theme.palette.secondary?.light,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 65,
    height: 65,
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  relative: {
    position: 'relative',
  },
  playButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
}));

function ContentCard(props) {
  const classes = useStyles();
  const { item, onDelete, onAdd, handlePropertiesChange } = props;

  const AltImage = () => (
    <div className={classes.greyCoverBg}>
      <GraphicEq className={classes.icon} />
    </div>
  );

  return (
    <Card className={classes.card}>
      <span className={classes.relative}>
        <PlayContent item={item} buttonClass={classes.playButton} />
        {item.imageUrl ? (
          <CardMedia image={item.imageUrl} title={item.originalTitle} className={classes.cover} />
        ) : (
          <AltImage />
        )}
      </span>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems={'flex-start'} justifyContent={'space-between'} wrap={'nowrap'}>
          <Grid item xs={10}>
            <Typography component="h6" variant="subtitle1" gutterBottom>
              <Link
                to={`/content/${item.id}/edit#basic`}
                component={RouterLink}
                target="_blank"
                rel="noreferrer"
              >
                {item.originalTitle}
              </Link>
            </Typography>
          </Grid>
          {onAdd && (
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ADD_ITEM' })}>
                <IconButton onClick={() => onAdd(item)}>
                  <Add />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          {onDelete && (
            <Grid item>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  {/*
                    <DecorationFormModal
                      item={item}
                      decorationTitle={item.originalTitle}
                      handlePropertiesChange={handlePropertiesChange}
                    />
                  */}
                </Grid>
                <Grid item>
                  <Tooltip title={lookUp({ key: 'CONSOLE_DELETE_ITEM' })}>
                    <IconButton onClick={() => onDelete(item)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

ContentCard.propTypes = {
  onAdd: func,
  onDelete: func,
  decorationModal: func,
  item: shape({}).isRequired,
};

export default ContentCard;
