import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import {
  Chip,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
  Tooltip,
  MenuItem,
  FormHelperText,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EditorInfo, FormFooter, Loader } from 'components';
import { goBackOrStay } from 'helpers/common';
import playbackPolicyService from 'services/playbackPolicyService';
import countryCodes from 'constants/countryCodes';
import navigationAction from 'store/actions/navigationAction';
import AlertService from 'services/alertService';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import SectionContainer from 'components/SectionContainer';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';

const PlaybackPolicyForm = ({ enqueueSnackbar }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);

  const [model, setModel] = useState({
    name: '',
    enabled: false,
    isDefault: false,
    deviceCap: 0,
    concurrentStreamCap: 0,
    downloadDeviceCap: 0,
    maxQuality: '',
    useParentalControl: false,
    addAdvertisements: false,
    drmSettings: {
      hdcpLevel: '',
    },
    geoFence: {
      blocked: [],
      allowed: [],
    },
    cdnSwitch: {
      enabled: false,
      originHost: '',
      targetHost: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [geoFenceCountries, setGeoFenceCountries] = useState(
    Object.keys(countryCodes.countryToCode),
  );
  const [videoQualityTypes, setVideoQualityTypes] = useState([]);
  const [hdcpOptions, setHdcpOptions] = useState(['Level 1', 'Level 2', 'Level 3']);

  const breadcrumbs = [
    {
      text: lookUp({ key: 'CONSOLE_MONETIZATION' }),
      link: '/monetization',
    },
    {
      text: lookUp({ key: 'CONSOLE_PLAYBACK_POLICIES' }),
      link: '/monetization/playback-policies',
    },
    {
      text: id
        ? lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: model.name })
        : lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: 'CONSOLE_PLAYBACK_POLICY' }),
      isCurrent: true,
    },
  ];

  useEffect(() => {
    const getVideoQualityData = () => {
      playbackPolicyService
        .getVideoQualityTypes()
        .then((data) => {
          setVideoQualityTypes(data);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_BITRATE' }),
            }),
          });
          setIsLoading(false);
        });
    };
    getVideoQualityData();
  }, []);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      playbackPolicyService
        .get(id)
        .then((data) => {
          data.geoFence = {
            allowed: data?.geoFence?.allowed?.map(
              (countryCode) => countryCodes.codeToCountry[countryCode],
            ) || [], 
            blocked:  data?.geoFence?.blocked?.map(
              (countryCode) => countryCodes.codeToCountry[countryCode],
            ) || []
          };
          if (!data.cdnSwitch) {
            data.cdnSwitch = {
              enabled: false,
              originHost: '',
              targetHost: '',
            };
          }
          setModel(data);
          dispatch(
            navigationAction.setPageTitle(
              lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: data.name }),
            ),
          );
          setGeoFenceCountries((countries) => {
            const allowFiltered = countries.filter(
              (country) => !data.geoFence.allowed.includes(country),
            );
            const blockFiltered = allowFiltered.filter(
              (country) => !data.geoFence.blocked.includes(country),
            );
            return blockFiltered;
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({
              key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
              type: lookUp({ key: 'CONSOLE_PLAYBACK_POLICY' }),
            }),
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (id) {
      getData();
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({
            key: 'CONSOLE_CREATE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_PLAYBACK_POLICY` }),
          }),
        ),
      );
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({ ...prev, [key]: value }));
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    const saveModel = publish ? { ...model, enabled: !model.enabled } : { ...model };
    saveModel.geoFence.allowed = saveModel.geoFence.allowed.map(
      (country) => countryCodes.countryToCode[country],
    );
    saveModel.geoFence.blocked = saveModel.geoFence.blocked.map(
      (country) => countryCodes.countryToCode[country],
    );

    const editOrCreate = id ? 'edit' : 'create';
    playbackPolicyService[editOrCreate](saveModel)
      .then(() => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message:
            publish && model.enabled === false
              ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE_TEMPLATE', title: model.name })
              : publish && model.enabled === true
              ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE_TEMPLATE', title: model.name })
              : id
              ? lookUp({ key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE', type: model.name })
              : lookUp({ key: 'CONSOLE_CREATE_SUCCESS_TEMPLATE', title: model.name }),
        });
        goBackOrStay(navigate, '/monetization/playback-policies');
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      })
      .finally(() => {
        publish ? setIsPublishing(false) : setIsSaving(false);
      });
  };

  const deletePolicy = async () => {
    setIsDeleting(true);
    playbackPolicyService
      .remove({ id })
      .then(() => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        setTimeout(() => navigate('/monetization/playback-policies'), 50);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', title: model.name }),
        });
      })
      .catch((error) => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
        });
        setIsDeleting(false);
      });
  };

  const createOption = (inputValue, values) => {
    if (inputValue.length < 4) return false;
    const typedArray = inputValue.split(' ');
    const capitalisedTypedArray = typedArray.map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    });
    const capitalisedTypedValue = capitalisedTypedArray.join(' ');
    const isValidCountry = geoFenceCountries.includes(capitalisedTypedValue);
    if (isValidCountry) {
      values[values.length - 1] = capitalisedTypedValue;
      setGeoFenceCountries((countries) => {
        const filteredCountries = countries.filter((country) => country !== capitalisedTypedValue);
        filteredCountries.sort();
        return filteredCountries;
      });
    }
    return isValidCountry;
  };

  const selectOption = (inputValue) => {
    setGeoFenceCountries((countries) => {
      const filteredCountries = countries.filter((country) => country !== inputValue);
      filteredCountries.sort();
      return filteredCountries;
    });
  };

  const removeOption = (geoFence, fenceType, values) => {
    const removed = geoFence[fenceType].filter((country) => !values.includes(country))[0];
    setGeoFenceCountries((countries) => {
      countries.push(removed);
      countries.sort();
      return countries;
    });
  };

  const clearOption = (geoFence, fenceType) => {
    setGeoFenceCountries((countries) => {
      geoFence[fenceType].forEach((country) => countries.push(country));
      countries.sort();
      return countries;
    });
  };

  const geofenceChangeHandler = (fenceType, values, reason) => {
    const geoFence = model.geoFence;
    const inputValue = values[values.length - 1];
    switch (reason) {
      case 'create-option':
        const isValidCountry = createOption(inputValue, values);
        if (!isValidCountry) return;
        break;
      case 'select-option':
        selectOption(inputValue);
        break;
      case 'remove-option':
        removeOption(geoFence, fenceType, values);
        break;
      case 'clear':
        clearOption(geoFence, fenceType);
        break;
      default:
        return;
    }
    geoFence[fenceType] = values;
    handleModelChange('geoFence', geoFence);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <SectionContainer flex={1}>
      <Box flex={1} sx={{ mb: 4 }}>
        <HeaderActionsContainer>
          {
            // Publish action button
            // only render publish/unpublish if the asset already exists
            id && (
              <ThemedButton
                color={model.enabled ? 'secondary' : 'success'}
                disabled={isPublishing || isSaving}
                onClick={(e) => {
                  e.preventDefault();
                  saveData(true);
                }}
                loading={isPublishing}
              >
                {lookUp({
                  key: model.enabled ? 'CONSOLE_UNPUBLISH_BUTTON' : 'CONSOLE_PUBLISH_BUTTON',
                })}
              </ThemedButton>
            )
          }
          {
            // Delete action button
            id && (
              <DeleteButton onDelete={deletePolicy} loading={isDeleting} disabled={isDeleting} />
            )
          }
          <Box display="flex" flex={1} />
          {/* Cancel action Button */}
          <ThemedButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(navigationAction.allowNavigation());
              window.history?.state?.idx === 0
                ? navigate('/monetization/playback-policies')
                : navigate(window.history?.state?.idx === 0 ? '/' : -1);
            }}
            disabled={isSaving || isPublishing}
          >
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </ThemedButton>

          {/* Save action Button */}
          <ThemedButton
            color="success"
            onClick={(e) => {
              e.preventDefault();
              saveData(false);
            }}
            disabled={isSaving || isPublishing}
            loading={isSaving}
          >
            {lookUp({ key: id ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' })}
          </ThemedButton>
        </HeaderActionsContainer>

        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label={lookUp({ key: 'CONSOLE_NAME' })}
                required
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
              <FormHelperText id="name">
                {lookUp({ key: 'CONSOLE_PLAYBACK_POLICY_NAME_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_SETTINGS_TITLE' })}
            </Typography>
          </Grid>
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_DEFAULT_POLICY' })}>
                <Switch
                  checked={model.isDefault}
                  onChange={() => handleModelChange('isDefault', !model.isDefault)}
                  color={'primary'}
                />
              </Tooltip>
              {lookUp({ key: 'CONSOLE_DEFAULT_POLICY' })}
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_PARENTAL_CONTROL' })}>
                <Switch
                  checked={model.useParentalControl}
                  onChange={() =>
                    handleModelChange('useParentalControl', !model.useParentalControl)
                  }
                  color={'primary'}
                />
              </Tooltip>
              {lookUp({ key: 'CONSOLE_PARENTAL_CONTROL' })}
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWING_AVOD' })}>
                <Switch
                  checked={model.addAdvertisements}
                  onChange={() => handleModelChange('addAdvertisements', !model.addAdvertisements)}
                  color={'primary'}
                />
              </Tooltip>
              {lookUp({ key: 'CONSOLE_ALLOWING_AVOD' })}
            </Grid>
            <Grid item>
              <Tooltip title={'Allow tokenization'}>
                <Switch
                  checked={model.useTokenSecurity}
                  onChange={() => handleModelChange('useTokenSecurity', !model.useTokenSecurity)}
                  color={'primary'}
                />
              </Tooltip>
              {lookUp({ key: 'CONSOLE_TOKENIZATION' })}
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_DOWNLOAD_TITLE' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  disabled={!videoQualityTypes?.length}
                  label={lookUp({ key: 'CONSOLE_MAXDOWNLOADQUALITY' })}
                  margin="dense"
                  value={model.maxDownloadQuality}
                  onChange={(event) => handleModelChange('maxDownloadQuality', event.target?.value)}
                >
                  {videoQualityTypes.length > 0 &&
                    videoQualityTypes.map((type) => (
                      <MenuItem value={type} key={type}>
                        {parseInt(type / 1000).toLocaleString()} kbps
                      </MenuItem>
                    ))}
                </TextField>
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_MAXDOWNLOADQUALITY_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment>hrs</InputAdornment>,
                  }}
                  defaultValue={model.downloadLicensePeriod}
                  onChange={(event) =>
                    handleModelChange('downloadLicensePeriod', parseInt(event.target.value))
                  }
                />
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_DOWNLOAD_LICENSE_PERIOD_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: <InputAdornment>hrs</InputAdornment>,
                  }}
                  defaultValue={model.downloadLicensePeriodAfterFirstPlay}
                  onChange={(event) =>
                    handleModelChange(
                      'downloadLicensePeriodAfterFirstPlay',
                      parseInt(event.target.value),
                    )
                  }
                />
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_LICENSE_PERIOD_AFTER_FIRST_PLAY_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label={lookUp({ key: 'CONSOLE_DOWNLOADDEVICECAP' })}
                type="number"
                value={model.downloadDeviceCap || undefined}
                onChange={(e) => handleModelChange('downloadDeviceCap', parseInt(e.target.value))}
              />
              <FormHelperText>
                {lookUp({ key: 'CONSOLE_DOWNLOADDEVICECAP_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_CAPS_TITLE' })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_DEVICECAP' })}
                  type="number"
                  value={model.deviceCap || undefined}
                  onChange={(e) => handleModelChange('deviceCap', parseInt(e.target.value))}
                />
                <FormHelperText>{lookUp({ key: 'CONSOLE_DEVICECAP_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_CONCURRENTSTREAMCAP' })}
                  type="number"
                  value={model.concurrentStreamCap || undefined}
                  onChange={(e) =>
                    handleModelChange('concurrentStreamCap', parseInt(e.target.value))
                  }
                />
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_CONCURRENTSTREAMCAP_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  disabled={!videoQualityTypes?.length}
                  fullWidth
                  label={lookUp({ key: 'CONSOLE_MAXQUALITY' })}
                  margin="dense"
                  value={model.maxQuality}
                  onChange={(event) => handleModelChange('maxQuality', event.target?.value)}
                >
                  {videoQualityTypes.length > 0 &&
                    videoQualityTypes.map((type) => (
                      <MenuItem value={type} key={type}>
                        {parseInt(type / 1000).toLocaleString()} kbps
                      </MenuItem>
                    ))}
                </TextField>
                <FormHelperText>{lookUp({ key: 'CONSOLE_MAXQUALITY_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  select
                  disabled={!hdcpOptions?.length}
                  label={lookUp({ key: 'CONSOLE_DRMSETTINGS' })}
                  margin="dense"
                  value={model.drmSettings.hdcpLevel}
                  onChange={(event) =>
                    handleModelChange('drmSettings', { hdcpLevel: event.target.value })
                  }
                >
                  {hdcpOptions.length > 0 &&
                    hdcpOptions.map((hdcp) => (
                      <MenuItem value={hdcp} key={hdcp}>
                        {hdcp}
                      </MenuItem>
                    ))}
                </TextField>
                <FormHelperText>{lookUp({ key: 'CONSOLE_DRMSETTINGS_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_GEOFENCE_TITLE' })}
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    freeSolo
                    blurOnSelect={false}
                    id="geoFence_allowed"
                    value={model.geoFence.allowed}
                    options={geoFenceCountries}
                    onChange={(event, values, reason) => {
                      geofenceChangeHandler('allowed', values, reason);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="default"
                          label={option}
                          style={{ backgroundColor: '#32CD32' }}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={lookUp({ key: 'CONSOLE_ALLOWED_COUNTRIES' })} />
                    )}
                  />
                  <FormHelperText>
                    {lookUp({ key: 'CONSOLE_Select_allowed_countries_HELPERTEXT' })}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="geoFence_blocked"
                    value={model.geoFence.blocked}
                    options={geoFenceCountries}
                    onChange={(event, values, reason) => {
                      geofenceChangeHandler('blocked', values, reason);
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          color="default"
                          label={option}
                          style={{ backgroundColor: '#ff6666' }}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={lookUp({ key: 'CONSOLE_BLOCKED_COUNTRIES' })} />
                    )}
                  />
                  <FormHelperText>
                    {lookUp({ key: 'CONSOLE_Select_blocked_countries_HELPERTEXT' })}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item>
              <Typography variant="h6" gutterBottom>
                {lookUp({ key: 'CONSOLE_CDN_REPLACE_TITLE' })}
              </Typography>
            </Grid>
            <Grid container spacing={1}>
              <FormControl fullWidth>
                <FormControlLabel
                  label={lookUp({ key: 'CONSOLE_CDN_SWITCH_ENABLED' })}
                  control={
                    <Checkbox
                      checked={model.cdnSwitch.enabled || ''}
                      onChange={(e) =>
                        handleModelChange('cdnSwitch', {
                          ...model.cdnSwitch,
                          enabled: e.target.checked,
                        })
                      }
                    />
                  }
                />

                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_CDN_SWITCH_ORIGIN_HOST_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_CDN_SWITCH_ORIGIN_HOST' })}
                  type="text"
                  value={model.cdnSwitch.originHost || ''}
                  onChange={(e) =>
                    handleModelChange('cdnSwitch', {
                      ...model.cdnSwitch,
                      originHost: e.target.value,
                    })
                  }
                />
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_CDN_SWITCH_ORIGIN_HOST_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_CDN_SWITCH_TARGET_HOST' })}
                  type="text"
                  value={model.cdnSwitch.targetHost || ''}
                  onChange={(e) =>
                    handleModelChange('cdnSwitch', {
                      ...model.cdnSwitch,
                      targetHost: e.target.value,
                    })
                  }
                />
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_CDN_SWITCH_TARGET_HOST_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </SectionContainer>
  );
};

PlaybackPolicyForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(PlaybackPolicyForm);
