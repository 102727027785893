import { lookUp } from 'services/stringService';
import { TextField, Grid, IconButton } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';

const WebsiteInput = ({url, handleChange, label = lookUp({ key: 'CONSOLE_WEBSITE' })}) => {
  
  return (
    <Grid item xs={12} container alignItems="center">
      <Grid item xs={11}>
        <TextField
          label={label}
          fullWidth
          value={url}
          onChange={(e) => handleChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton 
          fullWidth 
          onClick={() => window.open(url, "_blank")}>
          <OpenInNew/>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default WebsiteInput;
