import { useState } from 'react';
import { lookUp } from 'services/stringService';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  AudioOnly,
  CameraRecording,
  ScreenRecording,
  AudioAndScreen,
  ScreenAndCamera,
} from 'assets/icons/MeetingPresetsIcons';

const useStyles = makeStyles(() => ({
  modalSaveButton: {
    color: '#ffffff',
    backgroundColor: '#00897b',
    '&:hover': {
      backgroundColor: '#00998a',
    },
  },
  cardBox: {
    display: 'flex',
    alignItems: 'stretch',
    cursor: 'pointer',
  },
  activeCard: {
    border: '3px solid #1f86e1b5',
    boxShadow: 'none',
  },
  cardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    textAlign: 'center',
  },
  actionButtonsGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    margin: 4,
  },
  startNowButton: {
    color: '#ffffff',
    backgroundColor: '#F90A0A',
    '&:hover': {
      backgroundColor: '#F90A0A',
    },
  },
  setUpButton: {
    color: '#242323',
    backgroundColor: '#F5BC29',
    '&:hover': {
      backgroundColor: '#F5BC29',
    },
  },
}));

const presets = [
  {
    label: 'CONSOLE_AUDIOONLY_RECORDING',
    description: 'CONSOLE_AUDIOONLY_RECORDING_DESCRIPTION',
    icon: <AudioOnly />,
    config: {
      muted: false,
      video: false,
      screen: false,
    },
  },
  {
    label: 'CONSOLE_CAMERA_RECORDING',
    description: 'CONSOLE_CAMERA_RECORDING_DESCRIPTION',
    icon: <CameraRecording />,
    config: {
      muted: false,
      video: true,
      screen: false,
    },
  },
  {
    label: 'CONSOLE_SCREEN_RECORDING',
    description: 'CONSOLE_SCREEN_RECORDING_DESCRIPTION',
    icon: <ScreenRecording />,
    config: {
      muted: true,
      video: false,
      screen: true,
    },
  },
  {
    label: 'CONSOLE_AUDIO_SCREEN_RECORDING',
    description: 'CONSOLE_AUDIO_SCREEN_RECORDING_DESCRIPTION',
    icon: <AudioAndScreen />,
    config: {
      muted: false,
      video: false,
      screen: true,
    },
  },
  {
    label: 'CONSOLE_CAMERA_SCREEN_RECORDING',
    description: 'CONSOLE_CAMERA_SCREEN_RECORDING_DESCRIPTION',
    icon: <ScreenAndCamera />,
    config: {
      muted: false,
      video: true,
      screen: true,
    },
  },
];

const defaultPreset = {
  label: '',
  description: '',
  config: {
    muted: false,
    video: false,
    screen: false,
  },
};

const PresetsModal = (props) => {
  const {
    setIsCountbackOpen,
    toggleVideo,
    isVideoEnabled,
    toggleContentShare,
    isLocalUserSharing,
    muted,
    toggleMute,
  } = props;
  const classes = useStyles();
  const [isPresetsModalOpen, setIsPresetsModalOpen] = useState(true);
  const [activePreset, setActivePreset] = useState(defaultPreset);

  const backToDefault = () => {
    if (defaultPreset.config.mute !== muted) {
      toggleMute();
    }
    if (defaultPreset.config.video !== isVideoEnabled) {
      toggleVideo();
    }
    if (defaultPreset.config.screen !== isLocalUserSharing) {
      toggleContentShare();
    }
    setActivePreset(defaultPreset);
  }

  const presetAction = (preset) => {
    if (preset.label === activePreset?.label) {
      backToDefault()
      return;
    }
    if (preset.config.muted !== muted) {
      toggleMute();
    }
    if (preset.config.video !== isVideoEnabled) {
      toggleVideo();
    }
    if (preset.config.screen !== isLocalUserSharing) {
      toggleContentShare();
    }
    setActivePreset(preset);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      height="40vh"
      style={{ zIndex: 999999 }}
      open={isPresetsModalOpen}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          {lookUp({ key: 'CONSOLE_RECORDING_PRESETS' })}
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              presetAction(defaultPreset);
              setIsPresetsModalOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container xs={12} 
        justifyContent='center'
        spacing={2}
        // className={classes.cardsBox}
        >
          {presets.map((preset, index) => (
            <Grid item xs={4}
              className={classes.cardBox}
              key={`${preset.label}_${index}`}
              onClick={() => presetAction(preset)}
            >
              <Card
                style={{ height: '100%' }}
                className={preset.label === activePreset?.label && classes.activeCard}
              >
                <CardActionArea style={{ height: '100%' }}>
                  <CardContent className={classes.cardContent}>
                    <Box style={{ textAlign: 'center' }}>
                      {preset.icon}
                      <Typography
                        gutterBottom
                        component="div"
                        style={{ color: preset.label === activePreset?.label && '#1f86e1' }}
                      >
                        {lookUp({ key: `${preset.label}` })}
                      </Typography>
                    </Box>
                    <Typography variant="body2">
                      {lookUp({ key: `${preset.description}` })}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container className={classes.actionButtonsGroup}>
          <Button
            className={classes.startNowButton}
            
            onClick={() => {
              setIsPresetsModalOpen(false);
              setIsCountbackOpen(true);
            }}
          >
            {lookUp({ key: 'CONSOLE_STARTNOW_BUTTON' })}
          </Button>
          <Button
            className={classes.setUpButton}
            onClick={() => setIsPresetsModalOpen(false)}
          >
            {lookUp({ key: 'CONSOLE_CONFIRM_SETUP_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PresetsModal;
