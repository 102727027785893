import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ThemedButton from 'components/ThemedButton';
import SectionContainer from 'components/SectionContainer';

const ARCHIVE_OPTIONS = [
  'No Archive',
  'After a week',
  'After two weeks',
  'After a month',
  'After two months',
];
const DELETE_OPTIONS = [
  'After a month',
  'After two months',
];

const ArchiveTab = ({ id, model, enqueueSnackbar }) => {
  const [archive, setArchive] = useState('');
  const [deletion, setDeletion] = useState('');

  return (
    <SectionContainer flex={1}>
      <Grid container xs={12} md={6} spacing={2}>
        <Grid item>
          <Typography variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_ARCHIVE_TITLE' })}
          </Typography>
        </Grid>
        <Grid spacing={1} direction="column" item container>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                name="archive-policy"
                label={lookUp({ key: 'CONSOLE_ARCHIVE_POLICY' })}
                aria-describedby="archive-policy-helper"
                value={archive}
                onChange={(e) => setArchive(e.target.value)}
                size="small"
                select
              >
                {ARCHIVE_OPTIONS.map?.((archive) => (
                  <MenuItem key={archive} value={archive}>
                    {lookUp({ key: `CONSOLE_${archive}` })}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="archive-policy-helper">
                {lookUp({ key: 'CONSOLE_ARCHIVE_POLICY_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl fullWidth>
              <TextField
                name="delete-policy"
                label={lookUp({ key: 'CONSOLE_DELETE_POLICY' })}
                aria-describedby="delete-policy-helper"
                value={deletion}
                onChange={(e) => setDeletion(e.target.value)}
                size="small"
                select
              >
                {DELETE_OPTIONS.map?.((deleteOption) => (
                  <MenuItem key={deleteOption} value={deleteOption}>
                    {lookUp({ key: `CONSOLE_${deleteOption}` })}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText id="delete-policy-helper">
                {lookUp({ key: 'CONSOLE_DELETE_POLICY_HELPERTEXT' })}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={1}>
          <Grid item>
            <ThemedButton>
              {lookUp({ key: 'CONSOLE_ARCHIVE_NOW' })}
            </ThemedButton>
          </Grid>
          <Grid item>
            <ThemedButton>
              {lookUp({ key: 'CONSOLE_RETRIEVE_NOW' })}
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

ArchiveTab.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(ArchiveTab);
