import { SET_PREVIEW_ITEM, SET_PIC_IN_PIC_STATUS, SET_PAGE_NUMBER, SET_ROWS_NUMBER } from '../actions/previewAction';

export const initialState = {
  item: null,
  isPicInPic: false,
  pageNumber: 0,
  rowsNumber: 10,
};

const previewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREVIEW_ITEM:
      return {
        ...state,
        item: action.item
      };
    case SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber
      };
    case SET_ROWS_NUMBER:
        return {
          ...state,
          rowsNumber: action.rowsNumber
        };
    case SET_PIC_IN_PIC_STATUS:
      return {
        ...state,
        isPicInPic: action.status
      };
    default:
      return state;
  }
};

export default previewReducer;
