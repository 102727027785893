import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import DeleteButton from 'components/DeleteButton';
import AddButton from 'components/AddButton';
import PropsSelect from 'components/PropsSelect';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'baseline',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  row: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  rowItemWithRemove: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'baseline',
  },
  removeButtonContainer: {
    marginLeft: theme.spacing(.5),
  },
}));

const PropertiesTab = ({ model, handlePropertiesChange = () => {}, filtering = '' }) => {
  const [properties, setProperties] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (model) {
      setProperties(model.properties);
    }
  }, [model]);

  useEffect(() => {
    if (model) {
      let sortedModelProperties = Object.keys(model.properties)
        .sort().reduce((temp_obj, key) => {
          temp_obj[key] = model.properties[key];
          return temp_obj;
        }, {});
      setProperties(sortedModelProperties);
    }
  }, []);

  const addProperty = () => {
    setProperties((a) => ({ ...a, [filtering]: '' }));
  };

  const handlePropertyChange = (index, key, value) => {
    const newPropsArray = Object.entries(properties);
    newPropsArray[index] = [key, value];
    const newPropsObj = Object.fromEntries(newPropsArray);
    handlePropertiesChange(newPropsObj);
    setProperties(newPropsObj);
  };

  const removeProperty = (key) => {
    const copyProps = { ...properties };
    delete copyProps[key];
    setProperties(copyProps);
    handlePropertiesChange(copyProps);
  };

  return (
    <Box className={classes.root}>
      {Object.entries(properties).map?.(
        ([propertyKey, propertyValue], index) =>
          (!filtering || (filtering && propertyKey.includes(filtering))) && (
            <Grid container spacing={2} key={index} className={classes.row}>
              <>
                <Grid item sm={12} md={6}>
                  <PropsSelect
                    label={filtering || lookUp({ key: 'CONSOLE_PROPERTY' })}
                    value={propertyKey}
                    prefix={filtering}
                    handleChange={(key) => {
                      handlePropertyChange(index, key, propertyValue);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item container sm={12} md={6} className={classes.rowItemWithRemove}>
                  <Box flex={1}>
                    <TextField
                      label={lookUp({ key: 'CONSOLE_VALUE' })}
                      className={classes.textField}
                      value={propertyValue || ''}
                      onChange={(e) => handlePropertyChange(index, propertyKey, e.target.value)}
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.removeButtonContainer}>
                    <DeleteButton
                      onDelete={() => removeProperty(propertyKey)}
                      variant="icon"
                      size="small"
                    />
                  </Box>
                </Grid>
              </>
            </Grid>
          )
      )}
      <AddButton
        title={lookUp({ key: 'CONSOLE_PROPERTIES_HELPERTEXT' })}
        onAdd={() => addProperty()}
      />
    </Box>
  );
};

PropertiesTab.propTypes = {
  model: PropTypes.object.isRequired,
  handlePropertiesChange: PropTypes.func,
  filtering: PropTypes.string,
};

export default PropertiesTab;
