import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { store } from 'store/configureStore';
import contentAction from 'store/actions/contentAction';
import parametersService from 'services/parametersService';


export function isUrl(string) {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(string);
}

export function calcDuration(tracks) {
  return tracks.reduce((total, t) => total + t.trackDuration, 0);
}

export function omit(obj = {}, properties = []) {
  const props = Array.isArray(properties) ? properties : [properties];

  return Object.keys(obj)
    .filter((key) => !props.includes(key))
    .reduce((o, key) => ({ ...o, [key]: obj[key] }), {});
};

export function makeApiWithQuery(apiEndpoint, queryConfig) {
  if (!queryConfig) {
    queryConfig = apiEndpoint;
    apiEndpoint = '';
  }

  const keys = Object
    .keys(queryConfig)
    .filter(k => (
      queryConfig[k] !== undefined &&
      !Number.isNaN(queryConfig[k]) &&
      (queryConfig[k] && queryConfig[k].length > 0 || typeof queryConfig[k] === 'number')) ||
      queryConfig[k] === true ||
      queryConfig[k] === false
    );

  if (!keys.length) return apiEndpoint || '';

  const keyReducer = (url, key, i) => {
    return queryConfig[key] === undefined
      ? url
      : key === 'order' ? `${url}%20${queryConfig[key]}`
      : `${url}${i === 0 || queryConfig[key] === '' ? '' : '&'}${
      queryConfig[key] === '' ? '' : `${key}=${queryConfig[key]}`
      }`
  };
  return keys.reduce(keyReducer, `${apiEndpoint}?`);
};

export function getPageSizeFromCookies() {
  return Cookies.get('pageSize');
}

export function setPageSizeToCookies(pageSize) {
  Cookies.set('pageSize', pageSize);
};

export function getTokenFromCookies(key = 'token') {
  return Cookies.get(key);
}

export function setTokenToCookies(token, key = 'token') {
  const in12hours = new Date(new Date().getTime() + 720 * 60 * 1000);
  const in1year = new Date(new Date().getTime() + 365 * 24 * 3600 * 1000);
  Cookies.set(key, token, { expires: in1year });
};

export function removeTokenFromCookies(token = 'token') {
  return Cookies.remove(token);
}

export function setSelectedBrandToCookies(selectedBrand) {
  Cookies.set('selectedBrand', selectedBrand);
}

export function getSelectedBrandFromCookies() {
  return Cookies.getJSON('selectedBrand');
} 

export function parseUserFromToken(token = getTokenFromCookies()) {
  try {
    const decoded = jwtDecode(token);
    return {
      name: decoded.unique_name,
      id: decoded.nameid,
      role: decoded.role,
      organizations: decoded.org,
    };
  } catch (err) {
    return null;
  }
};

export function shuffle(array) {
  const shuffledArray = [...array];

  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
};

export function getColor() {
  const letters = '0123456789ABCDEF';

  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
};

export function createUniqueRandomGetter() {
  const colors = [];

  return () => {
    let color;
    let maxColorIterations = 20;

    while (maxColorIterations-- >= 0 && !colors.includes(color)) {
      color = getColor();
    }

    if (maxColorIterations === 0) {
      if (!colors.includes('#000')) {
        colors.push('#000');
      }
      color = '#000';
    }

    return color;
  };
};

export function isValidActionUrl(actionUrl, parametersItems, catalogItems) {
  const items = actionUrl.split('/').filter((u) => u !== '{vvvv}' && u !== '{locale}' && u !== 'n');

  const isValidParameter = (url) =>
    url.substr(url.indexOf('{') + 1, url.indexOf('}') - 1) === ''
      ? true
      : parametersItems.includes(
        url
          .substr(url.indexOf('{') + 1, url.indexOf('}') - 1)
          .replace('ApiUrl', '')
          .toLowerCase()
      );

  const isValidCatalog = (url) =>
    url.substr(url.indexOf('{') + 1, url.indexOf('}') - 1) === ''
      ? true
      : catalogItems.includes(
        url.substr(url.indexOf('{') + 1, url.indexOf('}') - 1).replace('Catalog:', '')
      );

  if (actionUrl.substr(actionUrl.indexOf('{') + 1, actionUrl.indexOf('}') - 1) === '') return true;

  return items[0] && isValidParameter(items[0]) && items[1] && isValidCatalog(items[1]);
};

export function isValidFeedUrl(url, parametersItems) {
  return parametersItems.includes(
    url
      .substr(url.indexOf('{') + 1, url.indexOf('}') - 1)
      .replace('ApiUrl', '')
      .toLowerCase()
  );
}

export function enumToOrderedArray(enumObj) {
  const array = Object.entries({...enumObj})
    .sort((a, b) => a[1] - b[1])
    .map((e) => e[0].split(':')[2]);
  return array;
}

export function getConsoleEnvName() {
  switch (window.__RUNTIME_CONFIG__.DEFAULT_TITLE) {
    case 'Nexius Learning':
      return 'Nexius'
    default:
      return 'ThinAIR'
  }
};

export function sortLangCodesByLangNames(langCodesAndNames) {
  return Object
    .keys(langCodesAndNames)
    .sort((lKey1, lKey2) => (langCodesAndNames[lKey1] > langCodesAndNames[lKey2]) ? 1 : -1)
    .reduce((ordLangs, lKey) => {
      ordLangs[lKey] = langCodesAndNames[lKey];
      return ordLangs;
    }, {});
}

export function sortArrayWithKey(key, arrayData) {
  return arrayData.sort((a, b) => {
    const valueA = a[key].toUpperCase();
    const valueB = b[key].toUpperCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}

/**\
 * Takes a sorted (by a 'property') array of objects and finds the index where a new entry
 * should be inserted while keeping the order.
 * @param objArray: input array of objects
 * @param property: the property based on the array has been sorted
 * @param value: the value to be inserted
 * @param bottomInd: the lowest current index in our search interval
 * @param topInd: the highest current index  in our search interval
 * @return an index where the new element should be inserted
\**/
export function binaryFindIndex(objArray, property, value, bottomInd = 0, topInd = objArray.length - 1) {
  const midInd = Math.floor((bottomInd + topInd) / 2);

  if (objArray[midInd][property] > value) {
    if (bottomInd < midInd) {
      if (objArray[midInd - 1][property] < value) return midInd;
      return binaryFindIndex(objArray, property, value, bottomInd, midInd - 1);
    }
    return bottomInd;
  }
  if (objArray[midInd][property] < value) {
    if (midInd < topInd) {
      if (value < objArray[midInd + 1][property]) return midInd + 1;
      return binaryFindIndex(objArray, property, value, midInd + 1, topInd);
    }
    return topInd;
  }
  return midInd;
}

export async function goBackOrStay(navigate, toWhere, delay=50) {
  const autoClose = store?.getState()?.content?.autoClose;
  if (autoClose?.paramChecked) {
    if (autoClose.toClose) return setTimeout(() => navigate(toWhere), delay);
  } else {
    try {
      const autoCloseParam = await parametersService.getByKey('Console:General:Edit:Autoclose');
      const toClose = autoCloseParam?.value?.toLowerCase() === 'true';
      store.dispatch(contentAction.setAutoClose({ toClose, paramChecked: true }))
      if (toClose) return setTimeout(() => navigate(toWhere), delay);
    } catch (error) {
      store.dispatch(contentAction.setAutoClose({ ...autoClose, paramChecked: true }))
    }
  }
}

/**
 * Used in conjuction with goBackOrStay, will return a path based on the current history path, return to previous section, dashboard, or stay in current
 *
 * @param {array} breadCrumbsLinks a breadCrumbsLinks array
 * @param {string} defaultPath the default path to navigate to
 * @param {number} depthsToRemove how many items to go back from the current section
 *
 * FIXME should return consistent type, we return both for backwards compatibility
 * @returns string|number
 */
export const getNavigationBreadcrumbsPath = (breadCrumbsLinks, defaultPath = '/dashboard', depthsToRemove = 2) => {
  if (window.history.state.idx === 0) {
    if (Array.isArray(breadCrumbsLinks) && breadCrumbsLinks.length > depthsToRemove) {
      let linkPath = breadCrumbsLinks[breadCrumbsLinks.length - depthsToRemove]?.link;

      if (`${linkPath}`.trim() !== '') {
        return linkPath;
      }
    }

    return defaultPath;
  }

  return -1;
}
