import { useEffect, useRef, useState } from 'react';
import { lookUp } from 'services/stringService';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import { Delete, Add } from '@material-ui/icons';
import TagCloud from 'pages/Content/TagCloud';
import markerService from 'services/markerService';
import AlertService from 'services/alertService';
import ColorPicker from 'components/ColorPicker';
import RelatedComboBox from 'components/RelatedComboBox';
import SectionContainer from 'components/SectionContainer';

// NOTE define form groups and fields sizes in MUI 12 grid system
// TODO move into constants file
const FORM_GROUP_WIDTH = 12;
const FORM_FIELD_MEDIUM = 6;
const FORM_FIELD_SMALL = 3;
const FORM_FIELD_SPACING = 2;
const FORM_SECTION_SPACING = 4;
const TEXT_FIELD_SIZE = 'small';
const CHIP_SIZE = 'small';

const DynamicInputs = (props) => {
  const classes = useInputStyles();
  const { handleChange, id, name, tooltip, values } = props;

  const lastValueRef = useRef(null);

  const handleAdd = () => {
    handleChange([...values, '']);
  };

  const handleItemChange = (index, value) => {
    values[index] = value;
    handleChange(values);
  };

  const handleDelete = (index) => {
    handleChange(values.filter((v, i) => i !== index));
  };

  useEffect(() => {
    lastValueRef.current && lastValueRef.current.focus();
  }, [values]);

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item xs={10}>
          <Typography>{name}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.center}>
          <Tooltip title={tooltip}>
            <Fab size={CHIP_SIZE} onClick={handleAdd} color="primary">
              <Add />
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        container
        direction={'column'}
        spacing={FORM_FIELD_SPACING}
        className={classes.marginTop}
      >
        {values.map((value, index) => (
          <Grid item xs={12} key={index}>
            <Grid container alignItems={'center'}>
              <Grid item xs={10}>
                <TextField
                  variant="filled"
                  size={TEXT_FIELD_SIZE}
                  label={`${id} ${index + 1}`}
                  className={classes.textField}
                  value={value || ''}
                  onChange={(e) => handleItemChange(index, e.target.value)}
                  {...(index === values.length - 1 ? { inputRef: lastValueRef } : {})}
                />
              </Grid>
              <Grid item xs={2} className={classes.center}>
                <Tooltip title={'Delete'}>
                  <IconButton onClick={() => handleDelete(index)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

DynamicInputs.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TagsTab = ({
  handleChange,
  model,
  triggers,
  setSelectedRelation,
  setTargetType,
  isTagCloudLoading,
  setIsTagCloudLoading,
  enqueueSnackbar,
  openSelector = () => {},
}) => {
  const contentReferencesOptions = [
   "Chat",
  ];

  const [tagTypes, setTagTypes] = useState([]);
  const [tagCloud, setTagCloud] = useState({});

  const getTagTypes = () => {
    markerService
      .getTags()
      .then((types) =>
        setTagTypes(
          types
            .filter((e) => e !== 'PersonTrackingTag' && e !== 'GlobalTag')
            .map((type) => {
              return {
                type,
                label: lookUp({ key: `CONSOLE_${type.replace('Tag', '')}` }),
              };
            })
        )
      )
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TAG_TYPES' }),
          }),
        });
      });
  };

  useEffect(() => {
    if (!model.id) return;
    setIsTagCloudLoading(true);
    const getMarkerTags = async (id, markerType) => {
      const markerS = {
        tags: [],
        idOrToken: id,
        finished: false,
      };
      try {
        while (!markerS.finished) {
          const queryParams = { type: markerType, itemLimit: 500 };
          const reqResp = await markerService.search(markerS.idOrToken, queryParams);
          if (reqResp?.pageContent?.length > 0) {
            markerS.tags = markerS.chapters.concat(reqResp.pageContent);
          }
          markerS.idOrToken = reqResp?.pagingToken;
          markerS.finished = reqResp?.finished;
        }
        return Promise.resolve(markerS.tags);
      } catch (error) {
        console.error(error);
        return Promise.reject(null);
      }
    };
    Promise.allSettled(tagTypes.map((tag) => getMarkerTags(model.id, tag.type)))
      .then((res) => {
        const tagCloudPromise = res.reduce((acc, tagType) => {
          const pageContent = tagType.value;
          if (tagType.status === 'fulfilled' && !!pageContent?.length) {
            acc = {
              ...acc,
              [pageContent[0].type]: Object.values(pageContent?.[0]?.tags).map((tag) => {
                return { value: tag.displayName, count: tag.occurrence };
              }),
            };
          }
          return acc;
        }, {});
        if (!tagCloudPromise) {
          setTagCloud({});
        }
        setTagCloud(tagCloudPromise);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TAGS' }),
          }),
        });
      })
      .finally(() => {
        setIsTagCloudLoading(false);
      });
  }, [tagTypes]);

  useEffect(() => {
    getTagTypes();
  }, []);

  const handleRelationshipClick = (selectedRelation, targetType) => {
    openSelector();
    setSelectedRelation(selectedRelation);
    setTargetType(targetType);
  };

  return (
    <SectionContainer flex={1}>
      <Grid container spacing={2}>
        <Grid item container spacing={FORM_FIELD_SPACING}>
          <Grid item xs={FORM_GROUP_WIDTH}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CATEGORIES_TITLE' })}
            </Typography>
          </Grid>
          <Grid item xs={FORM_GROUP_WIDTH}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CONTENT_REFERENCES_TITLE' })}
            </Typography>
          </Grid>
          {contentReferencesOptions.map((reference, index) => (
            <Grid item md={FORM_FIELD_MEDIUM} sm={12} key={`${reference}_${index}`}>
              <RelatedComboBox
                sourceType="Catalog"
                targetType="Content"
                relation={reference}
                id={model.id}
                onFocus={() => handleRelationshipClick(reference, 'Content')}
                trigger={triggers?.[reference]}
                size={TEXT_FIELD_SIZE}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </SectionContainer>
  );
}

TagsTab.propTypes = {
  model: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(TagsTab);
