import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import {
  AreaChart,
  Area,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer
} from 'recharts';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/styles';
import { Loader } from 'components';
import { getCustomerReport, getTotalAggregatedContentReport } from 'services/reportingService';
import AlertService from 'services/alertService';
import { getCustomer } from 'services/customersService';

const StatisticsTab = (props) => {
  const {model, enqueueSnackbar } = props;
  const { customerId } = model;
  const [dailyConsumption, setDailyConsumption] = useState([]);
  const [dailyContentByCreators, setDailyContentByCreators] = useState([]);
  const [dailyTopOrganisationByOrganisation, setDailyTopOrganisationByOrganisation] = useState([]);
  const [aggregatedTopOrganisationByOrganisation, setAggregatedTopOrganisationByOrganisation] = useState([]);
  const [dailyTargetNumberOfFavourites , setDailyTargetNumberOfFavourites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

  const chartColors = useSelector((state) => state.brandStyle.chartColors);

  const theme = useTheme();

  const digestData = (data) => data.dataItems
    ?.map(e => (
      {
        ...e, 
        date: e.date.substr(5), 
        quantity: e.data.reduce((q, d) => 
          q += d.quantity
        , 0)
      })
    );

    

  const getConsumptionData = async () => {
    try {
      const fetchedData = await getCustomerReport({ type: 'ClientReports/Daily/CustomerConsumption', customerId });     
      setDailyConsumption(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const getDailyContentByCreatorsData = async () => {
    try {
      const fetchedData = await getCustomerReport({ type: 'ClientReports/Daily/ContentByCreatorsConsumption', creatorId: customerId });
      setDailyContentByCreators(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const getDailyTopOrganisationByOrganisationData = async () => {
    try {
      const fetchedData = await getCustomerReport({ type: 'CustomerMetrics/Daily/TopOrganisationMembers', organisationId: customerId });
      setDailyTopOrganisationByOrganisation(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const getAggregatedTopOrganisationByOrganisationData = async () => {
    try {
      const fetchedData = await getTotalAggregatedContentReport({ type: 'CustomerMetrics/Aggregated/TopOrganisationMembers', organisationId: customerId });
      formatAggregatedReportToBarData(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const formatAggregatedReportToBarData = async (reportData) => {
    try {
      let barDataInFomat = [];
      await Promise.all(
        reportData[0].data.map(async (element) => {
          const customerData = await getCustomer(element.id);
          barDataInFomat.push({ name: customerData.username, value: element.quantity });
        })
      )
      setAggregatedTopOrganisationByOrganisation(barDataInFomat);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const getDailyTargetNumberOfFavouritesData = async () => {
    try {
      const fetchedData = await getCustomerReport({ type: 'CommercialMetrics/Daily/TargetNumberOfFavourites', customerId });     
      setDailyTargetNumberOfFavourites(digestData(fetchedData));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error
      });
    }
  };

  const Bars = useMemo(() => {
    return (
      <Bar dataKey="value">
        {aggregatedTopOrganisationByOrganisation.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]}/>
        )}
      </Bar>
    );
  }, [aggregatedTopOrganisationByOrganisation]);

  useEffect(() => {
    setIsLoading(true);
    getConsumptionData()
    .then(() => getDailyTargetNumberOfFavouritesData())
    .then(() => model.customerType === 'Creator' && getDailyContentByCreatorsData())
    .then(() => model.customerType === 'Organization' && getDailyTopOrganisationByOrganisationData())
    .then(() => model.customerType === 'Organization' && getAggregatedTopOrganisationByOrganisationData())
    .then(() => setIsLoading(false));
  }, []);

  const CustomTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={60} y={0} textAnchor="end" fill="#666" transform="rotate(90)">
        {payload.value}
      </text>
    </g>
  );

  return isLoading ? (
    <Loader />
  ) : (
      <Grid container justifyContent={'center'}>
        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_CUSTOMER_CONSUMPTION_HELPERTEXT' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <AreaChart
              data={dailyConsumption}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Legend />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CUSTOMER_CONSUMPTION_HELPERTEXT' })}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>

        <Grid item sm={12}>
          <Typography align="center" variant="h6" gutterBottom>
            {lookUp({ key: 'CONSOLE_DAILY_CUSTOMER_TARGET_NUMBER_OF_FAVOURITES' })}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
            <AreaChart
              data={dailyTargetNumberOfFavourites}
              margin={{
                top: 25,
                right: 30,
                left: 20,
                bottom: 5
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
              <YAxis />
              <Tooltip cursor={{ fill: 'transparent' }} />
              <Legend />
              <Area
                dataKey="quantity"
                type="monotone"
                fill={theme.palette?.primary?.main}
                stroke={theme.palette?.primary?.dark}
                name={lookUp({ key: 'CONSOLE_DAILY_CUSTOMER_TARGET_NUMBER_OF_FAVOURITES' })}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Grid>

        {model.customerType === 'Creator' &&
          (
            <>
              <Grid item sm={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_DAILY_CONTENT_BY_CREATORS_HELPERTEXT' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
                  <AreaChart
                    data={dailyContentByCreators}
                    margin={{
                      top: 25,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                    <YAxis />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Legend />
                    <Area
                      dataKey="quantity"
                      type="monotone"
                      fill={theme.palette?.primary?.main}
                      stroke={theme.palette?.primary?.dark}
                      name={lookUp({ key: 'CONSOLE_DAILY_CONTENT_BY_CREATORS_HELPERTEXT' })}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Grid>
            </>
          )
        }

        {model.customerType === 'Organization' &&
          (
            <>
              <Grid item sm={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_DAILY_TOP_ORGANISATION_MEMBERS' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
                  <AreaChart
                    data={dailyTopOrganisationByOrganisation}
                    margin={{
                      top: 25,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" interval={0} tick={<CustomTick />} height={80} />
                    <YAxis />
                    <Tooltip cursor={{ fill: 'transparent' }} />
                    <Legend />
                    <Area
                      dataKey="quantity"
                      type="monotone"
                      fill={theme.palette?.primary?.main}
                      stroke={theme.palette?.primary?.dark}
                      name={lookUp({ key: 'CONSOLE_DAILY_TOP_ORGANISATION_MEMBERS' })}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Grid>

              <Grid item sm={12}>
                <Typography align="center" variant="h6" gutterBottom>
                  {lookUp({ key: 'CONSOLE_AGGREGATED_TOP_ORGANISATION_MEMBERS' })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <ResponsiveContainer aspect={4.0 / 2.0} width="100%">
                <BarChart data={aggregatedTopOrganisationByOrganisation}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" />
                  <YAxis tickFormatter={tickFormatter} />
                  <Tooltip
                    cursor={{ fill: 'transparent' }}
                    separator={''}
                    formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
                  />
                  {Bars}
                </BarChart>
              </ResponsiveContainer>
              </Grid>
            </>
          )
        }
      </Grid>
  );
};

export default withSnackbar(StatisticsTab);
