import React, { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Grid,
  Button,
  Checkbox,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.5, 2, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  formControl: {
    width: '100%',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    padding: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    padding: theme.spacing(1.5),
  },
}));

function Comprehend(props) {
  const { source, id, setOpen, enqueueSnackbar } = props;

  const [detectionTypes, setDetectionTypes] = useState({});
  const [providers, setProviders] = useState([]);
  const [provider, setProvider] = useState('');
  const classes = useStyles();

  const handleComprehend = (e, type) => {
    const comprehendJobs = { ...detectionTypes };
    comprehendJobs[type].request = e.target.checked;
    setDetectionTypes(comprehendJobs);
  };

  const startComprehend = () => {
    if (!source) return;
    const requestTypeCodes = Object.values(detectionTypes).reduce((acc, e) => {
      if (e.request) acc.push(+e.value);
      return acc;
    }, []);

    let reqBody = {
      ReferenceObjectId: id,
      InputFileLocation: source?.objectUrl,
      SourceLanguageCode: source?.locale,
    };

    requestTypeCodes.forEach((e) => {
      reqBody.DetectionType = e;
      reqBody.TextAnalysisProvider = provider;
      reqBody.Name = `TextAnalysisJob_Type${e}_${Date.now()}`;
      jobManagerService
        .create('TextAnalysisJob', reqBody)
        .then((jobResponse) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${jobResponse.detectionType}` })} ${lookUp({
              key: `CONSOLE_${jobResponse.statusMessage}`,
            })}`,
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
          });
        });
    });
  };

  useEffect(() => {
    if (Object.keys(detectionTypes).length > 0) return;
    jobManagerService.comprehendTypes().then((types) => {
      setDetectionTypes(
        Object.entries(types).reduce((acc, e) => {
          acc[e[1]] = { value: e[0], request: false };
          return acc;
        }, {})
      );
    });
  }, []);

  useEffect(() => {
    if (providers.length > 0) return;
    jobManagerService
      .comprehendProviders()
      .then((p) => {
        setProvider(p?.[0]);
        setProviders(p);
      })
      .catch(() => {
        setProviders(['Amazon', 'Azure']);
        setProvider('Amazon');
      });
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_COMPREHEND_TITLE' })}
        </Typography>
        <IconButton aria-label="close" onClick={(event) => setOpen('comprehend', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>
        {lookUp({ key: 'CONSOLE_COMPREHEND_HELPERTEXT' })}
      </span>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              select
              fullWidth
              label={lookUp({ key: 'CONSOLE_PROVIDER' })}
              value={provider || ''}
              onChange={({ target }) => setProvider(target.value)}
            >
              {providers.map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            {!!Object.entries(detectionTypes).length &&
              Object.entries(detectionTypes).map((e) => (
                <p key={e[0]}>
                  <Checkbox checked={e[1].request} onChange={(c) => handleComprehend(c, e[0])} />
                  {e[0]}
                </p>
              ))}
          </Grid>
        </Grid>
        <DialogActions>
          <Grid item xs={12} className={classes.dialogActions}>
            <Button
              onClick={() => {
                startComprehend();
                setOpen('comprehend', false);
              }}
              color="primary"
            >
              {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
    </>
  );
}

export default withSnackbar(Comprehend);
