import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import Tour from 'reactour';
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultVideoTransformDevice,
  DefaultMeetingSession,
  MeetingSessionConfiguration,
  LogLevel,
  isVideoTransformDevice,
  DefaultBrowserBehavior,
  DefaultSimulcastUplinkPolicy,
  AllHighestVideoBandwidthPolicy,
  VideoFxProcessor,
} from 'amazon-chime-sdk-js';
import {
  AudioInputControl,
  AudioOutputControl,
  ContentShare,
  ContentShareControl,
  useContentShareControls,
  ControlBar,
  ControlBarButton,
  IconButton,
  Information,
  LeaveMeeting,
  LocalVideo,
  useContentShareState,
  useLocalVideo,
  useToggleLocalMute,
  useMeetingManager,
  VideoInputControl,
  useUserActivityState,
  useBackgroundBlur,
  useBackgroundReplacement,
  useVideoInputs,
  PopOver,
  PopOverItem,
} from 'amazon-chime-sdk-component-library-react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import styled from 'styled-components';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  makeStyles,
  SvgIcon,
} from '@material-ui/core';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import TimeBox from './TimeBox';
import PresetsModal from './PresetsModal';
import contentService from 'services/contentService';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';
import './styles/countBack.css';
import './styles/pulseAnimation.css';
import collectionsServiceV2 from 'services/collectionsServiceV2';

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">SET</div>;
  }
  return (
    <div className="timer">
      <div className="value">{remainingTime}</div>
    </div>
  );
};

const StyledIconButton = styled(IconButton)`
  width: 100%;
  & .ch-icon {
    width: 35px;
    height: 35px;
  }
`;

const useStyles = makeStyles(() => ({
  reacTour: {
    '& .reactour__close': {
      top: 12,
      right: 12,
    },
  },
  dataTour: {
    position: 'relative',
    top: '40%',
    left: '35%',
  },
  recordTour: {
    left: '24%',
    top: '-20%',
  },
  leaveTour: {
    left: '20%',
  },
  screenFrame: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(84, 87, 99, 1)',
    zIndex: 1300,
  },
  videoFrame: {
    width: '100%',
    height: '100%',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    zIndex: 99999,
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  wfSelector: {
    width: '100%',
    color: '#50545e',
    border: '1px solid rgba(140, 140, 140, 0.75)',
    borderRadius: '5px',
    padding: '5px',
    marginLeft: '10px',
    fontSize: '15px',
  },
  inputFile: {
    cursor: 'pointer',
    padding: '1px 5px',
    background: 'red',
  },
  modalSaveButton: {
    color: '#ffffff',
    backgroundColor: '#00897b',
    '&:hover': {
      backgroundColor: '#00998a',
    },
  },
  controlBar: {
    '&.sc-bwzfXH.exqEmI:hover': {
      backgroundColor: '#c1c1c1',
    },
    '.hlkMUs.ch-control-bar-item-label': {
      fontSize: '14px',
    },
  },
  controlBarButton: {
    '&.hlkMUs .ch-control-bar-item-label': {
      fontSize: '14px',
      margin: '8px',
      textAlign: 'center',
      alignSelf: 'self-start',
      lineHeight: 1,
    },
    '&.hlkMUs .ch-control-bar-item-iconButton .ch-icon': {
      width: '30px',
      height: '30px',
    },
  },
  controlBarGrid: {
    display: 'flex',
    justifyContent: 'center',
    gap: '12px',
  },
  record: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 33,
  },
  recordIcon: {
    width: 80,
    borderRadius: '70%',
    background: 'white',
    height: 80,
    position: 'absolute',
    marginTop: -76,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#c1c1c1',
    },
  },
}));

const alertUser = (isRecording) => {
  if (isRecording) {
    window.onbeforeunload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
  }
};

function RecordButton(props) {
  return (
    <SvgIcon {...props}>
      {props.rec ? (
        <rect x="5" y="5" rx="3" ry="3" width="14" height="14" />
      ) : (
        <circle cx="12" cy="12" r="8" />
      )}
    </SvgIcon>
  );
}

function Meeting(props) {
  const { id, defaultBckg, orgName, enqueueSnackbar, } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const parentId = new URLSearchParams(location.search).get('parentId');
  const to = new URLSearchParams(location.search).get('to') || 'edit#items';

  const [screenWidth, setScreenWidth] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [isAudioOnlyModalOpen, setIsAudioOnlyModalOpen] = useState(false);
  const [isCountbackOpen, setIsCountbackOpen] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWf, setSelectedWf] = useState('');
  const [selectedBgFilter, setSelectedBgFilter] = useState('');
  const [file, setFile] = useState('');

  const contentId = useRef();
  const leftSession = useRef();
  const bgInputRef = useRef();

  //Chime SDK related variables
  const meetingManager = useMeetingManager();
  const logger = new ConsoleLogger('SDK', LogLevel.ERROR);
  const deviceController = new DefaultDeviceController(logger);
  const { selectedDevice } = useVideoInputs();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isLocalUserSharing } = useContentShareState();
  const { isUserActive } = useUserActivityState();
  const { isBackgroundBlurSupported } = useBackgroundBlur();
  const { isBackgroundReplacementSupported } = useBackgroundReplacement();

  let tourSteps = [
    'mute',
    'speaker',
    'record',
    'video',
    'background',
    'screen',
    'workflow',
    'leave',
  ];

  const screenWidthRef = useCallback((recordingScreen) => {
    if (recordingScreen) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target?.offsetWidth) setScreenWidth(entry.target.offsetWidth);
        }
      });
      resizeObs.observe(recordingScreen);
    }
  }, []);

  const recordButtonProps = {
    icon: (
      <RecordButton
        rec={isRecording}
        htmlColor={isRecording ? 'red' : 'black'}
        className={classes.recordIcon}
      />
    ),
    label: isRecording ? lookUp({ key: 'CONSOLE_STOP_BUTTON' }) : lookUp({ key: 'CONSOLE_RECORD' }),
    onClick: () => (!isRecording ? checkDevicesBeforeRecording() : leaveSession()),
  };
  const filterBackgroundProps = {
    icon: <BlurOnIcon />,
    label: lookUp({ key: 'CONSOLE_BACKGROUND_BUTTON' }),
  };
  const filterBlurBackgroundProps = {
    disabled: !isBackgroundBlurSupported,
    checked: selectedBgFilter === 'blur',
    onClick: () => {
      if (selectedBgFilter === 'blur') {
        setSelectedBgFilter('');
      } else {
        setSelectedBgFilter('blur');
      }
    },
    children: <>{lookUp({ key: 'CONSOLE_BLUR_BACKGROUND' })}</>,
  };
  const filterReplaceBackgroundProps = {
    disabled: !isBackgroundReplacementSupported,
    checked: selectedBgFilter === 'replace',
    onClick: () => {
      if (selectedBgFilter === 'replace') {
        setSelectedBgFilter('');
      } else {
        setSelectedBgFilter('replace');
        bgInputRef.current.click();
      }
    },
    children: <>{lookUp({ key: 'CONSOLE_CHOOSE_FILE' })}</>,
  };
  const leaveMeetingButtonProps = {
    icon: <LeaveMeeting width="35" height="auto" />,
    label: lookUp({ key: 'CONSOLE_LEAVE_MEETING' }),
    disabled: isRecording ? true : false,
    onClick: () => leaveSession(),
  };
  const guideButtonProps = {
    icon: <Information width="35" height="auto" />,
    label: lookUp({ key: 'CONSOLE_INFO' }),
    onClick: () => setOpenTutorial(!openTutorial),
  };

  useMemo(async () => {
    if (!isVideoEnabled || !selectedDevice) return;
    if (selectedBgFilter === 'blur' && !isBackgroundBlurSupported) return;
    if (selectedBgFilter === 'replace' && !isBackgroundReplacementSupported) return;
    try {
      let processor = [];
      if (selectedBgFilter === '' && isVideoTransformDevice(selectedDevice)) {
        await meetingManager.startVideoInputDevice(logger, selectedDevice, processor);

      } else if (selectedBgFilter !== '') {
        let videoFxConfig = {};
        let transformProcessor;

        if (selectedBgFilter === 'blur') {
          videoFxConfig = {
            backgroundBlur: {
              isEnabled: false,
              strength: 'medium',
            },
            backgroundReplacement: {
              isEnabled: false,
            },
          };
          transformProcessor = transformProcessor = await VideoFxProcessor.create(
            logger,
            videoFxConfig,
          );
        } else {
          videoFxConfig = {
            backgroundBlur: {
              isEnabled: false,
              strength: 'medium',
            },
            backgroundReplacement: {
              isEnabled: file ? true : false,
              backgroundImageURL: file ? URL.createObjectURL(file) : undefined,
              defaultColor: undefined,
            },
          };

          if (file) {
            transformProcessor = transformProcessor = await VideoFxProcessor.create(
              logger,
              videoFxConfig
            );
          }
        }

        transformProcessor && processor.push(transformProcessor);
        let transformDevice = new DefaultVideoTransformDevice(logger, selectedDevice, processor);
        await meetingManager.startVideoInputDevice(transformDevice);
      }
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        autoHideDuration: 10000,
        error,
        context: lookUp({ key: `CONSOLE_BACKGROUND_ERROR` }),
      });
    }
  }, [isVideoEnabled, selectedBgFilter, file]);

  const joinSession = async (meetingSessionConfiguration) => {
    try {
      // Create a `MeetingSession` using `join()` function with the `MeetingSessionConfiguration`
      await meetingManager.join(meetingSessionConfiguration);
      // Start the `MeetingSession` to join the meeting
      await meetingManager.start();
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: `CONSOLE_Cant start session_ERROR` }),
      });
      parentId ? navigate(`/content/${parentId}/${to}`) : navigate(window.history.state.idx === 0 ? '/' : -1);
    }
  };

  const checkDevicesBeforeRecording = () => {
    if (isRecording) return leaveSession();
    if (!isLocalUserSharing && !isVideoEnabled) {
      setIsAudioOnlyModalOpen(true);
      return;
    }
    setIsCountbackOpen(true);
  };

  const handleStartRecording = async () => {
    try {
      await contentService.startRecording(id);
      setIsRecording(true);
    } catch (error) {
      setIsCountbackOpen(false);
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        autoHideDuration: 10000,
        error,
        context: lookUp({ key: `CONSOLE_Cant start recording_ERROR` }),
      });
    }
  };

  const onFileSelect = file => {
    if (!file) return;
    setFile(file);
  };

  const addWorkflowId = async () => {
    try {
      const contentDetails = await contentService.getById(contentId.current);
      contentDetails.properties['Content:Process:WorkflowId'] = selectedWf;
      await contentService.edit(contentDetails);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        autoHideDuration: 10000,
        error,
        context: lookUp({ key: `CONSOLE_Cant add workflow_ERROR` }),
      });
    }
  };

  const addParentOrgsAndBack = () => {
    collectionsServiceV2.getContentRelation(parentId, 'Organization')
    .then(res => {
      const parentOrgs = res.pageContent.map(e => e.targetId);
      parentOrgs.forEach(id => collectionsServiceV2.addContentRelation(contentId.current, 'Organization', id))
    });
    navigate(`/content/${parentId}/${to}`);
  }

  const leaveSession = async () => {
    window.onbeforeunload = null;
    if (leftSession.current) return;
    try {
      if (isRecording) {
        if (!!selectedWf) {
          addWorkflowId();
        }
        await contentService.finishRecording(id);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SESSIONS' }),
          }),
          autoHideDuration: 10000,
        });
      } else {
        await contentService.remove(contentId.current);
      }
      await meetingManager.leave();
      leftSession.current = true;
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_FINISH_SESSION_ERROR' }),
        autoHideDuration: 20000,
      });
    } finally {
      parentId ? addParentOrgsAndBack() : navigate(window.history.state.idx === 0 ? '/' : -1);
    }
  };

  useEffect(() => {
    const getSessionData = async () => {
      try {
        const meetingData = await contentService.getMeetingData(id);
        contentId.current = meetingData.contentId;
        const attendeeData = await contentService.getAttendeeData(id);
        // Initalize the `MeetingSessionConfiguration`
        const meetingSessionConfiguration = new MeetingSessionConfiguration(
          meetingData,
          attendeeData,
        );

        const meetingSession = new DefaultMeetingSession(
          meetingSessionConfiguration,
          logger,
          deviceController,
        );

        // Set up the video quality
        meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 30);
        
        const browserBehavior = new DefaultBrowserBehavior();
        
        if (browserBehavior.isSimulcastSupported()) {
          meetingSessionConfiguration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = true;
          meetingSessionConfiguration.videoDownlinkBandwidthPolicy = new AllHighestVideoBandwidthPolicy(
            id
            );
            
            await meetingSession.audioVideo.enableSimulcastForContentShare(true);
          meetingSession.audioVideo.chooseVideoInputQuality(1280, 720, 15);
          meetingSessionConfiguration.videoUplinkBandwidthPolicy =
            new DefaultSimulcastUplinkPolicy(id, logger);
        }
        meetingSession.audioVideo.setVideoMaxBandwidthKbps(2000);

        //Join session
        joinSession(meetingSessionConfiguration);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_SESSIONS' }),
          }),
        });
        navigate(window.history.state.idx === 0 ? '/' : -1);
      }
    };
    const getWorkflowTemplates = async () => {
      try {
        jobManagerService.searchWfTemplates().then((response) => {
          const wfTemplates = response.data;
          setWorkflows([
            { id: '', name: lookUp({ key: 'CONSOLE_SELECT_WORKFLOW' }) },
            ...wfTemplates,
          ]);
        });
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_WORKFLOWSLABEL' }),
          }),
        });
      }
    };

    getSessionData();
    getWorkflowTemplates();
  }, [id]);

  const steps = useMemo(() => {
    if (screenWidth < 1000) {
      tourSteps = tourSteps.filter((step) => step !== 'screen');
    }
    return tourSteps.map((step) => ({
      selector: `[data-tour="step-${step}"]`,
      content: lookUp({ key: `CONSOLE_RECORDING_HINT_${step}` }),
      style: {
        width: '300px',
        fontSize: '16px',
        padding: '24px 24px',
      },
    }));
  }, [tourSteps, screenWidth]);

  useEffect(() => {
    alertUser(isRecording);
  }, [isRecording]);

  useEffect(() => {
    return () => {
      leaveSession();
    };
  }, []);

  useEffect(() => {
    const setBackGroundImage = async () => {
      if (defaultBckg?.length && isBackgroundReplacementSupported && isVideoEnabled) {
        const imgResponse = await fetch(defaultBckg);
        onFileSelect(await imgResponse.blob());
        setSelectedBgFilter('replace');
      }
    };
    setBackGroundImage();
  }, [defaultBckg, isBackgroundReplacementSupported, isVideoEnabled]);

  return (
    <section className={classes.screenFrame} ref={screenWidthRef}>
      <div className={classes.videoFrame}>
        <LocalVideo className={classes.localVideo} />
        <ContentShare />
        {isRecording && !isLocalUserSharing && !isVideoEnabled && (
          <div className="animate">{lookUp({ key: `CONSOLE_Ongoing audio recording` })}</div>
        )}
      </div>

      <ControlBar
        showLabels
        layout="bottom"
        style={{ height: '5.5rem' }}
        css=".exqEmI:hover {background-color: #c1c1c1;} .ch-icon {width: 30px;}"
      >
        <Grid container justifyContent="center" alignItems="start" spacing={1} wrap="nowrap">
          <Grid
            container
            item
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            maxWidth="100%"
            spacing={2}
            xs={3}
          >
            <Grid item xs={2} style={{ textAlign: 'left' }}>
              <span
                className={`${classes.dataTour} ${classes.leaveTour}`}
                data-tour="step-leave"
              ></span>
              <StyledIconButton {...leaveMeetingButtonProps} />
            </Grid>
            <Grid item xs>
              <select
                data-tour="step-workflow"
                className={classes.wfSelector}
                onChange={(event) => setSelectedWf(event.target.value)}
                name="workflows"
                id="workflow-select"
              >
                {workflows.map((workflow) => (
                  <option key={`key_${workflow.id}`} value={workflow.id}>
                    {workflow.name}
                  </option>
                ))}
              </select>
            </Grid>
          </Grid>
          <Grid item container xs>
            <Grid item container xs justifyContent="flex-end">
              <Grid item xs={6} md={4}>
                <span className={classes.dataTour} data-tour="step-mute"></span>
                <AudioInputControl
                  className={classes.controlBarButton}
                  muteLabel={lookUp({ key: 'CONSOLE_MUTE' })}
                  unmuteLabel={lookUp({ key: 'CONSOLE_UNMUTE' })}
                  mutedIconTitle={lookUp({ key: 'CONSOLE_MUTED_ICON_TITLE' })}
                  unmutedIconTitle={lookUp({ key: 'CONSOLE_UNMUTED_ICON_TITLE' })}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <span className={classes.dataTour} data-tour="step-speaker"></span>
                <AudioOutputControl
                  className={classes.controlBarButton}
                  label={lookUp({ key: 'CONSOLE_SPEAKER' })}
                />
              </Grid>
            </Grid>
            <Grid item container xs={3} md={2} justifyContent="center">
              <span
                className={`${classes.dataTour} ${classes.recordTour}`}
                data-tour="step-record"
              ></span>
              <Grid item onClick={recordButtonProps.onClick} className={classes.record}>
                {recordButtonProps.icon}
                <span style={{ color: '#50545e' }}>{recordButtonProps.label}</span>
              </Grid>
            </Grid>
            <Grid item container xs>
              <Grid item xs={6} md={4}>
                <span className={classes.dataTour} data-tour="step-video"></span>
                <VideoInputControl
                  className={classes.controlBarButton}
                  label={lookUp({ key: 'CONSOLE_VIDEO' })}
                  onClick={toggleVideo}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <span className={classes.dataTour} data-tour="step-background"></span>
                <input
                  type="file"
                  ref={bgInputRef}
                  accept="image/*"
                  hidden
                  onChange={(event) => onFileSelect(event.target?.files?.[0])}
                />
                <ControlBarButton
                  {...filterBackgroundProps}
                  className={classes.controlBarButton}
                  popOver={[
                    {
                      as: 'button',
                      ...filterBlurBackgroundProps,
                    },
                    {
                      as: 'button',
                      ...filterReplaceBackgroundProps,
                    },
                  ]}
                />
              </Grid>
              {screenWidth > 1000 && (
                <Grid item xs={4}>
                  <span className={classes.dataTour} data-tour="step-screen"></span>
                  <ContentShareControl
                    className={classes.controlBarButton}
                    label={lookUp({ key: 'CONSOLE_CONTENT' })}
                    pauseLabel={lookUp({ key: 'CONSOLE_PAUSE' })}
                    unpauseLabel={lookUp({ key: 'CONSOLE_UNPAUSE' })}
                    iconTitle={lookUp({ key: 'CONSOLE_SCREEN_SHARE' })}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            spacing={0}
            xs={2}
            md={3}
          >
            <Grid item xs={8}>
              <TimeBox
                leaveSession={leaveSession}
                recordingTime={recordingTime}
                isUserActive={isUserActive}
                isRecording={isRecording}
                isLocalUserSharing={isLocalUserSharing}
                isVideoEnabled={isVideoEnabled}
                enqueueSnackbar={enqueueSnackbar}
              />
            </Grid>
            <Grid item xs={4}>
              <StyledIconButton
                {...guideButtonProps}
                style={{
                  backgroundColor: openTutorial ? '#0042bb' : null,
                  color: openTutorial ? '#ffffff' : null,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ControlBar>
      {isCountbackOpen && (
        <div onClick={() => setIsCountbackOpen(false)} className={classes.backdrop}>
          <div>
            <CountdownCircleTimer
              isPlaying
              duration={3}
              colors={['#F7DD01', '#11DD55']}
              colorsTime={[3, 0]}
              onComplete={() => {
                setIsCountbackOpen(false);
                handleStartRecording();
                setRecordingTime(new Date());
                return { shouldRepeat: false, delay: 1 };
              }}
            >
              {renderTime}
            </CountdownCircleTimer>
          </div>
        </div>
      )}
      <PresetsModal
        setIsCountbackOpen={setIsCountbackOpen}
        toggleVideo={toggleVideo}
        isVideoEnabled={isVideoEnabled}
        toggleContentShare={toggleContentShare}
        isLocalUserSharing={isLocalUserSharing}
        muted={muted}
        toggleMute={toggleMute}
      />
      <Dialog
        style={{ zIndex: 999999 }}
        open={isAudioOnlyModalOpen}
        maxWidth={'xs'}
        onClose={() => setIsAudioOnlyModalOpen(false)}
      >
        <DialogTitle>{lookUp({ key: 'CONSOLE_RECORD_AUDIO_ONLY_QUESTION' })}</DialogTitle>
        <DialogContent>
          <p>{lookUp({ key: 'CONSOLE_RECORD_AUDIO_ONLY_TEXT' })}</p>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button onClick={() => setIsAudioOnlyModalOpen(false)}>
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </Button>
          <Button
            className={classes.modalSaveButton}
            color="primary"
            onClick={() => {
              setIsAudioOnlyModalOpen(false);
              setIsCountbackOpen(true);
            }}
          >
            {lookUp({ key: 'CONSOLE_OK_BUTTON' })}
          </Button>
        </DialogActions>
      </Dialog>
      <Tour
        steps={steps}
        startAt={0}
        showNumber={false}
        showNavigationNumber={false}
        isOpen={openTutorial}
        maskSpace={40}
        className={classes.reacTour}
        onRequestClose={() => setOpenTutorial(false)}
      />
    </section>
  );
}

export default Meeting;
