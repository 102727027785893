const SET_ACTION_GROUPS = 'SET_ACTION_GROUPS';
const SET_ACTION_TYPES = 'SET_ACTION_TYPES';
const SET_CATALOG_KINDS = 'SET_CATALOG_KINDS';
const SET_CATALOG_TYPES = 'SET_CATALOG_TYPES';
const SET_CONDITION_NAMES = 'SET_CONDITION_NAMES';
const SET_DATA_GROUP_TYPES = 'SET_DATA_GROUP_TYPES';
const SET_FEED_TYPES = 'SET_FEED_TYPES';
const SET_HTTP_METHODS = 'SET_HTTP_METHODS';
const SET_PLUGIN_TYPES = 'SET_PLUGIN_TYPES';
const SET_SORT_DIRECTIONS = 'SET_SORT_DIRECTIONS';
const SET_STRING_MATCHING = 'SET_STRING_MATCHING';
const SET_VALUE_FORMATS = 'SET_VALUE_FORMATS';
const SET_VISIBILITY_GROUPS = 'SET_VISIBILITY_GROUPS';
const SET_SCHEDULE_TYPES = 'SET_SCHEDULE_TYPES';
const SET_PROPERTY_OPTIONS = 'SET_PROPERTY_OPTIONS';
const SET_CATALOG_ITEMS = 'SET_CATALOG_ITEMS';
const SET_PARAMETERS_ITEMS = 'SET_PARAMETERS_ITEMS';
const SET_BREADCRUMBS_LINKS = 'SET_BREADCRUMBS_LINKS';
const SET_PARENT_NAME = 'SET_PARENT_NAME';
const SET_PARENT_ID = 'SET_PARENT_ID';
const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';

const setActionGroups = (actionGroups) => ({ type: SET_ACTION_GROUPS, actionGroups });

const setActionTypes = (actionTypes) => ({ type: SET_ACTION_TYPES, actionTypes });

const setCatalogKinds = (catalogKinds) => ({ type: SET_CATALOG_KINDS, catalogKinds });

const setCatalogTypes = (catalogTypes) => ({ type: SET_CATALOG_TYPES, catalogTypes });

const setConditionNames = (conditionNames) => ({ type: SET_CONDITION_NAMES, conditionNames });

const setDataGroupTypes = (dataGroupTypes) => ({ type: SET_DATA_GROUP_TYPES, dataGroupTypes });

const setFeedTypes = (feedTypes) => ({ type: SET_FEED_TYPES, feedTypes });

const setHttpMethods = (httpMethods) => ({ type: SET_HTTP_METHODS, httpMethods });

const setPluginTypes = (pluginTypes) => ({ type: SET_PLUGIN_TYPES, pluginTypes });

const setSortDirections = (sortDirections) => ({ type: SET_SORT_DIRECTIONS, sortDirections });

const setStringMatching = (stringMatching) => ({ type: SET_STRING_MATCHING, stringMatching });

const setValueFormats = (valueFormats) => ({ type: SET_VALUE_FORMATS, valueFormats });

const setVisibilityGroups = (visibilityGroups) => ({ type: SET_VISIBILITY_GROUPS, visibilityGroups });

const setScheduleTypes = (scheduleTypes) => ({ type: SET_SCHEDULE_TYPES, scheduleTypes });

const setPropertyOptions = (propertyOptions) => ({ type: SET_PROPERTY_OPTIONS, propertyOptions });

const setCatalogItems = (catalogItems) => ({ type: SET_CATALOG_ITEMS, catalogItems });

const setParametersItems = (parametersItems) => ({ type: SET_PARAMETERS_ITEMS, parametersItems });

const setBreadCrumbsLinks = (breadcrumbsLinks)=> ({ type: SET_BREADCRUMBS_LINKS, breadcrumbsLinks });

const setParentName = (parentName) => ({type: SET_PARENT_NAME, parentName})

const setParentId = (parentId) => ({type: SET_PARENT_ID, parentId})

const setPageNumber = (pageNumber) => ({ type: SET_PAGE_NUMBER, pageNumber });

export default {
  SET_ACTION_GROUPS,
  SET_ACTION_TYPES,
  SET_CATALOG_KINDS,
  SET_CATALOG_TYPES,
  SET_CONDITION_NAMES,
  SET_DATA_GROUP_TYPES,
  SET_FEED_TYPES,
  SET_HTTP_METHODS,
  SET_PLUGIN_TYPES,
  SET_SORT_DIRECTIONS,
  SET_STRING_MATCHING,
  SET_VALUE_FORMATS,
  SET_VISIBILITY_GROUPS,
  SET_SCHEDULE_TYPES,
  SET_PROPERTY_OPTIONS,
  SET_CATALOG_ITEMS,
  SET_PARAMETERS_ITEMS,
  SET_BREADCRUMBS_LINKS,
  SET_PARENT_NAME,
  SET_PARENT_ID,
  SET_PAGE_NUMBER,
  setActionTypes,
  setCatalogKinds,
  setCatalogTypes,
  setConditionNames,
  setDataGroupTypes,
  setFeedTypes,
  setHttpMethods,
  setPluginTypes,
  setSortDirections,
  setStringMatching,
  setValueFormats,
  setVisibilityGroups,
  setScheduleTypes,
  setPropertyOptions,
  setActionGroups,
  setCatalogItems,
  setParametersItems, 
  setBreadCrumbsLinks,
  setParentName,
  setParentId,
  setPageNumber,
};

export {
  SET_ACTION_GROUPS,
  SET_ACTION_TYPES,
  SET_CATALOG_KINDS,
  SET_CATALOG_TYPES,
  SET_CONDITION_NAMES,
  SET_DATA_GROUP_TYPES,
  SET_FEED_TYPES,
  SET_HTTP_METHODS,
  SET_PLUGIN_TYPES,
  SET_SORT_DIRECTIONS,
  SET_STRING_MATCHING,
  SET_VALUE_FORMATS,
  SET_VISIBILITY_GROUPS,
  SET_SCHEDULE_TYPES,
  SET_PROPERTY_OPTIONS,
  SET_CATALOG_ITEMS,
  SET_PARAMETERS_ITEMS,
  SET_BREADCRUMBS_LINKS,
  SET_PARENT_NAME,
  SET_PARENT_ID,
  SET_PAGE_NUMBER,
  setActionTypes,
  setCatalogKinds,
  setCatalogTypes,
  setConditionNames,
  setDataGroupTypes,
  setFeedTypes,
  setHttpMethods,
  setPluginTypes,
  setSortDirections,
  setStringMatching,
  setValueFormats,
  setVisibilityGroups,
  setScheduleTypes,
  setPropertyOptions,
  setActionGroups,
  setCatalogItems,
  setParametersItems,
  setBreadCrumbsLinks,
  setParentName,
  setParentId,
  setPageNumber,
};
