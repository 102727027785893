import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import { sortLangCodesByLangNames } from 'helpers/common';
import AlertService from 'services/alertService';
import parametersService from 'services/parametersService';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 2, 2, 1.5),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.5),
  },
}));


function Transcribe(props) {
  const {
    url,
    audioUrl,
    locale,
    contentData,
    setOpen,
    enqueueSnackbar
  } = props;
  
  const classes = useStyles();

  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [transcribeLangs, setTranscribeLangs] = useState('');
  const [languageCode, setLanguageCode] = useState('');

  const startTranscribe = () => {
    if (selectedProvider === 'OpenAI') {
      const inputFileLocation =
        contentData.assets.find((asset) => {
          const isStringType = typeof asset.type === 'string'
          const isAudio = isStringType && asset.type.toLowerCase() === 'audio';
          const isWav = isStringType && asset.subType.toLowerCase() === 'wav';
          const isOriginalWav = 
            isStringType && 
            asset.subType.toLowerCase() === 'original' &&
            asset.fileName.slice(-4) === '.wav';
          return isAudio && (isWav || isOriginalWav);
        })?.objectUrl;

      if (!inputFileLocation) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
          context: selectedProvider,
        });
        return;
      }

      const reqBody = {
        referenceObjectId: contentData.id,
        name: `Whisper_AI_job_${contentData.id}`,
        inputFileLocation,
        mediaProcessingType: 'WHISPER_AI_TRANSCRIPT',
        notificationEvents: ['SUCCEED', 'FAILED'],
        type: 'MEDIA_PROCESSOR',
      };

      jobManagerService
        .mediaProcessor(reqBody)
        .then((jobResponse) => {
          const jobType = lookUp({ key: `CONSOLE_${jobResponse.type}` });
          const jobStatus = lookUp({ key: `CONSOLE_${jobResponse.status}` });
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${jobType} -> ${jobStatus}`,
          });
        })
        .catch(() => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
            context: selectedProvider,
          });
        });
    } else {
      const reqBody = {
        ReferenceObjectId: contentData.id,
        Name: `TranscriptJob_${Date.now()}`,
        InputFileLocation: audioUrl || url,
        Provider: selectedProvider,
        LanguageCode: languageCode,
      };

      jobManagerService
        .create('TranscriptJob', reqBody)
        .then((resp) => {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${lookUp({ key: `CONSOLE_${resp.type}` })} ${resp.statusMessage}`,
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
          });
        });
    }
  };

  useEffect(() => {
    jobManagerService.transcriptProviders().then((e) => {
      setProviders(Object.values(e).concat(['OpenAI']));
      setSelectedProvider(Object.values(e)[0]);
    });

    parametersService.getByKey('Settings:DefaultLocale').then((response) => {
      setLanguageCode(response?.value || locale || navigator?.language || 'en-US');
    })
  }, []);

  useEffect(() => {
    if (Object.keys(transcribeLangs).length > 0) return;
    jobManagerService.getLanguageCodes('Translate')
      .then((translateLangs) => setTranscribeLangs(sortLangCodesByLangNames(translateLangs)))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_LANGUAGES'})
          }),
        });
      });
  }, []);

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_CREATE_TRANSCRIPTION_HEADING' })}
        </Typography>
        <IconButton aria-label="close" onClick={() => setOpen('transcribe', false)}>
          <Close />
        </IconButton>
      </div>
      <span className={classes.modalSubtitle}>{lookUp({ key: 'CONSOLE_CREATE_TRANSCRIPTION_TEXT' })}</span>
      <DialogContent className={classes.dialogBody}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          className={classes.transModalGrid}
        >
          <Grid item container spacing={2}>
            <Grid item md={6}>
              <TextField
                select
                fullWidth
                label={lookUp({ key: 'CONSOLE_PROVIDER' })}
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
              >
                {providers.map((e) => (
                  <MenuItem key={e} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {selectedProvider !== 'OpenAI' && (
              <Grid item md={6}>
                <TextField
                  select
                  fullWidth
                  label={lookUp({ key: 'CONSOLE_LANGUAGE' })}
                  value={languageCode}
                  onChange={(e) => setLanguageCode(e.target.value)}
                >
                  {Object.keys(transcribeLangs).map((langC) =>
                    <MenuItem key={langC} value={langC}>{transcribeLangs[langC]}</MenuItem>
                  )}
                </TextField>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} className={classes.dialogActions}>
          <Grid item>
            <Button
              onClick={() => {
                startTranscribe();
                setOpen('transcribe', false);
              }}
              color="primary"
            >
              {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
}

export default withSnackbar(Transcribe);
