import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { DataGrid } from '@material-ui/data-grid';
import { Typography, IconButton } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import Box from '@material-ui/core/Box';

const Favs = ({ columns, rows, pageSize = 5, dataGridProps }) => {
  const [open, setOpen] = useState(+localStorage.getItem('favOpen') === 0 ? 0 : 1);

  useEffect(() => {
    localStorage.setItem('favOpen', open);
  }, [open]);

  return (
    <Box flex={1}>
      <Typography variant="h6" gutterBottom>
        {lookUp({ key: 'CONSOLE_FAVOURITES' })}{' '}
        <IconButton onClick={() => setOpen((o) => (o + 1) % 2)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Typography>
      {!!open && (
        <DataGrid
          columns={columns}
          rows={rows.filter((row) => !!row.id)}
          pagination
          pageSize={pageSize}
          hideFooter={rows.length <= pageSize}
          autoHeight
          disableColumnMenu
          {...dataGridProps}
        />
      )}
    </Box>
  );
};

export default Favs;
