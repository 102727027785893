const overrides = {
  MuiTableCell: {
    root: { 
      paddingRight: 0
    }
  },
  MuiTabs: {
    indicator: {
      height: 4,
    },
  },
  MuiTab: {
    root: {
      lineHeight: 1.5,
      minWidth: 80,
      // This solves the issue of wrapping label vertically into 2 lines
      maxWidth: 'auto',
      '@media (min-width: 0px)': {minWidth: 80},
    },
  },
  MuiTabScrollButton: {
    root: {
      width: 24, // theme.spacing(3)
    }
  },
  MuiBreadcrumbs: {
    root: {
      maxHeight: 60
    }
  },
  MuiPickersDay: {
    day: {
      fontWeight: "300",
    },
  },
  MuiPickersYear: {
    root: {
      height: "64px",
    },
  },
  MuiPickersCalendar: {
    transitionContainer: {
      marginTop: "6px",
    },
  },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: "transparent",
      "& > *": {
        backgroundColor: "transparent",
      },
    },
    switchHeader: {
      marginTop: "2px",
      marginBottom: "4px",
    },
  },
  MuiPickersClock: {
    container: {
      margin: `32px 0 4px`,
    },
  },
  MuiPickersClockNumber: {
    clockNumber: {
      left: `calc(50% - 16px)`,
      width: "32px",
      height: "32px",
    },
  },
  MuiPickerDTHeader: {
    dateHeader: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
    timeHeader: {
      "& h3": {
        fontSize: "3rem",
        fontWeight: 400,
      },
    },
  },
  MuiPickersTimePicker: {
    hourMinuteLabel: {
      "& h2": {
        fontSize: "3.75rem",
        fontWeight: 300,
      },
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: "2.125rem",
        fontWeight: 400,
      },
    },
  },
  MuiChip: {
    root: {
      borderRadius: 2,
    },
  },
  MuiDataGrid: {
    root: {
      border: 'none',
      '& *': {
        outline: 'none !important',
      },
      '& .MuiDataGrid-columnHeader': {
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
      },
    },
  },
};

export default overrides;
