import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import ColorPicker from 'components/ColorPicker'
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  right: {
    marginLeft: 'auto'
  },
  left: {
    marginRight: 'auto'
  }

}));

const DesignProps = (props) => {
  
  const classes = useStyles();
  const { handleChange, properties } = props;

  const [designFilteredPropList, setDesignFilteredPropList] = useState([])
  const [items, setItems] = useState(properties);
  const [isDesignPropsLoading, setIsDesignPropsLoading] = useState(false);

  const handleItemsChange = (index, key, value) =>
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));

  const handleItemsRemove = (index) => setItems((prev) => prev.filter((p, i) => i !== index));

  const handleColorPropChange = (key, value) => {
    let exists = false;
    const newItems = items.map(item => {
       if (item.name === key) {
         exists = true;
         item.value = value;
          return item
        } else 
        return {...item}
       }
      )
    if (!exists) {
      newItems.push({'collection': '', 'name': key, 'value': value});
    }
    setItems(newItems)
  };

  const carouselDesign = [
    {'collection': '', 'name': 'Carousel:Design:Light:Background:Color', 'value': ''},
    {'collection': '', 'name': 'Carousel:Design:Dark:Background:Color', 'value': ''},
    {'collection': '', 'name': 'Carousel:Design:Light:FontColor:Main', 'value': ''},
    {'collection': '', 'name': 'Carousel:Design:Dark:FontColor:Main', 'value': ''},
    {'collection': '', 'name': 'Carousel:Design:Light:FontColor:ShortInfo', 'value': ''},
    {'collection': '', 'name': 'Carousel:Design:Dark:FontColor:ShortInfo', 'value': ''},
  ];
    
  useEffect(() => {
    const getDesignProps = async () => {
      if (properties) {
        const filteredList = properties.filter(props => props.name.startsWith('Carousel:Design'))
        setIsDesignPropsLoading(true);
        const filler = filteredList.map(f => f.name)
          const options = carouselDesign;
          for(let i = 0; i < options.length; i++) {
            if(!filler.includes(options[i].name)) {
              filteredList.push({'collection': '', 'name': options[i].name, 'value': ''})
            }
          }
          setDesignFilteredPropList(filteredList)
      } else {
        setDesignFilteredPropList(carouselDesign)
      }
    }
    getDesignProps()
  }, []);
    
  useEffect(() => {
    handleChange(items);
  }, [items]);
  
  return (
    <>
      {designFilteredPropList &&  (
        <Grid container alignItems={'center'}  className={classes.marginX} spacing={2}>
          <Grid item xs={12}><Typography variant="h6" style={{marginBottom: '1em'}}>{lookUp({ key: 'CONSOLE_LIGHT_N_DARK' })}</Typography></Grid>
          {designFilteredPropList.map((item, index) => {
            return (
              <Grid item xs={12} md={6} key={index} className={item.name.includes('Light') ? classes.right :  classes.left}>
                <ColorPicker
                   label={item.name}
                   handleChange={( value ) => handleColorPropChange(item.name, value)}
                   color={item.value}
                />
              </Grid>
          )
        })
      }
        </Grid>
      )}
    </>
  );
};

export default DesignProps
