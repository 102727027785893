import { useState } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getLastContentTabOpened } from 'store/reducers/contentReducer';
import { setLastContentTabOpened } from 'store/actions/contentAction';
import { sidebarWidth } from 'constants/index.js';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabPanel from 'components/TabPanel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import ListIcon from '@material-ui/icons/List';
import CodeIcon from '@material-ui/icons/Code';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import PublicIcon from '@material-ui/icons/Public';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import FilterListIcon from '@material-ui/icons/FilterList';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    // FIXME should normalize ALL sections who apply padding based on existing toolbars
    paddingTop: props.paddingTop + theme.spacing(2),
    paddingBottom: theme.spacing(3),
  }),
  appBar: (props) => ({
    top: props.addTopMargin > 0 ? HEADER_MIN_HEIGHT + props.addTopMargin : HEADER_MIN_HEIGHT,
    width: `calc(100% - ${props.collapsed ? sidebarWidth.closed : sidebarWidth.open}px)`,
  }),
  tabs: {
    padding: theme.spacing(0, 3),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const defaultIcons = {
  basic: <WebAssetIcon />,
  localization: <PublicIcon />,
  tags: <CodeIcon />,
  images: <WallpaperIcon />,
  assets: <ListIcon />,
  social: <PeopleAltIcon />,
  properties: <SettingsIcon />,
  settings: <SettingsIcon />,
  filtering: <FilterListIcon />,
  decorations: <AccountTreeIcon />,
};

const Tabbing = ({
  addTopMargin=0,
  // NOTE elements on tabs prop will be arranged at the start edge
  tabs = [],
  // NOTE elements on buttons prop will be arranged at the end edge
  buttons = [],
  paddingTop=HEADER_MIN_HEIGHT,
}) => {
  const trigger = useScrollTrigger();
  const navigate = useNavigate();
  const location = useLocation();
  const tab = location.hash.replace('#', '');
  const indexedTabs = tabs.filter((e) => !!e).map((e, index) => ({ ...e, number: index }));

  const dispatch = useDispatch();
  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const classes = useStyles({ collapsed: isSidebarCollapsed, addTopMargin, paddingTop });
  const lastContentTabOpened = useSelector(getLastContentTabOpened);
  const tabURLs = tabs.map((tab) => tab.name?.split(' ').join('-').toLowerCase());
  const [activeTab, setActiveTab] = useState(
    tab === tabURLs[0] ? 0 : Math.max(tabURLs.indexOf(tab), 0) || lastContentTabOpened
  );

  const filteredButtons = buttons?.filter((e) => !!e?.label);

  /**
   * FIXME We should consider either leaving the TabPanel content already rendered on a tab,
   * in order not to re, request and render the content when switching tabs.
   * Or canceling inflight http requests before navigating away.
   *
   * currently we get a log of warnigs like below when switching a tab,
   * due to hiding the nodes and attempting to update them when receiving the inflight request
   *
   * 'Warning: Can't perform a React state update on an unmounted component.
   * This is a no-op, but it indicates a memory leak in your application.
   * To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.'
   **/
  const setActiveTabPlusDispatch = (number) => {
    if (number >= tabURLs.length) {
      return;
    }

    setActiveTab(number);
    navigate(`${location.pathname}#${tabURLs[number]}`, { replace: true });
    dispatch(setLastContentTabOpened(number));
  };

  return (
    <Box className={classes.root}>
      <Slide appear={false} direction={'down'} in={!trigger}>
        <AppBar position="fixed" className={classes.appBar} color="inherit">
          <Tabs
            className={classes.tabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            value={activeTab}
            onChange={(e, newTab) => {
              e.preventDefault();
              setActiveTabPlusDispatch(newTab);
            }}
            aria-label="Edit page tabs"
          >
            {indexedTabs.map((tab) => (
              <Tab
                label={lookUp({ key: `CONSOLE_${tab.name}_TAB` })}
                icon={tab.icon || defaultIcons[tab.name?.toLowerCase()] || <ExpandMoreIcon />}
                key={tab.number}
                index={tab.number}
                {...a11yProps(tab.number)}
              />
            ))}
            <Box flex={1} />
            {filteredButtons.length > 0 && (
              <Box>
                {filteredButtons?.map((button, i) =>
                  button.link ? (
                    <Link key={`${button.label}-${i}`} to={button.link} className={classes.link}>
                      <Tab label={button.label} icon={button.icon} />
                    </Link>
                  ) : (
                    <Tab
                      label={button.label}
                      key={`${button.label}-${i}`}
                      icon={button.icon}
                      onClick={e => {
                        e.preventDefault();
                        button.action?.();
                      }}
                    />
                  )
                )}
              </Box>
            )}
          </Tabs>
        </AppBar>
      </Slide>
      {indexedTabs.map?.((tab) => (
        <TabPanel value={activeTab} index={tab.number} key={`${tab.name}-${tab.number}`}>
          {tab.content}
        </TabPanel>
      ))}
    </Box>
  );
};

Tabbing.propTypes = {
  // number, used to position AppBar from top in case we have more than 2 Toolbars above (1 - Header, 2 - Header Action Buttons)
  addTopMargin: PropTypes.number,
  // array of tab objects: name(label), icon, number, content.
  tabs: PropTypes.arrayOf(PropTypes.object),
  // actions (not tabbed content) to put in tab bar: label, icon, action.
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.node,
      action: PropTypes.func,
    })
  ),
};

export default Tabbing;
