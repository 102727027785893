import {
  formatInTimeZone,
  zonedTimeToUtc,
  utcToZonedTime,
} from 'date-fns-tz';

// NOTE find a better way to determine user's local IANA time zone name (e.g. America/New_York, America/Loas_Angeles, etc)
export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions()?.timeZone;

/**
 * Converts an UTC ISO string into a user's local time determined by getLocalTimezone()
 *
 * @param {string} date a utc iso string
 * @param {string} timeZone optionally provide a target timeZone
 * @param {boolean} asISO a flag to parse local date as ISO string
 *
 * @returns Date
 */
export const utcToLocalTimeZone = ({ date, timeZone = getLocalTimezone(), asISO = false }) => {
  const parsedDate = utcToZonedTime(new Date(date), timeZone);

  if (asISO) {
    return formatInTimeZone(parsedDate, timeZone, 'yyyy-MM-dd HH:mm:ss');
  }

  return parsedDate;
};

/**
 * Converts local date determined by getLocalTimezone() into an UTC ISO string
 *
 * @param {string} date users local date
 * @param {string} timeZone optionally provide local timeZone
 *
 * @returns string ISO formatted date
 */
export const localTimeZoneToUtc = ({ date, timeZone = getLocalTimezone() }) =>
  zonedTimeToUtc(date, timeZone).toISOString();

/**
 * formats a numeric time duration into a hours:minutes:seconds string
 *
 * @param {number} duration a stored time duration 137000
 *
 * @returns string
 */
export const formatDuration = (duration) => {
  if (isNaN(duration)) {
    return '00:00:00';
  }

  // parse a date based on duration and split the desired hours:minutes:seconds value
  // FIXME determine the requirements for duration, implement existing code until requirements are clear
  return new Date(duration).toISOString().split('T')[1].split('.')[0];
};