import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import StandardReports from '../StandardReports';
import RewardsReports from '../RewardsReports';
import ChartFromTo from '../ChartFromTo';


const reportTypes = {
  cashflow: 'CommercialMetrics/Daily/CashflowMicrotransaction',
  contentminting: 'CommercialMetrics/Daily/ContentMinting',
  purchaseorder: 'CommercialMetrics/Daily/PurchaseOrder',
  membership: 'CustomerMetrics/Daily/Membership',
  rewards: ' ',
};

function Commercial(props) {
  const { enqueueSnackbar } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedReport = searchParams.get('report') || 'cashflow';
  const reportTypeToShowRawMetircs = ['membership'];

  const ReportCharts = useMemo(() => {
    const props = {
      selectedReport,
      reportTypes,
      enqueueSnackbar,
      useRawMetricNames: reportTypeToShowRawMetircs.includes(selectedReport),
    };
    switch (selectedReport) {
      case 'rewards':
        return <RewardsReports {...props} />;
      default:
        return <StandardReports {...props} />
    }
  }, [location.search]);

  useEffect(() => {
    if (!searchParams.has('report')) {
      searchParams.set('report', 'cashflow');
      setSearchParams(searchParams);
    }
  });

  return (
    <Grid container alignItems="center" spacing={1}>

      <Grid item xs={3}>
        <TextField
          select
          label={lookUp({ key: 'CONSOLE_MENU_COMMERCIAL' })}
          fullWidth
          value={selectedReport}
          onChange={(event) => navigate(`${location.pathname}?report=${event.target.value}`)}
        >
          {Object.keys(reportTypes).map((reportType) =>
            <MenuItem key={reportType} value={reportType}>
              {lookUp({ key: `CONSOLE_REPORTING_${reportType}` })}
            </MenuItem>
          )}
        </TextField>
      </Grid>

      <ChartFromTo />

      {ReportCharts}

    </Grid>
  );
}

export default withSnackbar(Commercial);
