import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import TableComponent from 'components/Table';
import DialogModal from 'components/DialogModal';
import AuditElement from 'components/Audit/AuditElement';
import CreatedOn from 'components/CreatedOn'
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ClearAll, FilterList } from '@material-ui/icons';
import {
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Collapse, 
} from '@material-ui/core';

import insightsService from 'services/insightsService';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  select: {
    minWidth: 200
  },
  margin: {
    marginTop: theme.spacing(2)
  },
  marginTopSmall: {
    marginTop: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  expandIcon: {
    marginLeft: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(2, 0)
  },
  searchInput: {
    height: '40px',
    width: '100%'
  }

}));

function AuditLogs(props) {
  
  const { enqueueSnackbar } = props;
  const classes = useStyles();

  const twoWeeksAgo = moment().subtract(14, 'day').format('YYYY-MM-DD');
  const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');

  const defaultToolbarValues = {
    q: '',
    username: '',
    component: '',
    action: '',
    from: twoWeeksAgo,
    to: tomorrow,
  };

  const [searchOptions, setSearchOptions] = useState(defaultToolbarValues);
  const [searchTimeout, setSearchTimeout] = useState(undefined);
  const [filterParams, setFilterParams] = useState(defaultToolbarValues);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const clearSearchOptions = () => {
    setFilterParams(defaultToolbarValues);
    return {
      searchOptionsClear: setSearchOptions,
      searchOptions: defaultToolbarValues
    };
  };

  const handleSearchOptionsChange = (key, value) => {
    setSearchOptions((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const valiDate = (dateObject) => dateObject === null || dateObject._isValid;

  const filterTimeout = (key, value) => {
      let queryParam = '';
      switch (key) {
        case 'q':
        case 'username':
          queryParam = value;
          break;
        case 'component':
          queryParam = value === 'All' ? '' : value;
          break;
        case 'action':
          queryParam = value === 'All' ? '' : value;
          break;
        case 'from':
          queryParam = value ? moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
          break;
        case 'to':
          queryParam = value ? moment(value).format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
          break;
        default:
          queryParam = value;
          break;
      };
      setFilterParams(filterParams => ({
        ...filterParams,
        [key]: queryParam
      }));
  };

  const handleFilter = (id, value) => {
    setSearchTimeout(() => clearTimeout(searchTimeout));
    const delay = (id === 'sku') ? 1000 : 500;
    const timeout = setTimeout(() => filterTimeout(id, value), delay);
    setSearchTimeout(timeout);
  };

  const customToolbar = (clearFilters, isClearFiltersActive) => (
    <Grid container alignItems={'flex-start'} justifyContent={'space-between'}>
      <Grid item md={11} lg={10} xl={9} className={classes.padding}>
        <Grid container alignItems={'center'} spacing={2}>
            <Grid item md={3} lg={3} xl={3}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_LOG_FROM' })}
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/DD/YYYY"
                autoOk
                disableFuture
                maxDate={filterParams.to}
                className={classes.textField}
                value={searchOptions.from || null}
                placeholder={lookUp({ key: 'CONSOLE_LOG_FROM_PLACEHOLDER' })}
                onChange={(date) => {
                  handleSearchOptionsChange('from', date);
                  valiDate(date) && handleFilter('from', date);
                }}
                {...(searchOptions.purchaseUntil && { maxDate: searchOptions.purchaseUntil })}
              />
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <KeyboardDatePicker
                label={lookUp({ key: 'CONSOLE_LOG_UNTIL' })}
                variant="inline"
                inputVariant="outlined"
                size="small"
                format="MM/DD/YYYY"
                autoOk
                minDate={filterParams.from}
                maxDate={tomorrow}
                className={classes.textField}
                value={searchOptions.to || null}
                placeholder={lookUp({ key: 'CONSOLE_LOG_UNTIL_PLACEHOLDER' })}
                onChange={(date) => {
                  handleSearchOptionsChange('to', date);
                  valiDate(date) && handleFilter('to', date);
                }}
                {...(searchOptions.purchaseFrom && { minDate: searchOptions.purchaseFrom })}
              />
            </Grid>
            <Grid item md={3} lg={3} xl={3}>
              <TextField
                id={`username`}
                label={lookUp({ key: 'CONSOLE_USERNAME' })}
                className={classes.textField}
                value={searchOptions.username}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => handleSearchOptionsChange('username', e.target.value)}
                onKeyUp={(e) => handleFilter('username', e.target.value)}
              />
            </Grid>
          <Grid item>
            <IconButton
              aria-label={'Additional Filters'}
              onClick={() => setIsCollapseOpen(!isCollapseOpen)}
            >
              <Tooltip title={lookUp({ key: 'CONSOLE_Additional_Filters' })}>
                <FilterList />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              aria-label={'Clear Filters'}
              onClick={clearFilters}
              disabled={!isClearFiltersActive}
            >
              <Tooltip title={lookUp({ key: 'CONSOLE_CLEAR_FILTERS' })}>
                <ClearAll />
              </Tooltip>
            </IconButton>
          </Grid>
      </Grid>

        <Collapse in={isCollapseOpen} timeout="auto" style={{width: '100%'}}>
        <Grid md={7} lg={7} xl={7} container alignItems={'center'} justifyContent={'flex-start'} spacing={3} className={classes.marginTopSmall}>
          <Grid item md={5} lg={5} xl={5}>
            <TextField
              id='auditLogs-query'
              name="auditLogs-query"
              label={lookUp({ key: 'CONSOLE_SEARCH' })}
              placeholder={lookUp({ key: 'CONSOLE_SEARCH_PLACEHOLDER' })}
              className={classes.searchInput}
              value={searchOptions.q}
              onClick={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
              onChange={(e) => handleSearchOptionsChange('q', e.target.value)}
              onKeyUp={(e) => handleFilter('q', e.target.value)}
            />
          </Grid>
          <Grid item md={4} lg={4} xl={4}>
          <FormControl className={classes.formControl} variant="outlined" size="small">
              <InputLabel id="select-outlined-label">
                {lookUp({ key: 'CONSOLE_COMPONENT' })}
              </InputLabel>
              <Select
                variant="outlined"
                labelId="select-outlined-label"
                size="small"
                value={searchOptions.component}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => {
                  handleSearchOptionsChange('component', e.target.value);
                  handleFilter('component', e.target.value);
                }}
                label={lookUp({ key: 'CONSOLE_COMPONENT' })}
                inputProps={{
                  name: 'component',
                  id: 'auditLogs-components'
                }}
              >
                {['All', 'Access', 'Content', 'Channel', 'Playout', 'Playlist', 'Venue', 'Resort',
                 'Device', 'Voucher', 'Brand', 'Schedule', 'Insight','VODM', 'Partner', 'ApiKey',
                'Sku', 'FileAsset', 'Customer', 'Artist', 'Label', 'Parameter', 'MessageTemplate',
                'Festival', 'AppModel','AuthGroup', 'Strings', 'MediaProfile'].map(
                  (item) => (
                    <MenuItem value={item} key={item}>
                      {lookUp({ key: `CONSOLE_${item}` })}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={3} lg={3} xl={3}>
            <FormControl className={classes.formControl} variant="outlined" size="small">
              <InputLabel id="select-outlined-label">
              {lookUp({ key: 'CONSOLE_ACTION' })}
              </InputLabel>
              <Select
                variant="outlined"
                labelId="select-outlined-label"
                value={searchOptions.action}
                onClick={(e) => e.stopPropagation()}
                onFocus={(e) => e.stopPropagation()}
                onChange={(e) => {
                  handleSearchOptionsChange('action', e.target.value)
                  handleFilter('action', e.target.value);
                }}
                label={lookUp({ key: 'CONSOLE_ACTION' })}
                inputProps={{
                  name: 'action',
                  id: 'auditLogs-actions'
                }}
              >
                {['All','Create', 'Update', 'Delete', 'Authenticate', 'ResetPassword', 'Authorize', 'RefreshToken'].map(
                  (item) => (
                    <MenuItem value={item} key={item}>
                      {lookUp({ key: `CONSOLE_${item}` })}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        </Collapse>
      </Grid>

    </Grid>
  );

  const theadElements = [
    {
      name: 'createdDate',
      label: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left'
    },
    {
      name: 'component',
      label: lookUp({ key: 'CONSOLE_COMPONENT' }),
      align: 'left',
    },
    {
      name: 'action',
      label: lookUp({ key: 'CONSOLE_ACTION' }),
      align: 'left',
      smallColumn: true
    },
    {
      name: 'username',
      label: lookUp({ key: 'CONSOLE_USERNAME' }),
      align: 'left'
    },
    {
      name: 'objectid',
      label: lookUp({ key: 'CONSOLE_OBJECTID' }),
      align: 'left'
    }
  ];

  const tbodyElements = [
    {
      name: 'createdDate',
      align: 'left',
      render: (item) => <CreatedOn date={item.createdDate} />
    },
    {
      name: 'component',
      align: 'left',
      render: (item) => (
        <DialogModal title={item.component} dialogTitle={lookUp({ key: 'CONSOLE_LOG_DETAIL' })} button={false}>
          { (item && item.payload && Object.keys(item.payload).length > 0) ?
            <AuditElement data={JSON.parse(item.payload)}/>
            :
            <DisplayKeyValuesInGrid item={item} /> // as a backup until all types are wired in to give a payload akin to Job and Content.
          }
       </DialogModal>
      )
    },
    {
      name: 'action',
      align: 'left',
      render: (item) => item.action
    },
    {
      name: 'username',
      align: 'left',
      render: (item) => item.username
    },
    {
      name: 'objectid',
      align: 'left',
      render: (item) => item.objectId
    }
  ];

  const onPageChange = (pageNumber) => {
    
  }

  return (
    <TableComponent
      pageTitle={lookUp({ key: 'CONSOLE_AUDIT_LOGS_HEADING' })}
      pageNumber={props.pageNumber}
      tableHeadElements={theadElements}
      tableBodyElements={tbodyElements}
      loadData={insightsService.search}
      onPageChange={onPageChange}
      customToolbar={customToolbar}
      additionalFilters={[]}
      additionalFiltersState={filterParams}
      clearAdditionalFilters={clearSearchOptions}
   />
  );
};

export default withSnackbar(AuditLogs);

const DisplayKeyValuesInGrid = ( { item } ) => {

  return (
    <Grid container fullWidth spacing={2}>
    { Object.keys(item).length > 0 &&
      Object.keys(item).map(key => 
        <Grid item xs={12} md={12} container>
        <Grid item style={{fontWeight: 900, marginRight: ".5em"}}>
          {key}:
        </Grid>
        <Grid item >
          { key === 'payload' && item.payload ?
          <Grid item style={{marginLeft: "1em"}}>
            <DisplayKeyValuesInGrid item={JSON.parse(item[key])} />
          </Grid>
          : JSON.stringify(item[key])
        }
        </Grid>
        </Grid>
        )
    }
    </Grid>
  )
}