import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Tooltip, makeStyles, useScrollTrigger } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {
  Collections,
  Devices,
  EmojiEvents as Rewards,
  Equalizer as Statistics,
  NotInterested,
  Star,
  StarOutline,
  Subscriptions,
} from '@material-ui/icons';
import { EditorInfo, Loader, Tabbing } from 'components';
import {
  create,
  getCustomer,
  getIsBanned,
  addBan,
  removeBan,
  update,
  deleteCustomer,
  getCustomerTypes,
  getProviderTypes,
  forgotPassword,
} from 'services/customersService';
import {
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
} from 'services/collectionsServiceV2';
import AlertService from 'services/alertService';
import navigationAction from 'store/actions/navigationAction';
import { getConsoleEnvName, goBackOrStay } from 'helpers/common';
import BasicTab from './Tabs/BasicTab';
import PropertiesTab from 'components/FormTabs/PropertiesTab';
import SocialTab from './Tabs/SocialTab';
import ImagesTab from 'pages/CustomerPages/Tabs/ImagesTab';
import SubscriptionsTab from './Tabs/SubscriptionsTab';
import RewardsTab from '../Engagement/Customers/RewardHistory';
import DevicesTab from '../Engagement/Customers/Devices';
import StatisticsTab from './Tabs/StatisticsTab';
import CollectionsTab from './Tabs/CollectionsTab';
import TagsTab from './Tabs/TagsTab';
import { enumToOrderedArray } from 'helpers/common';
import { validateEmail } from 'helpers/strings';
import NoAccess from 'pages/NoAccess';
import { sidebarWidth } from 'constants/index.js';
import ContentSelector from 'components/ContentSelector';
import CustomerSelector from 'components/CustomerSelector';
import HeaderActionsContainer from 'components/HeaderActionsContainer';
import SectionContainer from 'components/SectionContainer';
import DeleteButton from 'components/DeleteButton';
import ThemedButton from 'components/ThemedButton';
import SectionDrawer from 'components/SectionDrawer';
import parametersService from 'services/parametersService';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const NO_PROVIDER_TYPES = ['Creator', 'Event', 'Organization', 'Sponsor', 'Project']; // Type which not necessarily need a provider

const useStyles = makeStyles((theme) => {
  return {
    highlightIcon: {
      color: theme.palette?.secondary?.main || 'yellow',
    },
    infoPanel: {
      position: 'fixed',
      top: 55,
      zIndex: 9,
      backgroundColor: theme.palette?.background?.default || 'white',
      padding: 20,
      height: 90,
      width: (props) =>
        `calc(100% - ${props.collapsed ? sidebarWidth.closed : sidebarWidth.open}px)`,
    },
    searchSidebar: {
      position: 'fixed',
      top: (props) => (props.header ? 216 : 0),
      zIndex: 9,
      right: 0,
      width: 320,
      backgroundColor: theme.palette.info.main,
      height: '100%',
    },
  };
});

function CustomerForm({ enqueueSnackbar }) {
  const { id } = useParams();
  const location = useLocation();
  const tab = location.hash?.replace('#', '');
  const navigate = useNavigate();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const trigger = useScrollTrigger();
  const user = useSelector((state) => state.auth.user);
  const userRole = user.role;
  const ownId = user.id;
  const { editpage } = useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.customer;
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [targetType, setTargetType] = useState(null);
  const [triggers, setTriggers] = useState({});
  const [formErrors, setFormErrors] = useState({
    username: null,
    emailAddress: null,
    idpEmail: null,
    organizations: null,
  });

  const dispatch = useDispatch();

  const isSidebarCollapsed = useSelector((state) => state.navigation.isSidebarCollapsed);
  const classes = useStyles({ collapsed: isSidebarCollapsed, header: !trigger });

  const [shouldValidateOrganization, setShouldValidateOrganization] = useState(false);
  const [shouldValidateCustomer, setShouldValidateCustomer] = useState(false);
  const [shouldValidateEmail, setShouldValidateEmail] = useState(false);
  const [shouldValidateIdpEmail, setShouldValidateIdpEmail] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const [customerTypes, setCustomerTypes] = useState([]);
  const [customerOrganizations, setCustomerOrganizations] = useState([]);
  const [members, setMembers] = useState([]);
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(!!id);
  const [isSaving, setIsSaving] = useState(false);
  const [model, setModel] = useState({
    customerId: id,
    customerType: '',
    username: '',
    emailAddress: '',
    socialNetwork: '',
    socialUserId: '',
    homeRegion: '',
    registrationDate: '',
    lastSeenDate: '',
    dataPolicyAccepted: new Date().toUTCString(),
    licenseAccepted: new Date().toUTCString(),
    isBanned: false,
    properties: {},
    tags: {
      Genre: [],
      Organization: [],
      Segmentation: [],
      Role: [],
    },
    parentalControl: {
      ageLimit: 0,
      country: 'HU',
      pinCode: '0000',
    },
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    if (selectedRelation && targetType) {
      setIsDrawerOpen(true);
    } else {
      setIsDrawerOpen(false);
    }
  }, [selectedRelation, targetType]);

  const buttonGridSize = {
    xs: 6,
    sm: 3,
  };

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePropertiesChange = (property) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    const newProperties = { ...model.properties, ...property };
    handleModelChange('properties', newProperties);
  };

  let tabs = [
    {
      name: 'basic',
      buttonGridSize,
      content: (
        <SectionContainer>
          <BasicTab
            model={model}
            providers={providers}
            customerOrganizations={customerOrganizations}
            setCustomerOrganizations={setCustomerOrganizations}
            members={members}
            setMembers={setMembers}
            setSelectedRelation={setSelectedRelation}
            setTargetType={setTargetType}
            customerTypes={customerTypes}
            handleModelChange={handleModelChange}
            handlePropertiesChange={handlePropertiesChange}
            triggers={triggers}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
          />
        </SectionContainer>
      ),
    },
  ];

  const moreThanBasic = [
    {
      name: 'properties',
      buttonGridSize,
      content: (
        <SectionContainer>
          <PropertiesTab
            model={model}
            handlePropertiesChange={(properties) => handleModelChange('properties', properties)}
          />
        </SectionContainer>
      ),
    },
    {
      name: 'social',
      buttonGridSize,
      content: (
        <SectionContainer>
          <SocialTab model={model} handlePropertiesChange={handlePropertiesChange} />
        </SectionContainer>
      ),
    },
    {
      name: 'images',
      buttonGridSize,
      content: (
        <SectionContainer>
          <ImagesTab
            model={model}
            onChange={(properties) => {
              handleModelChange('properties', properties)
            }}
          />
        </SectionContainer>
      ),
    },
  ];

  const hasHistory = [
    {
      name: 'tags',
      buttonGridSize,
      content: (
        <SectionContainer>
          <TagsTab
            model={model}
            handleChange={handleModelChange}
            selectedRelation={selectedRelation}
            setSelectedRelation={setSelectedRelation}
            setTargetType={setTargetType}
            triggers={triggers}
          />
        </SectionContainer>
      ),
    },
    {
      name: 'subscriptions',
      icon: <Subscriptions />,
      buttonGridSize,
      content: (
        <SectionContainer>
          <SubscriptionsTab id={id} />
        </SectionContainer>
      ),
    },
    {
      name: 'rewards',
      icon: <Rewards />,
      buttonGridSize,
      // new okay component
      content: (
        <SectionContainer>
          <RewardsTab id={model.customerId || ''} />
        </SectionContainer>
      ),
    },
    {
      name: 'devices',
      icon: <Devices />,
      buttonGridSize,
      // old stinky component
      content: <DevicesTab id={model.customerId} enqueueSnackbar={enqueueSnackbar} />,
    },
    {
      name: 'statistics',
      icon: <Statistics />,
      buttonGridSize,
      content: (
        <SectionContainer>
          <StatisticsTab model={model} />
        </SectionContainer>
      ),
    },
    {
      name: 'collections',
      icon: <Collections />,
      buttonGridSize,
      content: <CollectionsTab model={model} />,
    },
  ];

  if (!id && getConsoleEnvName() !== 'Nexius') {
    tabs = [...tabs, ...moreThanBasic];
  } else if (id) {
    tabs = [...tabs, ...moreThanBasic, ...hasHistory];
  }

  const fetchParameters = useCallback(() => {
    parametersService
      .getByKey('Console_Allow_OrganizationValidate', true)
      .then((val) => {
        setShouldValidateOrganization(val?.value === 'true' ? true : false);
      })
      .catch(() => {
        setShouldValidateOrganization(false);
      });

    parametersService
      .getByKey('Console_Allow_CustomerValidate', true)
      .then((val) => {
        setShouldValidateCustomer(val?.value === 'true' ? true : false);
      })
      .catch(() => {
        setShouldValidateCustomer(false);
      });

    parametersService
      .getByKey('Customer_Allow_EmailValidate', true)
      .then((val) => {
        setShouldValidateEmail(val?.value === 'true' ? true : false);
      })
      .catch(() => {
        setShouldValidateEmail(false);
      });

    parametersService
      .getByKey('Customer_Allow_IdpEmailValidate', true)
      .then((val) => {
        setShouldValidateIdpEmail(val?.value === 'true' ? true : false);
      })
      .catch(() => {
        setShouldValidateIdpEmail(false);
      });
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await getCustomer(id);
      const isBanned = await getIsBanned(id);
      if (data.isDeleted) throw new Error(lookUp({ key: 'CONSOLE_Customer_deleted_MESSAGE' }));
      data.properties = { ...model.properties, ...data.properties };
      data.tags = { ...model.tags, ...data.tags };
      data.parentalControl = { ...model.parentalControl, ...data.parentalControl };

      fetchParameters()

      setModel({ ...model, ...data, isBanned });
      return data.username;
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CUSTOMER' }),
        }),
      });
      navigate('../../');
    } finally {
      setIsLoading(false);
    }
  };

  const getTypeEnum = async () => {
    try {
      const data = await getCustomerTypes();
      setCustomerTypes(enumToOrderedArray(data));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_CUSTOMER_ENUMS' }),
        }),
      });
    }
  };

  const getProviderEnum = async () => {
    try {
      const data = await getProviderTypes();
      setProviders(data.filter((e) => !['None', 'Logical'].includes(e)));
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
          type: lookUp({ key: 'CONSOLE_PROVIDER_ENUMS' }),
        }),
      });
    }
  };

  const validateModel = (model) => {
    const errors = [];
    const formErrors = {
      customerType: null,
      username: null,
      emailAddress: null,
      idpEmail: null,
      organizations: null,
    }

    if(!shouldValidateCustomer){
      setFormErrors({...formErrors})
      return errors;
    }

    if (!model.customerType) {
      const errorMessage = lookUp({ key: 'CONSOLE_NO_SELECTED_CUSTOMERTYPE' });
      errors.push(errorMessage);
      formErrors.customerType = errorMessage;
    }
    if (!(getConsoleEnvName() === 'Nexius' && model.customerType === 'Organization')) {
      if (shouldValidateEmail && !validateEmail(model.emailAddress)) {
        const errorMessage = lookUp({ key: 'CONSOLE_INVALID_EMAIL_BASIC_TAB' });
        errors.push(errorMessage);
        formErrors.emailAddress = errorMessage;
      }
    }
    if (model.username.length < 3) {
      const errorMessage = lookUp({ key: 'CONSOLE_INVALID_USERNAME_BASIC_TAB' });
      errors.push(errorMessage);
      formErrors.username = errorMessage;
    }
    if (!NO_PROVIDER_TYPES.includes(model.customerType)) {
      if (
        !Object.entries(model.properties).filter(
          (e) => e[0].indexOf('Custom:SocialIds:') > -1 && e[1]
        ).length
      ) {
        errors.push(lookUp({ key: 'CONSOLE_SET_ONE_PROVIDER' }));
      }
    }
    if (shouldValidateIdpEmail && !(getConsoleEnvName() === 'Nexius' && (model.customerType === 'Admin' || model.customerType === 'Organization'))) {
      if (NO_PROVIDER_TYPES.includes(model.customerType) && !(shouldValidateEmail && validateEmail(model.properties['Custom:SocialIds:Password']))) {
        const errorMessage = lookUp({ key: 'CONSOLE_PROVIDERS_EMAIL_INVALID' });
        errors.push(errorMessage);
        formErrors.idpEmail = errorMessage; 
      }
    }
    if (model.customerType !== "Organization" && shouldValidateOrganization && !customerOrganizations.length && model.customerType !== 'SysAdmin') {
      const errorMessage = lookUp({ key: 'CONSOLE_MISSING_ORGANIZATIONS' });
      errors.push(errorMessage);
      formErrors.organizations = errorMessage;
    }
    setFormErrors(formErrors);
    return errors;
  };

  const sanitizeModel = (model) => {
    const sanitizedModel = { ...model };
    if (!NO_PROVIDER_TYPES.includes(model.customerType)) {
      if (!sanitizedModel.properties['Custom:SocialIds:Password'])
        sanitizedModel.properties['Custom:SocialIds:Password'] = model.emailAddress;
      if (!sanitizedModel.emailAddress)
        sanitizedModel.emailAddress = model.properties['Custom:SocialIds:Password'];
      setModel(sanitizedModel);
    }
    return sanitizedModel;
  };

  const trimModel = (model) => ({
    ...model,
    properties: Object.entries(model.properties)
      ?.filter((e) => e[1] !== '')
      .reduce((newObj, keyvalue) => {
        newObj[keyvalue[0]] = keyvalue[1];
        return newObj;
      }, {}),
  });

  const relateOnCreate = async (userId) => {
    const relationRequests = customerOrganizations.map((orgId) =>
      addCustomerRelation(userId, 'Organization', orgId)
    );
    if (isFavourite) relationRequests.push(addToFavourites);
    return await Promise.allSettled(relationRequests);
  };


  const createData = () => {
    const validationErrors = validateModel(model);
    if (validationErrors?.length > 0) {
      validationErrors.forEach((errorMessage) => {
        if (errorMessage.length) {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: errorMessage
          })
        }
      });
      return;
    }
    create(trimModel(model))
      .then((response) => relateOnCreate(response.data?.customerId))
      .then((r) => {
        if (getConsoleEnvName() === 'Nexius') {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_NEW_CUSTOMER_CREATED_TEMPLATE',
              type:
                model.username ||
                (model.customerType
                  ? lookUp({ key: `CONSOLE_${model.customerType}` })
                  : lookUp({ key: 'CONSOLE_CUSTOMER' })),
            }),
          });
        } else {
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: lookUp({
              key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE',
              type:
                model.username ||
                (model.customerType
                  ? lookUp({ key: `CONSOLE_${model.customerType}` })
                  : lookUp({ key: 'CONSOLE_CUSTOMER' })),
            }),
          });
        }
        dispatch(navigationAction.allowNavigation());
        const path = location.pathname.includes('entertainment')
          ? '/entertainment/customer'
          : '/access/customer';
        setTimeout(() => navigate(`${path}?type=${model.customerType}`), 250);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${model.customerType}` }),
          }),
        });
      });
  };

  const saveData = () => {
    const validationErrors = validateModel(model);
    if (validationErrors?.length > 0) {
      validationErrors.forEach((errorMessage) => {
        if (errorMessage.length) {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: errorMessage
          })
        }
      });
      return;
    }

    update(id, trimModel(model))
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({
            key: 'CONSOLE_SAVE_SUCCESS_TEMPLATE',
            type:
              model.username ||
              (model.customerType
                ? lookUp({ key: `CONSOLE_${model.customerType}` })
                : lookUp({ key: 'CONSOLE_CUSTOMER' })),
          }),
        });
        dispatch(navigationAction.allowNavigation());
        const path = location.pathname.includes('entertainment')
          ? '/entertainment/customer'
          : '/access/customer';
        const toWhere = window.history.state.idx === 0 ? `${path}?type=${model.customerType}` : -1;
        goBackOrStay(navigate, toWhere);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_SAVE_ERROR_MESSAGE_TEMPLATE',
            type: lookUp({ key: `CONSOLE_${model.customerType}` }),
          }),
        });
      })
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    getTypeEnum();
    getProviderEnum();
    let pageTitle;
    if (id) {
      getData().then((title) => {
        pageTitle = lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: title ?? id });
        dispatch(navigationAction.setPageTitle(pageTitle));
      });
    } else {
      fetchParameters()
      const type = new URLSearchParams(window.location.search).get('type');
      pageTitle = lookUp({
        key: 'CONSOLE_CREATE_TEMPLATE',
        type: type ? lookUp({ key: `CONSOLE_${type}` }) : lookUp({ key: 'CONSOLE_CUSTOMER' }),
      });
      dispatch(navigationAction.setPageTitle(pageTitle));
    }
    return () => dispatch(navigationAction.setPageTitle(''));
  }, [id]);

  const resetPassword = async () => {
    setIsResetting(true);

    forgotPassword(model.properties['Custom:SocialIds:Password'] || model.emailAddress)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_EMAIL_SENT_MESSAGE' }),
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      }).finally(() => {
        setIsResetting(false);
      });
  };

  const addToFavourites = () =>
    addCustomerRelation(ownId, 'FavCustomer', model.customerId)
      .then(() => setIsFavourite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });

  const removeFromFavourite = () => {
    deleteCustomerRelation(ownId, 'FavCustomer', model.customerId)
      .then(() => setIsFavourite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    if (!id) {
      setIsFavourite((o) => !o);
      return;
    }
    switch (isFavourite) {
      case true:
        removeFromFavourite(model.customerId);
        break;
      case false:
        addToFavourites(model.customerId);
        break;
      default:
        break;
    }
  };

  const switchBan = (id) => (model.isBanned ? removeBan(id) : addBan(id));

  useEffect(() => {
    if (!id) {
      setModel({ ...model });
      return;
    }
    getCustomerRelation(ownId, 'FavCustomer', id)
      .then(() => setIsFavourite(true))
      .catch(() => setIsFavourite(false));
  }, []);

  useEffect(() => {
    setSelectedRelation(null);
    setTargetType(null);
  }, [tab]);

  const removeCustomer = async () => {
    setIsDeleting(true);

    deleteCustomer(id)
      .then(() => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        const path = location.pathname.includes('entertainment')
          ? '/entertainment/customer'
          : '/access/customer';
        setTimeout(() => navigate(`${path}?type=${model.customerType}`), 250);
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_CUSTOMER_DELETED_MESSAGE' }),
        });
        // return forceReindex();
      })
      .catch((error) => {
        isNavigationBlocked && dispatch(navigationAction.allowNavigation());
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR' }),
        });
      }).finally(() => {
        setIsDeleting(false);
      });
  };

  const buttons = {
    // https://blueguava.atlassian.net/browse/NEX-1329?focusedCommentId=99009
    // ban: model?.customerType !== 'Customer'
    ban: true
      ? {}
      : {
          label: lookUp({ key: model.isBanned ? 'CONSOLE_UNBLOCK_USER' : 'CONSOLE_BLOCK_USER' }),
          action: () => {
            switchBan(id)
              .then(() => {
                AlertService.displayWarning({
                  msgBar: enqueueSnackbar,
                  message: model.isBanned
                    ? lookUp({ key: 'CONSOLE_BLOCK_USER_OFF_MESSAGE' })
                    : lookUp({ key: 'CONSOLE_BLOCK_USER_ON_MESSAGE' }),
                });
                handleModelChange('isBanned', !model.isBanned);
              })
              .catch((error) => {
                AlertService.displayError({
                  msgBar: enqueueSnackbar,
                  error,
                  context: `Can't change discussion ban: `,
                });
              });
          },
          icon: (
            <Tooltip
              title={lookUp({
                key: model.isBanned
                  ? 'CONSOLE_UNBLOCK_USER_HELPERTEXT'
                  : 'CONSOLE_BLOCK_USER_HELPERTEXT',
              })}
            >
              <NotInterested style={{ color: model.isBanned ? '#a52a2a' : '#ffffff' }} />
            </Tooltip>
          ),
        },
    favourite: !allowedTabs?.includes('favorite')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_FAVOURITE' }),
          action: setFavourite,
          icon: isFavourite ? (
            <Tooltip title={lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' })}>
              <Star className={classes.highlightIcon} />
            </Tooltip>
          ) : (
            <Tooltip title={lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' })}>
              <StarOutline />
            </Tooltip>
          ),
        },
  };

  useEffect(() => {
    setTriggers({ Members: !triggers?.Members });
  }, [members]);

  const handleDrawerClose = () => {
    setSelectedRelation(null);
    setTargetType(null);
  };

  useEffect(() => {
    const type = new URLSearchParams(window.location.search).get('type');
    if (!id) {
      setModel(() => ({
        customerId: id,
        customerType: type || '',
        username: '',
        emailAddress: '',
        socialNetwork: '',
        socialUserId: '',
        homeRegion: '',
        registrationDate: '',
        lastSeenDate: '',
        dataPolicyAccepted: new Date().toUTCString(),
        licenseAccepted: new Date().toUTCString(),
        isBanned: false,
        properties: {},
        tags: {
          Genre: [],
          Organization: [],
          Segmentation: [],
          Role: [],
        },
        parentalControl: {
          ageLimit: 0,
          country: 'HU',
          pinCode: '0000',
        },
      }));
      setIsFavourite(false);
      setCustomerOrganizations([]);
      setMembers([]);
    }
  }, [window.location.pathname, window.location.search]);

  const isEditting = Boolean(id);
  const toShowCancelButton = !(getConsoleEnvName() === 'Nexius' && id);

  const displaySaveButtonName = () => {
    if (getConsoleEnvName() === 'Nexius') {
      return lookUp({ key: isEditting ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_CUSTOMER_BUTTON' });
    } else {
      return lookUp({ key: isEditting ? 'CONSOLE_SAVE_BUTTON' : 'CONSOLE_CREATE_BUTTON' }) 
    }
  };

  return isLoading ? (
    <Loader />
  ) : id !== ownId && !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <Box flex={1}>
      <HeaderActionsContainer>
        {isEditting && (
          <>
            <DeleteButton onDelete={removeCustomer} loading={isDeleting} disabled={isDeleting} />
            <Tooltip title={lookUp({ key: 'CONSOLE_RESET_PASSWORD_HELPERTEXT' })}>
              <ThemedButton
                disabled={isSaving || isResetting}
                loading={isResetting}
                onClick={resetPassword}
              >
                {lookUp({ key: 'CONSOLE_RESET_PASSWORD_BUTTON' })}
              </ThemedButton>
            </Tooltip>
          </>
        )}
        <Box flex={1} />
        {
          /* `Cancel` action Button */
          toShowCancelButton && (
            <ThemedButton
              onClick={(e) => {
                e.preventDefault();
                dispatch(navigationAction.allowNavigation());
                window.history?.state?.idx === 0
                  ? navigate('/access/customer')
                  : navigate(window.history?.state?.idx === 0 ? '/' : -1);
              }}
              disabled={isSaving || isResetting}
            >
              {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
            </ThemedButton>
          )
        }

        {/* `Save` action Button */}
        <ThemedButton
          color="success"
          onClick={(e) => {
            e.preventDefault();
            isEditting ? saveData() : createData();
          }}
          disabled={isSaving || isResetting}
          loading={isSaving}
        >
          {displaySaveButtonName()}
        </ThemedButton>
      </HeaderActionsContainer>
      <Tabbing
        tabs={
          id === ownId
            ? tabs
            : tabs
                .sort(
                  (a, b) =>
                    allowedTabs.indexOf(a.name?.replace(' ', '-')) -
                    allowedTabs.indexOf(b.name?.replace(' ', '-')),
                )
                .filter((e) => allowedTabs.includes(e.name))
        }
        buttons={[buttons.ban, buttons.favourite]}
        addTopMargin={HEADER_MIN_HEIGHT}
        scrollButtons="on"
      />
      {/* FIXME implement drawer */}
      <SectionDrawer
        open={isDrawerOpen}
        // onClose={handleDrawerClose}
      >
        {targetType === 'Content' ? (
          <ContentSelector
            setSelectedRelation={setSelectedRelation}
            selectedRelation={selectedRelation}
            targetType={targetType}
            onClose={handleDrawerClose}
            onSelect={(e) => {
              addCustomerRelation(model.customerId, selectedRelation, e).then(() =>
                setTriggers((o) => ({ ...o, [selectedRelation]: !o[selectedRelation] })),
              );
            }}
          />
        ) : (
          <CustomerSelector
            setSelectedRelation={setSelectedRelation}
            selectedRelation={selectedRelation}
            targetType={targetType}
            onClose={handleDrawerClose}
            onSelect={
              id
                ? selectedRelation === 'Members'
                  ? (e) =>
                      addCustomerRelation(e, 'Organization', model.customerId).then(() =>
                        setTriggers((o) => ({ ...o, Members: !o['Members'] })),
                      )
                  : (e) =>
                      addCustomerRelation(model.customerId, selectedRelation, e).then(() =>
                        setTriggers((o) => ({ ...o, [selectedRelation]: !o[selectedRelation] })),
                      )
                : (e) => setCustomerOrganizations((prev) => [...new Set([...prev, e])])
            }
          />
        )}
      </SectionDrawer>
      {isEditting && (
        <EditorInfo
          createdAt={model.createdDate}
          subscriberSince={model.licenseAccepted}
          lastSeenDate={model.lastLogin}
          brandId={'brand'}
          modifiedAt={model.lastModifiedDate}
        />
      )}
    </Box>
  );
}

CustomerForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(CustomerForm);
