import React, { useState, useEffect, useRef } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  makeStyles,
  Link,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { green, red } from '@material-ui/core/colors';
import { Apple, FilterList } from '@material-ui/icons';
import {
  GridColumnMenuContainer,
} from '@material-ui/data-grid';
import {
  CreatedOn,
  Listing,
} from 'components';
import { search } from 'services/subscriptionsService';
import skuService from 'services/skuService';

import AlertService from 'services/alertService';


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  enabled: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1),
    backgroundColor: green[500]
  },
  notEnabled: {
    width: 30,
    height: 30,
    marginLeft: theme.spacing(1),
    backgroundColor: red[500]
  },
  margin: {
    marginTop: theme.spacing(2)
  },
  marginTopSmall: {
    marginTop: theme.spacing(1)
  },
  textField: {
    width: '100%'
  },
  expandIcon: {
    marginLeft: theme.spacing(2)
  },
  padding: {
    padding: theme.spacing(2, 0)
  },
  statusActiveRow: {
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[200] + ' !important'
    }
  },
  statusExpiredRow: {
    backgroundColor: red[100],
    '&:hover': {
      backgroundColor: red[200] + ' !important'
    }
  }
}));

function Subscriptions(props) {

  const {
    enqueueSnackbar,
  } = props;

  const classes = useStyles();
  const navigate = useNavigate();

  const [productOptions, setProductOptions] = useState({});
  const [storeTypeOptions, setStoreTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [isProductOptionsLoading, setIsProductOptionsLoading] = useState(true);
  const [isStoreTypeOptionsLoading, setIsStoreTypeOptionsLoading] = useState(true);
  const [isStatusOptionsLoading, setIsStatusOptionsLoading] = useState(true);

  const isFirstRun = useRef(true);

  const params = new URLSearchParams(location.search);

  const [filterParams, setFilterParams] = useState({
    productId: params.get('productId') || '',
    store: params.get('store') || '',
    status: params.get('status') || '',
    pFrom: params.get('pFrom') || '',
    pUntil: params.get('pUntil') || '',
  });

  useEffect(() => {
    const getStoreTypes = () => {
      skuService.getStoreTypes()
        .then((storeTypes) => {
          setStoreTypeOptions(() => {
            const skuTypes = storeTypes.map(type => {
              let label = type;
              switch (type) {
                case 'Apple':
                  label = lookUp({ key: 'CONSOLE_APPLE_ITUNES' });
                  break;
                case 'Google':
                  label = lookUp({ key: 'CONSOLE_GOOGLE_PLAY_STORE' });
                  break;
              };
              return { label, value: type }
            });
            return skuTypes;
          });
          setIsStoreTypeOptionsLoading(false);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE' , type: lookUp({ key: 'CONSOLE_STORE'}) })
          });
        });
    };
    getStoreTypes();
  }, []);

  useEffect(() => {
    const getProducts = () => {
      skuService.search()
        .then((products) => {
          const productsByType = products.data.reduce((obj, e) => 
            {
              obj[e.type] = 
                obj[e.type] 
                ? [...obj[e.type], {label: `${e.store} - ${e.name}`, value: e.productId}] 
                : [{label: `${e.store} - ${e.name}`, value: e.productId}];
              return obj;
          }, {});
          setProductOptions(productsByType);
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: `CONSOLE_${`Products`}` }) })
          });
        })
        .finally(() => setIsProductOptionsLoading(false));
    };
    getProducts();
    isFirstRun.current = false;
  }, []);

  useEffect(() => {
    const getStatuses = () => {
      setStatusOptions(() => ['NoSubscription', 'Trial', 'Active', 'Expired', 'Cancelled']
          .map(option => ({ label: option, value: option })
      ))
    };
    getStatuses();
    setIsStatusOptionsLoading(false);
  }, []);

  const paramsString = JSON.stringify(filterParams)

  useEffect(() => {
    if (isFirstRun.current) return;
    const theURL = new URLSearchParams(location.search);
    Object.entries(filterParams).forEach(e => { theURL.set(...e); if (!e[1]) theURL.delete(e[0])});
    navigate({ pathname: location.pathname, search: theURL.toString()})
  }, [paramsString]);

  const clearSearchOptions = () => {
    setFilterParams({
      productId: '',
      store: '',
      status: '',
      pFrom: '',
      pUntil: ''
    });
  };

  const networkIcon = (network) => {
    switch (network) {
      case 'Apple':
        return <Apple />;
      case 'Google':
        return <FontAwesomeIcon icon={['fab', 'google']} />;
      default:
        return '';
    }
  };

  const DateMenu = () => {
    return (
      <GridColumnMenuContainer onClick={(event) => event.stopPropagation()}>
        <Typography variant="button">From</Typography>
        <input style={{maxWidth: 230}} type="datetime-local" value={filterParams.pFrom} onChange={e => setFilterParams(f => ({...f, pFrom: e.target.value}))}/>
        <Typography variant="button">Until</Typography>
        <input style={{maxWidth: 230}} type="datetime-local" value={filterParams.pUntil} onChange={e => setFilterParams(f => ({...f, pUntil: e.target.value}))}/>
      </GridColumnMenuContainer>
    )
  }

  const columns = [
    {
      field: 'store',
      headerName: ' ',
      align: 'left',
      width: 60,
      disableColumnMenu: true,
      renderCell: (item) => (
        <Tooltip title={item.value}>
          <Avatar>{networkIcon(item.value)}</Avatar>
        </Tooltip>
      )
    },
    {
      field: 'customerId',
      headerName: lookUp({ key: 'CONSOLE_CUSTOMER_NUMBER' }),
      align: 'left',
      disableColumnMenu: true,
      flex: 3,
      renderCell: (item) => (
        <Link
          to={`/engagement/subscriptions/${item.value}/details`}
          component={RouterLink}
        >
          {item.value}
        </Link>
      )
    },
    {
      field: 'productId',
      headerName: lookUp({ key: 'CONSOLE_SKU' }),
      align: 'left',
      disableColumnMenu: true,
      flex: 2,
      renderCell: (item) =>
        item.row.sku ? (
          item.row.sku.skuId ? (
            <Link
              to={`/configuration/skus/${item.row.sku.skuId}/edit`}
              component={RouterLink}
            >
              {item.value}
            </Link>
          ) : (
            <p>{item.value}</p>
          )
        ) : (
          item.value || '-'
        )
    },
    {
      field: 'productName',
      headerName: lookUp({ key: 'CONSOLE_SKU_NAME' }),
      align: 'left',
      disableColumnMenu: true,
      width: 140,
      renderCell: (item) =>
        item.row.sku ? (
          item.row.sku.skuId ? (
            <Link
              to={`/configuration/skus/${item.row.sku.skuId}/edit`}
              component={RouterLink}
            >
              {item.value}
            </Link>
          ) : (
            <p>{item.value}</p>
          )
        ) : (
          item.productName || '-'
        )
    },
    {
      field: 'purchaseDate',
      headerName: lookUp({ key: 'CONSOLE_PURCHASEDATE' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
      renderHeader: () => 
        <>
        {lookUp({ key: 'CONSOLE_PURCHASEDATE' })}
        {(filterParams.pFrom || filterParams.pUntil) && <FilterList/>}
        </>
    },
    {
      field: 'expiresDate',
      headerName: lookUp({ key: 'CONSOLE_EXPIRESDATE' }),
      align: 'left',
      disableColumnMenu: true,
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />
    },
    {
      field: 'status',
      headerName: lookUp({ key: 'CONSOLE_STATUS' }),
      align: 'left',
      disableColumnMenu: true,
      renderCell: (item) => item.value
    }
  ];

  const productId = {
    label: lookUp({ key: 'CONSOLE_SKUS' }),
    key: 'productId',
    options: productOptions,
    change: (value) => setFilterParams(f => ({...f, productId: value})),
    loading: isProductOptionsLoading,
  };

  const store = {
    label: lookUp({ key: 'CONSOLE_STORES' }),
    key: 'store',
    options: storeTypeOptions,
    change: (value) => setFilterParams(f => ({...f, store: value})),
    loading: isStoreTypeOptionsLoading,
  };

  const status = {
    label: lookUp({ key: 'CONSOLE_STATUS' }),
    key: 'status',
    options: statusOptions,
    change: (value) => setFilterParams(f => ({...f, status: value}) ),
    loading: isStatusOptionsLoading,
  };

  const rowColor = (status) => {
    switch (status) {
      case 'Active':
        return classes.statusActiveRow;
      case 'Expired':
        return classes.statusExpiredRow;
      default:
        return null;
    }
  };

  return (
    <>
      <Listing
        multiSort
        tableHeadElements={columns}
        loadData={search}
        defaultPageSize={50}
        defaultSorting={[{field: "purchaseDate", sort: "desc"}]}
        noSearchBox
        dropdownFilters={[productId, store, status]}
        // dateFilters={[rFrom, ]} // Kept as example
        additionalFiltersState={filterParams}
        resetFilters={clearSearchOptions}
        dataGridProps={{ 
          density: 'standard', 
          disableDensitySelector: true, 
          disableColumnMenu: false, 
          getRowClassName: (params) => rowColor(params.row.status),
        }}
        extraDataGridComponents={{
          ColumnMenu: DateMenu,
        }}
      />
    </>
  );
};

export default withSnackbar(Subscriptions);
