import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import Dialog from '@material-ui/core/Dialog';
import { styled } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Button,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { addReward } from 'services/rewardsService';
import AlertService from 'services/alertService';
import { withSnackbar } from 'notistack';
import customersService from 'services/customersService';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SaveButton = styled(Button)({
  background: '#00897b',
  color: 'white',
});
const CancelButton = styled(Button)({
  background: '#f57f17',
  color: 'white',
});

function AddRewardModal({ id, enqueueSnackbar, getData }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [projects, setProjects] = useState([]);

  const [form, setForm] = useState({
    internalPrice: 0,
    tokenPrice: 0,
    fromCustomerId: null,
    friendlyName: ""
  });

  useEffect(() => {
    customersService
      .searchQuery({ type: 'Project' }, 25, 0)
      .then((response) => {
        const projects = response?.data?.map((project) => ({ id: project.customerId, name: project.username }));
        setProjects(projects);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleWithdraw = () => {
    if(!form.fromCustomerId) {
      return  AlertService.displayError({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_PROJECT_REQUIRED_ERROR', title: 'Reward' }),
      });
    }
    addReward(id, form.internalPrice, form.tokenPrice, form.fromCustomerId.id, form.friendlyName)
      .then(() => {
        getData();
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_WITHDRAW_REWARD_SUCCESS', title: 'Reward' }),
        });
        handleClose();
      })
      .catch(() => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_WITHDRAW_REWARD_ERROR', title: 'Reward' }),
        });
      });
  };

  return (
    <>
      <Button color="primary" onClick={handleClickOpen}>
        {lookUp({ key: 'CONSOLE_GIFT_REWARD' })}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Typography variant={'subtitle1'} className={classes.marginTop} gutterBottom>
            {lookUp({ key: 'CONSOLE_GIFT_REWARD_TITLE' })}
          </Typography>
          <Divider className={classes.marginX} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                options={projects}
                value={form.fromCustomerId}
                name="fromCustomerId"
                getOptionLabel={(option) => option.name}
                aria-describedby="fromCustomerId-helper"
                onChange={(e, value) => setForm({...form, fromCustomerId: value})}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label={lookUp({ key: 'CONSOLE_PROJECT' })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_FRIENDLY_NAME' })}
                fullWidth
                value={form.friendlyName || ''}
                onChange={(e) => setForm({ ...form, friendlyName: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_INTERNAL_PRICE' })}
                fullWidth
                type="number"
                value={form.internalPrice || ''}
                onChange={(e) => setForm({ ...form, internalPrice: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_TOKEN_PRICE' })}
                fullWidth
                type="number"
                value={form.tokenPrice || ''}
                onChange={(e) => setForm({ ...form, tokenPrice: e.target.value })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider className={classes.marginX} />
        <DialogActions>
          <CancelButton onClick={handleClose} color="primary">
            {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
          </CancelButton>
          <SaveButton color="primary" onClick={handleWithdraw}>
            {lookUp({ key: 'CONSOLE_WITHDRAW_BUTTON' })}
          </SaveButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withSnackbar(AddRewardModal);
