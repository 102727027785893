import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { Link as RouterLink } from 'react-router-dom';
import { Typography, Link } from '@material-ui/core';
import { string } from 'prop-types';
import { dateFormatter } from 'helpers/time';
import { getCustomer } from 'services/customersService';
import Box from '@material-ui/core/Box';

const EditorInfo = ({
  createdAt,
  modifiedAt,
  modifiedBy,
  lastSeenDate,
  subscriberSince
}) => {
  const [editorName, setEditorName] = useState('');

  useEffect(() => {
    if (modifiedBy) {
      const getEditorName = () => {
        getCustomer(modifiedBy).then((customer) => setEditorName(customer.username || modifiedBy));
      };
      getEditorName();
    };
  }, []);

  return (
    <Box flex={1} sx={{ mt: 3 }}>
      {modifiedAt && (
        <Typography variant="caption" component="p" gutterBottom>
          {lookUp({ key: 'CONSOLE_LAST_MODIFIED_AT_TEMPLATE', date: dateFormatter(modifiedAt) })}
        </Typography>
      )}
      {modifiedBy && (
        <Typography variant="caption" component="p" gutterBottom>
          {lookUp({ key: 'CONSOLE_LAST_MODIFIED_BY' })}:&nbsp;
          <Link component={RouterLink} to={`/access/${modifiedBy}/edit`}>
            {editorName || modifiedBy}
          </Link>
        </Typography>
      )}
      {createdAt && (
        <Typography variant="caption">
          {lookUp({ key: 'CONSOLE_CREATED_AT_TEMPLATE', date: dateFormatter(createdAt) })}
        </Typography>
      )}
      {subscriberSince && (
        <Typography variant="caption" component="p" gutterBottom>
          {lookUp({
            key: 'CONSOLE_SUBSCRIBER_SINCE_TEMPLATE',
            date: dateFormatter(subscriberSince),
          })}
        </Typography>
      )}
      {lastSeenDate && (
        <Typography variant="caption" component="p" gutterBottom>
          {lookUp({ key: 'CONSOLE_LAST_SEEN_AT_TEMPLATE', date: dateFormatter(lastSeenDate) })}
        </Typography>
      )}
    </Box>
  );
};

EditorInfo.propTypes = {
  createdAt: string,
  modifiedAt: string,
};

export default EditorInfo;
