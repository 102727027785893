import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const DropdownWithSearch = ({
  getOptions,
  params = { query: 'q', id: 'id', label: 'id' },
  label,
  onSelect,
  textFieldProps = {},
}) => {
  const [search, setSearch] = useState();
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();

  const newOptions = () =>
    getOptions({ [params.query]: search }).then((res) => {
      const labeled = res.data?.map((e) => ({ id: e[params.id], label: e[params.label] }));
      setOptions(labeled);
    });

  useEffect(() => {
    search?.length > 2 && newOptions();
  }, [search]);

  const handleSearchChange = (input) => {
    setSearch(input);
  };

  return (
    <Autocomplete
      disablePortal
      selectOnFocus
      handleHomeEndKeys
      options={options || []}
      value={selected}
      onChange={(event, selection) => onSelect(selection)}
      inputValue={search}
      noOptionsText={lookUp({ key: 'CONSOLE_SEARCH_CUSTOMER_NAME_HELPERTEXT' })}
      onInputChange={(e, searchInput) => handleSearchChange(searchInput)}
      getOptionSelected={() => options?.find((e) => e.value === selected)}
      getOptionLabel={(option) => option?.label || ''}
      renderInput={(params) => <TextField {...params} label={label} {...textFieldProps} />}
    />
  );
};

export default DropdownWithSearch;
