import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { useState } from 'react';
import { lookUp } from 'services/stringService';

const LIVESTREAM_BROADCAST = 'Broadcast';
const LIVESTREAM_INTERACTIVE = 'Interactive';
const LIVESTREAM_SCREENSHARE = 'Screenshare';
const LIVESTREAM_TYPE_OPTIONS = [
  LIVESTREAM_BROADCAST,
  LIVESTREAM_SCREENSHARE,
  LIVESTREAM_INTERACTIVE,
];

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  schedule: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  createButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

function CreateLiveStreamModal({ locale, submit, close }) {
  const classes = useStyles();

  const [originalTitle, setOriginalTitle] = useState('');
  const [originalDescription, setOriginalDescription] = useState('');
  const [type, setType] = useState('');
  const [availableFrom, setAvailableFrom] = useState(moment().add(1, 'month'));

  const handleSubmit = () => {
    const payload = {
      localizations: {
        [locale.lang]: {
          shortInfo: originalDescription,
          name: originalTitle,
        },
      },
      publishingRule: 'intime',
      notification: 'followed',
      originalTitle,
      visibility: 'public',
      allowChat: true,
      originalLanguage: locale.lang,
      exhibitionWindow: {
        '--': {
          advisoryCodes: [],
          ageLimit: 99,
          availableFrom: availableFrom.toISOString(),
          availableUntil: availableFrom.add(3, 'hours').toISOString(),
        },
      },
      type: 'LiveStream',
    };

    submit(payload);
  };

  return (
    <Dialog open maxWidth={'md'} fullWidth onClose={close}>
      <DialogTitle>
        {lookUp({ key: 'CONSOLE_CREATE_LIVE_STREAM' })}
        <IconButton aria-label="close" className={classes.closeButton} onClick={close}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          type="text"
          id="originalTitle"
          label={lookUp({ key: 'CONSOLE_LIVE_STREAM_TITLE' })}
          className={classes.title}
          value={originalTitle}
          onChange={(e) => setOriginalTitle(e.target.value)}
          required
        />
        <TextField
          multiline
          fullWidth
          minRows={5}
          type="textarea"
          id="description"
          label={lookUp({ key: 'CONSOLE_LIVE_STREAM_DESCRIPTION' })}
          className={classes.description}
          value={originalDescription}
          onChange={(e) => setOriginalDescription(e.target.value)}
          required
        />
        <KeyboardDateTimePicker
          name="startFrom"
          label={lookUp({ key: 'CONSOLE_LIVE_STREAM_SCHEDULE' })}
          variant="dialog"
          inputVariant="outlined"
          size="small"
          className={classes.schedule}
          value={availableFrom}
          placeholder={lookUp({ key: 'CONSOLE_LIVE_STREAM_SCHEDULE_HELPERTEXT' })}
          ampm={false}
          autoOk={false}
          onChange={(date, value) => {
            if (date === null || value === null) return;
            if (date._isValid) {
              setAvailableFrom(date);
            }
          }}
          format="DD/MM/YYYY HH:mm"
          disablePast
          required
        />
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel id="liveStreamType">{lookUp({ key: 'CONSOLE_LIVE_STREAM_TYPE' })}</InputLabel>
          <Select
            size="small"
            labelId="liveStreamType"
            label={lookUp({ key: 'CONSOLE_LIVE_STREAM_TYPE' })}
            value={type}
            onChange={(e) => {
              e.preventDefault();
              setType(e.target.value);
            }}
            required
          >
            {LIVESTREAM_TYPE_OPTIONS.map?.((type = '') => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button className={classes.createButton} color="primary" fullWidth onClick={handleSubmit}>
          {lookUp({ key: 'CONSOLE_ADD_LIVE_STREAM' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withSnackbar(CreateLiveStreamModal);
