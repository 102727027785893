import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { withSnackbar } from 'notistack';
import { FixedSizeList as List } from 'react-window';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Crop169Icon from '@material-ui/icons/Crop169';
import CropPortraitIcon from '@material-ui/icons/CropPortrait';
import CropSquareIcon from '@material-ui/icons/CropSquare';
import ImageCropUpload from 'components/ImageCropUpload';
import ImageCroppingModal from 'components/ImageCropping/CroppingModal';
import contentService from 'services/contentService';
import { lookUp } from 'services/stringService';
import { processedImageUrl } from 'services/imageProcessingService';
import SectionContainer from 'components/SectionContainer';

const TIME_DIVIDER = ':';
const LAYOUT_TYPE_LANDSCAPE = 'Landscape';
const LAYOUT_TYPE_PORTRAIT = 'Portrait';
const LAYOUT_TYPE_SQUARE = 'Square';
const LAYOUT_TYPE_THUMBNAILS = 'ScrubbingImages';

const IMAGE_RATIOS = {
  [LAYOUT_TYPE_LANDSCAPE]: { aspectRatio: 16 / 9, width: 1280, height: 720 },
  [LAYOUT_TYPE_PORTRAIT]: { aspectRatio: 2 / 3, width: 800, height: 1200 },
  [LAYOUT_TYPE_SQUARE]: { aspectRatio: 1 / 1, width: 1200, height: 1200 },
};
const DEFAULT_TIMELINE_WIDTH = 500;
const DEFAULT_TIMELINE_HEIGHT = 128;
const DEFAULT_TIMELINE_ITEM_SIZE = 200;

const useStyles = makeStyles((theme) => ({
  timelineContainer: {
    height: 'auto',
  },
  timeline: {
    background: theme.palette.grey[100],
    height: `${DEFAULT_TIMELINE_HEIGHT}px`,
    scrollbarColor: theme.palette.grey[100],
  },
  listImage: {
    width: `${DEFAULT_TIMELINE_ITEM_SIZE - 1}px`,
    maxHeight: `${DEFAULT_TIMELINE_HEIGHT}px`,
  },
  timepoint: {
    position: 'absolute',
    width: `${DEFAULT_TIMELINE_ITEM_SIZE - 2}px`,
    height: theme.spacing(3),
    bottom: 0,
    color: theme.palette.getContrastText(theme.palette.text.primary),
    background: theme.palette.action.active,
    display: 'flex',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },
}));

const ImagesTab = ({ handleChange, model }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [imageLayouts] = useState([
    LAYOUT_TYPE_LANDSCAPE,
    LAYOUT_TYPE_PORTRAIT,
    LAYOUT_TYPE_SQUARE
  ]);
  const [isThumbnailsLoading, setIsThumbnailsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [timelineWidth, setTimelineWidth] = useState(DEFAULT_TIMELINE_WIDTH);
  const [assets, setAssets] = useState([]);
  const classes = useStyles();

  const scrubsCbRef = useCallback((scrubImgGrid) => {
    if (scrubImgGrid) {
      const resizeObs = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target.offsetWidth) setTimelineWidth(entry.target.offsetWidth - 2);
        }
      });
      resizeObs.observe(scrubImgGrid);
    }
  }, []);

  // once the model is loaded, set assets and available images
  useEffect(() => {
    if (model.assets) {
      const imageAssets = model.assets?.filter?.(
        // FIXME simplify filter logic
        (asset) =>
          asset.type === 'Image' && imageLayouts.includes(asset.subType) && !asset.isDeleted
      );

      setAssets(model.assets);
      setImages(imageAssets);
    }
  }, [model]);

  // once we have assets, look for thumbnails (ScrubbingImages) and load them into thumbnails
  useEffect(() => {
    if (Array.isArray(assets) && assets.length > 0) {
      const thumbnailsAsset = assets.find(
        ({ subType, workflowStatus }) =>
          subType === LAYOUT_TYPE_THUMBNAILS && workflowStatus === 'Succeeded'
      );

      if (thumbnailsAsset) {
        const requestThumbnails = async () => {
          setIsThumbnailsLoading(true);

          try {
            const files = await contentService.getVttFile(thumbnailsAsset.publicUrl);
            setThumbnails(files);
          } catch (error) {
            console.error(error);
          } finally {
            setIsThumbnailsLoading(false);
          }
        };

        requestThumbnails();
      }
    }
  }, [assets]);

  const layoutIcon = (layout, isSelected = false) => {
    const iconProps = {
      color: isSelected ? 'secondary' : 'primary',
    };

    switch (layout) {
      case LAYOUT_TYPE_LANDSCAPE:
        return <Crop169Icon {...iconProps} />;
      case LAYOUT_TYPE_PORTRAIT:
        return <CropPortraitIcon {...iconProps} />;
      case LAYOUT_TYPE_SQUARE:
      default:
        return <CropSquareIcon {...iconProps} />;
    }
  };

  // attempts to get an image public url from one of the avaailable filtered images
  const getUrl = (subType) => {
    const targetImage = images.find((image = {}) => image.subType === subType);
    return targetImage?.publicUrl ?? '';
  };

  /**
   * parse a duration time pair 00:00:00.000 --> 00:00:01.000
   * into ([hours]:)[minutes]:[seconds]
   *
   * @param time string
   *
   * @return string
   */
  const getTimePoint = (time) => {
    const [hours, minutes, seconds] = time.substring(0, 8).split(TIME_DIVIDER);
    const minutesAndSeconds = `${minutes}${TIME_DIVIDER}${seconds}`;

    if (parseInt(hours) < 1) {
      return minutesAndSeconds;
    }

    return `${hours}${TIME_DIVIDER}${minutesAndSeconds}`;
  };

  // FIXME simplify filter logic once requirements are clear
  const setImage = (subType, publicUrl, id = uuid()) => {
    const filteredImages = images.filter?.((image) => image.subType !== subType) || [];
    const filteredAssets = assets.filter?.(
      (asset) => asset.type !== 'Image' || asset.subType !== subType || asset.locale !== '--'
    );
    const newImage = {
      id,
      subType,
      publicUrl,
      type: 'Image',
      locale: '--',
    };

    // update current images collection
    setImages([...filteredImages, newImage]);

    // update model assets
    handleChange('assets', [...filteredAssets, newImage]);
  };

  const ListColumn = ({ index, style, data }) => {
    const listItem = data[index] || {};
    const currentImage = thumbnails[index] || {};
    const processedUrl = processedImageUrl(listItem.thumbnail, {
      r1f: 'w', // fixed aspect ratio, width specified
      r1w: DEFAULT_TIMELINE_ITEM_SIZE, // width px
    });

    return (
      <Box flex={1} style={{ ...style, overflowY: 'hidden' }}>
        <img className={classes.listImage} src={processedUrl} alt={listItem.time} />
        <Box className={classes.timepoint}>
          {imageLayouts.map((layout) => {
            const imageUrl = getUrl(layout);
            const isSelected = currentImage.thumbnail === imageUrl;

            return (
              <Tooltip
                key={layout}
                title={lookUp({
                  key: 'CONSOLE_SET_IMAGE_TEMPLATE',
                  type: lookUp({ key: `CONSOLE_${layout}` }),
                })}
              >
                <IconButton
                  size="small"
                  onClick={() => setImage(layout, !isSelected ? currentImage.thumbnail : '')}
                >
                  {layoutIcon(layout, isSelected)}
                </IconButton>
              </Tooltip>
            );
          })}
          <Typography>{getTimePoint(listItem.time)}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <SectionContainer flex={1}>
      <Grid container spacing={2}>
        {thumbnails.length > 0 && (
          <Grid
            item
            container
            ref={scrubsCbRef}
            xs={12}
            direction="row"
            className={classes.timelineContainer}
          >
            <List
              className={classes.timeline}
              width={timelineWidth}
              height={DEFAULT_TIMELINE_HEIGHT}
              itemCount={thumbnails.length}
              itemData={thumbnails}
              itemSize={DEFAULT_TIMELINE_ITEM_SIZE}
              layout="horizontal"
            >
              {ListColumn}
            </List>
          </Grid>
        )}

        {imageLayouts.map((layout) => {
          const imageUrl = getUrl(layout);
          // FIXME defined crop enabled condition
          const shouldDisplayCropTools = imageUrl && imageUrl.trim?.() !== '';

          return (
            <Grid item container spacing={1} key={layout}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {lookUp({ key: `CONSOLE_${layout}_TITLE` })}
                </Typography>
                <TextField
                  label={lookUp({ key: `CONSOLE_${layout}` })}
                  fullWidth
                  size="small"
                  value={imageUrl}
                  onChange={(e) => {
                    setImage(layout, e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageCropUpload
                  fileUrl={imageUrl}
                  handleChange={(url, id) => {
                    setImage(layout, url, id);
                  }}
                />
              </Grid>
              {shouldDisplayCropTools && (
                <Grid item xs={12}>
                  <ImageCroppingModal
                    fileUrl={imageUrl}
                    handleChange={(url, id) => setImage(layout, url, id)}
                    {...IMAGE_RATIOS[layout]}
                  />
                </Grid>
              )}
            </Grid>
          );
        })}
      </Grid>
    </SectionContainer>
  );
};

ImagesTab.propTypes = {
  model: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default withSnackbar(ImagesTab);
