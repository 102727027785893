import React from 'react';
import { lookUp } from 'services/stringService';
import { PageCard } from 'components';
import { Work, DynamicFeed, DataUsage } from '@material-ui/icons';

const AppsHome = () => {
  
  
  const pages = [
    {
      name: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs',
      icon: <Work fontSize={'large'} />
    },
    {
      name: lookUp({ key: 'CONSOLE_DATA_SOURCES' }),
      link: '/apps/data-sources',
      icon: <DynamicFeed fontSize={'large'} />
    },
    {
      name: lookUp({ key: 'CONSOLE_DATA_GROUPS' }),
      link: '/apps/data-groups',
      icon: <DataUsage fontSize={'large'} />
    }
  ];

  return <PageCard pages={pages} />
};

export default AppsHome;
