import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DeleteButton from 'components/DeleteButton';
import AddButton from 'components/AddButton';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  fullWidth: {
    width: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  noWrapEllipsisContainer: {
    minWidth: 0,
    width: '18rem',
  },
}));

const SkuCard = ({ item, onDelete, onAdd }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems="flex-start" wrap="nowrap" justifyContent="space-between">
          <Grid item xs={8} container direction="column" className={classes.column}>
            <Grid item>
              {item.type && <Typography variant="body2">{item.type}</Typography>}
              {item.name && (
                <Tooltip title={item.name}>
                  <Typography variant="h6" gutterBottom noWrap>
                    <Link
                      to={`/monetization/skus/${item.id}/edit`}
                      component={RouterLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.name}
                    </Link>
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {lookUp({ key: 'CONSOLE_STORE' })}{' '}
                {item.store ? <strong>{item.store}</strong> : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={4}
            alignItems="baseline"
            wrap="nowrap"
            justifyContent="flex-end"
          >
            {onAdd && (
              <AddButton
                tooltipText={lookUp({ key: 'CONSOLE_ADD_ITEM' })}
                onAdd={() => onAdd(item)}
              />
            )}
            {onDelete && (
              <DeleteButton
                tooltipText={lookUp({ key: 'CONSOLE_DELETE_ITEM_FROM_LIST_HELPERTEXT' })}
                onDelete={() => onDelete(item)}
                variant="icon"
                size="small"
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

SkuCard.propTypes = {
  item: PropTypes.object.isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
};

export default SkuCard;
