import { useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import StandardReports from '../StandardReports';
import ChartFromTo from '../ChartFromTo';


const reportTypes = {
  customeractivity: 'CustomerMetrics/Daily/CustomerActivity',
  // teamsnumber: 'CustomerMetrics/Daily/TeamsNumber',
  creatorsmembers: 'CustomerMetrics/Daily/CreatorsMembers',
  eventsmembers: 'CustomerMetrics/Daily/EventsMembers',
  organisationsmembers: 'CustomerMetrics/Daily/OrganisationsMembers',
  projectsmembers: 'CustomerMetrics/Daily/ProjectsMembers',
  sponsorsmembers: 'CustomerMetrics/Daily/SponsorsMembers',
  customercreation: 'GeoTaggingMetrics/Daily/GeoCustomerCreation',
  customerweeklylist: 'GeoTaggingMetrics/CustomerWeeklyList',
};

function CustomerReporting(props) {
  const { enqueueSnackbar } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedReport = searchParams.get('report') || 'customeractivity';
  const reportTypeToShowRawMetircs = ['customeractivity', 'customercreation', 'customerweeklylist'];

  const ReportCharts = useMemo(() => {
    const props = {
      selectedReport,
      reportTypes,
      enqueueSnackbar,
      useRawMetricNames: reportTypeToShowRawMetircs.includes(selectedReport),
      hideDailyReport: selectedReport === 'customerweeklylist',
      hideReportTemplate: selectedReport === 'customerweeklylist',
    };
    return <StandardReports {...props} />
  }, [location.search]);

  useEffect(() => {
    if (!searchParams.has('report')) {
      searchParams.set('report', 'customeractivity');
      setSearchParams(searchParams);
    }
  });

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item xs={3}>
        <TextField
          select
          label={lookUp({ key: 'CONSOLE_MENU_CUSTOMERREPORTING' })}
          fullWidth
          value={selectedReport}
          onChange={(event) => navigate(`${location.pathname}?report=${event.target.value}`)}
        >
          {Object.keys(reportTypes).map((reportType) =>
            <MenuItem key={reportType} value={reportType}>
              {lookUp({ key: `CONSOLE_REPORTING_${reportType}` })}
            </MenuItem>
          )}
        </TextField>
      </Grid>

      <ChartFromTo />

      {ReportCharts}

    </Grid>
  );
}

export default withSnackbar(CustomerReporting);
