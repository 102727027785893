import { useState, useEffect } from 'react';
import { lookUp } from 'services/stringService';
import { useSelector, useDispatch } from 'react-redux';
import navigation from 'store/actions/navigationAction';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { 
  makeStyles, 
  Typography, 
  IconButton, 
  Slide, 
  useScrollTrigger
} from '@material-ui/core';
import {
  Dashboard,
  Settings,
  Collections,
  Devices,
  LocalLibrary,
  Group,
  RecentActors,
  PhonelinkSetup,
  SwapHoriz,
  ExpandMore,
  ExpandLess,
  ArrowRight,
  UnfoldLess,
  UnfoldMore,
} from '@material-ui/icons';
import { sidebarWidth } from 'constants/index.js';


const useStyles = makeStyles((theme) => ({
  doorKnob: (props) => ({
    height: 1,
    width: props.open ? sidebarWidth.open : sidebarWidth.closed,
    transition: 'width 0.25s',
  }),
  wrap: (props) => ({
    position: 'fixed',
    width: props.open ? sidebarWidth.open : sidebarWidth.closed,
    transition: 'width ease-out 0.25s',
    zIndex: theme.zIndex.drawer,
  }),
  root: {
    width: '100%',
    padding: 16,
    color: 'white',
    overflowX: 'hidden',
    overflowY: 'overlay',
    position: 'sticky',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    backgroundColor: theme.palette?.sidebar?.background || '#222',
    height: '100vh',
  },
  toggleOpen: (props) => ({
    position: 'fixed',
    color: 'white',
    backgroundColor: theme.palette?.sidebar?.background || '#222',
    top: -5,
    left: (props.open ? sidebarWidth.open : sidebarWidth.closed) - 15,
    transition: 'left 0.25s',
    borderRadius: '0px 0px 15px 0px',
    zIndex: theme.zIndex.drawer + 1,
    '&:hover': {
      color: theme.palette?.primary?.main,
      backgroundColor: theme.palette?.sidebar?.background || '#222',
    }
  }),
  toggleIcon: {
    transform: 'rotate(90deg)',
  },
  indicator: {
    position: 'absolute',
    left: 40,
  },
  title: (props) => ({
    textAlign: 'center',
    fontSize: '20px',
    transition: '0.25s',
    width: '100%',
    minHeight: props.open ? sidebarWidth.open / 3 : sidebarWidth.closed,
    paddingBottom: 5,
    marginTop: 5,
    height: sidebarWidth.closed,
    backgroundImage: props.open
      ? `url("${props.images?.whiteLogo}")`
      : `url("${props.images?.whiteEmblem}")`,
    backgroundSize: `contain`,
    backgroundPosition: props.open ? 'center' : 'left',
    backgroundRepeat: 'no-repeat',
  }),
  titleText: {
    textAlign: 'center',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    gap: 10,
  },
  groupLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
    padding: '13px 13px',
    marginTop: 10,
    borderRadius: 3,
    transition: 'background-color ease-out 0.25s',
    '&:hover': {
      backgroundColor: theme.palette?.sidebar?.hover || '#444',
    },
  },
  groupLabelActive: {
    backgroundColor: theme.palette.primary?.main,
    display: 'flex',
    alignItems: 'center',
    gap: 30,
    padding: '13px 13px',
    marginTop: 10,
    borderRadius: 3,
    transition: 'background-color ease-out 0.25s',
    '&:hover': {
      backgroundColor: theme.palette.primary?.light,
      boxShadow: `0px 5px 30px -20px ${theme.palette.primary?.light}`,
    },
  },
  subMenu: (props) => ({
    display: props.open ? 'flex' : 'none',
    flexDirection: 'column',
    paddingLeft: 0,
    gap: 0,
  }),
  toggleExpand: {
    position: 'absolute',
    left: 210,
  },
  link: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: 'white',
    padding: 10,
    paddingLeft: 70,
    borderRadius: 3,
    transition: 'background-color ease-out .25s',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette?.sidebar?.hover || '#444',
    },
  },
}));

const Sidebar = () => {
  const [open, setOpen] = useState(localStorage.getItem('isSidebarCollapsed') !== 'true');
  const [hover, setHover] = useState(false);
  const [debounce, setDebounce] = useState(undefined);

  const trigger = useScrollTrigger();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.auth.user.role);
  const lmsReturnURL = useSelector((state) => state.navigation.lmsReturnURL);
  const subOpen = useSelector((state) => state.navigation.openSidebarSubs) || {};
  const images = useSelector((state) => state.brandStyle.images);
  const { menu } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const classes = useStyles({ open: open || hover, images });

  const icons = {
    Dashboard: <Dashboard />,
    Entertainment: <RecentActors />,
    Configuration: <Settings />,
    Monetization: <PhonelinkSetup />,
    Content: <Collections />,
    Automation: <SwapHoriz />,
    Engagement: <Devices />,
    Apps: <LocalLibrary />,
    Access: <Group />,
    CatalogsV3: <LocalLibrary />,
  };

  const handleHover = (o) => {
    setDebounce(clearTimeout(debounce));
    const delay = 150;
    const timeout = setTimeout(() => {
      setHover(o);
    }, delay);
    setDebounce(timeout);
  };

  const highlighted = url => {
    if (url.split('?')[0] !== location.pathname) return false;
    const currentParams = new URLSearchParams(location.search);
    const itemParams = new URLSearchParams(url.split('?')[1]);
    if (JSON.stringify(...itemParams) === JSON.stringify(...currentParams)) return true;
    const itemParam = [...itemParams]?.[0]?.[0];
    if (itemParam && currentParams.get(itemParam) === itemParams.get(itemParam)) return true;
    return false;
  }

  useEffect(() => {
    dispatch(navigation.setCollapseSidebar(!(open || hover)));
  }, [open, hover]);


  return (
    <>
      <div className={classes.doorKnob} />
      <Slide appear={false} direction="down" in={!trigger}>
        <IconButton
          size="small"
          className={classes.toggleOpen}
          onClick={() => {
            setOpen((o) => !o);
            setHover(false);
            localStorage.setItem('isSidebarCollapsed', JSON.stringify(open));
          }}
        >
          {open ? <UnfoldLess className={classes.toggleIcon}/> : <UnfoldMore className={classes.toggleIcon}/>}
        </IconButton>
      </Slide>
      <div className={classes.wrap}>
        <div
          className={classes.root}
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          {images.whiteLogo ? (
            <div className={classes.title}></div>
          ) : (
            <Typography variant="h3" className={classes.titleText}>
              {open || hover
                ? lookUp({ key: 'CONSOLE_ENT360_DEFAULT_HEADING', defaultString: 'ENT.360' })
                : lookUp({ key: 'CONSOLE_ENT360_DEFAULT_HEADING_SHORT', defaultString: 'ENT' })}
            </Typography>
          )}
          {Array.isArray(menu) &&
            menu
              .filter((item) => !item.root)
              .map((item) => (
                <div
                  key={item.key}
                  className={classes.group}
                  onClick={(event) => {
                    event.stopPropagation();
                    item.menuitems?.length > 0
                      ? dispatch(
                          navigation.changeSidebarOpenSubmenus({
                            ...subOpen,
                            [item.name]: !subOpen[item.name],
                          })
                        )
                      : !item.haspage && navigate(item.url);
                    if (item.haspage) navigate(item.url);
                  }}
                >
                  <div
                    className={
                      location.pathname.indexOf(item.url) > -1
                        ? classes.groupLabelActive
                        : classes.groupLabel
                    }
                  >
                    {icons[item.name]}
                    <Typography>
                      {open || hover ? lookUp({ key: item.key }) || item.name : ''}
                    </Typography>
                    {(open || hover) && item.menuitems?.length > 0 && (
                      <div className={classes.toggleExpand}>
                        {subOpen[item.name] ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    )}
                  </div>
                  {subOpen[item.name] && (
                    <div className={classes.subMenu} onClick={(e) => e.stopPropagation()}>
                      {item?.menuitems
                        ?.map((menuitem) => (
                          <Link key={menuitem.key} className={classes.link} to={menuitem.url}>
                            <Typography>
                              {highlighted(menuitem.url) && (
                                <ArrowRight className={classes.indicator} />
                              )}
                              {lookUp({ key: menuitem.key }) || menuitem.name}
                            </Typography>
                          </Link>
                        ))}
                    </div>
                  )}
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
