import React from 'react';
import { lookUp } from 'services/stringService';
import { PageCard } from 'components';

import { Business, Subscriptions, Email, ShoppingBasket, Stars } from '@material-ui/icons';

const Engagement = () => {
  

const pages = [
  {
    name: 'Customers',
    link: '/engagement/customers?type=Customer',
    icon: <Business fontSize={'large'} />
  },
  {
    name: lookUp({key: 'CONSOLE_SUBSCRIPTIONS_TAB' }),
    link: '/engagement/subscriptions',
    icon: <Subscriptions fontSize={'large'} />
  },
  {
    name: 'Message Center',
    link: '/engagement/message-center',
    icon: <Email fontSize={'large'} />
  },
  {
    name: 'Purchase Order',
    link: '/engagement/purchase-order',
    icon: <ShoppingBasket fontSize={'large'} />
  },
  {
    name: lookUp({key: 'CONSOLE_REWARDS_TAB' }),
    link: '/engagement/rewards',
    icon: <Stars fontSize={'large'} />
  }
];


  return <PageCard pages={pages} />
}

export default Engagement;
