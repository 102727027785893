import React from 'react';
import { lookUp } from 'services/stringService';
import { Chip, Typography } from '@material-ui/core';

function MarkerInfo({ data, imageURL, dimensions }) {
  if (data.Face) data = { ...data, ...data.Face };
  if (data.Geometry) data = { ...data, BoundingBox: data.Geometry?.BoundingBox };

  const size = Math.min(
    180 / (dimensions?.width * data?.BoundingBox?.Width),
    240 / (dimensions?.height * data?.BoundingBox?.Height)
  );
  const bools = Object.entries(data).filter((e) => e[1]?.Value === true);

  const labelStyle = {
    variant: 'subtitle2',
    style: {
      width: 100, 
      textDecoration: 'strong',
      marginRight: '1em'
    },
  };

  const valueStyle = {
    variant: 'caption',
  }

  const Urls = (values) => {
    const format = (url) => {
      if (url.indexOf('.wikidata.') > -1) return 'WikiData';
      if (url.indexOf('.wikipedia.') > -1) return 'Wikipedia';
      if (url.indexOf('.imdb.') > -1) return 'IMDB';
      return 'Link';
    };
    return values.values.map((e) => (
      <Chip
        label={format(e)}
        size="small"
        key={e}
        style={{ margin: '.25em' }}
        onClick={() => window.open(`https://${e}`, '_blank')}
      />
    ));
  };

  const InfoBox = ({label, value}) => {
  
    if (!label || !value) return;
    return (
      <div style={{display: 'flex', borderBottom: '1px solid #eee'}}>
        {label && <Typography {...labelStyle}>{label.toUpperCase()}</Typography>}
        {value && <Typography {...valueStyle}>{value}</Typography>}
      </div>
    );
  };

  return data && 
    typeof data === 'string' ? data : (
      <>
        {data.BoundingBox && (
          <div
            style={{
              float: 'right',
              width: 180,
              minHeight: 240,
              background: `grey url("${imageURL}") no-repeat`,
              backgroundPosition: `-${data.BoundingBox?.Left * dimensions?.width * size}px -${
                data.BoundingBox?.Top * dimensions?.height * size
              }px`,
              border: '1px solid orange',
              backgroundSize: `${size * dimensions.width}px ${size * dimensions.height}px`,
              transition: 'all .5s ease',
            }}
          ></div>
        )}
        {data.Name && (
          <Typography variant="h6">{data.Name}</Typography>
        )}
        {data.DetectedText && (
          <Typography variant="h6">{data.DetectedText}</Typography>
        )}
        {data.Urls && Array.isArray(data.Urls) && data.Urls.length > 0 && 
          <Urls values={data.Urls} />
        }
        {data.Confidence && (
          <InfoBox 
            label={"Confidence"} 
            value={data.Confidence.toString().substr(0, 5) + '%'}
          />
        )}
        {data.AgeRange && (
          <InfoBox 
            label={"Age range"} 
            value={`${data.AgeRange.Low} - ${data.AgeRange.High}`}
          />
        )}
        {data.Gender && 
          <InfoBox 
            label={"Gender"} 
            value={
              `${data.Gender.Value.Value} 
              (${data.Gender.Confidence.toString().substr(0, 5)}%)
            `}
          />
        }
        {bools.length > 0 && (
          <InfoBox 
            label={lookUp({ key: 'CONSOLE_FEATURES' })}
            value=
            {Object.entries(data)
              .filter(
                (e) => 
                  e[1]?.Value === true 
                  || (!["Name", "Id"].includes(e[0]) && typeof e[1] === 'string'))
              .map(
                (e) => e[0].replace(/([A-Z])/g, ' $1') // spaces before uppercase letters
              ).join(', ')}
          />
        )}
        {data.Parents?.length > 0 && 
          <InfoBox
            label={lookUp({ key: 'CONSOLE_CATEGORIES' })}
            value={data.Parents.map((e) => e.Name).join(', ')}
          />
        }
        {data.ParentName && 
          <InfoBox
            label={lookUp({ key: 'CONSOLE_CATEGORIES' })}
            value={data.ParentName}
          />
        }
        {data.Emotions && 
          <InfoBox 
            label={lookUp({ key: 'CONSOLE_EMOTIONS' })}
            value={
              data.Emotions.slice(0, 6)
              .map((value) => 
              `${value.Type.Value} 
              (${value.Confidence.toString().substr(0, 5)}%)
               `).join('')}
          />
        }
      </>
    );
}

export default MarkerInfo;
