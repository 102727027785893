import React from 'react';
import { lookUp } from 'services/stringService';
import { Tabbing } from './';
import { makeStyles, Grid, TextField, Switch, Chip, Button, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';
import { Clear } from '@material-ui/icons';
import NoAccess from 'pages/NoAccess';
import AddButton from 'components/AddButton';

// The main header height NOTE this should be computed using a ref and clientHeight
const HEADER_MIN_HEIGHT = 65;

const useStyles = makeStyles((theme) => ({
  mainTable: {
    marginTop: '-3em',
    '& .MuiDataGrid-root': {
      border: 0,
    },
    '& .MuiDataGrid-columnHeader': {
      '& .MuiDataGrid-columnSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: 0,
      },
    },
  },
}));

const AutoTabbing = ({ model, customFields, handleChange, tab, allowedTabs }) => {
  const getBasics = (
    model // Distinguishes arrays from other objects, marks type of elements; opens up objects and marks their keys with type.
  ) =>
    Object.keys(model).map((key) => {
      switch (typeof model[key]) {
        case 'object':
          return Array.isArray(model[key])
            ? `${
                typeof model[key][0] === 'object'
                  ? `${key}:arrayOfObjects:{${getTypes(model[key])}}`
                  : `basic:${key}:array:${typeof model[key][0]}`
              }`
            : `basic:${key}:object{${getTypes(model[key])}}`;
          break;
        default:
          return `basic:${key}:${typeof model[key]}`;
      }
    });

  const getTabs = (
    basics // parsing back to object structure
  ) =>
    basics.reduce((tabs, e) => {
      tabs[e.split(':')[0]] = {
        ...tabs[e.split(':')[0]],
        [e.split(':')[1]]: e.substr(e.indexOf(':', e.indexOf(':') + 1) + 1, e.length),
      };
      return tabs;
    }, {});

  const digestTabs = (
    tabs // Tabs component-ready array of objects from model.
  ) =>
    Object.entries(tabs).map((e, number) => {
      return {
        number: number,
        name: (customFields[e[0]] && customFields[e[0]].label) || e[0],
        content: (
          <ProduceForm
            tab={e[0]}
            model={model}
            handleChange={handleChange}
            obj={e[1]}
            customFields={customFields}
          />
        ),
        icon: customFields[e[0]]?.tabIcon,
      };
    });

  const tabs = digestTabs(getTabs(getBasics(model)));

  return !allowedTabs?.includes(tab) &&
    tabs.map((e) => e.name.toLowerCase().replace(' ', '-')).includes(tab) ? (
    <NoAccess />
  ) : (
    <Tabbing
      tabs={tabs
        .sort(
          (a, b) =>
            allowedTabs.indexOf(a.name?.toLowerCase().replace(' ', '-')) -
            allowedTabs.indexOf(b.name?.toLowerCase().replace(' ', '-')),
        )
        .filter((e) => allowedTabs.includes(e.name.toLowerCase().replace(' ', '-')))}
      addTopMargin={HEADER_MIN_HEIGHT}
      scrollButtons="on"
    />
  );
}

const ProduceForm = ({ tab, model, handleChange, obj, customFields }) => {
  // Generates an input form table.
  return (
    <Grid container alignContent="space-between" spacing={2}>
      {Object.entries(obj).map((keyType, index) => (
        <ProduceField
          tab={tab}
          model={model}
          handleChange={handleChange}
          key={index}
          keyType={keyType}
          customFields={customFields}
        />
      ))}
    </Grid>
  );
};

const ProduceField = ({ tab, model, handleChange, keyType, customFields }) => {
  // Generates input fields.

  const key = keyType[0];
  const type = keyType[1];

  const input = (type) => {
    switch (type) {
      case 'string':
        const properties =
          customFields[key] && customFields[key].props ? customFields[key].props : {};
        return (
          <TextField
            fullWidth
            value={model[key] || ''}
            onChange={(e) => handleChange(key, e.target.value)}
            {...properties}
          />
        );
      case 'boolean':
        return (
          <Switch checked={model[key]} onChange={(e) => handleChange(key, e.target.checked)} />
        );

      case 'array:string':
      case 'array:number':
      case 'array:undefined':
        return (
          <ArrayToChips
            array={
              customFields[key] && customFields[key].options
                ? customFields[key].options
                : model[key]
            }
            value={model[key]}
            props={customFields[key] && customFields[key].props ? customFields[key].props : {}}
            handleChange={(e, value) => handleChange(keyType[0], value)}
          />
        );
      case 'arrayOfObjects':
        return (
          <ProduceEntry
            tab={tab}
            model={model}
            customFields={customFields}
            handleChange={handleChange}
            obj={objectify(keyType[1].substr(1, keyType[1].length - 2))}
          />
        );
      default:
        return type;
    }
  };

  return (
    <>
      {!(customFields[key] && customFields[key].hidden) && (
        <>
          {customFields[key] && customFields[key].break && (
            <Grid item md={6} xs={0} style={{ height: 0, margin: 0 }} />
          )}
          <Grid item xs={12}>
            {key !== 'arrayOfObjects'
              ? customFields[key] && customFields[key].label
                ? customFields[key].label
                : key.charAt(0).toUpperCase() + key.slice(1)
              : ''}
            <br />
            {input(key === 'arrayOfObjects' ? key : type)}
          </Grid>
        </>
      )}
    </>
  );
};

const ArrayToChips = ({ array, value, props, handleChange }) => {
  return (
    <Autocomplete
      multiple
      id="tags"
      options={array}
      value={value}
      onChange={handleChange}
      renderTags={(value, getTagProps) =>
        value.map((option, index) =>
          !!option ? (
            <Chip variant="outlined" key={index} label={option} {...getTagProps({ index })} />
          ) : (
            <></>
          )
        )
      }
      renderInput={(params) => <TextField {...params} />}
      {...props}
    />
  );
};

const ProduceEntry = ({ tab, obj, model, customFields, handleChange }) => {
  // Generates editable grid for an array of key/value objects.

  const classes = useStyles();

  const props = customFields[tab] && customFields[tab].props ? { ...customFields[tab].props } : {};

  const columns = [
    ...Object.entries(obj)
      .filter((e) => !['rowIndex'].includes(e[1]))
      .reduce((columnDefinitions, keyType) => {
        const prop = props[keyType[0]] ? props[keyType[0]] : {};
        columnDefinitions.push({
          field: keyType[0],
          headerName: keyType[0].charAt(0).toUpperCase() + keyType[0].slice(1),
          flex: ['name', 'description'].includes(keyType[0]) || keyType[1] === 'array',
          width: 120,
          editable: true,
          hide: ['id', 'rowIndex'].includes(keyType[0]),
          type: keyType[1],
          renderCell: (item) =>
            ['string', 'number'].includes(keyType[1])
              ? item.value
              : keyType[1] === 'array'
              ? JSON.parse(item.value).length
              : JSON.stringify(item.value),
          ...prop,
        });
        return columnDefinitions;
      }, []),
    {
      field: 'del',
      headerName: ' ',
      width: 40,
      align: 'right',
      renderCell: (item) => (
        <IconButton size="small" onClick={() => removeRow(item.row.rowIndex)}>
          <Clear />
        </IconButton>
      ),
    },
  ];
  const rows = model[tab].map((e, index) => {
    return { ...e, id: e.id || index, rowIndex: index };
  });

  const emptyRow = rows[0];

  const addRow = () => handleChange(tab, [...rows, emptyRow]);

  const removeRow = (rowIndex) => {
    handleChange(
      tab,
      rows.filter((e) => e.rowIndex !== rowIndex)
    );
  };

  const editRows = (rowsChange) => {
    const row = Object.keys(rowsChange)[0];
    if (!row) return;
    const property = Object.keys(rowsChange[row])[0];
    const { value } = rowsChange[row][property];
    rows[row][property] = value;
    handleChange(tab, rows);
  };

  return (
    <Grid container spacing={2}>
      <Grid item container sm={12} justifyContent="flex-end">
        <AddButton
          title={lookUp({ key: 'CONSOLE_ADD' })}
          onAdd={addRow}
        />
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          columns={columns}
          rows={rows.slice(1, rows.length)} // first row holds keys with empty values: not displayed.
          disableColumnFilter
          autoHeight
          disableColumnMenu
          hideFooter
          onEditRowsModelChange={(e) => editRows(e)}
          localeText={{
            noRowsLabel: lookUp({ key: 'CONSOLE_NO_RECORDS_FOUND' }),
          }}
        />
      </Grid>
      <Grid item container sm={12} justifyContent="flex-end">
        <AddButton
          title={lookUp({ key: 'CONSOLE_ADD' })}
          onAdd={addRow}
        />
      </Grid>
    </Grid>
  );
};

const getTypes = (obj) =>
  Object.entries(obj[0] || obj).map(
    (e) => `${e[0]}:${Array.isArray(e[1]) ? `array` : typeof e[1]}`
  );

const objectify = (objstr) => {
  return objstr.split(',').reduce((obj, keyType) => {
    obj[keyType.split(':')[0]] = keyType.split(':')[1];
    return obj;
  }, {});
};

export default AutoTabbing;
