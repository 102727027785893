import React from 'react';
import { lookUp } from 'services/stringService';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { CreatedOn } from '..';

const propertiesToLocalize = ['ipAddress', 'Version', 'Logged', 'Metadata']

function PropertyList(list) {
  const elements = list.list;
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {lookUp({ key: 'CONSOLE_PROPERTYNAME' })}
            </TableCell>
            <TableCell>
              {lookUp({ key: 'CONSOLE_PROPERTYVALUE' })}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {elements.map((e) =>
            <TableRow>
              <TableCell><strong>{e[0]}</strong></TableCell>
              <TableCell>{typeof e[1] === 'string' ? e[1] : propertyChange(e[1])}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const propertyChange = (change) => {
  return (
    <>
    { change && (
      <>
      <strong>{lookUp({ key: 'CONSOLE_FROM_TEXT' })}</strong> {change.From} <strong>{lookUp({ key: 'CONSOLE_TO_TEXT' })} </strong>{change.To}
      </>
    )}
    </>
  )
}

function AuditElement(data) {
  const { ChangedProperties = {'N/A': ''}, Host = {'N/A': ''}, Metadata = 'N/A', Subject = 'N/A', Title = 'N/A', User = 'N/A' } = data.data;
  const propertiesList = Object.entries(ChangedProperties);
  const hostData = Object.entries(Host);

  return (
    <>
      {Title &&
        <p><strong>{Title}</strong></p>
      }
      {User && User.IpAddress && (
        <p>{lookUp({ key: 'CONSOLE_IP_ADDRESS'})}: {User.IpAddress} </p>
      )}
      {hostData &&
        (<p>
          {hostData.filter(e => e[0] !== 'IpAddress').map(e => <> {propertiesToLocalize.includes(e[0]) ? lookUp({ key: `CONSOLE_${e[0]}` }) : `${e[0]}:`} {e[0] === 'Logged' ? <CreatedOn date={e[1]}/> : e[1]} <br/></>)}
        </p>
        )}
      {Metadata && Object.keys(Metadata).length > 0 &&
        (<>{lookUp({ key: 'CONSOLE_METADATA' })}: {JSON.stringify(Metadata)}</>)
      }
      {propertiesList && (
        <>
          <p>{lookUp({ key: 'CONSOLE_PROPERTY_CHANGES' })}</p>
          <PropertyList list={propertiesList} />
        </>
      )}
    </>
  )

}

export default AuditElement;