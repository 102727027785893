import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { shape, func } from 'prop-types';
import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Delete, Add, GraphicEq } from '@material-ui/icons';
import DecorationFormModal from './DecorationFormModal'
import customersService from 'services/customersService';


const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cover: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%'
  },
  greyCoverBg: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    backgroundColor: theme.palette.secondary?.light,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 65,
    height: 65
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2)
  },
  fullWidth: {
    width: '100%'
  },
  marginTop: {
    marginTop: theme.spacing(1.5)
  },
  marginLeft: {
    marginLeft: theme.spacing(1)
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  },
  relative: {
    position: 'relative'
  },
  playButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  }
}));

function ArtistCard(props) {
  
  const classes = useStyles();
  const { item, onDelete, onAdd, handlePropertiesChange } = props;
  const [ detailedItem, setDetailedItem ] = useState(item);

  const AltImage = () => (
    <div className={classes.greyCoverBg}>
      <GraphicEq className={classes.icon} />
    </div>
  );

  let description = '';
  if (item.description) {
    if (item.description.length < 200) {
      description = item.description;
    } else {
      description = `${item.description.slice(0, 195)} (...)`;
    }
  }

  useEffect(() => {
    const getDetails = async () => {
      const details = await customersService.getCustomer(item.customerId);
      setDetailedItem(details);
    };
    getDetails();
  }, [])

  return (
    <Card className={classes.card}>
      <span className={classes.relative}>
        <CardMedia image={detailedItem.properties?.['Customer:Image:Avatar2DUrl']} title={detailedItem.properties?.['Custom:User:Name']} className={classes.cover} />
      </span>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems={'flex-start'} justifyContent={'space-between'} wrap={'nowrap'}>
          <Grid item md={7} lg={11} className={classes.column}>
            <Typography component="h6" variant="subtitle1" gutterBottom>
              {detailedItem.properties?.['Custom:User:Name']}
            </Typography>
            <Grid container className={classes.marginTop}>
              <Grid item className={classes.controls} >
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  className={classes.marginLeft}
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {onAdd && (
              <Grid item xs={2}>
                <Tooltip title={lookUp({ key: 'CONSOLE_ADD_ITEM'})}>
                  <IconButton onClick={() => onAdd(item)}>
                    <Add />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {onDelete && (
              <Grid item>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <DecorationFormModal
                      item={item}
                      decorationTitle={item.name}
                      handlePropertiesChange={handlePropertiesChange}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title={lookUp({ key: 'CONSOLE_DELETE_ITEM'})}>
                      <IconButton onClick={() => onDelete(item)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ArtistCard.propTypes = {
  onAdd: func,
  onDelete: func,
  item: shape({}).isRequired
};

export default ArtistCard;
