import axios from 'axios';
import { lookUp } from 'services/stringService';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getContentReport = ({ type, contentId, from, to }) => 
  axios
    .get(makeApiWithQuery(`${getConsoleApiEndpoint('Reporting')}/${type}`, { contentId, from, to }))
    .then(res => res.data);

const getCustomerReport = ({ type, customerId, creatorId, organisationId, from, to }) =>
  axios
    .get(makeApiWithQuery(`${getConsoleApiEndpoint('Reporting')}/${type}`, { customerId, creatorId, organisationId, from, to }))
    .then(res => res.data);

const getTotalAggregatedReport = (from, to) => {
  const endpoint = getConsoleApiEndpoint('Reporting');
  return !endpoint
    ? Promise.reject({ message: lookUp({ key: 'CONSOLE_NO_ENDPOINT' })})
    : axios
      .get(makeApiWithQuery(`${endpoint}/ClientReports/TotalAggregated`, { from, to }))
      .then(res =>  res.data);
};

const getTotalAggregatedContentReport = ({type, organisationId, from, to}) => {
  const endpoint = getConsoleApiEndpoint('Reporting');
  return !endpoint
    ? Promise.reject({ message: lookUp({ key: 'CONSOLE_NO_ENDPOINT' })})
    : axios
    .get(makeApiWithQuery(`${getConsoleApiEndpoint('Reporting')}/${type}`, { organisationId, from, to }))
      .then(res =>  res.data);
};

const getAggregatedBestContent = (from, to) => {
  const endpoint = getConsoleApiEndpoint('Reporting');
  return !endpoint
    ? Promise.reject({ message: lookUp({ key: 'CONSOLE_NO_ENDPOINT' })})
    : axios
      .get(makeApiWithQuery(`${endpoint}/ClientReports/Aggregated/BestContentWatched`, { from, to }))
      .then(res => res.data);
};

const getAggregatedWorstContent = (from, to) => {
  const endpoint = getConsoleApiEndpoint('Reporting');
  return !endpoint
    ? Promise.reject({ message: lookUp({ key: 'CONSOLE_NO_ENDPOINT' })})
    : axios
      .get(makeApiWithQuery(`${endpoint}/ClientReports/Aggregated/WorstContentWatched`, { from, to }))
      .then(res => res.data);
};

const getDailyReports = ({ reportRoute, from, to }) => {
  const endpoint = getConsoleApiEndpoint('Reporting');
  return !endpoint
    ? Promise.reject({ message: lookUp({ key: 'CONSOLE_NO_ENDPOINT' })})
    : axios
      .get(makeApiWithQuery(`${endpoint}/${reportRoute}`, { from, to }))
      .then(res => res.data);
};

const getProductReport = ({ type, productId, from, to }) => 
  axios
    .get(makeApiWithQuery(`${getConsoleApiEndpoint('Reporting')}/${type}`, { productId, from, to }))
    .then(res => res.data);

export { 
  getContentReport, 
  getCustomerReport, 
  getTotalAggregatedReport,
  getTotalAggregatedContentReport,
  getAggregatedBestContent,
  getAggregatedWorstContent,
  getDailyReports,
  getProductReport,
};

export default {
  getContentReport, 
  getCustomerReport, 
  getTotalAggregatedReport,
  getTotalAggregatedContentReport,
  getAggregatedBestContent,
  getAggregatedWorstContent,
  getDailyReports,
  getProductReport,
};
