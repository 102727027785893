import React from 'react';
import { lookUp } from 'services/stringService';
import { PageCard } from 'components';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import { Ballot, VerticalSplit, Translate, SettingsApplications, ExpandMore  } from '@material-ui/icons';

const ConfigurationHome = () => { 
  
  const pages = [
    {
      name: 'Brands',
      link: '/configuration/brands',
      icon: <Ballot fontSize={'large'} />
    },
    {
      name: 'Parameters',
      link: '/configuration/parameters',
      icon: <VerticalSplit fontSize={'large'} />
    },
    {
      name: lookUp({key: 'CONSOLE_LOCALIZATION_TAB' }),
      link: '/configuration/localization',
      icon: <Translate fontSize={'large'} />
    },
    {
      name: 'Trancode Profiles',
      link: '/configuration/transcoding-profiles',
      icon: <RecentActorsIcon fontSize={'large'} />
    },
    {
      name: 'Maintenance',
      link: '/configuration/maintenance',
      icon: <SettingsApplications fontSize={'large'} />
    },
    {
      name: 'Service Version',
      link: '/configuration/service-version',
      icon: <ExpandMore fontSize={'large'} />
    }
  ];
  

  return <PageCard pages={pages} />;}

export default ConfigurationHome;
