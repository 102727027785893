import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { string, shape, func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Loader } from 'components';
import catalogService from 'services/catalogService';
import AutomaticCatalogsForm from './AutomaticCatalogs/form';
import ManualCatalogsForm from './ManualCatalogs/form';
import AlertService from 'services/alertService';
import { useNavigate, useParams } from 'react-router-dom';


function CatalogsForm(props) {
  const {
    enqueueSnackbar,
  } = props;

  const { id, tab } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isAutomatic, setIsAutomatic] = useState(undefined);
  const [catalogData, setCatalogData] = useState([]);

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      catalogService.get(id)
        .then(data => {
          setCatalogData(data);
          if (data.kind === 'Automatic') {
            setIsAutomatic(true);
          } else {
            setIsAutomatic(false);
          }
        })
        .catch((error) => {
          if (error.status === 404 || error.message?.includes('404')) {
            error.response.data.message = lookUp({ key: 'CONSOLE_NOT_FOUND_MESSAGE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG'})});
          }
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_LOAD_ERROR_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG'}) })
          });
          navigate('/apps/catalogs');
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (id) getData();
    else setIsAutomatic(true);
  }, []);

  return isLoading || isAutomatic === undefined ? (
    <Loader />
  ) : (
    isAutomatic ? (
      <AutomaticCatalogsForm
        id={id}
        tab={tab}
        data={catalogData}
        history={history}
        isAutomatic={isAutomatic}
        setIsAutomatic={setIsAutomatic}
      />
    ) : (
      <ManualCatalogsForm
        id={id}
        tab={tab}
        data={catalogData}
        history={history}
        isAutomatic={isAutomatic}
        setIsAutomatic={setIsAutomatic}
      />
    )
  )
};

CatalogsForm.propTypes = {
  enqueueSnackbar: func.isRequired,
};


export default withSnackbar(CatalogsForm);
