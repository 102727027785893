import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { arrayOf, func, oneOf, object } from 'prop-types';

import { Grid, Button, IconButton, TextField, makeStyles } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import { PropsSelect, } from 'components';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%'
  },
  textCenter: {
    textAlign: 'center'
  },
  marginTop: {
    marginTop: theme.spacing(2)
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const EndPointSettings = (props) => {
  
  const classes = useStyles();
  const { handleChange, data, title, type } = props;

  const [items, setItems] = useState(data);

  const handleItemsChange = (index, key, value) =>
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));

  const handleItemsRemove = (index) => setItems((prev) => prev.filter((p, i) => i !== index));

  const handleItemsAdd = () =>
    setItems((prev) => [
      ...prev,
      {
        [type === 'design' ? 'key' : 'name']: '',
        value: ''
      }
    ]);

  useEffect(() => {
    handleChange(items);
  }, [items]);

  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.marginX}>
        {/* <Typography variant="h5">{title}</Typography> */}
        <Button  color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
          {type === 'design' ? lookUp({ key: 'CONSOLE_DESIGN_BUTTON' }) : lookUp({ key: 'CONSOLE_SETTING_BUTTON' }) }
        </Button>
      </Grid>
      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
          {items.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item xs={11}>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={12} md={6}>
                      <PropsSelect
                        label={type === 'design' ? lookUp({ key: 'CONSOLE_KEY' }) : lookUp({ key: 'CONSOLE_NAME' })}
                        value={type === 'design' ? item.key : item.name}
                        handleChange={(value) =>
                          handleItemsChange(index, type === 'design' ? 'key' : 'name', value)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        label={lookUp({ key: 'CONSOLE_VALUE' })}
                        required
                        className={classes.textField}
                        value={item.value || ''}
                        onChange={(e) => handleItemsChange(index, 'value', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1} className={classes.textCenter}>
                  <IconButton onClick={() => handleItemsRemove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

EndPointSettings.propTypes = {
  type: oneOf(['endpoint', 'design']).isRequired,
  title: oneOf(['Settings', 'Endpoint Settings', 'Default Design']).isRequired,
  data: arrayOf(object).isRequired,
  handleChange: func.isRequired
};

export default EndPointSettings;
