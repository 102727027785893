import React, { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { func, oneOf } from 'prop-types';

import {
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import { PropsSelect } from 'components';

const useStyles = makeStyles((theme) => ({
  textCenter: {
    textAlign: 'center',
  },
  marginX: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const PropsForm = (props) => {
  const classes = useStyles();
  const { handleChange, properties } = props;

  const [items, setItems] = useState(properties || []);
  const [collectionDisplay, setCollectionDisplay] = useState(false);

  const handleItemsChange = (index, key, value) =>
    setItems((prev) => prev.map((p, i) => (i === index ? { ...p, [key]: value } : p)));

  const handleItemsRemove = (index) => setItems((prev) => prev.filter((p, i) => i !== index));

  const handleItemsAdd = () =>
    setItems((prev) => [
      ...prev,
      {
        collection: '',
        name: '',
        value: '',
        isNewItem: true,
      },
    ]);

  useEffect(() => {
    handleChange(items);
  }, [items]);

  const showCollection = () => {
    setCollectionDisplay(!collectionDisplay);
  };

  return (
    <>
      {props.title !== 'Properties' && (
        <Typography variant={'subtitle1'} className={classes.marginTop} gutterBottom>
          {lookUp({ key: 'CONSOLE_PROPERTIES' })}
        </Typography>
      )}
      <Grid item style={{ marginTop: '1em' }}>
        {items.length > 0 && (
          <Button  color="primary" onClick={showCollection}>
            {collectionDisplay
              ? lookUp({ key: 'CONSOLE_HIDE_COLLECTIONS' })
              : lookUp({ key: 'CONSOLE_SHOW_COLLECTIONS' })}
          </Button>
        )}
      </Grid>

      {items && items.length !== 0 && (
        <Grid container alignItems={'center'} className={classes.marginX} spacing={2}>
          {items.map((item, index) => {
            return !item.isNewItem ? (
              <Grid item xs={12} key={index}>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item xs={11}>
                    <Grid container alignItems={'center'} spacing={2}>
                      {collectionDisplay && (
                        <Grid item xs={12} md={4}>
                          <TextField
                            label={lookUp({ key: 'CONSOLE_COLLECTION' })}
                            fullWidth
                            value={item.collection || ''}
                            onChange={(e) => handleItemsChange(index, 'collection', e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={4}>
                        <TextField
                          label={item.name}
                          fullWidth
                          value={item.value || ''}
                          onChange={(e) => handleItemsChange(index, 'value', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.textCenter}>
                    <IconButton onClick={() => handleItemsRemove(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider className={classes.marginX} />
              </Grid>
            ) : props.title === 'Properties' ? (
              <Grid item xs={12} key={index}>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item xs={11}>
                    <Grid container alignItems={'center'} spacing={2}>
                      {collectionDisplay && (
                        <Grid item xs={12} md={4}>
                          <TextField
                            label={lookUp({ key: 'CONSOLE_COLLECTION' })}
                            fullWidth
                            value={item.collection || ''}
                            onChange={(e) => handleItemsChange(index, 'collection', e.target.value)}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} md={4}>
                        <PropsSelect
                          label={lookUp({ key: 'CONSOLE_NAME' })}
                          value={item.name}
                          handleChange={(value) => handleItemsChange(index, 'name', value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label={lookUp({ key: 'CONSOLE_VALUE' })}
                          fullWidth
                          value={item.value || ''}
                          onChange={(e) => handleItemsChange(index, 'value', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.textCenter}>
                    <IconButton onClick={() => handleItemsRemove(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider className={classes.marginX} />
              </Grid>
            ) : (
              <Grid item xs={12} key={index}>
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item xs={11}>
                    <Grid container alignItems={'center'} spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label={lookUp({ key: 'CONSOLE_COLLECTION' })}
                          fullWidth
                          value={item.collection || ''}
                          onChange={(e) => handleItemsChange(index, 'collection', e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <PropsSelect
                          label={lookUp({ key: 'CONSOLE_NAME' })}
                          value={item.name}
                          handleChange={(value) => handleItemsChange(index, 'name', value)}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          label={lookUp({ key: 'CONSOLE_VALUE' })}
                          fullWidth
                          value={item.value || ''}
                          onChange={(e) => handleItemsChange(index, 'value', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} className={classes.textCenter}>
                    <IconButton onClick={() => handleItemsRemove(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider className={classes.marginX} />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Button  color="primary" onClick={handleItemsAdd} startIcon={<Add />}>
        {lookUp({ key: 'CONSOLE_PROPERTY_BUTTON' })}
      </Button>
    </>
  );
};

PropsForm.propTypes = {
  title: oneOf(['Properties', 'Appearance', 'Forward Properties', 'Parameters']),
  handleChange: func.isRequired,
};

PropsForm.defaultProps = {
  title: 'Properties',
};

export default PropsForm;
