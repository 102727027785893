import { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { lookUp } from 'services/stringService';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'services/customersService';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StorageIcon from '@material-ui/icons/Storage';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LanguageSelector from 'components/LanguageSelector';
import OrganizationSelector from 'components/OrganizationSelector';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  button: {
    textTransform: 'capitalize',
    padding: 0,
  },
  avatar: {
    width: 40,
    height: 40,
    fontSize: 14,
  },
}));

const UserMenu = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selectedBrand = useSelector((state) => state.auth.selectedBrand);

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleProfileClick = (e) => {
    e.preventDefault();
    setProfileAnchorEl(e.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut(dispatch);
  };

  const handleUserPage = () => {
    handleProfileClose();
    navigate(`/myaccount`);
  };

  const getInitials = (name) =>
    name
      ?.split(' ')
      .map((word) => word[0])
      .join('')
      .toUpperCase() || 'XY';

  return (
    user && (
      <Box className={classes.container}>
        {user.role !== 'SysAdmin' && (
          <div>
            <OrganizationSelector />
          </div>
        )}
        <LanguageSelector />
        <IconButton
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleProfileClick}
          className={classes.button}
        >
          {user.properties?.['Customer:Image:Avatar2DUrl'] ? (
            <Avatar
              src={user.properties?.['Customer:Image:Avatar2DUrl']}
              className={classes.avatar}
            />
          ) : (
            <Avatar className={classes.avatar}>
              {getInitials(user.name || user.username || user.customerName)}
            </Avatar>
          )}
        </IconButton>
        <Menu
          id="user-menu"
          anchorEl={profileAnchorEl}
          keepMounted
          open={Boolean(profileAnchorEl)}
          onClose={handleProfileClose}
        >
          {!matches && (
            <MenuItem onClick={() => setOpenCollapse(!openCollapse)}>
              <ListItemIcon>
                <StorageIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">{selectedBrand.brandName}</Typography>
              <ListItemIcon>{openCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
            </MenuItem>
          )}
          {!matches && (
            <Collapse in={openCollapse} timeout="auto">
              <List component="div">
                {user.brandAccessList?.map((brandAccessItem, index) => (
                  <ListItem button onClick={() => handleBrandSelect(brandAccessItem)} key={index}>
                    <ListItemText
                      primary={brandAccessItem.brandName}
                      primaryTypographyProps={{ noWrap: true }}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
          {!matches && <Divider />}
          <MenuItem onClick={handleUserPage}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{lookUp({ key: 'CONSOLE_MY_ACCOUNT' })}</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{lookUp({ key: 'CONSOLE_SIGN_OUT_BUTTON' })}</Typography>
          </MenuItem>
        </Menu>
      </Box>
    )
  );
};

UserMenu.propTypes = {
  history: PropTypes.object,
};

export default UserMenu;
