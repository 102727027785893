import React, { useEffect, useState } from 'react';
import ShakaPlayer from 'shaka-player-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import 'shaka-player/dist/controls.css';
import { HHMMSSMsToMs } from 'helpers/time';
import { getMs } from 'helpers/time';

const useStyles = makeStyles(({
  overlay: {
    position: 'absolute',
    zIndex: 9,
  },
}));

const shakaStyles = makeStyles(({
  shakaVideo: props => ({
    margin: 0,
    width: '100%',
    overflow: 'hidden',      // overrides the class with the same name in node_modules/shaka-player/dist/controls.css
    // sets its z-index to 10 to the top of the canvas overlay
    '& .shaka-controls-container': {
      zIndex: 10,
    },
    '& .shaka-ad-markers': {
      background: props.markers,
    }
  }),
}))

const notLoaded = {
  areUrlsObtained: false,
  isShakaPlayerReady: false,
  isContentDataLoaded: false,
  isShakaLoadStarted: false,
  isVideoLoaded: false,
}

function VideoPlayer(props) {
  const {
    overlayRef,
    shakaCallbackRef,
    contentURL,
    overlayWidth,
    overlayHeight,
    autoHighlight,
    startAutoHighlight,
    handlePause,
    breakpoints,
    duration,
    loop,
    setLoop,
    skip,
  } = props;

  const theme = useTheme();
  const secondaryColor = theme.palette?.secondary?.main;

  const makePercentGradient = (breakpoints, duration) => {
    if (!breakpoints || !duration) return "";
    const transparent=" rgba(0,0,0,0) "
    const color=` ${secondaryColor || 'rgba(255,255,0,1)'} `
    let gradientString = "linear-gradient(90deg, rgba(0,0,0,0) 0%," + breakpoints.filter(e => !!e && !e.isInvisible).reduce((markers, e) => {
      markers+= transparent + (HHMMSSMsToMs(e.position)/duration*100).toFixed(1) + "%," + 
        color + (HHMMSSMsToMs(e.position)/duration*100).toFixed(1) + "%," + 
        color + (HHMMSSMsToMs(e.end || e.position)/duration*100).toFixed(1) + "%," + 
        transparent + (HHMMSSMsToMs(e.end || e.position)/duration*100).toFixed(1) + "%,";
      return markers;
    }, "")
    gradientString += "rgba(0,0,0,0)" + " 100%) !important";
    if (gradientString === 'linear-gradient(90deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 100%) !important') return;
    return gradientString;
  };

  const classes = shakaStyles({markers: makePercentGradient(breakpoints, duration)});
  const overlayStyle = useStyles();
  const markedPositions = breakpoints?.filter(e => !!e && !e.isInvisible).map(e => [getMs(e.position) / 1000, getMs(e.end) / 1000]) || [];
  const [loadSequence, setLoadSequence] = useState(notLoaded);
  const [loadVideo, setLoadvideo] = useState(false);

  /**\
   * Pushes the `loadSequence` chain by one step
   * @param currentStep the current step of the load sequence chain
   * @param data if we want to log out the retrieved data
   * @return void
  \**/
  const flickLoadSequence = (currentStep, data = '') => {
    setLoadSequence((loadSequence) => ({ ...loadSequence, [currentStep]: true }))
  };

  const jumping = (player) => { // seek according to loop or skip modes
    if (player.paused || (!skip && !loop)) return;
    const { currentTime } = player;
    const isOnMarker = markedPositions.find(e => e[0] < currentTime && e[1] >= currentTime);
    if (skip && !isOnMarker) { 
      const nextMarker = markedPositions.sort((a, b) => a[0] - b[0]).find(e => e[0] >= currentTime);
      if (nextMarker) { 
        player.currentTime = nextMarker[0]; 
        return;
      } else {
        player.pause();
        player.currentTime = markedPositions[0]?.[0] || 0;
        return;
      }
    };
    if (!loop) return;
    const loopStart = getMs(loop[0]) / 1000;
    const loopEnd = getMs(loop[1]) / 1000;
    if (currentTime > loopEnd || currentTime < loopStart) {
      if (currentTime > loopEnd + .5 || currentTime < loopStart - .5) {
        setLoop(null)
      } else {
        player.currentTime = loopStart;
      }
    };
  };

  useEffect(() => {
    contentURL && flickLoadSequence('areUrlsObtained', contentURL)
  }, [contentURL]);


  useEffect(() => {
    if (shakaCallbackRef) {
      flickLoadSequence('isShakaPlayerReady', shakaCallbackRef)
    }
}, [shakaCallbackRef]);

  useEffect(() => {
    const readyToLoadShaka = (
      loadSequence.areUrlsObtained &&
      loadSequence.isShakaPlayerReady &&
      !loadSequence.isShakaLoadStarted
    )
    if (readyToLoadShaka) {
      flickLoadSequence('isShakaLoadStarted', readyToLoadShaka)
      setTimeout(() => {
        setLoadvideo(true)
      }, 1000)
    }
  }, [loadSequence]);

  return (
    <>
      <canvas
        ref={overlayRef}
        height={overlayHeight}
        width={overlayWidth}
        className={overlayStyle.overlay}
      >
      </canvas>

      {
        loadVideo ? (
          <ShakaPlayer
            id="shakaVideo"
            ref={shakaCallbackRef}
            src={contentURL}
            onPlay={startAutoHighlight}
            onPause={handlePause}
            onTimeUpdate={(event) => {
              jumping(event.currentTarget);
              autoHighlight(event);
            }}
            className={classes.shakaVideo}
          />
        ) : (
          <video
            poster="../../../static/img/blur.jpg"
            className={classes.shakaVideo}
          />
        )
      }
    </>
  )
}

export default VideoPlayer;
