import { parseUserFromToken } from 'helpers/common';
import {
  SET_LOADING_STATUS,
  SET_ERROR_MESSAGE,
  SET_USER,
  RESET_USER,
  SET_ACCESS,
  SET_LAST_ROUTE,
  SET_SELECTED_BRAND,
  SET_SELECTED_ORGANIZATION,
  SET_ORGANIZATIONS_DETAILS,
  SET_SELECTED_LOCALE,
  SET_ENDPOINTS,
} from '../actions/authAction';


const userFromCookies = parseUserFromToken();
const organizations = userFromCookies?.organizations ? JSON.parse(userFromCookies.organizations) : [];

const initialState = {
  isLoading: false,
  user: !!userFromCookies ? {...userFromCookies, organizations } : {},
  selectedBrand: {},
  selectedOrganization: localStorage.getItem('selectedOrganization') || organizations[0] || '',
  lastRoute: '/',
  errorMessage: '',
  endpoints: {},
  orgsDetails: {},
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ENDPOINTS:
      return { ...state, endpoints: action.endpoints };
    case SET_LOADING_STATUS:
      return { ...state, isLoading: action.status };
    case SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.message };
    case SET_USER:
      return { ...state, user: { ...state.user, ...action.user, id: action.user.customerId } };
    case RESET_USER:
      return { ...state, user: {} };
    case SET_ACCESS:
      return { ...state, access: action.access };
    case SET_LAST_ROUTE:
      return { ...state, lastRoute: action.lastRoute };
    case SET_SELECTED_BRAND:
      return { ...state, selectedBrand: action.brand };
    case SET_SELECTED_ORGANIZATION:
      return { ...state, selectedOrganization: action.org };
    case SET_ORGANIZATIONS_DETAILS:
      return { ...state, orgsDetails: action.orgsDetails };
    case SET_SELECTED_LOCALE:
      return { ...state, selectedLocale: action.locale };
    default:
      return state;
  }
}

//selectors
export const getUser = (state) => state.auth.user;
export const getSelectedBrand = (state) => state.auth.selectedBrand;