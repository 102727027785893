import { lookUp } from 'services/stringService';


const AlertService = {
  /**\
   * Displays a string message in a colored message bar floating in from the right of the screen.
   * @param {*} alertParams object with the following properties: 
   *   @param {*} msgBar enqueueSnackbar component of notistack package
   *   @param {*} variant message variant (either: 'success', 'warning', 'error')
   *   @param {*} message string to display as a message
   *   @param {*} autoHideDuration duration while the message is displayed
   * @return void
  \**/
  showMessage({ msgBar, variant, message, autoHideDuration }) {
    msgBar(message, { variant, autoHideDuration });
  },

  /**\
   * Calls the `showMessage()` central function with the appropriate arguments.
   * Sets the `enqueueSnackbar` type to green `success`.
   * @param {*} successParams object with the following properties: 
   *   @param {*} msgBar enqueueSnackbar component of notistack package
   *   @param {*} message string to display as a message
   *   @param valueToLabel dictionary of { value: label } (BE JSON properties -> FE form labels)
   *   @param autoHideDuration duration while the message is displayed
   * @return void
  \**/
  displaySuccess({ msgBar, message, valueToLabel=null, autoHideDuration=5000 }) {
    this.showMessage({ msgBar, variant: 'success', message, autoHideDuration });
  },

  /**\
   * Calls the `showMessage()` central function with the appropriate arguments.
   * Sets the `enqueueSnackbar` type to blue `info`.
   * @param {*} infoParams object with the following properties: 
   *   @param {*} msgBar enqueueSnackbar component of notistack package
   *   @param {*} message string to display as a message
   *   @param valueToLabel dictionary of { value: label } (BE JSON properties -> FE form labels)
   *   @param autoHideDuration duration while the message is displayed
   * @return void
  \**/
  displayInfo({ msgBar, message, valueToLabel=null, autoHideDuration=5000 }) {
    this.showMessage({ msgBar, variant: 'info', message, autoHideDuration });
  },

  /**\
   * Calls the `showMessage()` central function with the appropriate arguments.
   * Sets the `enqueueSnackbar` type to yellow `warning`.
   * @param {*} warningParams object with the following properties: 
   *   @param {*} msgBar enqueueSnackbar component of notistack package
   *   @param {*} message string to display as a message
   *   @param valueToLabel dictionary of { value: label } (BE JSON properties -> FE form labels)
   *   @param autoHideDuration duration while the message is displayed
   * @return void
  \**/
  displayWarning({ msgBar, message, valueToLabel=null, autoHideDuration=5000 }) {
    this.showMessage({ msgBar, variant: 'warning', message, autoHideDuration });
  },

  /**\
   * Calls the `showMessage()` central function with the appropriate arguments.
   * Prepends the `context` to the message if it is not en empty string.
   * Transforms the `error` object to a string, or shows it as it is if it's a string.
   * Sets the `enqueueSnackbar` type to red `error`.
   * @param {*} errorParams object with the following properties: 
   *   @param {*} msgBar enqueueSnackbar component of notistack package
   *   @param {*} error string error message or an error object to be parsed
   *   @param context string it is prepended to the error message
   *   @param valueToLabel dictionary of { value: label } (BE JSON properties -> FE form labels)
   *   @param autoHideDuration duration while the message is displayed
   * @return void
  \**/
  displayError({ msgBar, error, context='', valueToLabel=null, autoHideDuration=5000, isBEkey=false }) {
    let errorMessage = '';

    if (error?.response?.data) {
      // handling 400 form validation errors
      // https://blueguava.atlassian.net/wiki/spaces/IGEMS/pages/3442475036/HTTP+Error+Handling
      if (error.response.data.errors) {
        const allErrors = error.response.data.errors;
        Object.keys(allErrors).forEach((value) => {
          allErrors[value].forEach((errorString) => {
            let errorMessage = errorString;
            if (valueToLabel !== null) {
              errorMessage = errorString.replaceAll(value, valueToLabel[value.toLowerCase()]);
            } else {
              errorMessage = lookUp({ key: errorMessage });
            }
            if (context !== '') {
              errorMessage = ''.concat(context, errorMessage);
            }
            this.showMessage({ msgBar, variant: 'error', message: errorMessage, autoHideDuration });
          });
        });
        return;
      } else if (typeof error.response.data === 'string') {
        errorMessage = error.response.data;
      } else if (typeof error.response.data.message === 'string') {
        errorMessage = error.response.data.message;
      } else if (error.response.data.title) {
        errorMessage = lookUp({ key: error.response.data.title });
      } else if (error.title) {
        errorMessage = lookUp({ key: error.title });
      }
    } else if (error?.toJSON) {
      const errorObj = error.toJSON();
      errorMessage = errorObj.message;
    } else if (error?.message) {
      errorMessage = error.message;
    } else if (error?.data?.message) {
      errorMessage = error.data.message;
    } else if (typeof error === 'string') {
      errorMessage = error;
    } 

    if (errorMessage === '') {
      errorMessage = lookUp({ key: 'CONSOLE_GENERIC_ERROR_MESSAGE' });
    }

    if (context !== '') {
      errorMessage = ''.concat(context, ' ', errorMessage);
    }

    this.showMessage({ msgBar, variant: 'error', message: errorMessage, autoHideDuration });
  },
};

export default AlertService;