import { lookUp } from 'services/stringService';
import { makeStyles, Typography, Link } from "@material-ui/core";
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  grid: {
    border: 0,
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer' : {
      padding: 0,
      overflow: 'visible',
      alignItems: 'flex-start',
    },
    '& .MuiDataGrid-columnHeaderTitle' : {
      fontWeight: 900,
      textOverflow: 'initial',
      whiteSpace: 'initial',
      lineHeight: '1.25em',
      textAlign: 'center',
      overflow: 'visible',
    },
    // Dynamic row heights attempt
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
    },
    '& .MuiDataGrid-iconButtonContainer' : {
      marginLeft: 'auto',
      width: 0,
      overflow: 'visible',
    },
    '& .MuiDataGrid-sortIcon' : {
      position: 'absolute',
    },
  },
  rank: {
    display: 'flex',
    position: 'relative',
    top: 0,
    left: -theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    minWidth: 50,
    borderRadius: 3,
    textShadow: '1px 1px 1px rgba(0,0,0,.75)',
    boxShadow: 'inset 0px 0px 10px rgba(0,0,0,.25)',
    backgroundColor: theme.palette.info.light,
  },
  detail: {
    lineHeight: 0,
  },
  best: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
  },
  worst: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
  },
}));

const Ranking = (
  {
    items,
    selector = "best",
  }) => {
  const classes = useStyles();
  const columns = [
    {
      field: 'rank',
      headerName: ' ',
      headerAlign: 'center',
      sortable: false,
      width: 60,
      renderCell: item => <Typography variant="h2" className={`${classes.rank} ${classes[selector]}`}>{item.value}</Typography>
    },
    {
      field: 'OriginalTitle',
      headerName: lookUp({ key: 'CONSOLE_TITLE' }),
      flex: 1,
      sortable: false,
      renderCell: item => <Link href={`content/${item.id}/edit#consumption`}>{item.value}</Link>,
    },
    {
      field: 'Consumption',
      headerName: `${lookUp({ key: 'CONSOLE_CONSUMPTION' })} [${lookUp({ key: 'CONSOLE_CONSUMPTION_UNIT' })}]`,
      align: 'center',
      headerAlign: 'center',
      width: 120,
      sortable: false,
      renderCell: item => 
        (
          <div className={classes.detail}>
            <Typography variant="h3">{item.value}</Typography> 
          </div>
        ),
    },
    {
      field: 'TimeSpent',
      headerName: `${lookUp({ key: 'CONSOLE_TOTALWATCH' })} [${lookUp({ key: 'CONSOLE_WATCHED_UNIT' })}]`,
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
      renderCell: item => 
        (
          <div className={classes.detail}>
            <Typography variant="h3">{item.value}</Typography>
            {/* <Typography variant="caption">{lookUp({ key: 'CONSOLE_WATCHED_UNIT' })}</Typography> */}
          </div>
        ),
    },
  ]
  return (
    <DataGrid 
      className={classes.grid}
      columns={columns}
      rows={items}
      rowHeight={100}
      headerHeight={50}
      autoHeight
      disableSelectionOnClick
      disableColumnMenu
      hideFooter
    />
  )
};

export default Ranking;