import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import jobManagerService from 'services/jobManagerService';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  transModalGrid: {
    marginTop: theme.spacing(1),
  },
  dialogBody: {
    margin: 0,
    padding: theme.spacing(2, 1.2, 1, 1.2),
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
  },
  formControl: {
    width: '100%',
  },
  modalTitle: {
    textAlign: 'left',
    fontSize: 'large',
    paddingLeft: theme.spacing(1.5),
    textTransform: 'uppercase',
  },
  xButtonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 6,
  },
  modalSubtitle: {
    width: '100%',
    paddingLeft: theme.spacing(1.6),
  },
}));


function AudioVisualizer(props) {
  const {
    contentData,
    setOpen,
    enqueueSnackbar,
  } = props;

  const [audVisSwitch, setAudVisSwitch] = useState({
    spectrogram: false,
    waveform: false,
  });

  const classes = useStyles();

  const startProcess = () => {
    const inputFileLocation = contentData.assets.find((asset) => (
      (typeof asset.type === 'string' && asset.type.toLowerCase() === 'audio') &&
      (typeof asset.subType === 'string' && asset.subType.toLowerCase() === 'wav')
    )).objectUrl;

    const reqBod = {
      name: contentData.originalTitle,
      inputFileLocation,
      referenceObjectId: contentData.id,
      notificationEvents: ['SCHEDULED', 'RUNNING', 'SUCCEED', 'FAILED'],
    };

    if (audVisSwitch.spectrogram) {
      jobManagerService.mediaProcessor({
        ...reqBod,
        mediaProcessingType: 'GENERATE_SPECTOGRAM',
        type: 'MEDIA_PROCESSOR',
        name: `${reqBod.name}_spectrogram`
      })
        .then((jobResponse) => {
          const jobType = lookUp({ key: `CONSOLE_${jobResponse.type}` });
          const jobStatus = lookUp({ key: `CONSOLE_${jobResponse.status}` });
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${jobType} -> ${jobStatus}`,
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
          });
        });
    }

    if (audVisSwitch.waveform) {
      jobManagerService.generateWaveform({
        ...reqBod,
        name: `${reqBod.name}_waveform`
      })
        .then((jobResponse) => {
          const jobType = lookUp({ key: `CONSOLE_${jobResponse.type}` });
          const jobStatus = lookUp({ key: `CONSOLE_${jobResponse.status}` });
          AlertService.displaySuccess({
            msgBar: enqueueSnackbar,
            message: `${jobType} -> ${jobStatus}`,
          });
        })
        .catch((error) => {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            error,
            context: lookUp({ key: 'CONSOLE_ORIGINAL_URL_ERROR' }),
          });
        });
    }
  };

  return (
    <>
      <div className={classes.xButtonWrapper}>
        <Typography className={classes.modalTitle}>
          {lookUp({ key: 'CONSOLE_AUDIO_VISUALIZER' })}
        </Typography>
        <IconButton aria-label="close" onClick={() => setOpen('audiovisualizer', false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogBody}>
        <Grid container spacing={2} direction="row" justifyContent="flex-start">
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audVisSwitch.spectrogram}
                    onChange={(event) => {
                      setAudVisSwitch((audVisSwitch) => (
                        { ...audVisSwitch, spectrogram: event.target.checked }
                      ));
                    }}
                    color="primary"
                  />
                }
                label={lookUp({ key: 'CONSOLE_GENERATESPECTROGRAMJOB' })}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={audVisSwitch.waveform}
                    onChange={(event) => {
                      setAudVisSwitch((audVisSwitch) => (
                        { ...audVisSwitch, waveform: event.target.checked }
                      ));
                    }}
                    color="primary"
                  />
                }
                label={lookUp({ key: 'CONSOLE_GENERATEWAVEFORMBJOB' })}
              />
            </FormControl>
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Grid item xs={12} className={classes.dialogActions}>
          <Button
            onClick={() => {
              startProcess();
              setTimeout(() => setOpen('audiovisualizer', false), 400);
            }}
            color="primary"
          >
            {lookUp({ key: 'CONSOLE_START_PROCESSING_BUTTON' })}
          </Button>
        </Grid>
      </DialogActions>
    </>
  );
};

export default withSnackbar(AudioVisualizer);
