import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { dateFormatter } from 'helpers/time';
import DeleteButton from 'components/DeleteButton';
import AddButton from 'components/AddButton';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cover: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    backgroundSize: 'cover',
    // FIXME use theme.pallete need to indetify theme config and overrides and add background light
    backgroundColor: '#F5F5F5',
  },
  coverAlternate: {
    width: 151,
    minWidth: 150,
    maxWidth: 151,
    height: '100%',
    // FIXME use theme.pallete need to indetify theme config and overrides and add background light
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexGrow: 1,
  },
  icon: {
    width: 65,
    height: 65,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: theme.spacing(1.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  relative: {
    position: 'relative',
  },
  playButton: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 0,
    color: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
  },
  noWrapEllipsisContainer: {
    minWidth: 0,
    width: '18rem',
  },
}));

const EMPTY_VALUE_INDICATOR = '-';

const ArtistCard = ({ item, onDelete, onAdd, handlePropertiesChange }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box component="span" className={classes.relative}>
        <CardMedia
          image={item.properties['Custom:User:Avatar']}
          title={item.username}
          className={classes.cover}
        />
      </Box>
      <CardContent className={classes.fullWidth}>
        <Grid container alignItems="flex-start" wrap="nowrap" justifyContent="space-between">
          <Grid item xs={10} container direction="column" className={classes.column}>
            <Grid item className={classes.noWrapEllipsisContainer}>
              {item.customerType && <Typography variant="body2">{item.customerType}</Typography>}
              {item.username && (
                <Tooltip title={item.username}>
                  <Typography variant="h6" gutterBottom noWrap>
                    <Link
                      to={`/access/customer/${item.customerId}/edit`}
                      component={RouterLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.username}
                    </Link>
                  </Typography>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {`${lookUp({ key: 'CONSOLE_CREATED_ON' })} `}
                {item.registrationDate ? (<strong>{dateFormatter(item.registrationDate)}</strong>) : EMPTY_VALUE_INDICATOR}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={2}
            spacing={1}
            alignItems="baseline"
            wrap="nowrap"
            justifyContent="flex-end"
          >
            {onAdd && (
              <AddButton
                tooltipText={lookUp({ key: 'CONSOLE_ADD_ITEM' })}
                onAdd={() => onAdd(item)}
              />
            )}
            {onDelete && (
              <DeleteButton
                tooltipText={lookUp({ key: 'CONSOLE_DELETE_ITEM_FROM_LIST_HELPERTEXT' })}
                onDelete={() => onDelete(item)}
                variant="icon"
                size="small"
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ArtistCard.propTypes = {
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  item: PropTypes.object.isRequired,
};

export default ArtistCard;
