import { useState, useEffect } from 'react';
import { MenuItem, TextField, Card, makeStyles } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedOrganization, setOrganizationsDetails } from 'store/actions/authAction';
import { lookUp } from 'services/stringService';
import { getCustomer } from 'services/customersService';
import contentAction from 'store/actions/contentAction';
import AlertService from 'services/alertService';


const useStyles = makeStyles(theme => ({
  warning: {
    backgroundColor: theme.palette.warning.light,
    padding: theme.spacing(.5)
  }
}));

const OrganizationSelector = ({ enqueueSnackbar }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const selectedOrganization = useSelector((state) => state.auth.selectedOrganization);
  const organizations = useSelector((state) => state.auth.user?.organizations);
  const orgsDetails = useSelector((state) => state.auth.orgsDetails);
  const role = useSelector((state) => state.auth.user?.role);
  const [labels, setLabels] = useState(organizations?.reduce((obj, e) => { 
    obj[e] = e;
    return obj;
  }, {}));

  useEffect(() => {
    if (role === 'SysAdmin') return;
    if (!localStorage.getItem('selectedOrganization')) {
      localStorage.setItem('selectedOrganization', selectedOrganization || organizations?.[0] || '');
    }
    if (
      organizations?.indexOf(localStorage.getItem('selectedOrganization')) === -1 &&
      !!organizations[0]
    ) {
      localStorage.setItem('selectedOrganization', organizations[0] || '');
      dispatch(setSelectedOrganization(organizations?.[0] || ''));
    }
    if (!selectedOrganization && !!organizations?.[0]) {
      localStorage.setItem('selectedOrganization', organizations?.[0]);
      dispatch(setSelectedOrganization(organizations?.[0]));
    };
    const getLabels = () => {
      Promise.all(organizations?.map(id => getCustomer(id)))
        .then((orgsDetails) => {
          setLabels(orgsDetails.reduce((labels, org) => ({
            ...labels, [org.customerId]: org.username
          }), {}));
          dispatch(setOrganizationsDetails(orgsDetails.reduce(
            (orgsDets, org) => ({ ...orgsDets, [org.customerId]: { ...org } }),
            {}
          )));
        })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_GENERIC_ERROR_MESSAGE' }),
        });
      });
    };
    getLabels();
  }, []);

  return role !== 'SysAdmin' ? (
    organizations?.length > 0 ? (
      <TextField
        select
        value={selectedOrganization}
        onChange={(e) => {
          dispatch(setSelectedOrganization(e.target.value));
          localStorage.setItem('selectedOrganization', e.target.value);
          dispatch(contentAction.setIsDefaultBckgForChime({ isDefault: false, paramChecked: false }));
        }}
        required
      >
        { organizations.filter(e => !!e)?.length > 0 &&
          organizations.map((e) => (
            <MenuItem value={e} key={e}>
              {labels[e]}
            </MenuItem>
          ))}
      </TextField>
    ) : (
      <Card className={classes.warning}>
        {lookUp({ key: 'CONSOLE_CUSTOMER_WITHOUT_ORG_WARNING' })} 
      </Card>
    )
  ) : null;
};

export default withSnackbar(OrganizationSelector);
