import { useState, useEffect, Fragment } from 'react';
import { lookUp } from 'services/stringService';
import { Grid, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { camelCaseToSentenceCase } from 'helpers/strings';
import {
  getCustomerRelationsEnum,
  getCustomerRelation,
} from 'services/collectionsServiceV2';
import { CreatedOn } from 'components';

const CollectionsTab = ({ model }) => {
  
  const { customerId } = model;

  const [customerRelationTypes, setCustomerRelationTypes] = useState();

  const [collections, setCollections] = useState([]);

  const getCollections = async () => {
    const allCalls = customerRelationTypes.reduce((arr, type) => {
      ['List'].forEach((collType) => {
        arr.push({
          collection: collType,
          type,
          call: () => getCustomerRelation(customerId, type, collType),
        });
      });
      return arr;
    }, []);
    const newCollections = await Promise.allSettled([
      ...allCalls.map(async (e) => ({ ...e, data: await e.call() })),
    ]);
    setCollections(newCollections.filter((a) => a.status === 'fulfilled').map((c) => c.value));
  };

  const getEnums = async () => {
    setCustomerRelationTypes((await getCustomerRelationsEnum()).filter(e => e !== 'BanDiscussion'));
  };

  useEffect(() => {
    getEnums();
  }, []);

  useEffect(() => {
    if (!customerRelationTypes) return;
    getCollections();
  }, [customerRelationTypes]);

  const basicColumns = [
    { field: 'createdDate', width: 180, renderCell: (item) => <CreatedOn date={item.row.createdDate} /> },
    { field: 'targetId', flex: 1, },
    // { field: 'type', flex: 1 },
    // { field: 'relation', flex: 1 }, 
  ];

  const columns = {
    List: {
      Rating: [
        { field: 'rating', flex: 1 },
        { field: 'comment', flex: 1 },
      ],
    },
  };

  return (
    <Grid container spacing={2}>
      {collections?.length > 0 &&
        collections.map((e, idx) => (
          <Fragment key={idx}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {lookUp({ key: `CONSOLE_COLLECTION_${e.type}` })}
              </Typography>
            </Grid>
            { Array.isArray(e?.data?.pageContent) && 
              <DataGrid
                style={{ border: 'none' }}
                autoHeight
                pagination
                columns={[...basicColumns, ...(columns[e.collection]?.[e.type] || [])].map((c) => ({
                  ...c,
                  headerName: lookUp({ key: `CONSOLE_${c.field}` }),
                }))}
                rows={e.data.pageContent.map(i => ({...i, id: `${i.sourceId} to ${i.targetId}`}))}
                localeText={{
                  noRowsLabel: lookUp({ key: 'CONSOLE_no_rows' }),
                }}
                componentsProps={{
                  pagination: { 
                    classes: null,
                    labelRowsPerPage: lookUp({ key: 'CONSOLE_ROWS_PER_PAGE' }),
                    labelDisplayedRows: ({from, to, count}) => `${from}-${to} / ${count}`,
                    SelectProps: {
                      MenuProps: { disableScrollLock: true }
                    }
                  },
                }}
              />
            }
          </Fragment>
        ))}
    </Grid>
  );
};

export default CollectionsTab;
