import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  LineChart,
  Line,
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { lookUp } from 'services/stringService';
import reportingSrv from 'services/reportingService';
import AlertService from 'services/alertService';


const useStyles = makeStyles(theme => ({
  yLabel: {
    transform: 'translateX(-50%) translateY(-50%) rotate(-90deg)',
    position: 'absolute',
    left: '7%',
    top: '50%',
  },
  chartGrid: {
    height: '100%',
    margin: theme.spacing(6, 8, 0, 0),
    marginBottom: theme.spacing(8),
    position: 'relative',
  },
  titleItem: {
    marginTop: theme.spacing(2),
  }
}));


function StandardReports(props) {
  const {
    selectedReport,
    reportTypes,
    enqueueSnackbar,
    useRawMetricNames = false,
    hideDailyReport = false,
    hideReportTemplate = false,
    showVerticleBar = false,
  } = props;

  const [metrics, setMetrics] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [barData, setBarData] = useState([]);

  const [searchParams] = useSearchParams();

  const chartColors = useSelector((state) => state.brandStyle.chartColors);

  const classes = useStyles()

  const reqPRef = useRef({});

  const avoidDuplicateRequest = (reqParams) => {
    if (
      Object.values(reqParams).length === 0 &&
      Object.values(reqPRef.current).length !== 0
    ) return true;

    if (!(searchParams.has('from') && searchParams.has('to'))) return true; 

    return Object.keys(reqParams).every(key => reqParams[key] === reqPRef.current[key]);
  };

  const CustomTick = ({ x, y, stroke, payload }) => (
    <g transform={`translate(${x},${y})`}>
      <text x={-10} y={5} textAnchor="end" fill="#666" transform="rotate(270)">
        {payload.value}
      </text>
    </g>
  );

  const tickFormatter = (tick) => tick?.toLocaleString ? tick.toLocaleString() : tick;

  const makeLineChartLabels = useCallback(() => metrics.map((metricName, i) => {
    const value = useRawMetricNames
      ? metricName
      : lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` });
    return ({
      id: `${i}_${metricName}`,
      value,
      type: 'square',
      color: chartColors[i],
    })
  }), [metrics]);

  const Lines = useMemo(() => metrics.map((metricName, index) => (
    <Line
      key={`${metricName}_${index}`}
      dataKey={metricName}
      stroke={chartColors[index]}
      strokeWidth={2}
      type="monotone"
      name={metricName}
    />
  )), [metrics]);

  const Bars = useMemo(() => {
    return (
      <Bar dataKey="value">
        {barData.map((barCh, i) =>
          <Cell key={`${barCh.name}_i`} fill={chartColors[i]}/>
        )}
      </Bar>
    );
  }, [barData]);

  useEffect(() => {
    const reqParams = {
      reportRoute: reportTypes[selectedReport],
      from: searchParams.has('from') ? searchParams.get('from') : undefined,
      to: searchParams.has('to') ? searchParams.get('to') : undefined,
    }
    if (avoidDuplicateRequest(reqParams)) return;

    reqPRef.current = { ...reqParams };
    reportingSrv.getDailyReports(reqParams)
      .then(reportData => {
        setMetrics(reportData.metrics);

        const aggregateData = {}

        const chartData = reportData?.dataItems.map((dataItem) => {
          const dataPoint = dataItem.data?.reduce((dtPoint, elem) => {
            const qntInt = parseInt(elem.quantity, 10);
            const quantity = (Number.isNaN(qntInt) || qntInt === undefined || qntInt === null)
              ? 0
              : qntInt;

            if (!aggregateData[elem.id]) {
              aggregateData[elem.id] = quantity;
            } else {
              aggregateData[elem.id] += quantity;
            }

            dtPoint[elem.id] = quantity;
            return dtPoint;
          }, {});

          let date = new Date(dataItem.date);
          date = date.toString() === 'Invalid Date'
            ? undefined
            : date.toISOString();

          return ({
            date,
            labelDate: date && dataItem.date?.substr(5),
            ...dataPoint,
          });
        });
        setLineData(chartData);
        setBarData(() => Object.keys(aggregateData)
          .reduce((barData, metricName) => {
            barData.push({
              name: useRawMetricNames ? metricName : lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${metricName}` }),
              value: aggregateData[metricName],
            });
            return barData;
          }, [])
        );
      })
      .catch(error => {
        setLineData([]);
        setBarData([]);
        setMetrics([]);
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error.message,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_REPORT' })
          })
        });
      });
  }, [searchParams]);

  return (
    <>
      {
        !hideDailyReport &&
        <>
          <Grid item xs={12} className={classes.titleItem}>
            <Typography align="center" variant="h6">
              {lookUp({
                key: 'CONSOLE_DAILY_REPORTING_TEMPLATE',
                type: lookUp({ key: `CONSOLE_REPORTING_${selectedReport}` })
              })}
            </Typography>
          </Grid>
          <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
            <div className={classes.yLabel}>{lookUp({ key: `CONSOLE_REPORTING_UNIT_${selectedReport}` })}</div>
            <ResponsiveContainer aspect={2} width="85%">
              <LineChart data={lineData}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="labelDate" height={70} interval={0} tick={<CustomTick />} hide={true} />
                <YAxis tickFormatter={tickFormatter} />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  formatter={(value, name) => (
                    [
                      value?.toLocaleString ? value.toLocaleString() : value,
                      useRawMetricNames ? name : lookUp({ key: `CONSOLE_REPORTING_CHART_LABEL_${name}` })
                    ]
                  )}
                  labelFormatter={(originalLabel) => {
                    const dateLabel = new Date(`${new Date().getFullYear()}-${originalLabel}`);
                    return (dateLabel && dateLabel !== 'Invalid Date')
                      ? dateLabel.toLocaleDateString()
                      : originalLabel;
                  }}
                />
                <Legend
                  align="left"
                  verticalAlign="bottom"
                  wrapperStyle={{ marginLeft: '60px', paddingTop: '20px' }}
                  payload={makeLineChartLabels()}
                />
                {Lines}
              </LineChart>
            </ResponsiveContainer>
          </Grid>
        </>
      }
      <Grid item xs={12} className={classes.titleItem}>
        <Typography align="center" variant="h6">
          {
            !hideReportTemplate &&
            lookUp({
              key: 'CONSOLE_AGGREGATE_REPORTING_TEMPLATE',
              type: lookUp({ key: `CONSOLE_REPORTING_${selectedReport}` })
            })
          }
          {
            hideReportTemplate &&
            lookUp({key: `CONSOLE_REPORTING_${selectedReport}`})
          }
        </Typography>
      </Grid>

      {
        !showVerticleBar &&
        <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
          <div className={classes.yLabel}>{lookUp({ key: `CONSOLE_REPORTING_UNIT_${selectedReport}` })}</div>
          <ResponsiveContainer aspect={2} width="85%">
            <BarChart data={barData}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" />
              <YAxis tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {Bars}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      }
      
      { showVerticleBar &&
        <Grid container item xs={12} xl={10} className={classes.chartGrid} justifyContent="center">
          <ResponsiveContainer aspect={2} width="85%">
            <BarChart data={barData} layout='vertical'>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis type='number' hide />
              <YAxis type="category" dataKey='name' tickFormatter={tickFormatter} />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                separator={''}
                formatter={(value) => [value?.toLocaleString ? value.toLocaleString() : value, '']}
              />
              {Bars}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      }
    </>
  );
}

export default StandardReports;
