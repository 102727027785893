import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Link, Tooltip } from '@material-ui/core';
import { Apple } from '@material-ui/icons';
import PublishedComponent from 'assets/icons/PublishedComponent';
import UnPublishedComponent from 'assets/icons/UnPublishedComponent';
import { Lister, Toolbar, QueryBar, Spacing, CreatedOn, ConfirmDialog } from 'components';
import LogButton from 'components/Audit/LogButton';
import skuService from 'services/skuService';
import AlertService from 'services/alertService';
import uniformServices from 'services/uniformServices';

const Sku = ({ enqueueSnackbar }) => {
  const [search, setSearch] = useSearchParams();
  const [storeTypeOptions, setStoreTypeOptions] = useState([]);
  const pageSize = search.get('pageSize') || 25;
  const page = search.get('page') || 0;
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [loading, setLoading] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [debounce, setDebounce] = useState();
  const [trigger, setTrigger] = useState();

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined) delete searchObj[key];
    setSearch(searchObj);
  };
  const platformFilter = {
    label: lookUp({ key: 'CONSOLE_PLATFORMS' }),
    key: 'p',
    options: storeTypeOptions.map((type) => {
      let label = type;
      switch (type) {
        case 'Apple':
          label = lookUp({ key: 'CONSOLE_APPLE_ITUNES' });
          break;
        case 'Google':
          label = lookUp({ key: 'CONSOLE_GOOGLE_PLAY_STORE' });
          break;
      }
      return { label, value: type };
    }),
    change: (value) => setParam('p', value),
  };

  const networkIcon = (network) => {
    switch (network) {
      case 'Apple':
        return <Apple />;
      case 'Google':
        return <FontAwesomeIcon icon={['fab', 'google']} />;
      default:
        return '';
    }
  };

  const columns = [
    {
      field: 'enabled',
      headerName: ' ',
      align: 'left',
      width: 40,
      renderCell: (item) =>
        item.value ? (
          <PublishedComponent title={lookUp({ key: 'CONSOLE_Enabled' })} />
        ) : (
          <UnPublishedComponent title={lookUp({ key: 'CONSOLE_Disabled' })} />
        ),
    },
    {
      field: 'name',
      headerName: lookUp({ key: 'CONSOLE_NAME' }),
      flex: 2,
      align: 'left',
      renderCell: (item) => (
        <Link to={`/monetization/skus/${item.id}/edit`} component={RouterLink}>
          {item.value}
        </Link>
      ),
    },
    {
      field: 'store',
      headerName: ' ',
      filtrable: true,
      align: 'left',
      renderCell: (item) => (
        <Tooltip title={item.value}>
          <Avatar>{networkIcon(item.value)}</Avatar>
        </Tooltip>
      ),
    },
    {
      field: 'productId',
      headerName: lookUp({ key: 'CONSOLE_SKU' }),
      flex: 3,
      align: 'left',
      renderCell: (item) => (
        <Link to={`/monetization/skus/${item.id}/edit`} component={RouterLink}>
          {item.value}
        </Link>
      ),
    },
    {
      field: 'availableFrom',
      headerName: lookUp({ key: 'CONSOLE_AVAILABILITY' }),
      align: 'left',
      width: 140,
      renderCell: (item) => {
        return (
          <p>
            {item.row.availableFrom == null ? (
              lookUp({ key: 'CONSOLE_NA' })
            ) : <CreatedOn date={item.row.availableFrom} /> - item.row.availableUntil == null ? (
              lookUp({ key: 'CONSOLE_NA' })
            ) : (
              <CreatedOn date={item.row.availableUntil} />
            )}
          </p>
        );
      },
    },
    {
      field: 'createdDate',
      headerName: lookUp({ key: 'CONSOLE_CREATED_ON' }),
      align: 'left',
      width: 140,
      renderCell: (item) => <CreatedOn date={item.value} />,
    },
    {
      field: 'type',
      headerName: ' ',
      align: 'center',
      width: 40,
      filterable: false,
      sortable: false,
      renderCell: (item) => <LogButton type="Sku" id={item.id} />,
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      align: 'center',
      width: 40,
      renderCell: (item) => (
        <ConfirmDialog
          onConfirm={() => handleDelete(item.id)}
          message={lookUp({
            key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE',
            title: item.row.name || 'item',
          })}
          dialogText={lookUp({ key: 'CONSOLE_DELETE' })}
          buttonText={lookUp({ key: 'CONSOLE_DELETE' })}
          hint={lookUp({ key: 'CONSOLE_DELETE_ITEM' })}
        />
      ),
    },
  ];

  const handleDelete = (id, name) => {
    setDeletedIds(id);
    uniformServices
      .remove('Sku', id)
      .then((e) => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_DELETE_SUCCESS_TEMPLATE', title: name }),
        });
        setDebounce(clearTimeout(debounce));
        const delay = 500;
        const timeout = setTimeout(() => {
          setTrigger(Symbol('remove_content'));
        }, delay);
        setDebounce(timeout);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error: error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: name }),
        });
      });
  };

  const getData = () => {
    setLoading(true);
    uniformServices
      .search('Sku', search, pageSize || 25, page || 0)
      .then((res) => {
        setTotalCount(res.totalCount);
        setItems([
          ...res.data
            .filter((item) => !deletedIds?.includes(item.id)),
        ]);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: `CONSOLE_STRINGS` }),
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getStoreTypes = () => {
    skuService
      .getStoreTypes()
      .then((storeTypes) => {
        setStoreTypeOptions(storeTypes);
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_STORE_TYPES' }),
          }),
        });
      });
  };

  useEffect(() => {
    getStoreTypes();
  }, []);

  useEffect(() => {
    getData();
  }, [search, trigger]);

  return (
    <>
      <Toolbar create triggerSearch={() => setTrigger(Symbol('refresh'))} />
      <Spacing height={1} />
      <QueryBar filters={[platformFilter]} />
      <Spacing height={2} />
      <Lister loading={loading} columns={columns} rows={items || []} totalCount={totalCount} />
    </>
  );
};

export default withSnackbar(Sku);
