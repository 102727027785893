import './styles/index.scss';
import React, { createRef } from 'react';
import { lookUp } from 'services/stringService';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import * as serviceWorker from './serviceWorker';
import customersService from './services/customersService';
import { store } from './store/configureStore';
import { getTokenFromCookies, setTokenToCookies, removeTokenFromCookies } from './helpers/common';
import { CssBaseline, MuiThemeProvider, Button } from '@material-ui/core';
import { theme } from './styles/MuiTheme';
import RouterWrapper from './routes/RouterWrapper';


library.add(fab);

const requestSuccess = (request) => {
  const authToken = getTokenFromCookies();
  if (authToken && authToken !== 'undefined') {
    const decoded = jwtDecode(authToken);
    if (decoded.exp * 1000 < Date.now()) {
      return customersService
        .refreshToken()
        .then((responseData) => {
          if (responseData === null) {  // is being refreshed
            request.headers.Authorization = `Bearer ${authToken}`;
            return request;
          };
          const { accessToken, refreshToken } = responseData;
          if (!accessToken || !refreshToken) {
            request.headers.Authorization = `Bearer ${authToken}`;
            return request;
          }
          setTokenToCookies(accessToken);
          setTokenToCookies(refreshToken, 'refreshToken');
          request.headers.Authorization = `Bearer ${accessToken}`;
          return request;
        })
        .catch((error) => {
          console.error(error);
          // Redirect to login and clear cookies
          localStorage.removeItem('selectedOrganization');
          removeTokenFromCookies();
          removeTokenFromCookies('refreshToken');
          window.location.href= '/';
          return Promise.reject(`Couldn't refresh authorization token! -> ${error}`);
        });
    } else {
      request.headers = { ...request.headers, Authorization: `Bearer ${authToken}` };
      return request;
    }
  }
  return request;
};

const requestError = (error) => Promise.reject(error);
const responseSuccess = (response) => response;
const responseError = (error) => Promise.reject(error);

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(requestSuccess, requestError);
axios.interceptors.response.use(responseSuccess, responseError);

const notistackRef = createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={5}
            preventDuplicate
            style={{ marginTop: '2.5em' }}
            ref={notistackRef}
            action={(key) => <Button onClick={onClickDismiss(key)}>{lookUp({ key: 'CONSOLE_DISMISS_BUTTON' })}</Button>}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
              autoHideDuration: 2000,
            }}
          >
            <CssBaseline />
            <RouterWrapper />
          </SnackbarProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
