import { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ThemedButton from 'components/ThemedButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from 'components/Dialog';
import { lookUp } from 'services/stringService';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme) => ({
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

const DeleteButton = ({
  children,
  onDelete,
  label,
  tooltipText,
  dialogTitle,
  dialogContent,
  loading=false,
  dividers=false,
  variant='button',
  size='medium',
  disabled=false,
  isConfirm=false,
  stopPropag=false,
}) => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const buttonProps = {
    onClick: (event) => {
      if (stopPropag) event.stopPropagation();
      setIsDialogOpen(true);
    },
    size,
    disabled,
  };

  const handleConfirm = () => {
    setIsDialogOpen(false);
    onDelete?.();
  };

  const handleClose = (e, reason) => {
    // if isConfirm forces clicking on an option
    if (isConfirm && ['escapeKeyDown', 'backdropClick'].includes(reason)) {
      return;
    }

    setIsDialogOpen(false);
  };

  const getButtonText = () => {
    if (!tooltipText) {
      return !isEmpty(label) ? label : lookUp({ key: 'CONSOLE_DELETE' });
    }

    return typeof tooltipText === 'string' && tooltipText.trim() !== ''
      ? tooltipText
      : lookUp({ key: 'CONSOLE_DELETE' });
  };

  const getTitle = () => {
    let title = lookUp({ key: 'CONSOLE_ARE_YOU_SURE' });

    if (dialogTitle) {
      return dialogTitle;
    }

    return dialogTitle && dialogTitle.trim?.() === '' && dialogContent ? '' : title;
  };

  return (
    <>
      <Tooltip item title={getButtonText()}>
        {variant === 'icon' ? (
          <IconButton {...buttonProps}>{!children ? <DeleteIcon /> : children}</IconButton>
        ) : (
          <ThemedButton color="error" loading={loading} {...buttonProps}>{getButtonText()}</ThemedButton>
        )}
      </Tooltip>
      <Dialog
        dividers={dividers}
        open={isDialogOpen}
        onClose={handleClose}
        title={getTitle()}
        actions={
          <>
            <ThemedButton onClick={handleClose}>
              {lookUp({ key: 'CONSOLE_CANCEL_BUTTON' })}
            </ThemedButton>
            <ThemedButton color="error" onClick={handleConfirm} autoFocus>
              {getButtonText()}
            </ThemedButton>
          </>
        }
        maxWidth="sm"
        fullWidth
      >
        {dialogContent}
      </Dialog>
    </>
  );
};

DeleteButton.propTypes = {
  onDelete: PropTypes.func,
  tooltipText: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  dividers: PropTypes.bool,
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node), PropTypes.any]),
  disabled: PropTypes.bool,
  children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  variant: PropTypes.oneOf(['icon', 'button']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  isConfirm: PropTypes.bool,
};

export default DeleteButton;
