import { useEffect, useState } from 'react';
import { lookUp } from 'services/stringService';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { string, shape, func, bool } from 'prop-types';
import { withSnackbar } from 'notistack';
import DesignProps from '../../components/DesignProps';
import {
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  FormHelperText,
  Checkbox,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Visibility, Sort, DoubleArrow, Star, StarOutline, ViewList } from '@material-ui/icons';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { EditorInfo, FileUpload, FormFooter, Loader, Tabbing } from 'components';
import { goBackOrStay } from 'helpers/common';
import ImageCroppingModal from 'components/ImageCropping/CroppingModal';
import PropsForm from '../../components/PropsForm';
import RulesForm from '../../components/RulesForm';
import VisibilityForm from '../components/VisibilityForm';
import OrderRules from '../components/OrderingForm';
import ActionsForm from '../../components/ActionsForm';
import catalogService from 'services/catalogService';
import {
  getCustomerRelation,
  deleteCustomerRelation,
  addCustomerRelation,
} from 'services/collectionsServiceV2';
import dataGroupService from 'services/dataGroupService';
import libraryEnumsService from 'services/libraryEnumsService';
import libraryPropertyService from 'services/libraryPropertyService';
import {
  setCatalogTypes,
  setBreadCrumbsLinks,
  setVisibilityGroups,
} from 'store/actions/libraryEnumsAction';
import NormalTextField from 'styles/inputs/textfields';
import navigationAction from 'store/actions/navigationAction';
import {
  getBreadcrumbsLinks,
  getParentName,
  getParentId,
  getCatalogTypes,
  getVisibilitesGroups,
} from 'store/reducers/libraryEnumsReducer';
import AlertService from 'services/alertService';
import NoAccess from 'pages/NoAccess';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  marginX: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  floatingLabelFocusStyle: {
    fontWeight: 700,
  },
  dividerMargins: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function AutomaticCatalogsForm(props) {
  const { id, data, enqueueSnackbar, isAutomatic, setIsAutomatic } = props;
  const location = useLocation();
  const tab = location.hash?.replace('#', '');
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isNavigationBlocked = useSelector((state) => state.navigation.isNavigationBlocked);
  const userRole = useSelector((state) => state.auth.user.role);
  const ownId = useSelector((state) => state.auth.user.id);
  const { editpage } =
    useSelector((state) => state.auth.access)?.find((e) => e.role === userRole) || {};
  const allowedTabs = editpage?.catalog;

  const breadcrumbsLinks = useSelector(getBreadcrumbsLinks);
  const visibilitiesGroups = useSelector(getVisibilitesGroups);
  const parentName = useSelector(getParentName);
  const parentId = useSelector(getParentId);
  const catalogTypes = useSelector(getCatalogTypes);

  const [model, setModel] = useState({
    id: id ? id : undefined,
    index: 0,
    name: '',
    imageUrl: null,
    type: 'Folder',
    dataGroupId: '',
    parentId: parentId,
    enabled: false,
    displayRules: [],
    paging: null,
    properties: [],
    actions: [],
    filterRules: [],
    orderRules: [],
    cacheable: '',
  });

  const [isFavourite, setIsFavourite] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [dataGroupOptions, setDataGroupOptions] = useState([]);
  const [isDataGroupOptionsLoading, setIsDataGroupOptionsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isParentOptionsLoading, setIsParentOptionsLoading] = useState(true);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isTypeOptionsLoading, setIsTypeOptionsLoading] = useState(false);
  const [cacheable, setCacheable] = useState([
    { label: lookUp({ key: 'CONSOLE_CACHEABLE' }), value: null },
  ]);
  const [libraryPropertyEnums, setLibraryPropertyEnums] = useState([]);
  const [isEnumsTypeLoading, setIsEnumsTypeLoading] = useState([]);
  const [cardValue, setCardValue] = useState('');
  const [bigScreenCardValue, setBigScreenCardValue] = useState('');
  const [mobileCardValue, setMobileCardValue] = useState('');
  const [mobileWebCardValue, setMobileWebCardValue] = useState('');
  const [desktopCardValue, setDesktopCardValue] = useState('');
  const [shortInfoValue, setShortInfoValue] = useState('');
  const [pageSizeChecked, setPageSizeChecked] = useState(false);
  const [contentTitle, setContentTitle] = useState('');
  const [defaultParentValue, setDefaultParentValue] = useState([
    { label: parentName, value: parentId },
  ]);

  const addToFavourites = () => {
    addCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(true))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_ADD_FAVOURITE_ERROR' }),
        });
      });
  };

  const removeFromFavourite = () => {
    deleteCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(false))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_REMOVE_FAVOURITE_ERROR' }),
        });
      });
  };

  const setFavourite = () => {
    switch (isFavourite) {
      case true:
        removeFromFavourite(id);
        break;
      case false:
        addToFavourites(id);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!id) {
      setModel({ ...model });
      return;
    }
    getCustomerRelation(ownId, 'FavCatalog', id)
      .then(() => setIsFavourite(true))
      .catch(() => setIsFavourite(false));
  }, []);

  useEffect(() => {
    fetchVisibilityGroups();
  }, []);

  useEffect(() => {
    const getData = async () => {
      dispatch(navigationAction.setPageTitle(data.name));
      setIsLoading(true);
      try {
        const copyModel = { ...data };
        const actions = copyModel.actions.map((action) => {
          if (action.schedule == null) {
            action.schedule = { type: 'None', value: '' };
          }
          return action;
        });
        copyModel.actions = actions;
        setModel(copyModel);
        if (data.paging != null) {
          setPageSizeChecked(true);
        } else {
          setPageSizeChecked(false);
        }
        const cardProp = data.properties.filter((pr) => pr.name === 'Content:CardSize');
        const bigScreenCardSizeProp = data.properties.filter(
          (pr) => pr.name === 'Content:BigScreenCardSize'
        );
        const mobileCardSizeProp = data.properties.filter(
          (pr) => pr.name === 'Content:MobileCardSize'
        );
        const mobileWebCardSizeProp = data.properties.filter(
          (pr) => pr.name === 'Content:MobileWebCardSize'
        );
        const desktopCardSizeProp = data.properties.filter(
          (pr) => pr.name === 'Content:DesktopCardSize'
        );
        const shortInfoProp = data.properties.filter((pr) => pr.name === 'Carousel:ShortInfo');
        const contentTitleProp = data.properties.filter((pr) => pr.name === 'Content:Title');

        if (cardProp.length != 0) {
          setCardValue(cardProp[0].value);
        } else {
          setCardValue('');
        }
        if (bigScreenCardSizeProp.length != 0) {
          setBigScreenCardValue(bigScreenCardSizeProp[0].value);
        } else {
          setBigScreenCardValue('');
        }
        if (desktopCardSizeProp.length != 0) {
          setDesktopCardValue(desktopCardSizeProp[0].value);
        } else {
          setDesktopCardValue('');
        }
        if (mobileCardSizeProp.length != 0) {
          setMobileCardValue(mobileCardSizeProp[0].value);
        } else {
          setMobileCardValue('');
        }
        if (mobileWebCardSizeProp.length != 0) {
          setMobileWebCardValue(mobileWebCardSizeProp[0].value);
        } else {
          setMobileWebCardValue('');
        }
        if (shortInfoProp.length != 0) {
          setShortInfoValue(shortInfoProp[0].value);
        } else {
          setShortInfoValue('');
        }
        if (contentTitleProp.length != 0) {
          setContentTitle(contentTitleProp[0].value);
        } else {
          setContentTitle('');
        }
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_CATALOG_DATA' }),
          }),
        });
        setIsLoading(false);
      }
    };
    if (id) {
      getData();
    } else {
      dispatch(
        navigationAction.setPageTitle(
          lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG' }) })
        )
      );
    }
    return () => {
      dispatch(navigationAction.setPageTitle(''));
      dispatch(setBreadCrumbsLinks(null));
    };
  }, []);

  useEffect(() => {
    const getDataGroupOptions = async () => {
      setIsDataGroupOptionsLoading(true);
      try {
        const response = await dataGroupService.search();
        let data = response.data;
        if (response.totalCount > 1000) {
          const secondQuery = await dataGroupService.search(undefined, {
            page: 0,
            perPage: response.totalCount,
          });
          data = secondQuery.data;
        }
        setDataGroupOptions(data.map((d) => ({ label: d.name, value: d.id })));
        setIsDataGroupOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_DATA_GROUPS' }),
          }),
        });
        setIsDataGroupOptionsLoading(false);
      }
    };
    getDataGroupOptions();
  }, []);

  useEffect(() => {
    const getParentOptions = async () => {
      try {
        const { totalCount: catalogTC } = await catalogService.search({ page: 0, perPage: 1 });
        const { data: catalogData } = await catalogService.search({ page: 0, perPage: catalogTC });
        setDefaultParentValue([
          { label: lookUp({ key: 'CONSOLE_NONE' }), value: '' },
          ...catalogData.map((p) => ({ label: p.name, value: p.id })),
        ]);
        setIsParentOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_PARENT' }),
          }),
        });
      }
    };
    getParentOptions();
  }, []);

  useEffect(() => {
    const getTypeOptions = async () => {
      setIsTypeOptionsLoading(true);

      try {
        const options =
          catalogTypes.length === 0 ? await libraryEnumsService.getCatalogTypes() : catalogTypes;

        catalogTypes.length === 0 && dispatch(setCatalogTypes(options));
        setTypeOptions(options.map((o) => ({ label: o, value: o })));
        setIsTypeOptionsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_OPTIONS_ERROR_TEMPLATE',
            type: lookUp({ key: 'CONSOLE_TYPE' }),
          }),
        });
        setIsTypeOptionsLoading(false);
      }
    };
    getTypeOptions();
  }, []);

  useEffect(() => {
    const getLibraryPropertyEnums = async () => {
      setIsEnumsTypeLoading(true);

      try {
        let options = await libraryPropertyService.getPropertyEnums();
        let enumsTypes = options['Content:CardSize'];
        setLibraryPropertyEnums(enumsTypes.map((o) => ({ label: o, value: o })));

        setIsEnumsTypeLoading(false);
      } catch (error) {
        console.error(error);
        setIsEnumsTypeLoading(false);
      }
    };
    getLibraryPropertyEnums();
  }, []);

  useEffect(() => {
    const cacheData = [
      { label: lookUp({ key: 'CONSOLE_AUTO' }), value: null },
      { label: lookUp({ key: 'CONSOLE_YES' }), value: true },
      { label: lookUp({ key: 'CONSOLE_NO' }), value: false },
    ];
    setCacheable(cacheData);
  }, []);

  useEffect(() => {
    if (!breadcrumbsLinks?.length) {
      visibilitiesGroups.length && breadBuilder(id);
    }
  }, [visibilitiesGroups]);

  const fetchVisibilityGroups = async () => {
    const emptyVisiG = visibilitiesGroups.length === 0;
    const options = emptyVisiG ? await catalogService.catalogTypes() : visibilitiesGroups;
    emptyVisiG && dispatch(setVisibilityGroups(options));
  };

  const makeFinalCrumbsSequence = (breads, vGroupInd) => {
    const vGroup = visibilitiesGroups[vGroupInd];
    breads.push({
      text: vGroup.name,
      link: `/apps/catalogs/${vGroup.id}`,
      isCurrent: false,
      order: null,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs',
      isCurrent: false,
      order: null,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_APPS' }),
      link: '/apps',
      isCurrent: false,
      order: null,
    });
    breads.reverse();
    const lastInd = breads.length - 1;
    breads.push({
      text: lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: breads[lastInd].text }),
      link: `/apps/catalogs/${id}/edit`,
      isCurrent: true,
      order: null,
    });
    breads.forEach((bread, index) => (bread.order = index + 1));
    dispatch(setBreadCrumbsLinks(breads));
  };

  const makeCrumbOfCreate = () => {
    const breads = [];
    breads.push({
      text: lookUp({ key: 'CONSOLE_APPS' }),
      link: '/apps',
      isCurrent: false,
      order: 1,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CATALOGS' }),
      link: '/apps/catalogs',
      isCurrent: false,
      order: 2,
    });
    breads.push({
      text: lookUp({ key: 'CONSOLE_CREATE_TEMPLATE', type: lookUp({ key: 'CONSOLE_CATALOG' }) }),
      link: '/apps/catalogs/create',
      isCurrent: true,
      order: 3,
    });
    dispatch(setBreadCrumbsLinks(breads));
  };

  const breadBuilder = (parentId, breads = []) => {
    if (parentId === undefined) return makeCrumbOfCreate();
    const vGroupInd = visibilitiesGroups.findIndex((vGroup) => vGroup.id === parentId);
    if (vGroupInd > -1) {
      makeFinalCrumbsSequence(breads, vGroupInd);
    } else if (parentId) {
      catalogService.get(parentId).then((response) => {
        breads.push({
          text: response.name,
          link: `/apps/catalogs/${response.id}`,
          isCurrent: false,
          order: null,
        });
        breadBuilder(response.parentId, breads);
      });
    } else {
      return;
    }
  };

  const goBackCrumbLink = (crumb) => {
    const breads = breadcrumbsLinks.filter((bcLink) => bcLink.order <= crumb.order);
    breads[breads.length - 1].isCurrent = true;
    dispatch(setBreadCrumbsLinks(breads));
  };

  const handleModelChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePageSizeChange = (key, value) => {
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setModel((prev) => ({
      ...prev,
      paging: {
        ...prev.paging,
        [key]: value,
      },
    }));
  };

  const handleCardSizePropChange = (key, value) => {
    setCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleDesktopCardPropChange = (key, value) => {
    setDesktopCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleBigScreenCardPropChange = (key, value) => {
    setBigScreenCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleMobileCardPropChange = (key, value) => {
    setMobileCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleMobileWebCardPropChange = (key, value) => {
    setMobileWebCardValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleContentTitlePropChange = (key, value) => {
    setContentTitle(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleShortInfoPropChange = (key, value) => {
    setShortInfoValue(value);
    const propNames = model.properties.map((prop) => prop.name);
    if (model.properties.length > 0 && propNames.includes(key)) {
      const newValue = model.properties.map((item) => {
        if (item.name === key) {
          item.value = value;
          item.isNewItem = false;
        }
        return item;
      });
      model.properties = newValue;
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel(model);
    } else {
      const newItem = { collection: '', name: key, value: value, isNewItem: false };
      !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
      setModel((prev) => ({
        ...prev,
        properties: [...prev.properties, newItem],
      }));
    }
  };

  const handleChange = (event) => {
    let saveModel = { ...model, paging: pageSizeChecked ? null : { pageSize: 0 } };
    !isNavigationBlocked && dispatch(navigationAction.blockNavigation());
    setPageSizeChecked(event.target.checked);
    setModel(saveModel);
  };

  const saveData = async (publish = false) => {
    publish ? setIsPublishing(true) : setIsSaving(true);

    try {
      const copyModel = { ...model };
      const actions = copyModel.actions.map((action) => {
        if (action.schedule && action.schedule.type === 'None') {
          action.schedule = null;
        }
        return action;
      });
      copyModel.actions = actions;
      const saveModel = JSON.parse(JSON.stringify(copyModel));
      if (publish) {
        saveModel.enabled = !model.enabled;
      }

      id ? await catalogService.edit(saveModel) : await catalogService.create(saveModel);
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: `${model.name} – ${
          publish && model.enabled === false
            ? lookUp({ key: 'CONSOLE_PUBLISHED_MESSAGE' })
            : publish && model.enabled === true
            ? lookUp({ key: 'CONSOLE_UNPUBLISHED_MESSAGE' })
            : id
            ? lookUp({ key: 'CONSOLE_SAVED_MESSAGE' })
            : lookUp({ key: 'CONSOLE_CREATED_MESSAGE' })
        }`,
      });
      goBackCrumbLink(breadcrumbsLinks[breadcrumbsLinks.length - 2]);
      const toWhere = window.history.state.idx === 0
        ? breadcrumbsLinks[breadcrumbsLinks.length - 2]?.link || '/apps/catalogs'
        : -1;
      goBackOrStay(navigate, toWhere);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
      });
    } finally {
      publish ? setIsPublishing(false) : setIsSaving(false);
    }
  };

  const deleteCatalog = async () => {
    setIsDeleting(true);
    try {
      await catalogService.remove({ id: id });
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      dispatch(setBreadCrumbsLinks([]));
      setTimeout(() => {
        if (parentId && parentId !== id) {
          navigate(`/apps/catalogs/${parentId}`);
        } else {
          navigate('/apps/catalogs');
        }
      }, 50);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE', title: model.name }),
      });
    } catch (error) {
      isNavigationBlocked && dispatch(navigationAction.allowNavigation());
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_CATALOGS' }),
        }),
      });
      setIsDeleting(false);
    }
  };

  const tabs = [
    {
      name: 'basic',
      content: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{lookUp({ key: 'CONSOLE_POSITION_HEADING' })}</Typography>
          </Grid>
          <Grid item xs={12} className={classes.textCenter}>
            <Tooltip title={lookUp({ key: 'CONSOLE_TYPE' })}>
              <Switch
                checked={!isAutomatic}
                onChange={() => setIsAutomatic((isAutomatic) => !isAutomatic)}
                color={'primary'}
                disabled={!!id}
              />
            </Tooltip>
            {lookUp({ key: 'CONSOLE_AUTOMATIC' })}
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              {isParentOptionsLoading ? (
                <Loader inline />
              ) : (
                <>
                  <TextField
                    select
                    fullWidth
                    label={lookUp({ key: 'CONSOLE_PARENT' })}
                    placeholder={parentName}
                    onChange={({ target }) => handleModelChange('parentId', target.value)}
                    value={model.parentId || null}
                  >
                    {defaultParentValue.map((e) => (
                      <MenuItem value={e.value}>{e.label}</MenuItem>
                    ))}
                  </TextField>
                  <FormHelperText style={{ marginLeft: '14px' }}>
                    {lookUp({ key: 'CONSOLE_Pick_parent_item_HELPERTEXT' })}
                  </FormHelperText>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <NormalTextField
                label={lookUp({ key: 'CONSOLE_INDEX' })}
                helperText={lookUp({ key: 'CONSOLE_CATALOG_POSITION_HELPERTEXT' })}
                required
                type="number"
                value={model.index || ''}
                onChange={(e) => handleModelChange('index', e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_NAME_AND_DESCRIPTION_HEADING' })}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={6}>
              <NormalTextField
                label={lookUp({ key: 'CONSOLE_REFERENCE_NAME' })}
                required
                value={model.name || ''}
                onChange={(e) => handleModelChange('name', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_CONTENTTITLE' })}
                className={classes.fullWidth}
                placeholder={lookUp({ key: 'CONSOLE_CONTENT_TITLE_HELPERTEXT' })}
                value={contentTitle || ''}
                onChange={(e) => handleContentTitlePropChange('Content:Title', e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                label={lookUp({ key: 'CONSOLE_SHORTINFO' })}
                multiline
                rows={3}
                className={classes.fullWidth}
                helperText={lookUp({ key: 'CONSOLE_CATALOG_SHORTINFO_HELPERTEXT' })}
                placeholder={lookUp({ key: 'CONSOLE_CATALOG_SHORTINFO_PLACEHOLDER' })}
                value={shortInfoValue || ''}
                onChange={(e) => handleShortInfoPropChange('Carousel:ShortInfo', e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_SETTINGS_HEADING' })}
            </Typography>
          </Grid>
          {model.id && (
            <Grid item xs={12}>
              <Grid item xs={6}>
                <NormalTextField label={lookUp({ key: 'CONSOLE_ID' })} value={model.id} disabled />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid item xs={6}>
              {isDataGroupOptionsLoading ? (
                <Loader inline />
              ) : (
                <>
                  <TextField
                    select
                    fullWidth
                    required
                    label={lookUp({ key: 'CONSOLE_DATAGROUP' })}
                    placeholder={lookUp({ key: 'CONSOLE_DATAGROUP_PLACEHOLDER' })}
                    value={model.dataGroupId || null}
                    onChange={({ target }) => handleModelChange('dataGroupId', target.value)}
                  >
                    {dataGroupOptions.map((e, i) => (
                      <MenuItem key={i} value={e.value}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  <FormHelperText style={{ marginLeft: '14px' }}>
                    {lookUp({ key: 'CONSOLE_DATAGROUP_HELPERTEXT' })}
                  </FormHelperText>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={6}>
              {isTypeOptionsLoading ? (
                <Loader inline />
              ) : (
                <>
                  <TextField
                    select
                    fullWidth
                    label={lookUp({ key: 'CONSOLE_CATALOGTYPE' })}
                    onChange={({ target }) => handleModelChange('type', target.value)}
                    value={model.type}
                  >
                    {typeOptions.map((e, i) => (
                      <MenuItem key={i} value={e.value}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={6}>
              <TextField
                select
                disabled={!cacheable?.length}
                fullWidth
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                label={lookUp({ key: 'CONSOLE_CACHEABLE' })}
                onChange={({ target }) => {
                  handleModelChange('cacheable', target.value);
                }}
                value={
                  model.cacheable === undefined || model.cacheable === '' ? null : model.cacheable
                }
              >
                {cacheable.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.textCenter}>
            <>
              <Checkbox
                checked={pageSizeChecked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              {lookUp({ key: 'CONSOLE_LIMIT_CATALOG_ITEMS' })}
            </>
          </Grid>
          <Grid item xs={2}>
            {pageSizeChecked && (
              <TextField
                label={lookUp({ key: 'CONSOLE_PAGESIZE' })}
                type="number"
                helperText={lookUp({ key: 'CONSOLE_RECOMMENDED_LABEL_TEMPLATE', number: 20 })}
                className={classes.fullWidth}
                value={model.paging ? model.paging.pageSize : null}
                onChange={(e) => handlePageSizeChange('pageSize', e.target.value)}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      name: 'design',
      icon: <ColorizeIcon />,
      content: (
        <>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6" style={{ marginBottom: '1em' }}>
                {lookUp({ key: 'CONSOLE_CARD_SIZE_HEADING' })}
              </Typography>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_GENERAL' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={cardValue}
                onChange={({ target }) =>
                  handleCardSizePropChange('Content:CardSize', target.value)
                }
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_CATALOGSIZE_HELPERTEXT' })}
              </FormHelperText>
            </Grid>
          </Grid>

          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_DESKTOP' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={desktopCardValue}
                onChange={({ target }) =>
                  handleDesktopCardPropChange('Content:DesktopCardSize', target.value)
                }
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_DESKTOP_CATALOG_HELPERTEXT' })}
              </FormHelperText>
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_BIG_SCREEN' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={bigScreenCardValue}
                onChange={({ target }) =>
                  handleBigScreenCardPropChange('Content:BigScreenCardSize', target.value)
                }
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_BIGSCREEN_CATALOG_HELPERTEXT' })}
              </FormHelperText>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={6}>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_MOBILEPHONE' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={mobileCardValue}
                onChange={({ target }) =>
                  handleMobileCardPropChange('Content:MobileCardSize', target.value)
                }
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_MOBILE_CATALOG_HELPERTEXT' })}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                disabled={!libraryPropertyEnums?.length}
                fullWidth
                label={lookUp({ key: 'CONSOLE_MOBILEWEB' })}
                placeholder={lookUp({ key: 'CONSOLE_SELECTITEM_PLACEHOLDER' })}
                value={mobileWebCardValue}
                onChange={({ target }) =>
                  handleMobileWebCardPropChange('Content:MobileWebCardSize', target.value)
                }
              >
                {libraryPropertyEnums.map((e, i) => (
                  <MenuItem key={i} value={e.value}>
                    {e.label}
                  </MenuItem>
                ))}
              </TextField>
              <FormHelperText style={{ marginLeft: '14px' }}>
                {lookUp({ key: 'CONSOLE_MOBILEWEB_CATALOG_HELPERTEXT' })}
              </FormHelperText>
            </Grid>
          </Grid>
          <DesignProps
            properties={model.properties}
            handleChange={(data) => handleModelChange('properties', data)}
          />

          <Grid item xs={12}>
            <Typography variant="h6" style={{ marginBottom: '1em' }}>
              {lookUp({ key: 'CONSOLE_IMAGE_HEADING' })}
            </Typography>
          </Grid>
          {model.type === 'Folder' && (
            <Grid item xs={12}>
              <FileUpload
                fileUrl={model.imageUrl || ''}
                handleChange={(url) => handleModelChange('imageUrl', url)}
              />
            </Grid>
          )}
          {model.imageUrl && (
            <Grid item xs={12}>
              <ImageCroppingModal
                fileUrl={model.imageUrl}
                handleChange={(url) => handleModelChange('imageUrl', url)}
                aspectRatio={16 / 9}
                width={1210}
                height={720}
              />
            </Grid>
          )}
        </>
      ),
    },
    {
      name: 'visibility',
      icon: <Visibility />,
      content: (
        <VisibilityForm
          handleChange={(data) => handleModelChange('displayRules', data)}
          displayRules={model.displayRules}
        />
      ),
    },
    {
      name: 'properties',
      content: (
        <PropsForm
          properties={model.properties}
          handleChange={(data) => handleModelChange('properties', data)}
        />
      ),
    },
    {
      name: 'filtering',
      content: (
        <RulesForm
          title={'Filtering Rules'}
          rules={model.filterRules}
          handleChange={(data) => handleModelChange('filterRules', data)}
          model={model}
        />
      ),
    },
    {
      name: 'ordering',
      icon: <Sort />,
      content: (
        <OrderRules
          orderRules={model.orderRules}
          handleChange={(data) => handleModelChange('orderRules', data)}
        />
      ),
    },
    {
      name: 'actions',
      icon: <DoubleArrow />,
      content: (
        <ActionsForm
          actions={model.actions}
          handleChange={(data) => handleModelChange('actions', data)}
        />
      ),
    },
  ];

  const buttons = {
    favourite: !allowedTabs?.includes('favorite')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_FAVOURITE' }),
          action: setFavourite,
          icon: isFavourite ? (
            <Tooltip title={lookUp({ key: 'CONSOLE_REMOVE_FROM_FAVOURITE_HELPERTEXT' })}>
              <Star color="secondary" />
            </Tooltip>
          ) : (
            <Tooltip title={lookUp({ key: 'CONSOLE_ADD_TO_FAVOURITE_HELPERTEXT' })}>
              <StarOutline />
            </Tooltip>
          ),
        },
    logs: !allowedTabs?.includes('logs')
      ? {}
      : {
          label: lookUp({ key: 'CONSOLE_LOGS_TAB' }),
          link: `/access/logs/Catalog/${model.id}`,
          icon: <ViewList />,
        },
  };

  return isLoading ? (
    <Loader />
  ) : !allowedTabs?.includes(tab) && tabs.map((e) => e.name).includes(tab) ? (
    <NoAccess />
  ) : (
    <div className={classes.fullWidth}>
      <Tabbing
        tabs={tabs
          .sort(
            (a, b) =>
              allowedTabs.indexOf(a.name?.replace(' ', '-')) -
              allowedTabs.indexOf(b.name?.replace(' ', '-'))
          )
          .filter((e) => allowedTabs.includes(e.name))}
        buttons={[buttons.favourite, buttons.logs]}
      />
      <FormFooter
        isDeleting={isDeleting}
        deleteData={deleteCatalog}
        isSaving={isSaving}
        existPublishing={true}
        isPublishing={model.enabled}
        onSave={() => saveData(false)}
        onCancel={() => {
          navigate(
            window.history.state.idx === 0
              ? breadcrumbsLinks[breadcrumbsLinks.length - 2]?.link || '/apps/catalogs'
              : -1
          );
        }}
        onPublish={() => saveData(true)}
        saveButtonText={
          id ? lookUp({ key: 'CONSOLE_SAVE_BUTTON' }) : lookUp({ key: 'CONSOLE_CREATE_BUTTON' })
        }
        publishButtonText={
          model.enabled
            ? lookUp({ key: 'CONSOLE_UNPUBLISH_BUTTON' })
            : lookUp({ key: 'CONSOLE_PUBLISH_BUTTON' })
        }
        isNewItem={!id}
      />
      {id && (
        <EditorInfo
          createdAt={model.createdDate}
          modifiedAt={model.lastModifiedDate}
          modifiedBy={model.lastModifiedBy}
        />
      )}
    </div>
  );
}

AutomaticCatalogsForm.propTypes = {
  history: shape({}).isRequired,
  id: string,
  enqueueSnackbar: func.isRequired,
  isAutomatic: bool,
  setIsAutomatic: func.isRequired,
};

export default withSnackbar(AutomaticCatalogsForm);
