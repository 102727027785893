import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getVenuesEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Venues');
  if (!endpoint) throw Error('No Venues endpoint.');
  return endpoint;
};

const search = ({ page = 0, perPage = 10, query = '', sortBy, order } = {}) =>
  axios
    .get(
      makeApiWithQuery(`${getVenuesEndpoint()}/Search/${perPage}/${page}`, {
        q: query,
        sortBy,
        order
      })
    )
    .then((res) => res.data);

const get = (id) => axios.get(`${getVenuesEndpoint()}/${id}`)
  .then((res) => res?.data);

const create = (body) => axios.post(`${getVenuesEndpoint()}`, body)
  .then((res) => res?.data);

const edit = ({ id, name, resortId, decoration }) => axios.put(`${getVenuesEndpoint()}/${id}`, { name, resortId, decoration })
  .then((res) => res?.data);

const destroy = ({ id }) => axios.delete(`${getVenuesEndpoint()}/${id}`);

export { get, search, create, edit, destroy };
export default { get, search, create, edit, destroy };
