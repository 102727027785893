import { useState, useEffect, useRef } from 'react';
import {
  makeStyles,
  Box,
  Button,
  Chip,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { lookUp } from 'services/stringService';
import markerService from 'services/markerService';
import ConfirmDialog from 'components/ConfirmDialog';
import { Clear } from '@material-ui/icons';
import AlertService from 'services/alertService';
import { getSubtLang } from 'helpers/strings';
import { useNavigate } from 'react-router-dom';


function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useStyles = makeStyles((theme) => ({
  tagCloud: {
    zIndex: 101,
  },
  tagChips: {
    margin: '2px',
    zIndex: 101,
  },
  breakpointTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    margin: 0,
    zIndex: 101,
    width: '100%'
  },
  typeSelect: {
    backgroundColor: theme.palette?.secondary?.main
  },
  langSelectorContainer: {
    margin: theme.spacing(0, 0, 0, 1),
    padding: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    textTransform: 'none',
  },
  langSelector: {
    minWidth: '100px',
  },
  langLoaderContainer: {
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', 
    backgroundColor: theme.palette.text.divider,
  },
  subtitleMenuItem: {
    '&:hover': {
      backgroundColor: '#d9d9d9',
    }
  },
  deleteButtonContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  fadeInnerWrapper: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  subtitleFilter: {
    margin: 0,
    maxWidth: 250
  },
}));

export default function Selector(props) {
  const {
    contentId,
    markerId,
    selectedBrPt,
    switchPlayPause,
    resetSelectedBrPt,
    setSelectedTag,
    loadMarkers,
    brPtTypes,
    timelineListRef,
    brPtPanelListRef,
    featureWhitelist,
    setFeatureWhitelist,
    enqueueSnackbar,
    isBrPtsLineLoading,
    addBreakpoint,
    subtitleFilter,
    setSubtitleFilter
  } = props;

  const navigate = useNavigate();
  const subtLang = getSubtLang(window.location);

  const [markerTags, setMarkerTags] = useState({});
  const [tagCategories, setTagCategories] = useState([]);
  const classes = useStyles();
  const [sortings, setSortings] = useState([
    { label: lookUp({ key: 'CONSOLE_OCCURRENCE' }), tooltip: lookUp({ key: 'CONSOLE_OCCURRENCE_HELPERTEXT' }), sortBy: 'occurrence' },
    { label: lookUp({ key: 'CONSOLE_ALPHABETICAL' }), tooltip: lookUp({ key: 'CONSOLE_ALPHABETICAL_HELPERTEXT' }), sortBy: '' },
  ]);
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('');

  const isThereChosenSubtLang = useRef(false);
  const dropDownHashChange = useRef(false);

  const markersWithNoTags = ['Chapter', 'Intro', 'Face', 'Emotion', 'CreditRoll', 'EasterEgg', 'Advertisement', 'Cut'];
  const filtrableMarkers = ['Face', 'Emotion'];
  const excludeType = ['Clip'];

  const getFilters = (markers) => {
    const filters = markers.reduce((acc, m) => {
      Object.entries(m).filter(e => e[0] !== 'isLastMarker' && typeof e[1] === 'boolean').forEach(f => {
        if (!acc.includes(f[0])) {
          acc.push(f[0]);
        }
      })
      return acc;
    }, []);
    return filters.map(e => ({tagId: e, displayName: e}));
  };

  /**\
   * Retrieves the marker tags (if there are any) and puts them in markerTags state
   * @param {*} markerType type of the marker
   * @return if there are marker tags or not [boolean]
  \**/
  const getMarkerTags = (markerType, tags) => {
    const categories = tagCategories.length > 0 ? tagCategories : tags;
    const tagsCatIndex = categories.findIndex((tagCat) => tagCat.slice(0, -3) === markerType);
    resetSelectedBrPt(markerType);
    if (tagsCatIndex !== -1) {
      const queryParams = { type: categories[tagsCatIndex], itemLimit: 1000 };
      if (markersWithNoTags.includes(markerType)) queryParams.type = markerType;
      markerService
        .search(contentId, queryParams)
        .then((response) => {
          if (markersWithNoTags.includes(markerType)) {
            loadMarkers(markerType, { tagCloudParam: markerType });
          }
          // BE sends back the response paginated (pageContent) needed reduce to accumulate
          const newTags = markersWithNoTags.includes(markerType) && !filtrableMarkers.includes(markerType)
            ? []
            : filtrableMarkers.includes(markerType) 
              ? getFilters(response.pageContent)
              : response.pageContent.reduce((tagsAccum, page) => {
                const tagNames = Object.keys(page.tags).map((tagName) => ({
                  tagId: tagName,
                  ...page.tags[tagName],
                  isSelected: tagName === markerId,
                }));
                return tagsAccum.concat(tagNames);
              }, []);
          if (newTags.map((e) => e.tagId).includes(markerId)) {
            loadMarkers(markerType, { tagCloudParam: markerId });
          }
          newTags.sort((tagA, tagB) => (tagA.displayName >= tagB.displayName ? 1 : -1));
          setMarkerTags((tags) => ({ ...tags, [`${markerType}Tag`]: newTags }));
        })
        .catch((error) => console.error(error));
    }
    // true if there are markerTags for the given tagCategory false if not
    return tagsCatIndex !== -1;
  };

  const highlightChip = (markerType, id) => {
    const updatedTags = markerTags[markerType]?.map((marker) => {
      marker.isSelected = false;
      return marker;
    });
    updatedTags.find((e) => e.tagId === id).isSelected = true;
    setMarkerTags((tags) => ({ ...tags, markerType: updatedTags }));
    setSelectedTag({ ...updatedTags.find((e) => e.tagId === id), markerType: markerType[0] });
  };

  const handleChipClick = (markerTag) => {
    if (switchPlayPause) {
      switchPlayPause('pause');
    }
    resetSelectedBrPt(selectedBrPt.type);
    highlightChip([`${selectedBrPt.type}Tag`], markerTag.tagId);
    switch (selectedBrPt.type) {
      case 'Face':
      case 'Emotion':
        break;
      default:
        return loadMarkers(selectedBrPt.type, { tagCloudParam: markerTag.tagId });
    }
  };

  const deleteAll = (markerType) => {
    markerService
    .deleteMarkersByType(contentId, markerType)
    .then(() => {
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({ key: 'CONSOLE_MARKERS_DELETED_TEMPLATE', markerType: lookUp({ key: `CONSOLE_${markerType}` }) })
      });
    })
    .catch((error) => {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: markerType })
      });
    });
  }

  const deleteTag = (tagKey, displayName) => {
    markerService
      .deleteTag(contentId, selectedBrPt.type, tagKey)
      .then(() => {
        AlertService.displaySuccess({
          msgBar: enqueueSnackbar,
          message: lookUp({ key: 'CONSOLE_TAG_DELETED_TEMPLATE', type: displayName })
        });
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE', type: displayName })
        });
      });
  };

  const filtered = (e) => {
    if (!filter || filter.length < 2) {
      return true;
    }
    const searchRegex = new RegExp(escapeRegExp(filter), 'i');
    return searchRegex.test(e.displayName);
  };

  useEffect(() => {
    const getTags = async () => {
      try {
        const tagCategories = await markerService.getTags();
        setTagCategories([...tagCategories, ...markersWithNoTags.map((e) => e + 'Tag')]);
        return [...tagCategories, ...markersWithNoTags.map((e) => e + 'Tag')];
      } catch (error) {
        console.error(error);
      }
    };
    getTags().then((tags) => getMarkerTags(selectedBrPt.type, tags));
  }, []);

  useEffect(() => {
    if (
      window.location.hash?.split('|')[0].includes('#Subtitle') &&
      markerTags?.SubtitleTag?.length &&
      !isThereChosenSubtLang.current
    ) {
      const chip = subtLang
        ? markerTags.SubtitleTag.find(({ tagId }) => tagId === subtLang) || markerTags.SubtitleTag[0]
        : markerTags.SubtitleTag[0];

      isThereChosenSubtLang.current = true;

      handleChipClick(chip)
        .then(() => {
          const langCode = chip.tagId;
          dropDownHashChange.current = true;
          navigate(`/content/${contentId}/editor#Subtitle_${langCode}`);
          dropDownHashChange.current = false;
        });
    } else if (!window.location?.hash.split('|')[0]?.includes('#Subtitle')) {
      isThereChosenSubtLang.current = false;
    }
  }, [window.location.hash, markerTags, subtLang]);

  useEffect(() => {
    // This is because we need to handle when the user changes the #Hash in the URL
    // by writing a new value there
    if (
      !dropDownHashChange.current &&
      markerTags?.SubtitleTag?.length &&
      selectedBrPt?.type === 'Subtitle'
    ) {
      handleChipClick(markerTags.SubtitleTag[0]);
    }
  }, [window.location.hash]);

  return (
    <Grid container direction="row" className={classes.tagCloud}>
      <Grid item className={classes.breakpointTitle}>
        <Select
          labelId="brPtType_select"
          margin="dense"
          variant="outlined"
          displayEmpty
          className={classes.typeSelect}
          value={selectedBrPt.type}
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          onChange={(event) => {
            const markerType = event.target.value;
            if (switchPlayPause) {
              switchPlayPause('pause');
            }
            resetSelectedBrPt(markerType);
            window.history.replaceState(null, '', `/content/${contentId}/editor#${markerType}`);
            setSelectedTag({ displayName: '' });
            const areThereMarkerTags = getMarkerTags(markerType);
            // only load the markers (breakpoints) if there are no tags
            if (!areThereMarkerTags) {
              loadMarkers(markerType, null);
            } else {
              timelineListRef?.current?.resetAfterColumnIndex(0);
              brPtPanelListRef?.current?.resetAfterColumnIndex(0);
            }
          }}
        >
          {Object.keys(brPtTypes)
            .filter((e) => !excludeType.includes(e))
            .map((brPtType, index) => (
              <MenuItem value={brPtType} key={`${brPtType}_${index}`}>
                {brPtTypes[brPtType]}
              </MenuItem>
            ))}
        </Select>

        {selectedBrPt.type !== 'Subtitle' && (
          <div className={classes.deleteButtonContainer}>
            <ConfirmDialog
              onConfirm={() => deleteAll(selectedBrPt.type)}
              message={lookUp({
                key: 'CONSOLE_DELETE_ALL_MARKERS_MESSAGE_TEMPLATE',
                type: lookUp({ key: `CONSOLE_${selectedBrPt.type}` }),
              })}
              dialogText={lookUp({ key: 'CONSOLE_DELETE_HEADING' })}
              hint={lookUp({
                key: 'CONSOLE_DELETE_ALL_MARKERS_MESSAGE_TEMPLATE',
                type: lookUp({ key: `CONSOLE_${selectedBrPt.type}` }),
              })}
            />
          </div>
        )}

        {selectedBrPt.type === 'Subtitle' && (
          <Fade in={selectedBrPt.type === 'Subtitle'} timeout={1000}>
            <Box className={classes.fadeInnerWrapper}>
              <Button className={classes.langSelectorContainer} style={{ alignItems: 'start' }}>
                {isBrPtsLineLoading ? (
                  <Box className={classes.langLoaderContainer}>
                    <CircularProgress color="inherit" size={24} />
                  </Box>
                ) : (
                  <TextField
                    select
                    value={subtLang}
                    onChange={(event) => {
                      handleChipClick(
                        markerTags[`${selectedBrPt.type}Tag`]?.find(
                          (mrk) => mrk.tagId === event.target.value,
                        ),
                      ).then(() => {
                        dropDownHashChange.current = true;
                        navigate(`/content/${contentId}/editor#Subtitle_${event.target.value}`);
                        dropDownHashChange.current = false;
                      });
                    }}
                    className={classes.langSelector}
                  >
                    {markerTags[`${selectedBrPt.type}Tag`]
                      ?.filter((marker) => /[a-z]{2}-[A-Z]{2}/.test(marker?.tagId))
                      .map((marker) => (
                        <MenuItem
                          key={marker.tagId}
                          value={marker.tagId}
                          className={classes.subtitleMenuItem}
                        >
                          {marker.displayName}
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Button>
              <IconButton
                onClick={() => {
                  addBreakpoint(selectedBrPt.type);
                  setSubtitleFilter('');
                }}
                style={{ paddingTop: '8px' }}
              >
                <Add />
              </IconButton>
            </Box>
          </Fade>
        )}
        {selectedBrPt.type === 'Subtitle' && (
          <Box flex="1" display="flex" alignItems="center" justifyContent="flex-end">
            <TextField
              fullWidth
              margin="dense"
              placeholder={lookUp({ key: 'CONSOLE_FILTER' })}
              value={subtitleFilter}
              onChange={(e) => setSubtitleFilter(e.target.value)}
              className={classes.subtitleFilter}
              InputProps={{
                endAdornment: (
                  <IconButton
                    edge="end"
                    size="small"
                    disableRipple
                    disableFocusRipple
                    disabled={!subtitleFilter}
                    onClick={() => setSubtitleFilter('')}
                  >
                    <Clear />
                  </IconButton>
                ),
              }}
            />
          </Box>
        )}
      </Grid>
      {false && filtrableMarkers.includes(selectedBrPt.type) && (
        <TextField
          fullWidth
          margin="dense"
          placeholder={lookUp({ key: 'CONSOLE_FILTER' })}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton
                edge="end"
                size="small"
                disableRipple
                disableFocusRipple
                disabled={!filter}
                onClick={() => setFilter('')}
              >
                <Clear />
              </IconButton>
            ),
          }}
        />
      )}
      {selectedBrPt.type !== 'Subtitle' && (
        <Grid item xs={12}>
          {!markerTags[`${selectedBrPt.type}Tag`] ? (
            <Typography variant="subtitle2">
              {'\u00A0'}
              {lookUp({ key: 'CONSOLE_NO_TAGS_FOR_MARKER' })}
            </Typography>
          ) : (
            markerTags[`${selectedBrPt.type}Tag`] &&
            markerTags[`${selectedBrPt.type}Tag`]
              .filter((e) => filtered(e))
              .sort((a, b) => (!sort ? 0 : parseInt(b[sort]) - parseInt(a[sort])))
              .map((markerTag, index, array) => {
                if (array.length === 0) return null;
                if (markersWithNoTags.includes(markerTag)) return null;
                let tagLabel = `${markerTag.displayName}`;
                if (selectedBrPt.type !== 'Subtitle' && markerTag.occurrence > 1) {
                  tagLabel += `\u00A0\u00A0(${markerTag.occurrence})`;
                }
                return filtrableMarkers.includes(selectedBrPt.type) ? (
                  <Chip
                    key={`${markerTag.tagId}_${index}`}
                    variant={featureWhitelist.includes(markerTag.tagId) ? 'default' : 'outlined'}
                    color={featureWhitelist.includes(markerTag.tagId) ? 'primary' : 'secondary'}
                    size="small"
                    clickable
                    className={classes.tagChips}
                    onClick={() => {
                      setFeatureWhitelist((f) =>
                        f[0] === markerTag.tagId ? [] : [markerTag.tagId],
                      );
                    }}
                    label={tagLabel}
                  />
                ) : (
                  <Chip
                    key={`${markerTag.tagId}_${index}`}
                    variant={markerTag.isSelected ? 'default' : 'outlined'}
                    color={markerTag.isSelected ? 'primary' : 'secondary'}
                    size="small"
                    clickable
                    className={classes.tagChips}
                    onClick={() => handleChipClick(markerTag)}
                    onDelete={() => {
                      deleteTag(
                        markerTag.tagId,
                        markerTag.displayName ||
                          markerTag.title ||
                          markerTag.labelId ||
                          markerTag.tagId,
                      );
                      resetSelectedBrPt(selectedBrPt.type);
                      loadMarkers(selectedBrPt.type, { tagCloudParam: markerTag.tagId });
                    }}
                    label={tagLabel}
                  />
                );
              })
          )}
        </Grid>
      )}
    </Grid>
  );
}
