import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookUp } from 'services/stringService';
import { shape, string, func } from 'prop-types';
import { withSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { People, HourglassEmpty, Apple, Twitter, Facebook } from '@material-ui/icons';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import { green, red } from '@material-ui/core/colors';
import ThemedButton from 'components/ThemedButton';
import { Grid, makeStyles, Typography, Avatar, Tooltip } from '@material-ui/core';
import customersService from 'services/customersService';
import subscriptionsService from 'services/subscriptionsService';
import { BreadcrumbsComponent, Tabbing, Loader, CreatedOn } from 'components';
import PlayBackHistory from './PlayBackHistory';
import HistoryTableComponent from 'components/HistoryTable';
import Followed from './Followed';
import Devices from './Devices';
import AlertService from 'services/alertService';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  marginX: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  marginLeft: {
    marginLeft: theme.spacing(1.5),
  },

  statusActiveRow: {
    backgroundColor: green[100],
    '&:hover': {
      backgroundColor: green[200] + ' !important',
    },
  },
  statusExpiredRow: {
    backgroundColor: red[100],
    '&:hover': {
      backgroundColor: red[200] + ' !important',
    },
  },
}));

function SubscriptionDetails(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = props;

  const { id, tab } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [customer, setCustomer] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      try {
        const customer = await customersService.getReportById(id);
        setCustomer(customer);
        setIsLoading(false);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
        setIsLoading(false);
      }
    };
    id && getData();
  }, [id]);

  const softDelete = async () => {
    try {
      await customersService.softDelete(id);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({
          key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE',
          title: lookUp({ key: `CONSOLE_${'Customer'}` }),
        }),
      });
      navigate(window.history.state.idx === 0 ? '/' : -1);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_CUSTOMER' }),
        }),
      });
    }
  };

  const hardDelete = async () => {
    try {
      await customersService.hardDelete(id);
      AlertService.displaySuccess({
        msgBar: enqueueSnackbar,
        message: lookUp({
          key: 'CONSOLE_DELETED_MESSAGE_TEMPLATE',
          title: lookUp({ key: `CONSOLE_Customer` }),
        }),
      });
      navigate(window.history.state.idx === 0 ? '/' : -1);
    } catch (error) {
      AlertService.displayError({
        msgBar: enqueueSnackbar,
        error,
        context: lookUp({
          key: 'CONSOLE_DELETE_ERROR_MESSAGE_TEMPLATE',
          title: lookUp({ key: 'CONSOLE_CUSTOMER' }),
        }),
      });
    }
  };

  const breadcrumbs = [
    {
      link: '/engagement',
      text: lookUp({ key: 'CONSOLE_ENGAGEMENT' }),
    },
    {
      link: '/engagement/subscriptions',
      text: lookUp({ key: 'CONSOLE_SUBSCRIPTIONS' }),
    },
    {
      text: lookUp({ key: 'CONSOLE_SUBSCRIPTION_DETAILS' }),
      isCurrent: true,
    },
  ];

  const networkIcon = (network) => {
    switch (network) {
      case 'Apple':
        return <Apple />;
      case 'Google':
        return <FontAwesomeIcon icon={['fab', 'google']} />;
      case 'Twitter':
        return <Twitter />;
      case 'Facebook':
        return <Facebook />;
      case '':
        return '';
      default:
        return '';
    }
  };

  const rowColor = (item) => {
    switch (item.subscriptionStatus) {
      case 'Active':
        return classes.statusActiveRow;
      case 'Expired':
        return classes.statusExpiredRow;
      default:
        return null;
    }
  };

  const theadElements = [
    {
      name: 'Platform',
      label: lookUp({ key: 'CONSOLE_PLATFORM' }),
      align: 'left',
      smallColumn: true,
    },
    {
      name: 'productName',
      label: lookUp({ key: 'CONSOLE_PRODUCTNAME' }),
      align: 'left',
    },
    {
      name: 'productId',
      label: lookUp({ key: 'CONSOLE_PRODUCTID' }),
      align: 'left',
    },
    {
      name: 'productType',
      label: lookUp({ key: 'CONSOLE_PRODUCTTYPE' }),
      align: 'left',
    },
    {
      name: 'price',
      label: lookUp({ key: 'CONSOLE_PRICE' }),
      align: 'left',
    },
    {
      name: 'status',
      label: lookUp({ key: 'CONSOLE_STATUS' }),
      align: 'left',
      smallColumn: true,
    },
    {
      name: 'PurchaseDate',
      label: lookUp({ key: 'CONSOLE_PURCHASEDATE' }),
      align: 'right',
    },
    {
      name: 'Expiry',
      label: lookUp({ key: 'CONSOLE_EXPIRY' }),
      align: 'right',
    },
  ];

  const tbodyElements = [
    {
      name: 'Platform',
      align: 'left',
      render: (item) => (
        <Tooltip title={`${item.subscriptionStatus.store} network`}>
          <Avatar>{networkIcon(item.subscriptionStatus.store)}</Avatar>
        </Tooltip>
      ),
    },
    {
      name: 'Product Name',
      align: 'left',
      render: (item) => <p>{item.subscriptionStatus.productName}</p>,
    },
    {
      name: 'productId',
      align: 'left',
      render: (item) => <p>{item.subscriptionStatus.productId}</p>,
    },
    {
      name: 'Product Type',
      align: 'left',
      render: (item) => <p>{item.subscriptionStatus.productType}</p>,
    },
    {
      name: 'Price',
      align: 'left',
      render: (item) => (
        <p>
          {item.subscriptionStatus.price}({item.subscriptionStatus.currency})
        </p>
      ),
    },
    {
      name: 'Status',
      align: 'left',
      render: (item) => <p>{item.subscriptionStatus.status}</p>,
    },
    {
      name: 'PurchaseDate',
      align: 'right',
      render: (item) => {
        return (
          <p>
            <CreatedOn date={item.subscriptionStatus.purchaseDate} />
          </p>
        );
      },
    },
    {
      name: 'Expiry',
      align: 'right',
      render: (item) => {
        return (
          <p>
            <CreatedOn date={item.subscriptionStatus.expiresDate} />
          </p>
        );
      },
    },
  ];

  const tDeviceheadElements = [
    {
      name: 'deviceType',
      label: lookUp({ key: 'CONSOLE_DEVICETYPE' }),
      align: 'left',
    },
    {
      name: 'deviceModel',
      label: lookUp({ key: 'CONSOLE_DEVICEMODEL' }),
      align: 'left',
    },
    {
      name: 'dateAdded',
      label: lookUp({ key: 'CONSOLE_DATEADDED' }),
      align: 'left',
    },
    {
      name: 'lastSeen',
      label: lookUp({ key: 'CONSOLE_LASTSEENDATE' }),
      align: 'left',
    },
  ];

  const tDevicebodyElements = [
    {
      name: 'deviceType',
      align: 'left',
      render: (item) => {
        return <p>{item.deviceType}</p>;
      },
    },
    {
      name: 'deviceModel',
      align: 'left',
      render: (item) => {
        return <p>{item.deviceModel}</p>;
      },
    },
    {
      name: 'dateAdded',
      align: 'left',
      render: (item) => <p>{item.dateAdded}</p>,
    },
    {
      name: 'lastSeen',
      align: 'left',
      render: (item) => <p>{item.lastSeen}</p>,
    },
  ];

  const tabs = [
    {
      number: 0,
      name: 'Subscription History',
      icon: <HourglassEmpty />,
      content: id && (
        <HistoryTableComponent
          pageTitle={lookUp({ key: 'CONSOLE_SUBSCRIPTIONS_TITLE' })}
          loadData={() => subscriptionsService.history(id)}
          tableHeadElements={theadElements}
          tableBodyElements={tbodyElements}
          rowColorFunc={rowColor}
          handleChangePage={(token) => subscriptionsService.historyPage(token)}
        />
      ),
    },
    {
      number: 1,
      name: 'PlayBack History',
      icon: <SkipPreviousIcon />,
      content: id && <PlayBackHistory id={id} />,
    },
    {
      number: 2,
      name: 'Devices',
      icon: <DeviceUnknownIcon />,
      content: <Devices id={id} />,
    },
    {
      number: 3,
      name: 'Followed',
      icon: <People />,
      content: <Followed id={id} />,
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.root}>
      <Helmet title={lookUp({ key: 'CONSOLE_Subscription_Details' })} />
      <BreadcrumbsComponent breadcrumbs={breadcrumbs} goToCrumb={() => {}} />
      <div>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          className={classes.marginX}
        ></Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>Customer #:</strong>
              {customer.customerId ? customer.customerId : '-'}
            </Typography>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>Last activity:</strong>
              {customer.lastSeenDate ? <CreatedOn date={customer.lastSeenDate} /> : '-'}
            </Typography>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>Subscriber since:</strong>
              {customer.registrationDate ? <CreatedOn date={customer.registrationDate} /> : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>E-mail:</strong>
              {customer.emailAddress ? customer.emailAddress : '-'}
            </Typography>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>Product name:</strong>
              {customer.productName ? customer.productName : '-'}
            </Typography>
            <Typography gutterBottom>
              <strong className={classes.marginRight}>Nr. of plays:</strong>
              {customer.numberOfPlays ? customer.numberOfPlays : '-'}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Tabbing tabs={tabs} />
      <Grid
        container
        alignItems={'center'}
        justifyContent={'space-between'}
        className={classes.marginX}
      >
        <Grid item>
          <Grid container alignItems={'center'} spacing={2} justifyContent={'flex-start'}>
            <ThemedButton color="secondary" style={{ margin: '8px' }} onClick={softDelete}>
              {lookUp({ key: 'CONSOLE_RESET_BUTTON' })}
            </ThemedButton>
            <ThemedButton color="error" onClick={hardDelete}>
              {lookUp({ key: 'CONSOLE_DELETE_BUTTON' })}
            </ThemedButton>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems={'center'}
            justifyContent={'flex-end'}
            className={classes.marginX}
          >
            <ThemedButton
              onClick={() => navigate(window.history.state.idx === 0 ? '/' : -1)}
              color="primary"
            >
              {lookUp({ key: 'CONSOLE_DELETE_BUTTON' })}
            </ThemedButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

SubscriptionDetails.propTypes = {
  enqueueSnackbar: func.isRequired,
};

export default withSnackbar(SubscriptionDetails);
