import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { lookUp } from 'services/stringService';
import { useSearchParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ThemedButton from 'components/ThemedButton';
import AddButton from 'components/AddButton';
import omit from 'lodash/omit';

const SEARCH_TIMEOUT = 300;

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

const Toolbar = ({
  create,
  hideSearch,
  label,
  helperText,
  color = 'inherit',
  size = 'small',
  queryBy = 'q',
  buttons = [],
  triggerSearch = () => {},
}) => {
  const [search, setSearch] = useSearchParams();
  const [q, setQ] = useState(search.get(queryBy));
  const [searchTimeout, setSearchTimeout] = useState(0);

  const classes = useStyles();

  // Allows other components to clear filters and reset the search value, fix issues in which the local search value persisted even if the query param queryBy on the url was removed
  useEffect(() => {
    if (!search.get(queryBy)) {
      setQ(null);
    }
  }, [search]);

  const createButton = {
    component: (
      <AddButton
        tooltipText={lookUp({ key: 'CONSOLE_ADD_BUTTON' })}
      />
    ),
  };

  const handleClear = () => {
    setSearch((search) => {
      const params = omit({ ...Object.fromEntries(search.entries())}, [queryBy]);
      return {
        ...params
      };
    });
  };

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (value === undefined || value === '') delete searchObj[key];
    setSearch(searchObj);
  };

  const handleTextSearch = async (event) => {
    setQ(event.target.value);
    setSearchTimeout(() => clearTimeout(searchTimeout));
    const timeout = setTimeout(
      async () => {
        setParam(queryBy, event.target.value);
      },
      event.key === 'Enter' ? 0 : SEARCH_TIMEOUT
    );
    setSearchTimeout(timeout);
  };

  return (
    <Box flex={1}>
      <Grid container spacing={1} justifyContent="space-between">
        {!hideSearch && (
          <Grid item xs={6} container>
            <Grid item xs>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                value={q || ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!!search.get(queryBy) && (
                        <IconButton
                          onClick={handleClear}
                          size={size}
                          disableRipple
                          disableFocusRipple
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                      {triggerSearch && (
                        <IconButton
                          onClick={triggerSearch}
                          size={size}
                          color="primary"
                          disableRipple
                          disableFocusRipple
                        >
                          <SearchIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
                label={label || lookUp({ key: 'CONSOLE_SEARCH' })}
                onChange={handleTextSearch}
                helperText={helperText}
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        {/* FIXME reduce code complexity, remove nested ternaries */}
        {(create ? [...buttons, createButton] : buttons)?.length > 0 && (
          <Grid item className={classes.actionsContainer}>
            {(create ? [...buttons, createButton] : buttons).map((button, i) =>
              button.component ? (
                <Box key={i}>{button.component}</Box>
              ) : button.link ? (
                <Link item key={i} to={button.to} className={classes.link}>
                  <ThemedButton
                    startIcon={button.startIcon}
                    endIcon={button.endIcon}
                    size={size}
                    color={color}
                    variant="text"
                  >
                    {button.child || button.label}
                  </ThemedButton>
                </Link>
              ) : (
                <ThemedButton item key={i} size={size} color={color} variant="text" {...button}>
                  {button.child || button.label}
                </ThemedButton>
              ),
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

Toolbar.propTypes = {
  onAdd: PropTypes.func,
  tooltipText: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
  navigateTo: PropTypes.string,
};

export default Toolbar;
