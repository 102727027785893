import { useState } from 'react';
import { lookUp } from 'services/stringService';
import {
  makeStyles,
  Grid,
  TextField,
  IconButton,
  MenuItem,
  Typography,
  ListSubheader,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSearchParams } from 'react-router-dom';
import { DropdownWithSearch, ThemedButton } from 'components';

const useStyles = makeStyles((theme) => ({
  toolbarButton: {
    backgroundColor: '#fff',
    paddingLeft: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const QueryBar = ({
  queryOptions,
  filters,
  label,
  queryBy = 'q',
  showSearch = false,
  triggerSearch,
}) => {
  const [search, setSearch] = useSearchParams();
  const [searchTimeout, setSearchTimeout] = useState(0);
  const [queryKey, setQueryKey] = useState(queryBy);
  const classes = useStyles();

  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (!value) delete searchObj[key];
    setSearch(searchObj);
  };

  const handleTextSearch = async (event) => {
    setSearchTimeout(() => clearTimeout(searchTimeout));
    const timeout = setTimeout(
      async () => {
        setParam(queryBy, event.target.value);
      },
      event.key === 'Enter' ? 0 : 500
    );
    setSearchTimeout(timeout);
  };

  return (
    <Grid container spacing={1}>
      {showSearch && queryBy && (
        <Grid item xs={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin="dense"
            defaultValue={search.get(queryBy) || ''}
            fullWidth
            label={label || lookUp({ key: 'CONSOLE_SEARCH' })}
            onChange={handleTextSearch}
            helperText={!queryOptions && lookUp({ key: 'CONSOLE_SEARCH_HELPERTEXT' })}
          />
          {queryOptions && (
            <QueryTypeSelector options={queryOptions} queryBy={queryKey} setQueryKey={setQueryKey} />
          )}
        </Grid>
      )}
      {filters?.length > 0 && filters.map((filter, i) => (
        <Grid key={i} item xs={3}>
          {filter.button ? (
            <ThemedButton {...filter} className={classes.toolbarButton} />
          ) : filter.dynamic ? (
            <DropdownWithSearch {...filter} textFieldProps={{ style: { minWidth: 160 } }} />
          ) : filter.component ? (
            filter.component
          ) : Array.isArray(filter.options) ? (
            filter.options.some((option) => option !== '') ? (
              <ArrayDropdown key={i} dropdown={filter} />
            ) : null
          ) : (
            <CategorizedDropdown key={i} dropdown={filter} />
          )}
        </Grid>
      ))}
      {!showSearch && !!triggerSearch && (
        <Grid item>
          <IconButton color="primary" onClick={() => triggerSearch()}>
            <Refresh />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export default QueryBar;

const dropdownOptions = ({ options, category }) => {
  if (!options.length) return;
  const menuItems = options
    .sort((a, b) => a?.label?.localeCompare(b.label))
    .map((option) => (
      <MenuItem key={option.value} value={option.value}>
        <Typography>{option.label}</Typography>
      </MenuItem>
    ));
  if (category) menuItems.unshift(<ListSubheader>{category}</ListSubheader>);
  return menuItems;
};

const ArrayDropdown = ({ dropdown }) => {
  const [search, setSearch] = useSearchParams();
  const setParam = (key, value) => {
    const searchObj = { ...Object.fromEntries(search.entries()), [key]: value };
    if (!value) delete searchObj[key];
    setSearch(searchObj);
  };

  return (
    <Autocomplete
      key={dropdown.key}
      disablePortal
      selectOnFocus
      handleHomeEndKeys
      disableClearable={dropdown.disableClearable}
      value={dropdown.options?.find((e) => e.value === search.get(dropdown.key)) || ''}
      options={dropdown.options || []}
      onChange={(event, selection) => {
        setParam(dropdown.key, selection?.value || null);
      }}
      getOptionSelected={() => {
        return dropdown.options?.find((e) => e.value === search.get(dropdown.key));
      }}
      getOptionLabel={(option) => option?.label || ''}
      renderInput={(params) => <TextField {...params} label={dropdown.label} />}
    />
  );
};

const CategorizedDropdown = ({ dropdown }) => {
  const [search, setSearch] = useSearchParams();
  const categories = Object.keys(dropdown?.options || {});
  return (
    <TextField
      select
      variant="standard"
      fullWidth
      InputProps={{ disableUnderline: true }}
      SelectProps={{
        displayEmpty: true,
        autoWidth: true,
        MenuProps: { disableScrollLock: true },
      }}
      value={search.get(dropdown.key) || ''}
      onChange={(e) => dropdown.change(e.target.value)}
    >
      <MenuItem value={''}>
        <Typography variant="button">
          {dropdown.loading
            ? `${dropdown.label} ${lookUp({ key: 'CONSOLE_LOADING' })}`
            : `${dropdown.label}`}
        </Typography>
      </MenuItem>
      {categories.map((cat) => dropdownOptions({ options: dropdown.options[cat], category: cat }))}
    </TextField>
  );
};

const QueryTypeSelector = ({ options, queryKey, setQueryKey }) => {
  return (
    !!options?.length && (
      <Typography variant="caption" gutterBottom>
        {lookUp({ key: 'CONSOLE_SEARCH_BY' })}
        {options.map((e) => (
          <>
            <input type="radio" onClick={() => setQueryKey(e)} checked={queryKey === e} />
            {lookUp({ key: `CONSOLE_${e}` })}
          </>
        ))}
      </Typography>
    )
  );
};
