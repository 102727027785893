import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import { shape } from 'prop-types';
import { create } from 'jss';
import createTheme from '../theme';
import { Grid, CssBaseline, CircularProgress, Paper, Typography } from '@material-ui/core';
import { refreshToken, handleUserResponse } from 'services/customersService';
import { setTokenToCookies } from 'helpers/common';
import { withSnackbar } from 'notistack';
import AlertService from 'services/alertService';

const BackgroundDiv = styled.div`
  min-width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)}px;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function LoginWithTemporaryToken(props) {
  const { enqueueSnackbar } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state) => state.themeReducer);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const lastRoute = useSelector((state) => state.auth.lastRoute);

  const { token } = useParams();

  useEffect(() => {
    refreshToken(token, dispatch)
      .then((userData) => {
        setTokenToCookies(userData.token);
        handleUserResponse(userData, dispatch);
      })
      .then(navigate(`/dashboard/`))
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
        });
      });
  }, []);

  return (
    <BackgroundDiv>
      <img src="../static/img/blur.jpg" style={{ width: '100vw', height: '100vh' }} />
      <Root style={{ position: 'absolute' }}>
        <CssBaseline />

        <Wrapper>
          <ImageWrapper>
            <img alt="Entertainment" src="/static/img/logo_white.svg" />
          </ImageWrapper>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Logging in
          </Typography>
          {isLoading && (
            <Grid item align="center">
              <CircularProgress color={'primary'} />
            </Grid>
          )}
        </Wrapper>
      </Root>
    </BackgroundDiv>
  );
}

LoginWithTemporaryToken.propTypes = {
  history: shape({}).isRequired,
};

export default withSnackbar(LoginWithTemporaryToken);
