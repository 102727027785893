import { combineReducers } from 'redux';
import auth from './authReducer';
import brandStyle from "./brandStyleReducer";
import content from './contentReducer';
import insights from './insightsReducer';
import lexicons from './localizationReducer';
import libraryEnums from './libraryEnumsReducer';
import navigation from './navigationReducer';
import parameters from './parametersReducer';
import preview from './previewReducer';
import sku from './skuReducer';


export default combineReducers({
  auth,
  brandStyle,
  content,
  insights,
  lexicons,
  libraryEnums,
  navigation,
  parameters,
  preview,
  sku,
});
