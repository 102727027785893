import axios from 'axios';
import { getConsoleApiEndpoint } from './apiEndpoints';

const getMessagingEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Messaging');
  if (!endpoint) throw Error('No Messaging endpoint.');
  return endpoint;
};

const create = (level, id, body) => axios.post(`${getMessagingEndpoint()}/${level}/${id}`, body)
  .then((resp) => resp);

export { create };

export default {
  create
};
