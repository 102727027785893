import React from 'react';
import PropTypes from 'prop-types';
import PublishedIcon from 'assets/icons/PublishedComponent'
import UnPublishedIcon from 'assets/icons/UnPublishedComponent'

const PublishStatusIcon = ({ isPublished = false, ...rest }) =>
  isPublished ? <PublishedIcon {...rest} /> : <UnPublishedIcon {...rest} />;

PublishStatusIcon.propTypes = {
  isPublished: PropTypes.bool,
};

export default PublishStatusIcon;
