import axios from 'axios';
import { makeApiWithQuery } from 'helpers/common';
import { getConsoleApiEndpoint } from './apiEndpoints';


const getMerchandiseEndpoint = () => {
  const endpoint = getConsoleApiEndpoint('Order');
  if (!endpoint) throw Error('No Merchandise endpoint.');
  return endpoint;
};

const search = ({ q = '', sortBy = 'name asc', ...rest } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getMerchandiseEndpoint()}/Search`, {
      sortBy,
      q,
      ...rest
    }))
    .then(response => response?.data);
};

const getAll = ({ query = '', sortBy, order } = {}) => {
  return axios.get(
    makeApiWithQuery(`${getMerchandiseEndpoint()}/Search/`, {
      q: query,
      sortBy,
      order
    })
  )
    .then(response => response?.data);
};

const create = (model) => axios.post(getMerchandiseEndpoint(), model)
  .then(response => response?.data);

const get = (id) => axios.get(`${getMerchandiseEndpoint()}/${id}`)
  .then(response => response?.data);

const edit = (model) => axios.put(`${getMerchandiseEndpoint()}/${model.id}`, model)
  .then(response => response?.data);

const getHistory = (id) => {
  return axios.get(`${getMerchandiseEndpoint()}/${id}/History`)
    .then(response => response?.data)
};

const remove = (id) => axios.delete(`${getMerchandiseEndpoint()}/${id}`)
  .then(response => response?.data);

export { search, getAll, create, get, edit, remove, getHistory, };

export default {
  search,
  getAll,
  create,
  get,
  edit,
  remove,
  getHistory,
};
