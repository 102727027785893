import {
  SET_LAST_CONTENT_TAB_OPENED,
  SET_CONTENT_FORMATS,
  SET_CONTENT_DRMS,
  SET_CONTENT_STREAMS,
  SET_CONTENT_TYPES,
  SET_AUTO_CLOSE,
  SET_IMPORT_EXTRA_FIELDS,
  SET_TITLE_LINK,
  SET_EDITABLE_PLAYLIST,
  SET_CHIME_DEFAULT_BCKG,
  SET_REMIX_SUBASSET_REDIRECT,
  SET_CONTENT_TYPE,
  SET_CONTENT_DATA, 
  SET_AUTO_CUT,
  SET_JOB_STATUS_KEYS,
} from '../actions/contentAction';

const initialState = {
  lastContentTabOpened: 0,
  contentFormats: [],
  contentDrms: [],
  contentStreams: [],
  contentTypes: [],
  autoClose: { toClose: false, paramChecked: false },
  importDialogExtraFields: { toDisplay: true, paramChecked: false },
  titleLink: { remix: 'metadata', content: 'metadata', paramChecked: false},
  editablePlaylist: { toOpen: false, paramChecked: false },
  isDefaultBckgForChime: { isDefault: false, paramChecked: false },
  isChildContentRedirect: { on: false, url: '', paramChecked: false },
  isAutoCut: { on: false, paramChecked: false },
  contentData: {},
  contentType: '',
  jobStatusKeys: {},
};

export default function contentReducer (state = initialState, action) {
  switch (action.type) {
    case SET_LAST_CONTENT_TAB_OPENED:
      return {
        ...state,
        lastContentTabOpened: action.lastContentTabOpened
      };
    case SET_CONTENT_FORMATS:
      return {
        ...state,
        contentFormats: action.contentFormats
      };
    case SET_CONTENT_DRMS:
      return {
        ...state,
        contentDrms: action.contentDrms
      };
    case SET_CONTENT_STREAMS:
      return {
        ...state,
        contentStreams: action.contentStreams
      };
    case SET_CONTENT_TYPES:
      return {
        ...state,
        contentTypes: action.contentTypes
      };
    case SET_AUTO_CLOSE:
      return {
        ...state,
        autoClose: action.autoClose
      };
    case SET_IMPORT_EXTRA_FIELDS:
      return {
        ...state,
        importDialogExtraFields: action.importDialogExtraFields,
      };
    case SET_TITLE_LINK:
      return {
        ...state,
        titleLink: action.titleLink,
      };
    case SET_EDITABLE_PLAYLIST:
      return {
        ...state,
        editablePlaylist: action.editablePlaylist,
      };
    case SET_CHIME_DEFAULT_BCKG:
      return {
        ...state,
        isDefaultBckgForChime: action.isDefaultBckgForChime,
      };
    case SET_REMIX_SUBASSET_REDIRECT:
      return {
        ...state,
        isChildContentRedirect: action.isChildContentRedirect
      };
    case SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.contentType,
      };
    case SET_CONTENT_DATA:
      return {
        ...state,
        contentData: action.contentData,
      };
    case SET_AUTO_CUT:
      return {
        ...state,
        isAutoCut: action.isAutoCut,
      };
    case SET_JOB_STATUS_KEYS:
      return {
        ...state,
        jobStatusKeys: action.jobStatusKeys,
      };
    default:
      return state;
  }
};

//selectors
export const getLastContentTabOpened = (state) => state.content.lastContentTabOpened;
export const getContentFormats = (state) => state.content.contentFormats;
export const getContentTypes = (state) => state.content.contentTypes;
export const getContentDrms = (state) => state.content.contentDrms;
export const getContentStreams = (state) => state.content.contentStreams;
