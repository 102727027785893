import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const TabPanel = ({ children, value, index }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`tabpanel-${index}`}
    aria-labelledby={`tab-${index}`}
  >
    {/**
       * FIXME We should consider leaving the content already rendered on a tab,
       * in order not to re, request and render the content when switching tabs.
       *
       * currently we get a log of warnigs like below when switching a tab,
       * due to hiding the nodes and attempting to update them when receiving the inflight request
       *
       * 'Warning: Can't perform a React state update on an unmounted component.
       * This is a no-op, but it indicates a memory leak in your application.
       * To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.'
       **/}
    {value === index && children}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;
