import React, { useState } from 'react';
import { lookUp } from 'services/stringService';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { arrayOf, func, object } from 'prop-types';
import { withSnackbar } from 'notistack';
import { UnFavouriteIcon } from 'assets/icons/FavouriteIcons'
import PublishedComponent from 'assets/icons/PublishedComponent';
import UnPublishedComponent from 'assets/icons/UnPublishedComponent';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Link,
  makeStyles,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  ConfirmDialog,
  CreatedOn,
} from 'components/index';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  smallColumn: {
    width: '40px'
  },
}));


function FavouriteCatalogs(props) {
  const {
    favourites,
    catalogIcon,
    removeFromFavourite,
    handleDelete,
    breadBuilder,
    addCrumbLink,
    enqueueSnackbar,
    root,
  } = props;

  const classes = useStyles();

  const iconsCell = {
    width: root ? 120 : 200, 
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'flex-start', 
    gap: '10px',
  }

  const tableBody = [
    {
      name: 'type',
      align: 'left',
      render: (item) => (
        <Tooltip  title={item.kind === 'Automatic' ? lookUp({ key: 'CONSOLE_FOLDER'}) : lookUp({ key: 'CONSOLE_MANUAL_FOLDER'})} key={item.id}>
        <Link 
          to={`/apps/catalogs-v3/${item.id}`}
          component={RouterLink}
          onClick={() => breadBuilder(item.id)}
        >
         {catalogIcon(item.kind, item.type)}
        </Link>
        </Tooltip>
      )
    },
    {
      name: 'editAction',
      align: 'left',
      render: (item) => (
        <>
         <Tooltip title='Edit'>
          <Link 
            to={{ pathname:`/apps/catalogs-v3/${item.id}/edit` }}
            component={RouterLink}
            onClick={() => { 
              breadBuilder(item.parentId, [{text: lookUp({ key: 'CONSOLE_EDIT_TEMPLATE', title: item.name })}])}}
          >
          <EditIcon style={{cursor:'pointer', verticalAlign: 'middle', display: 'inline-flex'}}/>
          </Link>  
        </Tooltip>
        </>
      )
    },
    {
      name: 'name',
      align: 'left',
      render: (item) => (
        <Link 
          to={`/apps/catalogs-v3/${item.id}`}
          component={RouterLink}
          onClick={() => {breadBuilder(item.id)}}
        >
           <p style={{color: 'black'}}> {item.name}</p>
        </Link>
      )
    },
    {
      name: 'createdDate',
      align: 'center',
      style: { whiteSpace: 'nowrap' },
      render: (item) => <CreatedOn date={item.createdDate} />
    },
    {
      name: 'icons',
      style: iconsCell,
      render: (item) => (
        <>
          {item.enabled ? (
            <PublishedComponent />
            ) : (
            <UnPublishedComponent />
          )}
          <UnFavouriteIcon
            removeFavourite={() => removeFromFavourite(item.id)} 
          />
          <ConfirmDialog
            onConfirm={() => handleDelete(item)}
            message={lookUp({ key: 'CONSOLE_DELETEITEM_MESSAGE_TEMPLATE', title: item.name || item.key || lookUp({ key: 'CONSOLE_ITEM'})})}
            dialogText={lookUp({ key: 'CONSOLE_DELETE'})}
          />
        </>
      )
    },
  ];

  return (
    <>
      {favourites.length > 0 && (
        <>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  className={classes.smallColumn}
                  align="left"                  
                  >
                  {lookUp({ key: 'CONSOLE_TYPE'})}
                </TableCell>
               <TableCell
                  className={classes.smallColumn}                  
                  >
                </TableCell>
                <TableCell                  
                  >
                  {lookUp({ key: 'CONSOLE_NAME'})}
                </TableCell>
                <TableCell
                  className={classes.smallColumn}                  
                  align="center"
                  >
                  {lookUp({ key: 'CONSOLE_CREATED_ON'})}
                </TableCell>
                <TableCell
                  style={{width: iconsCell.width}}                  
                  >
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {favourites.map((fav, index) => {
                return (
                  <TableRow
                    hover
                    key={fav.id}
                  >
                    {tableBody.map((element) => (
                      <TableCell
                        align={element.align || 'right'}
                        key={`${element.name}_${fav.id}`}
                        style={element.style}
                        component={element.name === 'name' ? 'th' : ''}
                      >
                        {element.render(fav)}
                      </TableCell>
                    ))}
                 </TableRow>
              )})}
            </TableBody>
          </Table>
        </>
      )}
    </>
  );
}

FavouriteCatalogs.propTypes = {
  favourites: arrayOf(object),
  removeFromFavourite: func.isRequired,
  enqueueSnackbar: func.isRequired,
  catalogIcon: func.isRequired,
};

FavouriteCatalogs.defaultProps = {
  favourites: [],
};

export default withSnackbar(FavouriteCatalogs);
