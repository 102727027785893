import { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { lookUp } from 'services/stringService';
import { withSnackbar } from 'notistack';
import Loader from 'components/Loader';
import AudioVideoEditor from 'pages/Content/Editor/AudioVideoEditor';
import ImageEditor from 'pages/Content/Editor/ImageEditor';
import RemixEditor from 'pages/Content/Editor/RemixEditor';
import contentService from 'services/contentService';
import AlertService from 'services/alertService';
import contentAction from 'store/actions/contentAction';


const Editors = ({ enqueueSnackbar }) => {
  const { id, markerType, markerId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const contentType = useSelector((state) => state.content.contentType);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    contentService
      .getById(id)
      .then((data) => {
        dispatch(contentAction.setContentData(data));
        dispatch(contentAction.setContentType(data.type));
      })
      .catch((error) => {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({ key: 'CONSOLE_LOAD_ERROR' }),
        });
        navigate('/content');
      })
      .finally(() => setIsLoading(false));

      return () => {
        dispatch(contentAction.setContentData({}));
        dispatch(contentAction.setContentType(''));
      };
  }, [id]);

  const ContentEditor = () => {
    if (contentType) {
      switch (contentType) {
        case 'Image':
          return <ImageEditor history={history} markerType={markerType} markerId={markerId} />;
        case 'Playlist':
        case 'Remix':
          return <RemixEditor id={id} history={history} />
        default:
          return <AudioVideoEditor history={history} markerType={markerType} markerId={markerId} />
      }
    } else {
      return null;
    }
  };

  return <>{isLoading ? <Loader /> : ContentEditor()}</>
};

export default withSnackbar(Editors);
