import React from 'react';
import { lookUp } from 'services/stringService';
import { Typography } from '@material-ui/core';
import { CenteredBlock } from 'components';

export default function NotFound() {
  return (
    <CenteredBlock height={'88vh'}>
      <Typography variant={'h2'} gutterBottom>
        404
      </Typography>
      <Typography variant={'h3'}>
        {lookUp({ key: 'CONSOLE_Page_not_found' })}
      </Typography>
    </CenteredBlock>
  );
}
