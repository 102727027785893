import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from 'components/Loader';
import PropertiesTab from 'components/FormTabs/PropertiesTab';
import Monetization from 'pages/Content/Form/Monetization';
import RelatedComboBox from 'components/RelatedComboBox';
import SectionContainer from 'components/SectionContainer';
import CastAndCrew from 'pages/Content/Form/CastAndCrew';
import { utcToLocalTimeZone, localTimeZoneToUtc } from 'utils/timezoneDate';
import { lookUp } from 'services/stringService';
import parametersService from 'services/parametersService';
import parametersAction from 'store/actions/parametersAction';
import AlertService from 'services/alertService';
import { getContentNotificationEnums } from 'services/contentService';

const LOCATION_PATHNAME_CREATE = 'create';
const VISIBILITY_PUBLIC = 'Public';
const VISIBILITY_PRIVATE = 'Private';
const VISIBILITY_PERSONAL = 'Personal';
const DEFAULT_VISIBILITY = VISIBILITY_PUBLIC;
const VISIBILITY_OPTIONS = [VISIBILITY_PUBLIC, VISIBILITY_PRIVATE, VISIBILITY_PERSONAL];

// NOTE define form groups and fields sizes in MUI 12 grid system
// TODO move into constants file
const FORM_GROUP_WIDTH = 12;
const FORM_FIELD_MEDIUM = 6;
const FORM_FIELD_SMALL = 3;
const FORM_FIELD_SPACING = 1;
const FORM_SECTION_SPACING = 2;
const TEXT_FIELD_SIZE = 'small';

const useStyles = makeStyles((theme) => ({
  leftIndent: {
    paddingLeft: theme.spacing(1),
  },
}));

function BasicTab({
  model,
  handleChange = noop,
  handleAuthGroupChange = noop,
  contentTypeOptions = [],
  setSelectedRelation = noop,
  setTargetType = noop,
  triggers = {},
  openSelector = noop,
  enqueueSnackbar = noop,
  formErrors = {},
  setFormErrors = noop,
  localeOptions,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const [notificationTypes, setNotificationTypes] = useState([]);
  const classes = useStyles();

  const routeParams = useParams();

  const defaultLocale = useSelector((state) => state.parameters.defaultLocale);

  const dispatch = useDispatch();

  const { type } = model;

  useEffect(() => {
    if (model.id || pathname?.includes?.(LOCATION_PATHNAME_CREATE)) {
      setIsLoading(false);
    }
  }, [model, pathname]);

  const onDateChange = (e) => {
    e.preventDefault();
    handleChange('releaseDate', localTimeZoneToUtc({ date: e.target.value }));
  };

  useEffect(() => {
    const fetchNotificationTypes = async () => {
      try {
        const types = await getContentNotificationEnums();
        setNotificationTypes(types);
      } catch (error) {
        AlertService.displayError({
          msgBar: enqueueSnackbar,
          error,
          context: lookUp({
            key: 'CONSOLE_LOAD_ERROR_TEMPLATE',
            type: 'Notification Enums',
          }),
        });
      }
    };

    fetchNotificationTypes();
  }, []);

  const getLocalizedDateString = () => {
    if (!model.releaseDate) {
      return;
    }

    return utcToLocalTimeZone({
      date: model.releaseDate,
      asISO: true,
    });
  };

  const handleRelationshipClick = (selectedRelation, targetType) => {
    openSelector();
    setSelectedRelation(selectedRelation);
    setTargetType(targetType);
  };

  useEffect(() => {
    const getDefaultLocaleLang = async () => {
      try {
        const response = await parametersService.getByKey('Settings:DefaultLocale');
        const lang = response?.value;
        if (/^[a-z]{2}-[A-Z]{2}$/.test(lang)) {
          dispatch(parametersAction.setDefaultLocaleLang({ lang, paramChecked: true }));
          if (!model.id && !routeParams.id) handleChange('originalLanguage', lang);
        } else {
          throw new Error('CONSOLE_INVALID_DEFAULT_LOCALE');
        }
      } catch (error) {
        if (error?.message === 'CONSOLE_INVALID_DEFAULT_LOCALE') {
          AlertService.displayWarning({
            msgBar: enqueueSnackbar,
            message: lookUp({ key: error.message }),
          });
        } else {
          AlertService.displayError({
            msgBar: enqueueSnackbar,
            message: error,
          });
        }
        dispatch(parametersAction.setDefaultLocaleLang({ ...defaultLocale, paramChecked: true }));
      }
    };

    if (!defaultLocale.paramChecked) {
      getDefaultLocaleLang();
    } else if (!model.id && !routeParams.id) {
      handleChange('originalLanguage', defaultLocale.lang);
    }
  }, [defaultLocale]);

  return (
    <SectionContainer flex={1}>
      {isLoading ? (
        <Loader inline />
      ) : (
        <Grid container spacing={FORM_SECTION_SPACING}>
          {contentTypeOptions?.length > 0 && (
            <Grid item md={FORM_FIELD_SMALL}>
              <FormControl fullWidth>
                <TextField
                  select
                  label={lookUp({ key: 'CONSOLE_TYPE' })}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  fullWidth
                  value={type}
                  onChange={(event) => {
                    handleChange('type', event.target.value);
                    setFormErrors({ ...formErrors, contentType: null });
                  }}
                  size={TEXT_FIELD_SIZE}
                  required
                  error={formErrors.contentType}
                >
                  {contentTypeOptions.map((o) => (
                    <MenuItem label={o.label} key={o.label} value={o.value}>
                      {lookUp({ key: `CONSOLE_${o.value}` })}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText
                  id="contentType-helper"
                  error={formErrors.contentType}
                  className={classes.leftIndent}
                >
                  {formErrors.contentType
                    ? formErrors.contentType
                    : lookUp({ key: 'CONSOLE_CONTENTTYPE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item container direction="row" spacing={FORM_FIELD_SPACING}>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_DOWNLOADABLE' })}>
                <Switch
                  checked={model.downloadable}
                  onChange={() => handleChange('downloadable', !model.downloadable)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_DOWNLOADABLE' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWCOMMENTS' })}>
                <Switch
                  checked={model.allowComments}
                  onChange={() => handleChange('allowComments', !model.allowComments)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWCOMMENTS' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWMINTING' })}>
                <Switch
                  checked={model.allowMinting}
                  onChange={() => handleChange('allowMinting', !model.allowMinting)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWMINTING' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWCHAT' })}>
                <Switch
                  checked={model.allowChat}
                  onChange={() => handleChange('allowChat', !model.allowChat)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWCHAT' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWREMIX' })}>
                <Switch
                  checked={model.allowRemix}
                  onChange={() => handleChange('allowRemix', !model.allowRemix)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWREMIX' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWSLIDESHOW' })}>
                <Switch
                  checked={model.allowSlideshow}
                  onChange={() => handleChange('allowSlideshow', !model.allowSlideshow)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWSLIDESHOW' })}</Typography>
            </Grid>
            <Grid item>
              <Tooltip title={lookUp({ key: 'CONSOLE_ALLOWLYRICS' })}>
                <Switch
                  checked={model.allowLyrics}
                  onChange={() => handleChange('allowLyrics', !model.allowLyrics)}
                  color={'primary'}
                />
              </Tooltip>
              <Typography variant="caption">{lookUp({ key: 'CONSOLE_ALLOWLYRICS' })}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={FORM_FIELD_SPACING}>
            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_ORIGINALTITLE' })}
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  value={model.originalTitle || ''}
                  onChange={(e) => {
                    if (formErrors.originalTitle && e.target.value?.length > 0) {
                      setFormErrors({ ...formErrors, originalTitle: null });
                    } else if (!e.target.value.length) {
                      setFormErrors({
                        ...formErrors,
                        originalTitle: lookUp({ key: 'CONSOLE_MISSING_TITLE_ERROR' }),
                      });
                    }
                    handleChange('originalTitle', e.target.value);
                  }}
                  size={TEXT_FIELD_SIZE}
                  required
                  error={formErrors.originalTitle}
                />
                <FormHelperText
                  id="originalTitle-helper"
                  error={formErrors.originalTitle}
                  className={classes.leftIndent}
                >
                  {formErrors.originalTitle
                    ? formErrors.originalTitle
                    : lookUp({ key: 'CONSOLE_ORIGINALTITLE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={FORM_FIELD_SMALL} sm={12}>
              <FormControl fullWidth>
                <TextField
                  label={lookUp({ key: 'CONSOLE_ORIGINALLANGUAGE' })}
                  value={model.originalLanguage || ''}
                  onChange={(e) => {
                    if (formErrors.originalLanguage && e.target.value?.length > 0) {
                      setFormErrors({ ...formErrors, originalLanguage: null });
                    } else if (!e.target.value.length) {
                      setFormErrors({
                        ...formErrors,
                        originalLanguage: lookUp({ key: 'CONSOLE_MISSING_LANGUAGE_ERROR' }),
                      });
                    }
                    handleChange('originalLanguage', e.target.value);
                  }}
                  size={TEXT_FIELD_SIZE}
                  error={formErrors.originalLanguage}
                  fullWidth
                  select
                >
                  {Object.entries(localeOptions).map(([code, label]) => (
                    <MenuItem label={label} key={label} value={code}>
                      {code === '--'
                        ? lookUp({ key: 'CONSOLE_SET_AS_DEFAULT' })
                        : lookUp({ key: `CONSOLE_LANG_${code}` })}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText
                  id="originalLanguage-helper"
                  error={formErrors.originalLanguage}
                  className={classes.leftIndent}
                >
                  {formErrors.originalLanguage
                    ? formErrors.originalLanguage
                    : lookUp({ key: 'CONSOLE_ORIGINALLANGUAGE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item container spacing={FORM_FIELD_SPACING}>
            <Grid item>
              <TextField
                label={lookUp({ key: 'CONSOLE_RELEASEDATE' })}
                InputLabelProps={{ shrink: true }}
                helperText={lookUp({ key: 'CONSOLE_RELEASEDATE_HELPERTEXT' })}
                type="datetime-local"
                value={getLocalizedDateString()}
                inputProps={{
                  step: 1,
                }}
                onChange={onDateChange}
                size={TEXT_FIELD_SIZE}
              />
            </Grid>
            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_DURATION' })}
                InputLabelProps={{ shrink: true }}
                helperText={lookUp({ key: 'CONSOLE_DURATION_HELPERTEXT' })}
                type="time"
                required
                inputProps={{
                  step: '1',
                  value: model.duration
                    ? new Date(model.duration).toISOString().split('T')[1].split('.')[0]
                    : '00:00:00',
                }}
                onChange={(e) =>
                  handleChange(
                    'duration',
                    e.target.value.split(':').reduce((acc, time) => 60 * acc + parseInt(time)) * 1000,
                  )
                }
                size={TEXT_FIELD_SIZE}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={FORM_FIELD_SPACING}>
            <Grid item md={FORM_FIELD_MEDIUM} sm={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_EXTERNALID' })}
                helperText={lookUp({ key: 'CONSOLE_EXTERNALID_HELPERTEXT' })}
                inputProps={{
                  maxLength: 50,
                }}
                fullWidth
                value={model.externalId || ''}
                onChange={(e) => handleChange('externalId', e.target.value)}
                size={TEXT_FIELD_SIZE}
              />
            </Grid>
            <Grid item md={FORM_FIELD_SMALL} sm={12}>
              <TextField
                label={lookUp({ key: 'CONSOLE_REFERENCEID' })}
                helperText={lookUp({ key: 'CONSOLE_REFERENCEID_HELPERTEXT' })}
                inputProps={{
                  maxLength: 50,
                }}
                fullWidth
                value={model.referenceId || ''}
                onChange={(e) => handleChange('referenceId', e.target.value)}
                size={TEXT_FIELD_SIZE}
              />
            </Grid>
          </Grid>
          {/* TODO create options array and map over */}
          <Grid item container spacing={FORM_FIELD_SPACING}>
            <Grid item md={FORM_FIELD_SMALL}>
              <FormControl variant="outlined" size={TEXT_FIELD_SIZE} fullWidth>
                <InputLabel id="publishingRule">
                  {lookUp({ key: 'CONSOLE_PUBLISHING_RULE' })}
                </InputLabel>
                <Select
                  labelId="publishingRule"
                  label={lookUp({ key: 'CONSOLE_PUBLISHING_RULE' })}
                  value={model.publishingRule}
                  defaultValue={'Upcoming'}
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange('publishingRule', e.target.value);
                  }}
                  size={TEXT_FIELD_SIZE}
                >
                  <MenuItem value="Upcoming">Upcoming</MenuItem>
                  <MenuItem value="InTime">In Time</MenuItem>
                </Select>
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_PUBLISHING_RULE_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={FORM_FIELD_SMALL}>
              <FormControl variant="outlined" size={TEXT_FIELD_SIZE} fullWidth>
                <InputLabel id="visibility">{lookUp({ key: 'CONSOLE_VISIBILITY' })}</InputLabel>
                <Select
                  labelId="visibility"
                  label={lookUp({ key: 'CONSOLE_VISIBILITY' })}
                  value={model.visibility}
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange('visibility', e.target.value);
                  }}
                  size={TEXT_FIELD_SIZE}
                >
                  {VISIBILITY_OPTIONS.map?.((visibility = '') => (
                    <MenuItem key={visibility} value={visibility}>
                      {visibility}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{lookUp({ key: 'CONSOLE_VISIBILITY_HELPERTEXT' })}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={FORM_FIELD_SMALL}>
              <FormControl variant="outlined" size={TEXT_FIELD_SIZE} fullWidth>
                <InputLabel id="notification">{lookUp({ key: 'CONSOLE_NOTIFICATION' })}</InputLabel>
                <Select
                  labelId="notification"
                  label={lookUp({ key: 'CONSOLE_NOTIFICATION' })}
                  value={model.notification}
                  defaultValue={'Followed'}
                  onChange={(e) => {
                    e.preventDefault();
                    handleChange('notification', e.target.value);
                  }}
                  size={TEXT_FIELD_SIZE}
                >
                  {notificationTypes.map?.((notification = '') => (
                    <MenuItem key={notification} value={notification}>
                      {notification}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {lookUp({ key: 'CONSOLE_NOTIFICATION_HELPERTEXT' })}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_NOTIFY_CUSTOMERS' })}
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <RelatedComboBox
                relation="Notification"
                sourceType="Notification"
                targetType="Notification"
                id={model.id}
                trigger={triggers?.['Notification']}
                onFocus={() => handleRelationshipClick('Notification', 'Notification')}
                size={TEXT_FIELD_SIZE}
              />
              <FormHelperText>{lookUp({ key: 'CONSOLE_NOTIFICATION_HELPERTEXT' })}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_MODERATION' })}
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <RelatedComboBox
                relation="Moderator"
                sourceType="Moderator"
                targetType="Moderator"
                id={model.id}
                trigger={triggers?.['Moderator']}
                onFocus={() => handleRelationshipClick('Moderator', 'Moderator')}
                size={TEXT_FIELD_SIZE}
              />
              <FormHelperText>{lookUp({ key: 'CONSOLE_MODERATION_HELPERTEXT' })}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_MONETIZATION_HEADING' })}
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <Monetization
                handleChange={handleChange}
                model={model}
                handleAuthGroupChange={handleAuthGroupChange}
                size={TEXT_FIELD_SIZE}
              />
              <FormHelperText>{lookUp({ key: 'CONSOLE_MONETIZATION_HELPERTEXT' })}</FormHelperText>
            </FormControl>
          </Grid>
          {model.type === 'Merchandise' && (
            <>
              <Grid item md={4} sm={12}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_INTERNALPRICE' })}
                  helperText={lookUp({ key: 'CONSOLE_INTERNALPRICE_HELPERTEXT' })}
                  inputProps={{
                    maxLength: 50,
                    type: 'number',
                    min: 0,
                  }}
                  fullWidth
                  value={model.internalPrice ?? null}
                  onChange={(e) => handleChange('internalPrice', +e.target.value)}
                  size={TEXT_FIELD_SIZE}
                />
              </Grid>
              <Grid item md={4} sm={12}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_EXTERNALPRICE' })}
                  helperText={lookUp({ key: 'CONSOLE_EXTERNALPRICE_HELPERTEXT' })}
                  inputProps={{
                    maxLength: 50,
                    type: 'number',
                    min: 0,
                    step: 0.01,
                  }}
                  fullWidth
                  value={model.externalPrice ?? null}
                  onChange={(e) => handleChange('externalPrice', +e.target.value)}
                  size={TEXT_FIELD_SIZE}
                />
              </Grid>
              <Grid item md={3} sm={12}>
                <TextField
                  label={lookUp({ key: 'CONSOLE_EXTERNALCURRENCY' })}
                  helperText={lookUp({ key: 'CONSOLE_EXTERNALCURRENCY_HELPERTEXT' })}
                  inputProps={{
                    maxLength: 10,
                  }}
                  fullWidth
                  value={model.externalCurrency ?? null}
                  onChange={(e) => handleChange('externalCurrency', e.target.value)}
                  size={TEXT_FIELD_SIZE}
                />
              </Grid>
              <Grid item md={1}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    handleChange('internalPrice', null);
                    handleChange('externalPrice', null);
                    handleChange('externalCurrency', null);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </>
          )}

          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_RELATED_HEADING' })}
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <RelatedComboBox
                sourceType="Content"
                targetType="Content"
                id={model.id}
                relation="Related"
                trigger={triggers?.['Related']}
                onFocus={() => handleRelationshipClick('Related', 'Content')}
                size={TEXT_FIELD_SIZE}
              />
              <FormHelperText>{lookUp({ key: 'CONSOLE_RELATED_HELPERTEXT' })}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_CAST_AND_CREW_HEADING' })}
            </Typography>
            <CastAndCrew model={model} handleChange={handleChange} size={TEXT_FIELD_SIZE} />
          </Grid>
          <Grid item md={FORM_GROUP_WIDTH} sm={12}>
            <Typography variant="h6" gutterBottom>
              {lookUp({ key: 'CONSOLE_PROPERTIES_HEADING' })}
            </Typography>
            <PropertiesTab
              model={model}
              handlePropertiesChange={(properties) => handleChange('properties', properties)}
              size={TEXT_FIELD_SIZE}
            />
          </Grid>
        </Grid>
      )}
    </SectionContainer>
  );
}

BasicTab.propTypes = {
  handleAuthGroupChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  contentTypeOptions: PropTypes.array.isRequired,
  setSelectedRelation: PropTypes.func.isRequired,
  setTargetType: PropTypes.func.isRequired,
  triggers: PropTypes.object.isRequired,
  openSelector: PropTypes.func,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default withSnackbar(BasicTab);
